import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Route } from 'react-router';

import Menu from '../menu/Menu';
import DiarioBordo from '../diarioBordo/DiarioBordo';
import PlanoDesenvolvimentoPessoal from '../pdp/PlanoDesenvolvimentoPessoal';
import { PainelSocioemocionalStyle, MainContainer } from './PainelSocioemocional.styled';
import HomeSocioemocional from './HomeSocioemocional';
import PainelSocioProvider from './home/context/PainelSocioProvider';
import AutoAvalicao from 'pages/autoavaliacao/Autoavaliacao';
import DiarioBordoProvider from 'pages/diarioBordo/context/DiarioBordoProvider';
import { useKeycloak } from '@react-keycloak/web';
import PainelSocioemocionalHeader from './PainelSocioemocionalHeader';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { ToasterDefault } from 'components/Toaster';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import CheckedGoToProvider from './home/context/CheckedGoToProvider';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

interface IPainelSocioemocional {}
const PainelSocioemocional: FunctionComponent<IPainelSocioemocional> = props => {
    const { keycloak } = useKeycloak();
    const { isSearchView, isResultView } = useContext(PesquisaContext.Context);
    const [isAutoAvaliacao, setAutoAvaliacao] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const ContainerToolbar = ({ children }: React.PropsWithChildren<{}>) => {
        return <>{children}</>;
    };

    useEffect(() => {
        const path = window.location.pathname;
        setAutoAvaliacao(path === '/painelSocioemocional/autoavaliacao');
    }, [window.location.pathname]);

    return (
        <PainelSocioProvider>
            <CheckedGoToProvider>
                <PainelSocioemocionalStyle>
                    {/*  <Menu homeSocioemocional /> */}
                    <DynamicHeader />

                    <MainContainer id="section-pse">
                        <Route
                            exact
                            path={['/painelSocioemocional/home', '/painelSocioemocional']}
                            component={() => (
                                <>
                                    <PainelSocioemocionalHeader />
                                    <HomeSocioemocional />
                                </>
                            )}
                        />

                        <Route
                            path="/painelSocioemocional/pdp"
                            component={() => (
                                <ContainerToolbar>
                                    <PainelSocioemocionalHeader />
                                    <PlanoDesenvolvimentoPessoal />
                                </ContainerToolbar>
                            )}
                        />

                        <Route
                            path="/painelSocioemocional/diarioBordo"
                            component={() => (
                                <ContainerToolbar>
                                    <DiarioBordoProvider>
                                        <PainelSocioemocionalHeader />
                                        <DiarioBordo />
                                    </DiarioBordoProvider>
                                </ContainerToolbar>
                            )}
                        />

                        <Route
                            path="/painelSocioemocional/autoavaliacao"
                            component={() => (
                                <ContainerToolbar>
                                    <PainelSocioemocionalHeader />
                                    <AutoAvalicao />
                                </ContainerToolbar>
                            )}
                        />
                    </MainContainer>
                </PainelSocioemocionalStyle>
                {!isAutoAvaliacao && <ContactContainer />}
                {ToasterDefault()}

                {/* {isSearchView && <BarraPesquisa />} */}
            </CheckedGoToProvider>
        </PainelSocioProvider>
    );
};

export default PainelSocioemocional;
