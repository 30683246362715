import styled from 'styled-components';

import { OutlineButton } from '../UI/Buttons';
import { MobileMaxWidth } from '../../constants/Dimensions';
import FullCss, { MediaQuery } from '../../core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from '../../core/util/cssHelp';

export const ButtonsContainer = styled.div(() => {
    return new FullCss(
        {
            marginTop: '16px',
            display: 'flex',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'column',
        })
    ).getCss();
});

export const OutlineButtonStyled = styled(OutlineButton)(() => {
    return new FullCss(
        {
            marginLeft: '16px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            marginLeft: 'unset',
            marginTop: '10px',
        })
    ).getCss();
});
