export namespace ArrayHelp {
    /**
     * Dado um array, é retornado um array de arrays desse array de entrada
     * @param arr Array de entrada
     * @param size Tamanho máximo do chunk
     * @returns Um array de arrays
     */
    export const getChunks = <T>(arr: T[], size: number): T[][] => {
        if (arr.length <= size) {
            return [arr];
        }

        return [arr.slice(0, size), ...getChunks(arr.slice(size), size)];
    };

    /**
     * Transforma um array bidimensional em unidimensional
     * @param arr Array bidimensional a ser mesclado
     * @returns Array unidimensional
     */
    export const merge = <T>(arr: T[][]): T[] => {
        const out: T[] = [];
        arr.forEach(item => {
            out.push(...item);
        });
        return out;
    };

    export const ensure = <T>(argument: T | undefined | null, message: string = 'Valor selecionado não encontrado na lista de opções'): T => {
        if (argument === undefined || argument === null) {
            throw new TypeError(message);
        }

        return argument;
    };
}
