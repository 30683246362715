import React, { useState } from 'react';
import { ComentarioGestaoAdmin } from 'models/recursos-costumizados/ComentariosRecursosCostumizados';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import Styled from './../../admin/subpages/socio/AdminSocioEmocional.styled';
import { capitalize } from 'lodash';
import { DateHelp } from 'core/util/DateHelp';
import { FormControl } from 'react-bootstrap';

export interface IAdminModalComentarioRecurso {
    show: boolean;
    onHide: () => void;
    comment?: ComentarioGestaoAdmin;
    updateData: () => void;
}

const service = new AdminRecursosCostumizados();
const CommentModal = ({ show, onHide, comment, updateData }: IAdminModalComentarioRecurso) => {
    const [answer, setAnswer] = useState<string>('');
    const [askExclude, setAskExclude] = useState<boolean>(false);

    const submitAnswer = async () => {
        if (comment) {
            const { status } = await service.adicionarResposta(comment.codigo, answer);

            if (status == 200) {
                ToastSuccess('Resposta Enviada');
                setAnswer('');
                onHide();
                updateData();
            } else {
                ToastError('Erro ao enviar Resposta');
            }
        }
    };

    const excludeAnswer = async (answerId: number) => {
        const { status } = await service.excluirResposta(answerId);

        if (status == 200) {
            ToastSuccess('Resposta Excluída');
            setAnswer('');
            onHide();
            updateData();
        } else {
            ToastError('Erro ao enviar Resposta');
        }
    };

    return (
        <AdminModalShow show={show} onHide={onHide}>
            {comment && (
                <>
                    <Styled.Modal.SubTitle style={{ marginTop: -30 }}>{capitalize(comment.nomeConteudo)}</Styled.Modal.SubTitle>
                    <br />
                    <Styled.Modal.UserName>{comment.nomeUsuario}</Styled.Modal.UserName>
                    <Styled.Modal.Date>{DateHelp.formatDateFull(comment.dataCriacao)}</Styled.Modal.Date>
                    <Styled.Modal.Text>{comment.comentario}</Styled.Modal.Text>
                </>
            )}
            <hr/>
            {comment?.respostas?.map(resposta => {
                return (
                    <>
                        <Styled.Modal.UserName>{resposta.nomePerfil}</Styled.Modal.UserName>
                        <Styled.Modal.Text style={{ marginTop: '0', marginLeft: '10px' }}>{resposta.conteudo}</Styled.Modal.Text>
                        <Styled.Modal.ExcludeContainer>
                            {!askExclude ? (
                                <span className="exclude-inicial" onClick={() => setAskExclude(true)}>
                                    Excluir Resposta
                                </span>
                            ) : (
                                <>
                                    <span className="exclude-ask">Excluir?</span>
                                    <span className="exclude-confirm" onClick={() => excludeAnswer(resposta.codigo!)}>
                                        Sim
                                    </span>
                                    <span className="exclude-confirm" onClick={() => setAskExclude(false)}>
                                        Não
                                    </span>
                                </>
                            )}
                        </Styled.Modal.ExcludeContainer>
                    </>
                );
            })}
            <hr/>
            <FormControl as="textarea" rows={2} value={answer} onChange={e => setAnswer(e.target.value)} />
            <Styled.Modal.ButtonArea>
                <Styled.Modal.GradientButton disabled={!answer} onClick={submitAnswer}>
                    Responder
                </Styled.Modal.GradientButton>
            </Styled.Modal.ButtonArea>
        </AdminModalShow>
    );
};

export default CommentModal;
