import { useKeycloak } from '@react-keycloak/web';
import DefaultVideoFrame from 'components/DefaultVideoFrame';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import { videoRealizandoEscolhasUrl } from 'constants/Resources';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import SharingContent from '../components/SharingContent';
import {
    Container,
    TopContent,
    Byline,
    Title,
    InnerTopContent,
    BottomContent,
    ButtonInitialPage,
    ButtonNext,
    Content,
    Text,
    Separator,
    SubTitle,
} from './Escolhas.styled';
import SharingContentMobile from '../components/SharingContentMobile';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';

export interface IEscolhas {}

const Escolhas = ({}: IEscolhas) => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const service = new RealizandoEscolhasService();
    const [ocupacao, setOcupacao] = useState('');
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const CODIGO_TIPO_CONTEUDO: number = 1;

    const registrarAcesso = async () => {
        await service.registrarAcesso(CODIGO_TIPO_CONTEUDO);
    };

    useEffect(() => {
        registrarAcesso();
        const userOcupacao = buscarOcupacaoPorPerfil(profile);
        setOcupacao(userOcupacao);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('escolhas_realizando_escolhas', 'voltar_home_realizando_escolhas');
        history.push('/realizandoEscolhas/home');
    };

    const goToTrilhaFormativa = () => {
        GTM_Page_Realizando_Escolhas('fundamentos_realizando_escolhas', 'acessar_trilha_formativa');
        history.push('/realizandoEscolhas/trilhaFormativa');
    };

    return (
        <Container>
            <TopContent>
                <InnerTopContent>
                    <Title>{'Por que falar de escolhas?'}</Title>
                    <Byline>{'Seu ponto de partida'}</Byline>
                </InnerTopContent>
                {IsMobileMaxWidth() ? <SharingContentMobile location="escolhas" /> : <SharingContent location="escolhas" />}
            </TopContent>

            <Content>
                <Text>
                    Um dos grandes desafios que os estudantes enfrentam, especialmente ao final do Ensino Fundamental e ao longo do Ensino Médio,
                    é <b>lidar com os sentimentos e pensamentos sobre o futuro</b>, uma vez que, embora se possa fazer planos, implementá-los
                    sempre é uma tarefa difícil e incerta.
                    <br />
                    <br />
                    Quando se considera um adolescente, as incertezas sobre o futuro tendem a ser ainda maiores, já que socialmente, se espera
                    dele ou dela definições complexas sobre os diversos temas da vida em um momento em que mudanças físicas, psicológicas e
                    sociais estão em pleno vapor.
                    <br />
                    <br />
                    Ao mesmo tempo, a experiência de vida já adquirida até então, que tanto ajuda nas escolhas, ainda está em construção.
                </Text>

                <SubTitle>Veja o vídeo de apresentação do projeto</SubTitle>
                <DefaultVideoFrame url={videoRealizandoEscolhasUrl} width={IsMobileMaxWidth() ? 400 : 550} height={280} />

                <Text>
                    Estudo recente com este público apontou para a{' '}
                    <b>
                        alta frequência de jovens que têm projetos de vida frágeis - muito vagos em relação aos seus planos futuros ou com uma
                        visão idealizada de progressão para alcance de uma vida boa
                    </b>
                    , o que indica a importância do desenvolvimento de um trabalho intencional e estruturado sobre escolhas e projeto de vida com
                    esses jovens.
                    <br />
                    <br />
                    Recentemente, com o Novo Ensino Médio, essa necessidade é reforçada, já que a partir de agora os jovens devem fazer escolhas
                    relacionadas à sua trajetória de formação, tornando-a mais aderente aos seus interesses e projetos de vida.
                </Text>

                <Text>
                    Nesse contexto, as evidências nos trazem formas de de apoiar os jovens na realização de escolhas mais seguras e informadas,
                    que dialoguem diretamente com os seus interesses de aprendizagem e de construção de projetos futuros.
                </Text>

                <Separator />

                <Text style={{ fontSize: '12px', color: 'black' }}>
                    1 Segundo os autores, cerca de 60% dos entrevistados possuíam projetos de vida considerados frágeis ou idealizados. Dados
                    sobre o estudo podem ser encontrados em Ulisses, Arantes e Pinheiro (2020) Projetos de vida: Fundamentos psicológicos, éticos
                    e práticas educacionais. Editora Summus Editorial
                </Text>
            </Content>
            <br />
            <br />

            <BottomContent style={!IsMobile() ? {} : { flexDirection: 'column-reverse' }}>
                {IsMobile() && (
                    <BottomContent>
                        <ButtonInitialPage onClick={goToHomePage}>{'Página inicial Realizando Escolhas'}</ButtonInitialPage>
                    </BottomContent>
                )}
                {ocupacao !== 'C' && <ButtonNext onClick={goToTrilhaFormativa}>{'Acessar trilha formativa'}</ButtonNext>}
            </BottomContent>
        </Container>
    );
};

export default Escolhas;
