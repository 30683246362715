import React, { useContext, useEffect } from 'react';
import { BibliotecaContext } from 'pages/biblioteca_v2/context/BibliotecaContext';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Styled from './BiblioTagSelector.styled';
import { useHistory, useLocation } from 'react-router-dom';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import { capitalize } from 'lodash';

export interface IBiblioTagsContainer {}

const BiblioTagsContainer = ({}: IBiblioTagsContainer) => {
    const location = useLocation();
    const history = useHistory();
    const { tagsFilter, setTagsFilter, categoriaLabel, filtroLabel, removeCategoriaLabel, removeFiltroLabel } = useContext(
        BibliotecaContext.Context
    );

    const removeTag = (newTag: string) => {
        const pathname = location.pathname;
        const serachparams = new URLSearchParams(location.search);
        let tagsCpy = tagsFilter.filter(c => c != newTag);
        setTagsFilter(tagsCpy);

        if (tagsCpy.length == 0) serachparams.delete('tag');
        else serachparams.set('tag', tagsCpy.join(','));

        serachparams.delete('page');

        history.push(pathname + '?' + serachparams.toString());
    };

    const removeCategoria = () => {
        removeCategoriaLabel();
        const re = /\/categoria\/[a-zA-Z0-9_]+/g;
        history.push(`${location.pathname}${location.search}`.replace(re, ''));
    };

    const removeFiltroPadrao = () => {
        removeFiltroLabel();
        const re = /tag_padrao=[a-zA-Z0-9_]+/g;
        history.push(`${location.pathname}${location.search}`.replace(re, ''));
    };

    useEffect(() => {
        const serachparams = new URLSearchParams(location.search);
        if (!serachparams.has('tag')) setTagsFilter([]);
        if (!serachparams.has('tag_padrao')) removeFiltroLabel();
        if (!serachparams.has('categoria')) removeCategoriaLabel();
    }, [location.pathname, location.search]);

    const convertFiltroPadraoLabel = (value: string): string => {
        if (value == 'POPULARES') return 'Mais populares';
        if (value == 'MAISRECENTES') return 'Mais Recentes';
        if (value == 'VISTOSRECENTES') return 'Vistos recentemente';
        if (value == 'MELHORESAVALIADOS') return 'Melhores avaliados';
        if (value == 'FAVORITOS') return 'Meus favoritos';

        return value;
    };

    return (
        <>
            {(tagsFilter.length > 0 || categoriaLabel.trim().length > 0 || filtroLabel.trim().length > 0) && (
                <Styled.RowTags>
                    <Styled.TextTags>Resultados para o filtro:</Styled.TextTags>

                    {categoriaLabel.trim().length > 0 && (
                        <Styled.TagItem>
                            <span className="label">{decodeURI(categoriaLabel)}</span>
                            <span
                                className="bt"
                                onClick={() => {
                                    GTM_Page_Biblioteca(`${categoriaLabel}`, `remove_${categoriaLabel}`, 'remove_tag_action');
                                    removeCategoria();
                                }}
                            >
                                <AiOutlineCloseCircle />
                            </span>
                        </Styled.TagItem>
                    )}

                    {tagsFilter.map((tag, idx) => {
                        return (
                            <Styled.TagItem key={idx}>
                                <span className="label">{decodeURI(tag)}</span>
                                <span
                                    className="bt"
                                    onClick={() => {
                                        GTM_Page_Biblioteca(`${idx}`, `remove_${tag}`, 'remove_tag_action');
                                        removeTag(tag);
                                    }}
                                >
                                    <AiOutlineCloseCircle />
                                </span>
                            </Styled.TagItem>
                        );
                    })}

                    {filtroLabel.trim().length > 0 && (
                        <Styled.TagItem>
                            <span className="label">{convertFiltroPadraoLabel(decodeURI(filtroLabel))}</span>
                            <span
                                className="bt"
                                onClick={() => {
                                    GTM_Page_Biblioteca(`${filtroLabel}`, `remove_${filtroLabel}`, 'remove_tag_action');
                                    removeFiltroPadrao();
                                }}
                            >
                                <AiOutlineCloseCircle />
                            </span>
                        </Styled.TagItem>
                    )}
                </Styled.RowTags>
            )}
        </>
    );
};

export default BiblioTagsContainer;
