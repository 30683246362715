import React from 'react';

import Menu from '../menu/Menu';

import { NotFoundStyle, MainContainer, Title, ContentContainer } from './NotFound.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const NotFound = () => {
    return (
        <NotFoundStyle>
            {/*  <Menu unauthorized /> */}
            <DynamicHeader />

            <MainContainer>
                <ContentContainer>
                    <Title>{`Pagina não encontrada :(`}</Title>
                </ContentContainer>
            </MainContainer>
        </NotFoundStyle>
    );
};

export default NotFound;
