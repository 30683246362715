import styled, { keyframes } from "styled-components";
import { MobileMaxWidth } from "../../../../../constants/Dimensions";

const fadeIn = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  padding: 20px;
  animation: ${fadeIn} 0.3s linear;

  @media (max-width: ${MobileMaxWidth}px) {
    padding: 40px 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 138px);
  grid-gap: 20px;

  @media (max-width: ${MobileMaxWidth}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
