import { Button, Divider, Drawer, Skeleton } from 'antd';
import { ToastError } from 'components/Toaster';
import { idCursoRealizandoEscolhasGestor } from 'constants/EnvironmentData';
import { ProfileService } from 'core/http/service/Profile.service';
import Colors from 'enums/Colors';
import { JornadaStatusEnum } from 'enums/JornadaStatusEnum';
import useUserData from 'hooks/useUserData';
import FullDashboardDTO from 'models/perfil/FullDashboardDTO';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 27.2px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
    `,

    Content: styled.div`
        display: flex;
        flex-wrap: wrap;
        margin-top: 26px;
        gap: 12px;
    `,

    Card: {
        Container: styled.div<{ isQuantity: boolean }>`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 320px;
            height: ${({ isQuantity }) => (isQuantity ? '220px' : '180px')};
            background-color: ${Colors.White};
            -webkit-box-shadow: 7px 9px 5px -4px rgba(214, 214, 214, 1);
            -moz-box-shadow: 7px 9px 5px -4px rgba(214, 214, 214, 1);
            box-shadow: 7px 9px 5px -4px rgba(214, 214, 214, 1);
            padding: 16px 27px;
            gap: 16px;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            width: 100%;
            color: ${Colors.Blue};
        `,

        Row: styled.div<{ isQuanty: boolean }>`
            display: flex;
            height: ${({ isQuanty }) => (isQuanty ? '100px' : '60px')};
            width: 100%;
            gap: 16px;
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
        `,

        Metric: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px solid #e6e6e6;
            width: 70px;
            height: 64px;
            border-radius: 8px;
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            color: ${Colors.Blue};
        `,

        SubInfo: styled.p`
            font-size: 12px;
            font-weight: 500;
            color: ${Colors.Blue};
        `,

        Status: {
            Container: styled.div<{ type: JornadaStatusEnum }>`
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${({ type }) =>
                    type === JornadaStatusEnum.NAO_INICIADA
                        ? 'rgb(153, 153, 161, 0.8)'
                        : type === JornadaStatusEnum.INICIADA
                        ? `${Colors.Yellow2}`
                        : type === JornadaStatusEnum.FINALIZADA
                        ? `rgb(0, 128, 0)`
                        : ''};
                color: ${({ type }) =>
                    type === JornadaStatusEnum.NAO_INICIADA
                        ? `${Colors.White}`
                        : type === JornadaStatusEnum.INICIADA
                        ? ``
                        : type === JornadaStatusEnum.FINALIZADA
                        ? `${Colors.White}`
                        : ''};
                width: 50%;
                height: 40px;
                border-radius: 8px;
            `,

            StatusText: styled.h1`
                font-family: 'Barlow';
                font-size: 14px;
                font-weight: 700;
            `,
        },
    },

    Button: styled.button`
        width: 180px;
        margin-top: 20px;
        height: 40px;
        border-radius: 8px;
        background-color: ${Colors.Yellow};
        font-size: 16px;
        font-family: 'Barlow';
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0.15000000596046448px;
        border: none;
    `,

    ButtonLink: styled(Button)`

        &:hover {
            text-decoration: underline;
            text-decoration-color: ${Colors.SoftBlue};
            text-underline-offset: calc(5px);

        }
    `
};

type IParmsQuanty = {
    iniciadas: number;
    finalizadas: number;
    nao_iniciadas: number;
};

interface IItemMeuPainel {
    title: string;
    parms: string[];
    link: string;
    type?: JornadaStatusEnum;
    textButton: string;
    isQuantitativo: boolean;
    quanty?: IParmsQuanty;
    loading: boolean;
}

const ItemMeuPainel = ({ title, parms, link, textButton, isQuantitativo, quanty, type, loading }: IItemMeuPainel) => {
    const history = useHistory();

    return (
        <Styled.Card.Container isQuantity={isQuantitativo}>
            <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Styled.Card.Title>{title}</Styled.Card.Title>
            </div>
            <Styled.Card.Row isQuanty={isQuantitativo}>
                {loading ? (
                    <>
                        {isQuantitativo ? (
                            <>
                                <Styled.Card.Column>
                                    <Skeleton.Node active={loading} style={{ width: 70, height: 64 }} />
                                    <Styled.Card.SubInfo>{parms[0]}</Styled.Card.SubInfo>
                                </Styled.Card.Column>
                                <Styled.Card.Column>
                                    <Skeleton.Node active={loading} style={{ width: 70, height: 64 }} />
                                    <Styled.Card.SubInfo>{parms[1]}</Styled.Card.SubInfo>
                                </Styled.Card.Column>
                                <Styled.Card.Column>
                                    <Skeleton.Node active={loading} style={{ width: 70, height: 64 }} />
                                    <Styled.Card.SubInfo>{parms[2]}</Styled.Card.SubInfo>
                                </Styled.Card.Column>
                            </>
                        ) : (
                            <Skeleton.Input active={loading} style={{ width: '50%', height: 40 }} />
                        )}
                    </>
                ) : isQuantitativo ? (
                    <>
                        <Styled.Card.Column>
                            <Styled.Card.Metric>
                                <p>{quanty?.iniciadas}</p>
                            </Styled.Card.Metric>
                            <Styled.Card.SubInfo>{parms[0]}</Styled.Card.SubInfo>
                        </Styled.Card.Column>
                        <Styled.Card.Column>
                            <Styled.Card.Metric>
                                <p>{quanty?.finalizadas}</p>
                            </Styled.Card.Metric>
                            <Styled.Card.SubInfo>{parms[1]}</Styled.Card.SubInfo>
                        </Styled.Card.Column>
                        <Styled.Card.Column>
                            <Styled.Card.Metric>
                                <p>{quanty?.nao_iniciadas}</p>
                            </Styled.Card.Metric>
                            <Styled.Card.SubInfo>{parms[2]}</Styled.Card.SubInfo>
                        </Styled.Card.Column>
                    </>
                ) : (
                    <Styled.Card.Status.Container type={type ?? JornadaStatusEnum.UNKNOWN}>
                        <Styled.Card.Status.StatusText>
                            {type === JornadaStatusEnum.NAO_INICIADA
                                ? 'Não Iniciada'
                                : type === JornadaStatusEnum.INICIADA
                                ? 'Iniciada'
                                : type === JornadaStatusEnum.FINALIZADA
                                ? 'Finalizada'
                                : ''}
                        </Styled.Card.Status.StatusText>
                    </Styled.Card.Status.Container>
                )}
            </Styled.Card.Row>
            <Divider style={{ margin: 0 }} />
            <div style={{ width: '100%' }}>
                <Styled.ButtonLink onClick={() => history.push(link)} type="link" style={{ fontSize: 16, color: Colors.SoftBlue, padding: 0, fontWeight: "500" }}>
                    {textButton}
                </Styled.ButtonLink>
            </div>
        </Styled.Card.Container>
    );
};

interface IMeuPainel {
    open: boolean;
    onClose: () => void;
}

const MeuPainel = ({ open, onClose }: IMeuPainel) => {
    const history = useHistory();
    const [dashboard, setDashboard] = useState<FullDashboardDTO>();
    console.log('🚀 ~ MeuPainel ~ dashboard:', dashboard);
    const [loading, setLoading] = useState<boolean>(false);
    const { isSearchView } = useContext(PesquisaContext.Context);
    const { loadData } = useUserData();

    const fetchDashboard = async () => {
        try {
            setLoading(true);

            const { perfilUsuario } = await loadData();

            const isGestor = buscarOcupacaoPorPerfil(perfilUsuario) === 'B';

            const service = new ProfileService();
            let { data } = await service.getFullDashboard(
                perfilUsuario?.username ?? '',
                isGestor,
                isGestor ? parseInt(idCursoRealizandoEscolhasGestor()) : -1
            );
            const dados = new FullDashboardDTO().fromJSON(data);
            setDashboard(dados);
        } catch (error) {
            ToastError('error' + error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) fetchDashboard();
    }, [open]);

    return (
        <Drawer style={{ zIndex: 1001, backgroundColor: '#fcfcfc' }} size="large" onClose={onClose} open={open}>
            <Styled.Container>
                <Styled.Title>Meu Painel</Styled.Title>
                <Styled.SubTitle>Aqui você tem um breve resumo das suas ações na nossa plataforma.</Styled.SubTitle>
                <Styled.Content>
                    <ItemMeuPainel
                        loading={loading}
                        title="Cursos Livres"
                        isQuantitativo
                        link="/cursos-livres"
                        parms={['Iniciados', 'Finalizados', 'Não iniciados']}
                        textButton="Acessar Cursos Livres"
                        quanty={{
                            iniciadas: (dashboard?.cursos.iniciados ?? 0) + (dashboard?.trilhas.iniciados ?? 0),
                            finalizadas: (dashboard?.cursos.finalizados ?? 0) + (dashboard?.trilhas.concluidos ?? 0),
                            nao_iniciadas:
                                (dashboard?.cursos.naoIniciados ?? 0) -
                                (dashboard?.trilhas.concluidos ?? 0) -
                                (dashboard?.trilhas.iniciados ?? 0),
                        }}
                    />
                    <ItemMeuPainel
                        loading={loading}
                        title="Conteúdos da Biblioteca"
                        isQuantitativo
                        link="/biblioteca"
                        parms={['Baixados', 'Favoritos', 'Acervo']}
                        textButton="Acessar Biblioteca"
                        quanty={{
                            iniciadas: dashboard?.biblioteca.baixados ?? 0,
                            finalizadas: dashboard?.biblioteca.favoritos ?? 0,
                            nao_iniciadas: dashboard?.biblioteca.acervo ?? 0,
                        }}
                    />
                    <Divider orientation="left">
                        Jornadas de Desenvolvimento <MdOutlineKeyboardArrowDown size={16} />
                    </Divider>
                    <ItemMeuPainel
                        loading={loading}
                        title="Socioemocional de Professores"
                        isQuantitativo={false}
                        link="/painelSocioemocional/home"
                        parms={['Iniciadas', 'Finalizadas', 'Não iniciadas']}
                        textButton="Acessar Jornada"
                        type={dashboard?.jornadas.find(j => j.jornada === 'SOCIOEMOCIONAL_PROFESSORES')?.status}
                    />
                    <ItemMeuPainel
                        loading={loading}
                        title="Realizando Escolhas"
                        isQuantitativo={false}
                        link="/realizandoEscolhas/home"
                        parms={['Iniciadas', 'Finalizadas', 'Não iniciadas']}
                        textButton="Acessar Jornada"
                        // type={dashboard?.jornadas.find(j => j.jornada === 'REALIZANDO_ESCOLHAS')?.status}
                        type={dashboard?.jornadas.find(j => j.jornada === 'SOCIOEMOCIONAL_PROFESSORES')?.status}
                    />
                </Styled.Content>
                <Styled.Button onClick={onClose}>Fechar</Styled.Button>
            </Styled.Container>
        </Drawer>
    );
};

export default MeuPainel;
