import React, { useEffect } from 'react';
import { GradientButton } from 'components/UI/Buttons';
import BiblioNotification from 'models/biblioteca/BiblioNotification';
import Styled from './BiblioNotificationPage.styled';
import iconClose from '../../../../images/close-circle.svg';

export interface IBiblioNotificationModalShow {
    show: boolean;
    itemId: number;
    onHide: () => void;
    item?: BiblioNotification;
}

const BiblioNotificationModalShow = ({ show, onHide, itemId, item }: IBiblioNotificationModalShow) => {
    const MAX_CHARS = 650;
    const sanitizeContent = (content?: string) => {
        if (content && content.length > MAX_CHARS) return content.substring(0, MAX_CHARS) + '...';
        else return content;
    };

    return (
        <Styled.Modal show={show} onHide={onHide}>
            <Styled.ModalContainer style={{ textAlign: 'justify', padding: '30px' }}>
                <Styled.ModalIcon2 src={iconClose} onClick={onHide} />
                <br />
                <br />
                <Styled.Title>{item?.title}</Styled.Title>
                <Styled.ModalText2>{sanitizeContent(item?.content) || 'Vazio'}</Styled.ModalText2>
                <br />
                {/* <Styled.ModalCenter>
                    <GradientButton>Ir para a página do item</GradientButton>
                </Styled.ModalCenter> */}
            </Styled.ModalContainer>
        </Styled.Modal>
    );
};

export default BiblioNotificationModalShow;
