import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import RegraItem from './RegraItem';

export { ObjetivoLista };

const ObjetivosStyle = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
`;

const ObjetivoLista = styled.div`
    display: inline-block;
    flex-direction: column;
    flex-wrap: wrap;
    width: 11rem;
`;

interface IObjetivos {
    size?: string;
}
const ObjetivosContainer: FunctionComponent<IObjetivos> = props => {
    return (
        <ObjetivosStyle>
            <ObjetivoLista>
                <RegraItem value="Respeito" />
                <RegraItem value="Transparência" />
                <RegraItem value="Aprendizagem" />
                <RegraItem value="Reflexão" />
            </ObjetivoLista>

            <ObjetivoLista>
                <RegraItem value="Troca com pares" />
                <RegraItem value="Feedbacks" />
                <RegraItem value="Construção coletiva e individual" alignItens="flex-start" />
            </ObjetivoLista>
        </ObjetivosStyle>
    );
};

export default ObjetivosContainer;
