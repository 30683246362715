import React from 'react';
import Styled from './BiblioNotificationItemMark.styled';
import BiblioNotification from 'models/biblioteca/BiblioNotification';
import { DateHelp } from 'core/util/DateHelp';
import { sanitizeNotificationContent } from './BiblioNotificationPage';
import { StringHelp } from '../../../../core/util/StringHelp';

export interface IBiblioNotificationItemMark {
    data: BiblioNotification;
    onToggle: (id: number) => void;
    onClick: () => void;
}

const BiblioNotificationItemMark = ({ data, onToggle, onClick }: IBiblioNotificationItemMark) => {
    return (
        <Styled.Container>
            <Styled.MarkContainer>
                <Styled.Checkbox checked={data.marked ?? false} onClick={() => onToggle(data.id)} />
            </Styled.MarkContainer>
            <Styled.ContentContainer onClick={onClick}>
                <Styled.Title>{data.title}</Styled.Title>
                <Styled.Text>{sanitizeNotificationContent(StringHelp.toSize(data.content, 150))}</Styled.Text>
                <Styled.Date>{DateHelp.formatTimeAgo(data.date)}</Styled.Date>
            </Styled.ContentContainer>
        </Styled.Container>
    );
};

export default BiblioNotificationItemMark;
