import React, { useContext, useEffect } from 'react';
import IntermediatePageContent, { IntermediatePageContentSection } from 'pages/intermediarias/components/IntermediatePageContent';
import JornadaDesenvolvimento from './subcomponents/JornadaDesenvolvimento';
import Lampada from '../../../images/homepage/educationalSolutions/lampada.svg';
import IntermediatePage from 'pages/intermediarias/components/IntermediatePage';
import Menu from 'pages/menu/Menu';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';

export interface IRealizandoEscolhasDetail {}

{
    /*  Componente desabilitado pela ateração dia 02/08!!!  */
}
const RealizandoEscolhasDetail = ({}: IRealizandoEscolhasDetail) => {
    /* const PAGE_KEY = PaginasIntermediariasEnum.REALIZANDO_ESCOLHAS;
    const { isSearchView } = useContext(PesquisaContext.Context); */
    const history = useHistory();

    /* const sections: IntermediatePageContentSection[] = [
        {
            section: 'Jornada',
            content: <JornadaDesenvolvimento />,
            subroute: '',
        },
    ]; */

    useEffect(() => {
        const newPageUrl = '/pagina/realizandoescolhas';
        history.replace(newPageUrl);
    }, []);

    return (
        <>
            {/*  <Menu homepage homeIntermediate /> */}

            {/*  <DynamicHeader />

            <IntermediatePage
                helmetTitle={'Jornada de desenvolvimento'}
                helmetMetaDescription={''}
                title1={'Jornada de desenvolvimento'}
                title2={'Realizando escolhas'}
                icon={Lampada}
                breadCrumbName={'Jornada Realizando escolhas'}
            >
                <IntermediatePageContent pageKey={PAGE_KEY} pageRoot="realizando-escolhas" sections={sections} isOffsetRight={true} />
            </IntermediatePage>

            {isSearchView && <BarraPesquisa />} */}

            <Loading />
        </>
    );
};

export default RealizandoEscolhasDetail;
