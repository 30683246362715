import ComponentQuizModel from 'models/componentInteraction/ComponentQuiz';
import React, { useEffect, useState } from 'react';

import AdminImageUploader from 'pages/admin/components/imageUploader/AdminImageUploader';
import styled from 'styled-components';
import { Button, Collapse, ConfigProvider, Input, Popconfirm, Radio, Switch, Tooltip, Typography } from 'antd';
import ComponentQuizAlternativasModel from 'models/componentInteraction/ComponentQuizAlternativas';
import Colors from 'enums/Colors';
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { MdDeleteForever } from 'react-icons/md';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';

const Styled = {
    Container: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        width: 50%;
    `,
};

interface IQuizInteractionAdmin {
    dados: ComponentQuizModel;
    component: ComponentInteractionModel;
    maxListQuiz: number;
    myIndex: number;
    handleNewQuiz: () => void;
    handleNavigateQuiz: (next: boolean) => void;
    atualizarPerguntaQuiz: (pergunta: string) => void;
    atualizarFeedbackQuiz: (feedback: string) => void;
    adicionarNovaAlternativaQuiz: () => void;
    atualizarAlternativaQuiz: (indexAlternativa: number, text: string) => void;
    atualizarCheckAlternativa: (indexAlternativa: number, checked: boolean) => void;
    removerAlternativaQuiz: (indexAlternativa: number) => void;
    atualizarObrigatory: (checked: boolean) => void;
    atualizarMultiplos: (checked: boolean) => void;
    deletarQuiz: () => void;
}

const QuizInteractionAdmin = ({
    dados,
    maxListQuiz,
    myIndex,
    component,
    handleNewQuiz,
    handleNavigateQuiz,
    atualizarPerguntaQuiz,
    adicionarNovaAlternativaQuiz,
    atualizarAlternativaQuiz,
    atualizarCheckAlternativa,
    atualizarObrigatory,
    atualizarMultiplos,
    atualizarFeedbackQuiz,
    removerAlternativaQuiz,
    deletarQuiz,
}: IQuizInteractionAdmin) => {
    return (
        <Styled.Container>
            <Styled.Content style={{ width: '45%' }}>
                <Typography.Title level={3}>Digite a pergunta do Quiz:</Typography.Title>
                <ReactQuill
                    theme="snow"
                    modules={editorConfig.modules}
                    formats={editorConfig.formats}
                    value={dados.pergunta}
                    onChange={val => {
                        atualizarPerguntaQuiz(val);
                    }}
                />
                <div style={{ marginTop: '40px' }}>
                    {dados.alternativas?.map((alternativa, indexAlternativa) => {
                        if (dados.multiplos) {
                            return (
                                <div key={indexAlternativa} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Selecionar a alternativa correta">
                                        <Checkbox
                                            style={{ margin: '6px 0px' }}
                                            checked={alternativa.correct}
                                            onChange={e => atualizarCheckAlternativa(indexAlternativa, e.target.checked)}
                                        >
                                            <Input
                                                size="large"
                                                onChange={e => atualizarAlternativaQuiz(indexAlternativa, e.target.value)}
                                                value={alternativa.alternativa}
                                                placeholder="Texto da alternativa"
                                            />
                                        </Checkbox>
                                    </Tooltip>
                                    <Tooltip title="Excluir essa alternativa">
                                        <MdDeleteForever
                                            onClick={() => removerAlternativaQuiz(indexAlternativa)}
                                            size={22}
                                            cursor={'pointer'}
                                            color={Colors.DeleteRed}
                                        />
                                    </Tooltip>
                                </div>
                            );
                        } else {
                            return (
                                <div key={indexAlternativa} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Selecionar a alternativa correta">
                                        <Radio
                                            style={{ margin: '6px 0px' }}
                                            checked={alternativa.correct}
                                            onChange={e => atualizarCheckAlternativa(indexAlternativa, e.target.checked)}
                                        >
                                            <Input
                                                size="large"
                                                onChange={e => atualizarAlternativaQuiz(indexAlternativa, e.target.value)}
                                                value={alternativa.alternativa}
                                                placeholder="Texto da alternativa"
                                            />
                                        </Radio>
                                    </Tooltip>

                                    <Tooltip title="Excluir essa alternativa">
                                        <MdDeleteForever
                                            onClick={() => removerAlternativaQuiz(indexAlternativa)}
                                            size={22}
                                            cursor={'pointer'}
                                            color={Colors.DeleteRed}
                                        />
                                    </Tooltip>
                                </div>
                            );
                        }
                    })}
                </div>
                <Button
                    onClick={adicionarNovaAlternativaQuiz}
                    type="dashed"
                    style={{ backgroundColor: Colors.Yellow2, width: '200px', marginTop: '20px' }}
                >
                    Adicionar nova alternativa
                </Button>

                <Switch
                    style={{ width: '200px', margin: '10px 0px' }}
                    checkedChildren="Multipla Escolha"
                    unCheckedChildren="Única Escolha"
                    checked={dados.multiplos}
                    onChange={c => atualizarMultiplos(c)}
                />

                <Collapse
                    style={{
                        margin: '10px 0px',
                    }}
                    items={[
                        {
                            key: '1',
                            label: 'Adicionar texto de feedback',
                            children: (
                                <ReactQuill
                                    style={{ marginTop: '20px' }}
                                    theme="snow"
                                    modules={editorConfig.modules}
                                    formats={editorConfig.formats}
                                    value={dados.feedback}
                                    onChange={val => {
                                        atualizarFeedbackQuiz(val);
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </Styled.Content>
            <Styled.Content>
                <div
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                        alignContent: 'center',
                        height: '100%',
                    }}
                >
                    <Tooltip title="Essa opção torna obrigatório que todos os quizzes deste conteúdo sejam respondidos antes de prosseguir para o próximo tópico.">
                        <Switch
                            style={{ width: '200px', margin: '10px 0px' }}
                            checkedChildren="Quizzes obrigatórios"
                            unCheckedChildren="Quizzes não obrigatórios"
                            checked={component.obrigatory}
                            onChange={e => atualizarObrigatory(e)}
                        />
                    </Tooltip>

                    {maxListQuiz > 1 && (
                        <div style={{ display: ' flex', gap: '0.5rem', marginLeft: '20px', alignItems: 'center' }}>
                            <ConfigProvider
                                button={{
                                    style: {
                                        width: 60,
                                        backgroundColor: Colors.DarkBlue,
                                    },
                                }}
                            >
                                {' '}
                                <Button onClick={() => handleNavigateQuiz(false)} type="primary">
                                    <FaLongArrowAltLeft size={22} />
                                </Button>
                                <p style={{ fontWeight: 600, display: 'flex', width: '40px' }}>{`${myIndex} / ${maxListQuiz}`}</p>
                                <Button onClick={() => handleNavigateQuiz(true)} type="primary">
                                    <FaLongArrowAltRight size={16} />
                                </Button>
                            </ConfigProvider>
                        </div>
                    )}
                    <ConfigProvider
                        button={{
                            style: {
                                width: 200,
                                marginTop: '20px',
                            },
                        }}
                    >
                        <Button onClick={handleNewQuiz} type="primary">
                            Criar um novo Quiz
                        </Button>
                        {component.quiz && component.quiz?.length > 1 && (
                            <Popconfirm
                                title="Deletar Quiz"
                                description="Você deseja deletar esse quiz?"
                                okText="Sim, desejo deletar"
                                okButtonProps={{
                                    onClick: deletarQuiz,
                                }}
                                cancelText="Não"
                            >
                                <Button danger>Excluir Quiz</Button>
                            </Popconfirm>
                        )}
                    </ConfigProvider>
                </div>
            </Styled.Content>
        </Styled.Container>
    );
};

export default QuizInteractionAdmin;
