

export enum ComponentInteractionEnum {
    AUDIO = 'AUDIO',
    TEXT = 'TEXT',
    SANFONA = 'SANFONA',
    QUIZ = 'QUIZ',
    CORRESPONDENT = 'CORRESPONDENT',
    ABAS = 'ABAS',
    CARROSSEL = 'CARROSSEL',
    NENHUM = ''
}