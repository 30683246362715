import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import Loading from 'components/Loading';
import { getMemetypeFromName } from './utils';
import { AppStorage } from 'storage/AppStorage';
import * as diaryActions from 'store/actions/Diary';
import { DiarioBordoContext } from './DiarioContext';
import DiarioTag from 'models/diarioBordo/DiarioTag';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import * as profileActions from 'store/actions/Profile';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import DiarioAttach from 'models/diarioBordo/DiarioAttach';
import useFetch, { useFetchCallback } from 'hooks/useFetch';
import DiarioBordoAnexoService from 'core/http/service/DiarioBordoAnexo.service';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';
import UsuarioDTO from 'models/UsuarioDTO';

interface IDiarioBordoProvider {}
const DiarioBordoProvider: FunctionComponent<IDiarioBordoProvider> = ({ children }: React.PropsWithChildren<IDiarioBordoProvider>) => {
    const [tags, setTags] = useState<DiarioTag[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingDiary, setLoadingDiary] = useState<boolean>(false);
    const [currentAttach, setCurrentAttach] = useState<DiarioAttach | null>(null);
    const [showAttachConfirmation, setShowAttachConfirmation] = useState<boolean>(false);
    const [showRegisterConfirmation, setShowRegisterConfirmation] = useState<boolean>(false);
    const [showMaxLengthAttachAlert, setShowMaxLengthAttachAlert] = useState<boolean>(false);
    const [showableCurrent, _setShowableCurrent] = useState<boolean>(false);
    const [onEditting, _setOnEditting] = useState(false);

    const [markedForDeletion, setMarkedForDeletion] = useState<DiarioBordo | undefined>();

    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const allTags: DiarioTag[] = useAppSelector(state => state.diarioReducer.tags);
    const items: DiarioBordo[] = useAppSelector(state => state.diarioReducer.diaries);
    const { keycloak } = useKeycloak();

    const current: DiarioBordo | undefined =
        useAppSelector(state => {
            const c = state.diarioReducer.current;
            const index = items.findIndex(item => item.id === c?.id);

            return index > -1 ? items[index] : undefined;
        }) ?? undefined;

    const dispatch = useDispatch();

    const updateFilteredTagList = () => {
        if (current?.tags) {
            const { tags } = current;
            setTags(allTags.filter(item => tags.findIndex(c => c.id === item.id) === -1));
        } else {
            setTags(allTags);
        }
    };

    useFetch(
        async () => {
            const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
            await dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));
        },
        [],
        setLoading
    );

    // modificou a tag //
    useEffect(() => {
        updateFilteredTagList();
    }, [allTags, profile, current, current?.tags?.length]);

    useEffect(() => {
        setShowableCurrent(false);
    }, []);

    // mudou o perfil //
    useFetch(async () => {
        if (profile) {
            await dispatch(diaryActions.getDiaries(profile.codigo));
        }
    }, [profile]);

    // clicou em criar //
    const onCreate = useFetchCallback(
        async () => {
            if (profile) {
                await dispatch(diaryActions.criarRegistro(profile));
                setShowableCurrent(true);
            }
        },
        [profile],
        setLoadingDiary
    );

    const setShowableCurrent = (value: boolean) => {
        _setShowableCurrent(value);
    };

    const setOnEditting = (value: boolean) => {
        _setOnEditting(value);
    };

    const onCancel = () => {
        setShowRegisterConfirmation(false);
        setShowAttachConfirmation(false);
        setShowMaxLengthAttachAlert(false);
    };

    const onDelete = async (item: DiarioBordo): Promise<void> => {
        setMarkedForDeletion(item);
        setShowRegisterConfirmation(true);
    };

    const isTextValid = (current: DiarioBordo): boolean => {
        if (current.isFixed) {
            return !(current.texto == null || current.texto.trim().length == 0);
        } else {
            return current.texto != null;
        }
    };

    const onUpdate = async () => {
        if (profile && current?.id && isTextValid(current)) {
            await dispatch(diaryActions.atualizarDiario(current, profile.codigo));
        }
    };

    const onDeleteConfirm = useFetchCallback(
        async (): Promise<void> => {
            if (profile && markedForDeletion && !markedForDeletion.isFixed) {
                await dispatch(diaryActions.excluirDiario(markedForDeletion.id, profile.codigo));
                onCancel();

                if (current) {
                    await dispatch(diaryActions.buscarPorId(current.id, profile.codigo));
                }
            }
        },
        [profile, markedForDeletion?.id],
        setLoadingDiary
    );
    const onSelect = useCallback(
        async (diario: DiarioBordo) => {
            if (profile && current?.id !== diario.id) {
                setLoadingDiary(true);
                await dispatch(diaryActions.buscarPorId(diario.id, profile.codigo));
                setLoadingDiary(false);
            }
        },
        [current?.id, profile]
    );

    const onDuplicate = useCallback(
        async (item: DiarioBordo): Promise<void> => {
            if (profile) {
                setLoadingDiary(true);
                await dispatch(diaryActions.duplicarDiario(item, profile.codigo));
                setLoadingDiary(false);
            }
        },
        [profile]
    );

    const onAddTag = async (item: number): Promise<void> => {
        const tag: DiarioTag | undefined = allTags.find(t => t.id === item);

        if (tag && current) {
            if (!current.tags) {
                current.tags = [];
            }
            current.tags.push(tag);
            updateFilteredTagList();
        }

        await onUpdate();
    };

    const onRemoveTag = async (item: number): Promise<void> => {
        if (current && current.tags) {
            current.tags = current.tags.filter(t => t.id !== item);
            updateFilteredTagList();
        }
        await onUpdate();
    };

    const onAddAttach = async (file: File | File[]): Promise<void> => {
        const formData = new FormData();

        if (Array.isArray(file)) {
            formData.append('file', file[0]);
        } else formData.append('file', file);

        if (current && profile) {
            await dispatch(diaryActions.inserirAnexo(current.id, profile.codigo, formData));
        }
    };

    const onSizeErrorAttach = () => {
        setShowMaxLengthAttachAlert(true);
    };

    const onRemoveAttach = async (it: DiarioAttach): Promise<void> => {
        setCurrentAttach(it);
        setShowAttachConfirmation(true);
    };

    const onRemoveAttachConfirm = async (): Promise<void> => {
        if (currentAttach && profile) {
            await dispatch(diaryActions.removerAnexo(currentAttach.id, profile.codigo));
        }
        onCancel();
    };

    const onDownloadAttach = async (attach: DiarioAttach): Promise<void> => {
        const service = new DiarioBordoAnexoService();

        try {
            if (attach && profile) {
                const response = await service.buscarAnexo(attach.id, profile?.codigo);

                if (response.status < 300) {
                    const { filename, base64Content, filepath } = response.data;
                    const linkSource = `data:${getMemetypeFromName(filepath)};base64,${base64Content}`;
                    const downloadLink = document.createElement('a');

                    downloadLink.href = linkSource;
                    downloadLink.download = filename;
                    downloadLink.click();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onSearch = async (text: string): Promise<void> => {
        if (profile) {
            if (text.length > 2) {
                await dispatch(diaryActions.getDiaries(profile.codigo, text));
            } else if (text.length === 0) {
                await dispatch(diaryActions.getDiaries(profile.codigo));
            }
        }
    };

    const context: DiarioBordoContext.IContext = {
        tags,
        items,
        showableCurrent,
        setShowableCurrent,
        onUpdate,
        onAddTag,
        onCreate,
        onDelete,
        onSelect,
        onCancel,
        onSearch,
        onDuplicate,
        onRemoveTag,
        isLoadingDiary,
        onDeleteConfirm,
        showAttachConfirmation,
        showRegisterConfirmation,
        showMaxLengthAttachAlert,
        current: current ?? new DiarioBordo(),
        profile,
        onAddAttach,
        onSizeErrorAttach,
        onDownloadAttach,
        onRemoveAttach,
        onRemoveAttachConfirm,
        onEditting,
        setOnEditting,
    };
    return (
        <DiarioBordoContext.Context.Provider value={context}>{isLoading ? <Loading /> : <>{children}</>}</DiarioBordoContext.Context.Provider>
    );
};

export default DiarioBordoProvider;
