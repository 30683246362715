import React from 'react';

import styled from 'styled-components';

import getAvatarFromId from '../utils/getAvatarFromId';

import Colors from '../../../../../enums/Colors';
import checkImg from '../../../../../images/avatars/confirm.svg';

export const Img = styled.img<{ gray: boolean }>`
    width: 100%;
    height: auto;
    margin-top: -20px;
    filter: grayscale(${props => (props.gray ? '100%' : '0')});
    opacity: ${props => (props.gray ? '0.77' : '1')};

    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

export const Container = styled.div({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
});

export const Check = styled.img<{ visible: boolean }>`
    position: relative;
    width: 30px;
    height: auto;
    z-index: 1;
    float: right;

    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

interface IAvatarHolder {
    id: number;
    checked: boolean;
    wb: boolean;
    clickCallback: any;
}

const AvatarHolder = ({ id, checked, wb, clickCallback }: IAvatarHolder) => {
    return (
        <Container>
            <Check src={checkImg} visible={checked} />
            <button onClick={clickCallback} style={{ border: 'none', backgroundColor: Colors.White }}>
                <Img src={getAvatarFromId(id)} gray={wb} />
            </button>
        </Container>
    );
};

export default AvatarHolder;
