import React from 'react';
import Switch from 'react-switch';

export interface IBiblioSwicth {
    value: boolean;
    onChange: (value: boolean) => void;
}

const BiblioSwicth = ({ value, onChange }: IBiblioSwicth) => {
    return (
        <Switch
            height={18}
            width={40}
            handleDiameter={22}
            onHandleColor={'#0B78C8'}
            offHandleColor={'#0B78C8'}
            onColor={'#BFDFF7'}
            offColor={'#dfdfdf'}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={value}
            onChange={onChange}
        />
    );
};

export default BiblioSwicth;
