import React from 'react';
import Styled from './AdminEditor.styled';

import successIcon from '../../../../images/success-icon.svg';
import { GradientButton } from 'components/UI/Buttons';

export interface IAdminEditModalDelete {
    show: boolean;
    onHide: () => void;
}

const AdminEditModalDelete = ({ show, onHide }: IAdminEditModalDelete) => {
    return (
        <Styled.Modal show={show} onHide={onHide}>
            <Styled.Center>
                <img src={successIcon} style={{ width: '50px' }} />
                <br />

                <Styled.TextModal>O conteúdo foi Excluído!</Styled.TextModal>
                <br />
                <br />
                <Styled.ContainerBottom style={{ textAlign: 'center' }}>
                    <GradientButton onClick={onHide}>Fechar</GradientButton>
                </Styled.ContainerBottom>
            </Styled.Center>
        </Styled.Modal>
    );
};

export default AdminEditModalDelete;
