import styled from "styled-components";
import Colors from "../../enums/Colors";
import { MobileMaxWidth } from "../../constants/Dimensions";

export const ProfileStyle = styled.div`
  background-color: ${Colors.White};
  height: 100%;
`;

export const MainContainer = styled.section`
  padding-top: 86px;
  position: relative;
  height: 100%;

  @media only screen and (max-width: ${MobileMaxWidth}px) {
    padding-top: 60px;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
`;
