import React, { useState, useEffect, useCallback } from 'react';

import * as yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import WebForm from './formRows/WebForm';
import MobileForm from './formRows/MobileForm';
import Colors from '../../../../../enums/Colors';
import ContinuarButton from '../../UI/ContinuarButton';
import ProfileData from '../../../../../models/ProfileData';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';
import PickList from '../../../../../components/UI/pickList/PickList';
import { IsMobileMaxWidth } from '../../../../../core/util/WindowSize';
import * as profileActions from '../../../../../store/actions/Profile';
import PickListModel from '../../../../../components/UI/pickList/PickListModel';
import { FormContainer, SubmitButtonContainer, InteressesContainer } from './AtuacaoForm.styled';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import * as authenticationActions from '../../../../../store/actions/Authentication';
import { useKeycloak } from '@react-keycloak/web';
import { RoleEnum } from 'enums/RoleEnum';
import { fetchRoleDescription, getUserRole, isAutoCadastrado, isUserFromLMS, isUsuarioLms } from 'core/util/KeycloakHelp';
import UsuarioDTO from 'models/UsuarioDTO';

const PickerTitleContainerStyle = styled.div``;

const PickerTitle = styled.p`
    font-size: 32px;
    line-height: 42px;
    color: ${Colors.BlueGrey};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
    }
`;

const PickerSubtitle = styled.p`
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: ${Colors.Grey4};
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 14px;
    }
`;

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const CODIGO_TIPO_ENSINO_ESCOLA_PRIVADA = 1;
const CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA = 2;
const CODIGO_TIPO_ENSINO_ESCOLA_NAO_SE_APLICA = 3;

interface IIdentificacaoForm {
    ReloadContentHandler: any;
    profileParameterData: any;
    onContinuarHandler: any;
    profileData: ProfileData | null;
}
const IdentificacoForm = ({ ReloadContentHandler, profileParameterData, profileData, onContinuarHandler }: IIdentificacaoForm) => {
    const [listaAreaInteresse, setListaAreaInteresse] = useState<PickListModel[] | []>([]);
    const [hasInteresseSelected, setHasInteressSelected] = useState<boolean>(false);
    const [hasAtuacaoData, setHasAtuacaoData] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const [roleEnum, setRoleEnum] = useState<RoleEnum>(RoleEnum.Professor);

    interface IValues {
        tipoPerfil: string;
        outroPerfilIdentificacao: string;
        tipoGestor: number | null;
        tipoEnsino: number | null;
        tipoEnsinoRedePublica: number | null;
        categoriaEnsino: number | null;
    }

    const formikInitial: IValues = {
        tipoPerfil: '',
        outroPerfilIdentificacao: '',
        tipoGestor: 0,
        tipoEnsino: null,
        tipoEnsinoRedePublica: 0,
        categoriaEnsino: 0,
    };

    const initialize = async () => {
        setIsLoading(true);

        await dispatch(authenticationActions.getUsuarioLogado());
        if (profileData?.atuacaoCompleta) {
            if (!isAutoCadastrado(usuarioLogado) && !isUsuarioLms(usuarioLogado)) {
                formikInitial.tipoPerfil = fetchRoleDescription(profileData.tipoPerfil);
            } else {
                formikInitial.tipoPerfil = profileData.tipoPerfil;
            }
            formikInitial.tipoEnsino = profileData.tipoEnsino;
            formikInitial.tipoEnsinoRedePublica = profileData.tipoEnsinoRedePublica;
            formikInitial.categoriaEnsino = profileData.categoriaEnsino;
            formikInitial.tipoGestor = profileData.tipoGestor;
            formikInitial.outroPerfilIdentificacao = profileData.outroPerfilIdentificacao;

            setListaAreaInteresse(profileData.listaAreasInteresse);

            setHasAtuacaoData(true);
        } else {
            await initializeProfile();
        }
        setIsLoading(false);
    };

    const initializeProfile = useCallback(async () => {
        const rolesUser: string[] = usuarioLogado.roles.filter((value, index, arr) => {
            return value !== 'default-roles-ias' && value !== 'offline_access' && value !== 'uma_authorization';
        });

        if (!(rolesUser && rolesUser.length > 0)) {
            console.log('Integração com bncc não concluída');
            formikInitial.tipoPerfil = fetchRoleDescription(RoleEnum.Professor);
            setRoleEnum(RoleEnum.Professor);
        } else if (isUserFromLMS(rolesUser)) {
            setRoleEnum(RoleEnum.Outro);
        } else {
            const roles: RoleEnum[] = getUserRole(rolesUser);
            formikInitial.tipoPerfil = fetchRoleDescription(roles[0]);
            setRoleEnum(roles[0]);
        }
    }, []);

    useEffect(() => {
        initialize();
    }, []);

    const onSubmitHandler = async (values: IValues, actions: any) => {
        setSubmitting(true);
        const { tipoEnsino, tipoEnsinoRedePublica, categoriaEnsino, tipoGestor, outroPerfilIdentificacao } = values;
        if (profileData) {
            profileData.tipoPerfil = roleEnum;
            profileData.tipoEnsino = tipoEnsino;
            profileData.tipoEnsinoRedePublica = tipoEnsinoRedePublica;
            profileData.categoriaEnsino = categoriaEnsino;
            profileData.listaAreasInteresse = listaAreaInteresse;
            profileData.atuacaoCompleta = true;
            profileData.tipoGestor = tipoGestor;
            profileData.outroPerfilIdentificacao = outroPerfilIdentificacao;

            try {
                await dispatch(profileActions.storeProfileData(profileData));
                setSubmitting(false);
                onContinuarHandler();
            } catch (err) {
                setSubmitting(false);
            }
        }
    };

    const yupValidation = yup.object().shape({
        tipoPerfil: yup.string().required(),
        outroPerfilIdentificacao: yup.string().when('tipoPerfil', {
            is: RoleEnum.Outro,
            then: yup.string().required().min(3),
        }),
        tipoEnsino: yup.number().required(),
        tipoEnsinoRedePublica: yup.number().when('tipoEnsino', {
            is: CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA,
            then: yup.number().required().min(1),
        }),
        tipoGestor: yup.number().when('tipoPerfil', {
            is: fetchRoleDescription(RoleEnum.GestorRede),
            then: yup.number().required().min(1),
        }),
        categoriaEnsino: yup.number().when('tipoEnsino', {
            is: CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA || CODIGO_TIPO_ENSINO_ESCOLA_PRIVADA,
            then: yup.number().required(),
        }),
    });

    const handleChangeInteresses = (novaLista: PickListModel[]) => {
        const totalSelected = novaLista.filter((item: PickListModel) => item.isSelected === true).length;
        setHasInteressSelected(totalSelected > 0);
        setListaAreaInteresse(novaLista);
    };

    const getSizeField = (value: string): number => {
        return value.length * 18;
    };

    return (
        <FormContainer id="formContainer">
            <Formik initialValues={formikInitial} onSubmit={onSubmitHandler} validationSchema={yupValidation}>
                {formik => {
                    return (
                        <>
                            {IsMobile() ? (
                                <MobileForm
                                    formik={formik}
                                    ReloadContentHandler={ReloadContentHandler}
                                    profileParameterData={profileParameterData}
                                    isLoading={isLoading}
                                    hasAtuacaoData={hasAtuacaoData}
                                    updateRole={(r: RoleEnum) => setRoleEnum(r)}
                                />
                            ) : (
                                <WebForm
                                    formik={formik}
                                    ReloadContentHandler={ReloadContentHandler}
                                    profileParameterData={profileParameterData}
                                    isLoading={isLoading}
                                    hasAtuacaoData={hasAtuacaoData}
                                    size={getSizeField(formik.values['tipoPerfil'])}
                                    updateRole={(r: RoleEnum) => setRoleEnum(r)}
                                />
                            )}

                            {(formik.touched.categoriaEnsino ||
                                (formik.touched.tipoEnsino && formik.values.tipoEnsino === CODIGO_TIPO_ENSINO_ESCOLA_NAO_SE_APLICA) ||
                                hasAtuacaoData) && (
                                <>
                                    <InteressesContainer>
                                        <PickerTitleContainerStyle>
                                            <PickerTitle>{`Meus interesses são:`}</PickerTitle>
                                            <PickerSubtitle>{`Selecione até 3 interesses`}</PickerSubtitle>
                                        </PickerTitleContainerStyle>
                                        <PickList
                                            preLoadedData={listaAreaInteresse}
                                            data={profileParameterData.listaAreaInteresse}
                                            showAdicionar={true}
                                            totalSelecao={3}
                                            onCallback={(lista: PickListModel[]) => {
                                                handleChangeInteresses(lista);
                                            }}
                                        />
                                    </InteressesContainer>

                                    <SubmitButtonContainer>
                                        <ContinuarButton
                                            isDisabled={
                                                !hasInteresseSelected ||
                                                (hasAtuacaoData && !formik.isValid) ||
                                                (!hasAtuacaoData && !(formik.isValid && formik.dirty)) ||
                                                formik.isSubmitting ||
                                                submitting
                                            }
                                            label={`Continuar`}
                                            onClickHandler={formik.handleSubmit}
                                        />
                                    </SubmitButtonContainer>
                                </>
                            )}
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
};

export default IdentificacoForm;
