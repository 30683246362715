import React, { useEffect, useState } from 'react';
import { RealizandoEscolhasContext as RealizandoEscolhas } from './RealizandoEscolhasContext';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { PreferenciasService } from 'core/http/service/Preferencias.service';
import { Preferences } from 'constants/Preferences';
import PrefenciaUsuario from 'models/PreferenciaUsuario';
import { useHistory, useLocation } from 'react-router-dom';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';

export interface IRealizandoEscolhasProvider {}

export const cacheShowCardRealizandoEscolhasKey = 'USERPREF:EXIBIR_REALIZANDO_ESCOLHAS';

const RealizandoEscolhasProvider = ({ children }: React.PropsWithChildren<IRealizandoEscolhasProvider>) => {
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const use = useLocation();
    const history = useHistory();
    const [isShowBannerInfo, _setShowBannerInfo] = useState(true);
    const [showModalNavigate, _setShowBannerBlocked] = useState(false);
    const [goTo, _setGoTo] = useState<string>('');

    const service = new PreferenciasService();

    const setShowBannerInfo = async () => {
        _setShowBannerInfo(!isShowBannerInfo);
        sessionStorage.setItem(cacheShowCardRealizandoEscolhasKey, String(!isShowBannerInfo));

        const preferencia = new PrefenciaUsuario();
        preferencia.chave = Preferences.RealizandoEscolhasExpanded.chave;
        preferencia.valor = isShowBannerInfo
            ? Preferences.RealizandoEscolhasExpanded.valor.naoExibir
            : Preferences.RealizandoEscolhasExpanded.valor.exibir;

        if (profile) {
            try {
                await service.setPreferencia(preferencia, profile.codigo);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const buscarPreferencia = async () => {
        const service = new PreferenciasService();

        if (profile) {
            const prefChaveSbm = Preferences.RealizandoEscolhasExpanded.chave;
            const { data } = await service.getPreferencia(prefChaveSbm, profile.codigo);

            if (data && data.chave && data.chave.length >= 0 && data.valor === Preferences.RealizandoEscolhasExpanded.valor.exibir) {
                _setShowBannerInfo(true);
            } else {
                _setShowBannerInfo(false);
            }
        }
    };

    const setShowModalNavigate = (value: boolean) => {
        _setShowBannerBlocked(value);
    };

    const isVerifyGoToPage = (rotaDesejada: string) => {
        if (rotaDesejada !== use.pathname && use.pathname === '/realizandoEscolhas/avaliacao' && !showModalNavigate) {
            _setShowBannerBlocked(true);
            _setGoTo(rotaDesejada);
        } else {
            goToPage(rotaDesejada);
        }
    };

    const goToPage = (link?: string) => {
        _setShowBannerBlocked(false);
        GTM_Page_Realizando_Escolhas('header_desktop', `acessar_page_${link?.replace('/realizandoEscolhas/', '').toLowerCase()}`);

        history.push(link ?? goTo);
    };

    const context: RealizandoEscolhas.IContext = {
        isShowBannerInfo,
        setShowBannerInfo,

        showModalNavigate,
        setShowModalNavigate,

        isVerifyGoToPage,
        goToPage,
    };

    useEffect(() => {
        buscarPreferencia();
    }, []);

    return <RealizandoEscolhas.Context.Provider value={context}>{children}</RealizandoEscolhas.Context.Provider>;
};

export default RealizandoEscolhasProvider;
