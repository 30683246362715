import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TipoModeloCursosStyled from './TipoModeloCursos.styled';

interface ITipoModeloCursos {}

const TipoModeloCursos: FunctionComponent<ITipoModeloCursos> = ({ ...props }: ITipoModeloCursos) => {
    return (
        <TipoModeloCursosStyled.Container>
            <p>Aqui monta a página com modelo de cursos livres </p>
        </TipoModeloCursosStyled.Container>
    );
};

export default TipoModeloCursos;
