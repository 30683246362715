import { CSSObject } from 'styled-components';
import FullCss, { MediaQuery } from './FullCss';

/**
 * Função para ajudar a IDE a identificar 'style objects' no código.
 * > @media only screen and (max-width: ${max}px)
 * @param css Objeto CSS
 * @param media CSS de dentro do selector
 * @param max Largura máxima para em que será exibido
 * @returns Um objeto utilizável pelo styled
 */
export const mediaOnlyScreenMaxWidth = (css: CSSObject, media: CSSObject, max: number): CSSObject => {
    return new FullCss(css, [new MediaQuery(mediaOnlyScreenMaxWidthStr(max), media)]).getCss();
};

/**
 * Função para ajudar a IDE a identificar 'style objects' no código.
 * > @media only screen and (max-width: 760px)
 * @param css Objeto CSS
 * @param media CSS de dentro do selector
 * @returns Um objeto utilizável pelo styled
 */
export const media760 = (css: CSSObject, media: CSSObject): CSSObject => {
    return mediaOnlyScreenMaxWidth(css, media, 760);
};

export const mediaOnlyScreenMaxWidthStr = (max: number) => `@media only screen and (max-width: ${max}px)`;

export const mediaBetweenScreenMinMaxWidth = (min: number, max: number) =>
    `@media only screen and (min-width: ${min}px) and (max-width: ${max}px)`;
