import React, { useState } from 'react';
import Styled from './BibioCommentSection.styled';
import { useKeycloak } from '@react-keycloak/web';
import BiblioComentarioService from 'core/http/service/biblioteca/BiblioComentario.service';
import BiblioItemAnswer from 'models/biblioteca/BiblioItemAnswer';
import trashIcon from '../../../../images/admin/trash-colorized.svg';
import editIcon from '../../../../images/admin/edit-colorized.svg';
import ReactTooltip from 'react-tooltip';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import BiblioItemComment from 'models/biblioteca/BiblioItemComment';
import ModalDefault from 'pages/realizandoEscolhas/pages/components/ModalDefault';
import ExcludeCommentModal from './ExcludeCommentModal';

export interface IComment {
    /* id: string;
    username: string;
    content: string;
    userCode: string; */
    comment: BiblioItemComment;
    answers: BiblioItemAnswer[];

    onExclude: () => void;
    onEdited: () => void;
}

const BiblioComment = ({ comment, onExclude, onEdited, answers }: IComment) => {
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [editContent, setEditContent] = useState<string>('');
    const [contentInline, setContentInline] = useState(comment.comment);
    const [showConfirmExclude, _setShowConfirmExclude] = useState<boolean>(false);

    const Keycloak = useKeycloak();

    const isSameUserOwner = (): boolean => {
        const tokenParsed = Keycloak.keycloak.idTokenParsed;

        if (tokenParsed?.sub == comment.userId) return true;
        return false;
    };

    const setShowConfirmExclude = () => {
        _setShowConfirmExclude(true);
    };

    const excludeComment = async () => {
        const service = new BiblioComentarioService();
        const response = await service.deletarComentario(comment.id);

        if (response.status == 200) onExclude();
    };

    const editComment = async () => {
        const service = new BiblioComentarioService();
        const { data, status } = await service.alterarComentario(comment.id, editContent);

        if (status == 200) {
            onEdited();
        }

        setOnEdit(false);
    };

    const cancelEdit = () => {
        setOnEdit(false);
    };

    const onHandleEdit = () => {
        setOnEdit(true);
        setEditContent(comment.comment);
    };

    const isEdited = (): boolean => {
        return comment.dataCriacao != comment.dateAtualizacao;
    };

    return (
        <Styled.CommentWrap>
            <Styled.CommentContainer>
                <Styled.CommentHolder>
                    <Styled.CommentUsername>
                        {comment.username}
                        {isEdited() && <Styled.EditMessage>Editado</Styled.EditMessage>}
                    </Styled.CommentUsername>

                    {!onEdit ? (
                        <Styled.CommentBody>{contentInline}</Styled.CommentBody>
                    ) : (
                        <>
                            <Styled.InputEditContent value={editContent} onChange={e => setEditContent(e.target.value)} />
                            <Styled.SendEditButton onClick={editComment}>Enviar</Styled.SendEditButton>
                            <Styled.CancelEditButton onClick={cancelEdit}>Cancelar</Styled.CancelEditButton>
                        </>
                    )}
                </Styled.CommentHolder>

                <Styled.CommentContainerRight>
                    {isSameUserOwner() && (
                        <>
                            {
                                <Styled.DivIcons>
                                    <Styled.EditIcon src={editIcon} onClick={onHandleEdit} data-tip={'Editar comentário'} />
                                    <ModalDefault show={showConfirmExclude}>
                                        <ExcludeCommentModal
                                            setShow={_setShowConfirmExclude}
                                            goToExcludeCommentModalCallback={() => {
                                                excludeComment();
                                                _setShowConfirmExclude(false);
                                            }}
                                        />
                                    </ModalDefault>
                                    <Styled.TrashIcon src={trashIcon} onClick={setShowConfirmExclude} data-tip={'Excluir comentário'} />
                                </Styled.DivIcons>
                            }
                        </>
                    )}
                </Styled.CommentContainerRight>
                <Styled.AnswerContainer>
                    {answers.map(answer => {
                        return (
                            <Styled.CommentHolder key={answer.id}>
                                <Styled.CommentUsername>{answer.username}</Styled.CommentUsername>
                                <Styled.CommentBody>{answer.content}</Styled.CommentBody>
                                <br />
                            </Styled.CommentHolder>
                        );
                    })}
                </Styled.AnswerContainer>
            </Styled.CommentContainer>
            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </Styled.CommentWrap>
    );
};

export default BiblioComment;
