import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import Styled from './DesktopMenu.styled';
import HeaderItem from 'models/admin/AdminHeaderItem';
import { GTM_Menu } from 'components/GoogleTagManager';
import { useHistory, useLocation } from 'react-router-dom';
import { useOutsideRef } from 'hooks/useOutsideRef';
import useLogged from '../../../hooks/useLogged';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { RoleEnum } from 'enums/RoleEnum';

export interface IDesktopMenuItem {
    item: HeaderItem;
}

const DesktopMenuItem = ({ item }: IDesktopMenuItem) => {
    const [openMenu, setOpenMenu] = useState(false);

    const logged = useLogged();
    const history = useHistory();
    const _location = useLocation();
    const popOverRef = useRef(null);

    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const isCurrentLocation = (path: string): boolean => {
        if (path.startsWith('#') && _location.hash == path) return true;
        else if (path.length > 0 && path == _location.pathname && _location.hash == '') return true;

        return false;
    };

    useOutsideRef(popOverRef, () => {
        setOpenMenu(false);
    });

    const scrollToAnchor = () => {
        const elem = document.querySelector(item.url);

        if (elem) elem.scrollIntoView();
        else if (item.url.includes('inicio')) history.push('/');
        else {
            history.push('/' + item.url);

            setTimeout(() => {
                const anchor = document.querySelector(item.url);
                if (anchor) anchor.scrollIntoView();
            }, 600);
        }
    };

    const onClickHandler = () => {
        if (item.subheaders.length > 0) setOpenMenu(!openMenu);
        else {
            GTM_Menu('menu_header', item.eventoGTM);

            if (item.url.startsWith('#')) {
                scrollToAnchor();
            } else if (!item.jornadaConcluida) history.push(item.url);
            else logged.login(item.url);
        }
    };

    const onClickHandlerSubItem = (sub: HeaderItem) => {
        GTM_Menu('menu_header', sub.eventoGTM);

        //Não permitir que o ADMIN acesse a pagina de socioemocional de professores
        if (usuarioLogado.roles.includes(RoleEnum.Administrador) && sub.url.includes('painelSocioemocional')) {
            history.push('/admin');
            return;
        }

        if (item.url.startsWith('#')) {
            const elem = document.querySelector(sub.url);
            elem && elem.scrollIntoView();
        } else if (!item.jornadaConcluida) history.push(sub.url);
        else logged.login(sub.url);
    };

    return (
        <Styled.MenuItem onClick={() => onClickHandler()}>
            <Styled.MenuItemTextHover active={isCurrentLocation(item.url)}>{item.nome}</Styled.MenuItemTextHover>

            <Styled.Point className="point"></Styled.Point>

            {item.subheaders.length > 0 && openMenu && (
                <Styled.PopOver ref={popOverRef}>
                    {item.subheaders
                        .sort((a, b) => a.ordem - b.ordem)
                        .map(sub => {
                            return (
                                <Styled.OptionsContainer key={sub.codigo}>
                                    <Styled.MenuOptionsWithHover onClick={() => onClickHandlerSubItem(sub)}>
                                        {sub.nome}
                                    </Styled.MenuOptionsWithHover>
                                </Styled.OptionsContainer>
                            );
                        })}
                </Styled.PopOver>
            )}
        </Styled.MenuItem>
    );
};

export default DesktopMenuItem;
