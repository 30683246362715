import React from 'react';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import Styled from 'pages/admin/subpages/socio/AdminSocioEmocional.styled';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';
import AdminGestaoPaginaNotas from 'models/admin/AdminGestaoPaginaNotas';

export interface IModalAvaliations {
    show: boolean;
    onHide: () => void;
    dados?: AdminGestaoPaginaNotas;
}

export interface IComment {
    comment: string;
}

const ModalAvaliations = ({ show, onHide, dados }: IModalAvaliations) => {
    const Commentary = ({ comment }: IComment) => {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{comment.length > 0 ? <p>{comment}</p> : 'Não possuí nenhum comentário.'}</div>
        );
    };

    return (
        <AdminModalShow show={show} onHide={onHide}>
            <Styled.Modal.Title>{dados?.tipo.toString().toUpperCase()}</Styled.Modal.Title>
            <Styled.Modal.SubTitle>{dados?.nomeConteudo}</Styled.Modal.SubTitle>
            <br />
            <Commentary comment={dados?.comentario ?? ''} />
            <br />
            <Styled.Modal.UserName>{dados?.nomeUsuario}</Styled.Modal.UserName>
            <Styled.Modal.Date></Styled.Modal.Date>
            <div>
                <AdminRateStar rate={dados?.nota ?? 0} />
            </div>
        </AdminModalShow>
    );
};

export default ModalAvaliations;
