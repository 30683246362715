import styled from 'styled-components';

import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const StatementContainerStyle = styled.div`
    display: flex;

    flex-direction: column;
    background-color: ${Colors.White};
    padding-top: 90px;
    padding-bottom: 50px;

    .slick-slider {
        max-height: 550px;
    }
    @media screen and (max-width: ${MobileMaxWidth}px) {
        padding-top: 10px;
    }
`;

export const Wrapper = styled.div`
    text-align: center;
`;

export const CarouselContainer = styled.div`
    margin-top: 90px;
    padding: 10px;

    @media screen and (min-width: 1367px) {
        display: inline-block;
    }

    @media screen and (max-width: 760px) {
        margin-top: 0px;
    }

    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        max-width: 1366px;

        .slide {
            transform: scale(0.7);
            transition: transform 500ms;
            opacity: 0.4;
        }

        .activeSlide {
            transform: scale(1.1);
            opacity: 1;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        padding: 0px 0 10px 0;
    }
`;

export const StatementCarouselCardContainer = styled.div`
    outline: none;
    border: none;
`;

interface IArrowButton {
    next: boolean;
}
export const ArrowButton = styled.div<IArrowButton>`
    cursor: pointer;
    width: 56px;
    height: 56px;
    outline: none;
    border: none;
    position: absolute;
    z-index: 8;
    top: 50%;
    right: ${props => (props.next ? '0%' : '')};
    left: ${props => (!props.next ? '0%' : '')};
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 56px;
    width: 56px;
    fill: transparent;

    &:hover {
        fill: ${Colors.SoftBlue};
    }

    &:hover path {
        stroke: ${Colors.White};
    }
`;

export const CarouselDots = styled.div<{ color?: Colors }>`
    .slick-active div {
        background: ${({ color }) => (color ? color : Colors.BlueGrey)};
    }
`;

export const CarouselCustomPaging = styled.div`
    width: 12px;
    height: 12px;
    border: 1px solid ${Colors.Grey5};
    border-radius: 50%;
`;

export const IconSvg = styled.img``;

export const TitleText = styled.p`
    display: flex;
    padding: 0 0 0 100px;
    font-size: 32px;
    color: ${Colors.Blue};
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 20px 20px 0 20px;
    }

    @media only screen and (max-width: 320px) {
        font-size: 26px;
        word-break: break-word;
    }
`;
