import axios from 'axios';
import { useEffect, useCallback } from 'react';

import OnError from '../core/onError/OnError';

type AnyRead = readonly any[];
export const useFetchEffect = <T extends (...args: any[]) => any>(func: T, dependencies: AnyRead = []) => {
    useEffect(() => {
        const source = axios.CancelToken.source();
        func();
        return () => {
            source.cancel('Componente desmontado'); //CLEANUP
        };
    }, dependencies);
};

export const useFetchCallback = <T extends (...args: any[]) => any>(func: T, dependencies: AnyRead = [], setLoading?: (b: boolean) => void) => {
    return useCallback(async () => {
        try {
            if (setLoading) setLoading(true);
            await func();
        } catch (error) {
            OnError.sendToAnalytics('useFetchCallback.ts', String(error));
        } finally {
            if (setLoading) setLoading(false);
        }
    }, dependencies);
};

const useFetch = <T extends (...args: any[]) => any>(func: T, dependencies: AnyRead = [], setLoading?: (b: boolean) => void) => {
    const fetch = useFetchCallback(func, dependencies, setLoading);

    useFetchEffect(() => {
        fetch();
    }, dependencies);

    return fetch;
};

export default useFetch;
