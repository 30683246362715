import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';

export const SuporteContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-left: 110px;
    overflow: hidden;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 40px 0;
    }
`;

export const TemporaryFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    height: 500px;
    margin: 40px 40px 40px 0px;
    background: ${Colors.Grey10};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin: 40px 0px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        justify-content: flex-start;
    }
`;

export const GobackButton = styled.button`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
    width: 227px;
    height: 42px;
    padding: 12px 40px;
    gap: 10px;
    border: 2px solid #003c78;
    border-radius: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background: ${Colors.White};
    color: ${Colors.Blue};

    &:hover {
        background: ${Colors.Blue};
        color: ${Colors.White};
    }

`;
