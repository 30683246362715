import React, { useContext, useEffect, useState } from 'react';
import AdminSecaoPaginaData, { AdminSecaoPaginaItemCustom } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import Styled from './AdminEditor.styled';
import ActionComponent from './components/ActionComponent';
import { cloneDeep, update } from 'lodash';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import { ToastError, ToastSuccess } from 'components/Toaster';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import { Form } from 'react-bootstrap';

interface IAdminItemSection {
    itens: AdminSecaoPaginaItemCustom[];
    setItens: (itens: AdminSecaoPaginaItemCustom[]) => void;
    currentSection: AdminSecaoPaginaData;
    fetchItem: () => void;
}

const AdminItemSection = ({ itens, setItens, currentSection, fetchItem }: IAdminItemSection) => {
    const [idCardSelected, setIdCardSelected] = useState<number>(0);
    const [showModalDeleteCard, setShowModalDeleteCard] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [submittingIndex, setSubmittingIndex] = useState(-1);

    const service = new AdminGestaoPaginaService();

    const isValidInputs = (idx: number): boolean => {
        const item = itens[idx];

        if (!item.ocultarDetalhamento) {
            if (item.detalhamento.trim().length === 0 || !item.corDetalhamento || !item.tamanhoDetalhamento) {
                return false;
            }
        }

        if (!item.ocultarInfoDestaque) {
            if (item.infoDestaque.trim().length === 0 || !item.corDetalhamento || !item.tamanhoDetalhamento) {
                return false;
            }
        }

        if (!item.ocultarAnexo) {
            if (item.anexoBase64?.trim() === undefined) {
                return false;
            }
        }

        return true;
    };

    const handleDeleteCard = async () => {
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCardCustom(idCardSelected);

            if (response.status === 200) {
                ToastSuccess('Cartão excluído com sucesso!');
                fetchItem();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteCard(false);
        setIsLoadingDelete(false);
    };

    const submitCard = async (idx: number) => {
        setSubmittingIndex(idx);

        const item = itens[idx];

        if (!item.isPersist) await saveCard(currentSection, idx);
        else await updateCard(idx);

        setSubmittingIndex(-1);
    };

    const saveCard = async (secao: AdminSecaoPaginaData, idx: number) => {
        const item = itens[idx];

        const { status, data } = await service.inserirCartaoCustom(
            secao.codigo,
            item.infoDestaque,
            item.corInfoDestaque,
            item.tamanhoInfoDestaque.toString(),
            item.ocultarInfoDestaque ?? false,
            item.detalhamento,
            item.corDetalhamento,
            item.tamanhoDetalhamento.toString(),
            item.ocultarDetalhamento ?? false,
            item.anexoBase64 ?? '',
            item.ocultarAnexo ?? false,
            item.oculto ?? false
        );

        if (status < 300) {
            setCodigo(idx, data.codigo);
            ToastSuccess('Cartão salvo');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateCard = async (idx: number) => {
        const item = itens[idx];

        const { status, data } = await service.atualizarCartaoCustom(
            item.codigo,
            item.infoDestaque,
            item.corInfoDestaque,
            item.tamanhoInfoDestaque.toString(),
            item.ocultarInfoDestaque ?? false,
            item.detalhamento,
            item.corDetalhamento,
            item.tamanhoDetalhamento.toString(),
            item.ocultarDetalhamento ?? false,
            item.anexoBase64 ?? '',
            item.ocultarAnexo ?? false,
            (item.oculto = false)
        );

        if (status < 300) {
            ToastSuccess('Cartão atualizado');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const setCodigo = (idx: number, value: number) => {
        const clone = cloneDeep(itens);
        clone[idx].codigo = value;
        setItens(clone);
    };

    const setInfoDestaque = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].infoDestaque = value;
        setItens(clone);
    };

    const setCorInfoDestaque = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].corInfoDestaque = value;
        setItens(clone);
    };

    const setTamanhoInfoDestaque = (idx: number, value: number) => {
        const clone = cloneDeep(itens);
        clone[idx].tamanhoInfoDestaque = value;
        setItens(clone);
    };

    const setOcultoInfoDestaque = (idx: number, value: boolean) => {
        const clone = cloneDeep(itens);
        clone[idx].ocultarInfoDestaque = value;
        setItens(clone);
    };

    const setDetalhamento = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].detalhamento = value;
        setItens(clone);
    };

    const setCorDetalhamento = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].corDetalhamento = value;
        setItens(clone);
    };

    const setTamanhoDetalhamento = (idx: number, value: number) => {
        const clone = cloneDeep(itens);
        clone[idx].tamanhoDetalhamento = value;
        setItens(clone);
    };

    const setOcultoDetalhamento = (idx: number, value: boolean) => {
        const clone = cloneDeep(itens);
        clone[idx].ocultarDetalhamento = value;
        setItens(clone);
    };

    const setOcultoAnexo = (idx: number, value: boolean) => {
        const clone = cloneDeep(itens);
        clone[idx].ocultarAnexo = value;
        setItens(clone);
    };

    const setCover = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].anexoBase64 = value;
        setItens(clone);
    };

    return (
        <>
            {itens.map((item, idx) => {
                return (
                    <>
                        <ModalExclusao
                            showModal={showModalDeleteCard}
                            handleHiden={setShowModalDeleteCard}
                            handleExcluxed={handleDeleteCard}
                            isLoading={isLoadingDelete}
                        />
                        <Styled.Container key={idx}>
                            <div>
                                <Styled.CardContainer>
                                    <Styled.Label>Informação de destaque</Styled.Label>
                                    <Styled.Input value={item.infoDestaque} onChange={(evt: any) => setInfoDestaque(idx, evt.target.value)} />
                                    <ActionComponent
                                        name={'info' + item.codigo}
                                        textOculto="Informações"
                                        valueColorText={item.corInfoDestaque}
                                        valueFontSize={item.tamanhoInfoDestaque}
                                        isOculto={item.ocultarInfoDestaque}
                                        setColorText={value => setCorInfoDestaque(idx, value)}
                                        setValueFontSize={value => setTamanhoInfoDestaque(idx, value)}
                                        setIsOculto={value => setOcultoInfoDestaque(idx, value)}
                                    />
                                </Styled.CardContainer>
                                <Styled.CardContainer>
                                    <Styled.Label>Detalhamento</Styled.Label>
                                    <Styled.Input value={item.detalhamento} onChange={(evt: any) => setDetalhamento(idx, evt.target.value)} />
                                    <ActionComponent
                                        name={'detalhe' + item.codigo}
                                        textOculto="Detalhamento"
                                        valueColorText={item.corDetalhamento}
                                        valueFontSize={item.tamanhoDetalhamento}
                                        isOculto={item.ocultarDetalhamento}
                                        setColorText={value => setCorDetalhamento(idx, value)}
                                        setValueFontSize={value => setTamanhoDetalhamento(idx, value)}
                                        setIsOculto={value => setOcultoDetalhamento(idx, value)}
                                    />
                                </Styled.CardContainer>
                            </div>
                            <Styled.RigthArea>
                                <Styled.ImageContainer style={{ paddingTop: 'calc(1rem + 10px)', flexDirection: 'column' }}>
                                    <AdminImageUploader
                                        onSelect={() => {}}
                                        setBase64={(base64: string) => setCover(idx, base64)}
                                        size={'sm'}
                                        title={'Imagem do card'}
                                        preImageB64={item.anexoBase64 ?? ''}
                                        recommendedDimension={{ w: 150, h: 85 }}
                                    />
                                    <Styled.Action>
                                        <Styled.Label>Ocultar Imagem: </Styled.Label>
                                        <Form.Check
                                            style={{ marginTop: '20px', marginLeft: '10px' }}
                                            name={'ocultar-imagem' + item.codigo}
                                            checked={item.ocultarAnexo}
                                            onChange={e => setOcultoAnexo(idx, e.target.checked)}
                                            type="switch"
                                            id={'ocultar-imagem' + item.codigo}
                                        />
                                    </Styled.Action>
                                </Styled.ImageContainer>
                            </Styled.RigthArea>
                            <Styled.RightContainer>
                                <RedSolidButton
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                        setIdCardSelected(item.codigo);
                                        setShowModalDeleteCard(true);
                                    }}
                                >
                                    Deletar
                                </RedSolidButton>

                                <GradientButton onClick={() => submitCard(idx)} disabled={!isValidInputs(idx) || submittingIndex == idx}>
                                    {submittingIndex == idx ? 'Enviando' : !item.isPersist ? 'Salvar Cartão' : 'Atualizar Cartão'}
                                </GradientButton>
                            </Styled.RightContainer>
                        </Styled.Container>
                    </>
                );
            })}
        </>
    );
};

export default AdminItemSection;
