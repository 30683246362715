import React, { useState, useEffect } from 'react';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory, useLocation } from 'react-router-dom';
import Styled from './TrilhaFormativaModal.styled';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';

const InstrumentoRoute: string = '/realizandoEscolhas/instrumento';
const TrilhaFormativaRoute: string = '/realizandoEscolhas/trilhaFormativa';
export interface ITrilhaFormativaModal {
    setShow: (value: boolean) => void;
    goToTrilhaFormativaCallback?: (trilha: number, is9ano: boolean) => void;
    isHome?: boolean;
}

interface IMobile {
    isMobile: boolean;
}

const TrilhaFormativaModal = ({ setShow, goToTrilhaFormativaCallback, isHome }: ITrilhaFormativaModal) => {
    const history = useHistory();
    const location = useLocation();
    const [isPathTriha, setIsPathTrilha] = useState<boolean>(false);

    useEffect(() => {
        setIsPathTrilha(location.pathname.includes(TrilhaFormativaRoute));
    }, []);

    const buttonTrilha = (isTrilha: boolean, trilha: number, label: string) => (
        <Styled.ButtonMore
            isTrilha={isTrilha}
            onClick={() => {
                if (trilha !== 3) {
                    if (goToTrilhaFormativaCallback != undefined) {
                        goToTrilhaFormativaCallback(trilha, trilha === 1 ? true : false);
                    }
                } else {
                    history.push('/realizandoEscolhas/trilhaFormativa');
                    GTM_Page_Realizando_Escolhas('home_cards_realizando_escolhas', `acessar_page_trilhaformativa`);
                }
            }}
        >
            {label}
        </Styled.ButtonMore>
    );

    const trilha9o = buttonTrilha(isPathTriha, 1, 'Acessar trilha 9º Ano');

    const trilhaEM = buttonTrilha(isPathTriha, 2, 'Acessar trilha EM');

    const trilhaFM = buttonTrilha(isPathTriha, 3, 'Acessar trilha formativa');

    const ButtonRender = ({ isMobile }: IMobile): JSX.Element => {
        return (
            <Styled.ButtonContainer isMobile={isMobile}>
                <Styled.ButtonFechar onClick={() => setShow(false)}>{'Fechar'}</Styled.ButtonFechar>

                <>
                    {isHome ? (
                        <>{trilhaFM}</>
                    ) : (
                        <>
                            {trilha9o}
                            {trilhaEM}
                        </>
                    )}
                </>
            </Styled.ButtonContainer>
        );
    };

    return (
        <Styled.TrilhaFormativaContainer isMobile={IsMobileMaxWidth()}>
            <Styled.Title>{'Atenção!'}</Styled.Title>
            <Styled.Description isMobile={IsMobileMaxWidth()}>
                {'A avaliação estará disponível somente após a finalização da trilha formativa.'}
            </Styled.Description>
            <ButtonRender isMobile={IsMobileMaxWidth()} />
        </Styled.TrilhaFormativaContainer>
    );
};

export default TrilhaFormativaModal;
