import { ICollapse } from 'components/UI/Expandable';
import Text from 'components/UI/Texts';
import React from 'react';
import styled from 'styled-components';

const Paragraph = styled(Text)`
    margin: 10px 0;
    text-align: justify;
`;

const expandableData: ICollapse[] = [
    {
        title: 'Qual a relevância de construir seu PDP para o desenvolvimento de competências socioemocionais?',
        content: (
            <>
                <Paragraph>
                    É uma etapa essencial no seu processo de desenvolvimento profissional, pois permite organizar e registrar o que conheceu
                    sobre si mesmo e suas competências socioemocionais, as desenvolvidas e as que julga importante desenvolver.
                </Paragraph>
                <Paragraph>
                    Também reforça a compreensão de que a prática docente mobiliza, além de conhecimentos técnicos e específicos de sua área,
                    diversas competências socioemocionais para as demandas relacionais e de gestão emocional e do fazer docente. O
                    desenvolvimento das competências socioemocionais também facilita direta e indiretamente a aprendizagem dos estudantes. Essas
                    competências podem ser desenvolvidas de modo intencional e estruturado, e o PDP apoia esse processo.
                </Paragraph>
            </>
        ),
    },
];

export default expandableData;
