import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { MobileMaxWidth } from 'constants/Dimensions';
import { Colors } from 'enums/Colors';
import _Input from 'components/UI/Input';
import { Form, FormControl } from 'react-bootstrap';

const RESIZE_MEDIUM_WIDTH: number = 1200;

const DialogFeedbackStyled = {
    Modal: styled(Modal)<{ width?: number }>`
        padding-left: unset;

        .dialogWidth {
            min-width: ${props => (props.width ? props.width + 'px' : '1166px')};
            border-radius: 10px;
        }

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            .dialogWidth {
                min-width: 800px;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            .dialogWidth {
                min-width: 316px;
            }
        }
    `,

    ModalBody: styled(Modal.Body)`
        padding: 0;
        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            padding: 21px;
        }
    `,

    Container: styled.div<{ columnReverse: boolean }>`
        width: 100%;
        height: 100%;
        display: flex;

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            flex-direction: ${props => (props.columnReverse ? 'column-reverse' : 'column')};
            justify-content: center;
            align-items: center;
        }
    `,

    ImageSection: styled.section<{ svgSource: any; height?: number }>`
        background: url('${props => props.svgSource}');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 40%;
        height: ${props => (props.height ? props.height + 'px' : '956px')};
        border-radius: 10px 0px 0px 10px;

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            width: 284px;
            height: 177px;
            margin-top: 40px;
            margin-bottom: 60px;
            border-radius: 10px 10px 10px 10px;
        }
    `,

    ContentSection: styled.div`
        width: 80%;
        height: 100%;
        padding: 20px 115px 0 88px;

        display: flex;
        flex-direction: column;

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            padding: 0;
            width: 100%;
            height: unset;
        }
    `,

    ContentTitle: styled.div`
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.Blue};
        margin-bottom: 20px;

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            margin-top: 50px;
            margin-bottom: 37px;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    `,

    Text: styled.p`
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
    `,

    CloseIconContainer: styled.div`
        width: 100%;
        transform: translateX(90px);
    `,

    CloseIcon: styled.img`
        width: 25px;
        height: auto;
        float: right;
        cursor: pointer;
    `,
};

export default DialogFeedbackStyled;
