import React, { useEffect, useState } from 'react';
import Styled from './ModalModifyTemplate.styled';
import { Modal } from 'react-bootstrap';
import Colors from 'enums/Colors';
import { AiOutlineClose } from 'react-icons/ai';
import Form from 'react-bootstrap';
import Row from 'components/Row';
import Select from 'components/Select';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { ToastSuccess } from 'components/Toaster';

interface IModalModifyTemplate {
    showModal: boolean;
    isLoading: boolean;
    namePage: string;
    idPage: number;
    onHiden: () => void;
    handleModify: () => void;
}

const ModalModifyTemplate = ({ showModal, onHiden, handleModify, namePage, idPage, isLoading }: IModalModifyTemplate) => {
    const service = new PaginaIntermediariaService();

    const [template, setTemplate] = useState({
        value: '',
        label: '',
    });

    const handleSelcted = (value: any) => {
        setTemplate(value);
    };

    useEffect(() => {
        setTemplate({ value: '', label: '' });
    }, [showModal]);

    const handleUpdateTemplate = async () => {
        const response = await service.editarTemplate(idPage, parseInt(template.value));

        if (response.status === 200) {
            ToastSuccess('Template atualizado com sucesso!');
            onHiden();
        }
    };

    return (
        <Modal show={showModal} onHide={onHiden} centered>
            <Styled.Modal.CloseContainer>
                <p onClick={onHiden}>
                    <AiOutlineClose />
                </p>
            </Styled.Modal.CloseContainer>

            <Styled.ContainerModal style={{ marginLeft: 0 }}>
                <Styled.Modal.TitleAtencao>Alteração no template</Styled.Modal.TitleAtencao>
                <Styled.Modal.Title>
                    Infelizmente o template da página ou recurso: <b>{namePage}</b> foi perdida.
                    <br />
                    <br />
                    <b>OBS: A mudança para um template incorreto poderá ocorrer perdas de informações.</b>
                </Styled.Modal.Title>

                <Row justify="space-around">
                    <Select
                        itens={[
                            {
                                value: 1,
                                label: 'Página em Branco',
                            },
                            {
                                value: 3,
                                label: 'Novos Recursos',
                            },
                        ]}
                        value={{
                            value: template.value,
                            label: template.label,
                        }}
                        onSelected={o => handleSelcted(o)}
                        isSmall={true}
                    />
                </Row>
                <br />
                <Row justify="space-around">
                    <Styled.Button disabled={isLoading} color={Colors.White} bgHover={Colors.Blue} onClick={handleUpdateTemplate}>
                        {isLoading ? 'Alterando...' : 'Alterar'}
                    </Styled.Button>
                    <Styled.Button color={Colors.White} bgHover={Colors.InfoRed} onClick={onHiden}>
                        Fechar
                    </Styled.Button>
                </Row>
            </Styled.ContainerModal>
        </Modal>
    );
};

export default ModalModifyTemplate;
