export namespace Preferences {
    export const ExibirInstrucaoPainel = {
        chave: 'EXIBIR_INSTRUCAO_PAINELSOCIO',
        valor: {
            naoExibir: 'NAO_EXIBIR',
            exibir: 'EXIBIR',
        },
    };

    export const SaibaMaisExpanded = {
        chave: 'EXIBIR_SAIBA_MAIS',
        valor: {
            naoExpandir: 'NAO_EXPANDIR',
            expandir: 'EXPANDIR',
        },
    };

    export const DialogSlider = {
        chave: 'DIALOG_SLIDER',
        valor: {
            naoExibir: 'NAO_EXIBIR',
            exibir: 'EXIBIR',
        },
    };

    export const RealizandoEscolhasExpanded = {
        chave: 'EXIBIR_REALIZANDO_ESCOLHAS',
        valor: {
            naoExibir: 'NAO_EXIBIR',
            exibir: 'EXIBIR',
        },
    };
}
