import { GenericService } from '../Generic.service';

export default class AdminUserService extends GenericService {
    readonly URI: string = '/v1/perfilUsuario/admin';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listar(pagina: number, por_pagina: number = 10) {
        return this.get('list', { pagina, por_pagina });
    }

    public buscar(nome: string | null, cpf: string | null, email: string | null, pagina: number, por_pagina: number = 10) {
        return this.get('buscar', { nome, cpf, email, pagina, por_pagina });
    }

    public excluir(keycloak_id: string) {
        return this.deleteByParams('excluir', { keycloak_id });
    }

    public resetSenha(username: string) {
        return this.put('reset-senha?username=' + username, {});
    }

    public atualizarPerfil(username: string, role: string) {
        return this.put('alterar-role', { username, role });
    }
}
