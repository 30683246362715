import React from 'react';
import Styled from './BiblioNotificationPage.styled';

import dangerIcon from '../../../../images/biblioteca/danger.svg';
import { DangerButton, OutlineButton } from 'components/UI/Buttons';

export interface IBiblioNotificationExcludeModal {
    show: boolean;
    goAction: () => void;
    onHide: () => void;
}

const BiblioNotificationExcludeModal = ({ show, onHide, goAction }: IBiblioNotificationExcludeModal) => {
    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            <Styled.ModalContainer>
                <Styled.ModalIcon src={dangerIcon} />
                <Styled.ModalText>Tem certeza de que deseja excluir as notificações selecionadas?</Styled.ModalText>
                <Styled.ModalBottomContainer>
                    <OutlineButton onClick={() => onHide()}>Cancelar</OutlineButton>
                    <DangerButton onClick={goAction}>Excluir</DangerButton>
                </Styled.ModalBottomContainer>
            </Styled.ModalContainer>
        </Styled.Modal>
    );
};

export default BiblioNotificationExcludeModal;
