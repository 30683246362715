import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import BibliotecaItemPage from 'pages/biblioteca_v2/subpages/item-page/BibliotecaItemPage';
import BiblioNotificationPage from 'pages/biblioteca_v2/subpages/notificacao/BiblioNotificationPage';

import Styled from '../../Biblioteca.styled';
import BiblioFilterItem from '../filter-item/BiblioFilterItem';
import BiblioResultGrid from '../result-grid/BiblioResultGrid';
import BiblioNotificationConfigPage from 'pages/biblioteca_v2/subpages/notificacao/BiblioNotificationConfigPage';
import { MobileMaxWidth } from 'constants/Dimensions';

export interface IBiblioContent {}

const BiblioContent = ({}: IBiblioContent) => {
    const location = useLocation();

    const showInfo = (): boolean => {
        return !location.pathname.includes('/item') && !location.pathname.includes('/notificacoes');
    };

    useEffect(() => {
        if (window.screen.width > MobileMaxWidth && !location.search.includes('page=')) {
            scrollTo(0, 0);
        }
    }, [location.pathname, location.search]);

    return (
        <>
            {showInfo() && (
                <Styled.HeadContainer>
                    <Styled.Title>Biblioteca de conteúdos</Styled.Title>
                    <Styled.Text>
                        Acervo e curadoria do Instituto Ayrton Senna compostos por guias temáticos, livros e estudos. Todos disponíveis para
                        download gratuito.
                    </Styled.Text>
                    <BiblioFilterItem />
                </Styled.HeadContainer>
            )}

            <Route exact path="/biblioteca" component={BiblioResultGrid} />
            <Route exact path="/biblioteca/categoria/:categoria" component={BiblioResultGrid} />
            <Route exact path="/biblioteca/tag/:tag" component={BiblioResultGrid} />

            <Route exact path="/biblioteca/item/:slug" component={BibliotecaItemPage} />
            <Route exact path="/biblioteca/notificacoes" component={BiblioNotificationPage} />
            <Route exact path="/biblioteca/notificacoes/preferencias" component={BiblioNotificationConfigPage} />
        </>
    );
};

export default BiblioContent;
