import { jsonProperty, Serializable } from 'ts-serializable';
import { EtapaItemRecursosCostumizado } from './EtapaItemRecursosCostumizado';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

export class ItemRecursosCostumizadoPublic extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(Number, null)
    codigoPaginaIntermediaria?: number | null = -1;

    @jsonProperty(Number, null)
    codigoCategoriaPaginaExclusiva?: number | null = -1;

    @jsonProperty(String, null)
    nome: string = '';

    @jsonProperty(String, null)
    descricao: string = '';

    @jsonProperty(String, null)
    descricao_extras: string = '';

    @jsonProperty(Boolean, null)
    linkExterno: boolean = false;

    @jsonProperty(Boolean, null)
    conteudosSequenciais: boolean = false;

    @jsonProperty(Boolean, null)
    possuiAcesso: boolean = false;

    @jsonProperty(Boolean, null)
    etapasSequenciais: boolean = false;

    @jsonProperty(String, null)
    url: string = '';

    @jsonProperty(String, null)
    usuarioResponsavel?: string = '';

    @jsonProperty(String, null)
    ultimaAtualizacao?: string = '';

    @jsonProperty(String, null)
    anexoBase64: string = '';

    @jsonProperty(String, null)
    filePath?: string = '';

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;

    @jsonProperty(Boolean, null)
    oculto: boolean = false;

    @jsonProperty(Number, null)
    ordem: number | null = -1;
}
