import { PrimaryButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import React from 'react';

import styled, { CSSProperties } from 'styled-components';

interface IActionButtonContainer {}
export const ActionButtonContainer = styled.div(({ ...props }: React.PropsWithChildren<IActionButtonContainer>) => ({}));

export const MainActionButton = styled(PrimaryButton)`
    width: 106px;
    height: 42px;
    font-size: 14px;
    /* border-radius: 50px; */
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    outline: none;
    border: none;
`;

export const RegisterButton = styled.button`
    width: 106px;
    height: 42px;
    background-color: ${Colors.White};
    border: 1px solid ${Colors.Blue};
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.Blue};
    cursor: pointer;
    outline: none;

    padding-left: 12px;
    padding-right: 12px;
    min-width: 150px;
    /* margin-right: 15px; */

    &:hover {
        background-color: ${Colors.Blue};
        color: ${Colors.White};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

export const PopupMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
`;

export const PopOver = styled.div`
    button {
        filter: grayscale(0);
    }
    position: absolute;
    left: -19px;
    top: 52px;
    width: 150px;
    text-align: center;
    background: #ffffff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
`;

export const MenuOptions = styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #747070;
    margin-bottom: 29px;

    &:hover {
        cursor: pointer;
    }
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MenuOptionsWithHover = styled.button`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #747070;
    height: 60px;
    background: transparent;
    border: none;

    &:hover {
        cursor: pointer;
        color: ${Colors.White};
        font-weight: 600;
        letter-spacing: 0.2px;
        background: ${Colors.Blue};
    }

    &:last-child:hover {
        border-radius: 0px 0px 8px 8px;
    }
`;

export const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    gap: 26px;
    padding-top: 15px;
`;

export const CloseMenuButton = styled(PrimaryButton)`
    display: flex;
    justify-content: center;
    width: 144px;
    height: 18px;
    background: transparent;
    color: white;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    font-weight: 400;
    border: none;
    padding: 0;
    margin: 0;
`;

export function primaryButtonStyle(): CSSProperties {
    return {
        background: 'transparent',
        width: '144px',
        height: '32px',
        minWidth: '0',
        border: `1px solid ${Colors.Yellow}`,
        color: Colors.Yellow,
    };
}

export function secondaryButtonStyle(): CSSProperties {
    return {
        width: '144px',
        height: '32px',
        margin: '0',
        border: `1px solid ${Colors.Yellow}`,
        background: Colors.Yellow,
        color: Colors.Blue,
    };
}
export const MenuPerfil = {
    Container: styled.div`
        min-width: 238px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 40px;
    `,

    Content: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Name: styled.h1`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: ${Colors.Blue};
    `,

    Cargo: styled.p`
        font-family: 'Barlow';
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: ${Colors.Blue};
    `,

    Opcoes: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            height: 56px;
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: left;
            padding-left: 10px;
            gap: 10px;
            cursor: pointer;
            border-top: 1px solid #F5F5F5;
            transition: all 0.5s;

            &:hover {
                background-color: #F5F5F5;
            }
        `,
    },
};
