import styled, { keyframes } from 'styled-components';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';
import Colors from '../../../../../enums/Colors';

const fadeIn = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: ${fadeIn} 0.3s linear;
`;

export const ImageButton = styled.button`
    width: 167px;
    height: 37px;
    background-color: ${Colors.White};
    border: 1px solid ${Colors.BlueGrey};
    box-sizing: border-box;
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: ${Colors.BlueGrey};
    cursor: pointer;
    outline: none;
    margin-top: 30px;

    &:hover {
        background-color: ${Colors.BlueGrey};
        color: ${Colors.White};
    }

    @media (max-width: ${MobileMaxWidth}px) {
    }
`;

export const ConfirmButton = styled.button<{ disabled: boolean }>`
    width: 167px;
    height: 42px;
    margin-top: 50px;

    background: ${props => (props.disabled ? '#e0e0e0' : Colors.Yellow)};
    color: ${props => (props.disabled ? Colors.Grey3 : Colors.DarkBlue)};

    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: bold;

    &:hover {
        color: ${props => (props.disabled ? Colors.Grey3 : Colors.White)};
    }

    @media (max-width: ${MobileMaxWidth}px) {
        width: 100%;
        margin: 0 auto;
    }
`;

export const FixedBottom = styled.div<{ fixed: boolean }>`
    position: ${props => (props.fixed ? 'fixed' : 'sticky')};
    width: 100%;
    bottom: 10px;
    margin-top: 55px;
    width: ${props => (props.fixed ? 'calc(100% - 2 * 20px)' : '100%')};
`;

export const Preview = styled.img`
    border: 1px ${Colors.BlueGrey} solid;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    width: 250px;
    height: 250px;
    cursor: pointer;
    margin: 0;

    @media (max-width: ${MobileMaxWidth}px) {
        margin: 20px 0;
    }
`;
