import React, { useCallback, useRef } from 'react';
import { clone } from 'lodash';
import { useEffect, useState } from 'react';
import Styled from './CarouselCategory.styled';
import arrowYellow from '../../../images/homepage/arrow-yellow.svg';

import ReactTooltip from 'react-tooltip';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Slider from 'react-slick';

import CardCarousel from './components/card/CardCarousel';
import SecaoCardCategoria from 'models/generic-components/SecaoCardCategoria';
import { CardsSecaoCardCategoria } from 'models/generic-components/CardsSecaoCardCategoria';
import { CardCategoria } from 'models/generic-components/CardCategoria';
export interface ICardsContainer {
    dados: SecaoCardCategoria;
}

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const CarouselCategory = ({ dados }: ICardsContainer) => {
    const [iCardsCarrossel] = useState<CardsSecaoCardCategoria[]>(dados.cards);

    //Categorias
    const [categorias, setCategorias] = useState<CardCategoria[]>([]);
    const [activeTab, setActiveTab] = useState<CardCategoria | null>(null);

    const [showAll, setShowAll] = useState<boolean>(false);
    const [showScroll, setShowSroll] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [allCategoriesFirst, setAllCategoriesFirst] = useState(false);

    const scrow = useRef<HTMLDivElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const listarCategorias = async () => {
        const categories = Array.from(dados.cards).map((c: any) => c.categoria);
        setCategorias(categories);

        setAllCategoriesFirst(true);
        setShowAll(true);
        setActiveTab(null);
    };

    const selectTab = (category: CardCategoria) => {
        setShowAll(false);
        setActiveTab(category);
    };

    const scrollTo = (orientation: 'R' | 'L') => {
        const increaseScroll = 472 * 2;

        if (orientation == 'R' && scrow.current) scrow.current.scrollBy(increaseScroll, 0);
        else if (scrow.current) scrow.current.scrollBy(-1 * increaseScroll, 0);
    };

    const scrollToZero = () => {
        scrow.current?.scrollTo(0, 0);
    };

    useEffect(() => {
        listarCategorias();
    }, []);

    useEffect(() => {
        if (scrollContainer.current && scrow.current) {
            const containerW = scrollContainer.current.clientWidth;
            const scrowW = scrow.current.scrollWidth;

            setShowSroll(containerW <= scrowW);
        }
    }, [scrollContainer.current, scrow.current, activeTab, showAll, iCardsCarrossel]);

    useEffect(() => {
        if (scrow.current) {
            scrow.current.addEventListener('scroll', e => {
                setScrollLeft(scrow.current?.scrollLeft ?? 0);
            });
        }

        return () => scrow.current?.removeEventListener('scroll', () => {});
    }, [scrow.current]);

    const sortEducationalSolutions = (a: CardsSecaoCardCategoria, b: CardsSecaoCardCategoria) => {
        if (a.ordem == b.ordem) return a.ordem - b.ordem;
        else return a.ordem - b.ordem;
    };

    const getCards = useCallback(() => {
        let outcome: CardsSecaoCardCategoria[] = [];

        if (showAll) {
            outcome = iCardsCarrossel.filter(s => s.desabilitado == false).sort(sortEducationalSolutions);
        } else {
            outcome = iCardsCarrossel
                .filter(s => s.categoria && s.categoria.codigo == activeTab?.codigo)
                .filter(s => s.desabilitado == false)
                .sort(sortEducationalSolutions);
        }

        return outcome;
    }, [activeTab, showAll, categorias, iCardsCarrossel]);

    useEffect(() => {
        scrollToZero();
    }, [activeTab, showAll]);

    const IsCustomMaxWidth = (max: number): boolean => {
        return window.innerWidth <= max;
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <React.Fragment>
            <Styled.Container>
                <Styled.ContainerHeader>
                    <Styled.Title>{dados.titulo}</Styled.Title>
                    <Styled.SubTitle>{dados.descricao}</Styled.SubTitle>
                </Styled.ContainerHeader>
                <Styled.TabsContiner>
                    {allCategoriesFirst && (
                        <Styled.Tab
                            active={showAll}
                            onClick={() => {
                                setShowAll(true);
                                setActiveTab(null);
                            }}
                        >
                            Todas as jornadas
                        </Styled.Tab>
                    )}

                    {categorias.map((c, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Styled.Tab
                                    active={c.descricao == activeTab?.descricao}
                                    onClick={() => selectTab(c)}
                                    key={idx}
                                    data-tip={c.hoverMenu}
                                    data-for={`tooltip-id-${idx}`}
                                >
                                    {c.descricao}
                                </Styled.Tab>

                                {!IsCustomMaxWidth(1250) && (
                                    <ReactTooltip
                                        id={`tooltip-id-${idx}`}
                                        className="max-toptip"
                                        effect="solid"
                                        place="top"
                                        delayShow={0}
                                        backgroundColor={'#003C78'}
                                        multiline={true}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}

                    {!allCategoriesFirst && (
                        <Styled.Tab
                            active={showAll}
                            onClick={() => {
                                setShowAll(true);
                                setActiveTab(null);
                            }}
                        >
                            Todas as jornadas
                        </Styled.Tab>
                    )}
                </Styled.TabsContiner>

                <br />

                <Styled.ScrollContainer ref={scrollContainer}>
                    {showScroll && <Styled.ArrowButton src={arrowYellow} onClick={() => scrollTo('L')} active={scrollLeft > 0} />}

                    {IsMobile() ? (
                        <Styled.CarrouselContainer>
                            <Slider {...settings}>
                                {getCards().map(item => {
                                    return <CardCarousel key={item.codigo} item={item} />;
                                })}
                            </Slider>
                        </Styled.CarrouselContainer>
                    ) : (
                        <Styled.Scroll ref={scrow}>
                            {getCards().map(item => (
                                <Styled.ScrollItem key={item.codigo}>
                                    <CardCarousel item={item} />
                                </Styled.ScrollItem>
                            ))}
                        </Styled.Scroll>
                    )}

                    {showScroll && (
                        <Styled.ArrowButton
                            className="flip"
                            src={arrowYellow}
                            style={{ visibility: showScroll ? 'visible' : 'hidden' }}
                            onClick={() => scrollTo('R')}
                            active={
                                !(scrow.current && scrow.current?.scrollLeft >= scrow.current?.scrollWidth - scrow.current?.clientWidth) ?? true
                            }
                        />
                    )}
                </Styled.ScrollContainer>
            </Styled.Container>
        </React.Fragment>
    );
};

export default CarouselCategory;
