import { MobileMaxWidth } from 'constants/Dimensions';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import TileStyle from './Tile.styled';
import iconBook from './assets/icone-livro.svg';

export interface ITile {
    title: string;
    icon?: any;
    undertitle: string;
    contentText: string;
    buttonText: string;
    buttonCallback: (isMobile: boolean) => void;
    srcBackground?: any;
    index: number;
    disabled?: boolean;
}

const Tile = ({ icon, title, undertitle, contentText, buttonText, srcBackground, buttonCallback, index, disabled = false }: ITile) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <TileStyle.Container
            onClick={async () => {
                if (isMobile) await buttonCallback(true);
            }}
        >
            <TileStyle.Content>
                <TileStyle.InnerContent>
                    <TileStyle.SideContent>
                        <TileStyle.BlueCircle>
                            <TileStyle.NumberIndex>{icon ? icon : index}</TileStyle.NumberIndex>
                        </TileStyle.BlueCircle>
                    </TileStyle.SideContent>
                    <TileStyle.LateralContent>
                        <TileStyle.Title>{title}</TileStyle.Title>
                        <TileStyle.UnderTitle>{undertitle}</TileStyle.UnderTitle>
                    </TileStyle.LateralContent>
                </TileStyle.InnerContent>

                <TileStyle.Retract>
                    <TileStyle.TextContainer>
                        <TileStyle.Text>{contentText}</TileStyle.Text>
                    </TileStyle.TextContainer>
                </TileStyle.Retract>

                <TileStyle.ButtonContainer>
                    <TileStyle.Button
                        onClick={async () => {
                            await buttonCallback(false);
                        }}
                        disabled={disabled}
                    >
                        {buttonText}
                    </TileStyle.Button>
                </TileStyle.ButtonContainer>
            </TileStyle.Content>

            {/* <TileStyle.ImageBackground src={srcBackground} /> */}
        </TileStyle.Container>
    );
};

export default Tile;
