import styled from 'styled-components';

import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';

export const PickerListContainerStyle = styled.div``;

export const ItemsContainer = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,
    maxWidth: '60%',
});

export const AddOtherButton = styled.button`
    padding: 10px 20px;
    height: 42px;
    border: 1px solid ${Colors.BlueGrey};
    border-radius: 10px;
    background-color: transparent;
    outline: none;
    color: ${Colors.BlueGrey};
    text-align: center;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: '38px';
    }
`;

export const AddOtherInput = styled.input({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: 0.1,
    fontSize: '16px',
    fontStyle: 'italic',
    lineHeight: '22px',
    color: Colors.BlueGrey,
    height: '42px',
    width: '275px',
    border: `1px solid ${Colors.BlueGrey}`,
    borderRadius: '10px',
    padding: '10px 12px',
    outline: 'none',
});
