import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const BiblioFilterItemStyled = {
    Container: styled.div`
        background-color: #003c78;
        width: 100%;
        max-width: 100%;
        //overflow: hidden;
        color: white;
        padding: 22px;
        margin: 20px 0;
    `,

    Row: styled.div`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    `,

    Row2: styled.div`
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    Row3: styled.div``,

    SearchContainer: styled.div`
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 10px;
        }
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
    `,

    label: styled.span`
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #fcfcfc;
        font-family: 'Inter';
        font-style: normal;
        margin-bottom: 5px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 10px;
        }
    `,

    Input: styled.input`
        border: 0;
        outline: 0;
        padding: 10px;
        border-radius: 4px;
        width: 100%;
    `,

    IconContainer: styled.div<{ centralize: boolean }>`
        width: 50px;
        padding: 10px;
        border: 1px white solid;
        border-radius: 3px;
        margin-left: 10px;
        cursor: pointer;
        display: flex;
        justify-content: ${props => (props.centralize ? 'center' : 'none')};

        :hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    `,

    IconCount: styled.div`
        background-color: rgb(255, 72, 48);
        color: white;

        width: 17px;
        height: 17px;

        border-radius: 50%;
        display: flex;

        align-items: center;
        justify-content: center;

        transform: translateX(-50%) translateY(-25%);

        span {
            font-family: 'Barlow', sans-serif;
            text-align: center;
            width: 100%;
            height: 100%;
            //transform: translateY(-1px);
        }
    `,

    IconInner: styled.div`
        display: flex;
        width: fit-content;
        height: fit-content;
        font-size: 1.5em;
    `,
};

export default BiblioFilterItemStyled;
