import { GenericService } from './Generic.service';

export class KeycloakService extends GenericService {
    readonly URI: string = '/v1/keycloakController';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public getUsuarioLogado() {
        return this.get('getUsuarioLogado');
    }
}
