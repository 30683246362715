import { jsonProperty, Serializable } from 'ts-serializable';
import Macrocompetencia from '../pdp/Macrocompetencia';
import AcaoMeta from './AcaoMeta';

class RegistroMacrocompetencia extends Serializable {
    @jsonProperty(Number)
    id: number = 0;

    @jsonProperty(Number)
    ordem: number = 0;

    @jsonProperty(Macrocompetencia)
    macrocompetencia: Macrocompetencia = new Macrocompetencia();

    @jsonProperty(String)
    motivoDesenvolvimento: string = '';

    @jsonProperty(String)
    objetivoDesenvolvimento: string = '';

    @jsonProperty(String)
    quemOInspira: string = '';

    @jsonProperty([AcaoMeta], [])
    listaAcoesMetasCurtoPrazo: AcaoMeta[] = [];

    @jsonProperty([AcaoMeta], [])
    listaAcoesMetasMedioPrazo: AcaoMeta[] = [];

    @jsonProperty([AcaoMeta], [])
    listaAcoesMetasLongoPrazo: AcaoMeta[] = [];

    @jsonProperty([AcaoMeta], [])
    listaAcoesMetasPrazo: AcaoMeta[] = [];

    constructor() {
        super();
    }
}

export default RegistroMacrocompetencia;
