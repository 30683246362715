import React, { useEffect, useState } from 'react';
import Styled from './JourneysHome.styled';
import { ButtonLink } from '../solucoes/CarouselSolucoes';
import Colors from 'enums/Colors';
import EducationalSolution from 'models/EducationalSolution';
import defaultContentHome from 'data/defaultContentHome';
import useLogged from 'hooks/useLogged';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { clone } from 'lodash';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ButtonHomeNotHoverV2, ButtonHomeV2 } from '../button/ButtonHomeV2.styled';

const JourneyHome = () => {
    const [educationalSolutions, setEducationalSolutions] = useState<EducationalSolution[]>(defaultContentHome.educationalSolutions);
    const { keycloak } = useKeycloak();
    const usuarioLogado = keycloak.authenticated;
    const history = useHistory();

    // Página Jornada
    const goToJourney = (educationalSolutionItem: EducationalSolution) => {
        GTM_Page_Home('secao_jornada_desenvolvimento', `btn_click_${educationalSolutionItem.title.replace(/\s+/g, '_').toLowerCase()}`);
        if (educationalSolutionItem.active) {
            if (educationalSolutionItem.title === 'Socioemocional de Professores') {
                history.push({
                    pathname: '/login',
                    state: { pathToRedirect: '/painelSocioemocional/home' },
                });
            } else if (educationalSolutionItem.title === 'Realizando Escolhas') {
                history.push({
                    pathname: '/login',
                    state: { pathToRedirect: '/realizandoEscolhas/home' },
                });
            }
        }
    };

    // Página Intermediária
    const goToDetailPage = (educationalSolutionItem: EducationalSolution) => {
        GTM_Page_Home('secao_jornada_desenvolvimento', `btn_click_${educationalSolutionItem.followRoute.replace('/', '').toLowerCase()}`);
        if (educationalSolutionItem.linkExterno) {
            let url = educationalSolutionItem.followRoute;
            if (!url.match(/^https?:\/\//i)) {
                url = 'https://' + url;
            }

            window.open(url, '_blank');
        } else
            history.push({
                pathname: educationalSolutionItem.followRoute,
                state: {
                    socioemocionalEducadores: true,
                },
            });
    };

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('SOLUCOES_EDUCACIONAIS');

        if (status == 200) {
            const _solucoes: EducationalSolution[] = clone(educationalSolutions);
            const newSols = Array.from(data).map((sol: any) =>
                new EducationalSolution().fromJSON({
                    id: sol.id,
                    title: sol.titulo,
                    text: sol.conteudo,
                    active: sol.ativo,
                    image: '',
                    imagePath: '',
                    followRoute: sol.link,
                    followText: '',
                    linkExterno: sol.linkExterno,
                    defaultData: false,
                    complement: parseInt(sol.complemento),
                })
            );

            for (let i = 0; i < newSols.length; i++) {
                if (newSols[i].complement > -1) _solucoes[newSols[i].complement] = newSols[i];
            }

            setEducationalSolutions(_solucoes);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    return (
        <Styled.Container>
            <Styled.Content2>
                <Styled.Title1 dangerouslySetInnerHTML={{ __html: `JORNADAS <div>DE</div> <br/> DESENVOLVIMENTO` }} />
                <Styled.Description style={{ marginLeft: 0 }}>
                    Vivencie experiências, acesse ferramentas e materiais estruturados para potencializar o seu processo de desenvolvimento!
                </Styled.Description>
            </Styled.Content2>
            <Styled.BodyJourneys>
                {educationalSolutions
                    .filter(i => i.active)
                    .map((item, index) => (
                        <Styled.Content key={index}>
                            <Styled.Title2>{item.title.toLocaleUpperCase()}</Styled.Title2>
                            <Styled.Description>{item.text}</Styled.Description>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <ButtonHomeV2
                                    style={{ backgroundColor: Colors.White, color: Colors.Blue, minWidth: '80px', }}
                                    color={Colors.SoftBlue}
                                    onClick={() => {
                                        goToDetailPage(item);
                                    }}
                                >
                                    Saiba mais
                                </ButtonHomeV2>
                                <ButtonHomeV2
                                    style={{ backgroundColor: Colors.Yellow, color: Colors.Blue, width: '220px',  }}
                                    color={Colors.SoftBlue}
                                    onClick={() => {
                                        goToJourney(item);
                                    }}
                                >
                                    Acessar
                                </ButtonHomeV2>
                            </div>
                        </Styled.Content>
                    ))}
            </Styled.BodyJourneys>
        </Styled.Container>
    );
};

export default JourneyHome;
