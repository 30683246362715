import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { GenericService } from './Generic.service';

export class PaginaIntermediariaService extends GenericService {
    readonly URI: string = '/v1/paginaIntermediaria';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listar(tipoTemplate?: string | undefined) {
        return this.get('list', { tipoTemplate });
    }

    public listarTipos() {
        return this.get('tiposPagina');
    }

    public inserir(data: AdminPaginaIntermediaria) {
        return this.post('insert', data);
    }

    public inserirDinamica(data: AdminPaginaIntermediaria) {
        return this.post('insertDynamicPage', data);
    }

    public buscarPagina(paginaId: number) {
        return this.get('getByIdResumido', { id: paginaId });
    }

    public buscarPaginaPorLink(link: string) {
        return this.get('getByLinkResumido', { link });
    }

    public editar(id: number, data: AdminPaginaIntermediaria) {
        return this.put(String(id), data);
    }

    public editarTemplate(id: number, template: number) {
        return this.patch(`${id}/${template}`);
    }

    public excluir(id: number) {
        return this.deleteByParams(String(id));
    }

    public excluirRecurso(id: number) {
        return this.deleteByParams('recurso/' + String(id));
    }

    public verificarUriPageExistent(link?: string) {
        return this.get('checarUrl', { link });
    }
}
