import styled from 'styled-components';

const StyleSecaoJornadasCompetenciasSocioemocionais = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        background-color: #003c78;
        color: #ffffff;
        padding: ${({ isMobile }) => (isMobile ? '32px' : '40px 78px')};
        min-height: 500px;
    `,

    Row: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,
};

export default StyleSecaoJornadasCompetenciasSocioemocionais;
