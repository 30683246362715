import React, { useEffect } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import ImagemAcelera from '../../assets/formulaVitoria/logo.jpg';

import imagem_01 from '../../assets/formulaVitoria/material/imagem_01.jpg';

import imagem_inpratice_01 from '../../assets/formulaVitoria/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/formulaVitoria/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/formulaVitoria/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/formulaVitoria/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/formulaVitoria/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/formulaVitoria/inPratice/img_06.jpg';
import imagem_inpratice_07 from '../../assets/formulaVitoria/inPratice/img_07.jpg';
import imagem_inpratice_08 from '../../assets/formulaVitoria/inPratice/img_08.jpg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';

const FormulaVitoria = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Fórmula da Vitória',
                        descricao: `
                        Em 2009, o Instituto Ayrton Senna cria o Fórmula da Vitória para qualificar a aprendizagem em Matemática e Língua Portuguesa para alunos dos anos finais do Ensino Fundamental. 
                        Agora, em 2024, a solução recebe uma atualização para adequação e alinhamento à Base Nacional Comum Curricular – BNCC, mantendo a mesma essência.
                        `,
                        objetivo: `
                         O objetivo do Fórmula da Vitória é qualificar a aprendizagem dos
                        estudantes em Língua Portuguesa e Matemática para reversão do quadro
                        de reprovação e exclusão no cotidiano escolar.
                         `,
                        publicoAlvo: '6º ao 9º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (enturmação).',
                        formacao: `
                        Encontros formativos para professores, gestores escolares e
                        Secretaria (carga horária – 28 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                            metas previamente definidas.
                        `,
                        atores: `
                       Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                        Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                        (gestão da política educacional).
                        `,
                        elementos: `
                          (I) Material didático do aluno e do professor, com fundamentos da
                            proposta e orientações específicas para o trabalho aula a aula, com objetivos
                            específicos de desenvolvimento em Língua Portuguesa e Matemática; (II) Coordenação
                            e gerenciamento; (III) Formação presencial e a distância (EAD), inicial e continuada;
                            (IV) Sistema de monitoramento e avaliação, gerenciamento dos indicadores de
                            aprendizagem; e (V) Apoio de agentes técnicos.
                        
                        `,
                        img: ImagemAcelera,
                        heightImg: '280px',
                    }}
                />
                <InPractice
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_07,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_08,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Fórmula da Vitória acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Fórmula da Vitória e ter as imagens compartilhadas aqui na humane. Que tal? 


                    `}
                />
                <SupportMaterial
                    description={
                        <>
                            O desenvolvimento do trabalho no Fórmula da Vitória Língua Portuguesa se dá com o uso da metodologia de sequências
                            didáticas, constituídas por atividades devidamente planejadas. A perspectiva metodológica para o ensino de Matemática
                            na proposta do Fórmula da Vitória é a da resolução de problemas.
                            <br />
                            <br />A solução tem os seguintes materiais que são disponibilizados para as redes parceiras que implementam o Fórmula
                            da Vitória: <strong>livros do aluno e livro do professor.</strong>
                        </>
                    }
                    img={imagem_01}
                />

                <FormSolucoes isBlue tipoSolucao={TipoSolucoesEnum.FORMULA_DA_VITORIA} preDefinition />
                <ContactContainer isLight />
            </GlobalStyled.Container>
        </div>
    );
};

export default FormulaVitoria;
