import Loading from 'components/Loading';
import Row from 'components/Row';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { RedSolidButton, PrimaryButton, DangerButton, BlueSolidButton } from 'components/UI/Buttons';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { StringHelp } from 'core/util/StringHelp';
import Colors from 'enums/Colors';
import { RecursosCostumizadosEnum } from 'enums/RecursosCostumizados';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { ConteudoRecursoCustomizado, ConteudoOrder } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { EtapaItemRecursosCostumizado } from 'models/recursos-costumizados/EtapaItemRecursosCostumizado';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { ResumoRecursoCustomizado } from 'models/recursos-costumizados/ResumoRecursoCustomizado';
import hash from 'object-hash';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { AdminGestaoPaginasMode } from 'pages/gestao_paginas/AdminGestaoPaginasContextWrapper';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MdDeleteForever } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';

import arrowIcon from '../../../../images/admin/arrow-r.svg';
import { AdminGestaoPaginasRecursosDropzone, AdminGestaoPaginasRecursosDragDrop } from '../components/dragdrop/DragDrop';
import ModalExclusao from '../components/modal/ModalExclusao';
import Styled from '../item-recurso/AdminGestaoPaginaItemRecurso.styled';

interface IAdiminGestaoItemEtapa {
    mode: AdminGestaoPaginasMode;
}

const AdminGestaoPaginaItemEtapaRecurso = ({ mode }: IAdiminGestaoItemEtapa) => {
    const history = useHistory();
    const params: any = useParams();
    const [codigoEtapa, setCodigoEtapa] = useState<number>(-1);
    const [etapaOculto, setEtapaOculto] = useState<boolean>(false);
    const [codigoItem, setCodigoItem] = useState<number>(-1);
    const [nameItem, setNameItem] = useState<string>('');
    const [nameEtapa, setNameEtapa] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [conteudosResumidos, setConteudosResumidos] = useState<ResumoRecursoCustomizado[] | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [idConteudo, setIdConteudo] = useState<number>(0);
    const [quantidade, setQuantidade] = useState<number>(0);
    const [excluxed, setExcluxed] = useState<RecursosCostumizadosEnum>();
    const [typeModeEdit, setTypeModeEdit] = useState<boolean>(mode === 'NEW' ? false : true);

    const [loadingOrdem, setLoadingOrdem] = useState<boolean>(false);

    const MAX_CHAR_CONTENT = 200;
    const MAX_CHAR_CONTENT_NAME = 75;
    const MAX_CHAR_CONTENT_DESCRIPTION = 300;

    const serviceRecursosCostumizados = new AdminRecursosCostumizados();

    const { breadCrumb, setBreadCrumb, currentPage, selectPage } = useContext(AdminGestaoPaginasContext.Context);

    const breadCrumbInitial = (idItem?: number, name?: string, nameItem?: string) => {
        setBreadCrumb([
            { route: 'gestao-paginas', label: 'Gestão de páginas' },
            { route: 'gestao-paginas/editar-recurso/' + currentPage?.codigo, label: 'Editar Recurso' },
            { route: 'gestao-paginas/editar-item-recurso/' + idItem, label: nameItem ?? 'Item' },
            { route: 'gestao-paginas/editar-etapa-item/' + codigoEtapa, label: name ?? 'Etapa' },
        ]);
    };

    const fetchConteudosResumidos = async (etapaId: number) => {
        const { data } = await serviceRecursosCostumizados.buscarListaConteudoResumido(etapaId);

        const conteudosResumidos = Array.from(data).map((i: any) => new ResumoRecursoCustomizado().fromJSON(i));

        setConteudosResumidos(conteudosResumidos);
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const fetchItem = async (etapaId: number, idItem: number, nameItem: string) => {
        const { data } = await serviceRecursosCostumizados.etapaGetById(etapaId);
        const pagedata = new EtapaItemRecursosCostumizado().fromJSON(data);

        setCodigoEtapa(pagedata.codigo ?? -1);
        setNameEtapa(pagedata.nome);
        setUrl(pagedata.url);
        setDescription(pagedata.descricao);
        fetchConteudosResumidos(etapaId);
        breadCrumbInitial(idItem, pagedata.nome, nameItem);
        setEtapaOculto(pagedata.oculto);
        setQuantidade(pagedata.ordem ?? 0);
        setLoading(false);
    };

    const isValidInputs = (): boolean => {
        return url.trim().length > 0 && nameEtapa.trim().length > 0 && description.trim().length > 0;
    };

    const submitItem = async () => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');
            return;
        }
        setSubmitting(true);
        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await serviceRecursosCostumizados.verificarUriExistent('/' + sanitizeUrl, codigoEtapa, codigoItem, 'etapa');
            isExistent = value;
        }

        if (isExistent) {
            ToastError('Já existe um conteúdo para a URL informada');
            setLoading(false);
            setSubmitting(false);
            return;
        }

        const payload = new EtapaItemRecursosCostumizado().fromJSON({
            codigoItem: codigoItem,
            nome: nameEtapa,
            url: '/' + sanitizeUrl.replace(/^\//g, ''),
            descricao: description,
            ordem: quantidade,
        });

        try {
            if (mode === 'NEW' && codigoEtapa === -1) {
                const { status, data } = await serviceRecursosCostumizados.novaEtapa(payload);

                if (status < 300) {
                    setCodigoEtapa(data.codigo);
                    setUrl(data.url);
                    ToastSuccess('Etapa salva');
                    breadCrumbInitial(codigoItem, data.nome, nameItem);
                    setTypeModeEdit(true);
                    fetchItem(data.codigo, codigoItem, nameItem);
                }
            } else {
                const { status, data } = await serviceRecursosCostumizados.atualizarEtapa(codigoEtapa, payload);

                if (status < 300) {
                    ToastSuccess('Etapa salva');
                    setUrl(data.url);
                    breadCrumbInitial(codigoItem, data.nome, nameItem);
                }
            }
        } catch (err) {}

        setSubmitting(false);
        scrollToTop();
    };

    const setupOrder = async (item: ResumoRecursoCustomizado, neworder: number) => {
        setLoadingOrdem(true);

        const { codigo } = item;
        const dados = new ConteudoOrder();
        dados.codigo = codigo;
        dados.codigoEtapa = codigoEtapa;
        dados.ordem = neworder;

        const sequencial = await serviceRecursosCostumizados.getSenquencial(codigoItem);

        if (sequencial.data) {
            ToastError('Não é possível ordenar conteúdos sequênciais');
            setLoadingOrdem(false);
            return;
        }

        const { data, status } = await serviceRecursosCostumizados.uptadeOrdemConteudo(dados);

        if (status == 200) {
            const resumoRecurso = Array.from(data).map((s: any) => new ResumoRecursoCustomizado().fromJSON(s));

            const _resumos: ResumoRecursoCustomizado[] = [];

            for (const resumo of resumoRecurso) {
                const dados = new ResumoRecursoCustomizado();
                dados.codigo = resumo.codigo;
                dados.nome = resumo.nome;
                dados.oculto = resumo.oculto;
                dados.ordem = resumo.ordem;

                _resumos.push(resumo);
            }

            setConteudosResumidos(_resumos);
            ToastSuccess('Ordem alterada com sucesso!');
        } else {
            ToastError('Houve um erro ao tentar ordenar os conteúdos.');
        }

        setLoadingOrdem(false);
    };

    const handleOcultar = async (id: number, type: RecursosCostumizadosEnum) => {
        if (type === RecursosCostumizadosEnum.ETAPA) {
            const response = await serviceRecursosCostumizados.ocultarEtapa(id);

            if (response.status === 200) {
                ToastSuccess('Etapa ocultado.');
                fetchInitial();
            }
        } else {
            const response = await serviceRecursosCostumizados.ocultarConteudo(id);

            if (response.status === 200) {
                ToastSuccess('Conteúdo ocultado.');
                fetchInitial();
            }
        }
    };

    const handleExibir = async (id: number, type: RecursosCostumizadosEnum) => {
        if (type === RecursosCostumizadosEnum.ETAPA) {
            const response = await serviceRecursosCostumizados.exibirEtapa(id);

            if (response.status === 200) {
                ToastSuccess('Etapa visivel.');

                fetchInitial();
            }
        } else {
            const response = await serviceRecursosCostumizados.exibirConteudo(id);

            if (response.status === 200) {
                ToastSuccess('Conteúdo visivel.');
                fetchInitial();
            }
        }
    };

    const handleDelete = async () => {
        if (excluxed === RecursosCostumizadosEnum.CONTEUDO) {
            const response = await serviceRecursosCostumizados.deleteConteudo(idConteudo);

            if (response.status === 200) {
                ToastSuccess('Conteúdo excluido com sucesso!');

                fetchConteudosResumidos(codigoEtapa);
            } else {
                ToastError('Houve algum problema ao tentar excluir o conteúdo.');
            }
        } else {
            const response = await serviceRecursosCostumizados.deleteEtapa(codigoEtapa);

            if (response.status === 200) {
                ToastSuccess('Etapa excluida com sucesso!');

                history.push('/admin/gestao-paginas/editar-item-recurso/' + codigoItem);
            } else {
                ToastError('Houve algum problema ao tentar excluir o conteúdo.');
            }
        }

        setShowModal(false);
    };

    const fetchInitial = () => {
        const idItem = String(params?.idItem);
        const id = String(params?.id);
        const nameItem = String(params?.nomeItem);
        const qtdList = String(params?.quantidade);
        setCodigoItem(parseInt(idItem));
        setNameItem(nameItem);
        setQuantidade(parseInt(qtdList));

        if (mode == 'EDIT') {
            setLoading(true);
            fetchItem(parseInt(id), parseInt(idItem), nameItem);
            return;
        }

        // Pegar os params da url e colocar no breadCrumb

        breadCrumbInitial(parseInt(id), undefined, nameItem);
    };

    const validateOrder = () => {
        let newOrder = 0;

        for (const conteudos of conteudosResumidos ?? []) {
            if (conteudos.ordem > newOrder) {
                newOrder = conteudos.ordem;
            }
        }

        return newOrder + 1;
    };

    useEffect(() => {
        fetchInitial();
    }, []);

    return (
        <Styled.Container>
            {isLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <ModalExclusao showModal={showModal} handleHiden={setShowModal} handleExcluxed={handleDelete} />
                    <AdminBreadCrumb crumbs={breadCrumb} />
                    <br />

                    <Styled.DividorRowBettween>
                        <Styled.Title>{'Dados básicos da etapa'}</Styled.Title>
                    </Styled.DividorRowBettween>

                    <br />
                    <Styled.FormContainer>
                        <Row>
                            <Styled.Divisor width="400px">
                                <Styled.Label>Nome da etapa</Styled.Label>
                                <Styled.Input
                                    value={nameEtapa}
                                    onChange={(evn: any) => setNameEtapa(evn.target.value)}
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT_NAME}
                                />
                                <Styled.CountLetter danger={nameEtapa.length >= MAX_CHAR_CONTENT_NAME}>
                                    {nameEtapa.length}/{MAX_CHAR_CONTENT_NAME}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                            <Styled.Divisor width="260px">
                                <Styled.Label>URL</Styled.Label>
                                <Styled.Input
                                    value={url}
                                    onChange={(evn: any) =>
                                        setUrl('/' + StringHelp.alnum(StringHelp.toSize(evn.target.value.toLowerCase().trim(), 200)))
                                    }
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT}
                                />
                                <Styled.CountLetter danger={url.length >= MAX_CHAR_CONTENT}>
                                    {url.length}/{MAX_CHAR_CONTENT}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                        </Row>
                    </Styled.FormContainer>

                    <Styled.FormContainer>
                        <Styled.Divisor width="100%">
                            <Styled.Label>Descrição da etapa</Styled.Label>
                            <Styled.Input
                                value={description}
                                onChange={(evn: any) => setDescription(evn.target.value)}
                                rows={5}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT_DESCRIPTION}
                            />
                            <Styled.CountLetter danger={description.length >= MAX_CHAR_CONTENT_DESCRIPTION}>
                                {description.length}/{MAX_CHAR_CONTENT_DESCRIPTION}
                            </Styled.CountLetter>

                            <Row justify="end" style={{ marginTop: 20, justifyContent: 'space-between' }}>
                                {typeModeEdit && (
                                    <>
                                        <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                            <Styled.DivAround style={{ justifyContent: 'space-between', width: '200px' }}>
                                                <Row>
                                                    <RedSolidButton
                                                        onClick={() => {
                                                            setExcluxed(RecursosCostumizadosEnum.ETAPA);
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        Deletar
                                                    </RedSolidButton>
                                                </Row>
                                            </Styled.DivAround>
                                        </AllowedGroupsValidator>
                                        <Styled.CkeckContainer>
                                            <Row>
                                                <span style={{ marginRight: '10px', fontSize: '16px', fontWeight: 600 }}>Ocultar</span>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id={'etapa' + codigoEtapa}
                                                        checked={etapaOculto}
                                                        onClick={() => {
                                                            !etapaOculto
                                                                ? handleOcultar(codigoEtapa, RecursosCostumizadosEnum.ETAPA)
                                                                : handleExibir(codigoEtapa, RecursosCostumizadosEnum.ETAPA);
                                                        }}
                                                    />
                                                </Form>
                                            </Row>
                                        </Styled.CkeckContainer>
                                    </>
                                )}
                                <Row justify="end">
                                    <BlueSolidButton disabled={submitting} onClick={submitItem}>
                                        {submitting ? 'Salvando...' : 'Salvar página'}
                                    </BlueSolidButton>
                                </Row>
                            </Row>
                        </Styled.Divisor>
                    </Styled.FormContainer>

                    <Styled.BottomContainer>
                        <hr style={{ margin: ' 30px 0' }} />
                        {codigoEtapa !== -1 && (
                            <>
                                <Row justify="space-between" style={{ maxWidth: '1100px', alignItems: 'center', marginBottom: '30px' }}>
                                    <Styled.Title>Conteúdos da etapa</Styled.Title>
                                </Row>
                                <div>
                                    {loadingOrdem && (
                                        <div style={{ position: 'absolute', left: '400px' }}>
                                            <Loading />
                                        </div>
                                    )}
                                    <Styled.ListItens key={hash(conteudosResumidos)} loading={loadingOrdem}>
                                        <DndProvider backend={HTML5Backend}>
                                            {conteudosResumidos
                                                ?.sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                                                .map((dados, index) => {
                                                    const URL = `${nameItem}/${codigoItem}/${nameEtapa}/${codigoEtapa}/${dados.codigo}`;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <AdminGestaoPaginasRecursosDropzone
                                                                thisOrder={dados.ordem ?? -1}
                                                                onDropItem={setupOrder}
                                                            />
                                                            <AdminGestaoPaginasRecursosDragDrop secao={dados}>
                                                                <Styled.RedirectButton>
                                                                    <Styled.DivSpanCard
                                                                        onClick={() =>
                                                                            history.push(
                                                                                '/admin/gestao-paginas/editar-item-etapa-recurso/' + URL
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>{dados.nome}</span>
                                                                    </Styled.DivSpanCard>
                                                                    <Styled.DivAround>
                                                                        <Styled.CkeckContainer>
                                                                            <Row>
                                                                                <span style={{ marginRight: '10px', fontSize: '14px' }}>
                                                                                    Ocultar
                                                                                </span>
                                                                                <Form>
                                                                                    <Form.Check
                                                                                        type="switch"
                                                                                        id={'conteudo' + dados.codigo}
                                                                                        checked={dados.oculto}
                                                                                        onClick={() => {
                                                                                            !dados.oculto
                                                                                                ? handleOcultar(
                                                                                                      dados.codigo,
                                                                                                      RecursosCostumizadosEnum.CONTEUDO
                                                                                                  )
                                                                                                : handleExibir(
                                                                                                      dados.codigo,
                                                                                                      RecursosCostumizadosEnum.CONTEUDO
                                                                                                  );
                                                                                        }}
                                                                                    />
                                                                                </Form>
                                                                            </Row>
                                                                        </Styled.CkeckContainer>
                                                                        <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                                            <MdDeleteForever
                                                                                onClick={() => {
                                                                                    setIdConteudo(dados.codigo);
                                                                                    setExcluxed(RecursosCostumizadosEnum.CONTEUDO);

                                                                                    setShowModal(true);
                                                                                }}
                                                                                size={25}
                                                                                color={Colors.DeleteRed}
                                                                                cursor={'pointer'}
                                                                            />
                                                                        </AllowedGroupsValidator>
                                                                    </Styled.DivAround>
                                                                    <img src={arrowIcon} />
                                                                </Styled.RedirectButton>
                                                            </AdminGestaoPaginasRecursosDragDrop>
                                                            {index == conteudosResumidos.length - 1 && (
                                                                <AdminGestaoPaginasRecursosDropzone
                                                                    thisOrder={(dados.ordem ?? 0) + 1}
                                                                    onDropItem={setupOrder}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </DndProvider>
                                    </Styled.ListItens>
                                </div>
                                <PrimaryButton
                                    onClick={() =>
                                        history.push(
                                            '/admin/gestao-paginas/novo-item-etapa-recurso/' +
                                                nameItem +
                                                '/' +
                                                codigoItem +
                                                '/' +
                                                nameEtapa +
                                                '/' +
                                                codigoEtapa +
                                                '/' +
                                                validateOrder()
                                        )
                                    }
                                >
                                    Adicionar novo conteúdo
                                </PrimaryButton>
                            </>
                        )}
                    </Styled.BottomContainer>
                </React.Fragment>
            )}
        </Styled.Container>
    );
};

export default AdminGestaoPaginaItemEtapaRecurso;
