import React, { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

import RateFeedback from './RateFeedback';
import Styled from './DialogFeedback.styled';
import closeIcon from '../../images/close.svg';
import { DestaqueText, BoldText } from '../UI/Texts';
import { PrimaryButton } from 'components/UI/Buttons';
import Feedback, { TipoFuncionalidade } from 'models/Feedback';
import Banner from '../../images/pdp/imageBannerFinal.png';
import BannerSmall from '../../images/pdp/imageBannerFinalSmall.png';
import { FeedbackService } from 'core/http/service/FeedbackService.service';

const RESIZE_MEDIUM_WIDTH: number = 1200;

const IsMobile = () => {
    return IsCustomMaxWidth(RESIZE_MEDIUM_WIDTH);
};

export interface IDialogFeedback {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    nomeFucionalidade: string;
    funcionalidade: TipoFuncionalidade;
}

const DialogFeedback = ({ show, funcionalidade, nomeFucionalidade, onSubmit, onHide }: IDialogFeedback) => {
    const [comment, setComment] = useState('');
    const [rate, setRate] = useState<number>(0);
    const profile = useAppSelector(state => state.profileReducer.userProfile);

    const submitFeedback = async () => {
        const service = new FeedbackService();

        if (profile && onSubmit) {
            await service.salvarFeedback({
                nota: rate,
                funcionalidade,
                comentario: comment,
                id_perfil: profile?.codigo,
            });

            onSubmit();
        }
    };

    return (
        <Styled.Modal show={show} width={958} dialogClassName="dialogWidth" contentClassName="dialogWidth">
            <Styled.ModalBody>
                <Styled.Container columnReverse={false}>
                    <Styled.ImageSection height={557} svgSource={IsMobile() ? BannerSmall : Banner}></Styled.ImageSection>
                    <Styled.ContentSection>
                        <Styled.CloseIconContainer>
                            <Styled.CloseIcon src={closeIcon} onClick={() => onHide()} />
                        </Styled.CloseIconContainer>
                        <Styled.ContentTitle>Sua opinião é muito importante!</Styled.ContentTitle>
                        <Styled.Text>
                            Queremos te ouvir. Não se preocupe, <DestaqueText>sua resposta é anônima.</DestaqueText>
                        </Styled.Text>
                        <br />
                        <Styled.Text>
                            Como você classificaria sua experiência com o <BoldText>{nomeFucionalidade}</BoldText>?
                        </Styled.Text>
                        <RateFeedback onRate={value => setRate(value)} />
                        <br />
                        <Styled.Text>Algum comentário ou sugestão de melhoria?</Styled.Text>

                        <br />
                        <Form.Group>
                            <Form.Control
                                type="text"
                                as="textarea"
                                value={comment}
                                placeholder="Insira sua mensagem"
                                rows={4}
                                onChange={(evt: any) => {
                                    const value = evt.target.value;
                                    setComment(value);
                                }}
                            />
                        </Form.Group>
                        <PrimaryButton disabled={rate == 0} onClick={submitFeedback}>
                            Enviar
                        </PrimaryButton>
                    </Styled.ContentSection>
                </Styled.Container>
            </Styled.ModalBody>
        </Styled.Modal>
    );
};

export default DialogFeedback;
