import React, { useEffect, useState } from 'react';
import AdminTagChip from './AdminTagChip';
import Styled from './AdminTagSelector.styled';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminTag from 'models/admin/AdminTag';
import { remove } from 'js-cookie';
import { cloneDeep } from 'lodash';

export interface IAdminTagSelector {
    tags: string[];
    onSelected: (tags: string[]) => void;
}

const service = new AdminBiblioService();

const AdminTagSelector = ({ tags, onSelected }: IAdminTagSelector) => {
    const [suggests, setSuggets] = useState<AdminTag[]>([]);
    const [term, setTerm] = useState<string>('');
    const [errorInput, setErrorInput] = useState(false);

    const fetchTags = async () => {
        if (term.length >= 1) {
            const { data } = await service.buscarTags(1, term);

            const _suggest: AdminTag[] = data.rows.map((s: any) => new AdminTag().fromJSON(s));

            setSuggets(_suggest.filter(s => !tags.some(t => t == s.content)));
        } else setSuggets([]);
    };

    const onblur = () => {
        setTimeout(() => {
            setTerm('');
            setSuggets([]);
        }, 300);
    };

    const select = (suggest: string) => {
        if (tags.includes(suggest)) return;

        const newTags = [...tags, suggest];

        onSelected(newTags);

        setTerm('');
        setSuggets([]);
    };

    const removeTag = (idx: number) => {
        const clone = cloneDeep(tags);
        clone.splice(idx, 1);
        onSelected(clone);
    };

    useEffect(() => {
        setErrorInput(tags.includes(term));
        fetchTags();
    }, [term]);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.Title>Tags da publicação</Styled.Title>
            </Styled.Row>

            <Styled.Content>
                {tags.map((tag, idx) => {
                    if (typeof tag == 'string' && tag.trim().length > 0)
                        return <AdminTagChip key={idx} tag={tag} onRemove={() => removeTag(idx)} />;
                })}

                <Styled.InputTransparent
                    value={term}
                    onBlur={onblur}
                    error={errorInput}
                    onChange={(evt: any) => setTerm(evt.target.value)}
                    onKeyPress={(evt: any) => {
                        if (evt.key == 'Enter' || evt.key == 'NumpadEnter') select(evt.target.value);
                    }}
                />
            </Styled.Content>

            {suggests.length > 0 && (
                <Styled.SelectorContainer>
                    {suggests.map(s => (
                        <Styled.SelectRow key={s.id} onClick={() => select(s.content)}>
                            <Styled.SelectLabel>{s.content}</Styled.SelectLabel>
                            <Styled.SelectSubLabel>{s.quantity ?? '0'} publicações</Styled.SelectSubLabel>
                        </Styled.SelectRow>
                    ))}
                </Styled.SelectorContainer>
            )}
        </Styled.Container>
    );
};

export default AdminTagSelector;
