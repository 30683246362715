import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminPagesStyled = {
    Container: styled.div`
        margin-left: 100px;
        margin-bottom: 100px;
    `,

    Content: styled.div`
        background: white;
        padding: 20px;
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 26px;
        line-height: 42px;
        color: #183f73;
    `,

    Text: styled.p`
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #2e2e2e;
    `,

    RedirectButton: styled.div`
        width: 730px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #c9c9c9;

        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        margin: 25px 0;

        :hover {
            background-color: #f8f8f8;
        }

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #5f758b;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,
};

export default AdminPagesStyled;
