import React, { useState } from 'react';
import Styled from './AdminBiblioNew.styled';

import infoIcon from '../../../../images/info-icon.svg';
import successIcon from '../../../../images/success-icon.svg';
import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import { useHistory } from "react-router-dom";

export interface IAdminBiblioNewModalConfirm {
    show: boolean;
    onHide: () => void;
}

const AdminBiblioNewModalConfirm = ({ show, onHide }: IAdminBiblioNewModalConfirm) => {
    const [isSaved, setIsSaved] = useState(true);

    const onConfirm = () => {
        //SAVE
        setIsSaved(true);
    };

    const history = useHistory();

    const goToPost = () => {
      history.push('/admin/biblioteca/posts');
    };

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            <Styled.Container style={{ padding: '20px' }}>
                <Styled.Center>
                    <Styled.IconSave src={isSaved ? successIcon : infoIcon} />
                    <br />
                    <Styled.TextModal>{isSaved ? 'O conteúdo foi salvo!' : 'Tem certeza que deseja publicar?'}</Styled.TextModal>
                </Styled.Center>

                <br />
                <br />

                {!isSaved ? (
                    <Styled.ContainerBottom>
                        <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                        <GradientButton onClick={onConfirm}>Publicar</GradientButton>
                    </Styled.ContainerBottom>
                ) : (
                    <Styled.ContainerBottom style={{ textAlign: 'center' }}>
                        <GradientButton onClick={goToPost}>Fechar</GradientButton>
                    </Styled.ContainerBottom>
                )}
            </Styled.Container>
        </Styled.Modal>
    );
};

export default AdminBiblioNewModalConfirm;
