export const resizeImage = (base64Str: string, maxWidth = 400, maxHeight = 350) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL());
            } else {
                reject();
            }
        };
    });
};

export const generateImageFromVideo = (videoElemt: HTMLVideoElement | null): string => {
    if (videoElemt) {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let [w, h] = [videoElemt.videoWidth, videoElemt.videoHeight];
        canvas.width = w;
        canvas.height = h;

        if (context) {
            context.drawImage(videoElemt, 0, 0, w, h);
            let src = canvas.toDataURL();

            /*let base64Length = src.length - (src.indexOf(",") + 1);
       let padding =
        src.charAt(src.length - 2) === "="
          ? 2
          : src.charAt(src.length - 1) === "="
          ? 1
          : 0;
      let fileSize = base64Length * 0.75 - padding; */

            return src;
        }
    }

    return '';
};
