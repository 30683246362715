import { YellowButton } from 'components/UI/Buttons';
import { ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledCardConteudoFavorito = {
    Container: styled.div`
        width: 47%;
        min-height: 80px;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: ${Colors.Blue};
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: ${ResizeMediumWidth}px) {
            width: 100%;
        }
    `,

    ButtonTransparent: styled.button`
        background-color: transparent;
        color: white;
        border: none;
        margin-left: 10px;
        margin-right: 15px;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
            border: none !important;
        }

        &::selection {
            background-color: transparent;
            border: none !important;
        }
    `,

    ButtonAccess: styled(YellowButton)`
        order: 0;
        width: 110px;
        position: relative;
        margin-right: 5%;
    `,

    Divisor: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-word;
        padding: 10px;
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        max-width: 450px;
    `,
};

export default StyledCardConteudoFavorito;
