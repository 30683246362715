import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled, { CSSProperties, CSSPseudos } from 'styled-components';

const BannerInfoStyle = {
    Container: styled.div<{ isExpanded?: boolean }>`
        height: ${props => (props.isExpanded ? '234px' : '70px')};
        max-width: 1253;
        border-radius: 6px;
        overflow-y: hidden;
        background-color: ${Colors.SoftBlue};
        padding: 20px 32px;
        margin-bottom: 20px;

        @media only screen and (max-width: 1024px) {
            height: ${props => (props.isExpanded ? '100%' : '105px')};

            margin: 0px 7px 16px 7px;
            box-flex: 1;
            flex-grow: 1;
        }

        @media only screen and (min-width: 450px) {
            height: ${props => (props.isExpanded ? '100%' : '70px')};
        }

        @media only screen and (max-width: 449px) {
            height: ${props => (props.isExpanded ? '100%' : '70px')};
            div > h2 {
                font-size: 20px;
            }
            padding: 20px 18px;
        }

        @media only screen and (max-width: 361px) {
            height: ${props => (props.isExpanded ? '100%' : '105px')};
            padding: 20px 32px;
            div > h2 {
                font-size: 20px;
            }
        }
    `,

    HeadContainer: styled.div`
        display: flex;
        justify-content: space-between;
        user-select: none;
    `,

    CloseIcon: styled.img`
        width: 25px;
        cursor: pointer;
    `,

    Content: styled.div`
        display: flex;
        align-items: left;
        flex-direction: column;
        padding: 0px 25px 10px 0px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
            padding: 0px 35px 10px 0px;
        }
    `,

    Text: styled.p`
        color: white;
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        padding: 20px 0;
    `,

    ButtonContainer: styled.div`
        width: fit-content;
        padding: 0px;
        height: fit-content;
    `,

    ButtonSbm: styled.button`
        width: 230px;
        height: 42px;
        font-weight: bold;
        border-radius: 50px;
        box-sizing: border-box;
        border: 1px solid white;
        background-color: white;
        color: #00a8e9;

        :hover {
            background-color: #00a8e9;
            color: white;
        }
    `,
};

/*export const styleButton: CSSProperties = {
    backgroundColor: 'white',
    color: Colors.SoftBlue,
    width: '230px',
};*/

export default BannerInfoStyle;
