import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { jsonProperty, Serializable } from 'ts-serializable';

export class SecaoImagem extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    corTitulo: string = '';

    @jsonProperty(String)
    tamanhoTitulo: string = '';

    @jsonProperty(Boolean)
    ocultarTitulo: Boolean = false;

    @jsonProperty(String)
    texto: string = '';

    @jsonProperty(String)
    corTexto: string = '';

    @jsonProperty(String)
    tamanhoTexto: string = '';

    @jsonProperty(Boolean)
    ocultarTexto: Boolean = false;

    @jsonProperty(String)
    corSecao: string = '';

    @jsonProperty(String)
    estiloBotao: ButtonsCustom = ButtonsCustom.Button_01;

    @jsonProperty(Boolean)
    ocultarBotao: Boolean = false;

    @jsonProperty(String)
    linkBotao: string = '';

    @jsonProperty(String)
    textoBotao: string = '';

    @jsonProperty(String, null)
    orientacao: string | null = null;

    @jsonProperty(String)
    usuarioResponsavel: string = '';

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(String)
    filePath: string = '';
}

export default SecaoImagem;
