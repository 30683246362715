import { GenericService } from '../Generic.service';

export default class BiblioMediaNotaService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarAvaliacaoMediaNota(page: number, term?: string) {
        const por_pagina = 20;
        return this.get('avaliacoesMedia', { por_pagina: por_pagina, pagina: page, filtro: term });
    }
}
