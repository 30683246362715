import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

interface IHomePageHeaderContainer {}
const HomePageHeaderContainer = styled.div(({ ...props }: React.PropsWithChildren<IHomePageHeaderContainer>) =>
    new FullCss(
        {
            background: `${Colors.White}`,
            display: 'flex',
            gap: '47px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'column',
        })
    ).getCss()
);

interface IHomePageHeader {}

const HomePageHeader: FunctionComponent<IHomePageHeader> = ({ children, ...props }: React.PropsWithChildren<IHomePageHeader>) => {
    return <HomePageHeaderContainer>{children}</HomePageHeaderContainer>;
};

export default HomePageHeader;
