import React, { useEffect, useState } from 'react';
import Styled from './BiblioRates.styled';

import starEnableIcon from '../../../../images/start-enable.svg';
import starDisableIcon from '../../../../images/start-disable.svg';

export interface IBiblioRateStarsSelector {
    onSelect: (value: number) => void;
    prerate: number;
}

interface IStar {
    enabled: boolean;
    onClick: () => void;
}

const Star = ({ enabled, onClick }: IStar) => {
    return <Styled.Modal.ImageStar onClick={onClick} src={enabled ? starEnableIcon : starDisableIcon} />;
};

const BiblioRateStarsSelector = ({ onSelect, prerate }: IBiblioRateStarsSelector) => {
    const MAX = 5;
    const [value, setValue] = useState(prerate);

    const changeValue = (newValue: number) => {
        setValue(newValue);
        onSelect(newValue);
    };

    return (
        <Styled.Container>
            {[...Array(MAX)].map((item, i) => {
                return <Star key={i} enabled={value >= i + 1} onClick={() => changeValue(i + 1)} />;
            })}
        </Styled.Container>
    );
};

export default BiblioRateStarsSelector;
