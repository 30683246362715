import React, { useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton } from 'components/UI/Buttons';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import submitAttach from './submitAttach';
import { isStringsValid } from '../newContent/isValidNewContentInput';
import defaultContentHome from 'data/defaultContentHome';
import { Form } from 'react-bootstrap';

export interface IAdminEditCover {
    onSaved: () => void;
}

const AdminEditCover = ({ onSaved }: IAdminEditCover) => {
    const [cover, setCover] = useState<File>();
    const [preCover, setPreCover] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [texto_botao_b, setTextoBotaoB] = useState('');
    const [link_interno, setLinkInterno] = useState('');
    const [paginaLateral, setPaginaLateral] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [thisId, setThisId] = useState<number>(0);

    const MAX_CHAR_CONTENT = 1000;

    const isValidInputs = (): boolean => {
        if (!cover && !preCover) return false;
        return isStringsValid([title, text, texto_botao_a, texto_botao_b, link_interno]);
    };

    const submit = async () => {
        setSubmitting(true);
        const service = new AdminHomeConteudoService();

        let data, status: number;

        if (thisId <= 0) {
            const response = await service.submeterCapa(title, text, texto_botao_a, texto_botao_b, link_interno, paginaLateral);
            data = response.data;
            status = response.status;
        } else {
            const response = await service.atualizarCapa(thisId, title, text, texto_botao_a, texto_botao_b, link_interno, paginaLateral);
            data = response.data;
            status = response.status;
        }

        if (status == 200) {
            setThisId(data.id);
            if (cover) await submitAttach(data.id, cover);
            onSaved();
        }

        setSubmitting(false);
    };

    const fetchItem = async () => {
        const service = new AdminHomeConteudoService();
        const { data } = await service.buscaConteudo('CAPA');

        const result = new AdminHomeConteudo().fromJSON(data[0]);

        if (result.id != -1) {
            setTitle(result.title);
            setText(result.content);
            setTextoBotaoA(result.texto_botao_a);
            setTextoBotaoB(result.texto_botao_b);
            setPaginaLateral(result.paginaLateral);
            setLinkInterno(result.linkInterno);
            setThisId(result.id);
            fetchImage(result.id);
        } else {
            const dft = defaultContentHome;
            setPreCover(dft.cover.image);
            setTitle(dft.cover.title);
            setText(dft.cover.text);
            setTextoBotaoA(dft.cover.texto_botao_a);
            setTextoBotaoB(dft.cover.texto_botao_b);
            setLinkInterno(dft.cover.link_interno);
            setPaginaLateral(dft.cover.paginaLateral);
        }
    };

    const fetchImage = async (contentId: number) => {
        if (contentId != -1) {
            const service = new AdminHomeConteudoService();
            const { data, status } = await service.buscarAnexoConteudo(contentId, true);
            if (status == 200 && data.base64Content) setPreCover('data:image/*;base64,' + data.base64Content);
        }
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <Styled.Container>
            <div>
                <AdminImageUploader
                    onSelect={(_, file: File) => {
                        setCover(file);
                    }}
                    size={'lg'}
                    title={'Imagem do topo'}
                    preImageB64={preCover}
                    recommendedDimension={!paginaLateral ? { w: 1524, h: 660 } : { w: 439, h: 470 }}
                />

                <Styled.Label>Layout banner</Styled.Label>
                <Styled.CkeckContainer>
                    <Form.Check
                        id="radio-banner-lateral"
                        checked={paginaLateral}
                        type="radio"
                        aria-label="radio 1"
                        onChange={() => setPaginaLateral(true)}
                    />
                    <label htmlFor={`radio-banner-lateral`} style={{ marginRight: '10px' }}>
                        Lateral
                    </label>
                    <Form.Check
                        id="radio-interno-completo"
                        checked={!paginaLateral}
                        type="radio"
                        aria-label="radio 2"
                        onChange={() => setPaginaLateral(false)}
                    />
                    <label htmlFor={`radio-interno-completo`}>Completo</label>
                </Styled.CkeckContainer>

                <Styled.Label>Título</Styled.Label>
                <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />

                <Styled.Label>Texto</Styled.Label>
                <Styled.Input
                    value={text}
                    onChange={(evn: any) => setText(evn.target.value)}
                    rows={8}
                    as="textarea"
                    className={'form-control'}
                    maxLength={MAX_CHAR_CONTENT}
                />
                <Styled.CountLetter danger={text.length >= MAX_CHAR_CONTENT}>
                    {text.length}/{MAX_CHAR_CONTENT}
                </Styled.CountLetter>

                <Styled.Label>Botão primário</Styled.Label>
                <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />

                <Styled.Label>Botão secundário</Styled.Label>
                <Styled.Input value={texto_botao_b} onChange={(evt: any) => setTextoBotaoB(evt.target.value)} />

                <Styled.Label>Link Interno botão secundário</Styled.Label>
                <Styled.Input value={link_interno} onChange={(evt: any) => setLinkInterno(evt.target.value)} />
            </div>

            <Styled.RigthArea>
                <GradientButton disabled={submitting || !isValidInputs()} onClick={submit}>
                    {submitting ? 'Salvando...' : 'Salvar'}
                </GradientButton>
            </Styled.RigthArea>
        </Styled.Container>
    );
};

export default AdminEditCover;
