import React, { useContext, useEffect, useState } from 'react';
import { IAdminEditSection } from './AdminEditBanner';
import Loading from 'components/Loading';
import Styled from './AdminEditor.styled';
import AdminSecaoPaginaData, {
    AdminSecaoPaginaCardItem,
    AdminSecaoPaginaItemCustom,
    AdminSecaoPaginaItensCustom,
} from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { GradientButton, OutlineButton, RedSolidButton } from 'components/UI/Buttons';
import { cloneDeep } from 'lodash';
import AdminItemSection from './AdminItemSection';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import SecaoItemCustomizado from 'models/generic-components/SecaoItemCustomizado';
import { useParams } from 'react-router-dom';
import { CardsItemCustomizado } from 'models/generic-components/CardsItemCustomizado';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';

const initialContent = new AdminSecaoPaginaItemCustom('', '#000000', 12, false, '', '#000000', 12, false, false);

const AdminEditItensCustom = ({ onSaved, onUpdate, onDelete, mode }: IAdminEditSection) => {
    const [corSecao, setCorSecao] = useState('#FFFFFF');
    const service = new AdminGestaoPaginaService();
    const [randomKey, setRandomKey] = useState(0);
    const [itens, setItens] = useState<AdminSecaoPaginaItemCustom[]>([]);
    const { currentSection, selectSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);
    const [loading, setLoading] = useState(false);
    const [idSecao, setIdSecao] = useState(-1);
    const [submitting, setSubmitting] = useState(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const params: any = useParams();

    const addNewItem = () => {
        const newItem = cloneDeep(initialContent);
        setItens([...itens, newItem]);
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setLoading(true);

            const id = String(params.id);
            const { data } = await service.buscarCartoesCustomizados(parseInt(id));
            const secao = new SecaoItemCustomizado().fromJSON(data);
            setIdSecao(parseInt(id));
            setCorSecao(secao.corSecao);
            setModePage(true);

            const { data: datacards } = await service.buscarCartoesCustom(parseInt(id));
            const _cardsraw = Array.from(datacards).map((c: any) => new CardsItemCustomizado().fromJSON(c));

            const _cards: AdminSecaoPaginaItemCustom[] = [];

            for (const c of _cardsraw) {
                const newCard = new AdminSecaoPaginaItemCustom(
                    c.infoDestaque,
                    c.corInfoDestaque,
                    parseInt(c.tamanhoInfoDestaque ?? '12'),
                    c.ocultarInfoDestaque,
                    c.detalhamento,
                    c.corDetalhamento,
                    parseInt(c.tamanhoDetalhamento ?? '12'),
                    c.ocultarDetalhamento,
                    c.ocultarAnexo,
                    c.anexoBase64,
                    c.oculto
                );
                newCard.setCodigo(c.codigo);
                _cards.push(newCard);
            }

            setItens(_cards);

            setLoading(false);
        }
    };

    const submitSection = async () => {
        if (!currentSection) {
            alert('Erro ao enviar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaItensCustom;
        holder.corSecao = corSecao;

        currentSection.modelData = holder;

        if (!currentSection.isPersist) await saveSection(currentSection, holder);
        else await updateSection(currentSection);

        setSubmitting(false);
    };

    const updateSection = async (section: AdminSecaoPaginaData) => {
        if (!section) {
            ToastError('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarItemCustom(section.codigo, corSecao, section.ordem);

        if (status < 300) {
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaItensCustom) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.secaoItemCustomizado.codigo);
            selectSection(section);
            setModePage(true);
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteItemCustom(idSecao);

            if (response.status === 200) {
                ToastSuccess('Item excluído com sucesso!');
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteSecao(false);
        setIsLoadingDelete(false);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <ModalExclusao
                showModal={showModalDeleteSecao}
                handleHiden={setShowModalDeleteSecao}
                handleExcluxed={handleDelete}
                isLoading={isLoadingDelete}
            />
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Styled.HeadFormContainer>
                        <Styled.Label>Cor de Fundo</Styled.Label>
                        <Styled.ColorPicker type="color" value={corSecao} onChange={e => setCorSecao(e.target.value)} />
                        <Styled.TextAlert>
                            {' '}
                            <strong>⚠️ Alerta: Número de Itens Mínimo e Máximo Necessários</strong> <br />
                            <br />
                            Por favor, note que para exibir o componente, você precisará criar no mínimo <strong>dois itens</strong> . O máximo
                            que o sistema suporta é de <strong>cinco itens</strong> . Certifique-se de adicionar a quantidade adequada de itens
                            para garantir o funcionamento correto do componente.
                        </Styled.TextAlert>
                        <Styled.RightContainer>
                            {modePage && (
                                <RedSolidButton style={{ marginRight: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                    Deletar
                                </RedSolidButton>
                            )}
                            <GradientButton onClick={submitSection} disabled={submitting}>
                                {submitting ? 'Salvando' : 'Salvar'} Seção
                            </GradientButton>
                        </Styled.RightContainer>
                    </Styled.HeadFormContainer>
                    {currentSection?.isPersist && (
                        <>
                            <div>
                                <Styled.Title style={{ marginTop: '30px' }}>Itens</Styled.Title>
                            </div>
                            <div style={{ width: '100%' }}>
                                <AdminItemSection fetchItem={fetchItem} currentSection={currentSection} itens={itens} setItens={setItens} />
                            </div>

                            {itens.length < 5 && (
                                <OutlineButton style={{ marginTop: '25px', marginBottom: '25px' }} onClick={addNewItem}>
                                    <span style={{ fontSize: '0.95rem' }}>+ Adicionar novo item</span>
                                </OutlineButton>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default AdminEditItensCustom;
