import React, { FunctionComponent, useContext, useState } from 'react';

import { TiEdit } from 'react-icons/ti';

import Cards from './card/Cards';
import Loading from 'components/Loading';
import SearchCard from './search/SearchCard';
import SwipeList from 'components/swipe/SwipeList';
import { DiaryCardStyle } from './DiaryCard.styled';
import { GradientButton } from 'components/UI/Buttons';
import { DiarioBordoContext } from '../../context/DiarioContext';
import { Question } from 'components/popupQuestion/QuestionEnum';
import PopupExcluded from 'components/popupExcluded/PopupExcluded';
import DiaryAlert from '../textEditor/DiaryAlert';

interface IDiaryCards {}

const DiarioCards: FunctionComponent<IDiaryCards> = (_: React.PropsWithChildren<IDiaryCards>) => {
    const [showExcluded, setShowExcluded] = useState<boolean>(false);

    const { onCreate, showRegisterConfirmation, onDeleteConfirm, onCancel, isLoadingDiary } = useContext(DiarioBordoContext.Context);

    const question = 'Não será possível reverter essa ação. Você deseja deletar esse registro? ';

    const onDelete = async () => {
        await onDeleteConfirm();
        setShowExcluded(true);
    };

    return (
        <DiaryCardStyle.Container>
            {isLoadingDiary ? (
                <Loading />
            ) : (
                <>
                    <DiaryAlert visible={showRegisterConfirmation} hide={onCancel} text={question} textButton={'Excluir'} toAction={onDelete} />

                    <PopupExcluded
                        visible={showExcluded}
                        onClose={() => {
                            setShowExcluded(false);
                        }}
                    />

                    <DiaryCardStyle.Title>Meus registros</DiaryCardStyle.Title>

                    <Cards />
                </>
            )}
        </DiaryCardStyle.Container>
    );
};

export default DiarioCards;
