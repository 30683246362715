import styled, { keyframes } from "styled-components";
import Colors from "../../../../../enums/Colors";
import { MobileMaxWidth } from "../../../../../constants/Dimensions";
import FormikReactSelect from "../../formik/FormikReactSelect";

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 43px;
`;

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FormControl = styled.div`
  display: flex;
  animation: ${fade} 0.3s linear;
  margin-bottom: 10px;
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 41px;
  margin-bottom: 41px;
  @media only screen and (max-width: ${MobileMaxWidth}px) {
    display: flex;
    justify-content: center;
  }
`;

export const InlineFormikContainer = styled.div`
  margin-left: 10px;
`;

export const InteressesContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
`;
