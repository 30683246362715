import { Serializable, jsonName, jsonProperty } from 'ts-serializable';

class Email extends Serializable {
    @jsonProperty(String)
    userId: string = '';

    @jsonProperty(String)
    username: string = '';

    @jsonProperty(String)
    emailUser: string = '';

    @jsonProperty(String)
    emailTo: string = '';

    @jsonProperty(String, null)
    pageId?: string = '';

    @jsonProperty(String, null)
    emailFrom?: string = '';

    @jsonProperty(String)
    subject: string = '';

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(Boolean)
    isRealizandoEscolha: boolean = false;

    @jsonProperty(String, null)
    sendDateEmail?: string = '';

    constructor() {
        super();
    }
}

export default Email;
