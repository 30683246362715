import styled from 'styled-components';

const StyleSecaoNewSimplesWithImage = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        padding: ${({ isMobile }) => (isMobile ? '32px' : '40px 78px')};
    `,

    Content: styled.div`
        display: flex;
    `,

    Column: styled.div`
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;

        @media screen and (min-width: 1448px) {
            width: 50%;
        }
    `,

    TextDestaque: styled.p`
        color: #003c78;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.5%; /* 22.23px */
        letter-spacing: 0.25px;
        font-family: Barlow;
    `,

    Img: styled.img<{width?: string}>`
        width: ${({width}) => width && width};
        height: auto;
        margin-left: 25px;

        @media screen and (max-width: 760px) {
            margin-left: 0px;
            
        }


    `,
};

export default StyleSecaoNewSimplesWithImage;
