import React, { useEffect, useState } from 'react';

import BiblioCommentComponent from './Comment';
import Styled from './BibioCommentSection.styled';
import { BlueSolidButton } from 'components/UI/Buttons';
import BiblioComentarioService from 'core/http/service/biblioteca/BiblioComentario.service';
import BiblioItemComment from 'models/biblioteca/BiblioItemComment';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import useLogged from 'hooks/useLogged';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import { ToastError } from 'components/Toaster';
import PlusCircle from './assets/plusCircle.svg';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import Colors from 'enums/Colors';
import { fetchPalavrasProibidas, validatePalavraProibida } from 'helpers/Functions';

export interface IBibioCommentSection {
    item_id: number;
}

const COMMENT_LASTSESSION_KEY = 'COMMENT_LASTSESSION';

const BibioCommentSection = ({ item_id }: IBibioCommentSection) => {
    const [commentInput, setCommentInput] = useState<string>('');
    const [comments, setComments] = useState<BiblioItemComment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [palavrasProibidas, setPalavrasProibidas] = useState<string[]>();
    const [isPalavrasProibidas, setIsPalavrasProibidas] = useState<boolean>(false);
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const history = useHistory();
    const { login } = useLogged();
    const [isDropped, setDropped] = useState<boolean>(false);

    const isValidComment = (): boolean => {
        return commentInput.trim().length > 2;
    };


    const fetchPP = async () => {
        setPalavrasProibidas(await fetchPalavrasProibidas());
    };


    const validatePalavras = () => {
        setIsPalavrasProibidas(validatePalavraProibida(palavrasProibidas ?? [""], commentInput));
    };

    useEffect(() => {
        validatePalavras();
    }, [commentInput]);

    const fetchComments = async () => {
        setComments([]);
        const service = new BiblioComentarioService();
        const response = await service.buscarComentarios(item_id);
        const data = response.data;
        const _comments = Array.from(data).map((comment: any) => new BiblioItemComment().fromJSON(comment));
        setComments(_comments);
    };

    const sendComment = async (content?: string) => {
        setLoading(true);
        const service = new BiblioComentarioService();
        await service.enviarComentario(item_id, commentInput || content || '');

        if (location.pathname.includes('comment')) {
            history.replace(location.pathname.split('&')[0]);
        }

        await fetchComments();
        setCommentInput('');
        setLoading(false);
    };

    const toSendComment = async () => {
        if (keycloak?.authenticated) sendComment();
        else {
            login(location.pathname, `comment=${commentInput.replace(/\s/g, '___')}`);
        }
    };

    const checkLastSession = () => {
        const value = location.pathname.split('comment=')[1];

        if (value) {
            sendComment(value.replace(/___/g, ' '));
        }
    };

    useEffect(() => {
        checkLastSession();
        fetchComments();
        fetchPP();
    }, []);

    return (
        <Styled.Container>
            <Styled.Label>Comentários</Styled.Label>
            <br />
            <Styled.CommentBox isDropped={isDropped}>
                {comments.map((comment: BiblioItemComment) => (
                    <BiblioCommentComponent
                        key={comment.id}
                        comment={comment}
                        answers={comment.answers}
                        onExclude={() => fetchComments()}
                        onEdited={() => fetchComments()}
                    />
                ))}
                {(comments.length > 2 || (comments.length > 1 && (comments[0].comment.length > 0 || comments[1].comment.length > 0))) &&
                !isDropped ? (
                    <Styled.SeeMoreButton
                        isDropped={isDropped}
                        onClick={() => {
                            setDropped(!isDropped);
                        }}
                    >
                        <Styled.Icon src={PlusCircle} />
                        {'Ler mais'}
                    </Styled.SeeMoreButton>
                ) : null}
            </Styled.CommentBox>

            <Styled.Label>Deixe seu comentário</Styled.Label>
            {isPalavrasProibidas && (
                <p style={{ color: Colors.InfoRed, marginTop: '10px' }}>
                    Para publicar seu comentário, remova todas as palavras proibidas que estão presentes nele.
                </p>
            )}

            <br />
            <br />
            <Styled.FormControl
                as="textarea"
                rows={3}
                onChange={evt => {
                    const value = evt.target.value;
                    if (value.length >= 300) ToastError('Comentário muito longo');
                    else setCommentInput(value);
                }}
                maxLength={300}
                value={commentInput}
            />
            <Styled.AlignRight>
                <Styled.SendComentary
                    onClick={() => {
                        GTM_Page_Biblioteca(`${item_id}`, `send_comment_${item_id}`, 'send_comment_btn');
                        toSendComment();
                    }}
                    disabled={(!isValidComment() && !loading) || isPalavrasProibidas}
                >
                    {loading ? 'Publicando' : 'Publicar'}
                </Styled.SendComentary>
            </Styled.AlignRight>
            <br />
            <br />
        </Styled.Container>
    );
};

export default BibioCommentSection;
