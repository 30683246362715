import React from 'react';
import { Route } from 'react-router-dom';
import EbookProvider from './context/EbookProvider';
import AlfaTeoriaPratica from './AlfaTeoriaPratica';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const EbooksRoute = () => {
    return (
        <EbookProvider>
            <DynamicHeader />

            <Route exact path={'/ebooks/alfabetizacao-integral-da-teoria-a-pratica-az'} component={AlfaTeoriaPratica} />
        </EbookProvider>
    );
};

export default EbooksRoute;
