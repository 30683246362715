import { jsonProperty, Serializable } from 'ts-serializable';

export enum PaginaIntermediariaTipoEnum {
    UNKNOW = -1,
    BLANK = 1,
    CURSOLIVRE = 2,
    NOVOSRECURSO = 3 
}

class PaginaIntermediariaTipo extends Serializable {
    @jsonProperty(Number)
    codigo: PaginaIntermediariaTipoEnum = PaginaIntermediariaTipoEnum.UNKNOW;

    @jsonProperty(String)
    descricao: string = '';
}

export default PaginaIntermediariaTipo;
