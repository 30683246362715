import React, { useCallback, useEffect, useState } from 'react';
import { SelectOptionType } from 'components/Select';
import Styled from './AdminBiblioNewContent.styled';
import AdminBiblioNewArtigo from 'pages/admin/components/newContent/AdminBiblioNewArtigo';
import AdminBiblioNewAudio from 'pages/admin/components/newContent/AdminBiblioNewAudio';
import AdminBiblioNewAtividade from 'pages/admin/components/newContent/AdminBiblioNewAtividade';
import AdminBiblioNewLivro from 'pages/admin/components/newContent/AdminBiblioNewLivro';
import AdminBiblioNewVideo from 'pages/admin/components/newContent/AdminBiblioNewVideo';
import AdminBiblioNewModalConfirm from 'pages/admin/components/newContent/AdminBiblioNewModalConfirm';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import { useParams } from 'react-router-dom';
import BiblioItem from 'models/biblioteca/BiblioItem';
import BiblioItemCategoriaModel from 'models/biblioteca/BiblioItemCategoriaModel';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import Loading from 'components/Loading';

export interface IAdminBiblioEditContent {}

const categories = ['Artigos', 'Guias Temáticos', 'Livros', 'Estudo', 'Vídeos']; /*  'Áudios', 'Atividades', Foi removido */

const AdminBiblioEditContent = ({}: IAdminBiblioEditContent) => {
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [showModal, setShowModal] = useState(false);
    const [itemData, setItemData] = useState<BiblioItem | null>(null);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState<BiblioItemCategoriaModel[]>([]);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<BiblioItemCategoriaModel | null>(null);

    const params: any = useParams();

    const onSaved = () => setShowModal(true);

    const fetchItem = async (_categorias: BiblioItemCategoriaModel[]) => {
        setLoading(true);

        const service = new AdminBiblioService();
        const { data } = await service.buscarItem(params.id);

        if (data) {
            const _itemData = new BiblioItem().fromJSON(data);
            setItemData(_itemData);

            const categoria = _categorias.find(c => c.descricao == _itemData.category);

            if (categoria) {
                setCategoriaSelecionada(categoria);
            }
        }

        setLoading(false);
    };

    const fetchCategorias = async () => {
        const service = new BiblioConteudoService();
        const { data } = await service.buscarCategorias(true);
        const _categorias = Array.from(data).map((c: any) => new BiblioItemCategoriaModel().fromJSON(c));
        setCategorias(_categorias.filter(c => c.ativo).sort((c1, c2) => c1.ordem - c2.ordem));

        await fetchItem(_categorias);
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    const switchContent = useCallback(() => {
        if (categoriaSelecionada?.comportamento == 'artigo')
            return <AdminBiblioNewArtigo onSaved={onSaved} categoriaId={categoriaSelecionada.id} itemData={itemData} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'atividade')
            return (
                <AdminBiblioNewAtividade onSaved={onSaved} categoriaId={categoriaSelecionada.id} itemData={itemData} categorias={categorias} />
            );
        if (categoriaSelecionada?.comportamento == 'audio')
            return <AdminBiblioNewAudio onSaved={onSaved} categoriaId={categoriaSelecionada.id} itemData={itemData} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'livro')
            return <AdminBiblioNewLivro onSaved={onSaved} categoriaId={categoriaSelecionada.id} itemData={itemData} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'video')
            return <AdminBiblioNewVideo onSaved={onSaved} categoriaId={categoriaSelecionada.id} itemData={itemData} categorias={categorias} />;
    }, [categoriaSelecionada, categorias]);

    return (
        <Styled.Container>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {itemData && switchContent()}
                    <AdminBiblioNewModalConfirm show={showModal} onHide={() => setShowModal(false)} />
                </>
            )}
        </Styled.Container>
    );
};

export default AdminBiblioEditContent;
