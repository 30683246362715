import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { H1 } from '../../components/UI/Texts';
// import DiarioBordoDialog from './DiarioBordoDialog';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { DiarioBordoStyle } from './DiarioBordo.styled';
import DiarioInfo from './components/moreInfo/DiaryInfo';
import DiarioText from './components/textEditor/DiaryText';
import DiarioCards from './components/diariesCards/DiaryCards';
import { DiarioBordoContext } from './context/DiarioContext';
import { showDialogFeedback } from './utils/showDialogFeedback';
import DialogFeedback from 'components/feedbackDialog/DialogFeedback';
import { TipoFuncionalidade } from 'models/Feedback';
import { FiHome } from 'react-icons/fi';
import { DiaryCardStyle } from './components/diariesCards/DiaryCard.styled';
import Card from './components/diariesCards/card/Card';
import DiaryText from './components/textEditor/DiaryText';
import { IoArrowBackOutline } from 'react-icons/io5';
import DiaryAlert from './components/textEditor/DiaryAlert';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';

interface IDiarioBordo {}

const DiarioBordoMobile: FunctionComponent<IDiarioBordo> = (_: React.PropsWithChildren<IDiarioBordo>) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(true);
    const [isShowDialogFeedback, setIsShowDialogFeedback] = useState<boolean>(false);

    // const [showModalExclude, setShowModalExclude] = useState(false);

    const { current, items, onCancel, onCreate, onDeleteConfirm, onDelete, setOnEditting, onEditting, showRegisterConfirmation } = useContext(
        DiarioBordoContext.Context
    );

    const [isSelected, setIsSelected] = useState<boolean>(false);

    const profile = useAppSelector(state => state.profileReducer.userProfile);

    const questionExclude = 'Não será possível reverter essa ação. Você deseja deletar esse registro? ';

    const onCreateHandler = async () => {
        await onCreate();
        setIsSelected(true);
        setOnEditting(true);
    };

    const onExcludeHandler = async (data: DiarioBordo) => {
        await onDelete(data);
        setIsSelected(false);
    };

    const checkFeedback = async () => {
        if (!isModalOpen) {
            const _isShowDialogFeedback = await showDialogFeedback(items, profile);
            setIsShowDialogFeedback(_isShowDialogFeedback);
        }
    };

    const toClose = () => {
        setIsSelected(false);
        setOnEditting(false);
    };

    const toEdit = () => {
        setOnEditting(true);
        setIsSelected(true);
    };

    useEffect(() => {
        checkFeedback();
    }, [isModalOpen]);

    return (
        <>
            {/* <PopupQuestion onButton={onButtonHandler} okButtonText={'Excluir'} question={question} visible={showRegisterConfirmation} /> */}

            {/* <PopupExcluded
                visible={showExcluded}
                onClose={() => {
                    setShowExcluded(false);
                }}
            /> */}

            <div style={{ transform: 'translateY(-25px)' }}>
                <DiarioBordoStyle.TopBar>
                    <span style={{ marginRight: '7px', transform: 'translateY(-2px)' }}>
                        <FiHome />
                    </span>
                    Jornada Socioemocional de Professores
                </DiarioBordoStyle.TopBar>

                <DiarioBordoStyle.Container>
                    <br />
                    <DiarioBordoStyle.Row>
                        <DiarioBordoStyle.Title>
                            {isSelected && (
                                <span onClick={toClose} style={{ marginRight: '10px' }}>
                                    <IoArrowBackOutline />
                                </span>
                            )}
                            Diário de bordo
                        </DiarioBordoStyle.Title>

                        {!isSelected && (
                            <DiarioBordoStyle.NewDiaryButton onClick={onCreateHandler}>Novo registro</DiarioBordoStyle.NewDiaryButton>
                        )}
                    </DiarioBordoStyle.Row>
                    <br />
                    {!isSelected ? (
                        <>
                            <DiarioBordoStyle.Box>
                                <DiarioBordoStyle.SubTitle>Meus registros</DiarioBordoStyle.SubTitle>
                                {items.map(item => {
                                    return (
                                        <Card
                                            data={item}
                                            key={item.id}
                                            toDelete={data => onExcludeHandler(data)}
                                            toEdit={toEdit}
                                            toOpen={() => setIsSelected(true)}
                                        />
                                    );
                                })}
                            </DiarioBordoStyle.Box>
                        </>
                    ) : (
                        <DiaryText onDeleted={() => setIsSelected(false)} />
                    )}
                </DiarioBordoStyle.Container>
            </div>
            <DiaryAlert
                textButton="Deletar"
                toAction={onDeleteConfirm}
                visible={showRegisterConfirmation}
                text={questionExclude}
                hide={onCancel}
            />
        </>
    );
};

export default DiarioBordoMobile;
