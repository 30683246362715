import Axios from 'axios';
import { RoleEnum } from 'enums/RoleEnum';

export const getUserRole = (roles: string[]): RoleEnum => {
    if (roles.includes(RoleEnum.GestorRede)) {
        return RoleEnum.GestorRede;
    } else if (roles.includes(RoleEnum.GestorEscolar)) {
        return RoleEnum.GestorEscolar;
    } else if (roles.includes(RoleEnum.Professor)) {
        return RoleEnum.Professor;
    } else if (roles.includes(RoleEnum.ColaboradorParceria)) {
        return RoleEnum.ColaboradorParceria;
    } else if (roles.includes(RoleEnum.CoordenadorRegional)) {
        return RoleEnum.CoordenadorRegional;
    } else if (roles.includes(RoleEnum.Pesquisador)) {
        return RoleEnum.Pesquisador;
    } else if (roles.includes(RoleEnum.CoordenadorNaoParceiro)) {
        return RoleEnum.CoordenadorNaoParceiro;
    } else if (roles.includes(RoleEnum.TecnicoEducacao)) {
        return RoleEnum.TecnicoEducacao;
    } else if (roles.includes(RoleEnum.Diretor)) {
        return RoleEnum.Diretor;
    } else {
        return RoleEnum.Unknown;
    }
};

export const loginWithRole = async (role: RoleEnum, userIdKeycloak: string, token: string): Promise<any> => {
    let url = 'https://devsistema.socioemocionaisbncc.org.br/api/v1/';

    if (role === RoleEnum.Professor) url += 'teachers';
    else if (role === RoleEnum.GestorRede) url += 'network-managers';
    else if (role === RoleEnum.GestorEscolar) url += 'schoolmanagers';
    else if (role === RoleEnum.CoordenadorRegional) url += 'regional-coordinators';
    else if (role === RoleEnum.Pesquisador) url += 'researchers';
    //else if (role === RoleEnum.ColaboradorParceria) url += '';

    const result = await Axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            userKeycloakId: userIdKeycloak,
        },
    });

    if (result.status === 200) return result.data;
    else return null;
};
