import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import RelatedContent from 'components/RelatedContent';
import { bucketS3, caminhoAnexosPaginasIntermediariasConteudosRelacionados, isDevelopment, lmsIntegrationUrl } from 'constants/EnvironmentData';
import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import arrowYellow from '../../../../images/homepage/arrow-yellow.svg';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Slider from 'react-slick';

import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';

import {
    OtherSolutionsStyle,
    TitleText,
    CardTitleText,
    MiniCardsContainer,
    MiniCard,
    Img,
    ContentContainer,
    FooterContaier,
    CardFooter,
    FooterText,
    CarouselDots,
    CarouselCustomPaging,
    MobileMiniCardWrapper,
    FooterButton,
    ScrollContainer,
    ArrowButtons,
    ScrollItem,
    Scroll,
} from './OtherSolutions.styled';

interface IOtherSolutions {
    conteudos: PgIntermediariaConteudoRelacionado[];
    tituloSecao: string;
}

const OtherSolutions = ({ conteudos, tituloSecao }: IOtherSolutions) => {
    const history = useHistory();
    const [showScroll, setShowSroll] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scrow = useRef<HTMLDivElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const scrollTo = (orientation: 'R' | 'L') => {
        const increaseScroll = 472 * 2;
        if (orientation == 'R' && scrow.current) scrow.current.scrollBy(increaseScroll, 0);
        else if (scrow.current) scrow.current.scrollBy(-1 * increaseScroll, 0);
    };

    useEffect(() => {
        if (scrollContainer.current && scrow.current) {
            const containerW = scrollContainer.current.clientWidth;
            const scrowW = scrow.current.scrollWidth;
            setShowSroll(containerW <= scrowW);
        }
    }, [scrollContainer.current, scrow.current]);

    useEffect(() => {
        if (scrow.current) {
            scrow.current.addEventListener('scroll', e => {
                setScrollLeft(scrow.current?.scrollLeft ?? 0);
            });
        }
        return () => scrow.current?.removeEventListener('scroll', () => {});
    }, [scrow.current]);

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosPaginasIntermediariasConteudosRelacionados();
    };

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };
    const mobileCarouselSettings = {
        dots: true,
        infinite: false,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // eslint-disable-next-line react/display-name
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    interface IMiniCard {
        imageUrl: any;
        title: string;
        footerAction: () => void;
    }
    const MiniCardFC: FunctionComponent<IMiniCard> = props => {
        const location = useLocation();

        return (
            <MiniCard>
                <div style={{ height: '112px' }}>
                    <Img src={props.imageUrl} />
                </div>
                <ContentContainer>
                    <CardTitleText>{props.title} </CardTitleText>
                </ContentContainer>
                <FooterContaier>
                    <FooterButton onClick={props.footerAction}>Acessar</FooterButton>
                </FooterContaier>
            </MiniCard>
        );
    };

    const footerAction = (link: string, tituloPagina: string) => {
        GTM_Page_Intermediaria('outras_solucoes_btn', 'acessar_' + tituloPagina.replace(' ', '_') + '_btn');
        RelatedContent.RedirectLinkAction(link);
    };

    return (
        <OtherSolutionsStyle>
            {conteudos.length > 0 && <TitleText>{tituloSecao}</TitleText>}
            <ScrollContainer ref={scrollContainer}>
                {showScroll && <ArrowButtons src={arrowYellow} onClick={() => scrollTo('L')} active={scrollLeft > 0} />}

                <Scroll ref={scrow}>
                    {conteudos.map((item, idx) => {
                        return (
                            <ScrollItem key={idx}>
                                <MiniCardFC
                                    imageUrl={getS3BaseUrl() + item.filePath}
                                    title={item.titulo}
                                    footerAction={() => {
                                        footerAction(item.link, item.titulo);
                                    }}
                                />
                            </ScrollItem>
                        );
                    })}
                </Scroll>

                {showScroll && (
                    <ArrowButtons
                        className="flip"
                        src={arrowYellow}
                        style={{ visibility: showScroll ? 'visible' : 'hidden' }}
                        onClick={() => scrollTo('R')}
                        active={!(scrow.current && scrow.current?.scrollLeft >= scrow.current?.scrollWidth - scrow.current?.clientWidth) ?? true}
                    />
                )}
            </ScrollContainer>
        </OtherSolutionsStyle>
    );
};

export default OtherSolutions;
