import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';
import FullDashboardJornadas from './FullDashboardJornadas';
import FullDashboardBiblioteca from './FullDashboardBiblioteca';
import FullDashboardCursos from './FullDashboardCursos';
import FullDasboardTrilhasAprendizagem from './FullDasboardTrilhasAprendizagem';

class FullDashboardDTO extends Serializable {
    @jsonProperty([FullDashboardJornadas])
    jornadas: FullDashboardJornadas[] = [];

    @jsonProperty(FullDashboardCursos)
    cursos: FullDashboardCursos = new FullDashboardCursos();

    @jsonProperty(FullDashboardBiblioteca)
    biblioteca: FullDashboardBiblioteca = new FullDashboardBiblioteca();

    @jsonProperty(FullDasboardTrilhasAprendizagem)
    trilhas: FullDasboardTrilhasAprendizagem = new FullDasboardTrilhasAprendizagem();

    constructor() {
        super();
    }
}

export default FullDashboardDTO;
