import Loading from 'components/Loading';
import Select from 'components/Select';
import { ToastError } from 'components/Toaster';
import { DangerButton, GradientButton, OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { CursoLivreService } from 'core/http/service/CursoLivre.service';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { PgIntermediariaConteudoRelacionadoService } from 'core/http/service/PgIntermediariaConteudoRelacionado.service';
import { PreferenciasService } from 'core/http/service/Preferencias.service';
import { FetchUtils } from 'core/util/FetchUtils';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { TipoConteudoInternoEnum } from 'enums/TipoConteudoInternoEnum';
import useFetch from 'hooks/useFetch';
import { cloneDeep } from 'lodash';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import ConteudoRelacionadoInternoResumido from 'models/ConteudoRelacionadoInternoResumido';
import { AdminContext } from 'pages/admin/context/AdminContext';
import React, { FunctionComponent, useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReactSwitch from 'react-switch';

import styled from 'styled-components';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { isStringsValid, isValidLink } from '../newContent/isValidNewContentInput';
import PaginaIntermediaria from '../paginaIntermediaria/PaginaIntermediaria';
import { PaginasIntermediariasEnum } from '../relatedContent/PaginasIntermediariasEnum';
import AdminEditModalDelete from './AdminEditModalDelete';
import AdminEditorStyled from './AdminEditor.styled';
import AllowedGroupsValidator from '../allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

const RelatedStyles = {
    AdminEditIntermediateRelatedContentContainer: styled.div`
        margin-top: 20px;
        margin-bottom: 70px;
    `,
    Title: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,

    ContentContainer: styled.div`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    InputGroup: styled.div`
        display: flex;
        flex-direction: column;
        gap: 7px;
    `,

    ConteudosContainer: styled.div`
        display: grid;
        grid-template-columns: 1fr 450px;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px #ddd solid;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,
    RightColumn: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    `,
};

const isMobile = () => IsMobileMaxWidth();

interface IAdminEditIntermediateRelatedContent {
    onSaved: () => void;
}

const AdminEditIntermediateRelatedContent: FunctionComponent<IAdminEditIntermediateRelatedContent> = ({
    onSaved,
}: IAdminEditIntermediateRelatedContent) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const history = useHistory();

    const paginaService = new PaginaIntermediariaService();
    const conteudosService = new PgIntermediariaConteudoRelacionadoService();
    const cursosService = new CursoLivreService();
    const biblioService = new BiblioConteudoService();

    const [paginasIntermediarias, setPaginasIntermediarias] = useState<AdminPaginaIntermediaria[]>([]);
    const [paginaSelecionada, setPaginaSelecionada] = useState<AdminPaginaIntermediaria | null>(null);
    const [tituloSecaoConteudosRelacionados, setTituloSecaoConteudosRelacionados] = useState<string>('');
    const [conteudosRelacionados, setConteudosRelacionados] = useState<PgIntermediariaConteudoRelacionado[]>([]);

    const [cursosAtivos, setCursosAtivos] = useState<ConteudoRelacionadoInternoResumido[]>([]);
    const [itensBiblioPublicados, setItensBiblioPublicados] = useState<ConteudoRelacionadoInternoResumido[]>([]);

    const MAX_CHAR_TITULO_SECAO = 50;
    const MAX_CHAR_TITLE = 200;
    const MAX_CHAR_URL = 200;

    const paginasReplaces: string[][] = [
        [PaginasIntermediariasEnum.SOCIOEMOCIONAL_PROFESSORES, 'Socioemocional Professores'],
        [PaginasIntermediariasEnum.REALIZANDO_ESCOLHAS, 'Realizando Escolhas'],
        [PaginasIntermediariasEnum.CRIATIVIDADE_PENSAMENTO_CRITICO, 'Criatividade e Pensamento Crítico'],
        [PaginasIntermediariasEnum.ALFABETIZACAO_360, 'Alfabetização 360°'],
        [PaginasIntermediariasEnum.COMPETENCIAS_SOCIOEMOCIONAIS, 'Competências Socioemocionais'],
    ];

    const loadIntermediatePages = async () => {
        const responsePagina: any = await paginaService.listar();
        let output = responsePagina.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

        let arr: AdminPaginaIntermediaria[] = [];
        output.forEach((element: AdminPaginaIntermediaria) => {
            if (
                element.pagina !== PaginasIntermediariasEnum.CURSO_LIVRE &&
                element.pagina !== PaginasIntermediariasEnum.FAQ &&
                element.pagina !== 'DYNAMIC_PAGE'
            ) {
                arr.push(element);
            }
        });

        setPaginasIntermediarias(arr);
    };

    const gotoSubroute = (sub: string) => {
        history.push(sub);
    };

    const loadRelatedContent = async () => {
        if (paginaSelecionada) {
            const responseRelatedContent: any = await conteudosService.listarPorPagina(paginaSelecionada.codigo);
            let output = responseRelatedContent.data.map((item: any) => new PgIntermediariaConteudoRelacionado().fromJSON(item));
            setConteudosRelacionados(output);
        }
    };

    const loadCoursesAndLibraryItens = async () => {
        const responseCourses: any = await cursosService.listarAtivosResumido();
        let outputCourses = responseCourses.data.map((item: any) => new ConteudoRelacionadoInternoResumido().fromJSON(item));
        setCursosAtivos(outputCourses);

        const responseLibraryItens: any = await biblioService.listarPublicoResumido();
        let outputLibraryItens = responseLibraryItens.data.map((item: any) => new ConteudoRelacionadoInternoResumido().fromJSON(item));
        setItensBiblioPublicados(outputLibraryItens);
    };

    const loadData = async () => {
        await loadIntermediatePages();
        await loadCoursesAndLibraryItens();
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoading, loadData);
    }, []);

    useEffect(() => {
        setPaginaSelecionada(paginasIntermediarias[0]);
    }, [paginasIntermediarias]);

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, loadRelatedContent);
        setTituloSecaoConteudosRelacionados(paginaSelecionada?.tituloSecaoConteudosRelacionados ?? '');
    }, [paginaSelecionada]);

    const onSaveTituloSecao = () => {
        FetchUtils.loadingHof(setSubmitting, submitTituloSecao);
    };

    const submitTituloSecao = async () => {
        if (paginaSelecionada) {
            paginaSelecionada.tituloSecaoConteudosRelacionados = tituloSecaoConteudosRelacionados;
            await paginaService.editar(paginaSelecionada.codigo, paginaSelecionada);
        }
        onSaved();
    };

    type SelectOptionType = {
        value: string | number;
        label: string;
    };

    const acceptedTypes = (): SelectOptionType[] => {
        return paginasIntermediarias.map(
            (item: AdminPaginaIntermediaria) =>
                ({
                    label: getLabelFromKey(item.pagina),
                    value: item.codigo,
                } as SelectOptionType)
        );
    };

    const getLabelFromKey = (key: string): string => {
        const index = paginasReplaces.findIndex(item => {
            return item[0] === key;
        });
        if (index === -1) return '';

        return paginasReplaces[index][1];
    };

    type SelectOptionTypeConteudosInternos = {
        value: TipoConteudoInternoEnum;
        label: string;
    };
    const acceptedTypesTiposConteudoInterno: SelectOptionTypeConteudosInternos[] = [
        { label: 'Curso Livre', value: TipoConteudoInternoEnum.CURSO_LIVRE },
        { label: 'Conteúdo da Biblioteca', value: TipoConteudoInternoEnum.ITEM_BIBLIOTECA },
    ].map(l => ({ label: l.label, value: l.value } as SelectOptionTypeConteudosInternos));

    const getLabelTiposConteudosInternos = (value: string): string => {
        let labelRetorno = value;
        acceptedTypesTiposConteudoInterno.filter(type => {
            if (type.value == value) {
                labelRetorno = type.label;
            }
        });
        return labelRetorno;
    };

    const acceptedTypesConteudosInternos = useCallback(
        (idx: number, item: PgIntermediariaConteudoRelacionado): SelectOptionType[] => {
            let optionsType: SelectOptionType[] = [];
            if (item.tipoConteudoInterno === TipoConteudoInternoEnum.CURSO_LIVRE) {
                optionsType = cursosAtivos.map(
                    (itemCurso: ConteudoRelacionadoInternoResumido) =>
                        ({
                            label: itemCurso.titulo,
                            value: itemCurso.codigo,
                        } as SelectOptionType)
                );
            } else {
                optionsType = itensBiblioPublicados.map(
                    (itemBiblio: ConteudoRelacionadoInternoResumido) =>
                        ({
                            label: itemBiblio.titulo,
                            value: itemBiblio.codigo,
                        } as SelectOptionType)
                );
            }
            return optionsType;
        },
        [conteudosRelacionados, cursosAtivos, itensBiblioPublicados]
    );

    const getLabelConteudoInternoFromKey = (key: number, item: PgIntermediariaConteudoRelacionado): string => {
        let index: number = -1;
        let label: string = '';
        if (item.tipoConteudoInterno === TipoConteudoInternoEnum.CURSO_LIVRE) {
            index = cursosAtivos.findIndex(itemCurso => {
                return itemCurso.codigo === key;
            });
            if (index !== -1) {
                label = cursosAtivos[index].titulo;
            }
        } else {
            index = itensBiblioPublicados.findIndex(itemBiblio => {
                return itemBiblio.codigo === key;
            });
            if (index !== -1) {
                label = itensBiblioPublicados[index].titulo;
            }
        }

        return label;
    };

    const isValidInputs = (idx: number): boolean => {
        const conteudo = conteudosRelacionados[idx];

        if (!conteudo.anexoBase64 || (conteudo.anexoBase64 && conteudo.anexoBase64.trim().length === 0)) {
            return false;
        }

        if (conteudo.link && !isValidLink(conteudo.link, false)) return false;

        if (
            conteudo.conteudoInterno &&
            (conteudo.tipoConteudoInterno === TipoConteudoInternoEnum.UNKNOWN || !conteudo.identificadorConteudoInterno)
        ) {
            return false;
        }

        return isStringsValid([conteudo.titulo]);
    };

    const setTitle = (idx: number, value: string) => {
        const clone = cloneDeep(conteudosRelacionados);
        clone[idx].titulo = value.substring(0, 200);
        setConteudosRelacionados(clone);
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(conteudosRelacionados);
        clone[idx].link = value.substring(0, 200);
        setConteudosRelacionados(clone);
    };

    const setConteudoInterno = (idx: number, value: boolean) => {
        const clone = cloneDeep(conteudosRelacionados);

        clone[idx].conteudoInterno = value;
        setConteudosRelacionados(clone);
    };

    const setTipoConteudoInterno = (idx: number, value: TipoConteudoInternoEnum) => {
        const clone = cloneDeep(conteudosRelacionados);

        clone[idx].tipoConteudoInterno = value;
        setConteudosRelacionados(clone);
    };

    const setIdentificadorConteudoInterno = (idx: number, value: number) => {
        const clone = cloneDeep(conteudosRelacionados);

        clone[idx].identificadorConteudoInterno = value;
        setConteudosRelacionados(clone);
    };

    const setAtivo = (idx: number, value: boolean) => {
        const clone = cloneDeep(conteudosRelacionados);

        clone[idx].ativo = value;
        setConteudosRelacionados(clone);
    };

    const setImageBase64 = (idx: number, value: string) => {
        const clone = cloneDeep(conteudosRelacionados);
        clone[idx].anexoBase64 = value;
        setConteudosRelacionados(clone);
    };

    const excluirConteudo = async (idx: number) => {
        if (confirm('Excluir este curso?')) {
            setDeleting(true);

            const clone = cloneDeep(conteudosRelacionados);

            const response = await conteudosService.excluir(clone[idx].codigo);
            if (response.status === 200) {
                let newConteudos = conteudosRelacionados.filter(
                    (conteudoRelacionado: PgIntermediariaConteudoRelacionado) => conteudoRelacionado.codigo !== clone[idx].codigo
                );
                setConteudosRelacionados(newConteudos);
                setShowModalDelete(true);
            } else {
                ToastError('Houve um erro ao tentar excluir o curso. Por favor, tente novamente!');
            }
            setDeleting(false);
        }
    };

    const removerConteudo = (idx: number) => {
        if (confirm('Remover este conteudo do formulário?')) {
            let cloneConteudos = cloneDeep(conteudosRelacionados);
            const conteudo = cloneConteudos[idx];

            cloneConteudos = cloneConteudos.filter((item, idxItem) => {
                return idx !== idxItem;
            });

            setConteudosRelacionados(cloneConteudos);
        }
    };

    const addConteudo = () => {
        const clone = cloneDeep(conteudosRelacionados);

        const conteudo = new PgIntermediariaConteudoRelacionado();

        clone.push(conteudo);
        setConteudosRelacionados(clone);
    };

    const submit = async (idx: number) => {
        setSubmitting(true);
        const clone = cloneDeep(conteudosRelacionados);

        let response;

        if (!clone[idx].codigo || clone[idx].codigo === 0) {
            if (paginaSelecionada) {
                clone[idx].codigoPaginaIntermediaria = paginaSelecionada.codigo;
            }
            response = await conteudosService.inserir(clone[idx]);
        } else {
            response = await conteudosService.editar(clone[idx].codigo, clone[idx]);
        }

        if (response.status === 200 || response.status === 201) {
            const objResposta: PgIntermediariaConteudoRelacionado = new PgIntermediariaConteudoRelacionado().fromJSON(response.data);
            clone[idx].codigo = objResposta.codigo;
            setConteudosRelacionados(clone);
            onSaved();
        } else {
            ToastError('Houve um erro ao tentar salvar o curso. Por favor, tente novamente!');
        }

        setSubmitting(false);
    };

    const { subPage } = useContext(AdminContext.Context);

    useEffect(() => {
        if (subPage === null) gotoSubroute('/admin/biblioteca/posts');
    }, [subPage]);

    return (
        <RelatedStyles.AdminEditIntermediateRelatedContentContainer>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <RelatedStyles.InputGroup>
                        <AdminEditorStyled.Label>Selecione a Página Intermediária</AdminEditorStyled.Label>
                        <Select
                            itens={acceptedTypes()}
                            value={{ value: paginaSelecionada?.codigo ?? -1, label: getLabelFromKey(paginaSelecionada?.pagina ?? '') }}
                            onSelected={selectedItem => {
                                const selectedPage = paginasIntermediarias.find((pagina: AdminPaginaIntermediaria) => {
                                    return pagina.codigo === selectedItem.value;
                                });

                                if (selectedPage !== undefined) {
                                    setPaginaSelecionada(selectedPage);
                                }
                            }}
                            isSmall={true}
                        />
                    </RelatedStyles.InputGroup>
                    <br />
                    <br />

                    {paginaSelecionada && !loadingRelatedContent ? (
                        <RelatedStyles.ContentContainer>
                            <RelatedStyles.InputGroup style={{ width: 'calc(50%)' }}>
                                <RelatedStyles.Title>Editar Título da Seção</RelatedStyles.Title>

                                <div>
                                    <AdminEditorStyled.Input
                                        value={tituloSecaoConteudosRelacionados}
                                        onChange={(e: any) => {
                                            setTituloSecaoConteudosRelacionados(e.target.value);
                                        }}
                                        maxLength={MAX_CHAR_TITULO_SECAO}
                                    />

                                    <AdminEditorStyled.CountLetter
                                        danger={
                                            paginaSelecionada.tituloSecaoConteudosRelacionados !== null &&
                                            paginaSelecionada.tituloSecaoConteudosRelacionados.length >= MAX_CHAR_TITULO_SECAO
                                        }
                                    >
                                        {paginaSelecionada.tituloSecaoConteudosRelacionados !== null &&
                                            paginaSelecionada.tituloSecaoConteudosRelacionados.length}
                                        /{MAX_CHAR_TITULO_SECAO}
                                    </AdminEditorStyled.CountLetter>
                                </div>

                                <GradientButton
                                    style={{ marginBottom: '15px', alignSelf: 'flex-end' }}
                                    onClick={async () => {
                                        await onSaveTituloSecao();
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Salvando...' : 'Salvar'}
                                </GradientButton>
                            </RelatedStyles.InputGroup>

                            {/* Conteudos Relaciondos */}
                            <RelatedStyles.InputGroup style={{ width: 'calc(50%)' }}>
                                <RelatedStyles.Title>Editar Conteúdos Relacionados</RelatedStyles.Title>
                            </RelatedStyles.InputGroup>

                            {conteudosRelacionados.map((item, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <RelatedStyles.Title style={{ paddingTop: '5px 0', marginTop: '35px' }}>
                                            Conteúdo {idx + 1}
                                        </RelatedStyles.Title>

                                        <RelatedStyles.ConteudosContainer>
                                            <div>
                                                <AdminEditorStyled.Label>Título</AdminEditorStyled.Label>
                                                <AdminEditorStyled.Input
                                                    value={item.titulo}
                                                    onChange={(e: any) => setTitle(idx, e.target.value)}
                                                    maxLength={MAX_CHAR_TITLE}
                                                />
                                                <AdminEditorStyled.CountLetter danger={item.titulo.length >= MAX_CHAR_TITLE}>
                                                    {item.titulo.length}/{MAX_CHAR_TITLE}
                                                </AdminEditorStyled.CountLetter>

                                                <AdminEditorStyled.Label>Link redirecionamento</AdminEditorStyled.Label>
                                                <AdminEditorStyled.Input
                                                    className={
                                                        item.link && item.link.length > 3 && !isValidLink(item.link ?? '', false) && 'is-invalid'
                                                    }
                                                    value={item.link}
                                                    onChange={(evt: any) => setLink(idx, evt.target.value)}
                                                    maxLength={MAX_CHAR_URL}
                                                />
                                                <AdminEditorStyled.CountLetter danger={item.link.length >= MAX_CHAR_URL}>
                                                    {item.link.length}/{MAX_CHAR_URL}
                                                </AdminEditorStyled.CountLetter>

                                                <AdminEditorStyled.CkeckContainer>
                                                    <span style={{ marginRight: '10px' }}>Ativo</span>
                                                    <ReactSwitch
                                                        height={18 * 0.7}
                                                        width={40 * 0.7}
                                                        handleDiameter={22 * 0.7}
                                                        onHandleColor={'#0B78C8'}
                                                        offHandleColor={'#0B78C8'}
                                                        onColor={'#BFDFF7'}
                                                        offColor={'#dfdfdf'}
                                                        checkedIcon={false}
                                                        uncheckedIcon={false}
                                                        checked={item.ativo ?? false}
                                                        onChange={value => setAtivo(idx, value)}
                                                    />
                                                </AdminEditorStyled.CkeckContainer>

                                                <AdminEditorStyled.CkeckContainer>
                                                    <span style={{ marginRight: '10px' }}>Conteúdo Interno</span>
                                                    <ReactSwitch
                                                        height={18 * 0.7}
                                                        width={40 * 0.7}
                                                        handleDiameter={22 * 0.7}
                                                        onHandleColor={'#0B78C8'}
                                                        offHandleColor={'#0B78C8'}
                                                        onColor={'#BFDFF7'}
                                                        offColor={'#dfdfdf'}
                                                        checkedIcon={false}
                                                        uncheckedIcon={false}
                                                        checked={item.conteudoInterno ?? false}
                                                        onChange={value => setConteudoInterno(idx, value)}
                                                    />
                                                </AdminEditorStyled.CkeckContainer>

                                                {item.conteudoInterno && (
                                                    <>
                                                        <AdminEditorStyled.Label>Tipo de Conteúdo Interno</AdminEditorStyled.Label>
                                                        <Select
                                                            itens={acceptedTypesTiposConteudoInterno}
                                                            value={{
                                                                value: item.tipoConteudoInterno,
                                                                label: getLabelTiposConteudosInternos(item.tipoConteudoInterno),
                                                            }}
                                                            onSelected={o => setTipoConteudoInterno(idx, o.value as TipoConteudoInternoEnum)}
                                                            isSmall={true}
                                                        />

                                                        {item.tipoConteudoInterno && (
                                                            <React.Fragment key={item.tipoConteudoInterno}>
                                                                <AdminEditorStyled.Label>
                                                                    Selecione o conteúdo relacionado
                                                                </AdminEditorStyled.Label>
                                                                <Select
                                                                    itens={acceptedTypesConteudosInternos(idx, item)}
                                                                    value={{
                                                                        value: item.identificadorConteudoInterno ?? 0,
                                                                        label: getLabelConteudoInternoFromKey(
                                                                            item.identificadorConteudoInterno ?? 0,
                                                                            item
                                                                        ),
                                                                    }}
                                                                    onSelected={o => setIdentificadorConteudoInterno(idx, o.value as number)}
                                                                    isSmall={true}
                                                                />
                                                            </React.Fragment>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            <RelatedStyles.RightColumn>
                                                <AdminImageUploader
                                                    size={'sm'}
                                                    title={'Imagem de capa'}
                                                    onSelect={(base64: string, file: File) => setImageBase64(idx, base64)}
                                                    preImageB64={item.anexoBase64}
                                                    recommendedDimension={{ w: 224, h: 112 }}
                                                />

                                                <br />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <GradientButton
                                                        style={{ marginBottom: '15px' }}
                                                        onClick={async () => await submit(idx)}
                                                        disabled={!isValidInputs(idx) || submitting}
                                                    >
                                                        {submitting ? 'Salvando...' : 'Salvar'}
                                                    </GradientButton>

                                                    {item.codigo > 0 ? (
                                                        <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                            <DangerButton disabled={deleting} onClick={async () => excluirConteudo(idx)}>
                                                                {deleting ? 'Excluindo...' : 'Excluir'}
                                                            </DangerButton>
                                                        </AllowedGroupsValidator>
                                                    ) : (
                                                        <DangerButton onClick={async () => removerConteudo(idx)} style={{ fontSize: '0.8em' }}>
                                                            Remover do formulário
                                                        </DangerButton>
                                                    )}
                                                </div>
                                            </RelatedStyles.RightColumn>
                                        </RelatedStyles.ConteudosContainer>
                                    </React.Fragment>
                                );
                            })}

                            <AdminEditorStyled.BottomButton>
                                <OutlineButton onClick={addConteudo}>+ Adicionar Conteúdo</OutlineButton>
                            </AdminEditorStyled.BottomButton>
                        </RelatedStyles.ContentContainer>
                    ) : (
                        <Loading />
                    )}
                </div>
            )}

            <AdminEditModalDelete
                show={showModalDelete}
                onHide={() => {
                    setShowModalDelete(false);
                }}
            />
        </RelatedStyles.AdminEditIntermediateRelatedContentContainer>
    );
};

export default AdminEditIntermediateRelatedContent;
