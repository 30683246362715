import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Styled from './IntermediatePageContent.styled';

import card1 from '../../../images/homepage/educationalSolutions/img_card_2.svg';
import card2 from '../../../images/homepage/educationalSolutions/img_card_3.svg';
import { IsMediumSizeMaxWidth } from 'core/util/WindowSize';
import OtherSolutions from 'pages/intermediarias/educationalSolutionsDetail/components/OtherSolutions';
import { PrimaryButton } from 'components/UI/Buttons';
import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { FetchUtils } from 'core/util/FetchUtils';
import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import { PgIntermediariaConteudoRelacionadoService } from 'core/http/service/PgIntermediariaConteudoRelacionado.service';

export type IntermediatePageContentSection = {
    section: string;
    content: any;
    subroute: string;
};

export interface IIntermediatePageContent {
    sections: IntermediatePageContentSection[];
    pageRoot: string;
    complementComponent?: JSX.Element;
    isOffsetRight?: boolean;
    pageKey: string;
}

const IntermediatePageContent = ({ sections, pageRoot, complementComponent, isOffsetRight, pageKey }: IIntermediatePageContent) => {
    const history = useHistory();
    const location = useLocation();
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(true);
    const [paginaIntermediaria, setPaginaIntermediaria] = useState<AdminPaginaIntermediaria | null>(null);
    const [conteudosRelacionados, setConteudosRelacionados] = useState<PgIntermediariaConteudoRelacionado[]>([]);

    const paginaService = new PaginaIntermediariaService();
    const conteudosService = new PgIntermediariaConteudoRelacionadoService();

    const IsMobile = () => {
        return IsMediumSizeMaxWidth();
    };

    const [activeSection, setActiveSection] = useState<IntermediatePageContentSection | null>(sections[0] || null);

    useEffect(() => {
        const subroute = location.hash.replace('#', '').split('&')[0];

        if (!subroute) {
            setActiveSection(sections[0]);
        } else {
            const section = sections.find(s => s.subroute == subroute);
            if (section) {
                setActiveSection(section);
            }
        }
    }, [activeSection, location]);

    const fetchPaginaIntermediaria = async () => {
        const responsePagina: any = await paginaService.listar();
        const outputPagina: AdminPaginaIntermediaria[] = responsePagina.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

        if (outputPagina) {
            let currentPage: AdminPaginaIntermediaria = outputPagina.find(item => item.pagina === pageKey) ?? new AdminPaginaIntermediaria();
            setPaginaIntermediaria(currentPage);
        }
    };

    const fetchRelatedContent = async () => {
        if (paginaIntermediaria) {
            const responseRelatedContent: any = await conteudosService.listarPorPaginaPublico(paginaIntermediaria.codigo);
            let output = responseRelatedContent.data.map((item: any) => new PgIntermediariaConteudoRelacionado().fromJSON(item));
            setConteudosRelacionados(output);
        }
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingPage, fetchPaginaIntermediaria);
    }, []);

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, fetchRelatedContent);
    }, [paginaIntermediaria]);

    const gotoPage = (route: string) => {
        history.push(route);
    };

    return (
        <Styled.Container>
            <Styled.Grid>
                {!IsMobile() ? (
                    <>
                        <Styled.Column>
                            {sections.map(sec => {
                                return (
                                    <Styled.Title2
                                        key={sec.section}
                                        active={sec.section == activeSection?.section}
                                        onClick={() => {
                                            GTM_Page_Intermediaria(
                                                'default_intermediaria',
                                                `${pageRoot.toLowerCase()}_secao_${sec.subroute}_btn`
                                            );
                                            gotoPage(`/${pageRoot}#${sec.subroute}`);
                                        }}
                                    >
                                        {sec.section}
                                    </Styled.Title2>
                                );
                            })}
                        </Styled.Column>

                        <Styled.Column>
                            <Styled.VerticalLine />
                        </Styled.Column>
                    </>
                ) : (
                    <Styled.SelectionContainer>
                        {sections.map(sec => {
                            return (
                                <Styled.TabItem
                                    key={sec.section}
                                    onClick={() => {
                                        GTM_Page_Intermediaria('default_intermediaria', `${pageRoot.toLowerCase()}_secao_${sec.subroute}_btn`);
                                        gotoPage(`/${pageRoot}#${sec.subroute}`);
                                    }}
                                >
                                    <Styled.TabItemLabel isActive={sec.section == activeSection?.section}>{sec.section}</Styled.TabItemLabel>
                                    {sec.section == activeSection?.section && <Styled.TabItemIndicator />}
                                </Styled.TabItem>
                            );
                        })}
                    </Styled.SelectionContainer>
                )}

                <Styled.Column className="content">{activeSection && <>{activeSection.content}</>}</Styled.Column>

                <Styled.Column></Styled.Column>
                <Styled.Column></Styled.Column>
                <Styled.Column className="mobile-center">
                    <br />
                    <br />

                    <Styled.ButtonContainer>
                        {complementComponent && <Styled.ComplementContainer>{complementComponent}</Styled.ComplementContainer>}

                        <Styled.OutlineButton
                            offsetRight={isOffsetRight}
                            onClick={() => {
                                GTM_Page_Intermediaria('default_intermediaria', `${pageRoot.toLowerCase()}_voltar_btn`);
                                gotoPage('/');
                            }}
                        >
                            Voltar página inicial
                        </Styled.OutlineButton>
                    </Styled.ButtonContainer>

                    {!IsMobile() && !loadingPage && !loadingRelatedContent && (
                        <Styled.SeeMore>
                            <OtherSolutions
                                conteudos={conteudosRelacionados}
                                tituloSecao={paginaIntermediaria?.tituloSecaoConteudosRelacionados ?? ''}
                            />
                        </Styled.SeeMore>
                    )}
                </Styled.Column>
            </Styled.Grid>

            <br />
            <br />

            {IsMobile() && !loadingPage && !loadingRelatedContent && (
                <div style={{ padding: '15px' }}>
                    <OtherSolutions
                        conteudos={conteudosRelacionados}
                        tituloSecao={paginaIntermediaria?.tituloSecaoConteudosRelacionados ?? ''}
                    />
                </div>
            )}
        </Styled.Container>
    );
};

export default IntermediatePageContent;
