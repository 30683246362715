import { jsonProperty, Serializable } from 'ts-serializable';
import { ConteudoRecursoCustomizado } from './ConteudoRecursoCustomizado';

export class ConteudoOrderEtapa extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(Number)
    codigoItem: number | null = -1;

    @jsonProperty(Number)
    ordem: number | null = -1;
}

export class EtapaItemRecursosCostumizado extends Serializable {
    @jsonProperty(Number, null)
    codigo?: number | null = -1;

    @jsonProperty(Number, null)
    codigoItem: number | null = -1;

    @jsonProperty(String, null)
    nome: string = '';

    @jsonProperty(String, null)
    descricao: string = '';

    @jsonProperty([ConteudoRecursoCustomizado])
    conteudos: ConteudoRecursoCustomizado[] = [];

    @jsonProperty(String, null)
    url: string = '';

    @jsonProperty(String, null)
    usuarioResponsavel: string = '';

    @jsonProperty(String, null)
    ultimaAtualizacao: string = '';

    @jsonProperty(Boolean, null)
    isConcluido: boolean = false;

    @jsonProperty(Boolean, null)
    oculto: boolean = false;

    @jsonProperty(Number, null)
    ordem: number | null = -1;
}
