import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';
import { FormikInputStyle } from './profile/components/formik/FormikTextInput';

const StyledLogin = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 48px 70px;
        gap: 1rem;
    `,

    Title: styled.h1`
        font-weight: 600;
        color: ${Colors.Blue};
        font-size: 30px;
    `,

    Subtitle: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 159.02%;

        strong {
            font-weight: 700;
        }
    `,

    Row: styled.div<{ error?: boolean }>`
        display: flex;
        margin: 7px 0;
        align-items: center;

        flex-wrap: wrap;

        background-color: ${props => (props.error ? 'rgba(255, 0, 0, 0.1)' : 'unset')};

        border-radius: 7px;
        padding: 5px 10px;
        width: fit-content;
        min-width: 350px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
            // justify-content: center;
        }
    `,

    Column: styled.div<{ error?: boolean }>`
        display: flex;
        margin: 7px 0;
        flex-direction: column;

        flex-wrap: wrap;

        background-color: ${props => (props.error ? 'rgba(255, 0, 0, 0.1)' : 'unset')};

        border-radius: 7px;
        padding: 5px 10px;
        width: fit-content;
        min-width: 350px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
            // justify-content: center;
        }
    `,

    Label: styled.span<{ error?: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 42px;
        color: ${props => (props.error ? 'red' : '#5f758b')};
        margin-right: 10px;
    `,

    TwoColumns: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 830px;
        padding-left: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            grid-template-columns: 1fr;
        }
    `,

    CheckBox: styled.input`
        margin-right: 10px;
    `,

    LabelCheck: styled.label`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #455a64;
        cursor: pointer;
    `,

    InputText: styled(FormikInputStyle)``,
};

export default StyledLogin;
