import React, { useState } from 'react';

import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import { FiLinkedin, FiYoutube, FiInstagram, FiTwitter, FiFacebook, FiMapPin, FiMail, FiPhone, FiGlobe } from 'react-icons/fi';
import {
    ContactContainerStyle,
    ContentContainer,
    Title,
    ContactItem,
    ItemText,
    Link,
    SocialIcon,
    Img,
    Contents,
    AcessButton,
    ContentItemsContainer,
    FlexContainerSpaceBetween,
    HumaneLogoStyle,
    HeaderContainer,
    LogRodapeContainer,
    FlexContainerFooter,
    LinkFooter,
    LinkContainer,
    FlexContainerColumnSpace,
    FlexAccountsRedes,
    TextRede,
    SocialIcon2,
} from './ContactContainer.styled';

import HumaneLogo from '../../../../images/homepage/contact/humane.svg';
import HumaneLogoBlue from '../../../../images/homepage/contact/humaneBlue.svg';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Termos from 'pages/profile/components/regrasConvivencia/Termos';
import ModalTerms from 'pages/profile/components/regrasConvivencia/ModalTerms';
import LogoIAS from '../../../../images/homepage/contact/LogoIAS.png';
import Selo30Anos from './assets/selo-30-anos.webp';
import PrivacyPolicy from 'pages/home/components/contact/PrivacyPolicy';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import Colors from 'enums/Colors';
import { MdCopyright } from 'react-icons/md';
import LogoDefault from '../../../../images/logo/logo-default-text-white.png';
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

interface IContactContainer {
    buttonDisabled?: boolean;
    isLight?: boolean;
}

const ContactContainer = ({ buttonDisabled, isLight }: IContactContainer) => {
    const history = useHistory();
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
    const anoAtual = new Date().getFullYear();
    const { keycloak } = useKeycloak();

    const login = () => {
        GTM_Page_Home('secao_contato', 'btn_click_acessar_plataforma');
        history.push('/login');
    };

    const register = () => {
        GTM_Page_Home('secao_contato', 'btn_click_fazer_cadastro');
        const url = keycloak.createRegisterUrl({
            redirectUri: window.location.origin + '/login',
        });
        location.assign(url);
    };

    return (
        <ContactContainerStyle isLight={isLight} id="contactContainer">
            <Contents>
                <LinkContainer href={'https://institutoayrtonsenna.org.br'} target="_blank" rel="noopener noreferrer">
                    <Img
                        style={{
                            width: 110,
                            marginRight: 10,
                            borderRadius: '0px 10px 0px 0px',
                        }}
                        src={Selo30Anos}
                    />
                </LinkContainer>
                <FlexContainerColumnSpace>
                    <ContentContainer>
                        <ContentItemsContainer>
                            <ContactItem isLight={isLight}>
                                <SocialIcon
                                    href="https://institutoayrtonsenna.org.br"
                                    target="_blank"
                                    onClick={() => {
                                        GTM_Page_Home('secao_contato', 'btn_click_website');
                                    }}
                                >
                                    <FiGlobe color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                                    <p style={{ color: isLight ? Colors.Blue : Colors.White }}>institutoayrtonsenna.org.br</p>
                                </SocialIcon>
                            </ContactItem>
                            <ContactItem isLight={isLight}>
                                <SocialIcon
                                    href="mailto:humane@ias.org.br"
                                    target="_blank"
                                    onClick={() => {
                                        GTM_Page_Home('secao_contato', 'btn_click_email');
                                    }}
                                >
                                    <FiMail color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                                    <p style={{ color: isLight ? Colors.Blue : Colors.White }}>humane@ias.org.br</p>
                                </SocialIcon>
                            </ContactItem>
                            <ContactItem isLight={isLight}>
                                <FiMapPin style={{ width: '22px', height: '22px' }} />
                                <ItemText
                                    color={isLight ? Colors.Blue : Colors.White}
                                >{`R. Dr. Fernandes Coelho, 85 - 15°. ${'\n'}São Paulo - Brasil`}</ItemText>
                            </ContactItem>
                        </ContentItemsContainer>
                    </ContentContainer>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextRede style={{ color: isLight ? Colors.Blue : Colors.White }}>Siga-nos nas nossas redes</TextRede>
                        <FlexAccountsRedes>
                            <SocialIcon2
                                href="https://www.linkedin.com/company/institutoayrtonsenna/mycompany/"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_linkedin');
                                }}
                            >
                                <FaLinkedinIn style={{ width: '22px', height: '22px' }} color={isLight ? Colors.Blue : Colors.White} />
                            </SocialIcon2>

                            <SocialIcon2
                                href="https://www.facebook.com/institutoayrtonsenna"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_facebook');
                                }}
                            >
                                <FaFacebookF color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            </SocialIcon2>

                            <SocialIcon2
                                href="https://www.instagram.com/institutoayrtonsenna/"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_instagram');
                                }}
                            >
                                <FiInstagram color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            </SocialIcon2>

                            <SocialIcon2
                                href="https://www.youtube.com/channel/UCJCq5ZlBsXYnhAbbZnwwjoA"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_youtube');
                                }}
                            >
                                <FaYoutube color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            </SocialIcon2>
                        </FlexAccountsRedes>
                    </div>
                </FlexContainerColumnSpace>
            </Contents>

            <FlexContainerSpaceBetween style={{marginTop: "40px"}}>
                <div id="linksContainer" style={{ display: 'flex', gap: '15px' }}>
                    <LinkFooter isLight={isLight}>
                        <p
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_politica_privacidade');
                                setShowPrivacyPolicyModal(true);
                            }}
                        >
                            Política de Privacidade
                        </p>
                    </LinkFooter>

                    <LinkFooter isLight={isLight}>
                        <p
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_termos_uso');
                                setShowTermsModal(true);
                            }}
                        >
                            Termos de Uso
                        </p>
                    </LinkFooter>

                    <LinkFooter
                        isLight={isLight}
                        href={'https://institutoayrtonsenna.gupy.io/'}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            GTM_Page_Home('secao_contato', 'btn_click_trabalhe_conosco');
                        }}
                    >
                        Trabalhe com a gente
                    </LinkFooter>
                </div>

                <LogRodapeContainer style={{}}>
                    {!IsMobile() && (
                        <ItemText color={isLight ? Colors.Blue : Colors.White}>
                            Copyright {<MdCopyright />} {anoAtual} Instituto Ayrton Senna. Todos direitos reservados.
                        </ItemText>
                    )}
                </LogRodapeContainer>
            </FlexContainerSpaceBetween>

            {IsMobile() && (
                <FlexContainerFooter>
                    <ItemText
                        color={isLight ? Colors.Blue : Colors.White}
                        style={{ paddingTop: '20px', marginLeft: '0', whiteSpace: 'nowrap', fontSize: '10px' }}
                    >
                        Copyright {<MdCopyright />} {anoAtual} Instituto Ayrton Senna. Todos direitos reservados.
                    </ItemText>
                </FlexContainerFooter>
            )}

            <ModalTerms show={showPrivacyPolicyModal} setShow={setShowPrivacyPolicyModal}>
                <PrivacyPolicy />
            </ModalTerms>

            <ModalTerms show={showTermsModal} setShow={setShowTermsModal}>
                <Termos
                    agree={() => {
                        setShowTermsModal(false);
                    }}
                    decline={() => {
                        setShowTermsModal(false);
                    }}
                    isLight={isLight}
                />
            </ModalTerms>
        </ContactContainerStyle>
    );
};

export default ContactContainer;
