import React from 'react';
import Menu from 'pages/menu/Menu';
import { Route, useLocation } from 'react-router-dom';
import RecursosPage from './RecursosPage';
import RecursosPageItem from './recursos-item/RecursosPageItem';
import RecursosProvider from './context/RecursosProvider';
import ConteudoFavorito from './conteudos-favoritos/ConteudoFavorito';
import RecursosConteudoPage from './recursos-conteudo/RecursosConteudoPage';
import NotFound from 'pages/not_found/NotFound';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface IRecursoRoute {}

const RecursosRoute = ({}: IRecursoRoute) => {
    const location = useLocation();
    const isFavLocation = () => location.pathname == '/recursos/conteudos-favoritos';

    return (
        <RecursosProvider>
            <DynamicHeader />

            <Route exact path="/recursos" component={NotFound} />

            {isFavLocation() ? (
                <Route exact path="/recursos/conteudos-favoritos" component={ConteudoFavorito} />
            ) : (
                <Route exact path="/recursos/:recursoId" component={RecursosPage} />
            )}

            <Route exact path="/recursos/:recursoId/:itemId" component={RecursosPageItem} />
            <Route exact path="/recursos/:recursoId/:itemId/:etapaId/:conteudoId" component={RecursosConteudoPage} />
        </RecursosProvider>
    );
};

export default RecursosRoute;
