import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import Menu from '../menu/Menu';
import { MenuRealizandoEscolhasStyle, MainContainer, DivPaddingMarginTop, InstrumentoContainer } from './RealizandoEscolhas.styled';
import { useKeycloak } from '@react-keycloak/web';
import RealizandoEscolhasHeader from './home/components/RealizandoEscolhasHeader';
import RealizandoEscolhasHome from './home/RealizandoEscolhasHome';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import RealizandoEscolhasHeaderMobile from './home/components/mobile/RealizandoEscolhasHeaderMobile';
import { ResizeMediumWidth } from 'constants/Dimensions';
import Escolhas from './pages/escolhas/Escolhas';
import Fundamentos from './pages/fundamentos/Fundamentos';
import TrilhaFormativa from './pages/trilhaFormativa/TrilhaFormativa';
import Instrumento from './pages/instrumento/Instrumento';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { ToasterDefault } from 'components/Toaster';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import RealizandoEscolhasProvider from './home/context/RealizandoEscolhasProvider';

interface IRealizandoEscolhas {}
const RealizandoEscolhas: FunctionComponent<IRealizandoEscolhas> = props => {
    const { keycloak } = useKeycloak();
    const { isSearchView } = useContext(PesquisaContext.Context);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const ContainerToolbar = ({ children }: React.PropsWithChildren<{}>) => {
        return <>{children}</>;
    };

    const IsTablet = () => {
        return IsCustomMaxWidth(ResizeMediumWidth);
    };

    useEffect(() => {}, []);

    return (
        <>
            <RealizandoEscolhasProvider>
                <MenuRealizandoEscolhasStyle>
                    <Menu realizandoEscolhas />

                    <DivPaddingMarginTop>
                        <Route
                            exact
                            path={['/realizandoEscolhas/home', '/realizandoEscolhas']}
                            component={() => (
                                <>
                                    <MainContainer>
                                        <ContainerToolbar>
                                            {!IsTablet() ? <RealizandoEscolhasHeader /> : <RealizandoEscolhasHeaderMobile />}
                                            <RealizandoEscolhasHome />
                                        </ContainerToolbar>
                                    </MainContainer>
                                    <ContactContainer />
                                </>
                            )}
                        />

                        <Route
                            path={['/realizandoEscolhas/escolhas']}
                            component={() => (
                                <>
                                    <MainContainer>
                                        <ContainerToolbar>
                                            {!IsTablet() ? <RealizandoEscolhasHeader /> : <RealizandoEscolhasHeaderMobile />}
                                            <Escolhas />
                                        </ContainerToolbar>
                                    </MainContainer>
                                    <ContactContainer />
                                </>
                            )}
                        />

                        <Route
                            path={['/realizandoEscolhas/fundamentos']}
                            component={() => (
                                <>
                                    <MainContainer>
                                        <ContainerToolbar>
                                            {!IsTablet() ? <RealizandoEscolhasHeader /> : <RealizandoEscolhasHeaderMobile />}
                                            <Fundamentos />
                                        </ContainerToolbar>
                                    </MainContainer>
                                    <ContactContainer />
                                </>
                            )}
                        />

                        <Route
                            path={['/realizandoEscolhas/trilhaFormativa']}
                            component={() => (
                                <>
                                    <MainContainer>
                                        <ContainerToolbar>
                                            {!IsTablet() ? <RealizandoEscolhasHeader /> : <RealizandoEscolhasHeaderMobile />}
                                            <TrilhaFormativa />
                                        </ContainerToolbar>
                                    </MainContainer>
                                    <ContactContainer />
                                </>
                            )}
                        />

                        <Route
                            path={['/realizandoEscolhas/avaliacao']}
                            component={() => (
                                <>
                                    <InstrumentoContainer>
                                        <ContainerToolbar>
                                            {!IsTablet() ? <RealizandoEscolhasHeader /> : <RealizandoEscolhasHeaderMobile />}
                                            <Instrumento />
                                        </ContainerToolbar>
                                    </InstrumentoContainer>
                                </>
                            )}
                        />
                        {ToasterDefault()}
                    </DivPaddingMarginTop>
                </MenuRealizandoEscolhasStyle>
                {/* {isSearchView && <BarraPesquisa />} */}
            </RealizandoEscolhasProvider>
        </>
    );
};

export default RealizandoEscolhas;
