import styled from 'styled-components';

const BackInitialPageButton = styled.button`
    display: flex;
    padding: 16px 40px;
    max-height: 32px;
    border: 1px solid #003c78;
    border-radius: 50px;
    background-color: transparent;
    align-items: center;
    gap: 10px;
    max-width: 260px;

    @media only screen and (max-width: 1200px) {
        margin-left: 30px;
        padding: 0;
        max-width: 200px;
        max-height: 25px;
    }

    @media only screen and (max-width: 300px) {
        margin-left: 10px;
    }

    &:hover {
        background: #003c78;
        img {
            filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
        }
        p {
            color: white;
        }
    }
`;

const ButtonText = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #003c78;
`;

const Img = styled.img`
    width: 10px;
    margin: 5px;
    cursor: pointer;
`;

export { BackInitialPageButton, ButtonText, Img };
