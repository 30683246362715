import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
    padding: 20px;
    margin-top: -35px;
`;

export const Content = styled.div`
    width: 100%;
    margin-top: 15px;
    max-height: 480px;
    margin-bottom: 7px;
    overflow-y: scroll;
    padding: 8px;

    p {
        font-family: Barlow;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        color: #5f758b;
        margin: 10px 0;
        text-align: justify;
    }

    h1 {
        color: #5f758b;
        font-weight: 700;
        display: inline;
    }
`;
