import PrefenciaUsuario from 'models/PreferenciaUsuario';
import { GenericService } from './Generic.service';

export class PreferenciasService extends GenericService {
    readonly URI: string = '/v1/userPrefs';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public async getPreferenciaComCache(chavePreferencia: string, idPerfil: number) {
        const cachePrefName = `USERPREF:${chavePreferencia}`;
        const cachedValor = localStorage.getItem(cachePrefName);

        if (cachedValor) return { data: { valor: cachedValor } };

        const result = await this.get('getPreferencia', { chave: chavePreferencia, idPerfil });

        if (result?.data?.valor && result?.data?.valor != 'null') {
            localStorage.setItem(cachePrefName, result.data.valor);
        }

        return result;
    }

    public async getPreferencia(chavePreferencia: string, idPerfil: number) {
        return await this.get('getPreferencia', { chave: chavePreferencia, idPerfil });
    }

    public async setPreferencia(preferencia: PrefenciaUsuario, idPerfil: number) {
        const cachePrefName = `USERPREF:${preferencia.chave}`;
        const result = await this.post('setPreferencia', { ...preferencia, idPerfil });

        if (result.status < 300 && preferencia.valor) localStorage.setItem(cachePrefName, preferencia.valor);

        return result;
    }
}
