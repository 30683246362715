import React, { PropsWithChildren, FunctionComponent } from 'react';

import styled, { keyframes } from 'styled-components';

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FormControlStyle = styled.div<{ noFlex: boolean }>`
    display: ${props => (props.noFlex ? 'block' : 'flex')};
    animation: ${fade} 0.3s linear;
    margin-bottom: 10px;
`;

interface IFormControl {
    noFlex?: boolean;
}

const FormControl: FunctionComponent<IFormControl> = (props: PropsWithChildren<IFormControl>) => {
    return <FormControlStyle noFlex={props.noFlex ?? false}>{props.children}</FormControlStyle>;
};

export default FormControl;
