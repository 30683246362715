import Debounce from 'core/util/Debounce';

export namespace DiaryTextHelper {
    const debounce = new Debounce();
    export const debounceOnChangeText = (onTimeout: () => void, time = 1000): void => {
        debounce.launch(() => {
            onTimeout();
        }, time);
    };

    export const clearDebounce = () => {
        debounce.clear();
    };

    export const onChangeCheckTextFix = (value: string, newValue: string): string => {
        const re =
            /^<p><strong style="color: rgb\(0.*168,.*233\);">(O que você já conhece sobre competências socioemocionais\?|Você consegue identificar situações do dia a dia em que mobilizou essas competências\?)<\/strong><\/p>/i;

        if (!re.test(newValue)) return value;
        return newValue;
    };
}
