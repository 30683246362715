import { CSSProperties } from 'react';

import styled from 'styled-components';
import Colors from 'enums/Colors';
import { MobileMaxWidth } from 'constants/Dimensions';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';

export namespace DiaryCardStyle {
    interface IContainer {}
    export const Container = styled.div<IContainer>(() => {
        return new FullCss(
            {
                flex: 1,
                width: 330,
                minWidth: 330,
                maxWidth: 300,
                marginRight: 5,
                paddingRight: 5,
                paddingTop: '0px',
                borderRight: `1px solid #00A8E9`,
                background: 'rgba(0, 168, 233, 0.05)',
                // border: '1px red solid',
            },
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                paddingRight: 0,
                marginRight: 0,
                borderRight: 'none',
            })
        ).getCss();
    });

    export const Scroll = styled.div`
        max-height: calc(515px - 80px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    `;

    export const Title = styled.p`
        width: 100%;
        text-align: center;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
        margin: 10px 0;
    `;

    export const Center = styled.div`
        width: 100%;
        text-align: center;
    `;

    export const NewDiaryButton = styled.button`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #003c78;
        width: fit-content;
        border: 0;
        outline: 0;
        background-color: transparent;
        padding: 7px;
        border-radius: 7px;

        :hover {
            background-color: #d7edf5;
        }
    `;

    export const CardsContainer = styled.div``;
}
