import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import editIcon from '../../../../../images/admin/edit.svg';
import trashIcon from '../../../../../images/admin/trash.svg';
import searchIcon from '../../../../../images/biblioteca/search-icon-grey.svg';

import { GradientButton } from 'components/UI/Buttons';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminPalavraProibida from 'models/admin/AdminPalavraProibida';
import capitalize from 'pages/admin/utils/capitalize';
import AdminModalNewWord from './AdminModalNewWord';
import AdminModalEditword from './AdminModalEditPalavra';
import AdminModalExcludePalavra from './AdminModalExcludePalavra';
import Loading from 'components/Loading';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

export interface IAdminBiblioPalavrasProibidas {}

const AdminBiblioPalavrasProibidas = ({}: IAdminBiblioPalavrasProibidas) => {
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [items, setItems] = useState<AdminPalavraProibida[]>([]);
    const [showModalNewWord, setShowModalNewWord] = useState(false);
    const [showModalEditWord, setShowModalEditWord] = useState(-1);
    const [showModalExcludeWord, setShowModalExcludeWord] = useState(-1);

    const [isLoading, setLoading] = useState<boolean>(false);

    const changePage = (page: number) => {
        setPage(page);
    };

    const fetchItens = async (filtro?: string) => {
        setLoading(true);
        const service = new AdminBiblioService();
        const { data } = await service.buscarPalavrasProibidas(page, filtro);

        const _items = data.rows.map((item: any) => new AdminPalavraProibida().fromJSON(item));
        setTotalPages(data.totalPages);
        setItems(_items);
        setLoading(false);
    };

    useEffect(() => {
        fetchItens();
    }, []);

    useEffect(() => {
        if (!isLoading) fetchItens();
    }, [page]);

    return (
        <Styled.Container style={{ padding: '20px', marginTop: '3rem' }}>
            <Styled.Title>Gestão das palavras proibidas</Styled.Title>
            <Styled.Text>Palavras ou termos com cunho ofensivo, sexual, discriminatório e ilegal são banidas da plataforma.</Styled.Text>

            <br />
            <br />
            <Styled.RowSpace>
                <Styled.SearchContainer>
                    <Styled.SearchInput placeholder="O que você está procurando?" value={term} onChange={env => setTerm(env.target.value)} />
                    <Styled.SearchIconButton src={searchIcon} onClick={() => fetchItens(term)} />
                </Styled.SearchContainer>

                <br />

                <GradientButton onClick={() => setShowModalNewWord(true)}>Criar nova</GradientButton>
            </Styled.RowSpace>

            <br />
            <br />

            {!isLoading ? (
                <Styled.GridPalavras>
                    <Styled.GridHead>Palavra proibida</Styled.GridHead>
                    <Styled.GridHead>Variações</Styled.GridHead>
                    <Styled.GridHead>Motivação</Styled.GridHead>
                    <Styled.GridHead>Ações</Styled.GridHead>

                    {items.map((item, idx) => (
                        <React.Fragment key={idx}>
                            <Styled.GridCol>{capitalize(item.content)}</Styled.GridCol>
                            <Styled.GridCol>{item.variations.join(', ')}</Styled.GridCol>
                            <Styled.GridCol>{capitalize(item.motivation.replace('_', ' '))}</Styled.GridCol>
                            <Styled.GridCol>
                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <Styled.Icon src={trashIcon} onClick={() => setShowModalExcludeWord(item.id)} />
                                </AllowedGroupsValidator>
                                <Styled.Icon src={editIcon} onClick={() => setShowModalEditWord(item.id)} />
                            </Styled.GridCol>
                        </React.Fragment>
                    ))}
                </Styled.GridPalavras>
            ) : (
                <Styled.LoadingContainer>
                    <Loading />
                </Styled.LoadingContainer>
            )}

            <br />

            <AdminPagination onChangePage={changePage} total={totalPages} />

            <AdminModalNewWord
                show={showModalNewWord}
                onSaved={() => {
                    setShowModalNewWord(false);
                    fetchItens();
                }}
                onHide={() => setShowModalNewWord(false)}
            />

            <AdminModalExcludePalavra
                show={showModalExcludeWord != -1}
                onDelete={() => {
                    setShowModalExcludeWord(-1);
                    fetchItens();
                }}
                onHide={() => setShowModalExcludeWord(-1)}
                word={items.find(t => t.id == showModalExcludeWord)}
            />

            {items.find(i => i.id == showModalEditWord) && (
                <AdminModalEditword
                    show={showModalEditWord != -1}
                    onEdit={() => {
                        setShowModalEditWord(-1);
                        fetchItens();
                    }}
                    onHide={() => setShowModalEditWord(-1)}
                    word={items.find(i => i.id == showModalEditWord) ?? ({} as AdminPalavraProibida)}
                />
            )}
        </Styled.Container>
    );
};

export default AdminBiblioPalavrasProibidas;
