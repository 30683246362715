import { PrimaryButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSelectButtons = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    Content: styled.div`
        display: flex;
        gap: 0.5rem;
    `,

    Button: styled(PrimaryButton)<{ color: Colors; background: Colors }>`
        background-color: ${({ background }) => background} !important;
        color: ${({ color }) => color} !important;
        border: ${({ background }) => (background === Colors.White ? '1px solid' + Colors.Blue : 'none')};
        &:hover {
            box-shadow: none !important;
        }
    `,

    Radius: styled.input``,
};

export default StyledSelectButtons;
