import Colors from 'enums/Colors';
import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import iconAlert from '../../../../images/profile/alert-icon.svg';

export interface IAlertDeclineDialog {
    show: boolean;
    onClose: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

const IconAlert = styled.img`
    width: 70px;
    height: 'auto';
    margin: 20px 0;
`;

const ButtonBack = styled.button`
    width: 190px;
    height: 42px;
    background: ${Colors.InfoRed};
    border-radius: 50px;
    outline: none;
    border: none;
    color: ${Colors.White};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
`;

const TextContent = styled.p`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    margin: 20px 0;
`;

const AlertDeclineDialog = ({ show, onClose }: IAlertDeclineDialog) => {
    return (
        <Modal size={'sm'} show={show}>
            <Modal.Body>
                <Container>
                    <IconAlert src={iconAlert} />
                    <TextContent>É necessário aceitar os Termos de Uso e Política de privacidade antes de continuar</TextContent>
                    <ButtonBack onClick={onClose}>Voltar</ButtonBack>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default AlertDeclineDialog;
