import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminHeader.styled';
import AdminHeaderItem from 'models/admin/AdminHeaderItem';
import AdminHeaderService from 'core/http/service/admin/AdminHeader.service';
import { Form, Modal } from 'react-bootstrap';
import Row from 'components/Row';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Select, { SelectOptionType } from 'components/Select';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import { cloneDeep } from 'lodash';
import { BsPlusCircle } from 'react-icons/bs';
import { OutlineButton } from 'components/UI/Buttons';
import { BiTrash } from 'react-icons/bi';
import { AdminContext } from 'pages/admin/context/AdminContext';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import AdminHeaderModalConfirmated from './AdminHeaderModalConfirmated';

export interface IAdminHeader {
    itemId: number;
    allItens: AdminHeaderItem[];
    show: boolean;
    onHide: () => void;
    onSubmit: (item: AdminHeaderItem) => void;
    onDelete: (itemId: number) => void;
}

const MAX_CHARS_LENGTH = 20;
const MAX_CHARS_LENGTH_SUBITEM = 35;

const AdminHeaderModal: React.FC<IAdminHeader> = ({ itemId, show, onHide, allItens, onDelete, onSubmit }) => {
    const [item, setItem] = useState<AdminHeaderItem>(new AdminHeaderItem());
    const [submitting, setsubmitting] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [idItemDelete, setIdItemDelete] = useState<number>(0);
    const [deleteInfo, setDeleteInfo] = useState<number>(0);
    const [showSubModal, setSubModal] = useState<boolean>(false);
    const { checkAllowedGroups } = useContext(AdminContext.Context);

    const onHandlerSubitem = (value: boolean) => {
        const cloneItem = cloneDeep(item);
        if (!value) cloneItem.subheaders = [];
        else
            cloneItem.subheaders = [
                new AdminHeaderItem().fromJSON({
                    codigo: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
                    codigo_header_pai: item.codigo,
                    ordem: item.subheaders.length ?? 0,
                    logado: item.logado,
                    isNew: true,
                }),
            ];

        setItem(cloneItem);
        setIsEdit(true);
    };

    const addSubitens = () => {
        const cloneItem = cloneDeep(item);

        cloneItem.subheaders.push(
            new AdminHeaderItem().fromJSON({
                codigo: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
                codigo_header_pai: item.codigo,
                ordem: item.subheaders.length,
                logado: item.logado,
                isNew: true,
            })
        );

        setItem(cloneItem);
        setIsEdit(true);
    };

    const setNome = (newNome: string) => {
        const cloneItem = cloneDeep(item);
        cloneItem.nome = newNome;
        setItem(cloneItem);
        setIsEdit(true);
    };

    const setUrl = (newUrl: string) => {
        const cloneItem = cloneDeep(item);
        cloneItem.url = newUrl;
        setItem(cloneItem);
        setIsEdit(true);
    };

    const setOrdem = (newOrder: number) => {
        if (item.ordem != newOrder) {
            const cloneItem = cloneDeep(item);
            cloneItem.ordem = newOrder;
            setItem(cloneItem);
            setIsEdit(true);
        }
    };

    const deleteHandler = () => {
        if (item.isNew) hide();
        else onDelete(item.codigo);
    };

    const setSubNome = (tempId: number, newNome: string) => {
        const cloneItem = cloneDeep(item);

        for (let i = 0; i < cloneItem.subheaders.length; i++)
            if (cloneItem.subheaders[i].codigo == tempId) {
                cloneItem.subheaders[i].nome = newNome;
            }

        setItem(cloneItem);
        setIsEdit(true);
    };

    const setSubUrl = (tempId: number, newUrl: string) => {
        const cloneItem = cloneDeep(item);

        for (let i = 0; i < cloneItem.subheaders.length; i++)
            if (cloneItem.subheaders[i].codigo == tempId) {
                cloneItem.subheaders[i].url = newUrl;
            }

        setItem(cloneItem);
        setIsEdit(true);
    };

    const setSubOrdem = (tempId: number, newOrdem: number) => {
        const cloneItem = cloneDeep(item);

        for (let i = 0; i < cloneItem.subheaders.length; i++) {
            if (cloneItem.subheaders[i].codigo == tempId) {
                cloneItem.subheaders[i].ordem = newOrdem;
                cloneItem.subheaders[newOrdem].ordem = i;
            }
        }

        setItem(cloneItem);
        setIsEdit(true);
    };

    const setEventoGTM = (newEvent: string) => {
        const cloneItem = cloneDeep(item);
        cloneItem.eventoGTM = newEvent;
        setItem(cloneItem);
        setIsEdit(true);
    };

    const setSubEventoGTM = (tempId: number, newEvent: string) => {
        const cloneItem = cloneDeep(item);

        for (let i = 0; i < cloneItem.subheaders.length; i++)
            if (cloneItem.subheaders[i].codigo == tempId) {
                cloneItem.subheaders[i].eventoGTM = newEvent;
            }

        setItem(cloneItem);
        setIsEdit(true);
    };

    const setJornadaConcluido = (value: boolean) => {
        const cloneItem = cloneDeep(item);
        cloneItem.jornadaConcluida = value;
        setItem(cloneItem);
        setIsEdit(true);
    };

    const setSubJornadaConcluido = (tempId: number, value: boolean) => {
        const cloneItem = cloneDeep(item);

        for (let i = 0; i < cloneItem.subheaders.length; i++)
            if (cloneItem.subheaders[i].codigo == tempId) {
                cloneItem.subheaders[i].jornadaConcluida = value;
            }

        setItem(cloneItem);
        setIsEdit(true);
    };

    const hide = () => {
        onHide();
    };

    const itemsOrder = (orderThis: number, isSubItem = false) => {
        const options: SelectOptionType[] = [];

        const length = isSubItem ? item.subheaders.length : allItens.filter(i => i.codigoHeaderPai == null && i.logado == item.logado).length;

        for (let i = 0; i < length; i++) {
            if (i != orderThis) {
                const option = {
                    value: i,
                    label: String(i + 1) + 'º',
                } as SelectOptionType;

                options.push(option);
            }
        }

        return options;
    };

    const removeSubitem = async () => {
        await onDelete(idItemDelete);
    };

    const validItem = (): boolean => {
        if (item.subheaders.length == 0) return item.nome.trim().length > 0 && item.url.trim().length > 0;
        else {
            let subvalid = true;

            for (let i = 0; i < item.subheaders.length; i++) {
                const sub = item.subheaders[i];

                if (sub.nome.trim().length == 0 || sub.url.trim().length == 0) {
                    subvalid = false;
                    break;
                }
            }

            return item.nome.trim().length > 0 && subvalid;
        }
    };

    const isAdminMaster = () => {
        return checkAllowedGroups([KcAdminGroups.MASTER]);
    };

    const save = async () => {
        setsubmitting(true);
        await onSubmit(item);
        setsubmitting(false);
    };

    const handleHideConfirmated = () => {
        onHide();
    };

    useEffect(() => {
        const item = allItens.find(i => i.codigo == itemId);
        if (item) setItem(cloneDeep(item));
    }, [itemId]);

    return (
        <>
            <AdminHeaderModalConfirmated
                show={showSubModal}
                isDelete={deleteInfo === 1 || deleteInfo === 2}
                onHide={() => {
                    setSubModal(false);
                    setDeleteInfo(0);
                }}
                handleConfirmated={() => {
                    deleteInfo === 1 ? removeSubitem() : deleteInfo === 2 ? deleteHandler() : handleHideConfirmated();
                }}
            />

            <Modal
                show={show}
                onHide={() => {
                    isEdit ? setSubModal(true) : onHide();
                }}
                backdrop="static"
                keyboard={false}
            >
                <Styled.Modal.Container>
                    <Row justify="space-between">
                        <Styled.Modal.Title>Item header {`${item.logado ? '' : 'des'}`}logado</Styled.Modal.Title>

                        <Styled.Modal.CloseButton
                            onClick={() => {
                                isEdit ? setSubModal(true) : onHide();
                            }}
                        >
                            <AiOutlineCloseCircle />
                        </Styled.Modal.CloseButton>
                    </Row>

                    <br />

                    <Row>
                        <Styled.Column style={{ marginRight: '10px' }}>
                            <Styled.Modal.Label>Nome do item</Styled.Modal.Label>
                            <Form.Control
                                style={{ width: '340px' }}
                                value={item.nome}
                                onChange={e => {
                                    if (e.target.value.length <= MAX_CHARS_LENGTH) {
                                        setNome(e.target.value);
                                    }
                                }}
                            />
                            <Row justify="end">
                                <Styled.Modal.Count style={{ color: item.nome.length >= MAX_CHARS_LENGTH ? 'red' : '#acacac' }}>
                                    {item.nome.length}/{MAX_CHARS_LENGTH}
                                </Styled.Modal.Count>
                            </Row>
                        </Styled.Column>

                        <Styled.Column>
                            <Styled.Modal.Label style={{ margin: 0 }}>Ordem do item</Styled.Modal.Label>
                            <span title={item.isNew ? 'A alteração da ordem do item estará disponível após o seu salvamento' : ''}>
                                <Select
                                    itens={itemsOrder(item.ordem)}
                                    isSmall
                                    width="100px"
                                    value={{ value: item.ordem, label: String(item.ordem + 1) }}
                                    onSelected={o => setOrdem(parseInt(String(o.value)))}
                                    disabled={item.isNew}
                                />
                            </span>
                        </Styled.Column>
                    </Row>
                    <br />

                    <Row>
                        <AdminSwitch value={item.subheaders.length > 0} onChange={v => onHandlerSubitem(v)} />
                        <Styled.Modal.Sublabel> Subitens </Styled.Modal.Sublabel>
                    </Row>

                    {item.subheaders.length > 0 ? (
                        <>
                            <hr />
                            <Styled.Modal.Autoscroll>
                                {item.subheaders
                                    .sort((a, b) => a.ordem - b.ordem)
                                    .map((s, idx) => {
                                        return (
                                            <Styled.Modal.SubItemContainer key={s.codigo}>
                                                <Row>
                                                    <Styled.Column style={{ marginRight: '10px' }}>
                                                        <Styled.Modal.Label>Nome do subitem</Styled.Modal.Label>
                                                        <Form.Control
                                                            style={{ width: '320px' }}
                                                            value={s.nome}
                                                            placeholder="Nome do subitem"
                                                            onChange={e => {
                                                                if (e.target.value.length <= MAX_CHARS_LENGTH_SUBITEM) {
                                                                    setSubNome(s.codigo, e.target.value);
                                                                }
                                                            }}
                                                        />
                                                        <Row justify="end">
                                                            <Styled.Modal.Count
                                                                style={{ color: s.nome.length >= MAX_CHARS_LENGTH_SUBITEM ? 'red' : '#acacac' }}
                                                            >
                                                                {s.nome.length}/{MAX_CHARS_LENGTH_SUBITEM}
                                                            </Styled.Modal.Count>
                                                        </Row>
                                                    </Styled.Column>

                                                    <Styled.Column>
                                                        <Styled.Modal.Label style={{ margin: 0, fontSize: '0.76em', lineHeight: 'none' }}>
                                                            Ordem do subitem
                                                        </Styled.Modal.Label>
                                                        <Select
                                                            itens={itemsOrder(s.ordem, true)}
                                                            isSmall
                                                            width="100px"
                                                            value={{ value: s.ordem, label: String(idx + 1) }}
                                                            onSelected={o => setSubOrdem(s.codigo, parseInt(String(o.value)))}
                                                        />
                                                    </Styled.Column>
                                                </Row>

                                                <Styled.Column>
                                                    <Styled.Modal.Label>URL do subitem</Styled.Modal.Label>
                                                    <Form.Control
                                                        style={{ width: '435px' }}
                                                        value={s.url}
                                                        placeholder="URL do subitem"
                                                        onChange={e => {
                                                            setSubUrl(s.codigo, e.target.value);
                                                        }}
                                                    />
                                                </Styled.Column>

                                                <Row>
                                                    <Styled.Column>
                                                        <br />
                                                        <Styled.Modal.Label>Evento do GTM do subitem</Styled.Modal.Label>
                                                        <Form.Control
                                                            style={{ width: '340px' }}
                                                            value={s.eventoGTM}
                                                            onChange={e => {
                                                                setSubEventoGTM(s.codigo, e.target.value);
                                                            }}
                                                        />
                                                    </Styled.Column>
                                                </Row>

                                                <br />

                                                {!item.logado && (
                                                    <Row>
                                                        <AdminSwitch
                                                            value={s.jornadaConcluida}
                                                            onChange={v => setSubJornadaConcluido(s.codigo, v)}
                                                        />
                                                        <Styled.Modal.Sublabel> Jornada concluída </Styled.Modal.Sublabel>
                                                    </Row>
                                                )}

                                                <Row justify="center">
                                                    <Styled.Modal.DeleteSub
                                                        onClick={() => {
                                                            setIdItemDelete(s.codigo);
                                                            setDeleteInfo(1);
                                                            setSubModal(true);
                                                        }}
                                                    >
                                                        Remover Subitem
                                                    </Styled.Modal.DeleteSub>
                                                </Row>

                                                <br />
                                            </Styled.Modal.SubItemContainer>
                                        );
                                    })}

                                <Row justify="center">
                                    <Styled.Modal.AddSubButton onClick={addSubitens}>
                                        <BsPlusCircle />
                                        <span>Adicionar subitem</span>
                                    </Styled.Modal.AddSubButton>
                                </Row>
                            </Styled.Modal.Autoscroll>
                            <hr />
                        </>
                    ) : (
                        <>
                            <Styled.Column>
                                <br />
                                <Styled.Modal.Label>URL do item</Styled.Modal.Label>
                                <Form.Control
                                    style={{ width: '340px' }}
                                    value={item.url}
                                    onChange={e => {
                                        setUrl(e.target.value);
                                    }}
                                />
                            </Styled.Column>{' '}
                            <br />
                            <Row>
                                <Styled.Column>
                                    <br />
                                    <Styled.Modal.Label>Evento do GTM</Styled.Modal.Label>
                                    <Form.Control
                                        style={{ width: '340px' }}
                                        value={item.eventoGTM}
                                        onChange={e => {
                                            setEventoGTM(e.target.value);
                                        }}
                                    />
                                </Styled.Column>
                            </Row>
                            <br />
                            {!item.logado && (
                                <Row>
                                    <AdminSwitch value={item.jornadaConcluida} onChange={v => setJornadaConcluido(v)} />
                                    <Styled.Modal.Sublabel> Jornada concluída </Styled.Modal.Sublabel>
                                </Row>
                            )}
                        </>
                    )}

                    <br />
                    <Row justify="space-around">
                        <OutlineButton
                            onClick={() => {
                                isEdit ? setSubModal(true) : onHide();
                            }}
                        >
                            Cancelar
                        </OutlineButton>
                        <Styled.Modal.SaveButton disabled={!validItem() || submitting} onClick={save}>
                            {submitting ? 'Salvando...' : `Salvar item ${item.logado ? '' : 'des'}logado`}
                        </Styled.Modal.SaveButton>

                        {isAdminMaster() && (
                            <Styled.Modal.DeleteButton
                                onClick={() => {
                                    setDeleteInfo(2);
                                    setSubModal(true);
                                }}
                            >
                                <BiTrash />
                            </Styled.Modal.DeleteButton>
                        )}
                    </Row>
                </Styled.Modal.Container>
            </Modal>
        </>
    );
};

export default AdminHeaderModal;
