import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const BibliotecaStyled = {
    Wrapper: styled.article`
        margin-top: 86px; //altura do Menu
        width: 100%;
        min-height: calc(100% - 86px);
        background-color: white;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 60px;
            min-height: calc(100% - 60px);
        }
    `,

    NoContentText: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #2e353a;
        width: 100%;
        text-align: center;
        opacity: 0.6;
        padding: 20px 0;
    `,

    Container: styled.div`
        margin: 0 auto;
        max-width: 1100px;
        padding: 40px 0;
    `,

    Grid: styled.div``,

    Title: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        color: #003c78;
    `,

    Text: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #555;
        margin-top: 20px;
        padding-bottom: 15px;
    `,

    HeadContainer: styled.div`
        margin-bottom: 10px;
        padding-bottom: 16px;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 0 15px;
        }
    `,
};

export default BibliotecaStyled;
