import React, { useState, useEffect } from 'react';

import SideImage from '../SideImage';
import AtuacaoForm from './Form/AtuacaoForm';
import { useHistory } from 'react-router-dom';
import { FormSection } from './Atuacao.styled';
import { AtuacaoStyle } from './Atuacao.styled';
import FormHeader from '../formHeader/FormHeader';
import ProfileData from '../../../../models/ProfileData';
import { ProgressConsumer } from '../../ProgressContext';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import ParametrosCadastroPerfilDTO from '../../../../models/perfil/ParametrosCadastroPerfilDTO';
import * as authenticationActions from '../../../../store/actions/Authentication';
import Atuacao_img_1 from '../../../../images/profile/atuacao_img_1.svg';
import Atuacao_img_4 from '../../../../images/profile/atuacao_img_4.svg';
import { useDispatch } from 'react-redux';
import UsuarioDTO from 'models/UsuarioDTO';
import BaloonAlert from './Form/BaloonAlert';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const Atuacao = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentImg, setCurrentImg] = useState(Atuacao_img_1);
    const [hasAtuacaoData, setHasAtuacaoData] = useState<boolean>();
    const [statusProgresso, setStatusProgresso] = useState<number>(40);

    const profileParameterData: ParametrosCadastroPerfilDTO = useAppSelector(state => state.profileReducer.parametrosCadastro);

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    const initialize = async () => {
        if (profileData?.atuacaoCompleta) {
            setHasAtuacaoData(true);
            setCurrentImg(Atuacao_img_4);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const [title, setTitle] = useState<string>(`E como você atua hoje, ${profileData?.apelido}?`);

    const onContinuar = () => {
        //history.push('/profile/identificacaoImagem');
        history.push('/profile/regrasConvivencia');
    };

    const ReloadContent = (formik: any, name: string, progress: number, image: any, titleParam?: string) => {
        formik.setFieldTouched(name);
        if (progress > statusProgresso && !hasAtuacaoData) {
            setStatusProgresso(progress);
            if (image && image !== currentImg) setCurrentImg(image);
            if (titleParam && titleParam !== title) setTitle(titleParam);
        }
    };

    return (
        <AtuacaoStyle>
            <ProgressConsumer>
                {value => {
                    const { progresso, setProgresso } = value;
                    if (statusProgresso > progresso) {
                        setProgresso(statusProgresso);
                    }
                    return (
                        <FormSection id="FormSection">
                            <FormHeader title={title} subtitle="Nos conte um pouco mais sobre você!" />
                            <BaloonAlert />
                            <AtuacaoForm
                                onContinuarHandler={onContinuar}
                                ReloadContentHandler={ReloadContent}
                                profileParameterData={profileParameterData}
                                profileData={profileData}
                            />
                        </FormSection>
                    );
                }}
            </ProgressConsumer>
            {!IsMobile() && <SideImage image={currentImg} />}
        </AtuacaoStyle>
    );
};

export default Atuacao;
