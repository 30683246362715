import React, { useRef } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import SliderItemAutoavaliacao from './SliderItemAutoavaliacao';
import sliderAutoavaliacaoData from './SliderAutoavaliacaoData';

import arrowLeft from '../../../../images/painelSocio/arrow-slide-L.svg';
import { useContext } from 'react';
import { PainelSocioContext } from '../../../painelSocioemocional/home/context/PainelSocioContext';
import { useEffect } from 'react';

export interface ISlider {}

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return <img className={className} style={{ ...style }} onClick={onClick} src={arrowLeft} />;
};

const CustomArrowRight = (props: any) => {
  const { className, style, onClick } = props;
  return <img className={className} style={{ ...style, transform: 'scale(-1, 1)' }} onClick={onClick} src={arrowLeft} />;
};

const SliderAutoavaliacao = ({}: ISlider) => {
  const slider = useRef<Slider>(null);
  const { currentIndexSlideAutoavaliacao, updateCurrentIndexSlideAutoavaliacao } = useContext(PainelSocioContext.Context);

  const settings: SliderSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange(currentSlide, nextSlide) {
        setTimeout(() =>  {
          updateCurrentIndexSlideAutoavaliacao(nextSlide);
        },500);
        return;
      }, 
      adaptiveHeight: true,
      prevArrow: currentIndexSlideAutoavaliacao != 0 ? <CustomArrowLeft /> : undefined,
      nextArrow: currentIndexSlideAutoavaliacao != sliderAutoavaliacaoData.length-1 ? <CustomArrowRight /> : undefined,
  };



  useEffect(() => {
      if (slider?.current) {
          slider.current.slickGoTo(currentIndexSlideAutoavaliacao);
      }
  }, [currentIndexSlideAutoavaliacao]);

  return (
      <Slider ref={slider} {...settings}>

          {sliderAutoavaliacaoData.map((item, idx) => {
              return <SliderItemAutoavaliacao key={idx} index={item.index} title={item.title} image={item.image} image2={item.image2} image3 = {item.image3}
              content={item.content} content2={item.content2} content3={item.content3} />;
          })}
      </Slider>
  );
};

export default SliderAutoavaliacao;
