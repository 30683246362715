import React, { useEffect, useState } from 'react';
import Styled from './AdminPagination.styled';

import arrowIcon from '../../../../images/admin/arrow.svg';

export interface IAdminPagination {
    total?: number;
    withLetters?: boolean;
    onChangePage: (page: number) => void;
}

const AdminPagination = ({ total = 5, onChangePage, withLetters }: IAdminPagination) => {
    const [page, setPage] = useState<number>(1);

    const nextPage = () => {
        if (page < total) setPage(page + 1);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    useEffect(() => {
        onChangePage(page);
    }, [page]);

    useEffect(() => {
        if (total === 1) setPage(1);
    }, [total]);

    return (
        <Styled.Container>
            {withLetters && (
                <Styled.DropDownContainer>
                    <span>A-Z</span>
                </Styled.DropDownContainer>
            )}

            <Styled.ArrowButtonPage onClick={prevPage}>
                <img src={arrowIcon} />
            </Styled.ArrowButtonPage>

            {[...Array(total)].map((_, idx) => {
                return (
                    <Styled.ButtonPage selected={idx + 1 == page} key={idx} onClick={() => setPage(idx + 1)}>
                        {idx + 1}
                    </Styled.ButtonPage>
                );
            })}

            <Styled.ArrowButtonPage onClick={nextPage}>
                <img src={arrowIcon} style={{ transform: 'scaleX(-1)' }} />
            </Styled.ArrowButtonPage>
        </Styled.Container>
    );
};

export default AdminPagination;
