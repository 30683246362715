import React from 'react';
import Colors from 'enums/Colors';
import {
    Title,
    Paragraph,
    ContentContainerInner,
    CompetenciasWrapper,
    CompetenciasContainer,
    CompetenciaItem,
    ColoredIndication,
} from './SocioemocionaisEducadores.styled';

export interface IObjectivesEducationalSolutions {}

const ObjectivesEducationalSolutions = ({}: IObjectivesEducationalSolutions) => {
    return (
        <ContentContainerInner>
            <Title>{`O que são competências socioemocionais de professores?`}</Title>
            <Paragraph>
                As competências socioemocionais de professores são características individuais que se manifestam em padrões de pensamentos,
                sentimentos e comportamentos, sendo originadas na relação recíproca de aspectos biológicos e ambientais. Elas continuam a se
                desenvolver por meio de experiências formais e informais de aprendizagem e podem ser adquiridas e desenvolvidas em formações
                iniciais e na prática profissional, além de poderem facilitar direta e indiretamente a aprendizagem dos estudantes e a interação
                com colegas, profissionais da educação, pais e sociedade.
            </Paragraph>

            <Paragraph style={{ margin: '0' }}>
                Assim como as competências cognitivas e a inteligência, as socioemocionais também podem ser desenvolvidas ao longo da vida, por
                meio de estímulos formais e informais. Estudos indicam que profissionais com essas competências desenvolvidas têm também mais
                altos índices de satisfação com a profissão e com a vida, e se sentem mais preparados para contribuir com o clima escolar.
            </Paragraph>

            <Paragraph>
                Embora exista um grande número de características relacionadas a essas competências, as evidências mostram que, no caso dos
                professores, elas podem ser agrupadas em 4 conjuntos, o que facilita seu conhecimento e desenvolvimento.
            </Paragraph>

            <br />

            <CompetenciasWrapper>
                <CompetenciasContainer>
                    <CompetenciaItem>
                        <ColoredIndication color={'#DC9D00'} />
                        {`Autorregulação das Emoções`}
                    </CompetenciaItem>
                    <CompetenciaItem>
                        <ColoredIndication color={Colors.SoftBlue} />
                        {`Conexão com o outro`}
                    </CompetenciaItem>
                    <CompetenciaItem>
                        <ColoredIndication color={Colors.DarkBlue} />
                        {`Gestão de ensino e aprendizagem`}
                    </CompetenciaItem>
                    <CompetenciaItem>
                        <ColoredIndication color={Colors.Yellow} />
                        {`Inventividade`}
                    </CompetenciaItem>
                </CompetenciasContainer>
            </CompetenciasWrapper>
        </ContentContainerInner>
    );
};

export default ObjectivesEducationalSolutions;
