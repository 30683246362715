import React, { useEffect, useState } from 'react';
import Styled from '../AdminGestaoPaginasPage.styled';
import { ComentarioGestaoAdmin } from 'models/recursos-costumizados/ComentariosRecursosCostumizados';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import Badge from 'components/badge/Badge';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import eyeIcon from '../../../images/admin/eye.svg';
import CommentModal from './CommentModal';
import Select, { SelectOptionType } from 'components/Select';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import { cloneDeep } from 'lodash';
import { DateHelp } from 'core/util/DateHelp';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import AdminEditorStyled from 'pages/admin/components/pageEditors/AdminEditor.styled';
import { OutlineButton } from 'components/UI/Buttons';
import moment from 'moment';
import searchIcon from '../../../images/biblioteca/search-icon-black.svg';

const CommentaryGestaoDePaginas = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recursoComentario, setRecursoComentario] = useState<ComentarioGestaoAdmin[]>([]);
    const [showIdItemModal, setShowIdItemModal] = useState(-1);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filterResponse, setFilterResponse] = useState('');
    const [ordem, setOrdem] = useState('');
    const [cleanKey, setCleanKey] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [filtroComment, setFiltroComment] = useState('');

    const changePage = (page: number) => {
        setPage(page);
    };

    const answeredFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: 'Respondido',
            value: 'true',
        },
        {
            label: 'Não Respondido',
            value: 'false',
        },
    ];

    const orderOptions: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: 'Nome da página',
            value: 't.nome_pagina:asc',
        },
        {
            label: 'Nome do conteúdo',
            value: 'rc.nome:asc',
        },
        {
            label: 'Mais recentes',
            value: 'data_criacao:desc',
        },
    ];

    interface IFiltro {
        dataInicio: Date | null;
        dataFim: Date | null;
    }
    const [filtroDatas, setFiltro] = useState<IFiltro>({
        dataInicio: null,
        dataFim: null,
    });

    const service = new AdminRecursosCostumizados();

    const fetchComentarios = async (filtro?: string, respondido?: string | undefined, ordem?: string | undefined) => {
        setLoading(true);
        const dataFormat = filtroDatas.dataInicio !== null ? moment(filtroDatas.dataInicio).format('L') : undefined;
        const datafimFormat = filtroDatas.dataFim !== null ? moment(filtroDatas.dataFim).format('L') : undefined;
        const { data } = await service.getAllComments(page, filtro, respondido, ordem, dataFormat, datafimFormat);
        setTotalPages(data.totalPages);
        setRecursoComentario(data.rows);
        setTotalItems(data.range);
        setLoading(false);
    };

    const enableComment = async (idx: number) => {
        const clone = cloneDeep(recursoComentario);
        clone[idx].ativo = !clone[idx].ativo;
        const { data } = await service.habilitarComentario(clone[idx].codigo, { comentario: clone[idx].comentario, ativo: clone[idx].ativo });
        setRecursoComentario(clone);
    };

    const cleanFilter = () => {
        setFiltro({ dataInicio: null, dataFim: null });
        setOrdem('');
        setFilterResponse('');
        setCleanKey(Math.random());
        fetchComentarios();
    };

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';
    const DatePickerInicioWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            selected={filtroDatas.dataInicio}
            onChange={(date: Date) =>
                setFiltro({
                    ...filtroDatas,
                    dataInicio: date ?? null,
                })
            }
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;
    const DatePickerFimWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            selected={filtroDatas.dataFim}
            onChange={(date: Date) =>
                setFiltro({
                    ...filtroDatas,
                    dataFim: date ?? null,
                })
            }
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    useEffect(() => {
        fetchComentarios();
    }, []);

    useEffect(() => {
        fetchComentarios(filtroComment, filterResponse, ordem);
    }, [filtroComment]);

    useEffect(() => {
        fetchComentarios();
    }, [filtroDatas]);

    useEffect(() => {
        fetchComentarios();
    }, [page]);

    return (
        <>
            <CommentModal
                show={showIdItemModal != -1}
                onHide={() => setShowIdItemModal(-1)}
                comment={recursoComentario.find(i => i.codigo == showIdItemModal)}
                updateData={() => fetchComentarios('', filterResponse)}
            />
            <div key={cleanKey}>
                <Styled.DivRowRight style={{ position: 'relative', zIndex: 2 }}>
                    <Styled.RowRigth>
                        <span className="label-order">Respondido:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={answeredFilter}
                                onSelected={o => {
                                    fetchComentarios('', o.value.toString() ?? '', ordem);
                                    setFilterResponse(o.value.toString() ?? '');
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Ordernar por:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={orderOptions}
                                onSelected={o => {
                                    fetchComentarios('', filterResponse, o.value.toString() ?? '');
                                    setOrdem(o.value.toString() ?? '');
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                </Styled.DivRowRight>

                <Styled.DivRowRight style={{ position: 'relative' }}>
                    <Styled.RowRigth>
                        <span className="label-order">Data inicial:</span>
                        <DatePickerInicioWrapper />
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Data final:</span>
                        <DatePickerFimWrapper />
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Resultados encontrados: </span>
                        <span style={{ fontSize: '14px' }}>{totalItems}</span>
                    </Styled.RowRigth>
                </Styled.DivRowRight>
                <Styled.DivRowRight style={{ position: 'relative', marginBottom: '20px' }}>
                    <Styled.SearchContainer>
                        <Styled.SearchInput
                            placeholder="Digite o Nome da página, Nome do conteúdo ou Coméntario"
                            value={filtroComment}
                            onChange={env => setFiltroComment(env.target.value)}
                            onKeyUp={env => {
                                if (env.key === 'Enter') {
                                    env.preventDefault();
                                    fetchComentarios(filtroComment, filterResponse, ordem);
                                }
                            }}
                        />
                        <Styled.SearchIconButton
                            src={searchIcon}
                            onClick={() => {
                                fetchComentarios(filtroComment, filterResponse, ordem);
                            }}
                        />
                    </Styled.SearchContainer>
                    <Styled.RowRigth>
                        <OutlineButton onClick={cleanFilter} style={{ height: '40px', marginLeft: '50%' }}>
                            Limpar filtros
                        </OutlineButton>
                    </Styled.RowRigth>
                </Styled.DivRowRight>
            </div>

            <Styled.TableGrid>
                <Styled.TableGridHead>
                    <Styled.TableGridHeadCell>Nome da página</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Nome do conteúdo</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Autor</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>E-mail</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Data</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Respondido</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Ações</Styled.TableGridHeadCell>
                </Styled.TableGridHead>
                {!loading &&
                    recursoComentario.map((comentario, idx) => {
                        return (
                            <Styled.TableGridRow key={comentario.codigo}>
                                <Styled.TableGridCell>{comentario.nomePagina}</Styled.TableGridCell>
                                <Styled.TableGridCell>{comentario.nomeConteudo}</Styled.TableGridCell>
                                <Styled.TableGridCell>{comentario.nomeUsuario}</Styled.TableGridCell>
                                <Styled.TableGridCell>
                                    {comentario.email ?? (comentario.nomeUsuario === 'humane' && 'adminestruturante@gmail.com')}
                                </Styled.TableGridCell>
                                <Styled.TableGridCell>{DateHelp.formatDateFull(comentario.dataCriacao)}</Styled.TableGridCell>
                                <Styled.TableGridCell>
                                    <Badge color={comentario.respostaAdmin ? '#229645' : '#D64545'}>
                                        {comentario.respostaAdmin ? 'Sim' : 'Não'}
                                    </Badge>
                                </Styled.TableGridCell>

                                <Styled.TableGridCell>
                                    <OverlayTrigger
                                        overlay={<Tooltip id={'tooltip-action-view_' + comentario.codigo}>Visualizar</Tooltip>}
                                        placement={'top'}
                                    >
                                        <span>
                                            <Styled.ViewButton
                                                style={{ marginRight: '5px' }}
                                                src={eyeIcon}
                                                onClick={() => setShowIdItemModal(comentario.codigo)}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={'tooltip-action-edit_' + comentario.codigo}>
                                                {`${comentario.ativo ? 'Desabilitar ' : 'Habilitar '}`}
                                                publicação do comentário
                                            </Tooltip>
                                        }
                                        placement={'top'}
                                    >
                                        <span>
                                            <AdminSwitch value={comentario.ativo} onChange={() => enableComment(idx)} />
                                        </span>
                                    </OverlayTrigger>
                                </Styled.TableGridCell>
                            </Styled.TableGridRow>
                        );
                    })}
            </Styled.TableGrid>
            <AdminPagination onChangePage={changePage} total={totalPages} />
        </>
    );
};

export default CommentaryGestaoDePaginas;
