import imageAuto from '../images/painelSocio/bg-tile-1.webp';
import imagePdp from '../images/painelSocio/bg-tile-2.webp';
import imageDiario from '../images/painelSocio/bg-tile-3.webp';
import imageJornada from '../images/painelSocio/bg-tile-4.webp';

export type ContentSEType = {
    title: string;
    image: string;
    text: string;
};

export default {
    SAIBAMAIS: {
        image: '',
        title: 'Saiba Mais',
        text: 'Após anos de estudos e pesquisas acerca das competências socioemocionais dos estudantes e o desenvolvimento de uma matriz e instrumentos específicos de monitoramento para esse público alvo, o Instituto Ayrton Senna voltou seu olhar para o socioemocional dos educadores brasileiros e para a necessidade de ver esses profissionais sob a mesma ótica integral que se aplica aos estudantes, mas desta vez com uma matriz específica ao fazer docente, que possa oferecer apoio em seu percurso de desenvolvimento profissional com base em evidências',
    } as ContentSEType,

    AUTOAVALIACAO: {
        title: 'Seu ponto de partida',
        text: 'Todo desenvolvimento começa com uma avaliação sobre pontos fortes e os que precisam de atenção. Responda ao instrumento autoavaliativo de competências socioemocionais docentes e aproveite para refletir sobre si mesmo e sua prática.',
        image: imageAuto,
    } as ContentSEType,

    DESENVOLVIMENTO: {
        title: 'Construa após a autoavaliação',
        text: 'Faça seu Plano de Desenvolvimento Pessoal (PDP) e crie um percurso formativo único e desenvolvido por você especialmente para suas necessidades e segundo seus próprios objetivos, de carreira e de vida!',
        image: imagePdp,
    } as ContentSEType,

    DIARIO: {
        title: 'Registre a qualquer momento',
        text: 'Crie um repositório de ideias, anotações e impressões em um único ambiente e tenha sempre em mãos esse espaço de registro do seu processo de reflexão e de busca ativa pelo seu desenvolvimento!',
        image: imageDiario,
    } as ContentSEType,

    JORNADA: {
        title: 'Conhecimento e formação',
        text: 'Faça um processo de aprendizado e desenvolvimento direcionado para cada macrocompetência relevante para o fazer docente.',
        image: imageJornada,
    } as ContentSEType,
};
