import CardAtividades from 'models/secoes/CardAtividades';
import { StatusCardModulo } from 'models/secoes/CardModulos';

export default function instanciaCardAtividades(values: Partial<CardAtividades> = {}): CardAtividades {
    const instancia = new CardAtividades();
    Object.assign(instancia, values);
    return instancia;
}


export const TrilhaApronfundadaCardAtividades: CardAtividades[] = [
    instanciaCardAtividades({
        title: 'Atividades Práticas',
        description:
            'Aqui você irá encontrar atividades práticas relacionadas as aulas da Trilha Alfabetização Integral em apoio ao Compromisso Nacional Criança Alfabetizada.',
        status: StatusCardModulo.DISPONIVEL,
    }),
];
