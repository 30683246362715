import React, { useContext, useEffect, useRef, useState } from 'react';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { AdminContext } from 'pages/admin/context/AdminContext';

interface IAllowedGroupsValidator {
    allowedGroups: KcAdminGroups[];
}
const AllowedGroupsValidator = ({ allowedGroups, children }: React.PropsWithChildren<IAllowedGroupsValidator>) => {
    const { checkAllowedGroups } = useContext(AdminContext.Context);

    return <>{checkAllowedGroups(allowedGroups) && <>{children}</>}</>;
};

export default AllowedGroupsValidator;
