import React from 'react';

import styled from 'styled-components';

import LoadingImage from '../images/loading.svg';

export const LoadingStyle = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
});

export const SvgContainer = styled.div({
    width: 201,
    height: 201,
});

const Loading = () => {
    return (
        <LoadingStyle>
            <SvgContainer>
                <img src={LoadingImage} alt="Imagem de 'carregamento'" />
            </SvgContainer>
        </LoadingStyle>
    );
};

export default Loading;
