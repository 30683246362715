import React, { useEffect, useState } from 'react';
import {
    RedirectButton,
    BottomContainer,
    InnerContainer,
    MainContainer as MainContainer,
    PainelContainer,
    Text,
    Title,
    TopContainer,
} from './CursosLivresPainel.styled';
import ReactTooltip from 'react-tooltip';
import ShareIcon from '../../assets/ShareIcon.svg';
import { useHistory } from 'react-router-dom';
import InternalCard from '../InternalCard';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import FullDashboardCursos from 'models/perfil/FullDashboardCursos';
import Sharebox from '../Sharebox';
import { PaginasEnum } from 'enums/PaginasEnum';
import { GTM_Page_Meu_Painel } from 'components/GoogleTagManager';
import Colors from 'enums/Colors';

export interface ICursosLivresPainel {
    cursos: FullDashboardCursos;
}

const CursosLivresPainel = ({ cursos }: ICursosLivresPainel) => {
    const history = useHistory();
    const [params, setParams] = useState({ iniciados: 0, finalizados: 0, naoIniciados: 0 });

    const updateCursosValue = () => {
        let params = {
            iniciados: 0,
            finalizados: 0,
            naoIniciados: 0,
        };
        if (cursos != null) {
            params.iniciados = cursos.iniciados;
            params.finalizados = cursos.finalizados;
            params.naoIniciados = cursos.naoIniciados;
        }
        setParams(params);
    };

    useEffect(() => {
        updateCursosValue();
    }, []);

    const goToCursosLivres = () => {
        GTM_Page_Meu_Painel('curso_livre_redirect_button', 'acessar_cursos', 'principal_curso_livre');
        history.push('/cursos-livres');
    };

    const goToCursosIniciados = () => {
        GTM_Page_Meu_Painel('curso_livre_redirect_button', 'acessar_cursos_iniciados', 'principal_pagina_lms_cursos_iniciados');
        window.open('https://cursoslivres.institutoayrtonsenna.org.br/d2l/home');
    };

    return (
        <PainelContainer>
            <MainContainer>
                <TopContainer>
                    <Title>{`Cursos Livres`}</Title>
                    <Sharebox location={PaginasEnum.CURSOS_LIVRES} />
                </TopContainer>

                <InnerContainer>
                    <InternalCard value={params.iniciados} description={'iniciados'} colorPicker={'curso'}></InternalCard>
                    <InternalCard value={params.finalizados} description={'finalizados'} colorPicker={'curso'}></InternalCard>
                    <InternalCard value={params.naoIniciados} description={'não iniciados'} colorPicker={'curso'}></InternalCard>
                </InnerContainer>

                <BottomContainer style={{ justifyContent: 'space-between' }}>
                    <RedirectButton Colors={Colors.Yellow} onClick={goToCursosIniciados}>
                        <Text>{'Acessar Iniciados'}</Text>
                    </RedirectButton>
                    <RedirectButton onClick={goToCursosLivres}>
                        <Text>{'Inscreva-se'}</Text>
                    </RedirectButton>
                </BottomContainer>
            </MainContainer>

            {/* Others */}

            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </PainelContainer>
    );
};

export default CursosLivresPainel;
