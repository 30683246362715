import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const Tipo = styled.p`
    text-transform: uppercase;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 90%;
    color: #003c78;

    @media only screen and (max-width: 1200px) {
        font-size: 12px;
    }
`;
const Titulo = styled(Tipo)`
    text-transform: none;
    font-weight: 700;
    font-size: 48px;

    @media only screen and (max-width: 1200px) {
        font-size: 24px;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const SubTitulo = styled(Tipo)`
    text-transform: none;
    font-size: 18px;
    line-height: 22px;

    @media only screen and (max-width: 1200px) {
        font-size: 14px;
    }
`;

const Container = styled.div<{ paddingTop?: string }>`
    display: flex;
    flex-direction: column;
    max-width: 450px;
    gap: 15px;
    padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
`;
const MidiaContainer = styled.div`
    display: none;

    @media only screen and (max-width: 1200px) {
        display: flex;
        height: 100%;
        justify-content: center;
    }
`;

export interface ITextoApresentacao {
    tipo: string;
    titulo: string;
    subtitulo: string;
    style: { container: {}; titulo: {}; subtitulo: {} };
    midia?: any;
    id?: string;
}

const TextoApresentacao = ({ tipo, titulo, subtitulo, midia, style, children, id }: React.PropsWithChildren<ITextoApresentacao>) => {
    return (
        <Container style={style.container}>
            <TopContainer id={id || ''}>
                <Tipo>{tipo}</Tipo>
                <Titulo style={style.titulo}>{titulo}</Titulo>
            </TopContainer>
            <MidiaContainer style={midia ? {} : { height: '0' }}>{midia}</MidiaContainer>
            <SubTitulo style={style.subtitulo}>{subtitulo}</SubTitulo>
            {children}
        </Container>
    );
};

export default TextoApresentacao;
