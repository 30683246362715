import Feedback, { TipoFuncionalidade } from 'models/Feedback';
import { GenericService } from './Generic.service';

export class FeedbackService extends GenericService {
    readonly URI: string = '/v1/feedback';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public async salvarFeedback(feedback: Feedback) {
        return this.post('inserir', { ...feedback });
    }

    public async existeRegistroFeedback(id_perfil: number, funcionalidade: TipoFuncionalidade) {
        return this.get('existeRegistro', { id_perfil, funcionalidade });
    }
}
