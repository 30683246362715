import TextoApresentacao from 'components/TextoApresentacao';
import { bucketS3, caminhoAnexosGestaoPaginaBanner } from 'constants/EnvironmentData';
import {
    BannerContainerStyle,
    BannerStyle,
    ContentContainer,
    TitleStyle,
    ContentStyle,
    ButtonsContainer,
    Layer,
    TextContainer,
    BannerSection,
    SplitContainer,
    YellowButton,
    ButtonsBox,
    ImgLateral,
    LeftContainer,
    ImgMobile,
} from 'pages/home/components/banner/BannerContainer.styled';

import React, { useState } from 'react';
import Styled from './Banner.styled';
import BannerModel from 'models/generic-components/SecaoBanner';
import GenericComponentsUtils from '../generic-components.utils';
import { useHistory } from 'react-router';
import useLogged from 'hooks/useLogged';
import { GTM_Gestao_Paginas } from 'components/GoogleTagManager';
import ButtonGeneric from '../components/buttonGeneric/ButtonGeneric';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';

export interface IBanner {
    dados: BannerModel;
}

// A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INTITUTO AYRTON SENNA

const Banner = ({ dados }: IBanner) => {
    const history = useHistory();
    const { authenticated } = useLogged();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaBanner();
    };

    const getPathname = (): string => {
        return location.pathname.split('/pagina')[1];
    };

    const acessar = (location: string) => {
        GenericComponentsUtils.gotoLink(location, history, authenticated, dados);
    };

    return (
        <>
            {dados && (
                <>
                    {!dados.paginaLateral ? (
                        <BannerContainerStyle colorFundo={'#FFFFFF'} id="inicio">
                            <BannerStyle svgSource={getS3BaseUrl() + dados.filePath}>
                                <Layer />
                                <ContentContainer>
                                    <TextContainer>
                                        <TitleStyle style={{ color: dados.corTitulo }}>{dados.titulo}</TitleStyle>
                                        <ContentStyle style={{ color: dados.corTexto }}>{dados.descricao}</ContentStyle>
                                    </TextContainer>

                                    <ButtonsContainer>
                                        <ButtonGeneric
                                            name={dados.textoBotaoA}
                                            type={dados.estiloBotaoA as ButtonsCustom}
                                            onClick={() => {
                                                GTM_Gestao_Paginas(getPathname(), `banner_${dados.textoBotaoA}`, `${dados.link}`);
                                                acessar(dados.link);
                                            }}
                                        />
                                    </ButtonsContainer>
                                </ContentContainer>
                            </BannerStyle>
                        </BannerContainerStyle>
                    ) : (
                        <BannerContainerStyle colorFundo={dados.corFundo} id="inicio">
                            <BannerSection>
                                <SplitContainer>
                                    <LeftContainer>
                                        <ImgMobile src={getS3BaseUrl() + dados.filePath} />

                                        <TextoApresentacao
                                            style={{
                                                container: { maxWidth: '650px' },
                                                titulo: { fontSize: 'clamp(24px, 6.5vw, 36px)', color: dados.corTitulo },
                                                subtitulo: { fontSize: '16px', color: dados.corTexto },
                                            }}
                                            tipo=""
                                            titulo={dados.titulo ?? ''}
                                            subtitulo={dados.descricao ?? ''}
                                        >
                                            <ButtonsBox>
                                                <ButtonGeneric
                                                    name={dados.textoBotaoA}
                                                    type={dados.estiloBotaoA as ButtonsCustom}
                                                    styled={{ width: '250px' }}
                                                    onClick={() => {
                                                        GTM_Gestao_Paginas(getPathname(), `banner_${dados.textoBotaoA}`, `${dados.link}`);
                                                        acessar(dados.link);
                                                    }}
                                                />
                                            </ButtonsBox>
                                        </TextoApresentacao>
                                    </LeftContainer>
                                    <ImgLateral src={getS3BaseUrl() + dados.filePath} />
                                </SplitContainer>
                            </BannerSection>
                        </BannerContainerStyle>
                    )}
                </>
            )}
        </>
    );
};

export default Banner;
