import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class FullDashboardBiblioteca extends Serializable {
    @jsonProperty(Number)
    baixados: number = 0;

    @jsonProperty(Number)
    favoritos: number = 0;

    @jsonProperty(Number)
    acervo: number = 0;

    constructor() {
        super();
    }
}

export default FullDashboardBiblioteca;
