import BiblioTipoItem from 'models/biblioteca/BiblioTipoItem';
import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminBiblioItemSave extends Serializable {
    slug: string = '';
    tipoItemBiblioteca: number = BiblioTipoItem.LIVRO;
    autores?: string;
    titulo: string = '';
    conteudo: string = '';
    anoPublicacao?: string;
    numeroPaginas?: number;
    idioma?: string;
    status: 'PUBLICADO' | 'RASCUNHO' = 'RASCUNHO';
    publico: boolean = false;
    linkYoutube?: string[];
    linkSoundCloud?: string[];
    tags?: string[];
    titleTag: string = '';
    metaDescriptionTag: string = '';
}

export default AdminBiblioItemSave;
