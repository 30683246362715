import React from 'react';
import { Modal } from 'react-bootstrap';
import Styled from './AdminHeaderModalConfirmated.styled';
import Colors from 'enums/Colors';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { MdReportProblem } from 'react-icons/md';
import { DangerButton, OutlineButton } from 'components/UI/Buttons';

interface IAdminHeaderModalConfirmated {
    show: boolean;
    isDelete: boolean;
    onHide: () => void;
    handleConfirmated: () => void;
}

const AdminHeaderModalConfirmated = ({ show, isDelete, onHide, handleConfirmated }: IAdminHeaderModalConfirmated) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Styled.Container>
                <MdReportProblem style={{ fontSize: 100, color: Colors.Red }} />
                <Styled.Title>
                    {isDelete
                        ? 'Você está prestes a deletar uma informação. Tem certeza de que deseja fechar agora?'
                        : 'Você está prestes a fechar o modal e perder as informações adicionadas. Tem certeza de que deseja fechar agora?'}
                </Styled.Title>
                <div>
                    <DangerButton style={{ marginRight: 20 }} onClick={() => handleConfirmated()}>
                        {isDelete ? 'Deletar' : 'Fechar'}
                    </DangerButton>
                    <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                </div>
            </Styled.Container>
        </Modal>
    );
};

export default AdminHeaderModalConfirmated;
