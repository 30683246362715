import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import ReferencesEbook from 'pages/ebooks/components/ReferencesEbook';

const ATP_Modulo06 = ({ setImgAmpliar, scrollToDivLink, modulos }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="06"
                titulo={`
            Para saber mais
            `}
                description={`
                  Autoras
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 95,
                }}
            >
                <Styled.ContentWhite>
                    <Styled.TitleYellow>Referências</Styled.TitleYellow>
                    <br />
                    <Styled.TitleYellow style={{ fontSize: 16 }}>
                        Capítulo 1 - Caminhos da alfabetização no Brasil: uma análise histórica
                    </Styled.TitleYellow>
                    <ReferencesEbook
                        text="FREIRE, P. A importância do ato de ler: em três artigos que se completam. 20. ed. São Paulo: Cortez, 1987."
                        url=""
                        lastAccess=""
                    />
                    <ReferencesEbook
                        text="
                        BARROS et al. Educação, alfabetização em múltiplas linguagens e direitos 
                        humanos. São Paulo: IAS; Insper, 2017."
                        url=""
                        lastAccess=""
                    />
                    <ReferencesEbook
                        text={`
                        
                        BRASIL. Ministério da Educação. Instituto Nacional de Estudos e Pesquisas 
                        Educacionais Anísio Teixeira. Censo Escolar 2023: divulgação dos resultados. Brasília, DF: Inep, 2023a. Disponível em:  
                        `}
                        url="https://download.inep.gov.br/censo_escolar/resultados/2023/apresentacao_coletiva.pdf"
                        lastAccess="Acesso em: 2 
                        jul. 2024."
                    />
                    <ReferencesEbook
                        text="BRASIL. Ministério da Educação. Instituto Nacional de Estudos e Pesquisas Educacionais Anísio Teixeira. Relatório de resultados do Saeb 2019: volume 2: 2º ano do ensino fundamental. 2. ed. rev. Brasília, DF: Inep, 2023b. 
                        Disponível em:"
                        url="https://download.inep.gov.br/educacao_basica/saeb/2019/resultados/
                        relatorio_de_resultados_do_saeb_2019_volume_2.pdf "
                        lastAccess="Acesso em: 2 jul. 2024."
                    />
                    <ReferencesEbook
                        text="
                            BRASIL. Ministério da Educação. Instituto Nacional de Estudos e Pesquisas 
                            Educacionais Anísio Teixeira. Relatório de resultados do Saeb 2021: Volume 
                            2: 2º ano do ensino fundamental. 2. ed. rev. Brasília, DF: Inep, 2023c. 
                            Disponível em:"
                        url="https://download.inep.gov.br/educacao_basica/saeb/2021/resultados/
                        relatorio_de_resultados_do_saeb_2021_volume_2.pdf"
                        lastAccess="Acesso em: 2 jul. 2024."
                    />
                    <ReferencesEbook
                        text="6
                    BASSO, F. V.; RODRIGUES, C. G. Avaliação da Alfabetização. Cadernos de 
                    Estudos e Pesquisas em Políticas Educacionais, v. 8, 2023. Disponível em:"
                        url="https://cadernosdeestudos.inep.gov.br/ojs3/index.php/cadernos/article/view/5766/4304"
                        lastAccess=" Acesso em: 2 jul. 2024"
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 96,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="
                    ORGANIZAÇÃO PARA COOPERAÇÃO E DESENVOLVIMENTO ECONÔMICO 
                    (OCDE). PISA 2018 Results (Volume V): Effective Policies, Successful Schools. 
                    Paris: Pisa; OECD Publishing, 2020. Disponível em:"
                        url="https://www.oecd.org/en/publications/pisa-2018-results-volume-v_ca768d40-en.html"
                        lastAccess="Acesso em: 2 jul. 2024."
                    />
                    <ReferencesEbook
                        text="
                        BRASIL. Ministério da Educação. Instituto Nacional de Estudos e Pesquisas 
                        Educacionais Anísio Teixeira. Relatório da pesquisa Alfabetiza Brasil: diretrizes para uma política nacional de avaliação da alfabetização das crianças. 
                        Brasília, DF: Inep, 2023d. Disponível em:"
                        url="https://download.inep.gov.br/publicacoes/institucionais/avaliacoes_e_exames_da_educacao_basica/
                        relatorio_da_pesquisa_alfabetiza_brasil.pdf"
                        lastAccess="Acesso em: 2 jul. 2024."
                    />
                    <Styled.TitleYellow style={{ fontSize: 16 }}>Capítulo 2 - Alfabetização e neurociências</Styled.TitleYellow>
                    <ReferencesEbook text="DEHAENE, S. Reading in the brain. New York: Viking, 2009." />
                    <ReferencesEbook text="DEHAENE S. Inside the letterbox: how literacy transforms the human brain. Ann Arbor: Cerebrum, 2013." />
                    <ReferencesEbook text="PEGADO, F.; NAKAMURA, K.; HANAGAN, T. How does literacy break mirror invariance in the visual system? Frontiers in Psychology, v. 5, 2014" />
                    <ReferencesEbook
                        text="PEGADO, F. et al. A protocol to examine the learning effects of ‘multisystem mapping’ training combined with post-training sleep consolidation 
                    in beginning readers. STAR Protocols, v. 2, p. 100712, 2021."
                    />
                    <ReferencesEbook text="MORAIS, J. Os caminhos da literacia. Cadernos de Linguística, v. 1, n. 1, 2020." />
                    <ReferencesEbook text="SCARBOROUGH (2001). Caderno da Política Nacional de Alfabetização, página 29" />
                    <ReferencesEbook
                        text="AARON, P. G. et al. Diagnosis and treatment of reading disabilities based on the component model of reading. 
                    Journal of Learning Disabilities, v. 41, n. 1, p. 67-84, 2008."
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 97,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="CORDEIRO, C. et al. (2021). Teaching reading comprehension in Portuguese 
                        primary and middle schools. Technology, Knowledge and Learning: Learning 
                        mathematics, science and the arts in the context of digital technologies, v. 
                        26, n. 2, p. 339-354. DOI"
                        url="https://doi.org/10.1007/s10758-020-09486-y"
                    />
                    <ReferencesEbook
                        text="
                        ORGANIZAÇÃO PARA COOPERAÇÃO E DESENVOLVIMENTO ECONÔMICO 
                        (OCDE). Beyond academic learning: First results from the Survey of 
                        Social and Emotional Skills. Paris: OECD Publishing, 2021. DOI "
                        url="https://doi.org/10.1787/92a11084-en"
                    />
                    <ReferencesEbook
                        text="
                        STEPONAVIČIUS, M., C., GRESS-WRIGHT, & LINZARINI, A. (2023), “Social and emotional skills: 
                        Latest evidence on teachability and impact on life outcomes”, OECD Education Working Papers, No. 304, OECD Publishing, Paris"
                        url="ttps://doi.org/10.1787/ba34f086-en"
                    />
                    <ReferencesEbook
                        text="
                        PETSCHER, Y., CABELL, S.Q., CATTS, H.W., COMPTON, D.L., FOORMAN, B.R., 
                        HART, S.A., LONIGAN, C.J., PHILLIPS, B.M., SCHATSCHNEIDER, C., STEACY, 
                        L.M., TERRY, N.P., & WAGNER, R.K. (2020). How the Science of Reading 
                        Informs 21st-Century Education. Reading Research Quarterly, 55(S1), S267–
                        S282."
                        url="https://doi.org/10.1002/rrq.352"
                    />
                    <ReferencesEbook
                        text="
                        SEABRA, A. G., & DIAS, N. M. (2012). Reconhecimento de palavras e compreensão de leitura: dissociação e habilidades linguístico-mnemônicas 
                        preditoras. Neuropsicologia Latinoamericana, 4(spe), 43-56."
                        url="https://dx.doi.org/10.5579/rnl.2012.0101"
                    />
                    <ReferencesEbook
                        text="
                        MONTEIRO, S. M., & SOARES, M. (2014). Processos cognitivos na leitura inicial: relação entre estratégias de reconhecimento de palavras e alfabetização.
                         Educação e Pesquisa, 40, 449-466."
                    />
                    <ReferencesEbook
                        text="
                        YONCHEVA, Y. N.; WISE, J.; MCCANDLISS, B. Hemispheric specialization for 
                        visual words is shaped by attention to sublexical units during initial learning. 
                        Brain Lang, v. 145-146, p. 23-33, 2015."
                    />
                    <ReferencesEbook
                        text="
                        KIM, T. A., DA SILVA LOUREIRO, V., FERRANDINI, L. M., & CARDOSO, F. B. 
                        (2022). Intervenção Neuropsicopedagógica em Habilidades Preditoras da Alfabetização: Revisão de Literatura Sobre Consciência Fonológica. 
                        Epistemologia e Práxis Educativa-EPEduc, 5(1)."
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 98,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="
                        ARAUJO, F. A. S. de et al. Efeitos da alfabetização sobre os resultados nos 
                        anos iniciais do ensino fundamental: análise dos dados longitudinais do 
                        Ceará (SPAECE- Alfa e do 5º ano EF) entre 2016 e 2019. In: encontro da associação nacional dos centros de pós-graduação em economia, 51., 2023, Rio 
                        de Janeiro. Anais […]. Niterói: Anpec, 2023."
                    />
                    <ReferencesEbook
                        text="
                        5BRAGA, L. B. et al. Tracking adult literacy acquisition with functional MRI: 
                        A single-case study. Mind Brain Educ., v. 11, n. 3, p. 121-32, 2017. DOI"
                        url="I http://dx.doi.org/10.1111/mbe.12143"
                    />
                    <ReferencesEbook
                        text="
                       HART, B.; RISLEY, T. R. The early catastrophe: The 30 million word gap by age 3”. American Educator, p. 4-9, 2003."
                    />
                    <Styled.TitleYellow style={{ fontSize: 16 }}>Capítulo 3 - Educação infantil: a fase de cuidar do broto</Styled.TitleYellow>
                    <ReferencesEbook
                        text="
                        NATAL, J. F. <strong> Análise sobre a relação entre saber ler ou não e estar matriculado ou não no ensino infantil.</strong> 2013. Projeto (Bolsas no Brasil – Iniciação 
                        Científica) – Fundação de Amparo à Pesquisa do Estado de São Paulo, São 
                        Paulo, 2013."
                    />
                    <ReferencesEbook
                        text="
                        SANTOS, D. D. <strong> Impactos do ensino infantil sobre o aprendizado: </strong> benefícios positivos, mas desiguais. 2015. Tese (Livre-docência em Economia) – 
                        Faculdade de Economia, Administração e Contabilidade de Ribeirão Preto, 
                        Universidade de São Paulo, São Paulo, 2015."
                    />
                    <ReferencesEbook
                        text="
                        CUNHA, F. et al. Interpreting the Evidence on Life Cycle Skill Formation. 
                        In: HANUSHEK, E.; WELCH, F. (ed.). <strong> Handbook of the Economics of 
                        Education. </strong> Amsterdam: Elsevier, 2006. p. 697-812. v. 1. DOI 10.1016/
                        S1574-0692(06)01012-9."
                    />
                    <ReferencesEbook
                        text="
                        CIPRIANO, A. C. <strong> Sumário executivo da avaliação da qualidade da educação infantil: </strong> um retrato pós-BNCC. São Paulo: Fundação Maria Cecilia Souto 
                        Vidigal, 2022. Disponível em:"
                        url="https://www.fmcsv.org.br/pt-BR/biblioteca/estudo-nacional-qualidade-educacao-infantil/"
                        lastAccess="Acesso em: 16 jul. 2024."
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 99,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="
                        CAMPOS, M. M. Entre as políticas de qualidade e a qualidade das práticas. 
                       <strong> Cadernos Pesquisa </strong>, v. 43, n. 148, p. 22-43, 2013. DOI10.1590/S0100-15742013000100003."
                    />
                    <ReferencesEbook
                        text="
                        SCHWEINHART, L. J.; WEIKART, D. P. The High/Scope Perry Preschool 
                        Program. In: PRICE, R. H. et al. (eds.). <strong> Fourteen ounces of prevention:  </strong>
                        A casebook for practitioners. Washington, DC: American Psychological 
                        Association, 1988. p. 53-65. DOI"
                        url="https://doi.org/10.1037/10064-005"
                    />
                    <ReferencesEbook
                        text="
                        TINGEY-MICHAELIS, C. The importance of structure in early education programs for disadvantaged and handicapped children. <strong> Early Child 
                        Development and Care, </strong> v. 23, n. 4, p. 283-297, 1986. DOI"
                        url="https://doi.org/10.1080/0300443860230403"
                    />
                    <ReferencesEbook
                        text="
                        SYLVA, K. et al. <strong> Students’ educational and developmental outcomes at 
                        age 16: </strong> Effective Pre-school, Primary and Secondary Education (EPPSE 
                        3-16) Project. Oxford: University of Oxford; London: University of London, 
                        2014. Disponível em:"
                        url="https://ora.ox.ac.uk/objects/uuid:eda164e0-5005-41c6-aee5-9d4eadf2c118/files/m98b4c5d765f56c819db0b1f5821a30c4"
                        lastAccess="Acesso em: 16 jul. 2024."
                    />
                    <ReferencesEbook
                        text="
                       MAGNUSSUN, K.; RUHM, C.; WALDFOGEL, J. Does prekindergarten improve 
                       school preparation and performance? <strong> Economics of Education Review, </strong> v. 26, n. 1, p. 33-51, 2007. Disponível em:"
                        url="https://www.sciencedirect.com/journal/economics-of-education-review/vol/26/issue/1"
                        lastAccess="Acesso em: 16 jul. 2024."
                    />
                    <ReferencesEbook
                        text="
                      SYLVA, K. et al. <strong> Early childhood matters: </strong> evidence from the effective pre-school and primary education project. Oxfordshire: Routledge, 2010."
                    />
                    <ReferencesEbook
                        text="
                      ABUCHAIM, B. de O. <strong> Panorama das políticas públicas de primeira infância. </strong> Brasília, DF: Unesco, 2018."
                    />
                    <ReferencesEbook
                        text="
                      BRASIL. Ministério da Educação. <strong> Base Nacional Comum Curricular. </strong> Brasília, DF: MEC, 2016."
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 100,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="
                        INSTITUTO BRASILEIRO DE GEOGRAFIA E ESTATÍSTICA (IBGE). <strong> Pesquisa 
                        Nacional por Amostra Domiciliar Contínua – Educação 2023. </strong> IBGE, 2024. 
                        Disponível em:"
                        url="https://biblioteca.ibge.gov.br/visualizacao/livros/liv102068_informativo.pdf"
                    />
                    <ReferencesEbook
                        text="
                         BRASIL. <strong> Plano Nacional de Educação (PNE) – Lei n. 13.005/2014. </strong> 2014."
                    />

                    <ReferencesEbook
                        text="
                        MARTINS, N. F. A.; GOMES, L. A. C. Perfil síntese da primeira infância e famílias 
                        no cadastro único. In: BRASIL. Ministério do Desenvolvimento e Assistência 
                        Social, Família e Combate à Fome. Secretaria de Avaliação, Gestão da 
                        Informação e Cadastro Único. <strong> Cadernos de Estudos Desenvolvimento 
                        Social em Debate. </strong> Brasília, DF: MDS, 2024. n. 36. Disponível em:"
                        url="https://aplicacoes.mds.gov.br/sagi/pesquisas/documentos/estudo_pesquisa/estudo_pesquisa_300.pdf"
                        lastAccess="Acesso em: 16 jul. 2024."
                    />
                    <ReferencesEbook
                        text="
                        REYNOLDS, A. J. et al. Schoolbased early childhood education and age-28 well-being: effects by timing, dosage, and subgroups. <strong> Science, </strong> v. 333, n. 
                        6040, p. 360-364, 2011. DOI 10.1126/science.1203618."
                    />
                    <Styled.TitleYellow style={{ fontSize: 16 }}>Capítulo 4 - Alfabetização integral</Styled.TitleYellow>
                    <ReferencesEbook
                        text="
                        AARON, P. G. et al. Diagnosis and treatment of reading disabilities based on 
                        the component model of reading. Journal of Learning Disabilities, v. 41, n. 1, 
                        p. 67-84, 2008."
                    />
                    <ReferencesEbook
                        text="BRASIL. <strong> Plano Nacional de Educação (PNE) – Lei n. 13.005/2014. </strong> 2014"
                    />
                    <ReferencesEbook
                        text="SEN, A. <strong> Algumas ideias sobre o Dia Internacional da Alfabetização </strong>
                            [Mensagem gravada para as celebrações do Dia Internacional da 
                            Alfabetização/ONU]. [S. l.], 2003."
                    />
                    <ReferencesEbook
                        text="BRASIL. <strong> Constituição da República Federativa do Brasil de 1988. </strong> Brasília, DF: Presidência da República, [2024a]. Disponível em:"
                        url='https://www.planalto.gov.br/ccivil_03/Constituicao/Constituicao.htm'
                        lastAccess='Acesso em: 16 maio 2024.'
                    />
                    <ReferencesEbook
                        text="BRASIL. <strong> Lei nº 9.394, de 20 de dezembro de 1996. </strong> Estabelece as diretrizes e bases da educação nacional. 
                        Brasília, DF: Presidência da República, [2024b]. Disponível em:"
                        url='http://www.planalto.gov.br/ccivil03/leis/L9394.htm'
                        lastAccess='Acesso em: 10 maio 2024.'
                    />
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Para saber mais',
                }}
                footer={{
                    numberSummary: 101,
                }}
            >
                <Styled.ContentWhite>
                    <ReferencesEbook
                        text="
                        BRASIL. <strong> Lei nº 14.640, de 31 de julho de 2023. </strong> Institui o Programa Escola 
                        em Tempo Integral; e altera as Leis nos 11.273, de 6 de fevereiro de 2006, 
                        13.415, de 16 de fevereiro de 2017, e 14.172, de 10 de junho de 2021. Brasília, DF: 
                        Presidência da República, [2024d]. Disponível em:"
                        url="http://www.planalto.gov.br/ccivil_03/_ato2023-2026/2023/lei/L14640.htm"
                        lastAccess='Acesso em: 10 maio 2024.'
                    />
                    <ReferencesEbook
                        text="COMPROMISSO Nacional Criança Alfabetizada. <strong> Ministério da Educação, </strong> [2024]. Disponível em:"
                        url="https://www.gov.br/mec/pt-br/crianca-alfabetizada"
                        lastAccess='Acesso em: 10 maio 2024. Acesso em: 16 maio 2024.'
                    />
                    <ReferencesEbook
                        text="INSTITUTO AYRTON SENNA (IAS). <strong> Guia educação integral na alfabetização. </strong> São Paulo: IAS, 2019. Disponível em:"
                        url="https://institutoayrtonsenna.org.br/app/uploads/2022/10/instituto-ayrton-senna-guia-educacao-integral-na-alfabetizacao-v2.pdf"
                        lastAccess='Acesso em: 10 maio 2024.'
                    />
                    <ReferencesEbook
                        text="ORGANISATION FOR ECONOMIC CO-OPERATION AND DEVELOPMENT (OECD). Pisa 2018 Results (Volume III): What school life means for student´s lives. Disponível"
                        url="https://www.oecd-ilibrary.org/education/pisa-2018-results-volume-iii_d69dc209-en"
                    />
                    <ReferencesEbook
                        text="INSTITUTO AYRTON SENNA (IAS). <strong> Competências socioemocionais: </strong> a 
                        importância do desenvolvimento e monitoramento para a educação integral. São Paulo: IAS, 2021. Disponível em:"
                        url="https://institutoayrtonsenna.org.br/app/uploads/2022/12/instituto-ayrton-senna-avaliacao-socioemocional-1.pdf"
                        lastAccess='Acesso em: 10 maio 2024'
                    />
                    <ReferencesEbook
                        text="ORGANISATION FOR ECONOMIC CO-OPERATION AND DEVELOPMENT 
                        (OECD). <strong> Social and Emotional Skills for Better Lives: </strong> Findings from the 
                        OECD Survey on Social and Emotional Skills 2023. Paris: OECD Publishing, 
                        2024. Disponível em:"
                        url="https://doi.org/10.1787/35ca7b7c-en"
                        lastAccess='Acesso em: 16 maio 2024'
                    />
                   
                </Styled.ContentWhite>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo06;
