import { MobileMaxWidth } from 'constants/Dimensions';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const BiblioCollectionPageStyled = {
    Container: styled.div`
        padding: 0px 30px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 20px 15px;
        }
    `,

    DotMenu: styled.img`
        margin-left: 10px;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    `,

    Head: styled.div`
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    RightContainer: styled.div`
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    `,

    CountChar: styled.p`
        font-size: 12px;
    `,

    LeftContainer: styled.div``,

    Title: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #003c78;
        margin-bottom: 5px;
    `,

    SubTitle: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #2e2e2e;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 15px 0;
        }
    `,

    SearchContainer: styled.div`
        width: 50%;
        height: 38px;
        max-width: 390px;
        display: flex;

        border: 1px solid #dadada;
        border-radius: 6px;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
        }
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        color: grey;

        &::placeholder {
            color: grey;
            opacity: 0.6;
        }
    `,

    //==== MODAL CRIACAO/EDICAO ========

    Modal: {
        Component: styled(Modal)``,
        Container: styled.div`
            padding: 25px;
        `,

        Title: styled.p`
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: 0.04em;
            color: #00a8e9;
        `,

        Subtilte: styled.p`
            font-size: 18px;
            line-height: 22px;
            color: #2e2e2e;
        `,

        LoadingText: styled.div`
            font-size: 12px;
            opacity: 0.5;
        `,

        ContainerItem: styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            border: 1px solid #dedede;
            border-radius: 5px;
            margin: 7px 0;
            padding: 5px;
            cursor: pointer;

            :hover {
                border: 1px solid #00a8e9;
                color: #00a8e9;
                & > p {
                    color: #00a8e9;
                }
            }
        `,

        ScrollContainer: styled.div`
            max-height: 175px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 12px;
                box-sizing: content-box;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d8d8d8; /* color of the scroll thumb */
                border-radius: 20px; /* roundness of the scroll thumb */
                margin: 10px;
                border: 4px solid white;
                box-sizing: content-box;
            }

            &::-webkit-scrollbar-track {
                background: transparent; /* color of the tracking area */
            }
        `,

        ImageItem: styled.img`
            height: 55px;
            width: 55px;
            border-radius: 6px;
            object-fit: cover;
        `,

        LabelItem: styled.p`
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #747474;
            padding: 0 10px;
        `,

        IconClose: styled.img`
            height: 25px;
            width: 25px;
            cursor: pointer;
        `,

        CloseContainer: styled.div`
            text-align: right;
        `,

        TitleCyan: styled.p`
            font-weight: 500;
            font-size: 24px;
            line-height: 42px;
            letter-spacing: 0.04em;
            color: #00a8e9;
        `,

        InputEdit: styled.input`
            padding: 15px;
            color: #2e2e2e;
            width: 100%;
            outline: none;
            border-radius: 5px;
            border: 1px solid #dedede;
            margin-top: 10px;
        `,

        CharCounter: styled.p`
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #747474;
            margin-top: 10px;
        `,

        DangerText: styled.p`
            font-weight: 600;
            font-size: 16px;
            line-height: 42px;
            letter-spacing: 0.04em;
            text-decoration-line: underline;
            color: #ff4830;
            cursor: pointer;
        `,

        ContainerBottom: styled.div`
            text-align: center;
            display: flex;
            justify-content: center;
        `,

        DangerIcon: styled.img`
            height: 60px;
            width: auto;
        `,

        BottomContainer: styled.div`
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-top: 20px;
        `,

        Text: styled.p`
            margin: 20px 0;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #2e2e2e;
        `,

        TextBold: styled.span`
            font-weight: 700;
        `,
    },
};

export default BiblioCollectionPageStyled;
