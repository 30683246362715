import { jsonProperty, Serializable } from 'ts-serializable';

class CorrespondentItemModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(Boolean)
    fixed: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(Number)
    codeInVinculaty: number = -1;
    
}



export default CorrespondentItemModel;
