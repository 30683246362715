import { createContext } from 'react';

import DiarioTag from 'models/diarioBordo/DiarioTag';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import DiarioAttach from 'models/diarioBordo/DiarioAttach';

export namespace DiarioBordoContext {
    export interface IContext {
        tags: DiarioTag[]; //já filtradas de acordo com o item atual
        current: DiarioBordo;
        showableCurrent: boolean;
        setShowableCurrent: (value: boolean) => void;
        items: DiarioBordo[];
        isLoadingDiary: boolean;
        showAttachConfirmation: boolean;
        showRegisterConfirmation: boolean;
        showMaxLengthAttachAlert: boolean;
        profile: PerfilUsuario | null;

        onEditting: boolean;
        setOnEditting: (value: boolean) => void;

        onCreate: () => Promise<void>;
        onDelete: (item: DiarioBordo) => Promise<void>;
        onDeleteConfirm: () => Promise<void>;
        onSelect: (item: DiarioBordo) => Promise<void>;
        onDuplicate: (item: DiarioBordo) => Promise<void>;
        onUpdate: () => Promise<void>;
        onCancel: () => void;
        onSearch: (text: string) => Promise<void>;

        onAddTag: (item: number) => Promise<void>;
        onRemoveTag: (item: number) => Promise<void>;

        onAddAttach: (file: File | File[]) => Promise<void>;
        onSizeErrorAttach: () => void;
        onRemoveAttach: (item: DiarioAttach) => Promise<void>;
        onRemoveAttachConfirm: () => Promise<void>;
        onDownloadAttach: (attacth: DiarioAttach) => Promise<void>;

        // onPrintDiario
    }

    export const Context = createContext({} as IContext);
}
