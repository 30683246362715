import React from 'react';

import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import Colors from '../../../../../enums/Colors';
import icon from '../../../../../images/profile/alert-icon.svg';

interface IModalSizeImage {
    show: boolean;
    onCloseCallback?: any;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

const IconAlert = styled.img`
    width: 73px;
    height: 'auto';
    margin: 20px 0;
`;

const TextContent = styled.p`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    width: 346px;
    margin: 20px 0;
`;

const ButtonBack = styled.button`
    width: 190px;
    height: 42px;
    background: ${Colors.InfoRed};
    border-radius: 50px;
    outline: none;
    border: none;
    color: ${Colors.White};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
`;

const ModalSizeImage = ({ show, onCloseCallback }: IModalSizeImage) => {
    return (
        <Modal show={show} backdrop="static" keyboard={false} centered>
            <Modal.Body>
                <Container>
                    <IconAlert src={icon} />
                    <TextContent>A imagem escolhida é maior que o tamanho especificado</TextContent>
                    <ButtonBack onClick={onCloseCallback}>Voltar</ButtonBack>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ModalSizeImage;
