import React, { useContext, useEffect } from 'react';
import IntermediatePage from 'pages/intermediarias/components/IntermediatePage';
import IntermediatePageContent, { IntermediatePageContentSection } from 'pages/intermediarias/components/IntermediatePageContent';
import Menu from 'pages/menu/Menu';
import Lampada from '../../../images/homepage/educationalSolutions/lampada.svg';
import JornadaDesenvolvimento from 'pages/intermediarias/criatividadeDetail/subcomponents/jornadaDesenvolvimento/JornadaDesenvolvimento';
import CursoLivre from 'pages/intermediarias/criatividadeDetail/subcomponents/cursoLivre/CursoLivre';
import Guia from 'pages/intermediarias/criatividadeDetail/subcomponents/guia/Guia';

import useLogged from 'hooks/useLogged';
import { useHistory, useLocation } from 'react-router-dom';
import { cursoLivrePensamentoCritico } from 'constants/EnvironmentData';
import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import ABTest from 'components/ab_tests/ABTest';
import TestVersion, { getURLParamFromVersion } from 'enums/TestVersions';
import CriatividadeUpdated from './versions/CriatividadeUpdated';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface IcriatividadeDetail {}

const UTMContentName = 'utm_content';

const CriatividadeDetail = ({}: IcriatividadeDetail) => {
    const history = useHistory();
    const PAGE_KEY = PaginasIntermediariasEnum.CRIATIVIDADE_PENSAMENTO_CRITICO;

    const { authenticated, login } = useLogged();
    const location = useLocation();

    const setupRedirectToVersion = () => {
        const serachparams = new URLSearchParams(location.search);
        const versionTest = ABTest.getCurrentVersion();

        if (serachparams.has(UTMContentName)) {
            const value = serachparams.get(UTMContentName);

            if (value && !value.includes('testeAB-versao')) {
                serachparams.delete(UTMContentName);
                serachparams.append(UTMContentName, getURLParamFromVersion(versionTest));
            }
        } else {
            serachparams.append(UTMContentName, getURLParamFromVersion(versionTest));
        }

        history.push(location.pathname + '?' + serachparams.toString());
    };

    useEffect(() => {
        //setupRedirectToVersion();
        //ABTest.sendGTM_Event();
    }, []);

    const gotoCursoLivre = () => {
        if (authenticated) {
            const url = cursoLivrePensamentoCritico();
            window.open(url, '_blank');
        } else {
            login(location.pathname + '#cursos', '');
        }
    };

    const sections: IntermediatePageContentSection[] = [
        {
            section: 'Jornada',
            content: <JornadaDesenvolvimento />,
            subroute: 'jornada',
        },
        {
            section: 'Curso Livre',
            content: (
                <CursoLivre
                    gotoCursoLivre={() => {
                        GTM_Page_Intermediaria('cpc_intermediaria', `cpc_inscreva_se_curso_livre`);
                        gotoCursoLivre();
                    }}
                />
            ),
            subroute: 'cursos',
        },

        {
            section: 'Guia',
            content: (
                <Guia
                    gotoCursoLivre={() => {
                        GTM_Page_Intermediaria('cpc_intermediaria', `cpc_acessar_curso_livre`);
                        gotoCursoLivre();
                    }}
                />
            ),
            subroute: 'guia',
        },
    ];

    const CriatividadeOld = () => {
        return (
            <>
                {/*  <Menu homepage homeIntermediate /> */}

                <DynamicHeader />

                <IntermediatePage
                    helmetTitle={'Jornada de desenvolvimento'}
                    helmetMetaDescription={''}
                    title1={'Jornada de desenvolvimento'}
                    title2={'Criatividade e pensamento crítico'}
                    icon={Lampada}
                    breadCrumbName={'Jornada Criatividade e pensamento crítico'}
                >
                    <IntermediatePageContent
                        pageKey={PAGE_KEY}
                        pageRoot="criatividade-pensamento-critico"
                        sections={sections}
                        isOffsetRight={true}
                    />
                </IntermediatePage>
            </>
        );
    };

    const redirectTestAB = () => {
        const currentVersion = ABTest.getCurrentVersion();
        if (currentVersion == TestVersion.VersionA || currentVersion == TestVersion.VersionB) {
            return <CriatividadeOld />;
        } else {
            return <CriatividadeUpdated />;
        }
    };

    //return <>{redirectTestAB()}</>;

    return <CriatividadeUpdated />;
};

export default CriatividadeDetail;
