function urlToImageFile(url: string): Promise<Blob> {
    const xhr = new XMLHttpRequest();

    return new Promise(resolve => {
        xhr.onload = function () {
            resolve(xhr.response);
        };

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

export default urlToImageFile;
