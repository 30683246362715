import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { H1 } from '../../components/UI/Texts';
// import DiarioBordoDialog from './DiarioBordoDialog';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { DiarioBordoStyle } from './DiarioBordo.styled';
import DiarioInfo from './components/moreInfo/DiaryInfo';
import DiarioText from './components/textEditor/DiaryText';
import DiarioCards from './components/diariesCards/DiaryCards';
import { DiarioBordoContext } from './context/DiarioContext';
import { showDialogFeedback } from './utils/showDialogFeedback';
import DialogFeedback from 'components/feedbackDialog/DialogFeedback';
import { TipoFuncionalidade } from 'models/Feedback';
import DiarioBordoMobile from './DiarioBordoMobile';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

interface IDiarioBordo {}

const DiarioBordo: FunctionComponent<IDiarioBordo> = (_: React.PropsWithChildren<IDiarioBordo>) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(true);
    const [isShowDialogFeedback, setIsShowDialogFeedback] = useState<boolean>(false);

    const isMobile = IsMobileMaxWidth();

    const { items, onCreate, current, showableCurrent, onSelect, setOnEditting } = useContext(DiarioBordoContext.Context);
    const profile = useAppSelector(state => state.profileReducer.userProfile);

    const onCreateHandler = async () => {
        await onCreate();
        setOnEditting(true);
    };

    const checkFeedback = async () => {
        if (!isModalOpen) {
            const _isShowDialogFeedback = await showDialogFeedback(items, profile);
            setIsShowDialogFeedback(_isShowDialogFeedback);
        }
    };

    const setupSectionSe = (value: string) => {
        const el = document.getElementById('section-pse');
        if (el) el.style.maxWidth = value;
    };

    useEffect(() => {
        checkFeedback();
    }, [isModalOpen]);

    useEffect(() => {
        setupSectionSe('unset');

        return () => setupSectionSe('1253px');
    }, []);

    return (
        <>
            {!isMobile ? (
                <DiarioBordoStyle.Container>
                    <DiarioBordoStyle.TopContainer>
                        <DiarioBordoStyle.Title>Diário de Bordo</DiarioBordoStyle.Title>
                        <DiarioBordoStyle.NewDiaryButton
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'novo_registro_btn');
                                onCreateHandler();
                            }}
                        >
                            Novo registro
                        </DiarioBordoStyle.NewDiaryButton>
                    </DiarioBordoStyle.TopContainer>

                    <DiarioBordoStyle.Content>
                        <DiarioCards />

                        {current && showableCurrent ? (
                            <DiarioText />
                        ) : (
                            <DiarioBordoStyle.EmptyContainer>
                                <DiarioBordoStyle.EmptyText>
                                    Para ver seus registros anteriores, selecione o registro desejado ao lado.
                                </DiarioBordoStyle.EmptyText>
                            </DiarioBordoStyle.EmptyContainer>
                        )}
                        {/* <DiarioInfo /> */}
                    </DiarioBordoStyle.Content>
                    {/*<DiarioBordoDialog visible={isModalOpen} onBeginCallback={onCloseInitialPopupHandler} onCloseCallback={onCloseInitialPopupHandler} />*/}
                    <DialogFeedback
                        show={isShowDialogFeedback}
                        nomeFucionalidade="Diário de Bordo"
                        funcionalidade={TipoFuncionalidade.DIARIOBORDO}
                        onSubmit={() => setIsShowDialogFeedback(false)}
                        onHide={() => setIsShowDialogFeedback(false)}
                    />
                </DiarioBordoStyle.Container>
            ) : (
                <DiarioBordoMobile />
            )}
        </>
    );
};

export default DiarioBordo;
