import { jsonProperty, Serializable } from 'ts-serializable';
import ComponentItemCarrosselInteraction from './ComponentItemCarrosselInteraction';

class ComponentCarrosselInteraction extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    itens: ComponentItemCarrosselInteraction[] | null = [];
}

export default ComponentCarrosselInteraction;
