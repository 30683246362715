import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminSocioEmocionalStyled = {
    Container: styled.div`
        background-color: white;
        padding: 20px;
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 26px;
        line-height: 42px;
        color: #183f73;
    `,

    ButtonDownload: styled.div`
        display: flex;
        justify-content: right;
    `,

    TopBar: styled.div`
        background: #ffffff;
        box-shadow: 1px 1px 10px rgba(0, 60, 120, 0.1);
        border-radius: 8px;
        padding: 20px 20px 30px 20px;

        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    TopBlueBar: styled.div`
        background: linear-gradient(318.36deg, #00a8e9 0%, #47aefc 100%);
        border-radius: 5px;
        width: 100%;
        margin-bottom: 15px;
        padding: 15px 20px;

        display: flex;
        justify-content: left;

        p {
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            display: flex;
            align-items: center;
            letter-spacing: 0.04em;
            color: #ffffff;
        }
    `,

    LeftButton: styled(OutlineButton)`
        border-color: white;
        color: white;
        background-color: transparent;
        transform: scale(0.85);
    `,

    SearchContainer: styled.div`
        width: 50%;
        height: 38px;
        max-width: 390px;
        display: flex;

        border: 1px solid #dadada;
        border-radius: 6px;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;
        overflow: hidden;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
            width: 100%;
            margin-bottom: 15px;
        }
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        color: grey;

        &::placeholder {
            color: grey;
            opacity: 0.6;
        }
    `,

    Grid: styled.div`
        display: grid;
        grid-template-columns: repeat(3, 160px) 1fr 180px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    GridHead: styled.div`
        background: #e5f6fd;
        color: #003c78;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
    `,

    GridCol: styled.div<{ leftAlign?: boolean }>`
        width: 100%;
        padding: 10px;
        font-size: 14px;
        align-items: center;
        color: #2e2e2e;
        height: 56px;

        border-bottom: 0.5px #dcdcdc solid;

        display: flex;
        align-self: center;
        justify-content: ${props => (props.leftAlign ? 'flex-start' : 'center')};
    `,

    BottonPage: styled.div`
        width: 100%;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
    `,

    ButtonPage: styled.button<{ selected?: boolean }>`
        width: 38px;
        height: 32px;
        outline: none;
        border: 1.5px #003c78 solid;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin: 7px 5px;

        background-color: ${props => (props.selected ? '#003c78' : 'white')};
        color: ${props => (!props.selected ? '#003c78' : 'white')};
    `,

    RowRigth: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        .label-order {
            font-size: 14px;
            color: #00a8e9;
            font-weight: 600;
        }

        .span-select {
            margin-left: 0px;
            transform: scale(0.85) translateX(-10px);
        }
    `,

    ArrowButtonPage: styled.button`
        width: 38px;
        height: 32px;
        outline: none;
        border-radius: 5px;
        text-align: center;
        margin: 7px 5px;
        border: none;
        background-color: white;
        vertical-align: middle;

        border: 1.5px #747474 solid;
    `,

    ViewButton: styled.img`
        cursor: pointer;
    `,

    //=========MODAL ITEM============

    Modal: {
        Title: styled.p`
            font-size: 16px;
            line-height: 19px;
            color: #2aabf4;
        `,

        SubTitle: styled.p`
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #1e4e7e;
            margin: 10px 0;
        `,

        UserName: styled.span`
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #003c78;
            margin-right: 10px;
        `,

        Date: styled.span`
            font-size: 14px;
            line-height: 17px;
            color: #2e2e2e;
        `,

        Text: styled.div`
            font-size: 16px;
            line-height: 19px;
            color: #2e2e2e;
            overflow-wrap: break-word;
            margin: 10px 0;
        `,

        ButtonArea: styled.div`
            text-align: right;
            margin-top: 10px;
        `,

        GradientButton: styled(GradientButton)`
            width: 100px;
            height: 35px;
            font-size: 0.8em;
        `,

        ExcludeContainer: styled.div`
            text-align: right;
            color: #e53935;
            font-size: 0.85em;

            .exclude-inicial {
                cursor: pointer;
                font-weight: 600;
            }

            .exclude-ask {
                font-weight: 600;
            }

            .exclude-confirm {
                color: #e53935;
                font-size: 0.9em;
                cursor: pointer;
                margin: 0 5px;
                font-weight: 500;

                :hover {
                    text-decoration: underline;
                }
            }
        `,
    },
};

export default AdminSocioEmocionalStyled;
