import React, { useState, ReactChildren, ReactChild } from 'react';

interface IProgressContext {
    progresso: number;
    setProgresso: (progresso: number) => void;
}
const ProgressContext = React.createContext({} as IProgressContext);

const ProgressProvider = ProgressContext.Provider;

export const ProgressConsumer = ProgressContext.Consumer;

interface IMyProgressContext {
    children: ReactChild | ReactChildren;
}

export const MyProgressContext = ({ children }: IMyProgressContext) => {
    const [progresso, setProgresso] = useState<number>(0);

    const defaultContext: IProgressContext = {
        progresso,
        setProgresso,
    };

    return <ProgressProvider value={defaultContext}>{children}</ProgressProvider>;
};

export default {};
