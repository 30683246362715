import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledTagSolucoes = {
    Container: styled.div`
        display: flex;
        align-items: start;

    `,

    Tag: styled.span<{ bgColor: String; color: String, outline?: boolean }>`
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 63px;
        background-color: ${({ bgColor }) => (bgColor ? `${bgColor}` : '')};
        color: ${({ color }) => (color ? `${color}` : '')};
        border: ${({outline}) => outline && `1px solid ${Colors.Blue}`};
        font-size: 12px;
    `,
};

export default StyledTagSolucoes;
