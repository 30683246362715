import styled from 'styled-components';
import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';
import { media760, mediaOnlyScreenMaxWidth } from '../../../core/util/cssHelp';
import FullCss from 'core/util/FullCss';

interface IVisualizacaoContainer {}
export const VisualizacaoContainer = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            backgroundColor: Colors.White,
            padding: '48px',
        },
        {
            padding: '48px 20px',
        },
        1300
    )
);

export const ProfileSection = styled.section({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '30px',
});

export const Section = styled.section({
    marginTop: '30px',
});

export const ProfileInfoCard = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '72px',
        },
        {
            marginRight: '0px',
            width: '50%',
        },
        760
    )
);

export const ProfileInfoText = styled.div({
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: Colors.DarkGrey,
});

export const AnswerText = styled.p({
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: Colors.DarkGrey,
});

export const SelectedMacroCard = styled.div({
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '20px',
    paddingLeft: '20px',
    backgroundColor: Colors.SoftBlue3,
    borderRadius: '50px',
    color: Colors.SoftBlue,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '14px',
    marginRight: '16px',
    marginBottom: '12px',
});

export const FlexContainer = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            display: 'flex',
        },

        {
            flexDirection: 'column',
        },
        920
    )
);

export const FlexColumnOnlyContainer = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '20px',
            width: '50%',
            wordBreak: 'break-word',
        },

        {
            marginRight: '0px',
            marginBottom: '20px',
            width: '100%',
        },
        920
    )
);

export const GoToTopContainer = styled.div(
    media760(
        {},

        {
            marginTop: '10px',
        }
    )
);

export const Separator = styled.div(new FullCss({ paddingTop: '20px', borderBottom: '1px solid #cbd2e0', width: '100%' }).getCss());

export const CentralSection = styled.div`
    margin-left: 15vw;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: 0;
        height: 0;
    }
`;
