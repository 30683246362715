import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import QuestionIndicator from './QuestionIndicator';
import Title from './Title';
import { media760, mediaOnlyScreenMaxWidth } from '../../core/util/cssHelp';

const QuestionHolderContainer = styled.div(
    media760(
        {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    )
);

const CustomTitleContainer = styled.div(
    media760(
        {
            marginLeft: '17px',
        },
        {
            marginLeft: '0px',
            marginTop: '10px',
        }
    )
);

interface IQuestionHolder {
    numberValue: number;
    question: string;
}
const QuestionHolder: FunctionComponent<IQuestionHolder> = ({ ...props }: IQuestionHolder) => {
    interface ICustomTitle {
        question: string;
    }
    const CustomTite: FunctionComponent<ICustomTitle> = ({ ...props }: ICustomTitle) => {
        return (
            <CustomTitleContainer>
                <Title size={'s'}>{props.question}</Title>
            </CustomTitleContainer>
        );
    };

    return (
        <QuestionHolderContainer>
            <QuestionIndicator value={props.numberValue} />
            <CustomTite question={props.question} />
        </QuestionHolderContainer>
    );
};
export default QuestionHolder;
