import React, { useState, useEffect } from 'react';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import { FormHeaderStyle, Title, SubTitle } from './FormHeader.styled';

interface IFormHeader {
    title: string;
    subtitle: string;
    secondSubtitle?: string;
}
const FormHeader = ({ title, subtitle, secondSubtitle }: IFormHeader) => {
    const TitleComponent = () => {
        useEffect(() => {}, [title]);
        return <Title>{title}</Title>;
    };

    return (
        <FormHeaderStyle>
            <TitleComponent />
            <SubTitle>{subtitle}</SubTitle>
            {secondSubtitle ? <SubTitle>{secondSubtitle}</SubTitle> : <></>}
        </FormHeaderStyle>
    );
};

export default FormHeader;
