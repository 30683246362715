import { jsonProperty, Serializable } from 'ts-serializable';
import ComponentInteractionModel from './ComponentInteraction';
import ComponentSanfonaConteudoModel from './ComponentSanfonaConteudo';

class ComponentSanfonaModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    componentSanfonaConteudos: ComponentSanfonaConteudoModel[] | null = [];

}


export default ComponentSanfonaModel;