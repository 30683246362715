import React from 'react';
import CellInfo from './CellInfo';
import {
    ButtonProfileContainer,
    CellInfoContainer,
    CellInfoContainerMobile,
    CellInfoExternalMobile,
    CellInfoInternalMobile,
    HeadInfoStyle,
    ImgSvg,
    ProfileContainer,
    ProfileImage,
    SubtitleProfile,
    Text,
    TitleContainer,
    TitleDiv,
    TitleUsername,
    VerPainelButton,
} from './HeadInfo.styled';
import TipoImagem from 'models/imagemPerfil/TipoImagemEnum';
import AvatarDefault from './AvatarDefault';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { useHistory } from 'react-router-dom';
import { fetchRoleDescription, getUserRole } from 'core/util/KeycloakHelp';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { RoleEnum } from 'enums/RoleEnum';
import ReactTooltip from 'react-tooltip';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import occupations, { occupationDescription, occupationIsOther } from 'data/userOccupations';
import PlusCircle from './assets/plus-circle.svg';
import { MobileMaxWidth } from 'constants/Dimensions';

export interface IHeadInfo {
    profile?: ProfileWithAvatar;
}

const HeadInfo = ({ profile }: IHeadInfo) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const history = useHistory();

    const editarPerfil = () => {
        return history.push('/editarPerfil');
    };

    const verMeuPainel = () => {
        return history.push('/painel');
    };

    const IsMobile = () => {
        return IsCustomMaxWidth(MobileMaxWidth);
    };

    const getRoleByUser = () => {
        let role = RoleEnum.Unknown;

        if (profile && profile.role) {
            if (String(profile.role) === 'PROFESSOR') {
                role = RoleEnum.Professor;
            } else if (String(profile.role) === 'GESTOR_REDE') {
                role = RoleEnum.GestorRede;
            } else if (String(profile.role) === 'GESTOR_ESCOLAR') {
                role = RoleEnum.GestorEscolar;
            } else {
                role = profile.role;
            }
        } else if (usuarioLogado) {
            const roleUser: RoleEnum = getUserRole(usuarioLogado.roles)[0];
            role = roleUser ? roleUser : role;
        }

        return fetchRoleDescription(role);
    };

    return (
        <HeadInfoStyle>
            <ProfileContainer>
                <ButtonProfileContainer onClick={editarPerfil} data-tip={'Editar perfil'}>
                    {!profile || profile?.tipoIdentificacao == TipoImagem.EscolherDepois ? (
                        <AvatarDefault name={usuarioLogado.primeiroNome} />
                    ) : (
                        <ProfileImage src={profile?.srcImage} />
                    )}
                </ButtonProfileContainer>
                <TitleContainer>
                    <TitleDiv>
                        <TitleUsername data-hj-suppress>Olá, {usuarioLogado.primeiroNome}</TitleUsername>
                        {!IsMobile() && (
                            <VerPainelButton onClick={() => verMeuPainel()}>
                                <ImgSvg src={PlusCircle} />
                                <Text>{'Ver meu painel'}</Text>
                            </VerPainelButton>
                        )}
                    </TitleDiv>
                    <CellInfoContainer>
                        {!IsMobile() && (
                            <>
                                <CellInfo property="Ocupação" value={occupationDescription(profile?.ocupacao ?? '') || getRoleByUser()} />
                                {profile && !occupationIsOther(profile?.ocupacao ?? '') && (
                                    <>
                                        <CellInfo property="Local" value={`${profile?.cidade} (${profile?.estado})`} />
                                        <CellInfo property="Rede" value={profile?.tipoEnsino?.descricao || ''} />
                                    </>
                                )}
                            </>
                        )}
                    </CellInfoContainer>
                    <CellInfoContainerMobile>
                        {IsMobile() && (
                            <>
                                <CellInfoExternalMobile>
                                    <CellInfoInternalMobile>
                                        <CellInfo
                                            property="Ocupação"
                                            value={occupationDescription(profile?.ocupacao ?? '') || getRoleByUser()}
                                        />
                                        {profile && !occupationIsOther(profile?.ocupacao ?? '') && (
                                            <CellInfo property="Local" value={`${profile?.cidade} (${profile?.estado})`} />
                                        )}
                                    </CellInfoInternalMobile>
                                    {profile && !occupationIsOther(profile?.ocupacao ?? '') && (
                                        <CellInfoInternalMobile>
                                            <CellInfo property="Rede" value={profile?.tipoEnsino?.descricao || ''} />

                                            <VerPainelButton onClick={() => verMeuPainel()}>
                                                <Text>{'Ver meu painel'}</Text>
                                                <ImgSvg src={PlusCircle} />
                                            </VerPainelButton>
                                        </CellInfoInternalMobile>
                                    )}
                                </CellInfoExternalMobile>
                            </>
                        )}
                    </CellInfoContainerMobile>
                </TitleContainer>
            </ProfileContainer>
            {!IsCustomMaxWidth(1250) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </HeadInfoStyle>
    );
};

export default HeadInfo;
