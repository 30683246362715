import { Serializable, jsonProperty } from 'ts-serializable';

export class ResumoRecursoCustomizado extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    nome: string = '';

    @jsonProperty(Boolean)
    oculto: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;
}
