import { GradientButton, PrimaryButton, SecondaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const TileStyle = {
    Container: styled.div`
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        color: #183f73;
        background-color: white;
        box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.2);
        transition: 0.3s ease;
        margin-bottom: 5px;
        justify-content: center;

        display: flex;

        @media only screen and (max-width: 320px) {
            height: 175px;
        }
    `,

    ImageBackground: styled.img`
        width: 40%;
        height: 100%;
        object-fit: cover;
    `,

    Content: styled.div`
        padding: 15px 15px 5px 15px;
        position: relative;
        height: 100%;
        max-width: 410px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 13px 15px;
            flex-grow: 1;
            max-width: 100%;
        }
    `,

    Retract: styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 0px;
        }
    `,

    Title: styled.p`
        font-family: Barlow;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;

        max-width: 360px;
        word-wrap: break-word;
        overflow-x: hidden;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 18px;
            line-height: 22px;
            padding: 0;
        }
    `,

    UnderTitle: styled.p`
        font-family: Barlow;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        font-style: normal;
        font-weight: 500;
        color: ${Colors.Blue};

        max-width: 360px;
        word-wrap: break-word;
        overflow: hidden;
    `,

    Text: styled.div`
        font-style: italic;
        font-size: 16px;
        line-height: 18px;
        margin: 10px 0;
        height: 100%;
        font-weight: 500;
        color: ${Colors.Blue};

        max-width: 360px;
        word-wrap: break-word;
        overflow-x: hidden;

        flex-grow: 1;
    `,

    Button: styled(PrimaryButton)<{ disabled: boolean }>`
        width: 100%;
        max-height: 50px;
        margin: 0 auto;
        margin-bottom: 10px;
        border: none;
        color: #fff;
        border-radius: 5px;
        background-color: ${({ disabled }) => (disabled ? Colors.DisabledBlue : Colors.Blue)};
        font-weight: 500;

        &:hover {
            box-shadow: ${({ disabled }) => disabled && 'none'};
            color: #fff;
        }
    `,

    TextContainer: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    `,

    NumberIndex: styled.div`
        font-family: Barlow;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        color: ${Colors.Blue};
    `,

    BlueCircle: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 41px;
        background-color: ${Colors.Yellow};
        border-radius: 50%;
    `,

    InnerContent: styled.div`
        display: flex;
        flex-direction: row;
        gap: 16px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding-bottom: 0px;
        }
    `,

    SideContent: styled.div`
        width: 41px;
    `,

    LateralContent: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
    `,

    ButtonContainer: styled.div`
        display: flex;
    `,
};

export default TileStyle;
