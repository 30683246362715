import styled from 'styled-components';

const AdminGestaoPaginasSectionFormStyled = {
    Container: styled.div`
        border-radius: 10px;
        padding: 15px 45px;
        background-color: white;
        margin-left: 120px;
    `,

    Title: styled.p`
        font-weight: 600;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin-bottom: 20px;
        margin-top: 50px;
    `,
};

export default AdminGestaoPaginasSectionFormStyled;
