import React from 'react';
import Styled from './SecaoLinks.styled';
import StyleGlobal from '../../CompetenciasSocioemocionais.styled';
import { MdOutlineArrowForward } from 'react-icons/md';
import Banner from '../../assets/img_outrosConteudos.svg';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';

interface IListItemLink {
    title: string;
    link: string;
}
interface IItemLink {
    dados: IListItemLink;
    handleClick: (link: string) => void;
}

const ItemLink = ({ dados, handleClick }: IItemLink) => {
    return (
        <Styled.ItemContainer>
            <label>{dados.title}</label>
            <Styled.ButtonLink onClick={() => handleClick(dados.link)}>
                Acessar <MdOutlineArrowForward size={20} />
            </Styled.ButtonLink>
        </Styled.ItemContainer>
    );
};

interface ISecaoLinks {
    isMobile: boolean;
}

const SecaoLinks = ({ isMobile }: ISecaoLinks) => {
    const history = useHistory();

    const linksAccess: IListItemLink[] = [
        {
            title: 'Ideias para o desenvolvimento da Resiliência Emocional',
            link: '/biblioteca/item/ideias-para-o-desenvolvimento-de-competencias-socioemocionais-resiliencia-emocional',
        },
        {
            title: 'Ideias para o desenvolvimento da Autogestão',
            link: '/biblioteca/item/ideias-para-o-desenvolvimento-de-competencias-socioemocionais-autogestao',
        },
        {
            title: 'Ideias para o desenvolvimento da Abertura ao novo',
            link: '/biblioteca/item/ideias-para-o-desenvolvimento-de-competencias-socioemocionais-abertura-ao-novo',
        },
        {
            title: 'Ideias para o desenvolvimento da Amabilidade',
            link: '/biblioteca/item/ideias-para-o-desenvolvimento-de-competencias-socioemocionais-amabilidade',
        },
    ];

    const goToNavigate = (link: string) => {
        history.push(link);
    };

    return (
        <Styled.Container>
            <Styled.Img src={Banner} isMobile={isMobile} />
            <Styled.Content isMobile={isMobile}>
                <StyleGlobal.Title isMobile={isMobile} style={{ color: '#ffffff' }}>
                    COLOQUE EM PRÁTICA NA SALA DE AULA
                </StyleGlobal.Title>
                <Styled.ContentCenter>
                    {linksAccess.map((dados, index) => (
                        <ItemLink key={index} dados={dados} handleClick={goToNavigate} />
                    ))}
                </Styled.ContentCenter>
            </Styled.Content>
        </Styled.Container>
    );
};

export default SecaoLinks;
