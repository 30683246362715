import { OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 50px 10%;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
        padding: 50px 20px;
    }
`;

const Box = styled.div`
    display: flex;
    background: #003c78;
    border-radius: 19px;
    max-width: 354px;
    max-height: 140px;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;

const MobileBox = styled(Box)`
    max-height: 41px;
    padding: 12px 9px;
    border-radius: 4px;

    @media only screen and (max-width: 1200px) {
        display: flex;
    }

    @media only screen and (min-width: 1200px) {
        display: none;
    }
`;
const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.3px;
`;

const WhiteText = styled(Text)`
    font-weight: 500;
    font-size: 18px;
    line-height: 90%;

    color: #fcfcfc;
`;

const ModuloContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
`;

const SliderContainer = styled.div<{ colorDots?: string }>`
    max-width: 80vw;
    width: 100%;
    padding: 30px 0;

    .slick-track {
        display: flex;
        align-items: center;
    }
    .slick-slide.slick-active.slick-current > div {
        display: flex;
        justify-content: center;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots li button:before {
        font-size: 10px;
        color: ${({ colorDots }) => (colorDots ? colorDots : '')};
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
        color: ${({ colorDots }) => (colorDots ? colorDots : '#003c78')};
        font-size: 10px;
    }
`;

interface IArrowButton {
    next: boolean;
}

const ArrowButton = styled.div<IArrowButton>`
    cursor: pointer;
    width: 56px;
    height: 56px;
    outline: none;
    border: none;
    position: absolute;
    z-index: 8;
    top: 50%;
    right: ${props => (props.next ? '0%' : '')};
    left: ${props => (!props.next ? '5%' : '90%')};
`;

const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 56px;
    width: 56px;
    fill: transparent;

    &:hover {
        fill: ${Colors.SoftBlue};
    }

    &:hover path {
        stroke: ${Colors.White};
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Row = styled.div`
    display: flex;

    @media only screen and (max-width: ${MobileMaxWidth}) {
        display: flex;
        flex-direction: column;
    }
`;

const ImgSvg = styled.img`
    max-height: 44px;
`;

const YellowButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background: #ffca00;
    border-radius: 50px;
    color: #003c78;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    max-width: 209px;
    border: none;
    max-height: 49px;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        max-width: none;
        max-height: 38px;
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

const OutlinerButton = styled(OutlineButton)`
    width: 263px;
    margin-left: 30px;
`;

const BoxContainer = styled.div`
    display: flex;
    justify-content: center; ;
`;

const StyledCursoLivre = {
    Title: styled.h1`
        color: #003c78;
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 20px;
    `,

    SubTitle: styled.p`
        color: #003c78;
        width: 80%;
        font-size: 20px;
        line-height: 30px;
        width: 47vw;

        @media screen and (max-width: 760px) {
            width: 100%;
        }
    `,

    Status: {
        Cotainer: styled.div`
            display: flex;
            gap: 2rem;
            margin-top: 32px;
            margin-bottom: 24px;
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
        `,
    },
};

export default StyledCursoLivre;

export {
    Container,
    Box,
    MobileBox,
    WhiteText,
    ModuloContainer,
    SliderContainer,
    ArrowButton,
    SvgIcon,
    ItemContainer,
    ImgSvg,
    YellowButton,
    BoxContainer,
    OutlinerButton,
    Row,
};
