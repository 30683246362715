import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminSubAtividade extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('numeroPaginas')
    @jsonProperty(Number)
    pagesNumber: number = -1;

    @jsonName('idioma')
    @jsonProperty(String)
    language: string = '';

    @jsonName('itemBiblioteca')
    @jsonProperty(Number)
    itemParent: number = -1;

    @jsonName('descricao')
    @jsonProperty(String)
    title: string = '';

    @jsonName('anoPublicacao')
    @jsonProperty(String)
    yearPublication: string | number = '';

    @jsonIgnore()
    attach: File | null = null;
}

export default AdminSubAtividade;
