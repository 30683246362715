enum TestVersion {
    VersionA = 'VERSION_A',
    VersionB = 'VERSION_B',
    VersionC = 'VERSION_C',
    VersionD = 'VERSION_D',
}

export function getEventFromVersion(version: TestVersion): string {
    switch (version) {
        case TestVersion.VersionA:
            return 'test_ab_versao_A_usuario';
        case TestVersion.VersionB:
            return 'test_ab_versao_B_usuario';
        case TestVersion.VersionC:
            return 'test_ab_versao_C_usuario';
        case TestVersion.VersionD:
            return 'test_ab_versao_D_usuario';
    }
}

export function stringFromTestVersion(str: string): TestVersion {
    if (str.includes('_B')) return TestVersion.VersionB;
    if (str.includes('_C')) return TestVersion.VersionC;
    if (str.includes('_D')) return TestVersion.VersionD;
    else return TestVersion.VersionA;
}

export function getURLParamFromVersion(version: TestVersion): string {
    switch (version) {
        case TestVersion.VersionA:
            return 'testeAB-versao-A';
        case TestVersion.VersionB:
            return 'testeAB-versao-B';
        case TestVersion.VersionC:
            return 'testeAB-versao-C';
        case TestVersion.VersionD:
            return 'testeAB-versao-D';
    }
}

export default TestVersion;
