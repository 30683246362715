import React from 'react';
import { StepStatus, StepType } from './Steps';
import { Bridge, Container, IndexContainer, IndexLabel, IndexWrapper, SubtitleStep, TextsContainer, TitleStep } from './Step.styled';

export interface IStep {
    step: StepType;
    oncliked: () => void;
    isLast: boolean;
}

const Step = ({ step, isLast, oncliked }: IStep) => {
    return (
        <Container inatived={step.status === StepStatus.Waiting} onClick={oncliked}>
            <IndexContainer>
                <IndexWrapper>
                    <IndexLabel>{step.index}</IndexLabel>
                </IndexWrapper>
                {!isLast && <Bridge />}
            </IndexContainer>

            <TextsContainer isCurrent={step.status === StepStatus.Current}>
                <TitleStep size="medium" inatived={step.status === StepStatus.Waiting}>
                    {step.title}
                </TitleStep>
                <SubtitleStep>{step.subtitle}</SubtitleStep>
            </TextsContainer>
        </Container>
    );
};

export default Step;
