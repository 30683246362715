import styled from 'styled-components';

const AdminCursosPageStyled = {
    Container: styled.div`
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 15px 25px;
        margin-left: 120px;
        width: calc(100% - 120px);
    `,
};

export default AdminCursosPageStyled;
