import { RoleEnum } from 'enums/RoleEnum';
import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class CategoriaUsersDTO2 extends Serializable {
    @jsonName('email')
    @jsonProperty(String)
    email: string = '';

    @jsonName('estado')
    @jsonProperty(String)
    estado: string = '';

    @jsonName('cidade')
    @jsonProperty(String)
    cidade: string = '';

    @jsonName('role')
    role: any = [];

    @jsonName('categoria')
    @jsonProperty(String)
    categoria: string = '';

    @jsonName('token')
    @jsonProperty(String)
    token: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    ultimaAtualizacao: string = '';
}

export default CategoriaUsersDTO2;
