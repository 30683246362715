import BiblioItem from 'models/biblioteca/BiblioItem';
import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';
import React from 'react';
import Styled from './BiblioCardItem.styled';
import defaultCover from '../../../../images/biblioteca/cover-default.png';
import { DateHelp } from 'core/util/DateHelp';
import { bucketS3, caminhoAnexosBibliotecaCapas } from 'constants/EnvironmentData';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import BiblioFavoriteButton from 'pages/biblioteca/components/favorites/BiblioFavoriteButton';

export interface IBiblioCardItem {
    data: BiblioItemPreview;
}

const biblioItemImageSanitize = (data: BiblioItemPreview) => {
    if (data.cover == 'sem capa' || !data.cover) return defaultCover;
    else return bucketS3() + caminhoAnexosBibliotecaCapas() + data.cover ?? defaultCover;
};

const BiblioCardItem = ({ data }: IBiblioCardItem) => {
    const history = useHistory();

    const gotoItem = () => {
        history.push(`/biblioteca/item/${data.slug}`);
    };

    const onClickHandler = () => {
        GTM_Page_Biblioteca(`${data.id}`, `biblioteca_item_${data.title.replace(/\s+/g, '_').toLowerCase()}`, 'click_item_biblioteca');
        gotoItem();
    };

    return (
        <Styled.Container>
            <Styled.CoverContainer>
                <Styled.Cover src={biblioItemImageSanitize(data)} onClick={onClickHandler} />
                <Styled.BookmarkContainer>
                    <BiblioFavoriteButton isFavorited={data?.isFavorited} data={data} />
                </Styled.BookmarkContainer>
            </Styled.CoverContainer>
            <Styled.TextRow>
                <Styled.Chip>{data.category}</Styled.Chip>
                <Styled.Date>Postado em: {DateHelp.formatDateFull(data.date)}</Styled.Date>
            </Styled.TextRow>

            <Styled.Desc onClick={onClickHandler}>{data.title}</Styled.Desc>
        </Styled.Container>
    );
};

export default BiblioCardItem;
