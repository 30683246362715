import styled from 'styled-components';

import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from '../../../../core/util/cssHelp';
import Colors from '../../../../enums/Colors';
import FullCss, { MediaQuery } from 'core/util/FullCss';

export const HeaderContainer = styled.div(() => {
    return new FullCss(
        {
            display: 'flex',
            gap: '47px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            padding: '40px 10px 10px 10px',
            flexDirection: 'column',
        })
    ).getCss();
});

export const RealizandoEscolhasHeaderStyle = styled.nav(() => {
    return new FullCss(
        {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '72px',
            left: '0px',
            top: '86px',
            background: Colors.SoftBlue,
            position: 'fixed',
            boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1)',
            zIndex: '2',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            display: 'none',
        })
    ).getCss();
});

export const ImgSvgLogo = styled.img(() => {
    return new FullCss(
        {
            position: 'absolute',
            height: '56px',
            left: '50px',
            top: '8px',
            width: '220px',
        },
        new MediaQuery(':hover', {
            cursor: 'pointer',
        })
    ).getCss();
});

export const HomePageButton = styled.button(() => {
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px',
            width: '163px',
            border: '1px solid #FCFCFC',
            borderRadius: '50px',
            background: 'transparent',
        },
        new MediaQuery(':hover', { cursor: 'pointer', border: '2px solid #FCFCFC' })
    ).getCss();
});

export const DefaultText = styled.p(() => {
    return new FullCss(
        {
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '137%',
            letterSpacing: '0.3px',
            color: 'white',
            textAlign: 'center',
        },
        new MediaQuery('&:hover', {
            fontWeight: '700',
        })
    ).getCss();
});

export const MenuItem = styled.div(() => {
    return new FullCss({
        marginRight: '5px',
        gap: '34px',
        lineHeight: '18px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '80px',
    }).getCss();
});

export const MenuItemText = styled.button(() => {
    return new FullCss(
        {
            color: Colors.White,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            background: 'transparent',
            border: 'none',
        },
        new MediaQuery('&:hover', {
            cursor: 'pointer',
            fontWeight: '700',
            textDecoration: 'underline',
            textDecorationThickness: '6px',
            textUnderlineOffset: '21px',
        })
    ).getCss();
});

export const IconHome = styled.img`
    height: 25px;
`;
