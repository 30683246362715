import AcaoMeta from '../../../../models/pdp/AcaoMeta';
import Meta from '../../../../models/pdp/Meta';
import TipoMeta from '../../../../models/pdp/TipoMetaEnum';
import { MetaComponentType, PrazoMeta } from './Metas';
import * as yup from 'yup';
import RegistroMacrocompetencia from 'models/pdp/RegistroMacrocompetencia';
import Macrocompetencia from 'models/pdp/Macrocompetencia';
import { MetaContainer } from './Metas.styled';

export const getOrdinal = (index: number): string => {
    switch (index) {
        case 1:
            return 'primeira';
        case 2:
            return 'segunda';
        default:
            return '';
    }
};

export const capitalizeFirstLetter = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const convertToAcaoMeta = (metasComponent: MetaComponentType[], prazoMeta: PrazoMeta): AcaoMeta[] => {
    const acoesMeta: AcaoMeta[] = [];

    metasComponent.forEach(metaComponent => {
        const meta: Meta = new Meta();
        meta.descricao = metaComponent.prazo;
        meta.id = metaComponent.id;
        meta.codigo = metaComponent.id;
        switch (prazoMeta) {
            case 'curto':
                meta.tipo = TipoMeta.CurtoPrazo;
                break;
            case 'longo':
                meta.tipo = TipoMeta.LongoPrazo;
                break;
            case 'medio':
                meta.tipo = TipoMeta.MedioPrazo;
                break;
            default:
                meta.tipo = TipoMeta.Prazo
        }

        const acaoMeta = {
            meta,
            descricao: metaComponent.acao,
        } as AcaoMeta;

        //Não adicionar com dados faltosos
        if (metaComponent.acao.trim().length && metaComponent.prazo.trim().length && metaComponent.id !== -1) {
            acoesMeta.push(acaoMeta);
        }
    });

    return acoesMeta;
};

export const convertToMetaType = (acaoMetas: AcaoMeta[] | undefined): MetaComponentType[] => {
    if (!acaoMetas) return [];

    const metastype: MetaComponentType[] = [];

    acaoMetas.forEach((acaoMeta, idx) => {
        const metatype = {
            index: idx,
            id: acaoMeta.meta.id,
            acao: acaoMeta.descricao,
            prazo: acaoMeta.meta.descricao,
        } as MetaComponentType;

        metastype.push(metatype);
    });

    return metastype;
};

export const checkMetasPreenchidas = (acaoMetas: MetaComponentType[]): boolean => {
    for (let i = 0; i < acaoMetas.length; i++) {
        const acaoMeta = acaoMetas[i];

        if ((acaoMeta.acao && !acaoMeta.prazo) || (acaoMeta.acao && acaoMeta.prazo && acaoMeta.id === -1)) return false;
        if (!acaoMeta.acao && acaoMeta.prazo && acaoMeta.id !== -1) return false;
    }

    return true;
};

export interface IValues {
    motivoDesenvolvimento: string;
    objetivoDesenvolvimento: string;
    quemOInspira: string;
}

export const formikInitial: IValues = {
    motivoDesenvolvimento: '',
    objetivoDesenvolvimento: '',
    quemOInspira: '',
};

export const yupValidation = yup.object().shape({
    motivoDesenvolvimento: yup.string().required().min(5),
    objetivoDesenvolvimento: yup.string().required().min(5),
    quemOInspira: yup.string().required().min(5),
});

export const orderMacrocompetencia = (lista: Macrocompetencia[]): Macrocompetencia[] => {
    return lista.sort((m1: Macrocompetencia, m2: Macrocompetencia) => (m1.descricao < m2.descricao ? -1 : 1));
};
