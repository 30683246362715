import DefaultVideoFrame from 'components/DefaultVideoFrame';
import { videoCriatividadeDetail } from 'constants/Resources';
import keycloak from 'core/config/keycloak';
import { IsCustomMinWidth } from 'core/util/WindowSize';
import React from 'react';
import { useHistory } from 'react-router-dom';

import TextoApresentacao from '../../../../../../components/TextoApresentacao';
import BackgroundVideo from '../assets/BackgroundVideo.svg';
import { VideoContainer, TransparentButton, SplitContainer, Container, ButtonContainer, BlueButton, BackgroundImg } from './Material.styled';

export interface IMaterial {}

const Material = ({}: IMaterial) => {
    const history = useHistory();
    const scrollWithOffset = (el: any) => {
        if (el) {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -102;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }
    };

    const MaterialCss = () => {
        return IsCustomMinWidth(1200) ? { paddingTop: '112px' } : { paddingTop: '20px', maxWidth: '100%' };
    };

    const MaterialVideo = () => {
        return (
            <VideoContainer>
                <DefaultVideoFrame url={videoCriatividadeDetail} height={290} width={470} />
            </VideoContainer>
        );
    };

    const openUrl = () => {
        if (keycloak.authenticated) {
            history.push('/biblioteca/item/guia-criatividade-e-pensamento-critico');
        } else {
            const _url = keycloak.createRegisterUrl({
                redirectUri: window.location.origin + '/login?' + '/biblioteca/item/guia-criatividade-e-pensamento-critico',
            });
            window.location.assign(_url);
        }
    };

    return (
        <Container id="material_section">
            <SplitContainer>
                <BackgroundImg src={BackgroundVideo}>{MaterialVideo()}</BackgroundImg>

                <TextoApresentacao
                    style={{ container: MaterialCss(), titulo: {}, subtitulo: {} }}
                    tipo="Material Gratuito"
                    titulo="Guia Criatividade e Pensamento Critico"
                    subtitulo="Guia gratuito disponível para download, com o objetivo de contribuir com referências e inspirações que possibilitem pensar caminhos para estimular o desenvolvimento da criatividade e do pensamento crítico na Educação Básica."
                    midia={MaterialVideo()}
                >
                    <ButtonContainer style={{ marginTop: '15px' }}>
                        <BlueButton onClick={() => openUrl()}>{'Baixar material'}</BlueButton>
                        <TransparentButton
                            smooth
                            scroll={el => {
                                scrollWithOffset(el);
                            }}
                            to={'#outros'}
                        >
                            {'Ver mais conteúdos'}
                        </TransparentButton>
                    </ButtonContainer>
                </TextoApresentacao>
            </SplitContainer>
        </Container>
    );
};

export default Material;
