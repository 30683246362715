import React, { FunctionComponent } from 'react';

import Dialog from 'components/UI/dialog/Dialog';
import { IsCustomMaxWidth } from '../../../../core/util/WindowSize';
import { ResizeMediumWidth } from '../../../../constants/Dimensions';
import PopupStart from '../../../../components/popupComecar/PopupStart';
import { DestaqueText, BoldText } from '../../../../components/UI/Texts';
import { Content, ContentTitle } from '../../../../components/UI/dialog/Dialog.styled';

import Banner from '../../../../images/pdp/imgBannerInicio.svg';
import TrophyImage from '../../../../images/pdp/TrophyImage.svg';
import BannerSmall from '../../../../images/pdp/imgBannerInicioSmall.svg';
import Expandable from 'components/UI/Expandable';
import expandableData from './ExpandableData';
import { ExpandableContainer } from './DialogFinal.styled';

const IsMobile = () => IsCustomMaxWidth(ResizeMediumWidth);

interface IDialogInicio {
    visible: boolean;
    onBeginCallback: () => void;
    onCloseCallback: () => void;
}
const DialogInicio: FunctionComponent<IDialogInicio> = ({ visible, onBeginCallback, onCloseCallback }: IDialogInicio) => {
    const Head = () => {
        return <ContentTitle>Olá, Educador</ContentTitle>;
    };
    return (
        <Dialog
            visible={visible}
            bannerSvg={Banner}
            icon={TrophyImage}
            mobileHeader={<Head />}
            onClose={onCloseCallback}
            bannerSmallSvg={BannerSmall}
            footer={<PopupStart onStart={onBeginCallback} onCancel={onCloseCallback} />}
        >
            {!IsMobile() && <Head />}

            <Content>
                Como parte do compromisso que você assumiu com o seu desenvolvimento profissional, apresentamos o
                <DestaqueText>{` Plano de Desenvolvimento Pessoal (PDP) `}</DestaqueText>, uma ferramenta aliada no seu percurso de
                autodesenvolvimento.
            </Content>
            <br />
            <Content>
                O PDP permite a estruturação, a partir do que você identificou sobre si e as suas
                <BoldText>{` competências socioemocionais `}</BoldText>, dos seus objetivos de desenvolvimento e do que pode ser feito no dia a
                dia para alcançá-los. O PDP é uma ferramenta viva, assim como o seu Diário de Bordo. Então, revisite e atualize o seu PDP a cada
                atingimento de meta ou sempre que quiser estabelecer novas metas.
            </Content>

            <ExpandableContainer>
                {expandableData.map((data, idx) => {
                    return <Expandable key={idx} title={data.title} content={data.content} />;
                })}
            </ExpandableContainer>
        </Dialog>
    );
};

export default DialogInicio;
