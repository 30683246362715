import React from 'react';
import Styled from './Overview.styled';
import { IoArrowBackOutline } from 'react-icons/io5';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import { MdArrowForwardIos, MdMyLocation } from 'react-icons/md';
import Colors from 'enums/Colors';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { FaFileAlt } from 'react-icons/fa';
import { IoSchool } from 'react-icons/io5';
import { FaChartColumn } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi2';
import { LuWorkflow } from 'react-icons/lu';
import { useHistory } from 'react-router-dom';
import { BsFillTrophyFill } from 'react-icons/bs';
import ButtonExclusivo from './ButtonExclusivo';

interface IElementGeneric {
    wdFull?: boolean;
    icon: any;
    title: string;
    description: string;
}

const ElementGeneric = ({ wdFull, icon, title, description }: IElementGeneric) => {
    return (
        <Styled.Element.Container wdFull={wdFull ?? false} bgColor={Colors.Blue}>
            {icon}
            <Styled.Element.Content wdFull={wdFull ?? false}>
                <Styled.Element.Title color={Colors.White}>{title}</Styled.Element.Title>
                <Styled.Element.Description color={Colors.White} dangerouslySetInnerHTML={{ __html: description }}></Styled.Element.Description>
            </Styled.Element.Content>
        </Styled.Element.Container>
    );
};

type TOverview = {
    title: string;
    descricao: string;
    objetivo: string;
    img?: string;
    publicoAlvo: string;
    avaliacao: string;
    elementos: string;
    formacao: string;
    atores: string;
    acompanhamento: string;
    isBlue?: boolean;
    heightImg?: string;
    linkExclusivo?: string;
};
interface IOverview {
    dados: TOverview;
}

const Overview = ({ dados }: IOverview) => {
    const history = useHistory();

    const goBack = () => {
        history.push('/solucoes');
    };

 
    return (
        <Styled.Container>
            <Styled.GoCallBack onClick={goBack}>
                <IoArrowBackOutline size={18} /> VOLTAR
            </Styled.GoCallBack>
            <Styled.NameCourse>{dados.title}</Styled.NameCourse>
            <Styled.Title>VISÃO GERAL</Styled.Title>
            <Styled.Description dangerouslySetInnerHTML={{ __html: dados.descricao }}></Styled.Description>
            <ButtonExclusivo inativeButton linkExclusivo={dados.linkExclusivo ?? ''} />

            <Divider style={{ width: IsCustomMaxWidth(600) ? '90%' : '50%', marginBottom: '24px', marginTop: 4 }} />
            <Styled.RowBeetween imgInvible={dados.img && dados.img.length > 0 ? false : true}>
                <Styled.Row>
                    <ElementGeneric title="Objetivo" description={dados.objetivo} icon={<BsFillTrophyFill size={56} color={Colors.Yellow} />} />
                    <ElementGeneric
                        title="Público Alvo"
                        description={dados.publicoAlvo}
                        icon={<MdMyLocation size={56} color={Colors.Yellow} />}
                    />
                    <ElementGeneric title="Avaliação" description={dados.avaliacao} icon={<FaFileAlt size={56} color={Colors.Yellow} />} />
                    <ElementGeneric title="Formação" description={dados.formacao} icon={<IoSchool size={56} color={Colors.Yellow} />} />
                    <ElementGeneric
                        title="Acompanhamento"
                        description={dados.acompanhamento}
                        icon={<FaChartColumn size={56} color={Colors.Yellow} />}
                    />
                    <ElementGeneric title="Atores" description={dados.atores} icon={<HiUsers size={56} color={Colors.Yellow} />} />
                    <ElementGeneric
                        wdFull
                        title="Elementos"
                        description={dados.elementos}
                        icon={<LuWorkflow size={56} color={Colors.Yellow} />}
                    />
                </Styled.Row>
                {dados.img && <Styled.Img height={dados.heightImg} src={dados.img} />}
            </Styled.RowBeetween>
        </Styled.Container>
    );
};

export default Overview;
