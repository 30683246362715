import styled from 'styled-components';

import Colors from '../../../../../enums/Colors';
import { ConfirmButton } from '../components/ImageSelect.styled';

export const Container = styled.div`
    padding: 10px 10px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CanvasContainer = styled.div`
    flex: 1;
    border-radius: 10px;
    border: 1px #ddd solid;
    overflow: hidden;
`;

export const ButtonContainer = styled.div`
    padding: 0 10px;
`;

export const CaptureButton = styled(ConfirmButton)`
    margin: 10px 0;
`;

export const CancelButton = styled(ConfirmButton)`
    border: 1px solid ${Colors.SoftBlue};
    color: ${Colors.SoftBlue};
    background-color: white;

    &:hover {
        color: white;
        background-color: ${Colors.SoftBlue};
    }
`;
