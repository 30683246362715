import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Container, Grid } from './AvatarGrid.styled';
import ProfileData from '../../../../../models/ProfileData';
import { storeProfileData } from '../../../../../store/actions/Profile';
import ImagemPerfil from '../../../../../models/imagemPerfil/ImagemPerfil';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';

import AvatarHolder from './AvatarHolder';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';

export const AVATAR_LIST_SIZE = 9;

const AvatarGrid = () => {
    const noSelected = -1;
    const [idSelected, setIdSelected] = useState(noSelected);
    const dispatch = useDispatch();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    useEffect(() => {
        if (profileData) {
            //Limpar os estados de Imagen do Profile
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.NoSelected,
                codigoAvatar: -1,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    }, []);

    const selectAvatar = (id: number) => {
        setIdSelected(id);
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.Avatar,
                codigoAvatar: id,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    return (
        <Container>
            <Grid>
                {[...Array(AVATAR_LIST_SIZE)].map((_, i) => {
                    const id = i + 1;
                    return (
                        <AvatarHolder
                            key={i}
                            id={id}
                            checked={idSelected === id}
                            wb={idSelected !== noSelected && idSelected !== id}
                            clickCallback={() => {
                                selectAvatar(id);
                            }}
                        />
                    );
                })}
            </Grid>
        </Container>
    );
};

export default AvatarGrid;
