import React, { useState } from 'react';
import { DateHelp } from 'core/util/DateHelp';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import capitalize from 'pages/admin/utils/capitalize';
import Styled from '../../socio/AdminSocioEmocional.styled';
import BiblioItemComment from 'models/biblioteca/BiblioItemComment';
import { FormControl } from 'react-bootstrap';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import BiblioItemAnswer from 'models/biblioteca/BiblioItemAnswer';
import { ToastError, ToastSuccess } from 'components/Toaster';

export interface IAdminModalBiblioItem {
    show: boolean;
    onHide: () => void;
    comment?: BiblioItemComment;
    updateData: () => void;
}

const service = new AdminBiblioService();
const AdminModalBiblioItem2 = ({ show, onHide, comment, updateData }: IAdminModalBiblioItem) => {
    const [answer, setAnswer] = useState<string>('');
    const [askExclude, setAskExclude] = useState<boolean>(false);

    const submitAnswer = async () => {
        if (comment) {
            const { status } = await service.adicionarResposta(comment.id, answer);

            if (status == 200) {
                ToastSuccess('Resposta Enviada');
                setAnswer('');
                onHide();
                updateData();
            } else {
                ToastError('Erro ao enviar Resposta');
            }
        }
    };

    const excludeAnswer = async (answerId: number) => {
        const { status } = await service.excluirResposta(answerId);

        if (status == 200) {
            ToastSuccess('Resposta Excluída');
            setAnswer('');
            onHide();
            updateData();
        } else {
            ToastError('Erro ao enviar Resposta');
        }
    };

    return (
        <AdminModalShow show={show} onHide={onHide}>
            {comment && comment.item && (
                <>
                    <Styled.Modal.Title>{capitalize(comment.item.category)}</Styled.Modal.Title>
                    <Styled.Modal.SubTitle>{capitalize(comment.item.title)}</Styled.Modal.SubTitle>
                    <br />
                    <Styled.Modal.UserName>{comment.username}</Styled.Modal.UserName>
                    <Styled.Modal.Date>{DateHelp.formatDateFull(comment.dateAtualizacao)}</Styled.Modal.Date>
                    <Styled.Modal.Text>{comment.comment}</Styled.Modal.Text>

                    <hr />

                    {comment.answers.map((answer: BiblioItemAnswer) => {
                        return (
                            <>
                                <Styled.Modal.UserName>{answer.username}</Styled.Modal.UserName>
                                <Styled.Modal.Text style={{ marginTop: '0', marginLeft: '10px' }}>{answer.content}</Styled.Modal.Text>
                                <Styled.Modal.ExcludeContainer>
                                    {!askExclude ? (
                                        <span className="exclude-inicial" onClick={() => setAskExclude(true)}>
                                            Excluir Resposta
                                        </span>
                                    ) : (
                                        <>
                                            <span className="exclude-ask">Excluir?</span>
                                            <span className="exclude-confirm" onClick={() => excludeAnswer(answer.id)}>
                                                Sim
                                            </span>
                                            <span className="exclude-confirm" onClick={() => setAskExclude(false)}>
                                                Não
                                            </span>
                                        </>
                                    )}
                                </Styled.Modal.ExcludeContainer>
                                <hr />
                            </>
                        );
                    })}

                    <FormControl as="textarea" rows={2} value={answer} onChange={e => setAnswer(e.target.value)} />
                    <Styled.Modal.ButtonArea>
                        <Styled.Modal.GradientButton disabled={!answer} onClick={submitAnswer}>
                            Responder
                        </Styled.Modal.GradientButton>
                    </Styled.Modal.ButtonArea>
                </>
            )}
        </AdminModalShow>
    );
};

export default AdminModalBiblioItem2;
