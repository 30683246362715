import Colors from 'enums/Colors';
import styled from 'styled-components';

import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const RegrasConvivenciaStyle = styled.div`
    padding-top: 40px;
    padding-left: 100px;
    padding-bottom: 55px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-left: 21px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const CheckboxContainer = styled.div`
    margin-top: 160px;
    display: flex;
`;

export const LabelChackbox = styled.span`
    margin-left: 10px;
    font-weight: 600;
    color: ${Colors.GreyBlue};
`;

export const LinkChackbox = styled.span`
    color: ${Colors.SoftBlue};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const ButtonContainer = styled.div`
    margin-bottom: 43px;
    margin-top: 10px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: flex;
        jutify-content: center;
        align-items: center;
    }
`;
