import ItemBiblitoecaConteudoRelacionado from 'models/admin/ItemBibliotecaConteudoRelacionado';
import { GenericService } from './Generic.service';

export class ItemBibliotecaConteudoRelacionadoService extends GenericService {
    readonly URI: string = '/v1/itemBibliotecaConteudoRelacionado';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listaPorItem(idItem: number) {
        return this.get('listByItem', { idItem: idItem });
    }

    public listaPorItemPublico(idItem: number) {
        return this.get('public/listByItem', { idItem: idItem });
    }

    public inserir(data: ItemBiblitoecaConteudoRelacionado) {
        return this.post('insert', data);
    }

    public editar(id: number, data: ItemBiblitoecaConteudoRelacionado) {
        return this.put(String(id), data);
    }

    public excluir(id: number) {
        return this.deleteByParams(String(id));
    }
}
