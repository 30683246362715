import { createContext } from 'react';
import { AdminBarraLateralItemType } from '../components/barraLateral/AdminBarraLateral';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

export namespace AdminContext {
    export interface IContext {
        menu: AdminBarraLateralItemType;
        setMenu: (value: AdminBarraLateralItemType) => void;

        subPage: string | null;
        setSubPage: (value: string | null) => void;

        checkAllowedGroups: (allowedGroups: KcAdminGroups[]) => boolean;
    }

    export const Context = createContext({} as IContext);
}
