import React, { useState } from 'react';
import Styled from './AdminModal.styled';
import { Modal } from 'react-bootstrap';
import Row from 'components/Row';
import PerfilUsuario from 'models/perfil/PerfilUsuario';

import { AiOutlineCloseCircle } from 'react-icons/ai';

export interface IAdminModalPass {
    show: boolean;
    onHide: () => void;
    perfil: PerfilUsuario;
    onSubmit: (perfil: PerfilUsuario) => void;
}

const AdminModalPass = ({ show, onHide, perfil, onSubmit }: IAdminModalPass) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Styled.Container>
                <Row justify="space-between">
                    <Styled.Title>Redefinir Senha</Styled.Title>
                    <Styled.CloseIconContainer>
                        <AiOutlineCloseCircle onClick={() => onHide()} />
                    </Styled.CloseIconContainer>
                </Row>
                <br />
                <Styled.Text>Nome do usuário: {perfil.apelido}</Styled.Text>
                <Styled.Text>CPF do usuário: {perfil.cpf}</Styled.Text>
                <Styled.Text>E-mail usuário: {perfil.email}</Styled.Text>
                <br />
                <br />
                <Styled.TextStrongRed>Senha padrão: 123456</Styled.TextStrongRed>

                <br />
                <br />
                <Row justify="space-between">
                    <Styled.CancelButton onClick={onHide}>Cancelar</Styled.CancelButton>
                    <Styled.PrimaryButton onClick={() => onSubmit(perfil)}>Redefinir Senha</Styled.PrimaryButton>
                </Row>
            </Styled.Container>
        </Modal>
    );
};

export default AdminModalPass;
