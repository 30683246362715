import React, { useEffect, useState } from 'react';

import FaleConosco from '../../../../../../models/FaleConosco';

import { ContactUsStyle, Text, TextContainer } from './ContactUsV2.styled';
import ModalTerms from 'pages/profile/components/regrasConvivencia/ModalTerms';
import Termos from 'pages/profile/components/regrasConvivencia/Termos';
import PrivacyPolicy from 'pages/home/components/contact/PrivacyPolicy';
import { TitlePage } from '../../FaqContainer.styled';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';

interface IContatUs {}

const ContactUsv2 = ({}: IContatUs) => {
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

    const handleAccess = () => {
        window.open('https://humaneias.zendesk.com/hc/pt-br/requests/new');
    };

    return (
        <ContactUsStyle>
            <TextContainer>
                <TitlePage>Fale Conosco</TitlePage>
                <Text>Está com problemas ou precisa de ajuda para navegar na humane?</Text>
                <Text style={{ marginTop: '10px' }}>Entre em contato com nosso Suporte.</Text>
            </TextContainer>

            <BlueButton onClick={handleAccess}>Acessar suporte</BlueButton>

            <ModalTerms show={showTermsModal} setShow={setShowTermsModal}>
                <Termos
                    agree={() => {
                        setShowTermsModal(false);
                    }}
                    decline={() => {
                        setShowTermsModal(false);
                    }}
                />
            </ModalTerms>

            <ModalTerms show={showPrivacyPolicyModal} setShow={setShowPrivacyPolicyModal}>
                <PrivacyPolicy />
            </ModalTerms>
        </ContactUsStyle>
    );
};

export default ContactUsv2;
