import 'core-js/proposals/reflect-metadata';
import { JornadaEnum } from 'enums/JornadaEnum';
import { jsonProperty, Serializable } from 'ts-serializable';
import { JornadaStatusEnum } from '../../enums/JornadaStatusEnum';

class FullDashboardJornadas extends Serializable {
    @jsonProperty(String)
    jornada: JornadaEnum = JornadaEnum.UNKNOWN;

    @jsonProperty(String)
    status: JornadaStatusEnum = JornadaStatusEnum.UNKNOWN;
}

export default FullDashboardJornadas;
