import styled from 'styled-components';

const Container = styled.div<{ padding?: string }>`
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => (padding ? padding : '40px 10% 0 0')};

    @media only screen and (max-width: 1200px) {
        padding: 20px 0 50px 0;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.3px;
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: clamp(24px, 5vw, 48px);
    line-height: 90%;
    color: #003c78;
`;

const SplitContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 10% 0 0;

    @media only screen and (max-width: 1200px) {
        padding: 0px 10% 40px 10%;
    }
`;

export { Container, TitleContainer, Title, SplitContainer };
