import { MobileMaxWidth } from 'constants/Dimensions';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyledItemRecurso = {
    Container: styled.div`
        border-radius: 10px;
        padding: 15px 45px;
        background-color: white;
        margin-left: 120px;
    `,

    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
    `,

    ListItens: styled.div<{ loading?: boolean }>`
      filter: ${props => (props.loading ? 'blur(4px)' : 'blur(0)' )};
      pointer-events: ${props => (props.loading ? 'none' : 'all' )};
    `,

    Label: styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #00a8e9;
        margin-bottom: 10px;
    `,

    FormContainer: styled.div`
        display: flex;
        max-width: 700px;
    `,

    Divisor: styled.div<{ width?: string }>`
        display: flex;
        flex-direction: column;
        width: ${props => (props.width ? props.width : 'auto')};
        margin: 10px;
    `,

    DividorRowBettween: styled.div`
        display: flex;
        max-width: 800px;
        height: 35px;
    `,

    CkeckContainer: styled.div`
        display: flex;
        flex-direction: column;

        label {
            cursor: pointer;
        }
    `,

    FormSections: styled.div`
        max-width: 820px;
        margin-top: 20px;
    `,

    BottomContainer: styled.div`
        width: 730px;
        margin-top: 20px;
    `,

    SecoesContainer: styled.div``,

    Handler: styled.span`
        color: #888;
        margin-right: 10px;
        cursor: move;
    `,

    RedirectButton: styled.div`
        width: 730px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #c9c9c9;
        user-select: none;

        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;

        :hover {
            background-color: #f8f8f8;
        }

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #5f758b;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,

    ListItem: styled.div``,

    Dropzone: styled.div<{ isHover?: boolean }>`
        height: 18px;
        margin: 5px 0;
        border-radius: 5px;
        transition: background-color 200ms;
        background-color: ${props => (props.isHover ? '#90CAF999' : 'transparent')};
    `,

    Input: styled(Form.Control)`
        width: 100%;
    `,

    CountLetter: styled.p<{ danger: boolean }>`
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: ${props => (props.danger ? 'red' : '#747474')};
        text-align: right;
        margin-top: 3px;
    `,

    DivAround: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 25%;
    `,

    DivSpaceBetween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 25%;
    `,

    DivSpanCard: styled.div`
        width: 70%;
        padding: 20px;
        cursor: pointer;
    `,
};

export default StyledItemRecurso;
