import { useKeycloak } from '@react-keycloak/web';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import Menu from 'pages/menu/Menu';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HeadInfo from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo';
import ReturnButton from './assets/ReturnButton.svg';
import { IsMediumSizeMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import {
    BannerTitle1,
    BannerTitle2,
    BreadcrumbContainer,
    BreadcrumbItem,
    ChildrenContainer,
    ContentContainer,
    Divisor,
    IconSvg,
    IntermediatePageContainer,
    ReturnButtonContainer,
    TitleBannerContainer,
    TitleContainer,
} from './IntermediatePage.styled';
import Dashboard from 'pages/painelSocioemocional/home/components/Dashboard/Dashboard';
import { ToasterDefault, ToastError } from 'components/Toaster';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

interface IIntermediatePage {
    helmetTitle: string;
    helmetMetaDescription: string;
    title1: string;
    title2: string;
    icon: any;
    breadCrumbName: string;
}

const IntermediatePage: FunctionComponent<IIntermediatePage> = ({ ...props }: React.PropsWithChildren<IIntermediatePage>) => {
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState<boolean>(true);
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const history = useHistory();

    const IsMobile = () => {
        return IsMediumSizeMaxWidth();
    };

    const isCursoLivre = () => {
        return location.pathname.includes('cursosLivres') || location.pathname.includes('cursos-livres');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            if (keycloak.authenticated) {
                await fetchUserInfo(userProfile?.username ?? '', userProfile?.codigo || -1).then((perfilData: ProfileWithAvatar) => {
                    setProfile(perfilData);
                });
            }
        } catch (err: any) {
            console.log('err, fetch user info');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const TitleBanner = () => {
        return (
            <TitleBannerContainer>
                {IsMobile() && (
                    <ReturnButtonContainer
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <img style={{ height: '22px' }} src={ReturnButton}></img>
                    </ReturnButtonContainer>
                )}
                <TitleContainer>
                    <BannerTitle1 style={{ marginBottom: '7px' }}>{props.title1}</BannerTitle1>
                    <BannerTitle2>{props.title2}</BannerTitle2>
                </TitleContainer>
            </TitleBannerContainer>
        );
    };

    return (
        <>
            <Helmet>
                <title>{props.helmetTitle}</title>
                <meta name="description" content={props.helmetMetaDescription} data-react-helmet="true" />
            </Helmet>

            {/* <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <IntermediatePageContainer id="anchor">
                <ContentContainer>
                    {/* {keycloak.authenticated && !loading && (
                        <HomePageHeader>
                            <HeadInfo profile={profile} />
                            <Dashboard />
                        </HomePageHeader>
                    )} */}

                    <TitleBanner />

                    {!IsMobile() && (
                        <>
                            <IconSvg src={props.icon} transformRule={'translateY(40%)'} />

                            <BreadcrumbContainer isCursoLivre={isCursoLivre()}>
                                <BreadcrumbItem
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                >
                                    Início
                                </BreadcrumbItem>
                                <Divisor>{` > `}</Divisor>
                                <BreadcrumbItem currentPage={true}>{props.breadCrumbName}</BreadcrumbItem>
                            </BreadcrumbContainer>
                        </>
                    )}
                    <ChildrenContainer>{props.children}</ChildrenContainer>
                </ContentContainer>

                <ContactContainer />
                {ToasterDefault()}
            </IntermediatePageContainer>
        </>
    );
};

export default IntermediatePage;
