import 'core-js/proposals/reflect-metadata';
import { jsonIgnore, jsonProperty, Serializable } from 'ts-serializable';

import LocalidadeDTODTO from './LocalidadeDTO';
import TipoFormacaoDTO from './TipoFormacaoDTO';
import PerfilDTOGenerico from './PerfilDTOGenerico';
import TipoIdentificacaoDTO from './TipoIdentificacaoDTO';
import EstadoDTO from './EstadoDTO';

class ParametrosCadastroPerfilDTO extends Serializable {
    @jsonProperty([TipoFormacaoDTO])
    listaTipoFormacao: TipoFormacaoDTO[] = [];

    @jsonProperty([EstadoDTO])
    listaEstados: EstadoDTO[] = [];

    @jsonProperty([PerfilDTOGenerico])
    listaCores: PerfilDTOGenerico[] = [];

    @jsonProperty([PerfilDTOGenerico])
    listaGeneros: PerfilDTOGenerico[] = [];

    @jsonProperty([PerfilDTOGenerico])
    listaComoConheceu: PerfilDTOGenerico[] = [];

    @jsonProperty([PerfilDTOGenerico])
    listaEtapasEnsino: PerfilDTOGenerico[] = [];

    @jsonProperty([PerfilDTOGenerico])
    listaRedeEnsino: PerfilDTOGenerico[] = [];

    /// SEM USO APOS S9-002
    @jsonIgnore()
    listaTipoIdentificacao: TipoIdentificacaoDTO[] = [];

    constructor() {
        super();
    }
}

export default ParametrosCadastroPerfilDTO;
