import styled from 'styled-components';

const AdminTagSelectorStyled = {
    Container: styled.div`
        position: relative;
    `,

    Title: styled.p`
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
        margin-bottom: 10px;
    `,

    Row: styled.div`
        display: flex;
    `,

    Content: styled.div`
        width: 100%;
        border: 1px solid #b5b5b5;
        padding: 10px;
        border-radius: 5px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5px;
        height: 56px;
    `,

    ContainerChip: styled.div`
        background: #1e4e7e;
        border-radius: 5px;

        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        padding: 9px;
        margin-right: 7px;

        span {
            color: white;
            font-size: 14px;
        }
    `,

    CloseButton: styled.img`
        cursor: pointer;
        height: 16px;
        margin-left: 10px;

        svg > circle {
            stroke: white;
        }
    `,

    SelectorContainer: styled.div`
        z-index: 999;

        background: #ffffff;
        box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 7px 0;

        width: 100%;

        position: absolute;
        top: 90px;
        left: 0;
    `,

    SelectRow: styled.div`
        padding: 10px 20px;
        cursor: pointer;

        display: flex;
        justify-content: space-between;

        :hover {
            background: #f1fffd;
        }
    `,

    SelectLabel: styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #2e2e2e;
    `,

    SelectSubLabel: styled.div`
        font-size: 12px;
        line-height: 14px;
        opacity: 0.7;
        color: #2e2e2e;
    `,

    InputTransparent: styled.input<{ error: boolean }>`
        flex-grow: 1;
        border: none;
        outline: none;

        color: ${props => (props.error ? '#D50000' : 'unset')};
    `,
};

export default AdminTagSelectorStyled;
