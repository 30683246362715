import { SvgContainer } from 'components/Loading';
import styled from 'styled-components';
import React from 'react';
import { IoHelpCircleSharp } from "react-icons/io5";

const FloatingContainer = styled.div`
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 0;
    right: 0;
    margin: 100px 0px;
    vertical-align: middle;
    font-size: 16px;

    &:hover .floating-button {
        box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
        -webkit-transform: translatey(5px);
        transform: translatey(5px);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
`;

const FloatingButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 65px;
    height: 65px;
    background: #003c78;
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    line-height: 65px;
    text-align: center;
    font-size: 23px;

    box-shadow: 0 10px 25px -5px #003c78;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
`;

const SvgFloatingButton = styled.svg`
    fill: currentColor;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 32px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
`;

const Zendesk = () => {

    const handleClick = () => {
        window.open("https://institutoayrtonsenna5795.zendesk.com/hc/pt-br/requests/new")
    }

    return (
        <>
            <FloatingContainer onClick={handleClick} className="floating-container">
                <FloatingButton className="floating-button">
                <IoHelpCircleSharp size={60}/>
                </FloatingButton>
            </FloatingContainer>
        </>
    );
};

export default Zendesk;
