import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const LancamentoStyled = {
    Container: styled.div`
        background-color: #003c78;
        padding: 100px 0;
        padding-left: 10%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 60px 35px;
        }
    `,

    RowGrid: styled.div`
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    UnderTitle: styled.p`
        color: #ffca00;
        line-height: 22px;
    `,

    Title: styled.p`
        font-family: Barlow;
        color: #ffca00;
        font-size: 48px;
        font-weight: 700;
        line-height: 52px;
        max-width: 530px;
        margin-bottom: 20px;
        margin-top: 15px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 36px;
            line-height: 40px;
        }
    `,

    Text: styled.div`
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
    `,

    UnderTitle2: styled.p`
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-family: Barlow;
        margin-bottom: 35px;
    `,

    VDivisionContainer: styled.div`
        flex-grow: 1;
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: none;
        }
    `,

    VDivision: styled.div`
        background-color: white;
        width: 1px;
        height: 100px;
    `,

    Col: styled.div`
        display: flex;
        flex-direction: column;
    `,

    ColLeft: styled.div`
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin: auto 0;
        margin-right: 100px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 0 auto;
            margin-top: 30px;
            width: 80%;
        }
    `,

    ButtonsContainer: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 60px;
        width: 105%;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
            margin-top: 30px;
        }
    `,

    AccessMakerButton: styled(PrimaryButton)`
        width: 260px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
        }
    `,

    MoreContentButton: styled(PrimaryButton)`
        width: 260px;

        border-color: #ffca00;
        background-color: transparent;
        color: #ffca00;

        :hover {
            background-color: #ffca00;
            color: #003c78;
        }
    `,

    LogosContainer: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    `,

    LogoImage: styled.img`
        height: 60px;
        width: fit-content;
        padding-right: 20%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 45px;
        }
    `,
};

export default LancamentoStyled;
