import keycloak from 'core/config/keycloak';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { LocationDescriptor, LocationState, Path } from 'history';

namespace GenericComponentsUtils {
    const reDomain =
        /^(?:https?:\/\/(?:localhost:3000|homhumane\.institutoayrtonsenna\.org\.br|humane\.institutoayrtonsenna\.org\.br))?(?:\/(?:pagina|recursos|alfabetizacao-360|competencias-socioemocionais|cursos-livres|socioemocional-professores|biblioteca|realizando-escolhas|criatividade-pensamento-critico|realizandoEscolhas)\/?)?$/i;

    const isInternalLink = (path: string): boolean => {
        if (
            path.includes('localhost') ||
            path.includes('institutoayrtonsenna') ||
            path.includes('pagina') ||
            path.includes('recursos') ||
            path.includes('alfabetizacao') ||
            path.includes('competencias-socioemocionais') ||
            path.includes('competencias-socioemocionais') ||
            path.includes('cursos-livres') ||
            path.includes('socioemocional-professores') ||
            path.includes('biblioteca') ||
            path.includes('realizando-escolhas') ||
            path.includes('realizandoEscolhas') ||
            path.includes('criatividade-pensamento-critico')
        ) {
            return true;
        }

        return false;
    };

    const isLMS = (path: string): boolean => {
        if (path.includes('brightspace') || path.includes('cursoslivres') || path.includes('d2l')) {
            return true;
        }

        return false;
    };

    const verifyRedirecionamento = (path: string, isAuthenticated: boolean, redirect: TipoRedirecionamento) => {
        if (redirect == TipoRedirecionamento.IGNORAR) {
            window.open(path.replace(reDomain, ''), '_blank');
        } else if (!isAuthenticated) {
            if (redirect == TipoRedirecionamento.LOGIN) {
                const urlRegister = keycloak.createLoginUrl({
                    redirectUri: window.location.origin + '/login?' + path.replace(reDomain, ''),
                });
                window.open(urlRegister, '_blank');
            } else if (redirect == TipoRedirecionamento.CADASTRO) {
                const urlRegister = keycloak.createRegisterUrl({
                    redirectUri: window.location.origin + '/login?' + path.replace(reDomain, ''),
                });
                window.open(urlRegister, '_blank');
            }
        } else {
            window.open(path, '_blank');
        }
    };

    interface SecaoDataPartial {
        tipoRedirecionamento: TipoRedirecionamento;
    }

    interface Redirectable {
        push: (location: Path | LocationDescriptor<LocationState>, state?: LocationState) => void;
    }

    export function gotoLink(
        path: string,
        redirectable: Redirectable,
        isAuthenticated: boolean,
        extras: SecaoDataPartial,
        defaultExtras?: SecaoDataPartial
    ) {
        if (isLMS(path)) {
            if (extras.tipoRedirecionamento === TipoRedirecionamento.LOGIN) {
                window.open(path, '_blank');
            } else {
                verifyRedirecionamento(path, isAuthenticated, extras.tipoRedirecionamento);
            }
        } else if (isInternalLink(path) || extras.tipoRedirecionamento !== TipoRedirecionamento.IGNORAR) {
            if (defaultExtras) {
                verifyRedirecionamento(path, isAuthenticated, defaultExtras.tipoRedirecionamento);
            } else {
                verifyRedirecionamento(path, isAuthenticated, extras.tipoRedirecionamento);
            }
        } else {
            window.open(path, '_blank');
        }
    }
}

export default GenericComponentsUtils;
