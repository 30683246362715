import { jsonProperty, Serializable } from 'ts-serializable';

class CategoriaPE extends Serializable {
    @jsonProperty(Number, null)
    id: number | null = -1;

    @jsonProperty(String)
    nome: string = '';
}


export default CategoriaPE