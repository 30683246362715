import styled, { keyframes } from 'styled-components';

import Colors from '../../../../../enums/Colors';
import { ConfirmButton } from './ImageSelect.styled';

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    animation: ${fade} 0.3s linear;
`;

export const AddImageButton = styled(ConfirmButton)`
    width: 100%;
    margin: 0;
`;

export const Gloom = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContainer = styled.div`
    width: 90%;
    margin: 0 10px;
    z-index: 999;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0;
    overflow: hidden;

    animation: ${fade} 0.3s linear;

    // transform: translateY(62px);
    position: fixed;
    bottom: 10px;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    padding: 20px;
    transition: 0.2s;
    user-select: none;

    &:hover {
        background-color: #e9e9e9;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${Colors.Grey8};
`;

export const Icon = styled.img`
    height: 20px;
    width: auto;
`;

export const Label = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${Colors.BlueGrey};
    margin: 0 15px;
    user-select: none;
`;
