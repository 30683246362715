import { ICategoriaRegra } from "pages/acessoExclusivo/components/Categoria/CardCategoria";
import { GenericService } from "../Generic.service";



export class CategoriaRegrasPE extends GenericService {
    readonly URI: string = '/v1/categoriaRegraPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public saveAll(regras: ICategoriaRegra[]) {
        return this.post('', regras)
    }
    public deleteCR(data: ICategoriaRegra) {
        return this.deleteByBody('', data)
    }


}