import React, { FunctionComponent, useState } from 'react';

import styled from 'styled-components';
import Colors from '../../../enums/Colors';

const AddOtherInputStyle = styled.div`
    height: 57px;
    padding: 14px 28px;
    border-bottom: 1px solid;
    border-color: ${Colors.Grey8};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const InputField = styled.input`
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.2px;
    border-radius: 3px;
    background-color: ${Colors.Grey11};
    height: 36px;
    width: 162px;
    color: ${Colors.Grey4};
    font-weight: 500;
    padding: 12px 11px;
`;

const InputAddCommandButton = styled.p`
    cursor: pointer;
    text-transform: uppercase;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: ${Colors.SoftBlue};
`;

interface IAddOtherButton {
    OnAddOtherHandler: (value: string) => void;
}
const AddOtherButton: FunctionComponent<IAddOtherButton> = ({ OnAddOtherHandler }: IAddOtherButton) => {
    const [inputValue, setInputValue] = useState<string>('');

    const OnClickHandler = () => {
        if (inputValue) {
            OnAddOtherHandler(inputValue);
            setInputValue('');
        }
    };

    return (
        <AddOtherInputStyle>
            <InputField
                maxLength={18}
                placeholder="Outra"
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                }}
            />
            <InputAddCommandButton onClick={OnClickHandler}>Adicionar</InputAddCommandButton>
        </AddOtherInputStyle>
    );
};

export default AddOtherButton;
