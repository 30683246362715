import { createContext } from 'react';

export namespace PdpContext {
    export interface IContext {
        operacaoAlteracao: boolean;
        setOperacaoAlteracao: (value: boolean) => void;
        isMetasFetched: boolean;
        setMetasFetched: (value: boolean) => void;
    }

    export const Context = createContext({} as IContext);
}
