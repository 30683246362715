import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import AdminGestaoPaginasProvider from './context/AdminGestaoPaginasProvider';
import AdminGestaoPaginasNewEdit from './new-edit-pagina/AdminGestaoPaginasNewEdit';
import AdminGestaoPaginaNovoRecurso from './new-edit-recurso/AdminGestaoPaginaNovoRecurso';
import AdminGestaoPaginaItemEtapaRecurso from './new-edit-recurso/item-etapa-recurso/AdminGestaoPaginaItemEtapaRecurso';
import AdminGestaoDePaginaItemRecurso from './new-edit-recurso/item-recurso/AdminGestaoPaginaItemRecurso';
import AdminGestaoPaginasSectionForm from './section-form/AdminGestaoPaginasSectionForm';
import AdminGestaoPaginaConteudoRecurso from './new-edit-recurso/conteudo-recurso/AdminGestaoPaginaConteudoRecurso';

export type AdminGestaoPaginasMode = 'NEW' | 'EDIT';

export interface IAdminGestaoPaginasContextWrapper {}

const AdminGestaoPaginasContextWrapper = ({}: IAdminGestaoPaginasContextWrapper) => {
    return (
        <AdminGestaoPaginasProvider>
            {/*  Manipular Páginas */}
            <Route exact path="/admin/gestao-paginas/nova-pagina" component={() => <AdminGestaoPaginasNewEdit mode="NEW" />} />
            <Route exact path="/admin/gestao-paginas/editar-pagina/:id" component={() => <AdminGestaoPaginasNewEdit mode="EDIT" />} />

            {/* Manipular Seções */}
            <Route exact path="/admin/gestao-paginas/editar-secao" component={() => <AdminGestaoPaginasSectionForm mode={'NEW'} />} />
            <Route exact path="/admin/gestao-paginas/editar-secao/:id" component={() => <AdminGestaoPaginasSectionForm mode={'EDIT'} />} />

            {/* RECURSOS */}
            <Route exact path="/admin/gestao-paginas/novo-recurso" component={() => <AdminGestaoPaginaNovoRecurso mode="NEW" />} />
            <Route exact path="/admin/gestao-paginas/editar-recurso/:id" component={() => <AdminGestaoPaginaNovoRecurso mode={'EDIT'} />} />

            {/* ITEM DE UM NOVO RECURSO */}
            <Route
                exact
                path="/admin/gestao-paginas/novo-item-recurso/:quantidade"
                component={() => <AdminGestaoDePaginaItemRecurso mode="NEW" />}
            />
            <Route
                exact
                path="/admin/gestao-paginas/editar-item-recurso/:id"
                component={() => <AdminGestaoDePaginaItemRecurso mode={'EDIT'} />}
            />

            {/* ETAPAS DE UM ITEM */}
            <Route
                exact
                path="/admin/gestao-paginas/nova-etapa-item/:nomeItem/:idItem/:quantidade"
                component={() => <AdminGestaoPaginaItemEtapaRecurso mode="NEW" />}
            />
            <Route
                exact
                path="/admin/gestao-paginas/editar-etapa-item/:nomeItem/:idItem/:id"
                component={() => <AdminGestaoPaginaItemEtapaRecurso mode="EDIT" />}
            />
            {/* CONTEUDOS DE UM ETAPA */}
            <Route
                exact
                path="/admin/gestao-paginas/novo-item-etapa-recurso/:nomeItem/:idItem/:nomeEtapa/:idEtapa/:quantidade"
                component={() => <AdminGestaoPaginaConteudoRecurso mode={'NEW'} />}
            />
            <Route
                exact
                path="/admin/gestao-paginas/editar-item-etapa-recurso/:nomeItem/:idItem/:nomeEtapa/:idEtapa/:id"
                component={() => <AdminGestaoPaginaConteudoRecurso mode={'EDIT'} />}
            />
        </AdminGestaoPaginasProvider>
    );
};

export default AdminGestaoPaginasContextWrapper;
