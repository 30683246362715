import { jsonProperty, Serializable } from 'ts-serializable';
import TipoMeta from './TipoMetaEnum';

class Meta extends Serializable {
    @jsonProperty(Number, null)
    id: number | null = null;

    @jsonProperty(Number, null)
    codigo: number | null = null;

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(String)
    tipo: string = '';

    constructor() {
        super();
    }
}

export default Meta;
