import styled from 'styled-components';

const Main = styled.main`
    padding: 90px 0 0 0;

    @media only screen and (max-width: 1200px) {
        padding: 60px 0 0 0;
    }
`;

const Section = styled.section<{ color?: string; padding?: string }>`
    display: flex;
    flex-direction: column;
    height: 600px;
    padding-top: 10px;
    padding-left: ${props => (props.padding ? props.padding : '10%')};
    background-color: ${props => (props.color ? props.color : '#FFFFFF')};
    position: relative;

    @media only screen and (max-width: 1200px) {
        padding-left: 0;
        height: 100%;
    }
`;

export { Main, Section };
