import styled from 'styled-components';
import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';

export const AquecimentoStyle = styled.div`
    background-color: ${Colors.White};
    padding: 48px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 20px;
    }
`;

export const FormControl = styled.div({
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
});

export const ButtonContainer = styled.div({
    marginTop: '40px',
});

export const QuestionContainer = styled.div({
    marginTop: '40px',
    marginBottom: '32px',
});

export const Container = styled.div<{ isInvalid: boolean }>`
    display: inline-block;
    padding: 0 15px;
    background: ${props => (props.isInvalid ? 'rgba(255, 0, 0, 0.24)' : '')};
`;
