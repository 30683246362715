import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}
export const ArrowButton = styled.div<IArrowButton>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: #00a8e9;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 50%;
    position: absolute;
    z-index: 8;
    top: 50%;
    right: ${props => (props.next ? '12%' : '')};
    left: ${props => (!props.next ? '12%' : '')};
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 16px;
    width: 16px;
    fill: transparent;
    position: relative;
    top: 5px;

    &:hover {
        fill: ${Colors.SoftBlue};
    }

    &:hover path {
        stroke: ${Colors.White};
    }
`;

export const CarouselDots = styled.div`
    .slick-active div {
        background: ${Colors.Blue};
    }
`;

export const CarouselCustomPaging = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid ${Colors.Grey5};
    border-radius: 50%;
`;

export const Wrapper = styled.div`
    text-align: center;
`;

export const CarouselCardContainer = styled.div`
    outline: none;
    border: none;
`;

export const CarouselContainer = styled.div`
    margin-bottom: 5vw;

    .slick-list {
        margin: 0 5px;
    }

    .slick-slider {
        right: 100px;
        bottom: 30px;
    }

    .slick-slider > div {
        padding: 0 5px;
    }

    .slick-track {
        margin-bottom: 60px;
        display: flex;
        align-items: center;
    }

    @media screen and (min-width: 1250px) {
        display: inline-block;
    }

    @media screen and (max-width: 1250px) {
        .slick-slider {
            right: 0;
            bottom: 45px;
        }
    }

    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        max-width: 100%;
        height: 300px;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 0px;
        padding: 0px 0 15px 0;

        .slick-slide {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-slider {
            right: 0;
            bottom: 45px;
        }
    }
`;
