import React, { CSSProperties, FunctionComponent } from 'react';

import { IconType } from 'react-icons';
import { MdInfo } from 'react-icons/md';
import { CSSObject } from 'styled-components';

import Colors from '../../enums/Colors';
import { Question } from './QuestionEnum';
import { PopupQuestionStyles } from './PopupQuestion.styled';
import { OutlineButton, PrimaryButton } from '../UI/Buttons';
import { IsMobileMaxWidth } from '../../core/util/WindowSize';
import { ModalStyled } from '../../pages/pdp/UI/dialog/DialogFinal.styled';

interface IPopupQuestion {
    visible: boolean;
    onButton: (val: Question.Options) => void;

    question: string;

    icon?: IconType;
    alertColor?: string;

    okButton?: React.ReactNode;
    okButtonText: string;
    cancelButton?: React.ReactNode;
    cancelButtonText?: string;
    infoOnly?: boolean;

    style?: CSSObject;
}

const PopupQuestion: FunctionComponent<IPopupQuestion> = ({
    onButton,
    visible,
    question,
    okButton,
    okButtonText,
    cancelButton,
    cancelButtonText = 'Cancelar',
    infoOnly = false,
    icon: Icon = MdInfo,
    alertColor = Colors.InfoRed,
}: React.PropsWithChildren<IPopupQuestion>) => {
    const onOk = () => {
        onButton(Question.Options.Confirm);
    };
    const onCancel = () => {
        onButton(Question.Options.Cancel);
    };

    if (!okButton && !infoOnly) {
        okButton = (
            <PrimaryButton onClick={onOk} bgColor={alertColor} color={Colors.White} hoverColor={Colors.BlueGrey}>
                {okButtonText}
            </PrimaryButton>
        );
    }
    if (!cancelButton) {
        cancelButton = <OutlineButton onClick={onCancel}>{cancelButtonText}</OutlineButton>;
    }
    const iconStyle: CSSProperties = {
        fontSize: IsMobileMaxWidth() ? 60 : 73,
        color: alertColor,
    };

    return (
        <ModalStyled show={visible} keyboard={false} backdrop="static" dialogAs={PopupQuestionStyles.Container}>
            <PopupQuestionStyles.Header>
                <Icon style={iconStyle} />
            </PopupQuestionStyles.Header>
            <PopupQuestionStyles.Body>{question}</PopupQuestionStyles.Body>
            <PopupQuestionStyles.Footer>
                {cancelButton}
                {okButton}
            </PopupQuestionStyles.Footer>
        </ModalStyled>
    );
};

export default PopupQuestion;
