import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckedGoToContext } from './CheckedGoToContext';

interface ICheckedGoToProvider {}

const CheckedGoToProvider = ({ children }: React.PropsWithChildren<ICheckedGoToProvider>) => {
    const use = useLocation();
    const history = useHistory();
    const [showModal, _setShowModal] = useState<boolean>(false);
    const [goTo, _setGoTo] = useState<string>('');

    const setShowModal = (value: boolean) => {
        _setShowModal(value);
    };

    const isVerifyGoToPage = (rotaDesejada: string) => {
        if (
            (rotaDesejada !== use.pathname && use.pathname === '/painelSocioemocional/autoavaliacao') ||
            (rotaDesejada !== use.pathname && use.pathname === '/realizandoEscolhas/avaliacao' && !showModal)
        ) {
            _setShowModal(true);
            _setGoTo(rotaDesejada);
        } else {
            goToPage(rotaDesejada);
        }
    };

    const goToPage = (link?: string) => {
        _setShowModal(false);
        history.push(link ?? goTo);
    };

    const context: CheckedGoToContext.IContext = {
        showModal,
        setShowModal,

        isVerifyGoToPage,
        goToPage,
    };

    return <CheckedGoToContext.Context.Provider value={context}>{children}</CheckedGoToContext.Context.Provider>;
};

export default CheckedGoToProvider;
