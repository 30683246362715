import { jsonProperty, Serializable } from 'ts-serializable';
import { CardCategoria } from './CardCategoria';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

export class CardsSecaoCardCategoria extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(CardCategoria, null)
    categoria: CardCategoria | null = null;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(Boolean)
    linkExterno: boolean = false;

    @jsonProperty(String)
    link: string = '';

    @jsonProperty(String)
    linkSaibaMais: string = '';

    @jsonProperty(Boolean)
    ativo: boolean = false;

    @jsonProperty(Boolean)
    desabilitado: boolean = false;

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(String, null)
    filePath: string | null = '';

    @jsonProperty(String)
    filterCategory: string = '';

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;
}
