import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { ResizeMediumWidth } from 'constants/Dimensions';
import { ModalStyled, ModalBodyStyled, Container, ContentSection, ImageSection, SvgIcon, Footer } from 'components/UI/dialog/Dialog.styled';

interface IDialogContainer {}
const DialogContainer = styled.div<IDialogContainer>(({ ...props }: IDialogContainer) => ({}));

interface IDialog {
    icon: string;
    visible: boolean;
    bannerSvg: string;
    onClose: () => void;
    bannerSmallSvg: string;
    footer: React.ReactNode;
    mobileHeader?: React.ReactNode;
}

const isMobile = () => IsCustomMaxWidth(ResizeMediumWidth);

const Dialog: FunctionComponent<IDialog> = ({
    footer,
    visible,
    onClose,
    children,
    icon,
    bannerSvg,
    mobileHeader,
    bannerSmallSvg,
}: React.PropsWithChildren<IDialog>) => {
    return (
        <ModalStyled
            body
            show={visible}
            keyboard={false}
            onHide={onClose}
            backdrop="static"
            dialogClassName="dialogWidth"
            contentClassName="dialogWidth"
        >
            <ModalBodyStyled>
                <Container>
                    <ContentSection>
                        {children}
                        <Footer>{footer}</Footer>
                    </ContentSection>
                    <ImageSection svgSource={isMobile() ? bannerSmallSvg : bannerSvg}></ImageSection>
                    {isMobile() && mobileHeader}

                    <SvgIcon src={icon} />
                </Container>
            </ModalBodyStyled>
        </ModalStyled>
    );
};

export default Dialog;
