import React, { FunctionComponent } from 'react';

import { BoldText } from '../UI/Texts';
import { GradientButton } from '../UI/Buttons';
import { ButtonsContainer, OutlineButtonStyled } from './PopulStart.styled';

interface IPopupStart {
    title?: string;
    start?: string;
    cancel?: string;
    onStart: () => void;
    onCancel: () => void;
    startDisabled?: boolean;
    cancelDisabled?: boolean;
}

const PopupStart: FunctionComponent<IPopupStart> = ({
    title = 'Vamos começar?',
    start = 'Começar',
    cancel = 'Agora não',
    onStart,
    onCancel,
    startDisabled = false,
    cancelDisabled = false,
}: React.PropsWithChildren<IPopupStart>) => {
    return (
        <>
            <BoldText>{title}</BoldText>
            <ButtonsContainer>
                <GradientButton onClick={onStart} disabled={startDisabled}>
                    {start}
                </GradientButton>
                <OutlineButtonStyled onClick={onCancel} disabled={cancelDisabled}>
                    {cancel}
                </OutlineButtonStyled>
            </ButtonsContainer>
        </>
    );
};

export default PopupStart;
