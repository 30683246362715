import React, { useEffect, FunctionComponent, useContext, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import editorConfig from './editorConfig';
import DiaryTextTitle from './DiaryTextTitle';
import { DiaryTextStyle } from './DiaryText.styled';
import { DiaryTextHelper } from './DiaryTextHelper';
import { DiarioBordoContext } from 'pages/diarioBordo/context/DiarioContext';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { AiOutlineFile, AiOutlinePrinter } from 'react-icons/ai';
import DiaryTextFooter from './DiaryTextFooter';
import * as html2pdf from 'html2pdf.js';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

interface IDiarioText {
    onDeleted?: () => void;
}

const DiarioText = ({ onDeleted }: IDiarioText) => {
    const { current, onUpdate, onDelete, onEditting, setOnEditting, profile } = useContext(DiarioBordoContext.Context);
    const [value, setValue] = useState(current.texto);
    const [isSaving, setSaving] = useState<boolean>(false);
    const [noSaved, setNoSaved] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const [randomKey, setRandomKey] = useState<number>(0);

    const onTitleEditHandler = (value: string) => {
        current.titulo = value;

        if (current.texto == null) current.texto = '';

        DiaryTextHelper.debounceOnChangeText(onTimeout);
    };

    const onExcludeHandler = async () => {
        await onDelete(current);

        if (onDeleted) onDeleted();
    };

    const onTimeout = async () => {
        // o uso do hook useFetchCallback
        // acarreta erro na atualização do título
        setSaving(true);

        try {
            await onUpdate();
        } catch (error) {
            console.error(error);
        } finally {
            setSaving(false);
            setNoSaved(false);
        }
    };

    const onSave = async () => {
        await onTimeout();
        setOnEditting(false);
    };

    const cleanHTML = (dirtyHTML: string) =>
        DOMPurify.sanitize(dirtyHTML, {
            USE_PROFILES: { html: true },
        });

    useEffect(() => {
        DiaryTextHelper.clearDebounce();
        setValue(current.texto);
    }, [current]);

    useEffect(() => {
        setRandomKey(Math.random());
    }, [current.id]);

    useEffect(() => {
        current.texto = value;
    }, [value]);

    const CancelEdit = () => {
        if (noSaved) setShowAlert(true);
        else setOnEditting(false);
    };

    const ConfirmCancelEdit = () => {
        setOnEditting(false);
    };

    const print = () => {
        window.print();
    };

    const printAsPdf = () => {
        const printButtons = document.getElementById('print-buttons');
        if (printButtons) printButtons.style.visibility = 'hidden';

        const footerButtons = document.getElementById('footer-buttons');
        if (footerButtons) footerButtons.style.visibility = 'hidden';

        const element = document.getElementById('content-diary');
        const opt = {
            margin: 1,
            filename: `${current.titulo?.replace(/\s/g, '-') ?? 'sem-titulo'}-${moment().format('DD-MM_HH:mm')}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        if (element) {
            html2pdf().set(opt).from(element).save();
        }

        setTimeout(() => {
            if (printButtons) printButtons.style.visibility = 'visible';
            if (footerButtons) footerButtons.style.visibility = 'visible';
        }, 1500);
    };

    return (
        <DiaryTextStyle.Container id="content-diary">
            {!onEditting && (
                <>
                    <DiaryTextStyle.PrintContainer id="print-buttons">
                        <DiaryTextStyle.PrintIcon
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'imprimir_btn');
                                print();
                            }}
                        >
                            <AiOutlinePrinter />
                        </DiaryTextStyle.PrintIcon>
                        <DiaryTextStyle.PrintIcon
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'imprimir_pdf_btn');
                                printAsPdf();
                            }}
                        >
                            <AiOutlineFile style={{ marginLeft: '20px' }} />
                        </DiaryTextStyle.PrintIcon>
                    </DiaryTextStyle.PrintContainer>
                    <DiaryTextStyle.Title>{current.titulo || '(sem tílulo)'}</DiaryTextStyle.Title>
                    <DiaryTextStyle.SubTitle>Editado em: {moment(current.edicao ?? new Date()).format('DD/MM/YYYY')}</DiaryTextStyle.SubTitle>
                </>
            )}

            <DiaryTextStyle.TextContainer>
                {onEditting ? (
                    <>
                        <DiaryTextStyle.Label>Título do registro</DiaryTextStyle.Label>
                        <DiaryTextTitle
                            key={randomKey}
                            title={current.titulo || ''}
                            editable={!current.isFixed}
                            onChange={onTitleEditHandler}
                            onBlur={onTimeout}
                        />

                        <br />
                        <DiaryTextStyle.Label>Corpo do registro</DiaryTextStyle.Label>
                        <DiaryTextStyle.RichContainer>
                            {!showAlert && (
                                <ReactQuill
                                    theme="snow"
                                    modules={editorConfig.modules}
                                    formats={editorConfig.formats}
                                    value={value ?? ''}
                                    onChange={val => {
                                        if (current.isFixed) {
                                            const newVal = DiaryTextHelper.onChangeCheckTextFix(current.texto ?? '', val);
                                            setValue(newVal);
                                        } else {
                                            setValue(val);
                                        }

                                        setNoSaved(true);

                                        //NÃO mover para o hook useEffect!!!
                                        DiaryTextHelper.debounceOnChangeText(onTimeout, 2000);
                                    }}
                                />
                            )}
                        </DiaryTextStyle.RichContainer>
                        <br />

                        <DiaryTextStyle.Row>
                            <DiaryTextStyle.OutlineButtonDiary
                                style={{ marginRight: '30px' }}
                                onClick={() => {
                                    GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'cancelar_btn');
                                    CancelEdit();
                                }}
                            >
                                Voltar
                            </DiaryTextStyle.OutlineButtonDiary>
                            <DiaryTextStyle.PrimaryButtonDiary
                                onClick={() => {
                                    GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'salvar_btn');
                                    onSave();
                                }}
                            >
                                {isSaving ? 'Salvando...' : 'Salvar registro'}{' '}
                            </DiaryTextStyle.PrimaryButtonDiary>
                        </DiaryTextStyle.Row>
                    </>
                ) : (
                    <>
                        <DiaryTextStyle.Text dangerouslySetInnerHTML={{ __html: cleanHTML(current.texto ?? '') }} />

                        {!onEditting && (
                            <DiaryTextStyle.FootButtonContainer id="footer-buttons">
                                {!current.isFixed && (
                                    <DiaryTextStyle.OutlineButtonDiary
                                        className="red-mobile"
                                        style={{ marginRight: '30px' }}
                                        onClick={() => {
                                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'deletar_btn');
                                            onExcludeHandler();
                                        }}
                                    >
                                        Deletar registro
                                    </DiaryTextStyle.OutlineButtonDiary>
                                )}

                                <DiaryTextStyle.PrimaryButtonDiary
                                    onClick={() => {
                                        GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'editar_btn');
                                        setOnEditting(true);
                                    }}
                                >
                                    Editar registro
                                </DiaryTextStyle.PrimaryButtonDiary>
                            </DiaryTextStyle.FootButtonContainer>
                        )}
                    </>
                )}
            </DiaryTextStyle.TextContainer>

            <DiaryTextFooter isLoading={isSaving} dateUpdate={current.edicao ?? new Date()} />
            <br />
        </DiaryTextStyle.Container>
    );
};

export default DiarioText;
