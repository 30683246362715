import React, { useCallback, useEffect, useState } from 'react';
import Styled from './Avaliations.styled';
import Select, { SelectOptionType } from 'components/Select';
import { Avaliations_optionsOrder, GP_defaultOption, GP_optionOrderNota, GP_optionOrderType, GP_optionsOrder } from './OptionsFilter';
import AdminEditorStyled from 'pages/admin/components/pageEditors/AdminEditor.styled';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import TableAvaliation from './tables/TableAvaliation';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import AdminGestaoPaginaNotas from 'models/admin/AdminGestaoPaginaNotas';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import moment from 'moment';
import AdminGestaoPaginaMedia from 'models/admin/AdminGestaoPaginaMedia';
import Loading from 'components/Loading';
import { OutlineButton } from 'components/UI/Buttons';
import searchIcon from '../../../../images/biblioteca/search-icon-black.svg';

export interface IFilterAvaliacao {
    pagina: number;
    ordem: string;
    tipo: string;
    nota: string;
    por_pagina: number;
    data: Date | string | undefined;
    dataFim: Date | string | undefined;
}

export interface IFilterMedia {
    filtro: string;
    tipo: string;
    pagina: number;
    ordem: string;
    por_pagina: number;
}

const AvaliationsGestaoDePaginas = () => {
    const [loading, setLoading] = useState(false);
    const [avaliations, setAvaliations] = useState<AdminGestaoPaginaNotas[]>([]);
    const [avaliationsMedia, setAvaliationsMedia] = useState<AdminGestaoPaginaMedia[]>([]);

    const [totalPages, setTotalPages] = useState(1);
    const [isAvarage, setIsAvarage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [cleanKey, setCleanKey] = useState(0);

    const service = new AdminRecursosCostumizados();

    const [pathFilterMedia, setPathFilterMedia] = useState<IFilterMedia>({
        filtro: '',
        tipo: '',
        ordem: '',
        pagina: 1,
        por_pagina: 10,
    });

    const [pathFilter, setArrayFilter] = useState<IFilterAvaliacao>({
        ordem: '',
        pagina: 1,
        tipo: '',
        nota: '',
        por_pagina: 10,
        data: undefined,
        dataFim: undefined,
    });

    const changePage = (page: number) => {
        isAvarage ? fetchAverage(page) : fetchAvaliations(page);
    };

    const fetchAverage = async (pagina?: number) => {
        setLoading(true);
        const { data } = await service.fetchGestaoDePaginaAverage({ ...pathFilterMedia, pagina: pagina ?? pathFilter.pagina });
        setAvaliationsMedia(data.rows ?? []);
        setTotalPages(data.totalPages);
        setLoading(false);
    };

    const fetchAvaliations = async (pagina?: number) => {
        setLoading(true);
        const dataFormat = pathFilter.data !== undefined ? moment(pathFilter.data).format('L') : undefined;
        const datafimFormat = pathFilter.dataFim !== undefined ? moment(pathFilter.dataFim).format('L') : undefined;

        const { data } = await service.fetchGestaoDePaginaAvaliations({
            ...pathFilter,
            pagina: pagina ?? pathFilter.pagina,
            data: dataFormat,
            dataFim: datafimFormat,
        });
        setAvaliations(data.rows ?? []);
        setTotalPages(data.totalPages);
        setTotalItems(data.range);
        setLoading(false);
    };

    const cleanFilter = () => {
        setArrayFilter({ ordem: '', pagina: 1, tipo: '', nota: '', por_pagina: 10, data: undefined, dataFim: undefined });
        setCleanKey(Math.random());
        fetchAvaliations();
    };

    useEffect(() => {
        if (isAvarage) {
            fetchAverage();
        } else {
            fetchAvaliations();
        }
    }, [isAvarage]);

    useEffect(() => {
        if (!isAvarage) fetchAvaliations();
    }, [pathFilter]);

    useEffect(() => {
        if (isAvarage) fetchAverage();
    }, [pathFilterMedia]);

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';

    const DatePickerWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat={'dd/MM/yyyy'}
            selected={pathFilter.data}
            onChange={(date: Date) => {
                setArrayFilter({
                    ...pathFilter,
                    data: date ?? undefined,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    const DatePickerFimWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat={'dd/MM/yyyy'}
            selected={pathFilter.dataFim}
            onChange={(date: Date) => {
                setArrayFilter({
                    ...pathFilter,
                    dataFim: date ?? undefined,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    return (
        <Styled.Container>
            <Styled.Title>Avaliações</Styled.Title>
            <Styled.ContainerRow style={{ marginBottom: '20px' }}>
                <Styled.SectionChip enabled={!isAvarage} onClick={() => setIsAvarage(false)}>
                    Notas
                </Styled.SectionChip>
                <Styled.SectionChip enabled={isAvarage} onClick={() => setIsAvarage(true)}>
                    Média notas
                </Styled.SectionChip>
            </Styled.ContainerRow>

            <div key={cleanKey}>
                {!isAvarage ? (
                    <>
                        <Styled.DivRowRight style={{ position: 'relative', zIndex: 1 }}>
                            <Styled.RowRigth>
                                <span className="label-order">Data inicial:</span>
                                <DatePickerWrapper />
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <span className="label-order">Data final:</span>
                                <DatePickerFimWrapper />
                            </Styled.RowRigth>

                            <Styled.RowRigth>
                                <span className="label-order">Tipo:</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={GP_optionOrderType}
                                        defaultValue={GP_defaultOption}
                                        onSelected={selected => {
                                            setArrayFilter({
                                                ...pathFilter,
                                                tipo: selected.value.toString(),
                                            });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>
                        </Styled.DivRowRight>
                        <br></br>
                        <Styled.DivRowRight style={{ marginBottom: '30px', position: 'relative', zIndex: 0 }}>
                            <Styled.RowRigth>
                                <span className="label-order">Ordenar por:</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={GP_optionsOrder}
                                        defaultValue={GP_optionsOrder[0]}
                                        onSelected={selected => {
                                            setArrayFilter({
                                                ...pathFilter,
                                                ordem: selected.value.toString(),
                                            });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>

                            <Styled.RowRigth>
                                <span className="label-order">Nota:</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={GP_optionOrderNota}
                                        defaultValue={GP_defaultOption}
                                        onSelected={selected => {
                                            setArrayFilter({
                                                ...pathFilter,
                                                nota: selected.value.toString(),
                                            });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <span className="label-order">Resultados encontrados: </span>
                                <span style={{ fontSize: '14px' }}>{totalItems}</span>
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <OutlineButton onClick={cleanFilter} style={{ height: '40px' }}>
                                    Limpar filtros
                                </OutlineButton>
                            </Styled.RowRigth>
                        </Styled.DivRowRight>
                    </>
                ) : (
                    <Styled.DivRowRight style={{ marginBottom: '30px' }}>
                        <Styled.RowRigth>
                            <span className="label-order">Tipo:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={GP_optionOrderType}
                                    defaultValue={GP_defaultOption}
                                    onSelected={selected => {
                                        setPathFilterMedia({
                                            ...pathFilterMedia,
                                            tipo: selected.value.toString(),
                                        });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>

                        <Styled.RowRigth>
                            <span className="label-order">Ordernar por:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={Avaliations_optionsOrder}
                                    defaultValue={GP_defaultOption}
                                    onSelected={selected => {
                                        setPathFilterMedia({
                                            ...pathFilterMedia,
                                            ordem: selected.value.toString(),
                                        });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>

                        <Styled.SearchContainer>
                            <Styled.SearchInput
                                placeholder="Digite o Título do conteúdo ou nome da página"
                                value={pathFilterMedia.filtro}
                                onChange={env => setPathFilterMedia({ ...pathFilterMedia, filtro: env.target.value })}
                                onKeyUp={env => {
                                    if (env.key === 'Enter') {
                                        env.preventDefault();
                                        fetchAverage();
                                    }
                                }}
                            />
                            <Styled.SearchIconButton
                                src={searchIcon}
                                onClick={() => {
                                    fetchAverage();
                                }}
                            />
                        </Styled.SearchContainer>
                    </Styled.DivRowRight>
                )}
            </div>
            {loading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <Styled.GridAvaliacao isAvarage={isAvarage}>
                        <Styled.GridHead>Tipo</Styled.GridHead>
                        <Styled.GridHead>Página</Styled.GridHead>
                        <Styled.GridHead>Título do conteúdo</Styled.GridHead>
                        {!isAvarage && <Styled.GridHead>Autor</Styled.GridHead>}
                        {!isAvarage && <Styled.GridHead>Data</Styled.GridHead>}
                        <Styled.GridHead>{isAvarage ? 'Média' : 'Nota'}</Styled.GridHead>
                        <Styled.GridHead>{isAvarage ? 'Total' : 'Ações'}</Styled.GridHead>

                        <TableAvaliation avaliations={avaliations} avarages={avaliationsMedia} isAverage={isAvarage} />
                    </Styled.GridAvaliacao>
                </React.Fragment>
            )}
            <AdminPagination onChangePage={changePage} total={totalPages} />
        </Styled.Container>
    );
};

export default AvaliationsGestaoDePaginas;
