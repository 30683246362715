import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const BiblioTagSelectorStyled = {
    Container: styled.div``,

    Button: styled.button`
        background-color: #003c78;
        color: white;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border: none;
        outline: none;
        padding: 4px 15px;
        border-radius: 5px;
    `,

    TextHead: styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #2d3648;
    `,

    TextTags: styled.span`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #9e9e9e;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 10px;
            margin-right: 10px;
        }
    `,

    TagItem: styled.div`
        padding: 0 10px;
        color: #003c78;
        border: 1px solid #003c78;
        border-radius: 6px;
        margin: 0 15px;
        height: fit-content;

        .label {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #003c78;
            margin-right: 7px;
        }

        .bt {
            cursor: pointer;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 0;
        }
    `,

    OverContent: styled.div`
        padding-top: 15px;
    `,

    Scroll: styled.div`
        max-width: 100%;
        max-height: 324px;
        overflow-y: auto;
        padding-top: 10px;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
        max-width: 100%;
    `,

    RowTags: styled.div`
        display: flex;
        padding: 0;
        min-height: 30px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-wrap: wrap;
        }
    `,

    ButtonContainer: styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    `,

    SearchButton: styled.button`
        border: none;
        outline: none;
        background: #00a8e9;
        border-radius: 6px;
        color: white;
        padding: 4px 14px;
        margin-left: 20px;

        :disabled {
            opacity: 0.5;
        }
    `,

    CloseButton: styled.button`
        border: none;
        outline: none;
        background: transparent;

        span {
            color: #003c78;
            text-decoration: underline;
        }
    `,

    Desc: styled.label`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #2d3648;
        cursor: pointer;
        transform: translateY(0px);
    `,
};

export default BiblioTagSelectorStyled;
