import { PrimaryButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';

const Styled = {
    Container: styled.div<{ isBlue?: boolean }>`
        display: flex;
        justify-content: space-between;
        background-color: ${({ isBlue }) => (isBlue ? Colors.Blue : Colors.White)};
        color: ${({ isBlue }) => (isBlue ? Colors.White : Colors.Blue)};

        @media screen and (max-width: 1920px) {
            height: auto;
        }
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
        width: 70%;
        padding-top: 40px;
        padding-left: 70px;
        padding-bottom: 40px;

        @media screen and (max-width: 1330px) {
            width: 60%;
        }

        @media screen and (max-width: 990px) {
            padding-left: 40px;
        }

        @media screen and (max-width: 870px) {
            width: 100%;
        }
    `,

    Img: styled.img`
        min-height: 100%;
        width: 30%;
        object-fit: cover;

        @media screen and (max-width: 870px) {
            display: none;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        text-align: left;
        margin-bottom: 20px;
    `,

    Description: styled.p`
        font-family: Barlow;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.019em;
        text-align: left;
        max-width: 90%;
        margin-bottom: 20px;

        ul {
            list-style-type: disc; /* ou 'circle', 'square', etc. */
            padding-left: 20px; /* Adiciona um recuo à lista */
        }

        li {
            margin-bottom: 10px; /* Adiciona espaçamento entre os itens da lista */
        }

        @media screen and (max-width: 1250px) {
            width: 80%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    Button: styled(PrimaryButton)`
        width: 300px;
        margin: 0;
    `,
};

interface IOverviewExclusivo {
    isBlue?: boolean;
    img: string;
    title: string;
    description: string;
    arquivo: string;
    nome_arquivo: string;
}

const OverviewExclusivo = ({ isBlue, img, title, description, arquivo, nome_arquivo }: IOverviewExclusivo) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = arquivo; // URL do PDF
        link.download = `${nome_arquivo}.pdf`; // Nome do arquivo que será baixado
        document.body.appendChild(link); // Adiciona o link ao DOM
        link.click(); // Simula o clique no link
        document.body.removeChild(link); // Remove o link do DOM
    };

    return (
        <Styled.Container isBlue={isBlue}>
            <Styled.Content>
                <div>
                    <Styled.Title>{title}</Styled.Title>
                    <Styled.Description
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </div>

                <Styled.Button onClick={handleDownload}>{`Baixe o documento de visão geral`}</Styled.Button>
            </Styled.Content>
            <Styled.Img src={img} />
        </Styled.Container>
    );
};

export default OverviewExclusivo;
