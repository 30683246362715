import { SelectOptionType } from 'components/Select';
import BiblioItem from 'models/biblioteca/BiblioItem';

export function isStringsValid(values: string[]): boolean {
    for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (!value || value.trim().length == 0) return false;
    }

    return true;
}

export function isFileNotNull(values: any): boolean {
    for (let i = 0; i < values.length; i++) {
        if (!values[i]) return false;
    }

    return true;
}

export function isValidLink(link: string, exterLink?: boolean): boolean {
    if (exterLink) {
        const re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
        return re.test(link) || link.includes('localhost:3000');
    } else return true;
}

export const acceptedExtsLivro = ['pdf', 'txt', 'doc', 'docx', 'odt', 'xls'];

export const acceptedLanguages: SelectOptionType[] = ['Português', 'Inglês', 'Espanhol', 'Francês', 'Outro'].map(
    l => ({ label: l, value: l } as SelectOptionType)
);

export function getExtension(filename: string): string {
    const idxChar = filename.lastIndexOf('.');
    return filename.substring(idxChar + 1, filename.length);
}

export const isSaveRascunho = (itemData?: BiblioItem | null): boolean => {
    if (!itemData) return true;
    else if (itemData.status == 'RASCUNHO') return true;
    else return false;
};
