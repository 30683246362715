import { IsMobileBiggerWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { Iframe } from 'pages/autoavaliacao/Autoavaliacao.styled';
import React from 'react';
import VideoExplicativo from '../VideoExplicativo/VideoExplicativo';
import Styled from './SliderItem.styled';

export interface ISliderItem {
    index: number;
    content: any;
    image: string;
}

const SliderItem = ({ index, content, image }: ISliderItem) => {
    const showVideo = (): boolean => {
        return index == 1 && IsMobileBiggerWidth();
    };

    return (
        <Styled.Container>
            {showVideo() ? <VideoExplicativo /> : <Styled.Image src={image} />}
            <Styled.Content>{content}</Styled.Content>
        </Styled.Container>
    );
};

export default SliderItem;
