import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledParceirosImplementadores = {
    Container: styled.div`
        min-height: 450px;
        padding-left: 70px;
        padding-top: 70px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 24px;

         @media screen and (max-width: 720px) {
               padding-left: 20px;
               height: auto;
               margin-bottom: 24px;
            }
    `,

    Item: {
        Container: styled.div`
            display: flex;
            align-items: start;
            justify-content: start;
            gap: 1rem;
            min-height: 120px;
            margin-bottom: 20px;

            @media screen and (max-width: 720px) {
                gap: 0;
                height: auto;
                flex-direction: column;
            }
        `,

        Imagem: styled.img`
            object-fit: cover;
            height: 100%;
            width: 260px;
        `,

        Content: styled.div`
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            flex-direction: column;

           
        `,

        Title: styled.h1`
            font-size: 24px;
            color: ${Colors.Blue};
            font-weight: 500;
        `,

        Description: styled.p`
            line-height: 28px;
            max-width: 80%;
            font-size: 18px;

            @media screen and (max-width: 720px) {
                max-width: 90%;
                width: 100%;
            }
        `,
    },
};

export default StyledParceirosImplementadores;
