import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { IoSchoolOutline } from 'react-icons/io5';
import { TbCurrencyDollarOff } from 'react-icons/tb';
import { MdOutlineWatchLater } from 'react-icons/md';
import Logo from '../assets/dialogos/LogoPatners.svg';
import { PrimaryButton } from 'components/UI/Buttons';
import { useHistory } from 'react-router-dom';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        min-height: 520px;
        padding-bottom: 40px;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-left: 70px;

        @media screen and (max-width: 700px) {
            padding-left: 40px;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        color: ${Colors.Blue};
        text-align: left;
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.019em;
        text-align: left;
        max-width: 70%;
        margin-bottom: 20px;
        color: ${Colors.Blue};

        @media screen and (max-width: 1250px) {
            width: 80%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 1160px) {
            flex-direction: column-reverse;
            align-items: start;
        }
    `,

    Certificated: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 60px;
            padding: 20px;
            font-size: 16px;
            border-radius: 20px;
            background-color: ${Colors.Blue};
            color: ${Colors.White};
            margin-right: 20px;

            @media screen and (max-width: 1280px) {
                width: 400px;
            }
            @media screen and (max-width: 1160px) {
                width: 350px;
            }

            @media screen and (max-width: 1160px) {
                width: auto;
                margin-bottom: 20px;
            }
        `,
    },

    Time: styled.div`
        display: flex;
        gap: 1rem;
    `,

    SpaceCenter: styled.div`
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 20px;
        color: ${Colors.Blue};
    `,

    Img: styled.img`
        width: 40%;
        height: 560px;
        margin: 10px 0px;
        border-radius: 20px;
        object-fit: cover;

        @media screen and (max-width: 1920px) {
            width: 60%;
        }
        @media screen and (max-width: 1280px) {
            width: 80%;
        }
        @media screen and (max-width: 700px) {
            display: none;
        }
    `,
};

interface IPartnersExclusivo {
    title: string;
    description: string;
    img: string;
    button: {
        label: string;
        url: string;
    };
}

const PartnersExclusivo = ({ title, description, img, button }: IPartnersExclusivo) => {
    const history = useHistory();

    return (
        <Styled.Container>
            <Styled.Content>
                <Styled.RowBetween>
                    <Styled.Title>{title.toLocaleUpperCase()}</Styled.Title>
                    {/* <Styled.Certificated.Container>
                        {' '}
                        <IoSchoolOutline size={25} color={Colors.Yellow} /> Cerificado pelo IEA USP Ribeirão Preto e Instituto Ayrton Senna
                    </Styled.Certificated.Container> */}
                </Styled.RowBetween>
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
                {/* <Styled.Time>
                    <Styled.SpaceCenter>
                        <TbCurrencyDollarOff color={Colors.Yellow} /> Grátis
                    </Styled.SpaceCenter>
                    <Styled.SpaceCenter>
                        {' '}
                        <MdOutlineWatchLater color={Colors.Yellow} /> X horas
                    </Styled.SpaceCenter>
                </Styled.Time> */}
                <PrimaryButton onClick={() => history.push(button.url)} style={{ width: '250px', margin: '10px 0' }}>
                    {button.label}
                </PrimaryButton>
                <Styled.Img src={img} />
            </Styled.Content>
        </Styled.Container>
    );
};

export default PartnersExclusivo;
