import { jsonProperty, Serializable } from 'ts-serializable';
import { CardsCarrossel } from './CardsCarrossel';

export class SecaoCarrossel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty([CardsCarrossel], [])
    carrosseis: [CardsCarrossel] | [] = [];

    @jsonProperty(String)
    usuarioResponsavel: string = '';
}

export default SecaoCarrossel;
