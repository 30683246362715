import { useKeycloak } from '@react-keycloak/web';
import { getRoles } from '@testing-library/react';
import { RoleEnum } from 'enums/RoleEnum';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { KeycloakInstance, KeycloakRoles } from 'keycloak-js';
import UsuarioDTO from 'models/UsuarioDTO';
import KcUserInfo, { KcAdminGroups } from 'models/kc/KcUserInfo';
import { useHistory } from 'react-router-dom';

export const getUserRole = (keycloakRoles: string[] | undefined): RoleEnum[] => {
    var retorno: RoleEnum[] = [];

    keycloakRoles?.forEach(role => {
        switch (role) {
            case RoleEnum.Professor:
                retorno.push(RoleEnum.Professor);
                break;
            case RoleEnum.GestorEscolar:
                retorno.push(RoleEnum.GestorEscolar);
                break;
            case RoleEnum.GestorRede:
                retorno.push(RoleEnum.GestorRede);
                break;
            case RoleEnum.Pesquisador:
                retorno.push(RoleEnum.Pesquisador);
                break;
            case RoleEnum.CoordenadorRegional:
                retorno.push(RoleEnum.CoordenadorRegional);
                break;
            case RoleEnum.ColaboradorParceria:
                retorno.push(RoleEnum.ColaboradorParceria);
                break;
            case RoleEnum.CoordenadorNaoParceiro:
                retorno.push(RoleEnum.CoordenadorNaoParceiro);
                break;
            case RoleEnum.TecnicoEducacao:
                retorno.push(RoleEnum.TecnicoEducacao);
                break;
            case RoleEnum.Diretor:
                retorno.push(RoleEnum.Diretor);
                break;
            case RoleEnum.Estudante:
                retorno.push(RoleEnum.Estudante);
                break;
            case RoleEnum.Outro:
                retorno.push(RoleEnum.Outro);
                break;
        }
    });

    return retorno;
};

export const getUserRoleForProfile = (keycloakRoles: string[] | undefined): RoleEnum[] => {
    var retorno: RoleEnum[] = [];

    keycloakRoles?.forEach(role => {
        switch (role) {
            case RoleEnum.Professor:
                retorno.push(RoleEnum.Professor);
                break;
            case RoleEnum.GestorEscolar:
                retorno.push(RoleEnum.GestorEscolar);
                break;
            case RoleEnum.GestorRede:
                retorno.push(RoleEnum.GestorRede);
                break;
            case RoleEnum.CoordenadorRegional:
                retorno.push(RoleEnum.CoordenadorRegional);
                break;
            case RoleEnum.TecnicoEducacao:
                retorno.push(RoleEnum.TecnicoEducacao);
                break;
            case RoleEnum.AgenteTecnico:
                retorno.push(RoleEnum.AgenteTecnico);
                break;
        }
    });

    return retorno;
};

const replaces: string[][] = [
    [RoleEnum.Professor, 'Professor'],
    ['PROFESSOR', 'Professor'], //
    [RoleEnum.GestorRede, 'Gestor(a) de Rede'],
    ['GESTOR_REDE', 'Gestor(a) de Rede'],
    [RoleEnum.GestorEscolar, 'Gestor(a) Escolar'],
    ['GESTOR_ESCOLAR', 'Gestor(a) Escolar'],
    [RoleEnum.AgenteTecnico, 'Agente técnico'],
    [RoleEnum.Pesquisador, 'Pesquisador(a)'],
    [RoleEnum.CoordenadorRegional, 'Coordenador(a) Regional'],
    [RoleEnum.ColaboradorParceria, 'Colaborador(a) de Parceria'],
    [RoleEnum.CoordenadorNaoParceiro, 'Coordenador(a) pedagógico'],
    ['COORD_N_PARCEIRO', 'Coordenador(a) não Parceiro'],
    [RoleEnum.TecnicoEducacao, 'Técnico(a) de Secretaria de Educação'],
    [RoleEnum.Diretor, 'Diretor(a)'],
    [RoleEnum.Estudante, 'Estudante'],
    [RoleEnum.Outro, 'Outro'],
    [RoleEnum.Unknown, '_'],
];

export const fetchRoleDescription = (role: RoleEnum) => {
    const index: number = replaces.findIndex(rep => {
        return rep[0] === role;
    });

    if (index == -1) return '';

    return replaces[index][1];
};

export const isUserFromLMS = (keycloakRoles: string[] | undefined): boolean => {
    const role = keycloakRoles?.findIndex(role => {
        return role === RoleEnum.LMS;
    });

    return role !== -1;
};

export const isAutoCadastrado = (usuario: UsuarioDTO): boolean => {
    //usuario.claims['autoCadastro'] = true;

    if (!usuario || !usuario.claims) return true;
    if (usuario.claims['autoCadastro'] && usuario.roles.includes(RoleEnum.Professor)) return true;
    if (getUserRole(usuario.roles).length == 0) return true;

    return false;
};

export const isUsuarioLms = (usuario: UsuarioDTO): boolean => {
    return usuario.roles.includes(RoleEnum.LMS);
};

export const isCoordNaoParceiro = (): boolean => {
    return false;
};

export const getUserGroups = async (keycloak: KeycloakInstance) => {
    let groups: KcAdminGroups[] = [];

    if (keycloak.authenticated) {
        await keycloak
            .loadUserInfo()
            .then(response => {
                groups = new KcUserInfo().fromJSON(response).groups;
            })
            .catch(err => {
                console.log('erro para obter infos do usuário');
            });
    }

    console.log(groups);
    return groups;
};
