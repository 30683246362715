import styled from 'styled-components';

const BibliotecaItemPageStyled = {
    Container: styled.div``,

    ContainerPageItem: styled.div`
        width: 100%;
        max-width: 1050px;
        margin: 30px auto;
        padding: 0 20px;

        @media only screen and (max-width: 1200px) {
            margin: 0px auto;
        }
    `,
};

export default BibliotecaItemPageStyled;
