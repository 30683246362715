import { MobileMaxWidth } from 'constants/Dimensions';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import iconClose from '../../../../images/close-circle.svg';

export const ModalComponent = styled(Modal)<{ width?: number }>`
    padding-left: unset;

    .dialogWidth {
        min-width: ${props => (props.width ? props.width + 'px' : '1500px')};
        border-radius: 10px;
    }

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        .dialogWidth {
            min-width: 800px;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .dialogWidth {
            min-width: 316px;
        }
    }
`;

export interface IModal {
    show: boolean;
}

const Head = styled.div`
    padding: 15px;
    text-align: right;
    z-index: 1;
`;

const IconClose = styled.img`
    height: 25px;
    cursor: pointer;
`;

const ModalDefault = ({ children, show }: React.PropsWithChildren<IModal>) => {
    return (
        <ModalComponent show={show} centered={true} width={500} style={{ backgroundColor: 'rgba(0, 60, 120, 0.85)' }}>
            {children}
        </ModalComponent>
    );
};

export const ModalDefaultMobile = ({ children, show }: React.PropsWithChildren<IModal>) => {
    return (
        <ModalComponent show={show} centered={true} style={{ backgroundColor: 'rgba(0, 60, 120, 0.85)' }}>
            {children}
        </ModalComponent>
    );
};

export default ModalDefault;
