import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .max-toptip {
        max-width: 280px;
        text-transform: none;
        line-height: 18px;
        font-size: 1.05em;

        @media only screen and (max-width: 280px) {
            max-width: 150px;
            font-size: 12px;
            text-align: center;
        }
    }
`;

export const Text = styled.p(
    new FullCss({
        fontWeight: '700',
        fontSize: '14px',
        letterSpacing: '0.01em',
        lineHeight: '160%',
        color: Colors.Blue,
        fontFeatureSettings: "'calt' off",
    }).getCss()
);

export const OptionsContainer = styled.div(
    new FullCss({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px 26px',
        gap: '37px',
        height: '54px',
        background: '#00A8E9',
        borderRadius: '9px',
    }).getCss()
);

export const ImgIconSvg = styled.img(
    new FullCss({
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    }).getCss
);
export default MainContainer;
