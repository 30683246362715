import React from 'react';
import styled from 'styled-components';

export interface IAdminTopBar {
    title: string;
    goBack: boolean;
    pageReturn?: () => void;
}

const Container = styled.div`
    background: linear-gradient(318.36deg, #00a8e9 0%, #47aefc 100%);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    padding: 15px 20px;

    display: flex;
    flex-direction: row;
`;

const Title = styled.p`
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #ffffff;
`;

const RedirectButton = styled.button`
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    margin: 0px 10px;
    background-color: transparent;
    transition: all 0.2s;

    :hover {
        background-color: #003c78;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
`;

const AdminTopBar = ({ title, goBack, pageReturn }: IAdminTopBar) => {
    return (
        <Container>
            {goBack && (
                <RedirectButton onClick={pageReturn}>
                    <span>Voltar</span>
                </RedirectButton>
            )}

            <Title>{title}</Title>
        </Container>
    );
};

export default AdminTopBar;
