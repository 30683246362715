import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class ConteudoRelacionadoInternoResumido extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    codigo: number = 0;

    @jsonName('titulo')
    @jsonProperty(String, null)
    titulo: string = '';
}

export default ConteudoRelacionadoInternoResumido;
