import React, { FunctionComponent, useEffect, useState, useRef } from 'react';

import styled from 'styled-components';

import Colors from '../../../enums/Colors';
import SelectionItem from './SelectionItem';
import AddOtherButton from './AddOtherButton';
import { useOutsideRef } from '../../../hooks/useOutsideRef';
import { MobileMaxWidth } from '../../../constants/Dimensions';

const CustomSelectStyle = styled.div``;

interface IDropdown {
    width?: number;
    placeholder: string;
}
const Dropdown = styled.div<IDropdown>`
    position: relative;
    height: 50px;
    width: ${props => (props.width ? props.width + 'px' : '318px')};
    background-color: transparent;
    border-bottom: 2px solid;
    border-bottom-color: ${Colors.BlueGrey};
    cursor: pointer;
    text-align: center;

    ${props =>
        props.placeholder
            ? `
          &:before {
            content: '${props.placeholder}';

            color: rgba(95, 117, 139, 0.25);
            font-family: 'Barlow';
            font-weight: 600;
            font-size: 26px;
          }`
            : ''}
`;

const DropdownValue = styled.p`
    color: ${Colors.BlueGrey};
    font-size: 32px;
    line-height: 42px;
    font-weight: 600;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 20px;
    }
`;

interface IDropdownContent {
    isOpen: boolean;
    width?: number;
}

const DropdownContent = styled.div<IDropdownContent>`
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    position: absolute;
    background-color: ${Colors.White};
    width: ${props => (props.width ? props.width + 'px' : '318px')};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    border-radius: 5px;
    border-color: ${Colors.Grey5};
    max-height: 250px;
    overflow-y: scroll;
    margin-top: 9px;
`;

interface IDropdownItem extends React.HTMLAttributes<HTMLElement> {
    isSelected: boolean;
}
const DropdownItem = styled.div<IDropdownItem>`
    height: 57px;
    padding: 14px 28px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${props => (props.isSelected ? Colors.SoftBlue : Colors.BlueGrey)};
    border-bottom: 1px solid;
    border-color: ${Colors.Grey8};
    cursor: pointer;
    &:hover {
        color: ${Colors.SoftBlue};
    }
`;

const NoRecordsText = styled.div`
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    color: ${Colors.BlueGrey};
`;

interface ICustomSelect {
    width?: number;
    placeholder?: string;
    isCreatable?: boolean;
    options: SelectionItem[];
    dropdownContentHeight?: number;
    value: SelectionItem | undefined;
    OnChangeHandler: (item: SelectionItem) => void;
}
const CustomSelect: FunctionComponent<ICustomSelect> = (props: ICustomSelect) => {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SelectionItem | undefined>(props.value);
    const [selectionOptions, setSelectionOptions] = useState<SelectionItem[]>([]);

    const dropdownRef = useRef(null);
    useOutsideRef(dropdownRef, () => {
        setOpen(false);
    });

    useEffect(() => {}, [selectedItem, open, props.value]);

    useEffect(() => {
        if (props.options) {
            setSelectionOptions(props.options);
        }
    }, [props.options]);

    const OnSelectionHandler = (item: SelectionItem) => {
        setSelectedItem(item);
        setOpen(false);
        props.OnChangeHandler(item);
    };

    const OnAddOtherHandler = (value: string) => {
        const newItem: SelectionItem = new SelectionItem().fromJSON({
            id: 99,
            nome: value,
        });

        const newArray: SelectionItem[] = selectionOptions.filter(obj => {
            return obj.id !== 99;
        });
        newArray.push(newItem);

        setSelectedItem(newItem);
        setSelectionOptions(newArray);
        setOpen(false);
        props.OnChangeHandler(newItem);
    };

    const getPlaceHolder = (): string => {
        if (selectedItem) return '';
        return 'Selecione';
    };

    return (
        <CustomSelectStyle ref={dropdownRef}>
            <Dropdown
                placeholder={getPlaceHolder()}
                width={props.width}
                onClick={() => {
                    setOpen(last => !last);
                }}
            >
                <DropdownValue>{selectedItem?.nome}</DropdownValue>
            </Dropdown>
            {selectionOptions.length > 0 && (
                <DropdownContent isOpen={open} width={props.width}>
                    {props.isCreatable && <AddOtherButton OnAddOtherHandler={OnAddOtherHandler} />}
                    {selectionOptions.map((item: SelectionItem) => (
                        <DropdownItem
                            key={item.id}
                            isSelected={item.id === selectedItem?.id}
                            onClick={() => {
                                OnSelectionHandler(item);
                            }}
                        >
                            {item.nome}
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}

            {selectionOptions.length === 0 && (
                <DropdownContent isOpen={open}>
                    <NoRecordsText>Não encontrado</NoRecordsText>
                </DropdownContent>
            )}
        </CustomSelectStyle>
    );
};

export default CustomSelect;
