import React, { FunctionComponent } from 'react';

import Colors from '../../../enums/Colors';
import Title from '../../../components/UI/Title';
import AcaoMeta from '../../../models/pdp/AcaoMeta';
import { media760 } from '../../../core/util/cssHelp';
import TipoMeta from '../../../models/pdp/TipoMetaEnum';
import RegistroMacrocompetencia from '../../../models/pdp/RegistroMacrocompetencia';

import styled from 'styled-components';

//Styled objects do componente
const VisualizacaoRegistroMacroContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

const TitleContainer = styled.div({
    marginBottom: '41px',
    marginTop: '50px',
});

const AcaoMetaContainer = styled.div({});

const AcaoMetaTitle = styled.p({
    color: Colors.DarkBlue,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '22px',
    marginBottom: '40px',
});

const AcaoMetaDescricao = styled.p({
    color: Colors.DarkGrey,
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
});

const MetaPrazo = styled.p({
    color: Colors.LightGrey,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    position: 'absolute',
    bottom: '17px',
});

const AcaoMetaCardStyle = styled.div(
    media760(
        {
            width: '366px',
            height: '170px',
            borderRadius: '5px',
            backgroundColor: Colors.White,
            boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1);',
            position: 'relative',
            marginRight: '24px',
            marginBottom: '18px',
        },

        {}
    )
);

const AcaoMetaContentContainer = styled.div({
    padding: '16px 22px',
    wordBreak: 'break-word',
});

const CardFooter = styled.div({
    height: '5px',
    width: '100%',
    backgroundColor: '#FFCA00',
    borderRadius: '0px 0px 5px 5px;',
    position: 'absolute',
    bottom: 0,
});

const AcaoMetaCardsContainer = styled.div(
    media760(
        {
            marginTop: '14px',
            display: 'flex',
            marginBottom: '42px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            gap: '50px',
        },

        {
            flexDirection: 'column',
        }
    )
);

const Description = styled.p`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;

    @media only screen and (max-width: 760px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

//Componente
interface IVisualizacaoRegistroMacro {
    registro: RegistroMacrocompetencia;
    currentStep: number;
    number: number;
}
const VisualizacaoRegistroMacro: FunctionComponent<IVisualizacaoRegistroMacro> = ({ ...props }: IVisualizacaoRegistroMacro) => {
    //Subcomponente para renredização dos cards
    interface IAcaoMetaCard {
        acaoMeta: AcaoMeta;
    }
    const AcaoMetaCard = ({ acaoMeta }: IAcaoMetaCard) => {
        return (
            <AcaoMetaCardStyle>
                <AcaoMetaContentContainer>
                    <AcaoMetaDescricao>{acaoMeta.descricao}</AcaoMetaDescricao>
                    {acaoMeta.meta.tipo == TipoMeta.CurtoPrazo && <MetaPrazo>Curto Prazo: {acaoMeta.meta.descricao}</MetaPrazo>}
                    {acaoMeta.meta.tipo == TipoMeta.MedioPrazo && <MetaPrazo>Médio Prazo: {acaoMeta.meta.descricao}</MetaPrazo>}
                    {acaoMeta.meta.tipo == TipoMeta.LongoPrazo && <MetaPrazo>Longo Prazo: {acaoMeta.meta.descricao}</MetaPrazo>}
                    {acaoMeta.meta.tipo == TipoMeta.Prazo && <MetaPrazo>Prazo: {acaoMeta.meta.descricao}</MetaPrazo>}
                </AcaoMetaContentContainer>
                <CardFooter />
            </AcaoMetaCardStyle>
        );
    };

    //Subcomponente para renredização das AcoesMetas
    interface IAcaoMetaFC {
        title: string;
        listaAcaoMeta: AcaoMeta[];
    }
    const AcaoMetaFC = ({ title, listaAcaoMeta }: IAcaoMetaFC) => {
        return (
            <AcaoMetaContainer>
                <AcaoMetaTitle>{title}</AcaoMetaTitle>
                <AcaoMetaCardsContainer>
                    {listaAcaoMeta
                        .sort((a, b) => (b.meta.codigo! < a.meta.codigo! ? 1 : -1))
                        .map((item: AcaoMeta, idx: number) => {
                            return <AcaoMetaCard acaoMeta={item} key={`pdp_visualizacao_registro_macro_acao_card_${idx}`} />;
                        })}
                </AcaoMetaCardsContainer>
            </AcaoMetaContainer>
        );
    };

    return (
        <VisualizacaoRegistroMacroContainer>
            <TitleContainer>
                <Description>
                    {`Macrocompetência ${props.number + 1}:`}{' '}
                    <Title style={{ fontSize: '24px' }}>{props.registro.macrocompetencia.descricao}</Title>{' '}
                </Description>
            </TitleContainer>
            {props.registro.listaAcoesMetasPrazo.length > 0 && (
                <AcaoMetaFC title={`Ações e Metas`} listaAcaoMeta={props.registro.listaAcoesMetasPrazo} />
            )}
            {props.registro.listaAcoesMetasCurtoPrazo.length > 0 && props.currentStep != 5 && (
                <AcaoMetaFC title={`Curto Prazo`} listaAcaoMeta={props.registro.listaAcoesMetasCurtoPrazo} />
            )}
            {props.registro.listaAcoesMetasMedioPrazo.length > 0 && props.currentStep != 5 && (
                <AcaoMetaFC title={`Médio Prazo`} listaAcaoMeta={props.registro.listaAcoesMetasMedioPrazo} />
            )}
            {props.registro.listaAcoesMetasLongoPrazo.length > 0 && props.currentStep != 5 && (
                <AcaoMetaFC title={`Longo Prazo`} listaAcaoMeta={props.registro.listaAcoesMetasLongoPrazo} />
            )}
        </VisualizacaoRegistroMacroContainer>
    );
};

export default VisualizacaoRegistroMacro;
