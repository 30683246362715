import React, { useState } from 'react';
import Styled from './AdminModal.styled';

import { FormControl, Modal } from 'react-bootstrap';
import Row from 'components/Row';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export interface IAdminModalExclude {
    show: boolean;
    onHide: () => void;
    perfil: PerfilUsuario;
    submitting: boolean;
    onSubmit: (perfil: PerfilUsuario) => void;
}

const AdminModalExclude = ({ show, onHide, perfil, submitting, onSubmit }: IAdminModalExclude) => {
    const [cpf, setCpf] = useState('');

    const isSameCpf = () => {
        return cpf.trim().length > 0 && perfil.cpf.trim().replace(/(\.|-)/g, '') == cpf.trim().replace(/(\.|-)/g, '');
    };

    const hide = () => {
        setCpf('');
        onHide();
    };

    return (
        <Modal show={show} onHide={hide}>
            <Styled.Container>
                <Row justify="space-between">
                    <Styled.Title>Excluir usuário</Styled.Title>
                    <Styled.CloseIconContainer>
                        <AiOutlineCloseCircle onClick={() => onHide()} />
                    </Styled.CloseIconContainer>
                </Row>
                <br />
                <Styled.Text>Nome do usuário: {perfil.apelido}</Styled.Text>

                <br />
                <Styled.TextStrong>Digite o CPF do usuário para confirmar a exclusão:</Styled.TextStrong>
                <FormControl value={cpf} onChange={e => setCpf(e.target.value)} />
                <br />
                <Styled.TextStrongRed>Atenção! Essa ação é irreversível.</Styled.TextStrongRed>

                <br />
                <br />
                <Row justify="space-between">
                    <Styled.CancelButton onClick={hide}>Cancelar</Styled.CancelButton>
                    <Styled.PrimaryButton
                        onClick={() => {
                            onSubmit(perfil);
                            setCpf('');
                        }}
                        disabled={!isSameCpf() || submitting}
                    >
                        Excluir usuário
                    </Styled.PrimaryButton>
                </Row>
            </Styled.Container>
        </Modal>
    );
};

export default AdminModalExclude;
