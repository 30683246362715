import Colors from 'enums/Colors';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}

const StyleTematicasHome = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${Colors.White};
        padding: 65.5px 79px;
        gap: 16px;
        min-height: 768px;

        @media screen and (max-width: 700px) {
            height: 960px;
        }
        @media screen and (max-width: 600px) {
            padding: 24px 30px;
        }
    `,

    Header: styled.div`
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 700px) {
            flex-direction: column;
            height: 260px;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 32px;
        font-weight: 700;
        line-height: 44.8px;
        text-align: right;
        color: ${Colors.Blue};
    `,

    SubTitle: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        max-width: 456px;
        margin-bottom: 32px;

        @media screen and (max-width: 700px) {
            text-align: center;
        }
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean }>`
        width: 1440px;
        padding: 10px;


        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-dots {
            li {
                button::before {
                    color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        @media screen and (max-width: 1440px) {
            width: 1200px;
        }
        @media screen and (max-width: 1100px) {
            width: 780px;
        }
        @media screen and (max-width: 900px) {
            width: 500px;
        }
        @media screen and (max-width: 800px) {
             width: 100%;
             padding: 0px;
       
        }

        @media screen and (max-width: 680px) {
            width: 100%;
        }
    `,

    Carrousel: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 450px;
        width: 100%;
        margin-top: 0;

        @media screen and (max-width: 600px) {
            margin-top: 80px;
        }
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 59px;
        width: 56px;
        fill: ${Colors.Yellow};

        &:hover {
            fill: ${Colors.Yellow};
        }

        @media screen and (max-width: 600px){
            height: 29px;
            width: 25px;
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '100%' : '-3%')};

        @media screen and (max-width: 1920px) {
            left: ${props => (props.next ? '100%' : '-4%')};
        }
        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '96%' : '0%')};
        }
        @media screen and (max-width: 1150px) {
            left: ${props => (props.next ? '93%' : '2%')};
        }
        @media screen and (max-width: 1070px) {
            left: ${props => (props.next ? '100%' : '-7%')};
        }

        @media screen and (max-width: 600px) {
            left: ${props => (props.next ? '100%' : '-5%')};
            width: 26px;
            height: 29px;
        }
    `,

    CardTematica: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px;
        background-color: ${Colors.White};
        width: 416px;
        min-height: 500px;
        border: 1px solid #f1f1f1;
        border-radius: 8px;
        gap: 16px;

        @media screen and (max-width: 1440px) {
            width: 380px;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            height: 620px;
        }
    `,

    ImgCard: styled.img`
        width: 100%;
        height: 174px;
        object-fit: cover;
        border-radius: 6px 6px 0px 0px;
    `,

    TitleCard: styled.h1`
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
/* 
        @media screen and (max-width: 1445px) {
           height: 50px;
        } */

    `,

    DescriptionCard: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    `,
};

export default StyleTematicasHome;
