import _ from 'lodash';

import FAQ from '../../models/FAQ';
import Statement from '../../models/Statement';
import EducationalSolution from '../../models/EducationalSolution';

import { GET_EDUCATIONAL_SOLUTIONS, GET_FAQ, GET_STATEMENTS } from '../actions/HomePage';

interface IState {
    statements: Statement[];
    frequentlyAskedQuestions: FAQ[];
    educationalSolutions: EducationalSolution[];
}

const initialState: IState = {
    statements: [],
    educationalSolutions: [],
    frequentlyAskedQuestions: [],
};

const homePageReducer = (state = initialState, action: any): IState => {
    switch (action.type) {
        case GET_EDUCATIONAL_SOLUTIONS: {
            return {
                ..._.cloneDeep(state),
                educationalSolutions: action.educationalSolutions,
            };
        }
        case GET_FAQ: {
            return {
                ..._.cloneDeep(state),
                frequentlyAskedQuestions: action.frequentlyAskedQuestions,
            };
        }
        case GET_STATEMENTS: {
            return {
                ..._.cloneDeep(state),
                statements: action.statements,
            };
        }
        default: {
            return state;
        }
    }
};

export default homePageReducer;
