import "core-js/proposals/reflect-metadata";
import { jsonProperty, Serializable } from "ts-serializable";

class FAQ extends Serializable {
  @jsonProperty(Number, null)
  id: number | null = null;

  @jsonProperty(String)
  question: string = "";

  @jsonProperty(String)
  answer: string = "";

  constructor() {
    super();
  }
}

export default FAQ;
