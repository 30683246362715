import React, { forwardRef, useEffect, useState } from 'react';
import Styled from './PaginaIntermediaria.styled';
import 'core/http/service/PaginaIntermediaria.service';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import Loading from 'components/Loading';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import AdminEditorStyled from '../pageEditors/AdminEditor.styled';
import { isStringsValid } from '../newContent/isValidNewContentInput';
import { GradientButton } from 'components/UI/Buttons';
import { ToastError } from 'components/Toaster';

export interface IPaginaIntermediaria {
    paginaKey: string;
    onSaved: () => void;
}

const PaginaIntermediaria = ({ onSaved, paginaKey }: IPaginaIntermediaria) => {
    const [pagina, setPagina] = useState<AdminPaginaIntermediaria>(new AdminPaginaIntermediaria());
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [titulo, setTitulo] = useState<string>('');
    const [descricao, setDescricao] = useState<string>('');

    const MAX_CHAR_TITLE = 300;
    const MAX_CHAR_CONTENT = 1000;

    const service = new PaginaIntermediariaService();

    const loadData = async () => {
        try {
            setLoading(true);
            const response: any = await service.listar();

            if (!response.data) return;

            const output: AdminPaginaIntermediaria[] = response.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

            if (output) {
                let paginaOutput: AdminPaginaIntermediaria = output.find(item => item.pagina === paginaKey) ?? new AdminPaginaIntermediaria();
                setPagina(paginaOutput);
                setTitulo(paginaOutput?.titulo);
                setDescricao(paginaOutput?.descricao);
            }
        } catch (error) {
            console.log('rrr', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const isValidInputs = (): boolean => {
        return isStringsValid([titulo, descricao]);
    };

    const submit = async () => {
        setSubmitting(true);

        if (pagina) {
            pagina.titulo = titulo ?? pagina.titulo;
            pagina.descricao = descricao ?? pagina.descricao;
            pagina.pagina = paginaKey;
        }

        let response;

        if (!pagina?.codigo || pagina?.codigo === 0) {
            response = await service.inserir(pagina);
        } else {
            response = await service.editar(pagina.codigo, pagina);
        }

        if (response.status === 200 || response.status === 201) {
            const objResposta: AdminPaginaIntermediaria = new AdminPaginaIntermediaria().fromJSON(response.data);
            setPagina(objResposta);
            onSaved();
        } else {
            ToastError('Houve um erro ao tentar salvar o curso. Por favor, tente novamente!');
        }

        setSubmitting(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Styled.MainContainer>
            <Styled.Title>Editar Página Intermediária</Styled.Title>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <AdminEditorStyled.Container>
                        <div>
                            <AdminEditorStyled.Label>Título da Página Intermediária</AdminEditorStyled.Label>
                            <AdminEditorStyled.Input
                                value={titulo}
                                onChange={(evt: any) => setTitulo(evt.target.value)}
                                maxLength={MAX_CHAR_TITLE}
                            />
                            <AdminEditorStyled.CountLetter danger={titulo.length >= MAX_CHAR_TITLE}>
                                {titulo.length}/{MAX_CHAR_TITLE}
                            </AdminEditorStyled.CountLetter>
                            <br />
                            <AdminEditorStyled.Label>Descrição da Página Intermediária</AdminEditorStyled.Label>
                            <AdminEditorStyled.Input
                                value={descricao}
                                onChange={(evn: any) => setDescricao(evn.target.value)}
                                rows={8}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />
                            <AdminEditorStyled.CountLetter danger={descricao.length >= MAX_CHAR_CONTENT}>
                                {descricao.length}/{MAX_CHAR_CONTENT}
                            </AdminEditorStyled.CountLetter>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                                <GradientButton
                                    style={{ marginBottom: '15px' }}
                                    onClick={async () => await submit()}
                                    disabled={!isValidInputs() || submitting}
                                >
                                    {submitting ? 'Salvando...' : 'Salvar'}
                                </GradientButton>
                            </div>
                        </div>
                    </AdminEditorStyled.Container>
                </>
            )}
        </Styled.MainContainer>
    );
};

export default PaginaIntermediaria;
