import { SelectOptionType } from 'components/Select';
import TipoAnexoCarrossel from 'enums/TipoAnexoCarrosselEnum';

export const tipoAnexoOptions: SelectOptionType[] = [
    {
        label: 'Imagem',
        value: TipoAnexoCarrossel.IMAGEM,
    },
    {
        label: 'Video',
        value: TipoAnexoCarrossel.VIDEO,
    },
];

export function getOptionFromValue(value: TipoAnexoCarrossel): SelectOptionType {
    if (value == TipoAnexoCarrossel.IMAGEM) return tipoAnexoOptions[0];
    else return tipoAnexoOptions[1];
}
