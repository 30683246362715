import BannerModel from 'models/generic-components/SecaoBanner';
import CarouselModel from 'models/generic-components/SecaoCarrossel';
import CarouselCategoryModel, { SecaoCardCategoria } from 'models/generic-components/SecaoCardCategoria';
import SimplesWithImageModel from 'models/generic-components/SecaoSimplesComOuSemImagem';
import { CanvasContainer } from 'pages/profile/components/identificacaoImagem/subpages/CameraCaptura.styled';
import React from 'react';
import Banner from '../banner/Banner';
import CarouselGeneric from '../carousel/CarouselGeneric';
import CarouselCategory from '../carouselCategory/CarouselCategory';
import SimpleWithImage from '../simpleWithImage/SimpleWithImage';
import Styled from './Exemples.styled';
import SecaoImagem from '../secaoImagem/SecaoImagem';
import SecaoImagemModel from '../../../models/generic-components/SecaoImagem';
import Colors from 'enums/Colors';
import SecaoInteresseModel from 'models/generic-components/SecaoInteresse';
import SecaoInteresse from '../secaoInteresse/SecaoInteresse';

export interface IExemples {}

export interface ISessaoBanner {
    codigo: number;
    titulo: string;
    descricao: string;
    link: string;
    textoBotaoA: string;
    textoBotaoB: string;
    paginaLateral: boolean;
    usuarioResponsavel: string;
    anexoBase64: string;
    filePath: string;
}

export interface ISessaoSimplesImagem {
    codigo: number;
    titulo: string;
    descricao: string;
    link: string;
    textoBotaoA: string;
    orientacao?: string;
    usuarioResponsavel: string;
    anexoBase64: string;
    filePath: string;
}

export interface ISessaoCarousel {
    codigo: number;
    codigoPaginaIntermediaria: number;
    tipoModeloSecao: {
        codigo: number;
        titulo: string;
        descricao: string;
    };
    secaoCarrossel: {
        codigo: number;
        carrosseis: ICardCarousel[];
    };
}

export interface ICardCarousel {
    codigo: number;
    titulo: string;
    conteudo: string;
    ocupacao: string;
    anexoBase64: string;
    filePath: string;
}

export interface ICardSessao {
    codigo: number;
    categorias: ICardSessaoCategory;
    titulo: string;
    conteudo: string;
    linkExterno: boolean;
    linkInterno: string;
    ativo: boolean;
    desabilitado: boolean;
    anexoBase64: string;
    filePath: string;
    order: number;
}

interface ICardSessaoCategory {
    codigo: number;
    descricao: string;
    hoverMenu: string;
}

const Exemples = ({}: IExemples) => {
    const sessaoBanner = new BannerModel().fromJSON({
        codigo: 1,
        titulo: 'A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INSTITUTO AYRTON SENNA',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        link: 'www.banner.com',
        textoBotaoA: 'Acessar a plataforma',
        textoBotaoB: 'cde',
        paginaLateral: false,
        usuarioResponsavel: 'fulano',
        anexoBase64: 'preenchido apenas quando não publico (para admin)',
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const sessaoBanner2 = new BannerModel().fromJSON({
        codigo: 1,
        titulo: 'A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INSTITUTO AYRTON SENNA',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        link: 'www.banner.com',
        textoBotaoA: 'Acessar a plataforma',
        textoBotaoB: 'cde',
        paginaLateral: true,
        usuarioResponsavel: 'fulano',
        anexoBase64: 'preenchido apenas quando não publico (para admin)',
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const sessaoSimpleImage = new SimplesWithImageModel().fromJSON({
        codigo: 1,
        titulo: 'A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INSTITUTO AYRTON SENNA',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        link: 'www.banner.com',
        textoBotaoA: 'Acessar a plataforma',
        orientacao: 'E',
        usuarioResponsavel: 'fulano',
        anexoBase64: 'preenchido apenas quando não publico (para admin)',
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const sessaoSimpleImage2 = new SimplesWithImageModel().fromJSON({
        codigo: 1,
        titulo: 'A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INSTITUTO AYRTON SENNA',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        link: 'www.banner.com',
        textoBotaoA: 'Acessar a plataforma',
        orientacao: 'D',
        usuarioResponsavel: 'fulano',
        anexoBase64: 'preenchido apenas quando não publico (para admin)',
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const sessaoSimpleImage3 = new SimplesWithImageModel().fromJSON({
        codigo: 1,
        titulo: 'A EXPERIÊNCIA DIGITAL DE FORMAÇÃO DO INSTITUTO AYRTON SENNA',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        link: 'www.banner.com',
        textoBotaoA: 'Acessar a plataforma',
        usuarioResponsavel: 'fulano',
        anexoBase64: 'preenchido apenas quando não publico (para admin)',
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const carouselCards = new CarouselModel().fromJSON({
        codigo: 1,
        titulo: 'DEPOIMENTOS DE EDUCADORES',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        carrosseis: [
            {
                codigo: 1,
                titulo: 'Maria Borges',
                conteudo:
                    'Os conteúdos são muito pragmáticos. Amei participar dos cursos, é o segundo que eu participo e estou amando. É como se, durante essas duas horas, você tirasse um tempinho para olhar pra si, para refletir sobre a sua vida.',
                ocupacao: 'Professora',
                anexoBase64: 'preenchido apenas quando não publico (para admin)',
                filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
            },
            {
                codigo: 2,
                titulo: 'Maria Borges',
                conteudo:
                    'Os conteúdos são muito pragmáticos. Amei participar dos cursos, é o segundo que eu participo e estou amando. É como se, durante essas duas horas, você tirasse um tempinho para olhar pra si, para refletir sobre a sua vida.',
                ocupacao: 'Professora',
                anexoBase64: 'preenchido apenas quando não publico (para admin)',
                filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
            },
            {
                codigo: 3,
                titulo: 'Maria Borges',
                conteudo:
                    'Os conteúdos são muito pragmáticos. Amei participar dos cursos, é o segundo que eu participo e estou amando. É como se, durante essas duas horas, você tirasse um tempinho para olhar pra si, para refletir sobre a sua vida.',
                ocupacao: 'Professora',
                anexoBase64: 'preenchido apenas quando não publico (para admin)',
                filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
            },
            {
                codigo: 4,
                titulo: 'Maria Borges',
                conteudo:
                    'Os conteúdos são muito pragmáticos. Amei participar dos cursos, é o segundo que eu participo e estou amando. É como se, durante essas duas horas, você tirasse um tempinho para olhar pra si, para refletir sobre a sua vida.',
                ocupacao: 'Professora',
                anexoBase64: 'preenchido apenas quando não publico (para admin)',
                filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
            },
        ],
    });

    const cardsCategory = new SecaoCardCategoria().fromJSON({
        codigo: 1,
        titulo: 'Cartões com categorias',
        descricao:
            'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        cards: [
            {
                codigo: 1,
                categoria: {
                    codigo: 1,
                    descricao: 'Para me desenvolver',
                    hoverMenu: 'Jornadas para desenvolvimento socioemocional e caminhos para lidar com questões do cotidiano escolar.',
                },
                titulo: 'Socioemocional de Professores',
                conteudo:
                    'Se você é professor e quer fortalecer suas competências socioemocionais, mergulhe nessa jornada usando nossas ferramentas de autoavaliação e diagnóstico dessas habilidades, Plano de Desenvolvimento Pessoal, diário de bordo e curso de Competências Socioemocionais de Educadores.',
                linkExterno: false,
                linkInterno: 'c2',
                ativo: true,
                desabilitado: false,
                anexoBase64: 'preenchido apenas quando não publico (para admin)',
                filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
                ordem: 1,
            },
        ],
        usuarioResponsavel: 'fulano',
    });

    const secaoImage = new SecaoImagemModel().fromJSON({
        codigo: 1,
        titulo: 'UMA REDE PELO DESENVOLVIMENTO COLETIVO',
        tamanhoTitulo: '30',
        corTitulo: Colors.Blue,
        ocultarTitulo: false,
        texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit animi ab aliquam dicta repudiandae expedita vel consequatur, odit earum quam sapiente debitis eveniet aspernatur nulla, perspiciatis quia. Voluptate, vero aliquid.',
        tamanhoTexto: '22',
        ocultarTexto: false,
        estiloBotao: 'BUTTON_01',
        ocultarBotao: false,
        textoBotao: 'Acessar',
        linkBotao: 'http://google.com',
        orientacao: 'B',
        corSecao: Colors.DarkGreyBlue,
        filePath: 'Frame.jpg_2023-01-09-1673282321490.jpg',
    });

    const SecaoInteresseComponent = new SecaoInteresseModel().fromJSON({
        codigo: 1,
        titulo: 'UMA REDE PELO DESENVOLVIMENTO COLETIVO',
        tamanhoTitulo: '30',
        corTitulo: Colors.Yellow,
        ocultarTitulo: false,
        descricao:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit animi ab aliquam dicta repudiandae expedita vel consequatur, odit earum quam sapiente debitis eveniet aspernatur nulla, perspiciatis quia. Voluptate, vero aliquid.',
        tamanhoDescricao: '16',
        ocultarDescricao: false,
        corDescricao: Colors.Yellow,
        textoApoio:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit animi ab aliquam dicta repudiandae expedita vel consequatur, odit earum quam sapiente debitis eveniet aspernatur nulla, perspiciatis quia. Voluptate, vero aliquid.',
        tamanhoTextoApoio: '12',
        corTextoApoio: Colors.Blue,
        ocultarTextoApoio: false,
        estiloBotao: 'BUTTON_01',
        corSecao: Colors.DarkGreyBlue,
    });

    return (
        <Styled.Container>
            <Styled.Title>Banner</Styled.Title>
            <Banner dados={sessaoBanner} />
            <Styled.Division />
            <Banner dados={sessaoBanner2} />

            <Styled.Title>Simple With Image</Styled.Title>
            <SimpleWithImage dados={sessaoSimpleImage} />
            <Styled.Division />
            <SimpleWithImage dados={sessaoSimpleImage2} />
            <Styled.Division />
            <SimpleWithImage dados={sessaoSimpleImage3} />
            <Styled.Division />

            <Styled.Title>Carousel</Styled.Title>
            <CarouselGeneric dados={carouselCards} length={carouselCards.carrosseis.length ?? 0} />
            <Styled.Title>Carousel Category</Styled.Title>
            <CarouselCategory dados={cardsCategory} />
            <Styled.Title>Seção Imagem</Styled.Title>
            <SecaoImagem dados={secaoImage} />
            <Styled.Title>Seção Interesse</Styled.Title>
            <SecaoInteresse dados={SecaoInteresseComponent} />
        </Styled.Container>
    );
};

export default Exemples;
