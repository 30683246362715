import { Serializable, jsonProperty } from 'ts-serializable';

class TipoDeficienciaDTO extends Serializable {
    @jsonProperty()
    codigoTipoDeficiencia: number = -1;

    @jsonProperty()
    descricao: string = '';

    constructor() {
        super();
    }
}

export default TipoDeficienciaDTO;
