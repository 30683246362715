import React, { useEffect, useState } from 'react';
import { bucketS3, caminhoAnexosGestaoPaginaSimplesImagem } from 'constants/EnvironmentData';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import SimplesWithImageModel from 'models/generic-components/SecaoSimplesComOuSemImagem';
import {
    Button,
    ButtonContainer,
    DescriptionContainer,
    TitleText,
    MainContainer,
    Text,
    TitleContainer,
} from 'pages/home/components/FAQ/SuporteContainer.styled';

import Styled from './SimpleWithImage.styled';
import GenericComponentsUtils from '../generic-components.utils';
import { useHistory } from 'react-router';
import useLogged from 'hooks/useLogged';
import { GTM_Gestao_Paginas } from 'components/GoogleTagManager';

interface ISimpleWithImage {
    dados: SimplesWithImageModel;
}

const SimpleWithImage = ({ dados }: ISimpleWithImage) => {
    const history = useHistory();
    const { authenticated } = useLogged();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaSimplesImagem();
    };

    const goToLink = (location: string) => {
        GenericComponentsUtils.gotoLink(location, history, authenticated, dados);
    };

    const getPathname = (): string => {
        return location.pathname.split('/pagina')[1];
    };

    const buttonMobileText: string = IsMobileMaxWidth() ? 'Acessar' : 'Acessar cursos livres';

    return (
        <>
            {dados && (
                <>
                    {dados.orientacao ? (
                        <Styled.Container side={dados.orientacao}>
                            <Styled.PaddingImage src={getS3BaseUrl() + dados.filePath} />
                            <Styled.ContentContainer>
                                <Styled.Title>{dados.titulo}</Styled.Title>
                                <Styled.TextBox>
                                    <Styled.TextDangerous dangerouslySetInnerHTML={{ __html: dados.descricao }} />
                                </Styled.TextBox>

                                <Styled.ButtonContainer>
                                    <Styled.Button
                                        onClick={() => {
                                            GTM_Gestao_Paginas(getPathname(), `simple_with_image_${dados.textoBotaoA}`, `${dados.link}`);
                                            goToLink(dados.link ?? '');
                                        }}
                                    >
                                        {dados.textoBotaoA ?? buttonMobileText}
                                    </Styled.Button>
                                </Styled.ButtonContainer>
                            </Styled.ContentContainer>
                        </Styled.Container>
                    ) : (
                        <MainContainer style={{ backgroundColor: dados.corFundo }}>
                            <TitleContainer>
                                <TitleText style={{ color: dados.corTitulo }}>{dados.titulo}</TitleText>
                            </TitleContainer>
                            <DescriptionContainer>
                                <Styled.TextDangerous dangerouslySetInnerHTML={{ __html: dados.descricao }} />
                            </DescriptionContainer>
                            <ButtonContainer>
                                <Button
                                    onClick={() => {
                                        GTM_Gestao_Paginas(getPathname(), `simple_without_image_${dados.textoBotaoA}`, `${dados.link}`);
                                        goToLink(dados.link ?? '');
                                    }}
                                >
                                    {' '}
                                    {dados.textoBotaoA ?? buttonMobileText}
                                </Button>
                            </ButtonContainer>
                        </MainContainer>
                    )}
                </>
            )}
        </>
    );
};

export default SimpleWithImage;
