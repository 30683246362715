import React from 'react';
import styled from 'styled-components';

export interface IBadge {
    color: string;
}

const Span = styled.span`
    color: white;
    border-radius: 15px;
    padding: 4px 10px;
`;

const Badge = ({ color, children }: React.PropsWithChildren<IBadge>) => {
    return <Span style={{ backgroundColor: color }}>{children}</Span>;
};

export default Badge;
