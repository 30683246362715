import Row from 'components/Row';
import React from 'react';
import { useHistory } from 'react-router';

import logoFab from '../../../../../../images/logo/logo-fablabrec.svg';
import logo from '../../../../../../images/logo/logo-white.svg';
import { gotoSection } from './Inicio';
import Styled from './Lancamento.styled';

export interface ILancamento {}

const Lancamento = ({}: ILancamento) => {
    const history = useHistory();

    const gotoTrilhaMaker = () => {
        history.push('/recursos/trilhas/trilha-maker-criatividade');
    };

    const goToTrilhaMakerPensamentoCritico = () => {
        history.push('/recursos/trilhas/trilha-maker-pensamento-critico');
    };

    const gotoFabLab = () => {
        window.open('https://www.youtube.com/@FabLabRec', '_blank');
    };

    return (
        <Styled.Container id="lancamento_section">
            <Styled.RowGrid>
                <Styled.Col style={{ maxWidth: '800px' }}>
                    <Styled.UnderTitle>LANÇAMENTO </Styled.UnderTitle>
                    <Styled.Title>Trilha Maker</Styled.Title>
                    <Styled.Text>
                        Conheça a <b>Trilha Maker Pensamento Crítico</b> e a <b>Trilha Maker Criatividade</b>, frutos da parceria entre <br />
                        Instituto Ayrton Senna e Fab Lab Rec.
                        <br />
                        <br />
                        Nas trilhas você vai encontrar vídeos, infográficos e materiais sobre a cultura maker e suas relações com desenvolvimento
                        do pensamento crítico e da criatividade. Ao final, conheça propostas de atividades práticas que servem de inspiração para
                        trabalhar com os estudantes na escola.
                    </Styled.Text>

                    <br />

                    <Styled.ButtonsContainer>
                        <Styled.AccessMakerButton onClick={goToTrilhaMakerPensamentoCritico}>
                            Acessar trilha Pensamento Crítico
                        </Styled.AccessMakerButton>
                        <Styled.AccessMakerButton onClick={gotoTrilhaMaker}>Acessar trilha Criatividade</Styled.AccessMakerButton>
                        <Styled.MoreContentButton onClick={() => gotoSection('curso_section')}>Mais conteúdos</Styled.MoreContentButton>
                    </Styled.ButtonsContainer>
                </Styled.Col>

                <Styled.VDivisionContainer>
                    <Styled.VDivision />
                </Styled.VDivisionContainer>

                <Styled.ColLeft>
                    <Styled.UnderTitle2>Uma co-produção:</Styled.UnderTitle2>
                    <Styled.LogosContainer>
                        <Styled.LogoImage onClick={gotoFabLab} style={{ cursor: 'pointer' }} src={logoFab} />
                        <Styled.LogoImage src={logo} />
                    </Styled.LogosContainer>
                </Styled.ColLeft>
            </Styled.RowGrid>
        </Styled.Container>
    );
};

export default Lancamento;
