import { SelectOptionType } from 'components/Select';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

export const tipoRedirecionamentoOptions: SelectOptionType[] = [
    {
        label: 'Ignorar',
        value: TipoRedirecionamento.IGNORAR,
    },
    {
        label: 'Login',
        value: TipoRedirecionamento.LOGIN,
    },
    {
        label: 'Cadastro',
        value: TipoRedirecionamento.CADASTRO,
    },
];

export function getOptionFromValue(value: TipoRedirecionamento): SelectOptionType {
    if (value == TipoRedirecionamento.IGNORAR) return tipoRedirecionamentoOptions[0];
    if (value == TipoRedirecionamento.LOGIN) return tipoRedirecionamentoOptions[1];
    else return tipoRedirecionamentoOptions[2];
}
