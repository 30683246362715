import React, { useEffect, useContext } from 'react';

import Title from 'components/UI/Title';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Meta from 'models/pdp/Meta';
import MetaComponent from './Meta';
import { fetchMetas } from 'store/actions/Pdp';
import { capitalizeFirstLetter } from './utils';
import { SelectOptionType } from 'components/Select';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import ToolTip from '../../../../components/UI/tooltip/Tooltip';
import { ButtonAdd, Grid, GridMobile, InfoIcon, MetaContainer, MetasContainer, TitleMini } from './Metas.styled';

import infoIcon from 'images/pdp/infoIcon.svg';
import MetasDTO from 'models/pdp/MetasDTO';

import { PdpContext } from 'pages/pdp/context/PdpContext';

export type PrazoMeta = 'curto' | 'medio' | 'longo' | 'prazo';

export type MetaComponentType = {
    id: number;
    acao: string;
    prazo: string;
    index: number;
};

interface IMetas {
    prazo: PrazoMeta;
    metas: MetaComponentType[];
    setMetas: (metas: MetaComponentType[]) => void;
    selectDefault?: SelectOptionType;
}

const MetasComponent = ({ prazo, metas, setMetas }: IMetas) => {
    const metasReducer: MetasDTO = useAppSelector(state => state.pdpReducer.metas);
    const { isMetasFetched, setMetasFetched } = useContext(PdpContext.Context);
    const MAX_LENGTH = 9;
    const dispatch = useDispatch();

    useEffect(() => {
        const lengthDefault = 2;

        if (metas.length == 0) {
            const metasInit = [...Array(lengthDefault)].map(n => ({
                id: 0,
                index: n,
                acao: '',
                prazo: '',
            }));

            setMetas(metasInit);
        }
        loadMetas();
    }, []);

    const loadMetas = async () => {
        if (!isMetasFetched) {
            await dispatch(fetchMetas());
        }
        setMetasFetched(true);
    };

    const selectOptions: SelectOptionType[] = useAppSelector(state => {
        const dto = state.pdpReducer.metas;
        let listaPrazos: Meta[];

        if (prazo == 'curto') {
            listaPrazos = dto.listaMetasCurtoPrazo;
        } else if (prazo == 'medio') {
            listaPrazos = dto.listaMetasMedioPrazo;
        } else if (prazo == 'longo') {
            listaPrazos = dto.listaMetasLongoPrazo;
        } else {
            listaPrazos = dto.listaMetasPrazo;
        }

        const options: SelectOptionType[] = listaPrazos.map((m: Meta) => ({
            value: m.id || 0,
            label: m.descricao,
        }));

        // options.unshift({ value: -1, label: 'Selecione' });

        return options;
    });

    const addRow = () => {
        setMetas([
            ...metas,
            {
                id: 0,
                index: metas.length,
                acao: '',
                prazo: '',
            },
        ]);
    };

    return (
        <MetasContainer>
            <Grid>
                <TitleMini> </TitleMini>
                <TitleMini>Ações </TitleMini>
                <TitleMini>
                    Metas
                    <ToolTip message="Em quanto tempo quero realizar essa ação?">
                        <InfoIcon src={infoIcon} />
                    </ToolTip>
                </TitleMini>
                {metas.map((meta, index) => {
                    return (
                        <MetaComponent
                            key={index}
                            selectOptions={selectOptions}
                            selectDefault={
                                meta.id && meta.prazo
                                    ? {
                                          value: meta.id,
                                          label: meta.prazo,
                                      }
                                    : undefined
                            }
                            setMetas={setMetas}
                            metas={metas}
                            index={index}
                        />
                    );
                })}
            </Grid>

            <GridMobile>
                {metas.map((meta, index) => {
                    return (
                        <MetaContainer key={index}>
                            <MetaComponent
                                selectDefault={
                                    meta.id && meta.prazo
                                        ? {
                                              value: meta.id,
                                              label: meta.prazo,
                                          }
                                        : undefined
                                }
                                selectOptions={selectOptions}
                                setMetas={setMetas}
                                metas={metas}
                                index={index}
                            />
                        </MetaContainer>
                    );
                })}
            </GridMobile>
            <ToolTip message={metas.length < MAX_LENGTH ? 'Adicionar nova meta' : 'Número máximo de metas adicionadas'}>
                <ButtonAdd onClick={addRow} disabled={metas.length >= MAX_LENGTH}>
                    +
                </ButtonAdd>
            </ToolTip>
        </MetasContainer>
    );
};

export default MetasComponent;
