import { OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const AdminModalStyled = {
    Container: styled.div`
        padding: 25px 30px;
        border-radius: 25px;
    `,

    CloseIconContainer: styled.div`
        font-size: 1.5rem;

        cursor: pointer;
        color: #003c78;
        width: fit-content;
        transform: translate(7px, -5px);
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #003c78;
    `,

    Text: styled.p`
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #003c78;
    `,

    TextStrong: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2d3648;
        margin-bottom: 10px;
    `,

    TextStrongRed: styled.p`
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #be4141;
        width: 100%;
        text-align: center;
    `,

    PrimaryButton: styled(PrimaryButton)`
        height: 36px;
        font-size: 18px;
        line-height: 22px;
    `,

    CancelButton: styled(OutlineButton)`
        height: 36px;
        font-size: 18px;
        line-height: 22px;
    `,
};

export default AdminModalStyled;
