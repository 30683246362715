import styled from 'styled-components';

const StyledContentMobile = {
    Container: styled.div`
        margin: 10px;
        margin-right: 30px;
    `,

    Icon: styled.img`
        height: 30px;
    `,
};

export default StyledContentMobile;
