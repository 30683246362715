import React, { useContext, useEffect, useRef, useState } from 'react';
import Styled from './AdminBarraLateral.styled';
import AdminBarraLateralItem from './AdminBarraLateralItem';

import { AdminContext } from 'pages/admin/context/AdminContext';
import { useLocation } from 'react-router-dom';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import AllowedGroupsValidator from '../allowedGroupsValidator/AllowedGroupsValidator';

export enum AdminBarraLateralItemType {
    HOME,
    SOCIO,
    BIBLIO,
    PAGES,
    CURSOS,
    GESTAO_PAGINAS,
    HEADER,
    FAQ,
    GESTAO_USUARIOS,
    GESTAO_CERTIFICADOS,
    GESTAO_ACESSOS_EXCLUSIVOS
}

export interface IAdminBarraLateral {
    contract?: boolean;
}

const AdminBarraLateral = ({ contract }: IAdminBarraLateral) => {
    const Type = AdminBarraLateralItemType;
    const { menu, setMenu } = useContext(AdminContext.Context);
    const containerElement = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(115);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('biblioteca')) setMenu(Type.BIBLIO);
        if (location.pathname.includes('socioemocional')) setMenu(Type.SOCIO);
        if (location.pathname.includes('cursos')) setMenu(Type.CURSOS);
        if (location.pathname.includes('gestao-paginas')) setMenu(Type.GESTAO_PAGINAS);
        if (location.pathname.includes('header')) setMenu(Type.HEADER);
        if (location.pathname.includes('faq')) setMenu(Type.FAQ);
        if (location.pathname.includes('gestao-usuarios')) setMenu(Type.GESTAO_USUARIOS);
        if (location.pathname.includes('gestao-certificados')) setMenu(Type.GESTAO_CERTIFICADOS);
        if (location.pathname.includes('gestao-acessos-exclusivos')) setMenu(Type.GESTAO_ACESSOS_EXCLUSIVOS);
    }, [location]);

    useEffect(() => {
        if (containerElement.current) {
            setWidth(containerElement.current.clientWidth + 15);
        }
    }, []);

    return (
        <Styled.Container ref={containerElement} style={{ marginLeft: contract ? `-${width}px` : '5px' }}>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO, KcAdminGroups.GESTAO_USUARIO]}>
                <AdminBarraLateralItem enabled={menu == Type.HOME} type={Type.HOME} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.SOCIO} type={Type.SOCIO} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.BIBLIO} type={Type.BIBLIO} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.CURSOS} type={Type.CURSOS} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.GESTAO_PAGINAS} type={Type.GESTAO_PAGINAS} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.HEADER} type={Type.HEADER} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                <AdminBarraLateralItem enabled={menu == Type.FAQ} type={Type.FAQ} />
            </AllowedGroupsValidator>
            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_USUARIO]}>
                <AdminBarraLateralItem enabled={menu == Type.GESTAO_USUARIOS} type={Type.GESTAO_USUARIOS} />
            </AllowedGroupsValidator>
        </Styled.Container>
    );
};

export default AdminBarraLateral;
