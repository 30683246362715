import Colors from 'enums/Colors';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}

const StyleDepoimentsHome = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 247px;

        @media screen and (max-width: 1050px) {
            height: 460px;
            align-items: start;
        }
    `,

    Info: styled.div`
        display: flex;
    `,

    InfoText: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        letter-spacing: -0.01em;
        text-align: left;
        max-width: 220px;
        margin-left: 27px;
    `,

    Carrossel: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        max-width: 700px;
        margin-left: 180px;

        @media screen and (max-width: 1200px) {
          
            margin-left: 20px;
        }

        @media screen and (max-width: 1050px) {
            height: 400px;
            width: 100%;
            margin-left: 0;
        }
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean }>`
        width: 650px;
        padding: 10px;

        .slick-slide {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 180px;

            @media screen and (max-width: 1050px) {
                height: 400px;
            }
        }

        .slick-prev,
        .slick-next {
            background-color: ${Colors.Yellow} !important; /* Altere para a cor desejada */
            border-radius: 100%;
            width: 40px;
            height: 40px;
        }

        @media screen and (max-width: 1050px) {
            padding-left: 0px;
            .slick-slide {
                justify-content: center;
                align-items: center;
            }
        }
        @media screen and (max-width: 650px) {
          width: 100%;
        }
    `,

    ContentAvatar: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin-left: 20px;

        @media screen and (max-width: 1050px) {
            margin-left: 0;
        }
    `,

    ContentDepoiments: styled.div`
        display: flex;
        align-items: center;
        gap: 28px;
        width: 600px;
        height: 180px;

        @media screen and (max-width: 1050px) {
            flex-direction: column;
            width: 100%;
            height: 300px;
        }
    `,

    BodyDepoiments: styled.div`
        display: flex;
        flex-direction: column;
        width: 480px;
        gap: 8px;

        @media screen and (max-width: 1050px) {
            align-items: center;
            width: 90%;
        }
    `,

    Name: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: left;
        color: ${Colors.Blue};
    `,

    Profissional: styled.h3`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: ${Colors.Blue};
    `,

    TextDepoiment: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 25.6px;
        text-align: left;

        @media screen and (max-width: 1050px) {
            width: 100%;
            text-align: center;
        }
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 59px;
        width: 56px;
        fill: ${Colors.Yellow};

        &:hover {
            fill: ${Colors.Yellow};
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '100%' : '-3%')};

        @media screen and (max-width: 1920px) {
            left: ${props => (props.next ? '100%' : '-4%')};
        }
        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '100%' : '-5%')};
        }

        @media screen and (max-width: 1050px) {
            top: 95%;
            left: ${props => (props.next ? '60%' : '35%')};
        }
        @media screen and (max-width: 600px) {
            top: 100%;
        }

      
    `,
};

export default StyleDepoimentsHome;
