import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledCardTrilha = {
    Container: styled.div`
        width: '100%';
    `,

    Header: styled.div<{ isProf: boolean; isMobile?: boolean }>`
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: ${props => (props.isProf ? '100%' : props.isMobile ? '100%' : '70%')};
        height: 60px;
    `,

    Tabs: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        transition: all 0.5s;
        text-align: center;
    `,

    TabsLabel: styled.label<{ isActive?: boolean }>`
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        cursor: pointer;
        transition: all 0.5s;
        color: ${props => (props.isActive ? '#003C78' : '#949494')};
    `,

    Marcador: styled.div<{ isActive?: boolean; isMobile?: boolean }>`
        position: absolute;
        top: ${props => (props.isMobile ? '40px' : '20px')};
        width: 100%;
        height: 5px;
        margin-top: 10px;
        background-color: ${props => (props.isActive ? Colors.LightYellow : 'none')};
        transition: all 0.5s;
    `,

    ContainerModulos: styled.div<{ isProf: boolean; tabActive?: number; isMobile?: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 20px;
        width: ${props =>
            props.isProf && props.isMobile
                ? '100%'
                : !props.isProf && !props.isMobile && props.tabActive === 0
                ? '130%'
                : !props.isProf && !props.isMobile
                ? '70%'
                : '100%'};
        border: ${props => (props.isMobile ? '' : '3px solid #cbd2e0')};
        border-radius: 20px;

        @media screen and (max-width: 1240px) {
            flex-wrap: wrap;
            width: ${props => !props.isProf && !props.isMobile && props.tabActive === 0 && '100%'};
        }
    `,

    CardDownload: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 9px;
        width: 265px;
        height: 169px;
        background: #f8f8f8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    `,

    IconCard: styled.img``,

    TitleCardDownload: styled.label`
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: -0.01em;
        color: #003c78;
    `,

    ButtonDownload: styled.button`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        gap: 8px;
        height: 46px;
        background: #00a8e9;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: -0.01em;
        color: #003c78;

        border: none;

        &:hover {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    DivRow: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    `,

    ButtonNext: styled.button<{ isMobile: boolean }>`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #003c78;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 10px;
        width: ${props => (props.isMobile ? '90%' : '264px')};
        height: 60px;
        /* identical to box height, or 150% */

        letter-spacing: -0.01em;
        color: white;

        border: none;

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    ButtonYellow: styled(PrimaryButton)<{ isMobile: boolean }>`
        border-radius: 10px;
        width: ${props => (props.isMobile ? '90%' : '264px')};
    `,
};

export default StyledCardTrilha;
