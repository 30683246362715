import PDP from '../../models/pdp/PDP';
import MetasDTO from '../../models/pdp/MetasDTO';
import { PdpService } from '../../core/http/service/Pdp.service';
import Macrocompetencia from '../../models/pdp/Macrocompetencia';

export const GET_METAS: string = 'GET_METAS';
export const STORE_PDP: string = 'STORE_PDP';
export const SET_CURRENTSTEPFORM: string = 'SET_CURRENTSTEPFORM';
export const GET_MACROCOMPETENCIAS: string = 'GET_PARAMETROS_CADASTRO';
export const CLEAR_PDP: string = 'CLEAR_PDP';
export const CLEAR_METAS: string = 'CLEAR_METAS';
export const CLEAR_MACRO: string = 'CLEAR_MACRO';
export const OPERACAO_ALTERACAO: string = 'OPERACAO_ALTERACAO';
export const CANCELAR_OPERACAO_ALTERACAO: string = 'CANCELAR_OPERACAO_ALTERACAO';

const sendToAnalytics = (where: string = '', error: any = null) => {
    console.error(where, error);
};

export const fetchMacrocompetencias = () => {
    const pdpService = new PdpService();

    return async (dispatch: any) => {
        try {
            let response: any = await pdpService.consultarMacrocompetencias();

            const output: Macrocompetencia[] = response.data.map((item: any) => new Macrocompetencia().fromJSON(item));

            dispatch({
                type: GET_MACROCOMPETENCIAS,
                macrocompetencias: output,
            });
        } catch (error) {
            sendToAnalytics('fetchMacrocompetencias', error);
            throw error;
        }
    };
};

export const fetchMetas = () => {
    const pdpService = new PdpService();

    return async (dispatch: any) => {
        try {
            let response: any = [];
            response = await pdpService.consultarMetas();
            const output: MetasDTO = new MetasDTO().fromJSON(response.data);

            dispatch({
                type: GET_METAS,
                metas: output,
            });
        } catch (error) {
            sendToAnalytics('fetchMetas', error);
            throw error;
        }
    };
};

export const consultarUltimoPdpPorPerfil = (idPerfil: number) => {
    const pdpService = new PdpService();

    return async (dispatch: any) => {
        try {
            let response: any = [];
            response = await pdpService.consultarUltimoPdpPorPerfil(idPerfil);

            const output: PDP = new PDP().fromJSON(response.data);

            dispatch({
                type: STORE_PDP,
                pdp: output,
            });
        } catch (error) {
            sendToAnalytics('consultarPdp', error);
            throw error;
        }
    };
};

export const setCurrentFormStep = (current: number) => {
    return (dispatch: any) => {
        dispatch({
            type: SET_CURRENTSTEPFORM,
            currentStepForm: current,
        });
    };
};

export const storePDP = (entity: PDP) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: STORE_PDP,
                pdp: entity,
            });
        } catch (error) {
            sendToAnalytics('storePDP', error);
            throw error;
        }
    };
};

export const cadastrarPDP = (pdp: PDP) => {
    const pdpService = new PdpService();

    return async (dispatch: any) => {
        try {
            await pdpService.cadastrarPDP(pdp);
        } catch (error) {
            sendToAnalytics('cadastrarPDP', error);
            throw error;
        }
    };
};

export const alterarPDP = (pdp: PDP) => {
    const pdpService = new PdpService();

    return async (dispatch: any) => {
        try {
            await pdpService.alterarPDP(pdp);
        } catch (error) {
            sendToAnalytics('cadastrarPDP', error);
            throw error;
        }
    };
};

export const iniciarOperacaoAlteracao = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: OPERACAO_ALTERACAO,
            });
        } catch (error) {
            sendToAnalytics('iniciarOperacaoAlteracao', error);
            throw error;
        }
    };
};

export const cancelarOperacaoAlteracao = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CANCELAR_OPERACAO_ALTERACAO,
            });
        } catch (error) {
            sendToAnalytics('cancelarOperacaoAlteracao', error);
            throw error;
        }
    };
};

export const clearStore = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CLEAR_PDP,
            });
        } catch (error) {
            sendToAnalytics('clearStore', error);
            throw error;
        }
    };
};

export const clearMetas = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CLEAR_METAS,
            });
        } catch (error) {
            sendToAnalytics('clearMetas', error);
            throw error;
        }
    };
};


export const clearMacro = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CLEAR_MACRO,
            });
        } catch (error) {
            sendToAnalytics('clearMacro', error);
            throw error;
        }
    };
};
