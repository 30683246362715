import { GenericService } from './Generic.service';
import PerfilUsuario from '../../../models/perfil/PerfilUsuario';
import DiarioBordo from '../../../models/diarioBordo/DiarioBordo';

class DiarioBordoService extends GenericService {
    readonly URI: string = '/v1/diarioBordo';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public findTags = () => {
        return this.get('findTags');
    };

    public criarRegistro = (profile: PerfilUsuario) => {
        return this.post('criarRegistro', profile);
    };

    public listarDiarios = (idPerfil: number, filtroPesquisa: string = '') => {
        const params = { idPerfil, filtroPesquisa };
        return this.get('listarDiarios', params);
    };

    public buscarPorId = (idDiario: number, idPerfil: number) => {
        const params = { idDiario, idPerfil };
        return this.get('buscarPorId', params);
    };

    public updateDiary = (diario: DiarioBordo, idPerfil: number) => {
        return this.post('atualizarDiario', diario, { idPerfil });
    };

    public excluirDiario = (idDiario: number, idPerfil: number) => {
        const params = { idPerfil, idDiario };
        return this.deleteByParams('excluirPorId', params);
    };

    public duplicarDiario = (diario: DiarioBordo, idPerfil: number) => {
        return this.post('duplicarDiario', diario, { idPerfil });
    };
}

export default DiarioBordoService;
