import { MobileMaxWidth } from 'constants/Dimensions';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModalCheckGoToPage = {
    Container: styled(Modal)<{ width?: number }>`
        padding-left: unset;

        .dialogWidth {
            min-width: ${props => (props.width ? props.width + 'px' : '1166px')};
            border-radius: 10px;
        }

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            .dialogWidth {
                min-width: 800px;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            .dialogWidth {
                min-width: 316px;
            }
        }
    `,

    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
};

export default StyledModalCheckGoToPage;
