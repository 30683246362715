import React from 'react';
import { IoAlertCircle } from 'react-icons/io5';
import Styled from './ResultadoPesquisa.styled';

export interface INoResults {}

const NoResults = ({}: INoResults) => {
    return (
        <Styled.NoResultsContainer>
            <IoAlertCircle />
            <span>Não foram encotrados resultados para essa busca</span>
        </Styled.NoResultsContainer>
    );
};

export default NoResults;
