import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    HomepageMenuStyle,
    MenuItemsContainer,
    MenuItem,
    MenuItemText,
    NavHashLinkStyle,
    MobileMenuButtonContainer,
    MobileMenuIcon,
    MobileMenu,
    MobileMenuCloseButton,
    MobileMenuItemsContainer,
    MobileMenuItem,
    Link,
    SearchButtonContainer,
} from './HomepageMenuStyled.styled';
import { IsCustomMaxWidth, IsMobileMaxWidth } from '../../../core/util/WindowSize';
import { useKeycloak } from '@react-keycloak/web';
import ActionButton from '../ActionButton/ActionButton';
import { LandingPageWebItems, OtherPageWebItems } from './NavMenuItems';
import { GTM_Menu } from 'components/GoogleTagManager';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import { FiSearch } from 'react-icons/fi';

interface IHomepageMenu {
    homeIntermediate: boolean;
    jornada: boolean;
}
const HomepageMenu = ({ ...props }: IHomepageMenu) => {
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const { openSearch } = useContext(PesquisaContext.Context);

    const { keycloak } = useKeycloak();
    const location = useLocation();

    const scrollWithOffset = (el: any) => {
        if (el) {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -102;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const currLocationHref: String = window.location.href;
        const currHrefSplit: string[] = currLocationHref.split('#');
        if (currHrefSplit.length > 1) {
            const el = document.getElementById(currHrefSplit[1]);
            setTimeout(() => {
                scrollWithOffset(el);
            }, 500);
        }
    }, [window.location.href, window.location.pathname]);

    const history = useHistory();

    const isMobile = () => {
        return IsCustomMaxWidth(1250);
    };

    const isIntermediate = (): boolean => {
        return props.homeIntermediate;
    };

    const isJornada = (): boolean => {
        return props.jornada;
    };

    const goToHome = () => {
        history.push('/');
    };

    const goToBiblioteca = () => {
        history.push('/biblioteca');
    };

    const goToCursosLivres = () => {
        history.push('/cursos-livres');
    };

    const goToMeuPainel = () => {
        history.push('/painel');
    };

    const goToSuporte = () => {
        history.push('/suporte');
    };

    const onHandlerPesquisa = () => {
        setIsMobileMenuVisible(false);
        openSearch();
    };

    return (
        <HomepageMenuStyle id="homepagemenuStyle">
            {isMobile() ? (
                <>
                    <MobileMenuButtonContainer>
                        <SearchButtonContainer onClick={openSearch} style={{ transform: 'scale(0.85) translateY(-2px)', marginRight: '20px' }}>
                            <FiSearch />
                        </SearchButtonContainer>

                        <MobileMenuIcon
                            onClick={() => {
                                setIsMobileMenuVisible(true);
                            }}
                        />
                    </MobileMenuButtonContainer>
                    <MobileMenu open={isMobileMenuVisible}>
                        <MobileMenuItemsContainer>
                            <MobileMenuCloseButton
                                onClick={() => {
                                    setIsMobileMenuVisible(false);
                                }}
                            />
                            {!isIntermediate() ? (
                                <>
                                    <NavHashLinkStyle
                                        smooth
                                        scroll={el => {
                                            scrollWithOffset(el);
                                            setIsMobileMenuVisible(false);
                                        }}
                                        to={keycloak.authenticated ? '#jornadas-de-desenvolvimento' : '#inicio'}
                                        onClick={() => GTM_Menu('menu_mobile', 'inicio_btn_mobile')}
                                    >
                                        <MobileMenuItem>
                                            <MenuItemText>{`Início`}</MenuItemText>
                                        </MobileMenuItem>
                                    </NavHashLinkStyle>
                                </>
                            ) : (
                                <MobileMenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            GTM_Menu('menu_mobile', 'inicio_btn_mobile_intermediaria');
                                            goToHome();
                                        }}
                                    >
                                        <MenuItemText>Início</MenuItemText>
                                    </MenuItem>
                                </MobileMenuItem>
                            )}

                            {!keycloak.authenticated && (
                                <>
                                    {!isIntermediate() ? (
                                        <NavHashLinkStyle
                                            smooth
                                            scroll={el => {
                                                scrollWithOffset(el);
                                                setIsMobileMenuVisible(false);
                                            }}
                                            to="#objetivos"
                                            onClick={() => GTM_Menu('menu_mobile', 'objetivos_btn_mobile')}
                                        >
                                            <MobileMenuItem>
                                                <MenuItemText>{`Objetivos`}</MenuItemText>
                                            </MobileMenuItem>
                                        </NavHashLinkStyle>
                                    ) : (
                                        <MobileMenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    GTM_Menu('menu_mobile', 'objetivos_btn_mobile_intermediaria');
                                                    window.location.href = window.location.origin + '/#objetivos';
                                                }}
                                            >
                                                <MenuItemText>{`Objetivos`}</MenuItemText>
                                            </MenuItem>
                                        </MobileMenuItem>
                                    )}
                                </>
                            )}

                            {!isJornada() ? (
                                !isIntermediate() ? (
                                    <NavHashLinkStyle
                                        smooth
                                        scroll={el => {
                                            scrollWithOffset(el);
                                            setIsMobileMenuVisible(false);
                                        }}
                                        to="#jornadas-de-desenvolvimento"
                                        onClick={() => GTM_Menu('menu_mobile', 'jornadas_btn_mobile')}
                                    >
                                        <MobileMenuItem>
                                            <MenuItemText>{`Jornadas`}</MenuItemText>
                                        </MobileMenuItem>
                                    </NavHashLinkStyle>
                                ) : (
                                    <MobileMenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                GTM_Menu('menu_mobile', 'jornadas_btn_mobile_intermediaria');
                                                window.location.href = window.location.origin + '/#jornadas-de-desenvolvimento';
                                            }}
                                        >
                                            <MenuItemText>{`Jornadas`}</MenuItemText>
                                        </MenuItem>
                                    </MobileMenuItem>
                                )
                            ) : (
                                <></>
                            )}

                            {!isIntermediate() ? (
                                <Link
                                    onClick={() => {
                                        GTM_Menu('menu_mobile', 'biblioteca_btn_mobile');
                                        goToBiblioteca();
                                    }}
                                >
                                    <MobileMenuItem>
                                        <MenuItemText>{`Biblioteca`}</MenuItemText>
                                    </MobileMenuItem>
                                </Link>
                            ) : (
                                <MobileMenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            GTM_Menu('menu_mobile', 'biblioteca_btn_mobile_intermediaria');
                                            goToBiblioteca();
                                        }}
                                    >
                                        <MenuItemText>{`Biblioteca`}</MenuItemText>
                                    </MenuItem>
                                </MobileMenuItem>
                            )}

                            {!isIntermediate() ? (
                                <Link
                                    onClick={() => {
                                        GTM_Menu('menu_mobile', 'cursolivre_btn_mobile');
                                        goToCursosLivres();
                                    }}
                                >
                                    <MobileMenuItem>
                                        <MenuItemText>{`Cursos livres`}</MenuItemText>
                                    </MobileMenuItem>
                                </Link>
                            ) : (
                                <MobileMenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            GTM_Menu('menu_mobile', 'cursolivre_btn_mobile_intermediaria');
                                            goToCursosLivres();
                                        }}
                                    >
                                        <MenuItemText>{`Cursos livres`}</MenuItemText>
                                    </MenuItem>
                                </MobileMenuItem>
                            )}

                            {keycloak.authenticated && (
                                <>
                                    {!isIntermediate() ? (
                                        <Link
                                            onClick={() => {
                                                GTM_Menu('menu_mobile', 'painel_btn_mobile');
                                                goToMeuPainel();
                                            }}
                                        >
                                            <MobileMenuItem>
                                                <MenuItemText>{`Meu painel`}</MenuItemText>
                                            </MobileMenuItem>
                                        </Link>
                                    ) : (
                                        <MobileMenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    GTM_Menu('menu_mobile', 'painel_btn_mobile_intermediaria');
                                                    goToMeuPainel();
                                                }}
                                            >
                                                <MenuItemText>{`Meu painel`}</MenuItemText>
                                            </MenuItem>
                                        </MobileMenuItem>
                                    )}
                                </>
                            )}

                            {!isIntermediate() ? (
                                <Link
                                    onClick={() => {
                                        GTM_Menu('menu_mobile', 'suporte_btn_mobile');
                                        goToSuporte();
                                    }}
                                >
                                    <MobileMenuItem>
                                        <MenuItemText>{`Suporte`}</MenuItemText>
                                    </MobileMenuItem>
                                </Link>
                            ) : (
                                <MobileMenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            GTM_Menu('menu_mobile', 'suporte_btn_mobile_intermediaria');
                                            goToSuporte();
                                        }}
                                    >
                                        <MenuItemText>{`Suporte`}</MenuItemText>
                                    </MenuItem>
                                </MobileMenuItem>
                            )}

                            <MobileMenuItem style={{ transform: `translate(${location.pathname.length < 2 ? 0 : 10}px)` }}>
                                <MenuItemText onClick={onHandlerPesquisa}>{`Pesquisa`}</MenuItemText>
                            </MobileMenuItem>

                            <ActionButton close={setIsMobileMenuVisible} />
                        </MobileMenuItemsContainer>
                    </MobileMenu>
                </>
            ) : (
                <MenuItemsContainer>
                    {isIntermediate() ? <OtherPageWebItems /> : <LandingPageWebItems callback={scrollWithOffset} />}

                    <ActionButton />
                </MenuItemsContainer>
            )}
        </HomepageMenuStyle>
    );
};
export default HomepageMenu;
