import styled from "styled-components";
import Colors from "../../../../enums/Colors";
import { MobileMaxWidth } from "../../../../constants/Dimensions";

export const ContinuarButtonStyle = styled.button`
  @media only screen and (max-width: ${MobileMaxWidth}px) {
    width: 316px;
  }
  width: 167px;
  height: 42px;
  background: ${Colors.Yellow};
  color: ${Colors.DarkBlue};
  font-weight: bold;
  letter-spacing: 0.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  outline: none;
  border: none;
  &: hover {
    color: ${Colors.White};
  }
  &:disabled {
    background-color: ${Colors.Grey5};
    color: ${Colors.Grey3};
  }
`;
