import { TypeForm } from "pages/solucoes/components/solucoes/FormSolucoes";
import { GenericService } from "./Generic.service";


export class SolucoesPerfilUsuarioService extends GenericService {
    readonly URI: string = '/v1/solucoesPerfilUsuario';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public inserir(dados: TypeForm){
        return this.post('', dados);
    }

}