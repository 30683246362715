import { JornadaStatusEnum } from 'enums/JornadaStatusEnum';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;
export const Content = styled.div`
    display: flex;
    gap: 60px;

    @media only screen and (max-width: 450px) {
        gap: 5px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 150px;

    @media only screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;

    @media only screen and (max-width: 1200px) {
        gap: 5px;
    }
`;

export const Text = styled.p<{ isStatus: boolean }>`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(10px, 4vw, 15px);
    line-height: 19px;
    letter-spacing: -0.6px;

    color: ${props => (props.isStatus ? '#FFFFFF' : '#183F73')};
`;

export const Status = styled.div<{ BGcolor: JornadaStatusEnum }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 10px;
    height: 30px;
    width: 120px;

    background: ${props =>
        props.BGcolor === JornadaStatusEnum.FINALIZADA
            ? '#229645'
            : props.BGcolor === JornadaStatusEnum.INICIADA
            ? '#dcb319'
            : props.BGcolor === JornadaStatusEnum.NAO_INICIADA
            ? '#D64545'
            : '#A9A9A9'};
    border-radius: 37px;

    @media only screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const IconSvg = styled.img`
    height: 17px;
    width: 17px;
    cursor: pointer;
`;

export const RedirectButton = styled.button`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #003c78;
    border: none;
    border-radius: 6px;
    width: 29px;
    height: 29px;

    &:hover {
        border: #003c78 1px solid;
        background: white;
        img {
            filter: invert(0.9) sepia(1) saturate(30) hue-rotate(227deg);
        }
    }
`;
