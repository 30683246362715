import { KeycloakService } from 'core/http/service/Keycloak.service';
import UsuarioDTO from 'models/UsuarioDTO';

export const LOGOUT: string = 'LOGOUT';
export const SSO_ACCESS_TOKEN = 'SSO_ACCESS_TOKEN';
export const USUARIO_LOGADO: string = 'USUARIO_LOGADO';

const sendToAnalytics = (where: string = '', error: any = null) => {
    console.error(where, error);
};

export const getUsuarioLogado = () => {
    const service = new KeycloakService();

    return async (dispatch: any) => {
        try {
            const response: any = await service.getUsuarioLogado();
            const { data } = response;

            /* let claims: Map<String, Object> = new Map<String, Object>();
            claims.set('cpf', data.claims?.cpf ?? ''); */

            const output: UsuarioDTO = new UsuarioDTO().fromJSON(data);

            output.claims = data.claims;

            dispatch({
                type: USUARIO_LOGADO,
                usuarioLogado: output,
            });
        } catch (error) {
            sendToAnalytics('getUsuarioLogado', error);
            throw error;
        }
    };
};

export const setUsuarioLogado = (usuario: UsuarioDTO) => {
    const service = new KeycloakService();

    return async (dispatch: any) => {
        try {
            dispatch({
                type: USUARIO_LOGADO,
                usuarioLogado: usuario,
            });
        } catch (error) {
            sendToAnalytics('setUsuarioLogado', error);
            throw error;
        }
    };
};

export const storeSSOAccessToken = (access_token: string) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: SSO_ACCESS_TOKEN,
                accessToken: access_token,
            });
        } catch (error) {
            sendToAnalytics('storeSSOAccessToken', error);
            throw error;
        }
    };
};

export const logout = () => {
    return async (dispatch: any) => {
        dispatch({
            type: LOGOUT,
        });
    };
};
