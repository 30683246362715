import React, { useContext } from 'react';
import { bucketS3, caminhoAnexosRecursosCostumizados } from 'constants/EnvironmentData';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import Styled from './RecursosPage.styled';
import { ItemRecursosCostumizadoPublic } from 'models/recursos-costumizados/ItemRecursosCostumizadoPublic';
import { RecursosContext } from './context/RecursosContext';

export interface IRecursosPage {
    data: ItemRecursosCostumizadoPublic;
}

const RecursoItem = ({ data }: IRecursosPage) => {
    const history = useHistory();
    const location = useLocation();

    const { loading, pagina } = useContext(RecursosContext.Context);

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosRecursosCostumizados();
    };

    const handleAccess = () => {
        history.push(location.pathname + data.url);
    };

    return (
        <Styled.ItemContainer>
            <Styled.HeadItem>
                <Styled.ItemImage src={getS3BaseUrl() + data.filePath} />

                {/* {data.isConcluido && (
                    <Styled.ItemCheck>
                        <BsFillCheckCircleFill />
                    </Styled.ItemCheck>
                )} */}
            </Styled.HeadItem>

            <Styled.ItemTitle>{data.nome}</Styled.ItemTitle>
            <Styled.ItemText>{data.descricao}</Styled.ItemText>

            <Styled.ButtonItem onClick={() => handleAccess()}>Acessar</Styled.ButtonItem>
        </Styled.ItemContainer>
    );
};

export default RecursoItem;
