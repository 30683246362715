import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import BiblioItemAnswer from './BiblioItemAnswer';
import BiblioItemPreview from './BiblioItemPreview';

class BiblioItemComment extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('item_id')
    @jsonProperty(Number)
    idItem: number = -1;

    @jsonName('itemBiblioteca')
    @jsonProperty(BiblioItemPreview)
    item: BiblioItemPreview | null = null;

    @jsonName('comentario')
    @jsonProperty(String)
    comment: string = '';

    @jsonName('respostaAdmin')
    @jsonProperty(Boolean)
    answered: boolean = false;

    @jsonName('nomeUsuario')
    @jsonProperty(String)
    username: string = '';

    @jsonName('email')
    @jsonProperty(String)
    email: string = '';

    @jsonName('codigoUsuario')
    @jsonProperty(String)
    userId: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    dateAtualizacao: string = '';

    @jsonName('data_criacao')
    @jsonProperty(String)
    dataCriacao: string = '';

    @jsonName('ativo')
    @jsonProperty(Boolean)
    active: boolean = true;

    @jsonName('respostas')
    @jsonProperty([BiblioItemAnswer])
    answers: BiblioItemAnswer[] = [];
}

export default BiblioItemComment;
