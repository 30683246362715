import { createContext } from 'react';

export namespace ProfileContext {
    export interface IContext {
        postProfilePath: string;
        _setPostProfilePath: (value: string) => void;
    }

    export const Context = createContext({} as IContext);
}
