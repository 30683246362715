import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    margin: 7px 0;
    align-items: center;

    flex-wrap: wrap;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-bottom: 30px;
        // justify-content: center;
    }
`;

export const LabelCheck = styled.label`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(12px, 4vw, 22px);
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2d3648;
    cursor: pointer;
`;

export const CheckBox = styled.input`
    margin-right: 10px;
    accent-color: black;
`;
