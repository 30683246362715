import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import {
    Title,
    FaqStyle,
    FormContainer,
    AccordionButton,
    FaleConoscoStyle,
    FaqContainerStyle,
    AccordionContainer,
    AccordionButtonIcon,
    AccordionContentStyle,
    ContactUsContainerStyle,
    ContactUsContainerStyleV2,
    TitleText,
    TextContainer,
    TitlePage,
    SubtitlePage,
} from './FaqContainer.styled';
import useFetch from '../../../../hooks/useFetch';
import ContactUsV2 from './contactUs/v2/ContactUsV2';
import FaleConosco from '../../../../models/FaleConosco';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import * as homePageActions from '../../../../store/actions/HomePage';
import TextoSobreposto from '../textoSobrepostoComponent/TextoSobreposto';
import { HomePageService } from '../../../../core/http/service/HomePage.service';

import PlusIconSvg from '../../../../images/homepage/faq/plusIcon.svg';
import ItemFaq from 'models/faq/ItemFaq';
import { FetchUtils } from 'core/util/FetchUtils';
import { ItemFaqService } from 'core/http/service/ItemFaq.service';
import FAQ from 'models/FAQ';
import SimpleAccordion, { IContentModel } from './Accordion';
import Expandable from 'components/UI/Expandable';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import ContentLoader from 'react-content-loader';

const CardHeader = styled(Card.Header)`
    padding: 16px 24px;
    width: 545px;
    height: 52px;
    background: rgba(0, 60, 120, 0.1);
    border-radius: 4px;
`;

const IsMobile = () => {
    return IsMobileMaxWidth();
};
const FaqContainer = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [itensFaq, setItensFaq] = useState<ItemFaq[]>([]);
    const [pagina, setPagina] = useState<AdminPaginaIntermediaria | null>(null);
    const itemFaqService = new ItemFaqService();
    const paginaService = new PaginaIntermediariaService();

    const OnSubmitHandler = async (model: FaleConosco) => {
        let err: boolean = false;
        const homepageService = new HomePageService();
        try {
            const response: any = await homepageService.openTicket(model);
            if (response.data.erro) {
                err = true;
            }
        } catch (error) {
            err = true;
        }

        return err;
    };

    const loadFaq = async () => {
        const response = await itemFaqService.listarPublico();
        setItensFaq(response.data.map((item: any) => new ItemFaq().fromJSON(item)));

        const responsePagina: any = await paginaService.listar();
        const output = responsePagina.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

        output.forEach((element: AdminPaginaIntermediaria) => {
            if (element.pagina === PaginasIntermediariasEnum.FAQ) {
                setPagina(element);
            }
        });
    };

    useEffect(() => {
        FetchUtils.loadingHof(setIsLoading, loadFaq);
    }, []);

    const getItemsAccordionModels = () => {
        let retorno: IContentModel[] = [];
        itensFaq.map(item => {
            if (item.ativo) {
                retorno.push({
                    identifier: item.codigo,
                    title: item.pergunta,
                    content: item.resposta,
                } as IContentModel);
            }
        });

        return retorno;
    };

    const MyLoader = () => {
        return (
            <>
                {!IsMobileMaxWidth(550) ? (
                    <>
                        <ContentLoader
                            width={600}
                            height={600}
                            viewBox="0 0 600 600"
                            backgroundColor="#f0f0f0"
                            foregroundColor="#dedede"
                            title="Carregando..."
                        >
                            <rect x="20" y="10" rx="2" ry="2" width="554" height="57" />
                            <rect x="20" y="197" rx="0" ry="0" width="550" height="51" />
                            <rect x="20" y="278" rx="0" ry="0" width="550" height="51" />
                            <rect x="20" y="357" rx="0" ry="0" width="550" height="51" />
                            <rect x="20" y="88" rx="2" ry="2" width="377" height="25" />
                            <rect x="20" y="439" rx="0" ry="0" width="550" height="51" />
                        </ContentLoader>
                    </>
                ) : (
                    <>
                        {' '}
                        <ContentLoader
                            speed={14}
                            width={600}
                            height={800}
                            viewBox="0 0 600 800"
                            backgroundColor="#e9dddd"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="14" y="17" rx="2" ry="2" width="312" height="109" />
                            <rect x="14" y="268" rx="0" ry="0" width="322" height="153" />
                            <rect x="15" y="166" rx="2" ry="2" width="278" height="58" />
                            <rect x="14" y="432" rx="0" ry="0" width="323" height="153" />
                            <rect x="14" y="600" rx="0" ry="0" width="323" height="153" />
                        </ContentLoader>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <ContactUsContainerStyleV2>
                <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    {!isLoading ? (
                        <>
                            <TextContainer>
                                <TitlePage>{pagina?.titulo ?? 'Perguntas Frequentes'}</TitlePage>
                                <SubtitlePage>{pagina?.descricao ?? 'Em construção... '}</SubtitlePage>
                            </TextContainer>
                            <SimpleAccordion contentList={getItemsAccordionModels()} />
                        </>
                    ) : (
                        <>
                            <MyLoader />
                        </>
                    )}
                </div>

                <ContactUsV2 />
            </ContactUsContainerStyleV2>
        </>
    );
};

export default FaqContainer;
