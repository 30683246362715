import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import React from 'react';

import styled from 'styled-components';

import Colors from '../../enums/Colors';

import arrowUpIcon from '../../images/arrow-up.svg';

const Container = styled.div`
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    width: 100%;
    text-align: right;
`;

const Button = styled.button`
    width: 162px;
    height: 40px;
    background-color: white;
    border: 1px solid ${Colors.Grey6};
    box-sizing: border-box;
    box-shadow: 4px 4px 80px -10px rgba(0, 60, 120, 0.2);
    border-radius: 5px;

    &:hover {
        background-color: ${Colors.Grey8};
    }
`;

const Label = styled.span`
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    margin-left: 10px;

    color: ${Colors.BlueGrey};
`;
const Icon = styled.img``;

const GotoTop = () => {
    const scroll = () => {
        GTM_Page_Jornada_Desenvolvimento('painel_pdp', 'voltar_topo_btn');
        window.scrollTo(0, 0);
    };

    return (
        <Container>
            <Button onClick={scroll}>
                <Icon src={arrowUpIcon} />
                <Label>Voltar ao topo</Label>
            </Button>
        </Container>
    );
};

export default GotoTop;
