import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im';
import { MdBookmarks } from 'react-icons/md';

const Styled = {
    Container: styled.div`
        font-family: 'Montserrat';
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 20px;
        background-color: ${Colors.White};
        
    `,

    Body: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,

    Content: styled.div`
        display: flex;
        gap: 1rem;
        width: 80%;
        align-items: start;
        flex-direction: column;

        @media screen and (max-width: 800px){
            width: 100%;
        }
    `,

    Header: {
        Container: styled.div`
            display: flex;
            gap: 1.5rem;
            flex-direction: column;
            margin-bottom: 100px;
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `,

        Divider: styled.div`
            background-color: ${Colors.Yellow};
            height: 5px;
            width: 100%;
        `,
    },

    Quote: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            color: ${Colors.Blue};
            font-weight: bold;
            flex-direction: column;
            min-height: 400px;
        `,

        Divider: styled.div<{ inversion?: boolean }>`
            display: flex;
            align-items: center;
            flex-direction: ${({ inversion }) => (inversion ? 'row' : 'row-reverse')};
            height: 40px;
            width: 100%;
        `,

        ContentDivider: styled.div`
            background-color: ${Colors.Yellow};
            height: 10px;
            width: 80%;
        `,
    },

    Footer: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            height: 80px;
            margin-top: 100px;
        `,

        Content: styled.div`
            display: flex;
            justify-content: space-between;
            height: 100%;
        `,

        Summary: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            color: ${Colors.Yellow};
            font-weight: bold;
        `,

        NumberPage: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${Colors.White};
            font-size: 25px;
            width: 80px;
            background-color: ${Colors.Yellow};
            height: 80%;
            border-radius: 0px 0px 20px 0px;
        `,

        Divider: styled.div`
            background-color: ${Colors.Yellow};
            height: 5px;
            width: 100%;
        `,
    },
};

interface Header {
    firstText: string;
    secoundText: string;
}

const Header = ({ firstText, secoundText }: Header) => {
    return (
        <Styled.Header.Container>
            <Styled.Header.Content>
                <p
                    style={{
                        color: Colors.Blue,
                        fontStyle: 'italic',
                        fontWeight: 600,
                    }}
                >
                    {firstText}
                </p>
                <p
                    style={{
                        color: Colors.Yellow,
                        fontStyle: 'italic',
                        fontWeight: 600,
                    }}
                >
                    {secoundText}
                </p>
            </Styled.Header.Content>
            <Styled.Header.Divider />
        </Styled.Header.Container>
    );
};

interface IQuote {}

const Quote = ({}: IQuote) => {
    return (
        <Styled.Quote.Container>
            <Styled.Quote.Divider>
                <Styled.Quote.ContentDivider /> <ImQuotesLeft color={Colors.Yellow} size={70} style={{ marginRight: '20px' }} />
            </Styled.Quote.Divider>
            <p
                style={{
                    fontSize: 20,
                    lineHeight: '32px',
                    textAlign: "end",
                    width: '80%',
                }}
                dangerouslySetInnerHTML={{
                    __html: `
                “[…] aprender a ler, a escrever, alfabetizar-se é, antes de mais <br />
                 nada, aprender a ler o mundo, compreender o seu contexto, não numa manipulação mecânica de palavras, mas numa <br />
                 relação dinâmica que vincula linguagem e realidade.” <br /><br />
                 (Paulo Freire)
                
                `,
                }}
            />
            <Styled.Quote.Divider inversion>
                <Styled.Quote.ContentDivider /> <ImQuotesRight color={Colors.Yellow} size={70} style={{ marginLeft: '20px' }} />
            </Styled.Quote.Divider>
        </Styled.Quote.Container>
    );
};

interface IPageEbook {
    header: {
        firstText: string;
        secoundText: string;
    };
    isQuote?: {
        have: boolean;
    };

    footer: {
        numberSummary: number
    }
}

const PageEbook = ({ children, header, isQuote, footer }: React.PropsWithChildren<IPageEbook>) => {
    return (
        <Styled.Container>
            <Header firstText={header.firstText} secoundText={header.secoundText} />
            {isQuote && isQuote.have && <Quote />}
            <Styled.Body>
                <Styled.Content>
                    <Styled.Content >{children}</Styled.Content>
                </Styled.Content>
            </Styled.Body>
            <Styled.Footer.Container>
                <Styled.Footer.Divider />
                <Styled.Footer.Content>
                    <Styled.Footer.Summary>
                        <MdBookmarks size={30} color={Colors.Yellow} />
                        <h1>SUMÁRIO</h1>
                    </Styled.Footer.Summary>
                    <Styled.Footer.NumberPage>{footer.numberSummary}</Styled.Footer.NumberPage>
                </Styled.Footer.Content>
            </Styled.Footer.Container>
        </Styled.Container>
    );
};

export default PageEbook;
