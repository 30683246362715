import styled from 'styled-components';
import Colors from '../../../../../enums/Colors';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';

export const RealizandoEscolhasHeaderMobileStyle = styled.nav(() => {
    return new FullCss(
        {
            display: 'flex',
            width: '100%',
            height: '46px',
            left: '0px',
            top: '60px',
            background: Colors.SoftBlue,
            position: 'fixed',
            zIndex: '2',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
                top: '80px',
            }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                top: '60px',
            }),
        ]
    ).getCss();
});

export const ImgHome = styled.img(() => {
    return new FullCss(
        {
            position: 'absolute',
            height: '50%',
            left: '15px',
            top: '13px',
        },
        new MediaQuery(':hover', {
            cursor: 'pointer',
        })
    ).getCss();
});

export const ImgLogo = styled.img(() => {
    return new FullCss(
        {
            position: 'absolute',
            height: '36px',
            left: '60px',
            top: '8px',
            width: '40%',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '60%',
            }),
            new MediaQuery(':hover', {
                cursor: 'pointer',
            }),
        ]
    ).getCss();
});
