import { jsonProperty, Serializable } from 'ts-serializable';
import { boolean } from 'yup';

export class CardsItemCustomizado extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    infoDestaque: string = '';

    @jsonProperty(String)
    corInfoDestaque: string = '';

    @jsonProperty(String, null)
    tamanhoInfoDestaque: string | null = null;

    @jsonProperty(Boolean)
    ocultarInfoDestaque: boolean = false;

    @jsonProperty(String)
    detalhamento: string = '';

    @jsonProperty(String)
    corDetalhamento: string = '';

    @jsonProperty(String, null)
    tamanhoDetalhamento: string | null = null;

    @jsonProperty(Boolean)
    ocultarDetalhamento: boolean = false;

    @jsonProperty(Boolean)
    oculto: boolean = false;

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(Boolean)
    ocultarAnexo: boolean = false;

    @jsonProperty(String, null)
    filePath: string | null = null;
}
