import Row from 'components/Row';
import { NavyButton, OutlineButtonInvert } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const RecursosPageStyled = {
    Container: styled.div`
        margin: 0 auto;
        margin-top: 86px;
        width: 100%;
        padding: 60px 0;
        background-color: white;
        max-width: 1280px;

        .mobile-only {
            display: none;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 10px 25px;

            .desk-only {
                display: none;
            }

            .mobile-only {
                display: block;
            }
        }
    `,

    ShareButton: styled(Button)`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: ${Colors.SoftBlue};
        height: 44px;
        width: 44px;
        border: none;
    `,

    ItemContainer: styled.div`
        display: flex;
        flex-direction: column;
        padding: 12px;

        background: #ffffff;
        box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: 300px;
        margin: 20px 0;
        height: 380px;
    `,

    HeadItem: styled.div`
        position: relative;
    `,

    ItemImage: styled.img`
        width: 100%;
        height: 105px;
        border-radius: 5px;
        object-fit: cover;
    `,

    ItemTitle: styled.p`
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
        margin: 10px 0;
    `,

    ItemCheck: styled.div`
        color: #49b806;
        font-size: 1.5rem;
        position: absolute;
        top: 5px;
        right: 5px;
    `,

    ItemText: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #111111;
        flex-grow: 1;
        overflow: hidden;
        margin-bottom: 5px;
        padding: 0 5px;
    `,

    ButtonItem: styled(OutlineButtonInvert)`
        width: 100%;
        border-radius: 5px;
    `,

    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #003c78;
    `,

    Text: styled.div`
        font-family: 'Barlow';
        font-size: 18px;
        line-height: 22px;
        color: #111111;
        margin-top: 15px;
        max-width: 630px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 20px 0;
        }
    `,

    ButtonFav: styled(NavyButton)`
        position: relative;
        bottom: 12px;
        background-color: #00a8e9;
        color: white;
        border: none;
        font-weight: 400;

        span {
            margin-left: 5px;
        }

        :hover {
            background-color: #47aefc;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: fit-content;
            padding: 0 25px;
            margin-bottom: 20px;
        }
    `,

    Grid: styled.div`
        padding: 40px 0;
        display: flex;
        align-items: center;
        max-width: 100%;
        flex-wrap: wrap;
        gap: 1rem;

        @media screen and (max-width: ${MobileMaxWidth}px) {
            justify-content: center;
        }
    `,

    Row: styled(Row)`
        max-width: 120px;
        border: 1px blue solid;
    `,

    Column: styled.div`
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: ${MobileMaxWidth}px) {
            margin-left: 0;
        }
    `,

    Label: styled.span`
        font-family: 'Inter';
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #111111;
        margin-bottom: 5px;
    `,
};

export default RecursosPageStyled;
