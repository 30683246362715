export function getMemetypeFromName(filename: string): string {
    filename = filename.toLowerCase();

    if (filename.endsWith('.txt')) {
        return 'text/plain';
    } else if (filename.endsWith('.pdf')) {
        return 'application/pdf';
    } else if (filename.endsWith('.doc')) {
        return 'application/msword';
    } else if (filename.endsWith('.docx')) {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (filename.endsWith('.odt')) {
        return 'application/vnd.oasis.opendocument.text';
    }

    return '';
}
