import { ResizeMediumWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const ShareBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
    width: 140px;
    height: 98px;
`;

export const ShareLink = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    height: 37px;
    width: 37px;
    align-items: center;

    &:hover{
      border-radius: 50%;
      background: #F3F3F3;
    }
`;

export const IconShareSvg = styled.img`
    height: 25px;
    width: 25px;
    cursor: pointer;
`;

export const IconSvg = styled.img`
    height: 17px;
    width: 17px;
    cursor: pointer;
`;

export const ShareButton = styled.button`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #003c78;
    border-radius: 6px;
    width: 29px;
    height: 29px;
    &:hover {
        border: none;
        background: #003c78;
        img {
          filter: invert(1) sepia(1) saturate(0);
        }
    }

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        border: none;
    }
`;
