import AdminCursoLivre from 'models/admin/AdminCursoLivre';
import { GenericService } from './Generic.service';

export class RealizandoEscolhasService extends GenericService {
    readonly URI: string = '/v1/realizandoEscolhas';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarCadernoGestao64Encoded() {
        return this.get('download/caderno-gestao');
    }

    public buscarModeloPedagogicoBase64Encoded() {
        return this.get('download/modelo-pedagogico');
    }

    public buscarOrientacoesDeUsoBase64Encoded() {
        return this.get('download/orientacoes-uso');
    }

    public registrarAcesso(conteudo: number) {
        return this.post('log-acesso', { codigoTipoConteudo: conteudo });
    }
}
