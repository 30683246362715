import { useKeycloak } from '@react-keycloak/web';
import { KeycloakLoginOptions } from 'keycloak-js';
import { useCallback, useEffect, useState } from 'react';

const useLogged = () => {
    const { keycloak } = useKeycloak();
    const [auth, setAuth] = useState<boolean>(false);

    useEffect(() => {
        setAuth(keycloak.authenticated ?? false);
    }, []);

    const login = async (redirectPath: string, params?: string) => {
        let redirectUri = window.location.origin + '/login?' + redirectPath;

        if (params) redirectUri += '&' + params;

        const options: KeycloakLoginOptions = {
            redirectUri,
        };

        await keycloak?.login(options);
    };

    return { authenticated: auth, login };
};

export default useLogged;
