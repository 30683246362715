import React, { useState, useContext } from 'react';
import { useAppSelector } from 'hooks/LocalReduxThunk';

import { Preferences } from 'constants/Preferences';

import BannerInfo from './home/components/BannerInfo/BannerInfo';
import HeadInfo from './home/components/HeadInfo/HeadInfo';
import TileGrid from './home/components/TileGrid';

import Dialog from './home/components/Dialog/Dialog';
import alertIcon from '../../images/profile/alert-icon.svg';
import infoIcon from '../../images/info-icon.svg';
import DialogSlider from './home/components/DialogSlider/DialogSlider';

import { PreferenciasService } from 'core/http/service/Preferencias.service';
import PrefenciaUsuario from 'models/PreferenciaUsuario';

import { PainelSocioContext } from './home/context/PainelSocioContext';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useEffect } from 'react';
import fetchUserInfo from './home/utils/fetchUserInfo';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import { Container, HeaderContainer } from './PainelSocioemocionalHeader.styled';
import Dashboard from './home/components/Dashboard/Dashboard';
import { ModalSEEnum } from 'enums/ModalSEEnum';
import { MobileMaxWidth } from 'constants/Dimensions';

import Styled from './PainelSocioemocional.styled';
import { FiHome } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Home } from 'components/GoogleTagManager';

export interface IHomeSocioemocional {}

interface IHeaderSE {
    handle: () => void;
}

const HeaderSE = ({ handle }: IHeaderSE) => {
    return (
        <Styled.Container>
            <FiHome onClick={handle} size={25} />
            <Styled.Title>Jornada Socioemocional de Professores</Styled.Title>
        </Styled.Container>
    );
};

const HomeSocioemocional = ({}: IHomeSocioemocional) => {
    const { showAlertDialog, showInfoDialog, hideAllDialogs, showSliderDialog, showBannerInfo, firstCloseBanner } = useContext(
        PainelSocioContext.Context
    );

    const history = useHistory();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const service = new PreferenciasService();

    const submeterPreferencia = async () => {
        const preferencia = new PrefenciaUsuario();
        preferencia.chave = Preferences.DialogSlider.chave;
        preferencia.valor = Preferences.DialogSlider.valor.naoExibir;

        if (profile) {
            try {
                await service.setPreferencia(preferencia, profile.codigo);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchUserInfo(userProfile?.username ?? '', userProfile?.codigo || -1).then((perfilData: ProfileWithAvatar) => {
            setProfile(perfilData);
        });
    }, []);

    const goToHome = () => {
        GTM_Page_Home('painel_socioemocional_home_mobile', 'callback_home_humane');
        history.push('/home');
    };

    return (
        <>
            <Dialog
                show={showAlertDialog && !showInfoDialog}
                title="Deseja fechar as instruções da plataforma?"
                textButton="Fechar"
                isInfo={false}
                icon={alertIcon}
                onHide={() => {
                    if (!loadingSubmit) hideAllDialogs();
                }}
                loading={loadingSubmit}
                buttonCallback={submeterPreferencia}
                cancelable={true}
            />

            <Dialog
                show={showInfoDialog && !showAlertDialog}
                title="Caso deseje ver novamente as instruções, vá até o Menu > Tutorial"
                icon={infoIcon}
                textButton="OK"
                isInfo={true}
                buttonCallback={hideAllDialogs}
                onHide={hideAllDialogs}
            />

            <DialogSlider data={ModalSEEnum.HOME} show={showSliderDialog && !firstCloseBanner} onClose={() => hideAllDialogs()} />

            <Container>
                <HeaderContainer>{isMobile ? <HeaderSE handle={goToHome} /> : <HeadInfo profile={profile} />}</HeaderContainer>
                {showBannerInfo ? <BannerInfo /> : ''}
                <TileGrid />
            </Container>
        </>
    );
};

export default HomeSocioemocional;
