import React, { useEffect, useState } from 'react';

import { ArrayHelp } from '../../../../core/util/ArrayHelp';
import CustomSelect from '../../../../components/UI/customSelect/CustomSelect';
import SelectionItem from '../../../../components/UI/customSelect/SelectionItem';

interface IFormikCreatableInput {
    formik: any;
    name: string;
    size?: number;
    options: any[];
    otherField: string;
    onChangeHandler: any;
    placeholder?: string;
    maxInputLength?: number;
}

const FormikCreatableInput = ({
    formik,
    onChangeHandler,
    name,
    otherField,
    options,
    size,
    placeholder,
    maxInputLength,
    ...props
}: IFormikCreatableInput) => {
    const [selectOptions, setSelectOptions] = useState<SelectionItem[] | []>([]);
    const [value, setValue] = useState<SelectionItem>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let initialArray: SelectionItem[] = options.map(item => {
            const itemToAdd: SelectionItem = new SelectionItem().fromJSON({
                id: item.id,
                nome: item.descricao,
            });
            return itemToAdd;
        });

        if (formik.values[name] && formik.values[name] === 99) {
            const item: SelectionItem = new SelectionItem().fromJSON({
                id: 99,
                nome: formik.values[otherField],
            });
            initialArray.push(item);
        }

        setSelectOptions(initialArray);

        if (formik.values[name]) {
            const item: SelectionItem = ArrayHelp.ensure(
                initialArray.find((obj: SelectionItem) => {
                    return obj.id === formik.values[name];
                })
            );

            setValue(item);
        }
        setIsLoading(false);
    }, []);

    const OnChangeHandler = async (selectedItem: SelectionItem) => {
        setValue(selectedItem);
        if (selectedItem.id === 99) {
            await formik.setFieldValue([otherField], selectedItem.nome);
        }
        await formik.setFieldValue([name], selectedItem.id);
        formik.handleChange(name);
        await onChangeHandler(selectedItem.id);
    };

    return (
        <>
            {!isLoading && (
                <CustomSelect value={value} options={selectOptions} OnChangeHandler={OnChangeHandler} isCreatable={true}></CustomSelect>
            )}
        </>
    );
};

export default FormikCreatableInput;
