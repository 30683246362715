import React, { FunctionComponent, useEffect, useState } from 'react';

import { Formik } from 'formik';

import Dot from '../../../../components/UI/Dot';
import Input from '../../../../components/UI/Input';
import Title from '../../../../components/UI/Title';
import Header from '../../UI/header/Header';
import { Container, GoToTopContainer, QuadroDestaque, QuestionContainer, QuestionHolder, TitleContainer } from './Macrocompetencia.styled';
import { checkMetasPreenchidas, convertToAcaoMeta, convertToMetaType, formikInitial, getOrdinal, IValues, yupValidation } from './utils';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import { GradientButton } from '../../../../components/UI/Buttons';
import Metas, { MetaComponentType } from './Metas';
import RegistroMacrocompetencia from '../../../../models/pdp/RegistroMacrocompetencia';
import GotoTop from '../../../../components/UI/GotoTop';
import { useDispatch } from 'react-redux';
import * as pdpActions from '../../../../store/actions/Pdp';
import PDP from '../../../../models/pdp/PDP';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

interface IMacrocompetencia {
    ordem: number;
    title?: string;
}

const MacrocompetenciaComponent: FunctionComponent<IMacrocompetencia> = ({ ordem }: IMacrocompetencia) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [metasCurtoPrazo, setMetasCurtoPrazo] = useState<MetaComponentType[]>([]);
    const [metasMedioPrazo, setMetasMedioPrazo] = useState<MetaComponentType[]>([]);
    const [metasLongoPrazo, setMetasLongoPrazo] = useState<MetaComponentType[]>([]);
    const [metasPrazo, setMetasPrazo] = useState<MetaComponentType[]>([]);

    const dispatch = useDispatch();
    const pdp: PDP = useAppSelector(state => state.pdpReducer.pdp);
    const currentStep = useAppSelector(state => state.pdpReducer.currentStepForm);

    const registroMacro: RegistroMacrocompetencia | undefined = useAppSelector(state => {
        return state.pdpReducer.pdp.listaMacrocompetencias.find(m => {
            return m.ordem == ordem;
        });
    });

    const minimoMetas = (): boolean => {
        const isMetasPreenchidas = checkMetasPreenchidas(metasPrazo);

        const MIN_LENGTH = 1;
        const metaPrazo = convertToAcaoMeta(metasPrazo, 'prazo');

        const metasVerifed = metaPrazo.length >= MIN_LENGTH;

        return metasVerifed && isMetasPreenchidas;
    };

    const onSubmitHandler = (values: IValues) => {
        updateState(values, () => {
            dispatch(pdpActions.setCurrentFormStep(currentStep + 1));
            window.scrollTo(0, 0);
        });
    };

    const updateState = async (values: IValues, callcack: Function) => {
        if (registroMacro) {
            registroMacro.quemOInspira = values.quemOInspira;
            registroMacro.motivoDesenvolvimento = values.motivoDesenvolvimento;
            registroMacro.objetivoDesenvolvimento = values.objetivoDesenvolvimento;
            registroMacro.listaAcoesMetasCurtoPrazo = convertToAcaoMeta(metasCurtoPrazo, 'curto');
            registroMacro.listaAcoesMetasMedioPrazo = convertToAcaoMeta(metasMedioPrazo, 'medio');
            registroMacro.listaAcoesMetasLongoPrazo = convertToAcaoMeta(metasLongoPrazo, 'longo');
            registroMacro.listaAcoesMetasPrazo = convertToAcaoMeta(metasPrazo, 'prazo');

            await dispatch(pdpActions.storePDP(pdp));
            callcack();
        }
    };

    useEffect(() => {
        if (registroMacro) {
            formikInitial.motivoDesenvolvimento = registroMacro.motivoDesenvolvimento;
            formikInitial.objetivoDesenvolvimento = registroMacro.objetivoDesenvolvimento;
            formikInitial.quemOInspira = registroMacro.quemOInspira;

            //Paliativo técnico 😂
            setTimeout(() => {
                setMetasCurtoPrazo(convertToMetaType(registroMacro?.listaAcoesMetasCurtoPrazo));
                setMetasMedioPrazo(convertToMetaType(registroMacro?.listaAcoesMetasMedioPrazo));
                setMetasLongoPrazo(convertToMetaType(registroMacro?.listaAcoesMetasLongoPrazo));
                setMetasPrazo(convertToMetaType(registroMacro?.listaAcoesMetasPrazo));
                setShowForm(true);
            }, 100);
        } else {
            formikInitial.motivoDesenvolvimento = formikInitial.objetivoDesenvolvimento = formikInitial.quemOInspira = '';
            setShowForm(true);
        }
    }, []);

    return (
        <Container>
            <Header
                title={`Reflita sobre sua Macrocompetência ${ordem}`}
                subtitle={`Com o resultado da sua autoavaliação socioemocional em mãos, utilize as questões a seguir para refletir sobre suas competências socioemocionais e elaborar os seus objetivos de desenvolvimento.`}
            />

            <TitleContainer>
                {/* <Dot color={'#01C509'} /> */}
                <Title size={'medium'} style={{ marginLeft: '20px' }}>
                    Macrocompetência {ordem}: {registroMacro?.macrocompetencia?.descricao}
                </Title>
            </TitleContainer>

            {showForm && (
                <Formik initialValues={formikInitial} onSubmit={onSubmitHandler} validationSchema={yupValidation}>
                    {formik => {
                        return (
                            <>
                                <QuestionContainer>
                                    <QuestionHolder
                                        numberValue={1}
                                        question={`Sobre a ${getOrdinal(ordem)} macrocompetência que elencou, destaque em uma frase por que
                                                pensa que precisa desenvolvê-la ainda mais?`}
                                    />
                                    <Input
                                        value={formik.values.motivoDesenvolvimento}
                                        onchange={e => {
                                            formik.setFieldValue('motivoDesenvolvimento', e.target.value);
                                            updateState(formik.values, () => {});
                                        }}
                                    />
                                </QuestionContainer>

                                <QuestionContainer>
                                    <QuestionHolder
                                        numberValue={2}
                                        question={`Qual seu objetivo de desenvolvimento para a macrocompetência socioemocional?`}
                                    />
                                    <Input
                                        value={formik.values.objetivoDesenvolvimento}
                                        onchange={e => {
                                            formik.setFieldValue('objetivoDesenvolvimento', e.target.value);
                                            updateState(formik.values, () => {});
                                        }}
                                    />
                                </QuestionContainer>

                                <QuestionContainer>
                                    <QuestionHolder
                                        numberValue={3}
                                        question={`Como você pode ver na jornada formativa desta plataforma, o desenvolvimento de competências socioemocionais ocorre de forma processual,
                                      reflexiva e incremental. portanto, pensar o processo é tão importante quanto pensar o resultado esperado.
                                      para alcançar o objetivo que registrou acima, liste, ações que você pode fazer no curto, médio e longo prazos:`}
                                    />
                                    <QuadroDestaque>
                                        <strong>DICA:</strong> você pode pensar em práticas do seu fazer pedagógico, oportunidades de colaboração
                                        e apoio e estudos que pode realizar, por exemplo.
                                    </QuadroDestaque>

                                    <Title size={'medium'}>Ações e Metas</Title>

                                    <Metas prazo={'prazo'} metas={metasPrazo} setMetas={setMetasPrazo} />
                                </QuestionContainer>

                                <QuestionContainer>
                                    <QuestionHolder
                                        numberValue={4}
                                        question={`O que ou quem o inspira para conseguir atingir as metas listadas? Com quem pode contar para atingi-las? Com quem pode trocar conhecimentos e experiências?`}
                                    />
                                    <Input
                                        value={formik.values.quemOInspira}
                                        onchange={e => {
                                            formik.setFieldValue('quemOInspira', e.target.value);
                                            updateState(formik.values, () => {});
                                        }}
                                    />
                                </QuestionContainer>

                                <ContinuarButton
                                    isDisabled={!(formik.isValid && minimoMetas()) || formik.isSubmitting}
                                    onClickHandler={formik.handleSubmit}
                                />
                            </>
                        );
                    }}
                </Formik>
            )}

            <GoToTopContainer>
                <GotoTop />
            </GoToTopContainer>
        </Container>
    );
};

interface IContinuarButton {
    onClickHandler: Function;
    isDisabled: boolean;
}

const ContinuarButton = ({ onClickHandler, isDisabled }: IContinuarButton) => {
    return (
        <GradientButton
            type="submit"
            disabled={isDisabled}
            onClick={() => {
                GTM_Page_Jornada_Desenvolvimento('painel_pdp_macrocompetencia', 'continuar_btn');
                onClickHandler();
            }}
        >
            Continuar
        </GradientButton>
    );
};

export default MacrocompetenciaComponent;
