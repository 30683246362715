import Select from 'components/Select';
import { ToastSuccess, ToastError } from 'components/Toaster';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import BiblioItemCategoria from 'models/biblioteca/BiblioItemCategoriaModel';
import React, { useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { MdOutlineEditOff } from 'react-icons/md';

import { exclusiveOptions } from './AdminBiblioCategorias';
import Styled from './AdminBiblioCategorias.styled';

export interface IAdminBiblioCategoriasInput {
    comportamentos: string[];
    onSubmit: (novo: BiblioItemCategoria) => void;
    onCancel: () => void;
}

const AdminBiblioCategoriasInput = ({ onSubmit, onCancel, comportamentos }: IAdminBiblioCategoriasInput) => {
    const [categoria, setCategoria] = useState('');
    const [rota, setRota] = useState('');
    const [comportamento, setComportamento] = useState('livro');
    const [perfilExclusivo, setperfilExclusivo] = useState<string>(String(exclusiveOptions[0].value));
    const service = new BiblioConteudoService();

    const onSelectHandler = (option: any) => {
        setComportamento(option.value);
    };

    const submit = async () => {
        if (categoria.trim().length == 0 || comportamento.trim().length == 0 || rota.trim().length == 0) {
            ToastError('Dados de categoria inválidos, verifique e tente novamente');
            return;
        }

        const { data, status } = await service.criarCategoria(categoria, comportamento, rota.replace(/^\//g, ''), perfilExclusivo ?? null);

        if (status == 200) {
            ToastSuccess('Item Criado!');
            const novaCategoria = new BiblioItemCategoria().fromJSON(data);
            onSubmit(novaCategoria);
        } else {
            ToastError('Houve um erro ao criar');
        }
    };

    return (
        <Styled.ContainerInput>
            <hr />
            <Styled.ColumnGrid>
                <Styled.Input
                    placeholder="Nova categoria"
                    value={categoria}
                    onChange={evt => {
                        setCategoria(evt.target.value);
                    }}
                />

                <Styled.Input
                    placeholder="Rota da categoria"
                    value={rota}
                    onChange={evt => {
                        setRota(evt.target.value);
                    }}
                />

                <div style={{ transform: 'translateY(5px)' }}>
                    <Select
                        itens={comportamentos.map(c => {
                            return { value: c, label: c };
                        })}
                        value={{ value: comportamento, label: comportamento }}
                        onSelected={onSelectHandler}
                        isSmall={true}
                    />
                </div>

                <span></span>
                <span></span>

                <Styled.Center>
                    <Select
                        width={'175px'}
                        isSmall
                        itens={exclusiveOptions}
                        defaultValue={exclusiveOptions[0]}
                        onSelected={o => setperfilExclusivo(String(o.value))}
                    />
                </Styled.Center>

                <div>
                    <Styled.Center>
                        <Styled.ButtonIcon onClick={onCancel} title="Cancelar Criação">
                            <MdOutlineEditOff />
                        </Styled.ButtonIcon>

                        <Styled.ButtonIcon onClick={submit} title="Salvar">
                            <FiSave />
                        </Styled.ButtonIcon>
                    </Styled.Center>
                </div>
            </Styled.ColumnGrid>
        </Styled.ContainerInput>
    );
};

export default AdminBiblioCategoriasInput;
