import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminPaginaIntermediariaResumida extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonName('link')
    @jsonProperty(String, null)
    link: string = '';

    @jsonName('nome')
    @jsonProperty(String, null)
    nome: string = '';

    @jsonName('tipoTemplate')
    @jsonProperty(Number, null)
    tipoTemplate: number | null = null;

    @jsonName('categoriaBusca')
    @jsonProperty(Number, null)
    categoriaBusca: number | null = null;

    @jsonName('descricao')
    @jsonProperty(String, null)
    descricao: string = '';
}

export class AdminPaginaIntermediariaSecaoResumida extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    idModelo: number = -1;

    @jsonProperty(Number)
    idPagina: number = -1;

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(Boolean)
    oculto: boolean = false;

    @jsonProperty(Number)
    idSecao: number = -1;
}

export default AdminPaginaIntermediariaResumida;
