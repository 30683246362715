import styled from 'styled-components';
import CommonStyled from 'pages/biblioteca/subpages/itemPage/categoriasItem/CommonStyled';

const BibioFavoritesStyled = {
    Container: styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    `,

    CollectionContainer: styled.div`
        border: 1px solid rgba(181, 181, 181, 0.5);
        border-radius: 6px;
        padding: 10px;
        min-width: 360px;
        margin: 20px 0;
    `,

    HeadContainer: styled.div`
        padding: 0 10px;
        padding-top: 20px;
        max-width: 330px;
        word-break: break-all;
    `,

    Title: styled.p`
        width: 90%;
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1e4e7e;
        margin-bottom: 7px;
        word-wrap: break-word;
    `,

    SubTitle: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #2e2e2e;
    `,

    Content: styled.div`
        border-top: 1px solid rgba(181, 181, 181, 0.5);
        border-bottom: 1px solid rgba(181, 181, 181, 0.5);
        padding: 10px 7px;
        margin-top: 15px;

        & > div {
            margin-bottom: 20px 0;
        }
    `,

    BottomContainer: styled.div`
        text-align: center;
        margin-top: 20px;
    `,

    ActionButton: CommonStyled.ActionButton,
};

export default BibioFavoritesStyled;
