import React from 'react';
import Title from 'components/UI/Title';
import { Content, PrivacyPolicyContainer } from './PrivacyPolicy.styled';

export interface IPrivacyPolicy {}

const PrivacyPolicy = ({}: IPrivacyPolicy) => {
    const LINK_IAS: string = 'https://www.institutoayrtonsenna.org.br/';
    const LINK_BRIGHTSPACE: string = 'https://ias.brightspace.com/';

    const SUPPORT_CHROME: string = 'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR';
    const SUPPORT_FIREFOX: string = 'https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s';
    const SUPPORT_SAFARI: string = 'https://support.apple.com/pt-br/guide/safari/sfri11471/mac';
    const SUPPORT_EXPLORER: string = 'https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies';
    const SUPPORT_EDGE: string = 'https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies';
    const SUPPORT_OPERA: string = 'https://help.opera.com/en/latest/web-preferences/#cookies';

    const ENCARREGADO_EMAIL: string = 'encarregado@ias.org.br';

    return (
        <PrivacyPolicyContainer>
            <Title style={{ color: '#003C78' }} size={'large'}>
                POLÍTICA DE PRIVACIDADE
            </Title>
            <Content>
                <p>
                    O Instituto Ayrton Senna (“IAS”) há mais de 25 anos procura desenvolver o potencial de crianças e jovens no Brasil, tendo
                    eleito a educação integral como motriz desse desenvolvimento. É com prazer que recebemos você no nosso site institucional e
                    nas nossas plataformas para compartilhar o conteúdo e o conhecimento desenvolvidos pela nossa equipe ao longo de nossa
                    história.
                </p>
                <p>
                    Para que a sua experiência no site institucional e plataformas administradas pelo IAS seja a melhor possível, o IAS apresenta
                    a sua POLÍTICA DE PRIVACIDADE (“Política”), documento que tem por objetivo esclarecer quais os termos e condições que guiam
                    as nossas atividades de tratamento de seus dados pessoais.
                </p>
                <p>
                    Este documento está dividido em doze partes: (A) introdução; (B) glossário; (C) coleta de dados pessoais; (D) uso dos dados
                    pessoais; (E) compartilhamento de dados pessoais; (F) armazenamento de dados pessoais; (G) direitos dos titulares de dados
                    pessoais; (H) registro de atividades; (I) responsabilidades; (J) transferência internacional de dados pessoais; (K) medidas
                    de segurança; e (L) disposições gerais.
                </p>
                <p>
                    <br />
                </p>
                <ol>
                    <li>
                        <h1>A. Introdução</h1>
                        <br />
                        <p>
                            A presente Política foi editada em atenção à legislação aplicável, qual seja, o Marco Civil da Internet (Lei nº
                            12.965/2014) e a Lei Geral de Proteção de Dados Pessoais ou “LGPD” (Lei nº 13.709/2018), e está pautada no nosso
                            compromisso necessário de transparência junto aos nossos apoiadores, usuários e seguidores.
                        </p>
                        <p>
                            As diretrizes contidas nesta Política apresentam a visão do IAS sobre privacidade e proteção de dados pessoais,
                            tratados no âmbito das atividades sociais do IAS, inclusive, mas sem se limitar com relação às atividades
                            desenvolvidas e relacionadas ao site institucional e demais plataformas administradas pelo IAS. Outros sites que
                            possam ser acessados por meio de links disponibilizados no site institucional e/ou pelas plataformas, podem ter
                            políticas de privacidade diferentes desta, e, portanto, sujeitos a outras regras e condições.
                        </p>
                        <br />
                        <br />
                    </li>
                    <li>
                        <h1>B. Glossário</h1>
                        <br />
                        <br />
                        <p>
                            <strong>Cadastro:</strong> é o registro feito no site institucional pelo usuário de assinatura do Newsletter do IAS
                            e/ou nas Plataformas do IAS e por meio do qual o IAS é autorizado a enviar comunicados ao usuário;
                        </p>
                        <p>
                            <strong>Conta de Usuário:</strong> registro e identificação do usuário, para a qual são definidos login e senha que
                            permitem o acesso à área logada das plataformas do IAS;
                        </p>
                        <p>
                            <strong>Cookies:</strong> são pequenos arquivos com dados que os sites solicitam que os navegadores armazenem,
                            permitindo que se identifique o seu dispositivo de acesso. Esses arquivos são usados, por exemplo, para protocolos de
                            segurança, ativação de funcionalidades e customização de navegação;
                        </p>
                        <p>
                            <strong>Dados Anonimizados:</strong> são dados que passaram por uma série de tratamentos de modo que não podem ser
                            mais associados direta ou indiretamente a uma pessoa, considerando os meios técnicos disponíveis e razoáveis;
                        </p>
                        <p>
                            <strong>Dados Pessoais:</strong> são informações relacionadas à pessoa natural identificada ou identificável,
                            incluindo, mas sem se limitar a, nome, e-mail, documento de identificação, data de nascimento, endereço IP,
                            geolocalização, dados sensíveis etc.;
                        </p>
                        <p>
                            <strong>Dados Sensíveis:</strong> são Dados Pessoais referentes à origem racial e étnica, convicção religiosa,
                            opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente
                            à saúde ou à vida sexual, dado genético ou biométrico;
                        </p>
                        <p>
                            <strong>Encarregado (DPO):</strong> pessoa, física ou jurídica, indicada pelo IAS como responsável pelo canal de
                            comunicação entre o IAS, você e as autoridades governamentais em relação à presente Política e ao uso, coleta e
                            tratamento de dados pessoais pelo IAS;
                        </p>
                        <p>
                            <strong>IAS:</strong> é o Instituto Ayrton Senna, associação de caráter filantrópico e responsável pela administração
                            do site institucional, cujo endereço principal é, atualmente,{' '}
                            <a href={LINK_IAS} target="_blank" rel="noreferrer">
                                {LINK_IAS}
                            </a>
                            , e demais plataformas relacionadas às suas atividades sociais e pelo IAS administradas;
                        </p>
                        <p>
                            <strong>Logs:</strong> registros de atividades dos usuários ou visitantes efetuados a partir do acesso ao site
                            institucional e plataformas administrados pelo IAS;
                        </p>
                        <p>
                            <strong>Newsletter:</strong> ação oferecida pelo IAS no seu site institucional, de envio de boletim informativo ao
                            usuário cadastrado com informações relacionadas, diretamente ou não, às atividades do IAS;
                        </p>
                        <p>
                            <strong>Plataformas:</strong> são plataformas digitais, administradas pelo IAS, destinadas, entre outros, ao
                            oferecimento de experiências de aprendizagem digitais, como cursos e certificações na área da educação, bem como de
                            sistemas de avaliação e acompanhamento de soluções educacionais, como instrumentos de avaliação socioemocional; e,
                        </p>
                        <p>
                            <strong>Titular dos dados:</strong> é a pessoa natural a quem se referem os Dados Pessoais e/ou Dados Sensíveis. Por
                            exemplo, você Fulano envia um e-mail ao IAS: o seu e-mail, fulano@exemplo123.br, é um Dado Pessoal e Fulano, é o
                            Titular desse Dado Pessoal;
                        </p>
                        <p>
                            <strong>Usuário:</strong> Todas as pessoas físicas que utilizam o site e as plataformas do IAS.
                        </p>
                        <br />
                    </li>
                    <li>
                        <h1>C. Coleta de dados pessoais</h1>
                        <br />
                        <p>Os dados pessoais coletados pelo IAS são:</p>
                        <p>
                            &#8226; Dados pessoais informados por você – dados cadastrais e de contato, como nome, documento de identificação,
                            endereço, data de nascimento, gênero e e-mail, assim como dados socioeconômicos e dados de ensino;
                        </p>
                        <p>
                            &#8226; Dados sensíveis informados por você - (i) considerando seu vínculo com o IAS, de acordo com a base legal
                            aplicável; e/ou (ii) na utilização de ferramentas avaliativas disponibilizadas pelas plataformas do IAS, como dados
                            referentes à saúde e dados psicométricos;
                        </p>
                        <p>
                            &#8226; Dados de navegação – cookies, endereço IP, interações realizadas e perfil de uso do site, das plataformas e
                            de suas respectivas funcionalidades, e dados técnicos, como informações de URL, de conexão de rede, do provedor; e
                        </p>
                        <p>
                            &#8226; Dados do dispositivo – atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo, e
                            dados de geolocalização do dispositivo caso autorizada a coleta pelo visitante/usuário.
                        </p>
                        <br />
                        <p>
                            O IAS pode receber dados pessoais de terceiros, pessoas físicas, empresas e instituições com as quais mantém
                            relacionamento, desde que esses terceiros tenham obtido o consentimento prévio do titular ou que a transferência de
                            dados esteja amparada por uma das bases legais previstas na LGPD. O IAS compromete-se a informar os titulares sobre a
                            origem dos seus dados e as finalidades do tratamento, assegurando o exercício de seus direitos conforme a legislação
                            vigente.
                        </p>
                        <br />
                        <ul>
                            <li>
                                <h1>D. USO DE DADOS PESSOAIS</h1>
                                <p>
                                    Os dados pessoais coletados e recebidos nas formas indicadas acima serão utilizados pelo IAS no âmbito de
                                    suas atividades e objetivos sociais, observando os princípios da finalidade e da necessidade previstos na
                                    LGPD. O tratamento de seus dados pessoais poderá incluir operações como coleta, produção, recepção,
                                    classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento,
                                    armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência,
                                    difusão ou extração, sempre vinculadas a finalidades específicas e legítimas, conforme indicadas na presente
                                    Política e com base nas permissões legais aplicáveis. O IAS garante que o tratamento dos dados será feito com
                                    transparência, observando as condições previstas nesta Política e nas bases legais previstas na LGPD.
                                </p>
                                <br />
                                <h1>Os usos de dados pessoais pelo IAS são:</h1>
                                <br />
                                <br />
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Dados Pessoais – </h1>
                                    <span>
                                        na execução de ações e atividades oferecidas no site e plataformas, contato para envio de Newsletter e
                                        outras comunicações do IAS, inclusive para identificação, autenticação e verificação de cadastro e/ou
                                        conta, aprimoramento dos conteúdos e das atividades do IAS, geração de estatísticas, estudos, pesquisas e
                                        levantamentos pertinentes às atividades das plataformas, suas funcionalidades e ações oferecidas pelo IAS
                                        atendimento de solicitações e dúvidas, prevenção e resolução de problemas técnicos ou de segurança,
                                        exercício regular de direitos do IAS, colaboração ou cumprimento de ordem judicial ou administrativa, de
                                        autoridade competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou regulatória;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Dados sensíveis – </h1>
                                    <span>
                                        na execução de ações e atividades oferecidas nas plataformas, aprimoramento dos conteúdos e das
                                        atividades do IAS, geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
                                        das plataformas, suas funcionalidades e ações oferecidas pelo IAS, exercício regular de direitos do IAS,
                                        colaboração ou cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão
                                        fiscalizador, e cumprimento de obrigação legal ou regulatória; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1> &#8226; Dados de navegação e do dispositivo – </h1>
                                    <span>
                                        na execução de ações e atividades oferecidas no site e plataformas, aprimoramento dos conteúdos e das
                                        atividades do IAS, geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
                                        e comportamento no uso do site e plataformas, suas funcionalidades e ações oferecidas pelo IAS, prevenção
                                        e resolução de problemas técnicos ou de segurança, exercício regular de direitos do IAS, colaboração ou
                                        cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão fiscalizador, e
                                        cumprimento de obrigação legal ou regulatória.
                                    </span>
                                    <br />
                                    <br />
                                    <span>
                                        Destaca-se que dados pessoais eventualmente informados diretamente por menores de 12 (doze) anos de idade
                                        ao IAS, por meio de autocadastro nas plataformas, serão utilizados apenas quando o tratamento for
                                        autorizado e validado de forma explícita por pelo menos um dos pais ou pelo responsável legal ou com base
                                        no legítimo interesse, ou nos casos de geração de estatísticas, estudos, pesquisas e levantamentos
                                        pertinentes às atividades sociais do IAS, sempre respeitando o melhor interesse do menor e, quando
                                        possível, realizando a anonimização dos dados para essas finalidades.
                                    </span>
                                </p>
                            </li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        <h1>E. COMPARTILHAMENTO DE DADOS PESSOAIS</h1>
                        <br />
                        <p>
                            Os dados pessoais coletados pelo IAS poderão ser compartilhados com empresas e terceiros colaboradores, parceiros
                            e/ou apoiadores, prestadores de serviço, bem como com autoridades públicas e órgãos reguladores, sempre que
                            necessário e, também, nas hipóteses listadas abaixo.
                        </p>
                        <p>
                            De acordo com os termos desta Política, os dados pessoais serão compartilhados pelo IAS em cumprimento aos seus
                            objetivos e atividades sociais, com uma base legal válida, e em atenção aos termos e condições das normativas e
                            regramentos aplicáveis, inclusive, sem se limitar, os termos desta Política.
                        </p>
                        <br />
                        <h1>O compartilhamento de dados pessoais pelo IAS pode ocorrer com:</h1>
                        <br />
                        <br />
                        <ul>
                            <li>
                                <p>
                                    <h1>Parceiros e/ou apoiadoras – </h1>
                                    <span>
                                        empresas e instituições com as quais o IAS tenha relacionamento em razão dos seus objetivos e atividades
                                        sociais (incluindo, sem se limitar, a Ayrton Senna Empreendimentos Ltda. e a L&V Licensing Ltda.), para
                                        fins de execução de ações e atividades oferecidas no site e plataformas, colaboração no envio de
                                        Newsletter e outras comunicações do IAS, inclusive para envio de notificações, envio de comunicações de
                                        natureza comercial, relacionadas aos parceiros e/ou apoiadores institucionais do IAS, aprimoramento dos
                                        conteúdos e das atividades do IAS, geração de estatísticas, estudos, pesquisas e levantamentos
                                        pertinentes às atividades e comportamento no uso do site e plataformas, suas funcionalidades e ações
                                        oferecidas pelo IAS, exercício regular de direitos do IAS, cumprimento de ordem judicial ou
                                        administrativa, de autoridade competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou
                                        regulatória;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Terceiros prestadores de serviços – </h1>
                                    <span>
                                        empresas que fornecem serviços de hospedagem, de tecnologia da informação, de comunicação, de serviços
                                        estatísticos e de consultoria especializada em dados, para fins de apoio técnico na disponibilização do
                                        site institucional, das plataformas e de funcionalidades/ações oferecidas pelo IAS, envio de Newsletter e
                                        outras comunicações do IAS, inclusive para envio de notificações e/ou comunicações, execução de ações e
                                        atividades oferecidas no site e plataformas, identificação, autenticação e verificação de cadastro e/ou
                                        conta, aprimoramento dos conteúdos e das atividades do IAS, atendimento de solicitações e dúvidas,
                                        geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades e comportamento no
                                        uso do site e plataformas, suas funcionalidades e ações oferecidas pelo IAS, exercício regular de
                                        direitos do IAS, cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão
                                        fiscalizador, e cumprimento de obrigação legal ou regulatória; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Autoridades públicas e órgãos reguladores – </h1>
                                    <span>
                                        entes fiscalizadores das atividades do IAS e do cumprimento das legislações aplicáveis, para fins de
                                        exercício regular de direitos do IAS, cumprimento de ordem judicial ou administrativa, de autoridade
                                        competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou regulatória.
                                    </span>
                                </p>
                                <p>
                                    <br />
                                    <span>
                                        <p>
                                            <strong>IMPORTANTE:</strong>
                                            <span style={{ fontStyle: 'italic' }}>
                                                O IAS compartilhará os seus dados pessoais dentro dos limites e propósito dos seus objetivos e
                                                atividades sociais, e em conformidade com os termos desta Política e da legislação aplicável. O
                                                compartilhamento respeitará, além disso, as melhores práticas de segurança e privacidade
                                                disponíveis para garantir a integridade e confidencialidade dos seus dados pessoais.
                                            </span>
                                        </p>
                                    </span>
                                </p>
                            </li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        <h1>F. ARMAZENAMENTO DE DADOS PESSOAIS</h1>
                        <br />
                        <p>
                            Os dados pessoais coletados no site institucional e nas plataformas administradas pelo IAS ou fornecidos diretamente
                            por você serão transferidos e armazenados em ambientes seguros e controlados pelo tempo necessário para cumprir com
                            as finalidades específicas para as quais foram coletados, observando os prazos legais aplicáveis. O IAS utilizará
                            servidores internos e/ou de terceiros prestadores de serviços, podendo ainda realizar o armazenamento por meio de
                            tecnologia de computação em nuvem e/ou outras tecnologias emergentes, em conformidade com as legislações aplicáveis e
                            garantindo a adoção de medidas técnicas e administrativas adequadas para proteger os dados pessoais contra acessos
                            não autorizados e incidentes de segurança, conforme o disposto na Lei Geral de Proteção de Dados (LGPD).
                        </p>
                        <p>
                            O IAS eliminará os dados pessoais quando cessar a finalidade para a qual foram coletados ou conforme exigido por lei,
                            podendo ainda excluir os dados pessoais conforme a sua conveniência. Não obstante, poderá manter os dados e o
                            histórico de registro após esse período para atender a obrigações legais ou regulatórias, auditorias, ou para o
                            exercício regular de direitos do IAS, pelo prazo que justifique a referida retenção.
                        </p>
                        <p>
                            Caso o titular solicite a exclusão de seus dados pessoais, o IAS atenderá à solicitação, exceto nos casos em que for
                            necessário manter os dados para cumprimento de obrigações legais ou regulatórias, para auditoria, ou para o exercício
                            regular de direitos, conforme previsto na LGPD. A eliminação dos dados não se aplicará a atividades passadas nas
                            quais o IAS tenha utilizado esses dados, e cópias residuais de suas informações pessoais poderão permanecer em
                            sistemas de backup por um período limitado., de acordo com nossas políticas de segurança.
                        </p>
                        <br />
                        <ul>
                            <li>
                                <h1> G. DIREITOS DOS TITULARES DE DADOS PESSOAIS </h1>
                                <p>
                                    Por meio do Contato com nosso <strong>Encarregado Antonio Carlos Rodrigues</strong>, utilizando-se do e-mail{' '}
                                    <a href="mailto:encarregado@ias.org.br"> encarregado@ias.org.br</a>, você poderá exercer os direitos
                                    garantidos aos titulares de dados pessoais pela legislação aplicável. Nesse sentido, garantindo maior
                                    transparência possível ao usuário, o IAS lista a seguir quais são esses direitos:
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1> Acesso aos dados – </h1>
                                    <span>permite que você solicite ao IAS acesso aos dados que armazena em relação a você;</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Correção dos dados – </h1>
                                    <span>
                                        permite que, em caso de dados incompletos, inexatos ou desatualizados, você solicite ao IAS a correção ou
                                        complementação dos dados pessoais;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Anonimização, Bloqueio ou Eliminação dos dados – </h1>
                                    <span>
                                        permite que você solicite (a) a anonimização dos dados, de forma a que eles não possam mais ser
                                        relacionados a você, (b) o bloqueio dos seus dados, suspendendo temporariamente o tratamento, ou (c) a
                                        eliminação dos seus dados, caso em que apagaremos todos os seus dados pessoais sem possibilidade de
                                        reversão, salvo os casos previstos em lei e conforme previsto nesta Política, explicitado no item F
                                        acima;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Confirmação de tratamento – </h1>
                                    <span>permite que você solicite ao IAS a confirmação do tratamento dos seus dados pessoais;</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Informações sobre compartilhamento – </h1>
                                    <span>
                                        permite que você solicite ao IAS confirmação sobre com quais terceiros seus dados pessoais foram
                                        compartilhados;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Informações sobre consentimento e revogação – </h1>
                                    <span>
                                        permite que você (a) tenha informações claras sobre a possibilidade e as consequências de não dar o seu
                                        consentimento, e (b) solicite ao IAS a revogação do seu consentimento em relação às atividades de
                                        tratamento que se baseiam no consentimento, sendo certo que você reconhece que a revogação não afetará a
                                        legalidade de qualquer tratamento realizado anteriormente pelo IAS e que a a revogação poderá te impedir
                                        de utilizar o site, as plataformas e as funcionalidades/ações oferecidas pelo IAS em sua plenitude, ou
                                        mesmo inviabilizar por completo referido uso;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Portabilidade de dados – </h1>
                                    <span>
                                        quando possível, e a partir da respectiva regulamentação pela Autoridade Nacional de Proteção de Dados
                                        (ANPD), permite que você solicite ao IAS a portabilidade dos seus dados; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>Oposição – </h1>
                                    <span>
                                        permite que você solicite a interrupção de determinado tratamento de dados pessoais realizado pelo IAS
                                        sem o seu consentimento.
                                    </span>
                                    <br />
                                    <span>
                                        Para o exercício dos direitos listados, acima, o IAS poderá solicitar a você comprovação da sua
                                        identidade, como uma medida de segurança. E, uma vez recebida a sua solicitação, o IAS terá o prazo de
                                        até 15 (quinze) dias para o envio de uma resposta, de acordo com a complexidade da solicitação. Os
                                        pedidos serão analisados conforme previsto em legislação vigente e, por motivos legítimos, algumas
                                        solicitações poderão não ser atendidas.
                                    </span>
                                    <br />
                                    <span>
                                        O IAS assegura que os Titulares recebam informações claras, precisas e de fácil entendimento sobre o
                                        Tratamento de seus Dados Pessoais, respeitados eventuais sigilos necessários às atividades sociais
                                        praticadas pelo IAS.
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <br />
                        <br />
                    </li>
                    <li>
                        <h1>H. REGISTRO DE ATIVIDADES</h1>
                        <br />
                        <p>
                            O IAS pode registrar as atividades que você realiza quando utiliza o site institucional e as plataformas, ou quando
                            acessa os comunicados e notificações enviados, criando, quando possível e aplicável, logs (registros de atividades
                            efetuadas) que conterão: o endereço IP, acesso e ações realizadas, data e hora de cada ação realizada e informações
                            sobre o dispositivo utilizado, tais como a versão de sistema operacional, navegador e geolocalização.
                        </p>
                        <p>
                            Além disso, sempre em respeito aos termos desta Política, o IAS utiliza ou poderá utilizar outras tecnologias,
                            próprias ou de terceiros, para o registro das suas atividades, conforme indicado a seguir:
                        </p>
                    </li>
                    <li>
                        <p>
                            <h1>Cookies - </h1>
                            <span>
                                o IAS utiliza cookies próprios e de terceiros para diversas funções, como possibilitar e facilitar a utilização
                                do site institucional e das plataformas, bem como de suas funcionalidades/ações, acompanhar as suas preferências,
                                informações relacionadas a suas atividades nos ambientes visitados, coletar informações para o envio de
                                comunicações e conteúdo mais personalizados, diminuir a ocorrência de falhas durante o acesso, mitigar o risco de
                                atividades fraudulentas, garantir uma navegação mais personalizável, oferecer recursos de segurança durante a
                                navegação e analisar de forma estatística as preferências gerais do conjunto de visitantes/usuários; e,
                            </span>
                        </p>
                        <p>
                            <h1>Ferramentas de analytics - </h1>
                            <span>
                                o IAS utiliza essas ferramentas para identificar os seus padrões de navegação no site e nas plataformas,
                                incluindo quais páginas e quando você visita tais páginas, e gera relatórios sobre essas atividades, para que
                                possamos melhorar o desenvolvimento das atividades pelo IAS.
                            </span>
                        </p>
                        <p>
                            <strong>IMPORTANTE:</strong>
                            <span style={{ fontStyle: 'italic' }}>
                                Caso não queira utilizar cookies, você poderá a qualquer momento bloquear o uso dos cookies ativando uma
                                configuração no seu navegador de internet, sendo que a capacidade desse bloqueio dependerá das configurações e
                                limitações do seu navegador. Em caso de bloqueio de cookies, o site institucional e as plataformas administradas
                                pelo IAS poderão não funcionar corretamente e as suas funcionalidades também poderão estar parcial ou totalmente
                                indisponíveis. Para administrar os seus cookies, recomendamos que clique nas configurações do seu navegador, e
                                confira as instruções relacionadas ao uso, bloqueio e exclusão de cookies.
                            </span>
                        </p>
                        <br />
                        <ul>
                            <h1>I- RESPONSABILIDADES</h1>
                            <br />
                            <p>
                                O usuário é exclusivamente responsável por todos e quaisquer atos ou omissões realizado e/ou dano causado a
                                partir de seu acesso à Internet, e/ou site e plataformas do IAS, bem como pelos conteúdos por você carregados,
                                enviados e/ou transmitidos ao site e plataformas do IAS.
                            </p>
                            <p>
                                Em nenhuma hipótese, o IAS será responsável por qualquer ato ou omissão realizado e/ou dano causado pelo
                                usuáriodecorrente do acesso ao site e plataformas do IAS tampouco pelo uso indevido do site e plataformas por
                                qualquer usuário ou terceiros e/ou pelos conteúdos carregados, enviados e/ou transmitidos ao site e plataformas
                                do IAS.
                            </p>
                            <p>
                                O usuário declara estar ciente de que todos e quaisquer conteúdos enviados e/ou transmitidos ao site e
                                plataformas por qualquer usuário e/ou terceiros não representam, de nenhuma forma, a opinião ou a visão do IAS.
                            </p>
                            <p>
                                Caso o usuário descumpra qualquer disposição desta Política de Privacidade ou disposição legal, sempre juízo de
                                outras medidas, o IAS poderá, por si ou por terceiros, a qualquer tempo, a seu exclusivo critério, sem
                                necessidade de qualquer aviso ou notificação prévia ou posterior a qualquer usuário ou a qualquer terceiro, sob
                                qualquer meio ou forma, sem prejuízo de iniciar as medidas legais cabíveis, suspender ou limitar o acesso ao site
                                e/ou plataformas, encerrar o acesso de qualquer usuário e/ou tomar outras providências que entender necessárias
                                para o cumprimento desta Política de Privacidade e para o bom funcionamento do Site e/ou plataforma, a qualquer
                                tempo.
                            </p>
                            <br />
                            <ul>
                                <h1>J. TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS</h1>
                                <li>
                                    <p>
                                        Como indicado nesta Política, o IAS utiliza servidores de terceiros contratados nos Estados Unidos da
                                        América, podendo armazenar seus dados pessoais em servidores de computação em nuvem localizados fora do
                                        Brasil. Nessas operações, o IAS adota todas as medidas técnicas e administrativas adequadas para garantir
                                        a proteção de seus dados pessoais, conforme as exigências da Lei Geral de Proteção de Dados (LGPD) e das
                                        normas emitidas pela Autoridade Nacional de Proteção de Dados (ANPD).
                                    </p>
                                    <p>
                                        Além disso, em alguns casos, o IAS poderá compartilhar seus dados pessoais com parceiros e/ou apoiadores
                                        localizados no exterior, exclusivamente para fins de apoio e colaboração na execução das ações e
                                        funcionalidades oferecidas nos sites e plataformas administrados pelo IAS.
                                    </p>
                                    <p>
                                        A transferência internacional de dados será realizada com base nas hipóteses legais previstas no art. 33
                                        da LGPD, incluindo cláusulas contratuais específicas ou cláusulaspadrão contratuais aprovadas pela ANPD,
                                        quando aplicável, garantindo que o nível de proteção de dados seja equivalente ao exigido pela legislação
                                        brasileira. Nesses casos, o IAS assegura que os terceiros com quem compartilha os dados também adotem as
                                        medidas de segurança adequadas e cumpram as obrigações previstas nesta Política e na legislação
                                        aplicável, a fim de garantir a integridade, confidencialidade e segurança dos seus dados pessoais.
                                    </p>
                                    <p>
                                        O IAS informará aos titulares de dados sobre as transferências internacionais realizadas, quando
                                        solicitado, garantindo-lhes o direito de acessar informações claras sobre as operações, a qualquer
                                        momento, conforme estabelecido na LGPD e em regulamentação da ANPD.
                                    </p>
                                </li>
                            </ul>
                            <br />
                            <ul>
                                <h1>K. MEDIDAS DE SEGURANÇA</h1>
                                <li>
                                    <p>
                                        Para manter a segurança e proteção de seus dados pessoais fornecidos no cadastro e/ou conta de usuário,
                                        recomendamos que você, em nenhuma hipótese, compartilhe seu login e senha com terceiros. Também
                                        orientamos que tenha cautela ao publicar seus dados pessoais em redes sociais ou em qualquer outro
                                        ambiente público. O IAS se compromete a adotar medidas técnicas e organizacionais adequadas para garantir
                                        a segurança e integridade de seus dados pessoais e, para isso, utilizamos práticas amplamente
                                        reconhecidas no mercado, como criptografia, firewalls, e tecnologia Secure Sockets Layer (SSL), para
                                        proteger as atividades de coleta, tratamento e armazenamento de dados.
                                    </p>
                                    <p>
                                        Mesmo com o uso de medidas de segurança robustas, nenhum sistema é totalmente impenetrável. Portanto, o
                                        IAS não pode garantir que nossos bancos de dados estejam completamente livres de falhas ou que seus dados
                                        pessoais não serão interceptados durante a transmissão. O IAS se compromete a tomar todas as medidas
                                        cabíveis para mitigar esses riscos, mas não se responsabiliza por danos ou prejuízos decorrentes de
                                        falhas de segurança, vírus ou invasões, exceto quando houver comprovação de culpa grave ou dolo exclusivo
                                        por parte do IAS.
                                    </p>
                                    <p>
                                        Caso o IAS identifique que ocorreu uma violação de segurança que afete seus dados pessoais, notificaremos
                                        os titulares e a ANPD, conforme exigido pela legislação aplicável. A notificação incluirá informações
                                        sobre a natureza do incidente, os dados pessoais comprometidos, as medidas adotadas para mitigar os
                                        efeitos e as orientações para que o titular proteja seus dados.
                                    </p>
                                </li>
                            </ul>
                            <br />
                            <ul>
                                <h1>L. DISPOSIÇÕES GERAIS </h1>
                                <br />
                                <br />
                                <li>
                                    <h1>1. Alterações a essa Política</h1>
                                    <p>
                                        Poderemos alterar a presente Política ao longo do tempo. Toda vez que alguma condição relevante desta
                                        Política for alterada, essas alterações serão válidas, eficazes e vinculantes a partir da publicação e
                                        divulgação da nova versão em nosso site institucional e plataformas.
                                    </p>
                                    <p>
                                        É recomendado que você consulte regularmente este documento para estar sempre atualizado com relação aos
                                        seus termos.
                                    </p>
                                    <p>
                                        <strong>IMPORTANTE:</strong>
                                        <span style={{ fontStyle: 'italic' }}>
                                            Esta Política foi alterada pela última vez e publicada no site institucional e plataformas
                                            administrados pelo IAS em setembro de 2024.
                                        </span>
                                    </p>
                                </li>
                            </ul>
                            <br />
                            <ul>
                                <h1>2. Fale conosco</h1>
                                <p>
                                    Em caso de dúvidas, sugestões ou comentários sobre as nossas práticas de privacidade e tratamento de dados
                                    pessoais, ou, ainda, sobre os termos deste documento, por favor, entre em contato conosco pelo e-mail:
                                    <a href="mailto:encarregado@ias.org.br"> encarregado@ias.org.br</a>.
                                </p>
                                <h1>Encarregado: Antonio Carlos Rodrigues</h1>
                                <p>
                                    O IAS segue à disposição dos visitantes e usuários do nosso site institucional e plataformas, em contínuo
                                    compromisso com a sua privacidade, autodeterminação informativa e liberdade de expressão e informação.
                                </p>
                                <br />
                                <p>
                                    Para acessar a política diretamente pelo site clique aqui:
                                    <a href="https://institutoayrtonsenna.org.br/politica-de-privacidade/">
                                        {' '}
                                        https://institutoayrtonsenna.org.br/politica-de-privacidade/
                                    </a>
                                </p>
                            </ul>
                        </ul>
                    </li>
                </ol>

                <br />
            </Content>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
