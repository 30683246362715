import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled, { CSSProperties } from 'styled-components';

export const Container = styled.div`
    padding: 60px 0;

    @media (max-width: ${MobileMaxWidth}px) {
        padding: 20px;
    }
`;

export const BannerInfoStyle = {
    Container: styled.div`
        border-radius: 6px;
        background-color: ${Colors.SoftBlue};
        padding: 10px 30px;
        margin-bottom: 10px;
    `,

    HeadContainer: styled.div`
        display: flex;
        justify-content: space-between;
        user-select: none;
    `,

    CloseIcon: styled.img`
        width: 15px;
        cursor: pointer;
    `,

    Content: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    Text: styled.p`
        color: white;
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        padding: 2px 0;
    `,

    ButtonContainer: styled.div`
        width: fit-content;
        padding: 0 55px;
        height: fit-content;
    `,
};

export const styleButton: CSSProperties = {
    backgroundColor: 'white',
    color: Colors.SoftBlue,
    width: '230px',
};

interface IFrameInterface {
    visibile: boolean;
}
export const Iframe = styled.iframe<IFrameInterface>`
    display: flex;
    visibility: ${props => (props.visibile ? 'initial' : 'hidden')};
    width: 100%;
    min-height: 800px;
`;
