import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import AdminEditFaq from 'pages/admin/components/pageEditors/AdminEditFaq';
import AdminEditModalSave from 'pages/admin/components/pageEditors/AdminEditModalSave';
import PaginaIntermediaria from 'pages/admin/components/paginaIntermediaria/PaginaIntermediaria';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styled from 'styled-components';

interface IAdminFaqPageContainer {}
const AdminFaqPageContainer = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 15px 25px;
    margin-left: 120px;
    width: calc(100% - 120px);
`;

interface IAdminFaqPage {}

const AdminFaqPage: FunctionComponent<IAdminFaqPage> = ({ ...props }: IAdminFaqPage) => {
    const [showModalSaved, setShowModalSaved] = useState(false);

    const onSaved = () => setShowModalSaved(true);
    return (
        <AdminFaqPageContainer>
            <AdminBreadCrumb crumbs={[{ route: 'faq', label: 'FAQ' }]} />
            <AdminEditFaq onSaved={onSaved} />

            <AdminEditModalSave
                show={showModalSaved}
                onHide={() => {
                    setShowModalSaved(false);
                }}
            />
        </AdminFaqPageContainer>
    );
};

export default AdminFaqPage;
