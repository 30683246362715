export enum TipoFuncionalidade {
    PDP = 'PDP',
    AUTOAVALIACAO = 'AUTOAVALIACAO',
    DIARIOBORDO = 'DIARIOBORDO',
    JORNADA = 'JORNADA',
}

type Feedback = {
    nota: number;
    id_perfil: number;
    comentario?: string;
    funcionalidade: TipoFuncionalidade;
};

export default Feedback;
