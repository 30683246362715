import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoHumane from '../../../../../images/logo/logo-humane-two.svg';
import Colors from 'enums/Colors';
import { Button, ConfigProvider, Divider, GetProps, Input, message } from 'antd';
import { PrimaryButton } from 'components/UI/Buttons';
import useUserData from 'hooks/useUserData';
import useFetch from 'hooks/useFetch';
import Banner from '../../../../home/assets/imagem_07.webp';
import { PerfilValidationService } from 'core/http/service/perfilValidation/PerfilValidation.service';
import { MdVerified } from 'react-icons/md';
import { KeycloakLoginOptions } from 'keycloak-js';
import keycloak from 'core/config/keycloak';
import { useHistory } from 'react-router-dom';
import {
    isViaAlfabetizacaoAprofundada,
    isViaBDS,
    isViaCompetenciasSocioemocionais,
    isViaRealizandoAlfabetizacao,
    isViaRealizandoEscolhas,
    isViaRealizandoTrilhaMaker,
    isViaViolenciaBully,
    limparRedirecionamentosPosLogin,
    possuiRedirecionamentoPosLogin,
} from 'core/util/StorageRedirectRulesHelper';
import { GTM_GA4_Ecommerce_Purchase } from 'components/GoogleTagManager';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import { ProfileContext } from 'pages/profile/context/ProfileContext';
import * as profileActions from '../../../../../store/actions/Profile';
import { useDispatch } from 'react-redux';

enum StatusValition {
    NOT_PERMISSAO = 'NOT_PERMISSAO',
    EXPIRED = 'EXPIRED',
    CODE_INVALID = 'CODE_INVALID',
    PERFIL_VALID = 'PERFIL_VALID',
}

type OTPProps = GetProps<typeof Input.OTP>;

const Styled = {
    Container: styled.div<{ url: string }>`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: linear-gradient(
                115deg,
                rgba(0, 0, 0, 0.4009978991596639) 0%,
                rgba(47, 47, 47, 0.5102415966386555) 64%,
                rgba(8, 8, 8, 0.7931547619047619) 100%
            ),
            url(${props => props.url});
        background-size: cover; // Opcional: ajustar a imagem para cobrir todo o container
        background-position: center; // Opcional: centralizar a imagem
        background-repeat: no-repeat;
        gap: 1rem;
        height: 100vh;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 420px;
        height: 460px;
        background-color: ${Colors.Blue};
        padding: 20px;
        color: ${Colors.White};
        border-radius: 8px;
        gap: 1.5rem;

        @media screen and (max-width: 420px) {
            width: 100%;
        }
    `,

    Titulo: styled.h1`
        text-transform: uppercase;
        font-weight: 500;
    `,

    Description: styled.div`
        line-height: 20px;
    `,

    LoadingSend: styled.div`
        /* HTML: <div class="loader"></div> */

        width: fit-content;
        font-size: 18px;
        font-family: system-ui, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        color: #0000;
        -webkit-text-stroke: 1px ${Colors.Blue};
        background: conic-gradient(${Colors.Blue} 0 0) 0/0% 100% no-repeat text;
        animation: l1 1s linear infinite;
        &:before {
            content: 'Enviando...';
        }
        @keyframes l1 {
            to {
                background-size: 120% 100%;
            }
        }
    `,
    LoadingVerify: styled.div`
        /* HTML: <div class="loader"></div> */

        width: fit-content;
        font-size: 18px;
        font-family: system-ui, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        color: #0000;
        -webkit-text-stroke: 1px ${Colors.Blue};
        background: conic-gradient(${Colors.Blue} 0 0) 0/0% 100% no-repeat text;
        animation: l1 1s linear infinite;
        &:before {
            content: 'Verificando...';
        }
        @keyframes l1 {
            to {
                background-size: 120% 100%;
            }
        }
    `,
};

const ValidationAccount = () => {
    const service = new PerfilValidationService();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [page, setPage] = useState<number>(1);
    const [submit, setSubmit] = useState<boolean>(false);
    const [codigo, setCodigo] = useState<string>('');
    const [modifyEmail, setModifyEmail] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [seconds, setSeconds] = useState(60);
    const [countdown, setCountdown] = useState<boolean>(false);
    const { redirectBehindRegister } = useContext(AccessPermissionContext.Context);
    const { postProfilePath } = useContext(ProfileContext.Context);

    const { loadData } = useUserData();

    const dispatch = useDispatch();

    useFetch(async () => {
        const { perfilUsuario, usuarioLogado } = await loadData();

        setEmail(perfilUsuario?.email ?? '');
    }, []);

    const success = (msg: string) => {
        messageApi.open({
            type: 'success',
            content: msg,
        });
    };

    const error = (msg: string) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    const warning = (msg: string) => {
        messageApi.open({
            type: 'warning',
            content: msg,
        });
    };

    const realodLoginUser = () => {
        const options: KeycloakLoginOptions = {
            redirectUri: window.location.origin + '/login?' + '/validar',
        };

        const _url = keycloak.createLoginUrl(options);

        window.location.assign(_url);
    };

    const handeSubmitEmail = async () => {
        setSubmit(true);
        const response = modifyEmail ? await service.novoCodigo(email) : await service.sendEmailValidation(email);

        if (response.status === 401) {
            realodLoginUser();
        }

        if (response.status === 201) {
            setPage(2);
            setSubmit(false);
            setCountdown(true);
        }
    };

    const handeReenviarCodigo = async () => {
        const response = modifyEmail ? await service.novoCodigo(email) : await service.sendEmailValidation(email);

        if (response.status === 401) {
            realodLoginUser();
        }

        if (response.status === 201) {
            success('Enviamos um novo código para seu e-mail.');
            setCountdown(true);
        }
    };

    const handleValidarCodigo = async () => {
        if (codigo.length < 4) {
            return warning('Nescessário preencher todos os campos!');
        }

        setSubmit(true);
        const response = await service.validarCode(codigo);

        if (response.status > 400) {
            setCodigo('');
            setSubmit(false);
            error('Ocorreu uma instabilidade no nosso sistema!');
        }

        switch (response.data.status) {
            case StatusValition.CODE_INVALID: {
                setSubmit(false);
                setCodigo('');
                return error('Código fornecido é inválido!');
            }
            case StatusValition.EXPIRED: {
                setSubmit(false);
                return warning('Código expirou-se, estamos enviando um novo código para você!');
            }
            case StatusValition.PERFIL_VALID: {
                setSubmit(false);
                return setPage(3);
            }
        }
    };

    const handleToRedirectURL = async () => {
        const { perfilUsuario, usuarioLogado } = await loadData();

        dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));

        const eventPurchase = (): string => {
            if (isViaRealizandoEscolhas()) {
                return 'realizando_escolhas';
            } else if (isViaRealizandoTrilhaMaker()) {
                return 'trilha_maker_criatividade';
            } else if (isViaRealizandoAlfabetizacao()) {
                return 'trilha-de-alfabetizacao';
            } else if (isViaCompetenciasSocioemocionais()) {
                return 'competências_socioemocionais';
            } else if (isViaViolenciaBully()) {
                return 'violencia_e_bullying';
            } else if (isViaAlfabetizacaoAprofundada()) {
                return 'alfabetização_aprofundada';
            } else if (isViaBDS()) {
                return 'beneficios_desenvolvimento_socioemocional';
            }

            return 'jornada_socioemocional';
        };

        const sendGTMEvents = () => {
            GTM_GA4_Ecommerce_Purchase(keycloak.subject ?? '', perfilUsuario?.cpf ?? '', eventPurchase());

            limparRedirecionamentosPosLogin();
        };

        if (possuiRedirecionamentoPosLogin()) {
            redirectBehindRegister('/');
        } else if (postProfilePath) {
            if (postProfilePath !== '/cursos-livres') {
                sendGTMEvents();
            }
            redirectBehindRegister(postProfilePath);
        } else {
            sendGTMEvents();
            history.push('/home');
        }
    };

    useEffect(() => {
        // Se a contagem voltar a 0, não faz nada
        if (seconds === 0) return;

        // Define um intervalo para atualizar os segundos a cada segundo
        const interval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        // Limpa o intervalo quando o componente é desmontado ou os segundos mudam
        return () => clearInterval(interval);
    }, [seconds]);

    // Formata o tempo para o formato mm:ss
    const formatTime = (sec: number) => {
        const minutes = Math.floor(sec / 60);
        const remainingSeconds = sec % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const onChange: OTPProps['onChange'] = text => {
        setCodigo(text);
    };

    const sharedProps: OTPProps = {
        onChange,
    };

    useEffect(() => {
        setSeconds(60);
    }, [countdown]);

    useEffect(() => {
        if (seconds === 0) {
            setCountdown(false);
        }
    }, [seconds]);

    return (
        <Styled.Container url={Banner}>
            {contextHolder}
            <Styled.Content>
                <img src={LogoHumane} alt="" />
                {page === 1 ? (
                    <>
                        <Styled.Titulo>VERIFICAÇÃO DA CONTA</Styled.Titulo>
                        <Styled.Description>
                            Para a verificação da sua conta vamos enviar um código de 4 números para o seu e-mail abaixo. <br /> <br />
                            <span style={{ color: Colors.Yellow }}>
                                Caso seu e-mail não esteja correto ou deseje alterá-lo, utilize o campo abaixo para fazer a correção.
                            </span>
                        </Styled.Description>
                        <Input readOnly={submit} value={email} onChange={e => setEmail(e.target.value)} size="large" />
                        <PrimaryButton
                            disabled={submit}
                            onClick={handeSubmitEmail}
                            style={{ borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {submit ? <Styled.LoadingSend /> : 'Enviar código'}
                        </PrimaryButton>
                    </>
                ) : page === 2 ? (
                    <>
                        <Styled.Titulo>VERIFICAÇÃO DA CONTA</Styled.Titulo>
                        <Styled.Description style={{textAlign: "center"}}>
                            Para finalizar a verificação da sua conta, insira o código que enviamos para o seu e-mail: <strong>{email}</strong>
                        </Styled.Description>
                        <Input.OTP inputMode="numeric" length={4} value={codigo} formatter={str => str.toUpperCase()} {...sharedProps} />
                        <PrimaryButton
                            disabled={submit}
                            onClick={handleValidarCodigo}
                            style={{ borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {submit ? <Styled.LoadingVerify /> : 'Confirmar código'}
                        </PrimaryButton>

                        {countdown ? (
                            <>
                                <p style={{ textAlign: 'center', lineHeight: '18px' }}>
                                    Para alterar o e-mail ou reenviar o código, aguarde: {formatTime(seconds)}
                                </p>
                            </>
                        ) : (
                            <>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        linkHoverDecoration: "underline",
                                       colorLinkHover: Colors.SoftBlue,
                                       colorLink: Colors.White
                                    }
                                }}
                            >

                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            onClick={() => {
                                                setPage(1);
                                                setModifyEmail(true);
                                            }}
                                            type="link"
                                        >
                                            Deseja mudar o e-mail? Clique aqui!
                                        </Button>
                                        <Button onClick={handeReenviarCodigo} type="link">
                                            Reenviar código
                                        </Button>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            onClick={() => {
                                                window.open("https://humaneias.zendesk.com/hc/pt-br/requests/new")
                                            }}
                                            type="link"
                                        >
                                            Está com problema para validar seu código? Clique aqui!
                                        </Button>
                                    </div>
                                </div>
                            </ConfigProvider>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Styled.Titulo>CONTA VERIFICADA</Styled.Titulo>
                        <MdVerified color={Colors.Yellow} size={48} />
                        <Styled.Description style={{textAlign: "center"}}>Agradecemos por verificar sua conta! Sua segurança é nossa prioridade.</Styled.Description>
                        <PrimaryButton
                            disabled={submit}
                            onClick={handleToRedirectURL}
                            style={{ borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            Concluir
                        </PrimaryButton>
                    </>
                )}
            </Styled.Content>
        </Styled.Container>
    );
};

export default ValidationAccount;
