import { GenericService } from './Generic.service';

class DiarioBordoAnexoService extends GenericService {
    readonly URI: string = '/v1/diarioBordo';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public inserirAnexo = (idDiario: number, data: FormData, idPerfil: number) => {
        return this.post('inserirAnexo', data, { idDiario, idPerfil });
    };

    public removerAnexo = (idAnexo: number, idPerfil: number) => {
        return this.deleteByParams('removerAnexo', { idAnexo, idPerfil });
    };

    public buscarAnexo = (idAnexo: number, idPerfil: number) => {
        return this.get('buscarAnexo', { idAnexo, idPerfil });
    };
}

export default DiarioBordoAnexoService;
