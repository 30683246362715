import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable, jsonObject, jsonIgnore } from 'ts-serializable';

class UsuarioDTO extends Serializable {
    @jsonProperty(String)
    id: string = '';

    @jsonProperty(String)
    username: string = '';

    @jsonProperty(String)
    primeiroNome: string = '';

    @jsonProperty(String)
    ultimoNome: string = '';

    @jsonProperty(String)
    email: string = '';

    @jsonProperty(String)
    cpf: string = '';

    @jsonProperty([String])
    roles: string[] = [];

    @jsonIgnore()
    claims: any = new Map<string, Object>();

    constructor() {
        super();
    }
}

export default UsuarioDTO;
