import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico from './PerfilDTOGenerico';

class TipoEnsinoEscolaPublicaDTO extends PerfilDTOGenerico {
    constructor() {
        super();
    }
}

export default TipoEnsinoEscolaPublicaDTO;
