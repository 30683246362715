import Keycloak from 'keycloak-js';
import { keycloakAuthURL } from 'constants/EnvironmentData';

const keycloak = Keycloak({
    url:  keycloakAuthURL(),
    realm: 'ias',
    clientId: 'plestru',
});

export default keycloak;
