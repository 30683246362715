import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import { queryParamBusca } from 'pages/home/context/PesquisaProvider';
import React, { useContext, useEffect, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';
import Styled from './BarraPesquisa.styled';

export interface IBarraPesquisa {}

export const barraPesquisaId = 'search-bar-input';

const BarraPesquisa = ({}: IBarraPesquisa) => {
    const blackdropElement = useRef<HTMLDivElement>(null);
    const { closeSearch, setTerm, term } = useContext(PesquisaContext.Context);
    const history = useHistory();
    const location = useLocation();

    const closeBar = () => {
        if (blackdropElement.current) {
            blackdropElement.current.style.opacity = '0';
        }

        setTimeout(closeSearch, 300);
    };

    const onHandlerSubmit = () => {
        const serachparams = new URLSearchParams(location.search);

        if (term.length < 3) {
            return;
        }

        if (!serachparams.has(queryParamBusca)) {
            serachparams.append(queryParamBusca, term);
        } else serachparams.set(queryParamBusca, term);

        history.push('/pesquisa?' + serachparams.toString());
    };

    useEffect(() => {
        if (blackdropElement.current) {
            blackdropElement.current.style.opacity = '1';
        }
    }, []);

    return (
        <Styled.BackdropShadow ref={blackdropElement}>
            <Styled.Container>
                <Styled.Icon className="desktop-only">
                    <FiSearch />
                </Styled.Icon>
                <Styled.Input
                    id={barraPesquisaId}
                    placeholder="O que você está pesquisando?"
                    onChange={evt => {
                        setTerm(evt.target.value);
                    }}
                    onKeyDown={evt => {
                        if (evt.key === 'Enter') {
                            onHandlerSubmit();
                        }
                    }}
                />
                <Styled.Button onClick={onHandlerSubmit}>Pesquisar</Styled.Button>
                <Styled.Icon onClick={closeBar} className="desktop-only">
                    <IoIosCloseCircleOutline className="clickable" />
                </Styled.Icon>
            </Styled.Container>

            <Styled.CloseArea onClick={closeBar} />
        </Styled.BackdropShadow>
    );
};

export default BarraPesquisa;
