import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';

export const CardGridContainer = styled.div(
    new FullCss(
        {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
            padding: '30px 0',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            gap: '15px',
            margin: '7px',
            display: 'flex',
            flexDirection: 'column',
        })
    ).getCss()
);
