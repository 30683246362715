import { useKeycloak } from '@react-keycloak/web';
import { IsCustomMinWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import TrilhaFormativaModal from 'pages/realizandoEscolhas/home/components/TrilhaFormativaModal';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../components/Card';
import ModalDefault from '../components/ModalDefault';
import SharingContent from '../components/SharingContent';
import {
    Container,
    TopContent,
    Byline,
    Title,
    InnerTopContent,
    BottomContent,
    ButtonInitialPage,
    ButtonNext,
    Content,
    Text,
    CardDeck,
    TrilhaFormativaButton,
} from './TrilhaFormativa.styled';
import IconChart from '../components/assets/images/icon-chart.svg';
import IconAward from '../components/assets/images/icon-award.svg';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import {
    buscarCategoriaPorPerfil,
    buscarOcupacaoPorPerfil,
    matricularUsuario,
    redirectToInstrumento,
    redirectToTrilhaFormativaLMS,
} from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import CardTrilha from '../components/CardTrilha';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import Modulos from '../../data/CardTrilha.json';
import { Tabs } from 'react-bootstrap';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { idCursoRealizandoEscolhasGestor } from 'constants/EnvironmentData';

export interface ITrilhaFormativa {}

const TrilhaFormativa = ({}: ITrilhaFormativa) => {
    const history = useHistory();
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profile = useAppSelector(state => state.profileReducer.userProfile);

    const [perfil, setPerfil] = useState<String>('');
    const [tabs, setTabs] = useState<String[]>([]);
    const [modulos, setModulos] = useState<any>([]);
    const [modulosDownload, setModulosDownload] = useState<any>([]);
    const [is9ano, setIs9ano] = useState(false);

    const { keycloak } = useKeycloak();
    const [showTrilhaFormativaReqModal, setShowTrilhaFormativaReqModal] = useState(false);
    const [userRole, setUserRole] = useState('');

    const [loading, setLoading] = useState<boolean>(false);

    const service = new RealizandoEscolhasService();

    const CODIGO_TIPO_CONTEUDO: number = 3;

    const registrarAcesso = async () => {
        await service.registrarAcesso(CODIGO_TIPO_CONTEUDO);
    };

    const registerMatriculaGestor = async () => {
        const categoriaUser = buscarOcupacaoPorPerfil(profile);

        if (categoriaUser === 'B') {
            const idCurso = idCursoRealizandoEscolhasGestor();
            await matricularUsuario(parseInt(idCurso), usuarioLogado, () => '');
        }
    };

    useEffect(() => {
        registerMatriculaGestor();
    }, []);

    useEffect(() => {
        registrarAcesso();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
        getCategoryByRole();
    }, []);

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('trilha_formativa_realizando_escolhas', 'voltar_home_realizando_escolhas');
        history.push('/realizandoEscolhas/home');
    };

    const getCategoryByRole = async () => {
        if (usuarioLogado) {
            setUserRole((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string));
        }
    };

    const goToTrilhaFormativaLMS = async (role: string) => {
        await redirectToTrilhaFormativaLMS(role, is9ano, usuarioLogado, setLoading);
        GTM_Page_Realizando_Escolhas('trilha_formativa_realizando_escolhas', 'acessar_trilha_formativa_lms');
    };

    const TabsDesktop = (): String[] => {
        const perfil = buscarOcupacaoPorPerfil(profile);

        if (perfil === 'A') {
            return Modulos.perfilProfessor.tabs;
        }

        return Modulos.PerfilGestoresEscolares.tabs;
    };

    const TabsMobile = (): String[] => {
        const perfil = buscarOcupacaoPorPerfil(profile);

        if (perfil === 'A') {
            return Modulos.perfilProfessor.tabsMobile;
        }

        return Modulos.PerfilGestoresEscolares.tabsMobile;
    };

    useEffect(() => {
        const perfil = buscarOcupacaoPorPerfil(profile);

        if (perfil !== 'A' && perfil !== 'B') {
            goToHomePage();
            return;
        }

        setPerfil(perfil);

        if (perfil === 'A') {
            const NonoAno = Modulos.perfilProfessor.modulos.NonoAno;
            const TrilhaFormativa = Modulos.perfilProfessor.modulos.thilhaFormativa;
            const Download = Modulos.perfilProfessor.download;

            // setTabs(tabs);

            setModulos((prevModulos: any) => [...prevModulos, ...[NonoAno, TrilhaFormativa]]);
            setModulosDownload(Download);
        } else {
            const TrilhaFormativa = Modulos.PerfilGestoresEscolares.modulos.thilhaFormativa;
            const Download = Modulos.PerfilGestoresEscolares.download;

            setTabs(Modulos.PerfilGestoresEscolares.tabs);
            setModulos(TrilhaFormativa);
            setModulosDownload(Download);
        }
    }, []);

    return (
        <Container>
            <TopContent>
                <InnerTopContent>
                    <Title>{'Trilha Formativa'}</Title>
                    <Byline>{'Conheça nossa proposta pedagógica'}</Byline>
                </InnerTopContent>
                <SharingContent location="trilha_formativa" />
            </TopContent>

            <Content>
                <Text>
                    Aqui, você encontrará uma trilha formativa com atividades de estudo e reflexão para que possa conhecer a proposta pedagógica
                    do projeto Realizando Escolhas.
                </Text>

                <Text>
                    O curso se inicia com a apresentação do <strong>Modelo Pedagógico</strong>, documento que apresenta como a proposta foi
                    planejada e seus fundamentos teóricos. Você irá se aprofundar nas temáticas de itinerários formativos, mundo do trabalho e
                    como fazer escolhas, além de começar a compreender como a proposta acontece na escola.
                </Text>

                <Text>
                    Após esta apresentação, será o momento de se <strong>planejar para desenvolver ou acompanhar o projeto nas escolas</strong>.
                    Para isso, professores poderão se aprofundar nas atividades propostas para os estudantes, refletindo sobre o planejamento das
                    aulas e acompanhamento dos jovens, enquanto gestores serão convidados a refletir sobre estratégias de acompanhamento do
                    trabalho.
                </Text>

                <Text>
                    Ao realizar este curso, esperamos que você reúna todo o aporte teórico e pedagógico necessário para apoiar os estudantes a
                    tomarem as decisões necessárias do início do Ensino Médio, como o itinerário que desejam seguir, e ao final da etapa, na
                    continuidade da vida acadêmica e/ou profissional.
                </Text>
                <CardTrilha
                    perfil={perfil}
                    tabs={IsMobileMaxWidth() ? TabsMobile() : TabsDesktop()}
                    modulos={modulos}
                    download={modulosDownload}
                />
            </Content>

            {IsMobile() && (
                <BottomContent>
                    <ButtonInitialPage onClick={goToHomePage}>{'Página inicial Realizando Escolhas'}</ButtonInitialPage>
                </BottomContent>
            )}

            <ModalDefault show={showTrilhaFormativaReqModal}>
                <TrilhaFormativaModal
                    setShow={setShowTrilhaFormativaReqModal}
                    goToTrilhaFormativaCallback={(trilha, is9Ano) => {
                        goToTrilhaFormativaLMS(userRole);
                        setIs9ano(is9Ano);
                        setShowTrilhaFormativaReqModal(false);
                    }}
                />
            </ModalDefault>
        </Container>
    );
};

export default TrilhaFormativa;
