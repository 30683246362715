import { GenericService } from '../Generic.service';

export default class BiblioComentarioService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarComentarios(item_id: number) {
        return this.get(`public/${item_id}/comentarios`);
    }

    public enviarComentario(item_id: number, comentario: string) {
        return this.post('comentario', { item_id, comentario });
    }

    public deletarComentario(comentario_id: number) {
        return this.deleteByParams('comentario/' + comentario_id);
    }

    public alterarComentario(comentario_id: number, comentario: string) {
        return this.put('comentario/' + comentario_id, { comentario });
    }
}
