import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import { BiblioItemCategoria } from './BiblioItemCategoria';

class BiblioItemPreview extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('slug')
    @jsonProperty(String)
    slug: string = '';

    @jsonName('titulo')
    @jsonProperty(String)
    title: string = '';

    @jsonName('categoria')
    @jsonProperty(String)
    category: string = '';

    @jsonName('autores')
    @jsonProperty(String)
    authors: string = '';

    @jsonName('criacao')
    @jsonProperty(String)
    date: string = '';

    @jsonName('capa')
    @jsonProperty(String, null)
    cover: string | null = null;

    @jsonName('favoritado')
    @jsonProperty(Boolean)
    isFavorited: boolean = false;

    @jsonName('avaliacao')
    @jsonProperty(Number, null)
    userRate: number = 0;
}

export default BiblioItemPreview;
