import React from 'react';
import styled from 'styled-components';
import Styled from '../../../components/IntermediatePageContent.styled';

import banner from '../../../../../images/homepage/banner-criatividade.png';
import clock from '../../../../../images/homepage/clock.svg';
import cert from '../../../../../images/homepage/cert.svg';
import { MobileMaxWidth } from 'constants/Dimensions';

export interface ICursoLivre {
    gotoCursoLivre?: () => void;
}

const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #003c78;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
        line-height: 22px;
    }
`;

const CardInscreva = styled.div`
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
    max-width: 345px;

    .banner {
        max-width: 345px;
        max-height: 191px;
    }

    .content {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
    }

    .text {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #003c78;
        margin-top: 15px;
        flex-grow: 1;
    }

    .row {
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: ${320}px) {
            flex-direction: column;
            padding: 10px;
        }
    }

    .label {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #003c78;
    }

    .icon {
        width: 18px;
        height: 18px;
        transform: translateY(4px);
        margin-right: 7px;
    }

    .button {
        width: 100%;
        background-color: #00a8e9;
        color: white;
        font-weight: 600;
        font-size: 18px;
        border-radius: 6px;
        border: none;
        outline: none;
        padding: 10px;
        margin: 7px 0;
        margin-top: 15px;
        border: 1px solid #00a8e9;
    }

    .button:hover {
        background-color: white;
        color: #00a8e9;
    }
`;

const CursoLivre = ({ gotoCursoLivre }: ICursoLivre) => {
    return (
        <Styled.Container>
            <Title>Curso livre “Criatividade e Pensamento Crítico na Escola”</Title>

            <br />

            <div style={{ padding: '0 20px' }}>
                <Styled.Text>Curso gratuito e 100% online, que conta com três módulos:</Styled.Text>

                <br />

                <Styled.TextNavy>
                    <b>Módulo 1:</b> <i>Eu sendo crítico e criativo</i>
                </Styled.TextNavy>
                <Styled.TextNavy>
                    <b>Módulo 2:</b> <i>Eu agindo no mundo</i>
                </Styled.TextNavy>
                <Styled.TextNavy>
                    <b>Módulo 3:</b> <i>Eu atuando na sala de aula</i>
                </Styled.TextNavy>

                <br />

                <Styled.Text>
                    Neles, você fará um mergulho nessas competências, por meio de vídeos, textos, podcasts e atividades mãos na massa. Haverá
                    certificado de participação para quem completar a formação e responder à avaliação.
                </Styled.Text>

                <br />

                <CardInscreva>
                    <img className="banner" src={banner} alt="cpc" />
                    <div className="content">
                        <Styled.Title style={{ lineHeight: 'unset' }}>Criatividade e pensamento crítico na escola</Styled.Title>
                        <p className="text">
                            Conheça conceitos, referências e inspirações que possam contribuir para sua reflexão sobre o desenvolvimento da
                            criatividade e do pensamento crítico entre si, no outro e na escola. Tenha acesso a atividades práticas para
                            trabalhar com o desenvolvimento dessas habilidades em sala de aula.
                        </p>

                        <div className="row">
                            <div style={{ marginTop: '5px' }}>
                                <img className="icon" src={clock} />
                                <span className="label">6 horas de dedicação</span>
                            </div>

                            <div style={{ marginTop: '5px' }}>
                                <img className="icon" src={cert} />
                                <span className="label">Com certificado</span>
                            </div>
                        </div>
                        <button className="button" onClick={gotoCursoLivre}>
                            Inscreva-se
                        </button>
                    </div>
                </CardInscreva>
            </div>
        </Styled.Container>
    );
};

export default CursoLivre;
