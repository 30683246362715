import { GenericService } from '../Generic.service';

export class PerfilValidationService extends GenericService {
    readonly URI: string = '/v1/perfilValidation';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public sendEmailValidation(email: string) {
        return this.post('sendEmailValidation', { email });
    }

    public validarCode(codigo: string) {
        return this.post('validarCode/' + codigo, {});
    }

    public novoCodigo(email: string) {
        return this.post('novoCodigo', { email });
    }
}
