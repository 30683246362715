import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11px 14px;
    gap: 14px;

    width: 130px;
    height: 104px;

    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(87, 87, 87, 0.3);
    border-radius: 4px;

    @media only screen and (max-width: 480px) {
        max-width: 22vw;
    }
`;

export const Counter = styled.p<{ colorPicker: string }>`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: clamp(36px, 1.5vw, 50px);
    line-height: 97%;

    text-align: center;
    text-transform: uppercase;

    color: ${props => (props.colorPicker == 'jornada' ? '#183f73' : props.colorPicker == 'curso' ? '#00A8E9' : '#FFCA00')};
`;

export const Description = styled.p<{ colorPicker: string }>`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: clamp(10px, 3.5vw,15px);
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.5px;
    text-transform: uppercase;

    color: ${props => (props.colorPicker == 'jornada' ? '#183f73' : props.colorPicker == 'curso' ? '#00A8E9' : '#FFCA00')};
`;
