import Loading from 'components/Loading';
import Select, { SelectOptionType } from 'components/Select';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { NavyButton } from 'components/UI/Buttons';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { RoleEnum } from 'enums/RoleEnum';
import { cloneDeep, capitalize } from 'lodash';
import BiblioItemCategoria from 'models/biblioteca/BiblioItemCategoriaModel';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import React, { useState, useEffect, useCallback } from 'react';
import { FiSave, FiEdit } from 'react-icons/fi';
import { MdOutlineModeEditOutline, MdOutlineEditOff } from 'react-icons/md';

import Styled from './AdminBiblioCategorias.styled';
import AdminBiblioCategoriasInput from './AdminBiblioCategoriasInput';

export interface IAdminBiblioCategorias {}

export const exclusiveOptions: SelectOptionType[] = [
    { value: 'Não exclusivo', label: 'Não exclusivo' },
    { value: RoleEnum.AgenteTecnico, label: 'Agente Técnico' },
];

export const getComportamentoPadrao = (categoria: string): string | null => {
    const comportamento: any = {
        artigo: 'artigo',
        atividade: 'atividade',
        audio: 'audio',
        guia: 'livro',
        livro: 'livro',
        video: 'video',
        estudo: 'livro',
    };

    return comportamento[categoria] ?? null;
};

const AdminBiblioCategorias = ({}: IAdminBiblioCategorias) => {
    const service = new BiblioConteudoService();
    const [categorias, setCategorias] = useState<BiblioItemCategoria[]>([]);
    const [comportamentos, setComportamentos] = useState<string[]>([]);
    const [onNewCategoria, setOnNewCategoria] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [onEditId, setOnEditId] = useState<number>(-1);
    const [onEditCategoria, setOnCategoria] = useState<string>('');
    const [onEditRota, setOnRota] = useState<string>('');
    const [onEditComportamento, setOnEditComportamento] = useState<SelectOptionType>({ value: '', label: '' });
    const [onEditPerfilExclusivo, setOnPerfilExclusivo] = useState<SelectOptionType>({ value: '', label: '' });

    const fetchCategorias = async () => {
        setLoading(true);
        const { data } = await service.buscarCategorias(true);

        const _categorias = Array.from(data).map((c: any) => new BiblioItemCategoria().fromJSON(c));
        const _comportamentos: string[] = [];

        _categorias.forEach(c => {
            if (!_comportamentos.includes(c.comportamento)) {
                _comportamentos.push(c.comportamento);
            }
        });

        setCategorias(_categorias.sort((c1, c2) => c1.ordem - c2.ordem));
        setComportamentos(_comportamentos);
        setLoading(false);
    };

    const atualizarAtivo = (id: number, value: boolean) => {
        const cloneCategorias = cloneDeep(categorias);

        for (let i = 0; i < cloneCategorias.length; i++) {
            if (cloneCategorias[i].id == id) {
                cloneCategorias[i].ativo = value;
                service.editarCategoria(cloneCategorias[i]);
            }
        }

        setCategorias(cloneCategorias);
    };

    const setupComportamento = (id: number, comportamento: string) => {
        const cloneCategorias = cloneDeep(categorias);

        for (let i = 0; i < cloneCategorias.length; i++) {
            if (cloneCategorias[i].id == id) {
                cloneCategorias[i].comportamento = comportamento;
                service.editarCategoria(cloneCategorias[i]);
            }
        }

        setCategorias(cloneCategorias);
        setOnEditComportamento({ value: comportamento, label: comportamento });
    };

    const setupPerfilExclusivo = async (id: number, perfil: string | null) => {
        const cloneCategorias = cloneDeep(categorias);

        for (let i = 0; i < cloneCategorias.length; i++) {
            if (cloneCategorias[i].id == id) {
                if (perfil == null || perfil == exclusiveOptions[0].value) {
                    cloneCategorias[i].perfilExclusivo = null;
                } else {
                    cloneCategorias[i].perfilExclusivo = perfil;
                }

                setCategorias(cloneCategorias);
                await service.editarCategoria(cloneCategorias[i]);
                break;
            }
        }
    };

    const onSubmit = (novo: BiblioItemCategoria) => {
        setCategorias([...categorias, novo]);
        setOnNewCategoria(false);
    };

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            for (let i = 0; i < categorias.length; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [categorias]
    );

    const getSortedCategorias = useCallback(() => {
        return categorias.sort((c1, c2) => c1.ordem - c2.ordem);
    }, [categorias]);

    const setupOrder = async (fromIndex: number, toIndex: number | string) => {
        if (typeof toIndex == 'string') toIndex = parseInt(toIndex);

        const item = cloneDeep(categorias[fromIndex]);
        item.ordem = toIndex + 1;

        const { status, data } = await service.editarCategoria(item);

        if (status === 200) {
            ToastSuccess('Ordem Atualizada!');
            await fetchCategorias();
        } else {
            ToastError('Houve um erro ao atualizar a ordem');
        }
    };

    const submit = async (itemId: number) => {
        const categoria = categorias.find(c => c.id == itemId);

        if (categoria) {
            categoria.descricao = onEditCategoria;
            categoria.rota = onEditRota.replace(/^\//g, '');

            if (categoria.descricao.trim().length == 0 || categoria.rota.trim().length == 0) {
                ToastError('Dados de categoria inválidos, verifique e tente novamente');
                return;
            }

            const { data, status } = await service.editarCategoria(categoria);

            if (status == 200) {
                ToastSuccess('Item Atualizado!');
                await fetchCategorias();
                setOnEditId(-1);
            } else {
                ToastError('Houve um erro ao atualizar');
            }
        }
    };

    useEffect(() => {
        if (onEditId == -1) {
            setOnEditComportamento({ value: '', label: '' });
            setOnCategoria('');
            setOnRota('');
            return;
        }

        const categoria = categorias.find(c => c.id == onEditId);
        if (categoria) {
            setOnCategoria(categoria.descricao ?? '');
            setOnRota(categoria.rota ?? '');
            setOnEditComportamento({ value: categoria.comportamento, label: categoria.comportamento });
            setOnPerfilExclusivo({
                value: categoria.perfilExclusivo ?? '',
                label: exclusiveOptions.find(o => o.value == categoria.perfilExclusivo)?.label ?? '',
            });
        }
    }, [onEditId]);

    useEffect(() => {
        fetchCategorias();
    }, []);
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Styled.Container>
                    <Styled.RowHead>
                        <Styled.ColumnGrid>
                            <Styled.Title>Categoria</Styled.Title>
                            <Styled.Title>Rota</Styled.Title>
                            <Styled.Title>Comportamento</Styled.Title>
                            <Styled.Title>Ordem</Styled.Title>
                            <Styled.Title>Ativo</Styled.Title>
                            <Styled.Title style={{ fontSize: '15px' }}>Exclusividade</Styled.Title>
                            <Styled.Title>Ação</Styled.Title>
                        </Styled.ColumnGrid>
                    </Styled.RowHead>

                    {getSortedCategorias().map((c, idx) => {
                        return (
                            <Styled.Row key={c.id}>
                                <Styled.ColumnGrid>
                                    {onEditId != c.id ? (
                                        <Styled.Center>{c.descricao}</Styled.Center>
                                    ) : (
                                        <Styled.Input
                                            value={onEditCategoria}
                                            onChange={evt => {
                                                setOnCategoria(evt.target.value);
                                            }}
                                        />
                                    )}
                                    {onEditId != c.id ? (
                                        <Styled.Center>{c.rota ? `/${c.rota}` : ''}</Styled.Center>
                                    ) : (
                                        <Styled.Input
                                            value={onEditRota}
                                            onChange={evt => {
                                                setOnRota(evt.target.value);
                                            }}
                                            disabled={c.temConteudos}
                                        />
                                    )}

                                    <Select
                                        key={`select-${c.id}-${onEditComportamento}`}
                                        itens={comportamentos.map(cc => {
                                            return { value: cc, label: cc };
                                        })}
                                        value={onEditId == c.id ? onEditComportamento : { value: c.comportamento, label: c.comportamento }}
                                        onSelected={o => setupComportamento(c.id, String(o.value))}
                                        isSmall={true}
                                        disabled={c.id != onEditId || !!getComportamentoPadrao(c.descricao) || c.temConteudos}
                                    />

                                    <Select
                                        width={'125px'}
                                        itens={itemsOrder(c.ordem - 1)}
                                        value={{ value: c.ordem, label: String(c.ordem) + 'º' }}
                                        onSelected={o => setupOrder(idx, String(o.value))}
                                        isSmall={true}
                                        disabled={false}
                                    />
                                    <Styled.Center>
                                        <AdminSwitch value={c.ativo} onChange={(value: boolean) => atualizarAtivo(c.id, value)} />
                                    </Styled.Center>
                                    <Styled.Center>
                                        <Select
                                            width={'175px'}
                                            isSmall
                                            itens={exclusiveOptions}
                                            disabled={true}
                                            onSelected={o => setupPerfilExclusivo(c.id, String(o.value))}
                                            value={{
                                                value: c.perfilExclusivo ?? exclusiveOptions[0].value,
                                                label:
                                                    exclusiveOptions.find(o => o.value == c.perfilExclusivo)?.label ?? exclusiveOptions[0].label,
                                            }}
                                        />
                                    </Styled.Center>
                                    {onEditId == c.id ? (
                                        <Styled.Center>
                                            <Styled.ButtonIcon onClick={() => setOnEditId(-1)} title="Cancelar Edição">
                                                <MdOutlineEditOff />
                                            </Styled.ButtonIcon>

                                            <Styled.ButtonIcon onClick={() => submit(c.id)} title="Salvar">
                                                <FiSave />
                                            </Styled.ButtonIcon>
                                        </Styled.Center>
                                    ) : (
                                        <Styled.Center title="Editar Item">
                                            <Styled.ButtonIcon onClick={() => setOnEditId(c.id)}>
                                                <MdOutlineModeEditOutline />
                                            </Styled.ButtonIcon>
                                        </Styled.Center>
                                    )}
                                </Styled.ColumnGrid>
                            </Styled.Row>
                        );
                    })}

                    <br />

                    {!onNewCategoria ? (
                        <Styled.Row style={{ textAlign: 'center' }}>
                            <NavyButton
                                onClick={() => setOnNewCategoria(true)}
                                style={{ transform: 'scale(0.9)', width: '140px', fontSize: '0.8em' }}
                            >
                                Adicionar categoria
                            </NavyButton>
                        </Styled.Row>
                    ) : (
                        <AdminBiblioCategoriasInput
                            comportamentos={comportamentos}
                            onSubmit={onSubmit}
                            onCancel={() => setOnNewCategoria(false)}
                        />
                    )}

                    <Styled.SpaceBottom />
                </Styled.Container>
            )}
        </>
    );
};

export default AdminBiblioCategorias;
