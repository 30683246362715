import styled, { keyframes } from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FormHeaderStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const Title = styled.p`
    color: ${Colors.Blue};
    font-size: 32px;
    line-height: 42px;
    font-weight: bold;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 24px;
        line-height: 28px;
    }
`;
//animation: ${fade} 0.3s linear;
export const SubTitle = styled.p`
    color: ${Colors.BlueGrey};
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;
    margin-top: 2px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 9px;
        line-height: 17px;
        font-size: 14px;
    }
`;
