import { GenericService } from './Generic.service';

export class TipoSolucoesService extends GenericService {
    readonly URI: string = '/v1/tipoSolucoes';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public getAll() {
        return this.get('public/all');
    }
}
