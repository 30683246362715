import imageBiblio from '../images/homepage/image-banner-biblio.png';
import imageSpace from '../images/homepage/image-banner-space.jpg';
import imageCover from '../images/homepage/banner.svg';

import Img_card_1 from '../images/homepage/educationalSolutions/img_card_1.svg';
import Img_card_2 from '../images/homepage/educationalSolutions/img_card_2.svg';
import Img_card_3 from '../images/homepage/educationalSolutions/img_card_3.svg';
import Img_card_4 from '../images/homepage/educationalSolutions/img_card_4.svg';
import EducationalSolution from 'models/EducationalSolution';

import imageAlf360 from '../images/homepage/educationalSolutions/img_card_2.svg';

export default {
    imageAlf360,
    imageBiblio,
    imageSpace,
    imageSe: Img_card_3,

    cover: {
        title: `A experiência digital de formação do Instituto Ayrton Senna`,
        image: imageCover,
        text: 'Somos um ambiente digital gratuito que oferece jornadas de desenvolvimento e formação integral para educadores, baseadas em evidências científicas e no conhecimento acumulado em décadas de atuação.',
        texto_botao_a: 'Saiba mais',
        texto_botao_b: 'Acessar a plataforma',
        link_interno: '/login',
        paginaLateral: false
    },

    biblio: {
        title: 'Biblioteca',
        image: imageBiblio,
        text: 'Uma formação continuada e reflexiva acontece de muitas formas, em percursos individuais e que atendem às demandas, desejos e necessidades de cada profissional. Essa jornada fica ainda mais enriquecida quando se tem materiais de qualidade para informar e inspirar experiências de desenvolvimento pessoal e profissional. Pensando nisso, criamos a Biblioteca Instituto Ayrton Senna, que reúne um repositório de conteúdos selecionados para apoiar seu desenvolvimento. Confira!',
        texto_botao_a: 'Acessar biblioteca de conteúdos',
        link_interno: '/biblioteca',
    },

    spaceTeacher: {
        title: 'Cursos livres',
        image: imageSpace,
        text: `Cursos gratuitos e de curta duração produzidos pelo Instituto Ayrton Senna.`,
        texto_botao_a: 'Acessar cursos livres',
        link_interno: '/cursos-livres',
    },

    educationalSolutions: [
        {
            image: Img_card_1,
            id: 1,
            title: 'Socioemocional de Professores',
            text: 'Ser professor é lidar com o desenvolvimento humano no contexto das transformações do século 21, intensificadas agora pela pandemia. Na sala de aula, virtual ou não, inúmeras situações demandam habilidades para planejamento, gestão de emoções, trabalho colaborativo, criação de novas propostas... Se você é professor e quer fortalecer essas habilidades, pode mergulhar nessa jornada de desenvolvimento de suas próprias competências socioemocionais, usando nossas ferramentas de autoavaliação, cursos, plano de desenvolvimento e muito mais, de acordo com suas possibilidades individuais.',
            active: true,
            followRoute: '/socioemocional-professores',
            followText: 'Saiba Mais',
            defaultData: true,
        },
        {
            image: Img_card_2,
            id: 2,
            title: 'Alfabetização 360°',
            text: 'Coordenadores pedagógicos podem acessar aqui um repositório de conteúdos focados na proposta Alfabetização 360°, que traz a perspectiva da educação integral para o desenvolvimento de estudantes dos anos iniciais do Ensino Fundamental. Promover as habilidades de leitura, escrita e cálculo e ao mesmo tempo as competências socioemocionais é algo que pode estar na prática de diversos profissionais da educação, mas iniciamos esse diálogo com os coordenadores pedagógicos que podem implementar essa perspectiva e estimular a formação dos professores em suas unidades escolares.',
            active: true,
            followRoute: '/alfabetizacao-360',
            followText: 'Acessar',
            defaultData: true,
        },
        {
            image: Img_card_4,
            id: 3,
            title: 'Socioemocional BNCC',
            text: 'Em breve, gestores de redes que já tenham parcerias e alianças com o Instituto Ayrton Senna poderão acessar a versão digital dos instrumentos de monitoramento socioemocional e de outras temáticas.',
            active: false,
            followRoute: '',
            followText: '',
            defaultData: true,
        },
        {
            image: Img_card_3,
            id: 4,
            title: 'Criatividade e pensamento crítico',
            text: 'O desenvolvimento da criatividade e do pensamento crítico é fundamental para a construção da autonomia e desenvolvimento integral de educadores e estudantes. Aqui você conhece um pouco mais sobre criatividade e pensamento crítico e encontra maneiras de trazer tais habilidades ao seu desenvolvimento e de seus estudantes.',
            active: false,
            followRoute: '/criatividade-pensamento-critico',
            followText: '',
            defaultData: true,
        },
    ] as EducationalSolution[],
};
