import React, { useState, useEffect } from 'react';

import PickListModel from './PickListModel';
import { PickerListCardContainerStyle } from './PickListCard.styled';

interface IPickListCard {
    item: PickListModel;
    onClickHandler: any;
    disabled?: boolean;
    small?: boolean;
}
const PickList = ({ item, onClickHandler, disabled = false, small = false }: IPickListCard) => {
    const [selected, setSelected] = useState<boolean>(item.isSelected);

    useEffect(() => {}, [selected]); //TODO: tá vazio!

    return (
        <PickerListCardContainerStyle
            small={small}
            isSelected={item.isSelected}
            onClick={() => {
                if (!disabled) {
                    onClickHandler(item);
                    setSelected(item.isSelected);
                }
            }}
        >
            {item.descricao}
        </PickerListCardContainerStyle>
    );
};

export default PickList;
