import Row from 'components/Row';
import { ToastSuccess } from 'components/Toaster';
import AdminHeaderService from 'core/http/service/admin/AdminHeader.service';
import { cloneDeep } from 'lodash';
import AdminHeaderItem, { NULL_CODE_PARENT } from 'models/admin/AdminHeaderItem';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import React, { useState, useEffect } from 'react';
import { BiEdit } from 'react-icons/bi';
import { BsPlusCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

import Styled from './AdminHeader.styled';
import AdminHeaderModal from './AdminHeaderModal';

export interface IAdminHeader {}

const service = new AdminHeaderService();

const AdminHeader = ({}: IAdminHeader) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [headerItens, setHeaderItens] = useState<AdminHeaderItem[]>([]);
    const [selectedItemId, setSelectedItemId] = useState<number>(-1);

    const feachHeaderItems = async () => {
        const { data } = await service.buscarHeader('TODOS');
        const _headerItens = Array.from(data).map((h: any) => {
            const _item = new AdminHeaderItem().fromJSON(h);
            _item.subheaders = Array.from(h.subheaders).map((sub: any) => new AdminHeaderItem().fromJSON(sub));
            return _item;
        });

        setHeaderItens(_headerItens);
    };

    const hasParent = (item: AdminHeaderItem): boolean => {
        return item.codigoHeaderPai != null && item.codigoHeaderPai != NULL_CODE_PARENT;
    };

    const addNewHeader = (logado: boolean) => {
        const newHeader = new AdminHeaderItem().fromJSON({
            codigo: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
            ordem: headerItens.filter(i => i.codigoHeaderPai == null && i.logado == logado).length,
            logado,
            isNew: true,
        });

        setHeaderItens([...headerItens, newHeader]);
        setSelectedItemId(newHeader.codigo);
    };

    const clearTempHeaders = () => {
        setHeaderItens(headerItens.filter(h => !h.isNew));
    };

    const submit = async (dataInsert: AdminHeaderItem) => {
        if (dataInsert.url.trim().length > 0 && !dataInsert.url.startsWith('#')) {
            dataInsert.url = '/' + dataInsert.url.replace(/^\//g, '').replace(/\s/g, '-');
        }

        const sanitizedData = dataInsert.toJSON();

        if (dataInsert.codigoHeaderPai < 1) sanitizedData.codigo_header_pai = null;

        delete sanitizedData.subheaders;
        delete sanitizedData.codigoHeaderPai;

        let data: any;

        if (dataInsert.isNew) {
            delete sanitizedData.codigo;
            data = (await service.inserirHeader(sanitizedData)).data;
        } else data = (await service.editarHeader(sanitizedData)).data;

        const itemSaved = new AdminHeaderItem().fromJSON(data);

        for (let i = 0; i < dataInsert.subheaders.length; i++) {
            const sanitizedDataSub: any = dataInsert.subheaders[i].toJSON();
            sanitizedDataSub.codigo_header_pai = itemSaved.codigo;
            sanitizedDataSub.url = '/' + sanitizedDataSub.url.replace(/^\//g, '').replace(/\s/g, '-');

            let data: any;
            delete sanitizedDataSub.subheaders;

            if (sanitizedDataSub.isNew) {
                delete sanitizedDataSub.codigo;
                data = (await service.inserirHeader(sanitizedDataSub)).data;
            } else data = (await service.editarHeader(sanitizedDataSub)).data;

            itemSaved.subheaders.push(new AdminHeaderItem().fromJSON(data));
        }

        ToastSuccess('Header salvo com sucesso!');

        feachHeaderItems();
        setSelectedItemId(-1);
    };

    const deleteHeader = async (itemId: number) => {
        const { status } = await service.excluirHeader(itemId);

        setSelectedItemId(-1);

        if (status < 300) {
            ToastSuccess('Item salvo com sucesso!');
        }

        feachHeaderItems();
    };

    const saveHeader = () => {
        ToastSuccess('Header salvo com sucesso!');
    };

    useEffect(() => {
        feachHeaderItems();
    }, []);

    return (
        <>
            {selectedItemId != -1 && (
                <AdminHeaderModal
                    allItens={headerItens}
                    itemId={selectedItemId}
                    show={selectedItemId != -1}
                    onHide={() => {
                        setSelectedItemId(-1);
                        clearTempHeaders();
                    }}
                    onSubmit={submit}
                    onDelete={deleteHeader}
                />
            )}

            <p>{JSON.stringify}</p>

            <Styled.Container>
                <AdminBreadCrumb
                    crumbs={[
                        {
                            label: 'Gestão do header',
                            route: '/header',
                        },
                    ]}
                />

                <br />
                <Styled.Title>Gestão do header</Styled.Title>

                <Styled.Division />

                <Styled.ContentGrid>
                    <Styled.Column>
                        <Styled.Subtitle>
                            Header usuário <strong>Deslogado</strong>
                        </Styled.Subtitle>
                        <br />

                        {headerItens
                            .filter(h => !h.logado && !hasParent(h) && !h.isNew)
                            .sort((a, b) => a.ordem - b.ordem)
                            .map(h => {
                                return (
                                    <React.Fragment key={h.codigo}>
                                        <Styled.Item>
                                            <span className="label">{h.nome}</span>

                                            <span
                                                className="icon"
                                                onClick={() => setSelectedItemId(h.codigo)}
                                                data-tip={'Editar'}
                                                data-for={`tooltip-id-${h.codigo}`}
                                            >
                                                <BiEdit />
                                            </span>

                                            <ReactTooltip
                                                id={`tooltip-id-${h.codigo}`}
                                                effect="solid"
                                                place="top"
                                                delayShow={1000}
                                                backgroundColor={'#003C78'}
                                            />
                                        </Styled.Item>

                                        {h.subheaders
                                            .sort((a, b) => a.ordem - b.ordem)
                                            .map(hh => {
                                                return (
                                                    <Styled.Item key={hh.codigo} isSub>
                                                        <span className="label">{hh.nome}</span>
                                                    </Styled.Item>
                                                );
                                            })}
                                    </React.Fragment>
                                );
                            })}

                        <Row justify="space-between" style={{ marginTop: '20px' }}>
                            <Styled.RoundButton
                                onClick={() => addNewHeader(false)}
                                data-tip={'Adicionar item'}
                                data-for={`tooltip-bt-add-unlogged`}
                            >
                                <BsPlusCircle />
                            </Styled.RoundButton>

                            <ReactTooltip
                                id={`tooltip-bt-add-unlogged`}
                                effect="solid"
                                place="top"
                                delayShow={1000}
                                backgroundColor={'#003C78'}
                            />

                            <Styled.SaveButton onClick={saveHeader}>Salvar header deslogado</Styled.SaveButton>
                        </Row>
                    </Styled.Column>

                    <Styled.DivisionPadding>
                        <div className="line" />
                    </Styled.DivisionPadding>

                    <Styled.Column>
                        <Styled.Subtitle>
                            Header usuário <strong>Logado</strong>
                        </Styled.Subtitle>

                        {headerItens
                            .filter(h => h.logado && !hasParent(h) && !h.isNew)
                            .sort((a, b) => a.ordem - b.ordem)
                            .map(h => {
                                return (
                                    <React.Fragment key={h.codigo}>
                                        <Styled.Item>
                                            <span className="label">{h.nome}</span>
                                            <span
                                                className="icon"
                                                data-tip={'Editar'}
                                                data-for={`tooltip-id-${h.codigo}`}
                                                onClick={() => setSelectedItemId(h.codigo)}
                                            >
                                                <BiEdit />
                                            </span>

                                            <ReactTooltip
                                                id={`tooltip-id-${h.codigo}`}
                                                effect="solid"
                                                place="top"
                                                delayShow={1000}
                                                backgroundColor={'#003C78'}
                                            />
                                        </Styled.Item>

                                        {h.subheaders
                                            .sort((a, b) => a.ordem - b.ordem)
                                            .map(hh => {
                                                return (
                                                    <Styled.Item key={hh.codigo} isSub>
                                                        <span className="label">{hh.nome}</span>
                                                    </Styled.Item>
                                                );
                                            })}
                                    </React.Fragment>
                                );
                            })}

                        <Row justify="space-between" style={{ marginTop: '20px' }}>
                            <Styled.RoundButton
                                onClick={() => addNewHeader(true)}
                                data-tip={'Adicionar item'}
                                data-for={`tooltip-bt-add-logged`}
                            >
                                <BsPlusCircle />
                            </Styled.RoundButton>

                            <ReactTooltip id={`tooltip-bt-add-logged`} effect="solid" place="top" delayShow={1000} backgroundColor={'#003C78'} />

                            <Styled.SaveButton onClick={saveHeader}>Salvar header logado</Styled.SaveButton>
                        </Row>
                    </Styled.Column>
                </Styled.ContentGrid>
            </Styled.Container>
        </>
    );
};

export default AdminHeader;
