import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico from './PerfilDTOGenerico';

class TipoEnsinoDTO extends PerfilDTOGenerico {
    constructor() {
        super();
    }
}

export default TipoEnsinoDTO;
