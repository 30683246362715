import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Styled from '../AdminBiblioteca.styled';

import editIcon from '../../../../../images/admin/edit.svg';
import trashIcon from '../../../../../images/admin/trash.svg';
import searchIcon from '../../../../../images/biblioteca/search-icon-grey.svg';

import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import AdminBiblioItemPreview from 'models/admin/AdminBiblioItemPreview';
import { DateHelp } from 'core/util/DateHelp';

import capitalize from 'pages/admin/utils/capitalize';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import { base64XLSToFile } from 'pages/admin/utils/base64ToFile';
import { cloneDeep, map } from 'lodash';
import Loading from 'components/Loading';
import { fetchItemId, fetchTagsItemId } from 'pages/admin/components/newContent/submitAttach';
import { StringHelp } from '../../../../../core/util/StringHelp';
import Badge from 'components/badge/Badge';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select, { SelectOptionType } from 'components/Select';
import BiblioItemCategoriaModel from 'models/biblioteca/BiblioItemCategoriaModel';
import { AdminContext } from 'pages/admin/context/AdminContext';
import Visualizacao from 'pages/pdp/components/Visualizacao';
import AdminEditorStyled from 'pages/admin/components/pageEditors/AdminEditor.styled';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ToastSuccess } from 'components/Toaster';

export interface IAdminBiblioPostagem {}

const service = new AdminBiblioService();

const defaultOption: SelectOptionType = { label: 'Todos', value: -1 };

const AdminBiblioPostagem = ({}: IAdminBiblioPostagem) => {
    const optionsOrder: SelectOptionType[] = [
        {
            label: 'Mais recentes',
            value: 'ultimos_adicionados:desc',
        },
        {
            label: 'Ordem Alfabética',
            value: 'titulo:asc',
        },
    ];

    const statusFilter: SelectOptionType[] = [
        {
            label: 'Todos',
            value: 0,
        },
        {
            label: 'Publicado',
            value: 'PUBLICADO',
        },
        {
            label: 'Rascunho',
            value: 'RASCUNHO',
        },
    ];

    const conteudoOptions: SelectOptionType[] = [
        {
            label: 'Todos',
            value: '',
        },
        {
            label: 'Sim',
            value: 'TRUE',
        },
        {
            label: 'Não',
            value: 'FALSE',
        },
    ];

    const visualizacaoOptions: SelectOptionType[] = [
        {
            label: 'Todos',
            value: '',
        },
        {
            label: 'Público',
            value: '1',
        },
        {
            label: 'Privado',
            value: '0',
        },
    ];

    const [searchContent, setSearchContent] = useState('');
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState(optionsOrder[0]);
    const [totalPages, setTotalPages] = useState(1);
    const [items, setItems] = useState<AdminBiblioItemPreview[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [cleanKey, setCleanKey] = useState(0);
    const [_items, _setItems] = useState<BiblioItemCategoriaModel[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const _isMounted = React.useRef(true);
    const { subPage, setSubPage } = useContext(AdminContext.Context);
    const [zindexCalendar, setZindexCalendar] = useState(1);

    const [categorias, setCategorias] = useState<BiblioItemCategoriaModel[]>([]);

    const history = useHistory();

    interface IFiltro {
        status: string | null;
        tipo: number | null;
        conteudo: string | null;
        visualizacao: number | null;
        dataInicio: Date | null;
        dataFim: Date | null;
    }

    const [filtro, setFiltro] = useState<IFiltro>({
        status: null,
        tipo: 0,
        conteudo: null,
        visualizacao: null,
        dataInicio: null,
        dataFim: null,
    });

    const changePage = (_page: number) => {
        setPage(_page);
    };

    const fetchItens = async (filtro?: string, order?: string) => {
        setLoading(true);
        const service = new BiblioConteudoService();
        const { data } = await service.buscarTodosItens(page, 20, filtro, order);
        if (_isMounted.current) {
            const rows = data.rows;
            const _items: AdminBiblioItemPreview[] = rows.map((_data: any) => new AdminBiblioItemPreview().fromJSON(_data));
            setTotalPages(data.totalPages);
            setItems(_items);
            setLoading(false);
            setTotalItems(data.range);
        }
        setLoading(false);
    };

    const getOptionsCategorias = (): SelectOptionType[] => {
        return _items.map(
            (item: BiblioItemCategoriaModel) =>
                ({
                    label: capitalize(item.descricao),
                    value: item.id,
                } as SelectOptionType)
        );
    };

    const fetchItemCategoria = async () => {
        const service = new BiblioConteudoService();
        const { data } = await service.buscarCategorias(true);

        const _items: BiblioItemCategoriaModel[] = data.map((item: any) => new BiblioItemCategoriaModel().fromJSON(item));
        _setItems(_items);
    };

    const openCalendar = () => {
        setZindexCalendar(11);
    };

    const closeCalendar = () => {
        setZindexCalendar(1);
    };

    const fetchItensFilter = async () => {
        setLoading(true);
        const service = new BiblioConteudoService();
        const porPagina = 20;

        let _filtro: any = {};

        if (filtro.tipo && filtro.tipo != -1) _filtro['tipo'] = filtro.tipo;
        if (filtro.status) _filtro['status'] = filtro.status;
        if (filtro.conteudo) _filtro['relacionado'] = filtro.conteudo;
        if (searchContent != null) _filtro['filtro'] = searchContent;
        if (filtro.visualizacao != null) _filtro['publico'] = filtro.visualizacao;
        if (filtro.dataInicio != null) _filtro['dataInicio'] = filtro.dataInicio.toLocaleDateString();
        if (filtro.dataFim != null) _filtro['dataFim'] = filtro.dataFim.toLocaleDateString();

        const { data } = await service.buscarTodosFiltrados(porPagina, _filtro, page, order.value);
        if (_isMounted.current) {
            const rows = data.rows;
            const _items: AdminBiblioItemPreview[] = rows.map((_data: any) => new AdminBiblioItemPreview().fromJSON(_data));

            setTotalPages(data.totalPages);
            setItems(_items);
            setLoading(false);
            setTotalItems(data.range);

            if(data.totalPages === 1)  setPage(1);
        }
        setLoading(false);
    };

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';

    const DatePickerInicioWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            selected={filtro.dataInicio}
            onCalendarOpen={openCalendar}
            onCalendarClose={closeCalendar}
            onChange={(date: Date) => {
                closeCalendar();
                setFiltro({
                    ...filtro,
                    dataInicio: date ?? null,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    const DatePickerFimWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            selected={filtro.dataFim}
            onCalendarOpen={openCalendar}
            onCalendarClose={closeCalendar}
            onChange={(date: Date) => {
                closeCalendar();
                setFiltro({
                    ...filtro,
                    dataFim: date ?? null,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    const cleanFilter = () => {
        setFiltro({ status: null, tipo: 0, conteudo: null, visualizacao: null, dataInicio: null, dataFim: null });
        setSearchContent('');
        _isMounted.current = true;
        setCleanKey(Math.random());
        fetchItensFilter();
    };

    const gotoNewContent = () => {
        history.push('/admin/biblioteca/novo');
    };

    const categoriaExistente = (categoria: string): boolean => {
        return categorias.filter(c => c.ativo).some(c => c.descricao == categoria);
    };

    useEffect(() => {
        fetchItensFilter();
    }, [page, filtro]);

    useEffect(() => {
        if(searchContent.length === 0){
          fetchItensFilter();
        } 
            
    }, [searchContent]);

    useEffect(() => {
        if (!isLoading) fetchItens();
        fetchItemCategoria();
        fetchItens('', String(order.value));
    }, []);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        };
    }, []);

    const downloadXLS = async () => {
        const response = await service.downloadXLSItens();

        if (response.status < 300 && response.data) {
            base64XLSToFile(response.data, `IAS-BibliotecaItens-${DateHelp.formatDateFull(new Date()).replaceAll('/', '-')}.xls`);
        }
    };

    const editRow = (itemId: number) => {
        history.push('/admin/biblioteca/edit/' + itemId);
    };

    const disableItem = async (idx: number) => {
        const clone = cloneDeep(items);

        const response = await service.atualizarStatusVisualizacao(clone[idx].id, !clone[idx].public);

        const isPublic = response.data.publico;

        if (response.status === 200) {
            clone[idx].public = isPublic;

            ToastSuccess(`Conteúdo ${isPublic ? 'públicado' : 'privado'} com sucesso!`);

            setItems(clone);
        }
    };

    const excludeItem = async (itemId: number) => {
        await service.removerItem(itemId);
        fetchItens();
    };

    return (
        <Styled.Container>
            <Styled.Title>Postagens</Styled.Title>
            <br />
            <Styled.RowSpace>
                <Styled.SearchContainer>
                    <Styled.SearchInput
                        placeholder="O que você está procurando?"
                        value={searchContent}
                        onChange={(env: any) => setSearchContent(env.target.value)}
                    />
                    <Styled.SearchIconButton src={searchIcon} onClick={() => fetchItensFilter()} />
                </Styled.SearchContainer>

                <Styled.ContainerButtons>
                    <br />
                    <br />
                    <OutlineButton style={{ marginRight: '10px' }} onClick={downloadXLS}>
                        Baixar.xls
                    </OutlineButton>

                    <GradientButton style={{ marginTop: '10px' }} onClick={gotoNewContent}>
                        Adicionar conteúdo
                    </GradientButton>
                </Styled.ContainerButtons>
            </Styled.RowSpace>

            <br />
            <br />
            <div key={cleanKey}>
                <Styled.DivRowRight style={{ zIndex: 10 }}>
                    <Styled.RowRigth>
                        <span className="label-order">Ordenar por:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={optionsOrder}
                                defaultValue={optionsOrder[0]}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        ...filtro,
                                        conteudo: filtro.conteudo,
                                        visualizacao: filtro.visualizacao,
                                        tipo: filtro.tipo,
                                        status: filtro.status,
                                    };

                                    setOrder(o);
                                    setFiltro(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Tipo:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={[defaultOption, ...getOptionsCategorias()]}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        ...filtro,
                                        conteudo: filtro.conteudo,
                                        visualizacao: filtro.visualizacao,
                                        status: filtro.status,
                                        tipo: o.value !== 0 ? Number(o.value) : null,
                                    };
                                    setFiltro(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>

                    <Styled.RowRigth>
                        <span className="label-order">Status:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={statusFilter}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        ...filtro,
                                        conteudo: filtro.conteudo,
                                        visualizacao: filtro.visualizacao,
                                        tipo: filtro.tipo,
                                        status: o.value !== 0 ? String(o.value) : null,
                                    };
                                    setFiltro(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                </Styled.DivRowRight>
                <Styled.DivRowRight style={{ zIndex: 9 }}>
                    <Styled.RowRigth>
                        <span className="label-order">Conteúdo Relacionado:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={conteudoOptions}
                                defaultValue={conteudoOptions[0]}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        ...filtro,
                                        conteudo: o.value !== 0 ? String(o.value) : null,
                                        visualizacao: filtro.visualizacao,
                                        tipo: filtro.tipo,
                                        status: filtro.status,
                                    };
                                    setFiltro(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Visualização:</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={visualizacaoOptions}
                                defaultValue={visualizacaoOptions[0]}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        ...filtro,
                                        conteudo: filtro.conteudo,
                                        visualizacao: o.value !== '' ? Number(o.value) : null,
                                        tipo: filtro.tipo,
                                        status: filtro.status,
                                    };
                                    setFiltro(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Resultados encontrados: </span>
                        <span style={{ fontSize: '14px' }}>{totalItems}</span>
                    </Styled.RowRigth>
                </Styled.DivRowRight>
                <Styled.DivRowRight style={{ zIndex: zindexCalendar }}>
                    <Styled.RowRigth>
                        <span className="label-order">Data de início: </span>
                        <DatePickerInicioWrapper />
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Data final: </span>
                        <DatePickerFimWrapper />
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <OutlineButton onClick={cleanFilter} style={{ height: '40px' }}>
                            Limpar filtros
                        </OutlineButton>
                    </Styled.RowRigth>
                </Styled.DivRowRight>
            </div>
            {!isLoading ? (
                <Styled.GridPost>
                    <Styled.GridHead>Status</Styled.GridHead>
                    <Styled.GridHead>Tipo</Styled.GridHead>
                    <Styled.GridHead>Título do conteúdo</Styled.GridHead>
                    <Styled.GridHead>Conteúdo relacionado</Styled.GridHead>
                    <Styled.GridHead>Visualização</Styled.GridHead>
                    <Styled.GridHead>Data de envio</Styled.GridHead>
                    <Styled.GridHead>Responsável</Styled.GridHead>
                    <Styled.GridHead>Ações</Styled.GridHead>

                    {items.map((data, idx) => (
                        <React.Fragment key={data.id}>
                            <Styled.GridCol>
                                <Styled.Dot publicated={data.status == 'PUBLICADO'}>&bull;</Styled.Dot>
                                <Styled.TextStatus>{data.status}</Styled.TextStatus>
                            </Styled.GridCol>
                            <Styled.GridCol>{capitalize(data.category)}</Styled.GridCol>
                            <Styled.GridCol>{StringHelp.toSize(data.title, 40)}</Styled.GridCol>
                            <Styled.GridCol>
                                <Badge color={data.relacionado ? '#229645' : '#D64545'}>{data.relacionado ? 'Sim' : 'Não'}</Badge>
                            </Styled.GridCol>
                            <Styled.GridCol>
                                <Badge color={data.public ? '#229645' : '#D64545'}>{data.public ? 'Público' : 'Privado'}</Badge>
                            </Styled.GridCol>
                            <Styled.GridCol>{DateHelp.formatDateFull(data.dateCriacao)}</Styled.GridCol>
                            <Styled.GridCol>{data.userSender}</Styled.GridCol>
                            <Styled.GridCol>
                                <OverlayTrigger
                                    overlay={<Tooltip id={'tooltip-action-edit_' + data.id}>Editar publicação</Tooltip>}
                                    placement={'top'}
                                >
                                    <Styled.Icon
                                        src={editIcon}
                                        onClick={() => {
                                            editRow(data.id);
                                            setSubPage('BiblioPageEdition');
                                        }}
                                    />
                                </OverlayTrigger>

                                {data.status !== 'RASCUNHO' ? (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={'tooltip-action-disable_' + data.id}>
                                                {`${!data.public ? 'Publicar' : 'Privar'}`} item
                                            </Tooltip>
                                        }
                                        placement={'top'}
                                    >
                                        <span>
                                            <AdminSwitch value={data.public} onChange={() => disableItem(idx)} />
                                        </span>
                                    </OverlayTrigger>
                                ) : (
                                    <OverlayTrigger
                                        overlay={<Tooltip id={'tooltip-action-edit_' + data.id}>Excluir publicação</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Styled.Icon src={trashIcon} onClick={() => excludeItem(data.id)} />
                                    </OverlayTrigger>
                                )}
                            </Styled.GridCol>
                        </React.Fragment>
                    ))}
                </Styled.GridPost>
            ) : (
                <Styled.LoadingContainer>
                    <Loading />
                </Styled.LoadingContainer>
            )}
            <br />
            <br />

            <AdminPagination onChangePage={changePage} total={totalPages} />
        </Styled.Container>
    );
};

export default AdminBiblioPostagem;
