import { Drawer, Input, notification } from 'antd';
import Colors from 'enums/Colors';
import UsuarioDTO from 'models/UsuarioDTO';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as authenticationActions from '../../../store/actions/Authentication';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { ProfileService } from 'core/http/service/Profile.service';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import PerfilEditDTO from 'models/perfil/PerfilEditDTO';
import { useHistory } from 'react-router-dom';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import ImageProfile from 'pages/editProfile/ImageProfile';
import { Formik } from 'formik';
import { MobileMaxWidth } from 'constants/Dimensions';
import * as yup from 'yup';
import { MaskMobile, maskOnlyNumbers } from 'core/util/MaskHelper';
import { PrimaryButton } from 'components/UI/Buttons';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import useUserData from 'hooks/useUserData';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 27.2px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 26px;
        color: ${Colors.Blue};
        gap: 16px;
    `,

    Button: styled.button`
        width: 180px;
        margin-top: 20px;
        height: 40px;
        border-radius: 8px;
        background-color: ${Colors.Yellow};
        font-size: 16px;
        font-family: 'Barlow';
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0.15000000596046448px;
        border: none;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media only screen and (max-width: 950px) {
            width: 100%;
            margin: 0;
            padding: 15px;
        }
    `,

    Row: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 6px;
        flex-wrap: wrap;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 10px;
        }
    `,

    BigLabel: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        margin-left: 10px;

        @media only screen and (min-width: 0px) and (max-width: 760px) {
            margin-left: 15px;
        }
    `,

    Label: styled.p`
        color: #2d3648;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    `,

    Input: styled.input`
        background: #ffffff;
        border: 2px solid #cbd2e0;
        border-radius: 6px;
        width: 324px;
        height: 48px;
        padding-left: 7px;

        @media only screen and (max-width: 950px) {
            width: 100%;
        }
    `,

    PrimaryButton: styled(PrimaryButton)`
        @media screen and (max-width: 950px) {
            width: 170px;
            margin-left: 10px;
        }
    `,

    TextAlert: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #2e2e2e;

        b {
            color: ${Colors.SoftBlue};
            cursor: pointer;
        }
    `,

    LoadingSave: styled.div`
        /* HTML: <div class="loader"></div> */
        width: 60px;
        aspect-ratio: 4;
        --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #0000);
        background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
        background-size: calc(100% / 3) 100%;
        animation: l7 1s infinite linear;
        @keyframes l7 {
            33% {
                background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
            }
            50% {
                background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
            }
            66% {
                background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
            }
        }
    `,
};

interface IMeuPerfil {
    open: boolean;
    onClose: () => void;
    reloadInfo: (nome: string, sobrenome: string) => void;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const MeuPerfil = ({ open, onClose, reloadInfo }: IMeuPerfil) => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const service = new ProfileService();
    const kcService = new KeycloakService();
    const hystory = useHistory();
    const [usuarioLogado, setUsuarioLogado] = useState<UsuarioDTO | null>(null);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [imageBase64, setImageBase64] = useState<string>('');

    const [api, contextHolder] = notification.useNotification();

    const { loadData } = useUserData();

    // loadings
    const [submitting, setSubmitting] = useState(false);
    const [submittingaPass, setSubmittingPass] = useState(false);

    const [pass, setPass] = useState('');
    const [confirmpass, setConfirmpass] = useState('');

    const [initialValues, setInitialValues] = useState<IValues>({
        nome: '',
        sobrenome: '',
        email: '',
        whatsapp: '',
    });

    interface IValues {
        nome: string;
        sobrenome: string;
        email: string;
        whatsapp: string;
    }

    const submit = async (data: any) => {
        const changingPass = data.senha != undefined;

        try {
            if (imageBase64) {
                data.imageBase64 = imageBase64;
            }

            changingPass ? setSubmittingPass(true) : setSubmitting(true);
            const { status } = await service.editProfile(usuarioLogado?.username ?? '', data);

            if (status == 200) {
                keycloak.updateToken(-1).then(refreshed => {
                    console.log('TOKEN REFRESHED? ', refreshed);
                });

                if (changingPass) {
                    openNotificationWithIcon(
                        'success',
                        'Senha atualizada!',
                        'A sua senha foi atualizada com sucesso, você precisará fazer o login novamente em alguns instantes!',
                        true
                    );
                } else {
                    openNotificationWithIcon('success', 'Perfil atualizado!', 'O seu perfil foi atualizado com sucesso!', false);
                    reloadInfo(data.nome, data.sobrenome);
                }
            } else if (status == 409) {
                openNotificationWithIcon('error', 'E-mail já em uso!', 'Perfil não atualizado! E-mail já em uso no sistema.', false);
            }
        } catch (error) {
            openNotificationWithIcon(
                'warning',
                'Instabilidade!',
                'Não conseguimos atualizar seu perfil no momento, tente novamente mais tarde!',
                false
            );
        } finally {
            changingPass ? setSubmittingPass(false) : setSubmitting(false);
        }
    };

    const validPass = () => {
        const re = /[A-Za-z\d@$!%*#?&]/g;
        return pass.length >= 3 && confirmpass == pass && re.test(pass);
    };

    const loadUser = async () => {
        const { perfilUsuario } = await loadData();

        const response: any = await kcService.getUsuarioLogado();
        if (response.status === 200) {
            const responseUsuario = new UsuarioDTO().fromJSON(response.data);
            dispatch(authenticationActions.setUsuarioLogado(responseUsuario));
            if (responseUsuario) {
                setUsuarioLogado(responseUsuario);

                let { data } = await service.getUserEdit(responseUsuario.username);
                const dados = new PerfilEditDTO().fromJSON(data);
                setPreviewImage(dados.imageBase64);
                setInitialValues({
                    nome: dados.nome ?? '',
                    sobrenome: dados.sobrenome ?? '',
                    email: perfilUsuario?.email ?? '',
                    whatsapp: dados.whatsapp ?? '',
                });
            }
        }
    };

    const openNotificationWithIcon = (type: NotificationType, title: string, msg: string, redirectLogin: boolean) => {
        api[type]({
            message: title,
            description: msg,
            duration: 5,
            onClose() {
                if (redirectLogin) {
                    hystory.push('/logout?redirect=login');
                }
            },
        });
    };

    const yupValidation = yup.object().shape({
        nome: yup.string().min(1).required(),
        sobrenome: yup.string().min(1).required(),
        email: yup.string().email().required(),
        whatsapp: yup.string().min(13),
    });

    const deleteProfile = () => {
        window.open('https://humaneias.zendesk.com/hc/pt-br/requests/new');
        setOpenDialog(true);
    };

    useEffect(() => {
        loadUser();
    }, [open]);

    useEffect(() => {
        loadUser();
    }, []);

    return (
        <Styled.Container>
            {contextHolder}
            <Drawer style={{ zIndex: 1001, backgroundColor: '#fcfcfc' }} size="large" onClose={onClose} open={open}>
                <Styled.Container>
                    <Styled.Title>Meu Perfil</Styled.Title>
                    <Styled.Content>
                        <ImageProfile
                            profile={profile}
                            username={usuarioLogado?.primeiroNome ?? ''}
                            onSelect={(base64: string, file: File) => setImageBase64(base64)}
                            preImageB64={previewImage}
                        />
                        <Styled.Title>Dados básicos</Styled.Title>
                        <Formik initialValues={initialValues} onSubmit={submit} validationSchema={yupValidation} enableReinitialize>
                            {formik => {
                                return (
                                    <>
                                        <Styled.Row>
                                            <Styled.Column>
                                                <Styled.Label>Primeiro Nome</Styled.Label>
                                                <Input
                                                    size="large"
                                                    style={{ width: '324px', height: '48px' }}
                                                    placeholder="Digite o seu primeiro nome"
                                                    value={formik.values['nome']}
                                                    onChange={e => {
                                                        formik.setFieldValue('nome', e.target.value);
                                                    }}
                                                />
                                            </Styled.Column>

                                            <Styled.Column>
                                                <Styled.Label>Sobrenome</Styled.Label>
                                                <Input
                                                    size="large"
                                                    style={{ width: '324px', height: '48px' }}
                                                    placeholder="Digite seu sobrenome"
                                                    value={formik.values['sobrenome']}
                                                    onChange={e => {
                                                        formik.setFieldValue('sobrenome', e.target.value);
                                                    }}
                                                />
                                            </Styled.Column>
                                        </Styled.Row>

                                        <Styled.Row>
                                            <Styled.Column>
                                                <Styled.Label>E-mail</Styled.Label>
                                                <Input
                                                    size="large"
                                                    placeholder="Digite seu e-mail"
                                                    style={{ width: '324px', height: '48px' }}
                                                    value={formik.values['email']}
                                                    onChange={e => {
                                                        formik.setFieldValue('email', e.target.value);
                                                    }}
                                                />
                                            </Styled.Column>

                                            <Styled.Column>
                                                <Styled.Label>Whatsapp</Styled.Label>
                                                <Input
                                                    size="large"
                                                    style={{ width: '324px', height: '48px', color: Colors.SoftBlue }}
                                                    placeholder="(00) 00000-0000"
                                                    maxLength={14}
                                                    value={formik.values['whatsapp']}
                                                    onKeyDown={e => {
                                                        if (e.key == 'Backspace') {
                                                            formik.setFieldValue('whatsapp', maskOnlyNumbers(formik.values['whatsapp']));
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        formik.setFieldValue('whatsapp', MaskMobile(e.target.value));
                                                    }}
                                                    onPaste={e => {
                                                        e.preventDefault();
                                                        return false;
                                                    }}
                                                />
                                            </Styled.Column>
                                        </Styled.Row>
                                        <Styled.Row>
                                            <Styled.Title style={{ position: 'relative', top: '20px' }}>Senha de acesso</Styled.Title>
                                            <BlueButton
                                                style={{ borderRadius: '8px' }}
                                                onClick={() => formik.handleSubmit()}
                                                disabled={submitting}
                                            >
                                                {submitting ? <Styled.LoadingSave /> : 'Salvar dados'}
                                            </BlueButton>
                                        </Styled.Row>
                                    </>
                                );
                            }}
                        </Formik>

                        <Styled.Row>
                            <Styled.Column>
                                <Styled.Label>Nova senha</Styled.Label>
                                <Input
                                    size="large"
                                    style={{ width: '324px', height: '48px' }}
                                    type={'password'}
                                    placeholder="Digite uma nova senha"
                                    autoComplete={'new-password'}
                                    value={pass}
                                    onChange={(e: any) => {
                                        setPass(e.target.value);
                                    }}
                                />

                                {/* <Styled.SansCript>A senha deve ter no mínimo 8 caracteres</Styled.SansCript> */}
                            </Styled.Column>

                            <Styled.Column>
                                <Styled.Label>Confirmar nova senha</Styled.Label>
                                <Input
                                    size="large"
                                    style={{ width: '324px', height: '48px' }}
                                    type={'password'}
                                    autoComplete={'new-password'}
                                    placeholder="Confirme a nova senha"
                                    value={confirmpass}
                                    onChange={(e: any) => {
                                        setConfirmpass(e.target.value);
                                    }}
                                />
                            </Styled.Column>
                        </Styled.Row>
                        <Styled.Row>
                            <Styled.Title style={{ position: 'relative', top: '30px' }}>Excluir conta</Styled.Title>
                            <BlueButton
                                style={{ borderRadius: '8px', cursor: 'pointer' }}
                                onClick={() => submit({ senha: pass })}
                                disabled={!validPass() || submittingaPass}
                            >
                                {submittingaPass ? <Styled.LoadingSave /> : 'Salvar senha'}
                            </BlueButton>
                        </Styled.Row>
                        <Styled.TextAlert>
                            Atenção. Essa ação é irreversível e irá excluir sua conta e todo o seu progresso nas atividades humane. Se deseja
                            excluir sua conta, <b onClick={deleteProfile}>clique aqui</b> e fale com nosso suporte.
                        </Styled.TextAlert>
                    </Styled.Content>
                    <Styled.Button onClick={onClose}>Fechar</Styled.Button>
                </Styled.Container>
            </Drawer>
        </Styled.Container>
    );
};

export default MeuPerfil;
