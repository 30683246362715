import { TipoFuncionalidade } from 'models/Feedback';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import { FeedbackService } from 'core/http/service/FeedbackService.service';

export const showDialogFeedback = async (items: DiarioBordo[], profile: PerfilUsuario | null): Promise<boolean> => {
    const existsNoFixed = items.some(item => !item.isFixed);

    if (profile && existsNoFixed) {
        const service = new FeedbackService();
        const result: Object[] = (await service.existeRegistroFeedback(profile.codigo, TipoFuncionalidade.DIARIOBORDO)).data;
        if (result.length == 0) return true;
    }

    return false;
};
