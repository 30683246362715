import Email from 'models/Email';
import { GenericService } from './Generic.service';

export class EmailService extends GenericService {
    readonly URI: string = '/v1/email';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public async sendEmail(data: Email) {
        return this.post('sendEmail', data);
    }
}
