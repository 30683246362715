import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import editIcon from '../../../../../images/admin/edit.svg';
import trashIcon from '../../../../../images/admin/trash.svg';
import searchIcon from '../../../../../images/biblioteca/search-icon-grey.svg';

import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminTag from 'models/admin/AdminTag';
import { DateHelp } from 'core/util/DateHelp';

import AdminModalExcludeTag from './AdminModalExcludeTag';
import AdminModalEditTag from './AdminModalEditTag';
import AdminModalNewTag from './AdminModalNewTag';
import Loading from 'components/Loading';
import tagsPadrao from './tagsPadrao';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

export interface IAdminBiblioTags {}

const AdminBiblioTags = ({}: IAdminBiblioTags) => {
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [tags, setTag] = useState<AdminTag[]>([]);

    const [showIdModalExclude, setShowIdModalExclude] = useState<number>(-1);
    const [showIdModalEdit, setShowIdModalEdit] = useState<number>(-1);
    const [showModalNew, setShowModalNew] = useState(false);

    const [isLoading, setLoading] = useState<boolean>(false);

    const changePage = (page: number) => {
        setPage(page);
    };

    const fetchTags = async (filtro?: string) => {
        setLoading(true);
        const service = new AdminBiblioService();
        const { data } = await service.buscarTagsInicial(page, filtro);

        const rows = data.rows;
        setTotalPages(data.totalPages);

        const _tags: AdminTag[] = rows.map((tag: any) => new AdminTag().fromJSON(tag));

        setTag(_tags.filter(t => t.content.trim().length > 0));
        setLoading(false);
    };

    useEffect(() => {
        fetchTags();
    }, [page]);

    useEffect(() => {
        fetchTags();
    }, []);

    const isTagPadrao = (tag: string): boolean => {
        return tagsPadrao.some(t => t.toLowerCase() == tag.toLowerCase());
    };

    return (
        <Styled.Container style={{ padding: '20px' }}>
            <Styled.Title>Gestão de tags</Styled.Title>
            <br />

            <Styled.RowSpace>
                <Styled.SearchContainer>
                    <Styled.SearchInput placeholder="O que você está procurando?" value={term} onChange={env => setTerm(env.target.value)} />
                    <Styled.SearchIconButton src={searchIcon} onClick={() => fetchTags(term)} />
                </Styled.SearchContainer>

                <br />

                <GradientButton onClick={() => setShowModalNew(true)}>Criar Nova</GradientButton>
            </Styled.RowSpace>

            <br />
            <br />

            {!isLoading ? (
                <Styled.GridTags>
                    <Styled.GridHead style={{ justifyContent: 'flex-start', paddingLeft: '20px' }}>Nome da Tag</Styled.GridHead>
                    <Styled.GridHead>Data de criação</Styled.GridHead>
                    <Styled.GridHead>Quantidade de uso</Styled.GridHead>
                    <Styled.GridHead>Ações</Styled.GridHead>

                    {tags.map((tag, idx) => (
                        <React.Fragment key={idx}>
                            <Styled.GridCol2 style={{ justifyContent: 'flex-start', paddingLeft: '20px' }}>
                                <p>{tag.content}</p>
                                {isTagPadrao(tag.content) && <span>(Tag Padrão)</span>}
                            </Styled.GridCol2>
                            <Styled.GridCol>{DateHelp.formatDateFull(tag.ultimaAtualizacao)}</Styled.GridCol>
                            <Styled.GridCol>{tag.quantity ?? 0}</Styled.GridCol>
                            <Styled.GridCol>
                                <OverlayTrigger overlay={<Tooltip id={'tooltip-action-edit_' + tag.id}>Editar Tag</Tooltip>} placement={'top'}>
                                    <Styled.Icon
                                        disabled={isTagPadrao(tag.content)}
                                        src={editIcon}
                                        onClick={() => {
                                            if (!isTagPadrao(tag.content)) setShowIdModalEdit(tag.id);
                                        }}
                                    />
                                </OverlayTrigger>

                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <OverlayTrigger
                                        overlay={<Tooltip id={'tooltip-action-delete_' + tag.id}>Excluir Tag</Tooltip>}
                                        placement={'top'}
                                    >
                                        <Styled.Icon
                                            disabled={isTagPadrao(tag.content)}
                                            src={trashIcon}
                                            onClick={() => {
                                                if (!isTagPadrao(tag.content)) setShowIdModalExclude(tag.id);
                                            }}
                                        />
                                    </OverlayTrigger>
                                </AllowedGroupsValidator>
                            </Styled.GridCol>
                        </React.Fragment>
                    ))}
                </Styled.GridTags>
            ) : (
                <Styled.LoadingContainer>
                    <Loading />
                </Styled.LoadingContainer>
            )}

            <br />
            <br />

            <AdminPagination onChangePage={changePage} total={totalPages} />

            <AdminModalExcludeTag
                show={showIdModalExclude != -1}
                onDelete={() => {
                    setShowIdModalExclude(-1);
                    fetchTags();
                }}
                onHide={() => setShowIdModalExclude(-1)}
                tag={tags.find(t => t.id == showIdModalExclude)}
            />

            <AdminModalEditTag
                show={showIdModalEdit != -1}
                onEdit={() => {
                    setShowIdModalEdit(-1);
                    fetchTags();
                }}
                onHide={() => setShowIdModalEdit(-1)}
                tag={tags.find(t => t.id == showIdModalEdit)}
            />

            <AdminModalNewTag
                show={showModalNew}
                onHide={() => setShowModalNew(false)}
                onSaved={() => {
                    setShowModalNew(false);
                    fetchTags();
                }}
            />
        </Styled.Container>
    );
};

export default AdminBiblioTags;
