import _ from 'lodash';
import { AnyAction } from 'redux';

import ProfileData from '../../models/ProfileData';
import PerfilUsuario from '../../models/perfil/PerfilUsuario';
import LocalidadeDTO from '../../models/perfil/LocalidadeDTO';
import ParametrosCadastroPerfilDTO from '../../models/perfil/ParametrosCadastroPerfilDTO';
import { GET_PARAMETROS_CADASTRO, GET_CIDADE_POR_UF, PROFILE_DATA, CLEAR_PROFILE_DATA, USER_PROFILE, SET_IS_LOGGEDIN } from '../actions/Profile';

interface IState {
    userProfile: PerfilUsuario | null;
    cidadesPorUf: LocalidadeDTO[];
    profileData: ProfileData | null;
    parametrosCadastro: ParametrosCadastroPerfilDTO;
    isLoggedin: Boolean;
}

const initialState: IState = {
    cidadesPorUf: [],
    userProfile: null,
    profileData: null,
    parametrosCadastro: new ParametrosCadastroPerfilDTO(),
    isLoggedin: false,
};

const profileReducer = (state = initialState, action: AnyAction): IState => {
    switch (action.type) {
        case GET_PARAMETROS_CADASTRO: {
            return {
                ..._.cloneDeep(state),
                parametrosCadastro: action.parametrosCadastro,
            };
        }
        case GET_CIDADE_POR_UF: {
            return {
                ..._.cloneDeep(state),
                cidadesPorUf: action.cidadesPorUf,
            };
        }
        case PROFILE_DATA: {
            return {
                ..._.cloneDeep(state),
                profileData: action.profileData,
            };
        }
        case USER_PROFILE: {
            return {
                ..._.cloneDeep(state),
                userProfile: action.userProfile,
            };
        }
        case CLEAR_PROFILE_DATA: {
            return {
                ..._.cloneDeep(state),
                profileData: action.profileData,
                userProfile: null,
                isLoggedin: false,
            };
        }

        case SET_IS_LOGGEDIN: {
            return {
                ..._.cloneDeep(state),
                isLoggedin: action.isLoggedin,
            };
        }

        default: {
            return state;
        }
    }
};

export default profileReducer;
