import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import { GenericService } from './Generic.service';

export class PgIntermediariaConteudoRelacionadoService extends GenericService {
    readonly URI: string = '/v1/paginaIntermediariaConteudosRelacionados';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listarPorPagina(idPagina: number) {
        return this.get('listByPagina', { idPagina: idPagina });
    }

    public listarPorPaginaPublico(idPagina: number) {
        return this.get('public/listByPagina', { idPagina: idPagina });
    }

    public inserir(data: PgIntermediariaConteudoRelacionado) {
        return this.post('insert', data);
    }

    public editar(id: number, data: PgIntermediariaConteudoRelacionado) {
        return this.put(String(id), data);
    }

    public excluir(id: number) {
        return this.deleteByParams(String(id));
    }
}
