import styled from 'styled-components';

const StyledSecaoNumeroDestaque = {
    Container: styled.div<{ bgColor?: string }>`
        min-height: 400px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 5rem;
        background-color: ${({ bgColor }) => bgColor && bgColor};

        @media screen and (max-width: 700px) {
            padding: 20px;
        }
    `,
};

export default StyledSecaoNumeroDestaque;
