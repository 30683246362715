import React from 'react';
import Styled from './Support.styled';
import { ButtonHomeV2 } from '../button/ButtonHomeV2.styled';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import { useHistory } from 'react-router-dom';

const Support = () => {
    const history = useHistory();

    const goToSuporte = () => {
        GTM_Page_Home('secao_suporte', 'btn_click_acessar_suporte');
        history.push('/suporte');
    };

    return (
        <Styled.Container>
            <Styled.Title>Dúvidas e Suporte</Styled.Title>
            <Styled.Description>
                Está com dificuldades ou precisa de ajuda para navegar na humane? <br />
                Acesse nosso suporte.
            </Styled.Description>
            <ButtonHomeV2 onClick={goToSuporte}>Fale com o nosso suporte</ButtonHomeV2>
        </Styled.Container>
    );
};

export default Support;
