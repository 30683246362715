import React, { useEffect } from 'react';

import styled, { keyframes } from 'styled-components';

const SideImageContainer = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const SvgFadeImg = styled.img``;
//animation: ${fade} 0.3s linear;

interface ISideImage {
    image: any;
}
const SideImage = ({ image }: ISideImage) => {
    useEffect(() => {}, [image]);
    return (
        <SideImageContainer>
            <SvgFadeImg src={image} />
        </SideImageContainer>
    );
};

export default SideImage;
