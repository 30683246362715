import React, { useContext, useState } from 'react';
import Styled from './MobileMenu.styled';
import { FiSearch } from 'react-icons/fi';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import ActionButton from '../action-button/ActionButton';
import HeaderItem from 'models/admin/AdminHeaderItem';
import { GTM_Menu } from 'components/GoogleTagManager';
import { useHistory } from 'react-router-dom';
import useLogged from 'hooks/useLogged';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export interface IMobileMenu {
    items: HeaderItem[];
    itemActive: number;
    handleActive: (value: number) => void;
}

const MobileMenu = ({ items, itemActive, handleActive }: IMobileMenu) => {
    const { openSearch } = useContext(PesquisaContext.Context);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    const logged = useLogged();
    const history = useHistory();

    const onClickHandler = (item: HeaderItem) => {
        if (item.subheaders.length > 0) return;

        GTM_Menu('menu_mobile', item.eventoGTM);

        if (item.url.startsWith('#')) {
            setIsMobileMenuVisible(false);
            const elem = document.querySelector(item.url);
            elem && elem.scrollIntoView();
        } else if (!item.jornadaConcluida) history.push(item.url);
        else logged.login(item.url);
    };

    return (
        <>
            <Styled.MobileMenuButtonContainer>
                <Styled.SearchButtonContainer onClick={openSearch} style={{ transform: 'scale(0.85) translateY(-2px)', marginRight: '20px' }}>
                    <FiSearch />
                </Styled.SearchButtonContainer>

                <Styled.MobileMenuIcon
                    onClick={() => {
                        setIsMobileMenuVisible(true);
                    }}
                />
            </Styled.MobileMenuButtonContainer>
            <Styled.MobileMenu open={isMobileMenuVisible}>
                <Styled.MobileMenuItemsContainer>
                    <Styled.MobileMenuCloseButton
                        onClick={() => {
                            setIsMobileMenuVisible(false);
                        }}
                    />

                    {items.map(item => {
                        return (
                            <>
                                <Styled.MobileMenuItem key={item.codigo}>
                                    <Styled.MenuItem
                                        onClick={() => {
                                            onClickHandler(item);
                                            handleActive(item.codigo === itemActive ? 0 : item.codigo);
                                        }}
                                    >
                                        <Styled.MenuItemText>
                                            {item.nome}{' '}
                                            {item.subheaders.length > 0 && (
                                                <>{item.codigo === itemActive ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</>
                                            )}
                                        </Styled.MenuItemText>
                                    </Styled.MenuItem>

                                    {itemActive === item.codigo && (
                                        <>
                                            {item.subheaders
                                                .sort((a, b) => a.ordem - b.ordem)
                                                .map(sub => {
                                                    return (
                                                        <Styled.MenuItem onClick={() => onClickHandler(sub)} key={sub.codigo}>
                                                            <Styled.MenuItemTextSub>{sub.nome}</Styled.MenuItemTextSub>
                                                        </Styled.MenuItem>
                                                    );
                                                })}
                                        </>
                                    )}
                                </Styled.MobileMenuItem>
                            </>
                        );
                    })}

                    <br />
                    <br />

                    <ActionButton close={setIsMobileMenuVisible} />
                </Styled.MobileMenuItemsContainer>
            </Styled.MobileMenu>
        </>
    );
};

export default MobileMenu;
