import keycloak from 'core/config/keycloak';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { getIdSolucao } from 'pages/acessoExclusivo/context/AcessoExclusivoProvider';

export const validAccessExclusivo = async (): Promise<boolean> => {
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();

    if (keycloak.authenticated) {
        const response = await serviceCategoriaSolucoes.getSolucoesByUser();
        const link: string = location.pathname.split('/')[1];

        const idSolucao = getIdSolucao(link);

        if (response.status === 200) {
            const solucoes: TipoSolucoes[] = response.data;

            const possui = solucoes.find(f => f.id === idSolucao);

            if (possui) {
                return true;
            }
            
            return false
        }
        return false;
    } else {
        return false;
    }
};