import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminPalavraProibida extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('descricao')
    @jsonProperty(String)
    content: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    updatedAt: string = '';

    @jsonName('variacao')
    @jsonProperty([String], [])
    variations: string[] = [];

    @jsonName('motivacao')
    @jsonProperty(String)
    motivation: string = '';
}

export default AdminPalavraProibida;
