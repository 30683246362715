export enum Ocupation {
    GestorEscolar = 'Gestor(a) Escolar',
    Diretor = 'Diretor(a)',
    ViceDiretor = 'Vice-diretor(a)',
    AgenteTecnico = 'Agente Técnico',
    CoordenadorPedagogico = 'Coordenador(a) pedagógico',
    OrientadorPedagogico = 'Orientador(a) pedagógico',
    SecretarioEducacao = 'Secretário(a) de educação',
    SubSecretarioEducacao = 'Sub-secretário(a) de educação',
    TecnicoSecretaria = 'Técnico(a) de Secretaria',
    Professor = 'Professor(a)',
    Axuliar = 'Auxiliar/Monitor(a) de classe',
    Estagiario = 'Estagiário(a)',
    Outros = 'Outros',
}
