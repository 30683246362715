import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminGestaoPaginasPageStyled = {
    Container: styled.div`
        background-color: white;
        border-radius: 10px;
        padding: 15px 45px;
        font-family: 'Barlow';
        font-style: normal;
        margin-left: 120px;
        width: calc(100% - 120px);
    `,

    SearchContainer: styled.div`
        width: 100%;
        height: 38px;
        max-width: 540px;
        display: flex;
        border: 2px solid #003c78;

        background: white;
        color: black;
        border-radius: 6px;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
        }

        @media only screen and (min-width: ${MobileMaxWidth + 1}px) and (max-width: 968px) {
            max-width: 300px;
        }
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: white;
        color: black;

        &::placeholder {
            color: black;
            opacity: 0.6;
        }
    `,

    DivRowRight: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    ContainerModal: styled.div`
        background-color: white;
        padding: 15px 45px;
    `,

    Title: styled.p`
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin-bottom: 10px;
    `,

    Text: styled.p`
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 10px;
    `,

    TableGrid: styled.div`
        width: 100%;
    `,

    TableGridHead: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;
        width: 100%;
        max-width: 100%;
        padding: 10px 0;
        background: #e5f6fd;
    `,

    TableGridRow: styled.div`
        width: 100%;
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;
        border-bottom: 1px #ddd solid;
    `,

    TableGridHeadCell: styled.p`
        color: #124a88;
        font-weight: 600;
        text-align: center;
    `,

    TableGridCell: styled.p`
        color: #222;
        text-align: center;
    `,

    ViewButton: styled.img`
        cursor: pointer;
    `,

    TableGridCellChip: styled.span<{ isYes: boolean }>`
        color: white;
        width: fit-content;
        border-radius: 12px;
        background-color: ${props => (props.isYes ? '#3aba2f' : '#D24A4A')};
        padding: 5px 10px;
        font-size: 14px;
    `,

    RowRigth: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        .label-order {
            font-size: 14px;
            color: #00a8e9;
            font-weight: 600;
        }

        .span-select {
            margin-left: 0px;
            transform: scale(0.85) translateX(-10px);
        }
    `,

    Modal: {
        Container: styled.div`
            margin: 0;
            margin-bottom: 15px;
        `,

        Title: styled.p`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #003c78;
            margin-bottom: 25px;
        `,

        SubTitle: styled.p`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #343434;
            margin-bottom: 10px;
        `,

        FrameOption: styled.div`
            border: 1px #ddd solid;

            padding: 20px 30px;
            width: fit-content;
            height: fit-content;
            border-radius: 10px;
            text-align: center;
        `,

        Column: styled.div`
            width: fit-content;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #343434;

            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                margin-bottom: 20px;
            }
        `,

        CloseContainer: styled.div`
            display: flex;
            justify-content: end;
            padding: 10px;
            cursor: pointer;

            p {
                width: fit-content;
            }
        `,

        Image: styled.img`
            width: 135px;
            height: auto;
        `,
    },
};

export default AdminGestaoPaginasPageStyled;
