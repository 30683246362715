import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';
import TipoEnsinoDTO from './TipoEnsinoDTO';
import TipoFormacaoDTO from './TipoFormacaoDTO';
import AreaFormacaoDTO from './AreaFormacaoDTO';
import AreaInteresseDTO from './AreaInteresseDTO';
import CategoriaEnsinoDTO from './CategoriaEnsinoDTO';
import TipoEnsinoEscolaPublicaDTO from './TipoEnsinoEscolaPublicaDTO';
import TipoGestorDTO from './TipoGestorDTO';
import { RoleEnum } from 'enums/RoleEnum';

class PerfilUsuario extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    email: string = '';

    @jsonProperty(String)
    keycloakId: string = '';

    @jsonProperty(String)
    cpf: string = '';

    @jsonProperty(String)
    username: string = '';

    @jsonProperty(String, null)
    apelido: string = '';

    @jsonProperty(String)
    estado: string = '';

    @jsonProperty(String)
    genero: string = '';

    @jsonProperty(String)
    cor: string = '';

    @jsonProperty(String)
    comoConheceu: string = '';

    @jsonProperty(String)
    dataNascimento: string = '';

    @jsonProperty(String)
    whatsapp: string = '';

    @jsonProperty(Boolean, null)
    playAudio: boolean | null = null;

    @jsonProperty(Boolean, null)
    catedraOrIea: boolean | null = null;

    @jsonProperty(Boolean, null)
    possuiDeficiencia: boolean | null = null;

    @jsonProperty(String)
    cidade: string = '';

    @jsonProperty(String)
    ocupacao: string = '';

    @jsonProperty(String)
    tipoPerfil: RoleEnum = RoleEnum.Unknown;

    @jsonProperty(String)
    role: RoleEnum = RoleEnum.Unknown;

    @jsonProperty(TipoGestorDTO, null)
    tipoGestor: TipoGestorDTO | null = null;

    @jsonProperty(TipoEnsinoDTO)
    tipoEnsino: TipoEnsinoDTO = new TipoEnsinoDTO();

    @jsonProperty(TipoEnsinoEscolaPublicaDTO, null)
    tipoEnsinoEscolaPublica: TipoEnsinoEscolaPublicaDTO | null = null;

    @jsonProperty(String)
    tipoIdentificacao: string = '';

    @jsonProperty(TipoFormacaoDTO)
    tipoFormacao: TipoFormacaoDTO = new TipoFormacaoDTO();

    @jsonProperty(AreaFormacaoDTO)
    areaFormacao: AreaFormacaoDTO = new AreaFormacaoDTO();

    @jsonProperty(CategoriaEnsinoDTO)
    categoriaEnsino: CategoriaEnsinoDTO = new CategoriaEnsinoDTO();

    @jsonProperty([AreaInteresseDTO], null)
    listaAreaInteresse: AreaInteresseDTO[] | null = null;

    @jsonProperty(Date)
    ultimaAtualizacao: Date = new Date();

    constructor() {
        super();
    }
}

export default PerfilUsuario;
