import _ from 'lodash';
import UsuarioDTO from 'models/UsuarioDTO';
import { AnyAction } from 'redux';
import { AppStorage } from '../../storage/AppStorage';
import { LOGOUT, SSO_ACCESS_TOKEN, USUARIO_LOGADO } from '../actions/Authentication';

interface IState {
    accessToken: string | null;
    usuarioLogado: UsuarioDTO;
}

const initialState: IState = {
    accessToken: null,
    usuarioLogado: new UsuarioDTO(),
};

const authenticationReducer = (state = initialState, action: AnyAction): IState => {
    switch (action.type) {
        case SSO_ACCESS_TOKEN: {
            AppStorage.saveAccessToken(action.accessToken);

            return {
                ..._.cloneDeep(state),
                accessToken: action.accessToken,
            };
        }
        case USUARIO_LOGADO: {
            return {
                ..._.cloneDeep(state),
                usuarioLogado: action.usuarioLogado,
            };
        }
        case LOGOUT: {
            AppStorage.removeAccessToken();
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default authenticationReducer;
