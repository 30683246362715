export enum BiblioItemCategoria {
    'ARTIGO' = 'artigo',
    'ATIVIDADE' = 'atividade',
    'AUDIO' = 'audio',
    'GUIA' = 'guia',
    'LIVRO' = 'livro',
    'VIDEO' = 'video',
    'ESTUDO' = 'estudo',
    'UNKNONW' = '',
}

export enum BiblioItemOrdem {
    'POPULARES' = 'populares',
    'NOVOS' = 'novos',
}

export function categoriaToCode(categoria: string) {
    if (categoria == BiblioItemCategoria.ARTIGO) return 1;
    if (categoria == BiblioItemCategoria.ATIVIDADE) return 2;
    if (categoria == BiblioItemCategoria.AUDIO) return 3;
    if (categoria == BiblioItemCategoria.GUIA) return 4;
    if (categoria == BiblioItemCategoria.LIVRO) return 5;
    if (categoria == BiblioItemCategoria.VIDEO) return 6;
    if (categoria == BiblioItemCategoria.ESTUDO) return 7;
}
