import avatar1 from "../../../../../images/avatars/avatar_1.svg";
import avatar2 from "../../../../../images/avatars/avatar_2.svg";
import avatar3 from "../../../../../images/avatars/avatar_3.svg";
import avatar4 from "../../../../../images/avatars/avatar_4.svg";
import avatar5 from "../../../../../images/avatars/avatar_5.svg";
import avatar6 from "../../../../../images/avatars/avatar_6.svg";
import avatar7 from "../../../../../images/avatars/avatar_7.svg";
import avatar8 from "../../../../../images/avatars/avatar_8.svg";
import avatar9 from "../../../../../images/avatars/avatar_9.svg";

const getAvatarFromId = (id: number) => {
  switch (id) {
    case 1:
      return avatar1;
    case 2:
      return avatar2;
    case 3:
      return avatar3;
    case 4:
      return avatar4;
    case 5:
      return avatar5;
    case 6:
      return avatar6;
    case 7:
      return avatar7;
    case 8:
      return avatar8;
    default:
      return avatar9;
  }
};

export default getAvatarFromId;
