import React, { useEffect, useState } from 'react';
import Styled from './BarProgressContent.styled';

interface IBarProgress {
    value: number;
}

const BarProgressContent = ({ value }: IBarProgress) => {
    return (
        <Styled.Barra>
            <Styled.BarraActive progress={value}>
              <span>{value} %</span>
            </Styled.BarraActive>
        </Styled.Barra>
    );
};

export default BarProgressContent;
