import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const StyledCarousel = {
    Title: styled.h2`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin: 20px 0;
    `,
    SubTitle: styled.p`
        text-align: flex-start;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #2e2e2e;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 14px;
            max-width: 315px;
            text-align: center;
            width: 100%;
        }
    `,

    ContainerHeader: styled.div`
        margin-left: 100px;
    `,
};

export default StyledCarousel;
