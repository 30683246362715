import { GenericService } from '../Generic.service';

export default class AdminSocioemocionalService extends GenericService {
    readonly URI: string = '/v1';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarAvaliacoes(pagina: number, order?: string, funcionalidade?: string, nota?: number, existeComentario?: boolean | null) {
        const por_pagina = 20;
        return this.get('feedback', { pagina, por_pagina, ordem: order ?? 'funcionalidade:asc', nota, funcionalidade, existeComentario });
    }

    public downloadXLSAvaliacoes() {
        return this.get('feedback/xls');
    }

    public buscarAvaliacaoItem(item_id: number) {
        return this.get(`${item_id}/avaliacao`);
    }

    public buscarSecao(secao: string) {
        return this.get('plataformasocioemocional/conteudo', { secao });
    }

    public buscarSecoes() {
        return this.get('plataformasocioemocional/conteudo');
    }

    public buscarAnexoSecao(id: number) {
        return this.get(`plataformasocioemocional/conteudo/${id}/anexo`);
    }

    public salvarSecao(titulo: string, conteudo: string, secao: string) {
        return this.post('plataformasocioemocional/conteudo', { titulo, conteudo, secao });
    }

    public salvarAnexoSecao(data: FormData, conteudo_id: number, descricao: string) {
        return this.post('plataformasocioemocional/conteudo/inserirAnexo', data, { conteudo_id, descricao });
    }

    public atualizarSecao(id: number, titulo: string, conteudo: string, secao: string) {
        return this.put('plataformasocioemocional/conteudo/' + id, { titulo, conteudo, secao });
    }
}
