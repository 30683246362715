import { jsonProperty, Serializable } from 'ts-serializable';

class PrefenciaUsuario extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String, null)
    chave: string | null = null;

    @jsonProperty(String, null)
    valor: string | null = null;

    constructor() {
        super();
    }
}

export default PrefenciaUsuario;
