import React, { useEffect, useState } from 'react';
import { OutlineButton } from 'components/UI/Buttons';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import BiblioNotification from 'models/biblioteca/BiblioNotification';
import Styled from './BiblioNotificationShowModal.styled';
import icon from '../../../../images/biblioteca/config-grey.svg';
import { DateHelp } from 'core/util/DateHelp';
import { useHistory } from 'react-router-dom';
import { orderNotification, sanitizeNotificationContent } from 'pages/biblioteca/subpages/notification/BiblioNotificationPage';
import { useKeycloak } from '@react-keycloak/web';

export interface IBiblioNotificationShowModal {
    show: boolean;
    onHide: () => void;
}

const BiblioNotificationShowModal = ({ show, onHide }: IBiblioNotificationShowModal) => {
    const [notifications, setNotifications] = useState<BiblioNotification[]>([]);
    const { keycloak } = useKeycloak();

    const history = useHistory();

    const fetchUnreadNotification = async () => {
        const service = new BiblioNotificacaoService();
        const resonse = await service.buscarNotificacoesNaoLidas();

        let _notifications: BiblioNotification[] = resonse.data.map((data: any) => new BiblioNotification().fromJSON(data));

        for (let i = 0; i < _notifications.length; i++) _notifications[i].dateConvert = new Date(_notifications[i].date);

        _notifications = orderNotification(_notifications);
        setNotifications(_notifications);
    };

    const gotoNotifications = () => {
        onHide();
        history.push('/biblioteca/notificacoes');
    };

    const gotoConfigNotifications = () => {
        onHide();
        history.push('/biblioteca/notificacoes/preferencias');
    };

    useEffect(() => {
        if (keycloak?.authenticated) fetchUnreadNotification();
    }, [show]);

    return (
        <Styled.Modal show={show} onHide={onHide}>
            <Styled.Container>
                <Styled.TopContainer>
                    <Styled.Title>Notificações</Styled.Title>
                    <Styled.Icon src={icon} onClick={gotoConfigNotifications} />
                </Styled.TopContainer>
                <br />

                <Styled.ScrollContainer>
                    {notifications.map(notification => {
                        return (
                            <Styled.ItemContiner key={notification.id}>
                                <Styled.TitleItem>{notification.title}</Styled.TitleItem>
                                <Styled.ContentText>{sanitizeNotificationContent(notification.content)} </Styled.ContentText>
                                <Styled.Date>{DateHelp.formatTimeAgo(notification.date)}</Styled.Date>
                            </Styled.ItemContiner>
                        );
                    })}
                </Styled.ScrollContainer>

                <br />
                <Styled.BottomContainer>
                    <OutlineButton onClick={gotoNotifications}>Ver Tudo</OutlineButton>
                </Styled.BottomContainer>
            </Styled.Container>
        </Styled.Modal>
    );
};

export default BiblioNotificationShowModal;
