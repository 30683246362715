import { ToastError } from 'components/Toaster';

export namespace FetchUtils {
    export const loadingHof = async (loadingFunction: (value: boolean) => void, callback: () => void) => {
        try {
            loadingFunction(true);
            await callback();
        } catch (err: any) {
            ToastError('Houve um erro, tente novamente mais tarde');
        } finally {
            loadingFunction(false);
        }
    };
}
