import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio } from 'react-radio-input';

import { Form } from 'react-bootstrap';
import Styled from './AdminBiblioNew.styled';

import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { isFileNotNull, isSaveRascunho, isStringsValid } from './isValidNewContentInput';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminBiblioItemSave from 'models/admin/AdminBiblioItemSave';
import { cloneDeep } from 'lodash';
import submitAttach, { AdminBiblioAnexoType, fecthAttachImage, fetchTagsItemId } from './submitAttach';
import { IAdminBiblioEditorContent } from 'pages/admin/subpages/biblio/post/AdminBiblioNewContent';

import trashRed from '../../../../images/admin/trash-red.svg';
import checkIcon from '../../../../images/admin/ok.svg';
import { StringHelp } from '../../../../core/util/StringHelp';
import AdminTagSelector from '../tagSelector/AdminTagSelector';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { ToastError } from 'components/Toaster';
import ItemBibliotecaRelatedContentListagem from '../itemBibliotecaRelatedContentList/ItemBibliotecaRelatedContentListagem';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';

export interface IAdminBiblioNewAudio {
    onSaved: () => void;
}

const isMobile = () => IsMobileMaxWidth();

const AdminBiblioNewAudio = ({ onSaved, itemData, categoriaId, categorias }: IAdminBiblioEditorContent) => {
    const [id, setId] = useState<number>(-1);
    const [slug, setSlug] = useState<string>('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [titleTag, setTitleTag] = useState('');
    const [metaDescriptionTag, setMetaDescriptionTag] = useState('');
    const [isPublic, setIsPublic] = useState('false');

    const [links, setLinks] = useState<string[]>([' ']);

    const [imageCover, setImageCover] = useState<File>();
    const [preimageCover, setPreImageCover] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isValidInputs = (): boolean => {
        if (!preimageCover && !imageCover) return false;
        return isStringsValid([title, content, slug]);
    };

    const validLink = (value: string): boolean => {
        const re = /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/\d+&.+=true?/g;
        return re.test(value.trim());
    };

    const getMacthLink = (link: string): string => {
        const re = /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/\d+&.+=true?/g;
        const result = link.match(re);

        if (result && result[0]) return result[0];
        else return '';
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(links);
        clone[idx] = value;
        setLinks(clone);
    };

    const addNewLink = () => {
        const clone = cloneDeep(links);
        clone.push(' ');
        setLinks(clone);
    };

    const removeLink = (idx: number) => {
        const clone = cloneDeep(links);
        clone.splice(idx, 1);
        setLinks(clone);
    };

    const categoriaExistente = (): boolean => {
        return categorias.filter(c => c.ativo).some(c => c.id == categoriaId);
    };

    const submit = async (_status: 'PUBLICADO' | 'RASCUNHO') => {
        setIsSubmitting(true);

        const service = new AdminBiblioService();

        const itemSave = new AdminBiblioItemSave();

        itemSave.slug = slug;
        itemSave.titulo = title;
        itemSave.conteudo = content;
        itemSave.tipoItemBiblioteca = categoriaId;
        itemSave.status = _status;
        itemSave.publico = isPublic === 'true';
        itemSave.tags = tags;
        itemSave.autores = 'autores';
        itemSave.linkSoundCloud = links.filter(s => s.trim().length > 0).map(s => getMacthLink(s));
        itemSave.titleTag = titleTag;
        itemSave.metaDescriptionTag = metaDescriptionTag;

        if (itemSave.publico && !categoriaExistente()) {
            alert('Não é possível publicar um item de um categoria inativa');
            setIsSubmitting(false);
            return;
        }

        for (let i = 0; i < links.length; i++) {
            if (!validLink(links[i])) {
                ToastError('Verifique os links do SoundClound e tente novamente');
                return;
            }
        }

        let data, status: number;

        const responseUnicidade = await service.validarUnicidadeSlug(itemData?.id ?? null, itemSave.slug);
        let slugValido: boolean = false;
        if (responseUnicidade.status === 200 && responseUnicidade.data === true) {
            slugValido = true;
        }

        if (slugValido) {
            if (!itemData) {
                const response = await service.criarItem(itemSave);
                data = response.data;
                status = response.status;
            } else {
                const response = await service.atualizarItem2(itemData.id, itemSave, tags);
                data = response.data;
                status = response.status;
            }

            if (status == 200) {
                if (imageCover) submitAttach(data.id, imageCover, AdminBiblioAnexoType.CAPA);
            }

            onSaved();
        } else {
            ToastError('URL Slug já cadastrado para outro conteúdo');
        }

        setIsSubmitting(false);
    };

    const fillData = async () => {
        if (itemData != null) {
            setSlug(itemData.slug);
            setId(itemData.id);
            setContent(itemData.content ?? '');
            setTitle(itemData.title);
            setTitleTag(itemData.titleTag);
            setMetaDescriptionTag(itemData.metaDescriptionTag);
            setIsPublic('false');

            setLinks(itemData.linkSoundcloud ?? []);

            const _imageCover = await fecthAttachImage(itemData.id, AdminBiblioAnexoType.CAPA);
            if (_imageCover) setPreImageCover(_imageCover);
            const tags = await fetchTagsItemId(itemData.id);
            setTags(tags);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.FormContainer>
                    {isMobile() && (
                        <Styled.ImageContainer>
                            <AdminImageUploader
                                onSelect={(_, f) => setImageCover(f)}
                                size={'sm'}
                                title={'Imagem da capa'}
                                preImageB64={preimageCover}
                                recommendedDimension={{ w: 200, h: 130 }}
                            />
                        </Styled.ImageContainer>
                    )}

                    <Styled.Label style={{ marginTop: '20px' }}>Título</Styled.Label>
                    <Styled.Input value={title} onChange={(e: any) => setTitle(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Descrição</Styled.Label>
                    <ReactQuill
                        theme="snow"
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        value={content}
                        onChange={val => {
                            setContent(val);
                        }}
                    />
                    <br />

                    {links.map((link, idx) => {
                        return (
                            <div key={idx}>
                                <Styled.Label>Link do áudio no SoundCloud</Styled.Label>
                                <Styled.Row style={{ alignItems: 'center' }}>
                                    <Styled.Input value={link} onChange={(e: any) => setLink(idx, e.target.value)} />
                                    <Styled.CheckIcon src={checkIcon} show={validLink(link)} />
                                    {idx > 0 && <Styled.TrashIcon src={trashRed} onClick={() => removeLink(idx)} />}
                                </Styled.Row>
                                <Styled.PlusButton onClick={addNewLink}>+</Styled.PlusButton>
                            </div>
                        );
                    })}

                    <br />
                    <AdminTagSelector tags={tags} onSelected={(newTags: string[]) => setTags(newTags)} />

                    <br />
                    <Styled.Label>Url Slug</Styled.Label>
                    <Styled.Input
                        value={slug}
                        onChange={(e: any) => setSlug(StringHelp.alnum(StringHelp.toSize(e.target.value.toLowerCase().trim(), 150)))}
                    />
                    <br />
                    <Styled.Label>Título da Página</Styled.Label>
                    <Styled.Input value={titleTag} onChange={(e: any) => setTitleTag(StringHelp.toSize(e.target.value, 150))} />

                    <br />
                    <Styled.Label>Meta Description</Styled.Label>
                    <Styled.Input
                        value={metaDescriptionTag}
                        onChange={(e: any) => setMetaDescriptionTag(StringHelp.toSize(e.target.value, 500))}
                    />

                    <br />

                    <Styled.Label>Tipo de visualização</Styled.Label>

                    <Styled.CkeckContainer style={{ paddingTop: '12px' }}>
                        <RadioGroup name={'isPublic'} onChange={setIsPublic} selectedValue={'false'}>
                            <label htmlFor="isPublic">
                                <Radio id="isPublic" value={'true'} />
                                <Styled.TextCheckBox>Público</Styled.TextCheckBox>
                            </label>

                            <label htmlFor="isPrivate">
                                <Radio id="isPrivate" value={'false'} />
                                <Styled.TextCheckBox>Privado</Styled.TextCheckBox>
                            </label>
                        </RadioGroup>
                    </Styled.CkeckContainer>
                    <br />
                    <ItemBibliotecaRelatedContentListagem itemData={itemData} />
                    <br />

                    <Styled.BottomRight>
                        {isSaveRascunho(itemData) && (
                            <OutlineButton disabled={!isValidInputs() || isSubmitting} onClick={() => submit('RASCUNHO')}>
                                {isSubmitting ? 'Salvando...' : 'Salvar Rascunho'}
                            </OutlineButton>
                        )}
                        <GradientButton
                            disabled={!isValidInputs() || isSubmitting}
                            onClick={() => submit('PUBLICADO')}
                            style={{ marginLeft: '10px', marginTop: '10px' }}
                        >
                            {isSubmitting ? 'Salvando...' : 'Publicar'}
                        </GradientButton>
                    </Styled.BottomRight>
                </Styled.FormContainer>

                {!isMobile() && (
                    <Styled.ImageContainer>
                        <AdminImageUploader
                            onSelect={(_, f) => setImageCover(f)}
                            size={'sm'}
                            title={'Imagem da capa'}
                            preImageB64={preimageCover}
                            recommendedDimension={{ w: 200, h: 130 }}
                        />
                    </Styled.ImageContainer>
                )}
            </Styled.Row>
        </Styled.Container>
    );
};

export default AdminBiblioNewAudio;
