import React, { useEffect } from 'react';
import Styled from './CardNumeroDestaqueStyled';
import { CardsItemCustomizado } from 'models/generic-components/CardsItemCustomizado';
import { bucketS3, caminhoAnexosItemCustomizados } from 'constants/EnvironmentData';

interface ICardNumeroDestaque {
    dados: CardsItemCustomizado;
}

const CardNumeroDestaque = ({ dados }: ICardNumeroDestaque) => {
    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosItemCustomizados();
    };
    console.log("🚀 ~ getS3BaseUrl ~ getS3BaseUrl:", getS3BaseUrl)

    return (
        <Styled.Container>
            {!dados.ocultarAnexo && <Styled.Img src={getS3BaseUrl() + dados.filePath} />}
            <Styled.Info color={dados.corInfoDestaque} size={dados.tamanhoInfoDestaque ?? ''}>
                {!dados.ocultarInfoDestaque && dados.infoDestaque}
            </Styled.Info>
            <Styled.Detail color={dados.corDetalhamento} size={dados.tamanhoDetalhamento ?? ''}>
                {!dados.ocultarDetalhamento && dados.detalhamento}
            </Styled.Detail>
        </Styled.Container>
    );
};

export default CardNumeroDestaque;
