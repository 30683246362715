import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

interface IModaRemoveFavorite {
    setShow: (value: boolean) => void;
    show: boolean;
    goToExcluded: () => void;
}

const ModalRemoveFavorite = ({ show, setShow, goToExcluded }: IModaRemoveFavorite) => {
    const ModalExcludeContainer = styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 300px;
        border-radius: 10%;
        padding: 20px;
        position: absolute;
        background-color: white;
        top: 20;
    `;

    const Title = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: #003c78;
    `;

    const Description = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(12px, 1.8vw, 20px);
        line-height: 140%;
        color: #003c78;
        text-align: center;
        padding-top: 10px;
    `;

    const ButtonContainer = styled.div`
        display: flex;
        padding-top: 20px;
        gap: 5vw;
    `;

    const ButtonFechar = styled.button`
        border: 2px solid #003c78;
        border-radius: 6px;
        background-color: transparent;
        padding: 16px 24px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #003c78;

        &:hover {
            color: #fcfcfc;
            background: #003c78;
        }
    `;

    const ButtonMore = styled.button`
        border: 2px solid #003c78;
        padding: 16px 24px;
        background: #ff0000;
        border-radius: 6px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #fcfcfc;

        &:hover {
            color: #003c78;
            background: transparent;
        }
    `;

    return (
        <Modal show={show} style={{}} autoFocus={true}>
            <ModalExcludeContainer>
                <Title>{'Atenção!'}</Title>
                <Description>{'Deseja desfavoritar este conteúdo?'}</Description>
                <ButtonContainer>
                    <ButtonFechar onClick={() => setShow(false)}>{'Fechar aviso'}</ButtonFechar>
                    <ButtonMore onClick={goToExcluded}>{'Desfavoritar'}</ButtonMore>
                </ButtonContainer>
            </ModalExcludeContainer>
        </Modal>
    );
};

export default ModalRemoveFavorite;
