import styled from 'styled-components';

import Colors from '../../../../enums/Colors';
import { MobileMaxWidth, ResizeMediumWidth } from '../../../../constants/Dimensions';

//Início home v2 sem o faq inciialmente
export const FaleConoscoStyle = styled.div`
    display: flex;
    width: 100%;
    background-color: ${Colors.White};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        justify-content: center;
    }
`;

export const ContactUsContainerStyleV2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 103px;
    background-color: ${Colors.White};

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        padding-top: 50px;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-bottom: 70px;
        padding-top: 0px;
    }
`;
//Fim home v2 sem o faq incialmente

export const FaqContainerStyle = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 103,
    marginBottom: 103,
});

export const FaqStyle = styled.div({
    flex: 1,
});

export const ContactUsContainerStyle = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 400,
    marginTop: 64,
    marginLeft: 50,
});

export const FormContainer = styled.div({
    display: 'flex',
    marginTop: 70,
});

export const Title = styled.p`
    color: ${Colors.DarkBlue};
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-left: 7vw;
        font-size: 20px;
        line-height: 22px;
    }
`;

export const AccordionContainer = styled.div({
    display: 'flex',
    width: '100%',
    minWidth: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 70,
    marginBottom: 70,
});

export const AccordionButton = styled.button({});

export const AccordionButtonIcon = styled.img({
    marginRight: 20,
});

export const AccordionContentStyle = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '15px',
    paddingBottom: '15px',
    lineHeight: '20px',
});

export const TitleText = styled.p`
    display: flex;
    font-size: 32px;
    color: ${Colors.Blue};
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 0;
    }
`;

export const TextContainer = styled.div`
    padding: 0px 20px 0px 0px;
`;

export const TitlePage = styled.p({
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '42px',
    letterSpacing: '0.1px',
    color: Colors.Blue,
    marginBottom: '39px',
});

export const SubtitlePage = styled.p({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '27px',
    Colors: Colors.DarkGrey,
    marginBottom: '40px',
});
