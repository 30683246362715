import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import starEnable from '../../images/start-enable.svg';
import starDisable from '../../images/start-disable.svg';

export interface IRateFeedback {
    onRate: (value: number) => void;
}

const Container = styled.div`
    display: flex;
    margin: 20px 0;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 7px;
    cursor: pointer;
`;

const ImageStar = styled.img`
    height: 40px;
    width: auto;
    margin: 10px 0;
`;

const Label = styled.p`
    text-align: center;
    font-weight: normal;
    font-size: 14px;
`;

const Star = ({ enabled }: { enabled: boolean }) => {
    return enabled ? <ImageStar src={starEnable} /> : <ImageStar src={starDisable} />;
};

const RateFeedback = ({ onRate }: IRateFeedback) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (value > 0) onRate(value);
    }, [value]);

    return (
        <Container>
            <ItemContainer onClick={() => setValue(1)}>
                <Star enabled={value >= 1} />
                <Label>Muito insatisfeito</Label>
            </ItemContainer>

            <ItemContainer onClick={() => setValue(2)}>
                <Star enabled={value >= 2} />
                <Label>Insatisfeito</Label>
            </ItemContainer>

            <ItemContainer onClick={() => setValue(3)}>
                <Star enabled={value >= 3} />
                <Label>Indiferente</Label>
            </ItemContainer>

            <ItemContainer onClick={() => setValue(4)}>
                <Star enabled={value >= 4} />
                <Label>Satisfeito</Label>
            </ItemContainer>

            <ItemContainer onClick={() => setValue(5)}>
                <Star enabled={value >= 5} />
                <Label>Muito satisfeito</Label>
            </ItemContainer>
        </Container>
    );
};

export default RateFeedback;
