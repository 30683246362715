import React, { FunctionComponent, useEffect, useState } from 'react';
import Styled from './Admin.styled';

import Menu from 'pages/menu/Menu';
import { AiOutlineMenu } from 'react-icons/ai';
import AdminBarraLateral from './components/barraLateral/AdminBarraLateral';
import AdminContent from './components/AdminContent';
import AdminProvider from './context/AdminProvider';
import { MobileMaxWidth } from 'constants/Dimensions';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { ToasterDefault } from 'components/Toaster';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface IAdmin {}

const isMobile = () => window.screen.width <= MobileMaxWidth;

const Admin: FunctionComponent<IAdmin> = ({}: IAdmin) => {
    const [contractMenu, setContract] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (!isMobile()) setContract(false);
            else setContract(true);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    return (
        <>
            {/*  <Menu admin /> */}
            <DynamicHeader />

            <AdminProvider>
                <Styled.MenuIcon>
                    <AiOutlineMenu onClick={() => setContract(!contractMenu)} />
                </Styled.MenuIcon>
                <Styled.Container>
                    <AdminBarraLateral contract={contractMenu} />
                    <AdminContent contractMenu={contractMenu} />
                </Styled.Container>
                {ToasterDefault()}
            </AdminProvider>
        </>
    );
};

export default Admin;
