import React, { useContext } from 'react';
import Styled from './DesktopMenu.styled';
import ActionButton from '../action-button/ActionButton';
import { FiSearch } from 'react-icons/fi';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import HeaderItem from 'models/admin/AdminHeaderItem';
import DesktopMenuItem from './DesktopMenuItem';

export interface IDesktopMenu {
    items: HeaderItem[];
}

const DesktopMenu = ({ items }: IDesktopMenu) => {
    const { openSearch } = useContext(PesquisaContext.Context);

    return (
        <Styled.MenuItemsContainer>
            {items.map(item => {
                return <DesktopMenuItem item={item} key={item.codigo} />;
            })}

            <Styled.SearchButtonContainer onClick={openSearch}>
                <FiSearch />
            </Styled.SearchButtonContainer>
            <ActionButton />
        </Styled.MenuItemsContainer>
    );
};

export default DesktopMenu;
