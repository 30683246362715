import { useKeycloak } from '@react-keycloak/web';
import useLogged from 'hooks/useLogged';
import { ConteudoRecursoCustomizado as Conteudo } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { EtapaItemRecursosCostumizado as Etapa } from 'models/recursos-costumizados/EtapaItemRecursosCostumizado';
import React, { useState, useEffect } from 'react';
import { BsCheck2Circle } from 'react-icons/bs';
import { FiCircle } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';
import Styled from './Accordion.styled';
import { KeycloakLoginOptions } from 'keycloak-js';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';

interface IAccordion {
    keyAccordion: number;
    handleClick: () => void;
    open: number;
    data: Etapa;
    item: ItemRecursosCostumizado;
    isSequential: boolean;
    handleSequential: (value: Conteudo) => boolean;
}

const Accordion = ({ keyAccordion, handleClick, open, data, item, isSequential, handleSequential }: IAccordion) => {
    const history = useHistory();
    const location = useLocation();
    const { keycloak } = useKeycloak();

    const { login, authenticated } = useLogged();

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(keyAccordion === open ? true : false);
    }, [open]);

    const gotoContent = (conteudo: Conteudo) => {
        const route = location.pathname + '/' + data.url.replace(/\//g, '') + '/' + conteudo.url.replace(/\//g, '');

        if (authenticated) {
            if (handleSequential(conteudo)) {
                history.push(route);
            }
        } else {
            //login(location.pathname);

            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + route,
            };

            const _url =
                item.tipoRedirecionamento === TipoRedirecionamento.CADASTRO
                    ? keycloak.createRegisterUrl(options)
                    : keycloak.createLoginUrl(options);
            window.location.assign(_url);
        }
    };

    return (
        <Styled.Accordion isOpen={isOpen}>
            <Styled.Container onClick={handleClick} isEtapa={true} isOpen={isOpen}>
                <Styled.Div>
                    {isOpen ? <IoIosArrowDown size={30} /> : <IoIosArrowForward size={30} />}
                    <Styled.Div>
                        <Styled.Title>{data.nome}</Styled.Title>
                    </Styled.Div>
                </Styled.Div>
                <Styled.Div>{data.isConcluido && <BsCheck2Circle size={25} />}</Styled.Div>
            </Styled.Container>
            {isOpen && (
                <React.Fragment>
                    {data.conteudos
                        ?.sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                        .filter(c => c.oculto === false)
                        .map((conteudo, index) => (
                            <Styled.Container key={index} onClick={() => gotoContent(conteudo)} isEtapa={false} isOpen={isOpen}>
                                <Styled.Div>
                                    <Styled.Div>{conteudo.isConcluido ? <BsCheck2Circle size={25} /> : <FiCircle size={25} />}</Styled.Div>
                                    <Styled.Div style={{ marginLeft: '10px' }}>
                                        <Styled.Label>{conteudo.nome}</Styled.Label>
                                    </Styled.Div>
                                </Styled.Div>
                            </Styled.Container>
                        ))}
                </React.Fragment>
            )}
        </Styled.Accordion>
    );
};

export default Accordion;
