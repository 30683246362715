import accents from 'remove-accents';

export namespace StringHelp {
    export const alnum = (input: string): string => {
        let stringFinal = '';
        for (var i = 0; i < input.length; i++) {
            if (input[i].match('^[a-zA-Z0-9_-]{1,}$')) {
                stringFinal = stringFinal.concat(input[i]);
            }
        }
        return stringFinal;
    };

    /**
     * Retorna uma string reduzida e seguida de '...'.
     * @param input Texto de referência
     * @param size Tamanho máximo do texto (sem as reticências)
     * @returns Texto cortado + "..."
     */
    export const toSize = (input: string, size: number): string => {
        if (size > input.length) {
            return input;
        } else {
            return `${input.substring(0, size)}...`;
        }
    };

    export const capitalizeFirstIn = (input: string, needle: any, joinStr?: string): string => {
        const arr = input
            .trim()
            .split(needle)
            .map(word => {
                return word.length > 0 ? word[0].toUpperCase() + word.substr(1).toLowerCase() : word;
            });
        return arr.join(joinStr ?? needle);
    };

    export const capitalizeWords = (input: string): string => {
        return capitalizeFirstIn(input, /\s+/, ' ');
    };

    export const capitalizeSentences = (input: string): string => {
        return capitalizeFirstIn(input, '. ');
    };

    export const replaceAll = (input: string, needle: any, replacement: any) => {
        if (typeof needle === 'string') {
            const regex = new RegExp(needle, 'g');
            return input.replace(regex, replacement);
        } else if (Array.isArray(needle)) {
            let out = input;
            if (Array.isArray(replacement)) {
                needle.forEach((item, index) => {
                    const regex = new RegExp(item, 'g');
                    out = out.replace(regex, replacement[index]);
                });
            } else {
                needle.forEach(item => {
                    const regex = new RegExp(item, 'g');
                    out = out.replace(regex, replacement);
                });
            }
            return out;
        }
        return input;
    };

    //JaroWrinker
    //https://medium.com/@sumn2u/string-similarity-comparision-in-js-with-examples-4bae35f13968
    export const stringCompare = (s1: string, s2: string, minWeight: number = 0.9, ignoreCase: boolean = true): boolean => {
        let m: number = 0;

        s1 = s1.trim();
        s2 = s2.trim();

        // Exit early if either are empty.
        if (s1.length === 0 || s2.length === 0) {
            return false;
        }

        if (ignoreCase) {
            s1 = s1.toLocaleLowerCase();
            s2 = s2.toLocaleLowerCase();
        }

        // Exit early if they're an exact match.
        if (s1 === s2) {
            return true;
        }

        const range = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
        const s1Matches = new Array(s1.length);
        const s2Matches = new Array(s2.length);

        for (let i: number = 0; i < s1.length; i++) {
            const low = i >= range ? i - range : 0,
                high = i + range <= s2.length ? i + range : s2.length - 1;

            for (let j = low; j <= high; j++) {
                if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
                    ++m;
                    s1Matches[i] = s2Matches[j] = true;
                    break;
                }
            }
        }

        // Exit early if no matches were found.
        if (m === 0) {
            return false;
        }

        // Count the transpositions.
        let n_trans: number = 0;
        let k: number = 0;

        for (let i = 0; i < s1.length; i++) {
            if (s1Matches[i] === true) {
                for (let j = k; j < s2.length; j++) {
                    if (s2Matches[j] === true) {
                        k = j + 1;
                        break;
                    }

                    if (s1[i] !== s2[j]) {
                        ++n_trans;
                    }
                }
            }
        }

        let weight = (m / s1.length + m / s2.length + (m - n_trans / 2) / m) / 3,
            l = 0,
            p = 0.1;

        if (weight > 0.7) {
            while (s1[l] === s2[l] && l < 4) {
                ++l;
            }

            weight = weight + l * p * (1 - weight);
        }

        return weight >= minWeight;
    };

    export function removeTags(str: string, replace?: string): string {
        return str.replace(/(<([^>]+)>)/gi, replace ?? '');
    }

    export function removeAccents(str: string): string {
        return accents.remove(str);
    }
}
