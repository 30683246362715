import React, { useEffect, useState } from 'react';
import {
    RedirectButton,
    BottomContainer,
    CenterContainer,
    InnerContainer,
    LeftContainer,
    PainelContainer,
    RightContainer,
    Text,
    Title,
    TopContainer,
    VerticalSeparator,
    DropButton,
    MobileContainer,
    Separator,
} from './JornadaPainel.styled';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import InternalCard from '../InternalCard';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import FullDashboardJornadas from 'models/perfil/FullDashboardJornadas';
import { JornadaStatusEnum } from 'enums/JornadaStatusEnum';
import JornadaFrame from './JornadaFrame';
import Sharebox from '../Sharebox';
import { PaginasEnum } from 'enums/PaginasEnum';
import { GTM_Page_Meu_Painel } from 'components/GoogleTagManager';
import { ToasterDefault } from 'components/Toaster';

export interface IJornadaPainel {
    jornadas: FullDashboardJornadas[];
}

const JornadaPainel = ({ jornadas }: IJornadaPainel) => {
    const history = useHistory();
    const [isDropped, setDropped] = useState<boolean>(false);
    const [params, setParams] = useState({ iniciadas: 0, finalizadas: 0, nao_iniciadas: 0 });

    const updateJornadaValue = () => {
        let params = {
            iniciadas: 0,
            finalizadas: 0,
            nao_iniciadas: 0,
        };
        if (jornadas != null) {
            jornadas.forEach(e => {
                if (e.status == JornadaStatusEnum.INICIADA) {
                    params.iniciadas++;
                } else if (e.status == JornadaStatusEnum.NAO_INICIADA) {
                    params.nao_iniciadas++;
                } else if (e.status == JornadaStatusEnum.FINALIZADA) {
                    params.finalizadas++;
                }
            });
        }
        setParams(params);
    };

    useEffect(() => {
        updateJornadaValue();
    }, []);

    const goToJornada = () => {
        GTM_Page_Meu_Painel('jornadas_redirect_button', 'acessar_jornada', 'principal_jornada');
        history.push('/home?link=jornadas');
    };

    return (
        <PainelContainer isDropped={isDropped}>
            <LeftContainer>
                <TopContainer>
                    <Title>{`Jornada de Desenvolvimento`}</Title>
                    <Sharebox location={PaginasEnum.JORNADA_DESENVOLVIMENTO} />
                </TopContainer>

                <InnerContainer>
                    <InternalCard value={params.iniciadas} description={'iniciadas'} colorPicker={'jornada'}></InternalCard>
                    <InternalCard value={params.finalizadas} description={'finalizadas'} colorPicker={'jornada'}></InternalCard>
                    <InternalCard value={params.nao_iniciadas} description={'não iniciadas'} colorPicker={'jornada'}></InternalCard>
                </InnerContainer>

                {IsCustomMaxWidth(1200) && isDropped && (
                    <MobileContainer>
                        <Separator />
                        <JornadaFrame jornadas={jornadas} />
                    </MobileContainer>
                )}

                <BottomContainer>
                    <DropButton onClick={() => (isDropped ? setDropped(false) : setDropped(true))}>
                        {isDropped ? 'Menos detalhes' : 'Mais detalhes'}
                    </DropButton>
                    <RedirectButton onClick={goToJornada}>
                        <Text>{'Acessar jornadas'}</Text>
                    </RedirectButton>
                </BottomContainer>
            </LeftContainer>

            {!IsCustomMaxWidth(1200) && (
                <>
                    <CenterContainer>
                        <VerticalSeparator />
                    </CenterContainer>

                    <RightContainer>
                        <JornadaFrame jornadas={jornadas} />
                    </RightContainer>
                </>
            )}

            {/* Others */}
            {ToasterDefault()}

            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </PainelContainer>
    );
};

export default JornadaPainel;
