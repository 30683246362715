import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledCardCertificacoes = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        height: 110px;
        border-radius: 10px;
        box-shadow: 2px 2px 10px ${Colors.Grey5};
        background-color: #ffffff;
        width: 350px;
    `,

    Title: styled.h1`
        font-size: 18px;
        font-weight: bold;
        color: ${Colors.BlueGrey};
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    `,

    
};

export default StyledCardCertificacoes;
