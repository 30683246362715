import React, { forwardRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Styled from './SecaoFrame.styled';
import { MdPlayCircleFilled } from 'react-icons/md';

interface ISecaoFrame {
    videoId: string;
    isMobile: boolean;
}

const SecaoFrame = forwardRef<HTMLDivElement, ISecaoFrame>(({ videoId, isMobile }, ref) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Styled.Container ref={ref}  isMobile={isMobile}>
            <Modal show={show} onHide={handleClose} backdrop="static" size={isMobile ? 'sm' : 'xl'} centered keyboard={false}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <iframe
                        width="100%"
                        height="550"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="YouTube Video"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {!isMobile && (
                <Styled.Content isMobile={false}>
                    <Styled.Img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt="Video Thumbnail" />
                    <Styled.Play onClick={handleShow}>
                        <MdPlayCircleFilled size={60} />
                    </Styled.Play>
                </Styled.Content>
            )}

            <Styled.Content
                style={{
                    paddingLeft: isMobile ? '0px' : '20px',
                }}
                isMobile={isMobile}
            >
                <Styled.Title isMobile={isMobile}>O QUE SÃO COMPETÊNCIAS SOCIOEMOCIONAIS? </Styled.Title>
                {isMobile && (
                    <Styled.Content
                        isMobile
                        style={{
                            marginBottom: '24px',
                        }}
                    >
                        <Styled.Img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt="Video Thumbnail" />
                        <Styled.Play onClick={handleShow}>
                            <MdPlayCircleFilled size={60} />
                        </Styled.Play>
                    </Styled.Content>
                )}
                <Styled.SubTitle maxWidth='500px'>
                    São capacidades individuais que se manifestam nos modos de pensar, sentir e nos comportamentos ou atitudes para se relacionar
                    consigo mesmo e com os outros, estabelecer objetivos, tomar decisões e enfrentar situações adversas ou novas. Essas
                    competências são observadas em nosso padrão de ação e reação frente a estímulos na nossa vida pessoal e social.  
                </Styled.SubTitle>
                <Styled.SubTitle maxWidth='500px'>
                    No ambiente escolar, as competências socioemocionais são fundamentais para um cenário de educação integral, que considere
                    seus sujeitos para além das competências cognitivas. Por isso, o Instituto Ayrton Senna desenvolveu modelos que definem as
                    competências-chave para estudantes e professores. 
                </Styled.SubTitle>
            </Styled.Content>
        </Styled.Container>
    );
});

SecaoFrame.displayName = 'SecaoFrame';

export default SecaoFrame;
