import TipoAnexoCarrossel from 'enums/TipoAnexoCarrosselEnum';
import { jsonProperty, Serializable } from 'ts-serializable';

export class CarouselMedia extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    detalhamento: string = '';

    @jsonProperty(String)
    corDetalhamento: string = '';

    @jsonProperty(String)
    tamanhoDetalhamento: string = '';

    @jsonProperty(Boolean)
    ocultarDetalhamento: boolean = false;

    @jsonProperty(String)
    tipoAnexo: TipoAnexoCarrossel = TipoAnexoCarrossel.IMAGEM;

    @jsonProperty(Boolean)
    oculto: boolean = false;

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(String)
    urlVideo: string = '';

    @jsonProperty(String, null)
    filePath: string | null = null;
}
