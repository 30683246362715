import React, { useState, useContext, useEffect } from 'react';
import Colors from 'enums/Colors';
import {
    Button,
    ButtonContainer,
    CloseBanner,
    CloseIcon,
    Container,
    Content,
    HeadContainer,
    HeadTitle,
    OpenIcon,
    Text,
} from './BannerInfo.styled';
import closeIcon from '../assets/images/closeIcon.svg';
import plusCircleOutline from '../../../painelSocioemocional/assets/images/circle-plus-svgrepo.svg';
import { useHistory } from 'react-router-dom';
import BannerData from '../../data/BannerInfoData';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import { RealizandoEscolhasContext } from '../context/RealizandoEscolhasContext';

const BannerInfo = () => {
    const { isShowBannerInfo, setShowBannerInfo } = useContext(RealizandoEscolhasContext.Context);
    const history = useHistory();

    const goToEscolhas = () => {
        GTM_Page_Realizando_Escolhas('home_banner_realizando_escolhas', 'saiba_mais_escolhas');
        history.push('/realizandoEscolhas/escolhas');
    };

    return (
        <>
            <Container>
                <HeadContainer>
                    <HeadTitle size="large" style={{ color: Colors.White }}>
                        {BannerData.banner.title}
                    </HeadTitle>

                    {!isShowBannerInfo ? (
                        <OpenIcon
                            src={plusCircleOutline}
                            onClick={() => {
                                setShowBannerInfo();
                            }}
                            alt=""
                        />
                    ) : (
                        <CloseIcon
                            src={closeIcon}
                            onClick={() => {
                                GTM_Page_Realizando_Escolhas('home_banner_realizando_escolhas', 'fechar_banner');
                                setShowBannerInfo();
                            }}
                        />
                    )}
                </HeadContainer>
                {isShowBannerInfo && (
                    <Content>
                        <Text>{BannerData.banner.text}</Text>
                        <ButtonContainer>
                            <Button onClick={goToEscolhas}>{`Saiba Mais`}</Button>
                            <CloseBanner
                                onClick={() => {
                                    GTM_Page_Realizando_Escolhas('home_banner_realizando_escolhas', 'fechar_banner');
                                    setShowBannerInfo();
                                }}
                            >{`Fechar janela`}</CloseBanner>
                        </ButtonContainer>
                    </Content>
                )}
            </Container>
        </>
    );
};

export default BannerInfo;
