import { TipoAnexoConteudo } from './TipoAnexoConteudo';
import { jsonProperty, Serializable } from 'ts-serializable';
import { RespostasRecursosCostumizados } from './RespostasRecursosCostumizados';

export class ComentarioSimplificado extends Serializable {
    @jsonProperty(Number, null)
    codigoConteudo: number | null = -1;

    @jsonProperty(String, null)
    comentario: string = '';
}

export class ComentariosRecursosCostumizados extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(String, null)
    codigoUsuario: string = '';

    @jsonProperty(String, null)
    nomeUsuario: string = '';

    @jsonProperty(Number, null)
    codigoConteudo: number | null = -1;

    @jsonProperty(String, null)
    comentario: string = '';

    @jsonProperty([RespostasRecursosCostumizados], null)
    respostas: RespostasRecursosCostumizados[] | null = [];

    @jsonProperty(String, null)
    ultimaAtualizacao: string = '';

    @jsonProperty(Boolean, null)
    ativo: boolean = false;

    @jsonProperty(String, null)
    dataCriacao: string = '';
}

export class ComentarioGestaoAdmin extends Serializable {
    @jsonProperty(Number, null)
    codigo: number = -1;

    @jsonProperty(String, null)
    codigoUsuario: string = '';

    @jsonProperty(String, null)
    nomeUsuario: string = '';

    @jsonProperty(String, null)
    nomeConteudo: string = '';

    @jsonProperty(String, null)
    nomePagina: string = '';

    @jsonProperty(String, null)
    email: string = '';

    @jsonProperty(Number, null)
    codigoConteudo: number | null = -1;

    @jsonProperty(String, null)
    comentario: string = '';

    @jsonProperty([RespostasRecursosCostumizados], null)
    respostas: RespostasRecursosCostumizados[] | null = [];

    @jsonProperty(String, null)
    ultimaAtualizacao: string = '';

    @jsonProperty(Boolean, null)
    respostaAdmin: boolean = false;

    @jsonProperty(Boolean, null)
    ativo: boolean = false;

    @jsonProperty(String, null)
    dataCriacao: string = '';
}
