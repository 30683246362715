import { NavyButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { Col, FormControl } from 'react-bootstrap';
import styled from 'styled-components';

const AdminUsersStyled = {
    Container: styled.div`
        background-color: white;
        border-radius: 10px;
        padding: 15px 45px;
        font-family: 'Barlow';
        font-style: normal;
        margin-left: 120px;
        width: calc(100% - 120px);
        min-height: calc(100vh - 126px);

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-left: 0;
            width: 100%;
            padding: 15px 20px;
        }

        .modal-content {
            border-radius: 15px;
        }
    `,

    Title: styled.p`
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin-bottom: 10px;
    `,

    Text: styled.p`
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 10px;
    `,

    DivRowRight: styled.div`
        display: flex;

        width: 85%;
        margin-bottom: 15px;

        justify-content: space-between;
    `,

    RowRigth: styled.div`
        display: flex;
        margin-bottom: 15px;
        width: 100%;
        flex-direction: column;

        .label-order {
            font-family: 'Inter';
            font-size: 12px;
            color: #2d3648;
            font-weight: 600;
        }
    `,

    Input: styled(FormControl)`
        margin-top: 5px;
        border: 2px solid #cbd2e0;
        outline: 1;
        padding: 10px;
        border-radius: 6px;
        width: 95%;
    `,

    TableContainer: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
            border: 1px green solid;
        }
    `,

    GridPost: styled.div`
        display: grid;
        grid-template-columns: 1fr 140px 1fr 260px 200px;
        max-width: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    `,

    GridHead: styled.div`
        background: #e5f6fd;
        color: #003c78;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    `,

    GridCell: styled.div`
        width: 100%;
        text-align: center;
        padding: 10px 0;

        display: flex;
        justify-content: center;
        align-items: center;

        word-wrap: break-word;

        //border: 1px green solid;
    `,

    Separator: styled.div`
        margin-top: 20px;
        margin-bottom: 20px;
        height: 0px;
        border: 1px solid #cbd2e0;
        width: 100%;
        max-width: 690px;
    `,

    LoadingContainer: styled.div`
        width: 100%;
    `,

    NoContent: styled.div`
        width: 390px;
        height: fit-content;
        background-color: #e9e9e9;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 60px;
        padding: 20px;
        text-align: center;
    `,

    ContanerPagination: styled.div`
        display: flex;
        justify-content: center;
        margin-top: 40px;
        flex-wrap: wrap;
    `,

    ButtonPagination: styled(NavyButton)<{ active?: boolean }>`
        width: 40px;
        height: 30px;
        border-radius: 5px;
        margin: 5px 4px;

        ${props =>
            props.active
                ? ` background-color: #003c78;
                     color: white;`
                : ''}
    `,

    ButtonNextPagination: styled(NavyButton)`
        width: 40px;
        height: 30px;
        border-radius: 5px;
        margin: 5px 4px;
        color: #747474;
        border-color: #747474;
        background-color: white;

        :hover {
            border-color: #747474;
            background-color: white;
            color: #747474;
        }
    `,

    Label: styled.p`
        color: #003c78;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        margin-top: 7px;
    `,

    //Não é Whisky
    RedLabel: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #be4141;
        max-width: 200px;
        margin: 0 20px;
    `,

    Col: styled(Col)<{ disabled?: boolean }>`
        width: fit-content;
        padding: 5px;
        border-radius: 3px;
        user-select: none;
        transition: 150ms;
        opacity: ${props => (!props.disabled ? '1' : '0.6')};
        cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};

        :hover {
            background-color: #f4f4f4;
        }
    `,

    MiniLabel: styled.p`
        color: #999;
        font-size: 0.85em;
        cursor: pointer;
        transform: translateY(20px);

        :hover {
            text-decoration: underline;
        }
    `,
};

export default AdminUsersStyled;
