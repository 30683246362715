import Loading from 'components/Loading';
import Row from 'components/Row';
import Select from 'components/Select';
import copy from 'copy-to-clipboard';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { FilterRecursosCostumizados } from 'enums/FilterRecursosCostumizados';
import useLogged from 'hooks/useLogged';
import React, { useContext } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsShare } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { RecursosContext } from './context/RecursosContext';
import RecursoItem from './RecursoItem';
import Styled from './RecursosPage.styled';
import keycloak from 'core/config/keycloak';
import { RoleEnum } from 'enums/RoleEnum';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import { validateItemExclusivoManualmente } from 'helpers/Functions';
import PerfilUsuario from 'models/perfil/PerfilUsuario';

export interface IRecursosPage {}

const options = [
    {
        label: 'Todos',
        value: FilterRecursosCostumizados.TODOS,
    },
    {
        label: 'Iniciados',
        value: FilterRecursosCostumizados.INICIADOS,
    },
    {
        label: 'Não Iniciados',
        value: FilterRecursosCostumizados.NAO_INICIADOS,
    },
    {
        label: 'Finalizados',
        value: FilterRecursosCostumizados.FINALIZADOS,
    },
];

const RecursoPage = ({}: IRecursosPage) => {
    //Hooks
    const history = useHistory();
    const { login, authenticated } = useLogged();

    const { itemRecurso, itensRecursoResumido, pagina, updateWithStatus, loading, filterSelected } = useContext(RecursosContext.Context);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const perfilReducer: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const gotoFavs = () => {
        if (authenticated) history.push('/recursos/conteudos-favoritos');
        else login('/recursos/conteudos-favoritos');
    };

    const copyToClipboard = () => {
        copy(window.location.href);
        toast.success('Link do conteúdo copiado!');
    };

    const verifyAcesso = (): Boolean => {
        const link: string = location.pathname.split('/')[2];

        if (link === 'dialogos-socioemocionais') {
            if (keycloak.authenticated && usuarioLogado.roles.includes(RoleEnum.Administrador)) {
                return true;
            }

            const validate = validateItemExclusivoManualmente(perfilReducer);

            if (validate) {
                return true;
            }
        }

        return false;
    };

    const ComponentInformation = (): any => {
        if (IsMobileMaxWidth()) {
            return (
                <React.Fragment>
                    <Toaster />
                    <Styled.Title>{pagina?.nome}</Styled.Title>
                    <Styled.Text>{pagina?.descricao}</Styled.Text>

                    <Styled.Row justify="end" style={{ flexWrap: 'wrap' }}>
                        <Row alignVertical="end">
                            <Styled.ButtonFav className="desk-only" style={{ transform: 'translateY(12px)' }} onClick={gotoFavs}>
                                <AiOutlineHeart />
                                <span>Meus favoritos</span>
                            </Styled.ButtonFav>

                            <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>{'Compartilhar'}</Tooltip>}>
                                <Styled.ShareButton variant="secondary" onClick={copyToClipboard}>
                                    <BsShare size={'20px'} />
                                </Styled.ShareButton>
                            </OverlayTrigger>
                            {/* {authenticated && (
                                <Styled.Column style={{ marginLeft: 20, marginRight: '13px' }}>
                                    <Styled.Label>Filtro </Styled.Label>
                                    <Select isSmall value={filterSelected} itens={options} onSelected={e => updateWithStatus(String(e.value))} />
                                </Styled.Column>
                            )} */}
                        </Row>
                    </Styled.Row>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Toaster />
                    <Styled.Title>{pagina?.nome}</Styled.Title>
                    <Styled.Row justify="space-between" style={{ flexWrap: 'wrap' }}>
                        <Styled.Text>{pagina?.descricao}</Styled.Text>
                        <Row alignVertical="end">
                            <Styled.ButtonFav
                                className="desk-only"
                                style={{ transform: 'translateY(12px)', marginRight: 10 }}
                                onClick={gotoFavs}
                            >
                                <AiOutlineHeart />
                                <span>Meus favoritos</span>
                            </Styled.ButtonFav>
                            <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>{'Compartilhar'}</Tooltip>}>
                                <Styled.ShareButton variant="secondary" onClick={copyToClipboard}>
                                    <BsShare size={'20px'} />
                                </Styled.ShareButton>
                            </OverlayTrigger>

                            {/* {authenticated && (
                                <Styled.Column>
                                    <Styled.Label>Filtro </Styled.Label>
                                    <Select isSmall value={filterSelected} itens={options} onSelected={e => updateWithStatus(String(e.value))} />
                                </Styled.Column>
                            )} */}
                        </Row>
                    </Styled.Row>
                </React.Fragment>
            );
        }
    };

    return (
        <>
            {loading ? (
                <div style={{ marginTop: '120px' }}>
                    <Loading />
                </div>
            ) : (
                <Styled.Container>
                    <Row justify="end">
                        <div className="mobile-only">
                            <Styled.ButtonFav onClick={gotoFavs}>
                                <AiOutlineHeart />
                                <span>Meus favoritos</span>
                            </Styled.ButtonFav>
                        </div>
                    </Row>

                    <ComponentInformation />

                    <Styled.Grid>
                        {itensRecursoResumido
                            .sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                            .filter(
                                i =>
                                    i.oculto === false &&
                                    (keycloak.authenticated && verifyAcesso() ? true : i.possuiAcesso)
                            )
                            .map((dados, index) => {
                                return <RecursoItem key={index} data={dados} />;
                            })}
                    </Styled.Grid>
                </Styled.Container>
            )}
        </>
    );
};

export default RecursoPage;
