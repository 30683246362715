import React, { useContext, useEffect, useState } from 'react';
import { IAdminEditSection } from './AdminEditBanner';
import AdminSecaoPaginaData, {
    AdminSecaoPaginaCarousel,
    AdminSecaoPaginaCarouselMedia,
    AdminSecaoPaginaCarouselMediaItem,
} from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Styled from './AdminEditor.styled';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import { ToastError, ToastSuccess } from 'components/Toaster';
import Loading from 'components/Loading';
import { GradientButton, OutlineButton, RedSolidButton } from 'components/UI/Buttons';
import AdminEditCarouselMediaItem from './AdminEditCarouselMediaItem';
import SecaoCarrosselMedia from 'models/generic-components/SecaoCarrosselMedia';
import { CarouselMedia } from 'models/generic-components/CarouselMedia';
import TipoAnexoCarrossel from 'enums/TipoAnexoCarrosselEnum';
import { getOptionFromValue } from './TipoAnexoCarrossel';

const initialContent = new AdminSecaoPaginaCarouselMediaItem('', '#000000', 12, false, TipoAnexoCarrossel.IMAGEM, '');

const AdminEditCarouselMedia = ({ onSaved, onUpdate, onDelete, mode }: IAdminEditSection) => {
    const [corSecao, setCorSecao] = useState<string>('#FFFFFF');
    const service = new AdminGestaoPaginaService();
    const [randomKey, setRandomKey] = useState(0);
    const [itens, setItens] = useState<AdminSecaoPaginaCarouselMediaItem[]>([]);
    const { currentSection, selectSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);
    const [loading, setLoading] = useState(false);
    const [idSecao, setIdSecao] = useState(-1);
    const [submitting, setSubmitting] = useState(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const params: any = useParams();

    const addNewItem = () => {
        const newItem = cloneDeep(initialContent);
        setItens([...itens, newItem]);
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteItemCustom(idSecao); // alterar

            if (response.status === 200) {
                ToastSuccess('Item excluído com sucesso!');
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteSecao(false);
        setIsLoadingDelete(false);
    };

    const submitSection = async () => {
        if (!currentSection) {
            alert('Erro ao enviar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaCarouselMedia;
        holder.corSecao = corSecao;

        currentSection.modelData = holder;

        if (!currentSection.isPersist) await saveSection(currentSection, holder);
        else await updateSection(currentSection);

        setSubmitting(false);
    };

    const updateSection = async (section: AdminSecaoPaginaData) => {
        if (!section) {
            ToastError('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSecaoCarrosselMultimidia(section.codigo, corSecao); // alterar

        if (status < 300) {
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaCarouselMedia) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.secaoCarrosselMultimidia.codigo);
            selectSection(section);
            setModePage(true);
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setLoading(true);

            const id = String(params.id);
            const { data } = await service.buscarCarrosseisMultimidia(parseInt(id));
            const secao = new SecaoCarrosselMedia().fromJSON(data);
            setIdSecao(parseInt(id));
            setCorSecao(secao.corSecao);
            setModePage(true);

            const { data: datacards } = await service.buscarCarrosselMultimidia(parseInt(id));
            const _cardsraw = Array.from(datacards).map((c: any) => new CarouselMedia().fromJSON(c));

            const _cards: AdminSecaoPaginaCarouselMediaItem[] = [];

            for (const c of _cardsraw) {
                const newCard = new AdminSecaoPaginaCarouselMediaItem(
                    c.detalhamento,
                    c.corDetalhamento,
                    parseInt(c.tamanhoDetalhamento),
                    c.ocultarDetalhamento,
                    c.tipoAnexo,
                    c.anexoBase64,
                    c.urlVideo,
                    c.oculto
                );
                newCard.setCodigo(c.codigo);
                _cards.push(newCard);
            } // alterar

            setItens(_cards);

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <ModalExclusao
                showModal={showModalDeleteSecao}
                handleHiden={setShowModalDeleteSecao}
                handleExcluxed={handleDelete}
                isLoading={isLoadingDelete}
            />
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Styled.HeadFormContainer>
                        <Styled.Label>Cor de Fundo</Styled.Label>
                        <Styled.ColorPicker type="color" value={corSecao} onChange={e => setCorSecao(e.target.value)} />
                        <Styled.TextAlert>
                            {' '}
                            <strong>⚠️ Alerta: Número de Itens Mínimo e Máximo Necessários</strong> <br />
                            <br />
                            Por favor, note que para exibir o componente, você precisará criar no mínimo <strong>três itens</strong> . O máximo
                            que o sistema suporta é de <strong>seis itens</strong> . Certifique-se de adicionar a quantidade adequada de itens
                            para garantir o funcionamento correto do componente.
                        </Styled.TextAlert>
                        <Styled.RightContainer>
                            {modePage && (
                                <RedSolidButton style={{ marginRight: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                    Deletar
                                </RedSolidButton>
                            )}
                            <GradientButton onClick={submitSection} disabled={submitting}>
                                {submitting ? 'Salvando' : 'Salvar'} Seção
                            </GradientButton>
                        </Styled.RightContainer>
                    </Styled.HeadFormContainer>
                    {currentSection?.isPersist && (
                        <>
                            <div>
                                <Styled.Title style={{ marginTop: '30px' }}>Itens</Styled.Title>
                            </div>
                            <div style={{ width: '100%' }}>
                                <AdminEditCarouselMediaItem
                                    fetchItem={fetchItem}
                                    currentSection={currentSection!}
                                    itens={itens}
                                    setItens={setItens}
                                />
                            </div>

                            {itens.length < 6 && (
                                <OutlineButton style={{ marginTop: '25px', marginBottom: '25px' }} onClick={addNewItem}>
                                    <span style={{ fontSize: '0.95rem' }}>+ Adicionar novo item</span>
                                </OutlineButton>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default AdminEditCarouselMedia;
