import { ButtonCPC } from 'components/UI/Buttons';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 10% 0 0;
    padding-bottom: 50px;

    @media only screen and (max-width: 1200px) {
        padding: 40px 10% 50px 10%;
    }
`;

const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.3px;
`;

const WhiteText = styled(Text)`
    font-size: clamp(14px, 5vw, 22px);
    line-height: 26px;
    color: #fcfcfc;
    max-width: 580px;
    margin-bottom: 35px;
`;

const YellowTitle = styled(Text)`
    display: flex;
    font-weight: 700;
    font-size: clamp(24px, 5vw, 36px);
    line-height: 90%;
    color: #ffca00;
    text-align: center;
    margin-top: 80px;
    justify-content: center;

    @media only screen and (max-width: 1200px) {
        padding-left: 0;
        height: 100%;
        text-align: inherit;
        margin-top: 20px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`;

const TransparentButton = styled(ButtonCPC)`
    background: transparent;
    border-radius: 50px;
    border: 1px solid #ffca00;
    color: #ffca00;

    &:hover {
        border: 1px solid white;
        color: white;
    }
`;

export { Container, WhiteText, YellowTitle, ButtonContainer, TransparentButton };
