import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

export class EducationalSolutionCategory extends Serializable {
    @jsonProperty(Number, null)
    id?: number | null = -1;

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(String)
    label: string = '';

    @jsonProperty(Boolean)
    desativado: boolean = false;
}

class EducationalSolution extends Serializable {
    @jsonProperty(Number, null)
    id: number | null = null;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(Boolean)
    active: boolean = false;

    @jsonProperty(Boolean)
    disabled: boolean = false;

    @jsonProperty(Boolean)
    linkExterno: boolean = false;

    @jsonProperty(String)
    image: string = '';

    @jsonProperty(String)
    imagePath: string = '';

    @jsonProperty(String)
    followRoute: string = '';

    @jsonProperty(String)
    followText: string = '';

    @jsonProperty(Boolean)
    defaultData: boolean = true;

    @jsonProperty(Number)
    complement: number = -1;

    @jsonProperty(Boolean)
    proximoLancamento: boolean = false;

    @jsonProperty(Boolean)
    novidade: boolean = false;

    @jsonProperty(Number)
    order: number = 0;

    @jsonProperty(Number)
    ordem: number = 0;



    @jsonProperty(String, null)
    categorias: String = '';

    constructor() {
        super();
    }
}

export default EducationalSolution;
