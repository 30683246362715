import styled from 'styled-components';

import Colors from '../../enums/Colors';
import { media760, mediaOnlyScreenMaxWidthStr } from '../../core/util/cssHelp';
import { MediaQuery } from 'core/util/FullCss';
import { MobileMaxWidth } from 'constants/Dimensions';

interface PainelSocioemocionalStyleProps {
    backgroundColor?: Colors;
}

export const PainelSocioemocionalStyle = styled.div<PainelSocioemocionalStyleProps>`
    flex: 1;
    min-height: 100vh;
    background-color: ${props => props.backgroundColor};
`;

export const MainContainer = styled.section(
    media760(
        {
            height: '100%',
            paddingTop: '86px',
            position: 'relative',
            maxWidth: '1253px',
            margin: '0 auto',
            marginTop: '30px',
        },
        {
            marginTop: '0px',
            paddingTop: '60px',
        }
    )
);

export const Title = styled.p(
    media760(
        {
            fontSize: '32px',
            lineHeight: '42px',
            fontStyle: 'normal',
            fontFamily: 'Barlow',
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
            color: Colors.BlueGrey,
        },
        {
            fontSize: '18px',
        }
    )
);

const StyledHeader = {
    Container: styled.div`
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 1rem;
        color: #fff;
        height: 50px;
        background-color: ${Colors.SoftBlue};
        margin-bottom: 30px;
    `,

    Title: styled.label`
        font-weight: bold;
    `,
};

export default StyledHeader;
