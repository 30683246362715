import React, { useEffect, useState, useContext } from 'react';
import Styled from './AdminSocioEmocional.styled';

import { OutlineButton } from 'components/UI/Buttons';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import AdminAvaliacao from '../../../../models/admin/AdminAvaliacao';
import AdminSocioemocionalService from 'core/http/service/admin/AdminSocioemocional.service';
import { DateHelp } from 'core/util/DateHelp';
import AdminModalItem from './AdminModalItem';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';
import { base64XLSToFile } from 'pages/admin/utils/base64ToFile';

import eyeIcon from '../../../../images/admin/eye.svg';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select, { SelectOptionType } from 'components/Select';
import { AdminContext } from 'pages/admin/context/AdminContext';
import styled from 'styled-components';

export interface IAdminSocioEmocional {}

const AdminSocioEmocional = ({}: IAdminSocioEmocional) => {
    const funcionalidadeFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: 0,
        },
        {
            label: 'PDP',
            value: 'PDP',
        },
        {
            label: 'Diário de bordo',
            value: 'DIARIOBORDO',
        },
    ];

    const notaFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: 0,
        },
        {
            label: '1',
            value: '1',
        },
        {
            label: '2',
            value: '2',
        },
        {
            label: '3',
            value: '3',
        },
        {
            label: '4',
            value: '4',
        },
        {
            label: '5',
            value: '5',
        },
    ];

    const commentFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: 0,
        },
        {
            label: 'Com comentário',
            value: 'true',
        },
        {
            label: 'Sem comentário',
            value: 'false',
        },
    ];

    const [page, setPage] = useState(1);
    const [totalpages, setTotalPages] = useState(1);
    const [items, setItems] = useState<AdminAvaliacao[]>([]);
    const [showIdItemModal, setShowIdItemModal] = useState(-1);

    const { subPage } = useContext(AdminContext.Context);

    const [order] = useState();

    interface IFiltro {
        funcionalidade: string | null;
        nota: number | null;
        possuiComentario: boolean | null;
    }

    const [filtro, setFiltro] = useState<IFiltro>({
        funcionalidade: null,
        nota: null,
        possuiComentario: null,
    });

    const history = useHistory();

    const changePage = (page: number) => {
        setPage(page);
    };

    const fetchItens = async (paramFilter: IFiltro) => {
        const service = new AdminSocioemocionalService();
        const { data } = await service.buscarAvaliacoes(
            page,
            order ?? '',
            paramFilter.funcionalidade ?? '',
            paramFilter.nota ?? undefined,
            paramFilter.possuiComentario
        );
        const rows = data.rows;
        setTotalPages(data.totalPages);

        const _items: AdminAvaliacao[] = rows.map((item: any) => new AdminAvaliacao().fromJSON(item));
        setItems(_items);
    };

    const downloadXLS = async () => {
        const service = new AdminSocioemocionalService();
        const response = await service.downloadXLSAvaliacoes();

        if (response.status < 300) {
            base64XLSToFile(response.data, `avaliacoesSocioemocional-${DateHelp.formatDateFull(new Date()).replaceAll('/', '-')}.xls`);
        }
    };

    const viewItem = (idItem: number) => {
        setShowIdItemModal(idItem);
    };

    useEffect(() => {
        fetchItens(filtro);
    }, [page]);

    useEffect(() => {
        if (subPage === null) history.push('/admin/socioemocional/edit');
    }, [subPage]);

    // Espaço horizontal esquerdo (Isso serve para o menu lateral não ficar por cima do conteúdo).
    const SpaceLeft = styled.div`
        margin-left: 100px;
        margin-bottom: 100px;
    `;

    return (
        <SpaceLeft>
            <Styled.TopBlueBar>
                <Styled.LeftButton onClick={() => history.push('/admin/socioemocional/edit')}>Voltar</Styled.LeftButton>
                <p>Painel Sociemocional</p>
            </Styled.TopBlueBar>

            <AdminModalItem show={showIdItemModal != -1} onHide={() => setShowIdItemModal(-1)} item={items.find(i => i.id == showIdItemModal)} />

            <Styled.Container>
                <Styled.Title>Avaliações por funcionalidade</Styled.Title>
                <br />

                <Styled.ButtonDownload>
                    <OutlineButton onClick={downloadXLS}>Baixar.xls</OutlineButton>
                </Styled.ButtonDownload>

                <Styled.TopBar>
                    <Styled.RowRigth>
                        <span className="label-order">Funcionalidade</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={funcionalidadeFilter}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        funcionalidade: o.value !== 0 ? String(o.value) : null,
                                        nota: filtro.nota,
                                        possuiComentario: filtro.possuiComentario,
                                    };
                                    setFiltro(filter);
                                    fetchItens(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Comentários</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={commentFilter}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        funcionalidade: filtro.funcionalidade,
                                        nota: filtro.nota,
                                        possuiComentario: null,
                                    };

                                    if (o.value === 0) {
                                        filter.possuiComentario = null;
                                    } else {
                                        filter.possuiComentario = o.value === 'true' ? true : false;
                                    }
                                    setFiltro(filter);
                                    fetchItens(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                    <Styled.RowRigth>
                        <span className="label-order">Notas</span>
                        <span className="span-select">
                            <Select
                                width="200px"
                                isSmall={true}
                                itens={notaFilter}
                                onSelected={o => {
                                    const filter: IFiltro = {
                                        funcionalidade: filtro.funcionalidade,
                                        nota: o.value !== 0 ? Number(o.value) : null,
                                        possuiComentario: filtro.possuiComentario,
                                    };
                                    setFiltro(filter);
                                    fetchItens(filter);
                                }}
                            />
                        </span>
                    </Styled.RowRigth>
                </Styled.TopBar>

                <br />

                <Styled.Grid>
                    <Styled.GridHead>Funcionalidade</Styled.GridHead>
                    <Styled.GridHead>Data avaliação</Styled.GridHead>
                    <Styled.GridHead>Avaliações</Styled.GridHead>
                    <Styled.GridHead>Comentário</Styled.GridHead>
                    <Styled.GridHead>Ações</Styled.GridHead>

                    {items.map((data, idx) => (
                        <React.Fragment key={idx}>
                            <Styled.GridCol>{data.feature}</Styled.GridCol>
                            <Styled.GridCol>{DateHelp.formatDateFull(data.ultimaAtualizacao)}</Styled.GridCol>
                            <Styled.GridCol>
                                <AdminRateStar rate={data.rate} />
                            </Styled.GridCol>
                            <Styled.GridCol style={{ overflow: 'hidden' }}>{data.comment}</Styled.GridCol>
                            <Styled.GridCol>
                                <OverlayTrigger overlay={<Tooltip id={'tooltip-action-edit_' + data.id}>Visualizar</Tooltip>} placement={'top'}>
                                    <span>
                                        <Styled.ViewButton src={eyeIcon} onClick={() => viewItem(data.id)} />
                                    </span>
                                </OverlayTrigger>
                            </Styled.GridCol>
                        </React.Fragment>
                    ))}
                </Styled.Grid>
                <br />
                <br />

                <AdminPagination onChangePage={changePage} total={totalpages} />
            </Styled.Container>
        </SpaceLeft>
    );
};

export default AdminSocioEmocional;
