import { OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled, { keyframes } from 'styled-components';

const grow = keyframes`
  from {
    width:0px;
  }
  to {
    width: 100%;
  }
`;

const IntermediatePageContentStyled = {
    Container: styled.div`
        width: 100%;
        max-width: 100vw;
        padding-bottom: 20px;
        padding-left: 30px;

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            padding-left: 0;
            padding-right: 0;
        }
    `,

    Grid: styled.div`
        display: grid;
        grid-template-columns: 200px 10px 1fr;

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            grid-template-columns: 1fr;
        }

        .content {
            @media only screen and (max-width: ${ResizeMediumWidth}px) {
                padding: 15px;
            }
        }

        .mobile-center {
            @media only screen and (max-width: ${ResizeMediumWidth}px) {
                text-align: left;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
    `,

    VerticalLine: styled.div`
        width: 3px;
        background-color: #aaaaaa;
        height: 100%;
    `,

    Column: styled.div`
        width: 100%;
        padding-right: 42px;
    `,

    ButtonContainer: styled.div`
        width: 100%;
        padding: 0;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    `,

    ComplementContainer: styled.div`
        display: inline-block;
    `,

    SeeMore: styled.div`
        margin-top: 50px;
        max-width: 100%;
        transform: translate(-90px, 0px);

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            transform: translate(0px, 0px);
        }
    `,

    Title2: styled.div<{ active: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-size: 16px;
        line-height: 42px;
        cursor: pointer;
        text-align: right;
        color: ${props => (props.active ? '#183f73' : '#888888')};
        font-weight: ${props => (props.active ? '700' : '500')};
        padding-right: 15px;
    `,

    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 42px;
        color: #183f73;
    `,

    OutlineButton: styled(OutlineButton)<{ offsetRight?: boolean }>`
        color: #003c78;
        border: 2px solid #003c78;
        margin-top: 40px;
        transform: translate(${props => (props.offsetRight ? '-90px' : '0')}, 0px);

        :hover {
            background-color: #003c78;
            color: white;
        }

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            margin: 0;
            transform: translate(0px, 0px);
            max-width: 227px;
        }
    `,

    SectionContainer: styled.div`
        width: 100%;
        padding: 0px 49px;

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            padding: 0;
        }
    `,

    SelectionContainer: styled.div`
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid ${Colors.Grey8};
    `,

    TabItem: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        flex-grow: 1;
    `,

    TabItemLabel: styled.p<{ isActive: boolean }>`
        height: 100%;
        background-color: transparent;
        font-weight: ${props => (props.isActive ? '700' : '500')};
        color: ${props => (props.isActive ? Colors.DarkBlue : Colors.Grey5)};
        border: none;
        padding: 20px 0;
    `,

    TabItemIndicator: styled.div`
        width: 100%;
        height: 5px;
        background: linear-gradient(318.36deg, rgb(232, 149, 13) 0%, rgb(255, 184, 0) 100%);
        border-radius: 5px;
        animation: ${grow} 0.3s linear;
    `,

    Text: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #2e2e2e;

        max-width: 860px;
    `,

    TextNavy: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #003c78;
    `,

    TextNavyBold: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #2e2e2e;
        margin: 20px;
    `,

    ButtonLink: styled.button`
        background-color: ${Colors.Blue};
        outline: none;
        color: ${Colors.White};
        border: none;
        width: 240px;
        height: 35px;
        border-radius: 5px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        border: 1px solid ${Colors.Blue};

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            margin: 10px auto;
        }

        &:hover {
            background: ${Colors.DarkBlue3};
            border: 1px solid ${Colors.DarkBlue3};
            color: ${Colors.SoftBlue};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    Row: styled.div`
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
    `,

    Card: {
        Container: styled.div`
            border-radius: 5px;
            width: 224px;
            height: 224px;
            box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            margin-right: 40px;
        `,

        Image: styled.img`
            width: 100%;
            height: 110px;
        `,

        Content: styled.div`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #183f73;
            flex-grow: 1;
            padding: 10px;
        `,

        Button: styled.div`
            background: linear-gradient(318.36deg, #0b78c8 0%, #47aefc 100%);
            border-radius: 5px;
            color: white;
            text-align: center;
            height: 30px;
            padding-top: 6px;
            cursor: pointer;
        `,
    },
};

export default IntermediatePageContentStyled;
