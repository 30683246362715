import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';
import CommonStyled from './CommonStyled';

const ArtigoItemStyled = {
    Container: styled.div`
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    LoadingContainer: styled.div``,

    Content: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            max-width: 100%;
            line-height: 1.75rem;
        }
    `,

    Title: styled(CommonStyled.Title)`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
            text-align: left;
        }
    `,

    SubTitle: styled(CommonStyled.SubTitle)`
        color: #00a8e9;
    `,

    Body: styled.div`
        margin: 20px 0;
        line-height: 1.75em;
    `,

    Cover: styled.img`
        max-width: 100%;
    `,

    ActionButton: CommonStyled.ActionButton,

    Date: CommonStyled.Date,

    ActionContainer: styled.div`
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: row;
            justify-content: flex-start;
        }
    `,
};

export default ArtigoItemStyled;
