import { ConteudoFavoritoCostumizado } from 'models/recursos-costumizados/ConteudoFavoritoCostumizado';
import { RecursosContext } from 'pages/recursos/context/RecursosContext';
import React, { useContext, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillHeart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Styled from './CardConteudoFavorito.styled';

interface ICardConteudoFavorito {
    dados: ConteudoFavoritoCostumizado;
    handleDesfavoritar: (value: boolean) => void;
}

const CardConteudoFavorito = ({ dados, handleDesfavoritar }: ICardConteudoFavorito) => {
    const history = useHistory();

    function gotoContent() {
        console.log(dados);
        history.push(`/recursos${dados.urlRecurso}${dados.urlItem}${dados.urlEtapa}/${dados.urlConteudo}`);
    }

    return (
        <Styled.Container>
            <Styled.Divisor>
                <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>{'Remover favorito'}</Tooltip>}>
                    <Styled.ButtonTransparent onClick={() => handleDesfavoritar(true)}>{<AiFillHeart size={'24px'} />}</Styled.ButtonTransparent>
                </OverlayTrigger>
                <Styled.Title>{dados.nomeConteudo}</Styled.Title>
            </Styled.Divisor>

            <Styled.ButtonAccess onClick={gotoContent}>Acessar</Styled.ButtonAccess>
        </Styled.Container>
    );
};

export default CardConteudoFavorito;
