import { ItemContainer } from 'components/RelatedContent.styled';
import Colors from 'enums/Colors';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { callbackify } from 'util';

const SimpleAccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 400;
    margin-bottom: 70px;
    gap: 16px;
`;

interface IAccordionOpenControl {
    open: boolean;
}

const AccordionItem = styled.div<IAccordionOpenControl>`
    display: flex;
    flex-direction: column;
    width: 545px;

    border-radius: 4px;

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 90%;

    color: ${props => (props.open ? Colors.White : Colors.Blue)};
    background: ${props => (props.open ? Colors.Blue : 'rgba(0, 60, 120, 0.1)')};
    cursor: ${props => (props.open ? 'unset' : 'pointer')};
    height: ${props => (props.open ? 'fit-content' : '60px')};

    max-height: 1200px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(1, 1, 1, 1);

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: ${props => (props.open ? 'fit-content' : '82px')};
    }

    @media only screen and (max-width: 350px) {
        height: ${props => (props.open ? 'fit-content' : '120px')};
    }
`;

const AccordionHeader = styled.div<IAccordionOpenControl>`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    justify-content: center;
`;

const TitleContainer = styled.div<IAccordionOpenControl>`
    margin-right: 15px;
`;

const SvgContainer = styled.div<IAccordionOpenControl>`
    cursor: ${props => (!props.open ? 'unset' : 'pointer')};
`;

const HeaderContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const HeaderBottomBorder = styled.div<IAccordionOpenControl>`
    width: 100%;
    margin-top: 12px;
    height: 1px;
    border-bottom: ${props => (!props.open ? 'unset' : '1px solid #7291B0')};
    display: ${props => (!props.open ? 'none' : 'initial')};
`;

const ContentBody = styled.div`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 16px 24px;

    a {
        color: ${Colors.White};
        text-decoration: underline;
    }
`;

export interface IContentModel {
    identifier: number;
    title: string;
    content: string;
    open: boolean;
}

interface ISimpleAccordion {
    contentList: IContentModel[];
}

interface IAccordionFC {
    item: IContentModel;
    callback: (identifier: number) => void;
}

const IconFC: FunctionComponent<IAccordionOpenControl> = ({ ...props }: IAccordionOpenControl) => {
    return (
        <>
            {!props.open ? (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M9.99998 5.83334C10.4602 5.83334 10.8333 6.20644 10.8333 6.66668V9.16668H13.3333C13.7935 9.16668 14.1666 9.53977 14.1666 10C14.1666 10.4602 13.7935 10.8333 13.3333 10.8333H10.8333V13.3333C10.8333 13.7936 10.4602 14.1667 9.99998 14.1667C9.53974 14.1667 9.16665 13.7936 9.16665 13.3333V10.8333H6.66665C6.20641 10.8333 5.83331 10.4602 5.83331 10C5.83331 9.53977 6.20641 9.16668 6.66665 9.16668H9.16665V6.66668C9.16665 6.20644 9.53974 5.83334 9.99998 5.83334Z"
                        fill="#003C78"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.833313 10C0.833313 4.9374 4.93737 0.833344 9.99998 0.833344C15.0626 0.833344 19.1666 4.9374 19.1666 10C19.1666 15.0626 15.0626 19.1667 9.99998 19.1667C4.93737 19.1667 0.833313 15.0626 0.833313 10ZM9.99998 2.50001C5.85784 2.50001 2.49998 5.85787 2.49998 10C2.49998 14.1421 5.85784 17.5 9.99998 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85787 14.1421 2.50001 9.99998 2.50001Z"
                        fill="#003C78"
                    />
                </svg>
            ) : (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M6.66665 9.16668C6.20641 9.16668 5.83331 9.53977 5.83331 10C5.83331 10.4602 6.20641 10.8333 6.66665 10.8333H13.3333C13.7935 10.8333 14.1666 10.4602 14.1666 10C14.1666 9.53977 13.7935 9.16668 13.3333 9.16668H6.66665Z"
                        fill="#FCFCFC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99998 0.833344C4.93737 0.833344 0.833313 4.9374 0.833313 10C0.833313 15.0626 4.93737 19.1667 9.99998 19.1667C15.0626 19.1667 19.1666 15.0626 19.1666 10C19.1666 4.9374 15.0626 0.833344 9.99998 0.833344ZM2.49998 10C2.49998 5.85787 5.85784 2.50001 9.99998 2.50001C14.1421 2.50001 17.5 5.85787 17.5 10C17.5 14.1421 14.1421 17.5 9.99998 17.5C5.85784 17.5 2.49998 14.1421 2.49998 10Z"
                        fill="#FCFCFC"
                    />
                </svg>
            )}
        </>
    );
};

const cleanHTML = (dirtyHTML: string) =>
    DOMPurify.sanitize(dirtyHTML, {
        USE_PROFILES: { html: true },
    });

const AccordionFC: FunctionComponent<IAccordionFC> = ({ ...props }: IAccordionFC) => {
    return (
        <AccordionItem
            open={props.item.open}
            onClick={() => {
                if (!props.item.open) {
                    props.callback(props.item.identifier);
                }
            }}
        >
            <AccordionHeader open={props.item.open} id="header">
                <HeaderContentContainer id="headercontainer">
                    <TitleContainer open={props.item.open}>{props.item.title}</TitleContainer>
                    <SvgContainer
                        open={props.item.open}
                        onClick={() => {
                            if (props.item.open) {
                                props.callback(props.item.identifier);
                            }
                        }}
                    >
                        <IconFC open={props.item.open} />
                    </SvgContainer>
                </HeaderContentContainer>
                <HeaderBottomBorder open={props.item.open} id="bottomBorder" />
            </AccordionHeader>

            {props.item.open && (
                <div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                    <ContentBody dangerouslySetInnerHTML={{ __html: cleanHTML(props.item.content ?? '') }}></ContentBody>
                </div>
            )}
        </AccordionItem>
    );
};

const SimpleAccordion: FunctionComponent<ISimpleAccordion> = ({ ...props }: ISimpleAccordion) => {
    const [contents, setContents] = useState<IContentModel[]>(props.contentList);

    const toggleAccordion = (identifier: number) => {
        let clone = cloneDeep(contents);

        const itens: IContentModel[] = clone.filter(item => {
            return item.identifier === identifier;
        });

        if (itens[0].open) {
            itens[0].open = false;
        } else {
            itens[0].open = true;
            clone.forEach(item => {
                if (item.identifier !== identifier) {
                    item.open = false;
                }
            });
        }

        setContents(clone);
    };

    return (
        <SimpleAccordionContainer>
            {contents.map((item: IContentModel, idx: number) => {
                return (
                    <React.Fragment key={idx}>
                        <AccordionFC callback={toggleAccordion} item={item as IContentModel} />
                    </React.Fragment>
                );
            })}
        </SimpleAccordionContainer>
    );
};

export default SimpleAccordion;
