import React from 'react';
import styled from 'styled-components';

export interface IAvatarDefault {
    name: string;
    customStyles?: React.CSSProperties;
}

const Container = styled.div`
    background-color: #00a8e9;

    height: 82px;
    width: 82px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Letter = styled.p`
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
`;

const AvatarDefault = ({ name, customStyles }: IAvatarDefault) => {
    const letter = name.charAt(0).toLocaleUpperCase();

    return (
        <Container style={customStyles}>
            <Letter>{letter}</Letter>
        </Container>
    );
};

export default AvatarDefault;
