import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { jsonProperty, Serializable } from 'ts-serializable';

export class CardsCarrossel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    textoBotao: string = '';

    @jsonProperty(String)
    link: string = '';

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(String)
    ocupacao: string = '';

    @jsonProperty(Boolean)
    oculto: boolean = false;

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;

    @jsonProperty(String, null)
    filePath: string | null = null;
}
