import { ToastSuccess } from 'components/Toaster';
import { OutlineButton } from 'components/UI/Buttons';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminGestaoPaginasMode } from '../AdminGestaoPaginasContextWrapper';
import AdminSecaoPaginaData from '../AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from '../context/AdminGestaoPaginasContext';
import { initialBreadCrumb } from '../context/AdminGestaoPaginasProvider';
import { AdminModeloSecaoModelEnum } from '../new-edit-pagina/AdminGestaoPaginasModeloSecaoModal';
import Styled from './AdminGestaoPaginasSectionForm.styled';
import AdminEditBanner from './forms/AdminEditBanner';
import AdminEditCarousel from './forms/AdminEditCarousel';
import AdminEditCategoryCards from './forms/AdminEditCategoryCards';
import AdminEditSimple from './forms/AdminEditSimple';
import AdminEditSimpleImage from './forms/AdminEditSimpleImage';
import AdminEditInteresse from './forms/AdminEditInteresse';
import AdminEditItensCustom from './forms/AdminEditItensCustom';
import AdminEditCarouselMedia from './forms/AdminEditCarouselMedia';

export interface IAdminGestaoPaginasSectionForm {
    mode: AdminGestaoPaginasMode;
}

const AdminGestaoPaginasSectionForm = ({ mode }: IAdminGestaoPaginasSectionForm) => {
    const history = useHistory();
    const { currentSection, sections, setSections, breadCrumb, setBreadCrumb, currentPage } = useContext(AdminGestaoPaginasContext.Context);

    useEffect(() => {
        setBreadCrumb([
            { route: 'gestao-paginas', label: 'Gestão de páginas' },
            { route: 'gestao-paginas/editar-pagina/' + currentPage?.codigo, label: 'Editar Página' },
            { route: 'gestao-paginas/editar-secao', label: 'Editar Seção' },
        ]);
    }, []);

    const onSaveHandler = (secao: AdminSecaoPaginaData) => {
        setSections([...sections, secao]);
        history.replace('/admin/gestao-paginas/editar-pagina/' + currentPage?.codigo);
        ToastSuccess('Seção adicionada à pagina "' + currentPage?.nome + '"');
    };

    const deleteSecao = () => {
        history.push('/admin/gestao-paginas/editar-pagina/' + currentPage?.codigo);
    };

    const onUpdateHandler = (secao: AdminSecaoPaginaData) => {
        history.goBack();
        ToastSuccess('Seção atualizada');
    };

    const getSwitchForm = useCallback(() => {
        if (!currentSection) return 'Nenhum modelo válido';

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.BANNER) {
            return <AdminEditBanner onSaved={onSaveHandler} onUpdate={onUpdateHandler} onDelete={deleteSecao} mode={mode} />;
        }

        if (
            currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D ||
            currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E ||
            currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_I
        ) {
            return (
                <AdminEditSimpleImage
                    onDelete={deleteSecao}
                    onSaved={onSaveHandler}
                    onUpdate={onUpdateHandler}
                    mode={mode}
                    custom={currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_I}
                />
            );
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.INTERESSE) {
            return <AdminEditInteresse onDelete={deleteSecao} onSaved={onSaveHandler} onUpdate={onUpdateHandler} mode={mode} />;
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.SIMPLES_TEXTO) {
            return <AdminEditSimple onDelete={deleteSecao} onSaved={onSaveHandler} mode={mode} onUpdate={onUpdateHandler} />;
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.CAROUSEL) {
            return <AdminEditCarousel onDelete={deleteSecao} onSaved={onSaveHandler} onUpdate={onUpdateHandler} mode={mode} />;
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.CARTOES) {
            return <AdminEditCategoryCards onDelete={deleteSecao} onSaved={onSaveHandler} onUpdate={onUpdateHandler} mode={mode} />;
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.ITENS_CUSTOM) {
            return <AdminEditItensCustom onDelete={deleteSecao} onSaved={onSaveHandler} onUpdate={onUpdateHandler} mode={mode} />;
        }

        if (currentSection.modelData.modeloId == AdminModeloSecaoModelEnum.CAROUSEL_MEDIA) {
            return <AdminEditCarouselMedia onDelete={deleteSecao} onSaved={onSaveHandler} onUpdate={onUpdateHandler} mode={mode} />;
        }

        return 'Nenhum modelo válido';
    }, [currentSection]);

    return (
        <Styled.Container>
            <AdminBreadCrumb crumbs={breadCrumb} />

            <Styled.Title> Editar seção: {currentSection?.modelData.modeloNome} </Styled.Title>

            {getSwitchForm()}

            <br />
            <OutlineButton onClick={() => history.goBack()}>Voltar</OutlineButton>
        </Styled.Container>
    );
};

export default AdminGestaoPaginasSectionForm;
