import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledBackgroundComponent = {
    Container: styled.div`
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    `,

    Label: styled.p`
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
        margin-top: 25px;
        margin-bottom: 5px;
    `,

    CardColor: styled.div<{ colorBack: string | null }>`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dotted ${Colors.LightGrey};
        background-color: ${({ colorBack }) => (colorBack ? colorBack : '#FFFFF')};
        width: 80%;
        height: 120px;
        border-radius: 5px;
        gap: 1.5rem;
    `,

    ColorPicker: styled.input`
        width: 40px;
        height: 40px;
        margin-top: 10px;
    `,

    Title: styled.label<{ color: string }>`
        font-weight: 700;
        font-size: 20px;
        color: ${({ color }) => (color ? color : '#000')};
    `,

    Description: styled.label<{ color: string }>`
        font-weight: 500;
        color: ${({ color }) => (color ? color : '#000')};
    `,
};

export default StyledBackgroundComponent;
