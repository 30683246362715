import React, { RefObject } from 'react';
import { AppStorage } from 'storage/AppStorage';

export const CHAVE_REDIRECT_CURSO_LIVRE = 'CURSOLIVREREDIRECT';
export const CHAVE_REDIRECT_RE = 'REREDIRECT';
export const CHAVE_REDIRECT_TM = 'CHAVE_REDIRECT_TM';
export const CHAVE_REDIRECT_ALF = 'CHAVE_REDIRECT_ALF';
export const CHAVE_REDIRECT_VB = 'CHAVE_REDIRECT_VB';
export const CHAVE_REDIRECT_AFL_AP = 'CHAVE_REDIRECT_AFL_AP';
export const CHAVE_REDIRECT_BDS = 'CHAVE_REDIRECT_BDS';
export const CHAVE_REDIRECT_JCS = 'CHAVE_REDIRECT_JCS';

export const possuiRedirecionamentoPosLogin = () => {
    const redirectCursoLivre = sessionStorage.getItem(CHAVE_REDIRECT_CURSO_LIVRE);
    return redirectCursoLivre && redirectCursoLivre.length > 0;
};

export const limparRedirecionamentosPosLogin = () => {
    if (possuiRedirecionamentoPosLogin()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_CURSO_LIVRE);
    } else if (isViaRealizandoEscolhas()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_RE);
    } else if (isViaRealizandoTrilhaMaker()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_TM);
    } else if (isViaRealizandoAlfabetizacao()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_ALF);
    }else if (isViaViolenciaBully()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_VB);
    }else if (isViaAlfabetizacaoAprofundada()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_ALF);
    }else if (isViaBDS()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_BDS);
    } else if (isViaCompetenciasSocioemocionais()) {
        sessionStorage.removeItem(CHAVE_REDIRECT_JCS);
    }
};

export const limparTodasChaves = () => {
    sessionStorage.removeItem(CHAVE_REDIRECT_CURSO_LIVRE);
    sessionStorage.removeItem(CHAVE_REDIRECT_RE);
    sessionStorage.removeItem(CHAVE_REDIRECT_TM);
    sessionStorage.removeItem(CHAVE_REDIRECT_ALF);
    sessionStorage.removeItem(CHAVE_REDIRECT_VB);
    sessionStorage.removeItem(CHAVE_REDIRECT_AFL_AP);
    sessionStorage.removeItem(CHAVE_REDIRECT_BDS);
    sessionStorage.removeItem(CHAVE_REDIRECT_JCS);
};

export const isViaRealizandoEscolhas = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_RE);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaViolenciaBully = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_VB);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaCompetenciasSocioemocionais = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_JCS);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaAlfabetizacaoAprofundada = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_AFL_AP);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaRealizandoAlfabetizacao = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_ALF);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaBDS = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_BDS);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const isViaRealizandoTrilhaMaker = (): boolean => {
    const redirectRe = sessionStorage.getItem(CHAVE_REDIRECT_TM);

    if (redirectRe) return redirectRe.length > 0;
    return false;
};

export const redirectByUrl = (url: string) => {
    window.open(url, '_blank');
};

export const redirecionarCursoLivrePosLogin = (ref: RefObject<HTMLButtonElement>) => {
    const url: string | null = sessionStorage.getItem(CHAVE_REDIRECT_CURSO_LIVRE);
    const urlTrim = url?.trim();
    ref.current?.addEventListener('click', e => {
        let wi = window.open(urlTrim);
        //if (wi) wi.location.href = urlTrim ?? '';
        e.preventDefault();
    });
    ref.current?.click();

    limparRedirecionamentosPosLogin();
};

export const adicionarRedirecionamentoPosLogin = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
};
