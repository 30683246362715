import { Action } from 'redux';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { NavHashLink } from 'react-router-hash-link';
import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';

export const HomepageMenuStyle = styled.div`
    margin-left: auto;
`;

export const MenuItemsContainer = styled.div`
    display: flex;
    flex: 3;
    justify-content: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    height: 100%;
`;

export const MenuItem = styled.div`
    margin-right: 5px;
    line-height: 18px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;

    &:hover {
        > * {

          &:first-child{
            cursor: pointer;
            color: #003c78;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-decoration: none !important ;
          }
            &.point{
            visibility: visible;
          }
     }
    }
`;

export const MenuItemText = styled.div`
    color: ${Colors.Grey4};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    @media only screen and (max-width: 1250px) {
        color: ${Colors.White};
        letter-spacing: 0.2px;
    }
`;

export const MenuItemTextHover = styled.div`
    color: ${Colors.Grey4};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
`;

export const Point = styled.div`
  position: absolute;
  translate: 0 26px;
  background-color: #003c78;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  visibility: hidden;
`;

export const SearchButtonContainer = styled.div`
    color: #888888;
    margin-left: 15px;
    font-size: 1.5em;

    cursor: pointer;

    :hover {
        color: #003c78;
    }
`;

export const NavHashLinkStyle = styled(NavHashLink)`
    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;
    }
`;

export const Link = styled.a`
    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;
    }
`;

export const MobileMenuButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;

export const MobileMenuIcon = styled(FaBars)`
    color: ${Colors.Grey3};
    cursor: pointer;
`;

interface MobileMenu {
    open: boolean;
}
export const MobileMenu = styled.nav<MobileMenu>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${Colors.DarkBlue};
    transform: ${props => (props.open ? 'translateX(0)' : 'translateX(140%)')};
    height: 100vh;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    width: 262px;
`;

export const MobileMenuCloseButton = styled(IoMdClose)`
    color: ${Colors.White};
    cursor: pointer;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 40px;
    left: 65%;
`;

export const MobileMenuItemsContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
    align-items: flex-start;
`;

export const MobileMenuItem = styled.div`
    margin-bottom: 24px;
`;
