export enum PaginaBrancoGestao {
    PAGINABRANCO = 'PAGINABRANCO',
    BANNER = 'BANNER',
    SIMPLESCOMIMAGEM = 'SIMPLESCOMIMAGEM',
    SIMPLESIMAGEM_I = 'SIMPLESIMAGEM_I',
    INTERESSE = 'INTERESSE',
    SIMPLES = 'SIMPLES',
    CARDCATEGORIA = 'CARDCATEGORIA',
    CARROSSEL = 'CARROSSEL',
    CARROSSELMULTIMEDIA = 'CARROSSELMULTIMEDIA',
    NUMEROSDESTAQUES = 'NUMEROSDESTAQUES',
    DEFAULT = 'DEFAULT',
}
