export const MaskMobile = (value: string) => {
    if (value.length > 13) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})(\d+?)$/, '$1');
    } else {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');
    }
};

export const maskOnlyLetters = (value: string) => {
    return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');
};

export const maskOnlyNumbers = (value: string) => {
    return value.replace(/\D/g, '');
};

export const CpfMask = (value: string) => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const MaskDate = (value: string): string => {
    if (value.length > 10) value = value.substring(0, 10);

    if (value.length < 3) return value.replace(/[^\d]/g, '').replace(/(\d{2})/, '$1/');
    else if (value.length == 4) return value.replace(/[^\d]/g, '').replace(/(\d{2})(\d{1})/, '$1/$2');
    else if (value.length < 10) return value.replace(/[^\d]/g, '').replace(/(\d{2})(\d{2})/, '$1/$2/');
    else return value.replace(/[^\d]/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
};
