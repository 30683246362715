class OnError {
    constructor() {}

    static sendToAnalytics = (where: string, errorMsg: string): boolean => {
        console.error(where, errorMsg);
        //TODO: enviar para servidor de LOG
        return true;
    };
}

export default OnError;
