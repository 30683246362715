import React, { useContext, useEffect, useState } from 'react';
import { Form, ResponsiveEmbed } from 'react-bootstrap';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ObjetivosContainer from './Objetivos';
import FormHeader from '../formHeader/FormHeader';
import ContinuarButton from '../UI/ContinuarButton';
import Loading from '../../../../components/Loading';
import { ProgressConsumer } from '../../ProgressContext';
import ProfileData from '../../../../models/ProfileData';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import * as profileActions from '../../../../store/actions/Profile';
import * as authenticationActions from '../../../../store/actions/Authentication';
import { RegrasConvivenciaStyle, ButtonContainer, CheckboxContainer, LabelChackbox, LinkChackbox } from './RegrasConvivencia.styled';
import Checkbox from 'components/UI/Checkbox';
import ModalTerms from './ModalTerms';
import Termos from './Termos';
import AlertDialog from './AlertDeclineDialog';
import UsuarioDTO from 'models/UsuarioDTO';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import { useKeycloak } from '@react-keycloak/web';
import { isAutoCadastrado } from 'core/util/KeycloakHelp';
import { ProfileService } from 'core/http/service/Profile.service';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import ImagemPerfil from 'models/imagemPerfil/ImagemPerfil';
import TipoImagem from 'models/imagemPerfil/TipoImagemEnum';
import { useFetchEffect } from 'hooks/useFetch';
import { ProfileContext } from 'pages/profile/context/ProfileContext';
import { AppStorage } from 'storage/AppStorage';
import { possuiRedirecionamentoPosLogin, redirecionarCursoLivrePosLogin } from 'core/util/StorageRedirectRulesHelper';

const RegrasConvivencia = () => {
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);

    const statusProgresso = 90;
    const { keycloak, initialized } = useKeycloak();
    const dispatch = useDispatch();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const { postProfilePath } = useContext(ProfileContext.Context);
    const { redirectBehindRegister } = useContext(AccessPermissionContext.Context);

    const onContinuar = async () => {
        setSubmitting(true);
        if (profileData) {
            profileData.email = usuarioLogado.email ?? '';
            profileData.cpf = profileData.cpf.length ? profileData.cpf : usuarioLogado?.username;
            profileData.username = usuarioLogado?.username;
            profileData.keycloakId = usuarioLogado?.id ?? keycloak.subject;
            profileData.autoCadastrado = isAutoCadastrado(usuarioLogado);
            profileData.imagemPerfil = { tipoImagem: TipoImagem.EscolherDepois, codigoAvatar: -1, imageBase64: '' } as ImagemPerfil;

            try {
                await dispatch(profileActions.storeProfileData(profileData));
                await dispatch(profileActions.saveProfile(profileData));
                await dispatch(authenticationActions.getUsuarioLogado());
                await dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));
                keycloak.updateToken(-1).then(refreshed => {
                    //forcing token refresh after saving the profile to update the realm access roles
                    console.log('TOKEN REFRESEHD? ', refreshed);
                });
                setSubmitting(false);
                if (possuiRedirecionamentoPosLogin()) {
                    // redirecionarCursoLivrePosLogin();
                    redirectBehindRegister('/');
                } else if (postProfilePath) {
                    redirectBehindRegister(postProfilePath);
                } else {
                    history.push('/');
                }
            } catch (err) {
                setSubmitting(false);
            }
        }
    };

    const redirectToLogin = () => {
        setShowAlertDialog(false);
    };

    return (
        <ProgressConsumer>
            {value => {
                const { setProgresso } = value;
                setProgresso(statusProgresso);
                return (
                    <>
                        {submitting ? (
                            <Loading />
                        ) : (
                            <>
                                <ModalTerms show={showTermsModal} setShow={setShowTermsModal}>
                                    <Termos
                                        agree={() => {
                                            setShowTermsModal(false);
                                            setAccepted(true);
                                        }}
                                        decline={() => {
                                            setAccepted(false);
                                            setShowTermsModal(false);
                                            setShowAlertDialog(true);
                                        }}
                                    />
                                </ModalTerms>

                                <AlertDialog show={showAlertDialog} onClose={redirectToLogin} />

                                <RegrasConvivenciaStyle>
                                    <FormHeader title="Que bom tê-lo conosco!" subtitle="Nosso objetivo é proporcionar a você um ambiente de:" />
                                    <ObjetivosContainer />

                                    {/*<CheckboxContainer>
                                        <Checkbox
                                            checked={accepted}
                                            onClick={() => {
                                                setAccepted(!accepted);
                                            }}
                                        />
                                        <LabelChackbox>
                                            Declaro que li e concordo:
                                            <LinkChackbox onClick={() => setShowTermsModal(true)}>Termos de Uso</LinkChackbox> e{' '}
                                            <LinkChackbox onClick={() => setShowTermsModal(true)}>Políticas de Privacidade</LinkChackbox>.
                                        </LabelChackbox>
                                    </CheckboxContainer>
                                        */}
                                    <ButtonContainer>
                                        <ContinuarButton isDisabled={submitting} label="Vamos começar!" onClickHandler={onContinuar} />
                                    </ButtonContainer>
                                </RegrasConvivenciaStyle>
                            </>
                        )}
                    </>
                );
            }}
        </ProgressConsumer>
    );
};

export default RegrasConvivencia;
