import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const StyledAvaliations = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
    `,
    DivRowRight: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: white;
        color: black;

        &::placeholder {
            color: black;
            opacity: 0.6;
        }
    `,

    SearchContainer: styled.div`
        width: 50%;
        height: 38px;
        max-width: 540px;
        display: flex;
        border: 2px solid #003c78;

        background: white;
        color: black;
        border-radius: 6px;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
        }

        @media only screen and (min-width: ${MobileMaxWidth + 1}px) and (max-width: 968px) {
            max-width: 300px;
        }
    `,

    Title: styled.p`
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin-bottom: 20px;
    `,

    GridAvaliacao: styled.div<{ isAvarage?: boolean }>`
        display: grid;
        grid-template-columns: ${props => (!props.isAvarage ? '80px 260px 1fr 145px 100px 200px 150px' : '80px 260px 1fr 145px 150px')};
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    GridHead: styled.div`
        background: #e5f6fd;
        color: #003c78;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
    `,

    GridCol: styled.div<{ leftAlign?: boolean }>`
        width: 100%;
        padding: 10px;
        font-size: 14px;
        align-items: center;
        color: #2e2e2e;
        height: 56px;

        border-bottom: 0.5px #dcdcdc solid;

        display: flex;
        align-self: center;
        justify-content: ${props => (props.leftAlign ? 'flex-start' : 'center')};

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            border-bottom: none;
            height: fit-content;
        }
    `,

    ContainerRow: styled.div`
        display: flex;
        align-items: center;
        gap: 1rem;
    `,

    RowRigth: styled.div`
        display: flex;
        align-items: center;

        .label-order {
            font-size: 14px;
            color: #00a8e9;
            font-weight: 600;
        }

        .span-select {
            margin-left: 0px;
            transform: scale(0.85) translateX(-10px);
        }
    `,

    SectionChip: styled.div<{ enabled: boolean }>`
        width: fit-content;
        border-radius: 10px;
        padding: 5px 10px;
        cursor: pointer;
        font-weight: 500;
        margin-left: 10px;
        user-select: none;

        color: ${props => (props.enabled ? '#00a8e9' : '#747474')};
        border: 1px ${props => (props.enabled ? '#00a8e9' : '#747474')} solid;
        background-color: ${props => (props.enabled ? '#E5F6FD' : 'white')};
    `,
};

export default StyledAvaliations;
