import PaginaIntermediariaModeloSecaoDTO from 'models/generic-components/PaginaIntermediariaModeloSecaoDTO';
import SecaoBanner from 'models/generic-components/SecaoBanner';
import SecaoBannerComponent from 'pages/generic-components/banner/Banner';
import SecaoCarrosselComponent from 'pages/generic-components/carousel/CarouselGeneric';
import SecaoCardsComCategoriaComponent from 'pages/generic-components/carouselCategory/CarouselCategory';
import SecaoSimplesComOuSemImagemComponent from 'pages/generic-components/simpleWithImage/SimpleWithImage';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SectionSelectorStyled from './SectionSelector.styled';
import SecaoImagem from 'pages/generic-components/secaoImagem/SecaoImagem';
import SecaoInteresse from 'pages/generic-components/secaoInteresse/SecaoInteresse';
import CarrosselMultimidiaGeneric from 'pages/generic-components/secaoCarrosselMultimidia/CarrosselMultimidiaGeneric';
import SecaoNumeroDestaque from 'pages/generic-components/secaoNumeroDestaque/SecaoNumeroDestaque';

const CODIGO_TIPO_MODELO_SECAO_BANNER = 1;
const CODIGO_TIPO_MODELO_SECAO_SIMPLES_IMAGEM_E = 2;
const CODIGO_TIPO_MODELO_SECAO_SIMPLES_IMAGEM_D = 3;
const CODIGO_TIPO_MODELO_SECAO_SIMPLES_CARTOES_CATEGORIA = 4;
const CODIGO_TIPO_MODELO_SECAO_SIMPLES = 5;
const CODIGO_TIPO_MODELO_SECAO_CARROSSEL = 6;
const CODIGO_TIPO_MODELO_SECAO_INTERESSE = 7;
const CODIGO_TIPO_MODELO_SECAO_IMAGEM = 8;
const CODIGO_TIPO_MODELO_ITEM_CUSTOMIZADO = 9;
const CODIGO_TIPO_MODELO_SECAO_CARROSSEL_MULTIMIDIA = 10;

interface ISectionSelector {
    secao: PaginaIntermediariaModeloSecaoDTO;
}

const SectionSelector: FunctionComponent<ISectionSelector> = ({ ...props }: ISectionSelector) => {
    const [secao, setSecao] = useState<PaginaIntermediariaModeloSecaoDTO>(props.secao);

    const Selector = () => {
        switch (secao.tipoModeloSecao?.codigo) {
            case CODIGO_TIPO_MODELO_SECAO_BANNER:
                return <>{secao.secaoBanner && <SecaoBannerComponent dados={secao.secaoBanner} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_CARROSSEL:
                return (
                    <>
                        {secao.secaoCarrossel && (
                            <SecaoCarrosselComponent
                                dados={secao.secaoCarrossel}
                                length={secao.secaoCarrossel.carrosseis.filter(d => !d.oculto).length ?? 0}
                            />
                        )}
                    </>
                );
            case CODIGO_TIPO_MODELO_SECAO_SIMPLES:
                return <>{secao.secaoSimples && <SecaoSimplesComOuSemImagemComponent dados={secao.secaoSimples} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_SIMPLES_CARTOES_CATEGORIA:
                return <>{secao.secaoCardCategoria && <SecaoCardsComCategoriaComponent dados={secao.secaoCardCategoria} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_SIMPLES_IMAGEM_D:
                return <>{secao.secaoSimplesImagem && <SecaoSimplesComOuSemImagemComponent dados={secao.secaoSimplesImagem} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_SIMPLES_IMAGEM_E:
                return <>{secao.secaoSimplesImagem && <SecaoSimplesComOuSemImagemComponent dados={secao.secaoSimplesImagem} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_IMAGEM:
                return <>{secao.secaoImagem && <SecaoImagem dados={secao.secaoImagem} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_INTERESSE:
                return <>{secao.secaoInteresse && <SecaoInteresse dados={secao.secaoInteresse} />}</>;
            case CODIGO_TIPO_MODELO_ITEM_CUSTOMIZADO:
                return <>{secao.secaoItemCustomizado && <SecaoNumeroDestaque dados={secao.secaoItemCustomizado} />}</>;
            case CODIGO_TIPO_MODELO_SECAO_CARROSSEL_MULTIMIDIA:
                return (
                    <>
                        {secao.secaoCarrosselMultimidia && (
                            <CarrosselMultimidiaGeneric
                                dados={secao.secaoCarrosselMultimidia}
                                length={secao.secaoCarrosselMultimidia.carrosseis.filter(d => !d.oculto).length ?? 0}
                            />
                        )}
                    </>
                );
            default:
                return <>error {secao.codigo}</>;
        }
    };

    useEffect(() => {
        console.log(secao);
    }, [secao]);

    return (
        <SectionSelectorStyled.Container>
            <Selector />
        </SectionSelectorStyled.Container>
    );
};

export default SectionSelector;
