import { ButtonCPC, PrimaryButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 10% 0 0;

    @media only screen and (max-width: 1200px) {
        padding: 50px 0 50px 0;
    }

    @media only screen and (max-width: 760px) {
        padding: 20px 0 50px 0;
    }
`;

const SplitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
    }

    @media only screen and (max-width: 1200px) {
        padding: 0 30px 0 30px;
    }
`;

const Img = styled.img`
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 50px;

    @media only screen and (min-width: 1200px) {
        display: none;
    }

    @media only screen and (max-width: 375px) {
        max-width: 260px;
    }
`;

const ImgLateral = styled.img`
    width: 100%;
    max-width: 480px;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 100px;

    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`;

/* const YellowButton = styled(ButtonCPC)`
    background: #ffca00;
    border-radius: 50px;
    color: #003c78;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`; */

const YellowButton = styled(PrimaryButton)`
    background: #ffca00;
    border-radius: 50px;
    color: #003c78;
    width: 205px;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

const TransparentButton = styled(ButtonCPC)`
    background: transparent;
    border: 1px solid #003c78;
    border-radius: 50px;
    color: #003c78;
`;

export { Container, SplitContainer, Img, ImgLateral, ButtonContainer, YellowButton, TransparentButton };
