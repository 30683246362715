import React, { useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton } from 'components/UI/Buttons';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import AdminSocioemocionalService from 'core/http/service/admin/AdminSocioemocional.service';
import adminSocioSections from 'pages/admin/subpages/socioEdit/adminSocioSections';
import { isStringsValid } from '../newContent/isValidNewContentInput';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import defaultContentSE, { ContentSEType } from 'data/defaultContentSE';
import { ToastError } from 'components/Toaster';

export interface IAdminEditSocioPage {
    section: string;
    selectSection: (value: string) => void;
    onSaved: () => void;
}

const options = adminSocioSections.map(s => ({ value: s, label: s }));

const isMobile = () => IsMobileMaxWidth();

const AdminEditSocioPage = ({ onSaved, section, selectSection }: IAdminEditSocioPage) => {
    const service = new AdminSocioemocionalService();
    const [cover, setCover] = useState<File>();
    const [preCover, setPreCover] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [thisId, setThisId] = useState<number>(0);
    const MAX_CHAR = 540;
    const MAX_CHAR_TITULO = 60;

    const normalizeSection = (sec: string): string => {
        if (sec == adminSocioSections[0]) return 'SAIBAMAIS';
        if (sec == adminSocioSections[1]) return 'AUTOAVALIACAO';
        if (sec == adminSocioSections[2]) return 'DESENVOLVIMENTO';
        if (sec == adminSocioSections[3]) return 'DIARIO';
        else return 'JORNADA';
    };

    const isValidInputs = (): boolean => {
        if (!cover && !preCover && normalizeSection(section) != 'SAIBAMAIS') return false;
        return isStringsValid([title, text]);
    };

    const submit = async () => {
        if (text.length > MAX_CHAR) {
            ToastError('Texto muito longo!');
            return false;
        }

        if (title.length > MAX_CHAR_TITULO) {
            ToastError('Título muito longo!');
            return false;
        }

        if (!cover?.name.endsWith('.jpg') && !cover?.name.endsWith('.png') && !preCover && normalizeSection(section) != 'SAIBAMAIS') {
            ToastError('Formato de arquivo inválido');
            return false;
        }

        setSubmitting(true);

        if (thisId <= 0) {
            const { data, status } = await service.salvarSecao(title, text, normalizeSection(section));
            if (status == 200 && cover) {
                const formdata: FormData = new FormData();
                formdata.append('file', cover);
                await service.salvarAnexoSecao(formdata, data.id, '');
                setSubmitting(false);
                onSaved();
                return;
            }
        } else {
            const { data, status } = await service.atualizarSecao(thisId, title, text, normalizeSection(section));
            if (status == 200 && cover) {
                const formdata: FormData = new FormData();
                formdata.append('file', cover);
                await service.salvarAnexoSecao(formdata, data.id, '');
                setSubmitting(false);
                onSaved();
                return;
            }
        }

        setSubmitting(false);
        onSaved();
    };

    const fetchItem = async () => {
        const { data } = await service.buscarSecao(normalizeSection(section));

        const result = new AdminHomeConteudo().fromJSON(data[0]);

        if (result.id != -1) {
            setTitle(result.title);
            setText(result.content);
            setThisId(result.id);
            fetchImage(result.id);
        } else {
            let dft: ContentSEType | null = null;

            const normalizedSection = normalizeSection(section);

            if (normalizedSection == 'SAIBAMAIS') dft = defaultContentSE.SAIBAMAIS;
            else if (normalizedSection == 'DESENVOLVIMENTO') dft = defaultContentSE.DESENVOLVIMENTO;
            else if (normalizedSection == 'DIARIO') dft = defaultContentSE.DIARIO;
            else if (normalizedSection == 'AUTOAVALIACAO') dft = defaultContentSE.AUTOAVALIACAO;
            else dft = defaultContentSE.JORNADA;

            setTitle(dft.title);
            setText(dft.text);
            setPreCover(dft.image);
        }
    };

    const fetchImage = async (sectionId: number) => {
        const { data, status } = await service.buscarAnexoSecao(sectionId);
        if (status == 200 && data.base64Content) setPreCover('data:image/*;base64,' + data.base64Content);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    useEffect(() => {
        fetchItem();
    }, [section]);

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Styled.TopBar>
                <Styled.TextTop>Qual seção do Painel Socioemocional você deseja alterar?</Styled.TextTop>
                <Styled.Select
                    isSmall={true}
                    itens={options}
                    onSelected={option => selectSection(String(option.value))}
                    defaultValue={options.find(o => o.value == section)}
                />
            </Styled.TopBar>

            <Styled.ContainerWhite>
                <div>
                    <Styled.Label>Título</Styled.Label>
                    <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} maxLength={MAX_CHAR_TITULO} />
                    <Styled.CountLetter danger={title.length >= MAX_CHAR_TITULO}>
                        {title.length}/{MAX_CHAR_TITULO}
                    </Styled.CountLetter>
                    <br />
                    <Styled.Label>Texto</Styled.Label>
                    <Styled.Input
                        value={text}
                        onChange={(evn: any) => setText(evn.target.value)}
                        rows={8}
                        as="textarea"
                        className={'form-control'}
                        maxLength={MAX_CHAR}
                    />
                    <Styled.CountLetter danger={text.length >= MAX_CHAR}>
                        {text.length}/{MAX_CHAR}
                    </Styled.CountLetter>

                    <br />

                    {isMobile() && (
                        <GradientButton onClick={submit} disabled={!isValidInputs() || submitting}>
                            {submitting ? 'Salvando' : 'Salvar'}
                        </GradientButton>
                    )}
                </div>

                {/* A secao de Saiba Mais nao possui um seletor de imagem */}
                <Styled.RigthArea style={{ justifyContent: section != 'Saiba Mais' ? 'space-between' : 'flex-end' }}>
                    {/* {section != 'Saiba Mais' && (
                        <AdminImageUploader
                            onSelect={(_, file: File) => setCover(file)}
                            size={'sm'}
                            title={'Imagem do banner'}
                            preImageB64={preCover}
                            recommendedDimension={{ w: 245, h: 290 }}
                        />
                    )} */}

                    <br />
                    {!isMobile() && (
                        <GradientButton onClick={submit} disabled={!isValidInputs() || submitting}>
                            {submitting ? 'Salvando' : 'Salvar'}
                        </GradientButton>
                    )}
                </Styled.RigthArea>
            </Styled.ContainerWhite>
        </div>
    );
};

export default AdminEditSocioPage;
