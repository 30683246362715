import React, { useEffect, useState } from 'react';
import Styled from './DepoimentsHome.styled';
import { RiMessage2Line } from 'react-icons/ri';
import Colors from 'enums/Colors';
import Slider from 'react-slick';
import { Avatar, ConfigProvider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IsCustomMaxWidth, IsCustomMinWidth } from 'core/util/WindowSize';
import { bucketS3, caminhoAnexosHomepage } from 'constants/EnvironmentData';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import { cloneDeep } from 'lodash';

const service = new AdminHomeConteudoService();

const DepoimentsHome = () => {
    const isMin1050x = IsCustomMinWidth(1050);
    const isMax1050x = IsCustomMaxWidth(1050);
    const [loading, setLoading] = useState<boolean>(true);
    const [depoiments, setDepoiments] = useState<AdminHomeConteudo[]>([]);

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosHomepage();
    };

    const fetchItens = async () => {
        const { data } = await service.buscaConteudo('DEPOIMENTOS');

        const result = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));

        setDepoiments(result);
        fetchImg(result);
    };

    const fetchImg = async (_solutions: AdminHomeConteudo[]) => {
        const clone = cloneDeep(_solutions);

        for (let i = 0; i < clone.length; i++) {
            try {
                const { data } = await service.buscarAnexoConteudo(clone[i].id, false);
                clone[i].precover = getS3BaseUrl() + data.filePath;
            } catch (e: any) {
                console.error(e);
            }
        }

        setDepoiments(clone);
        setLoading(false);
    };

    useEffect(() => {
        fetchItens();
    }, []);

    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                <Styled.SvgIcon>
                    <path
                        d="M0.472656 26.5214L11.1593 15.0974L0.472656 3.6734L3.76266 0.164062L17.7627 15.0974L3.76266 30.0307L0.472656 26.5214Z"
                        fill={Colors.Yellow}
                        stroke={Colors.Yellow}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                <Styled.SvgIcon>
                    <path
                        d="M17.9931 26.5214L7.30646 15.0974L17.9931 3.6734L14.7031 0.164062L0.703125 15.0974L14.7031 30.0307L17.9931 26.5214Z"
                        fill={Colors.Yellow}
                        stroke={Colors.Yellow}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <Styled.Container>
            {isMin1050x && (
                <Styled.Info>
                    <RiMessage2Line size={60} color={Colors.Blue} />
                    <Styled.InfoText>O que os educadores falam sobre nós!</Styled.InfoText>
                </Styled.Info>
            )}
            <Styled.Carrossel>
                <Styled.CarouselCardContainer>
                    <Slider {...settings}>
                    {depoiments.map((dados, index) => {
                            return (
                                <div key={index}>
                                    <Styled.ContentDepoiments>
                                        <ConfigProvider>
                                            <Styled.ContentAvatar>
                                                <Avatar
                                                    size={isMax1050x ? 100 : 120}
                                                    icon={<UserOutlined />}
                                                    src={dados.precover}
                                                    style={{
                                                        objectFit: 'none', // Permite que a imagem seja exibida em seu tamanho original
                                                        transform: 'scale(1.2)',
                                                    }}
                                                />
                                            </Styled.ContentAvatar>
                                        </ConfigProvider>
                                        <Styled.BodyDepoiments>
                                            <Styled.Name>{dados.title}</Styled.Name>
                                            <Styled.Profissional>{dados.occupation}</Styled.Profissional>
                                            <Styled.TextDepoiment
                                                dangerouslySetInnerHTML={{
                                                    __html: `${dados.content}`
                                                }}
                                            />
                                        </Styled.BodyDepoiments>
                                    </Styled.ContentDepoiments>
                                </div>
                            );
                        })}
                    </Slider>
                </Styled.CarouselCardContainer>
            </Styled.Carrossel>
            
        </Styled.Container>
    );
};

export default DepoimentsHome;
