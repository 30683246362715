import styled from 'styled-components';
import React from 'react';
import Colors from 'enums/Colors';

const StyleSupport = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 328px;
        gap: 16px;
        background-color: ${Colors.White};
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        color: ${Colors.Blue};
    `,

    Description: styled.h2`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    `,
};

export default StyleSupport;
