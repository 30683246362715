import { createContext } from "react";





export namespace EbookContext {

    export interface IContext {

    }

    export const Context = createContext({} as IContext);



}