import { jsonProperty, Serializable } from 'ts-serializable';
import { EtapaItemRecursosCostumizado } from './EtapaItemRecursosCostumizado';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import CategoriaPE from 'models/acessoExclusivo/CategoriaPE';

export class ConteudoOrderItem extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(Number)
    codigoPaginaIntermediaria: number | null = -1;

    @jsonProperty(Number)
    ordem: number | null = -1;
}
export class ItemRecursosCostumizado extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(Number, null)
    codigoPaginaIntermediaria?: number | null = -1;

    @jsonProperty(String, null)
    nome: string = '';

    @jsonProperty(String, null)
    descricao: string = '';

    @jsonProperty(String, null)
    descricaoExtra: string = '';

    @jsonProperty([EtapaItemRecursosCostumizado])
    etapas: EtapaItemRecursosCostumizado[] = [];

    @jsonProperty(Boolean, null)
    linkExterno: boolean = false;

    @jsonProperty(Boolean, null)
    conteudosSequenciais: boolean = false;

    @jsonProperty(Boolean, null)
    etapasSequenciais: boolean = false;

    @jsonProperty(String, null)
    url: string = '';

    @jsonProperty(String, null)
    usuarioResponsavel?: string = '';

    @jsonProperty(String, null)
    ultimaAtualizacao?: string = '';

    @jsonProperty(String, null)
    anexoBase64: string = '';

    @jsonProperty(String, null)
    filePath?: string = '';

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;

    @jsonProperty(Number, null)
    percentualConclusao: number | null = -1;

    @jsonProperty(Number, null)
    codigoCertificacao: number | null = -1;

    @jsonProperty([CategoriaPE])
    codigosCategoriaPaginaExclusiva: CategoriaPE[] = [];

    @jsonProperty(Boolean, null)
    isConcluido: boolean = false;

    @jsonProperty(Boolean, null)
    possuiAcesso: boolean = false;

    @jsonProperty(Boolean, null)
    oculto: boolean = false;

    @jsonProperty(Boolean, null)
    isIniciado: boolean = false;

    @jsonProperty(Boolean, null)
    temConteudo: boolean = false;

    @jsonProperty(Number, null)
    ordem: number | null = -1;
}
