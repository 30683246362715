import React, { useContext } from 'react';
import Styled from './DynamicHeader.styled';
import { GTM_Menu } from 'components/GoogleTagManager';
import { CheckedGoToContext } from 'pages/painelSocioemocional/home/context/CheckedGoToContext';
import LogoHumane from '../../images/logo/logo-humane.svg';

const Logo = () => {
    const { isVerifyGoToPage } = useContext(CheckedGoToContext.Context);

    return (
        <Styled.LogoContainer>
            <Styled.Img
                onClick={() => {
                    GTM_Menu('header_menu', 'btn_click_logo');
                    isVerifyGoToPage('/home');
                }}
                src={LogoHumane}
            />
        </Styled.LogoContainer>
    );
};

export default Logo;
