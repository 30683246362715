import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import Loading from 'components/Loading';
import { ToastError, ToasterDefault } from 'components/Toaster';
import { ProfileService } from 'core/http/service/Profile.service';
import FullDashboardDTO from 'models/perfil/FullDashboardDTO';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import Menu from 'pages/menu/Menu';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IntermediatePage from '../components/IntermediatePage';
import Graph from './assets/graph.svg';
import BibliotecaPainel from './components/biblioteca/BibliotecaPainel';
import CursosLivresPainel from './components/cursos/CursosLivresPainel';
import JornadaPainel from './components/jornada/JornadaPainel';
import { PainelContainer, MainContainer, GobackButton, AuxContainer } from './Painel.styled';
import TrilhasAprendizagem from './components/trilhasAprendizagem/TrilhasAprendizagem';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import useUserData from 'hooks/useUserData';
import { idCursoRealizandoEscolhasGestor } from 'constants/EnvironmentData';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';

export interface IPainel {}

const Painel = ({}: IPainel) => {
    const history = useHistory();
    const [dashboard, setDashboard] = useState<FullDashboardDTO>();
    const [loading, setLoading] = useState<boolean>(false);
    const { isSearchView } = useContext(PesquisaContext.Context);
    const { loadData } = useUserData();

    const fetchDashboard = async () => {
        try {
            setLoading(true);

            const { perfilUsuario } = await loadData();

            const isGestor = buscarOcupacaoPorPerfil(perfilUsuario) === 'B';

            const service = new ProfileService();
            let { data } = await service.getFullDashboard(
                perfilUsuario?.username ?? '',
                isGestor,
                isGestor ? parseInt(idCursoRealizandoEscolhasGestor()) : -1
            );
            const dados = new FullDashboardDTO().fromJSON(data);

            setDashboard(dados);
        } catch (error) {
            ToastError('error' + error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDashboard();
    }, []);

    return (
        <>
            {/*  <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <IntermediatePage
                helmetTitle={'Meu Painel'}
                helmetMetaDescription={''}
                title1={''}
                title2={'Meu Painel'}
                icon={Graph}
                breadCrumbName={'Meu Painel'}
            >
                <PainelContainer>
                    <MainContainer>
                        <>
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    {dashboard && (
                                        <>
                                            <JornadaPainel jornadas={dashboard.jornadas} />
                                            <AuxContainer>
                                                <CursosLivresPainel cursos={dashboard.cursos} />
                                                <TrilhasAprendizagem trilhas={dashboard.trilhas} />
                                            </AuxContainer>
                                            <AuxContainer>
                                                <BibliotecaPainel biblioteca={dashboard.biblioteca} />
                                            </AuxContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </MainContainer>
                    <GobackButton
                        onClick={() => {
                            GTM_Page_Intermediaria('painel_intermediaria', 'voltar_pagina_btn');
                            history.push('/');
                        }}
                    >
                        Voltar página inicial
                    </GobackButton>
                </PainelContainer>
            </IntermediatePage>

            {/* {isSearchView && <BarraPesquisa />} */}
        </>
    );
};

export default Painel;
