import { createContext } from "react";




export namespace AcessoExclusivoContext {

    export interface IContext {
        loading: boolean
    }



    export const Context = createContext({} as IContext);
}