import React from 'react';
import { StepStatus, StepType } from './Steps';
import { ContainerMobile, IndexContainerMobile, IndexLabel, IndexWrapper, TitleStepMobile } from './Step.styled';

export interface IStep {
    step: StepType;
    oncliked: () => void;
    isLast: boolean;
}

const Step = ({ step, isLast, oncliked }: IStep) => {
    return (
        <ContainerMobile inatived={step.status == StepStatus.Waiting} onClick={oncliked}>
            <IndexContainerMobile>
                <IndexWrapper>
                    <IndexLabel>{step.index}</IndexLabel>
                </IndexWrapper>
            </IndexContainerMobile>
            <TitleStepMobile inatived={step.status == StepStatus.Waiting} visible={step.status == StepStatus.Current}>
                {step.title}
            </TitleStepMobile>
        </ContainerMobile>
    );
};

export default Step;
