import React, { useState, useEffect } from 'react';

import { ProgressBarStyle, Progress } from './ProgressBar.styled';

interface IProgressBar {
    percentualConcluido: number;
}
const ProgressBar = ({ percentualConcluido }: IProgressBar) => {
    const [percentual, setPercentual] = useState<number>(0);
    const [percentualAnterior, setPercentualAnterior] = useState<number>(0);

    useEffect(() => {
        setPercentualAnterior(percentual);
        setPercentual(percentualConcluido);
    }, [percentualConcluido]);

    return (
        <ProgressBarStyle>
            <Progress progress={percentual} prevPercentyal={percentualAnterior} />
        </ProgressBarStyle>
    );
};

export default ProgressBar;
