import styled from 'styled-components';

import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';

interface IPickerListStyle {
    isSelected: boolean;
    small?: boolean;
}
export const PickerListCardContainerStyle = styled.div<IPickerListStyle>`
    color: ${props => (props.isSelected ? Colors.White : Colors.BlueGrey)};
    background-color: ${props => (props.isSelected ? Colors.SoftBlue : Colors.White)};
    border-color: ${props => (props.isSelected ? Colors.SoftBlue : Colors.BlueGrey)};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid;
    padding: 10px 20px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-right: 11px;
    margin-bottom: 16px;
    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: ${props => (props.small ? '11px' : '14px')};
        height: 38px;
        line-height: 13px;
    }
`;
