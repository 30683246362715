import React, { CSSProperties, FunctionComponent } from 'react';

import styled from 'styled-components';
import { MdInfo, MdClose } from 'react-icons/md';

import Colors from '../../enums/Colors';
import { IsMobileMaxWidth } from '../../core/util/WindowSize';
import { ModalStyled } from '../../pages/pdp/UI/dialog/DialogFinal.styled';

interface IPopupExcludedContainer {}
const PopupExcludedContainer = styled.div<IPopupExcludedContainer>(({ ...props }: IPopupExcludedContainer) => ({
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',

    overflow: 'hidden',
    borderRadius: '10px',
    boxSizing: 'border-box',
    background: Colors.White,
    border: '1px solid #FFDCD8',
    height: IsMobileMaxWidth() ? '40px' : '60px',
    width: IsMobileMaxWidth() ? '320px' : '485px',
    boxShadow: '1px 1px 10px 6px rgba(0, 0, 0, 0.1)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const TextContainer = styled.div({
    display: 'flex',
    alignItems: 'center',

    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontFamily: 'Barlow',
    color: Colors.DarkGrey,
    letterSpacing: '0.3px',
});

const icon: CSSProperties = {
    fontSize: 30,
    marginLeft: 15,
    marginRight: 15,
    color: Colors.DeleteRed,
};

const close: CSSProperties = {
    ...icon,
    color: Colors.Grey3,
    cursor: 'pointer',
};

interface IPopupExcluded {
    onClose: () => void;
    visible: boolean;
}

const PopupExcluded: FunctionComponent<IPopupExcluded> = ({ onClose, visible }: React.PropsWithChildren<IPopupExcluded>) => {
    return (
        <ModalStyled onHide={onClose} backdrop={true} show={visible} keyboard={false} dialogAs={PopupExcludedContainer}>
            <TextContainer>
                <MdInfo style={icon} />
                <p>O seu registro foi excluído!</p>
            </TextContainer>
            <MdClose style={close} onClick={onClose} />
        </ModalStyled>
    );
};

export default PopupExcluded;
