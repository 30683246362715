import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useContext, useEffect } from 'react';
import Styled from './DialogosSocioemocionais.styled';
import OverviewExclusivo from '../components/OverviewExclusivo';
import logoOverview from '../assets/dialogos/LogoOverview.svg';
import ArquivoDownload from '../assets/dialogos/Dialogos_Socioemocionais_Visao_Geral.pdf';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import Logo from '../assets/dialogos/logo_01.png';
import { ListMateriaisDialogosSocioemocionais } from './ListMateriaisDialogosSocioemocionais';
import CicloExclusivo from '../components/CicloExclusivo';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import PartnersExclusivo from '../components/PartnersExclusivo';
import ImagemTrilha from '../assets/dialogos/imagem_01.jpg';
import Banner01 from '../assets/dialogos/banner_01.jpg';
import Banner02 from '../assets/dialogos/banner_02.jpg';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';

const DialogosSocioemocionaisPrivate = () => {
    const { loading } = useContext(AcessoExclusivoContext.Context);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Container>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        img={Banner01}
                        title="Diálogos Sócioemocionais"
                        description={`
                    O Diálogos Socioemocionais, desenvolvido pelo Instituto Ayrton Senna, 
                    é uma proposta educacional que apoia educadores e gestores na promoção intencional e estruturada do desenvolvimento de competências socioemocionais dos estudantes. 
                    A iniciativa enfatiza práticas pedagógicas baseadas em evidências e coloca o professor como mediador ativo, utilizando práticas SAFE (sequenciais, ativas, focadas e explícitas), 
                    metodologias ativas, avaliação formativa e mediação dialógica.
                   <br/>
                   <br/>
                    Para os gestores escolares e Secretarias de Educação, o programa oferece uma Sistemática de Monitoramento e Acompanhamento, 
                    que utiliza avaliação formativa e análise de dados para intervenções contínuas. Assim, o Diálogos Socioemocionais 
                    busca consolidar práticas de gestão e docência integradas para implementar as diretrizes da BNCC, 
                    promovendo uma educação integral e o desenvolvimento socioemocional dos estudantes.
                `}
                        arquivo={ArquivoDownload}
                        nome_arquivo="Dialogos_Socioemocionais_Visao_Geral"
                    />
                    <CicloExclusivo
                        title="TRILHA FORMATIVA"
                        description={`
                    A trilha formativa do Diálogos Socioemocionais apresenta os conteúdos da solução educacional de modo que os profissionais 
                    (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                    Inicie agora a sua trilha formativa!
                    `}
                        img={Banner02}
                        itens={[
                            {
                                title: 'Trilha Formativa Duplo Foco',
                                url: '/recursos/dialogos-socioemocionais/dialogos-socioemocionais-duplo-foco',
                            },
                        ]}
                    />

                    <MateriaisExclusivos
                        title="ACESSE OS MATERIAIS DO DIÁLOGOS SOCIOEMOCIONAIS"
                        description={`  Tenha acesso aos materiais exclusivos do Diálogos Socioemocionais, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={Logo}
                        items={ListMateriaisDialogosSocioemocionais}
                    />
                    <PartnersExclusivo
                        title="JORNADA SOCIOEMOCIONAL DE PROFESSORES"
                        description={`
                            Você sabia que o Instituto Ayrton Senna também disponibiliza na humane uma experiência de desenvolvimento focada no Socioemocional de Professores e que você pode acessa-la?
                            <br /><br />
                  Mergulhe nessa jornada usando nossas ferramentas de Autoavaliação e Diagnóstico das habilidades específicas ao fazer docente e tenha acesso a uma Trilha de Aprendizagem com 4 cursos sobre Competências Socioemocionais de Professores. Você também terá à sua disposição uma ferramenta para construção de um Plano de Desenvolvimento Pessoal.
Aproveite mais essa oportunidade!
                    `}
                        img={ImagemTrilha}
                        button={{
                            label: 'Acesse a jornada',
                            url: '/painelSocioemocional',
                        }}
                    />
                    <ContactContainer />
                </>
            )}
        </Styled.Container>
    );
};

export default DialogosSocioemocionaisPrivate;
