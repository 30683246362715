import { GenericService } from '../Generic.service';

export default class BiblioColecaoService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public novaColecao(descricao: string, item_id: number) {
        return this.post('bookmark', { titulo: descricao, item_id });
    }

    public deletarColecao(id: number) {
        return this.deleteByParams('colecao/' + id);
    }

    public adicionarItem(item_id: number, colecao_id?: number, titulo?: number) {
        return this.post('bookmark', { item_id, colecao_id, titulo });
    }

    public removerItem(item_id: number) {
        return this.deleteByBody('bookmark', { item_id });
    }

    public atualizar(item_id: number, descricao: string) {
        return this.put(`colecao/${item_id}`, { descricao });
    }

    public buscarColecoes() {
        return this.get(`colecaoBiblioteca`);
    }

    public buscarColecao(colecao_id: number) {
        return this.get(`colecao/${colecao_id}/biblioteca`);
    }
}
