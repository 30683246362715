import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';

export const Container = styled.div(
    new FullCss(
        {
            maxWidth: '1253px',
            padding: '4.5em 10px 50px 10px',
            margin: '0 auto',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            paddingTop: '40px',
        })
    ).getCss()
);

export const Text = styled.div(
    new FullCss({
        fontWeight: '700',
        fontSize: '12px',
        letterSpacing: '0.3px',
        lineHeight: '14px',
    }).getCss()
);

export const Img = styled.img(
    new FullCss({
        width: '10px',
        margin: '5px',
        cursor: 'pointer',
    }).getCss()
);

export const BackButton = styled(PrimaryButton)(
    new FullCss(
        {
            display: 'flex',
            border: '2px solid #fcfcfc',
            borderRadius: '50px',
            width: '275px',
            height: '46px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(400), {
            width: '100%',
        })
    ).getCss()
);
