import React, { useCallback, useState, useEffect } from 'react';
import OnError from 'core/onError/OnError';
import { RoleEnum } from 'enums/RoleEnum';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import { AppStorage } from 'storage/AppStorage';
import { Container, Iframe, BannerInfoStyle, styleButton } from './Autoavaliacao.styled';
import { loginWithRole, getUserRole } from './utils/externalRequests';
import { instrumentoIntegrationUrl } from 'constants/EnvironmentData';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import closeIcon from './../../images/close-white.svg';

import Title from 'components/UI/Title';
import Colors from 'enums/Colors';
import { PrimaryButton } from 'components/UI/Buttons';
import { useKeycloak } from '@react-keycloak/web';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import BaloonAlert from './BaloonAlert';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ToastError } from 'components/Toaster';
import DialogSlider from 'pages/painelSocioemocional/home/components/DialogSlider/DialogSlider';
import { ModalSEEnum } from 'enums/ModalSEEnum';

export interface IAutoavaliacao {}

const IsMobile = () => {
    return IsCustomMaxWidth(RESIZE_MEDIUM_WIDTH);
};

const Autoavalicao = ({}: IAutoavaliacao) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [_showInfoDialog, _setShowInfoDialog] = useState<boolean>(true);
    const [postSentToggle, setPostSentToggle] = useState<boolean>(false);
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const { keycloak } = useKeycloak();
    const setShowInfoDialog = () => {
        _setShowInfoDialog(false);
    };

    const hideAllDialogs = () => {};

    const loadData = async () => {
        try {
            setIsLoading(true);
            //RECUERAR TOKEN SSO

            const accessToken: string | undefined = await AppStorage.getAccessToken();
            const response: any = await new KeycloakService().getUsuarioLogado();
            const output: UsuarioDTO = new UsuarioDTO().fromJSON(response.data);
            keycloak.tokenParsed;
            let role: RoleEnum = getUserRole(output.roles);

            if (role == RoleEnum.Unknown && userProfile) {
                role = getUserRole([userProfile.role]);
            }

            if (role != RoleEnum.Unknown) {
                const postReq = {
                    token: accessToken,
                };

                const iframe = document.getElementById('ifr') as HTMLIFrameElement;

                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage(postReq, '*');
                    setPostSentToggle(true);
                }
            } else {
                console.error('Erro ao logar - Usuario:', output);
                ToastError('Erro ao logar');
            }
        } catch (error) {
            OnError.sendToAnalytics('loadData.ts', String(error));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
    }, []);

    return (
        <Container>
            <DialogSlider data={ModalSEEnum.AUTOAVALIACAO} show={_showInfoDialog && true} onClose={() => setShowInfoDialog()} />

            {IsMobile() && <BaloonAlert />}

            <Iframe visibile={postSentToggle} className="iframe" src={`${instrumentoIntegrationUrl()}/instrumentoContent`} id="ifr"></Iframe>
        </Container>
    );
};

export default Autoavalicao;
