import styled from "styled-components"



const StyledDialogosSocioemocionais = {

    Container : styled.div`
       display: flex;
        flex-direction: column;
        margin-top: 85px;
    `

}

export default StyledDialogosSocioemocionais