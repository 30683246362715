import { MAX_IMAGE_SIZE_IN_BYTES } from 'constants/Dimensions';
import React, { useState, useEffect, useRef } from 'react';
import { ToastError } from 'components/Toaster';
import { Container, DescText, Flexbox, Hidden, ImageContainer, PreviewImage, Wrapper } from './ImageProfile.styled';
import { OutlineButton, YellowButton } from 'components/UI/Buttons';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import TipoImagem from 'models/imagemPerfil/TipoImagemEnum';
import AvatarDefault from 'pages/painelSocioemocional/home/components/HeadInfo/AvatarDefault';
import { ProfileImage } from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo.styled';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import { ButtonLink } from 'pages/home/components/homeV2/solucoes/CarouselSolucoes';
import { ButtonLinkHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import { Button } from 'antd';
import Colors from 'enums/Colors';

export interface IImageProfile {
    profile?: ProfileWithAvatar;
    username?: string;
    onSelect: (base64: string, file: File) => void;
    preImageB64?: string | null;
}

const ImageProfile = ({ profile, username, onSelect, preImageB64 }: IImageProfile) => {
    const [encodedImage, setEncodedImage] = useState<string | null>(null);
    const imageElement = useRef<HTMLImageElement>(null);
    const inputElement = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (preImageB64) setEncodedImage(preImageB64);
    }, [preImageB64]);

    const chooseFile = () => {
        if (inputElement?.current) {
            inputElement.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.files && target.files[0]) {
                    if (target.files[0].size > MAX_IMAGE_SIZE_IN_BYTES) {
                        ToastError('O arquivo de imagem excede o tamanho máximo de 1MB');
                        return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = (ev: any) => {
                        const base64 = ev.target.result;
                        setEncodedImage(base64);
                        if (target.files) onSelect(base64, target.files[0]);
                    };
                    reader.readAsDataURL(target.files[0]);
                }
            };

            inputElement.current.click();
        }
    };

    return (
        <>
            <Wrapper>
                <Container>
                    {encodedImage == null ? (
                        <>
                            {!profile || profile?.tipoIdentificacao == TipoImagem.EscolherDepois ? (
                                <AvatarDefault name={username ?? ''} />
                            ) : (
                                <ProfileImage src={profile?.srcImage} />
                            )}
                        </>
                    ) : (
                        <ImageContainer>
                            <PreviewImage ref={imageElement} src={encodedImage} />
                        </ImageContainer>
                    )}

                    <Flexbox>
                        <Button type='link' style={{color: Colors.SoftBlue}} onClick={chooseFile} >
                            Escolher minha foto
                        </Button>
                        <DescText>
                            &#128712; Tamanho máximo: <b>1 MB</b>.
                        </DescText>
                    </Flexbox>
                    <Hidden ref={inputElement} type="file" id="file-input" accept=".png, .jpg, .jpeg" />
                </Container>
            </Wrapper>
        </>
    );
};

export default ImageProfile;
