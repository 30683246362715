import { KeycloakService } from 'core/http/service/Keycloak.service';
import { ProfileService } from 'core/http/service/Profile.service';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import UserData from 'models/UserData';
import UsuarioDTO from 'models/UsuarioDTO';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authenticationActions from '../store/actions/Authentication';
import * as profileActions from '../store/actions/Profile';
import { useAppSelector } from './LocalReduxThunk';

// Hook
const useUserData = () => {
    const dispatch = useDispatch();
    const userReducer: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const perfilReducer: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const loadData = async () => {
        const service = new KeycloakService();
        let usuario: UsuarioDTO | null = userReducer;
        let perfil: PerfilUsuario | null = perfilReducer;

        if (!userReducer || (userReducer && userReducer.id === '')) {
            const response: any = await service.getUsuarioLogado();
            if (response.data) {
                usuario = new UsuarioDTO().fromJSON(response.data);
                await dispatch(authenticationActions.setUsuarioLogado(usuario));
            }
        }

        if (!perfilReducer || (perfilReducer && perfilReducer.codigo === -1)) {
            //inicializa o profile
            const profileService = new ProfileService();
            const responseProfile: any = await profileService.findByUsername(usuario?.username ?? '');
            if (responseProfile.data) {
                perfil = new PerfilUsuario().fromJSON(responseProfile.data);
                await dispatch(profileActions.setUserProfile(perfil));
            }
        }

        const retorno: UserData = { usuarioLogado: usuario, perfilUsuario: perfil };
        return retorno;
    };

    return { loadData };
};

export default useUserData;
