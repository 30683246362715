import React, { useCallback, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import AdminHomeConteudoService, { secaoConteudoEnum } from 'core/http/service/admin/AdminHomeConteudo.service';
import { cloneDeep, isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import { isStringsValid, isValidLink } from '../newContent/isValidNewContentInput';
import Select, { SelectOptionType } from 'components/Select';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import SolucoesEducacionais from '../../../../data/SolucoesEducacionais.json';
import submitAttach from './submitAttach';
import urlToImageFile from 'pages/admin/utils/urlToImageFile';

const service = new AdminHomeConteudoService();

export interface IAdminEditTematica {
    onSaved: () => void;
}

const AdminEditTematica = ({ onSaved }: IAdminEditTematica) => {
    const [tematicas, setTematicas] = useState<AdminHomeConteudo[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [submittingOrder, setSubmittingOrder] = useState(false);
    const [loading, setLoading] = useState(false);

    const isValidInputs = (idx: number): boolean => {
        const tem = tematicas[idx];

        if (!tem.active) return true;

        if (!(tem.link && isValidLink(tem.link, tem.linkExtern))) return false;

        return isStringsValid([tem.title, tem.content]);
    };

    const addTematica = () => {
        let _tematicas = cloneDeep(tematicas);
        if (!isEmpty(tematicas)) {
            _tematicas = _tematicas.sort((s1, s2) => s1.ordem - s2.ordem);

            const lastTema = _tematicas[_tematicas.length - 1];

            const tem = new AdminHomeConteudo().fromJSON({
                section: 'TEMATICA',
                title: '',
                content: '',
                active: false,
                id: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
                ordem: lastTema.ordem + 1,
            });

            _tematicas.push(tem);
            setTematicas(_tematicas);
        } else {
            const tem = new AdminHomeConteudo().fromJSON({
                section: 'TEMATICA',
                title: '',
                content: '',
                active: false,
                id: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
                ordem: 1,
            });

            _tematicas.push(tem);
            setTematicas(_tematicas);
        }
    };

    const setTitle = (id: number, value: string) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.title = value;
        }

        setTematicas(clone);
    };

    const setText = (id: number, value: string) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.content = value;
        }

        setTematicas(clone);
    };

    const setLinkExtern = (id: number) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.linkExtern = !tem.linkExtern;
        }

        setTematicas(clone);
    };

    const setActive = (id: number) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.active = !tem.active;
        }

        setTematicas(clone);
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(tematicas);
        clone[idx].link = value;
        setTematicas(clone);
    };

    const setProximoLancamento = (id: number) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.proximoLancamento = !tem.proximoLancamento;
        }

        setTematicas(clone);
    };

    const setNovidade = (id: number) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.novidade = !tem.novidade;
        }

        setTematicas(clone);
    };

    const setOcultar = (id: number) => {
        const clone = cloneDeep(tematicas);

        for (let i = 0; i < clone.length; i++) {
            const tem = clone[i];
            if (tem.id == id) tem.disabled = !tem.disabled;
        }

        setTematicas(clone);
    };

    const setCover = (idx: number, value: File) => {
        const clone = cloneDeep(tematicas);
        clone[idx].attach = value;
        setTematicas(clone);
    };

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            for (let i = 0; i < tematicas.length; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [tematicas]
    );

    const isAllTematicasSaved = (): boolean => {
        let retorno = true;

        tematicas.forEach(item => {
            if (retorno) {
                retorno = item.ultimaAtualizacao !== '';
            }
        });

        return retorno;
    };

    const checkExistent = async (id: number): Promise<AdminHomeConteudo | null> => {
        const { data } = await service.buscaConteudo(secaoConteudoEnum.TEMATICAS);
        const result: AdminHomeConteudo[] = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));
        const existentTem = result.find(r => r.id == id);
        return existentTem ?? null;
    };

    const setupOrder = async (id: number, toIndex: number | string) => {
        setSubmittingOrder(true);

        const existent = await checkExistent(id);
        if (isAllTematicasSaved()) {
            if (existent) {
                await service.atualizarOrdem(id, toIndex, secaoConteudoEnum.TEMATICAS);
                await fetchItens();
            }

            ToastSuccess('Ordem dos itens atualizada');
        } else {
            ToastError('Antes de alterar a ordem é necessário salvar as novas soluções adicionadas');
        }
        setSubmittingOrder(false);
    };

    const fetchItens = async () => {
        setLoading(true);
        const { data } = await service.buscaConteudo(secaoConteudoEnum.TEMATICAS);

        const _tematicas = data.map((t: any) => new AdminHomeConteudo().fromJSON(t));

        _tematicas.sort((a: AdminHomeConteudo, b: AdminHomeConteudo) => a.ordem - b.ordem);

        fetchCovers(_tematicas);
    };

    const fetchCovers = async (tematicas: AdminHomeConteudo[]) => {
        const clone = cloneDeep(tematicas);
        for (let i = 0; i < tematicas.length; i++) {
            if (tematicas[i].precover == null) {
                const response = await service.buscarAnexoConteudo(tematicas[i].id, true);

                if (response.status == 200 && response.data.base64Content) {
                    const precover = 'data:image/*;base64,' + response.data.base64Content;

                    clone[i].precover = precover;
                } else {
                    const complement = parseInt(tematicas[i].complement ?? '-1');

                    const blob = await urlToImageFile(SolucoesEducacionais.data[complement].imageBase64);
                    const file = new File([blob], `image-${i}.png`, {
                        type: `image/*`,
                    });

                    clone[i].attach = file;
                    clone[i].precover = SolucoesEducacionais.data[complement].imageBase64;
                }
            }
        }
        setLoading(false);
        setTematicas(clone);
    };

    useEffect(() => {
        fetchItens();
    }, []);

    const submit = async (id: number) => {
        if (submittingOrder) {
            ToastError('Não é possível o envio enquanto ocorre a troca de ordem. Aguarde um instante e tente novamente');
            return;
        }

        setSubmitting(true);
        const { data } = await service.buscaConteudo(secaoConteudoEnum.TEMATICAS);
        const result: AdminHomeConteudo[] = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));

        const tema = tematicas.find(s => s.id == id);

        if (!tema) return;

        const { title, content, link, linkExtern, active, disabled, proximoLancamento, novidade, ordem } = tema;

        if (tema.ultimaAtualizacao === '' && tema.attach === null && tema.precover === null) {
            //paliativo  fixme!
            ToastError('É necessário adicionar uma Imagem de Capa');
            setSubmitting(false);
            return;
        }
        const existentTema = result.find(r => r.id == id);

        let data2, status: number;

        if (existentTema) {
            const response = await service.atualizarTematicas(
                existentTema.id,
                title,
                content,
                link ?? '',
                linkExtern ?? false,
                active ?? true,
                disabled ?? false,
                String(ordem),
                proximoLancamento ?? false,
                novidade ?? false,
                ordem
            );

            data2 = response.data;
            status = response.status;
        } else {
            const response = await service.submeterTematica(
                title,
                content,
                link ?? '',
                linkExtern ?? false,
                active ?? true,
                disabled ?? false,
                String(ordem),
                proximoLancamento ?? false,
                novidade ?? false,
                ordem
            );
            data2 = response.data;
            status = response.status;
        }

        if (status == 200) {
            if (tema.attach) {
                await submitAttach(data2.id, tema.attach);
            }

            await fetchItens();
            onSaved();
        }

        setSubmitting(false);
    };

    return (
        <>
            {tematicas.map((item, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <Styled.Title style={{ paddingTop: '5px 0' }}>Temática {idx + 1}</Styled.Title>
                        <Styled.SoluctionsContainer key={idx}>
                            <div>
                                <Styled.Label>Título</Styled.Label>
                                <Styled.Input value={item.title} onChange={(evt: any) => setTitle(item.id, evt.target.value)} />

                                <Styled.Label>Texto</Styled.Label>
                                <Styled.Input
                                    value={item.content}
                                    onChange={(evt: any) => setText(item.id, evt.target.value)}
                                    rows={8}
                                    as="textarea"
                                    className={'form-control'}
                                />

                                <Styled.Label>Link de integração do &#34;Acessar&#34;</Styled.Label>
                                <Styled.RowSol>
                                    <Styled.CkeckContainer>
                                        <Form.Check
                                            checked={!item.linkExtern}
                                            type="radio"
                                            aria-label="radio 1"
                                            onChange={() => setLinkExtern(item.id)}
                                            id={`radio-interno-${idx}`}
                                        />
                                        <label htmlFor={`radio-interno-${idx}`} style={{ marginRight: '10px' }}>
                                            Link interno
                                        </label>
                                        <Form.Check
                                            checked={item.linkExtern}
                                            type="radio"
                                            aria-label="radio 2"
                                            onChange={() => setLinkExtern(item.id)}
                                            id={`radio-externo-${idx}`}
                                        />
                                        <label htmlFor={`radio-externo-${idx}`}>Link Externo</label>
                                    </Styled.CkeckContainer>

                                    <Styled.CkeckContainer>
                                        <span style={{ marginRight: '10px' }}>Ativado</span>
                                        <Switch
                                            height={18 * 0.7}
                                            width={40 * 0.7}
                                            handleDiameter={22 * 0.7}
                                            onHandleColor={'#0B78C8'}
                                            offHandleColor={'#0B78C8'}
                                            onColor={'#BFDFF7'}
                                            offColor={'#dfdfdf'}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.active ?? false}
                                            onChange={value => setActive(item.id)}
                                        />
                                    </Styled.CkeckContainer>
                                </Styled.RowSol>
                                <Styled.Input
                                    className={
                                        item.link && item.link.length > 3 && !isValidLink(item.link ?? '', item.linkExtern) && 'is-invalid'
                                    }
                                    value={item.link}
                                    onChange={(evt: any) => setLink(idx, evt.target.value)}
                                />

                                <Styled.Row style={{ marginTop: '20px' }}>
                                    <Styled.Label style={{ marginRight: '15px', marginTop: 0 }}>Ordem</Styled.Label>
                                    <Select
                                        itens={itemsOrder(idx)}
                                        value={{
                                            value: idx,
                                            label: String(idx + 1),
                                        }}
                                        width={'85px'}
                                        onSelected={o => setupOrder(item.id, o.value)}
                                        disabled={submitting || tematicas.length < 2 || loading || submittingOrder}
                                        isSmall={true}
                                    />
                                </Styled.Row>
                                <Styled.SelectContainer>
                                    <Styled.Row>
                                        <span style={{ marginRight: '10px' }}>Próximo Lançamento</span>
                                        <Switch
                                            height={18 * 0.7}
                                            width={40 * 0.7}
                                            handleDiameter={22 * 0.7}
                                            onHandleColor={'#0B78C8'}
                                            offHandleColor={'#0B78C8'}
                                            onColor={'#BFDFF7'}
                                            offColor={'#dfdfdf'}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.proximoLancamento ?? false}
                                            onChange={value => setProximoLancamento(item.id)}
                                        />
                                    </Styled.Row>
                                    <Styled.Row>
                                        <span style={{ marginRight: '10px' }}>Novidade</span>
                                        <Switch
                                            height={18 * 0.7}
                                            width={40 * 0.7}
                                            handleDiameter={22 * 0.7}
                                            onHandleColor={'#0B78C8'}
                                            offHandleColor={'#0B78C8'}
                                            onColor={'#BFDFF7'}
                                            offColor={'#dfdfdf'}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.novidade ?? false}
                                            onChange={value => setNovidade(item.id)}
                                        />
                                    </Styled.Row>
                                    <Styled.Row>
                                        <span style={{ marginRight: '10px' }}>Ocultar</span>
                                        <Switch
                                            height={18 * 0.7}
                                            width={40 * 0.7}
                                            handleDiameter={22 * 0.7}
                                            onHandleColor={'#0B78C8'}
                                            offHandleColor={'#0B78C8'}
                                            onColor={'#BFDFF7'}
                                            offColor={'#dfdfdf'}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.disabled ?? false}
                                            onChange={value => setOcultar(item.id)}
                                        />
                                    </Styled.Row>
                                </Styled.SelectContainer>
                            </div>
                            <Styled.RightColumn>
                                <AdminImageUploader
                                    size={'sm'}
                                    title={'Imagem de capa'}
                                    onSelect={(_, file: File) => setCover(idx, file)}
                                    preImageB64={tematicas[idx].precover}
                                    recommendedDimension={{ w: 440, h: 175 }}
                                />

                                <br />
                                <GradientButton
                                    onClick={() => submit(item.id)}
                                    disabled={
                                        !isValidInputs(idx) || submitting || submittingOrder || !isStringsValid([item.title, item.content])
                                    }
                                >
                                    {submitting || submittingOrder ? 'Salvando...' : 'Salvar'}
                                </GradientButton>
                            </Styled.RightColumn>
                        </Styled.SoluctionsContainer>
                        <br />
                        <br />
                        <br />
                        <br />
                    </React.Fragment>
                );
            })}
            <Styled.BottomButton>
                <OutlineButton onClick={addTematica}>+ Adicionar</OutlineButton>
            </Styled.BottomButton>
        </>
    );
};

export default AdminEditTematica;
