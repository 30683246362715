import React, { useState, useContext } from 'react';
import { useAppSelector } from 'hooks/LocalReduxThunk';

import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useEffect } from 'react';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import { HeaderContainer } from './components/RealizandoEscolhasHeader.styled';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import HeadInfo from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo';
import BannerInfo from './components/BannerInfo';
import CardGrid from './components/CardGrid';
import { BackButton, Container, Img, Text } from './RealizandoEscolhasHome.styled';
import ArrowLeft from './assets/images/ArrowLeft.svg';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import RealizandoEscolhasProvider from './context/RealizandoEscolhasProvider';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { ToastError } from 'components/Toaster';

export interface IRealizandoEscolhas {}

const RealizandoEscolhasHome = ({}: IRealizandoEscolhas) => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const [profile, setProfile] = useState<ProfileWithAvatar>();

    const service = new RealizandoEscolhasService();

    const CODIGO_TIPO_CONTEUDO: number = 5;

    const registrarAcesso = async () => {
        await service.registrarAcesso(CODIGO_TIPO_CONTEUDO);
    };

    useEffect(() => {
        registrarAcesso();
    }, []);

    useEffect(() => {
        fetchUserInfo(userProfile?.username ?? '', userProfile?.codigo || -1).then((perfilData: ProfileWithAvatar) => {
            setProfile(perfilData);
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('home_realizando_escolhas', 'voltar_pagina_inicial');
        history.push('/');
    };

    return (
        <>
            <Container>
                <BannerInfo />
                <CardGrid />
                <BackButton onClick={goToHomePage}>
                    <Img src={ArrowLeft} />
                    <Text>{`Voltar para página inicial humane`}</Text>
                </BackButton>
            </Container>
        </>
    );
};

export default RealizandoEscolhasHome;
