import { Button } from 'antd';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const ButtonHomeV2 = styled.button<{ outline?: boolean }>`
    min-width: 167px;
    height: 46px;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    border-radius: 4px;
    transition: all 0.5s ease-out;
    border: 1px solid ${Colors.Blue};
    background-color: ${({ outline }) => (outline ? 'transparent' : Colors.Blue)};
    color: ${({ outline }) => (outline ? Colors.Blue : Colors.White)};
    font-weight: bold;

    &:hover {
        background-color: ${({ outline }) => !outline && Colors.SoftBlue};
        color: ${({ outline }) => !outline && Colors.White};
        border: ${({ outline }) => !outline && Colors.SoftBlue};
    }
`;
export const ButtonHomeSolftBlueV2 = styled.button<{ outline?: boolean }>`
    min-width: 167px;
    height: 46px;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    border-radius: 4px;
    transition: all 0.5s ease-out;
    border: 1px solid ${Colors.SoftBlue};
    background-color: ${({ outline }) => (outline ? 'transparent' : Colors.SoftBlue)};
    color: ${({ outline }) => (outline ? Colors.SoftBlue : Colors.White)};
    font-weight: bold;

    &:hover {
        background-color: ${({ outline }) => !outline && Colors.SoftBlue};
        color: ${({ outline }) => !outline && Colors.White};
        border: ${({ outline }) => !outline && Colors.SoftBlue};
    }
`;

export const ButtonHomeNotHoverV2 = styled.button<{ outline?: boolean }>`
    min-width: 167px;
    height: 46px;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    border-radius: 4px;
    transition: all 0.5s ease-out;
    font-weight: bold;
    border: 1px solid ${Colors.Blue};
    background-color: ${({ outline }) => (outline ? 'transparent' : Colors.Blue)};
    color: ${({ outline }) => (outline ? Colors.Blue : Colors.White)};


`;


export const ButtonLinkHomeV2 = styled(Button)<{ color?: Colors; colorHover?: Colors }>`
    color: ${({ color }) => color};

    &:hover {
        color: ${({ colorHover }) => colorHover} !important;
    }
`;
