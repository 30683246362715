import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminHomeConteudoService, { secaoConteudoEnum } from 'core/http/service/admin/AdminHomeConteudo.service';
import { cloneDeep } from 'lodash';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import EducationalSolution, { EducationalSolutionCategory } from 'models/EducationalSolution';
import defaultContentHome from 'data/defaultContentHome';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { isFileNotNull, isStringsValid, isValidLink } from '../newContent/isValidNewContentInput';
import Styled from './AdminEditor.styled';
import submitAttach from './submitAttach';
import SolucoesEducacionais from '../../../../data/SolucoesEducacionais.json';
import urlToImageFile from 'pages/admin/utils/urlToImageFile';
import { b64toBlob } from 'pages/admin/utils/base64ToBlob';
import Select, { SelectOptionType } from 'components/Select';
import { JsonWebTokenError } from 'jsonwebtoken';
import { ToastError, ToastSuccess } from 'components/Toaster';

export interface IAdminEditEducationSolutions {
    onSaved: () => void;
}

const service = new AdminHomeConteudoService();

const AdminEditEducationSolutions = ({ onSaved }: IAdminEditEducationSolutions) => {
    const [solutions, setSolutions] = useState<AdminHomeConteudo[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [submittingOrder, setSubmittingOrder] = useState(false);
    const [categorias, setCategorias] = useState<EducationalSolutionCategory[]>([]);
    const ALL_CATEGORIES: EducationalSolutionCategory = new EducationalSolutionCategory().fromJSON({
        label: 'Todas as categorias',
        descricao: 'Exibir em todas as categorias',
    });

    const isValidInputs = (idx: number): boolean => {
        const sol = solutions[idx];

        if (!sol.active) return true;

        if (!sol.categories) return false;

        if (!(sol.link && isValidLink(sol.link, sol.linkExtern))) return false;

        return isStringsValid([sol.title, sol.content]);
    };

    const addSolution = () => {
        let _solutions = cloneDeep(solutions);
        _solutions = _solutions.sort((s1, s2) => s1.ordem - s2.ordem);

        const lastSol = _solutions[_solutions.length - 1];

        const sol = new AdminHomeConteudo().fromJSON({
            section: 'SOLUCOES_EDUCACIONAIS',
            title: '',
            content: '',
            active: false,
            id: Math.floor(Number.MAX_SAFE_INTEGER * Math.random()),
            ordem: lastSol.ordem + 1,
        });

        _solutions.push(sol);
        setSolutions(_solutions);
    };

    const fetchCategories = async () => {
        const { data } = await service.buscarCategoriaConteudo();
        const _categories = Array.from(data).map((c: any) => new EducationalSolutionCategory().fromJSON(c));
        setCategorias(_categories);

        return _categories;
    };

    const fetchItens = async () => {
        let _solutions: AdminHomeConteudo[] = [];

        /* for (let i = 0; i < SolucoesEducacionais.data.length; i++) {
            const sol = SolucoesEducacionais.data[i];

            const blob = await b64toBlob(sol.imageBase64);

            const file = new File([blob], `image-${i}.png`, {
                type: `image/*`,
            });

            let homeConteudo = new AdminHomeConteudo().fromJSON({
                id: sol.id,
                title: sol.title,
                content: sol.description,
                section: 'SOLUCOES_EDUCACIONAIS',
                complement: i,
                link: sol.followRoute,
                linkExtern: false,
                active: sol.active,
                attach: file,
            });
            homeConteudo.attach = file;
            homeConteudo.precover = sol.imageBase64;
            _solutions.push(homeConteudo);
        } */

        const localCategorias = await fetchCategories();

        const { data } = await service.buscaConteudo('SOLUCOES_EDUCACIONAIS');
        const result: AdminHomeConteudo[] = data.map((s: any) => {
            const adminHomeConteudo = new AdminHomeConteudo().fromJSON(s);

            const categoriesRaw = s.categorias;

            if (categoriesRaw) {
                if (categoriesRaw.includes(',')) adminHomeConteudo.categories = ALL_CATEGORIES;
                else adminHomeConteudo.categories = localCategorias.find(c => categoriesRaw.includes(String(c.id)));
            }

            return adminHomeConteudo;
        });

        for (let i = 0; i < result.length; i++) {
            const newSol = result[i];
            const complement = parseInt(newSol.complement ?? '-1');

            if (complement > -1) {
                _solutions[complement] = newSol;
            }
        }

        _solutions = _solutions.filter(s => !!s).sort((a, b) => a.ordem - b.ordem);

        setSolutions(_solutions);
    };

    const setTitle = (id: number, value: string) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.title = value;
        }

        setSolutions(clone);
    };

    const setText = (id: number, value: string) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.content = value;
        }

        setSolutions(clone);
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(solutions);
        clone[idx].link = value;
        setSolutions(clone);
    };

    const setLinkExtern = (id: number) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.linkExtern = !sol.linkExtern;
        }

        setSolutions(clone);
    };

    const setActive = (id: number) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.active = !sol.active;
        }

        setSolutions(clone);
    };

    const setProximoLancamento = (id: number) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) {
              sol.proximoLancamento = !sol.proximoLancamento;
              sol.active = sol.proximoLancamento;
            }
        }

        setSolutions(clone);
    };

    const setNovidade = (id: number) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.novidade = !sol.novidade;
        }

        setSolutions(clone);
    };

    const setDisabled = (id: number) => {
        const clone = cloneDeep(solutions);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == id) sol.disabled = !sol.disabled;
        }

        setSolutions(clone);
    };

    const setCover = (idx: number, value: File) => {
        const clone = cloneDeep(solutions);
        clone[idx].attach = value;
        setSolutions(clone);
    };

    const setCategory = (idx: number, value: EducationalSolutionCategory | null) => {
        if (value) {
            const clone = cloneDeep(solutions);
            clone[idx].categories = value;
            setSolutions(clone);
        }
    };

    const isAllSolutionsSaved = (): boolean => {
        let retorno = true;

        solutions.forEach(item => {
            if (retorno) {
                retorno = item.ultimaAtualizacao !== '';
            }
        });

        return retorno;
    };

    const setupOrder = async (id: number, toIndex: number | string) => {
        setSubmittingOrder(true);

        const existent = await checkExistent(id);
        if (isAllSolutionsSaved()) {
            if (existent) {
                await service.atualizarOrdem(id, toIndex, secaoConteudoEnum.SOLUCOES_EDUCACIONAIS);
                await fetchItens();
            }

            ToastSuccess('Ordem dos itens atualizada');
        } else {
            ToastError('Antes de alterar a ordem é necessário salvar as novas soluções adicionadas');
        }
        setSubmittingOrder(false);
    };

    const checkExistent = async (id: number): Promise<AdminHomeConteudo | null> => {
        const { data } = await service.buscaConteudo(secaoConteudoEnum.SOLUCOES_EDUCACIONAIS);
        const result: AdminHomeConteudo[] = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));
        const existentSol = result.find(r => r.id == id);
        return existentSol ?? null;
    };

    const preSubmitCategory = (categories: EducationalSolutionCategory | null | undefined): number[] => {
        if (!categories) return [];
        else if (categories.label == ALL_CATEGORIES.label) return categorias.map(c => c.id ?? -1);
        else return [categories.id ?? -1];
    };

    const modifyCategory = async (idCategoria: number) => {
        await service.modificarCategoria(idCategoria);
        const clone = cloneDeep(categorias);

        for (let i = 0; i < clone.length; i++) {
            const sol = clone[i];
            if (sol.id == idCategoria) sol.desativado = !sol.desativado;
        }

        setCategorias(clone);
    };

    const submit = async (id: number) => {
        if (submittingOrder) {
            ToastError('Não é possível o envio enquanto ocorre a troca de ordem. Aguarde um instante e tente novamente');
            return;
        }

        setSubmitting(true);
        const { data } = await service.buscaConteudo(secaoConteudoEnum.SOLUCOES_EDUCACIONAIS);
        const result: AdminHomeConteudo[] = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));

        const solution = solutions.find(s => s.id == id);

        if (!solution) return;

        const { title, content, link, linkExtern, active, categories, disabled, proximoLancamento, novidade, ordem } = solution;

        if (categories == null) {
            ToastError('Campo "Categoria" não preenchido');
            setSubmitting(false);
            return;
        }

        if (solution.ultimaAtualizacao === '' && solution.attach === null && solution.precover === null) {
            //paliativo  fixme!
            ToastError('É necessário adicionar uma Imagem de Capa');
            setSubmitting(false);
            return;
        }
        const existentSol = result.find(r => r.id == id);

        let data2, status: number;

        if (existentSol) {
            const response = await service.atualizarSolEducacionais(
                existentSol.id,
                title,
                content,
                link ?? '',
                linkExtern ?? false,
                active ?? true,
                disabled ?? false,
                String(ordem),
                proximoLancamento ?? false,
                novidade ?? false,
                ordem,
                preSubmitCategory(categories)
            );

            data2 = response.data;
            status = response.status;
        } else {
            const response = await service.submeterSolEducacionais(
                title,
                content,
                link ?? '',
                linkExtern ?? false,
                active ?? true,
                disabled ?? false,
                String(ordem),
                proximoLancamento ?? false,
                novidade ?? false,
                ordem,
                preSubmitCategory(categories)
            );
            data2 = response.data;
            status = response.status;
        }

        if (status == 200) {
            if (solution.attach) {
                await submitAttach(data2.id, solution.attach);
            }

            await fetchItens();
            onSaved();
        }

        setSubmitting(false);
    };

    const fetchCovers = async () => {
        const clone = cloneDeep(solutions);
        for (let i = 0; i < solutions.length; i++) {
            if (solutions[i].precover == null) {
                const response = await service.buscarAnexoConteudo(solutions[i].id, true);

                if (response.status == 200 && response.data.base64Content) {
                    const precover = 'data:image/*;base64,' + response.data.base64Content;

                    clone[i].precover = precover;
                } else {
                    const complement = parseInt(solutions[i].complement ?? '-1');

                    const blob = await urlToImageFile(SolucoesEducacionais.data[complement].imageBase64);
                    const file = new File([blob], `image-${i}.png`, {
                        type: `image/*`,
                    });

                    clone[i].attach = file;
                    clone[i].precover = SolucoesEducacionais.data[complement].imageBase64;
                }
            }
        }
        setSolutions(clone);
    };

    useEffect(() => {
        fetchItens();

        return () => {
            setSolutions([]);
        };
    }, []);

    useEffect(() => {
        fetchCovers();
    }, [solutions.length]);

    const getCategoriesOptions = (): SelectOptionType[] => {
        const options: SelectOptionType[] = categorias.map((c: EducationalSolutionCategory) => ({ value: c.label, label: c.label }));
        options.push({ value: ALL_CATEGORIES.label, label: ALL_CATEGORIES.descricao });

        return options;
    };

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            for (let i = 0; i < solutions.length; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [solutions]
    );

    return (
        <>
            <Styled.Title style={{ paddingTop: '5px 0' }}>Categorias:</Styled.Title>
            {categorias.map((cat, idx) => {
                return (
                    <Styled.RowSol style={{ alignItems: 'flex-end', padding: '10px', maxWidth: 'calc(100% - 400px)' }} key={idx}>
                        <label htmlFor={`switch-categoria-${idx}`}>{cat.label}</label>
                        <Styled.CkeckContainer>
                            <span style={{ marginRight: '10px' }}>Ativado</span>
                            <Switch
                                id={`switch-categoria-${idx}`}
                                height={18 * 0.7}
                                width={40 * 0.7}
                                handleDiameter={22 * 0.7}
                                onHandleColor={'#0B78C8'}
                                offHandleColor={'#0B78C8'}
                                onColor={'#BFDFF7'}
                                offColor={'#dfdfdf'}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={!cat.desativado}
                                onChange={() => modifyCategory(cat.id!)}
                            />
                        </Styled.CkeckContainer>
                    </Styled.RowSol>
                );
            })}
            <br />
            <br />
            {solutions.map((sol, idx) => {
                return (
                    sol && (
                        <React.Fragment key={sol.id}>
                            <Styled.Title style={{ paddingTop: '5px 0' }}>Seção {idx + 1}</Styled.Title>
                            <Styled.SoluctionsContainer key={sol.id}>
                                <div>
                                    <Styled.Label>Título</Styled.Label>
                                    <Styled.Input value={sol.title} onChange={(evt: any) => setTitle(sol.id, evt.target.value)} />

                                    <Styled.Label>Texto</Styled.Label>
                                    <Styled.Input
                                        value={sol.content}
                                        onChange={(evt: any) => setText(sol.id, evt.target.value)}
                                        rows={8}
                                        as="textarea"
                                        className={'form-control'}
                                    />

                                    <Styled.Label>Link de integração do &#34;Saiba mais&#34;</Styled.Label>
                                    <Styled.RowSol>
                                        <Styled.CkeckContainer>
                                            <Form.Check
                                                checked={!sol.linkExtern}
                                                type="radio"
                                                aria-label="radio 1"
                                                onChange={() => setLinkExtern(sol.id)}
                                                id={`radio-interno-${idx}`}
                                            />
                                            <label htmlFor={`radio-interno-${idx}`} style={{ marginRight: '10px' }}>
                                                Link interno
                                            </label>
                                            <Form.Check
                                                checked={sol.linkExtern}
                                                type="radio"
                                                aria-label="radio 2"
                                                onChange={() => setLinkExtern(sol.id)}
                                                id={`radio-externo-${idx}`}
                                            />
                                            <label htmlFor={`radio-externo-${idx}`}>Link Externo</label>
                                        </Styled.CkeckContainer>
                                    </Styled.RowSol>
                                    <Styled.Input
                                        className={
                                            sol.link && sol.link.length > 3 && !isValidLink(sol.link ?? '', sol.linkExtern) && 'is-invalid'
                                        }
                                        value={sol.link}
                                        onChange={(evt: any) => setLink(idx, evt.target.value)}
                                    />

                                    <Styled.SelectContainer>
                                        <Styled.Row>
                                            <Styled.Label style={{ marginRight: '15px', marginTop: 0 }}>Categoria: </Styled.Label>
                                            <Select
                                                itens={getCategoriesOptions()}
                                                onSelected={(o: SelectOptionType) =>
                                                    setCategory(idx, categorias.find(c => c.label == o.value) ?? ALL_CATEGORIES)
                                                }
                                                value={{ value: sol.categories?.label ?? '', label: sol.categories?.label ?? '' }}
                                                isSmall={true}
                                            />
                                        </Styled.Row>

                                        <Styled.Row>
                                            <span style={{ marginRight: '10px' }}>Ocultar</span>
                                            <Switch
                                                height={18 * 0.7}
                                                width={40 * 0.7}
                                                handleDiameter={22 * 0.7}
                                                onHandleColor={'#0B78C8'}
                                                offHandleColor={'#0B78C8'}
                                                onColor={'#BFDFF7'}
                                                offColor={'#dfdfdf'}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                checked={sol.disabled ?? false}
                                                onChange={value => setDisabled(sol.id)}
                                            />
                                        </Styled.Row>
                                    </Styled.SelectContainer>
                                    <Styled.SelectContainer>
                                        <Styled.Row style={{ marginTop: '10px' }}>
                                            <Styled.Label style={{ marginRight: '15px', marginTop: 0 }}>Ordem</Styled.Label>
                                            <Select
                                                itens={itemsOrder(idx)}
                                                value={{
                                                    value: idx,
                                                    label: String(idx + 1),
                                                }}
                                                width={'85px'}
                                                onSelected={o => setupOrder(sol.id, o.value)}
                                                disabled={submitting || solutions.length < 2}
                                                isSmall={true}
                                            />
                                        </Styled.Row>
                                        <Styled.Row>
                                            <span style={{ marginRight: '10px' }}>Novidade</span>
                                            <Switch
                                                height={18 * 0.7}
                                                width={40 * 0.7}
                                                handleDiameter={22 * 0.7}
                                                onHandleColor={'#0B78C8'}
                                                offHandleColor={'#0B78C8'}
                                                onColor={'#BFDFF7'}
                                                offColor={'#dfdfdf'}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                checked={sol.novidade ?? false}
                                                onChange={value => setNovidade(sol.id)}
                                            />
                                        </Styled.Row>
                                        <Styled.Row>
                                            <span style={{ marginRight: '10px' }}>Próximo Lançamento</span>
                                            <Switch
                                                height={18 * 0.7}
                                                width={40 * 0.7}
                                                handleDiameter={22 * 0.7}
                                                onHandleColor={'#0B78C8'}
                                                offHandleColor={'#0B78C8'}
                                                onColor={'#BFDFF7'}
                                                offColor={'#dfdfdf'}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                checked={sol.proximoLancamento ?? false}
                                                onChange={value => setProximoLancamento(sol.id)}
                                            />
                                        </Styled.Row>
                                    </Styled.SelectContainer>
                                </div>

                                <Styled.RightColumn>
                                    <AdminImageUploader
                                        size={'sm'}
                                        title={'Imagem de capa'}
                                        onSelect={(_, file: File) => setCover(idx, file)}
                                        preImageB64={solutions[idx].precover}
                                        recommendedDimension={{ w: 440, h: 175 }}
                                    />

                                    <br />
                                    <GradientButton
                                        onClick={() => submit(sol.id)}
                                        disabled={
                                            !isValidInputs(idx) || submitting || submittingOrder || !isStringsValid([sol.title, sol.content])
                                        }
                                    >
                                        {submitting ? 'Salvando...' : 'Salvar'}
                                    </GradientButton>
                                </Styled.RightColumn>
                            </Styled.SoluctionsContainer>
                            <br />
                            <br />
                        </React.Fragment>
                    )
                );
            })}

            <Styled.BottomButton>
                <OutlineButton onClick={addSolution}>+ Adicionar</OutlineButton>
            </Styled.BottomButton>

            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default AdminEditEducationSolutions;
