import { jsonProperty, Serializable } from 'ts-serializable';
import { CarouselMedia } from './CarouselMedia';

export class SecaoCarrosselMedia extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    corSecao: string = '';

    @jsonProperty([CarouselMedia], [])
    carrosseis: [CarouselMedia] | [] = [];

    @jsonProperty(String)
    usuarioResponsavel: string = '';
}

export default SecaoCarrosselMedia;
