import { TipoConteudoInternoEnum } from 'enums/TipoConteudoInternoEnum';
import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class PgIntermediariaConteudoRelacionado extends Serializable {
    @jsonProperty(Number)
    codigo: number = 0;

    @jsonProperty(Number)
    codigoPaginaIntermediaria: number = 0;

    @jsonName('titulo')
    @jsonProperty(String, null)
    titulo: string = '';

    @jsonName('link')
    @jsonProperty(String, null)
    link: string = '';

    @jsonName('conteudoInterno')
    @jsonProperty(Boolean)
    conteudoInterno: boolean = false;

    @jsonName('tipoConteudoInterno')
    @jsonProperty(String, null)
    tipoConteudoInterno: TipoConteudoInternoEnum = TipoConteudoInternoEnum.UNKNOWN;

    @jsonName('identificadorConteudoInterno')
    @jsonProperty(Number, null)
    identificadorConteudoInterno: number | null = null;

    @jsonName('ativo')
    @jsonProperty(Boolean)
    ativo: boolean = true;

    @jsonName('usuarioResponsavel')
    @jsonProperty(String, null)
    usuarioResponsavel: string = '';

    @jsonName('anexoBase64')
    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonName('filePath')
    @jsonProperty(String)
    filePath: string = '';
}

export default PgIntermediariaConteudoRelacionado;
