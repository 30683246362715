import React, { useEffect, useState } from 'react';
import Styled from './ModalDelete.styled';
import { PopupQuestionStyles } from 'components/popupQuestion/PopupQuestion.styled';
import IconDanger from '../../images/danger-icon.svg';
import { Modal } from 'react-bootstrap';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { BaseButton, OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import { AiOutlineClose } from 'react-icons/ai';
import Row from 'components/Row';

interface IModalDelete {
    showModal: boolean;
    isLoading: boolean;
    namePage: string;
    onHiden: () => void;
    handleDelete: () => void;
}

const ModalDelete = ({ showModal, onHiden, handleDelete, namePage, isLoading }: IModalDelete) => {
    const [page, setPage] = useState<string>('');
    const [isConfirmateIncorret, setConfirmateIncorret] = useState<boolean>(false);

    const verifyDelete = () => {
        if (page.trim() === namePage.trim()) {
            handleDelete();
        } else {
            setConfirmateIncorret(true);
        }
    };

    useEffect(() => {
        setConfirmateIncorret(false);
        setPage('');
    }, [showModal]);

    return (
        <Modal show={showModal} onHide={onHiden} centered>
            <Styled.Modal.CloseContainer>
                <p onClick={onHiden}>
                    <AiOutlineClose />
                </p>
            </Styled.Modal.CloseContainer>

            <Styled.ContainerModal style={{ marginLeft: 0 }}>
                <Styled.Modal.TitleAtencao>Atenção!</Styled.Modal.TitleAtencao>
                <Styled.Modal.Title>
                    Para excluir essa página, digite esse nome <b>{namePage}</b> no campo abaixo.
                </Styled.Modal.Title>
                {isConfirmateIncorret && <Styled.Modal.TextAviso>Nome da página incorreta.</Styled.Modal.TextAviso>}
                <Styled.Modal.InputConfirmation
                    value={page}
                    onChange={e => setPage(e.target.value)}
                    isIncorret={isConfirmateIncorret}
                    placeholder="Nome da página a ser deletada."
                />
                <Row justify="space-around"></Row>
                <br />
                <Row justify="space-around">
                    <Styled.Button disabled={isLoading} color={Colors.White} bgHover={Colors.Red} onClick={verifyDelete}>
                        {isLoading ? 'Excluindo...' : 'Excluir'}
                    </Styled.Button>
                    <Styled.Button color={Colors.White} bgHover={Colors.Blue} onClick={onHiden}>
                        Fechar
                    </Styled.Button>
                </Row>
            </Styled.ContainerModal>
        </Modal>
    );
};

export default ModalDelete;
