import React, { useEffect, useState } from 'react';
import {
    RedirectButton,
    BottomContainer,
    InnerContainer,
    MainContainer,
    PainelContainer,
    Text,
    Title,
    TopContainer,
} from './BibliotecaPainel.styled';
import ReactTooltip from 'react-tooltip';
import ShareIcon from '../../assets/ShareIcon.svg';
import { useHistory } from 'react-router-dom';
import InternalCard from '../InternalCard';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import FullDashboardBiblioteca from 'models/perfil/FullDashboardBiblioteca';
import Sharebox from '../Sharebox';
import { PaginasEnum } from 'enums/PaginasEnum';
import { GTM_Page_Meu_Painel } from 'components/GoogleTagManager';

export interface IBibliotecaPainel {
    biblioteca: FullDashboardBiblioteca;
}

const BibliotecaPainel = ({ biblioteca }: IBibliotecaPainel) => {
    const history = useHistory();
    const [params, setParams] = useState({ baixados: 0, favoritos: 0, acervo: 0 });

    const updateBibliotecaValue = () => {
        let params = {
            baixados: 0,
            favoritos: 0,
            acervo: 0,
        };
        if (biblioteca != null) {
            params.baixados = biblioteca.baixados;
            params.favoritos = biblioteca.favoritos;
            params.acervo = biblioteca.acervo;
        }
        setParams(params);
    };

    useEffect(() => {
        updateBibliotecaValue();
    }, []);

    const goToBiblioteca = () => {
        GTM_Page_Meu_Painel('biblioteca_redirect_button', 'ver_conteudos', 'principal_biblioteca');
        history.push('/biblioteca');
    };

    return (
        <PainelContainer>
            <MainContainer>
                <TopContainer>
                    <Title>{`Conteúdos da Biblioteca`}</Title>
                    <Sharebox location={PaginasEnum.BIBLIOTECA} />
                </TopContainer>

                <InnerContainer>
                    <InternalCard value={params.baixados} description={'baixados'} colorPicker={'biblioteca'}></InternalCard>
                    <InternalCard value={params.favoritos} description={'favoritos'} colorPicker={'biblioteca'}></InternalCard>
                    <InternalCard value={params.acervo} description={'acervo'} colorPicker={'biblioteca'}></InternalCard>
                </InnerContainer>

                <BottomContainer>
                    <RedirectButton onClick={goToBiblioteca}>
                        <Text>{'Ver conteúdos'}</Text>
                    </RedirectButton>
                </BottomContainer>
            </MainContainer>

            {/* Others */}
            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </PainelContainer>
    );
};

export default BibliotecaPainel;
