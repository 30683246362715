import React, { useEffect, useState } from 'react';
import { AcessoExclusivoContext } from './AcessoExclusivoContext';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import keycloak from 'core/config/keycloak';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { useHistory } from 'react-router-dom';

export interface IAcessoExlusivoProvider {}

export const getIdSolucao = (url: string): number => {
    switch (url) {
        case 'dialogos-socioemocionais':
            return 6;
        default:
            return -1;
    }
};

const AcessoExlusivoProvider = ({ children }: React.PropsWithChildren<IAcessoExlusivoProvider>) => {
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

  

    const validarSePossuiAcesso = async () => {
        const link: string = location.pathname.split('/')[2];

        const idSolucao = getIdSolucao(link);

        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                const solucoes: TipoSolucoes[] = response.data;

                const possui = solucoes.find(f => f.id === idSolucao);

                if (!possui) {
                    history.push('/solucoes');
                } else {
                    setLoading(false);
                }
            }
        }else {
            history.push('/solucoes');
        }
    };

    useEffect(() => {
        validarSePossuiAcesso();
    }, [location.pathname]);
    const context: AcessoExclusivoContext.IContext = {
        loading: loading,
    };

    return <AcessoExclusivoContext.Context.Provider value={context}>{children}</AcessoExclusivoContext.Context.Provider>;
};

export default AcessoExlusivoProvider;
