import React, { FunctionComponent, useContext } from 'react';

import Card from './Card';
import { DiarioBordoContext } from '../../../context/DiarioContext';
import { DiaryCardStyle } from '../DiaryCard.styled';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

interface ICards {}

const Cards: FunctionComponent<ICards> = (_: React.PropsWithChildren<ICards>) => {
    const { items, onCreate, onSelect, setOnEditting } = useContext(DiarioBordoContext.Context);

    const onCreateHandler = async () => {
        await onCreate();
        setOnEditting(true);
    };

    return (
        <DiaryCardStyle.CardsContainer>
            <DiaryCardStyle.Scroll>
                {items.map((item, index) => (
                    <Card data={item} key={`diario_bordo_lista_diarios_card_${index}`} />
                ))}
            </DiaryCardStyle.Scroll>

            <DiaryCardStyle.Center>
                <DiaryCardStyle.NewDiaryButton>
                    <span
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'novo_registro_btn');
                            onCreateHandler();
                        }}
                    >
                        Novo registro
                    </span>
                    <AiOutlinePlusCircle />
                </DiaryCardStyle.NewDiaryButton>
            </DiaryCardStyle.Center>
        </DiaryCardStyle.CardsContainer>
    );
};

export default Cards;
