import { RoleEnum } from 'enums/RoleEnum';
import PerfilDTOGenerico from 'models/perfil/PerfilDTOGenerico';

export interface UserOccupation extends PerfilDTOGenerico {
    role: RoleEnum;
    autoCadastravel: boolean;
}

export const occupations: UserOccupation[] = [
    {
        id: 1,
        descricao: 'Professor(a)',
        role: RoleEnum.Professor,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 2,
        descricao: 'Coordenador(a) pedagógico',
        role: RoleEnum.CoordenadorNaoParceiro,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 3,
        descricao: 'Diretor(a)',
        role: RoleEnum.Diretor,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 4,
        descricao: 'Vice-diretor(a)',
        role: RoleEnum.Diretor,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 5,
        descricao: 'Secretário(a) de educação',
        role: RoleEnum.TecnicoEducacao,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 6,
        descricao: 'Sub-secretário(a) de educação',
        role: RoleEnum.TecnicoEducacao,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 7,
        descricao: 'Técnico(a) de Secretaria',
        role: RoleEnum.TecnicoEducacao,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 8,
        descricao: 'Auxiliar/Monitor(a) de classe',
        role: RoleEnum.Professor,
        autoCadastravel: true,
    } as UserOccupation,

    {
        id: 9,
        descricao: 'Orientador(a) pedagógico',
        role: RoleEnum.CoordenadorNaoParceiro,
        autoCadastravel: true,
    } as UserOccupation,
    {
        id: 10,
        descricao: 'Estagiário(a)',
        role: RoleEnum.Professor,
        autoCadastravel: true,
    } as UserOccupation,
    {
        id: 11,
        descricao: 'Agente Técnico',
        role: RoleEnum.AgenteTecnico,
        autoCadastravel: false,
    } as UserOccupation,
    {
        id: 12,
        descricao: 'Gestor(a) Escolar',
        role: RoleEnum.GestorEscolar,
        autoCadastravel: false,
    } as UserOccupation,
    {
        id: 13,
        descricao: 'Gestor(a) de Rede',
        role: RoleEnum.GestorRede,
        autoCadastravel: false,
    } as UserOccupation,
    {
        id: 14,
        descricao: 'Coordenador(a) de Regional',
        role: RoleEnum.CoordenadorRegional,
        autoCadastravel: false,
    } as UserOccupation,
    {
        id: 15,
        descricao: 'Estudante',
        role: RoleEnum.Estudante,
        autoCadastravel: false,
    } as UserOccupation,
    {
        id: 99,
        descricao: 'Outra',
        role: RoleEnum.Outro,
        autoCadastravel: true,
    } as UserOccupation,
];

export const occupationDescription = (occupationDesc: string): string => {
    try {
        const occ = occupations.find(o => o.descricao == occupationDesc);
        return occ?.descricao ?? occupationDesc;
    } catch (err) {
        return occupationDesc;
    }
};

export const occupationIsOther = (occupationDesc: string): boolean => {
    if (occupationDesc.length == 0) return false;

    for (const occ of occupations) {
        if (occ.descricao == occupationDesc) return false;
    }

    return true;
};

export default occupations;
