import { SelectOptionType } from 'components/Select';
import { FilterRecursosCostumizados } from 'enums/FilterRecursosCostumizados';
import PaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { ItemRecursosCostumizadoPublic } from 'models/recursos-costumizados/ItemRecursosCostumizadoPublic';
import { createContext } from 'react';

export namespace RecursosContext {
    export interface IContext {
        pagina: PaginaIntermediaria | null;
        itensRecursoResumido: ItemRecursosCostumizadoPublic[];
        itemRecurso: ItemRecursosCostumizado | null;
        loading: boolean;
        filterSelected: SelectOptionType;
        updateWithStatus: (status: FilterRecursosCostumizados | string) => Promise<void>;
        certificacao: CertificacoesModel
    }

    export const Context = createContext({} as IContext);
}
