import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Styled from './ArtigoItem.styled';

import cover from '../../../../images/biblioteca/cover2-exemple.png';

import BiblioShareModal from 'pages/biblioteca/components/share/BiblioShareModal';
import IBiblioPropsItem from './BiblioPropsItem';
import BiblioRateModal from 'pages/biblioteca/components/rates/BiblioRateModal';
import { DateHelp } from 'core/util/DateHelp';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { AdminBiblioAnexoType } from 'pages/admin/components/newContent/submitAttach';
import BiblioFavoriteButtonItem from 'pages/biblioteca/components/favorites/BiblioFavoriteButtonItem';
import { useKeycloak } from '@react-keycloak/web';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import Loading from 'components/Loading';
import { MiddleBox, Separator } from './DefaultItem.styled';
import RelatedContent from 'components/RelatedContent';
import ItemBiblitoecaConteudoRelacionado from 'models/admin/ItemBibliotecaConteudoRelacionado';
import { ItemBibliotecaConteudoRelacionadoService } from 'core/http/service/ItemBibliotecaConteudoRelacionado.service';
import { FetchUtils } from 'core/util/FetchUtils';
import { Title } from 'components/RelatedContent.styled';
import { RelatedContentAnexoEnum } from 'components/RelatedContentEnum';
import { ResizeMediumWidth } from 'constants/Dimensions';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

export const imageSanitize = (img: string) => {
    if (!img) return cover;
    else return 'data:image/*;base64,' + img ?? cover;
};

const ArtigoItem = ({ data }: IBiblioPropsItem) => {
    const [showShareModal, setShowShareModal] = useState(false);
    const [showRateModal, setShowRateModal] = useState(false);
    const { keycloak } = useKeycloak();
    const [isHeaderLoading, setHeaderLoading] = useState(false);
    const IsMobile = () => IsCustomMaxWidth(ResizeMediumWidth);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(true);
    const [conteudosRelacionados, setConteudosRelacionados] = useState<ItemBiblitoecaConteudoRelacionado[]>([]);
    const conteudosService = new ItemBibliotecaConteudoRelacionadoService();

    const [headerImg, setHeaderImg] = useState('');

    const cleanHTML = (dirtyHTML: string) =>
        DOMPurify.sanitize(dirtyHTML, {
            USE_PROFILES: { html: true },
        });

    const fetchHeader = async () => {
        setHeaderLoading(true);
        const service = new BiblioConteudoService();
        let response: any;

        if (keycloak?.authenticated) {
            response = await service.buscarAnexoTipo(data.id, AdminBiblioAnexoType.HEADER);
        } else {
            response = await service.buscarPublicaAnexoTipo(data.id, AdminBiblioAnexoType.HEADER);
        }

        const _data = response.data;
        setHeaderImg(_data.base64Content);
        setHeaderLoading(false);
    };

    const marcarNotificacaoLida = async () => {
        if (keycloak.authenticated) {
            const service = new BiblioNotificacaoService();
            await service.updateItemByUserItem(keycloak.subject!, data.id);
        }
    };

    useEffect(() => {
        fetchHeader();
        marcarNotificacaoLida();
    }, []);

    const fetchRelatedContent = async () => {
        const responseRelatedContent: any = await conteudosService.listaPorItemPublico(data.id);
        let output = responseRelatedContent.data.map((item: any) => new ItemBiblitoecaConteudoRelacionado().fromJSON(item));
        setConteudosRelacionados(output);
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, fetchRelatedContent);
    }, []);

    return (
        <>
            <Styled.Container>
                <Styled.Content>
                    {isHeaderLoading ? (
                        <Styled.LoadingContainer>
                            <Loading />
                        </Styled.LoadingContainer>
                    ) : (
                        <Styled.Cover src={imageSanitize(headerImg)} />
                    )}
                    <Styled.Date>Publicado em {DateHelp.formatDateFull(data.update_date)}</Styled.Date>
                    <br />

                    <Styled.Title>{data.title}</Styled.Title>
                    <br />
                    {/*  <Styled.SubTitle>
                    Materiais e conteúdos preparados pelo Instituto Ayrton Senna podem ajudar gestores e educadores brasileiros a lidar com o
                    retorno escolar
                  </Styled.SubTitle> */}

                    <Styled.Body dangerouslySetInnerHTML={{ __html: cleanHTML(data.content ?? '') }}></Styled.Body>

                    <BiblioRateModal
                        item_id={data.id}
                        show={showRateModal}
                        prerate={data.rateUser}
                        onShow={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `rate_artigo_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'open_rate_modal_btn'
                            );
                            setShowRateModal(true);
                        }}
                        onHide={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `rate_artigo_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'close_rate_modal_btn'
                            );
                            setShowRateModal(false);
                        }}
                    />
                </Styled.Content>

                <Styled.ActionContainer>
                    <BiblioFavoriteButtonItem data={data} isFavorited={data?.isFavorited} />
                    <BiblioShareModal
                        item_id={data.id}
                        show={showShareModal}
                        onShow={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `share_artigo_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'open_share_modal_btn'
                            );
                            setShowShareModal(true);
                        }}
                        onHide={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `share_artigo_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'close_share_modal_btn'
                            );
                            setShowShareModal(false);
                        }}
                    />
                </Styled.ActionContainer>
            </Styled.Container>
            <MiddleBox hasContent={conteudosRelacionados.length > 0} style={{ paddingTop: '40px' }}>
                <Separator />
                <Title>{'Conteúdos relacionados'}</Title>
                {RelatedContent.ConteudosRelacionados(
                    { loadingRelatedContent, IsMobile },
                    RelatedContentAnexoEnum.BIBLIOTECA,
                    conteudosRelacionados
                )}
                <Separator />
            </MiddleBox>
        </>
    );
};

export default ArtigoItem;
