import keycloak from 'core/config/keycloak';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';

const serviceConteudoBiblioteca = new BiblioConteudoService();

export const fetchPalavrasProibidas = async () => {
    const response = await serviceConteudoBiblioteca.listaPalavrasProibidas();

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const validatePalavraProibida = (ppl: string[], content: string) => {
    if (content.trim().length > 2 && ppl) {
        const regex = new RegExp('\\b' + ppl.join('\\b|\\b') + '\\b', 'i');
        const isProibid = regex.test(content);
        return isProibid;
    }

    return false;
};

export const validateItemExclusivoManualmente = (perfil?: PerfilUsuario | null): Boolean => {
    if (keycloak.authenticated) {
        if (perfil?.estado === 'MA' && perfil?.cidade === 'São Luís') {
            return true;
        }

        return false;
    }

    return false;
};
