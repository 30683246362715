import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { FiCheckCircle } from 'react-icons/fi';

const Styled = {
    Container: styled.div<{ width?: string }>`
        display: flex;
        gap: 0.2rem;
        color: ${Colors.Blue};
        font-weight: 700;
        width: ${({ width }) => (width ? width : '180px')};
        padding: 10px;
    `,

    Title: styled.span<{ width?: string }>`
        font-size: 14px;
        max-width: ${({ width }) => (width ? width : '140px')};
    `,
};

interface ICheckHability {
    label: string;
    containerWidth?: string;
    titleMaxWidth?: string;
}

const CheckHability = ({ label, containerWidth, titleMaxWidth }: ICheckHability) => {
    return (
        <Styled.Container width={containerWidth}>
            <FiCheckCircle style={{marginTop: 4}} size={16} color={Colors.Yellow} /> <Styled.Title width={titleMaxWidth}>{label}</Styled.Title>
        </Styled.Container>
    );
};

export default CheckHability;
