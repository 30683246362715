import PerfilUsuario from 'models/perfil/PerfilUsuario';
import UsuarioDTO from 'models/UsuarioDTO';
import { createContext } from 'react';
import { typePendencias } from './AccessPermissionProvider';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';

export namespace AccessPermissionContext {
    export interface IContext {
        redirectBehindRegister: (pathToRedirect: string) => void;
        isUserNoRegister: () => boolean;
        pendencias?: typePendencias[];
        pathRedirect: string;
        parametrosPerfil?: ParametrosCadastroPerfilDTO
        
    }

    export const Context = createContext({} as IContext);
}
