import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AnexoConteudo from 'models/biblioteca/AnexoConteudo';
import BiblioItem from 'models/biblioteca/BiblioItem';

export const MAX_FILE_BYTES = 15 * 1024 * 1024;

export enum AdminBiblioAnexoType {
    CAPA = 1,
    HEADER,
    CONTEUDO,
}

async function submitAttach(idItem: number, file: File, tipo_anexo: AdminBiblioAnexoType) {
    const service = new AdminBiblioService();

    if (file.size >= MAX_FILE_BYTES) {
        throw Error('Arquivo anexado muito grande (máximo 15MB)');
    }

    const formData = new FormData();
    formData.append('file', file);

    return service.inserirAnexo(idItem, tipo_anexo, formData);
}

export async function submitSubAttach(idItem: number, file: File | null) {
    const service = new AdminBiblioService();

    const formData = new FormData();

    if (file && file.size >= MAX_FILE_BYTES) {
        throw Error('Arquivo anexado muito grande (máximo 15MB)');
    }

    if (file != null) {
        formData.append('file', file);
        service.inserirSubAnexo(idItem, AdminBiblioAnexoType.CONTEUDO, formData);
    }
}

export async function fecthAttachImage(idItem: number, type: AdminBiblioAnexoType): Promise<string | null> {
    const service = new AdminBiblioService();
    const { data, status } = await service.buscarAnexoTipo(idItem, type);

    if (status == 200) {
        return 'data:image/*;base64,' + data.base64Content;
    }

    return null;
}

export async function fecthAttach(idItem: number, type: AdminBiblioAnexoType): Promise<AnexoConteudo> {
    const service = new AdminBiblioService();
    const { data, status } = await service.buscarAnexoTipo(idItem, type);
    return new AnexoConteudo().fromJSON(data);
}

export const fetchTagsItemId = async (idItem: number): Promise<string[]> => {
    const service = new AdminBiblioService();
    const { data } = await service.buscarItem(idItem);
    if (data) return data.tags.map((i: any) => i.descricao) ?? [];
    else return [];
};

export const fetchItemId = async (idItem: number): Promise<BiblioItem> => {
    const service = new AdminBiblioService();
    const { data } = await service.buscarItem(idItem);
    return new BiblioItem().fromJSON(data);
};

export function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab]);
    return bb;
}

export default submitAttach;
