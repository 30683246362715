import { NavyButton, PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const PADDING_DEFAULT = 45;

const EditProfileStyled = {
    HeadCyan: styled.div`
        width: 100%;
        height: 180px;
        background: #04b0f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 80px;
        padding-right: 60px;

        @media only screen and (max-width: ${950}px) {
            padding: 0 20px;
            height: 100px;
            justify-content: flex-start;
        }
    `,

    Title: styled.h2`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 57px;
        color: #003c78;

        @media only screen and (max-width: ${950}px) {
            font-size: 24px;
            line-height: 29px;
            margin-left: 10px;
        }
    `,

    TitleIcon: styled.h2`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 67px;
        color: #003c78;

        @media only screen and (max-width: ${950}px) {
            font-size: 24px;
            line-height: 29px;
        }
    `,

    TitleImage: styled.img`
        width: 150px;
        height: 172px;
        margin-right: 80px;
        transform: translateY(30%);

        @media only screen and (max-width: ${950}px) {
            display: none;
        }
    `,

    Divider: styled.div`
        width: 90%;
        margin: 20px auto;
        height: 2px;
        background-color: #e2e2e2;

        @media only screen and (min-width: ${MobileMaxWidth}px) {
            transform: translateX(10px);
            margin: 0px;
        }
    `,

    Container: styled.div`
        margin-top: 86px;
        background-color: white;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .mobile-only {
            display: none;
        }

        @media only screen and (max-width: 950px) {
            justify-content: center;
            margin-top: 60px;

            .mobile-only {
                display: inline-block;
            }
        }
    `,

    Content: styled.div`
        padding-top: 50px;
        flex-wrap: wrap;
        width: 760px;
        margin-left: 80px;

        @media only screen and (min-width: 0px) and (max-width: 760px) {
            margin: 0;
            padding-top: 30px;
        }
    `,

    TextAlert: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #2e2e2e;

        width: 350px;
        margin-bottom: 100px;
        margin-top: 20px;
        padding-left: 10px;

        b {
            cursor: pointer;
        }
    `,

    BigLabel: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        margin-left: 10px;

        @media only screen and (min-width: 0px) and (max-width: 760px) {
            margin-left: 15px;
        }
    `,

    SpaceVertical: styled.div<{ hidden?: boolean }>`
        height: 50px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 15px;
            display: ${props => (props.hidden ? 'none' : 'block')};
        }
    `,

    Label: styled.p`
        color: #2d3648;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    `,

    Row: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 10px;
        }
    `,

    Row2: styled.div`
        display: flex;
        flex-direction: row;

        justify-content: flex-start;
        align-items: center;
        margin-bottom: 60px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 0 15px;
            margin-bottom: 40px;
        }
    `,

    Row3: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        margin-top: 20px;
        margin-bottom: 45px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 0px;
            margin-bottom: 15px;
        }
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 10px;

        @media only screen and (max-width: 950px) {
            width: 100%;
            margin: 0;
            //padding: 0 ${PADDING_DEFAULT - 5}px;
            padding: 15px;
        }
    `,

    Input: styled.input`
        background: #ffffff;

        border: 2px solid #cbd2e0;
        border-radius: 6px;
        width: 324px;
        height: 48px;
        padding-left: 7px;

        @media only screen and (max-width: 950px) {
            width: 100%;
        }
    `,

    SansCript: styled.p`
        font-size: 0.8em;
        margin-top: 7px;
    `,

    BottomContainer: styled.div`
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        gap: 50px;

        @media only screen and (max-width: 950px) {
            padding: 0 15px;
            display: block;
            width: 100%;
        }
    `,

    CancelButton: styled(NavyButton)`
        @media only screen and (max-width: 950px) {
            width: 175px;
        }
    `,
    ActionButton: styled(PrimaryButton)`
        @media only screen and (max-width: 950px) {
            width: 175px;
        }
    `,
    Exclude: styled.p`
        color: #bd0000;
        text-decoration-line: underline;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        cursor: pointer;
        padding-left: 5px;

        @media only screen and (max-width: 950px) {
            width: 60%;
            padding: 0 7px;
            line-height: 1.25em;
        }
    `,

    ExcludeIcon: styled.img`
        transform: scale(0.8);
        cursor: pointer;
    `,

    Separator: styled.div`
        border: 1px solid;
        border-color: #cbd2e0;
        margin: 5px 0 15px 20px;

        @media screen and (max-width: 950px) {
            margin: 5px 20px 15px 20px;
        }
    `,

    PrimaryButton: styled(PrimaryButton)`
        @media screen and (max-width: 950px) {
            width: 170px;
            margin-left: 10px;
        }
    `,
};

export default EditProfileStyled;
