import styled from 'styled-components';

const BiblioCardItemStyled = {
    Container: styled.div`
        width: 100%;
        color: #003c78;
    `,

    CoverContainer: styled.div`
        width: 100%;
        height: fit-content;
        position: relative;
        cursor: pointer;
    `,

    Cover: styled.img`
        width: 100%;
        height: 250px;
        background-color: #d9d9d9;
        border-radius: 4px;
        overflow: hidden;
        object-fit: cover;
        margin-bottom: 10px;
        cursor: pointer;
    `,

    BookmarkContainer: styled.div`
        position: absolute;
        top: 0;
        right: 0;
    `,

    Chip: styled.span`
        background-color: #ffca00;
        color: #003c78;
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Barlow';
        text-transform: uppercase;
        padding: 3px 5px;
        border-radius: 10px;
    `,

    Date: styled.span`
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
    `,

    TextRow: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    Desc: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #003c78;
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
    `,
};

export default BiblioCardItemStyled;
