import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const Container = styled.div<{ imageSrc: string }>`
    position: relative;
    border-radius: 5px;
    height: 240px;
    max-width: 614px;
    overflow: hidden;
    color: ${Colors.White};
    border-radius: 13px;
    background-image: url('${props => props.imageSrc}');
    object-fit: cover;
    background-color: grey;
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    transition: 0.5s ease;

    @media only screen and (max-width: 1080px) {
        margin-bottom: 0;
    }
`;

export const Content = styled.div(
    new FullCss({
        padding: '80px 25px 25px 25px',
        position: 'relative',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(360deg, #124a88 0%, rgba(18, 74, 136, 0.26) 100%)',
    }).getCss()
);

export const OuterContainer = styled.div(new FullCss({ display: 'flex', flexDirection: 'column' }).getCss());

export const InnerContainer = styled.div(new FullCss({ display: 'flex', flexDirection: 'row' }).getCss());

export const Title = styled.a(
    new FullCss(
        {
            fontFamily: 'Barlow',
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '35px',
            padding: '10px 0',
            height: '90px',
            maxWidth: '290px',
            wordWrap: 'break-word',
            overflowX: 'hidden',
            color: Colors.White,
        },
        new MediaQuery(':hover', { color: Colors.White, cursor: 'pointer' })
    ).getCss()
);

export const Text = styled.a(
    new FullCss(
        {
            fontSize: 'clamp(13px, 1.5vw, 24px)',
            lineHeight: '29px',
            margin: '10px 0',
            height: '100%',
            fontWeight: '400',
            color: Colors.White,
            maxWidth: '400px',
            wordWrap: 'break-word',
            flexGrow: '1',
        },
        new MediaQuery(':hover', { color: Colors.White, cursor: 'pointer' })
    ).getCss()
);

export const Button = styled.button(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '101px',
            height: '40px',
            border: '2px solid #fcfcfc',
            borderRadius: '50px',
            background: Colors.White,
            color: Colors.Blue,
            fontWeight: '700',
            fontSize: '14px',
            letterSpacing: '0.3px',
            lineHeight: '14px',
        },
        new MediaQuery(':hover', { color: Colors.White, background: 'transparent' })
    ).getCss()
);

export const TextContainer = styled.div(
    new FullCss({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: '1',
    }).getCss()
);

export const InfoIcon = styled.img(
    new FullCss({
        position: 'absolute',
        top: '15px',
        left: '91%',
        width: '25px',
        height: '22px',
        cursor: 'pointer',
        zIndex: '1',
    }).getCss()
);

export default Container;
