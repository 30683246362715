import React from 'react';
import { Alert } from 'react-bootstrap';

export interface IBaloonAlert {}

const BaloonAlert = ({}: IBaloonAlert) => {
    return (
        <Alert variant="info" style={{ fontSize: '0.85em', lineHeight: '18px' }}>
            💡 Se você atua em mais de uma rede ou etapa de ensino, considere a que você normalmente trabalha o maior número de horas semanais.
        </Alert>
    );
};

export default BaloonAlert;
