import React from 'react';
import ArrowLeft from './assets/ArrowLeft.svg'
import { BackInitialPageButton, ButtonText, Img } from './BackInitialPageButton.styled';

export interface IInitialPageButton {
    goToLocation: () => void;
}

const InitialPageButton = ({ goToLocation }: IInitialPageButton) => {
    return (
        <BackInitialPageButton
            onClick={() => {
                goToLocation();
            }}
        >
            <Img src={ArrowLeft} />
            <ButtonText>Voltar a página inicial</ButtonText>
        </BackInitialPageButton>
    );
};

export default InitialPageButton;
