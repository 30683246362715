import { CSSProperties } from 'react';

import styled from 'styled-components';

import Colors from 'enums/Colors';
import { OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';

export namespace DiaryTextStyle {
    interface IContainer {}

    export const Container = styled.div<IContainer>(() => ({
        flexGrow: 1,
        height: '100%',
        padding: '20px',
        maxWidth: '100%',
    }));

    export const Saved = styled.p({
        position: 'absolute',
        fontSize: 11,
        marginTop: 50,
        lineHeight: '13px',
        fontStyle: 'italic',
        letterSpacing: 0.2,
        fontFamily: 'Barlow',
        fontWeight: 'normal',
        color: Colors.DarkGreyBlue,
    });

    export const Editor: CSSProperties = {
        height: 405,
    };

    export const Title = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #003c78;
    `;

    export const SubTitle = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8d8d8d;
        margin-top: 5px;
        text-align: right;
        width: 100%;
        text-align: left;
    `;

    export const PrintIcon = styled.span`
        font-size: 1.5em;
        color: #003c78;
        cursor: pointer;
    `;

    export const PrintContainer = styled.div`
        float: right;
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: none;
        }
    `;

    export const TextContainer = styled.div`
        margin-top: 15px;
        max-width: 100%;
    `;

    export const Text = styled.div`
        // border: 1px blue solid;
        margin-top: 15px;
        min-height: 200px;
        line-height: 1.6em;
        max-width: 100%;
    `;

    export const FootButtonContainer = styled.div`
        display: flex;
        margin-top: 15px;

        .red-mobile {
            color: #d93c3c;
            border-color: #d93c3c;

            :hover {
                background-color: #d93c3c;
                color: white;
            }
        }
    `;

    export const RichContainer = styled.div`
        background-color: white;
        max-width: 100%;
    `;

    export const OutlineButtonDiary = styled(OutlineButton)`
        border-radius: 5px;
        color: #003c78;
        border-color: #003c78;
        background-color: transparent;
    `;

    export const Row = styled.div`
        display: flex;
    `;

    export const Label = styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #003c78;
        margin: 7px 0;
    `;

    export const PrimaryButtonDiary = styled(PrimaryButton)`
        border-radius: 5px;
    `;

    export const Primary2ButtonDiary = styled(PrimaryButton)`
        border-radius: 5px;
        background-color: #003c78;
        background: #003c78;
        color: white;
    `;
}
