import { SelectOptionType } from 'components/Select';

const biblioFilterData: SelectOptionType[] = [
    {
        label: 'Selecione',
        value: 'TODOS',
    },
    {
        label: 'Mais recentes',
        value: 'MAISRECENTES',
    },
    {
        label: 'Mais populares',
        value: 'POPULARES',
    },
    {
        label: 'Melhores avaliados',
        value: 'MELHORESAVALIADOS',
    },
];

export default biblioFilterData;
