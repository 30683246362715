import 'react-quill/dist/quill.snow.css';

import Loading from 'components/Loading';
import Row from 'components/Row';
import Select from 'components/Select';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { RedSolidButton, OutlineButton, GradientButton } from 'components/UI/Buttons';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import AdminSecaoPaginaData, { AdminSecaoPaginaSimplesTexto } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import React, { useState, useEffect, useContext } from 'react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';

import { IAdminEditSection } from './AdminEditBanner';
import Styled from './AdminEditor.styled';
import { isValidLink, isStringsValid } from './isValidNewContentInput';
import editorConfig from './richtextProps';
import ActionComponent from './components/ActionComponent';
import SelectedButtons from './components/SelectedButtons';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import BackgroundComponent from './components/BackgroundComponent';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';
import { LabelObs, LabelRedirecionamento } from './components/LabelObs';

const AdminEditSimple = ({ onSaved, mode, onUpdate, onDelete }: IAdminEditSection) => {
    const params: any = useParams();
    const service = new AdminGestaoPaginaService();

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [link_interno, setLinkInterno] = useState('');
    const [estiloBotaoA, setEstiloBotaoA] = useState<ButtonsCustom>(ButtonsCustom.Button_01);
    const [corTitulo, setCorTitulo] = useState('#000000');
    const [corTexto, setCorTexto] = useState('#000000');
    const [corFundo, setCorFundo] = useState('#FFFFFF');
    const [tipoRedirecionamento, setTipoRedirecionamento] = useState<TipoRedirecionamento>(TipoRedirecionamento.LOGIN);

    const [idSecao, setIdSecao] = useState<number>(0);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState(false);
    const [loading, setloading] = useState(false);

    const { currentSection } = useContext(AdminGestaoPaginasContext.Context);

    const MAX_CHAR_CONTENT = 1000;

    const isValidInput = (): boolean => {
        return isValidLink(link_interno, !link_interno.startsWith('/')) && isStringsValid([title, text, texto_botao_a, link_interno]);
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaSimplesTexto) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onSaved(section);
            setModePage(true);
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaSimplesTexto) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSecaoSimples(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const submit = async () => {
        if (!currentSection) {
            alert('Seção não encontrada');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaSimplesTexto;

        holder.titulo = title;
        holder.textoBotaoA = texto_botao_a;
        holder.link = link_interno;
        holder.descricao = text;
        holder.estiloBotaoA = estiloBotaoA;
        holder.corTitulo = corTitulo;
        holder.corTexto = corTexto;
        holder.corFundo = corFundo;
        holder.tipoRedirecionamento = tipoRedirecionamento;

        currentSection.modelData = holder;

        if (mode == 'NEW') await saveSection(currentSection, holder);
        else await updateSection(currentSection, holder);

        setSubmitting(false);
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setloading(true);
            const id = String(params.id);
            const { data } = await service.buscarSecaoSimplesPorId(parseInt(id));
            setIdSecao(parseInt(id));
            setText(data.descricao);
            setTitle(data.titulo);
            setLinkInterno(data.link);
            setTextoBotaoA(data.textoBotaoA);
            setEstiloBotaoA(data.estiloBotaoA);
            setCorTitulo(data.corTitulo);
            setCorTexto(data.corTexto);
            setCorFundo(data.corFundo);
            setTipoRedirecionamento(data.tipoRedirecionamento);
            setloading(false);
            setModePage(true);
        }
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteSecaoSimplesSemImagem(idSecao);

            if (response.status === 200) {
                ToastSuccess('Simples sem imagem excluído com sucesso!');
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteSecao(false);
        setIsLoadingDelete(false);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <Styled.Container>
                <ModalExclusao
                    showModal={showModalDeleteSecao}
                    handleHiden={setShowModalDeleteSecao}
                    handleExcluxed={handleDelete}
                    isLoading={isLoadingDelete}
                />
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <BackgroundComponent
                            colorBackground={corFundo}
                            colorDescription={corTexto}
                            colorTitle={corTitulo}
                            setColor={setCorFundo}
                        />
                        <Styled.Label>Título da Seção</Styled.Label>
                        <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                        <ActionComponent
                            name={'titulo'}
                            valueColorText={corTitulo}
                            setColorText={setCorTitulo}
                            notVisibleOpcaoOculto
                            notVisibleSizeFonte
                        />
                        <Styled.Label>Texto da Seção</Styled.Label>

                        <ReactQuill
                            theme="snow"
                            modules={editorConfig.modules}
                            formats={editorConfig.formats}
                            value={text}
                            onChange={val => {
                                setText(val);
                            }}
                            //className={'form-control'}
                        />
                        <Styled.CountLetter danger={text.length >= MAX_CHAR_CONTENT}>
                            {text.length}/{MAX_CHAR_CONTENT}
                        </Styled.CountLetter>

                        <Styled.Label>Botão primário</Styled.Label>
                        <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />

                        <Styled.Label>Link do botão primário</Styled.Label>
                        <Styled.Input
                            className={
                                link_interno &&
                                link_interno.length > 0 &&
                                !isValidLink(link_interno, !link_interno.startsWith('/')) &&
                                'is-invalid'
                            }
                            value={link_interno}
                            onChange={(evt: any) => setLinkInterno(evt.target.value)}
                        />
                        <Styled.SubLabel>
                            <LabelRedirecionamento />
                        </Styled.SubLabel>

                        <Styled.Label>Aparência Do Botão</Styled.Label>
                        <SelectedButtons value={estiloBotaoA} setSelected={setEstiloBotaoA} />

                        <Styled.Label>Tipo de redirecionamento</Styled.Label>
                        <Select
                            isSmall
                            value={getOptionFromValue(tipoRedirecionamento)}
                            itens={tipoRedirecionamentoOptions}
                            onSelected={o => setTipoRedirecionamento(o.value as TipoRedirecionamento)}
                        />
                        <Styled.SubLabel>
                            <LabelObs />
                        </Styled.SubLabel>

                        <Row justify="space-between" style={{ alignItems: 'center', marginTop: '60px' }}>
                            <Styled.AlignRight>
                                {mode == 'NEW' ? (
                                    <GradientButton disabled={submitting || !isValidInput()} onClick={submit}>
                                        {submitting ? 'Salvando...' : 'Salvar'}
                                    </GradientButton>
                                ) : (
                                    <>
                                        {modePage && (
                                            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                <RedSolidButton style={{ marginRight: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                                    Deletar
                                                </RedSolidButton>
                                            </AllowedGroupsValidator>
                                        )}
                                        <GradientButton disabled={submitting || !isValidInput()} onClick={submit}>
                                            {submitting ? 'Atualizando...' : 'Atualizar'}
                                        </GradientButton>
                                    </>
                                )}
                            </Styled.AlignRight>
                        </Row>
                    </div>
                )}
            </Styled.Container>
        </>
    );
};

export default AdminEditSimple;
