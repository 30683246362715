import 'core-js/proposals/reflect-metadata';
import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';
import ImagemPerfil from './imagemPerfil/ImagemPerfil';
import pickerList from '../components/UI/pickList/PickListModel';
import { RoleEnum } from 'enums/RoleEnum';
import TipoDeficiencia from './perfil/TipoDeficiencia';
import TipoDeficienciaDTO from './perfil/TipoDeficienciaDTO';

class ProfileData extends Serializable {
    @jsonName('keycloak_id')
    @jsonProperty(String)
    keycloakId: string = '';

    @jsonProperty(Number, null)
    tipoFormacao: number | null = null;

    @jsonProperty(String)
    estado: string = '';

    @jsonProperty(String)
    estadoDescricao: string = '';

    @jsonProperty(Number, null)
    municipio: number | null = null;

    @jsonProperty(String)
    municipioDescricao: string = '';

    @jsonProperty(String)
    tipoPerfil: RoleEnum = RoleEnum.Unknown;

    @jsonProperty(Number, null)
    tipoEnsino: number | null = null;

    @jsonProperty(Boolean)
    termosDeConvivenciaAceitos: boolean = false;

    @jsonProperty(Number, null)
    catedraOrIea: number | null = -1;

    @jsonProperty(String)
    email: string = '';

    @jsonProperty(String)
    cpf: string = '';

    @jsonProperty(String)
    ocupacao: string = '';

    @jsonProperty(Number)
    comoConheceu: number = 0;

    @jsonProperty(Number)
    genero: number = 0;

    @jsonProperty(Number)
    cor: number = 0;

    @jsonProperty([TipoDeficienciaDTO])
    tipoDeficiencia: TipoDeficienciaDTO[] = [];

    @jsonProperty(Boolean)
    autoCadastrado: boolean = false;

    @jsonProperty(String)
    dataNascimento: string = '';

    @jsonProperty([Number])
    etapasEnsino: number[] = [];

    @jsonIgnore()
    outraOcupacao: string = '';

    @jsonProperty(String)
    username: string = '';

    // Ignorado apás s9-002
    @jsonIgnore()
    apelido: string = '';

    @jsonIgnore()
    areaFormacao: number | null = null;

    @jsonIgnore()
    outraAreaFormacao: string = '';

    @jsonIgnore()
    outroPerfilIdentificacao: string = '';

    @jsonIgnore()
    tipoGestor: number | null = null;

    @jsonIgnore()
    tipoEnsinoRedePublica: number | null = null;

    @jsonIgnore()
    categoriaEnsino: number | null = null;

    @jsonIgnore()
    listaAreasInteresse: pickerList[] | [] = [];

    @jsonIgnore()
    identificacaoCompleta: boolean = false;

    @jsonIgnore()
    atuacaoCompleta: boolean = false;

    @jsonIgnore()
    imagemPerfil: ImagemPerfil | null = null;

    constructor() {
        super();
    }
}

export default ProfileData;
