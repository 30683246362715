import { OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const BiblioNotificationPageStyled = {
    Container: styled.div`
        padding: 20px;
        padding-top: 15px;
    `,

    Title: styled.div`
        font-size: 36px;
        line-height: 26px;
        letter-spacing: 0.1625px;
        color: #00a8e9;
        font-weight: 600;
    `,

    Subtitle: styled.p`
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #5f758b;
        transform: translateY(-5px);
    `,

    Goback: styled.p`
        font-weight: 500;
        margin-bottom: 20px;
        opacity: 0.8;
        cursor: pointer;

        :hover {
            span {
                text-decoration: underline;
            }
        }

        span {
            margin-left: 5px;
        }
    `,

    TopContainer: styled.div`
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    ContentGrid: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 100px;
        padding: 5px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: block;
        }
    `,

    ScrollContainer: styled.div`
        border: 1px rgba(181, 181, 181, 0.3) solid;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        padding-top: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 20px;
        }
    `,

    ScrollTop: styled.div`
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    `,

    ScrollContent: styled.div`
        max-height: 550px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    `,

    Br: styled.div`
        height: 30px;
    `,

    CheckBoxContainer: styled.div`
        display: flex;
    `,

    Checkbox: styled(Form.Check)`
        cursor: pointer;
        transform: scale(1.2);
    `,

    CheckboxLabel: styled.label`
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
        cursor: pointer;
        user-select: none;
    `,

    ButtonActionContainer: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 20px;
        }
    `,

    ButtonAction: styled.button<{ active: boolean }>`
        border: 1px solid ${props => (props.active ? '#00A8E9' : '#b5b5b5')};
        color: ${props => (props.active ? '#00A8E9' : '#b5b5b5')};
        background-color: white;
        height: 32px;
        border-radius: 10px;
        margin-left: 10px;
        font-weight: 600;
        font-size: 14px;

        cursor: ${props => (props.active ? 'pointer' : 'default')};

        :hover {
            background-color: ${props => (props.active ? '#f6f6f6' : 'none')};
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-left: 0;
            margin-right: 10px;
        }
    `,

    Icon: styled.img`
        height: 15px;
        margin-right: 7px;
    `,

    Modal: styled(Modal)``,

    ModalContainer: styled.div`
        text-align: center;
        padding: 20px;
    `,

    ModalText: styled.p`
        font-weight: 600;
        font-size: 22px;
        line-height: 29px;
        color: #2e2e2e;
        margin: 25px 0;
    `,

    ModalText2: styled.p`
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #2e2e2e;
        margin: 25px 0;
    `,

    ModalCenter: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    `,

    ModalIcon: styled.img`
        height: 60px;
        width: auto;
    `,

    ModalIcon2: styled.img`
        height: 30px;
        width: auto;
        float: right;
        cursor: pointer;
    `,

    ModalBottomContainer: styled.div`
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 20px;
    `,
};

export default BiblioNotificationPageStyled;
