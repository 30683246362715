import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import ModalSizeImage from './ModalSizeImage';
import ProfileData from '../../../../../models/ProfileData';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import { Container, ImageButton, Preview } from './ImageSelect.styled';
import { storeProfileData } from '../../../../../store/actions/Profile';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';
import ImagemPerfil from '../../../../../models/imagemPerfil/ImagemPerfil';

import srcImageDefault from '../../../../../images/profile/identificacao_im.svg';

interface IImagePreview {
    onSelected: any;
}

const ImagePreview = ({ onSelected }: IImagePreview) => {
    const [showImageSizedModal, setShowImageSizedModal] = useState(false);

    const dispatch = useDispatch();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    const clearImagemperfil = () => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.NoSelected,
                imageBase64: null,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    useEffect(() => {
        //Limpar os estados de Imagen do Profile
        clearImagemperfil();
    }, []);

    const addImage = () => {
        const elInput = document.getElementById('file-input');

        if (elInput) {
            elInput.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.files) {
                    const file = target.files[0];

                    if (!file) return;

                    const sizeInMB = file.size / 1024 / 1024;

                    if (sizeInMB > 2.0) {
                        setShowImageSizedModal(true);
                    } else {
                        const reader = new FileReader();
                        reader.onloadend = (ev: any) => {
                            const base64 = ev.target.result;
                            setBase64ToImage(base64);
                            onSelected();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            };
        }

        elInput?.click();
    };

    const setBase64ToImage = (encoded: string) => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.Imagem,
                imageBase64: encoded,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    return (
        <>
            <input type="file" id="file-input" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} />
            <Container>
                <Preview src={srcImageDefault} onClick={addImage} />
                <ImageButton onClick={addImage}>Adicionar Imagem</ImageButton>
            </Container>

            {/* -- Modal de alerta caso a imagem exceda 2MB -- */}
            <ModalSizeImage
                show={showImageSizedModal}
                onCloseCallback={() => {
                    setShowImageSizedModal(false);
                }}
            />
        </>
    );
};

export default ImagePreview;
