import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleCardCategoria = {
    Container: styled.div`
    `,

    Button: styled.button`
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 0.2rem;
        background-color: ${Colors.LightGreen};
        color: ${Colors.White};
        border-radius: 10px;
        padding: 7px;
        border: none;
        transition: 0.2s all;
        margin-bottom: 10px;

        &:hover {
            box-shadow: 2px 2px 6px ${Colors.Grey3};
        }
    `,
};

export default StyleCardCategoria;
