import { jsonProperty, Serializable } from 'ts-serializable';
import { CardsSecaoCardCategoria } from './CardsSecaoCardCategoria';

export class SecaoCardCategoria extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty([CardsSecaoCardCategoria], [])
    cards: [CardsSecaoCardCategoria] | [] = [];

    @jsonProperty(String)
    usuarioResponsavel: string = '';
}

export default SecaoCardCategoria;
