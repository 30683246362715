import React, { useEffect, useState } from 'react';
import { Preferences } from 'constants/Preferences';
import { PreferenciasService } from 'core/http/service/Preferencias.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PrefenciaUsuario from 'models/PreferenciaUsuario';
import { PainelSocioContext } from './PainelSocioContext';

export interface IPainelSocioProvider {}

export const cacheShowSliderDialogKey = 'USERPREF:PAINELSOCIO_SHOWSLIDERDIALOG';

const PainelSocioProvider = ({ children }: React.PropsWithChildren<IPainelSocioProvider>) => {
    const [showAlertDialog, _setShowAlertDialog] = useState<boolean>(false);
    const [showInfoDialog, _setShowInfoDialog] = useState<boolean>(false);
    const [showSliderDialog, _setShowSliderDialog] = useState<boolean>(false);
    const [currentIndexSlide, setCurrentIndexSlide] = useState<number>(0);
    const [currentIndexSlideAutoavaliacao, setcurrentIndexSlideAutoavaliacao] = useState<number>(0);
    const [firstCloseBanner, setFirstCloseBanner] = useState<boolean>(false);
    const [showBannerInfo, _setShowBannerInfo] = useState<boolean>(true);
    const [isExpandedBanner, setExpandedBanner] = useState<boolean>(true);

    const profile = useAppSelector(state => state.profileReducer.userProfile);

    const setShowAlertDialog = () => {
        _setShowAlertDialog(true);
    };

    const setShowInfoDialog = () => {
        _setShowInfoDialog(true);
    };

    const setShowSliderDialog = () => {
        setCurrentIndexSlide(0);
        _setShowSliderDialog(true);
        sessionStorage.setItem(cacheShowSliderDialogKey, String(true));
    };

    const nextSlide = () => {
        setCurrentIndexSlide(currentIndexSlide + 1);
    };

    const prevSlide = () => {
        if (currentIndexSlide > 0) setCurrentIndexSlide(currentIndexSlide - 1);
    };

    const updateCurrentIndexSlide = (current: number) => {
        setCurrentIndexSlide(current);
    };

    const nextSlideAutoavaliacao = () => {
        setcurrentIndexSlideAutoavaliacao(+1);
    };

    const prevSlideAutoavaliacao = () => {
        if (currentIndexSlideAutoavaliacao > 0) setcurrentIndexSlideAutoavaliacao(currentIndexSlideAutoavaliacao - 1);
    };

    const updateCurrentIndexSlideAutoavaliacao = (current: number) => {
        setcurrentIndexSlideAutoavaliacao(current);
    };

    const hideAllDialogs = () => {
        _setShowAlertDialog(false);
        _setShowInfoDialog(false);
        _setShowSliderDialog(false);
        setFirstCloseBanner(false);
        sessionStorage.setItem(cacheShowSliderDialogKey, String(false));
    };

    const setShowBannerInfo = (value: boolean) => {
        _setShowBannerInfo(value);

        /* if (!value) {
            _setShowSliderDialog(false);
            sessionStorage.setItem(cacheShowSliderDialogKey, showSliderDialog.toString());
        } */
    };

    const buscarPreferencia = async () => {
        const service = new PreferenciasService();

        if (profile) {
            const prefChaveSbm = Preferences.SaibaMaisExpanded.chave;
            const { data } = await service.getPreferencia(prefChaveSbm, profile.codigo);
            const cacheShowSlideDialog = sessionStorage.getItem(cacheShowSliderDialogKey);

            if (data.valor) {
                const expanded = data.valor === Preferences.SaibaMaisExpanded.valor.expandir;
                if ((expanded && cacheShowSlideDialog == 'true') || (expanded && cacheShowSlideDialog == null)) setShowSliderDialog();

                setExpandedBanner(expanded);
            } else {
                setShowSliderDialog();
            }
        }
    };

    const context: PainelSocioContext.IContext = {
        showAlertDialog,
        setShowAlertDialog,

        showInfoDialog,
        setShowInfoDialog,

        showSliderDialog,
        setShowSliderDialog,

        currentIndexSlide,
        updateCurrentIndexSlide,
        prevSlide,
        nextSlide,

        currentIndexSlideAutoavaliacao,
        updateCurrentIndexSlideAutoavaliacao,
        prevSlideAutoavaliacao,
        nextSlideAutoavaliacao,

        hideAllDialogs,

        isExpandedBanner,
        setExpandedBanner,

        firstCloseBanner,

        showBannerInfo,
        setShowBannerInfo,
    };

    useEffect(() => {
        /*  const cacheShowSlideDialog = sessionStorage.getItem(cacheShowSliderDialogKey);
        if (cacheShowSlideDialog) setShowSliderDialog();
 */
        buscarPreferencia();
    }, []);

    return <PainelSocioContext.Context.Provider value={context}>{children}</PainelSocioContext.Context.Provider>;
};

export default PainelSocioProvider;
