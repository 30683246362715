import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Route, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import { KeycloakLoginOptions } from 'keycloak-js';
import Loading from 'components/Loading';
import Unauthorized from 'pages/unauthorized/Unauthorized';
import AccessPermissionProvider from 'core/context/AccessPermissionProvider';

interface IPrivateRoute extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    roles?: string[];
}

const PrivateRoute: FunctionComponent<IPrivateRoute> = ({ component: Component, path, roles, ...props }: IPrivateRoute) => {
    const { keycloak } = useKeycloak();
    const location: any = useLocation();
    const options: KeycloakLoginOptions = {
        redirectUri:
            location && location.state && location.state.pathToRedirect
                ? window.location.origin + '/login?' + location.state.pathToRedirect
                : window.location.origin + '/login?' + path,
    };

    const login = useCallback(async () => {
        await keycloak?.login(options);
    }, [keycloak]);

    useEffect(() => {
        if (!keycloak?.authenticated) {
            login();
        }
    }, []);

    const hasAnyRole = (): boolean => {
        let retorno: boolean = false;
        roles?.forEach(role => {
            if (keycloak.hasRealmRole(role)) {
                retorno = true;
            }
        });
        return retorno;
    };

    const validateAccess = (): boolean => {
        let retorno: boolean = false;

        if (!roles?.length || hasAnyRole()) {
            retorno = true;
        }

        return retorno;
    };

    if (!keycloak?.authenticated) {
        return <Loading />;
    } else if (keycloak.authenticated && !validateAccess()) {
        return <Unauthorized />;
    }

    return (
        <AccessPermissionProvider>
            <Route {...props} render={props => <Component {...props} />} />
        </AccessPermissionProvider>
    );
};

export default PrivateRoute;
