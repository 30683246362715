import React from "react";
import { IsMobileMaxWidth } from "../../../../core/util/WindowSize";
import { ContinuarButtonStyle } from "./ContinuarButton.styled";

interface IContinuarButton {
  onClickHandler: any;
  label: string;
  isDisabled: boolean;
}
const ContinuarButton = ({
  onClickHandler,
  label,
  isDisabled,
}: IContinuarButton) => {
  return (
    <ContinuarButtonStyle
      type="submit"
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      {label}
    </ContinuarButtonStyle>
  );
};

export default ContinuarButton;
