import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Colors from '../../../../../enums/Colors';
import getAvatarFromId from '../utils/getAvatarFromId';
import ProfileData from '../../../../../models/ProfileData';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';
import { ConfirmButton, Container, Preview } from '../components/ImageSelect.styled';

const ChangeButton = styled(ConfirmButton)`
    border: 1px solid ${Colors.SoftBlue};
    color: ${Colors.SoftBlue};
    background-color: white;

    &:hover {
        color: white;
        background-color: ${Colors.SoftBlue};
    }
`;

const ImagePreview = () => {
    const history = useHistory();
    const [content, setContent] = useState('');
    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    useEffect(() => {
        if (profileData) {
            const { imagemPerfil } = profileData;

            if (imagemPerfil != null) {
                if (imagemPerfil.tipoImagem === TipoImagem.Avatar) {
                    const avatarId = imagemPerfil.codigoAvatar;
                    if (avatarId && avatarId > 0) {
                        const _content = getAvatarFromId(avatarId);
                        setContent(_content);
                    }
                } else if (imagemPerfil.tipoImagem === TipoImagem.Imagem) {
                    const encodedBase64 = imagemPerfil.imageBase64;
                    if (encodedBase64) {
                        setContent(encodedBase64);
                    }
                }
            }
        }
    }, []);

    const trocarImagem = () => {
        history.push('/profile/identificacaoImagem');
    };

    return (
        <Container>
            <Preview src={content} style={{ border: 'none', cursor: 'default' }} />
            <ChangeButton disabled={false} onClick={trocarImagem}>
                Trocar Imagem
            </ChangeButton>
        </Container>
    );
};

export default ImagePreview;
