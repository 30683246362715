import React from 'react';
import Styled from './AdminTagSelector.styled';

import closeIcon from '../../../../images/close-circle.svg';

export interface IAdminTagChip {
    tag: string;
    onRemove: () => void;
}

const AdminTagChip = ({ tag, onRemove }: IAdminTagChip) => {
    return (
        <Styled.ContainerChip>
            <span>{tag}</span>
            <Styled.CloseButton src={closeIcon} onClick={onRemove} />
        </Styled.ContainerChip>
    );
};

export default AdminTagChip;
