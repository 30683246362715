import RegraPE from 'models/acessoExclusivo/RegraPE';
import { GenericService } from '../Generic.service';

export class RegrasPEService extends GenericService {
    readonly URI: string = '/v1/regraPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public getAll() {
        return this.get('');
    }

    public inserir(regras: RegraPE[]) {
        return this.post('', regras);
    }

    public deleteAll(codigo: number) {
        return this.deleteByParams(`${codigo}`);
    }
}
