import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { Serializable, jsonProperty } from 'ts-serializable';

export enum StatusCardModulo {
    EMBREVE = 'EMBREVE',
    DISPONIVEL = 'DISPONIVEL',
}

class CardModulos extends Serializable {
    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(Number)
    status: StatusCardModulo = StatusCardModulo.EMBREVE;

    @jsonProperty(Number)
    ordem: number = 0;

    @jsonProperty(String)
    time: string = '';

    @jsonProperty(String)
    titleTemp?: string = '';

    @jsonProperty(Boolean)
    isCertificated: boolean = false;

    @jsonProperty(Boolean)
    ordemOculto: boolean = false;

    @jsonProperty(String)
    link: string = '';

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.IGNORAR

    constructor() {
        super();
    }
}

export default CardModulos;
