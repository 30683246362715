import { useAppSelector } from 'hooks/LocalReduxThunk';
import React, { useEffect, useState, useCallback } from 'react';
import * as pdpActions from '../../../store/actions/Pdp';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ProfileContext } from './ProfileContext';

export interface IProfileProvider {}

const ProfileProvider = ({ children }: React.PropsWithChildren<IProfileProvider>) => {
    const [postProfilePath, setPostProfilePath] = useState<string>('');

    const _setPostProfilePath = (value: string) => {
        setPostProfilePath(value);
    };

    const context: ProfileContext.IContext = {
        postProfilePath,
        _setPostProfilePath,
    };

    return <ProfileContext.Context.Provider value={context}>{children}</ProfileContext.Context.Provider>;
};
export default ProfileProvider;
