import { jsonProperty, Serializable } from "ts-serializable";
import ComponentQuizAlternativasModel from "./ComponentQuizAlternativas";


class ComponentQuizModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    pergunta: string = '';

    @jsonProperty(String)
    feedback: string = '';

    @jsonProperty(Boolean)
    multiplos: boolean = false;

    @jsonProperty(String)
    filePath: string = '';

    @jsonProperty(String)
    anexoBase64: string = '';

    alternativas: ComponentQuizAlternativasModel[] | null = [];

   

}


export default ComponentQuizModel;