import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { OutlineButton } from '../../../../components/UI/Buttons';
import { mediaOnlyScreenMaxWidth } from '../../../../core/util/cssHelp';

export const RESIZE_MEDIUM_WIDTH: number = 1200;

interface IModalStyled {
    width?: Number;
}
export const ModalStyled = styled(Modal)<IModalStyled>`
    padding-left: unset;

    .dialogWidth {
        min-width: ${props => (props.width ? props.width + 'px' : '1166px')};
        border-radius: 10px;
    }

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        .dialogWidth {
            min-width: 800px;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .dialogWidth {
            min-width: 316px;
        }
    }
`;

export const ModalBodyStyed = styled(Modal.Body)`
    padding: 0;
    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        padding: 21px;
    }
`;

export const DialogInicioStyle = styled.div``;

interface IContainer {
    columnReverse: boolean;
}
export const Container = styled.section<IContainer>`
    width: 100%;
    height: 100%;
    display: flex;

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        flex-direction: ${props => (props.columnReverse ? 'column-reverse' : 'column')};
        justify-content: center;
        align-items: center;
    }
`;

interface IImageSection {
    svgSource: any;
    height?: number;
}
export const ImageSection = styled.section<IImageSection>`
    background: url('${props => props.svgSource}');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 40%;
    height: ${props => (props.height ? props.height + 'px' : '956px')};
    border-radius: 10px 0px 0px 10px;

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        width: 284px;
        height: 177px;
        margin-top: 40px;
        margin-bottom: 60px;
        border-radius: 10px 10px 10px 10px;
    }
`;

export const ContentSection = styled.section`
    width: 60%;
    height: 100%;
    padding: 88px 115px 0 88px;

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        padding: 0;
        width: 100%;
        height: unset;
    }
`;

export const ContentTitle = styled.p`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: ${Colors.Blue};
    margin-bottom: 44px;

    @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
        margin-top: 50px;
        margin-bottom: 37px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
`;

export const Content = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
`;

export const SvgIcon = styled.img(
    mediaOnlyScreenMaxWidth(
        {},
        {
            width: '35px',
            height: '33px',
        },
        RESIZE_MEDIUM_WIDTH
    )
);

export const SvgIconContainer = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            position: 'absolute',
            right: '51.5%',
            top: '88px',
            width: '151px',
            height: '147px',
            background: Colors.White,
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 50px 80px -20px rgba(0, 60, 120, 0.15)',
        },
        {
            width: '69px',
            height: '67px',
            right: '41.5%',
            top: '205px',
        },
        RESIZE_MEDIUM_WIDTH
    )
);

export const Footer = styled.div`
    margin-top: 27px;
    display: flex;
    flex-direction: column;
`;

export const ButtonsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
    }
`;

export const OutlineButtonStyled = styled(OutlineButton)`
    margin-left: 16px;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: unset;
        margin-top: 10px;
    }
`;

export const ExpandableContainer = styled.div`
    margin: 25px 0;
    margin-bottom: 30px;
`;
