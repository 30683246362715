import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { ContatoLancamentoService } from 'core/http/service/ContatoLancamento.service';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import PrivacyPolicy from 'pages/home/components/contact/PrivacyPolicy';
import ModalTerms from 'pages/profile/components/regrasConvivencia/ModalTerms';
import Termos from 'pages/profile/components/regrasConvivencia/Termos';
import React, { useState } from 'react';
import styled from 'styled-components';

export interface ICadastrarEmail {
    lancamento: PaginasIntermediariasEnum;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }
`;

const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #fcfcfc;
    margin-bottom: 15px;
`;

const PolicyText = styled(Text)`
    font-weight: 400;
    letter-spacing: 0.3px;
    font-style: italic;
    font-size: clamp(10px, 3vw, 14px);
    line-height: 17px;
    color: #cbd2e0;
    margin-left: 30%;
    width: 70%;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        margin-left: 0;
    }
`;

const Link = styled.a`
    :hover {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    margin-bottom: 35px;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const Input = styled.input`
    padding-left: 25px;
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #a3a3a3;

    height: 59px;
    border: none;
    background: #fcfcfc;
    border-bottom-left-radius: 67px;
    border-top-left-radius: 67px;
    width: 80%;

    &:focus-visible {
        outline: none;
    }

    ::placeholder {
        padding-left: 25px;
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        color: #a3a3a3;

        @media only screen and (max-width: 1200px) {
            font-size: 14px;
            padding-left: 10px;
            position: absolute;
            top: 25%;
        }
    }

    @media only screen and (max-width: 1200px) {
        border-radius: 67px;
        width: 100%;
        height: 38px;
    }
`;

const Submit = styled.input`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(14px, 3vw, 20px);
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #003c78;

    border: none;
    background: #ffca00;
    margin-left: -25px;
    border-radius: 68px;
    width: 30%;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        height: 59px;
        margin-left: 0;
        height: 38px;
    }

    &:hover {
        scale: 1.01;
    }
`;

const CadastrarEmail = ({ lancamento }: ICadastrarEmail) => {
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
    const [email, setEmail] = useState('');
    const service = new ContatoLancamentoService();

    const register = async () => {
        const re = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

        if (!re.test(email)) {
            ToastError('Email inválido');
            return;
        }

        const { status } = await service.registrarContato(lancamento, email, '');

        if (status == 200) {
            GTM_Page_Intermediaria('contato_intermediaria_updated', `${lancamento.toLowerCase()}_registrar_btn`);
            ToastSuccess('Registro enviado');
            setEmail('');
        } else {
            ToastError('Falha ao enviar');
        }
    };
    return (
        <Container>
            <Main>
                <Text>E-mail:*</Text>
                <InputContainer>
                    <Input
                        className="no-outline"
                        value={email}
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Escreva seu e-mail aqui ..."
                    ></Input>
                    <Submit value="Enviar" type="submit" onClick={() => register()}></Submit>
                </InputContainer>
                <PolicyText>
                    Ao continuar, a Plataforma Humane tratará seus dados em conformidade com a Lei Geral de Proteção de Dados Pessoais, com o{' '}
                    <Link
                        onClick={() => {
                            GTM_Page_Intermediaria('contato_intermediaria_updated', `${lancamento.toLowerCase()}_termos_uso_btn`);
                            setShowTermsModal(true);
                        }}
                    >
                        Termo de Uso
                    </Link>{' '}
                    e a{' '}
                    <Link
                        onClick={() => {
                            GTM_Page_Intermediaria('contato_intermediaria_updated', `${lancamento.toLowerCase()}_politica_privacidade_btn`);
                            setShowPrivacyPolicyModal(true);
                        }}
                    >
                        Política de Privacidade
                    </Link>{' '}
                    do Instituto Ayrton Senna para atender a sua solicitação.
                </PolicyText>

                <ModalTerms show={showTermsModal} setShow={setShowTermsModal}>
                    <Termos
                        agree={() => {
                            setShowTermsModal(false);
                        }}
                        decline={() => {
                            setShowTermsModal(false);
                        }}
                    />
                </ModalTerms>

                <ModalTerms show={showPrivacyPolicyModal} setShow={setShowPrivacyPolicyModal}>
                    <PrivacyPolicy />
                </ModalTerms>
            </Main>
        </Container>
    );
};

export default CadastrarEmail;
