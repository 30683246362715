import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import Step from './Step';
import StepMobile from './StepMobile';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import { setCurrentFormStep } from '../../../../store/actions/Pdp';
import { Space, StepsContainer, ContentContainer, Container, StepsContainerMobile, ScrollContainer, BridgeMobile } from './Steps.styled';

export type StepType = {
    index: number;
    title: string;
    subtitle: string;
    status?: StepStatus;
    component: any;
};

export enum StepStatus {
    Waiting,
    Current,
    Done,
}

export type Orientation = 'horizontal' | 'vertical';

export interface ISteps {
    stepsInit: StepType[];
    orientation: Orientation;
}

const Steps = ({ stepsInit, orientation }: ISteps) => {
    const current: number = useAppSelector(state => state.pdpReducer.currentStepForm);

    const [steps, setSteps] = useState(stepsInit);
    const isMobile = orientation === 'horizontal';
    const idScroll = 'steps-scroll-container';

    const dispatch = useDispatch();

    useEffect(() => {
        updateStaus();
        scrollToStep(current - 1);
    }, [current]);

    const updateStaus = () => {
        const stepsAux = [...steps];
        for (let i = 0; i < stepsAux.length; i++) {
            if (stepsAux[i].index === current) stepsAux[i].status = StepStatus.Current;
            else if (stepsAux[i].index < current) stepsAux[i].status = StepStatus.Done;
            else stepsAux[i].status = StepStatus.Waiting;
        }
        setSteps(stepsAux);
    };

    const scrollToStep = (unit: number) => {
        const el = document.getElementById(idScroll);

        if (el) {
            const stepWidth = 150; //px
            el.scrollTo(stepWidth * unit, 0);
        }
    };

    const gotoToStep = (index: number) => {
        if (index <= current && current > 2) {
            dispatch(setCurrentFormStep(index));
        }
    };

    return (
        <Container orientation={orientation}>
            {!isMobile ? (
                <StepsContainer>
                    {steps.map((step: StepType, idx) => {
                        return (
                            <Step
                                step={step}
                                isLast={idx === steps.length - 1}
                                key={`container_step_normal_${idx}`}
                                oncliked={() => gotoToStep(step.index)}
                            />
                        );
                    })}
                </StepsContainer>
            ) : (
                <>
                    <ScrollContainer id={idScroll}>
                        <BridgeMobile length={steps.length} />
                        <StepsContainerMobile>
                            {steps.map((step: StepType, idx) => {
                                return (
                                    <StepMobile
                                        step={step}
                                        isLast={idx === steps.length - 1}
                                        key={`container_step_mobile_${idx}`}
                                        oncliked={() => gotoToStep(step.index)}
                                    />
                                );
                            })}
                            <Space />
                        </StepsContainerMobile>
                    </ScrollContainer>
                </>
            )}

            <ContentContainer>
                {steps.map((step: StepType) => {
                    if (step.status === StepStatus.Current) {
                        return <div key={step.index}> {step.component} </div>;
                    }
                    return null;
                })}
            </ContentContainer>
        </Container>
    );
};

export default Steps;
