import PerfilTipoDeficienciaDTO from 'models/perfil/PerfilTipoDeficienciaDTO';
import { GenericService } from './Generic.service';

export class PerfilDeficienciaService extends GenericService {
    readonly URI: string = '/v1/perfildeficiencia';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public insert(data: PerfilTipoDeficienciaDTO) {
        return this.post('', data);
    }
}
