import React, { useEffect, useState } from 'react';
import { PainelSocioContext } from '../../context/PainelSocioContext';
import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Styled from './DialogSlider.styled';
import Slider from './Slider';
import sliderData from './SliderData';
import SliderIndicator from './SliderIndicator';
import closeIcon from '../../../../../images/close.svg';
import SliderAutoavaliacao from '../../../../autoavaliacao/components/Slider/SliderAutoavaliacao';
import SliderAutoavaliacaoData from '../../../../autoavaliacao/components/Slider/SliderAutoavaliacaoData';
import { OutlineButton } from 'components/UI/Buttons';
import { size } from 'lodash';
import { ModalSEEnum } from 'enums/ModalSEEnum';

export interface IDialogSlider {
    data: ModalSEEnum;
    show: boolean;
    onClose: () => void;
}

const DialogSlider = ({ show, onClose, data }: IDialogSlider) => {
    const { nextSlide, currentIndexSlide, nextSlideAutoavaliacao, currentIndexSlideAutoavaliacao, hideAllDialogs } = useContext(
        PainelSocioContext.Context
    );
    const [isLast, setIsLast] = useState(false);
    const [isLastAutoavaliacao, setIsLastAutoavaliacao] = useState(false);

    useEffect(() => {
        const _isLast = currentIndexSlide == sliderData.length;
        setIsLast(_isLast);

        const _isLastAutoavaliacao = currentIndexSlideAutoavaliacao == SliderAutoavaliacaoData.length - 1;
        setIsLastAutoavaliacao(_isLastAutoavaliacao);
    }, [currentIndexSlide, currentIndexSlideAutoavaliacao]);

    return (
        <>
            <Modal show={show} centered size={data === ModalSEEnum.HOME ? 'lg' : undefined}>
                <Modal.Body>
                    <Styled.Container>
                        <Styled.CloseContainer>
                            <Styled.CloseModal src={closeIcon} onClick={onClose} />
                        </Styled.CloseContainer>

                        {data === ModalSEEnum.HOME ? (
                            <React.Fragment>
                                <Slider />
                                <SliderIndicator current={currentIndexSlide} length={sliderData.length + 1} />
                                <Styled.ButtonContainer>
                                    {isLast ? (
                                        <Styled.Button onClick={hideAllDialogs}>Ir para o Painel</Styled.Button>
                                    ) : (
                                        <Styled.Button onClick={nextSlide}>Próximo</Styled.Button>
                                    )}
                                </Styled.ButtonContainer>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <SliderAutoavaliacao />
                                <SliderIndicator current={currentIndexSlideAutoavaliacao} length={SliderAutoavaliacaoData.length} />
                                <Styled.ButtonContainerAuto>
                                    {isLastAutoavaliacao ? (
                                        <React.Fragment>
                                            <OutlineButton className="btnAuto" onClick={onClose}>Fechar</OutlineButton>
                                            <Styled.Button className="btnAuto" onClick={onClose}>Entendi</Styled.Button>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <OutlineButton className="btnAuto" onClick={onClose}>
                                                Fechar
                                            </OutlineButton>
                                            <Styled.Button className="btnAuto" onClick={nextSlideAutoavaliacao}>
                                                Instruçôes
                                            </Styled.Button>
                                        </React.Fragment>
                                    )}
                                </Styled.ButtonContainerAuto>
                            </React.Fragment>
                        )}
                    </Styled.Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DialogSlider;
