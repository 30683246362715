import styled from 'styled-components';

import { Orientation } from './Steps';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const Container = styled.div<{ orientation: Orientation }>`
    display: flex;
    flex-direction: ${props => (props.orientation === 'vertical' ? 'row' : 'column')};
`;

export const StepsContainer = styled.div`
    width: 300px;
    padding-top: 30px;
`;

export const StepsContainerMobile = styled.div`
    width: 100%;
    display: flex;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        max-width: 100vw;
    }
`;

export const ScrollContainer = styled.div`
    /* border: 1px yellowgreen solid; */
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;

    padding-left: calc(50vw - 74px);

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        scroll-behavior: smooth;
        margin-top: 15px;

        position: relative;
        z-index: 0;
    }
`;

export const Space = styled.div`
    min-width: calc(50vw - 74px);
    height: 10px;
`;

export const ContentContainer = styled.div`
    flex: 1;
    border-left: 1px solid ${Colors.Grey8};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        border-left: none;
        max-width: 100vw;
        overflow-x: hidden;
    }
`;

export const NextContainer = styled.div`
    text-align: left;
    padding: 20px;
    padding-left: 45px;
`;

export const BridgeMobile = styled.div<{ length: number }>`
    width: calc(150px * ${props => props.length} - (1.5 * 80px));
    height: 1px;
    background-color: ${Colors.Grey8};
    transform: translate(60px, 23px);
`;
