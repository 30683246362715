import styled from 'styled-components';

const StyledApresentation = {
    Container: styled.div`
        display: flex;
        min-height: 450px;
        padding-left: 70px;

        @media screen and (max-width: 720px) {
            padding-left: 20px;
        }
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;
        padding-top: 100px;

        @media screen and (max-width: 720px) {
            max-width: 100%;
            width: 100%;
        }

        @media screen and (max-width: 480px) {
            padding-top: 50px;
        }
    `,

    Img: styled.img<{ isMobile: boolean }>`
        min-height: 100%;
        width: 50%;
        object-fit: cover;
        display: ${({ isMobile }) => (isMobile ? 'none' : 'inline')};
    `,
};

export default StyledApresentation;
