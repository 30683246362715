import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminAvaliacao extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('funcionalidade')
    @jsonProperty(String)
    feature: string = '';

    @jsonName('comentario')
    @jsonProperty(String)
    comment: string = '';

    @jsonName('nota')
    @jsonProperty(Number)
    rate: number = 0;

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    ultimaAtualizacao: string = '';
}

export default AdminAvaliacao;
