import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Styled from './BiblioNotificationPage.styled';

import BiblioNotificationItemMark from './BiblioNotificationItemMark';
import BiblioNotification, { StatusNotificationType } from 'models/biblioteca/BiblioNotification';

import trashIcon from '../../../../images/biblioteca/trash.svg';
import trashIconOn from '../../../../images/biblioteca/trash-on.svg';

import mailIcon from '../../../../images/biblioteca/mail-icon.svg';
import mailIconOn from '../../../../images/biblioteca/mail-icon-on.svg';

import BiblioNotificationExcludeModal from './BiblioNotificationExcludeModal';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import BiblioNotificationModalShow from './BiblioNotificationModalShow';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import { IoArrowBackOutline, IoChevronBackOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

export const orderNotification = (lista: BiblioNotification[]): BiblioNotification[] => {
    return lista.sort((m1: BiblioNotification, m2: BiblioNotification) => {
        if (m1.dateConvert && m2.dateConvert) return m1.dateConvert > m2.dateConvert ? -1 : 1;
        else return -1;
    });
};

export interface IBiblioNotificationPage {}

export const sanitizeNotificationContent = (content: string): string => {
    return content
        .replace(/<span.+?>/g, '')
        .replace('<p>', '')
        .replace('</p>', '')
        .replace('</span>', '')
        .replace(/<h\d>/g, '')
        .replace(/<\/h\d>/g, '');
};

const BiblioNotificationPage = ({}: IBiblioNotificationPage) => {
    const history = useHistory();
    const [notifications, setNotifications] = useState<BiblioNotification[]>([]);
    const [selectedAllUnread, setSelectedAllUnread] = useState<boolean>(false);
    const [selectedAllRead, setSelectedAllRead] = useState<boolean>(false);

    const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
    const [idModalExib, setIdModalExib] = useState<number>(-1);

    const toggleNotification = (id: number) => {
        const notificationsClone = cloneDeep(notifications);
        const notification = notificationsClone.find(n => n.id === id);

        if (notification) {
            notification.marked = !notification.marked;
            setNotifications(notificationsClone);
        }
    };

    const toggleAllUnread = () => {
        const notificationsClone = cloneDeep(notifications);

        const toggledValue = !selectedAllUnread;

        for (let i = 0; i < notificationsClone.length; i++) {
            const notif = notificationsClone[i];

            if (!notif.viewed) notif.marked = toggledValue;
            else notif.marked = false;
        }

        setSelectedAllRead(false);
        setSelectedAllUnread(toggledValue);
        setNotifications(notificationsClone);
    };

    const selectAllRead = () => {
        const notificationsClone = cloneDeep(notifications);

        const toggledValue = !selectedAllRead;

        for (let i = 0; i < notificationsClone.length; i++) {
            const notif = notificationsClone[i];

            if (notif.viewed) notif.marked = toggledValue;
            else notif.marked = false;
        }

        setSelectedAllUnread(false);
        setSelectedAllRead(toggledValue);
        setNotifications(notificationsClone);
    };

    const toExclude = () => {
        if (isSomeSelected()) setShowModalExclude(true);
    };

    const fetchNotifications = async () => {
        const service = new BiblioNotificacaoService();

        let response = await service.buscarNotificacoes('LIDA');
        let reads: BiblioNotification[] = response.data.map((n: any) => new BiblioNotification().fromJSON(n));

        response = await service.buscarNotificacoes('NAO_LIDA');
        let unreads: BiblioNotification[] = response.data.map((n: any) => new BiblioNotification().fromJSON(n));

        for (let i = 0; i < reads.length; i++) reads[i].dateConvert = new Date(reads[i].date);
        for (let i = 0; i < unreads.length; i++) unreads[i].dateConvert = new Date(unreads[i].date);

        reads = orderNotification(reads);
        unreads = orderNotification(unreads);

        setNotifications([...reads, ...unreads]);
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const isMarkableRead = (): boolean => {
        return notifications.some(n => n.viewed == false && n.marked == true);
    };

    const excludeNotifications = async () => {
        const selectedIds = notifications.filter(n => n.marked == true).map((n: BiblioNotification) => n.id);

        const service = new BiblioNotificacaoService();
        const response = await service.removerNotificacao(selectedIds);

        setShowModalExclude(false);

        if (response.status == 200) fetchNotifications();
    };

    const putToReadNotification = async () => {
        const selectedIds = notifications.filter(n => n.marked == true && n.viewed == false).map((n: BiblioNotification) => n.id);
        const service = new BiblioNotificacaoService();
        const response = await service.marcarNotificacaoLida(selectedIds);
        if (response.status == 200) fetchNotifications();
    };

    const putToReadNotificationById = async (id: number) => {
        const service = new BiblioNotificacaoService();
        const response = await service.marcarNotificacaoLida([id]);
        if (response.status == 200) fetchNotifications();
    };

    const isSomeSelected = (): boolean => {
        return notifications.some(n => n.marked == true);
    };

    const gotoBack = () => {
        history.push('/biblioteca');
    };

    useEffect(() => {
        const item = notifications.some(n => n.id == idModalExib);
        if (item) putToReadNotificationById(idModalExib);
    }, [idModalExib]);

    return (
        <>
            <BiblioNotificationExcludeModal
                show={showModalExclude}
                onHide={() => setShowModalExclude(false)}
                goAction={() => excludeNotifications()}
            />

            <BiblioNotificationModalShow
                show={idModalExib != -1}
                itemId={idModalExib}
                item={notifications.find(n => n.id == idModalExib)}
                onHide={() => setIdModalExib(-1)}
            />

            <Styled.Container>
                <Styled.TopContainer>
                    <div>
                        <Styled.Goback onClick={gotoBack}>
                            <IoArrowBackOutline style={{ transform: 'translateY(-1px)' }} />
                            <span>Voltar para a Biblioteca</span>
                        </Styled.Goback>
                        <Styled.Title>Notificações</Styled.Title>
                    </div>

                    <Styled.ButtonActionContainer>
                        <Styled.ButtonAction
                            active={isMarkableRead()}
                            onClick={() => {
                                GTM_Page_Biblioteca('0', 'notificacoes_biblioteca', 'marcar_todas_lidas_btn');
                                putToReadNotification();
                            }}
                        >
                            <Styled.Icon src={isMarkableRead() ? mailIconOn : mailIcon} />
                            <span>Marcar como lida</span>
                        </Styled.ButtonAction>
                        <Styled.ButtonAction
                            active={isSomeSelected()}
                            onClick={() => {
                                GTM_Page_Biblioteca('0', 'notificacoes_biblioteca', 'excluir_selecionadas_btn');
                                toExclude?.();
                            }}
                        >
                            <Styled.Icon src={isSomeSelected() ? trashIconOn : trashIcon} />
                            <span>Excluir</span>
                        </Styled.ButtonAction>
                    </Styled.ButtonActionContainer>
                </Styled.TopContainer>

                <Styled.Br />

                <Styled.ContentGrid>
                    <Styled.ScrollContainer>
                        <Styled.ScrollTop>
                            <Styled.Subtitle>Novas </Styled.Subtitle>
                            <Styled.CheckBoxContainer>
                                <Styled.Checkbox
                                    id="select-news"
                                    onChange={() => {
                                        GTM_Page_Biblioteca('0', 'notificacoes_biblioteca', 'content_select_all_new_btn');
                                        toggleAllUnread();
                                    }}
                                    checked={selectedAllUnread}
                                ></Styled.Checkbox>
                                <Styled.CheckboxLabel htmlFor="select-news">Selecionar todas</Styled.CheckboxLabel>
                            </Styled.CheckBoxContainer>
                        </Styled.ScrollTop>

                        <Styled.ScrollContent>
                            {notifications
                                .filter(n => !n.viewed)
                                .map((data, idx) => {
                                    return (
                                        <BiblioNotificationItemMark
                                            key={idx}
                                            data={data}
                                            onToggle={toggleNotification}
                                            onClick={() => {
                                                GTM_Page_Biblioteca(
                                                    `${data.id}`,
                                                    `notification_select_${data.title.replace(/\s+/g, '_').toLowerCase()}}`,
                                                    'content_new_select_btn'
                                                );
                                                setIdModalExib(data.id);
                                            }}
                                        />
                                    );
                                })}
                        </Styled.ScrollContent>
                    </Styled.ScrollContainer>

                    <Styled.ScrollContainer>
                        <Styled.ScrollTop>
                            <Styled.Subtitle>Lidas</Styled.Subtitle>
                            <Styled.CheckBoxContainer>
                                <Styled.Checkbox
                                    id="select-olds"
                                    onChange={() => {
                                        GTM_Page_Biblioteca('0', 'notificacoes_biblioteca', 'content_select_readed_new_btn');
                                        selectAllRead();
                                    }}
                                    checked={selectedAllRead}
                                ></Styled.Checkbox>
                                <Styled.CheckboxLabel htmlFor="select-olds">Selecionar todas</Styled.CheckboxLabel>
                            </Styled.CheckBoxContainer>
                        </Styled.ScrollTop>

                        <Styled.ScrollContent>
                            {notifications
                                .filter(n => n.viewed)
                                .map((data, idx) => {
                                    return (
                                        <BiblioNotificationItemMark
                                            key={idx}
                                            data={data}
                                            onToggle={toggleNotification}
                                            onClick={() => {
                                                GTM_Page_Biblioteca(
                                                    `${data.id}`,
                                                    `notification_select_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                                    'content_readed_select_btn'
                                                );
                                                setIdModalExib(data.id);
                                            }}
                                        />
                                    );
                                })}
                        </Styled.ScrollContent>
                    </Styled.ScrollContainer>
                </Styled.ContentGrid>
            </Styled.Container>
        </>
    );
};

export default BiblioNotificationPage;
