import { GTM_Page_Meu_Painel_Sharebox } from 'components/GoogleTagManager';
import PopoverOverlay from 'components/popover/Popover';
import { DefaultPopover } from 'components/popover/Popover.styled';
import { ToastSuccess } from 'components/Toaster';
import copy from 'copy-to-clipboard';
import Colors from 'enums/Colors';
import { JornadaEnum } from 'enums/JornadaEnum';
import { PaginasEnum } from 'enums/PaginasEnum';
import React from 'react';

import ClipboardIcon from '../assets/clipboard.svg';
import EmailIcon from '../assets/email.svg';
import FacebookIcon from '../assets/facebook.svg';
import InstagramIcon from '../assets/instagram.svg';
import LinkedinIcon from '../assets/linkedin.svg';
import ShareIcon from '../assets/ShareIcon.svg';
import WhatsappIcon from '../assets/whatsapp.svg';
import { ShareLink, ShareButton, ShareBoxContainer, IconSvg, IconShareSvg } from './Sharebox.styled';

export interface ISharebox {
    location: string;
}

const Sharebox = ({ location }: ISharebox) => {
    const origin = window.location.origin;

    const successMessage = () => {
        ToastSuccess('Compartilhado com sucesso!');
    };

    const shareWhatsapp = () => {
        const _url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(returnUrlByJornada(location));
        GTM_Page_Meu_Painel_Sharebox('whatsapp', location);
        window.open(_url, '_blank');
        successMessage();
    };

    const copyToClipboard = () => {
        copy(returnUrlByJornada(location));
        GTM_Page_Meu_Painel_Sharebox('clipboard', location);
        successMessage();
    };

    const shareFacebook = () => {
        const _url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(returnUrlByJornada(location));
        GTM_Page_Meu_Painel_Sharebox('facebook', location);
        window.open(_url, '_blank');
        successMessage();
    };

    const shareLinkedin = () => {
        const _url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(returnUrlByJornada(location))}`;
        GTM_Page_Meu_Painel_Sharebox('linkedin', location);
        window.open(_url, '_blank');
        successMessage();
    };

    const shareEmail = () => {
        const _url = `mailto:?body=${encodeURIComponent(returnUrlByJornada(location))}`;
        GTM_Page_Meu_Painel_Sharebox('email', location);
        window.open(_url, '_blank');
        successMessage();
    };

    const returnUrlByJornada = (location: string): string => {
        const utmConfig = '?utm_source=humane&utm_medium=link-compartilhado&utm_campaign=';

        if (location == PaginasEnum.JORNADA_DESENVOLVIMENTO) {
            return origin + '/home/' + utmConfig + 'jornada-todas';
        } else if (location == PaginasEnum.CURSOS_LIVRES) {
            return origin + '/cursos-livres/' + utmConfig + 'cursos-livres-todos';
        } else if (location == PaginasEnum.BIBLIOTECA) {
            return origin + '/biblioteca/' + utmConfig + 'biblioteca';
        } else if (location == JornadaEnum.SOCIOEMOCIONAL_PROFESSORES) {
            return origin + '/socioemocional-professores/' + utmConfig + 'jornada-socioemocional';
        } else if (location == JornadaEnum.ALF_360) {
            return origin + '/alfabetizacao-360/' + utmConfig + 'jornada-alfa';
        } else if (location == JornadaEnum.REALIZANDO_ESCOLHAS) {
            return origin + '/realizandoEscolhas/home' + utmConfig + 'jornada-realizando';
        } else if (location == JornadaEnum.CRIATIVIDADE_PENSAMENTO_CRITICO) {
            return origin + '/criatividade-pensamento-critico/' + utmConfig + 'jornada-cpc';
        } else if (location == JornadaEnum.TRILHAS) {
            return origin + '/recursos/trilhas/' + utmConfig + 'trilhas-de-aprendizagem';
        }
        return '';
    };

    return (
        <PopoverOverlay
            trigger={['click']}
            overlay={
                <DefaultPopover background={Colors.White} height={110} filter={true} no_border={true}>
                    {
                        <ShareBoxContainer>
                            <ShareLink onClick={() => shareWhatsapp()}>
                                <IconShareSvg src={WhatsappIcon} />
                            </ShareLink>

                            <ShareLink onClick={() => copyToClipboard()}>
                                <IconShareSvg src={ClipboardIcon} />
                            </ShareLink>

                            <ShareLink onClick={() => shareFacebook()}>
                                <IconShareSvg src={FacebookIcon} />
                            </ShareLink>

                            <ShareLink onClick={() => shareLinkedin()}>
                                <IconShareSvg src={LinkedinIcon} />
                            </ShareLink>

                            <ShareLink onClick={() => shareEmail()}>
                                <IconShareSvg src={EmailIcon} />
                            </ShareLink>
                        </ShareBoxContainer>
                    }
                </DefaultPopover>
            }
        >
            <ShareButton data-tip={'Compartilhar'}>
                <IconSvg src={ShareIcon} />
            </ShareButton>
        </PopoverOverlay>
    );
};

export default Sharebox;
