import React, { useContext, useEffect, useState } from 'react';
import { ToastError, ToastSuccess } from 'components/Toaster';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { cloneDeep } from 'lodash';
import Styled from './AdminEditor.styled';
import AdminSecaoPaginaData, { AdminSecaoPaginaCarouselMediaItem } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import ActionComponent from './components/ActionComponent';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import Select from 'components/Select';
import { getOptionFromValue, tipoAnexoOptions } from './TipoAnexoCarrossel';
import TipoAnexoCarrossel from 'enums/TipoAnexoCarrosselEnum';

interface IAdminItemSection {
    itens: AdminSecaoPaginaCarouselMediaItem[];
    setItens: (itens: AdminSecaoPaginaCarouselMediaItem[]) => void;
    currentSection: AdminSecaoPaginaData;
    fetchItem: () => void;
}

const AdminEditCarouselMediaItem = ({ itens, setItens, currentSection, fetchItem }: IAdminItemSection) => {
    const [idCardSelected, setIdCardSelected] = useState<number>(0);
    const [showModalDeleteCard, setShowModalDeleteCard] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [submittingIndex, setSubmittingIndex] = useState(-1);

    const service = new AdminGestaoPaginaService();

    const isValidInputs = (idx: number): boolean => {
        const item = itens[idx];

        if (!item.ocultarDetalhamento) {
            if (item.detalhamento.trim().length === 0 || !item.corDetalhamento || !item.tamanhoDetalhamento) {
                return false;
            }
        }

        if (item.tipoAnexo === TipoAnexoCarrossel.IMAGEM) {
            if (item.anexoBase64 === undefined || item.anexoBase64 === '') {
                return false;
            }
        } else {
            const url = item.urlVideo ?? '';

            if (url.length > 0) {
                if (!url.includes('youtube')) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    };

    const handleDeleteCard = async () => {
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCardCarrosselMultimidia(idCardSelected);

            if (response.status === 200) {
                ToastSuccess('Cartão excluído com sucesso!');
                fetchItem();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteCard(false);
        setIsLoadingDelete(false);
    };

    const submitCard = async (idx: number) => {
        setSubmittingIndex(idx);

        const item = itens[idx];

        if (!item.isPersist) await saveCard(currentSection, idx);
        else await updateCard(idx);

        setSubmittingIndex(-1);
    };

    const saveCard = async (secao: AdminSecaoPaginaData, idx: number) => {
        const item = itens[idx];

        const { status, data } = await service.inserirCarrosselMultimidia(
            secao.codigo,
            item.detalhamento,
            item.corDetalhamento,
            item.tamanhoDetalhamento.toString(),
            item.ocultarDetalhamento,
            item.tipoAnexo,
            item.anexoBase64 ?? '',
            item.urlVideo ?? '',
            false
        );

        if (status < 300) {
            setCodigo(idx, data.codigo);
            ToastSuccess('Carrossel salvo');
        } else {
            ToastError('Houve um erro ao tentar salvar o carrossel!');
        }
    };

    const updateCard = async (idx: number) => {
        const item = itens[idx];

        const { status, data } = await service.atualizarCarrosselMultimidia(
            item.codigo,
            item.detalhamento,
            item.corDetalhamento,
            item.tamanhoDetalhamento.toString(),
            item.ocultarDetalhamento,
            item.tipoAnexo,
            item.anexoBase64 ?? '',
            item.urlVideo ?? '',
            item.oculto ?? false
        );

        if (status < 300) {
            ToastSuccess('Carrossel atualizado');
        } else {
            ToastError('Houve um erro ao tentar salvar o carrossel!');
        }
    };

    const setCodigo = (idx: number, value: number) => {
        const clone = cloneDeep(itens);
        clone[idx].codigo = value;
        setItens(clone);
    };

    const setDetalhamento = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].detalhamento = value;
        setItens(clone);
    };

    const setCorDetalhamento = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].corDetalhamento = value;
        setItens(clone);
    };

    const setTamanhoDetalhamento = (idx: number, value: number) => {
        const clone = cloneDeep(itens);
        clone[idx].tamanhoDetalhamento = value;
        setItens(clone);
    };

    const setOcultoDetalhamento = (idx: number, value: boolean) => {
        const clone = cloneDeep(itens);
        clone[idx].ocultarDetalhamento = value;
        setItens(clone);
    };

    const setTipoAnexo = (idx: number, value: TipoAnexoCarrossel) => {
        const clone = cloneDeep(itens);
        clone[idx].tipoAnexo = value;
        setItens(clone);
    };

    const setAnexoVideo = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].anexoBase64 = value;
        setItens(clone);
    };

    const setUrlVideo = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].urlVideo = value;
        setItens(clone);
    };

    const setCover = (idx: number, value: string) => {
        const clone = cloneDeep(itens);
        clone[idx].anexoBase64 = value;
        setItens(clone);
    };

    return (
        <>
            {itens.map((item, idx) => {
                return (
                    <>
                        <ModalExclusao
                            showModal={showModalDeleteCard}
                            handleHiden={setShowModalDeleteCard}
                            handleExcluxed={handleDeleteCard}
                            isLoading={isLoadingDelete}
                        />
                        <Styled.Container key={idx}>
                            <div>
                                <Styled.CardContainer>
                                    <Styled.Label>Detalhamento</Styled.Label>
                                    <Styled.Input value={item.detalhamento} onChange={(evt: any) => setDetalhamento(idx, evt.target.value)} />
                                    <ActionComponent
                                        name={'detalhe' + item.codigo}
                                        valueColorText={item.corDetalhamento}
                                        valueFontSize={item.tamanhoDetalhamento}
                                        isOculto={item.ocultarDetalhamento}
                                        setColorText={value => setCorDetalhamento(idx, value)}
                                        setValueFontSize={value => setTamanhoDetalhamento(idx, value)}
                                        setIsOculto={value => setOcultoDetalhamento(idx, value)}
                                    />
                                </Styled.CardContainer>
                                <Styled.Label>Tipo de Carrossel</Styled.Label>
                                <Select
                                    isSmall
                                    value={getOptionFromValue(item.tipoAnexo)}
                                    itens={tipoAnexoOptions}
                                    onSelected={o => setTipoAnexo(idx, o.value as TipoAnexoCarrossel)}
                                />
                                {item.tipoAnexo == TipoAnexoCarrossel.VIDEO && (
                                    <>
                                        <Styled.Label>URL do video</Styled.Label>
                                        <Styled.Input value={item.urlVideo} onChange={(evt: any) => setUrlVideo(idx, evt.target.value)} />
                                    </>
                                )}
                                <Styled.RightContainer>
                                    <RedSolidButton
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            setIdCardSelected(item.codigo);
                                            setShowModalDeleteCard(true);
                                        }}
                                    >
                                        Deletar
                                    </RedSolidButton>

                                    <GradientButton onClick={() => submitCard(idx)} disabled={!isValidInputs(idx) || submittingIndex == idx}>
                                        {submittingIndex == idx ? 'Enviando' : !item.isPersist ? 'Salvar Cartão' : 'Atualizar Cartão'}
                                    </GradientButton>
                                </Styled.RightContainer>
                            </div>
                            {item.tipoAnexo == TipoAnexoCarrossel.IMAGEM && (
                                <Styled.RigthArea>
                                    <Styled.ImageContainer style={{ paddingTop: 'calc(1rem + 10px)' }}>
                                        <AdminImageUploader
                                            onSelect={() => {}}
                                            setBase64={(base64: string) => setCover(idx, base64)}
                                            size={'sm'}
                                            title={'Imagem do card'}
                                            preImageB64={item.anexoBase64 ?? ''}
                                            recommendedDimension={{ w: 150, h: 85 }}
                                        />
                                    </Styled.ImageContainer>
                                </Styled.RigthArea>
                            )}
                        </Styled.Container>
                    </>
                );
            })}
        </>
    );
};

export default AdminEditCarouselMediaItem;
