import React, { useEffect } from 'react';
import styled from 'styled-components';

import { MobileMaxWidth } from 'constants/Dimensions';
import { videoExplicativoUrl } from 'constants/Resources';

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    height: fit-content;
    width: fit-content;
    margin: 22px auto;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        box-shadow: none;
    }
`;

const VideoContainer = styled.div`
    display: flex;
    justify-content: center;

    video {
        height: 350px;
        width: auto;
        box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
            height: auto;
            box-shadow: none;
        }
    }
`;

export interface IVideoExplicativo {}

const VideoExplicativo = ({}: IVideoExplicativo) => {
    const getHeightPlayer = (): number => {
        const widthScreen: number = window.innerWidth;

        switch (widthScreen) {
            case 320:
                return 150;
            case 360:
            case 375:
            case 384:
            case 390:
            case 393:
            case 412:
            case 414:
            case 425:
                return 190;
            case 540:
            case 640:
            case 768:
            case 820:
                return 250;
            default:
                return 400;
        }
    };

    const getWidthPlayer = (): number => {
        const widthScreen: number = window.innerWidth;

        switch (widthScreen) {
            case 320:
                return 220;
            case 375:
                return 270;
            case 425:
                return 290;
            case 640:
            case 768:
            case 820:
                return 350;
            default:
                return 680;
        }
    };

    return (
        <Container>
            <VideoContainer>
                <iframe style={{ maxWidth: '100%' }} src={`//${videoExplicativoUrl}`} width={getWidthPlayer()} height={getHeightPlayer()} />
            </VideoContainer>
        </Container>
    );
};

export default VideoExplicativo;
