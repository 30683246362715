import { bucketS3, caminhoAnexosGestaoPaginaCarousel } from 'constants/EnvironmentData';
import { IsCustomMinWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import ComponentCarrosselInteraction from 'models/componentInteraction/ComponentCarrosselInterarion';
import ComponentItemCarrosselInteraction from 'models/componentInteraction/ComponentItemCarrosselInteraction';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';

interface ICarrosselInteractionPublic {
    dados: ComponentCarrosselInteraction | null;
}
interface ICardCarrossel {
    dados: ComponentItemCarrosselInteraction;
    s3Repository: string;
}

interface IArrowButton {
    next: boolean;
}

const Styled = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 33px 73px;
        height: 500px;

        @media screen and (max-width: 600px) {
            padding: 0;
        }
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 59px;
        width: 56px;
        fill: ${Colors.Yellow};

        &:hover {
            fill: ${Colors.Yellow};
        }

        @media screen and (max-width: 600px) {
            width: 26px;
            height: 29px;
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '110%' : '-20%')};

        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '100%' : '-5%')};
        }

        @media screen and (max-width: 600px) {
            left: ${props => (props.next ? '100%' : '-5%')};
            height: 29px;
            width: 26px;
        }
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean }>`
        width: 100%;

        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: start;
        }

        .slick-dots {
            li {
                button::before {
                    color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding: 0;
            .slick-slide {
                justify-content: center;
                align-items: center;
            }
        }
    `,

    Carrousel: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 620px;
        height: 450px;

        @media screen and (max-width: 600px) {
            margin: 10px;
            width: 300px;
        }
    `,

    CardCarousel: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${Colors.White};
        width: 620px;
        height: 460px;
        border: 1px solid #f1f1f1;
        border-radius: 20px;

        @media screen and (max-width: 600px) {
            width: 300px;
            height: 300px;
            min-height: auto;
        }
    `,

    CardContainer: {
        Content: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 20px;
            width: 100%;
            height: 100%;
        `,

        ImgContainer: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 120px;
        `,

        Img: styled.img<{ width?: string; height?: string }>`
            width: ${({ width }) => (width ? width : 'auto')};
            height: ${({ height }) => (height ? height : '100%')};
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            color: ${Colors.Blue};

            @media screen and (max-width: 600px) {
                font-size: 18px;
            }
        `,

        Description: styled.p`
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        `,
    },
};

const CardCarousel = ({ dados, s3Repository }: ICardCarrossel) => {
    return (
        <Styled.CardCarousel style={{ backgroundColor: dados.colorBg }}>
            <Styled.CardContainer.Content>
                {dados.type === 'I' ? (
                    <img
                        style={{ height: '460px', width: '100%', objectFit: 'cover', borderRadius: '20px' }}
                        src={s3Repository + dados.filepath}
                    />
                ) : (
                    <TextDangerouslySetInnerHtml
                        style={{ margin: 0, textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                            __html: dados.text ?? '',
                        }}
                    />
                )}
            </Styled.CardContainer.Content>
        </Styled.CardCarousel>
    );
};

const CarrosselInteractionPublic = ({ dados }: ICarrosselInteractionPublic) => {
    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaCarousel();
    };

    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M0.472656 26.5214L11.1593 15.0974L0.472656 3.6734L3.76266 0.164062L17.7627 15.0974L3.76266 30.0307L0.472656 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(18, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M17.9931 26.5214L7.30646 15.0974L17.9931 3.6734L14.7031 0.164062L0.703125 15.0974L14.7031 30.0307L17.9931 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(15, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: true,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <Styled.Container>
            <div>
                <Styled.Carrousel>
                    <Styled.CarouselCardContainer style={{ margin: 0 }}>
                        <Slider {...settings}>
                            {dados?.itens?.map((dados, index) => (
                                <CardCarousel key={index} dados={dados} s3Repository={getS3BaseUrl()} />
                            ))}
                        </Slider>
                    </Styled.CarouselCardContainer>
                </Styled.Carrousel>
            </div>
        </Styled.Container>
    );
};

export default CarrosselInteractionPublic;
