import React from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import AvatarEditor from 'react-avatar-edit';
import { useHistory } from 'react-router-dom';

import ProfileData from '../../../../../models/ProfileData';
import { ConfirmButton } from '../components/ImageSelect.styled';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import { storeProfileData } from '../../../../../store/actions/Profile';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';
import ImagemPerfil from '../../../../../models/imagemPerfil/ImagemPerfil';
import Colors from '../../../../../enums/Colors';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 100%;

    @media (min-width: ${MobileMaxWidth}px) {
        box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.1);
        max-width: 960px;
        border-radius: 16px;
        margin: 20px auto;
    }
`;

const PreviewContainer = styled.div`
    border-radius: 20px;
    overflow: hidden;
    margin: 20px 0;

    @media (min-width: ${MobileMaxWidth}px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
    }
`;

const TipLabel = styled.p<{ width: number }>`
    color: white;
    text-align: center;
    background-color: #191a1c;
    width: ${props => props.width}px;
    margin: 0 auto;
    padding: 10px 0;

    @media (max-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    width: 100%;

    @media (min-width: ${MobileMaxWidth}px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
        text-align: center;
    }
`;

const SendButton = styled(ConfirmButton)`
    margin-bottom: 10px;

    @media (min-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

const CancelButton = styled(ConfirmButton)`
    border: 1px solid ${Colors.SoftBlue};
    color: ${Colors.SoftBlue};
    background-color: white;

    &:hover {
        color: white;
        background-color: ${Colors.SoftBlue};
    }

    @media (min-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

const ConfirmImageButton = styled(ConfirmButton)`
    display: none;
    margin: 0 auto;
    margin-top: 20px;

    color: #0b78c8;
    background-color: white;
    border: 1px solid #0b78c8;

    &:hover {
        color: white;
        background: ${Colors.BrightBlueToStrongBlueGradient};
        border: none;
    }

    @media (min-width: ${MobileMaxWidth}px) {
        display: inline-block;
    }
`;

const Label = styled.p({
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 'bold',
});

interface IModalEditor {
    onDone: any;
    show: boolean;
}

const EditImagemPerfil = ({ onDone, show }: IModalEditor) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const widthCropArea = window.screen.width < MobileMaxWidth ? 320 : 500;

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    const encodedBase64 = profileData?.imagemPerfil?.imageBase64;

    const onCrop = (preview: string): void => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.Imagem,
                imageBase64: preview,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    const onClose = () => {
        history.push('/profile/identificacaoImagem');
    };

    const onContinuar = () => {
        history.push('/profile/identificacaoImagemConfirmar');
    };

    return (
        <Container>
            <Label>Editar Foto</Label>
            <PreviewContainer>
                {encodedBase64 && (
                    <AvatarEditor onCrop={onCrop} onClose={onClose} width={widthCropArea} height={385} imageHeight={385} src={encodedBase64} />
                )}
            </PreviewContainer>
            <TipLabel width={widthCropArea}>Arraste o círculo para reposicionar</TipLabel>

            <ButtonContainer>
                <SendButton onClick={onContinuar} disabled={false}>
                    Enviar
                </SendButton>
                <CancelButton onClick={onClose} disabled={false}>
                    Cancelar
                </CancelButton>

                <ConfirmImageButton onClick={onContinuar} disabled={false}>
                    Confirmar
                </ConfirmImageButton>
            </ButtonContainer>
        </Container>
    );
};

export default EditImagemPerfil;
