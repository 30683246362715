import { CSSObject } from 'styled-components';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import Colors from '../../../../enums/Colors';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

export const CustomStyles = {
    menuList: (provided: CSSObject, state: any) => ({
        ...provided,
        overflow: 'auto',
        maxHeight: '150px',
    }),
    valueContainer: (provided: CSSObject, state: any) => ({
        ...provided,
        minHeight: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    option: (provided: CSSObject, state: any) => ({
        ...provided,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '22px',
        color: state.isFocused ? Colors.SoftBlue : Colors.BlueGrey,
        cursor: 'pointer',
        padding: '20px',
        borderBottom: '1px solid',
        backgroundColor: Colors.White,
        borderBottomColor: Colors.Grey8,
    }),
    control: (provided: CSSObject) => ({
        ...provided,
        backgroundColor: 'transparent',
        border: 'none',
        appearance: 'none',
        borderBottom: '2px solid',
        borderBottomColor: Colors.BlueGrey,
        outline: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        cursor: 'pointer',
        height: '50px',
        '&:hover': {
            borderBottom: '2px solid',
            borderBottomColor: Colors.BlueGrey,
        },
        '&:focus': {
            borderBottom: '2px solid',
            borderBottomColor: Colors.BlueGrey,
        },
    }),
    singleValue: (provided: CSSObject) => ({
        ...provided,
        color: Colors.BlueGrey,
        fontSize: IsMobile() ? '20px' : '24px',
        lineHeight: '36px',
        fontWeight: 600,
    }),
    input: (provided: CSSObject) => ({
        ...provided,
        color: Colors.BlueGrey,
        fontSize: IsMobile() ? '20px' : '24px',
        lineHeight: '36px',

        fontWeight: 600,
    }),
};

export default CustomStyles;
