import styled from 'styled-components';

const BiblioNotificationConfigPageStyled = {
    Container: styled.div`
        padding: 20px 50px;
    `,

    Goback: styled.p`
        font-weight: 500;
        margin-bottom: 20px;
        opacity: 0.8;
        cursor: pointer;

        :hover {
            span {
                text-decoration: underline;
            }
        }

        span {
            margin-left: 5px;
        }
    `,

    Title: styled.div`
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        color: #5f758b;
        margin-bottom: 15px;
    `,

    Subtitle: styled.div`
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.1625px;
        color: #5f758b;
    `,

    Text: styled.p`
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.1625px;
        color: #2e2e2e;
        min-width: 100px;
        margin-bottom: 7px;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;

        & > p {
            transform: translateY(-2px);
        }
    `,

    PaddingH: styled.div`
        width: 30px;
    `,
};

export default BiblioNotificationConfigPageStyled;
