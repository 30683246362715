import { PrimaryButton } from 'components/UI/Buttons';
import Title from 'components/UI/Title';
import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const Container = styled.div(new FullCss({ borderRadius: '6px', backgroundColor: Colors.SoftBlue, padding: '25px 30px' }).getCss());

export const HeadContainer = styled.div(new FullCss({ display: 'flex', justifyContent: 'space-between', userSelect: 'none' }).getCss());

export const CloseIcon = styled.img(new FullCss({ width: '24px', height: '22px', cursor: 'pointer' }).getCss());

export const OpenIcon = styled.img(new FullCss({ width: '25px', height: '25px', cursor: 'pointer' }).getCss());

export const Content = styled.div(
    new FullCss(
        { display: 'flex', alignItems: 'baseline', padding: '10px 0', flexDirection: 'column' },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), { flexDirection: 'column' })
    ).getCss()
);

export const Text = styled.p(
    new FullCss({
        color: Colors.White,
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        padding: '10px 0',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '22px',
        letterSpacing: '0.3px',
        whiteSpace: 'break-spaces',
    }).getCss()
);

export const ButtonContainer = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '15px',
            width: 'fit-content',
            paddingTop: '20px',
            height: 'fit-content',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), { flexDirection: 'column', width: '100%', alignItems: 'flex-end' })
    ).getCss()
);

export const Button = styled(PrimaryButton)(
    new FullCss(
        {
            fontWeight: '700',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.3px',
            border: `1px solid ${Colors.DarkBlue2}`,
            backgroundColor: Colors.DarkBlue2,
            color: Colors.White,
        },
        [
            new MediaQuery(':hover', { border: `2px solid ${Colors.White}`, backgroundColor: 'transparent', color: Colors.Blue }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), { display: 'none' }),
        ]
    ).getCss()
);

export const HeadTitle = styled(Title)(new FullCss({ fontWeight: '700', fontSize: '24px', lineHeight: '29px' }).getCss());

export const CloseBanner = styled.button(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '218px',
            height: '49px',
            border: 'none',
            background: 'transparent',
            color: Colors.White,
            fontWeight: '700',
            fontSize: '14px',
            letterSpacing: '0.3px',
            textDecorationLine: 'underline',
        },
        [
            new MediaQuery(':hover', { color: Colors.Blue }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '113px',
                height: '40px',
                background: '#FCFCFC',
                borderRadius: '6px',
                fontWeight: '600',
                lineHeight: '24px',
                letterSpacing: '-0.01em',
                fontFeatureSettings: "'calt' off",
                color: Colors.Blue,
                textDecorationLine: 'none',
            }),
        ]
    ).getCss()
);

export default Container;
