import React from 'react';

import styled from 'styled-components';

import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import { CpfMask } from 'core/util/MaskHelper';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

export const FormikInputStyle = styled.input<{ error: boolean; fontSizePlaceholder?: string }>`
    width: ${props => (IsMobile() ? '100%' : props.size + 'px')};
    border: none;
    border-bottom: 2px solid ${props => (props.error ? '#E53935' : Colors.BlueGrey)};
    outline: none;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    padding: 3px;
    color: ${props => (props.error ? '#E53935' : Colors.BlueGrey)};
    margin-left: 5px;
    text-align: center;
    background-color: transparent;

    &::placeholder {
        color: rgba(95, 117, 139, 0.25);
        font-family: 'Barlow';
        font-weight: 600;
        font-size: ${({fontSizePlaceholder}) => fontSizePlaceholder ? fontSizePlaceholder : '26px'};
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 20px;
    }
`;

interface IFormikTextInput {
    formik: any;
    onBlurHandler: any;
    onChangeHandler: any;
    name: string;
    size?: number;
    styled?: any;
    disabled?: boolean;
    placeholder?: string;
    fontSizePlaceholder?: string;
    maxLength?: number;
    validateCpf?: boolean;
    onError?: boolean;
}
const FormikTextInput = ({
    formik,
    onBlurHandler,
    onChangeHandler,
    name,
    fontSizePlaceholder,
    size,
    disabled = false,
    placeholder,
    maxLength,
    validateCpf = false,
    styled,
    onError = false,
    ...props
}: IFormikTextInput) => {
    return (
        <FormikInputStyle
            fontSizePlaceholder={fontSizePlaceholder}
            type="text"
            maxLength={maxLength ? maxLength : 30}
            style={styled ? { ...styled } : {}}
            size={size}
            onPaste={e => {
                e.preventDefault();
                return false;
            }}
            value={validateCpf ? CpfMask(formik.values[name]) : formik.values[name]}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            disabled={disabled}
            placeholder={placeholder || ''}
            error={onError}
            {...props}
        />
    );
};

export default FormikTextInput;
