import styled from 'styled-components';

const StyledBannerCSE = {
    Container: styled.div`
        display: flex;
        height: 557px;

        @media screen and (max-width: 760px) {
            height: auto;
        }
    `,

    Content: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 80px;
        width: 50%;
        overflow: hidden;

        @media screen and (max-width: 760px) {
            width: 100%;
            padding: 32px;
        }
    `,

    ImgBanner: styled.img`
        height: 100%;
        width: 50%;
        object-fit: cover;

        @media screen and (max-width: 760px) {
            display: none;
        }
    `,
};

export default StyledBannerCSE;
