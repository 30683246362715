import { jsonProperty, Serializable } from 'ts-serializable';
import PickListModel from '../../components/UI/pickList/PickListModel';
import Macrocompetencia from '../pdp/Macrocompetencia';
import RegistroMacrocompetencia from './RegistroMacrocompetencia';

class PDP extends Serializable {
    @jsonProperty(Number)
    id: number = 0;

    @jsonProperty([Macrocompetencia], [])
    listaMacrocompetenciasDestaque: Macrocompetencia[] | [] = [];

    @jsonProperty(Macrocompetencia, null)
    macrocompetenciaMaisDesenvolvida: Macrocompetencia | null = null;

    @jsonProperty(String)
    dificuldadesMacrocompetencia: string = '';

    @jsonProperty(String)
    formaDesenvolvimentoMacrocompetencia: string = '';

    @jsonProperty([RegistroMacrocompetencia], [])
    listaMacrocompetencias: RegistroMacrocompetencia[] = [];

    @jsonProperty(Number)
    codigoPerfil: number = 0;

    constructor() {
        super();
    }
}

export default PDP;
