import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class BiblioMediaNota extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    codigoItem: number = -1;

    @jsonName('titulo')
    @jsonProperty(String)
    titulo: string = '';

    @jsonName('tipo')
    @jsonProperty(String)
    tipo: string = '';

    @jsonName('media')
    @jsonProperty(Number)
    media: number = -1;

    @jsonName('total')
    @jsonProperty(Number)
    total: number = -1;
}

export default BiblioMediaNota;
