import React, { useState } from 'react';
import Styled from '../Avaliations.styled';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineEye } from 'react-icons/ai';
import AdminGestaoPaginaNotas from 'models/admin/AdminGestaoPaginaNotas';
import AdminGestaoPaginaMedia from 'models/admin/AdminGestaoPaginaMedia';
import ModalAvaliations from '../ModalAvaliations';
import { DateHelp } from 'core/util/DateHelp';

interface ITableAvaliation {
    avaliations: AdminGestaoPaginaNotas[];
    avarages: AdminGestaoPaginaMedia[];
    isAverage: Boolean;
}

const TableAvaliation = ({ avaliations, avarages, isAverage }: ITableAvaliation) => {
    const [showModal, setShowModal] = useState(false);
    const [dadosSelected, setSelected] = useState<AdminGestaoPaginaNotas>();

    return (
        <React.Fragment>
            <ModalAvaliations show={showModal} onHide={() => setShowModal(false)} dados={dadosSelected} />

            {isAverage ? (
                <React.Fragment>
                    {avarages?.map((dados, index) => (
                        <React.Fragment key={index}>
                            <Styled.GridCol>{dados.tipo.toString().toUpperCase()}</Styled.GridCol>
                            <Styled.GridCol>{dados.nomePagina}</Styled.GridCol>
                            <Styled.GridCol>{dados.nome}</Styled.GridCol>
                            <Styled.GridCol>
                                <AdminRateStar rate={Math.trunc(dados.media ?? 0)} />
                            </Styled.GridCol>
                            <Styled.GridCol>{dados.total}</Styled.GridCol>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {avaliations?.map((dados, index) => (
                        <React.Fragment key={index}>
                            <Styled.GridCol>{dados.tipo.toString().toUpperCase()}</Styled.GridCol>
                            <Styled.GridCol>{dados.nomePagina}</Styled.GridCol>
                            <Styled.GridCol>{dados.nomeConteudo}</Styled.GridCol>
                            <Styled.GridCol>{dados.nomeUsuario}</Styled.GridCol>
                            <Styled.GridCol>{DateHelp.formatDateFull(dados.ultimaAtualizacao!)}</Styled.GridCol>
                            <Styled.GridCol>
                                <AdminRateStar rate={Math.trunc(dados.nota)} />
                            </Styled.GridCol>
                            <Styled.GridCol>
                                <OverlayTrigger overlay={<Tooltip id={'tooltip-action-view_' + 3}>Visualizar</Tooltip>} placement={'top'}>
                                    <span>
                                        <AiOutlineEye
                                            onClick={() => {
                                                setSelected(dados);
                                                setShowModal(true);
                                            }}
                                            size={25}
                                            cursor="pointer"
                                        />
                                    </span>
                                </OverlayTrigger>
                            </Styled.GridCol>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default TableAvaliation;
