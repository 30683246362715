import React from 'react';
import Aquecimento from '../../components/Aquecimento';
import Macrocompetencia from '../../components/Macrocompetencia/Macrocompetencia';
import Priorizacao from '../../components/Priorizacao';
import Visualizacao from '../../components/Visualizacao';

import { StepStatus, StepType } from './Steps';

const stepComponentTemp = (n: number) => {
    return <h1 style={{ margin: '30px' }}>Passo - {n}</h1>;
};

const steps: StepType[] = [
    {
        index: 1,
        component: <Aquecimento />,
        title: 'Aquecimento',
        subtitle: 'Selecione suas pricipais macrocompetências',
    },
    {
        index: 2,
        component: <Priorizacao />,
        title: 'Priorização',
        subtitle: 'Priorize as macrocompetências que quer desenvolver',
    },
    {
        index: 3,
        component: <Macrocompetencia ordem={1} />,
        title: 'Macrocompetência 1',
        subtitle: 'Continue seu plano de desenvolvimento pessoal a partir da Macrocompetência 1',
    },
    {
        index: 4,
        component: <Macrocompetencia ordem={2} />,
        title: 'Macrocompetência 2',
        subtitle: 'Continue seu plano de desenvolvimento pessoal a partir da Macrocompetência 2',
    },
    {
        index: 5,
        component: <Visualizacao />,
        title: 'Visualizar Plano',
        subtitle: 'Visualize seu Plano de desenvolvimento pessoal',
    },
];

export default steps.map(step => ({ ...step, status: StepStatus.Waiting }));
