import styled from 'styled-components';

import Colors from '../../../../enums/Colors';
import Title from '../../../../components/UI/Title';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const Container = styled.div<{ inatived: boolean }>`
    display: flex;
    filter: ${props => (!props.inatived ? 'grayscale(0)' : 'grayscale(1)')};
    cursor: ${props => (!props.inatived ? 'pointer' : 'default')};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
    }
`;

export const IndexWrapper = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${Colors.SoftBlue3};

    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 46px;
        height: 46px;
    }
`;

export const IndexLabel = styled.p`
    color: ${Colors.SoftBlue};
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 17px;
        line-height: 19px;
    }
`;

export const IndexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const IndexContainerMobile = styled(IndexContainer)`
    flex-direction: row;
`;

export const ContainerMobile = styled(Container)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 150px;
`;

export const TextsContainer = styled.div<{ isCurrent: boolean }>`
    padding: 15px;
    padding-top: 0;
    flex: 1;
    margin-bottom: 40px;

    border-right: 5px ${props => (props.isCurrent ? Colors.SoftBlue : Colors.White)} solid;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        border-right: none;
        margin: 0;
        padding: 0;
    }
`;

export const TitleStep = styled(Title)<{ inatived: boolean }>`
    color: ${props => (!props.inatived ? Colors.Blue : Colors.LightGrey)};
    margin-bottom: 7px;
`;

export const TitleStepMobile = styled(Title)<{ inatived: boolean; visible: boolean }>`
    width: fit-content;
    font-size: 11px;
    color: ${props => (!props.inatived ? Colors.Blue : Colors.LightGrey)};
    opacity: ${props => (props.visible ? '1' : '0')};
`;

export const SubtitleStep = styled.p`
    color: ${Colors.Grey3};

    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
`;

export const Bridge = styled.div`
    flex: 1;
    width: 1px;
    border: 1px ${Colors.Grey8} solid;
`;

export const ScrollContainer = styled.div`
    border: 1px brown solid;
`;
