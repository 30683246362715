import { OutlineButtonInvert, PrimaryButton, YellowButton } from 'components/UI/Buttons';
import { MobileBiggerWidth, MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledRecursosItem = {
    Container: styled.div`
        margin-top: 86px;
        width: 100%;
        padding: 50px 60px;
        max-width: 1620px;

        @media screen and (min-width: 2500px) {
            margin: 86px auto;
        }

        @media screen and (max-width: 860px) {
            justify-content: center;
            padding: 20px !important;
            max-width: 100%;
        }
    `,

    SpaceHorizontal: styled.div`
        flex-grow: 1;

        @media screen and (max-width: 860px) {
            display: none;
        }
    `,

    ButtonFavorite: styled.button<{ bdRadius?: string; width?: string }>`
        width: ${props => (props.width ? props.width : 'auto')};
        height: 40px;
        border-radius: ${props => (props.bdRadius ? props.bdRadius : '30px')};
        background-color: ${Colors.SoftBlue};
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        border: none;
        margin-right: 15px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            transform: translateX(20px);
            width: fit-content;
            padding: 0 25px;
        }
    `,

    Title: styled.h1`
        font-weight: 600;
        font-size: 30px;
        color: #003c78;
    `,

    SubTitle: styled.p`
        font-family: 'Barlow';
        margin-top: 20px;
        margin-bottom: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    `,

    TextProgress: styled.label`
        color: ${Colors.DarkBlue};
        margin-bottom: 10px;
        font-weight: bold;
    `,

    Button: styled(YellowButton)`
        margin-top: 30px;
        max-width: 400px;
        max-height: 100%;
        height: 50px;
        font-size: 16px;
        margin-bottom: 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin: 30px 0;
        }
    `,

    Content: styled.div`
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        flex-wrap: wrap;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    InfoContainer: styled.div`
        width: 680px;

        @media screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,

    EtapasContainer: styled.div`
        flex-grow: 1;
        max-width: 600px;

        @media screen and (max-width: ${MobileMaxWidth}px) {
            min-width: 100%;
            width: 100%;
        }
    `,

    GoBackButton: styled(PrimaryButton)`
        @media screen and (max-width: ${MobileMaxWidth}px) {
            width: 45px;
            height: 45px;
            margin: 0;
        }

        span {
            margin-left: 5px;
            transform: translateY(2px);

            @media screen and (max-width: ${MobileMaxWidth}px) {
                display: none;
            }
        }
    `,

    ShareButton: styled(Button)`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: ${Colors.SoftBlue};
        height: 44px;
        width: 44px;
        border: none;
    `,

    NextButton: styled(OutlineButtonInvert)`
        width: 42px;
        height: 42px;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;

        @media screen and (max-width: ${MobileBiggerWidth}px) {
            justify-content: space-between;
        }
    `,

    RowButtons: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: ${MobileBiggerWidth}px) {
            gap: 1.1rem;
        }
    `,
};

export default StyledRecursosItem;
