import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Colors from '../../../../enums/Colors';
import OtherSolutions from './OtherSolutions';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import TypeSelectionButton from '../../../home/components/typeSelectionButton/TypeSelectionButton';
import ReturnButton from './assets/ReturnButton.svg';

import Lampada from '../../../../images/homepage/educationalSolutions/lampada.svg';

import {
    SocioEmocionaisEducadoresStyle,
    TitleBanner,
    LampadaSvg,
    TextoSobrepostoStyle,
    TitleText,
    SubtitleText,
    BreadcrumbContainer,
    BreadcrumbItem,
    ContentContainer,
    ContentContainerMobile,
    LeftSection,
    TypeSelectionContainer,
    TypeSelecionItem,
    RightSection,
    Title,
    Paragraph,
    CompetenciasWrapper,
    CompetenciasContainer,
    MobileContentSection,
    CompetenciaItem,
    ColoredIndication,
    QueroConhecerButton,
    TitleContainer,
    BannerTitle1,
    BannerTitle2,
    Divisor,
    BtnVoltar,
    ButtonsContainer,
    ReturnButtonContainer,
} from './SocioemocionaisEducadores.styled';
import HomePageHeader from 'pages/home/components/header/HomePageHeaderContainer';
import HeadInfo from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo';
import { useKeycloak } from '@react-keycloak/web';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import { GobackButton, PrimaryButton } from 'components/UI/Buttons';
import Dashboard from 'pages/painelSocioemocional/home/components/Dashboard/Dashboard';
import Menu from 'pages/menu/Menu';
import styled from 'styled-components';
import IntermediatePage from 'pages/intermediarias/components/IntermediatePage';
import IntermediatePageContent, { IntermediatePageContentSection } from 'pages/intermediarias/components/IntermediatePageContent';
import DescriptionEducationalSolutions from './DescriptionEducationalSolutions';
import ObjectivesEducationalSolutions from './ObjectivesEducationalSolutions';
import { MobileMaxWidth } from 'constants/Dimensions';
import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const SocioemocionaisEducadores = () => {
    const PAGE_KEY = PaginasIntermediariasEnum.SOCIOEMOCIONAL_PROFESSORES;

    const sections: IntermediatePageContentSection[] = [
        {
            section: 'Descrição',
            content: <DescriptionEducationalSolutions />,
            subroute: 'o-que-e',
        },

        {
            section: 'Importância',
            content: <ObjectivesEducationalSolutions />,
            subroute: 'importancia',
        },
    ];

    const QueroConhecerButton = () => {
        const history = useHistory();
        const Button = styled(PrimaryButton)`
            margin-right: 20px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                width: 227px;
                margin-bottom: 20px;
                margin-right: 0;
            }
        `;

        const gotoPainelSocio = () => {
            history.push({
                pathname: '/login',
                state: { pathToRedirect: '/painelSocioemocional/home' },
            });
        };

        return (
            <Button
                onClick={() => {
                    GTM_Page_Intermediaria('socioemocional_intermediaria', 'acessar_jornada_btn');
                    gotoPainelSocio();
                }}
            >
                Acessar Jornada
            </Button>
        );
    };

    return (
        <>
            {/*  <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <IntermediatePage
                helmetTitle={'Jornada de desenvolvimento'}
                helmetMetaDescription={''}
                title1={'Jornada de desenvolvimento'}
                title2={'Socioemocional de Professores'}
                icon={Lampada}
                breadCrumbName={'Jornada Socioemocional de Professores'}
            >
                <IntermediatePageContent
                    pageKey={PAGE_KEY}
                    complementComponent={QueroConhecerButton()}
                    pageRoot="socioemocional-professores"
                    sections={sections}
                />
            </IntermediatePage>
        </>
    );
};

export default SocioemocionaisEducadores;
