import { MobileMaxWidth } from "constants/Dimensions"
import Colors from "enums/Colors"
import styled from "styled-components"



const StyledAdminGestaoCertificados = {

    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        max-width: 1200px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 5px;
        }
    `,

    Title: styled.h1`
        font-size: 24px;
        font-weight: bold;
        color: ${Colors.Blue};
    
    `,

    Row: styled.div`
        display: flex;
    `,
    RowBetween: styled.div`
        display: flex;
        justify-content: space-between;
    `




}

export default StyledAdminGestaoCertificados