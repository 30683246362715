import React, { useState } from 'react';

import styled from 'styled-components';

import FormLabel from '../../../formik/FormLabel';
import FormControl from '../../../formik/FormControl';
import ContinuarButton from '../../../UI/ContinuarButton';
import FormikTextInput from '../../../formik/FormikTextInput';
import FormikReactSelect from '../../../formik/FormikReactSelect';
import FormikCreatableInput from '../../../formik/FormikCreatableInput';
import { MobileMaxWidth } from '../../../../../../constants/Dimensions';

import Books_1 from '../../../../../../images/profile/books_1.svg';
import Books_2 from '../../../../../../images/profile/books_2.svg';
import LocationImg from '../../../../../../images/profile/location.svg';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { isUsuarioLms } from 'core/util/KeycloakHelp';
import Colors from 'enums/Colors';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import {
    CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL,
    CODIGO_TIPO_FORMACAO_ENSINO_MEDIO,
    CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL,
} from '../IdentificacaoForm';

const SubmitButtonContainer = styled.div`
    margin-top: 70px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: flex;
        justify-content: center;
    }
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 22px;
    width: 22px;
    fill: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export const QuestionMarkStyle = styled.div`
    align-self: center;
`;

export const CloseButtonContainer = styled.div`
    padding: 0 0 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CloseButton = styled.button`
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
`;

export const Container = styled.div(({ ...props }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    alignSelf: 'center',
    top: '50%',
    left: '50%',
    padding: '32px 16px',
    width: '251px',
    height: '289px',
    overflow: 'hidden',
    borderRadius: '15px',
    position: 'absolute',
    justifySelf: 'center',
    background: Colors.White,
    transform: 'translate(-50%, -50%)',
    boxShadow: '1px 1px 10px 6px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    backgroundColor: '#124A88',
    border: '3px solid #FFFFFF',
}));

export const InfoDialogText = styled.div`
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
`;

export const InfoDialogTitle = styled(InfoDialogText)`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
`;

export const InfoDialogDescription = styled(InfoDialogText)`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const InofoDialogButton = styled.button`
    display: flex;
    align-items: center;
    text-align: center;
    padding: 12px 60px;
    margin-top: 22px;
    height: 42px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    border: none;
    line-height: 18px;
    color: ${Colors.DarkBlue};
    background: ${Colors.Yellow};
    border-radius: 50px;
`;

interface IMobileForm {
    formik: any;
    ReloadContentHandler: any;
    hasProfileInfo: boolean;
    profileParameterData: any;
    loadCidadesPorUf: any;
    isLoading: boolean;
    cidadesPorUf: any;
    submitting: boolean;
}
const MobileForm = ({
    formik,
    ReloadContentHandler: reloadContentHandler,
    hasProfileInfo,
    profileParameterData,
    loadCidadesPorUf,
    isLoading,
    cidadesPorUf,
    submitting,
}: IMobileForm) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const isTipoFormacaoSemComplemento = (value: number) => {
        return (
            value === CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL ||
            value === CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL ||
            value === CODIGO_TIPO_FORMACAO_ENSINO_MEDIO
        );
    };

    const QuestionMark = () => {
        return (
            <QuestionMarkStyle
                onClick={() => {
                    setShowDialog(true);
                }}
            >
                <SvgIcon>
                    <path
                        d="M8.17248 5.3494C7.86213 5.29616 7.54296 5.35448 7.27149 5.51403C7.00003 5.67357 6.79378 5.92404 6.68929 6.22108C6.56711 6.56841 6.1865 6.75092 5.83917 6.62874C5.49185 6.50656 5.30933 6.12595 5.43151 5.77862C5.64049 5.18455 6.05298 4.68361 6.59592 4.36452C7.13885 4.04543 7.7772 3.92879 8.39789 4.03526C9.01859 4.14172 9.58157 4.46442 9.98714 4.9462C10.3926 5.42789 10.6146 6.0375 10.6137 6.66713C10.6134 7.68736 9.85703 8.36111 9.31687 8.72122C9.02645 8.91483 8.74077 9.0572 8.53033 9.15073C8.42416 9.19791 8.33476 9.23372 8.27034 9.25826C8.23807 9.27055 8.21191 9.28008 8.19287 9.28685L8.16972 9.29496L8.16234 9.29748L8.15974 9.29835L8.15872 9.29869C8.15872 9.29869 8.15789 9.29897 7.94707 8.66652L8.15789 9.29897C7.80859 9.41541 7.43105 9.22663 7.31461 8.87734C7.19826 8.52827 7.3867 8.151 7.73555 8.0343L7.74619 8.03056C7.75645 8.02692 7.77325 8.02082 7.79568 8.01228C7.84063 7.99515 7.90748 7.96846 7.98881 7.93231C8.15337 7.85917 8.36769 7.75154 8.57727 7.61182C9.037 7.30533 9.2804 6.97927 9.2804 6.66652L9.2804 6.66553C9.28087 6.35065 9.16989 6.04576 8.9671 5.80487C8.76432 5.56398 8.48283 5.40263 8.17248 5.3494Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M8.00033 10.6665C7.63213 10.6665 7.33366 10.965 7.33366 11.3332C7.33366 11.7014 7.63213 11.9998 8.00033 11.9998H8.00699C8.37518 11.9998 8.67366 11.7014 8.67366 11.3332C8.67366 10.965 8.37518 10.6665 8.00699 10.6665H8.00033Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984ZM8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984Z"
                        fill="#5F758B"
                    />
                </SvgIcon>
            </QuestionMarkStyle>
        );
    };

    const InfoDialog = () => {
        return (
            <>
                <Modal
                    show={showDialog}
                    onHide={() => {
                        setShowDialog(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    dialogAs={Container}
                >
                    <Modal.Body>
                        <InfoDialogTitle>{`Por que pedimos essa informação?`}</InfoDialogTitle>
                        <br />
                        <InfoDialogDescription>{`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.`}</InfoDialogDescription>
                        <br />
                        <InfoDialogDescription>{`Ele não será compartilhado com organizações externas.`}</InfoDialogDescription>
                        <InofoDialogButton
                            onClick={() => {
                                setShowDialog(false);
                            }}
                        >
                            Continuar Cadastro
                        </InofoDialogButton>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

    return (
        <>
            {/*Quando for usuário do lms solicita cpf */}
            {isUsuarioLms(usuarioLogado) ? (
                <>
                    <FormControl>
                        <FormLabel label="Meu CPF é " />
                        <FormikTextInput
                            validateCpf={true}
                            maxLength={14}
                            size={150}
                            name="cpf"
                            placeholder="Seu CPF"
                            onBlurHandler={() => {
                                reloadContentHandler(formik, 'cpf', 12, Books_2, 'Que bom ter você de volta!');
                            }}
                            formik={formik}
                            onChangeHandler={() => {}}
                        />
                        <QuestionMark />
                    </FormControl>
                </>
            ) : (
                <></>
            )}

            {((isUsuarioLms(usuarioLogado) && formik.touched.cpf) || hasProfileInfo || !isUsuarioLms(usuarioLogado)) && (
                <>
                    <FormControl>
                        <FormLabel label="Prefiro ser chamado(a) por" />
                    </FormControl>
                    <FormControl>
                        <FormikTextInput
                            placeholder="Seu nome"
                            size={315}
                            name="apelido"
                            onBlurHandler={() => {
                                reloadContentHandler(formik, 'apelido', 15, Books_2, 'Qual sua formação?');
                            }}
                            formik={formik}
                            onChangeHandler={() => {}}
                        />
                    </FormControl>
                </>
            )}

            {(formik.touched.apelido || hasProfileInfo) && (
                <>
                    <FormControl>
                        <FormLabel label="Meu maior nível de escolaridade concluído é " />
                    </FormControl>
                    <FormControl>
                        <FormikReactSelect
                            size={100}
                            sizeUnit="%"
                            name="tipoFormacao"
                            options={profileParameterData.listaTipoFormacao}
                            formik={formik}
                            onChangeHandler={async (value: number) => {
                                if (isTipoFormacaoSemComplemento(value)) {
                                    await formik.setFieldValue('areaFormacao', 0);
                                    reloadContentHandler(formik, 'tipoFormacao', 20, LocationImg, 'Onde você reside?');
                                } else {
                                    reloadContentHandler(formik, 'tipoFormacao', 20, Books_1, 'Qual sua formação?');
                                }
                            }}
                        />
                    </FormControl>
                </>
            )}

            {(formik.touched.tipoFormacao || hasProfileInfo) && (
                <>
                    {((formik.touched.tipoFormacao && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao'])) ||
                        (hasProfileInfo && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao']))) && (
                        <FormControl>
                            <FormLabel label=" em" />

                            <FormikCreatableInput
                                name="areaFormacao"
                                otherField="outraAreaFormacao"
                                size={100}
                                options={profileParameterData.listaAreasFormacao}
                                formik={formik}
                                onChangeHandler={() => {
                                    reloadContentHandler(formik, 'areaFormacao', 25, LocationImg, 'Onde você reside?');
                                }}
                            />
                        </FormControl>
                    )}
                </>
            )}

            {(formik.touched.areaFormacao ||
                (formik.touched.tipoFormacao && isTipoFormacaoSemComplemento(formik.values['tipoFormacao'])) ||
                hasProfileInfo) && (
                <FormControl>
                    <FormLabel label="Moro no estado de" />
                    <FormikReactSelect
                        placeholder="UF"
                        size={100}
                        filter={true}
                        sizeUnit="%"
                        name="estado"
                        options={profileParameterData.listaEstados}
                        formik={formik}
                        onChangeHandler={async (value: string) => {
                            await loadCidadesPorUf(value);
                            formik.setFieldValue('municipio', null);
                            reloadContentHandler(formik, 'estado', 30, LocationImg);
                        }}
                    />
                </FormControl>
            )}

            {((formik.touched.estado && !isLoading) || (!formik.touched.estado && hasProfileInfo && formik.values['municipio'])) && (
                <>
                    <FormControl>
                        <FormLabel label="no município de " />
                        <FormikReactSelect
                            size={100}
                            sizeUnit="%"
                            filter={true}
                            name="municipio"
                            options={cidadesPorUf}
                            formik={formik}
                            onChangeHandler={() => {
                                reloadContentHandler(formik, 'municipio', 35, LocationImg);
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel label=" no Brasil." />
                    </FormControl>

                    <SubmitButtonContainer>
                        <ContinuarButton
                            isDisabled={
                                (hasProfileInfo && !formik.isValid) ||
                                (!hasProfileInfo && !(formik.isValid && formik.dirty)) ||
                                formik.isSubmitting ||
                                submitting
                            }
                            label={`Continuar`}
                            onClickHandler={formik.handleSubmit}
                        />
                    </SubmitButtonContainer>
                </>
            )}

            {showDialog && <InfoDialog />}
        </>
    );
};

export default MobileForm;
