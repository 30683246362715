import TokenPE from 'models/acessoExclusivo/TokenPE';
import { GenericService } from '../Generic.service';
import TokenPE_DTO from 'models/acessoExclusivo/dtos/TokenPE_DTO';

export class TokenPEService extends GenericService {
    readonly URI: string = '/v1/tokenPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public getAllTokens() {
        return this.get('');
    }

    public updateToken(id: number, token: TokenPE_DTO) {
        return this.put('' + id, token);
    }

    public inserir(dto: TokenPE_DTO) {
        return this.post('', dto);
    }

    public resgate(dto: TokenPE_DTO, username: string) {
        return this.post('resgate', dto, { username: username });
    }
}
