import React from 'react';
import Menu from 'pages/menu/Menu';
import { Helmet } from 'react-helmet';
import Styled from './Biblioteca.styled';
import BibliotecaProvider from './context/BibliotecaProvider';
import BiblioFilterItem from './components/filter-item/BiblioFilterItem';
import BiblioResultGrid from './components/result-grid/BiblioResultGrid';
import BiblioContent from './components/biblio-content/BiblioContent';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface IBiblioteca {}

const Biblioteca = ({}: IBiblioteca) => {
    return (
        <>
            <Helmet>
                <title>A biblioteca da humane para o desenvolvimento de educadores</title>
                <meta
                    name="description"
                    content="Acesse os conteúdos do Instituto Ayrton Senna para apoio na formação e desenvolvimento de profissionais da educação"
                    data-react-helmet="true"
                />
            </Helmet>

            {/*  <Menu biblioteca /> */}
            <DynamicHeader />

            <BibliotecaProvider>
                <Styled.Wrapper>
                    <Styled.Container>
                        <BiblioContent />
                    </Styled.Container>
                </Styled.Wrapper>
            </BibliotecaProvider>
        </>
    );
};

export default Biblioteca;
