import DiarioTag from 'models/diarioBordo/DiarioTag';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import DiarioAttach from 'models/diarioBordo/DiarioAttach';
import DiarioBordoService from 'core/http/service/DiarioBordo.service';
import DiarioBordoAnexoService from 'core/http/service/DiarioBordoAnexo.service';

export const DIARY_UPDATE: string = 'DIARY_UPDATE';
export const DIARY_CLEAR_DATA = 'DIARY_CLEAR_DATA';
export const DIARY_GET_BY_ID: string = 'DIARY_GET_BY_ID';
export const DIARY_GET_ALL_TAGS: string = 'DIARY_GET_ALL_TAGS';
export const DIARY_CREATE_DIARY: string = 'DIARY_CREATE_DIARY';
export const DIARY_EXCLUDE_DIARY: string = 'DIARY_EXCLUDE_DIARY';
export const DIARY_INSERT_ATTACH: string = 'DIARY_INSERT_ATTACH';
export const DIARY_REMOVE_ATTACH: string = 'DIARY_REMOVE_ATTACH';
export const DIARY_GET_ALL_DIARIES: string = 'DIARY_GET_ALL_DIARIES';
export const DIARY_DUPLICATE_DIARY: string = 'DIARY_DUPLICATE_DIARY';

const sendToAnalytics = (where: string = '', error: any = null) => {
    console.error(where, error);
};

/**
 * Lista de DiarioTag
 * @returns Lista de tags para o diário de bordo
 */
export const getAllTags = () => {
    const service = new DiarioBordoService();

    return async (dispatch: any) => {
        try {
            const response = await service.findTags();
            const { data } = response;

            const output: DiarioTag[] = data.map((el: any) => new DiarioTag().fromJSON(el));

            dispatch({
                type: DIARY_GET_ALL_TAGS,
                tags: output,
            });
        } catch (error) {
            sendToAnalytics('getAllTags', error);
            throw error;
        }
    };
};

export const getDiaries = (idPerfil: number, query: string = '') => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            let response = await service.listarDiarios(idPerfil, query);
            const { data } = response;

            const output: DiarioBordo[] = data.map((el: any) => new DiarioBordo().fromJSON(el));

            let _current: DiarioBordo | null = null;
            if (output.length > 0) {
                const lastId = output[output.length - 1].id;
                response = await service.buscarPorId(lastId, idPerfil);
                _current = response.data;
            }

            dispatch({
                type: DIARY_GET_ALL_DIARIES,
                diaries: output,
                current: _current,
            });
        } catch (error) {
            sendToAnalytics('getAllDiaries', error);
            throw error;
        }
    };
};

export const criarRegistro = (profile: PerfilUsuario) => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            const response = await service.criarRegistro(profile);
            const { data } = response;

            const lastCreated: DiarioBordo = new DiarioBordo().fromJSON(data.novoRegistro);
            const output: DiarioBordo[] = data.listaAtualizada.map((el: any) => new DiarioBordo().fromJSON(el));

            dispatch({
                type: DIARY_CREATE_DIARY,
                diaries: output,
                current: lastCreated,
            });
        } catch (error) {
            sendToAnalytics('criarRegistro', error);
            throw error;
        }
    };
};

export const buscarPorId = (idDiario: number, idPerfil: number) => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            const response = await service.buscarPorId(idDiario, idPerfil);
            const { data } = response;

            const current: DiarioBordo = new DiarioBordo().fromJSON(data);

            dispatch({
                type: DIARY_GET_BY_ID,
                current: current,
            });
        } catch (error) {
            sendToAnalytics('buscarPorId', error);
            throw error;
        }
    };
};

export const atualizarDiario = (diario: DiarioBordo, idPerfil: number) => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            const response = await service.updateDiary(diario, idPerfil);
            const { data } = response;

            const current: DiarioBordo = new DiarioBordo().fromJSON(data);

            dispatch({
                type: DIARY_UPDATE,
                current: current,
            });
        } catch (error) {
            sendToAnalytics('atualizarDiario', error);
            throw error;
        }
    };
};

export const excluirDiario = (idDiario: number, idPerfil: number) => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            const response = await service.excluirDiario(idDiario, idPerfil);

            const { status } = response;
            if (status === 204) {
                dispatch({
                    type: DIARY_EXCLUDE_DIARY,
                    clearId: idDiario,
                });
            }
        } catch (error) {
            sendToAnalytics('excluirDiario', error);
            throw error;
        }
    };
};

export const duplicarDiario = (diario: DiarioBordo, idPerfil: number) => {
    const service = new DiarioBordoService();
    return async (dispatch: any) => {
        try {
            const response = await service.duplicarDiario(diario, idPerfil);

            const { data } = response;
            const current = new DiarioBordo().fromJSON(data);
            dispatch({
                type: DIARY_DUPLICATE_DIARY,
                current: current,
            });
        } catch (error) {
            sendToAnalytics('duplicarDiario', error);
            throw error;
        }
    };
};

//Chamadas de anexos

export const inserirAnexo = (idDiario: number, idPerfil: number, data: FormData) => {
    const service = new DiarioBordoAnexoService();

    return async (dispatch: any) => {
        try {
            const response = await service.inserirAnexo(idDiario, data, idPerfil);

            //verificar se é código 204
            if (response.status < 300) {
                const attach = new DiarioAttach().fromJSON(response.data);

                dispatch({
                    type: DIARY_INSERT_ATTACH,
                    idDiary: idDiario,
                    attach,
                });
            }
        } catch (error) {
            sendToAnalytics('inserirAnexo', error);
            throw error;
        }
    };
};

export const removerAnexo = (idDiario: number, idPerfil: number) => {
    const service = new DiarioBordoAnexoService();

    return async (dispatch: any) => {
        try {
            const response = await service.removerAnexo(idDiario, idPerfil);

            //verificar se é código 204
            if (response.status < 300) {
                dispatch({
                    type: DIARY_REMOVE_ATTACH,
                    attachId: idDiario,
                });
            }
        } catch (error) {
            sendToAnalytics('removerAnexo', error);
            throw error;
        }
    };
};

export const clearStore = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: DIARY_CLEAR_DATA,
            });
        } catch (error) {
            sendToAnalytics('clearStore', error);
            throw error;
        }
    };
};
