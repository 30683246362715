import React, { FunctionComponent } from 'react';

import styled from 'styled-components';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { TooltipCommon } from './Tooltip.common';

const renderTooltip = (content: string, props?: any) => <Tooltip {...props}>{content}</Tooltip>;

const TooltipHolder = styled.span`
    padding-left: 7px;
`;

const ToolTip: FunctionComponent<TooltipCommon.IToolTip> = ({
    message,
    children,
    placement = 'top',
    delayHide: hide = 500,
    delayShow: show = 1000,
}: React.PropsWithChildren<TooltipCommon.IToolTip>) => {
    return (
        <OverlayTrigger
            delay={{
                show,
                hide,
            }}
            placement={placement}
            overlay={renderTooltip(message)}
            trigger={['hover', 'focus', 'click']}
        >
            <TooltipHolder>{children}</TooltipHolder>
        </OverlayTrigger>
    );
};

export default ToolTip;
