import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';

import Img_01 from '../../assets/AlfaTeoriaPratica/modulo05/img_01.jpg';
import Img_02 from '../../assets/AlfaTeoriaPratica/modulo05/img_02.jpg';
import Img_03 from '../../assets/AlfaTeoriaPratica/modulo05/img_03.jpg';
import Img_04 from '../../assets/AlfaTeoriaPratica/modulo05/img_04.jpg';

//Graficos
import Grafico_01 from '../../assets/AlfaTeoriaPratica/modulo05/grafico_percentuais.png';

import CheckHability from 'pages/ebooks/components/CheckHability';
import { Content } from 'pages/home/components/contact/PrivacyPolicy.styled';

const ATP_Modulo05 = ({ setImgAmpliar, scrollToDivLink, modulos }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="05"
                titulo={`
            Práticas inspiradoras
            `}
                description={`
              Wanessa Ranielle Rodrigues Trajano Costa, Welen Freitas, <br />
            Joana Marques de Lima Saar Xavier e Vastí Ferrari Marques
            `}
            />

            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 59,
                }}
            >
                <Styled.Description>
                    Até aqui, apresentamos um{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[0], 1)}>
                        panorama histórico da alfabetização
                    </a>{' '}
                    no Brasil e das atualizações no campo das{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[1], 2)}>
                        neurociências
                    </a>{' '}
                    com o intuito de discutir aspectos teóricos e conceituais da{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[2], 3)}>
                        educação infantil
                    </a>{' '}
                    e da{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[3], 4)}>
                        {' '}
                        alfabetização integral
                    </a>
                    . Agora, vamos conhecer exemplos de integração desses conhecimentos na prática. Para tanto, este capítulo de fechamento
                    apresenta quatro práticas inspiradoras que estão transformando a alfabetização em diferentes regiões e contextos educacionais
                    brasileiros, mostrando que a inovação e o comprometimento podem gerar resultados significativos. Nosso objetivo aqui é
                    compartilhar ideias e promover a reflexão sobre formas de adaptar e incorporar os conhecimentos teóricos a cada contexto;
                    sabemos que cada realidade tem seus desafios e oportunidades e esperamos que as práticas apresentadas sirvam de inspiração
                    para educadores, gestores e formuladores de políticas públicas.
                    <br />
                    <br />
                    As duas primeiras práticas, desenvolvidas por professoras que atuam nos anos iniciais do ensino fundamental, demonstram que
                    metodologias diversificadas e adaptadas às necessidades dos estudantes podem impactar positivamente o processo de ensino e
                    aprendizagem. As experiências destacam a importância de intervenções pedagógicas que valorizem o protagonismo dos estudantes,
                    assim como da relação e do trabalho conjunto de diferentes áreas de conhecimento.
                    <br />
                    <br />
                    Vamos conhecer a primeira delas?
                    <br />
                    <br />
                    Durante o planejamento da reforma de ampliação da escola em que a professora Wanessa atua, ocorreu uma situação inusitada:
                    foi observada a necessidade de se demolir um muro, e os estudantes do 2º ano notaram que a obra afetaria uma colmeia de
                    abelhas-jataí. Muito conscientes dos impactos ambientais e mobilizados pelo bem-estar das abelhas, estudantes e professora
                    iniciaram um projeto para preservá-las. Você pode estar se perguntando: mas o que isso tem a ver com alfabetização? Damos a
                    palavra à professora Wanessa, que nos contará como conectou uma situação tão específica com a alfabetização de seus
                    estudantes.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 60,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        textAlign: 'end',
                    }}
                >
                    <Styled.TitleBlue>Meliponicultura na escola: protegendo as polinizadoras e o meio ambiente</Styled.TitleBlue>
                    <Styled.DividerYellow />
                    <Styled.TitleYellow style={{ marginTop: '20px' }}>Wanessa Ranielle Rodrigues Trajano Costa</Styled.TitleYellow>
                </div>
                <Styled.ContentBlue3>
                    Formada em Pedagogia pela Universidade Federal de Goiás (UFG), possui especialização em Alfabetização e Letramento pelo
                    Instituto Federal de Goiás (IFG). Desde 2014, atua como professora na rede municipal de educação na cidade de Aparecida de
                    Goiânia, no estado de Goiás.
                    <br />
                    <br />
                    <Styled.Img src={Img_01} onClick={() => setImgAmpliar(Img_01)} />
                    <br />
                    <br />O projeto teve início quando descobrimos abelhas-jataí habitando um muro do terreno adjacente à escola, que seria
                    demolido para a expansão das instalações escolares. Diante dessa situação, decidimos realizar o resgate das abelhas-jataí e
                    criar na escola um meliponário (criadouro racional de espécies de abelhas sem ferrão), proporcionando um ambiente seguro para
                    essas abelhas nativas. Desde então, as crianças têm a oportunidade de estudar diariamente o comportamento das abelhas,
                    monitorando suas atividades e aprendendo sobre a importância da preservação das polinizadoras para o equilíbrio ambiental.
                    <br />
                    <br />
                    Com esse projeto, oportunizamos a formação integral dos alunos promovendo:
                    <br />
                    <br />
                    <ul>
                        <li>a conscientização ambiental;</li>
                        <li>o desenvolvimento de habilidades científicas; e</li>
                        <li>o fortalecimento da alfabetização.</li>
                    </ul>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 61,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.Description>
                        A conscientização ambiental é fundamental para a formação de cidadãos responsáveis e conscientes do impacto de suas ações
                        no meio ambiente, enquanto o desenvolvimento de habilidades científicas prepara os estudantes para o pensamento crítico e
                        a resolução de problemas.
                        <br />
                        <br />A melhoria das habilidades de leitura e escrita é essencial para o sucesso acadêmico e profissional, e o
                        engajamento da comunidade escolar fortalece o vínculo entre a escola e a comunidade, criando um ambiente de apoio mútuo e
                        colaboração.
                        <br />
                        <br />
                    </Styled.Description>

                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais competências e habilidades foram mobilizadas?</Styled.TitleBlue>

                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências científicas</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Observação e pesquisa:</strong> habilidade de observar e documentar o comportamento das abelhas e as
                                características do seu hábitat
                            </li>
                            <li>
                                {' '}
                                <strong>Experimentação:</strong> realização de experimentos relacionados ao manejo das abelhas e ao estudo da
                                polinização
                            </li>
                            <li>
                                <strong>Análise de dados:</strong> coleta e interpretação dos dados científicos obtidos nas atividades do
                                projeto, com mediação dos professores
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências ambientais</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong> Conscientização ambiental:</strong> compreensão da importância da biodiversidade e dos serviços
                                ecossistêmicos prestados pelas abelhas
                            </li>
                            <li>
                                <strong> Sustentabilidade:</strong> aplicação de práticas sustentáveis e de baixo impacto ambiental na escola e
                                na comunidade
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências sociais e culturais</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Trabalho em equipe:</strong> colaboração entre os estudantes, professores e membros da comunidade escolas
                            </li>
                            <li>
                                <strong>Responsabilidade social:</strong> desenvolvimento de um senso de responsabilidade pela conservação
                                ambiental e pela proteção das abelhas nativas
                            </li>
                        </ul>

                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências comunicativas</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Comunicação oral e escrita:</strong> capacidade de comunicar os resultados das observações por meio de
                                textos e apresentações
                            </li>
                            <li>
                                <strong>Divulgação científica:</strong> habilidade de transmitir o conhecimento adquirido para a comunidade
                                escolar e para o público em geral Competências éticas
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 62,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências éticas</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Respeito pela vida:</strong> valorização e respeito pela vida das abelhas e pelo equilíbrio dos
                                ecossistemas
                            </li>
                            <li>
                                <strong>Consciência crítica:</strong> reflexão crítica sobre as ações humanas e seus impactos no meio ambiente
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências tecnológicas</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Uso de ferramentas tecnológicas:</strong> utilização de tecnologias para coleta de dados, pesquisa e
                                apresentação de resultados, como aplicativos de monitoramento e software de análise, com mediação dos professores
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                    <Styled.TitleBlue style={{ fontSize: 16, marginTop: '20px' }}>
                        Como essa prática se conecta com a alfabetização?
                    </Styled.TitleBlue>

                    <Styled.ContentWhite>
                        O projeto transversal se conecta com diferentes áreas do conhecimento e com componentes como Ciências, Geografia e
                        Matemática. Essa integração ajudou os estudantes a perceberem a alfabetização como uma competência ampla que se aplica a
                        diversos contextos. As seguintes atividades foram mobilizadoras de competências de leitura e escrita:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Leitura e escrita</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Relatórios e diários de campo:</strong> os estudantes foram incentivados a manter diários de campo onde
                                registraram suas observações diárias sobre as abelhas, suas atividades e o ambiente ao redor. Essa prática ajudou
                                a desenvolver habilidades de escrita descritiva e científica. Pesquisas e artigos: para entender melhor a
                                meliponicultura, os estudantes leram materiais informativos. Em seguida, escreveram seus próprios textos, de modo
                                a aprimorar as habilidades de leitura e escrita.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Vocabulário e conceitos científicos</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Terminologia específica:</strong> o projeto introduziu nos estudantes um novo vocabulário relacionado a
                                biologia, ecologia e meliponicultura. Termos como “polinização”, “biodiversidade”, “abelhas nativas” e
                                “sustentabilidade” ampliaram seu vocabulário e compreensão conceitual.
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 63,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Compreensão e interpretação de textos</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Leitura de material informativo:</strong> os estudantes leram materiais sobre meliponicultura e textos
                                educativos sobre a importância das abelhas, desenvolvendo suas habilidades de compreensão e interpretação de
                                textos.
                            </li>
                            <li>
                                <strong>Discussões e debates:</strong> as discussões em grupo sobre as leituras e as observações feitas
                                incentivaram a interpretação crítica e a expressão de ideias de forma clara e coerente.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Comunicação oral e apresentações</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Apresentações e seminários:</strong> os estudantes apresentaram suas descobertas e experiências para a
                                turma e para a comunidade escolar. Isso melhorou suas habilidades de comunicação oral e organização de
                                pensamento.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Produção de conteúdo</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Criatividade e narrativa:</strong> os estudantes criaram histórias, desenhos e outras formas de arte
                                inspiradas pelo trabalho com as abelhas, desenvolvendo habilidades narrativas e criativas que fazem parte do
                                processo de alfabetização.
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                    <Styled.TitleBlue style={{ fontSize: 16, marginTop: '20px' }}>Quais materiais foram utilizados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        A viabilização do projeto requereu o envolvimento coletivo de estudantes, professores e comunidade. Por meio de uma ação
                        integrada e coordenada, foi possível solucionar um problema iminente (risco às abelhas que estavam no muro adjacente à
                        escola) e, ainda, trabalhar conceitos básicos do ensino fundamental. Algumas das etapas e materiais importantes para o
                        desenvolvimento foram:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Planejamento e preparação inicial</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Definição do espaço:</strong> o projeto foi concebido como uma iniciativa educacional para introduzir a
                                meliponicultura na escola, visando proteger as abelhas polinizadoras e o meio ambiente.
                            </li>
                            <li>
                                <strong>Identificação dos participantes:</strong> a turma do 2º ano, composta por 26 estudantes, foi escolhida
                                como ponto de partida. Os professores e coordenadores pedagógicos foram envolvidos desde o início para planejar
                                as atividades e integrar o projeto ao currículo escolar
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 64,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Implementação da meliponicultura</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Construção do meliponário:</strong> com o suporte de meliponicultores locais, foi construído o
                                meliponário para abrigar abelhas nativas sem ferrão.
                            </li>
                            <li>
                                <strong>Introdução das abelhas:</strong> as colônias de abelhas foram introduzidas nos apiários, proporcionando
                                aos estudantes a oportunidade de aprender sobre o comportamento delas e os cuidados necessários para sua
                                manutenção.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Atividades educacionais e práticas</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Aulas teóricas e práticas:</strong> os estudantes participaram de aulas teóricas sobre a importância das
                                abelhas na polinização e na biodiversidade. Em seguida, realizaram atividades práticas, como a observação das
                                abelhas em seu meliponário e a coleta de dados sobre seu comportamento.
                            </li>
                            <li>
                                <strong>Manutenção das colmeias:</strong> os estudantes aprenderam a realizar a manutenção básica das colmeias,
                                incluindo a observação regular das colônias, o manejo correto e a coleta de mel quando apropriado.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Produção de materiais educativos</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Diários de campo e relatórios:</strong> cada estudante manteve um diário de campo para registrar suas
                                observações diárias e aprendizados. Além disso, todos produziram textos individuais sobre temas relacionados a
                                meliponicultura, como a importância das abelhas na agricultura.
                            </li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Eventos e divulgação comunitária</Styled.TitleYellow>
                        <ul>
                            <li>
                                <strong>Seminários e palestras:</strong> foram organizados seminários e palestras abertos à comunidade escolar,
                                nos quais os estudantes apresentaram seus projetos e compartilharam suas descobertas sobre meliponicultura.
                            </li>
                            <li>
                                <strong>Envolvimento da comunidade:</strong> foram organizados seminários e palestras abertos à comunidade
                                escolar, nos quais os estudantes apresentaram seus propais, membros da comunidade local e funcionários da obra de
                                reforma da escola foram convidados a participar de eventos específicos, aumentando o impacto do projeto para além
                                do ambiente escolar.
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 65,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: 16, marginTop: '20px' }}>Quais impactos foram observados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Por meio da revisão dos diários de campo dos estudantes, de reuniões, entrevistas e observação direta com todos os
                        envolvidos, avaliou-se e monitorou-se o progresso dos estudantes, a eficácia das atividades planejadas e o impacto do
                        projeto na conscientização ambiental e na alfabetização científica dos participantes. Também foi realizado um
                        acompanhamento junto aos professores e coordenadores com o objetivo de mapear e antecipar dificuldades na execução do
                        projeto. Com base nas observações destes, foram feitos ajustes nas atividades e estratégias do projeto para garantir que
                        os objetivos educacionais fossem alcançados de maneira eficaz. Por meio dessas ferramentas, foram observados alguns
                        impactos:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Aumento da conscientização ambiental:</Styled.TitleYellow>
                        os estudantes desenvolveram maior conscientização sobre a importância das abelhas nativas e seu papel na polinização e na
                        manutenção da biodiversidade. Além disso, o projeto incentivou a implementação de práticas sustentáveis na escola, como a
                        criação de hortas e a utilização de técnicas de manejo sustentável das abelhas. Essas práticas começaram a ser replicadas
                        na comunidade local.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>
                            Fortalecimento da alfabetização e desenvolvimento de habilidades científicas:
                        </Styled.TitleYellow>
                        a prática contribuiu para o desenvolvimento da leitura e escrita dos estudantes por meio da manutenção de diários de
                        campo, da produção de textos e de apresentações orais. Além disso, os estudantes adquiriram habilidades de observação,
                        pesquisa e experimentação científica, bem como um entendimento mais profundo de conceitos ecológicos e biológicos.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Engajamento da comunidade escolar:</Styled.TitleYellow>
                        houve aumento no engajamento da comunidade escolar e local na conservação ambiental, com uma maior participação em
                        atividades relacionadas ao projeto.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 66,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: 16, marginTop: '20px' }}>
                        Quais desafios foram encontrados e como foram transpostos?
                    </Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Ao longo do planejamento e execução do projeto, foram enfrentados desafios de diferentes tipos. Por exemplo:
                        <ul>
                            <li>necessidade de investimento em materiais específicos para a construção e instalação do meliponário;</li>
                            <li>planejamento adequado para a remoção e transporte das colônias, para garantir o bem-estar das abelhas; e</li>
                            <li>capacitação dos funcionários para o manejo e preservação das abelhas.</li>
                        </ul>
                        <br />
                        <br />
                        Além desses desafios, foi necessário estudar formas de integrar as atividades do projeto de maneira coesa e eficaz ao
                        currículo escolar e às diretrizes educacionais, considerando as dimensões administrativa e pedagógica e garantindo que as
                        atividades contribuíssem para o aprendizado acadêmico dos estudantes. Superar esses desafios exigiu trabalho em equipe,
                        perseverança e adaptação às diversas circunstâncias encontradas ao longo do processo.
                        <br />
                        <br />
                        No entanto, cada desafio proporcionou oportunidades valiosas de aprendizado e crescimento tanto para os estudantes quanto
                        para os educadores envolvidos no projeto.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 67,
                }}
            >
                <Styled.Description>
                    A prática inspiradora apresentada pela professora Wanessa nos mostra a importância de estarmos atentos ao nosso entorno e de
                    buscarmos ativamente a conexão entre conhecimento e o contexto em que estamos inseridos. Um fato que poderia ter passado
                    despercebido (a necessidade de remoção das abelhas-jataí) hoje tem espaço dentro da escola, dentro da sala de aula. Como a
                    própria professora Wanessa nos conta, foi necessária a mobilização de toda a escola em prol do projeto, o que evidencia a
                    importância do envolvimento dos diferentes agentes escolares, em suas diferentes funções.
                </Styled.Description>
                <Styled.ContentBlue3 style={{ borderRadius: '0px 20px 0px 20px' }}>
                    Que tal refletir sobre o seu contexto escolar? Quais situações poderiam se transformar em oportunidades para o trabalho com a
                    alfabetização? Quais outros conhecimentos poderiam ser integrados? Quem são os agentes escolares que poderiam apoiar você?
                </Styled.ContentBlue3>
                <Styled.Description>
                    Para oferecer ainda mais inspiração nessas reflexões e ampliar a diversidade de contextos em que é possível aliar demandas do
                    entorno e o processo de alfabetização, apresentamos a prática da professora Welen. Nela, vamos conhecer um projeto que se
                    iniciou com estudantes dos anos iniciais do ensino fundamental com o objetivo de fortalecer os conhecimentos em leitura,
                    escrita e compreensão de texto, se expandiu para a matemática e hoje faz parte do calendário escolar.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 68,
                }}
            >
                <Styled.ContentEnd>
                    <Styled.TitleBlue style={{ width: '350px' }}>Feira livre Palmira: educação financeira começa na escola</Styled.TitleBlue>
                    <Styled.DividerYellow />
                    <Styled.TitleYellow style={{ fontSize: '14px' }}>Welen Freitas</Styled.TitleYellow>
                </Styled.ContentEnd>
                <Styled.ContentBlue3>
                    Pedagoga, licenciada em Letras e especialista em Diversidade e Contexto Linguístico e também em Educação de Jovens e Adultos.
                    É professora na Secretaria Municipal de Educação e Cultura de Boa Vista, Roraima, com experiência na área de alfabetização e
                    letramento, além de organização e desenvolvimento de projetos para o ensino fundamental. Apaixonada pela educação e com
                    atuação na área desde 2011, busca a cada dia superar os desafios e contribuir para uma educação com qualidade e equidade.
                    <br />
                    <br />
                    <Styled.Img src={Img_02} onClick={() => setImgAmpliar(Img_02)} />
                    <br />
                    <br />A alfabetização matemática, conforme delineada em documentos curriculares, é parte da formação integral dos estudantes
                    no Brasil. O desenvolvimento das competências matemáticas vai além da simples memorização de fórmulas e cálculos, abrangendo
                    também o pensamento crítico, a resolução de problemas e a aplicação prática do conhecimento matemático no cotidiano, como em
                    jogos eletrônicos, no ato de fazer compras, em brincadeiras com dado, entre tantas outras situações que permeiam o dia a dia
                    de crianças, jovens e adultos.
                    <br />
                    <br />
                    Ao longo da alfabetização matemática, os estudantes são preparados para enfrentar desafios contemporâneos e se tornar
                    cidadãos mais conscientes e capazes de tomar decisões informadas, seja pelo raciocínio lógico, seja com base em constatações
                    alcançadas por meio de cálculos. O ensino da matemática deve seguir uma abordagem contextualizada e interdisciplinar visando
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 69,
                }}
            >
                <Styled.ContentBlue3>
                    não apenas o sucesso acadêmico, mas também o desenvolvimento de habilidades essenciais para a vida em sociedade, como a
                    criatividade, a capacidade de investigação e a interpretação de situações cotidianas que envolvem problemas, como, por
                    exemplo, cálculos de venda e troco ao realizar compras.
                    <br />
                    <br />
                    Orientado por essas ideias, o projeto apresentado a seguir teve como objetivo principal desenvolver a temática da educação
                    financeira em associação à sustentabilidade de longo prazo – a organização das finanças ao longo da vida e a preservação
                    ambiental pela utilização de objetos e materiais que não eram usados pelos estudantes e suas famílias e que estivessem em bom
                    estado para dar origem a novos objetos ou ser vendidos –, além de integrar diferentes habilidades e competências que
                    promovessem o fortalecimento e a recuperação da aprendizagem, oportunizando a alfabetização matemática vinculada à leitura e
                    à escrita para estudantes desde o 1º ano do ensino fundamental.
                    <br />
                    <br />O projeto pretendia superar os desafios dos alunos não alfabetizados estimulando-os com incentivos que mobilizassem
                    toda a turma e, posteriormente, toda a escola. Além disso, pretendia mitigar problemas do dia a dia e, assim, reduzir
                    barreiras na aprendizagem de inúmeros estudantes afetados por seus contextos de mundo.
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais competências e habilidades foram mobilizadas?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Competências da BNCC em destaque:
                        <br />
                        <br />
                        <ul>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>Competências gerais</Styled.TitleYellow>
                            <li>
                                <strong>10.</strong> Agir pessoal e coletivamente com autonomia, responsabilidade, flexibilidade, resiliência e
                                determinação, tomando decisões com base em princípios éticos, democráticos, inclusivos, sustentáveis e solidários
                                Competências específicas de Linguagens para o ensino fundamental
                            </li>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>
                                Competências específicas de Linguagens para o ensino fundamental
                            </Styled.TitleYellow>
                            <li>
                                <strong>2.</strong> Apropriar-se da linguagem escrita, reconhecendo-a como forma de interação nos diferentes
                                campos de atuação da vida social e utilizando-a para ampliar suas possibilidades de participar da cultura
                                letrada, de construir conhecimentos (inclusive escolares) e de se envolver com maior autonomia e protagonismo na
                                vida social
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 70,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <ul>
                            <li>
                                <strong>3.</strong> Ler, escutar e produzir textos orais, escritos e multissemióticos que circulam em diferentes
                                campos de atuação e mídias, com compreensão, autonomia, fluência e criticidade, de modo a se expressar e
                                partilhar informações, experiências, ideias e sentimentos, e continuar aprendendo
                            </li>
                            <li>
                                <strong>4.</strong> Utilizar diferentes linguagens para defender pontos de vista que respeitem o outro e promovam
                                os direitos humanos, a consciência socioambiental e o consumo responsável em âmbito local, regional e global,
                                atuando criticamente frente a questões do mundo contemporâneo Competências específicas de Matemática para o
                                ensino fundamental
                            </li>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>
                                Competências específicas de Matemática para o ensino fundamental
                            </Styled.TitleYellow>
                            <li>
                                <strong>2.</strong> Desenvolver o raciocínio lógico, o espírito de investigação e a capacidade de produzir
                                argumentos convincentes, recorrendo aos conhecimentos matemáticos para compreender e atuar no mundo
                            </li>
                            <li>
                                <strong>7.</strong> Desenvolver e/ou discutir projetos que abordem, sobretudo, questões de urgência social, com
                                base em princípios éticos, democráticos, sustentáveis e solidários, valorizando a diversidade de opiniões de
                                indivíduos e de grupos sociais, sem preconceitos de qualquer natureza Competências específicas de Ciências da
                                Natureza para o ensino fundamental
                            </li>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>
                                Competências específicas de Ciências da Natureza para o ensino fundamental
                            </Styled.TitleYellow>
                            <li>
                                <strong>8.</strong> Agir pessoal e coletivamente com respeito, autonomia, responsabilidade, flexibilidade,
                                resiliência e determinação, recorrendo aos conhecimentos das Ciências da Natureza para tomar decisões frente a
                                questões científicotecnológicas e socioambientais e a respeito da saúde individual e coletiva, com base em
                                princípios éticos, democráticos, sustentáveis e solidários
                            </li>
                        </ul>
                        <br />
                        <br />
                        Habilidades da BNCC em destaque:
                        <br />
                        <br />
                        <ul>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>Língua Portuguesa</Styled.TitleYellow>
                            <li>
                                <strong>(EF12LP01)</strong> Ler palavras novas com precisão na decodificação, no caso de palavras de uso
                                frequente, ler globalmente, por memorização
                            </li>
                            <li>
                                <strong>(EF01LP02)</strong> Escrever, espontaneamente ou por ditado, palavras e frases de forma alfabética –
                                usando letras/grafemas que representem fonemas
                            </li>
                            <li>
                                <strong>(EF02LP01)</strong> Utilizar, ao produzir o texto, grafia correta de palavras conhecidas ou com
                                estruturas silábicas já dominadas, letras maiúsculas em início de frases e em substantivos próprios, segmentação
                                entre as palavras, ponto final, ponto de interrogação e ponto de exclamação
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 71,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <ul>
                            <li>
                                <strong>(EF01LP05)</strong> Reconhecer o sistema de escrita alfabética como representação dos sons da fala
                            </li>
                            <li>
                                <strong>(EF01LP08)</strong> Relacionar elementos sonoros (sílabas, fonemas, partes de palavras) com sua
                                representação escrita
                            </li>
                            <li>
                                <strong>(EF01LP04)</strong> Distinguir as letras do alfabeto de outros sinais gráficos
                            </li>
                            <li>
                                <strong>(EF12LP04)</strong> Ler e compreender, em colaboração com os colegas e com a ajuda do professor ou já com
                                certa autonomia, listas, agendas, calendários, avisos, convites, receitas, instruções de montagem (digitais ou
                                impressos), dentre outros gêneros do campo da vida cotidiana, considerando a situação comunicativa e o
                                tema/assunto do texto e relacionando sua forma de organização à sua finalidade
                            </li>
                        </ul>
                        <ul>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>Matemática</Styled.TitleYellow>
                            <li>
                                <strong>(EF01MA02)</strong> Contar de maneira exata ou aproximada, utilizando diferentes estratégias como o
                                pareamento e outros agrupamentos
                            </li>
                            <li>
                                <strong>(EF01MA16)</strong> Relatar em linguagem verbal ou não verbal sequência de acontecimentos relativos a um
                                dia, utilizando, quando possível, os horários dos eventos
                            </li>
                            <li>
                                <strong>(EF01MA08)</strong> Resolver e elaborar problemas de adição e de subtração, envolvendo números de até
                                dois algarismos, com os significados de juntar, acrescentar, separar e retirar, com o suporte de imagens e/ou
                                material manipulável, utilizando estratégias e formas de registro pessoais
                            </li>
                            <li>
                                <strong>(EF09MA05)</strong> Resolver e elaborar problemas que envolvam porcentagens, com a ideia de aplicação de
                                percentuais sucessivos e a determinação das taxas percentuais, preferencialmente com o uso de tecnologias
                                digitais, no contexto da educação financeira
                            </li>
                        </ul>
                        <ul>
                            <Styled.TitleYellow style={{ fontSize: 14 }}>Geografia</Styled.TitleYellow>
                            <li>
                                <strong>(EF05GE11)</strong> Identificar e descrever problemas ambientais que ocorrem no entorno da escola e da
                                residência (lixões, indústrias poluentes, destruição do patrimônio histórico etc.), propondo soluções (inclusive
                                tecnológicas) para esses problemas
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 72,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Como essa prática se conecta com a alfabetização?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        O projeto “Feira livre Palmira: educação financeira começa na escola” deu seus primeiros passos objetivando incentivar os
                        estudantes no desenvolvimento de suas habilidades de leitura e escrita e de sua fluência leitora. A cada avanço observado
                        na fluência leitora, na escrita ou na compreensão textual, os estudantes ganhavam uma cédula fictícia com valor de R$
                        2,00 e, ao final do mês, havia uma feirinha em sala com produtos etiquetados que os estudantes podiam comprar com o
                        dinheiro fictício. Nesse contexto, também eram trabalhados conceitos matemáticos. O projeto foi ganhando espaço e
                        incluindo outros estudantes ainda não alfabetizados que se sentiram motivados a participar.
                        <br />
                        <br />
                        Com o tempo, ele foi ampliado de modo a abarcar toda a escola: cada turma abordou uma área de conhecimento dentro das
                        macroáreas, cujas temáticas foram organizadas por títulos e campos de saber pertinentes aos anos/ turmas (conforme
                        orientação da BNCC). Dessa forma, ainda houve expansão do foco de atuação, que incluiu, além da alfabetização, outros
                        temas relevantes, como meio ambiente e sustentabilidade.
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais materiais foram utilizados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        O projeto tem alcance dentro e fora da sala de aula, mobilizando toda a comunidade escolar. Portanto, cada prática teve
                        um recorte e um alcance previamente planejados e envolveu atividades e materiais como:
                        <br />
                        <br />
                        <ul>
                            <li>Criação de jogos matemáticos</li>
                            <li>Criação de jogos para alfabetização</li>
                            <li>Oficinas de formação sobre consciência ecológica e preservação do ambiente</li>
                            <li>Oficinas de formação em educação financeira</li>
                            <li>Produção do dinheiro a ser entregue aos estudantes</li>
                            <li>Desenvolvimento de uma planilha de ações para que os estudantes ganhassem o dinheirinho</li>
                            <li>Confecção dos objetos a serem vendidos</li>
                            <li>Organização da feirinha</li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 73,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais impactos foram observados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        O projeto teve como objetivo promover, de forma sustentável, a alfabetização em leitura, escrita e matemática e a
                        educação financeira, além do trabalho interdisciplinar e contextualizado, e, conforme se expandiu para além da sala de
                        aula, também foi observada a expansão de seus impactos na comunidade. Foi criada uma{' '}
                        <a
                            href="#"
                            onClick={() =>
                                window.open(
                                    'https://institutoas-my.sharepoint.com/:w:/g/personal/arquivosias_ias_org_br/EVBU58kfPLRFk25CE-gMWcYBNX9R8xrJ7szvD8CMGBKVJA?rtime=ceLUtETX3Eg'
                                )
                            }
                        >
                            planilha de acompanhamento
                        </a>{' '}
                        para os estudantes não alfabetizados e foi elaborado um plano de ação para desenvolvimento da feira. Alguns dos
                        principais impactos foram:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Alfabetização e educação financeira:</Styled.TitleYellow>
                        por meio dos conhecimentos trabalhados em sala de aula em jogos de alfabetização matemática, letramento, leituras
                        compartilhadas, leitura e interpretação de problemas etc., os estudantes foram alfabetizados e puderam vivenciar a
                        leitura, a escrita e a matemática de forma contextualizada, conscientizadora e formadora. Estimulamos o empreendedorismo
                        e a consciência de investir em materiais acessíveis para se ter um retorno financeiro e ensinamos a negociar, receber
                        dinheiro, calcular troco etc.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Criticidade:</Styled.TitleYellow> o projeto ofereceu oportunidade de
                        aprendizado, conhecimento e pensamento crítico acerca da educação.
                        <br />
                        <br />
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 74,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Motivação e mobilização:</Styled.TitleYellow>
                        as ações do projeto contribuíram para que os estudantes reconhecessem seu trabalho e tivessem disposição e criatividade
                        para pensar novas fontes de renda, além de promover o desenvolvimento da leitura e da escrita.
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais desafios foram encontrados e como foram transpostos?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        O maior desafio, com certeza, foi alfabetizar aqueles que apresentavam defasagens de aprendizagem, ajudando-os na
                        compreensão e desenvolvimento da escrita e leitura alfabética e matemática. Outro dos desafios iniciais foi conscientizar
                        os estudantes sobre sua capacidade de mudar a própria realidade e expandir essa compreensão para seus lares. Além disso,
                        aliar a promoção da educação financeira por meio da matemática à temática da sustentabilidade (reutilizar e reciclar
                        materiais e objetos para ter retorno financeiro) foi algo que demandou esforço e tempo de dedicação para pensar boas
                        estratégias pedagógicas. Hoje, os desafios foram contornados e a feira passou a fazer parte do calendário anual da
                        escola, tornando-se referência para a comunidade escolar pela oportunidade de benefício financeiro, mas principalmente
                        pela aprendizagem dos estudantes.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 75,
                }}
            >
                <Styled.Description>
                    Se na prática da professora Wanessa havia uma situação emergente, na prática apresentada pela professora Welen havia um
                    cenário já conhecido na escola e na comunidade, em que os estudantes tinham dificuldades e defasagens na alfabetização. O
                    ponto de partida foi a busca por estratégias para mitigar esses problemas e incentivar os estudantes a pensarem suas
                    realidades de forma crítica e protagonista. Mais uma vez, reafirmamos a necessidade de realizar um trabalho que integre
                    escola e comunidade externa. A professora Welen explicita a importância do papel de todos os educadores e também do entorno
                    da escola na concretização do projeto.
                    <br />
                    <br />A defasagem na alfabetização observada por ela ainda é uma realidade em muitos contextos. No capítulo{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[0], 1)}>
                        “Caminhos da alfabetização no Brasil: uma análise histórica”
                    </a>{' '}
                    , vimos que, embora tenha havido avanços importantes no país, ainda nos encontramos em um cenário de vulnerabilidade quanto
                    ao exercício pleno do direito à educação, de modo que há um caminho a ser trilhado rumo à estabilização de diretrizes e
                    programas nacionais. Em tal cenário, são práticas inspiradoras como as trazidas aqui que apoiam e contribuem para uma
                    educação mais acessível e transformadora.
                    <br />
                    <br />A próxima prática inspiradora que apresentaremos tem conexão com o que foi abordado nos capítulos{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[1], 2)}>
                        “Alfabetização e neurociências ”
                    </a>{' '}
                    e{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[2], 3)}>
                        “Educação infantil: a fase de cuidar do broto”
                    </a>
                    . Desenvolvida pela professora Joana, ela acontece na educação infantil e emprega diversos recursos abordados nos capítulos
                    referenciados. Além disso, associa atividades ligadas ao uso mediado de recursos gráficos e compreensão de texto a uma
                    abordagem centrada na educação antirracista. Vamos conhecê-la?
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 76,
                }}
            >
                <Styled.ContentEnd>
                    <Styled.TitleBlue>
                        Práticas pedagógicas antirracistas e educação infantil: caminhos para a (re)construção das identidades de crianças bem
                        pequenas
                    </Styled.TitleBlue>
                    <Styled.DividerYellow />
                    <Styled.TitleYellow style={{ fontSize: '14px' }}>Joana Marques de Lima Saar Xavier</Styled.TitleYellow>
                </Styled.ContentEnd>
                <Styled.ContentBlue3>
                    Licenciada em História e em Pedagogia e especialista em Educação para as Relações Étnico-Raciais; professora de História
                    desde os 22 anos e, atualmente, docente da Educação Infantil-creche; membra consultiva da Comissão de Igualdade Racial da
                    OAB/Acre; pesquisadora associada à Associação Brasileira de Pesquisadores(as) Negros e Negras (ABPN); finalista da 2ª edição
                    do Prêmio Educador Transformador, tendo ficado em terceiro lugar na etapa nacional na categoria Educação Infantil.
                    <br />
                    <br />
                    <Styled.Img src={Img_03} onClick={() => setImgAmpliar(Img_03)} />
                    <br />
                    <br />
                    Todas as etapas da educação devem contribuir significativamente para a formação cidadã como um direito humano previsto em lei
                    (Lei n° 9.394/1996). Esse direito deve ser garantido desde a educação infantil, momento em que a criança faz seu primeiro
                    contato formal com a educação. A promoção da formação cidadã deve ser atravessada pelo trabalho com as relações
                    étnico-raciais de maneira contínua e desde os primeiros anos da educação básica, de forma a garantir que as crianças tenham a
                    oportunidade de ampliar seus saberes, suas experiências e seus conhecimentos com vista a respeitar e valorizar a diversidade
                    étnico-racial.
                    <br />
                    <br />A prática inspiradora a seguir tem esses preceitos como alicerce, a fim de contribuir para a garantia do direito a uma
                    educação inicial de qualidade, que prepare a criança e desperte seu interesse pelo conhecimento, assim como do direito à
                    diferença e à identidade. Por meio de atividades pedagógicas
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 77,
                }}
            >
                <Styled.ContentBlue3>
                    planejadas ao longo do ano, a prática aqui apresentada focou a valorização das histórias e culturas africanas,
                    afro-brasileiras e dos povos indígenas brasileiros, com o intuito de promover a igualdade racial e o combate ao racismo desde
                    a educação infantil, proporcionando que as crianças desenvolvessem o interesse por esses aprendizados, necessários para sua
                    formação pessoal e cidadã.
                    <br />
                    <br />
                    Seus objetivos foram:
                    <br />
                    <br />
                    <ul>
                        <li>
                            possibilitar à criança conhecimento de si mesma e de sua história de vida pela apropriação de sua história familiar e
                            identidade étnico-racial;
                        </li>
                        <li>desenvolver uma visão positivada sobre sua identidade, ancestralidade e cultura;</li>
                        <li>oportunizar o contato com diferentes culturas e modos de vida;</li>
                        <li>trabalhar a capacidade de respeitar a identidade do outro, de seus semelhantes e do meio que a cerca;</li>
                        <li>estimular relações saudáveis e afetivas entre todas as crianças; e</li>
                        <li>ouvir, interessar-se, recontar e criar histórias e situações.</li>
                    </ul>
                    <br />
                    As propostas planejadas estavam em conformidade com o projeto institucional da creche, chamado “A beleza da cor”, e com as
                    orientações do “Currículo de Referência Único do Acre – Educação Infantil”; e eram pautadas pelo entrelaçamento de campos de
                    experiências da BNCC (o eu, o outro e o nós; corpo, gestos e movimentos; traços, sons, cores e formas; escuta, fala,
                    pensamento e imaginação; e espaços, tempos, quantidades, relações e transformações) e, através das interações e das
                    brincadeiras, pelos direitos de conviver, brincar, participar, explorar, expressar-se e conhecer-se numa perspectiva
                    étnico-racial.
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 78,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais competências e habilidades foram mobilizadas?</Styled.TitleBlue>

                    <Styled.ContentWhite>
                        <Styled.Row style={{ flexWrap: "wrap" }}>
                            <CheckHability label="Autoconhecimento" />
                            <CheckHability label="Autocuidado" />
                            <CheckHability label="Empatia" />
                            <CheckHability label="Autoconfiança" />
                            <CheckHability label="Cooperação" />
                            <CheckHability label="Comunicação" />
                            <CheckHability label="Autoestima" />
                            <CheckHability label="Conhecimento" />
                            <CheckHability label="Autopercepção" />
                            <CheckHability label=" Construção de identidade" />
                            <CheckHability label="Socialização de saberes" />
                            <CheckHability label="Respeito" />
                            <CheckHability label="Valorização dos pertencimentos étnico-raciais" />
                            <CheckHability label="Repertório cultural numa perspectiva étnico-racial" />
                            <CheckHability label="Exercício da cidadania" />
                            <CheckHability label="Pensamento científico" />
                            <CheckHability label="Pensamento crítico" />
                            <CheckHability label="Pensamento criativo" />
                        </Styled.Row>
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Como essa prática se conecta com a alfabetização?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        A atividade tem como princípio o desenvolvimento do letramento na educação infantil. Conforme a Base Nacional Comum
                        Curricular, o letramento é condição para a alfabetização. Por meio das práticas de letramento oportunizadas nas leituras
                        compartilhadas sobre as histórias e culturas africanas, afro-brasileiras e indígenas, nas interações e nas brincadeiras
                        na educação infantil, a prática aqui relatada buscou contribuir para que as crianças pudessem, numa perspectiva
                        étnico-racial, desenvolver uma alfabetização integral.
                        <br />
                        <br />O letramento foi trabalhado incentivando-se as crianças a refletir sobre suas próprias identidades e
                        pertencimentos, assim como promovendo-se a criação
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 79,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        de narrativas e a recontação das histórias ouvidas. Essa abordagem permitiu que as crianças se conectassem de forma mais
                        profunda com os conhecimentos compartilhados e expressassem suas compreensões por meio de diversas linguagens,
                        fortalecendo, assim, suas habilidades de leitura e escrita dentro de um contexto culturalmente significativo. A prática
                        aqui relatada buscou contribuir para que as crianças pudessem, dentro da mencionada perspectiva étnico-racial e de
                        desenvolvimento da alfabetização integral:
                        <br />
                        <br />
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            familiarizar -se com o mundo letrado, realizando leitura não convencional em diferentes portadores textuais;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            desenvolver, gradativamente, a compreensão sobre as relações entre a fala e a escrita, por meio da observação do
                            outro nos diversos contextos;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            compreender que desenhos, letras e outros sinais gráficos são formas de comunicação;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            compreender, gradativamente, o que a escrita representa e como é representada;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            desenvolver comportamento de escritor ao fazer de conta que escreve, imitando o adulto;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            desafiar-se a produzir, autonomamente, comunicações escritas não convencionais (garatujas), manuseando diferentes
                            instrumentos e suportes de escrita;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>conhecer a história do seu nome e o dos colegas;</Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            ler e escrever livremente o próprio nome e o nome dos colegas;
                        </Styled.ContentBlue3>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 80,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            compreender, gradativamente, a função dos diferentes portadores textuais, fazendo uso deles no cotidiano;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            produzir escritas espontâneas em brincadeiras de faz de conta, tendo como base os diferentes portadores textuais;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>brincar de escrever;</Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            folhear livros, contando suas histórias para os colegas;
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>escolher e selecionar livros para ler;</Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            presenciar situações significativas de leitura e escrita.
                        </Styled.ContentBlue3>
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: '16px' }}>Quais materiais foram utilizados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Os materiais adquiridos e produzidos formam o “Cantinho étnico-racial”, que é compartilhado com outras professoras para
                        disseminar e fortalecer o trabalho com o tema. Cada prática pedagógica foi planejada com base na escuta atenta às
                        expressões das crianças e envolveu atividades e materiais como:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>• Rodas de conversas sobre…</Styled.TitleYellow>
                        <ul>
                            <li>África: berço da humanidade, das tecnologias e dos conhecimentos</li>
                            <li>
                                Identidade, com uso de bonecas e bonecos de diferentes pertencimentos, lápis de cor e giz de cera com as
                                diversidades de tons de pele, espelho
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 81,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <ul>
                            <li>Diversidade cultural e respeito às diferenças</li>
                            <li>
                                Ancestralidade, cabelos, penteados e tranças, usando as obras A Rainha Dandara e a beleza dos cabelos crespos e
                                Cada um com seu jeito, cada jeito é de um!
                            </li>
                            <li>Povos originários do Acre, por meio do uso de imagens, pulseiras com kenês e vídeos</li>
                            <li>Quilombos</li>
                            <li>
                                Ancestralidade e meio ambiente, em conversas sobre as árvores baobá e sumaúma – usamos vídeo sobre a história da
                                árvore ancestral baobá e da árvore ancestral sumaúma
                            </li>
                            <li>Cuidados com o meio ambiente e os povos originários, com produção de arte com uso de folhas caídas</li>
                        </ul>

                        <Styled.TitleYellow style={{ fontSize: 14 }}>• África, por meio de imagens e vídeos</Styled.TitleYellow>
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>
                            • Leituras de obras de literatura africana, afro-brasileira e indígena, como…
                        </Styled.TitleYellow>
                        <ul>
                            <li>Ubuntu, de Sílvia Moral (editora Santillana)</li>
                            <li>A Lua e o Sol, de Fabio Gonçalves Ferreira (editora Cedic)</li>
                            <li>Turma da Mônica Lendas Brasileiras – Iara, de Mauricio de Sousa (editora Girassol)</li>
                            <li>Poeminhas da Terra, de Márcia Leite (editora Pulo do Gato)</li>
                            <li>O Tupi que você fala, de Cláudio Fragata (editora Globinho)</li>
                            <li>Poeminhas da Minha Terra Tupi, de Maté (editora Brinque-book)</li>
                            <li>Encanto, de Tea Stilton (editora Planeta)</li>
                            <li>A aventura de Abaré, de Juliana Schroden (editora FTD)</li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 82,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <ul>
                            <li>A Rainha Dandara e a beleza dos cabelos crespos, de Dayse Cabral de Moura (editora UFPE)</li>
                            <li>As bonecas da vó Maria, de Mel Duarte (editora Itaú)</li>
                            <li>Pantera Negra, de Stan Lee (editora Melhoramentos)</li>
                            <li>Princesa Moana, de Ron Clements e John Musker (editora Melhoramentos)</li>
                            <li>Cada um com seu jeito, cada jeito é de um!, de Lucimar Rosa Dias (editora Alvorada)</li>
                            <li>O Pequeno Príncipe Preto, de Rodrigo França (editora Nova Fronteira)</li>
                            <li>Doutora Brinquedos, de vários autores (editora DCL)</li>
                            <li>Outros Contos Africanos, de Rogério Andrade Barbosa (editora Paulinas)</li>
                            <li>Meu Crespo é de Rainha, de bell hooks (editora Boitatá)</li>
                            <li>Amoras, de Emicida (editora Companhia das Letrinhas)</li>
                            <li>Amor de Cabelo, de Matthew A. Cherry (editora Galerinha)</li>
                            <li>Os olhos de jaguar, de Yaguarê Yamã (editora Jujuba)</li>
                            <li>A descoberta do Adriel, de Mel Duarte (editora Itaú)</li>
                        </ul>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>
                            • Brinquedos e animações com personagens africanos, afro-brasileiros e indígenas
                        </Styled.TitleYellow>
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>• Brincadeiras de origens africanas e indígenas</Styled.TitleYellow>
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>
                            • Músicas que trazem a cultura africana, afro-brasileira e indígena
                        </Styled.TitleYellow>
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>• Danças de origem africana, afro-brasileira e indígena</Styled.TitleYellow>
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>• Produção de bonecas abayomis</Styled.TitleYellow>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 83,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais impactos foram observados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Quando oportunizamos experiências que trazem a beleza que há na diversidade étnico-racial, estamos garantindo o direito à
                        diferença e auxiliando as crianças a ampliarem o modo como percebem a si mesmas e os outros; a valorizarem seus
                        pertencimentos, ancestralidades, identidades e modos de vida; a respeitarem a si e os outros; e a crescerem em um mundo
                        harmônico, justo, com menos desigualdades e com respeito às diferenças étnico-raciais, culturais e sociais.
                        <br />
                        <br />
                        Por meio do registro das conquistas, avanços e aprendizados das crianças, feito em cada experiência pela professora e
                        coordenadora pedagógica, foi possível observar impactos importantes:
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Identidade:</Styled.TitleYellow>
                        aumento na autoestima, principalmente entre as crianças negras. As crianças passaram a gostar de suas cores, seus cabelos
                        e outros fenótipos – algo que antes era pouco visto nas turmas.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Relacionamentos:</Styled.TitleYellow>
                        afeto, elogios, autocuidado, melhora na forma como as crianças se relacionam, cuidam umas das outras, interagem e
                        brincam.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Relações étnico-raciais:</Styled.TitleYellow>
                        valorização da ancestralidade e das culturas africanas, afro-brasileiras e indígenas e interesse pelas histórias sobre
                        essas culturas. As crianças pediam a recontação e a releitura das histórias, e as famílias comentavam sobre as histórias
                        lidas e contadas, porque as crianças contavam em casa o que tinham aprendido na instituição.
                        <br />
                        <br />
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Letramento e alfabetização:</Styled.TitleYellow>
                        as crianças eram incentivadas a reler e recontar as histórias e a criar suas próprias narrativas para os brinquedos, para
                        os colegas e em casa, com base no que haviam compreendido. Além disso, foram motivadas a buscar outros livros para ler,
                        fosse para si mesmas, para os colegas ou para os brinquedos.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 84,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais desafios foram encontrados e como foram transpostos?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Um desafio constante é significar e reconhecer a educação infantil como uma etapa crucial no letramento e na
                        alfabetização, inserindo a criança no mundo do conhecimento e fortalecendo suas múltiplas dimensões. Na prática aqui
                        descrita, além desse desafio, enfrentamos a escassez de recursos, incluindo a ausência de brinquedos e livros que
                        reflitam a beleza da diversidade e dos diferentes pertencimentos étnico-raciais nas unidades educativas; diante disso,
                        foi necessário um investimento próprio para a aquisição e produção de brinquedos, livros e outros materiais.
                        <br />
                        <br />
                        Apesar disso, há um compromisso da gestão da creche com a educação antirracista, de modo que, quando utiliza recursos
                        próprios para a compra de materiais, ela o faz numa perspectiva étnico-racial (livros, brinquedos, giz de cera, lápis de
                        cor etc.). Essa percepção é resultado das formações continuadas ofertadas para todos os servidores da instituição.
                        Portanto, a mitigação dos desafios passa pelo investimento em formações continuadas em educação com foco em relações
                        étnico-raciais, com vista a um atendimento qualitativo e respeitoso, assim como pelo apoio de políticas e instituições na
                        aquisição de materiais e na utilização dos recursos sob uma perspectiva étnico-racial.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 85,
                }}
            >
                <Styled.ContentWhite>
                    A prática compartilhada pela professora Joana ressalta a importância de iniciar a construção do conhecimento de forma
                    inclusiva e crítica desde os primeiros anos de escolarização. Ela se destaca por integrar ao currículo temas de diversidade
                    étnico-racial, promovendo uma educação que valorize todas as culturas e contribua para a formação de cidadãos conscientes e
                    respeitosos das diferenças. Tudo isso atrelado a recursos para promover a alfabetização considerando as características das
                    crianças na educação infantil e a importância da aproximação delas à leitura e à escrita.
                    <br />
                    <br />
                    Essas ideias e associações entre diversos temas de relevância para a formação plena do indivíduo também foram discutidas no
                    capítulo{' '}
                    <a href="#" onClick={() => scrollToDivLink && scrollToDivLink(modulos[3], 4)}>
                        {' '}
                        “Alfabetização integral”
                    </a>
                    , no qual foram propostas reflexões sobre as múltiplas linguagens presentes na alfabetização integral. Quais outras reflexões
                    são suscitadas pela associação desse capítulo com a prática da professora Joana? Como você adaptaria essa prática para outros
                    anos do ciclo básico?
                    <br />
                    <br />
                    Podemos observar que as três práticas apresentadas até aqui têm algo em comum para além da temática da alfabetização. Você já
                    identificou o que é? Independentemente da região do país ou do contexto em que as práticas foram desenvolvidas, todas abordam
                    a necessidade da participação dos diferentes agentes educacionais. O professor tem papel essencial na captação de temáticas,
                    na implementação de práticas, no monitoramento dos estudantes. Porém, essas ações não podem ser realizadas de forma
                    aprofundada se o professor estiver sozinho. A equipe gestora da escola e da rede (seja municipal, estadual, privada etc.)
                    precisa ser parceira na construção e implementação dessas práticas.
                    <br />
                    <br />A última prática inspiradora apresentada neste capítulo aborda a perspectiva da gestão de rede, nesse caso, municipal,
                    voltada para a temática da alfabetização. A iniciativa revela como a administração escolar pode atuar de forma estratégica
                    para apoiar e potencializar as ações pedagógicas nas escolas. Além disso, destaca a importância de um planejamento integrado
                    e de políticas públicas efetivas, que considerem as particularidades locais e promovam equidade no acesso à educação de
                    qualidade e formação docente.
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 86,
                }}
            >
                <Styled.ContentEnd>
                    <Styled.TitleBlue>Programa Escola Inovadora – a leitura como solo fértil para a alfabetização</Styled.TitleBlue>
                    <Styled.DividerYellow />
                    <Styled.TitleYellow style={{ fontSize: 14 }}>Vastí Ferrari Marques</Styled.TitleYellow>
                </Styled.ContentEnd>
                <Styled.ContentBlue3>
                    Licenciada em Pedagogia, com especialização em Geografia e Deficientes Auditivos; pós-graduada em Metodologias de Ensino e
                    Filosofia; mestra na linha de Pesquisa Política, Planejamento, Gestão e Avaliação da Educação Básica; gestora de Educação em
                    Jundiaí desde outubro de 2017, com experiência de 36 anos na docência e gestão de escolas da rede municipal.
                    <br /> <br />
                    <Styled.Img src={Img_04} onClick={() => setImgAmpliar(Img_04)} />
                    <br />
                    <br />
                    Entendemos que, em uma rotina com as crianças, a alfabetização é repertoriada por uma série de habilidades importantes. Em
                    Jundiaí, ler é uma prática de que não abrimos mão na educação infantil desde o berçário. Além de ler para os pequenos, ler
                    com eles e estimular a leitura de livros específicos para a faixa etária está entre as atividades permanentes na rotina das
                    escolas. Ao abrir espaço para que as crianças coloquem em jogo seus conhecimentos de leitura e escrita desde muito pequenas,
                    aproveitamos as janelas de oportunidade para que elas aprendam no tempo certo, por meio de interações e brincadeiras. Em
                    Jundiaí, a partir dos 4 anos, as crianças são monitoradas nas fases da escrita e leitura, assim como em relação a outros
                    direitos de aprendizagem, o que possibilita o estabelecimento de metas em cada ano dos ciclos para que nenhuma fique para
                    trás. Todas as escolas da rede municipal de Jundiaí foram envolvidas em ações para o desenvolvimento dos processos de leitura
                    e escrita, com foco na alfabetização na idade certa pois, sabemos que as crianças
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 87,
                }}
            >
                <Styled.ContentBlue3>
                    em situação de maior vulnerabilidade social têm na escola um “farol” para ir além junto com os outros meninos e meninas.
                    Assim, o principal objetivo de estimular as aprendizagens de leitura e escrita desde muito cedo é favorecer as oportunidades
                    de aprendizagem em busca da equidade social.
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais competências e habilidades foram mobilizadas?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        <Styled.TitleYellow style={{ fontSize: 14 }}>Competências relacionadas à alfabetização</Styled.TitleYellow>
                        <CheckHability containerWidth="auto" titleMaxWidth="auto" label="Leitura" />
                        <CheckHability containerWidth="auto" titleMaxWidth="auto" label="Escrita" />
                        <CheckHability containerWidth="auto" titleMaxWidth="auto" label="Compreensão e interpretação" />
                        <CheckHability containerWidth="auto" titleMaxWidth="auto" label="Ampliação de repertório e vocabulário" />
                        <CheckHability containerWidth="auto" titleMaxWidth="auto" label="Oralidade" />
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Como essa prática se conecta com a alfabetização?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        A alfabetização das crianças integra o eixo da qualidade de ensino do Programa Escola Inovadora e só acontece da forma
                        desejada com o respaldo a certas ações, entre as quais: a formação dos nossos educadores para o incentivo à leitura, a
                        oportunização das aprendizagens em diversos ambientes dentro e fora da escola pelo “Desemparedamento da Escola”, a
                        conexão entre as aprendizagens e as realidades das crianças, além da atenção integral às conexões dos ciclos das crianças
                        entre a Educação Infantil e o Ensino Fundamental.
                        <br />
                        <br />
                        Há sete anos, buscamos parametrizar a rede municipal de Jundiaí por meio de um currículo construído a muitas mãos. Ao
                        democratizar a construção
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 88,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        do currículo, estabelecemos uma parceria responsável com todos os educadores das unidades escolares. Depois dessa
                        construção, percebemos a necessidade de trabalhar com a educação infantil e o ensino fundamental com foco no entendimento
                        de que todos, desde os educadores do berçário, são responsáveis pela qualidade do ensino, especialmente da alfabetização
                        como direito. Então, na sequência, buscamos incutir na rede o entendimento de que a alfabetização não se inicia no
                        primeiro ano do ensino fundamental.
                        <br />
                        <br />
                        Considerar as características próprias de cada escola como a naturalização dos espaços e os ambientes de aprendizagem com
                        e na natureza, organização dos cantinhos de leitura, as rodas de conversa ao redor da fogueira, e estímulo à leitura em
                        família com o Projeto Contém Sonhos (às sextas-feiras, todos os estudantes, da creche à EJA, levam livros para casa para
                        ler com seus cuidadores), entre outros, é essencial para o desenvolvimento e a prática da leitura e da escrita. Ao mesmo
                        tempo que incentivamos práticas que estimulam essas competências e habilidades, nos organizamos enquanto sistema para
                        implementar ações formativas, com foco em conteúdos relevantes ao processo de alfabetização, como o princípio alfabético,
                        consciência fonológica, compreensão e fluência leitora, além de acompanhar e monitorar as fases da escrita, para avaliar
                        o avanço de cada criança, de cada turma e de cada escola, com o intuito de estabelecer políticas públicas com foco na
                        equidade.
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais materiais foram utilizados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Além dos livros didáticos e paradidáticos, a tecnologia por meio de jogos, aplicativos e plataformas instrumentalizam os
                        professores para que, a partir do monitoramento, elaborem suas rotinas de forma eficiente e condizente com os princípios
                        de equidade e dos direitos de aprendizagem. Esse é um trabalho desenvolvido desde 2017 com ações de formação continuada
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 89,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        e supervisão escolar por meio do Programa Escola Inovadora. Algumas das premissas consideradas são:
                        <br />
                        <br />
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            Educação integral como alicerce do programa, com ênfase nas múltiplas dimensões humanas (intelectual, física,
                            cultural, social e emocional), protagonismo estudantil, projeto de vida pessoal, social e profissiona
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>Formação de educadores focada na alfabetização</Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            Criação de projetos estimulantes: Lê no Ninho, Leiturinha em Foco, Viagem Literária, Diálogos Embalados
                            Personalizados, Contém Sonhos
                        </Styled.ContentBlue3>
                        <Styled.ContentBlue3 style={{ marginBottom: 3 }}>
                            Planilhas de monitoramento mensal dos avanços individuais e coletivos em leitura, escrita e produção textual
                        </Styled.ContentBlue3>
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais impactos foram observados?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Os resultados, focados nas metas, têm sido alcançados de forma bastante satisfatória. Importante ressaltar que as
                        supervisoras de ensino têm papel importante no monitoramento, na medida em que, observando as planilhas de avaliação, se
                        comprometem com a formação de seu grupo de escolas e de professores, mais especificamente apoiando e dando suporte a cada
                        um deles, seja de forma coletiva ou individual. Como gestora de educação, acompanho o processo muito de perto, buscando
                        entender os avanços e as dificuldades, com o objetivo de proporcionar momentos formativos que deem conta das necessidades
                        dos educadores e das escolas.
                        <br />
                        <br />
                        Todo esse nosso esforço tem gerado resultados muito significativos para os
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 90,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        estudantes da rede. Na educação infantil, avançamos muito com as fases da escrita das crianças. No grupo 5, em dezembro
                        de 2023, tivemos 4.159 crianças avaliadas, das quais 3.206 encontram-se nas fases Silábica sem Valor, Silábica com Valor,
                        Silábicas Alfabéticas e Alfabéticas.
                        <br />
                        <br />
                        <Styled.ContentBorderBlue>
                            <Styled.TextItalic>
                                <strong>Silábico sem valor sonoro:</strong> utiliza letras que podem não representar os respectivos sons. Por
                                exemplo: pipoca - ABI.
                                <br />
                                <br />
                                <strong>Silábico com valor sonoro:</strong> já com maior domínio do alfabeto, é capaz de relacionar as sílabas
                                com o seu fonema mais forte. Por exemplo: macaco - MKO
                                <br />
                                <br />
                                <strong>Silábico-alfabética:</strong> essa fase marca a transição da etapa silábica para a alfabética e traz a
                                noção de que as sílabas possuem mais de uma letra. Por exemplo: cavalo - KVALU
                                <br />
                                <br />
                                <strong>Silábico:</strong> é capaz de reconhecer todos os fonemas de uma palavra, mesmo que as represente com
                                erros ortográficos. Por exemplo: carro - CARO
                                <br />
                                <br />
                            </Styled.TextItalic>
                        </Styled.ContentBorderBlue>
                        Quando observamos os dados de 2023 do ensino fundamental relativos ao total de 20.066 estudantes do 1º ao 5º ano,
                        percebemos o avanço em leitura e escrita das crianças nas séries iniciais, como mostra o gráfico a seguir:
                        <Styled.Img src={Grafico_01} onClick={() => setImgAmpliar(Grafico_01)} />
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 91,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        Para estimular as aprendizagens considerando as janelas de oportunidade e os direitos das crianças, precisamos também
                        incentivar nossos educadores a pensar na responsabilidade da escola pública em oferecer o melhor ensino, sem esquecer de
                        conectar dados e evidências, sempre levando em conta as experiências, vivências e investigações nas infâncias e para as
                        infâncias. Para muitas crianças, a escola é o único lugar onde terão oportunidade de desenvolver as aprendizagens
                        necessárias para a vida. É na escola que as crianças encontrarão espaço para viver, transformar sua realidade e prosperar
                        como seres humanos.
                    </Styled.ContentWhite>
                    <br />
                    <br />
                    <Styled.TitleBlue style={{ fontSize: 16 }}>Quais desafios foram encontrados e como foram transpostos?</Styled.TitleBlue>
                    <Styled.ContentWhite>
                        Quando lançamos a proposta de uma escola inovadora percebemos o desafio de garantir uma excelente qualidade de ensino e
                        de ir além da sala de aula. Nosso desejo é que os meninos, meninas, jovens ou adultos matriculados na rede municipal de
                        Jundiaí tenham a oportunidade de realizar uma formação que considere sua integralidade em compasso com a sociedade
                        contemporânea. Para tanto, é preciso efetivar um conjunto de ações diversas, desafiadoras e igualmente imprescindíveis,
                        como:
                        <br />
                        <br />
                        <ul>
                            <li>investimentos e parcerias para implementação das ações planejadas;</li>
                            <li>superação da fragmentação, proporcionando ao estudante o protagonismo em seu aprendizado;</li>
                            <li>formação continuada dos educadores que se reflita na aprendizagem dos estudantes;</li>
                            <li>
                                aquisição de materiais didáticos, recursos tecnológicos, livros paradidáticos e outros recursos e projetos que
                                visem auxiliar o desenvolvimento das aprendizagens;
                            </li>
                        </ul>
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 92,
                }}
            >
                <Styled.ContentBlue3>
                    <Styled.ContentWhite>
                        <ul>
                            <li>
                                constância no monitoramento da aprendizagem e dos projetos, a fim de planejar e tomar decisões pautadas em
                                evidências.
                            </li>
                        </ul>
                        <br />
                        Dificuldades e desafios fazem parte do cotidiano escolar e educacional e, para promover a equidade social, é essencial
                        trabalhar para garantir os direitos de aprendizagem dos estudantes, seja pelo estudo ou aprimoramento dos conceitos.
                    </Styled.ContentWhite>
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Práticas inspiradoras',
                }}
                footer={{
                    numberSummary: 93,
                }}
            >
                <Styled.ContentWhite>
                    Concluímos este capítulo reafirmando a crença de que a educação transformadora se constrói pela união de pessoas que atuam em
                    diversos níveis e contextos. As práticas aqui apresentadas ilustram como a colaboração entre educadores, gestores, famílias e
                    comunidades é essencial para enfrentar os desafios da alfabetização. Cada um desses atores, ao contribuir com suas
                    experiências e perspectivas, fortalece o tecido educativo e ajuda a criar um ambiente no qual a aprendizagem pode florescer
                    de maneira significativa e inclusiva.
                    <br /> <br />
                    As práticas abordadas neste capítulo foram escolhidas não apenas para fornecer exemplos inspiradores, mas também para
                    provocar reflexões mais amplas sobre o tema da alfabetização. Para tanto, buscamos contemplar a diversidade das regiões do
                    Brasil e as diferentes demandas existentes em seu vasto território. Cada experiência relatada carrega consigo as
                    particularidades de seu contexto, mostrando que, embora os desafios sejam comuns, as soluções podem e devem ser adaptadas às
                    realidades locais. Incentivamos que tais práticas sejam discutidas em espaços coletivos, de modo que o diálogo enriqueça
                    ainda mais as possibilidades de adaptação e inovação. A troca de ideias e a construção coletiva são caminhos promissores para
                    a criação de estratégias pedagógicas que, respeitando as singularidades de cada local, garantam o direito à alfabetização de
                    todas as crianças.
                </Styled.ContentWhite>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo05;
