import styled from 'styled-components';

const StyledTrilhaApronfundada = {
    Container: styled.div`
        min-height: 1100px;
        color: #003c78;
        padding: 50px 10%;

        @media screen and (max-width: 760px) {
            display: flex;
            padding: 50px 20px;
            flex-direction: column;
        }
    `,

    Header: styled.div`
        display: flex;
        align-items: end;
        justify-content: space-between;

        @media screen and (max-width: 760px) {
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }
    `,

    Title: styled.h1`
        font-weight: 600;
        font-size: 32px;
    `,

    Certificated: {
        Container: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-size: 18px;
            font-weight: 500;
            width: 340px;
            padding: 10px;
            background-color: #003c78;
            height: 64px;
            color: #ffffff;
            border-radius: 20px;
            line-height: 20px;
            box-shadow: 2px 2px 10px #001428;
        `,
    },

    SubTitle: styled.p`
        margin-top: 32px;
        line-height: 30px;
        font-weight: 400;
        width: 40vw;
        font-size: 20px;

        @media screen and (max-width: 760px) {
            width: 100%;
        }
    `,

    Status: {
        Cotainer: styled.div`
            display: flex;
            gap: 2rem;
            margin-top: 32px;
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
        `,
    },

    ContainerConteudos: styled.div`
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
    `,

    CardTrilha: {
        Container: styled.div<{bgColor?: string}>`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: ${({bgColor}) => bgColor ? `${bgColor}` : 'white'};
            width: 300px;
            height: 341px;
            margin: 10px;
            border-radius: 8px;
            color: #1e1e1e;
            padding: 16px;
            box-shadow: 1px 1px 8px #1e1e1e;
        `,

        Header: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `,

        Status: styled.div<{ isDisponivel: boolean }>`
            background-color: ${({ isDisponivel }) => (isDisponivel ? '#2E7D32' : '#B1E9FF')};
            color: ${({ isDisponivel }) => (isDisponivel ? '#FFFFFF' : '#003C78')};
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            padding: 4px 8px 4px 8px;
            border-radius: 8px;
        `,

        Modulo: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #201e1e;
            font-size: 14px;
        `,

        Title: styled.h1`
            max-width: 227px;
            font-size: 16px;
            line-height: 19.2px;
            font-weight: 600;
            color: #003c78;
            margin-top: 16px;
            margin-bottom: 16px;
        `,

        Description: styled.p`
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
        `,

        Footer: {
            Container: styled.div`
                display: flex;
                width: 100%;
                align-items: center;
                gap: 1rem;
            `,

            Content: styled.div`
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-weight: 400;
                font-size: 14px;
            `,
        },

        ContentCenter: styled.div`
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        `,

        Button: styled.button<{ isDisponivel: boolean, isAtividade?: Boolean }>`
            max-height: 42px;
            border-radius: 20px;
            height: 42px;
            width: 168px;
            font-size: 15px;
            margin-top: 16px;
            background-color: ${({ isDisponivel, isAtividade }) => (isDisponivel ? isAtividade ? '#003979' : '#ffca00' : '#e0e0e0')};
            color: ${({ isDisponivel, isAtividade }) => (isDisponivel ? isAtividade ? 'white' : '#003C78' : '#8b8b8b')};
            font-weight: 600;
            font-size: 15px;
            line-height: 26px;
            letter-spacing: 0.46px;
            border: none;

            &:hover {
                box-shadow: ${({ isDisponivel }) => (isDisponivel ? '1px 1px 4px #000' : '')};
            }
        `,
    },

    Button: styled.button`
        max-height: 42px;
        border-radius: 20px;
        height: 42px;
        width: 168px;
        font-size: 15px;
        margin-top: 16px;
        background-color: #003c78;
        color: #ffffff;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.46px;
        border: none;

        &:hover {
            box-shadow: 1px 1px 4px #000;
        }
    `,
};

export default StyledTrilhaApronfundada;
