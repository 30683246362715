import "core-js/proposals/reflect-metadata";
import { jsonProperty, Serializable } from "ts-serializable";

class SelectionItem extends Serializable {
  @jsonProperty(Number)
  id: number = 0;

  @jsonProperty(String)
  nome: string = "";

  constructor() {
    super();
  }
}

export default SelectionItem;
