import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledHeaderNavigate = {
    Container: styled.div`
        display: flex;
        margin-left: 120px;
        margin-bottom: 10px;
        margin-top: 20px;
        flex-direction: column;
        height: 110px;
        border-radius: 5px;
        color: #ffff;
        padding: 20px;
        padding-top: 30px;
        background: linear-gradient(318.36deg, #00a8e9 0%, #47aefc 100%);
    `,

    Title: styled.div`
        font-size: 25px;
        font-weight: 600;
    `,

    Row: styled.div`
        display: flex;
        gap: 0.5rem;
        align-items: center;
    `,

    ButtonLink: styled.button<{ active: boolean }>`
        background-color: transparent;
        font-weight: 500;
        color: ${({ active }) => (active ? '#ffc41f' : '#ffff')};
        border: none;
        border-bottom: ${({ active }) => active && '3px solid' + Colors.VividOrange};
        font-size: 16px;
        padding: 10px;
        margin-top: 19px;
    `,
};

export default StyledHeaderNavigate;
