enum BiblioTipoItem {
    ARTIGO = 1,
    ATIVIDADE,
    AUDIO,
    GUIA,
    LIVRO,
    VIDEO,
    ESTUDO,
}

export default BiblioTipoItem;
