import React, { CSSProperties } from 'react';

import { Form } from 'react-bootstrap';
import Colors from '../../enums/Colors';

interface IInput {
    value: any;
    placeholder?: string;
    style?: CSSProperties;
    onchange?: (event: any) => void;
    onblur?: () => void;
    readonly?: boolean;
}
const _Input = ({ placeholder = 'Escreva aqui', onchange, onblur, value, style, readonly = false }: IInput) => {
    return (
        <Form.Control
            value={value}
            style={{ padding: '25px 20px', borderRadius: '5px', background: Colors.Grey11, ...style }}
            placeholder={placeholder}
            onChange={event => {
                if (onchange) onchange(event);
            }}
            onBlur={() => {
                if (onblur) onblur();
            }}
            readOnly={readonly}
        />
    );
};

export default _Input;
