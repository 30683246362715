import React from 'react';
import { EbookContext } from './EbookContext';

interface IEbookProvider {}

const EbookProvider = ({ children }: React.PropsWithChildren<IEbookProvider>) => {
    const context: EbookContext.IContext = {};

    return <EbookContext.Context.Provider value={context}>{children}</EbookContext.Context.Provider>;
};

export default EbookProvider;