import React, { useEffect, useState } from 'react';

import Webcam from 'react-webcam';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import ProfileData from '../../../../../models/ProfileData';
import { generateImageFromVideo } from '../utils/videoUtils';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import { storeProfileData } from '../../../../../store/actions/Profile';
import ImagemPerfil from '../../../../../models/imagemPerfil/ImagemPerfil';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';
import { ButtonContainer } from '../../regrasConvivencia/RegrasConvivencia.styled';
import { CancelButton, CanvasContainer, CaptureButton, Container } from './CameraCaptura.styled';

interface CanvasDimensions {
    width: number;
    height: number;
}

const CameraCaptura = () => {
    const canvasId = 'canvas-container';
    const webcamRef = React.useRef(null);
    const image: any = null;
    const [canvasDim, setCanvasDim] = useState<CanvasDimensions>({
        width: 350,
        height: 480,
    });
    const [showVideo, setShowVideo] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    const onClose = () => {
        history.push('/profile/identificacaoImagem');
    };

    const addImage = (encoded: string) => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.Imagem,
                imageBase64: encoded,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
            history.push('/profile/identificacaoImagemEditar');
        }
    };

    useEffect(() => {
        const containerElement = document.getElementById(canvasId);

        if (containerElement) {
            const rect = containerElement.getBoundingClientRect();
            setCanvasDim({
                width: rect.width,
                height: rect.height,
            });

            setShowVideo(true);
        }
    }, []);

    const capture = React.useCallback(() => {
        const videoElemt = document.querySelector('video');
        const encoded: string = generateImageFromVideo(videoElemt);
        if (encoded.length > 0) {
            addImage(encoded);
        }
    }, [webcamRef]);

    return (
        <Container>
            <CanvasContainer id={canvasId}>
                {showVideo && (
                    <Webcam
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            width: canvasDim.width,
                            height: canvasDim.height,
                            facingMode: 'user',
                        }}
                    />
                )}
            </CanvasContainer>
            <ButtonContainer>
                <CaptureButton disabled={false} onClick={capture}>
                    Capturar Imagem
                </CaptureButton>
                <CancelButton disabled={false} onClick={onClose}>
                    Cancelar
                </CancelButton>
            </ButtonContainer>
            <img style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '250px' }} src={image} alt="Captura de câmera" />
        </Container>
    );
};

export default CameraCaptura;
