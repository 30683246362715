import React, { useState } from 'react';
import BiblioItemComment from 'models/biblioteca/BiblioItemComment';
import Styled from '../AdminBiblioteca.styled';
import { capitalize } from 'lodash';
import { DateHelp } from 'core/util/DateHelp';
import AdminModalBiblioItem2 from './AdminModalBiblioItem2';

import eyeIcon from '../../../../../images/admin/eye.svg';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Badge from 'components/badge/Badge';

export interface IAdminBiblioGridComment {
    itens: BiblioItemComment[];
    enableComment: (idx: number) => void;
    updateData: () => void;
    inComment?: boolean;
}

const AdminBiblioGridComment = ({ itens, enableComment, updateData, inComment }: IAdminBiblioGridComment) => {
    const [showIdItemModal, setShowIdItemModal] = useState(-1);

    return (
        <React.Fragment>
            <AdminModalBiblioItem2
                show={showIdItemModal != -1}
                onHide={() => setShowIdItemModal(-1)}
                comment={itens.find(i => i.id == showIdItemModal)}
                updateData={updateData}
            />

            {itens.map((comment, idx) => {
                const isAdmin = comment.username.includes('admin');

                if (comment && comment.item)
                    return (
                        <React.Fragment key={idx}>
                            <Styled.GridCol>{capitalize(comment.item.category)}</Styled.GridCol>
                            <Styled.GridCol>
                                <Styled.GridColContent>{comment.item.title}</Styled.GridColContent>
                            </Styled.GridCol>
                            <Styled.GridCol>{DateHelp.formatDateFull(comment.dateAtualizacao)}</Styled.GridCol>
                            <Styled.GridCol>{comment.username}</Styled.GridCol>
                            <Styled.GridCol>
                                <Styled.GridColContent>
                                    {comment.email === '' ? (isAdmin ? 'adminestruturante@gmail.com' : 'E-mail inexistente') : comment.email}
                                </Styled.GridColContent>
                            </Styled.GridCol>

                            {inComment ? (
                                <Styled.GridCol>
                                    <Badge color={comment.answered ? '#229645' : '#D64545'}>{comment.answered ? 'Sim' : 'Não'}</Badge>
                                </Styled.GridCol>
                            ) : null}

                            <Styled.GridCol>
                                <OverlayTrigger
                                    overlay={<Tooltip id={'tooltip-action-view_' + comment.id}>Visualizar</Tooltip>}
                                    placement={'top'}
                                >
                                    <span>
                                        <Styled.ViewButton
                                            style={{ marginRight: '5px' }}
                                            src={eyeIcon}
                                            onClick={() => setShowIdItemModal(comment.id)}
                                        />
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={'tooltip-action-edit_' + comment.id}>
                                            {`${comment.active ? 'Desabilitar ' : 'Habilitar '}`}
                                            publicação do comentário
                                        </Tooltip>
                                    }
                                    placement={'top'}
                                >
                                    <span>
                                        <AdminSwitch value={comment.active} onChange={() => enableComment(idx)} />
                                    </span>
                                </OverlayTrigger>
                            </Styled.GridCol>
                        </React.Fragment>
                    );
            })}
        </React.Fragment>
    );
};

export default AdminBiblioGridComment;
