import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useEffect, useState } from 'react';

import Styled from './Solucoes.styled';
import Apresentation from './components/solucoes/Apresentation';
import MultiSolucoes from './components/solucoes/MultiSolucoes';
import ParceirosImplementadores from './components/solucoes/ParceirosImplementadores';
import FormSolucoes from './components/solucoes/FormSolucoes';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import ResgatedToken from './components/solucoes/ResgatedToken';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

const Solucoes = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <DynamicHeader />
            <Styled.Container>
                <Apresentation />
                <MultiSolucoes height={ IsCustomMaxWidth(480) ? '1200px' : '1000px'} />
                <ParceirosImplementadores />
                <FormSolucoes isBlue preDefinition={false} setOpenModalToken={() => setOpenModalToken(true)} />
                <ContactContainer isLight />
                <ResgatedToken valueModalToken={openModalToken} setHidenModalToken={setOpenModalToken} />
            </Styled.Container>
        </>
    );
};

export default Solucoes;
