import RelatedContent from 'components/RelatedContent';
import { RelatedContentAnexoEnum } from 'components/RelatedContentEnum';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { PgIntermediariaConteudoRelacionadoService } from 'core/http/service/PgIntermediariaConteudoRelacionado.service';
import { FetchUtils } from 'core/util/FetchUtils';
import { IsMediumSizeMaxWidth } from 'core/util/WindowSize';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InitialPageButton from '../../../criatividadeDetail/versions/components/BackInitialPageButton';
import { Container, SplitContainer, Title, TitleContainer } from '../../../criatividadeDetail/versions/components/sections/Outros.styled';

export interface IOutros {
    padding?: string;
    pageKey: PaginasIntermediariasEnum;
}

const OutrosConteudos = ({ padding, pageKey }: IOutros) => {
    const history = useHistory();
    const [paginaIntermediaria, setPaginaIntermediaria] = useState<AdminPaginaIntermediaria | null>(null);
    const [conteudosRelacionados, setConteudosRelacionados] = useState<PgIntermediariaConteudoRelacionado[]>([]);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(true);
    const paginaService = new PaginaIntermediariaService();
    const conteudosService = new PgIntermediariaConteudoRelacionadoService();

    const GoToInitialPage = () => {
        history.push('/');
    };

    const IsMobile = () => {
        return IsMediumSizeMaxWidth();
    };

    const fetchPaginaIntermediaria = async () => {
        const responsePagina: any = await paginaService.listar();
        const outputPagina: AdminPaginaIntermediaria[] = responsePagina.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

        if (outputPagina) {
            let currentPage: AdminPaginaIntermediaria = outputPagina.find(item => item.pagina === pageKey) ?? new AdminPaginaIntermediaria();
            setPaginaIntermediaria(currentPage);
        }
    };

    const fetchRelatedContent = async () => {
        if (paginaIntermediaria) {
            const responseRelatedContent: any = await conteudosService.listarPorPaginaPublico(paginaIntermediaria.codigo);
            let output = responseRelatedContent.data.map((item: any) => new PgIntermediariaConteudoRelacionado().fromJSON(item));
            setConteudosRelacionados(output);
        }
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingPage, fetchPaginaIntermediaria);
    }, []);

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, fetchRelatedContent);
    }, [paginaIntermediaria]);

    return (
        <Container padding={padding}>
            <SplitContainer>
                <TitleContainer>
                    <Title>{paginaIntermediaria?.tituloSecaoConteudosRelacionados}</Title>
                </TitleContainer>

                {RelatedContent.ConteudosRelacionados(
                    { loadingPage, loadingRelatedContent, IsMobile },
                    RelatedContentAnexoEnum.INTERMEDIARIA,
                    conteudosRelacionados
                )}
            </SplitContainer>
            <InitialPageButton goToLocation={() => GoToInitialPage()} />
        </Container>
    );
};

export default OutrosConteudos;
