import React, { useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import closeIcon from '../../../../../images/close-circle.svg';
import Select from 'components/Select';
import { GradientButton } from 'components/UI/Buttons';
import motivations from '../wordsBlock/wordsBlockMotivations';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminTag from 'models/admin/AdminTag';
import { ToastError } from 'components/Toaster';

export interface IAdminModalNewTag {
    show: boolean;
    onHide: () => void;
    onSaved: () => void;
}

const AdminModalNewTag = ({ show, onHide, onSaved }: IAdminModalNewTag) => {
    const [tag, setTag] = useState('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const onSave = async () => {
        setLoading(true);

        let exists: boolean = false;

        const service = new AdminBiblioService();
        const { data } = await service.buscarAllTags();
        const rows = data.rows;
        const _tags: AdminTag[] = rows.map((tag: any) => new AdminTag().fromJSON(tag));

        _tags.forEach(t => {
            if (t.content.toLowerCase() === tag.toLowerCase()) {
                exists = true;
            }
        });

        if (!exists) {
            await service.criarTag(tag);
            setTag('');
            onSaved();
        } else {
            ToastError('Tag já existente');
        }
        setLoading(false);
    };

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            <Styled.Container style={{ padding: '10px 30px' }}>
                <Styled.CloseContainer>
                    <img
                        src={closeIcon}
                        onClick={() => {
                            setTag('');
                            onHide();
                        }}
                    />
                </Styled.CloseContainer>

                <Styled.ModalEditTitle>Nova Tag</Styled.ModalEditTitle>
                <Styled.Text>Escreva um nova tag</Styled.Text>
                <Styled.Input value={tag} onChange={(e: any) => setTag(e.target.value)} />
                <br />
                <br />
                <Styled.RightContainer>
                    <GradientButton disabled={tag.trim().length == 0 || isLoading} onClick={onSave}>
                        Salvar
                    </GradientButton>
                </Styled.RightContainer>
            </Styled.Container>
        </Styled.Modal>
    );
};

export default AdminModalNewTag;
