import React from 'react';

import styled from 'styled-components';

import Colors from '../../../../enums/Colors';

import CheckCircle from '../../../../images/profile/regrasConvivencia/check_circle.svg';

interface IItemContainer {
    alignitems?: string;
}

const ItemContainer = styled.div<IItemContainer>`
    display: flex;
    align-items: ${props => (props.alignitems ? props.alignitems : 'center')};
    margin-bottom: 10px;
`;

const ItemText = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.Grey9};
    margin-left: 10px;
`;

export const CheckedIcon = styled.img({});

interface IRegraItem {
    value: string;
    alignItens?: string;
}

const RegraItem = ({ value, alignItens }: IRegraItem) => {
    return (
        <ItemContainer alignitems={alignItens}>
            <CheckedIcon src={CheckCircle} />
            <ItemText>{value}</ItemText>
        </ItemContainer>
    );
};

export default RegraItem;
