import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const AdminBibliotecaStyled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 5px;
        }
    `,

    ContainerModal: styled.div`
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,

    TopContainer: styled.div`
        background: linear-gradient(318.36deg, #0b78c8 0%, #47aefc 100%);
        border-radius: 5px;
        width: 100%;
        padding: 30px;
        padding-bottom: 0;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    TopTitle: styled.p`
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
        color: #ffffff;
    `,

    ModalEditTitle: styled.div`
        font-weight: bold;
        font-size: 24px;
        line-height: 42px;
        letter-spacing: 0.04em;
        color: #00a8e9;
        text-align: left;
        width: 100%;
    `,

    Row: styled.div`
        display: flex;
    `,

    RowSpace: styled.div`
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
            width: 100%;
        }
    `,

    ContainerButtons: styled.div`
        button: {
            margin-bottom: 10px;
        }
    `,

    Tab: styled.div<{ selected?: boolean }>`
        padding: 7px 15px;
        text-align: center;
        transform: translateY(2px);
        cursor: pointer;

        font-weight: ${props => (props.selected ? '600' : '400')};
        color: ${props => (props.selected ? '#FFC41F' : '#ffffff')};
        border-bottom: ${props => (props.selected ? '3px' : '0')} #e8950d solid;
    `,

    Content: styled.div`
        background-color: white;
        padding: 10px;
        border-radius: 5px;
    `,

    SectionChip: styled.div<{ enabled: boolean }>`
        width: fit-content;
        border-radius: 10px;
        padding: 5px 10px;
        cursor: pointer;
        font-weight: 500;
        margin-left: 10px;
        user-select: none;

        color: ${props => (props.enabled ? '#00a8e9' : '#747474')};
        border: 1px ${props => (props.enabled ? '#00a8e9' : '#747474')} solid;
        background-color: ${props => (props.enabled ? '#E5F6FD' : 'white')};
    `,

    Title: styled.p`
        font-weight: 700;
        font-size: 32px;
        color: #1e4e7e;
    `,

    InputComfirmated: styled.input`
        width: 100%;
        height: 40px;
        border-radius: 10px;
        padding: 5px;
        border: 1px solid ${Colors.Blue};
        font-size: 18px;

        &:focus{
            outline: none;
        }
    `,

    //BUSCA///
    SearchContainer: styled.div`
        width: 50%;
        height: 38px;
        max-width: 390px;
        display: flex;

        border: 1px solid #dadada;
        border-radius: 6px;
        overflow: hidden;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
            width: 100%;
        }
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        color: grey;

        &::placeholder {
            color: grey;
            opacity: 0.6;
        }
    `,

    ViewButton: styled.img`
        cursor: pointer;
    `,

    ////

    GridPost: styled.div`
        display: grid;
        grid-template-columns: 120px 145px 1fr 145px 100px 145px 145px 100px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    GridAvaliacao: styled.div<{ inComment?: boolean }>`
        display: grid;
        grid-template-columns: ${props => (props.inComment ? '80px 260px 120px 145px 1fr 150px 100px' : '80px 260px 120px 145px 1fr 100px')};

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    GridPalavras: styled.div`
        display: grid;
        grid-template-columns: 260px 1fr 120px 120px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
            grid-template-columns: 120px 200fr 120px 120px;
        }
    `,

    GridTags: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 120px 120px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
        }
    `,

    GridHead: styled.div`
        background: #e5f6fd;
        color: #003c78;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
    `,

    GridCol: styled.div<{ leftAlign?: boolean }>`
        width: 100%;
        padding: 10px;
        font-size: 14px;
        align-items: center;
        color: #2e2e2e;
        height: 56px;

        border-bottom: 0.5px #dcdcdc solid;

        display: flex;
        align-self: center;
        justify-content: ${props => (props.leftAlign ? 'flex-start' : 'center')};

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            border-bottom: none;
            height: fit-content;
        }
    `,

    GridCol2: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 10px;
        padding-bottom: 0;
        border-bottom: 0.5px #dcdcdc solid;

        span {
            font-style: italic;
            font-weight: 400;
            opacity: 0.7;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            border-bottom: none;
        }
    `,

    GridColContent: styled.p`
        max-width: 200px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    `,

    ButtonPage: styled.button<{ selected?: boolean }>`
        width: 38px;
        height: 32px;
        outline: none;
        border: 1.5px #003c78 solid;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin: 7px 5px;

        background-color: ${props => (props.selected ? '#003c78' : 'white')};
        color: ${props => (!props.selected ? '#003c78' : 'white')};
    `,

    Dot: styled.span<{ publicated: boolean }>`
        font-size: 1.5rem;
        color: ${props => (props.publicated ? '#52AD22' : '#FF4830')};
        margin-right: 5px;
        transform: translateY(-3px);
    `,

    TextStatus: styled.span`
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #2e2e2e;
    `,

    CloseContainer: styled.div`
        text-align: right;
        width: 100%;
    `,

    BottonPage: styled.div`
        width: 100%;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
    `,

    ArrowButtonPage: styled.button`
        width: 38px;
        height: 32px;
        outline: none;
        border-radius: 5px;
        text-align: center;
        margin: 7px 5px;
        border: none;
        background-color: white;
        vertical-align: middle;

        border: 1.5px #747474 solid;
    `,

    Text: styled.p`
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #2e2e2e;
        margin: 10px 0;
    `,

    Icon: styled.img<{ disabled?: boolean }>`
        margin: 0 5px;
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
        opacity: ${props => (props.disabled ? '0.5' : '1')};
    `,

    Modal: styled(Modal)``,

    TextModal: styled.div`
        font-size: 24px;
        line-height: 32px;
        color: #2e2e2e;
        width: 100%;
        text-align: center;
    `,

    Center: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    `,

    BottomButton: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    Input: styled(Form.Control)``,

    RightContainer: styled.div`
        width: 100%;
        text-align: right;
    `,

    LoadingContainer: styled.div`
        width: 100%;
    `,

    DivRowRight: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    RowRigth: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        .label-order {
            font-size: 14px;
            color: #00a8e9;
            font-weight: 600;
        }

        .span-select {
            margin-left: 0px;
            transform: scale(0.85) translateX(-10px);
        }
    `,

    Calendar: styled.div`
        border-radius: 10px;
        box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
        overflow: hidden;

        &:focus {
            outline: none;
        }
    `,

    Popper: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    `,
};

export default AdminBibliotecaStyled;
