import React from 'react';

import Menu from '../menu/Menu';

import { UnauthorizedStyle, MainContainer, Title, ContentContainer } from './Unauthorized.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const Unauthorized = () => {
    return (
        <UnauthorizedStyle>
            {/*  <Menu unauthorized /> */}
            <DynamicHeader />

            <MainContainer>
                <ContentContainer>
                    <Title>Não Autorizado</Title>
                </ContentContainer>
            </MainContainer>
        </UnauthorizedStyle>
    );
};

export default Unauthorized;
