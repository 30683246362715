import React, { useState, useEffect } from 'react';
import SecaoInteresseModel, { InserirInteresse } from 'models/generic-components/SecaoInteresse';
import Styled from './SecaoInteresse.styled';
import ButtonGeneric from '../components/buttonGeneric/ButtonGeneric';
import Colors from 'enums/Colors';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ValidateEmail } from 'core/util/ValidationsHelp';
import { ToastError, ToastSuccess, ToasterDefault } from 'components/Toaster';

interface IInsertInteresse {
    dados: InserirInteresse;
}
interface ISecaoInteresse {
    dados: SecaoInteresseModel;
}

const SecaoInteresse = ({ dados }: ISecaoInteresse) => {
    const service = new AdminGestaoPaginaService();
    const dataUser: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailUser, setEmailUser] = useState<string | undefined>('');

    const sendInteresse = async () => {
        const body = new InserirInteresse().fromJSON({
            email: emailUser,
            chaveInteresse: dados.chaveInteresse,
            codigoSecaoInteresse: dados.codigo,
        });

        const response = await service.cadastrarInteresse(body);

        if (response.status === 201) {
            ToastSuccess('Seu interesse foi cadastrado com sucesso!');
        } else {
            ToastError('Ocorreu alguma instabilidade no servidor.');
        }
    };

    const validEmail = () => {
        const valid = ValidateEmail(emailUser);

        if (emailUser && emailUser.length > 0) {
            setEmailIsValid(valid);
        } else {
            setEmailIsValid(true);
        }
    };

    useEffect(() => {
        validEmail();
    }, [emailUser]);

    useEffect(() => {
        setEmailUser(dataUser?.email);
    }, [dataUser]);

    return (
        <Styled.Container color={dados.corSecao}>
            {ToasterDefault()}
            {!dados.ocultarTitulo && (
                <Styled.Title color={dados.corTitulo} fontSize={dados.tamanhoTitulo}>
                    {dados.titulo}
                </Styled.Title>
            )}

            {!dados.ocultarDescricao && (
                <Styled.Text color={dados.corDescricao} fontSize={dados.tamanhoDescricao}>
                    {dados.descricao}
                </Styled.Text>
            )}


            
            <Styled.ContentSendEmail.Container>
                <Styled.ContentSendEmail.Input
                    value={emailUser?.toString()}
                    isInvalid={emailIsValid}
                    onChange={e => setEmailUser(e.target.value)}
                    isWhite={dados.corSecao === Colors.White || dados.corSecao === Colors.White.toLocaleLowerCase()}
                    placeholder="Escreva seu e-mail aqui ..."
                />
                <ButtonGeneric
                    styled={{
                        position: 'relative',
                        left: '5px',
                        width: '200px',
                        height: '100%',
                        border: '1px solid' + dados.corSecao === ButtonsCustom.Button_01 ? Colors.Blue : Colors.Yellow,
                    }}
                    name="Cadastrar"
                    onClick={sendInteresse}
                    type={dados.estiloBotao}
                    key={1}
                />
            </Styled.ContentSendEmail.Container>
            {!dados.ocultarTextoApoio && (
                <Styled.Text color={dados.corTextoApoio} fontSize={dados.tamanhoTextoApoio}>
                    {dados.textoApoio}
                </Styled.Text>
            )}
        </Styled.Container>
    );
};

export default SecaoInteresse;
