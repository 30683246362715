import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentItemCarrosselInteraction extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    filepath: string = '';

    @jsonProperty(String)
    base64: string = '';

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(String)
    colorBg: string = '';

    @jsonProperty(String)
    type: string = '';
}

export default ComponentItemCarrosselInteraction;
