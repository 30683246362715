import styled from 'styled-components';

import Colors from '../../enums/Colors';
import { MobileMaxWidth } from '../../constants/Dimensions';

export const mobileTopMenuHeight: number = 60;

export const MenuStyle = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 86px;
    left: 0px;
    top: 0px;
    background: ${Colors.White};
    position: fixed;
    z-index: 10;
    padding-left: 60px;
    padding-right: 60px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        justify-content: unset;
        height: ${mobileTopMenuHeight}px;
        padding-left: 10px;
        padding-right: 20px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 2450px;
    width: 100%;
`;

export const LogoContainer = styled.div`
    display: flex;
    height: 48px;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: 32px;
    }
`;

export const Img = styled.img`
    &:hover {
        cursor: pointer;
    }
`;

export const RightSideContainer = styled.div`
    margin-left: auto;
`;

export const RightButton = styled.button`
  width: 109px;
  height: 37px;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.BlueGrey};
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${Colors.BlueGrey};
  cursor: pointer;
  outline: none;
  margin-left: 40px;

  &:hover {
    background-color: ${Colors.BlueGrey};
    color: ${Colors.White};
  }
`;

export const RightMobileButton = styled.button`
  border:none;
  background:transparent;
  cursor: pointer;
  outline: none;

`;

export const RightMobileButton2 = styled.div`
    cursor: pointer;
    width: 56px;
    height: 56px;
    outline: none;
    border: none;
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 36px;
    width: 36px;
    fill: transparent;

    &:hover {
        fill: ${Colors.BlueGrey};
    }

    &:hover path {
        fill: ${Colors.White};
    }
`;
