import React, { Fragment, useEffect, useState } from 'react';
import Styled from './SelectedButtons.styled';
import Colors from 'enums/Colors';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';

interface ISelectedButtons {
    value: ButtonsCustom;
    setSelected: (values: ButtonsCustom) => void;
    ocultarButton?: number[];
}

interface IButtons {
    bgColor: Colors;
    color: Colors;
    type: ButtonsCustom;
}

const SelectedButtons = ({ value, ocultarButton, setSelected }: ISelectedButtons) => {
    const initialButtons: IButtons[] = [
        {
            bgColor: Colors.Blue,
            color: Colors.White,
            type: ButtonsCustom.Button_01,
        },
        {
            bgColor: Colors.White,
            color: Colors.Blue,
            type: ButtonsCustom.Button_02,
        },
        {
            bgColor: Colors.Yellow,
            color: Colors.Blue,
            type: ButtonsCustom.Button_03,
        },
    ];

    const [buttons, setButtons] = useState<IButtons[]>(initialButtons);

    useEffect(() => {
        if (ocultarButton?.length ?? 0 > 0) {
            const updatedButtons = [...initialButtons];

            ocultarButton?.forEach((index: number) => {
                if (index < updatedButtons.length) {
                    updatedButtons.splice(index, 1);
                }
            });

            setButtons(updatedButtons);
        }
    }, [ocultarButton]);
    return (
        <Styled.Container>
            {buttons.map((button, index) => (
                <>
                    <Styled.Content key={index}>
                        <Styled.Radius
                            type="radio"
                            name="radiusButton"
                            checked={value === button.type}
                            onClick={() => setSelected(button.type)}
                        />
                        <Styled.Button color={button.color} background={button.bgColor} onClick={() => setSelected(button.type)}>
                            Botão {index + 1}
                        </Styled.Button>
                    </Styled.Content>
                </>
            ))}
        </Styled.Container>
    );
};

export default SelectedButtons;
