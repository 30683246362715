import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import Colors from '../../../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../../../constants/Dimensions';
import { GradientSquareButton } from 'components/UI/Buttons';

export const ContactUsStyle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FormStyle = styled(Form)`
    width: 100%;

    .error {
        border: 2px solid ${Colors.WarningRed};
    }

    .error-message {
        font-family: 'Mulish', sans-serif;
        color: ${Colors.WarningRed};
        padding: 0.5em 0.2em;
        height: 1em;
        font-size: 0.8em;
        letter-spacing: 0.3px;
        font-weight: 500;
    }
`;

export const FormLabel = styled(Form.Label)`
    color: ${Colors.DarkGrey};
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin-bottom: 9px;
`;

export const FormControl = styled(Form.Control)`
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        justify-content: flex-start;
    }
`;

export const SubmitButton = styled.button`
    width: 145px;
    height: 42px;
    background: ${Colors.Yellow};
    color: ${Colors.Brown};
    font-weight: bold;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    outline: none;
    border: none;

    &:hover {
        color: ${Colors.White};
    }

    &:disabled {
        background-color: ${Colors.Grey8};
    }
`;

interface IStatusMessage {
    err: boolean;
}
export const StatusMessage = styled.p<IStatusMessage>`
    font-family: 'Mulish', sans-serif;
    color: ${props => (props.err ? Colors.WarningRed : Colors.BrightGreen)};
    padding: 0.5em 0.2em;
    height: 1em;
    font-size: 0.8em;
    letter-spacing: 0.3px;
    font-weight: 500;
`;

export const StatusMessageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const GradientButton = styled(GradientSquareButton)`
    width: 201px;
`;

export const Button = styled.button`
    color: ${Colors.White};
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    width: 167px;
    padding: 12px 13px;
    gap: 10px;
    background: ${Colors.Blue};
    border: 1px solid ${Colors.Blue};
    border-radius: 50px;

    &:hover {
        background: ${Colors.DarkBlue3};
        border: 1px solid ${Colors.DarkBlue3};
        color: ${Colors.White};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        line-height: unset;
        width: 100%;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-right: 15px;
    padding-left: 15px;
`;

export const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #2c2c2c;
`;

export const Link = styled.a`
    :hover {
        cursor: pointer;
    }
`;
