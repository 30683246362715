import React, { useEffect, useState } from 'react';
import Title from 'components/UI/Title';
import Colors from 'enums/Colors';
import BannerStyled from './BannerInfo.styled';
import { PrimaryButton } from '../../../../../components/UI/Buttons';
import plusCircleOutline from '../../../assets/images/circle-plus-svgrepo.svg';

import closeIcon from '../../../assets/images/close-59.svg';

import { useContext } from 'react';
import { PainelSocioContext } from '../../context/PainelSocioContext';
import AdminSocioemocionalService from 'core/http/service/admin/AdminSocioemocional.service';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { PreferenciasService } from 'core/http/service/Preferencias.service';
import PrefenciaUsuario from 'models/PreferenciaUsuario';
import { Preferences } from 'constants/Preferences';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { servicesVersion } from 'typescript';
import fetchUserInfo from '../../utils/fetchUserInfo';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import { cacheShowSliderDialogKey } from '../../context/PainelSocioProvider';

const textContent = `Após anos de estudos e pesquisas acerca das competências
  socioemocionais dos estudantes e o desenvolvimento de uma matriz e instrumentos
  específicos de monitoramento para esse público alvo, o Instituto Ayrton Senna voltou
  seu olhar para o socioemocional dos educadores brasileiros e para a necessidade de ver
  esses profissionais sob a mesma ótica integral que se aplica aos estudantes, mas desta
  vez com uma matriz específica ao fazer docente, que possa oferecer apoio em seu percurso
  de desenvolvimento profissional com base em evidências.`;

const BannerInfo = () => {
    const { setShowSliderDialog, isExpandedBanner, setExpandedBanner, showSliderDialog, firstCloseBanner } = useContext(
        PainelSocioContext.Context
    );
    const [content, setContent] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const fetchContent = async () => {
        const service = new AdminSocioemocionalService();
        let { data } = await service.buscarSecao('SAIBAMAIS');

        if (data) {
            setContent(data[0]?.conteudo);
            setTitle(data[0]?.titulo);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const setupExpand = () => {
        setExpandedBanner(!isExpandedBanner);

        const service = new PreferenciasService();
        const preferenciaSbm = new PrefenciaUsuario();
        preferenciaSbm.chave = Preferences.SaibaMaisExpanded.chave;
        preferenciaSbm.valor = !isExpandedBanner
            ? Preferences.SaibaMaisExpanded.valor.expandir
            : Preferences.SaibaMaisExpanded.valor.naoExpandir;

        if (userProfile) {
            service.setPreferencia(preferenciaSbm, userProfile.codigo);
        }
    };

    return (
        <BannerStyled.Container isExpanded={isExpandedBanner}>
            <BannerStyled.HeadContainer>
                <Title size="large" style={{ color: Colors.White }}>
                    {title ?? 'Socioemocional de Professores'}
                </Title>

                <BannerStyled.CloseIcon src={isExpandedBanner ? closeIcon : plusCircleOutline} onClick={setupExpand} />
            </BannerStyled.HeadContainer>

            <BannerStyled.Content>
                <BannerStyled.Text>{content ?? textContent}</BannerStyled.Text>
                <BannerStyled.ButtonContainer>
                    <BannerStyled.ButtonSbm
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('banner_saiba_mais', 'saiba_mais_btn');
                            setShowSliderDialog();
                        }}
                    >
                        Saiba Mais
                    </BannerStyled.ButtonSbm>
                </BannerStyled.ButtonContainer>
            </BannerStyled.Content>
        </BannerStyled.Container>
    );
};

export default BannerInfo;
