import { Popconfirm } from 'antd';
import keycloak from 'core/config/keycloak';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import Colors from 'enums/Colors';
import { KeycloakLoginOptions } from 'keycloak-js';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { getIdSolucao } from 'pages/acessoExclusivo/context/AcessoExclusivoProvider';
import React, { useEffect, useState } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Styled = {
    Button: styled.button<{ bgColor?: Colors; marginTop?: string }>`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: ${({ bgColor }) => (bgColor ? bgColor : Colors.Yellow)};
        color: ${({ bgColor }) => (bgColor === Colors.Blue ? Colors.White : Colors.Blue)};
        padding: 16px 40px;
        border-radius: 10px;
        height: 45px;
        font-weight: 700;
        font-size: 12px;
        width: 260px;
        border: none;
        padding: 0;
        gap: 0.5rem;
        margin-top: ${({ marginTop }) => marginTop};
    `,
};

interface IButtonExclusivo {
    linkExclusivo: string;
    bgColor?: Colors;
    inativeButton?: boolean;
    marginTop?: string;
    handleButtonActiveModalToken?: () => void;
}

const ButtonExclusivo = ({ linkExclusivo, bgColor, inativeButton, handleButtonActiveModalToken, marginTop }: IButtonExclusivo) => {
    const [access, setAccess] = useState<boolean>(false);
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const history = useHistory();

    const validatedAccess = async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();
            const link: string = location.pathname.split('/')[1];

            const idSolucao = getIdSolucao(link);

            if (response.status === 200) {
                const solucoes: TipoSolucoes[] = response.data;

                const possui = solucoes.find(f => f.id === idSolucao);

                if (possui) {
                    setAccess(true);
                }
            }
        }
    };

    const validateAccessToken = (goTologin: boolean) => {
        const current = location.pathname;

        if (keycloak.authenticated) {
            handleButtonActiveModalToken && handleButtonActiveModalToken();
        } else {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + current,
            };

            const _url = goTologin ? keycloak.createLoginUrl(options) : keycloak.createRegisterUrl(options);
            window.location.assign(_url);
        }
    };

    const goToPageExclusiva = () => {
        if (linkExclusivo) {
            history.push(linkExclusivo);
        }
    };

    useEffect(() => {
        validatedAccess();
    }, [linkExclusivo]);

    return (
        <>
            {access ? (
                <Styled.Button style={{       fontSize: 16}} marginTop={marginTop} bgColor={bgColor ? bgColor : Colors.Blue} onClick={goToPageExclusiva}>
                    {` Acessar conteúdo exclusivo`} <MdArrowForwardIos />
                </Styled.Button>
            ) : (
                !inativeButton && (
                    <Popconfirm
                        disabled={keycloak.authenticated}
                        placement="bottom"
                        title={<strong>Continue como Parceiro do Instituto</strong>}
                        description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                        onConfirm={() => validateAccessToken(true)}
                        onCancel={() => validateAccessToken(false)}
                        okText="Já sou cadastrado e quero fazer meu login"
                        cancelText="Quero me cadastrar"
                        cancelButtonProps={{
                            style: {
                                width: '200px',
                                height: '30px',
                            },
                        }}
                        okButtonProps={{
                            style: {
                                height: '30px',
                                backgroundColor: Colors.Blue,
                            },
                        }}
                    >
                        <Styled.Button
                        style={{
                            width: keycloak.authenticated ? '400px' : '',
                            padding: keycloak.authenticated ? '20px' : '',
                            fontSize: 16
                        }}
                            onClick={() => keycloak.authenticated && handleButtonActiveModalToken && handleButtonActiveModalToken()}
                            marginTop={marginTop}
                            bgColor={bgColor ? bgColor : Colors.Blue}
                        >
                            {keycloak.authenticated ? 'Já sou parceiro e possuo um código de acesso' : ` Já sou parceiro`}{' '}
                            <MdArrowForwardIos />
                        </Styled.Button>
                    </Popconfirm>
                )
            )}
        </>
    );
};

export default ButtonExclusivo;
