import AdminCursoLivre from 'models/admin/AdminCursoLivre';
import { GenericService } from './Generic.service';

export class CursoLivreService extends GenericService {
    readonly URI: string = '/v1/cursoLivre';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listar() {
        return this.get('list');
    }

    public listarAtivosResumido() {
        return this.get('listarAtivosResumido');
    }

    public listarPublico() {
        return this.get('public/list');
    }

    public inserir(data: AdminCursoLivre) {
        return this.post('insert', data);
    }

    public editar(id: number, data: AdminCursoLivre) {
        return this.put(String(id), data);
    }

    public excluir(id: number) {
        return this.deleteByParams(String(id));
    }
}
