import { GenericService } from '../Generic.service';
import { StatusNotificationType } from 'models/biblioteca/BiblioNotification';

export default class BiblioNotificacaoService extends GenericService {
    readonly URI: string = '/v1/biblioteca/notificacao';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public marcarNotificacaoLida(itens: number[]) {
        return this.put('marcarLida', { itens });
    }

    public totalNotificacaoNaoLida() {
        return this.get('quantidadePendente');
    }

    public removerNotificacao(itens: number[]) {
        return this.deleteByBody('removerVarios', { itens });
    }

    public buscarNotificacoes(status: StatusNotificationType, por_pagina?: number, pagina?: number) {
        if (status == 'LIDA') return this.get('lidas', { por_pagina, pagina });
        else return this.get('pendentes', { por_pagina, pagina });
    }

    public buscarPreferencias() {
        return this.get('preferencias');
    }

    public atualizarPreferencias(codigos: number[]) {
        return this.post('preferencias', { categorias: codigos ?? [] });
    }

    public buscarNotificacoesNaoLidas() {
        return this.get('pendentes');
    }

    public updateItemByUserItem(codigoUsuario: string, codigoItemBiblioteca: number) {
        const params = { codigoUsuario: codigoUsuario, codigoItemBiblioteca: codigoItemBiblioteca };
        return this.put('updateItemByUserItem', params);
    }
}
