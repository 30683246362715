import React from 'react';
import styled from 'styled-components';

interface ICellInfo {
    property: string;
    value: string;
}

const CellInfoStyle = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media only screen and (max-width: 760px) {
        min-width: 80px;
        width: 90px;
    }
`;

const Property = styled.p`
    font-weight: 700;
    font-size: clamp(12px, 2.5vw, 18px);
    line-height: 22px;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    color: #003c78;
`;

const Value = styled.p`
    font-weight: 400;
    font-size: clamp(12px, 2.5vw, 18px);
    line-height: 22px;
    color: #124a88;

    @media only screen and (max-width: 760px) {
        line-height: 1;
    }
`;

const CellInfo = ({ property, value }: ICellInfo) => {
    return (
        <CellInfoStyle>
            <Property>{property}</Property>
            <Value>{value}</Value>
        </CellInfoStyle>
    );
};

export default CellInfo;
