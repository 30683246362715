import { ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const PainelContainer = styled.div<{ isDropped: boolean }>`
    display: flex;
    flex-direction: row;

    width: 998px;
    height: ${props => (props.isDropped ? '580px' : '270px')};

    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 18px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        width: 100%;
    }

    @media only screen and (max-width: 480px) {
        height: ${props => (props.isDropped ? '555px' : '240px')};
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    gap: 15px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        width: 100%;
    }
`;

export const CenterContainer = styled.div`
    width: 2%;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    gap: 15px;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80px;

    @media only screen and (max-width: 330px) {
        gap: 50px;
    }
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        justify-content: space-between;
    }
`;

export const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
`;

export const DropButton = styled.button`
    display: none;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;

        height: 27px;
        background: #ffffff;
        color: #003c78;
        border: 1px solid #003c78;
        border-radius: 6px;
    }

    @media only screen and (max-width: 350px) {
        height: 100%;
        padding: 0;
    }

    @media only screen and (max-width: 290px) {
        font-size: 14px;
    }

`;

export const RedirectButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    height: 27px;
    background: #003c78;
    border: none;
    border-radius: 6px;

    &:hover {
        border: 1px solid #003c78;
        background: #ffffff;
    }

    @media only screen and (max-width: 350px) {
        height: 100%;
        padding: 0;
    }
`;

export const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #fcfcfc;

    &:hover {
        color: #003c78;
    }
`;

export const RedirectIconButton = styled.button``;

export const VerticalSeparator = styled.div`
    border-left: 2px solid #cbd2e0;
    height: 200px;
    position: relative;
    left: 50%;
    margin-left: -3px;
    top: 30px;
`;

export const Separator = styled.div`
    border: 1px solid;
    border-color: ${Colors.Grey8};
    margin: 5px 0 15px 0;
`;

export const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(16px, 4.5vw, 34px);
    line-height: 97%;

    text-transform: uppercase;
    color: #183f73;
`;
