import React from 'react';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { ArrowButton, CarouselContainer, CarouselCustomPaging, CarouselDots, SvgIcon, Wrapper } from './Carousel.styled';
import Slider from 'react-slick';

export interface ICarousel {}

const Carousel = ({ children }: React.PropsWithChildren<ICarousel>) => {

    const IsTablet = () => {
        return IsCustomMaxWidth(1250);
    };

    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={true} onClick={props.onClick}>
                <SvgIcon>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 7C0.447715 7 -3.73832e-07 7.44771 -3.49691e-07 8C-3.2555e-07 8.55228 0.447715 9 1 9L12.5858 9L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893C8.31658 -0.0976318 7.68342 -0.0976318 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L12.5858 7L1 7Z"
                            fill="#FCFCFC"
                        />
                    </svg>
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={false} onClick={props.onClick}>
                <SvgIcon>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7L3.41421 7L8.70711 1.70711C9.09763 1.31658 9.09763 0.683417 8.70711 0.292893C8.31658 -0.0976314 7.68342 -0.0976315 7.29289 0.292893L0.292893 7.29289C-0.0976318 7.68342 -0.0976318 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L3.41421 9L15 9Z"
                            fill="#FCFCFC"
                        />
                    </svg>
                </SvgIcon>
            </ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const mobileSettings = {
        dots: true,
        infinite: false,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const defaultSettings = IsTablet() ? mobileSettings : settings;
    return (
        <Wrapper>
            <CarouselContainer>
                <Slider {...defaultSettings}>
                    {children}
                </Slider>
            </CarouselContainer>
        </Wrapper>
    );
};

export default Carousel;
