import React from 'react';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import Styled from './ModalExclusao.styled';
import { BlueSolidButton, RedSolidButton } from 'components/UI/Buttons';
import { MdDeleteForever } from 'react-icons/md';
interface IModalExclusao {
    showModal: boolean;
    handleHiden: (value: boolean) => void;
    text?: string;
    handleExcluxed: () => void;
    isLoading?: boolean;
}

const ModalExclusao = ({ showModal, handleHiden, text, handleExcluxed, isLoading }: IModalExclusao) => {
    return (
        <AdminModalShow show={showModal} onHide={() => handleHiden(false)}>
            <Styled.Modal.Title>Atenção!</Styled.Modal.Title>

            <Styled.Modal.SubTitle>
                Pense duas vezes antes de excluir esse item, pois a ação é irreversível e não será possível recuperar os dados ou informações
                associadas. <br /> Certifique-se de que realmente deseja prosseguir com a exclusão antes de continuar.
            </Styled.Modal.SubTitle>
            <br />
            <Styled.Modal.ButtonArea>
                <RedSolidButton onClick={() => handleExcluxed()}>{isLoading ? 'Excluíndo...' : 'Excluir'}</RedSolidButton>
                <BlueSolidButton onClick={() => handleHiden(false)}>Fechar</BlueSolidButton>
            </Styled.Modal.ButtonArea>
            <br />
        </AdminModalShow>
    );
};

export default ModalExclusao;
