import React from 'react';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';

export const HeadInfoStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background-color: #ffffff;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #efefef;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

    margin-bottom: 20px;

    .max-toptip {
        max-width: 280px;
        text-transform: none;
        line-height: 18px;
        font-size: 1.05em;

        @media only screen and (max-width: 280px) {
            max-width: 150px;
            font-size: 12px;
            text-align: center;
        }
    }
`;

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;

    @media only screen and (max-width: 760px) {
        align-items: flex-start;
    }
`;

export const ButtonProfileContainer = styled.button(() => {
    return new FullCss({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50px',
        width: '82px',
        height: '82px',
        border: 'none',
        background: 'transparent',
    }).getCss();
});

export const ProfileImage = styled.img`
    height: 76px;
    width: 76px;
    border-radius: 50%;
    object-fit: cover;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vw;
`;

export const TitleDiv = styled.div`
    display: flex;
    gap: 5vw;
    align-items: center;
`;

export const TitleUsername = styled.p`
    color: #003c78;
    font-weight: 700;
    font-size: clamp(24px, 2.5vw, 36px);
    line-height: 43px;
    word-break: break-word;
    white-space: pre-wrap;

    @media only screen and (max-width: 760px) {
        line-height: 1;
    }
`;

export const SubtitleProfile = styled.p`
    color: #124a88;
    font-weight: 400;
    font-size: clamp(12px, 2.5vw, 16px);
    line-height: 19px;
    letter-spacing: 0.3px;
`;

export const CellInfoContainer = styled.div`
    display: flex;
    gap: 4vw;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;

    @media only screen and (max-width: 760px) {
        row-gap: 0vw;
        column-gap: 10vw;
    }
`;

export const CellInfoContainerMobile = styled.div`
    display: flex;
    row-gap: 10px;
    column-gap: 20px;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    align-items: center;
`;

export const CellInfoExternalMobile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 370px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 0;
    }
`;

export const CellInfoInternalMobile = styled.div`
    display: flex;
    gap: 5vw;
    align-items: center;

    @media only screen and (max-width: 370px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }
`;

export const ImgSvg = styled.img`
    width: 13%;
    @media only screen and (max-width: 760px) {
        width: 24%;
    }
`;

export const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(8px, 2.9vw, 18px);
    line-height: 22px;
    color: #003c78;

    @media only screen and (max-width: 760px) {
        font-size: 12px;
        line-height: 1;
        padding: 5px;
    }
`;

export const VerPainelButton = styled.button`
    display: flex;
    max-height: 30px;
    min-width: 161px;
    background: #ffffff;
    border: 1px solid #003c78;
    border-radius: 5px;
    align-items: center;
    justify-content: space-around;

    &:hover {
        background: #003c78;
        img {
            filter: invert(1) sepia(1) saturate(0);
        }
        p {
            color: #ffffff;
        }
    }

    @media only screen and (max-width: 760px) {
    }

    @media only screen and (max-width: 760px) {
        min-height: 34px;
        max-width: 90px;
        min-width: auto;
        position: relative;
        text-align: initial;
    }
`;
