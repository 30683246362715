import React, { FunctionComponent, useState, useEffect, useCallback, useContext } from 'react';

import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import Header from '../UI/header/Header';
import PDP from '../../../models/pdp/PDP';
import Input from '../../../components/UI/Input';
import * as pdpActions from '../../../store/actions/Pdp';
import Text, { BoldText } from '../../../components/UI/Texts';
import { GradientButton } from '../../../components/UI/Buttons';
import PickList from '../../../components/UI/pickList/PickList';
import { useAppSelector } from '../../../hooks/LocalReduxThunk';
import Macrocompetencia from '../../../models/pdp/Macrocompetencia';
import PickListModel from '../../../components/UI/pickList/PickListModel';
import { AquecimentoStyle, FormControl, ButtonContainer, QuestionContainer, Container } from './Aquecimento.styled';
import { PdpContext } from '../context/PdpContext';
import Checklist from './Macrocompetencia/Checklist';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

const MIN_INPUT_LENGTH = 5;

interface IAquecimento {}
const Aquecimento: FunctionComponent<IAquecimento> = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(true);
    const [hasMacros, setHasMacros] = useState<boolean>(false);
    const [selecaoMacrosFinished, setSelecaoMacrosFinished] = useState<boolean>(false);
    const [listaMacrocompetenciasDestaque, setListaMacrocompetenciasDestaque] = useState<PickListModel[]>([]);
    const [macrosParaSelecaoMaisDesenvolvida, setMacrosParaSelecaoMaisDesenvolvida] = useState<PickListModel[]>([]);

    // Campos descritivos
    const [dificuldadesMacro, setDificuldadesMacro] = useState<string>('');
    const [formaDesenvolvimentoMacro, setFormaDesenvolvimentoMacro] = useState<string>('');
    const [isValidFields, setIsValidFields] = useState<boolean>(false);

    const dispatch = useDispatch();
    const pdp: PDP = useAppSelector(state => state.pdpReducer.pdp);
    const pdpOriginal: PDP = useAppSelector(state => state.pdpReducer.pdpOriginal);

    const currentStep = useAppSelector(state => state.pdpReducer.currentStepForm);

    const macrocompetencias: Macrocompetencia[] = useAppSelector(state => state.pdpReducer.macrocompetencias);

    const [macrosPreLoadedData, setMacrosPreLoadedData] = useState<PickListModel[]>([]);
    const [macroMaisDesenvolvidaPreLoadedData, setMacroMaisDesenvolvidaPreLoadedData] = useState<PickListModel[]>([]);

    const { operacaoAlteracao } = useContext(PdpContext.Context);

    const loadData = useCallback(async () => {
        setIsLoading(true);
        if (!(macrocompetencias && macrocompetencias.length > 0)) {
            await dispatch(pdpActions.fetchMacrocompetencias());
        }

        if (operacaoAlteracao && pdpOriginal.id > 0) {
            setSelecaoMacrosFinished(true);
            setDificuldadesMacro(pdpOriginal.dificuldadesMacrocompetencia);
            setFormaDesenvolvimentoMacro(pdpOriginal.formaDesenvolvimentoMacrocompetencia);

            let preLoadMacro: PickListModel[] = [];
            pdpOriginal.listaMacrocompetenciasDestaque.forEach(m => {
                preLoadMacro.push(
                    new PickListModel().fromJSON({
                        id: m.id,
                        descricao: m.descricao,
                        isSelected: true,
                    })
                );
            });

            setMacrosPreLoadedData(preLoadMacro);
            handleSelecaoMacros(preLoadMacro);

            let macroDesenv: PickListModel[] = [];
            macroDesenv.push(
                new PickListModel().fromJSON({
                    id: pdpOriginal.macrocompetenciaMaisDesenvolvida?.id,
                    descricao: pdpOriginal.macrocompetenciaMaisDesenvolvida?.descricao,
                    isSelected: true,
                })
            );
            setMacroMaisDesenvolvidaPreLoadedData(macroDesenv);
            handleSelecaoMacroMaisDesenvolvida(macroDesenv);
            validateInputs();

            //pdp.macrocompetenciaMaisDesenvolvida = macrocompetenciaMaisDesenvolvida;
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {}, [selecaoMacrosFinished]);

    useEffect(() => {
        if (!hasMacros) {
            setSelecaoMacrosFinished(false);
        }
    }, [hasMacros]);

    const handleSelecaoMacros = (novaLista: PickListModel[]) => {
        const newSelectedMacros: PickListModel[] = novaLista.filter((item: PickListModel) => item.isSelected === true);
        setHasMacros(newSelectedMacros.length === 2);
        setListaMacrocompetenciasDestaque(newSelectedMacros);
        validateInputs();
    };

    const handleSelecaoMacroMaisDesenvolvida = (novaLista: PickListModel[]) => {
        const newSelectedMacros: PickListModel[] = novaLista.filter((item: PickListModel) => item.isSelected === true);
        setHasMacros(newSelectedMacros.length === 1);
        setMacrosParaSelecaoMaisDesenvolvida(newSelectedMacros);
        validateInputs();
    };

    const onSubmitHandler = async () => {
        setSubmitting(true);

        pdp.listaMacrocompetenciasDestaque = [];

        let listaDestaques: Macrocompetencia[] = [];
        let listaDesenvolvidos: Macrocompetencia | null = null;

        macrosParaSelecaoMaisDesenvolvida.forEach(item => {
            const macro: Macrocompetencia = new Macrocompetencia().fromJSON({ id: item.id, descricao: item.descricao });
            listaDesenvolvidos = macro;
        });

        listaMacrocompetenciasDestaque.forEach(item => {
            const macro: Macrocompetencia = new Macrocompetencia().fromJSON({ id: item.id, descricao: item.descricao });
            listaDestaques.push(macro);
        });

        pdp.dificuldadesMacrocompetencia = dificuldadesMacro;
        pdp.formaDesenvolvimentoMacrocompetencia = formaDesenvolvimentoMacro;
        pdp.listaMacrocompetenciasDestaque = listaDestaques;
        pdp.macrocompetenciaMaisDesenvolvida = listaDesenvolvidos;

        try {
            await dispatch(pdpActions.storePDP(pdp));
            setSubmitting(false);
            dispatch(pdpActions.setCurrentFormStep(currentStep + 1));
        } catch (err) {
            setSubmitting(false);
        }
    };

    const validateInputs = () => {
        const isValid =
            listaMacrocompetenciasDestaque.length == 2 &&
            macrosParaSelecaoMaisDesenvolvida.length == 1 &&
            formaDesenvolvimentoMacro.trim().length >= MIN_INPUT_LENGTH &&
            dificuldadesMacro.trim().length >= MIN_INPUT_LENGTH;
        setIsValidFields(isValid);
    };

    useEffect(() => {
        validateInputs();
    }, [listaMacrocompetenciasDestaque, macrosParaSelecaoMaisDesenvolvida, formaDesenvolvimentoMacro, dificuldadesMacro]);

    return (
        <AquecimentoStyle>
            <Header title={`Olá, vamos começar refletindo sobre as suas fortalezas!`} />

            {!isLoading && (
                <>
                    <FormControl>
                        <QuestionContainer>
                            <Text>
                                Pensando no resultado da sua autoavaliação socioemocional,
                                <BoldText> selecione as 2 (duas) macrocompetências</BoldText> nas quais você se destaca e que o definem como
                                profissional:
                            </Text>
                        </QuestionContainer>

                        <Text style={listaMacrocompetenciasDestaque.length > 2 ? { color: '#F00000' } : { display: 'none' }}>
                            {'* Selecione somente 2 macrocompetências'}
                        </Text>
                        <Container isInvalid={listaMacrocompetenciasDestaque.length > 2}>
                            <Checklist
                                preLoadList={macrosPreLoadedData}
                                data={macrocompetencias}
                                destaque={false}
                                disabled={selecaoMacrosFinished && !operacaoAlteracao}
                                onCallback={(lista: PickListModel[]) => {
                                    handleSelecaoMacros(lista);
                                }}
                            />
                        </Container>

                        <QuestionContainer>
                            <Text>
                                Pensando no resultado da sua autoavaliação socioemocional,
                                <BoldText> destaque a que você percebe que mais desenvolveu</BoldText> nos últimos anos de sua atuação:
                            </Text>
                        </QuestionContainer>

                        <Text style={macrosParaSelecaoMaisDesenvolvida.length > 1 ? { color: 'red' } : { display: 'none' }}>
                            {'* Selecione somente 1 macrocompetência'}
                        </Text>
                        <Container isInvalid={macrosParaSelecaoMaisDesenvolvida.length > 1}>
                            <Checklist
                                preLoadList={macroMaisDesenvolvidaPreLoadedData}
                                data={macrocompetencias}
                                destaque={true}
                                onCallback={(lista: PickListModel[]) => {
                                    handleSelecaoMacroMaisDesenvolvida(lista);
                                }}
                            />
                        </Container>
                    </FormControl>

                    <FormControl>
                        <QuestionContainer>
                            <Text>
                                <BoldText>Como você fez para avançar e o que aprendeu com isso?</BoldText> (Pense em práticas que você adotou, em
                                conversas que teve, em estudos que realizou, por exemplo)
                            </Text>
                        </QuestionContainer>
                        <Input
                            value={formaDesenvolvimentoMacro}
                            onchange={e => {
                                setFormaDesenvolvimentoMacro(e.target.value);
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <QuestionContainer>
                            <Text>
                                <BoldText> O que considerou mais difícil nesse processo e como superou?</BoldText>
                            </Text>
                        </QuestionContainer>
                        <Input
                            value={dificuldadesMacro}
                            onchange={e => {
                                setDificuldadesMacro(e.target.value);
                            }}
                        />

                        <ButtonContainer>
                            <GradientButton
                                disabled={!isValidFields}
                                onClick={() => {
                                    GTM_Page_Jornada_Desenvolvimento('painel_pdp_aquecimento', 'continuar_btn');
                                    onSubmitHandler();
                                }}
                            >
                                Continuar
                            </GradientButton>
                        </ButtonContainer>
                    </FormControl>
                </>
            )}
        </AquecimentoStyle>
    );
};

export default Aquecimento;
