import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico2 from './PerfilDTOGenerico2';

class LocalidadeDTO extends PerfilDTOGenerico2 {
    constructor() {
        super();
    }
}

export default LocalidadeDTO;
