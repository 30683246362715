import { useKeycloak } from '@react-keycloak/web';
import { GTM_Menu } from 'components/GoogleTagManager';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';
import Colors from 'enums/Colors';
import { useOutsideRef } from 'hooks/useOutsideRef';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import { CheckedGoToContext } from 'pages/painelSocioemocional/home/context/CheckedGoToContext';
import React, { useContext, useReducer, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { MenuOptions, MenuOptionsWithHover, OptionsContainer, PopOver } from '../ActionButton/ActionButton.styled';
import { Link, MenuItem, MenuItemTextHover, NavHashLinkStyle, SearchButtonContainer, Point } from './HomepageMenuStyled.styled';
import { urlPagIntermediariaRE } from 'constants/EnvironmentData';

const CPCRoute: string = '/criatividade-pensamento-critico';

const jornadas: string[] = ['/criatividade-pensamento-critico', '/alfabetizacao-360', '/realizando-escolhas', '/socioemocional-professores'];

function selectedStyle(route: string | undefined): CSSProperties {
    const location = useLocation();
    const actualRoute = location.pathname;
    let style: CSSProperties = {
        color: Colors.Blue,
        cursor: 'pointer',
        fontWeight: '700',
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textUnderlineOffset: '18px',
    };

    if (!(actualRoute === route) && !(route === 'jornada' && jornadas.indexOf(actualRoute) >= 0)) {
        if (!(route === 'objetivos' && location.hash === '#objetivos')) {
            style = {};
        }
    }

    if (route === '/' && location.hash === '#objetivos') {
        style = {};
    }

    return style;
}

export const OtherPageWebItems = () => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [openMenu, setOpenMenu] = useState(false);

    const { openSearch } = useContext(PesquisaContext.Context);

    const { isVerifyGoToPage, showModal, setShowModal, goToPage } = useContext(CheckedGoToContext.Context);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const goToHome = () => {
        isVerifyGoToPage('/');
    };

    const goToBiblioteca = () => {
        isVerifyGoToPage('/biblioteca');
    };

    const goToCursosLivres = () => {
        isVerifyGoToPage('/cursos-livres');
    };

    const goToMeuPainel = () => {
        isVerifyGoToPage('/painel');
    };

    const goToSuporte = () => {
        isVerifyGoToPage('/suporte');
    };

    const popOverRef = useRef(null);
    useOutsideRef(popOverRef, () => {
        setOpenMenu(false);
    });

    return (
        <>
            <MenuItem
                onClick={() => {
                    GTM_Menu('menu_header', 'inicio_btn_intermediaria');
                    goToHome();
                }}
            >
                <MenuItemTextHover style={selectedStyle('/')}>Início</MenuItemTextHover>
                <Point className="point"></Point>
            </MenuItem>

            {!keycloak.authenticated && (
                <MenuItem
                    onClick={() => {
                        GTM_Menu('menu_header', 'objetivos_btn_intermediaria');
                        window.location.href = window.location.origin + '/#objetivos';
                    }}
                >
                    <MenuItemTextHover style={selectedStyle('objetivos')}>Objetivos</MenuItemTextHover>
                    <Point className="point"></Point>
                </MenuItem>
            )}

            <div style={{ position: 'relative' }}>
                <MenuItem onClick={toggleMenu}>
                    <MenuItemTextHover style={selectedStyle('jornada')}>Jornadas</MenuItemTextHover>
                    <Point className="point"></Point>
                    {openMenu && (
                        <PopOver ref={popOverRef}>
                            <OptionsContainer>
                                {keycloak.authenticated ? (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'socioemocional_btn_intermediaria');
                                            isVerifyGoToPage('/painelSocioemocional/home');
                                        }}
                                    >
                                        {`Socioemocional Professores`}
                                    </MenuOptionsWithHover>
                                ) : (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'socioemocional_btn_intermediaria');
                                            isVerifyGoToPage('/socioemocional-professores');
                                        }}
                                    >
                                        {`Socioemocional Professores`}
                                    </MenuOptionsWithHover>
                                )}
                                <MenuOptionsWithHover
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'alf_360_btn_intermediaria');
                                        isVerifyGoToPage('/alfabetizacao-360');
                                    }}
                                >
                                    Alfabetização 360°
                                </MenuOptionsWithHover>
                                {keycloak.authenticated ? (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'realizando_escolhas_btn');
                                            history.push({
                                                pathname: '/login',
                                                state: { pathToRedirect: '/realizandoEscolhas/home' },
                                            });
                                        }}
                                    >
                                        {`Realizando Escolhas`}
                                    </MenuOptionsWithHover>
                                ) : (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'realizando_escolhas_btn');
                                            history.push('/pagina/realizandoescolhas');
                                        }}
                                    >
                                        Realizando Escolhas
                                    </MenuOptionsWithHover>
                                )}
                                <MenuOptionsWithHover
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'cpc_btn');
                                        isVerifyGoToPage('/criatividade-pensamento-critico');
                                    }}
                                >
                                    Criatividade e Pensamento Crítico
                                </MenuOptionsWithHover>
                            </OptionsContainer>
                        </PopOver>
                    )}
                </MenuItem>
            </div>

            <MenuItem
                onClick={() => {
                    GTM_Menu('menu_header', 'biblioteca_btn_intermediaria');
                    goToBiblioteca();
                }}
            >
                <MenuItemTextHover style={selectedStyle('/biblioteca')}>Biblioteca</MenuItemTextHover>
                <Point className="point"></Point>
                <div className="point"></div>
            </MenuItem>

            <MenuItem
                onClick={() => {
                    GTM_Menu('menu_header', 'cursoslivres_btn_intermediaria');
                    goToCursosLivres();
                }}
            >
                <MenuItemTextHover style={selectedStyle('/cursos-livres')}>Cursos livres</MenuItemTextHover>
                <Point className="point"></Point>
            </MenuItem>

            {keycloak.authenticated && (
                <MenuItem
                    onClick={() => {
                        GTM_Menu('menu_header', 'painel_btn_intermediaria');
                        goToMeuPainel();
                    }}
                >
                    <MenuItemTextHover style={selectedStyle('/painel')}>Meu painel</MenuItemTextHover>
                    <Point className="point"></Point>
                </MenuItem>
            )}

            <MenuItem
                onClick={() => {
                    GTM_Menu('menu_header', 'suporte_btn_intermediaria');
                    goToSuporte();
                }}
            >
                <MenuItemTextHover style={selectedStyle('/suporte')}>Suporte</MenuItemTextHover>
                <Point className="point"></Point>
            </MenuItem>

            <SearchButtonContainer onClick={openSearch}>
                <FiSearch />
            </SearchButtonContainer>

            <ModalCheckGoToPage
                showModal={showModal}
                setShowModal={setShowModal}
                goToPage={goToPage}
                isRealizandoEscolhas={location.pathname === '/realizandoEscolhas/avaliacao' ? true : false}
            />
        </>
    );
};

interface ILandingPageWebItems {
    callback: (el: any) => void;
}
export const LandingPageWebItems = ({ callback }: ILandingPageWebItems) => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [openMenu, setOpenMenu] = useState(false);
    const { openSearch } = useContext(PesquisaContext.Context);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const goToBiblioteca = () => {
        history.push('/biblioteca');
    };

    const goToCursosLivres = () => {
        history.push('/cursos-livres');
    };

    const goToMeuPainel = () => {
        history.push('/painel');
    };

    const goToSuporte = () => {
        history.push('/suporte');
    };

    const popOverRef = useRef(null);
    useOutsideRef(popOverRef, () => {
        setOpenMenu(false);
    });

    return (
        <>
            <NavHashLinkStyle
                smooth
                scroll={el => callback(el)}
                to={keycloak.authenticated ? '#jornadas-de-desenvolvimento' : '#inicio'}
                onClick={() => GTM_Menu('menu_header', 'inicio_btn')}
            >
                <MenuItem>
                    <MenuItemTextHover style={selectedStyle('/')}>Início</MenuItemTextHover>
                    <Point className="point"></Point>
                </MenuItem>
            </NavHashLinkStyle>

            {!keycloak.authenticated && (
                <NavHashLinkStyle smooth scroll={el => callback(el)} to="#objetivos" onClick={() => GTM_Menu('menu_header', 'objetivos_btn')}>
                    <MenuItem>
                        <MenuItemTextHover style={selectedStyle('objetivos')}>Objetivos</MenuItemTextHover>
                        <Point className="point"></Point>
                    </MenuItem>
                </NavHashLinkStyle>
            )}

            <div style={{ position: 'relative' }}>
                <MenuItem onClick={toggleMenu}>
                    <MenuItemTextHover style={selectedStyle(CPCRoute)}>Jornadas</MenuItemTextHover>
                    <Point className="point"></Point>
                    {openMenu && (
                        <PopOver ref={popOverRef}>
                            <OptionsContainer>
                                {keycloak.authenticated ? (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'socioemocional_btn');
                                            history.push({
                                                pathname: '/login',
                                                state: { pathToRedirect: '/painelSocioemocional/home' },
                                            });
                                        }}
                                    >
                                        {`Socioemocional Professores`}
                                    </MenuOptionsWithHover>
                                ) : (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'socioemocional_btn');
                                            history.push('/socioemocional-professores');
                                        }}
                                    >
                                        {`Socioemocional Professores`}
                                    </MenuOptionsWithHover>
                                )}
                                <MenuOptionsWithHover
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'alf_360_btn');
                                        history.push('/alfabetizacao-360');
                                    }}
                                >
                                    Alfabetização 360°
                                </MenuOptionsWithHover>

                                {keycloak.authenticated ? (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'realizando_escolhas_btn');
                                            history.push({
                                                pathname: '/login',
                                                state: { pathToRedirect: '/realizandoEscolhas/home' },
                                            });
                                        }}
                                    >
                                        {`Realizando Escolhas`}
                                    </MenuOptionsWithHover>
                                ) : (
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'realizando_escolhas_btn');
                                            history.push('/pagina/realizandoescolhas');
                                        }}
                                    >
                                        Realizando Escolhas
                                    </MenuOptionsWithHover>
                                )}

                                <MenuOptionsWithHover
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'cpc_btn');
                                        history.push('/criatividade-pensamento-critico');
                                    }}
                                >
                                    Criatividade e Pensamento Crítico
                                </MenuOptionsWithHover>
                            </OptionsContainer>
                        </PopOver>
                    )}
                </MenuItem>
            </div>

            <Link
                onClick={() => {
                    GTM_Menu('menu_header', 'biblioteca_btn');
                    goToBiblioteca();
                }}
            >
                <MenuItem>
                    <MenuItemTextHover style={selectedStyle('/biblioteca')}>Biblioteca</MenuItemTextHover>
                    <Point className="point"></Point>
                </MenuItem>
            </Link>

            <Link
                onClick={() => {
                    GTM_Menu('menu_header', 'cursoslivres_btn');
                    goToCursosLivres();
                }}
            >
                <MenuItem>
                    <MenuItemTextHover style={selectedStyle('/cursos-livres')}>Cursos livres</MenuItemTextHover>
                    <Point className="point"></Point>
                </MenuItem>
            </Link>
            {keycloak.authenticated && (
                <Link
                    onClick={() => {
                        GTM_Menu('menu_header', 'painel_btn');
                        goToMeuPainel();
                    }}
                >
                    <MenuItem>
                        <MenuItemTextHover style={selectedStyle('/painel')}>Meu painel</MenuItemTextHover>
                        <Point className="point"></Point>
                    </MenuItem>
                </Link>
            )}

            <MenuItem
                onClick={() => {
                    GTM_Menu('menu_header', 'suporte_btn');
                    goToSuporte();
                }}
            >
                <MenuItemTextHover style={selectedStyle('/suporte')}>Suporte</MenuItemTextHover>
                <Point className="point"></Point>
            </MenuItem>

            <SearchButtonContainer onClick={openSearch}>
                <FiSearch />
            </SearchButtonContainer>
        </>
    );
};
