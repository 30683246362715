import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleCoursesHome = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        height: 464px;
        background-color: ${Colors.White};

        @media screen and (max-width: 700px) {
            height: 940px;
        }
    `,

    Body: styled.div`
        display: flex;

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    `,

    Content: styled.div<{ img: string }>`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        width: 50%;
        height: 400px;
        background-image: radial-gradient(
                circle,
                rgba(0, 0, 0, 0.4009978991596639) 0%,
                rgba(47, 47, 47, 0.5102415966386555) 49%,
                rgba(8, 8, 8, 0.7931547619047619) 100%
            ),
            url(${props => props.img});
        background-size: cover;
        background-position: center;
        padding-left: 53px;
        margin: 0;

        @media screen and (min-width: 1919px) {
            background-position: center 20%;
        }
        @media screen and (min-width: 2500px) {
            background-position: center 20%;
        }

        .tag,
        h1 {
            transform: translateY(100px);
        }

        &:hover {
            .tag {
                background-color: ${Colors.Blue};
                color: ${Colors.White};
            }
            .tag,
            h1 {
                transform: translateY(0);
                transition: all 0.3s ease;
            }

            .description_course,
            .button_course {
                opacity: 1; /* Torna o botão visível */
                transform: translateY(0); /* Move o botão para a posição original */
            }
        }

        .description_course,
        .button_course {
            transform: translate(0%, 70px); /* Começa fora da tela, movendo-o para baixo */
            opacity: 0; /* Começa invisível */
            transition: all 0.3s ease; /* Suaviza a transição para visibilidade e posição */

            
        }

        @media screen and (max-width: 700px) {
            width: 100%;
            height: 440px;
            padding-left: 20px;
            justify-content: end;
            padding-bottom: 22px;

            .description_course {
                display: none;
            }
            .tag {
                background-color: ${Colors.Blue};
                color: ${Colors.White};
            }
            .tag,
            h1 {
                transform: translateY(0);
                transition: all 0.3s ease;
            }

            .button_course {
                opacity: 1; /* Torna o botão visível */
                transform: translateY(0); /* Move o botão para a posição original */
            }
        }
    `,

    TagCourse: styled.div`
        text-align: center;
        width: 86px;
        height: 29px;
        padding: 10px;
        gap: 10px;
        background-color: ${Colors.Yellow};
        font-weight: 600;
        font-size: 14px;
        transition: all 0.3s ease-out;
    `,

    TitleCard: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        text-align: left;
        color: ${Colors.White};
        max-width: 468px;
    `,

    DescriptionCard: styled.p`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 25.6px;
        max-width: 480px;
        color: ${Colors.White};

        @media screen and (max-width: 700px){
            max-width: 90%;
        }
    `,

    Footer: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        height: 100%;

        a {
            color: ${Colors.SoftBlue};
        }

        @media screen and (max-width: 600px) {
            text-align: center;
            font-size: 16px;
        }
    `,
};

export default StyleCoursesHome;
