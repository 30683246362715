import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const MainContainer = styled.div(() => ({
    width: '100%',
    paddingBottom: '20px',
    paddingTop: '20px',
    backgroundColor: `${Colors.White}`,
}));

export const TitleContainer = styled.div<{ padding?: string }>`
    display: flex;
    padding: ${({ padding }) => (padding ? padding : '0 0 50px 100px')};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 40px 20px 40px 20px;
        justify-content: center;
    }
`;

export const DescriptionContainer = styled.div<{ padding?: string }>`
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => (padding ? padding : '0 0 50px 100px')};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        align-items: center;
        padding: 0 30px 30px 30px;
    }
`;

export const TitleText = styled.p`
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;
`;

export const Text = styled.p`
    text-align: flex-start;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #2e2e2e;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 14px;
        max-width: 315px;
        text-align: center;
        width: 100%;
    }
`;

export const ButtonContainer = styled.div<{ padding?: string }>`
    display: flex;
    width: 100%;
    padding: ${({ padding }) => (padding ? padding : '0 0 50px 100px')};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        justify-content: center;
        padding: 0 0 30px 0;
    }
`;

export const Button = styled.button`
    color: ${Colors.White};
    font-weight: 700;
    font-size: clamp(12px, 3vw, 14px);
    line-height: 18px;
    letter-spacing: 0.3px;
    width: 250px;
    padding: 12px 13px;
    gap: 10px;
    background: ${Colors.Blue};
    border: 1px solid ${Colors.Blue};
    border-radius: 50px;

    &:hover {
        background: ${Colors.DarkBlue3};
        border: 1px solid ${Colors.DarkBlue3};
        color: ${Colors.White};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 50%;
        height: 100%;
        line-height: unset;
    }
`;
