import React from 'react';
import Styled from './ButtonGeneric.styled';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';

interface IButtonGeneric {
    type: ButtonsCustom;
    name: string;
    styled?: any;
    onClick: () => void;
}

const ButtonGeneric = ({ name, type, styled, onClick }: IButtonGeneric) => {
    return (
        <Styled.Button style={{ ...styled }} onClick={onClick} typeButton={type}>
            {name}
        </Styled.Button>
    );
};

export default ButtonGeneric;
