import React, { useState } from 'react';
import Styled from './AdminModal.styled';
import { ModalBodyStyled } from 'components/UI/dialog/Dialog.styled';
import { Modal } from 'react-bootstrap';
import Row from 'components/Row';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { fetchRoleDescription } from 'core/util/KeycloakHelp';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Select, { SelectOptionType } from 'components/Select';
import { RoleEnum } from 'enums/RoleEnum';

export interface IAdminModalEdit {
    show: boolean;
    onHide: () => void;
    perfil: PerfilUsuario;
    onSubmit: (perfil: PerfilUsuario, newRole: string) => Promise<void>;
}

const AdminModalEdit = ({ show, onHide, perfil, onSubmit }: IAdminModalEdit) => {
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const getRoles = (): SelectOptionType[] => {
        let _roles = [
            [RoleEnum.Professor, 'Professor'],
            /*  [RoleEnum.Diretor, 'Diretor'],
            ['COORD_N_PARCEIRO', 'Coordenador não Parceiro'],
            [RoleEnum.TecnicoEducacao, 'Técnico de Secretaria de Educação'], */
        ]
            .map(r => ({ value: r[0], label: r[1] } as SelectOptionType))
            .filter(o => o.value != perfil.role);

        return _roles;
    };

    const hide = () => {
        setSelectedRole(null);
        setSubmitting(false);
        onHide();
    };

    return (
        <Modal show={show} onHide={hide}>
            <Styled.Container>
                <Row justify="space-between">
                    <Styled.Title>Alterar Perfil</Styled.Title>
                    <Styled.CloseIconContainer>
                        <AiOutlineCloseCircle onClick={() => onHide()} />
                    </Styled.CloseIconContainer>
                </Row>
                <br />
                <Styled.Text>Usuário: {perfil.apelido}</Styled.Text>
                <Styled.Text>Perfil atual: {fetchRoleDescription(perfil.role) || perfil.role || perfil.ocupacao}</Styled.Text>
                <br />
                <br />
                <Styled.TextStrong>Novo perfil:</Styled.TextStrong>
                <Select isSmall itens={getRoles()} width="100%" onSelected={o => setSelectedRole(String(o.value))} />
                <br />
                <br />
                <Row justify="space-between">
                    <Styled.CancelButton onClick={hide}>Cancelar</Styled.CancelButton>
                    <Styled.PrimaryButton
                        onClick={async () => {
                            setSubmitting(true);
                            await onSubmit(perfil, selectedRole || RoleEnum.Professor);
                            setSubmitting(false);
                        }}
                        disabled={selectedRole == null || submitting}
                    >
                        {submitting ? 'Alterando' : 'Alterar Perfil'}
                    </Styled.PrimaryButton>
                </Row>
            </Styled.Container>
        </Modal>
    );
};

export default AdminModalEdit;
