import { OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminSocioEmocionalStyled = {
    Container: styled.div`
        background-color: white;
        padding: 20px;
        margin-bottom: 100px;
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 26px;
        line-height: 42px;
        color: #183f73;
    `,

    Text: styled.p`
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #2e2e2e;
    `,

    TopBlueBar: styled.div`
        background: linear-gradient(318.36deg, #00a8e9 0%, #47aefc 100%);
        border-radius: 5px;
        width: 100%;
        margin-bottom: 15px;
        padding: 15px 20px;

        display: flex;
        justify-content: space-between;

        p {
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            display: flex;
            align-items: center;
            letter-spacing: 0.04em;
            color: #ffffff;
        }
    `,

    RigthButton: styled(OutlineButton)`
        border-color: white;
        color: white;
        background-color: transparent;
        transform: scale(0.85);
    `,

    RedirectButton: styled.div`
        width: 730px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #c9c9c9;

        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        margin: 25px 0;

        :hover {
            background-color: #f8f8f8;
        }

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #5f758b;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,
};

export default AdminSocioEmocionalStyled;
