import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleJourneysHome = {
    Container: styled.div`
        display: flex;
        position: relative;
        padding: 52.5px 73px;
        background-color: ${Colors.Blue};
        min-height: 315px;
        gap: 1rem;

        @media screen and (max-width: 1100px) {
            padding-left: 40px;
        }

        @media screen and (max-width: 1000px) {
            flex-wrap: wrap;
            gap: 2rem;
        }
    `,

    Title1: styled.h1`
        font-family: 'Barlow';
        font-size: 32px;
        font-weight: 800;
        line-height: 38.4px;
        text-align: left;
        color: ${Colors.White};

        div {
            width: 36px;
            height: 36px;
            align-items: center;
            justify-content: center;
            position: absolute;
            text-align: center;
            left: 260px;
            top: 50px;
            background-color: ${Colors.Yellow};
            color: ${Colors.Blue};
            font-size: 22px;
            font-weight: 400;
            border-radius: 4px;
            transform: rotate(-22deg);

            @media screen and (max-width: 1100px) {
                left: 220px;
            }
        }
    `,

    Title2: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        text-align: left;
        color: ${Colors.White};
        max-width: 400px;
        min-height: 45px;

         @media screen and (min-width: 1000px) and (max-width: 1262px) {
            min-height: 70px;
         }
         @media screen and (max-width: 926px) {
            min-height: 70px;
         }

        @media screen and (max-width: 700px) {
            max-width: 100%;
            min-height: auto;
            margin-left: 0;
        }
    `,

    BodyJourneys: styled.div`
        display: flex;
        justify-content: space-around;
        width: 100%;
        gap: 2rem;

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    `,

    Content2: styled.div`
        display: flex;
        align-items: start;
        flex-direction: column;
        height: 150px;
        justify-content: space-between;
        gap: 9px;
    `,
    Content: styled.div`
        display: flex;
        align-items: start;
        flex-direction: column;
        min-height: 250px;
        gap: 3px;

        @media screen and (max-width: 700px) {
            
            min-height: auto;
            margin-top: 22px;
        }


    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: ${Colors.White};
        max-width: 400px;
        min-height: 160px;
     

        @media screen and (max-width: 1350px){
            height: 100%;
        }

        @media screen and (max-width: 700px){
           max-width: 100%;
           margin-left: 0;
           
        }
    `,
};

export default StyleJourneysHome;
