//Duplo foco
import DuploFoco from '../assets/dialogos/materiais/duplo_foco_modelo_pedagogico_2024.pdf';

//Checklist
import CheckList from '../assets/dialogos/materiais/checklist_de_apoio_ao_professor.pdf';

//Caderno de sistematica de acompanhamento
import CA from '../assets/dialogos/materiais/caderno_orientacoes_sobre sistematica_de_acompanhamento.pdf';

//Caderno de gestao e gerenciamento
import CGG from "../assets/dialogos/materiais/caderno_orientacoes_de_gestao_e_gerenciamento.pdf"


//Caderno de gestao e gerenciamento para avalição
import CGGA from "../assets/dialogos/materiais/caderno_de_orientacao_para_avaliacao_das_competencias_socioemocionais.pdf"

//artes_linguagens
import AL1 from '../assets/dialogos/materiais/artes_linguagens/VF_DIGITAL_DSE_ARTES_ARQUIVO_COMPLETO.pdf';
import AL2 from '../assets/dialogos/materiais/artes_linguagens/VF_DIGITAL_DSE_ARTES_ENSINO-FUNDAMENTAL.pdf';
import AL3 from '../assets/dialogos/materiais/artes_linguagens/VF_DIGITAL_DSE_ARTES_ENSINO-MEDIO.pdf';

//ciencias_da_natureza
import CN1 from '../assets/dialogos/materiais/ciencias_da_natureza/VF_DIGITAL_DSE_CIENCIAS_ARQUIVO_COMPLETO.pdf';
import CN2 from '../assets/dialogos/materiais/ciencias_da_natureza/VF_DIGITAL_DSE_CIENCIAS_ENSINO-FUNDAMENTAL.pdf';
import CN3 from '../assets/dialogos/materiais/ciencias_da_natureza/VF_DIGITAL_DSE_CIENCIAS_ENSINO-MEDIO.pdf';

// Educação Física
import ED1 from '../assets/dialogos/materiais/educacao_fisica/VF_DIGITAL_DSE_EDUCACAO_FISICA_ARQUIVO_COMPLETO.pdf';
import ED2 from '../assets/dialogos/materiais/educacao_fisica/VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-FUNDAMENTAL.pdf';
import ED3 from '../assets/dialogos/materiais/educacao_fisica/VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-MEDIO.pdf';

// Educação Física
import ENR1 from '../assets/dialogos/materiais/ensino_religioso/VF_DIGITAL_DSE_ENSINO_RELIGIOSO_COMPLETO.pdf';

// Filosofia
import FLS1 from '../assets/dialogos/materiais/filosofia/VF_DIGITAL_DSE_FILOSOFIA_COMPLETO.pdf';

// Física
import FSC1 from '../assets/dialogos/materiais/fisica/VF_DIGITAL_DSE_FISICA.pdf';

// lingua portuguesa
import LP1 from '../assets/dialogos/materiais/lingua_portuguesa/VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ARQUIVO_COMPLETO.pdf';
import LP2 from '../assets/dialogos/materiais/lingua_portuguesa/VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-FUNDAMENTAL.pdf';
import LP3 from '../assets/dialogos/materiais/lingua_portuguesa/VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-MEDIO.pdf';

// Matemática
import MT1 from '../assets/dialogos/materiais/matematica/VF_DIGITAL_DSE_MATEMATICA_ARQUIVO_COMPLETO.pdf';
import MT2 from '../assets/dialogos/materiais/matematica/VF_DIGITAL_DSE_MATEMATICA_ENSINO-FUNDAMENTAL.pdf';
import MT3 from '../assets/dialogos/materiais/matematica/VF_DIGITAL_DSE_MATEMATICA_ENSINO-MEDIO.pdf';

// Quimica
import QM1 from '../assets/dialogos/materiais/quimica/VF_DIGITAL_DSE_QUIMICA.pdf';

// Sociologia
import SC1 from '../assets/dialogos/materiais/sociologia/VF_DIGITAL_DSE_SOCIOLOGIA_COMPLETO.pdf';

// GEO CIENCIAS HUMANAS
import GCH1 from '../assets/dialogos/materiais/geografia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_GEOGRAFIA_COMPLETO.pdf';
import GCH2 from '../assets/dialogos/materiais/geografia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-FUNDAMENTAL.pdf';
import GCH3 from '../assets/dialogos/materiais/geografia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-MEDIO.pdf';

// HIST CIENCIAS HUMANAS
import HCH1 from '../assets/dialogos/materiais/historia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_HISTORIA_COMPLETO.pdf';
import HCH2 from '../assets/dialogos/materiais/historia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_HISTORIA_ENSINO-FUNDAMENTAL.pdf';
import HCH3 from '../assets/dialogos/materiais/historia_ciencias_humanas_sociais_e_aplicadas/VF_DIGITAL_DSE_HISTORIA_ENSINO-MEDIO.pdf';

// MATERIAS EXTRAS
import MTX1 from "../assets/dialogos/materiais/extras/ebook_competencias_socioemocionais_sao_para_a_vida.pdf"
import MTX2 from "../assets/dialogos/materiais/extras/ebook_trajetorias_socioemocionais_durante_a_pandemia.pdf"
import MTX3 from "../assets/dialogos/materiais/extras/infografico_bem-estar_x_competencias_socioemocionais.pdf"
import MTX4 from "../assets/dialogos/materiais/extras/infografico_bullying_x_competencias_socioemocionais.pdf"
import MTX5 from "../assets/dialogos/materiais/extras/infografico_desempenho_escolar_x_competencias_socioemocionais.pdf"
import MTX6 from "../assets/dialogos/materiais/extras/infografico_pertencimento_x_competencias_socioemocionais.pdf"
import MTX7 from "../assets/dialogos/materiais/extras/infografico_saude_mental_x_competencias_socioemocionais.pdf"
import MTX8 from "../assets/dialogos/materiais/extras/infografico_violencia_escolar_x_competencias_socioemocionais.pdf"


type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
    
};

export const ListMateriaisDialogosSocioemocionais: TItem[] = [
    {
        title: 'Modelo pedagógico Duplo Foco',
        materiais: [
            {
                arquivos: [{ nome: 'duplo_foco_modelo_pedagogico_2024', arquivo: DuploFoco }],
            },
        ],
    },
    {
        title: 'Banco de planejamentos docentes inspiracionais',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Artes Linguagens',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_ARTES_ARQUIVO_COMPLETO', arquivo: AL1 },
                    { nome: 'VF_DIGITAL_DSE_ARTES_ENSINO-FUNDAMENTAL', arquivo: AL2 },
                    { nome: 'VF_DIGITAL_DSE_ARTES_ENSINO-MEDIO', arquivo: AL3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ciências das Natureza',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ARQUIVO_COMPLETO', arquivo: CN1 },
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ENSINO-FUNDAMENTAL', arquivo: CN2 },
                    { nome: 'VF_DIGITAL_DSE_CIENCIAS_ENSINO-MEDIO', arquivo: CN3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Educação Física',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ARQUIVO_COMPLETO', arquivo: ED1 },
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-FUNDAMENTAL', arquivo: ED2 },
                    { nome: 'VF_DIGITAL_DSE_EDUCACAO_FISICA_ENSINO-MEDIO', arquivo: ED3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ensino Relegioso',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_ENSINO_RELIGIOSO_COMPLETO', arquivo: ENR1 }],
            },
            {
                isPaste: true,
                titlePaste: 'Filosofia',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_FILOSOFIA_COMPLETO', arquivo: FLS1 }],
            },
            {
                isPaste: true,
                titlePaste: 'Física',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_FISICA', arquivo: FSC1 }],
            },

            {
                isPaste: true,
                titlePaste: 'Língua Portuguesa',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ARQUIVO_COMPLETO', arquivo: LP1 },
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-FUNDAMENTAL', arquivo: LP2 },
                    { nome: 'VF_DIGITAL_DSE_LINGUA_PORTUGUESA_ENSINO-MEDIO', arquivo: LP3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Matemática',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ARQUIVO_COMPLETO', arquivo: MT1 },
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ENSINO-FUNDAMENTAL', arquivo: MT2 },
                    { nome: 'VF_DIGITAL_DSE_MATEMATICA_ENSINO-MEDIO', arquivo: MT3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Química',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_QUIMICA', arquivo: QM1 }],
            },
            {
                isPaste: true,
                titlePaste: 'Sóciologia',
                arquivos: [{ nome: 'VF_DIGITAL_DSE_SOCIOLOGIA_COMPLETO', arquivo: SC1 }],
            },
            {
                isPaste: true,
                titlePaste: 'Geográfia Ciências Humanas, Sociais e Aplicadas',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_COMPLETO', arquivo: GCH1 },
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-FUNDAMENTAL', arquivo: GCH2 },
                    { nome: 'VF_DIGITAL_DSE_GEOGRAFIA_ENSINO-MEDIO', arquivo: GCH3 },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'História Ciências Humanas, Sociais e Aplicadas',
                arquivos: [
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_COMPLETO', arquivo: HCH1 },
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_ENSINO-FUNDAMENTAL', arquivo: HCH2 },
                    { nome: 'VF_DIGITAL_DSE_HISTORIA_ENSINO-MEDIO', arquivo: HCH3 },
                ],
            },
        ],
    },
    {
        title: 'Checklist do planejamento docente',
        materiais: [
            {
                arquivos: [{ nome: 'checklist_de_apoio_ao_professor', arquivo: CheckList }],
            },
        ],
    },
    {
        title: 'Orientações sobre Sistemática de Acompanhamento',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_orientacoes_sobre sistematica_de_acompanhamento', arquivo: CA }],
            },
        ],
    },
    {
        title: 'Orientações de Gestão e Gerenciamento',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_orientacoes_de_gestao_e_gerenciamento', arquivo: CGG }],
            },
        ],
    },
    {
        title: 'Caderno de Orientação para Avaliação',
        materiais: [
            {
                arquivos: [{ nome: 'caderno_de_orientacao_para_avaliacao_das_competencias_socioemocionais', arquivo: CGGA }],
            },
        ],
    },
    {
        title: 'Materiais extras',
        materiais: [
            {
                isPaste: true,
                titlePaste: "Ebooks",
                arquivos: [
                    { nome: 'ebook_competencias_socioemocionais_sao_para_a_vida', arquivo: MTX1 },
                    { nome: 'ebook_trajetorias_socioemocionais_durante_a_pandemia', arquivo: MTX2 },
                ]
            },
            {
                isPaste: true,
                titlePaste: "Infográficos",
                arquivos: [
                    { nome: 'infografico_bem-estar_x_competencias_socioemocionais', arquivo: MTX3 },
                    { nome: 'infografico_bullying_x_competencias_socioemocionais', arquivo: MTX4 },
                    { nome: 'infografico_desempenho_escolar_x_competencias_socioemocionais', arquivo: MTX5 },
                    { nome: 'infografico_pertencimento_x_competencias_socioemocionais', arquivo: MTX6 },
                    { nome: 'infografico_saude_mental_x_competencias_socioemocionais', arquivo: MTX7 },
                    { nome: 'infografico_violencia_escolar_x_competencias_socioemocionais', arquivo: MTX8 },
                ],
            },
        ],
    },
];
