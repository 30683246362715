import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

export const NULL_CODE_PARENT = -2;

class AdminHeaderItem extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonName('codigo_header_pai')
    @jsonProperty(Number, null)
    codigoHeaderPai: number = NULL_CODE_PARENT;

    @jsonProperty(String)
    nome: string = '';

    @jsonProperty(String)
    url: string = '';

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(Boolean)
    logado: boolean = false;

    @jsonName('jornada_concluida')
    @jsonProperty(Boolean, null)
    jornadaConcluida: boolean = false;

    @jsonProperty(String, null)
    eventoGTM: string = '';

    @jsonProperty(Boolean, null)
    isNew: boolean = false;

    @jsonProperty([Object], [])
    subheaders: AdminHeaderItem[] = [];
}

export default AdminHeaderItem;
