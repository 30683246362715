import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class BiblioNotificationConfig extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('descricao')
    @jsonProperty(String)
    label: string = '';

    @jsonName('descricao')
    @jsonProperty(String)
    code: number = 0;
}

export default BiblioNotificationConfig;
