import React, { ReactNode, useEffect, useState } from 'react';
import Styled from './AcessoExlusivo.style';
import { Drawer, Tabs, Input, Button, Alert, Table, Switch, Divider, Popconfirm, DatePicker, Select, Collapse } from 'antd';
import { EditOutlined, DeleteOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import CardCategoria from './components/Categoria/CardCategoria';
import { IoAdd, IoReload } from 'react-icons/io5';
import Colors from 'enums/Colors';
import { CategoriaPEService } from 'core/http/service/acessosExclusivos/CategoriaPE.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import useFetch from 'hooks/useFetch';
import CardCategoriaModel from 'models/acessoExclusivo/CardCategoria';
import RegraPE from 'models/acessoExclusivo/RegraPE';
import { RegrasPEService } from 'core/http/service/acessosExclusivos/RegrasPE.service';
import TokenPE from 'models/acessoExclusivo/TokenPE';
import { TokenPEService } from 'core/http/service/acessosExclusivos/TokenPE.service';
import { TfiReload } from 'react-icons/tfi';
import TokenPE_DTO from 'models/acessoExclusivo/dtos/TokenPE_DTO';
import { RoleList } from 'enums/RoleEnum';
import EstadoDTO from 'models/perfil/EstadoDTO';
import { ProfileService } from 'core/http/service/Profile.service';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';
import type { PopconfirmProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import CategoriaUsersDTO from 'models/acessoExclusivo/dtos/CategoriaUsersDTO';
import { CategoriaUsersPE } from 'core/http/service/acessosExclusivos/CategoriaUsersPE.service';
import moment from 'moment';
import { SearchProps } from 'antd/es/input';
import CategoriaUsersDTO2 from 'models/acessoExclusivo/dtos/CategoriaUsersDTO2';

type TAbas = {
    name: string;
    icon: any;
};

interface IDrawerForm {
    open: boolean;
    handleClose: () => void;

    title: string;
    children?: JSX.Element;
    size?: 'large' | 'default';
}

export const DrawerForm = ({ children, handleClose, open, title, size = 'default' }: IDrawerForm) => {
    return (
        <Drawer size={size} style={{}} title={title} placement={'right'} closable={false} onClose={handleClose} open={open}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
            >
                {children}
            </div>
        </Drawer>
    );
};

interface ICategorias {
    realodAll: number;
    listCategorias: (value: CardCategoriaModel[]) => void;
}

const Categorias = ({ realodAll, listCategorias }: ICategorias) => {
    const [loading, setLoading] = useState<boolean>(false);
    // const actions: React.ReactNode[] = [<EditOutlined key="edit" />, <SettingOutlined key="setting" />, <EllipsisOutlined key="ellipsis" />];

    const [open, setOpen] = useState(false);
    const [nomeCategoria, setNomeCategoria] = useState<string>('');
    const [reload, setReload] = useState<number>(0);

    const [cardsCategoria, setCardCategorias] = useState<CardCategoriaModel[]>([]);

    const serviceCategoria = new CategoriaPEService();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleSubmitCategoria = async () => {
        if (nomeCategoria.length === 0) {
            return ToastError('Campo vazio!');
        }

        setLoading(true);

        const response = await serviceCategoria.inserir(nomeCategoria);

        if (response.status === 201) {
            setReload(reload + 1);
            ToastSuccess('Categoria criada com sucesso!');
            setNomeCategoria('');
            onClose();
        }
        setLoading(false);
    };

    useFetch(async () => {
        setLoading(true);
        const listCardsCategorias = await serviceCategoria.listAll();

        let data: CardCategoriaModel[] = listCardsCategorias.data;

        setCardCategorias(data);
        listCategorias(data);
        setLoading(false);
    }, [reload, realodAll]);

    return (
        <>
            <DrawerForm handleClose={onClose} open={open} title="Nova categoria">
                <>
                    <Alert
                        message="Informação"
                        description={
                            'A categoria é um grupo de regras de negócio que armazena os usuários vinculados e define quais soluções terão acesso.'
                        }
                        type="warning"
                    />
                    <Input placeholder={'Categoria'} value={nomeCategoria} onChange={e => setNomeCategoria(e.target.value)} />
                    <Button
                        loading={loading}
                        onClick={() => handleSubmitCategoria()}
                        style={{
                            backgroundColor: Colors.LightGreen,
                            color: Colors.White,
                        }}
                    >
                        {'Criar categoria'}
                    </Button>
                </>
            </DrawerForm>
            <Styled.Button onClick={showDrawer} style={{ width: 130, marginTop: 10, marginBottom: 10 }}>
                <IoAdd color={Colors.White} /> Categoria
            </Styled.Button>
            <Styled.Content>
                {cardsCategoria.map((dados, index) => (
                    <CardCategoria
                        key={index}
                        id={dados.id ?? -1}
                        nome={dados.nome}
                        dadosRegras={dados?.regraPaginaExclusivas ?? []}
                        dadosToken={dados?.tokenPaginaExclusivas ?? []}
                        dadosSolucao={dados?.tipoSolucoes ?? []}
                        actions={[]}
                        loading={loading}
                        reloadCategorias={() => setReload(reload + 1)}
                        reloadAll={realodAll}
                    />
                ))}
            </Styled.Content>
        </>
    );
};

interface IRegraPE {
    role: string;
    estado: string;
    municipio: string;
}

const Regras = () => {
    const [regras, setRegras] = useState<RegraPE[]>([]);
    const [reload, setReload] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

    const [cidades, setCidades] = useState<LocalidadeDTO[]>([]);
    const [estados, setEstados] = useState<EstadoDTO[]>([]);
    const [open, setOpen] = useState(false);
    const [roles] = useState(RoleList);

    const profileService = new ProfileService();

    const [regra, setRegra] = useState<IRegraPE>({
        role: '',
        estado: '',
        municipio: '',
    });

    useFetch(async () => {
        const response = await profileService.findAllEstados();

        setEstados(response.data);
    }, []);

    const serviceRegra = new RegrasPEService();

    useFetch(async () => {
        const responses = await serviceRegra.getAll();

        const data: RegraPE[] = responses.data;

        setRegras(data);
    }, [reload]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setRegra({
            role: '',
            estado: '',
            municipio: '',
        });
    };

    const handleSubmitRegra = async () => {
        setLoadingInfo(true);

        let newRegra = new RegraPE();
        newRegra.role = regra.role;
        newRegra.estado = regra.estado;
        newRegra.municipio = regra.municipio;

        if (!(newRegra.role.length > 0 && newRegra.estado.length > 0 && newRegra.municipio.length > 0)) {
            return ToastError('Para criar uma nova regra precisa preencher todos os campos!');
        }

        const response = await serviceRegra.inserir([newRegra]);

        if (response.status === 201) {
            ToastSuccess('Nova regra criada com sucesso!');
            setReload(reload + 1);
            onClose();
        } else {
            ToastError('Ocorreu algum erro ao salvar a nova regra!');
        }

        setLoadingInfo(false);
    };

    const loadCidadesPorUf = async (uf: string) => {
        try {
            setCidades([]);

            const response: any = await profileService.consultarCidadesPorUF(uf);
            let _cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));

            const cidadeDefault = new LocalidadeDTO();
            cidadeDefault.id = 0;
            cidadeDefault.nome = 'Todos Municípios';

            _cidades.unshift(cidadeDefault);

            setCidades(_cidades);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const handleDelete = async (codigoRegra: number) => {
        const response = await serviceRegra.deleteAll(codigoRegra);

        if (response.status === 200) {
            ToastSuccess('Regra deletada com sucesso!');
            setReload(reload + 1);
        }
    };

    const cancel: PopconfirmProps['onCancel'] = e => {
        console.log(e);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DrawerForm handleClose={onClose} open={open} title="Nova categoria">
                <>
                    <Collapse
                        items={[
                            {
                                key: '1',
                                label: 'Informações Importantes!',
                                children: (
                                    <Alert
                                        message=""
                                        description={
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: `
                       <strong>(Professor) </strong> <br/>
                        (Professor, Estágiario e Auxiliar / Monitor de Classe )<br/>
                        _______________________________<br/>
                       <strong>(Diretor) </strong> <br/>
                        (Diretor(a) e Vice-diretor(a))<br/>
                    _______________________________<br/>
                     <strong> (Técnico de Secretaria / Técnico da Educação) </strong> <br/>
                        (Secretário(a) de educação, Sub-secretário(a) de educação e Técnico(a) de secretaria)<br/>
                    _______________________________<br/>
                     <strong>(Coordenador não parceiro) </strong> <br/>
                        (Coordenador(a) pedagógico, Orientador(a) pedagógico)<br/>
                    `,
                                                }}
                                            />
                                        }
                                        type="warning"
                                    />
                                ),
                            },
                        ]}
                    />

                    <Styled.LabelForm>Role</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={regra.role}
                        as={'select'}
                        onChange={e => {
                            setRegra({ ...regra, role: e.target.value });
                        }}
                    >
                        <option>Selecione</option>
                        {roles.map((dados, index) => (
                            <option key={index} value={dados.value}>
                                {dados.label}
                            </option>
                        ))}
                    </Styled.InputFormSelect>

                    <Styled.LabelForm>Estado</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={regra.estado}
                        as={'select'}
                        onChange={e => {
                            setRegra({ ...regra, estado: e.target.value });
                            loadCidadesPorUf(e.target.value);
                        }}
                    >
                        <option>Selecione</option>
                        {estados.map((dados, index) => (
                            <option key={index} value={dados.id}>
                                {dados.nome}
                            </option>
                        ))}
                    </Styled.InputFormSelect>
                    <Styled.LabelForm>Município</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={regra.municipio}
                        onChange={(e: any) => setRegra({ ...regra, municipio: e.target.value })}
                        as={'select'}
                        disabled={cidades.length === 0}
                    >
                        <option>Selecione</option>
                        {cidades.map((dados, index) => (
                            <option key={index} value={dados.nome}>
                                {dados.nome}
                            </option>
                        ))}
                    </Styled.InputFormSelect>
                    <Button
                        loading={loadingInfo}
                        onClick={() => handleSubmitRegra()}
                        style={{
                            backgroundColor: Colors.LightGreen,
                            color: Colors.White,
                            height: 40,
                            marginTop: '1rem',
                        }}
                    >
                        {'Salvar Regra'}
                    </Button>
                </>
            </DrawerForm>
            <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                <Styled.Button onClick={showDrawer} style={{ width: 130, marginTop: 10, marginBottom: 10 }}>
                    <IoAdd color={Colors.White} /> Nova Regra
                </Styled.Button>
                <Styled.Button
                    onClick={() => {
                        setReload(reload + 1);
                        ToastSuccess('Informações carregadas com sucesso!');
                    }}
                    style={{ backgroundColor: Colors.PureOrange }}
                >
                    <TfiReload size={24} />
                </Styled.Button>
            </div>

            <Table
                columns={[
                    { title: 'Role', dataIndex: 'role' },
                    { title: 'Estado', dataIndex: 'estado' },
                    { title: 'Município', dataIndex: 'municipio' },
                    { title: 'Ações', dataIndex: 'acoes' },
                ]}
                dataSource={
                    regras.length > 0
                        ? regras.map((d, index) => ({
                              ...d,
                              role: roles.find(rl => rl.value === d.role)?.label || d.role,
                              acoes: (
                                  <>
                                      <Popconfirm
                                          title="Deletar regra"
                                          description={
                                              <p>
                                                  Você realemente deseja deletar está regra? <br />
                                                  Se sim, será removida a regra de todas as categorias relacionada com a mesma.
                                              </p>
                                          }
                                          onConfirm={() => handleDelete(d.id ?? -1)}
                                          onCancel={cancel}
                                          okText="Sim, deletar"
                                          cancelText="Cancelar"
                                      >
                                          <DeleteOutlined key="edit" style={{ cursor: 'pointer' }} />
                                      </Popconfirm>
                                  </>
                              ),
                              key: index,
                          }))
                        : []
                }
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

interface IToken {
    setListTokens: (value: TokenPE[]) => void;
}

const Tokens = ({ setListTokens }: IToken) => {
    const [tokens, setTokens] = useState<TokenPE[]>([]);
    const [token, setToken] = useState<string>('');
    const [reload, setReload] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const serviceToken = new TokenPEService();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useFetch(async () => {
        const responses = await serviceToken.getAllTokens();
        const data: TokenPE[] = responses.data;

        setTokens(data);
        setListTokens(data);
        setLoading(false);
    }, [reload]);

    const handleUpdate = async (id: number, token: TokenPE_DTO) => {
        const response = await serviceToken.updateToken(id ?? -1, token);

        if (response.status === 200) {
            ToastSuccess('Token atualizado com sucesso!');
            setReload(reload + 1);
            setLoading(true);
        }
    };

    function gerarToken(): string {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let token = '';

        for (let i = 0; i < 25; i++) {
            // Adiciona um caractere aleatório
            token += caracteres.charAt(Math.floor(Math.random() * caracteres.length));

            // A cada 5 caracteres, adiciona um '-'
            if ((i + 1) % 5 === 0 && i !== 24) {
                token += '-';
            }
        }

        return token;
    }

    const handleSubmitToken = async (tokenGenerator?: string) => {
        setLoadingButton(true);
        let tokenData = new TokenPE_DTO();

        if (tokenGenerator) {
            tokenData.token = tokenGenerator;
        } else {
            tokenData.token = token;
        }

        if (tokenData.token.length === 0) return ToastError('Campo vazio!');

        tokenData.status = true;

        const response = await serviceToken.inserir(tokenData);

        if (response.status === 201) {
            onClose();
            setReload(reload + 1);
            ToastSuccess('Novo token gerado com sucesso!');

            if (!tokenGenerator) {
                setToken('');
            }
        }
        setLoadingButton(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DrawerForm key={'token'} handleClose={onClose} open={open} title="Novo Token">
                <>
                    <Alert
                        message="Informação"
                        description={'Ao criar um token o mesmo estará disponível para os vínculos nas categorias existentes.'}
                        type="warning"
                    />
                    <Input placeholder={'Token'} value={token} onChange={e => setToken(e.target.value)} />
                    <Button
                        loading={loadingButton}
                        onClick={() => handleSubmitToken()}
                        style={{
                            backgroundColor: Colors.LightGreen,
                            color: Colors.White,
                            height: 40,
                        }}
                    >
                        {'Criar token'}
                    </Button>
                    <Divider />
                    <Button
                        loading={loadingButton}
                        onClick={() => handleSubmitToken(gerarToken())}
                        style={{
                            backgroundColor: Colors.PureOrange,
                            color: Colors.White,
                            height: 40,
                        }}
                    >
                        Gerar um token random
                    </Button>
                </>
            </DrawerForm>
            <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                <Styled.Button onClick={showDrawer} style={{ width: 130, marginTop: 10, marginBottom: 10 }}>
                    <IoAdd color={Colors.White} /> Novo Token
                </Styled.Button>
                <Styled.Button
                    onClick={() => {
                        setReload(reload + 1);
                        ToastSuccess('Informações carregadas com sucesso!');
                    }}
                    style={{ backgroundColor: Colors.PureOrange }}
                >
                    <TfiReload size={24} />
                </Styled.Button>
            </div>

            <Table
                loading={loading}
                columns={[
                    { title: 'Token', dataIndex: 'token' },
                    { title: 'Status', dataIndex: 'status' },
                    { title: 'Ações', dataIndex: 'acoes' },
                ]}
                dataSource={tokens.map((d, index) => ({
                    ...d,
                    status: d.status ? (
                        <span
                            style={{
                                backgroundColor: Colors.LightGreen,
                                color: Colors.White,
                                padding: 5,
                                borderRadius: 10,
                            }}
                        >
                            Ativo
                        </span>
                    ) : (
                        <span
                            style={{
                                backgroundColor: Colors.InfoRed,
                                color: Colors.White,
                                padding: 5,
                                borderRadius: 10,
                            }}
                        >
                            Inativo
                        </span>
                    ),
                    acoes: (
                        <Switch
                            checked={d.status}
                            loading={loading}
                            onClick={() => handleUpdate(d.id ?? -1, TokenPE_DTO.fromJSON({ token: d.token, status: !d.status }))}
                        />
                    ),
                    key: index,
                }))}
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

const { RangePicker } = DatePicker;

type TSelected = {
    value: string;
    label: string;
};

interface IIFilter {
    filterCategoria: TSelected;
    filterToken: TSelected;
}

interface IUsers {
    listCategorias: CardCategoriaModel[];
    listTokens: TokenPE[];
}

const Users = ({ listCategorias, listTokens }: IUsers) => {
    const [users, setUsers] = useState<CategoriaUsersDTO[]>([]);
    const [reload, setReload] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<CategoriaUsersDTO[]>([]);

    const [searchCategoria, setSearchCategoria] = useState('Categoria');
    const [searchToken, setSearchToken] = useState('Token');

    const [filter, setFilter] = useState<IIFilter>({
        filterCategoria: { value: '', label: '' },
        filterToken: { value: '', label: '' },
    });

    const serviceCategoriaUsers = new CategoriaUsersPE();

    const mapUserRolesToLabels = (users: CategoriaUsersDTO2[]): CategoriaUsersDTO2[] => {
        return users.map(user => {
            // Encontrar o item correspondente na RoleList
            const roleItem = RoleList.find(role => role.value === user.role);

            // Criar uma nova instância de CategoriaUsersDTO2
            const newUser = new CategoriaUsersDTO2();
            newUser.email = user.email;
            newUser.estado = user.estado;
            newUser.cidade = user.cidade;
            newUser.role = roleItem ? roleItem.label : user.role; // Substitui o role pelo label correspondente
            newUser.categoria = user.categoria;
            newUser.token = user.token;
            newUser.ultimaAtualizacao = user.ultimaAtualizacao;

            return newUser;
        });
    };

    useFetch(async () => {
        const responses = await serviceCategoriaUsers.listAll();
        const data: CategoriaUsersDTO[] = responses.data;

        // Mapeando os roles para labels
        const usersWithLabels = mapUserRolesToLabels(data);

        setUsers(usersWithLabels);
        setLoading(false);
    }, [reload]);

    const onSearchCategoria: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterCategoria: { value: value, label: value } });
        setSearchCategoria(value);
    };

    const onSearchToken: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterToken: { value: value, label: value } });
        setSearchToken(value);
    };

    const handleRangePickerChange = (dates: any, dateStrings: any) => {
        if (!dates) {
            setDateRange([]);
        } else {
            setDateRange(dateStrings);
        }
    };

    const handleDownloadXLS = () => {
        const selectedUsers = filteredUsers;

        if (selectedUsers.length === 0) return ToastError('Lista vazia para gerar o XLS');

        // Mapeia os usuários para um formato adequado para o Excel
        const xlsData = selectedUsers.map(user => [
            user.email,
            user.role,
            user.estado,
            user.cidade,
            user.categoria,
            user.token,
            moment(user.ultimaAtualizacao).format('DD/MM/YYYY HH:mm:ss'),
        ]);

        // Cria o conteúdo do arquivo XLS
        const xlsContent = [['Email', 'Role', 'Estado', 'Municipio', 'Categoria', 'Token', 'Data'], ...xlsData]
            .map(row => row.join('\t')) // Usa tabulação como delimitador para XLS
            .join('\n');

        // Cria um Blob a partir do conteúdo
        const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `relatorio-${new Date().getMilliseconds()}-${new Date().getSeconds()}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const applyFilters = (users: CategoriaUsersDTO[], filter: IIFilter) => {
        if (!filter) {
            return users;
        }

        return users.filter(user => {
            const matchCategoria = user.categoria.toLowerCase().includes(filter.filterCategoria.value.toLowerCase());
            const matchToken = user.token.toLowerCase().includes(filter.filterToken.value.toLowerCase());

            let matchDate = true;

            if (dateRange.length > 0) {
                const formattedStartDate = moment(dateRange[0], 'DD-MM-YYYY').startOf('day');
                const formattedEndDate = moment(dateRange[1], 'DD-MM-YYYY').endOf('day');
                const userDate = moment(user.ultimaAtualizacao);

                matchDate = userDate.isSameOrAfter(formattedStartDate) && userDate.isSameOrBefore(formattedEndDate);
            }

            return matchCategoria && matchToken && matchDate;
        });
    };

    const resetFilter = () => {
        setFilter({ filterCategoria: { value: '', label: '' }, filterToken: { value: '', label: '' } });
        setDateRange([]);
        setFilteredUsers(users);
        setSearchCategoria('Categoria');
        setSearchToken('Token');
    };

    useEffect(() => {
        setFilteredUsers(applyFilters(users, filter));
    }, [filter, users, dateRange]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                <Styled.Button onClick={handleDownloadXLS} style={{ width: 130, marginTop: 10, marginBottom: 10 }}>
                    Gerar XLS
                </Styled.Button>
                <Styled.Button
                    onClick={() => {
                        setReload(reload + 1);
                        ToastSuccess('Informações carregadas com sucesso!');
                    }}
                    style={{ backgroundColor: Colors.PureOrange }}
                >
                    <TfiReload size={24} />
                </Styled.Button>
            </div>

            <div
                style={{
                    display: 'flex',
                    marginBottom: 10,
                    gap: '2rem',
                }}
            >
                <RangePicker placeholder={['Início', 'Fim']} format={'DD-MM-YYYY'} onChange={handleRangePickerChange} />
                <Select
                    style={{ width: 120 }}
                    placeholder="Categorias"
                    onChange={value => onSearchCategoria(value)}
                    value={searchCategoria}
                    options={listCategorias.map(dados => ({
                        label: dados.nome,
                        value: dados.nome,
                    }))}
                />
                <Select
                    style={{ width: 120 }}
                    placeholder="Tokens"
                    onChange={value => onSearchToken(value)}
                    value={searchToken}
                    defaultActiveFirstOption
                    options={listTokens.map(dados => ({
                        label: dados.token,
                        value: dados.token,
                    }))}
                />
                <Styled.ButtonFilterReset onClick={resetFilter}>
                    <IoReload />
                    <p>Limpar Filtros</p>
                </Styled.ButtonFilterReset>
            </div>
            <Table
                loading={loading}
                columns={[
                    { title: 'E-mail', dataIndex: 'email' },
                    { title: 'Role', dataIndex: 'role' },
                    { title: 'Estado', dataIndex: 'estado' },
                    { title: 'Município', dataIndex: 'cidade' },
                    { title: 'Categoria', dataIndex: 'categoria' },
                    { title: 'Token', dataIndex: 'token' },
                    { title: 'Data', dataIndex: 'data' },
                ]}
                dataSource={filteredUsers.map((d, index) => ({
                    ...d,
                    data: moment(d.ultimaAtualizacao).format('DD/MM/YYYY HH:mm:ss'),
                    key: index,
                }))}
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

const Abas: TAbas[] = [
    {
        name: 'Categorias',
        icon: null,
    },
    {
        name: 'Regras',
        icon: null,
    },
    {
        name: 'Tokens',
        icon: null,
    },
    {
        name: 'Usuários',
        icon: null,
    },
];

const AcessoExclusivo = () => {
    const [abaAcitveAll, setAbaActiveAll] = useState<number>(1);
    const [reloadAll, setReloadAll] = useState<number>(1);
    const [listCategorias, setListCategorias] = useState<CardCategoriaModel[]>([]);
    const [listTokens, setListTokens] = useState<TokenPE[]>([]);

    useFetch(() => {
        if (abaAcitveAll) setReloadAll(reloadAll + 1);
    }, [abaAcitveAll]);

    return (
        <Styled.Container>
            <Styled.H1>{`Gestão dos Acessos Exclusivos`.toLocaleUpperCase()}</Styled.H1>
            <Tabs
                defaultActiveKey="1"
                activeKey={abaAcitveAll.toString()}
                onChange={e => setAbaActiveAll(parseInt(e))}
                items={Abas.map((dados, i) => {
                    const id = String(i + 1);
                    return {
                        key: id,
                        label: dados.name,
                        children:
                            parseInt(id) === 1
                                ? Categorias({
                                      realodAll: reloadAll,
                                      listCategorias(value) {
                                          setListCategorias(value);
                                      },
                                  })
                                : parseInt(id) === 2
                                ? Regras()
                                : parseInt(id) === 3
                                ? Tokens({
                                      setListTokens(value) {
                                          setListTokens(value);
                                      },
                                  })
                                : Users({ listCategorias: listCategorias, listTokens: listTokens }),
                        icon: dados.icon,
                    };
                })}
            />
        </Styled.Container>
    );
};

export default AcessoExclusivo;
