import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import Styled from './ModalCheckGoToPage.styled';
import { PopupQuestionStyles } from 'components/popupQuestion/PopupQuestion.styled';
import IconDanger from '../../images/danger-icon.svg';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { GobackButton } from 'components/UI/Buttons';

interface IModalCheckGoToPage {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    goToPage: () => void;
    isRealizandoEscolhas?: boolean;
}

const ModalCheckGoToPage = ({ showModal, setShowModal, goToPage, isRealizandoEscolhas }: IModalCheckGoToPage) => {
    return (
        <Styled.Container show={showModal} onHide={() => setShowModal(false)} dialogAs={PopupQuestionStyles.Container}>
            <Styled.Header>
                <img src={IconDanger} />
            </Styled.Header>
            <Modal.Body
                style={{
                    display: 'flex',
                    fontSize: IsMobileMaxWidth() ? '20px' : '24px',
                    fontWeight: 'bold',
                    lineHeight: '32px',
                    fontStyle: 'normal',
                    textAlign: 'center',
                    fontFamily: 'Barlow',
                    alignItems: 'center',
                    color: Colors.DarkGrey,
                    textTransform: 'uppercase',
                }}
            >
                {isRealizandoEscolhas ? (
                    <>{' Você está saindo da página de avaliação do realizando escolhas. Deseja sair?'}</>
                ) : (
                    <>{' Você está saindo da página de resposta do instrumento. Deseja sair'}</>
                )}
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <GobackButton style={{ width: '100%' }} onClick={() => goToPage()}>
                    Sair da página
                </GobackButton>
                <GobackButton style={{ width: '100%' }} onClick={() => setShowModal(false)}>
                    Cancelar
                </GobackButton>
            </Modal.Footer>
        </Styled.Container>
    );
};

export default ModalCheckGoToPage;
