import { jsonProperty, Serializable } from 'ts-serializable';

class RegraPE extends Serializable {
    @jsonProperty(Number, null)
    id: number | null = -1;

    @jsonProperty(String)
    role: string = '';

    @jsonProperty(String)
    estado: string = '';

    @jsonProperty(String)
    municipio: string = '';
}


export default RegraPE;