import React, { useState, useEffect, useCallback } from 'react';

import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import WebForm from './formRows/WebForm';
import MobileForm from './formRows/MobileForm';
import OnError from '../../../../../core/onError/OnError';
import { FormContainer } from './IdentificacaoForm.styled';
import ProfileData from '../../../../../models/ProfileData';
import * as profileActions from '../../../../../store/actions/Profile';
import { IsMobileMaxWidth } from '../../../../../core/util/WindowSize';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import LocalidadeDTO from '../../../../../models/perfil/LocalidadeDTO';
import UsuarioDTO from 'models/UsuarioDTO';
import { isUsuarioLms } from 'core/util/KeycloakHelp';
import { cpf } from 'cpf-cnpj-validator';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import { ProfileService } from 'core/http/service/Profile.service';

export const CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL = 1;
export const CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL = 2;
export const CODIGO_TIPO_FORMACAO_ENSINO_MEDIO = 3;
export const CODIGO_TIPO_FORMACAO_GRADUACAO = 4;
export const CODIGO_TIPO_FORMACAO_ESPECIALIZACAO = 5;
export const CODIGO_TIPO_FORMACAO_MESTRADO = 6;
export const CODIGO_TIPO_FORMACAO_DOUTORADO = 7;

const IsMobile = () => {
    return IsMobileMaxWidth();
};

interface IIdentificacaoForm {
    ReloadContentHandler: any;
    profileParameterData: any;
    onContinuarHandler: any;
    profileData: ProfileData | null;
}
const IdentificacoForm = ({ ReloadContentHandler, profileParameterData, onContinuarHandler, profileData }: IIdentificacaoForm) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [hasProfileInfo, setHasProfileInfo] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    interface IValues {
        apelido: string;
        tipoFormacao: number | null;
        areaFormacao: number | null;
        outraAreaFormacao: string;
        estado: string;
        municipio: number | null;
        cpf: string;
        isUsuarioLms: boolean;
    }

    let formikInitial: IValues = {
        apelido: '',
        tipoFormacao: null,
        outraAreaFormacao: '',
        areaFormacao: 0,
        estado: '',
        municipio: null,
        cpf: '',
        isUsuarioLms: isUsuarioLms(usuarioLogado),
    };

    useEffect(() => {
        if (profileData && profileData.identificacaoCompleta) {
            formikInitial.apelido = profileData.apelido;
            formikInitial.tipoFormacao = profileData.tipoFormacao;
            formikInitial.areaFormacao = profileData.areaFormacao;
            formikInitial.estado = profileData.estado;
            formikInitial.municipio = profileData.municipio;
            formikInitial.outraAreaFormacao = profileData.outraAreaFormacao;
            formikInitial.cpf = profileData.cpf;
            setHasProfileInfo(true);
        }
    }, []);

    const closePopup = () => {
        setShowErrorModal(false);
    };

    const onSubmitHandler = async (values: IValues, actions: any) => {
        setSubmitting(true);

        const { apelido, tipoFormacao, areaFormacao, estado, municipio, outraAreaFormacao, cpf } = values;

        if (!profileData) {
            profileData = new ProfileData().fromJSON(values);
        } else {
            profileData.municipio = municipio;
            profileData.apelido = apelido;
            profileData.tipoFormacao = tipoFormacao;
            profileData.areaFormacao = areaFormacao;
            profileData.estado = estado;
            profileData.outraAreaFormacao = outraAreaFormacao;
            profileData.cpf = cpf;
        }

        profileData.identificacaoCompleta = true;

        const cidade = cidadesPorUf.find((obj: any) => obj.id === municipio);
        const estadoDescricao = profileParameterData.listaEstados.find((obj: any) => obj.id === estado);
        profileData.municipioDescricao = cidade?.nome ?? '';
        profileData.estadoDescricao = estadoDescricao.nome;

        try {
            const profileService = new ProfileService();
            const response: any = await profileService.existePorCpf(profileData.cpf);
            const cpfJaCadastrado: boolean = response.data;
            if (cpfJaCadastrado === false) {
                await dispatch(profileActions.storeProfileData(profileData));
                onContinuarHandler();
            } else {
                setShowErrorModal(true);
            }
        } catch (err) {
            console.log('err -> ', err);
        } finally {
            setSubmitting(false);
        }
    };

    const yupValidation = yup.object().shape({
        apelido: yup.string().min(3).max(20).required(),
        tipoFormacao: yup.number().required(),
        areaFormacao: yup.number().when('tipoFormacao', {
            is: (tipoFormacao: any) => {
                return (
                    tipoFormacao !== CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL &&
                    tipoFormacao !== CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL &&
                    tipoFormacao !== CODIGO_TIPO_FORMACAO_ENSINO_MEDIO
                );
            },

            then: yup.number().required().min(1),
        }),
        outraAreaFormacao: yup.string().min(3),
        estado: yup.number().required(),
        municipio: yup.number().required(),
        isUsuarioLms: yup.boolean().required(),
        cpf: yup
            .string()
            .when('isUsuarioLms', {
                is: true,
                then: yup.string().min(14).max(14).required(),
            })
            .test('cpfValidation', 'Informe um cpf válido', (valor: any) => {
                return (isUsuarioLms(usuarioLogado) && cpf.isValid(valor)) || !isUsuarioLms(usuarioLogado);
            }),
    });

    const cidadesPorUf: LocalidadeDTO[] = useAppSelector(state => state.profileReducer.cidadesPorUf);

    const loadCidadesPorUf = useCallback(async (uf: string) => {
        try {
            setIsLoading(true);
            await dispatch(profileActions.fetchCidadesPorEstado(uf));
        } catch (error) {
            OnError.sendToAnalytics('IdentificacaoForm.tsx - line 71 - loadCidadesPorUf', String(error));
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <FormContainer id="formContainer">
            <Formik initialValues={formikInitial} onSubmit={onSubmitHandler} validationSchema={yupValidation}>
                {formik => {
                    useEffect(() => {
                        console.log('dirty', formik.dirty);
                        console.log('valid', formik.isValid);
                        console.log('values', formik.values);
                    }, [formik.values]);
                    return (
                        <>
                            {IsMobile() ? (
                                <MobileForm
                                    formik={formik}
                                    ReloadContentHandler={ReloadContentHandler}
                                    hasProfileInfo={hasProfileInfo}
                                    profileParameterData={profileParameterData}
                                    loadCidadesPorUf={loadCidadesPorUf}
                                    isLoading={isLoading}
                                    cidadesPorUf={cidadesPorUf}
                                    submitting={submitting}
                                />
                            ) : (
                                <WebForm
                                    formik={formik}
                                    ReloadContentHandler={ReloadContentHandler}
                                    hasProfileInfo={hasProfileInfo}
                                    profileParameterData={profileParameterData}
                                    loadCidadesPorUf={loadCidadesPorUf}
                                    isLoading={isLoading}
                                    cidadesPorUf={cidadesPorUf}
                                    submitting={submitting}
                                />
                            )}

                            <PopupQuestion
                                onButton={closePopup}
                                okButtonText={'Ok'}
                                cancelButtonText={'Ok'}
                                infoOnly={true}
                                question={'O CPF informado já possui uma conta cadastrada em nossa base de dados.'}
                                visible={showErrorModal}
                            />
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
};

export default IdentificacoForm;
