import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';

// Imgs
import Img_01 from '../../assets/AlfaTeoriaPratica/modulo04/img_01.jpg';
import Img_02 from '../../assets/AlfaTeoriaPratica/modulo04/quadro-estrategias-de-desenvolvimento-integral-no-processo-de-alfabetização-em-sala-de-aula.png';

const ATP_Modulo04 = ({ setImgAmpliar }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="04"
                titulo={`
           Alfabetização integral
            `}
                description={`
                 Angela Chuvas Naschold e <br />
                 Ana Carolina Zuanazzi
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 52,
                }}
            >
                <Styled.Img src={Img_01} alt="" onClick={() => setImgAmpliar(Img_01)} />
                <Styled.Description>
                    A alfabetização desempenha um papel crucial/crítico na medida em que potencializa no indivíduo maior autonomia e capacidade
                    de expressão em diversas áreas, como estudo, criação, mobilidade e transformação social. Essa ideia está intimamente
                    relacionada à proposta de educação integral, cuja finalidade é o desenvolvimento pleno do estudante. Nessa abordagem,
                    considera-se o estudante como uma pessoa complexa e diversificada, com experiências variadas, dotada de vivências, vontades,
                    conhecimento, emoção, motivação, criatividade e inserida em contextos micro e macrossociais. Podemos assumir, portanto, que a
                    alfabetização é um dos alicerces para o desenvolvimento pleno por meio da educação integral.
                </Styled.Description>
                <Styled.ContentBorderYellow>
                    A proposta de educação integral nasce de uma ampla discussão promovida por especialistas da educação e se consolida em
                    documentos nacionais como a Constituição Federal, que, em seu artigo 205, determina que um dos objetivos da educação é o
                    desenvolvimento pleno da pessoa. Além dela, a Lei de Diretrizes e Bases da Educação Nacional, o Plano Nacional de Educação,
                    e, mais recentemente, o Programa Escola em Tempo Integral defendem a extensão progressiva do tempo de permanência na escola
                    como uma das estratégias para ampliar a qualidade da educação (educação em tempo integral). Outro documento de destaque é a
                    Base Nacional Comum Curricular (BNCC), que trata da educação integral de forma ainda mais explícita.
                </Styled.ContentBorderYellow>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 53,
                }}
            >
                <Styled.Description>
                    Os principais documentos oficiais que norteiam e propõem diretrizes e metas para alavancar as taxas de alfabetização de
                    crianças são a Base Nacional Comum Curricular e o Compromisso Nacional Criança Alfabetizada, que estabelecem como meta a
                    alfabetização das crianças até o 2º ano do ensino fundamental; esses documentos têm como elemento norteador a ideia da
                    alfabetização como um processo complexo e multidimensional, que não se restringe à apropriação do sistema de escrita
                    alfabética ou à descoberta fonológica da escrita, mas que se expande para as práticas sociais da linguagem dentro e fora da
                    escola e por meio de diferentes recursos de linguagem.
                </Styled.Description>
                <Styled.ContentBorderYellow>
                    Entre outros documentos educacionais nacionais, o Compromisso Nacional Criança Alfabetizada47 destaca a violação do direito à
                    alfabetização como um agravante da vulnerabilidade socioeconômica e propulsor de desigualdades regionais, de raça/cor e de
                    gênero.
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    É na fase da alfabetização que ocorre o desenvolvimento de competências essenciais e basilares para uma trajetória escolar e
                    de vida satisfatória, contribuindo para o desenvolvimento integral. Por isso, discute-se a alfabetização integral como uma
                    estratégia de promoção da aprendizagem integral no ciclo da alfabetização, considerando toda a complexidade e as multifacetas
                    do estudante. Em concordância com os documentos oficiais citados, para a consolidação da alfabetização integral, o trabalho
                    pedagógico precisa ir além do domínio do sistema alfabético de escrita e matemática. Na concepção da alfabetização integral,
                    o domínio da oralidade, da leitura, da escrita e dos conceitos básicos da matemática requer a incorporação de linguagens de
                    outras áreas do conhecimento, como a linguagem corporal, a científica, a artística e a digital, atrelada à valorização dos
                    aspectos biológicos, ambientais, cognitivos e socioemocionais do estudante.
                    <br />
                    <br />A inclusão dessas outras linguagens originárias de diferentes áreas do conhecimento amplia as formas de interação do
                    estudante com o mundo na medida em que expande os tipos de estruturas e possibilidades de comunicação e expressão. Por
                    exemplo, a linguagem corporal pode favorecer, de maneiras propositadas, a expressão de emoções e intenções e as relações
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 54,
                }}
            >
                <Styled.Description>
                    sociais. A linguagem científica, por meio da observação, experimentação e consolidação de informações, favorece o
                    desenvolvimento de raciocínios ligados à investigação e à explicação dos fenômenos. A linguagem artística transcende as
                    barreiras da linguagem verbal e favorece a expressão por meio da música, da pintura, da dança e de outras formas de expressão
                    criativa. A linguagem digital, por sua vez, abre conexões de interação com a informação e trabalha a criticidade em relação a
                    esta. O trabalho com múltiplas linguagens integra o que acontece na sala de aula com o que acontece no mundo e, assim, supera
                    a compartimentalização do currículo.
                </Styled.Description>
             
                <Styled.ContentYellow style={{ borderRadius: '0px 30px 0px 30px' }}>
                    Confira o relato de diferentes especialistas sobre a importância das múltiplas linguagens no processo de aprendizagem no
                    vídeo:{' '}
                    <a href="#" onClick={() => window.open('https://www.youtube.com/watch?v=c2NhSZkdi-8')}>
                        https://www.youtube.com/watch?v=c2NhSZkdi-8
                    </a>{' '}
                    (acesso em: 10 maio 2024).
                </Styled.ContentYellow>
                <Styled.Description>
                    A alfabetização integral também se preocupa com as necessidades do indivíduo e sua relação com o meio. Dessa forma, questões
                    biológicas, ambientais, cognitivas e socioemocionais precisam ser consideradas ao se planejar estratégias de alfabetização,
                    sua avaliação, monitoramento e gestão. Por exemplo, do ponto de vista das questões biológicas, é consenso na literatura
                    científica que, quando adequados a cada faixa etária, a alimentação, o sono e as atividades físicas atuam como propulsores do
                    aprendizado. Na sala de aula e na escola como um todo, é preciso estar atento a condições de insegurança alimentar bem como à
                    qualidade do repouso e da estimulação física dos estudantes. Outro conhecido promotor da aprendizagem e, por extensão, da
                    alfabetização são as condições ambientais. Por ambiente, se entende tanto a estrutura física da escola e os recursos
                    disponíveis na comunidade quanto o ambiente de socialização, ou seja, a qualidade das interações que são estabelecidas entre
                    educadores e estudante e entre estudante e seus pares. Esses elementos ambientais criam um senso de pertencimento escolar
                    que, por sua vez, favorece o engajamento e comprometimento do estudante com as atividades ali desenvolvidas.
                    <br />
                    <br />
                    Quando a criança está aprendendo, importantes processos cognitivos básicos são ativados, como a atenção, a memória, as
                    funções executivas e a
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 55,
                }}
            >
                <Styled.Description>
                    visuoconstrução (capacidade de organizar e manipular manualmente informações espaciais). Especialmente na faixa etária da
                    alfabetização, muitos desses processos ainda estão em desenvolvimento. Por isso, a criança, em comparação a um adulto, tende
                    a manter sua atenção em algo por um período muito mais curto; ela também precisa de instruções mais concretas e diretas para
                    conseguir completar uma tarefa com mais autonomia e facilidade; e está em pleno desenvolvimento quanto à capacidade de
                    associar grafias e sons (processo grafofonológico, essencial para a leitura e escrita). Planejar atividades que levem em
                    consideração as características cognitivas da criança favorece o sucesso em seu percurso.
                </Styled.Description>
                <Styled.ContentBorderYellow>
                    Os processos cognitivos básicos podem ser compreendidos como os processos mentais pelos quais captamos, percebemos,
                    reconhecemos, organizamos, armazenamos e recuperamos as informações ao nosso redor.
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    Organizar os materiais escolares, atuar de forma protagonista e corresponsável em sala de aula, acreditar no próprio
                    potencial, enfrentar desafios de aprendizagem de maneira otimista e resiliente, desenvolver relacionamentos interpessoais e
                    redes de apoio, manter-se curioso e interessado em aprender são competências socioemocionais recorrentemente indicadas em
                    estudos científicos como requeridas no processo de aprendizado. Nos anos iniciais do ensino fundamental, a criança está
                    iniciando o desenvolvimento também dessas competências, de modo que um trabalho intencional que atue no campo socioemocional
                    tem dupla função: além de impulsionar o aprendizado, proporciona reflexões fundamentais sobre como a criança se percebe, suas
                    preferências e características.
                    <br />
                    <br />O quadro a seguir traz estratégias de desenvolvimento integral no processo de alfabetização em sala de aula.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 56,
                }}
            >
                <Styled.Img src={Img_02} onClick={() => setImgAmpliar(Img_02)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Alfabetização integral',
                }}
                footer={{
                    numberSummary: 57,
                }}
            >
                <Styled.Description>
                    Na perspectiva da alfabetização integral, as competências socioemocionais estão presentes como uma das múltiplas linguagens e
                    também por meio do olhar atento às necessidades emocionais dos estudantes. Muitas crianças podem se sentir frustradas,
                    ansiosas ou mesmo desmotivadas por terem dificuldades para ler e escrever; compreendê-las como seres plenos é compreender a
                    necessidade de desenvolvimento de sua resiliência emocional, da curiosidade pela descoberta, entre outras competências.
                </Styled.Description>
                <Styled.ContentYellow style={{ borderRadius: '0px 30px 0px 30px' }}>
                    Quer conhecer mais sobre o desenvolvimento socioemocional de crianças dos anos iniciais do ensino fundamental? Acesse o
                    e-book: <br />
                    <a
                        href="#"
                        onClick={() =>
                            window.open(
                                'https://institutoayrtonsenna.org.br/app/uploads/2023/05/competencias-socioemocionais-e-emocionais-da-crianca-dos-anos-iniciais-do-ensino-fundamental'
                            )
                        }
                    >
                        https://institutoayrtonsenna.org.br/app/uploads/2023/05/competencias-socioemocionais-e-emocionais-da-crianca-dos-anos-iniciais-do-ensino-fundamental
                    </a>
                    . pdf (acesso em: 16 maio 2024).
                </Styled.ContentYellow>
                <Styled.Description>
                    Em resumo, a perspectiva da alfabetização integral favorece o olhar atento à necessidade de integração das áreas de
                    conhecimento por meio do uso das múltiplas linguagens e por considerar as características desenvolvimentais e ambientais.
                    Entender o estudante como um indivíduo pleno, ou seja, não fragmentado, dá mais intencionalidade ao planejamento de
                    atividades que impulsionem a alfabetização.
                </Styled.Description>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo04;
