import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import React, { useState, useEffect } from 'react';

import CadastrarEmail from '../../criatividadeDetail/versions/components/CadastrarEmail';
import {
    YellowTitle,
    WhiteText,
    TransparentButton,
    Container,
    ButtonContainer,
} from '../../criatividadeDetail/versions/components/sections/Contato.styled';

export interface IContato {}

const Contato = ({}: IContato) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= 1200);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= 1200);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    const scrollWithOffset = (el: any) => {
        if (el) {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -102;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }
    };

    return (
        <Container>
            <WhiteText>{'Deixe seu contato e receba atualizações sobre a temática de Alfabetização.'}</WhiteText>
            <CadastrarEmail lancamento={PaginasIntermediariasEnum.ALFABETIZACAO_360} />
        </Container>
    );
};

export default Contato;
