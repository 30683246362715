import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleAlfaTeoriaPratica = {
    Img: styled.img`
        width: 100%;
        cursor: pointer;
    `,

    TitleYellow: styled.h1`
        color: ${Colors.Yellow};
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
    `,
    TitleSolftBlue: styled.h1`
        color: ${Colors.SoftBlue5};
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 30px;
    `,
    TitleBlue: styled.h1`
        color: ${Colors.Blue};
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 30px;
    `,

    Description: styled.p`
        font-size: 16px;
        line-height: 28px;
    `,

    Credits: styled.p`
        width: 100%;
        text-align: center;
        margin-bottom: 22px;
    `,

    ContentBlue: styled.div`
        background-color: ${Colors.SoftBlue5};
        line-height: 24px;
        padding: 10px;
        width: 100%;
    `,

    ContentWhite: styled.div`
        background-color: ${Colors.White};
        color: ${Colors.Blue};
        line-height: 24px;
        padding: 10px;
        width: 100%;

        li {
            list-style-type: circle;
            margin: 10px;
        }
    `,

    ContentBlue3: styled.div`
        background-color: ${Colors.SoftBlue3};
        line-height: 24px;
        padding: 10px;
        width: 100%;
        ul {
            padding-left: 55px;
        }

        li {
            list-style-type: circle;
            margin: 10px;
        }
    `,

    ContentBlue4: styled.div`
        background-color: #00a7ec;
        line-height: 24px;
        padding: 10px;
        width: 100%;
        ul {
            padding-left: 55px;
        }

        li {
            list-style-type: circle;
            margin: 10px;
        }
    `,
    ContentYellow: styled.div`
        background-color: #ffeea0;
        line-height: 24px;
        padding: 10px;
        width: 100%;
    `,

    ContentBorderBlue: styled.div`
        padding: 20px;
        border-radius: 0px 30px 0px 30px;
        line-height: 22px;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        border: 4px solid ${Colors.SoftBlue};
        margin-bottom: 22px;
    `,

    ContentBorderYellow: styled.div`
        padding: 20px;
        border-radius: 0px 30px 0px 30px;
        line-height: 22px;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        border: 4px solid ${Colors.Yellow};
        margin-bottom: 22px;
    `,

    TextItalic: styled.p`
        font-style: italic;
    `,

    Row: styled.div`
        display: flex;
        gap: 1rem;
        

        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
    `,

    ContentEnd: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: end;
        text-align: end;
    `,

    DividerYellow: styled.div`
        width: 35%;
        height: 15px;
        background-color: ${Colors.Yellow};
    `,
};

export default StyleAlfaTeoriaPratica;
