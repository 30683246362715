import React, { useContext, useState } from 'react';
import Styled from './AdminBarraLateral.styled';
import { AdminBarraLateralItemType } from './AdminBarraLateral';

import homeOnIcon from '../../../../images/admin/home-on.svg';
import homeOffIcon from '../../../../images/admin/home-off.svg';

import socioOnIcon from '../../../../images/admin/socio-on.svg';
import socioOffIcon from '../../../../images/admin/socio-off.svg';

import biblioOnIcon from '../../../../images/admin/biblio-on.svg';
import biblioOffIcon from '../../../../images/admin/biblio-off.svg';

import adminCursosIcon from '../../../../images/admin/admin-cursos.svg';
import adminCursosOnIcon from '../../../../images/admin/admin-cursos-on.svg';

import adminGestaoPaginasIcon from '../../../../images/admin/admin-gestao-paginas.svg';
import adminGestaoPaginasOnIcon from '../../../../images/admin/admin-gestao-paginas-on.svg';

import adminHeaderIcon from '../../../../images/admin/admin-header.svg';
import adminHeaderOnIcon from '../../../../images/admin/admin-header-on.svg';

import adminFaqIcon from '../../../../images/admin/admin-faq.svg';
import adminFaqOnIcon from '../../../../images/admin/admin-faq-on.svg';

import adminUserIcon from '../../../../images/admin/admin-gestao-usuarios.svg';
import adminUserOnIcon from '../../../../images/admin/admin-gestao-usuarios-on.svg';

import markIcon from '../../../../images/admin/mark.svg';
import { AdminContext } from 'pages/admin/context/AdminContext';

export interface IAdminBarraLateralItem {
    type: AdminBarraLateralItemType;
    enabled: boolean;
}

const AdminBarraLateralItem = ({ enabled, type }: IAdminBarraLateralItem) => {
    const { setMenu, subPage, setSubPage } = useContext(AdminContext.Context);

    const switchIcon = () => {
        if (type == AdminBarraLateralItemType.HOME) return enabled ? homeOnIcon : homeOffIcon;
        else if (type == AdminBarraLateralItemType.SOCIO) return enabled ? socioOnIcon : socioOffIcon;
        else if (type == AdminBarraLateralItemType.BIBLIO) return enabled ? biblioOnIcon : biblioOffIcon;
        else if (type == AdminBarraLateralItemType.CURSOS) return enabled ? adminCursosOnIcon : adminCursosIcon;
        else if (type == AdminBarraLateralItemType.GESTAO_PAGINAS) return enabled ? adminGestaoPaginasOnIcon : adminGestaoPaginasIcon;
        else if (type == AdminBarraLateralItemType.HEADER) return enabled ? adminHeaderOnIcon : adminHeaderIcon;
        else if (type == AdminBarraLateralItemType.FAQ) return enabled ? adminFaqOnIcon : adminFaqIcon;
        else if (type == AdminBarraLateralItemType.GESTAO_USUARIOS) return enabled ? adminUserOnIcon : adminUserIcon;
    };

    const switchLabel = () => {
        if (type == AdminBarraLateralItemType.HOME) return 'Início';
        else if (type == AdminBarraLateralItemType.SOCIO) return 'Sociemocional ';
        else if (type == AdminBarraLateralItemType.BIBLIO) return 'Biblioteca';
        else if (type == AdminBarraLateralItemType.CURSOS) return 'Cursos Livres';
        else if (type == AdminBarraLateralItemType.GESTAO_PAGINAS) return 'Gestão de Páginas';
        else if (type == AdminBarraLateralItemType.HEADER) return 'Gestão Do Header';
        else if (type == AdminBarraLateralItemType.FAQ) return 'FAQ';
        else if (type == AdminBarraLateralItemType.GESTAO_USUARIOS) return 'Gestão de Usuários';
    };

    const selectMenu = () => {
        if (type == AdminBarraLateralItemType.PAGES && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.SOCIO && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.BIBLIO && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.CURSOS && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.GESTAO_PAGINAS && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.HEADER && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.FAQ && subPage != null) {
            setSubPage(null);
        } else if (type == AdminBarraLateralItemType.GESTAO_USUARIOS && subPage != null) {
          setSubPage(null);
        }

        setMenu(type);
    };

    return (
        <Styled.ContainerItem enabled={enabled} onClick={selectMenu}>
            <Styled.ContenItem>
                <Styled.IconItem src={switchIcon()} />
                <Styled.LabelItem enabled={enabled}>{switchLabel()}</Styled.LabelItem>
            </Styled.ContenItem>

            {enabled && <Styled.MarkItem src={markIcon} />}
        </Styled.ContainerItem>
    );
};

export default AdminBarraLateralItem;
