import { CertificacoesModel } from "models/certificacoes/CertificacoesModel";
import { GenericService } from "./Generic.service";


export class CertificacoesService extends GenericService {
    readonly URI: string = '/v1/certificacoes';

    constructor() {
        super();
        this.setUri(this.URI);
    }


    public inserir(data: CertificacoesModel) {
        return this.post('inserir', data);
    }

    public updateCertificated(id: Number, data: CertificacoesModel) {
        return this.put('update/' + id, data);
    }
    public deleteCertificated(id: Number) {
        return this.deleteByParams(id.toString());
    }

    public listarAll(){
        return this.get('');
    }
    public listarById(id: number){
        return this.get('public/' + id);
    }

}