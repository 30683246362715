import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';

//Imgs
import Imagem from '../../assets/AlfaTeoriaPratica/image_primary.png';
import LogoParceiros from '../../assets/AlfaTeoriaPratica/regua_logos_horizontal.png';

const FirstCapa = () => {
    return (
        <>
            <Styled.ContentWhite>
                <Styled.TitleBlue style={{ color: Colors.SoftBlue, fontSize: 36, marginBottom: 2 }}>ALFABETIZAR:</Styled.TitleBlue> <br />
                <Styled.TitleBlue style={{ fontSize: 36 }}>DAS DEFINIÇÕES ÀS BOAS PRÁTICAS</Styled.TitleBlue>
                <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                    <Styled.Img style={{ height: '700px', maxWidth: '900px' }} src={Imagem} />
                </div>
                <Styled.Credits>
                    Material produzido pelo Instituto Ayrton Senna | 2024. Pode ser reproduzido, desde que mantida a menção de autoria
                </Styled.Credits>
            </Styled.ContentWhite>
            <Styled.ContentBlue4>
                <Styled.Img src={LogoParceiros} />
            </Styled.ContentBlue4>
        </>
    );
};

export default FirstCapa;
