import Colors from 'enums/Colors';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';

const MobileMenuStyled = {
    MobileMenuButtonContainer: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
    `,

    SearchButtonContainer: styled.div`
        color: #888888;
        margin-left: 15px;
        font-size: 1.5em;

        cursor: pointer;

        :hover {
            color: #003c78;
        }
    `,

    MobileMenuIcon: styled(FaBars)`
        color: ${Colors.Grey3};
        cursor: pointer;
    `,

    MobileMenu: styled.nav<{ open: boolean }>`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: ${Colors.DarkBlue};
        transform: ${props => (props.open ? 'translateX(0)' : 'translateX(140%)')};
        height: 100vh;
        padding: 2rem;
        position: absolute;
        top: 0;
        right: 0;
        transition: transform 0.3s ease-in-out;
        width: 262px;
    `,

    MobileMenuItemsContainer: styled.div`
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding-left: 20px;
        align-items: flex-start;
    `,

    MobileMenuCloseButton: styled(IoMdClose)`
        color: ${Colors.White};
        cursor: pointer;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 40px;
        left: 65%;
    `,

    MobileMenuItem: styled.div`
        margin-bottom: 20px;
    `,

    MenuItem: styled.div`
        margin-right: 5px;

        &:hover {
            > * {
                &:first-child {
                    cursor: pointer;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    text-decoration: none !important ;
                }
                &.point {
                    visibility: visible;
                }
            }
        }
    `,

    MenuItemText: styled.div`
        color: ${Colors.Grey4};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;

        @media only screen and (max-width: 1250px) {
            color: ${Colors.White};
            letter-spacing: 0.2px;
        }
    `,

    MenuItemTextSub: styled.div`
        color: #fcfcfc;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 15px;
        padding-left: 15px;
        line-height: 17px;
        opacity: 0.85;

        @media only screen and (max-width: 1250px) {
            color: ${Colors.White};
            letter-spacing: 0.2px;
        }
    `,
};

export default MobileMenuStyled;
