
import { createContext } from 'react';

export namespace CheckedGoToContext {
    export interface IContext {

        showModal: boolean;
        setShowModal: (value: boolean) => void;


        isVerifyGoToPage: (value: string) => void;
        goToPage: () => void;


    }

    export const Context = createContext({} as IContext);
}
