import styled from 'styled-components';
import { IsMobileMaxWidth } from '../../core/util/WindowSize';

import Colors from '../../enums/Colors';

export namespace PopupQuestionStyles {
    interface IContainer {}
    export const Container = styled.div<IContainer>(({ ...props }: IContainer) => ({
        top: '50%',
        left: '50%',
        padding: 20,
        width: IsMobileMaxWidth() ? '320px' : '500px',
        height: IsMobileMaxWidth() ? '300px' : '389px',
        overflow: 'hidden',
        alignSelf: 'center',
        borderRadius: '20px',
        position: 'absolute',
        justifySelf: 'center',
        background: Colors.White,
        transform: 'translate(-50%, -50%)',
        boxShadow: '1px 1px 10px 6px rgba(0, 0, 0, 0.1)',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    }));

    export const Header = styled.div({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    interface IBody {}
    export const Body = styled.p<IBody>((p: IBody) => ({
        display: 'flex',
        fontSize: IsMobileMaxWidth() ? '20px' : '24px',
        fontWeight: 'bold',
        lineHeight: '32px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Barlow',
        alignItems: 'center',
        color: Colors.DarkGrey,
        textTransform: 'uppercase',
    }));

    interface IFooter {}
    export const Footer = styled.div<IFooter>((p: IFooter) => ({
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: IsMobileMaxWidth() ? 'column' : 'row',
    }));
}
