import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import FormControl from '../../../formik/FormControl';

import FormLabel from '../../../formik/FormLabel';
import FormikReactSelect from '../../../formik/FormikReactSelect';
import Atuacao_img_2 from '../../../../../../images/profile/atuacao_img_2.svg';
import Atuacao_img_3 from '../../../../../../images/profile/atuacao_img_3.svg';
import Atuacao_img_4 from '../../../../../../images/profile/atuacao_img_4.svg';
import FormikTextInput from 'pages/profile/components/formik/FormikTextInput';
import { RoleEnum } from 'enums/RoleEnum';
import { fetchRoleDescription, isAutoCadastrado, isUsuarioLms } from 'core/util/KeycloakHelp';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';

const CODIGO_TIPO_ENSINO_ESCOLA_PRIVADA = 1;
const CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA = 2;
const CODIGO_TIPO_ENSINO_ESCOLA_NAO_SE_APLICA = 3;

export const InlineFormikContainer = styled.div`
    margin-left: 10px;
`;

interface IWebForm {
    formik: any;
    ReloadContentHandler: any;
    hasAtuacaoData: boolean | undefined;
    profileParameterData: any;
    isLoading: boolean;
    size?: number;
    updateRole: (r: RoleEnum) => void;
}
const WebForm = ({ formik, updateRole, ReloadContentHandler, hasAtuacaoData, profileParameterData, isLoading, size }: IWebForm) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const getTiposPerifs = () => {
        return [
            { descricao: 'Professor(a)', id: RoleEnum.Professor },
            { descricao: 'Coordenador não parceiro', id: RoleEnum.CoordenadorNaoParceiro },
            { descricao: 'Técnico de Secretaria de Educação', id: RoleEnum.TecnicoEducacao },
            { descricao: 'Diretor', id: RoleEnum.Diretor },
            { descricao: 'Outro', id: RoleEnum.Outro },
        ];
    };

    return (
        <>
            {!isLoading && (
                <FormControl>
                    <FormLabel label="Eu atuo hoje como" />
                    {!isAutoCadastrado(usuarioLogado) && !isUsuarioLms(usuarioLogado) ? (
                        <FormikTextInput
                            size={size || 190}
                            name="tipoPerfil"
                            onBlurHandler={() => {
                                ReloadContentHandler(formik, 'tipoPerfil', 45, Atuacao_img_2);
                            }}
                            formik={formik}
                            disabled={true}
                            onChangeHandler={() => {}}
                        />
                    ) : (
                        <FormikReactSelect
                            size={270}
                            name="tipoPerfil"
                            options={getTiposPerifs()}
                            formik={formik}
                            onChangeHandler={async (value: RoleEnum) => {
                                updateRole(value);
                                ReloadContentHandler(formik, 'tipoPerfil', 45, Atuacao_img_2);
                            }}
                        />
                    )}
                </FormControl>
            )}

            {!isLoading && formik.values['tipoPerfil'] === RoleEnum.Outro && (
                <FormControl>
                    <FormLabel label="Identifique" />
                    <FormikTextInput
                        size={280}
                        name="outroPerfilIdentificacao"
                        onBlurHandler={() => {
                            ReloadContentHandler(formik, 'outroPerfilIdentificacao', 45, Atuacao_img_2);
                        }}
                        formik={formik}
                        disabled={false}
                        onChangeHandler={() => {}}
                    />
                </FormControl>
            )}

            {!isLoading && formik.values['tipoPerfil'] === fetchRoleDescription(RoleEnum.GestorEscolar) && (
                <FormControl>
                    <FormLabel label="Sou gestor(a)" />
                    <FormikReactSelect
                        size={260}
                        name="tipoGestor"
                        options={profileParameterData.listaTipoGestor}
                        formik={formik}
                        onChangeHandler={async (value: string) => {
                            ReloadContentHandler(formik, 'tipoGestor', 45, Atuacao_img_2);
                        }}
                    />
                </FormControl>
            )}

            {!isLoading &&
                (hasAtuacaoData ||
                    formik.touched.tipoGestor ||
                    !(formik.values['tipoPerfil'] === fetchRoleDescription(RoleEnum.GestorEscolar))) && (
                    <FormControl>
                        <FormLabel label="Na rede" />
                        <FormikReactSelect
                            size={132}
                            name="tipoEnsino"
                            options={profileParameterData.listaTipoEnsino}
                            formik={formik}
                            onChangeHandler={async (value: string) => {
                                ReloadContentHandler(formik, 'tipoEnsino', 50, Atuacao_img_3);
                            }}
                        />
                    </FormControl>
                )}

            {!isLoading &&
                (hasAtuacaoData ||
                    formik.touched.tipoGestor ||
                    !(formik.values['tipoPerfil'] === fetchRoleDescription(RoleEnum.GestorEscolar))) && (
                    <>
                        {((formik.touched.tipoEnsino && formik.values['tipoEnsino'] === CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA) ||
                            (hasAtuacaoData && formik.values['tipoEnsino'] === CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA)) && (
                            <FormControl>
                                <FormLabel label="Na esfera" />
                                <FormikReactSelect
                                    size={165}
                                    name="tipoEnsinoRedePublica"
                                    options={profileParameterData.listaTipoEnsinoEscolaPublica}
                                    formik={formik}
                                    onChangeHandler={async (value: string) => {
                                        ReloadContentHandler(formik, 'tipoEnsinoRedePublica', 55, Atuacao_img_3);
                                    }}
                                />
                            </FormControl>
                        )}
                    </>
                )}

            {((formik.touched.tipoEnsino && formik.values['tipoEnsino'] !== CODIGO_TIPO_ENSINO_ESCOLA_PUBLICA) ||
                formik.touched.tipoEnsinoRedePublica ||
                hasAtuacaoData) &&
                formik.values['tipoEnsino'] !== CODIGO_TIPO_ENSINO_ESCOLA_NAO_SE_APLICA && (
                    <FormControl>
                        <FormLabel label="No Ensino" />
                        <FormikReactSelect
                            size={296}
                            name="categoriaEnsino"
                            options={profileParameterData.listaCategoriaEnsino}
                            formik={formik}
                            onChangeHandler={async (value: string) => {
                                ReloadContentHandler(formik, 'categoriaEnsino', 60, Atuacao_img_4);
                            }}
                        />
                    </FormControl>
                )}
        </>
    );
};

export default WebForm;
