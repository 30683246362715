import React, { useContext, useEffect, useState } from 'react';

import Styled from './BiblioFavorites.styled';

import favIcon from '../../../../images/biblioteca/fav-icon.svg';
import noFavIcon from '../../../../images/biblioteca/nofav-icon.svg';
import BiblioCollectionModal from 'pages/biblioteca/subpages/collection/BiblioCollectionModal';
import BiblioColecaoService from 'core/http/service/biblioteca/BiblioColecao.service';
import BiblioCollectionNewModal from 'pages/biblioteca/subpages/collection/BiblioCollectionNewModal';
import BiblioCollectionRemoveModal from 'pages/biblioteca/subpages/collection/BiblioCollectionRemove';
import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';
import BiblioItem from 'models/biblioteca/BiblioItem';
import { BibliotecaContext } from 'pages/biblioteca_v2/context/BibliotecaContext';
import { useKeycloak } from '@react-keycloak/web';
import useLogged from 'hooks/useLogged';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import { ToastError } from 'components/Toaster';

export interface IBiblioFavoriteButton {
    isFavorited?: boolean;
    collection_id?: number;
    data: BiblioItemPreview | BiblioItem;
}

const BiblioFavoriteButton = ({ isFavorited = false, collection_id, data }: IBiblioFavoriteButton) => {
    const { setFavoriteItem } = useContext(BibliotecaContext.Context);
    const [showModal, setShowModal] = useState(false);
    const { keycloak } = useKeycloak();
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showNewCollectionModal, setShowNewCollectionModal] = useState<boolean>(false);
    const MAX_CHARS = 50;
    const { login } = useLogged();

    const toggleValue = () => {
        if (keycloak.authenticated) {
            if (data.isFavorited) setShowRemoveModal(true);
            else setShowModal(true);
        } else {
            login(location.pathname);
        }
    };

    const onCreate = async (name: string) => {
        if (name.length <= MAX_CHARS) {
            const service = new BiblioColecaoService();
            const response = await service.novaColecao(name, data.id);
            if (response.status == 200) {
                setShowNewCollectionModal(false);
                setFavoriteItem(data.id, true);
            }
        } else {
            ToastError('Nome da coleção muito longo!');
        }
    };

    const unfavoriteItem = async () => {
        const service = new BiblioColecaoService();
        await service.removerItem(data.id);
        setFavoriteItem(data.id, false);
        setShowRemoveModal(false);

        if (document.URL.includes('biblioteca/meu-perfil/favoritos')) {
            location.reload();
        }
    };

    return (
        <>
            <BiblioCollectionModal
                onSelected={() => setFavoriteItem(data.id, true)}
                item_id={data.id}
                show={showModal}
                onHide={() => setShowModal(false)}
                toCreate={() => {
                    GTM_Page_Biblioteca(`${data.id}`, `new_favorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`, 'click_new_favorite_btn');
                    setShowModal(false);
                    setShowNewCollectionModal(true);
                }}
            />

            <BiblioCollectionNewModal show={showNewCollectionModal} onHide={() => setShowNewCollectionModal(false)} onCreate={onCreate} />

            <BiblioCollectionRemoveModal
                show={showRemoveModal}
                titleItem={data?.title ?? ''}
                onHide={() => setShowRemoveModal(false)}
                onRemove={() => {
                    GTM_Page_Biblioteca(`${data.id}`, `unfavorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`, 'unfavorite_btn');
                    unfavoriteItem();
                }}
            />

            <Styled.Container>
                {data.isFavorited ? (
                    <Styled.ActionButton
                        src={favIcon}
                        onClick={() => {
                            GTM_Page_Biblioteca(`${data.id}`, `favorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`, 'click_favorite_btn');
                            toggleValue();
                        }}
                    />
                ) : (
                    <Styled.ActionButton
                        src={noFavIcon}
                        onClick={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `not_favorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'click_not_favorite_btn'
                            );
                            toggleValue();
                        }}
                    />
                )}
            </Styled.Container>
        </>
    );
};

export default BiblioFavoriteButton;
