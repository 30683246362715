import Colors from 'enums/Colors';
import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';

const DesktopMenuStyled = {
    SearchButtonContainer: styled.div`
        color: #888888;
        margin-left: 15px;
        font-size: 1.5em;
        cursor: pointer;

        :hover {
            color: #003c78;
        }
    `,

    MenuItemsContainer: styled.div`
        display: flex;
        flex: 3;
        justify-content: flex-end;
        padding-left: 30px;
        padding-right: 30px;
        align-items: center;
        height: 100%;
    `,

    NavHashLinkStyle: styled(NavHashLink)`
        text-decoration: none !important;
        &:hover {
            text-decoration: none !important;
        }
    `,

    PopOver: styled.div`
        button {
            filter: grayscale(0);
        }
        position: absolute;
        left: -19px;
        top: 52px;
        width: 150px;
        text-align: center;
        background: #ffffff;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 8px 8px;
    `,

    OptionsContainer: styled.div`
        display: flex;
        flex-direction: column;
    `,

    MenuItem: styled.div`
        margin-right: 5px;
        line-height: 18px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        position: relative;
        user-select: none;

        &:hover {
            > * {
                &:first-child {
                    cursor: pointer;
                    color: #003c78;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    text-decoration: none !important ;
                }
                &.point {
                    visibility: visible;
                }
            }
        }
    `,

    MenuOptionsWithHover: styled.button`
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #747070;
        height: 60px;
        background: transparent;
        border: none;
        text-transform: capitalize;

        &:hover {
            cursor: pointer;
            color: ${Colors.White};
            font-weight: 600;
            letter-spacing: 0.2px;
            background: ${Colors.Blue};
        }

        &:last-child:hover {
            border-radius: 0px 0px 8px 8px;
        }
    `,

    Point: styled.div`
        position: absolute;
        translate: 0 26px;
        background-color: #003c78;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        visibility: hidden;
    `,

    MenuItemTextHover: styled.div<{ active: boolean }>`
        color: ${props => (props.active ? Colors.Blue : Colors.Grey4)};
        font-style: normal;
        cursor: pointer;
        font-weight: ${props => (props.active ? 700 : 600)};
        text-decoration: ${props => (props.active ? 'underline' : 'none')};

        text-decoration-thickness: 3px;
        text-underline-offset: 18px;
        font-size: 14px;

        text-transform: capitalize;
    `,
};

export default DesktopMenuStyled;
