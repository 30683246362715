import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class TokenPE_DTO extends Serializable {
    @jsonName('token')
    @jsonProperty(String)
    token: string = '';

    @jsonName('status')
    @jsonProperty(Boolean)
    status: boolean = false;
}

export default TokenPE_DTO;
