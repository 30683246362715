import styled from 'styled-components';
import Colors from '../../enums/Colors';
import { media760, mediaOnlyScreenMaxWidthStr } from '../../core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { MobileMaxWidth } from 'constants/Dimensions';

export const MenuRealizandoEscolhasStyle = styled.div({
    flex: 1,
    minHeight: '100vh',
    backgroundColor: Colors.SoftBG,
});

const mq = [
    new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
        paddingTop: '60px',
    })
];

export const DivPaddingMarginTop = styled.div(
    new FullCss(
        {
            paddingTop: '30px',
            marginTop: '30px',
        },
        mq
    ).getCss()
);

export const InstrumentoContainer = styled.div(
    new FullCss(
        {
            height: '100%',
            paddingTop: '30px',
            position: 'relative',
            margin: '0 auto',
            marginTop: '30px',
        },
        mq
    ).getCss()
);

export const MainContainer = styled.section(
    new FullCss(
        {
            height: '100%',
            paddingTop: '86px',
            position: 'relative',
            maxWidth: '1253px',
            margin: '0 auto',
            marginTop: '30px',
        },
        mq
    ).getCss()
);

export const Title = styled.p(
    media760(
        {
            fontSize: '32px',
            lineHeight: '42px',
            fontStyle: 'normal',
            fontFamily: 'Barlow',
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
            color: Colors.BlueGrey,
        },
        {
            fontSize: '18px',
        }
    )
);
