import React from 'react';
import { DateHelp } from 'core/util/DateHelp';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import capitalize from 'pages/admin/utils/capitalize';
import Styled from '../../socio/AdminSocioEmocional.styled';
import AdminBiblioAvaliacao from 'models/admin/AdminBiblioAvaliacao';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';

export interface IAdminModalBiblioItem {
    show: boolean;
    onHide: () => void;
    rate?: AdminBiblioAvaliacao;
}

const AdminModalBiblioItem = ({ show, onHide, rate }: IAdminModalBiblioItem) => {
    return (
        <AdminModalShow show={show} onHide={onHide}>
            {rate && (
                <>
                    <Styled.Modal.Title>{capitalize(rate.item.category)}</Styled.Modal.Title>
                    <Styled.Modal.SubTitle>{capitalize(rate.item.title)}</Styled.Modal.SubTitle>
                    <br />
                    <Styled.Modal.UserName>{rate.username}</Styled.Modal.UserName>
                    <Styled.Modal.Date>{DateHelp.formatDateFull(rate.date)}</Styled.Modal.Date>
                    <div>
                        <AdminRateStar rate={rate.rate} />
                    </div>
                </>
            )}
        </AdminModalShow>
    );
};

export default AdminModalBiblioItem;
