import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import {
    AdminSecaoImagem,
    AdminSecaoInteresse,
    AdminSecaoPaginaBanner,
    AdminSecaoPaginaCardItem,
    AdminSecaoPaginaImagemSimples,
    AdminSecaoPaginaSimplesTexto,
    ModeloBase,
} from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { GenericService } from '../Generic.service';
import { PaginaIntermediariaService } from '../PaginaIntermediaria.service';
import { InserirInteresse } from 'models/generic-components/SecaoInteresse';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

export default class AdminGestaoPaginaService extends GenericService {
    readonly URI: string = '/v1/gestaoPaginas';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public criarPagina(data: AdminPaginaIntermediaria) {
        return new PaginaIntermediariaService().inserirDinamica(data);
    }

    public atualizarPagina(paginaId: number, data: AdminPaginaIntermediaria) {
        return new PaginaIntermediariaService().editar(paginaId, data);
    }

    public inserirSecao(data: ModeloBase, paginaId: number, ordem: number) {
        return this.post('paginaModeloSecao/insert', data.toObject(paginaId, ordem));
    }

    // ======================================== CARROSSEL ==============================================================

    public buscarCarrossel(secaoId: number) {
        return this.get('secaoCarrossel/getById', { id: secaoId });
    }

    public buscarCartoesCarrossel(idSecao: number) {
        return this.get('secaoCarrossel/carrossel/listBySecao', { idSecao });
    }

    public atualizarCarrossel(secaoId: number, titulo: string, descricao: string, ordem: number) {
        return this.put('secaoCarrosselMultimidia/updateSectionOnly/' + secaoId, { codigo: secaoId, titulo, descricao, ordem });
    }

    public atualizarSecaoCarrosselMultimidia(secaoId: number, corSecao: string) {
        return this.put('secaoCarrosselMultimidia/updateSectionOnly/' + secaoId, { codigo: secaoId, corSecao });
    }

    public deleteCarrosselMultimidia(id: number) {
        return this.deleteByParams('secaoCarrosselMultimidia/delete/' + id);
    }

    public buscarCarrosseisMultimidia(secaoId: number) {
        return this.get('secaoCarrosselMultimidia/getById', { id: secaoId });
    }

    public deleteCardCarrosselMultimidia(idCard: number) {
        return this.deleteByParams('secaoCarrosselMultimidia/delete/card/' + idCard);
    }

    public inserirCarrosselMultimidia(
        secaoId: number,
        detalhamento: string,
        corDetalhamento: string,
        tamanhoDetalhamento: string,
        ocultarDetalhamento: boolean,
        tipoAnexo: string,
        anexoBase64: string,
        urlVideo: string,
        oculto?: boolean
    ) {
        return this.post('secaoCarrosselMultimidia/carrossel/insertOneOnly?idSecao=' + secaoId, {
            detalhamento,
            corDetalhamento,
            tamanhoDetalhamento,
            ocultarDetalhamento,
            tipoAnexo,
            anexoBase64,
            urlVideo,
            oculto,
        });
    }

    public atualizarCarrosselMultimidia(
        cartaoId: number,
        detalhamento: string,
        corDetalhamento: string,
        tamanhoDetalhamento: string,
        ocultarDetalhamento: boolean,
        tipoAnexo: string,
        anexoBase64: string,
        urlVideo: string,
        oculto?: boolean
    ) {
        return this.put('secaoCarrosselMultimidia/carrossel/' + cartaoId, {
            codigo: cartaoId,
            detalhamento,
            corDetalhamento,
            tamanhoDetalhamento,
            ocultarDetalhamento,
            tipoAnexo,
            anexoBase64,
            urlVideo,
            oculto,
        });
    }

    public buscarCarrosselMultimidia(idSecao: number) {
        return this.get('secaoCarrosselMultimidia/carrossel/listBySecao', { idSecao });
    }

    public inserirCartaoCarrossel(
        secaoId: number,
        titulo: string,
        conteudo: string,
        ocupacao: string,
        link: string,
        textoBotao: string,
        anexoBase64: string
    ) {
        return this.post('secaoCarrossel/carrossel/insertOneOnly?idSecao=' + secaoId, {
            titulo,
            conteudo,
            ocupacao,
            anexoBase64,
            link,
            textoBotao,
        });
    }

    public atualizarItemCustom(secaoId: number, corSecao: string, ordem: number) {
        return this.put('secaoItemCustom/updateSectionOnly/' + secaoId, { codigo: secaoId, corSecao, ordem });
    }

    public deleteItemCustom(id: number) {
        return this.deleteByParams('secaoItemCustom/delete/' + id);
    }

    public buscarCartoesCustomizados(secaoId: number) {
        return this.get('secaoItemCustom/getById', { id: secaoId });
    }

    public deleteCardCustom(idCard: number) {
        return this.deleteByParams('secaoItemCustom/delete/card/' + idCard);
    }

    public inserirCartaoCustom(
        secaoId: number,
        infoDestaque: string,
        corInfoDestaque: string,
        tamanhoInfoDestaque: string,
        ocultarInfoDestaque: boolean,
        detalhamento: string,
        corDetalhamento: string,
        tamanhoDetalhamento: string,
        ocultarDetalhamento: boolean,
        anexoBase64: string,
        ocultarAnexo: boolean,
        oculto?: boolean
    ) {
        return this.post('secaoItemCustom/item/insertOneOnly?idSecao=' + secaoId, {
            infoDestaque,
            corInfoDestaque,
            tamanhoInfoDestaque,
            ocultarInfoDestaque,
            detalhamento,
            corDetalhamento,
            tamanhoDetalhamento,
            ocultarDetalhamento,
            anexoBase64,
            ocultarAnexo,
            oculto,
        });
    }

    public atualizarCartaoCustom(
        cartaoId: number,
        infoDestaque: string,
        corInfoDestaque: string,
        tamanhoInfoDestaque: string,
        ocultarInfoDestaque: boolean,
        detalhamento: string,
        corDetalhamento: string,
        tamanhoDetalhamento: string,
        ocultarDetalhamento: boolean,
        anexoBase64: string,
        ocultarAnexo: boolean,
        oculto?: boolean
    ) {
        return this.put('secaoItemCustom/item/' + cartaoId, {
            codigo: cartaoId,
            infoDestaque,
            corInfoDestaque,
            tamanhoInfoDestaque,
            ocultarInfoDestaque,
            detalhamento,
            corDetalhamento,
            tamanhoDetalhamento,
            ocultarDetalhamento,
            anexoBase64,
            ocultarAnexo,
            oculto,
        });
    }

    public buscarCartoesCustom(idSecao: number) {
        return this.get('secaoItemCustom/item/listBySecao', { idSecao });
    }

    public atualizarCartaoCarrossel(
        cartaoId: number,
        titulo: string,
        conteudo: string,
        ocupacao: string,
        link: string,
        textoBotao: string,
        anexoBase64: string,
        tipoRedirecionamento: TipoRedirecionamento
    ) {
        return this.put('secaoCarrossel/carrossel/' + cartaoId, {
            codigo: cartaoId,
            titulo,
            conteudo,
            ocupacao,
            link,
            textoBotao,
            anexoBase64,
            tipoRedirecionamento,
        });
    }
    // ==================================================================================================================

    // ======================================== CARTÕES ==============================================================
    public buscarCategoriasCartoes() {
        return this.get('secaoCard/listarCategorias');
    }

    public atualizarSecaoCartoes(secaoId: number, titulo: string, descricao: string, ordem: number) {
        return this.put('secaoCard/updateSectionOnly/' + secaoId, { titulo, descricao, ordem });
    }

    public inserirCartaoCategoria(secaoId: number, data: AdminSecaoPaginaCardItem) {
        return this.post('secaoCard/card/insertOneOnly?idSecao=' + secaoId, data.toObject());
    }

    public atualizarCartaoCategoria(cartaoId: number, data: AdminSecaoPaginaCardItem) {
        return this.put('secaoCard/card/' + cartaoId, data.toObject());
    }

    public atualizarOrdemCartaoCategoria(cartaoId: number, data: AdminSecaoPaginaCardItem) {
        return this.post('secaoCard/updateOrder/', { codigo: cartaoId, ...data.toObject() });
    }

    public buscaSecaoCartoesPorId(secaoId: number) {
        return this.get('secaoCard/getById', { id: secaoId });
    }

    public updateCartoesCategoriaOcultar(idCard: number) {
        return this.patch('secaoCarrossel/update/card/oculto/' + idCard);
    }
    // ==================================================================================================================
    public atualizarOcultacaoSessao(secaoId: number, secaoModelo: string, isOculto: boolean) {
        return this.patch('paginaModeloSecao/update/ocultar/' + secaoId + '?modelo=' + secaoModelo + '&oculto=' + isOculto);
    }
    // ======================================== EXCLUSÕES ==============================================================

    public deleteBanner(idBanner: number) {
        return this.deleteByParams('secaoBanner/delete/' + idBanner);
    }

    public deleteSecaoSimplesComImagem(idSecaoSimplesComImgame: number) {
        return this.deleteByParams('secaoSimplesImagem/delete/' + idSecaoSimplesComImgame);
    }

    public deleteSecaoImagem(idSecaoImgame: number) {
        return this.deleteByParams('secaoImagem/delete/' + idSecaoImgame);
    }
    public deleteSecaoInteresse(idSecaoImgame: number) {
        return this.deleteByParams('secaoInteresse/delete/' + idSecaoImgame);
    }

    public deleteSecaoSimplesSemImagem(idSecaoSimplesSemImgame: number) {
        return this.deleteByParams('secaoSimples/delete/' + idSecaoSimplesSemImgame);
    }

    public deleteCategoria(idCategoria: number) {
        return this.deleteByParams('secaoCard/delete/' + idCategoria);
    }

    public deleteCardCategoria(idCardCategoria: number) {
        return this.deleteByParams('secaoCard/delete/card/' + idCardCategoria);
    }

    public deleteCarrossel(idCarrossel: number) {
        return this.deleteByParams('secaoCarrossel/delete/' + idCarrossel);
    }

    public deleteCardCarrossel(idCardCarrossel: number) {
        return this.deleteByParams('secaoCarrossel/delete/card/' + idCardCarrossel);
    }

    // ==================================================================================================================

    public listsAllPublic(idPagina: number) {
        return this.get('paginaModeloSecao/listAll/' + idPagina);
    }

    public atualizarSecaoSimples(secaoId: number, data: AdminSecaoPaginaSimplesTexto) {
        return this.put('secaoSimples/update/' + secaoId, { ...data });
    }

    public buscaSecaoBannerPorId(secaoId: number) {
        return this.get('secaoBanner/getById', { id: secaoId });
    }

    public atualizarBanner(secaoId: number, data: AdminSecaoPaginaBanner) {
        return this.put('secaoBanner/update/' + secaoId, { ...data });
    }

    public atualizarSimplesImagem(secaoId: number, data: AdminSecaoPaginaImagemSimples) {
        return this.put('secaoSimplesImagem/' + secaoId, { ...data });
    }

    public atualizarSecaoImagem(secaoId: number, data: AdminSecaoImagem) {
        return this.put('secaoImagem/' + secaoId, { ...data });
    }

    public atualizarSecaoInteresse(secaoId: number, data: AdminSecaoInteresse) {
        return this.put('secaoInteresse/update/' + secaoId, { ...data });
    }

    public cadastrarInteresse(data: InserirInteresse) {
        return this.post('secaoInteresse/insertInteresse', { ...data });
    }

    public buscarSecoes(paginaId: number) {
        return this.get('paginaModeloSecao/list/' + paginaId);
    }

    public buscarPagina(paginaId: number) {
        return new PaginaIntermediariaService().buscarPagina(paginaId);
    }

    public buscarSecaoSimplesPorId(secaoId: number) {
        return this.get('secaoSimples/getById', { id: secaoId });
    }

    public buscarSecaoSimplesImagemPorId(secaoId: number) {
        return this.get('secaoSimplesImagem/getById', { id: secaoId });
    }

    public buscarSecaoImagemPorId(secaoId: number) {
        return this.get('secaoImagem/getById', { id: secaoId });
    }

    public buscarSecaoInteressePorId(secaoId: number) {
        return this.get('secaoInteresse/getById', { id: secaoId });
    }

    public updateOrder(idSecao: number, idModelo: number, ordem: number, idPagina: number) {
        return this.post('paginaModeloSecao/updateOrder', { idSecao: idSecao, idModelo: idModelo, ordem: ordem, idPagina: idPagina });
    }
}
