import React from 'react';
import Styled from '../../Biblioteca.styled';

export interface IBibliotecaNoContent {}

const BibliotecaNoContent = ({}: IBibliotecaNoContent) => {
    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <Styled.NoContentText>Ainda não há conteúdos para esse filtro</Styled.NoContentText>
        </div>
    );
};

export default BibliotecaNoContent;
