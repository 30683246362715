import { GradientButton, PrimaryButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const PainelSocioemocionalStyle = {
    Container: styled.div`
        width: 600px;
        max-width: 100%;
        text-align: center;
        user-select: none;
    `,

    Icon: styled.img`
        height: 50px;
        width: auto;
    `,

    Row: styled.div`
        display: flex;
        justify-content: center;
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        margin: 0 auto;
    `,

    ButtonInfo: styled(GradientButton)`
        color: white;
    `,

    ButtonAlert: styled(PrimaryButton)`
        background-color: #ff4830;
        color: white;
    `,

    Loading: styled.img<{ visible: boolean }>`
        height: 3em;
        width: auto;
        opacity: ${props => (props.visible ? '1' : '0')};
    `,
};

export default PainelSocioemocionalStyle;
