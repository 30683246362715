import Row from 'components/Row';
import { OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import PaginaIntermediariaTipo from 'models/paginas/PaginaIntermediariaTipo';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import Styled from './AdminGestaoPaginasPage.styled';

export interface IAdminGestaoPaginasPageModal {
    show: boolean;
    onHide: () => void;
    onSelect: (tipo: PaginaIntermediariaTipo) => void;
}

const AdminGestaoPaginasPageModal = ({ show, onHide, onSelect }: IAdminGestaoPaginasPageModal) => {
    const service = new PaginaIntermediariaService();
    const [tiposPagina, setTiposPagina] = useState<PaginaIntermediariaTipo[]>([]);
    const [tipoSelecionado, setTipoSelecionado] = useState<number>(1);

    const fetchTiposPaginas = async () => {
        const { data } = await service.listarTipos();

        const _tipos = Array.from(data).map((t: any) => new PaginaIntermediariaTipo().fromJSON(t));

        // Para buscar todos os tipos : setTiposPagina(_tipos);
        setTiposPagina([_tipos[0], _tipos[2]]);
    };

    const onSelectHandler = () => {
        const tipo = tiposPagina.find(t => t.codigo == tipoSelecionado);
        if (tipo) onSelect(tipo);
    };

    useEffect(() => {
        fetchTiposPaginas();
    }, []);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Styled.Modal.CloseContainer>
                <p onClick={onHide}>
                    <AiOutlineClose />
                </p>
            </Styled.Modal.CloseContainer>

            <Styled.ContainerModal style={{ marginLeft: 0 }}>
                <Styled.Modal.Title>Como você quer criar essa nova página?</Styled.Modal.Title>

                <Row justify="space-around">
                    {tiposPagina.map(tipo => {
                        return (
                            <Styled.Modal.FrameOption style={{ padding: '20px' }} key={tipo.codigo}>
                                <Styled.Modal.Column>
                                    <span>{tipo.descricao}</span>
                                    <Form.Check
                                        style={{ cursor: 'pointer', transform: 'translateX(7px)' }}
                                        inline
                                        label=""
                                        type="radio"
                                        checked={tipoSelecionado == tipo.codigo}
                                        onClick={() => setTipoSelecionado(tipo.codigo)}
                                        onChange={() => {}}
                                    />
                                </Styled.Modal.Column>
                            </Styled.Modal.FrameOption>
                        );
                    })}
                </Row>
                <br />
                <Row justify="space-around">
                    <OutlineButton style={{ transform: 'scale(0.8)' }} onClick={onHide}>
                        Cancelar
                    </OutlineButton>
                    <PrimaryButton style={{ transform: 'scale(0.8)' }} onClick={onSelectHandler}>
                        Criar página
                    </PrimaryButton>
                </Row>
            </Styled.ContainerModal>
        </Modal>
    );
};

export default AdminGestaoPaginasPageModal;
