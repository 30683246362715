import React from 'react';
import Styled from '../AdminBiblioteca.styled';

import dangerIcon from '../../../../../images/danger-icon.svg';

import { DangerButton, OutlineButton } from 'components/UI/Buttons';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminPalavraProibida from 'models/admin/AdminPalavraProibida';

export interface IAdminModalExcludeTag {
    show: boolean;
    onHide: () => void;
    onDelete: () => void;
    word?: AdminPalavraProibida;
}

const AdminModalExcludePalavra = ({ show, onDelete, onHide, word }: IAdminModalExcludeTag) => {
    const deleteTag = async () => {
        const service = new AdminBiblioService();

        if (word) {
            await service.apagarPalavraProibida(word.id);
            onDelete();
        }
    };

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            {word && (
                <Styled.ContainerModal>
                    <Styled.Center>
                        <img src={dangerIcon} />
                        <br />
                        <Styled.TextModal>{`Tem certeza que deseja excluir a palavra “${word?.content}” ?`}</Styled.TextModal>
                        <br />
                        <br />
                        <br />
                    </Styled.Center>

                    <Styled.BottomButton style={{ width: '100%' }}>
                        <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                        <DangerButton onClick={deleteTag}>Excluir</DangerButton>
                    </Styled.BottomButton>
                </Styled.ContainerModal>
            )}
        </Styled.Modal>
    );
};

export default AdminModalExcludePalavra;
