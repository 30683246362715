import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';

async function submitAttach(idItem: number, file: File, titulo = 'Título', descricao = 'Descrição') {
    const service = new AdminHomeConteudoService();

    const formData = new FormData();
    formData.append('file', file);

    service.inserirAnexo(idItem, titulo, descricao, formData);
}

export default submitAttach;
