import { BaseButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const ModalDelete = {
    ContainerModal: styled.div`
        background-color: white;
        padding: 15px 45px;
    `,

    Button: styled(BaseButton)<{ color: Colors; bg?: Colors; bgHover: Colors }>`
        border: 1px solid ${props => props.bgHover};
        color: ${props => props.bgHover};
        background-color: ${props => (props.bg ? props.bg : 'transparent')};
        outline: none;
        transform: scale(0.8);

        &:hover {
            color: ${Colors.White};
            background-color: ${props => props.bgHover};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    Modal: {
        Container: styled.div`
            margin: 0;
            margin-bottom: 15px;
        `,

        TitleAtencao: styled.h1`
            font-size: 35px;
            text-align: center;
            font-weight: bold;
            color: ${Colors.Red};
            margin-bottom: 20px;
        `,

        Title: styled.p`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 25px;
            color: ${Colors.DarkBlue3};
            text-align: center;
        `,

        SubTitle: styled.p`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #343434;
            margin-bottom: 10px;
        `,

        TextAviso: styled.p`
            font-size: 14px;
            font-weight: 700;
            position: absolute;
            bottom: 115px;
            color: ${Colors.Red};
        `,

        FrameOption: styled.div`
            border: 1px #ddd solid;

            padding: 20px 30px;
            width: fit-content;
            height: fit-content;
            border-radius: 10px;
            text-align: center;
        `,

        Column: styled.div`
            width: fit-content;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #343434;

            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                margin-bottom: 20px;
            }
        `,

        CloseContainer: styled.div`
            display: flex;
            justify-content: end;
            padding: 10px;
            cursor: pointer;

            p {
                width: fit-content;
            }
        `,

        Image: styled.img`
            width: 135px;
            height: auto;
        `,

        InputConfirmation: styled.input<{ isIncorret: boolean }>`
            width: 100%;
            height: 40px;
            border-radius: 10px;
            border: 1px solid ${props => (props.isIncorret ? Colors.Red : Colors.Blue)};
            padding-left: 10px;
            font-size: 18px;

            &:focus {
                outline: none;
            }
        `,
    },
};

export default ModalDelete;
