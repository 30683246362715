import styled from 'styled-components';

import Colors from '../../../../../enums/Colors';
import { MobileBiggerWidth, ResizeMediumWidth } from '../../../../../constants/Dimensions';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';

export const StatementCarouselMediaCardStyle = styled.div<{ corSecao?: string, isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    background: ${Colors.White};
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    width: 415px;
    height: 445px;
    gap: 10px;
    position: relative;
    background-color: ${props => (props.corSecao ? props.corSecao : '#FFF' )};

    margin-top: 55px;
    margin-bottom: 55px;

    padding: 30px 34px 30px 34px;

    @media screen and (max-width: ${ResizeMediumWidth}) {
        width: 300px;
        padding-left: 28px;
        padding-right: 28px;
    }

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        width: 263.55px;
        height: 467px;
    }
`;

export const StatementCarouselCardStyle = styled.div<{ corSecao?: string }>`
    display: flex;
    flex-direction: column;
    background: ${Colors.White};
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    width: 390px;
    height: 445px;
    gap: 10px;

    margin-top: 55px;
    margin-bottom: 55px;

    padding: 30px 34px 30px 34px;

    @media screen and (max-width: ${ResizeMediumWidth}) {
        width: 300px;
        padding-left: 28px;
        padding-right: 28px;
    }

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        width: 263.55px;
        height: 467px;
    }
`;

export const ProfileContainer = styled.div`
    display: flex;
    height: 40%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    min-height: 35%;
    max-height: 50%;
`;

export const DescriptionText = styled.p<{ cor?: string, tamanho?: number }>`
    font-size: ${props => (props.tamanho ? props.tamanho : 14 )};
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1px;
    color: ${props => (props.cor ? props.cor : Colors.DarkGrey )};

    word-wrap: break-word;
    max-width: 100%;

    overflow-y: auto;

    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 12px;
        box-sizing: content-box;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d8d8d8; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        margin: 10px;
        border: 4px solid white;
        box-sizing: content-box;
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
    }

    @media screen and (max-width: ${ResizeMediumWidth}) {
        line-height: 24px;
    }

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        line-height: 24px;
    }
`;

export const IdenficationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    width: 100%;
`;

export const NameText = styled.p`
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1px;
    color: ${Colors.DarkGrey};

    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FunctionText = styled.p`
    color: ${Colors.Blue};
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.1px;

    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const AvatarSvg = styled.img`
    max-width: 150px;
    max-height: 100px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.2);
`;

export const ImageComponent = styled.img`
    max-width: 200px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.2);
`;

export const ButtonContainer = styled.div(
    new FullCss(
        {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '16%',
            paddingTop: '10px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            height: '12%',
        })
    ).getCss()
);
export const HumaneButton = styled.button(
    new FullCss(
        {
            background: Colors.Blue,
            borderRadius: '50px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.3px',
            color: Colors.White,
            maxWidth: '193px',
            maxHeight: '49px',
            width: '100%',
            height: '100%',
            border: `1px solid ${Colors.Blue}`,
        },
        [
            new MediaQuery(':hover', {
                background: Colors.DarkBlue3,
                color: Colors.White,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
                maxWidth: '164px',
                maxHeight: '37px',
            }),
        ]
    ).getCss()
);
