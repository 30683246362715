import { ICategoriaSolucoes } from "pages/acessoExclusivo/components/Categoria/CardCategoria";
import { GenericService } from "../Generic.service";



export class CategoriaSolucoesPEService extends GenericService {
    readonly URI: string = '/v1/categoriaSolucoesPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public saveAll(regras: ICategoriaSolucoes[]) {
        return this.post('', regras)
    }

    public deleteCS(data: ICategoriaSolucoes) {
        return this.deleteByBody('', data)
    }
    public getSolucoesByUser() {
        return this.get('solucoes',)
    }
}