import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const CarouselMultimidiaCardContainer = styled.div<{ length?: number }>`
    width: 100%;
    margin-top: 90px;
    padding: 10px;
    //transform: translateX(-38%);

    .slick-slide {
        display: flex;
        justify-content: ${length < 3 ? 'center' : 'start'};
        align-items: center;
    }

    .activeSlide {
        position: relative;
        z-index: 1000;
    }

    .slick-list {
        padding: 0px;
    }

    @media screen and (min-width: 1367px) {
        display: inline-block;
    }

    @media screen and (max-width: 760px) {
        margin-top: 0px;
    }

    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        max-width: 1366px;

        .slide {
            transform: scale(0.7);
            transition: transform 500ms;
            opacity: 0.4;
        }

        .activeSlide {
            transform: scale(1.1);
            opacity: 1;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        padding: 0px 0 10px 0;
    }
`;

const StyledCarrosselMultipleCard = {
    Container: styled.div`
        width: 450px;
        margin: 20px;
        margin-bottom: 50px;

        @media screen and (max-width: 450px) {
            width: 325px;
        }

        @media screen and (max-width: 300px) {
            width: 270px;
        }
    `,

    Content: styled.div`
        height: 300px;
    `,

    Img: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    `,

    Video: {
        Container: styled.div`
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
        `,

        File: styled.img`
            width: 100%;
            height: 100%;
            border-radius: 10px;
        `,

        Play: styled.div`
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            border-radius: 100%;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            transition: all 0.5s ease;

            &:hover {
                background-color: rgba(0, 0, 0, 0.7);
            }
        `,
    },

    Detail: styled.p<{ color?: string; size?: string }>`
        margin: 15px;
        text-align: center;
        line-height: 20px;
        color: ${({ color }) => (color ? color : '')};
        font-size: ${({ size }) => (size ? size + 'px' : '16px')};
    `,
};

export default StyledCarrosselMultipleCard;
