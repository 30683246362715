import React, { useEffect, useRef, useState } from 'react';

import BannerContainer from './banner/BannerContainer';
import ContactContainer from './contact/ContactContainer';
import { MobileMaxWidth } from '../../../constants/Dimensions';
import ObjectivesContainer from './objectives/ObjectivesContainer';
import StatementsContainer from './statements/StatementsContainer';
import EducationalSolutionsContainer from './educationalSolutions/EducationalSolutionsContainer';

import styled from 'styled-components';
import BibliotecaBanner from './biblioBanner/biblioBanner';
import SpaceTeacher from './spaceTeacher/SpaceTeacher';
import OtherResources from './otherResources/OtherResources';
import { useKeycloak } from '@react-keycloak/web';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import HeadInfo from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import HomePageHeader from './header/HomePageHeaderContainer';
import Dashboard from 'pages/painelSocioemocional/home/components/Dashboard/Dashboard';
import UsuarioDTO from 'models/UsuarioDTO';
import SuporteContainer from './FAQ/SuporteContainer';
import TematicasContainer from './tematicas/TematicasContainer';
import { ToasterDefault, ToastError } from 'components/Toaster';
import CorouselHome from './homeV2/carousel/CarouselHome';
import CarouselSolucoes from './homeV2/solucoes/CarouselSolucoes';
import MultiSolucoes from 'pages/solucoes/components/solucoes/MultiSolucoes';
import Colors from 'enums/Colors';
import TematicasHome from './homeV2/tematicas/TematicasHome';
import CoursesHome from './homeV2/courses/CoursesHome';
import JourneyHome from './homeV2/journeys/JourneysHome';
import BibliotecaHome from './homeV2/biblioteca/BibliotecaHome';
import DepoimentsHome from './homeV2/depoiments/DepoimentsHome';
import Support from './homeV2/support/Support';
import { useLocation } from 'react-router-dom';

const MainStyle = styled.div`
    position: relative;
    background-color: #f5f5f5;
`;

const Main = () => {
    const { keycloak } = useKeycloak();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const serachparams = new URLSearchParams(location.search);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const [loading, setLoading] = useState<boolean>(true);
    const targetDivRef = useRef<HTMLDivElement>(null);
    const targetDivRefSolucoes = useRef<HTMLDivElement>(null);
    const targetDivRefTematicas = useRef<HTMLDivElement>(null);
    const targetDivRefCursos = useRef<HTMLDivElement>(null);
    const targetDivRefJornadas = useRef<HTMLDivElement>(null);
    const targetDivRefBiblioteca = useRef<HTMLDivElement>(null);

 

    const loadData = async () => {
        try {
            setLoading(true);
            if (keycloak.authenticated) {
                await fetchUserInfo(userProfile?.username ?? usuarioLogado.username ?? '', userProfile?.codigo || -1).then(
                    (perfilData: ProfileWithAvatar) => {
                        setProfile(perfilData);
                    }
                );
            }
        } catch (err: any) {
            console.log('err, fetch user info');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const yOffset = -100; // ajuste de deslocamento em pixels
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const linkParam = serachparams.get('link');
        // setLinkParams(linkParam ?? '');

        if (linkParam === 'jornadas') {
            scrollToSection(targetDivRefJornadas)
        }
    }, [serachparams]);

    return (
        <MainStyle>
            {keycloak.authenticated && !loading && <HomePageHeader />}

            <CorouselHome
                listTarget={[targetDivRefSolucoes, targetDivRefTematicas, targetDivRefCursos, targetDivRefJornadas, targetDivRefBiblioteca]}
                onChangeTarget={scrollToSection}
            />
            <div ref={targetDivRefSolucoes}>
                <CarouselSolucoes />
            </div>
            <div ref={targetDivRefTematicas}>
                <TematicasHome />
            </div>
            <div ref={targetDivRefCursos}>
                <CoursesHome />
            </div>
            <div ref={targetDivRefJornadas}>
                <JourneyHome />
            </div>
            <div ref={targetDivRefBiblioteca}>
                <BibliotecaHome />
            </div>
            <DepoimentsHome />
            <Support />
            <ContactContainer />
            {ToasterDefault()}
        </MainStyle>
    );
};

export default Main;
