import React, { useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import closeIcon from '../../../../../images/close-circle.svg';
import Select from 'components/Select';
import { GradientButton } from 'components/UI/Buttons';
import motivations from './wordsBlockMotivations';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';

export interface IAdminModalNewWord {
    show: boolean;
    onHide: () => void;
    onSaved: () => void;
}

const AdminModalNewWord = ({ show, onHide, onSaved }: IAdminModalNewWord) => {
    const [word, setWord] = useState('');
    const [motivation, setMotivation] = useState('');
    const [variations, setVariations] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSave = async () => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            let variacao: string[] = [];

            if (variations)
                variacao = variations
                    .replace(/\s+/g, ' ')
                    .split(/[\s,;]+/)
                    .filter(s => s.trim().length > 0);

            const service = new AdminBiblioService();
            await service.criarPalavraProibida(word, variacao ?? [], motivation);

            onSaved();
            clearData();
            setIsSubmitting(false);
        }
    };

    const clearData = () => {
        setWord('');
        setMotivation('');
        setVariations('');
    };

    return (
        <Styled.Modal
            centered
            show={show}
            onHide={() => {
                clearData();
                onHide();
            }}
        >
            <Styled.Container style={{ padding: '10px 30px' }}>
                <Styled.CloseContainer>
                    <img src={closeIcon} onClick={onHide} />
                </Styled.CloseContainer>

                <Styled.ModalEditTitle>Nova palavra proibida</Styled.ModalEditTitle>
                <Styled.Text>Escreva um nova palavra que será banida na plataforma</Styled.Text>
                <Styled.Input value={word} onChange={(e: any) => setWord(e.target.value)} />
                <br />
                <Styled.Text>Escreva outras formas que essa palavra pode ser escrita</Styled.Text>
                <Styled.Input value={variations} onChange={(e: any) => setVariations(e.target.value)} />
                <br />
                <Select itens={motivations} placeholder={'Motivação'} onSelected={option => setMotivation(String(option.value))} />
                <Styled.RightContainer>
                    <GradientButton disabled={word.trim().length == 0 || isSubmitting} onClick={onSave}>
                        {isSubmitting ? 'Salvando...' : 'Salvar'}
                    </GradientButton>
                </Styled.RightContainer>
            </Styled.Container>
        </Styled.Modal>
    );
};

export default AdminModalNewWord;
