import styled from 'styled-components';

const StyledBarProgress = {
    Barra: styled.div`
        background-color: #cbd2e0;
        width: 100%;
        height: 25px;
        border-radius: 20px;
    `,

    BarraActive: styled.div<{ progress: number }>`
        width: ${props => props.progress == 0 ? 97 : props.progress}%;
        height: 100%;
        background-color:${props => props.progress == 0 ? '#cbd2e0' : '#003c78'};
        box-sizing: content-box;
        border-radius: ${props => props.progress < 97 ? '20px 0 0 20px' : '20px 20px 20px 20px'};
        color: ${props => props.progress == 0 ? 'black' : 'white'};
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
    `,
};

export default StyledBarProgress;
