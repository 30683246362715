import React from 'react';
import Styled from './BannerCSE.styled';
import StyledGlobal from '../../CompetenciasSocioemocionais.styled';
import Img from '../../assets/img_banner.svg';
import { MdArrowForward } from 'react-icons/md';
interface IBannerCSE {
    isMobile: boolean;
    onClickSaibaMais: () => void;
}

const BannerCSE = ({ isMobile, onClickSaibaMais }: IBannerCSE) => {
    return (
        <Styled.Container>
            <Styled.Content>
                <StyledGlobal.Title isMobile={isMobile}>COMPETÊNCIAS SOCIOEMOCIONAIS </StyledGlobal.Title>
                <StyledGlobal.SubTitle maxWidth={ isMobile ? '400px' : '600px'} marginBottom='50px'>
                    Confira conceitos, materiais e formações para desenvolver as competências socioemocionais no contexto escolar e como elas
                    podem potencializar os resultados de vida dos estudantes 
                </StyledGlobal.SubTitle>
                <StyledGlobal.Button  onClick={onClickSaibaMais} isMobile={isMobile}>
                    Saiba mais <MdArrowForward size={24} />
                </StyledGlobal.Button>
            </Styled.Content>
            <Styled.ImgBanner src={Img} />
        </Styled.Container>
    );
};

export default BannerCSE;
