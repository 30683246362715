import { SelectOptionType } from 'components/Select';

const motivations: SelectOptionType[] = [
    {
        value: 'OFENSIVO',
        label: 'Ofensiva - Palavras chulas, “palavrões” e xingamentos.',
    },
    {
        value: 'SEXUAL',
        label: 'Conotação sexual - Palavras que sugere sexo, nudez e pornografia.',
    },
    {
        value: 'DISCURSO_ODIO',
        label: 'Discurso de ódio - Palavras que atacam e incitam ódio contra determinados grupos sociais baseadas em raça, etnia, gênero, orientação sexual, religiosa ou origem nacional.',
    },
    {
        value: 'ILEGAL',
        label: 'Ilegal - Palavras que remetam à atividades ilegais no país.',
    },
    {
        value: 'OUTROS',
        label: 'Outros - Qualquer palavra que não se enquadra em nenhuma categoria acima. ',
    },
];

export default motivations;
