import { min } from 'lodash';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Colors from '../../enums/Colors';

const QuestionIndicatorContainer = styled.div({
    minWidth: '37px',
    height: '37px',
    background: 'rgba(83, 173, 34, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
});

const ValueContainer = styled.div({
    color: Colors.DarkBlue,
    fontSize: '18px',
    lineheight: '24px',
    fontWeight: 600,
});

interface IQuestionIndicator {
    value: number;
}
const QuestionIndicator: FunctionComponent<IQuestionIndicator> = ({ ...props }: IQuestionIndicator) => {
    return (
        <QuestionIndicatorContainer>
            <ValueContainer>{props.value}</ValueContainer>
        </QuestionIndicatorContainer>
    );
};
export default QuestionIndicator;
