import React from 'react';
import Styled from './BackgroundComp.styled';

export interface IBackground {
    colorBackground: string | null;
    setColor: (value: string) => void;
    colorTitle: string;
    colorDescription: string;
}

const BackgroundComponent = ({ colorBackground, colorTitle, colorDescription, setColor }: IBackground) => {
    return (
        <Styled.Container>
            <Styled.Label>Cor de fundo:</Styled.Label>
            <Styled.CardColor colorBack={colorBackground}>
                <Styled.Title color={colorTitle}>Título da seção</Styled.Title>
                <Styled.Description color={colorDescription}>Texto da seção</Styled.Description>
            </Styled.CardColor>
            <Styled.ColorPicker type="color" value={colorBackground ?? '#FFFFF'} onChange={e => setColor(e.target.value)} />
        </Styled.Container>
    );
};

export default BackgroundComponent;
