import styled from 'styled-components';

namespace TrilhaFormativaModalStyles {
    export const TrilhaFormativaContainer = styled.div<{ isMobile: boolean }>`
        display: flex;
        align-items: center;
        flex-direction: column;
        height: ${props => (props.isMobile ? 'auto' : 'auto')};
        padding: 20px;
    `;

    export const Title = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: #003c78;
    `;

    export const Description = styled.p<{ isMobile: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: ${props => (props.isMobile ? '24px' : 'clamp(12px, 1.8vw, 20px)')};
        line-height: 140%;
        color: #003c78;
        text-align: center;
        padding-top: 10px;
    `;

    export const ButtonContainer = styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${props => (props.isMobile ? 'column-reverse' : 'row')};
        gap: 1rem;
        padding-top: 20px;
        width: ${props => props.isMobile && '300px'};
    `;

    export const ButtonFechar = styled.button`
        border: 2px solid #003c78;
        border-radius: 6px;
        background-color: transparent;
        padding: 16px 24px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #003c78;

        &:hover {
            color: #fcfcfc;
            background: #003c78;
        }
    `;

    export const ButtonMore = styled.button<{ isTrilha: boolean }>`
        border: 2px solid #003c78;
        background: #003c78;
        border-radius: 6px;
        padding: ${props => (props.isTrilha ? '16px 15px' : '6px')};
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        color: #fcfcfc;
        height: 60px;

        &:hover {
            color: #003c78;
            background: transparent;
        }
    `;
}

export default TrilhaFormativaModalStyles;
