export function base64XLSToFile(base64Content: string, filename: string): void {
    const memtype = 'xls';

    const linkSource = `data:${memtype};base64,${base64Content}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
}

export function base64PDFToFile(base64Content: string, filename: string): void {
    const memtype = 'pdf';

    const linkSource = `data:${memtype};base64,${base64Content}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
}
