import { YellowButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const StyledCompetenciasSocioemocionais = {
    Container: styled.div`
        margin-top: 90px;
    `,

    Title: styled.h1<{ isMobile: boolean }>`
        color: #003c78;
        font-size: ${({ isMobile }) => (isMobile ? '24px' : '34px')};
        font-style: normal;
        font-weight: 600;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        margin-bottom: 24px;
        font-family: Barlow;
    `,

    SubTitle: styled.p<{ maxWidth?: string; marginBottom?: string }>`
        font-size: 16px;
        font-weight: 400;
        max-width: ${({maxWidth}) => maxWidth ? maxWidth : 'auto'};
        line-height: 140.5%;
        letter-spacing: 0.25px;
        font-family: Barlow;
        padding-bottom: ${({ marginBottom }) => marginBottom && marginBottom};

       
    `,

    Button: styled(YellowButton)<{ isMobile: boolean; marginTop?: string }>`
        display: flex;
        align-items: center;
        margin-top: ${({ marginTop }) => (marginTop ? marginTop : '24px')};
        gap: 0.5rem;
        width: 200px;
        height: ${({ isMobile }) => (isMobile ? '40px' : '50px')};
        max-height: 50px;
        font-size: ${({ isMobile }) => (isMobile ? '14px' : '18px')};
        padding: 0px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.46px;
    `,
};

export default StyledCompetenciasSocioemocionais;
