import { GenericService } from "../Generic.service";


export class ComponentItemCarrosselService extends GenericService {
    readonly URI: string = '/v1/componentItemCarrossel';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public deleteById(idItem: number) {
        return this.delete(idItem);
    }
}