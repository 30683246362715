import React from 'react';

import styled from 'styled-components';

import Colors from '../../../../../enums/Colors';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';

interface ITextDescricao {
    tipo: TipoImagem;
}

const Text = styled.p({
    color: Colors.BlueGrey,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '28px',
    maxWidth: 492,
    marginTop: 20,
    userSelect: 'none',
});

const textImage =
    'Agora é só você selecionar uma foto que queira usar no seu perfil! Lembre de escolher uma foto com boa resolução. A resolução máxima é de 1920 x 1920 px e até 2MB de tamanho.';
const textAvatar = 'Agora é só você selecionar o avatar que quer usar no seu perfil!';

const TextDescricao = ({ tipo }: ITextDescricao) => {
    let content = '';

    if (tipo === TipoImagem.Imagem) {
        content = textImage;
    } else if (tipo === TipoImagem.Avatar) {
        content = textAvatar;
    }

    return <Text>{content}</Text>;
};

export default TextDescricao;
