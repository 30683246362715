import React from 'react';
import { Alert } from 'react-bootstrap';

export interface IBaloonAlert {}

const BaloonAlert = ({}: IBaloonAlert) => {
    return (
        <Alert variant="warning" style={{ fontSize: '0.85em', lineHeight: '18px' }}>
            💡 Para uma melhor experiência, recomendamos que preencha sua autoavaliação no computador e não utilize o celular.
        </Alert>
    );
};

export default BaloonAlert;
