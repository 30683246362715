import { jsonProperty, Serializable } from "ts-serializable";



export class CertificacoesModel extends Serializable {
    
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(String)
    nome: string = '';

    @jsonProperty(String, null)
    textoSolicitacao: string | null = null;

    @jsonProperty(Boolean)
    precisaSolicitar: boolean = true;

    @jsonProperty(String, null)
    link: string | null = null;

}