import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';

import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { isStringsValid, isValidLink } from './isValidNewContentInput';
import { Form } from 'react-bootstrap';
import AdminSecaoPaginaData, { AdminSecaoPaginaBanner } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import { AdminGestaoPaginasMode } from 'pages/gestao_paginas/AdminGestaoPaginasContextWrapper';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import Select from 'components/Select';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';
import ActionComponent from './components/ActionComponent';
import SelectedButtons from './components/SelectedButtons';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import BackgroundComponent from './components/BackgroundComponent';
import { LabelObs, LabelRedirecionamento } from './components/LabelObs';

export interface IAdminEditSection {
    onSaved: (secao: AdminSecaoPaginaData) => void;
    onUpdate: (secao: AdminSecaoPaginaData) => void;
    onDelete: () => void;
    mode?: AdminGestaoPaginasMode;
    custom?: boolean;
}

const AdminEditBanner = ({ onSaved, mode, onUpdate, onDelete }: IAdminEditSection) => {
    const history = useHistory();
    const params: any = useParams();
    const [cover, setCover] = useState<string | null>(null);
    const [idSecao, setIdSecao] = useState<number>(0);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [link_interno, setLinkInterno] = useState('');
    const [corTitle, setCorTitle] = useState('#000000');
    const [corDescription, setCorDescription] = useState('#000000');
    const [corDeFundo, setCorDeFundo] = useState('#FFFFFF');
    const [typeButton, setTypeButton] = useState<ButtonsCustom>(ButtonsCustom.Button_01);
    const [paginaLateral, setPaginaLateral] = useState(false);
    const [tipoRedirecionamento, setTipoRedirecionamento] = useState<TipoRedirecionamento>(TipoRedirecionamento.LOGIN);
    const [loading, setloading] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const service = new AdminGestaoPaginaService();

    const MAX_CHAR_CONTENT = 1000;

    const { currentSection } = useContext(AdminGestaoPaginasContext.Context);

    const isValidInputs = (): boolean => {
        if (!cover) return false;
        return isStringsValid([title, text, texto_botao_a, link_interno]) && isValidLink(link_interno, !link_interno.startsWith('/'));
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaBanner) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            setModePage(true);
            onSaved(section);
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaBanner) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarBanner(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    useEffect(() => {
        console.log('🚀 ~ file: AdminEditBanner.tsx:104 ~ AdminEditBanner ~ typeButton:', typeButton);
    }, [typeButton]);

    const submit = async () => {
        if (!currentSection) {
            alert('Seção não encontrada');
            return;
        }

        setSubmitting(true);

        const holder = currentSection?.modelData as AdminSecaoPaginaBanner;

        holder.titulo = title;
        holder.descricao = text;
        holder.textoBotaoA = texto_botao_a;
        holder.estiloBotaoA = typeButton;
        holder.corTitulo = corTitle;
        holder.corTexto = corDescription;
        holder.corFundo = corDeFundo;
        holder.link = link_interno;
        holder.paginaLateral = paginaLateral;
        holder.anexoBase64 = cover;
        holder.tipoRedirecionamento = tipoRedirecionamento;

        if (mode == 'NEW') {
            await saveSection(currentSection, holder);
        } else {
            await updateSection(currentSection, holder);
        }

        setSubmitting(false);
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setloading(true);
            const id = String(params.id);
            const { data } = await service.buscaSecaoBannerPorId(parseInt(id));
            setIdSecao(parseInt(id));
            setText(data.descricao);
            setTitle(data.titulo);
            setPaginaLateral(data.paginaLateral);
            setLinkInterno(data.link);
            setTextoBotaoA(data.textoBotaoA);
            setTypeButton(data.estiloBotaoA);
            setCorTitle(data.corTitulo);
            setCorDescription(data.corTexto);
            setCorDeFundo(data.corFundo);
            setCover(data.anexoBase64);
            setTipoRedirecionamento(data.tipoRedirecionamento);
            setloading(false);
            setModePage(true);
        }
    };

    useEffect(() => {
        fetchItem();
    }, []);

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteBanner(idSecao);

            if (response.status === 200) {
                ToastSuccess('Banner excluído com sucesso!');
                setShowModalDeleteSecao(false);
                setIsLoadingDelete(false);
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
    };

    return (
        <Styled.Container>
            <ModalExclusao
                showModal={showModalDeleteSecao}
                handleHiden={setShowModalDeleteSecao}
                handleExcluxed={handleDelete}
                isLoading={isLoadingDelete}
            />
            {!loading ? (
                <>
                    <div>
                        <AdminImageUploader
                            onSelect={() => {}}
                            setBase64={b => setCover(b)}
                            size={'lg'}
                            title={'Imagem do topo'}
                            preImageB64={cover}
                            recommendedDimension={!paginaLateral ? { w: 1524, h: 660 } : { w: 439, h: 470 }}
                        />

                        {paginaLateral && (
                            <BackgroundComponent
                                colorBackground={corDeFundo}
                                colorDescription={corDescription}
                                colorTitle={corTitle}
                                setColor={setCorDeFundo}
                            />
                        )}

                        <Styled.Label>Layout banner</Styled.Label>
                        <Styled.CkeckContainer>
                            <Form.Check
                                id="radio-banner-lateral"
                                checked={paginaLateral}
                                type="radio"
                                aria-label="radio 1"
                                onChange={() => setPaginaLateral(true)}
                            />
                            <label htmlFor={`radio-banner-lateral`} style={{ marginRight: '10px' }}>
                                Lateral
                            </label>
                            <Form.Check
                                id="radio-interno-completo"
                                checked={!paginaLateral}
                                type="radio"
                                aria-label="radio 2"
                                onChange={() => setPaginaLateral(false)}
                            />
                            <label htmlFor={`radio-interno-completo`}>Completo</label>
                        </Styled.CkeckContainer>

                        <Styled.Label>Título</Styled.Label>
                        <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                        <ActionComponent
                            name={'titulo'}
                            valueColorText={corTitle}
                            setColorText={setCorTitle}
                            notVisibleOpcaoOculto
                            notVisibleSizeFonte
                        />

                        <Styled.Label>Texto</Styled.Label>
                        <Styled.Input
                            value={text}
                            onChange={(evn: any) => setText(evn.target.value)}
                            rows={8}
                            as="textarea"
                            className={'form-control'}
                            maxLength={MAX_CHAR_CONTENT}
                        />
                        <Styled.CountLetter danger={text.length >= MAX_CHAR_CONTENT}>
                            {text.length}/{MAX_CHAR_CONTENT}
                        </Styled.CountLetter>
                        <ActionComponent
                            name={'description'}
                            valueColorText={corDescription}
                            setColorText={setCorDescription}
                            notVisibleOpcaoOculto
                            notVisibleSizeFonte
                        />

                        <Styled.Label>Texto Botão</Styled.Label>
                        <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />

                        {/*<Styled.Label>Texto Botão</Styled.Label>
                        <Styled.Input value={texto_botao_b} onChange={(evt: any) => setTextoBotaoB(evt.target.value)} />*/}

                        <Styled.Label>Link Botão</Styled.Label>
                        <Styled.Input
                            className={link_interno.length > 0 && !isValidLink(link_interno, !link_interno.startsWith('/')) && 'is-invalid'}
                            value={link_interno}
                            onChange={(evt: any) => setLinkInterno(evt.target.value)}
                        />
                        <Styled.SubLabel>
                            <LabelRedirecionamento />
                        </Styled.SubLabel>
                        <Styled.Label>Aparência Do Botão</Styled.Label>
                        <SelectedButtons value={typeButton} setSelected={setTypeButton} />

                        <Styled.Label>Tipo de redirecionamento</Styled.Label>
                        <Select
                            isSmall
                            value={getOptionFromValue(tipoRedirecionamento)}
                            itens={tipoRedirecionamentoOptions}
                            onSelected={o => setTipoRedirecionamento(o.value as TipoRedirecionamento)}
                        />
                        <Styled.SubLabel>
                            <LabelObs />
                        </Styled.SubLabel>
                    </div>

                    <Styled.RigthArea>
                        {modePage && (
                            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                <RedSolidButton style={{ marginBottom: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                    Deletar
                                </RedSolidButton>
                            </AllowedGroupsValidator>
                        )}
                        <GradientButton disabled={submitting || !isValidInputs()} onClick={submit}>
                            {submitting ? 'Salvando...' : 'Salvar'}
                        </GradientButton>
                    </Styled.RigthArea>
                </>
            ) : (
                <Loading />
            )}
        </Styled.Container>
    );
};

export default AdminEditBanner;
