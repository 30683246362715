import React from 'react';
import Styled from './AdminModalShow.styled';

import closeIcon from '../../../../images/close-circle.svg';

export interface IAdminModalShow {
    show: boolean;
    onHide: () => void;
}

const AdminModalShow = ({ show, children, onHide }: React.PropsWithChildren<IAdminModalShow>) => {
    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            <Styled.Container>
                <Styled.CloseContainer>
                    <Styled.CloseIcon src={closeIcon} onClick={onHide} />
                </Styled.CloseContainer>

                <Styled.Content>{children}</Styled.Content>
            </Styled.Container>
        </Styled.Modal>
    );
};

export default AdminModalShow;
