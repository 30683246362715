import React, { useEffect, useState, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import AdminBiblioAvaliacao from './rate/AdminBiblioAvaliacao';
import AdminBiblioPostagem from './post/AdminBiblioPostagem';
import AdminBiblioTags from './tags/AdminBiblioTags';
import Styled from './AdminBiblioteca.styled';
import AdminBiblioCategorias from './categorias/AdminBiblioCategorias';
import AdminBiblioPalavrasProibidas from './wordsBlock/AdminBiblioPalavrasProibidas';
import { AdminContext } from 'pages/admin/context/AdminContext';
import styled from 'styled-components';
import AdminBiblioNewContent from './post/AdminBiblioNewContent';
import AdminBiblioEditContent from './post/AdminBiblioEditContent';

export interface IAdminBiblioteca {}

enum AdminBibliotecaTab {
    POSTAGENS,
    AVALIACAO,
    TAGS,
    PALAVRASPROIBIDAS,
    CATEGORIAS,
}

const AdminBiblioteca = ({}: IAdminBiblioteca) => {
    const [selectedTab, setSelectedTab] = useState(AdminBibliotecaTab.POSTAGENS);
    const { subPage, setSubPage } = useContext(AdminContext.Context);
    const history = useHistory();
    const Tabs = AdminBibliotecaTab;

    const gotoSubroute = (sub: string) => {
        history.push(sub);
    };

    const switchTab = (tab: AdminBibliotecaTab) => {
        if (tab == Tabs.POSTAGENS && subPage != null) {
            gotoSubroute('/admin/biblioteca/posts');
            setSubPage(null);
        } else if (tab == Tabs.AVALIACAO) {
            gotoSubroute('/admin/biblioteca/avaliacoes');
            setSubPage('BiblioAvaliacoes');
        } else if (tab == Tabs.TAGS) {
            gotoSubroute('/admin/biblioteca/tags');
            setSubPage('BiblioTas');
        } else if (tab == Tabs.PALAVRASPROIBIDAS) {
            gotoSubroute('/admin/biblioteca/wordsblock');
            setSubPage('BiblioWords');
        } else if (tab == Tabs.CATEGORIAS) {
            gotoSubroute('/admin/biblioteca/categorias');
            setSubPage('BiblioCat');
        }

        setSelectedTab(tab);
    };

    useEffect(() => {
        if (subPage === null) gotoSubroute('/admin/biblioteca/posts');
    }, [subPage]);

    // Espaço horizontal esquerdo (Isso serve para o menu lateral não ficar por cima do conteúdo).
    const SpaceLeft = styled.div`
        margin-left: 100px;
    `;

    return (
        <SpaceLeft>
            <Styled.Container>
                <Styled.TopContainer>
                    <Styled.TopTitle>biblioteca</Styled.TopTitle>
                    <br />
                    <Styled.Row>
                        <Styled.Tab selected={subPage === null} onClick={() => switchTab(Tabs.POSTAGENS)}>
                            Postagens
                        </Styled.Tab>
                        <Styled.Tab selected={subPage === 'BiblioAvaliacoes'} onClick={() => switchTab(Tabs.AVALIACAO)}>
                            Avaliações
                        </Styled.Tab>
                        <Styled.Tab selected={subPage === 'BiblioTas'} onClick={() => switchTab(Tabs.TAGS)}>
                            Gestão de Tags
                        </Styled.Tab>
                        <Styled.Tab selected={subPage === 'BiblioWords'} onClick={() => switchTab(Tabs.PALAVRASPROIBIDAS)}>
                            Gestão de Palavras Proibidas
                        </Styled.Tab>
                        <Styled.Tab selected={subPage === 'BiblioCat'} onClick={() => switchTab(Tabs.CATEGORIAS)}>
                            Categorias
                        </Styled.Tab>
                    </Styled.Row>
                </Styled.TopContainer>

                <br />

                <Styled.Content>
                    <Route exact path="/admin/biblioteca/posts" component={AdminBiblioPostagem} />
                    <Route exact path="/admin/biblioteca/avaliacoes" component={AdminBiblioAvaliacao} />
                    <Route exact path="/admin/biblioteca/tags" component={AdminBiblioTags} />
                    <Route exact path="/admin/biblioteca/wordsblock" component={AdminBiblioPalavrasProibidas} />
                    <Route exact path="/admin/biblioteca/categorias" component={AdminBiblioCategorias} />
                    <Route exact path="/admin/biblioteca/novo" component={AdminBiblioNewContent} />
                    <Route exact path="/admin/biblioteca/edit/:id" component={AdminBiblioEditContent} />
                </Styled.Content>
            </Styled.Container>
        </SpaceLeft>
    );
};

export default AdminBiblioteca;
