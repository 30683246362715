import { TipoAnexoConteudo } from './TipoAnexoConteudo';
import { jsonProperty, Serializable } from "ts-serializable";

export class ConteudoFavoritoCostumizado extends Serializable {
  @jsonProperty(Number, null)
  codigoConteudo: number | null = -1;

  @jsonProperty(Number, null)
  codigoEtapa?: number | null = -1;

  @jsonProperty(Number, null)
  codigoItem?: number | null = -1;

  @jsonProperty(String, null)
  nomeConteudo: string = '';

  @jsonProperty(String, null)
  urlConteudo: string = '';

  @jsonProperty(String, null)
  urlEtapa: string = '';

  @jsonProperty(String, null)
  urlItem: string = '';

  @jsonProperty(String, null)
  urlRecurso: string = '';

  @jsonProperty(Number, null)
  codigoRecurso?: number | null = -1;

 
}
