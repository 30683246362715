import Loading from 'components/Loading';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { OutlineButton } from 'components/UI/Buttons';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { CursoLivreService } from 'core/http/service/CursoLivre.service';
import { ItemBibliotecaConteudoRelacionadoService } from 'core/http/service/ItemBibliotecaConteudoRelacionado.service';
import { FetchUtils } from 'core/util/FetchUtils';
import Colors from 'enums/Colors';
import { cloneDeep } from 'lodash';
import ItemBiblitoecaConteudoRelacionado from 'models/admin/ItemBibliotecaConteudoRelacionado';
import BiblioItem from 'models/biblioteca/BiblioItem';
import ConteudoRelacionadoInternoResumido from 'models/ConteudoRelacionadoInternoResumido';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styled from 'styled-components';
import ItemBiliotecaRelatedContentDialog from '../ItemBibliotecaRelatedContentDialog/ItemBibliotecaRelatedContentDialog';

const Styled = {
    ItemBibliotecaRelatedContentListContainer: styled.div``,
    SectionTitle: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,
    BottomButton: styled.div`
        padding: 10px 0px;
    `,

    CountLetter: styled.p<{ danger: boolean }>`
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: ${props => (props.danger ? 'red' : '#747474')};
        text-align: right;
    `,

    ListItem: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${Colors.DarkBlue};
        padding: 5px;
        max-width: 350px;
        border-radius: 7px;
        color: ${Colors.DarkBlue};
        font-weight: bold;
        &:hover {
            cursor: pointer;
            color: ${Colors.White};
            background-color: ${Colors.DarkBlue};
        }
    `,
};

interface IItemBibliotecaRelatedContentList {
    itemData?: BiblioItem | null;
}

const ItemBibliotecaRelatedContentListagem: FunctionComponent<IItemBibliotecaRelatedContentList> = ({
    ...props
}: IItemBibliotecaRelatedContentList) => {
    const [conteudosRelacionados, setConteudosRelacionados] = useState<ItemBiblitoecaConteudoRelacionado[]>([]);
    const [showRelatedContentModal, setShowRelatedContentModal] = useState<boolean>(false);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(true);
    const [selectedRelatedContent, setSelectedRelatedContent] = useState<ItemBiblitoecaConteudoRelacionado | null>(null);
    const [selectedRelatedContentIdx, setSelectedRelatedContentIdx] = useState<number | null>(null);

    const [cursosAtivos, setCursosAtivos] = useState<ConteudoRelacionadoInternoResumido[]>([]);
    const [itensBiblioPublicados, setItensBiblioPublicados] = useState<ConteudoRelacionadoInternoResumido[]>([]);

    const conteudosService = new ItemBibliotecaConteudoRelacionadoService();
    const cursosService = new CursoLivreService();
    const biblioService = new BiblioConteudoService();

    const addConteudoRelacionado = () => {
        if (props.itemData?.id) {
            setShowRelatedContentModal(true);
        } else {
            ToastError('É necessário salvar ou publicar antes de adicionar conteúdos relacionados');
        }
    };

    const editRelatedContent = (idx: number) => {
        setSelectedRelatedContent(conteudosRelacionados[idx]);
        setSelectedRelatedContentIdx(idx);
        setShowRelatedContentModal(true);
    };

    const loadRelatedContent = async () => {
        if (props.itemData?.id) {
            const responseRelatedContent: any = await conteudosService.listaPorItem(props.itemData.id);
            let output = responseRelatedContent.data.map((item: any) => new ItemBiblitoecaConteudoRelacionado().fromJSON(item));
            setConteudosRelacionados(output);
        }
    };

    const loadData = async () => {
        await loadRelatedContent();
        await loadCoursesAndLibraryItens();
    };

    const loadCoursesAndLibraryItens = async () => {
        const responseCourses: any = await cursosService.listarAtivosResumido();
        let outputCourses = responseCourses.data.map((item: any) => new ConteudoRelacionadoInternoResumido().fromJSON(item));
        setCursosAtivos(outputCourses);

        const responseLibraryItens: any = await biblioService.listarPublicoResumido();
        let outputLibraryItens = responseLibraryItens.data.map((item: any) => new ConteudoRelacionadoInternoResumido().fromJSON(item));
        setItensBiblioPublicados(outputLibraryItens);
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, loadData);
    }, []);

    return (
        <Styled.ItemBibliotecaRelatedContentListContainer>
            <Styled.SectionTitle>Conteúdos Relacionados</Styled.SectionTitle>

            {!loadingRelatedContent ? (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '30px', marginBottom: '30px' }}>
                        {conteudosRelacionados.map((item, idx) => {
                            return (
                                <Styled.ListItem
                                    onClick={() => {
                                        editRelatedContent(idx);
                                    }}
                                    key={idx}
                                >
                                    {item.titulo}
                                </Styled.ListItem>
                            );
                        })}
                    </div>
                    <Styled.BottomButton>
                        <OutlineButton onClick={addConteudoRelacionado}>+ Adicionar Conteúdo</OutlineButton>
                    </Styled.BottomButton>
                </>
            ) : (
                <Loading />
            )}

            <ItemBiliotecaRelatedContentDialog
                codigoItem={props.itemData?.id ?? null}
                show={showRelatedContentModal}
                conteudoRelacionado={selectedRelatedContent}
                conteudoIdx={selectedRelatedContentIdx}
                cursosAtivos={cursosAtivos}
                itensBiblioPublicados={itensBiblioPublicados}
                onHide={() => {
                    setSelectedRelatedContent(null);
                    setShowRelatedContentModal(false);
                }}
                onSubmit={(conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => {
                    const clone = cloneDeep(conteudosRelacionados);
                    clone.push(conteudoRelacionado);
                    setConteudosRelacionados(clone);
                    setShowRelatedContentModal(false);
                    setSelectedRelatedContent(null);
                    ToastSuccess('Conteúdo Salvo');
                }}
                onEdit={(idx: number, conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => {
                    const clone = cloneDeep(conteudosRelacionados);
                    clone[idx] = conteudoRelacionado;
                    setConteudosRelacionados(clone);
                    setShowRelatedContentModal(false);
                    setSelectedRelatedContent(null);
                    ToastSuccess('Conteúdo Editado');
                }}
                onDelete={(conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => {
                    const clone = cloneDeep(conteudosRelacionados);

                    let newConteudos = conteudosRelacionados.filter(
                        (item: ItemBiblitoecaConteudoRelacionado) => item.codigo !== conteudoRelacionado.codigo
                    );

                    setConteudosRelacionados(newConteudos);
                    setShowRelatedContentModal(false);
                    setSelectedRelatedContent(null);
                    ToastSuccess('Conteúdo Excluído');
                }}
            />
        </Styled.ItemBibliotecaRelatedContentListContainer>
    );
};

export default ItemBibliotecaRelatedContentListagem;
