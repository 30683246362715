import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminCursoLivre extends Serializable {
    @jsonProperty(Number)
    codigo: number = 0;

    @jsonName('titulo')
    @jsonProperty(String, null)
    titulo: string = '';

    @jsonName('descricao')
    @jsonProperty(String, null)
    descricao: string = '';

    @jsonName('duracao')
    @jsonProperty(Number)
    duracao: number = 0;

    @jsonName('tipoDuracao')
    @jsonProperty(String)
    tipoDuracao: string = '';

    @jsonName('emProducao')
    @jsonProperty(Boolean)
    emProducao: boolean = false;

    @jsonName('naoAplicavel')
    @jsonProperty(Boolean)
    naoAplicavel: boolean = false;

    @jsonName('ordem')
    @jsonProperty(Number, null)
    ordem: number = -1;

    @jsonName('prazoInscricao')
    @jsonProperty(Date, null)
    prazoInscricao: Date | null = null;

    @jsonName('dataExpiracao')
    @jsonProperty(Date, null)
    dataExpiracao: Date | null = null;

    @jsonName('url')
    @jsonProperty(String)
    url: string = '';

    @jsonName('urlAcessarCurso')
    @jsonProperty(String)
    urlAcessarCurso: string = '';

    @jsonName('idCurso')
    @jsonProperty(String)
    idCurso: string = '';

    @jsonName('anexoBase64')
    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonName('filePath')
    @jsonProperty(String)
    filePath: string = '';
}

export default AdminCursoLivre;
