import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class ItemFaq extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    codigo: number = 0;

    @jsonName('pergunta')
    @jsonProperty(String, null)
    pergunta: string = '';

    @jsonName('resposta')
    @jsonProperty(String, null)
    resposta: string = '';

    @jsonName('ativo')
    @jsonProperty(Boolean)
    ativo: boolean = false;

    @jsonName('ordem')
    @jsonProperty(Number, null)
    ordem: number = 0;

    @jsonName('ultimaAtualizacao')
    @jsonProperty(Date, null)
    ultimaAtualizacao: Date | null = null;
}

export default ItemFaq;
