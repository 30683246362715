import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { PrimaryButton, DangerButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Menu from 'pages/menu/Menu';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import icon from '../../images/cadeado.svg';

const Image = styled.img`
    max-width: 150px;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 20%;
        padding: 0 15px;
        box-sizing: content-box;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #f7f7f7;

    align-items: center;
    padding-top: 50px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: 10px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    max-width: 780px;
    margin: 0 auto;
    margin-top: 76px;
`;

const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.3px;

    color: #003c78;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 40px;
    }
`;

const SubTitle = styled(Title)`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 275;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.3px;

    color: #003c78;

    margin-bottom: 30px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 25px;
        line-height: 27px;
    }
`;

export interface IUnauthorizedPage {}

const UnauthorizedPage = ({}: IUnauthorizedPage) => {
    const history = useHistory();

    return (
        <>
            {/*  <Menu unauthorized /> */}
            <DynamicHeader />

            <Container>
                <Content>
                    <Image src={icon} />
                    <Column>
                        <Title>{'Que pena :('}</Title>
                        <br />
                        <SubTitle>Neste momento, seu perfil não pode acessar essa página.</SubTitle>
                    </Column>
                </Content>
                <Content style={{ marginTop: '10px' }}>
                    <PrimaryButton style={{ width: '250px' }} onClick={() => history.replace('/')}>
                        Voltar para página inicial
                    </PrimaryButton>
                </Content>
            </Container>
        </>
    );
};

export default UnauthorizedPage;
