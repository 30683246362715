import React, { useContext, useEffect, useState } from 'react';
import jornadaSocioProfessores from 'images/painelSocio/jornadaSocioProfessores.svg';
import {
    DefaultText,
    HomePageButton,
    ImgSvgLogo,
    MenuItem,
    MenuItemText,
    PainelSocioemocionalHeaderStyle,
} from './PainelSocioemocionalHeader.styled';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useHistory, useLocation } from 'react-router-dom';
import PDP from 'models/pdp/PDP';
import { IntegracaoInstrumentoService } from 'core/http/service/IntegracaoInstrumento.service';
import InstrumentoDTO from 'models/instrumento/InstrumentoDTO';
import StatusInstrumentoEnum from 'models/instrumento/StatusInstrumentoEnum';
import { lmsIntegrationUrlSocioemocional } from 'constants/EnvironmentData';
import { useDispatch } from 'react-redux';
import * as authenticationActions from '../../store/actions/Authentication';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import Colors from 'enums/Colors';
import { CSSProperties } from 'styled-components';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import { CheckedGoToContext } from './home/context/CheckedGoToContext';
import { Button, Modal } from 'react-bootstrap';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';

interface IPainelSocioemocionalHeader {}

const HomeRoute: string = '/painelSocioemocional/home';
const AutoAvaliacaoRoute: string = '/painelSocioemocional/autoavaliacao';
const PdpRoute: string = '/painelSocioemocional/pdp';
const DiarioBordoRoute: string = '/painelSocioemocional/diarioBordo';

const PainelSocioemocionalHeader = ({}: IPainelSocioemocionalHeader) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const actualRoute = location.pathname;
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const { isVerifyGoToPage, showModal, setShowModal, goToPage } = useContext(CheckedGoToContext.Context);

    const pdp: PDP | null = useAppSelector(state => state.pdpReducer.pdp);

    const [hasNoInstrument, setHasNoInstrument] = useState<boolean>(false);
    const [showPopupRealizarPdp, setShowPopupRealizarPdp] = useState<boolean>(false);
    const [hasNoFinisedInstrument, setHasNoFinishedIntrument] = useState<boolean>(false);
    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const integracaoInstrumentoService = new IntegracaoInstrumentoService();

    const redirectWithValidation = async (callback: Function) => {
        try {
            const response = await integracaoInstrumentoService.consultarInstrumentos(userProfile?.codigo ?? -1, userProfile?.username ?? '');
            const instrumentoArray: InstrumentoDTO[] = response.data.map((element: any) => new InstrumentoDTO().fromJSON(element));

            if (!instrumentoArray.length) {
                setHasNoInstrument(true);
            } else if (!instrumentoArray.find(item => item.situation === StatusInstrumentoEnum.FINISHED)) {
                setHasNoFinishedIntrument(true);
            } else {
                callback();
            }
        } catch (err: any) {
            console.log('Toolbar - Integração com o bncc ainda não concluída');
            setHasNoInstrument(true);
            setHasNoFinishedIntrument(true);
        }
    };

    const redirectToTrilhasFormativas = async () => {
        window.open(lmsIntegrationUrlSocioemocional(), '_blank');
    };

    const redirectToPdp = async () => {
        isVerifyGoToPage('/painelSocioemocional/pdp');
    };

    const goToTrilhasFormativas = async () => {
        redirectWithValidation(redirectToTrilhasFormativas);
    };

    const goToPdp = async () => {
        redirectWithValidation(redirectToPdp);
    };

    const gotoAutoavaliacao = async () => {
        try {
            await dispatch(authenticationActions.getUsuarioLogado());
            history.push('/painelSocioemocional/autoavaliacao');
        } catch (err: any) {
            setOpenPopup(true);
        }
    };

    const goToDiarioBordo = () => {
        isVerifyGoToPage('/painelSocioemocional/diarioBordo');
    };

    const goToHomePage = () => {
        isVerifyGoToPage('/painelSocioemocional/home');
    };

    const goToLandingpage = () => {
        isVerifyGoToPage('/');
    };

    const closePopup = () => {
        setHasNoInstrument(false);
        setHasNoFinishedIntrument(false);
        setShowPopupRealizarPdp(false);
    };

    const closePopupIntegracao = () => {
        setOpenPopup(false);
    };

    const ValidationPopup = () => {
        return (
            <>
                <PopupQuestion
                    onButton={closePopupIntegracao}
                    okButtonText={'Ok'}
                    cancelButtonText={'Ok'}
                    infoOnly={true}
                    question={'Por favor, atualize a página em alguns instantes e tente novamente. '}
                    visible={openPopup}
                />

                <PopupQuestion
                    onButton={closePopup}
                    okButtonText={'Ok'}
                    cancelButtonText={'Ok'}
                    infoOnly={true}
                    question={'Você deve começar seu percurso pelo instrumento de avaliação!'}
                    visible={hasNoInstrument}
                />
                <PopupQuestion
                    onButton={closePopup}
                    okButtonText={'Ok'}
                    cancelButtonText={'Ok'}
                    infoOnly={true}
                    question={'Você deve finalizar o instrumento da avaliação!'}
                    visible={hasNoFinisedInstrument}
                />

                <PopupQuestion
                    onButton={closePopup}
                    okButtonText={'Ok'}
                    cancelButtonText={'Ok'}
                    infoOnly={true}
                    question={'Para acessar a trilha formativa, primeiro você precisa finalizar o seu Plano de Desenvolvimento Pessoal!'}
                    visible={showPopupRealizarPdp}
                />
            </>
        );
    };

    function selectedStyle(route: string | undefined): CSSProperties {
        let style: CSSProperties;

        if (actualRoute === route) {
            style = {
                color: Colors.White,
                cursor: 'pointer',
                fontWeight: '700',
                textDecoration: 'underline',
                textDecorationThickness: '6px',
                textUnderlineOffset: '21px',
            };
        } else {
            style = {
                color: Colors.White,
            };
        }
        return style;
    }

    return (
        <PainelSocioemocionalHeaderStyle>
            <ModalCheckGoToPage showModal={showModal} setShowModal={setShowModal} goToPage={goToPage} />

            <ImgSvgLogo src={jornadaSocioProfessores} />
            <MenuItem>
                <MenuItemText
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'inicio_jornada_btn');
                        goToHomePage();
                    }}
                    style={selectedStyle(HomeRoute)}
                >{`Inicio Jornada`}</MenuItemText>
                <MenuItemText
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'autoavaliacao_btn');
                        gotoAutoavaliacao();
                    }}
                    style={selectedStyle(AutoAvaliacaoRoute)}
                >{`Autoavaliação`}</MenuItemText>
                <MenuItemText
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'trilhas_formativas_btn');
                        goToTrilhasFormativas();
                    }}
                    style={selectedStyle(undefined)}
                >{`Trilhas formativas`}</MenuItemText>
                <MenuItemText
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'pdp_btn');
                        goToPdp();
                    }}
                    style={selectedStyle(PdpRoute)}
                >{`PDP`}</MenuItemText>

                <MenuItemText
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'diario_bordo_btn');
                        goToDiarioBordo();
                    }}
                    style={selectedStyle(DiarioBordoRoute)}
                >{`Diário de bordo`}</MenuItemText>

                <HomePageButton
                    onClick={() => {
                        GTM_Page_Jornada_Desenvolvimento('socioemocional_header', 'pagina_inicial_btn');
                        goToLandingpage();
                    }}
                >
                    <DefaultText>{`Pagina inicial humane`}</DefaultText>
                </HomePageButton>
            </MenuItem>
            <ValidationPopup />
        </PainelSocioemocionalHeaderStyle>
    );
};
export default PainelSocioemocionalHeader;
