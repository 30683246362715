import { jsonProperty, Serializable } from 'ts-serializable';
import Macrocompetencia from './Macrocompetencia';
import Meta from './Meta';

class MetasDTO extends Serializable {
    @jsonProperty([Meta], [])
    listaMetasCurtoPrazo: Meta[] = [];

    @jsonProperty([Meta], [])
    listaMetasMedioPrazo: Meta[] = [];

    @jsonProperty([Meta], [])
    listaMetasLongoPrazo: Meta[] = [];

    @jsonProperty([Meta], [])
    listaMetasPrazo: Meta[] = [];

    constructor() {
        super();
    }
}

export default MetasDTO;
