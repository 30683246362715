import { MobileMaxWidth } from 'constants/Dimensions';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Styled from '../Alfabetizacao360Detail.styled';
import img0 from './assets/Frame_2040.png';
import img2 from './assets/Frame_2042.png';
import img1 from './assets/Frame_2043.png';
import arrw from './assets/Frame_2048.png';

export interface IGuia {}

const Guia = ({}: IGuia) => {
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= MobileMaxWidth);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    return (
        <Styled.Main id="guia">
            <Styled.SectionGuia.Container>
                <Styled.SectionGuia.Content>
                    <Styled.SectionGuia.ContentTexts>
                        <Styled.SectionGuia.PreTitle>GUIA DE IMPLEMENTAÇÃO</Styled.SectionGuia.PreTitle>
                        <Styled.SectionGuia.Title>Guia de Alfabetização para Coordenadores</Styled.SectionGuia.Title>

                        <Styled.SectionGuia.Text>
                            Reunimos insumos necessários para coordenadores pedagógicos realizarem formações, testes diagnósticos e metodologias
                            de recuperação da aprendizagem. Venha conhecer!
                        </Styled.SectionGuia.Text>

                        <iframe
                            width={!isMobile ? '583' : '350'}
                            height={!isMobile ? '327' : '196'}
                            src={`https://www.youtube.com/embed/Y9oXSaqCXHA`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        ></iframe>
                    </Styled.SectionGuia.ContentTexts>

                    <Styled.SectionGuia.Cards>
                        <Styled.SectionGuia.CardContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <Styled.SectionGuia.Icon src={img0} style={{ marginRight: '20px' }} />
                                </div>
                                <Styled.SectionGuia.CardTitle>Visão Geral da Propostas</Styled.SectionGuia.CardTitle>
                            </div>
                            <Styled.SectionGuia.CardText>
                                Infográfico com uma visão geral dos conteúdos e da proposta Alfabetização 360°.
                            </Styled.SectionGuia.CardText>

                            <Styled.SectionGuia.ContentBottom
                                onClick={() => {
                                    history.push('/biblioteca/item/alfabetizacao-360-visao-geral-da-proposta');
                                }}
                            >
                                <span className="mobile-only">Acessar</span>
                                <Styled.SectionGuia.Icon src={arrw} />
                            </Styled.SectionGuia.ContentBottom>
                        </Styled.SectionGuia.CardContainer>

                        <br />

                        <Styled.SectionGuia.CardContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <Styled.SectionGuia.Icon src={img1} style={{ marginRight: '20px' }} />
                                </div>
                                <Styled.SectionGuia.CardTitle>Vol. 1: Fundamentos da proposta</Styled.SectionGuia.CardTitle>
                            </div>

                            <Styled.SectionGuia.CardText>
                                Conheça o nosso histórico em alfabetização e aceleração da aprendizagem além dos conceitos-chave para o
                                desenvolvimento da proposta Alfabetização 360°.
                            </Styled.SectionGuia.CardText>
                            <Styled.SectionGuia.ContentBottom
                                onClick={() => {
                                    history.push('/biblioteca/item/alfabetizacao-360-vol-1-fundamentos-da-proposta');
                                }}
                            >
                                <span className="mobile-only">Acessar</span>
                                <Styled.SectionGuia.Icon src={arrw} />
                            </Styled.SectionGuia.ContentBottom>
                        </Styled.SectionGuia.CardContainer>

                        <br />

                        <Styled.SectionGuia.CardContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <Styled.SectionGuia.Icon src={img2} style={{ marginRight: '20px' }} />
                                </div>
                                <Styled.SectionGuia.CardTitle>Vol. 2: Implementação na escola</Styled.SectionGuia.CardTitle>
                            </div>

                            <Styled.SectionGuia.CardText>
                                Ferramenta para identificar oportunidades de aprimoramento nos eixos da gestão de aprendizagem e práticas
                                pedagógicas.
                            </Styled.SectionGuia.CardText>
                            <Styled.SectionGuia.ContentBottom
                                onClick={() => {
                                    history.push('/biblioteca/item/alfabetizacao-360-vol-2-implementacao-na-escola');
                                }}
                            >
                                <span className="mobile-only">Acessar</span>
                                <Styled.SectionGuia.Icon src={arrw} />
                            </Styled.SectionGuia.ContentBottom>
                        </Styled.SectionGuia.CardContainer>
                    </Styled.SectionGuia.Cards>
                </Styled.SectionGuia.Content>
            </Styled.SectionGuia.Container>
        </Styled.Main>
    );
};

export default Guia;
