import { IsMobileBiggerWidth, IsMobileMaxWidth, IsCustomMaxWidth, IsCustomMinWidth } from 'core/util/WindowSize';
import { Iframe } from 'pages/autoavaliacao/Autoavaliacao.styled';
import React from 'react';
import Styled from './SliderItemAutoavaliacao.styled';
import useWindowSize from 'hooks/useWindowSize';
import Size from 'models/Size';

export interface ISliderItemAutoavaliacao {
    index: number;
    title: any;
    content: any;
    content2: any;
    content3: any;
    image: string;
    image2: string;
    image3: string;
}

const SliderItemAutoavaliacao = ({index, content, image, image2, title, content2, content3, image3 }: ISliderItemAutoavaliacao) => {

  const translate = (): String => {

    const size: Size = useWindowSize();

    if(size.width){
      if(size.width <= 480){
        return "-13%";
      }

      if(size.width < 990){
        return "-5%";
      }
    }

    return "-25%";
};



    return (
        <div style={{ transform: index === 1 ? `translateY(${translate()})` : ''}}>
            <React.Fragment>
                <Styled.Title>{title}</Styled.Title>
                <br />
                <br />

                {index == 1 ? (
                    <React.Fragment>
                        <Styled.Container>
                            <Styled.Image src={image} />
                            <Styled.Content>{content}</Styled.Content>
                        </Styled.Container>
                        <Styled.Container>
                            <Styled.Image src={image2} />
                            <Styled.Content>{content2}</Styled.Content>
                        </Styled.Container>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Styled.Container2>
                            <Styled.Content>{content}</Styled.Content>
                            <Styled.Image2 src={image} />
                        </Styled.Container2>
                        <br />
                        <Styled.Container2>
                            <Styled.Content>{content2}</Styled.Content>
                            <Styled.Image2 src={image2} />
                        </Styled.Container2>
                        <br />
                        <Styled.Container>
                            <Styled.Image style={{ width: '26px' }} src={image3} />
                            <Styled.Content>{content3}</Styled.Content>
                        </Styled.Container>
                    </React.Fragment>
                )}
            </React.Fragment>
        </div>
    );
};

export default SliderItemAutoavaliacao;
