/**
 *   Não instanciar a classe dentro do mesmo escopo da chamada
 *   da função 'launch()'
 *
 *  Exemplo:
 *      const debounce = new Debounce();
 *      enviarDados(){
 *        debounce.launch(() => {
                ...
            }, time);
 *      }
 */
export default class Debounce {
    timeoutDebounce: ReturnType<typeof setTimeout>;

    constructor() {
        this.timeoutDebounce = setTimeout(() => {});
    }

    launch = (fn: Function, time = 1000) => {
        this.clear();
        this.timeoutDebounce = setTimeout(() => {
            fn();
        }, time);
    };

    clear = () => {
        clearTimeout(this.timeoutDebounce);
    };
}
