import React, { useState } from 'react';
import Styled from './AdminEditorImage.styled';

import closeIcon from '../../../../images/close.svg';
import { GradientButton, OutlineButton } from 'components/UI/Buttons';

import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export interface IAdminEditSectionorImage {
    show: boolean;
    onHide: () => void;
    inputImage: string | null;
    onSave: (description: string, imageEncode?: string) => void;
}

const AdminEditorImage = ({ show, onHide, inputImage, onSave }: IAdminEditSectionorImage) => {
    const [desc, setDesc] = useState('');
    const [crop, setCrop] = useState({} as Crop);

    const [imageRef, setImageRef] = useState<HTMLImageElement>();

    const save = () => {
        if (imageRef) {
            const b64 = getCroppedImg(imageRef, crop);
            if (b64) onSave(desc, b64);
        } else onSave(desc);
    };

    const getCroppedImg = (image: HTMLImageElement, crop: Crop): string | null => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        if (ctx) {
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );

            return canvas.toDataURL('image/jpeg');
        }

        return null;
    };

    return (
        <Styled.Modal show={show} onHide={onHide}>
            <Styled.Container>
                <Styled.CloseIconContainer>
                    <Styled.CloseIcon src={closeIcon} onClick={() => onHide()} />
                </Styled.CloseIconContainer>

                {inputImage && (
                    <Styled.CropperContainer>
                        <ReactCrop
                            src={inputImage}
                            crop={crop}
                            onChange={c => setCrop(c)}
                            onImageLoaded={(img: HTMLImageElement) => setImageRef(img)}
                        />
                        {/*  <Styled.Crop src={inputImage} /> */}
                    </Styled.CropperContainer>
                )}

                <br />
                <Styled.Label>Descrição da imagem</Styled.Label>
                <Styled.Input value={desc} onChange={(evt: any) => setDesc(evt.target.value)} />

                <br />

                <Styled.BottomButom>
                    <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                    <GradientButton onClick={save} disabled={!desc.trim().length}>
                        Salvar
                    </GradientButton>
                </Styled.BottomButom>
            </Styled.Container>
        </Styled.Modal>
    );
};

export default AdminEditorImage;
