import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const Container = styled.div(
    new FullCss(
        {
            maxWidth: '1253px',
            padding: '100px 10px 50px 10px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            paddingTop: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        })
    ).getCss()
);

export const Content = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            maxWidth: '80%',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            maxWidth: '90%',
        })
    ).getCss()
);

export const Separator = styled.div(new FullCss({ paddingTop: '20px', borderBottom: '1px solid #cbd2e0', width: '80%' }).getCss());

export const TopContent = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '20px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'row',
        })
    ).getCss()
);

export const InnerTopContent = styled.div(
    new FullCss({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    }).getCss()
);

export const Title = styled.p(
    new FullCss({
        fontWeight: '700',
        fontSize: '40px',
        letterSpacing: '0.3px',
        lineHeight: '35px',
        color: Colors.Blue,
    }).getCss()
);

export const SubTitle = styled.p(
    new FullCss({
        marginTop: '30px',
        fontWeight: '700',
        fontSize: '28px',
        letterSpacing: '0.3px',
        lineHeight: '88%',
        color: Colors.Blue,
    }).getCss()
);

export const Byline = styled.p(
    new FullCss({
        fontWeight: '400',
        fontSize: '28px',
        letterSpacing: '0.3px',
        lineHeight: '34px',
        fontStyle: 'italic',
        color: Colors.Blue,
    }).getCss()
);

export const Text = styled.p(
    new FullCss({
        font: 'Barlow',
        fontWeight: '400',
        fontSize: '18px',
        letterSpacing: '0.3px',
        lineHeight: '21.6px',
        color: Colors.Blue,
    }).getCss()
);

export const BottomContent = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'column',
            alignItems: 'center',
        })
    ).getCss()
);

export const ButtonInitialPage = styled.button(
    new FullCss(
        {
            border: '2px solid #00A8E9',
            borderRadius: '6px',
            width: '359px',
            height: '56px',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '-0.01em',
            fontFeatureSettings: "'calt' off",
            color: '#00A8E9',
            backgroundColor: '#F7F7F7',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '100%',
            }),
            new MediaQuery(':hover', {
                border: '2px solid #003C78',
                background: '#003C78',
                color: 'white',
            }),
        ]
    ).getCss()
);

export const ButtonNext = styled(ButtonInitialPage)(
    new FullCss(
        {
            border: '2px solid #003C78',
            background: '#003C78',
            width: '461px',
            height: '56px',
            color: '#FCFCFC',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '100%',
            }),
            new MediaQuery(':hover', {
                border: '2px solid #003C78',
                background: '#F7F7F7',
                color: '#003C78',
            }),
        ]
    ).getCss()
);
