import { jsonProperty, Serializable } from 'ts-serializable';
import CorrespondentItemModel from './CorrespondentItemModel';

class CorrespondentModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    pergunta: string = '';

    @jsonProperty(String)
    feedback: string = '';

    itens: CorrespondentItemModel[] | null = [];
}

export default CorrespondentModel;
