import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import AdminSecaoPaginaData from '../AdminSecaoPaginaData';
import Styled from './AdminGestaoPaginasNewEdit.styled';

export interface IAdminGestaoPaginasSecaoListItem {
    secao: AdminSecaoPaginaData;
}

export interface IAdminGestaoPaginasSecaoListDropzone {
    onDropItem: (item: AdminSecaoPaginaData, neworder: number) => void;
    thisOrder: number;
}

const typeItem = 'ADMIN-SECTION-ITEM';

export const AdminGestaoPaginasSecaoListItem = ({ children, secao }: React.PropsWithChildren<IAdminGestaoPaginasSecaoListItem>) => {
    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: typeItem,
            item: secao,
            collect: monitor => ({
                opacity: monitor.isDragging() ? 0.3 : 1,
            }),
        }),
        []
    );

    return (
        <Styled.ListItem ref={dragRef} style={{ opacity }}>
            {children}
        </Styled.ListItem>
    );
};

/* eslint-disable react/prop-types */
export const AdminGestaoPaginasSecaoListDropzone = ({ onDropItem, thisOrder }: IAdminGestaoPaginasSecaoListDropzone) => {
    const [props, dropRef] = useDrop(() => ({
        accept: typeItem,
        collect: monitor => {
            const item = monitor.getItem<AdminSecaoPaginaData>();

            return {
                ishover: monitor.isOver() && item.ordem != thisOrder && item.ordem + 1 != thisOrder,
            };
        },
        drop: (item: any, _) => {
            let neworder = thisOrder;

            if (item.ordem < neworder) neworder -= 1;

            if (item.ordem != neworder) onDropItem(item, neworder);
        },
    }));

    return <Styled.Dropzone ref={dropRef} isHover={props?.ishover ?? false} />;
};
