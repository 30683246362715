import React, { useEffect, useState } from 'react';

import { bucketS3, caminhoAnexosGestaoPaginaCarousel } from 'constants/EnvironmentData';
import { CarouselMedia } from 'models/generic-components/CarouselMedia';
import Styled from './CarrosselMultimidiaCard.styled';
import { MdOutlinePlayCircleFilled } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';

interface IStatementCarouselCard {
    statement: CarouselMedia;
    isActive: boolean;
}

const CarouselMultimidiaCard = ({ statement, isActive }: IStatementCarouselCard) => {
    const [codigoVideo, setCodigoVideo] = useState<string>('');
    const [show, setShow] = useState(false);

    const getS3BaseUrl = (): string => {
        if (statement.tipoAnexo !== 'I') return '';

        return bucketS3() + caminhoAnexosGestaoPaginaCarousel();
    };

    useEffect(() => {
        if (statement.tipoAnexo === 'V') {
            const url = statement?.urlVideo ?? '';

            const queryParams = new URLSearchParams(new URL(url).search);
            setCodigoVideo(queryParams.get('v') ?? '');
        }
    }, [statement.filePath]);

    return (
        <Styled.Container>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                centered
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <iframe
                        width="100%"
                        height="600"
                        src={`https://www.youtube.com/embed/${codigoVideo}`}
                        title="YouTube Video"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </Modal.Body>
            </Modal>
            <Styled.Content>
                {statement.tipoAnexo === 'I' ? (
                    <Styled.Img src={getS3BaseUrl() + statement.filePath} />
                ) : (
                    <Styled.Video.Container>
                        <Styled.Video.File src={`https://img.youtube.com/vi/${codigoVideo}/0.jpg`} alt="Video Thumbnail" />
                        {isActive && (
                            <Styled.Video.Play onClick={() => setShow(true)}>
                                <MdOutlinePlayCircleFilled size={50} color="white" />
                            </Styled.Video.Play>
                        )}
                    </Styled.Video.Container>
                )}
            </Styled.Content>
            {!statement.ocultarDetalhamento && (
                <Styled.Detail color={statement.corDetalhamento} size={statement.tamanhoDetalhamento}>
                    {statement.detalhamento}
                </Styled.Detail>
            )}
        </Styled.Container>
    );
};

export default CarouselMultimidiaCard;
