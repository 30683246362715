import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class PerfilDTOGenerico extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    descricao: string = '';

    constructor() {
        super();
    }
}

export default PerfilDTOGenerico;
