import FAQ from '../../models/FAQ';
import Statement from '../../models/Statement';
import EducationalSolution from '../../models/EducationalSolution';

import { HomePageService } from '../../core/http/service/HomePage.service';

export const GET_FAQ: string = 'GET_FAQ';
export const GET_STATEMENTS: string = 'GET_STATEMENTS';
export const GET_EDUCATIONAL_SOLUTIONS: string = 'GET_EDUCATIONAL_SOLUTIONS';

const sendToAnalytics = (where: string = '', error: any = null) => {
    console.error(where, error);
};

export const fetchEducationalSolutions = (isGestor: boolean) => {
    const homePageService = new HomePageService();

    return async (dispatch: any) => {
        try {
            let response: any = [];
            if (isGestor) {
                response = await homePageService.getManagerEducationalSolutions();
            } else {
                response = await homePageService.getEducationalSolutions();
            }
            const { data } = response;

            const output: EducationalSolution[] = data.map((element: any) => new EducationalSolution().fromJSON(element));

            dispatch({
                type: GET_EDUCATIONAL_SOLUTIONS,
                educationalSolutions: output,
            });
        } catch (error) {
            sendToAnalytics('fetchEducationalSolutions', error);
            throw error;
        }
    };
};

export const fetchFaq = () => {
    const homePageService = new HomePageService();

    return async (dispatch: any) => {
        try {
            const response: any = await homePageService.getFrequentlyAskedQuestions();
            const { data } = response;

            const output: FAQ[] = data.map((item: any) => new FAQ().fromJSON(item));

            dispatch({
                type: GET_FAQ,
                frequentlyAskedQuestions: output,
            });
        } catch (error) {
            sendToAnalytics('fetchFaq', error);
            throw error;
        }
    };
};

export const fetchStatements = () => {
    const homePageService = new HomePageService();

    return async (dispatch: any) => {
        try {
            const response: any = await homePageService.getStatements();
            const { data } = response;

            const output: Statement[] = data.map((item: any) => new Statement().fromJSON(item));

            dispatch({
                type: GET_STATEMENTS,
                statements: output,
            });
        } catch (error) {
            sendToAnalytics('fetchStatements', error);
            throw error;
        }
    };
};
