import React, { useEffect, useState, useCallback } from 'react';
import { AccessPermissionContext } from './AccessPermissionContext';
import { useHistory, useLocation } from 'react-router-dom';
import { RoleEnum } from 'enums/RoleEnum';
import useUserData from 'hooks/useUserData';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ProfileService } from 'core/http/service/Profile.service';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';

export interface IPainelSocioProvider {}

export enum typePendencias {
    DATA_NASCIMENTO = 'DATA_NASCIMENTO',
    GENERO = 'GENERO',
    COR = 'COR',
    CPF = 'CPF',
    COMO_CONHECEU = 'COMO_CONHECEU',
    CATEDRA_OR_IEA = 'CATEDRA_OR_IEA',
    ESTADO = 'ESTADO',
    CIDADE = 'CIDADE',
    DEFICIENCIAS = 'DEFICIENCIAS',
    WHATSAPP = 'WHATSAPP',
}

const AccessPermissionProvider = ({ children }: React.PropsWithChildren<IPainelSocioProvider>) => {
    const location = useLocation();
    const history = useHistory();

    const { loadData } = useUserData();

    const [verifyingPermission, setVerifyingPermission] = useState<boolean>(false);
    const [pendencias, setPendencias] = useState<typePendencias[]>();
    const [parametrosPerfil, setParametrosPerfil] = useState<ParametrosCadastroPerfilDTO>();
    const [pathRedirect, setPathRedirect] = useState<string>('');
    const profileService = new ProfileService();

    const verifyPermission = async () => {
        const { usuarioLogado, perfilUsuario } = await loadData();

        try {
            setVerifyingPermission(true);
            const roles = usuarioLogado?.roles;
            const currentRoute = location.pathname;

            if (currentRoute.includes('painelSocioemocional')) {
                let perfilOutro = false;
                perfilUsuario?.role === RoleEnum.Outro || perfilUsuario?.role === RoleEnum.AgenteTecnico
                    ? (perfilOutro = true)
                    : (perfilOutro = false);

                if (
                    roles?.includes(RoleEnum.Pesquisador) ||
                    roles?.includes(RoleEnum.ColaboradorParceria) ||
                    roles?.includes(RoleEnum.Estudante) ||
                    perfilOutro
                ) {
                    console.log('Bloqueado do painelSocioemocional: ', roles);
                    history.push('/naoAutorizado');
                }
            }

            if (currentRoute.includes('biblioteca')) {
                if (roles?.includes(RoleEnum.Pesquisador) || roles?.includes(RoleEnum.Estudante)) {
                    console.log('Bloqueado da biblioteca: ', roles);
                    history.push('/naoAutorizado');
                }
            }

            if (currentRoute.includes('realizandoEscolhas')) {
                if (roles) {
                    const fileredRoles: string[] = roles?.filter((value, index, arr) => {
                        return value !== 'default-roles-ias' && value !== 'offline_access' && value !== 'uma_authorization';
                    });

                    if (!(fileredRoles.length > 0)) {
                        history.push('/naoAutorizado');
                    }

                    if (
                        roles?.includes(RoleEnum.Pesquisador) ||
                        roles?.includes(RoleEnum.ColaboradorParceria) ||
                        roles?.includes(RoleEnum.Estudante)
                    ) {
                        history.push('/naoAutorizado');
                    }
                }
            }
        } catch (err: any) {
            console.log('verifiypermissionerr', err);
        } finally {
            setVerifyingPermission(false);
        }
    };

    const listParametrosCadastroPerfil = async () => {
        const response: any = await profileService.consultarParametrosCadastro();
        const params: ParametrosCadastroPerfilDTO = new ParametrosCadastroPerfilDTO().fromJSON(response.data);

        setParametrosPerfil(params);
    };

    const redirectBehindRegister = async (pathToRedirect: string) => {
        const { usuarioLogado, perfilUsuario } = await loadData();
        const currentRoute = location.pathname;

        const isEstudanteOuColaboradorParceriasOuPesquisador =
            usuarioLogado?.roles.includes(RoleEnum.Estudante) ||
            usuarioLogado?.roles.includes(RoleEnum.ColaboradorParceria) ||
            usuarioLogado?.roles.includes(RoleEnum.Pesquisador);

        //Se o usuário não possui perfil cadastrado ele é redirecionado para o cadastro do perfil
        //com a possibiliadade de ser redirecionado diretamenta para a jornada após conclusão do mesmo
        //caso seja um redirecionamento direto de jornada
        //Obs: usuários estudantes não se cadastram na humane
        if (!isEstudanteOuColaboradorParceriasOuPesquisador && !perfilUsuario) {
            history.push({
                pathname: '/profile',
                state: { postProfilePath: pathToRedirect },
            });
        } else if (perfilUsuario && usuarioLogado) {
            //se já possui perfil, vai direto para o path
            const responseProfile: any = await profileService.findByUsername(usuarioLogado.username);

            // validar se as informações do perfil está completamente preenchidas.
            if (currentRoute === '/profile/informacoes/2') {
                history.push(pathToRedirect);
            } else {
                validationPerfilUsuario(responseProfile.data, pathToRedirect);
            }
        } else {
            history.push('/');
        }
    };

    const validationPerfilUsuario = (perfil: PerfilUsuario, pathToRedirect: string) => {
        let havePendencia = false;
        let pendencias = new Array<typePendencias>();

        if (perfil.dataNascimento == '' || perfil.dataNascimento == null) {
            pendencias.push(typePendencias.DATA_NASCIMENTO);
            havePendencia = true;
        }

        if(perfil.cpf == '' || perfil.cpf == null){
            pendencias.push(typePendencias.CPF);
            havePendencia = true;
        }

        if (perfil.genero == '' || perfil.genero == null) {
            pendencias.push(typePendencias.GENERO);
            havePendencia = true;
        }

        if (perfil.cor == '' || perfil.cor == null) {
            pendencias.push(typePendencias.COR);
            havePendencia = true;
        }

        if (perfil.comoConheceu == '' || perfil.comoConheceu == null) {
            pendencias.push(typePendencias.COMO_CONHECEU);
            havePendencia = true;
        }

        if (perfil.estado == '' || perfil.estado == null) {
            pendencias.push(typePendencias.ESTADO);
            havePendencia = true;
        }

        if (perfil.cidade == '' || perfil.cidade == null) {
            pendencias.push(typePendencias.CIDADE);
            havePendencia = true;
        }

        if (perfil.possuiDeficiencia == null) {
            pendencias.push(typePendencias.DEFICIENCIAS);
            havePendencia = true;
        }

        if (perfil.whatsapp == '' || perfil.whatsapp == null) {
            pendencias.push(typePendencias.WHATSAPP);
        }

        if (!havePendencia) {
            history.push(pathToRedirect);
        } else {
            setPendencias(pendencias);
            setPathRedirect(pathToRedirect);
        }
    };

    const init = async () => {
        await verifyPermission();
    };

    useEffect(() => {
        init();
        listParametrosCadastroPerfil();
    }, []);

    useEffect(() => {
        verifyPermission();
    }, [location.pathname]);

    const isUserNoRegister = () => {
        return true;
    };

    const context: AccessPermissionContext.IContext = {
        redirectBehindRegister,
        isUserNoRegister,
        pendencias,
        pathRedirect,
        parametrosPerfil,
    };

    return <AccessPermissionContext.Context.Provider value={context}>{children}</AccessPermissionContext.Context.Provider>;
};

export default AccessPermissionProvider;
