import { ICategoriaToken } from "pages/acessoExclusivo/components/Categoria/CardCategoria";
import { GenericService } from "../Generic.service";



export class CategoriaTokensPEService extends GenericService {
    readonly URI: string = '/v1/categoriaTokenPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public saveAll(tokens: ICategoriaToken[]) {
        return this.post('', tokens)
    }

    public deleteCT(data: ICategoriaToken) {
        return this.deleteByBody('', data)
    }
}