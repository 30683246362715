import React, { FunctionComponent, ReactElement } from 'react';
import { ButtonToolbar, OverlayTrigger } from 'react-bootstrap';
import { OverlayChildren, Placement } from 'react-bootstrap/esm/Overlay';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';

interface IOverlayTrigger {
    children: ReactElement<any, string>;
    overlay: OverlayChildren;
    placement?: Placement;
    delayHide?: number;
    delayShow?: number;
    trigger?: OverlayTriggerType[];
}

/**
 * Componente de função Primário ao criar um popover
 * É obrigatório:
 *  overlay: Componente secundário que é o popover disparado {DefaultPopover}
 * É opcional:
 *  placement: Local onde o popover será exibido no componente filho, default: top
 *  delayHide: Tempo de delay para esconder o popover
 *  delayShow: Tempo de delay para exibir o popover
 */
const PopoverOverlay: FunctionComponent<IOverlayTrigger> = ({
    children,
    overlay,
    placement: placement = 'top',
    delayHide: hide = 50,
    delayShow: show = 100,
    trigger: trigger = ['hover', 'focus', 'click'],
}: React.PropsWithChildren<IOverlayTrigger>) => {
    return (
        <ButtonToolbar>
            <OverlayTrigger
                delay={{
                    show,
                    hide,
                }}
                rootClose
                trigger={trigger}
                placement={placement}
                overlay={overlay}
            >
                {children}
            </OverlayTrigger>
        </ButtonToolbar>
    );
};

export default PopoverOverlay;
