import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';

const VideoContainer = styled.div``;

export interface IDefaultVideoFrame {
    url: string;
    width: number;
    height: number;
}

const DefaultVideoFrame = ({ url, width, height }: IDefaultVideoFrame) => {
    const [iframeSize, setIframeSize] = useState<{ w: number; h: number }>({ h: height, w: width });

    const calculateIframe = () => {
        const innerWidth = window.innerWidth;

        if (innerWidth <= ResizeMediumWidth) {
            const box = document.getElementById('container-video');
            if (box) {
                let w = box.clientWidth - 24;
                let h = Math.floor(w * 0.56);

                if (w < width && h < height) {
                    setIframeSize({ w: w, h: h });
                } else {
                    setIframeSize({ w: width, h: height });
                }
            } else {
                setIframeSize({ w: width, h: height });
            }
        }
    };

    useEffect(() => {
        calculateIframe();
        window.addEventListener('resize', calculateIframe);
    }, []);

    return (
        <VideoContainer id="container-video">
            <iframe src={`//${url}`} width={iframeSize.w} height={iframeSize.h} />
        </VideoContainer>
    );
};

export default DefaultVideoFrame;
