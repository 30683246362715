import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormLabel from '../formik/FormLabel';
import AvatarGrid, { AVATAR_LIST_SIZE } from './components/AvatarGrid';
import FormHeader from '../formHeader/FormHeader';
import ImagePreview from './components/ImageSelect';
import TextDescricao from './components/TextDescricao';
import { ProgressConsumer } from '../../ProgressContext';
import ProfileData from '../../../../models/ProfileData';
import FormikReactSelect from '../formik/FormikReactSelect';
import ImageSelectMobile from './components/ImageSelectMobile';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import { storeProfileData } from '../../../../store/actions/Profile';
import TipoImagem from '../../../../models/imagemPerfil/TipoImagemEnum';
import ImagemPerfil from '../../../../models/imagemPerfil/ImagemPerfil';
import { ConfirmButton, FixedBottom } from './components/ImageSelect.styled';
import ParametrosCadastroPerfilDTO from '../../../../models/perfil/ParametrosCadastroPerfilDTO';
import { IdentificacaoImagemStyle, FormRow, FormContainer, ImgSection, SvgFadeImg } from './IdentificacaoImagem.styled';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';

import srcImageDefault from '../../../../images/profile/identificacao.svg';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const IdentificacaoImagem = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const statusProgresso: number = 68;
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [tipoSelected, setTipoSelected] = useState<TipoImagem>(TipoImagem.NoSelected);

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    useEffect(() => {
        setIsMobile(window.screen.width <= MobileMaxWidth);

        document.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        });

        clearImagemperfil();
    }, []);

    useEffect(() => {
        clearImagemperfil();

        if (tipoSelected === TipoImagem.EscolherDepois) {
            selectNullAvatar();
        }
    }, [tipoSelected]);

    const profileParameterData: ParametrosCadastroPerfilDTO = useAppSelector(state => state.profileReducer.parametrosCadastro);

    const clearImagemperfil = () => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.NoSelected,
                imageBase64: null,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    const selectNullAvatar = () => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.EscolherDepois,
                codigoAvatar: -1,
                imageBase64: '',
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));

            onContinuarSemConfirmacao();
        }
    };

    const title: string = `Como você gostaria de se identificar?`;
    const subtitle: string = `Você pode selecionar uma imagem ou escolher um avatar!`;

    const onContinuar = () => {
        history.push('/profile/identificacaoImagemConfirmar');
    };

    const onContinuarSemConfirmacao = () => {
        history.push('/profile/regrasConvivencia');
    };

    const onContinuarEdicao = () => {
        history.push('/profile/identificacaoImagemEditar');
    };

    const changeTipoByValue = (val: number) => {
        let tipoSelected = TipoImagem.NoSelected;

        switch (val) {
            case 1:
                tipoSelected = TipoImagem.Avatar;
                break;
            case 2:
                tipoSelected = TipoImagem.Imagem;
                break;
            default:
                tipoSelected = TipoImagem.EscolherDepois;
                break;
        }

        setTipoSelected(tipoSelected);
    };

    return (
        <ProgressConsumer>
            {value => {
                const { progresso, setProgresso } = value;
                if (statusProgresso > progresso) {
                    setProgresso(statusProgresso);
                }
                return (
                    <IdentificacaoImagemStyle>
                        <FormContainer>
                            <FormHeader title={title} subtitle={subtitle} />

                            <Formik
                                initialValues={{
                                    tipoIdentificacaoEnum: TipoImagem.NoSelected,
                                }}
                                onSubmit={console.log}
                            >
                                {formik => {
                                    return (
                                        <>
                                            <FormRow>
                                                <FormLabel label="Eu prefiro usar um (a)" />
                                                <FormikReactSelect
                                                    formik={formik}
                                                    size={180}
                                                    name="tipoIdentificacaoEnum"
                                                    options={[
                                                        ...profileParameterData.listaTipoIdentificacao,
                                                        { id: 0, nome: 'Escolher Depois' },
                                                    ]}
                                                    onChangeHandler={changeTipoByValue}
                                                />
                                            </FormRow>

                                            <TextDescricao tipo={tipoSelected} />

                                            {tipoSelected === TipoImagem.Avatar && <AvatarGrid />}

                                            {!isMobile || tipoSelected === TipoImagem.Avatar ? (
                                                <ConfirmButton
                                                    disabled={profileData?.imagemPerfil?.tipoImagem === TipoImagem.NoSelected}
                                                    onClick={onContinuar}
                                                >
                                                    Continuar
                                                </ConfirmButton>
                                            ) : (
                                                <FixedBottom fixed={tipoSelected === TipoImagem.NoSelected}>
                                                    {tipoSelected === TipoImagem.Imagem && !profileData?.imagemPerfil?.imageBase64 && (
                                                        <ImageSelectMobile onSelected={onContinuarEdicao} />
                                                    )}
                                                    <ConfirmButton
                                                        disabled={profileData?.imagemPerfil?.tipoImagem === TipoImagem.NoSelected}
                                                        onClick={onContinuar}
                                                    >
                                                        Continuar
                                                    </ConfirmButton>
                                                </FixedBottom>
                                            )}
                                        </>
                                    );
                                }}
                            </Formik>
                        </FormContainer>

                        {tipoSelected !== TipoImagem.Avatar && (
                            <ImgSection>
                                {tipoSelected === TipoImagem.NoSelected && <SvgFadeImg src={srcImageDefault} />}
                                {!isMobile && tipoSelected === TipoImagem.Imagem && <ImagePreview onSelected={onContinuarEdicao} />}
                            </ImgSection>
                        )}
                    </IdentificacaoImagemStyle>
                );
            }}
        </ProgressConsumer>
    );
};

export default IdentificacaoImagem;
