import { createContext } from 'react';

import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';
import BiblioItemCategoria from 'models/biblioteca/BiblioItemCategoriaModel';

export namespace BibliotecaContext {
    export interface IContext {
        loading: boolean;
        setLoading: (value: boolean) => void;

        categorias: BiblioItemCategoria[];

        categoriaActive: string;
        setCategoriaActive: (value: string) => void;

        hasMore: boolean;

        setFavoriteItem: (idItem: number, value: boolean) => void;

        resultItens?: BiblioItemPreview[];

        clearItens: () => void;

        tagsFilter: string[];
        setTagsFilter: (tags: string[]) => void;
        fetchItensByFilter: (_tags: string[], filtro: string, page?: number) => void;
        showContainerTag: boolean;
        getCategoria: () => Promise<void>;

        filtroLabel: string;
        removeFiltroLabel: () => void;

        categoriaLabel: string;
        removeCategoriaLabel: () => void;
    }

    export const Context = createContext({} as IContext);
}
