import Select from 'components/Select';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminBiblioNewContentStyled = {
    Container: styled.div`
        margin-left: 120px;
        margin-right: 22px;
        background-color: white;
        padding: 20px;
    `,

    TopBar: styled.div`
        width: 100%;
        background-color: #fff;
        box-shadow: 1px 1px 10px rgba(0, 60, 120, 0.1);
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    TextTop: styled.p`
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #747474;
        margin-right: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 16px;
        }
    `,

    Select: styled(Select)``,
};

export default AdminBiblioNewContentStyled;
