import styled from 'styled-components';

import Colors from '../../enums/Colors';
import { IsMobileMaxWidth } from '../../core/util/WindowSize';
import { MobileMaxWidth } from 'constants/Dimensions';

export namespace DiarioBordoStyle {
    export const Container = styled.div`
        display: flex;
        margin: 0 auto;
        margin-top: 60px;
        border-radius: 5px;
        box-sizing: border-box;
        flex-direction: column;
        background: ${Colors.White};
        padding: 20px 30px;
        border: 1px solid ${Colors.Grey6};
        max-width: 1253px;

        min-height: calc(100vh - 85px);

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 10px 15px;
            margin-top: 0px;
        }
    `;

    export const TopContainer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    `;

    export const EmptyContainer = styled.div`
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    `;

    export const Row = styled.div`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    `;

    export const EmptyText = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        width: 390px;
        color: #003c78;
        transform: translateY(-60px);
    `;

    export const NewDiaryButton = styled.button`
        background: #00a8e9;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: 180px;
        height: 50px;
        border: unset;
        color: white;
        outline: unset;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        :hover {
            background: ${Colors.White};
            color: ${Colors.SoftBlue};
            border: 1px ${Colors.SoftBlue} solid;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 160px;
            height: 32px;
            font-size: 17px;
            line-height: 24px;
            background-color: #ffcb00;
            padding: 0;

            :hover {
                color: ${Colors.DarkBlue2};
                background: #ffcb00;
                border: 1px #ffcb00 solid;
            }
        }
    `;

    export const Title = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #003c78;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 18px;
            line-height: 22px;
        }
    `;

    export const Content = styled.div`
        display: flex;
        background-color: #f2fbfe;
        border-radius: 0px 5px;
        padding-top: 0px;
        height: calc(100vh - 100px);
        padding-bottom: 10px;
    `;

    export const TopBar = styled.div`
        background-color: #00a8e9;
        color: #fcfcfc;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 102.5%;
        padding: 12px 0;
        text-align: center;
    `;

    export const Box = styled.div`
        background: #fcfcfc;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 20px;
    `;

    export const SubTitle = styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
        width: 100%;
        text-align: center;
    `;

    export const Center = styled.p`
        text-align: center;
        width: 100%;
    `;
}
