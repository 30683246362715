import React, { useEffect, useRef, useState } from 'react';
import { Radio, RadioGroup } from 'react-radio-input';

import { Form } from 'react-bootstrap';
import { GradientButton, OutlineButton } from 'components/UI/Buttons';

import Styled from './AdminBiblioNew.styled';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { acceptedExtsLivro, acceptedLanguages, getExtension, isFileNotNull, isSaveRascunho, isStringsValid } from './isValidNewContentInput';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminBiblioItemSave from 'models/admin/AdminBiblioItemSave';

import closeFileIcon from '../../../../images/admin/close-item.svg';
import submitAttach, {
    AdminBiblioAnexoType,
    dataURItoBlob,
    fecthAttach,
    fecthAttachImage,
    fetchTagsItemId,
    MAX_FILE_BYTES,
} from './submitAttach';
import { IAdminBiblioEditorContent } from 'pages/admin/subpages/biblio/post/AdminBiblioNewContent';
import AdminTagSelector from '../tagSelector/AdminTagSelector';
import Select, { SelectOptionType } from 'components/Select';
import { StringHelp } from '../../../../core/util/StringHelp';
import AnexoConteudo from 'models/biblioteca/AnexoConteudo';
import { DateHelp } from 'core/util/DateHelp';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { ToastError } from 'components/Toaster';
import ItemBibliotecaRelatedContentListagem from '../itemBibliotecaRelatedContentList/ItemBibliotecaRelatedContentListagem';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';

export interface IAdminBiblioNewLivro {
    onSaved: () => void;
}

const isMobile = () => IsMobileMaxWidth();

const AdminBiblioNewLivro = ({ onSaved, itemData, categoriaId, categorias }: IAdminBiblioEditorContent) => {
    const [id, setId] = useState<number>(-1);
    const [slug, setSlug] = useState<string>('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [authors, setAuthors] = useState('');
    const [year, setYear] = useState<number>(0);
    const [language, setLanguage] = useState('');
    const [numberPage, setNumberPage] = useState(0);

    const [tags, setTags] = useState<string[]>([]);
    const [titleTag, setTitleTag] = useState('');
    const [metaDescriptionTag, setMetaDescriptionTag] = useState('');
    const [isPublic, setIsPublic] = useState('false');

    const [imageCover, setImageCover] = useState<File>();
    const [attach, setAttach] = useState<File | null>();
    const inputElement = useRef<HTMLInputElement>(null);

    const [preimageCover, setPreImageCover] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [dataAtual, setDataAtual] = useState<Date>(new Date());

    const isValidInputs = (): boolean => {
        if (!imageCover && !preimageCover) return false;
        return isStringsValid([title, content, authors, slug]);
    };

    const categoriaExistente = (): boolean => {
        return categorias.filter(c => c.ativo).some(c => c.id == categoriaId);
    };

    const submit = async (_status: 'PUBLICADO' | 'RASCUNHO') => {
        if (year < 1000 || year > dataAtual.getFullYear()) {
            ToastError('Ano de publicação inválido');
            return false;
        }

        if (numberPage < 1) {
            ToastError('Número de páginas inválido');
            return false;
        }

        if (attach == null) {
            ToastError('Arquivo anexo inválido ou não selecionado');
            return false;
        }

        if (attach.size >= MAX_FILE_BYTES) {
            ToastError('Arquivo anexado muito grande (máximo 15MB)');
            return false;
        }

        setIsSubmitting(true);

        const service = new AdminBiblioService();

        const itemSave = new AdminBiblioItemSave();

        itemSave.slug = slug;
        itemSave.titulo = title;
        itemSave.conteudo = content;
        itemSave.tipoItemBiblioteca = categoriaId;
        itemSave.status = _status;
        itemSave.publico = isPublic === 'true';
        itemSave.tags = tags;
        itemSave.titleTag = titleTag;
        itemSave.metaDescriptionTag = metaDescriptionTag;

        if (itemSave.publico && !categoriaExistente()) {
            alert('Não é possível publicar um item de um categoria inativa');
            setIsSubmitting(false);
            return;
        }

        const date = new Date(year, 1, 1);
        itemSave.anoPublicacao = date.toISOString();

        itemSave.numeroPaginas = numberPage;
        itemSave.idioma = language;

        itemSave.autores = authors;

        let data, status: number;
        let successfullRequests: boolean = true;

        const responseUnicidade = await service.validarUnicidadeSlug(itemData?.id ?? null, itemSave.slug);
        let slugValido: boolean = false;
        if (responseUnicidade.status === 200 && responseUnicidade.data === true) {
            slugValido = true;
        }

        if (slugValido) {
            if (!itemData) {
                const response = await service.criarItem(itemSave);
                data = response.data;
                status = response.status;
            } else {
                const response = await service.atualizarItem2(itemData.id, itemSave, tags);
                data = response.data;
                status = response.status;
            }

            if (status == 200) {
                if (attach) {
                    try {
                        const { status: statusAttach } = await submitAttach(data.id, attach, AdminBiblioAnexoType.CONTEUDO);
                        if (statusAttach == 200) successfullRequests = true;
                        else throw new Error('O item foi salvo mas houve uma falha no carregamento do anexo (CONTEÚDO), tente novamente');
                    } catch (error) {
                        successfullRequests = false;
                        ToastError(String(error).split(':')[1]);
                        setAttach(null);
                    }
                }

                if (imageCover && successfullRequests) {
                    try {
                        const { status: statusAttach } = await submitAttach(data.id, imageCover, AdminBiblioAnexoType.CAPA);
                        if (statusAttach == 200) successfullRequests = true;
                        else throw new Error();
                    } catch (error) {
                        successfullRequests = false;
                        ToastError(String(error).split(':')[1]);
                        setImageCover(undefined);
                    }
                }
            }

            if (successfullRequests) onSaved();
        } else {
            ToastError('URL Slug já cadastrado para outro conteúdo');
        }

        setIsSubmitting(false);
    };

    const chooseFile = () => {
        if (inputElement?.current) {
            inputElement.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.files && target.files[0]) {
                    const file = target.files[0];
                    const filename = file.name;
                    const ext = getExtension(filename);

                    if (acceptedExtsLivro.includes(ext.toLowerCase())) setAttach(file);
                    else ToastError('Formato de arquivo inválido');
                }
            };
            inputElement.current.click();
        }
    };

    const fillData = async () => {
        //TODO: trazer isPublic
        //itemData?.yearPublication
        if (itemData != null) {
            setSlug(itemData.slug);
            setId(itemData.id);
            setContent(itemData.content ?? '');
            setTitle(itemData.title);
            setAuthors(itemData.authors);
            setTags(itemData.tags ?? []);
            setIsPublic('false');
            setYear(DateHelp.getYear(String(itemData.yearPublication)));
            setNumberPage(itemData.numberPages ?? 0);
            setLanguage(itemData.language ?? '');
            setTitleTag(itemData.titleTag);
            setMetaDescriptionTag(itemData.metaDescriptionTag);
            const _imageCover = await fecthAttachImage(itemData.id, AdminBiblioAnexoType.CAPA);
            if (_imageCover) setPreImageCover(_imageCover);
            const tags = await fetchTagsItemId(itemData.id);
            setTags(tags);

            const content: AnexoConteudo = await fecthAttach(itemData.id, AdminBiblioAnexoType.CONTEUDO);

            if (content) {
                //console.log(content);
                const blob = dataURItoBlob('data:application/*;base64,' + content.base64Content);
                const myFile = new File([blob], content.fileName);
                setAttach(myFile);
            }
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.FormContainer>
                    {isMobile() && (
                        <Styled.ImageContainer>
                            <AdminImageUploader
                                onSelect={(_, f) => setImageCover(f)}
                                size={'sm'}
                                title={'Imagem da capa'}
                                preImageB64={preimageCover}
                                recommendedDimension={{ w: 200, h: 130 }}
                            />
                        </Styled.ImageContainer>
                    )}

                    <br />

                    <Styled.Label>Url Slug</Styled.Label>
                    <Styled.Input
                        value={slug}
                        onChange={(e: any) => setSlug(StringHelp.alnum(StringHelp.toSize(e.target.value.toLowerCase().trim(), 150)))}
                    />
                    <br />
                    <Styled.Label>Título</Styled.Label>
                    <Styled.Input value={title} onChange={(e: any) => setTitle(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Autores</Styled.Label>
                    <Styled.Input value={authors} onChange={(e: any) => setAuthors(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Descrição</Styled.Label>
                    <ReactQuill
                        theme="snow"
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        value={content}
                        onChange={val => {
                            setContent(val);
                        }}
                    />

                    <Styled.RowGridLivro>
                        <Styled.ColumsGrid>
                            <Styled.Label>Ano de publicação</Styled.Label>
                            <Styled.Input
                                className={year != 0 && (year < 1000 || year > dataAtual.getFullYear()) && 'is-invalid'}
                                type="number"
                                value={year != 0 ? year : ''}
                                onChange={(e: any) => setYear(e.target.value)}
                            />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Número de páginas</Styled.Label>
                            <Styled.Input
                                className={numberPage < 0 && 'is-invalid'}
                                type="number"
                                value={numberPage != 0 ? numberPage : ''}
                                onChange={(e: any) => setNumberPage(e.target.value)}
                            />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Idioma</Styled.Label>
                            <Select
                                itens={acceptedLanguages}
                                onSelected={(o: SelectOptionType) => setLanguage(String(o.value))}
                                isSmall={true}
                                value={acceptedLanguages.find(o => o.value === language)}
                            />
                        </Styled.ColumsGrid>
                    </Styled.RowGridLivro>
                    <br />
                    <Styled.Label style={{ marginBottom: '10px' }}>Arquivo do livro</Styled.Label>

                    {!attach ? (
                        <>
                            <GradientButton onClick={chooseFile} style={{ fontSize: '13px' }}>
                                Adicionar arquivo
                            </GradientButton>

                            <Styled.Hidden ref={inputElement} type="file" id="file-input" />
                        </>
                    ) : (
                        <Styled.FileHolder>
                            <p>{attach.name}</p>
                            <div>
                                <Styled.FileHolderIcon src={closeFileIcon} onClick={() => setAttach(null)} />
                            </div>
                        </Styled.FileHolder>
                    )}

                    <br />
                    <br />

                    <AdminTagSelector tags={tags} onSelected={(newTags: string[]) => setTags(newTags)} />

                    <br />
                    <Styled.Label>Título da Página</Styled.Label>
                    <Styled.Input value={titleTag} onChange={(e: any) => setTitleTag(StringHelp.toSize(e.target.value, 150))} />

                    <br />
                    <Styled.Label>Meta Description</Styled.Label>
                    <Styled.Input
                        value={metaDescriptionTag}
                        onChange={(e: any) => setMetaDescriptionTag(StringHelp.toSize(e.target.value, 500))}
                    />

                    <br />

                    <Styled.Label>Tipo de visualização</Styled.Label>

                    <Styled.CkeckContainer style={{ paddingTop: '12px' }}>
                        <RadioGroup name={'isPublic'} onChange={setIsPublic} selectedValue={'false'}>
                            <label htmlFor="isPublic">
                                <Radio id="isPublic" value={'true'} />
                                <Styled.TextCheckBox>Público</Styled.TextCheckBox>
                            </label>

                            <label htmlFor="isPrivate">
                                <Radio id="isPrivate" value={'false'} />
                                <Styled.TextCheckBox>Privado</Styled.TextCheckBox>
                            </label>
                        </RadioGroup>
                    </Styled.CkeckContainer>
                    <br />
                    <ItemBibliotecaRelatedContentListagem itemData={itemData} />
                    <br />
                    <Styled.BottomRight>
                        {isSaveRascunho(itemData) && (
                            <OutlineButton disabled={!isValidInputs() || isSubmitting} onClick={() => submit('RASCUNHO')}>
                                {isSubmitting ? 'Salvando...' : 'Salvar Rascunho'}
                            </OutlineButton>
                        )}
                        <GradientButton
                            disabled={!isValidInputs() || isSubmitting}
                            style={{ marginLeft: '10px' }}
                            onClick={() => submit('PUBLICADO')}
                        >
                            {isSubmitting ? 'Salvando...' : 'Publicar'}
                        </GradientButton>
                    </Styled.BottomRight>
                </Styled.FormContainer>

                {!isMobile() && (
                    <Styled.ImageContainer>
                        <AdminImageUploader
                            onSelect={(_, f) => setImageCover(f)}
                            size={'sm'}
                            title={'Imagem da capa'}
                            preImageB64={preimageCover}
                            recommendedDimension={{ w: 200, h: 130 }}
                        />
                    </Styled.ImageContainer>
                )}
            </Styled.Row>
        </Styled.Container>
    );
};

export default AdminBiblioNewLivro;
