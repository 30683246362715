import React, { useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton } from 'components/UI/Buttons';
import { cloneDeep } from 'lodash';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import submitAttach from './submitAttach';
import { isStringsValid } from '../newContent/isValidNewContentInput';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { ToastError } from 'components/Toaster';

export interface IAdminEditTestemonial {
    onSaved: () => void;
}

const isMobile = () => IsMobileMaxWidth();

const AdminEditTestemonial = ({ onSaved }: IAdminEditTestemonial) => {
    const [testemonials, setTestemonials] = useState<AdminHomeConteudo[]>([]);

    const MAX_CHAR_TITLE = 40;
    const MAX_CHAR_CONTENT = 240;

    const isValidInputs = (idx: number): boolean => {
        const testemonial = testemonials[idx];

        if (testemonial.attach == null) {
            if (testemonial.precover && testemonial.precover.trim().length == 0) return false;
        }

        return isStringsValid([testemonial.title, testemonial.content, testemonial.occupation ?? '']);
    };

    const fetchItens = async () => {
        const service = new AdminHomeConteudoService();
        const { data } = await service.buscaConteudo('DEPOIMENTOS');

        const _testemonials: AdminHomeConteudo[] = data.map((t: any) => new AdminHomeConteudo().fromJSON(t));

        for (let i = 0; i < _testemonials.length; i++) {
            const response = await service.buscarAnexoConteudo(_testemonials[i].id, true);

            if (response.status == 200 && response.data.base64Content) {
                const precover = 'data:image/*;base64,' + response.data.base64Content;
                _testemonials[i].precover = precover;
            }
        }

        fillArray(_testemonials);
    };

    const fillArray = (t: AdminHomeConteudo[]) => {
        const _testemonials = cloneDeep(t);
        const length = _testemonials.length;

        if (length < 5) {
            for (let i = 0; i < 5 - length; i++) {
                _testemonials.push({ title: '', occupation: '', content: '', id: -1 } as AdminHomeConteudo);
            }
        }

        setTestemonials(_testemonials);
    };

    const submit = async (complemento: number) => {
        const service = new AdminHomeConteudoService();

        const { data } = await service.buscaConteudo('DEPOIMENTOS');
        const result: AdminHomeConteudo[] = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));

        const testemonial = testemonials[complemento];
        const { title, content, occupation } = testemonial;

        if (title.length > MAX_CHAR_TITLE) {
            ToastError(`O título deste depoimento possui excedente do limite (${MAX_CHAR_TITLE}) de caracteres`);
            return false;
        }

        if (occupation && occupation.length > MAX_CHAR_TITLE) {
            ToastError(`A profissão/ocupação deste depoimento possui excedente do limite (${MAX_CHAR_TITLE}) de caracteres`);
            return false;
        }

        if (content.length > MAX_CHAR_CONTENT) {
            ToastError('O conteúdo deste depoimento possui excedente do limite de caracteres');
            return false;
        }

        const existentTest = result.find(r => r.complement == String(complemento));

        let data2, status: number;

        if (existentTest) {
            const response = await service.atualizarDepoimento(existentTest.id, title, content, occupation ?? '', String(complemento));
            data2 = response.data;
            status = response.status;
        } else {
            const response = await service.submeterDepoimento(title, content, occupation ?? '', String(complemento));
            data2 = response.data;
            status = response.status;
        }

        if (status == 200) {
            if (testemonial.attach) await submitAttach(data2.id, testemonial.attach);
            onSaved();
        }
    };

    const setName = (idx: number, value: string) => {
        const clone = cloneDeep(testemonials);
        clone[idx].title = value;
        setTestemonials(clone);
    };

    const setOccupation = (idx: number, value: string) => {
        const clone = cloneDeep(testemonials);
        clone[idx].occupation = value;
        setTestemonials(clone);
    };

    const setText = (idx: number, value: string) => {
        const clone = cloneDeep(testemonials);
        clone[idx].content = value;
        setTestemonials(clone);
    };

    const setCover = (idx: number, value: File) => {
        const clone = cloneDeep(testemonials);
        clone[idx].attach = value;
        setTestemonials(clone);
    };

    /*     const fetchCovers = async () => {

        for (let i = 0; i < testemonials.length; i++) {
            if (testemonials[i].id != -1 && testemonials[i].precover == null) {
                const service = new AdminHomeConteudoService();
                const response = await service.buscarAnexoConteudo(testemonials[i].id);

                if (response.status == 200 && response.data.base64Content) {
                    const precover = 'data:image/*;base64,' + response.data.base64Content;

                    const clone = cloneDeep(testemonials);
                    clone[i].precover = precover;
                    setTestemonials(clone);
                }
            }
        }
    }; */

    useEffect(() => {
        fetchItens().then(() => {
            //fetchCovers();
        });
    }, []);

    return (
        <Styled.ContainerTestemonial>
            {!isMobile()
                ? testemonials.map((_, idx) => {
                      return (
                          <React.Fragment key={idx}>
                              <Styled.NumberIndex>{idx + 1}</Styled.NumberIndex>
                              <div>
                                  <Styled.Title>Depoimento</Styled.Title>
                                  <br />

                                  <Styled.Label style={{ marginTop: '0' }}>Nome</Styled.Label>
                                  <Styled.Input value={testemonials[idx].title} onChange={(evt: any) => setName(idx, evt.target.value)} />
                                  <br />
                                  <Styled.Label style={{ marginTop: '0' }}>Ocupação</Styled.Label>
                                  <Styled.Input
                                      value={testemonials[idx].occupation}
                                      onChange={(evt: any) => setOccupation(idx, evt.target.value)}
                                  />
                                  <br />
                                  <Styled.Label style={{ marginTop: '0' }}>Texto</Styled.Label>
                                  <Styled.Input
                                      value={testemonials[idx].content}
                                      onChange={(evt: any) => setText(idx, evt.target.value)}
                                      rows={8}
                                      as="textarea"
                                      className={'form-control'}
                                      maxLength={MAX_CHAR_CONTENT}
                                  />
                                  <Styled.CountLetter danger={testemonials[idx].content.length >= MAX_CHAR_CONTENT}>
                                      {testemonials[idx].content.length}/{MAX_CHAR_CONTENT}
                                  </Styled.CountLetter>

                                  <Styled.RightContainer>
                                      <GradientButton onClick={() => submit(idx)} disabled={!isValidInputs(idx)}>
                                          Salvar
                                      </GradientButton>
                                  </Styled.RightContainer>

                                  <hr />
                                  <br />
                              </div>

                              <Styled.ImageContainer>
                                  <AdminImageUploader
                                      onSelect={(_, file: File) => setCover(idx, file)}
                                      size={'sm'}
                                      title={'Imagem do banner'}
                                      preImageB64={testemonials[idx].precover}
                                      recommendedDimension={{ w: 150, h: 85 }}
                                  />
                              </Styled.ImageContainer>
                          </React.Fragment>
                      );
                  })
                : testemonials.map((_, idx) => {
                      return (
                          <div key={idx}>
                              <Styled.Row>
                                  <Styled.NumberIndex>{idx + 1}</Styled.NumberIndex>
                                  <Styled.Title style={{ marginLeft: '10px' }}>Depoimento</Styled.Title>
                              </Styled.Row>

                              <div style={{ marginTop: '-20px' }}>
                                  <Styled.ImageContainer>
                                      <AdminImageUploader
                                          onSelect={(_, file: File) => setCover(idx, file)}
                                          size={'sm'}
                                          title={'Imagem do banner'}
                                          preImageB64={testemonials[idx].precover}
                                          recommendedDimension={{ w: 150, h: 85 }}
                                      />
                                  </Styled.ImageContainer>
                                  <br />

                                  <Styled.Label style={{ marginTop: '0' }}>Nome</Styled.Label>
                                  <Styled.Input value={testemonials[idx].title} onChange={(evt: any) => setName(idx, evt.target.value)} />
                                  <br />
                                  <Styled.Label style={{ marginTop: '0' }}>Ocupação</Styled.Label>
                                  <Styled.Input
                                      value={testemonials[idx].occupation}
                                      onChange={(evt: any) => setOccupation(idx, evt.target.value)}
                                  />
                                  <br />
                                  <Styled.Label style={{ marginTop: '0' }}>Texto</Styled.Label>
                                  <Styled.Input
                                      value={testemonials[idx].content}
                                      onChange={(evt: any) => setText(idx, evt.target.value)}
                                      rows={8}
                                      as="textarea"
                                      className={'form-control'}
                                      maxLength={MAX_CHAR_CONTENT}
                                  />
                                  <Styled.CountLetter danger={testemonials[idx].content.length >= MAX_CHAR_CONTENT}>
                                      {testemonials[idx].content.length}/{MAX_CHAR_CONTENT}
                                  </Styled.CountLetter>

                                  <Styled.RightContainer>
                                      <GradientButton onClick={() => submit(idx)} disabled={!isValidInputs(idx)}>
                                          Salvar
                                      </GradientButton>
                                  </Styled.RightContainer>

                                  <hr />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                              </div>
                          </div>
                      );
                  })}
            <br />
        </Styled.ContainerTestemonial>
    );
};

export default AdminEditTestemonial;
