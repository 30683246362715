import { TipoAnexoConteudo } from './TipoAnexoConteudo';
import { jsonProperty, Serializable } from "ts-serializable";

export class RespostasRecursosCostumizados extends Serializable {
  @jsonProperty(Number, null)
  codigo: number | null = -1;

  @jsonProperty(Number, null)
  codigoComentario: number | null = -1;
  
  @jsonProperty(String, null)
  conteudo: string = '';

  @jsonProperty(String, null)
  nomePerfil: string = '';

  @jsonProperty(String, null)
  ultimaAtualizacao: string = ''; 
}