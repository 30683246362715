import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class PickListModel extends Serializable {
    @jsonProperty(Number, null)
    id: number | null = null;

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(Boolean)
    isSelected: boolean = false;

    @jsonProperty(Boolean)
    isOutroItem: boolean = false;

    constructor() {
        super();
    }
}

export default PickListModel;
