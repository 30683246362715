import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { jsonProperty, Serializable } from 'ts-serializable';

class ComponentSanfonaConteudoModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(String)
    tipoConteudo: ComponentInteractionEnum = ComponentInteractionEnum.NENHUM;
}

export default ComponentSanfonaConteudoModel;