import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import { GradientButton } from 'components/UI/Buttons';
import AdminTag from 'models/admin/AdminTag';

import closeIcon from '../../../../../images/close-circle.svg';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import { ToastError } from 'components/Toaster';

export interface IAdminModalEditTag {
    show: boolean;
    onHide: () => void;
    onEdit: () => void;
    tag?: AdminTag;
}

const AdminModalEditTag = ({ show, onEdit, onHide, tag }: IAdminModalEditTag) => {
    const [newTag, setNewTag] = useState<string>(tag?.content ?? '');
    const [isLoading, setLoading] = useState<boolean>(false);

    const edit = async () => {
        setLoading(true);
        let exists: boolean = false;
        const service = new AdminBiblioService();
        const { data } = await service.buscarAllTags();
        const rows = data.rows;
        const _tags: AdminTag[] = rows.map((tag: any) => new AdminTag().fromJSON(tag));

        _tags.forEach(t => {
            if (t.content.toLowerCase() === newTag.toLowerCase()) {
                exists = true;
            }
        });

        if (!exists) {
            if (tag) {
                await service.editarTag(tag.id, newTag);
                setNewTag('');
                onEdit();
            }
        } else {
            ToastError('Tag já existente');
        }
        setLoading(false);
    };

    useEffect(() => {
        if (tag) setNewTag(tag.content);
    }, [tag]);

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            {tag && (
                <Styled.ContainerModal>
                    <Styled.CloseContainer>
                        <Styled.Icon onClick={onHide} src={closeIcon} />
                    </Styled.CloseContainer>
                    <Styled.ModalEditTitle>Editar tag</Styled.ModalEditTitle>
                    <Styled.Text style={{ textAlign: 'left', width: '100%' }}>Editar tag existente</Styled.Text>
                    <br />
                    <Styled.Input value={newTag} onChange={(evt: any) => setNewTag(evt.target.value)} />
                    <br />
                    <Styled.RightContainer>
                        <GradientButton disabled={isLoading} onClick={edit}>
                            Salvar
                        </GradientButton>
                    </Styled.RightContainer>
                </Styled.ContainerModal>
            )}
        </Styled.Modal>
    );
};

export default AdminModalEditTag;
