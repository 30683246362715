import styled from 'styled-components';
import Colors from '../../enums/Colors';
import { MobileMaxWidth } from '../../constants/Dimensions';
import { media760 } from '../../core/util/cssHelp';
import FullCss from 'core/util/FullCss';

export const PlanoDesenvolvimentoPessoalStyle = styled.div`
    background-color: ${Colors.White};
    padding: 50px 10px;
    border-radius: 5px;
    margin-top: 60px;

    @media (max-width: ${MobileMaxWidth}px) {
        padding: 0;
        margin-top: 26px;
        width: 100%;
    }
`;

export const Separator = styled.div`
    border: 1px solid;
    border-color: ${Colors.Grey8};
    margin: 50px 0 0 0;
`;

export const Container = styled.section`
    display: flex;
`;

export const SideMenuContainer = styled.section`
    width: 30%;
    height: 200px;
    border-right: 2px solid ${Colors.Grey8};
`;

export const ComponentContainer = styled.section`
    width: 70%;
`;

export const HeaderContainer = styled.div(
    media760(
        { display: 'flex', gap: '25%' },

        {
            paddingLeft: '20px',
            paddingRight: '20px',
            flexDirection: 'column'
        }
    )
);

export const SharingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`;

export const Text = styled.p(
    new FullCss({
        fontWeight: '700',
        fontSize: '14px',
        letterSpacing: '0.01em',
        lineHeight: '160%',
        color: Colors.Blue,
        fontFeatureSettings: "'calt' off",
    }).getCss()
);

export const OptionsContainer = styled.div(
    new FullCss({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px 26px',
        gap: '37px',
        width: '76px',
        height: '54px',
        background: '#00A8E9',
        borderRadius: '9px',
    }).getCss()
);

export const ImgIconSvg = styled.img(
    new FullCss({
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    }).getCss
);
