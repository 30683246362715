import AdminTopBar from 'pages/admin/components/barraSuperior/AdminTopBar';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Styled from './AdminPages.styled';

import arrowIcon from '../../../../images/admin/arrow-r.svg';
import AdminPageEditor from './AdminPageEditor';
import adminEditorSections from './adminEditorSections';
import { AdminContext } from 'pages/admin/context/AdminContext';

export interface IAdminPages {}

const AdminPages = ({}: IAdminPages) => {
    const { subPage, setSubPage } = useContext(AdminContext.Context);
    const history = useHistory();

    return (
        <Styled.Container>
            <AdminTopBar
                title={'Home da Plataforma Estruturante'}
                goBack={subPage === null ? false : true}
                pageReturn={() => setSubPage(null)}
            />
            <Styled.Content>
                {subPage == null ? (
                    <>
                        <Styled.Title>Olá Administrador!</Styled.Title>
                        <Styled.Text>Edite as principais informações da página ínicial</Styled.Text>

                        <br />
                        <br />

                        {adminEditorSections.map((section: string) => (
                            <Styled.RedirectButton key={section} onClick={() => setSubPage(section)}>
                                <span>Editar {section} </span>
                                <img src={arrowIcon} />
                            </Styled.RedirectButton>
                        ))}
                    </>
                ) : (
                    <AdminPageEditor section={subPage ?? ''} onChangeSection={section => setSubPage(section)} />
                )}
            </Styled.Content>
        </Styled.Container>
    );
};

export default AdminPages;
