import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export const MainContainer = styled.div<{ isFundamentos: boolean }>`
    display: flex;
    position: relative;
    gap: 10px;
    height: 250px;
    max-width: ${props => (!props.isFundamentos ? '279px' : '600px')};
    background-color: #f3f3f3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 16px;
    top: ${props => (props.isFundamentos ? '50px' : '0')};
    left: ${props => (props.isFundamentos ? '20%' : '0')};

    @media only screen and (max-width: 1250px) {
        left: ${props => (props.isFundamentos ? '26%' : '0')};
    }

    @media only screen and (max-width: 1200px) {
        left: ${props => (props.isFundamentos ? '24%' : '0')};
    }

    @media only screen and (max-width: 1100px) {
        left: ${props => (props.isFundamentos ? '22%' : '0')};
    }

    @media only screen and (max-width: 960px) {
        left: ${props => (props.isFundamentos ? '17%' : '0')};
    }

    @media only screen and (max-width: 890px) {
        left: ${props => (props.isFundamentos ? '15%' : '0')};
    }

    @media only screen and (max-width: 790px) {
        left: ${props => (props.isFundamentos ? '13%' : '0')};
    }

    @media only screen and (max-width: 760px) {
        left: ${props => (props.isFundamentos ? '0%' : '0')};
        max-width: 100%;
        height: ${props => (!props.isFundamentos ? 'auto' : '350px')};
    }

    @media only screen and (max-width: 320px) {
        max-height: 100%;
        height: 350px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Title = styled.p<{ isFundamentos: boolean }>`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: ${props => (props.isFundamentos ? '18px' : '16px')};
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #003c78;
    text-align: start;
`;

export const Text = styled(Title)`
    font-weight: 400;

    @media only screen and (max-width: 403px) {
        font-size: 12px;
    }
`;

export const IconCards = styled.img``;

export interface ICard {
    title: string;
    description: string;
    isFundamentos: boolean;
    icon?: string;
}

const Card = ({ title, description, isFundamentos, icon: img }: ICard) => {
    return (
        <MainContainer isFundamentos={isFundamentos}>
            <IconContainer>
                <IconCards src={img} />
            </IconContainer>

            <Container>
                <Title isFundamentos={isFundamentos}>{title}</Title>
                <Text isFundamentos={isFundamentos}>{description}</Text>
            </Container>
        </MainContainer>
    );
};

export default Card;
