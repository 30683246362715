import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledOverview = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-left: 70px;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 24px;

        @media screen and (max-width: 600px) {
            padding-left: 20px;
        }
    `,



    GoCallBack: styled.div`
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 26px;
        gap: 0.5rem;
        color: ${Colors.Blue};
        cursor: pointer;
        margin-bottom: 32px;
    `,

    NameCourse: styled.p`
        color: #ffca00;
        font-weight: 600;
        line-height: 26px;
    `,

    Title: styled.h1`
        font-size: 56px;
        line-height: 61px;
        color: ${Colors.Blue};
        font-weight: 800;
        padding-bottom: 24px;
    `,

    Description: styled.p`
        font-size: 20px;
        line-height: 36px;
        max-width: 80%;
        font-weight: 400;
        margin-bottom: 24px;
    `,

    RowBeetween: styled.div<{ imgInvible?: boolean }>`
        display: flex;
        width: 100%;
        flex-direction: ${({ imgInvible }) => (imgInvible ? 'row' : 'row-reverse')};

        justify-content: space-between;

        @media screen and (max-width: 1170px) {
            flex-direction: column-reverse;
            justify-content: start;
        }
    `,

    Row: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 50%;
        margin-right: 20px;

        @media screen and (max-width: 1600px) {
            width: 60%;
        }

        @media screen and (max-width: 1450px) {
            width: 70%;
        }

        @media screen and (max-width: 1170px) {
            width: 100%;
        }
    `,

    Column: styled.div`
        width: 50%;
        display: flex;
        flex-direction: column;
    `,

    Element: {
        Container: styled.div<{ bgColor: Colors; wdFull: boolean }>`
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            padding: 20px;
            width: ${({ wdFull }) => (wdFull ? '918px' : '455px')};
            background-color: ${({ bgColor }) => (bgColor ? bgColor : '')};

            @media screen and (max-width: 1920px) {
                width: ${({ wdFull }) => (wdFull ? '99%' : '49%')};
            }

            @media screen and (max-width: 880px) {
                width: 100%;
            }
        `,

        Content: styled.div<{ wdFull: boolean }>`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: ${({ wdFull }) => (wdFull ? '90%' : '80%')};
            height: 100%;

            @media screen and (max-width: 1450px) {
                width: 80%;
            }
        `,

        Title: styled.h1<{ color: Colors }>`
            color: ${({ color }) => (color ? color : '')};
            font-family: 'Barlow';
            font-weight: 700;
            font-size: 24px;
            line-height: 26px;
        `,

        Description: styled.p<{ color: Colors }>`
            color: ${({ color }) => (color ? color : '')};
            font-family: 'Barlow';
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        `,
    },

    Img: styled.img<{ height?: string }>`
        display: flex;
        align-self: center;
        position: relative;
        width: 400px;
        height: ${({ height }) => (height ? height : '350px')};
        margin-left: 10%;

        @media screen and (max-width: 1600px) {
            margin-left: 0%;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            top: 0px;
        }
    `,
};

export default StyledOverview;
