import Row from 'components/Row';
import keycloak from 'core/config/keycloak';
import { IsCustomMinWidth } from 'core/util/WindowSize';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import TextoApresentacao from '../../../../../../components/TextoApresentacao';
import Award from '../assets/award.svg';
import Cash from '../assets/cash.svg';
import Clock from '../assets/clock.svg';
import ModuloCPC from '../ModuloCPC';
import {
    YellowButton,
    WhiteText,
    SvgIcon,
    SliderContainer,
    OutlinerButton,
    ModuloContainer,
    MobileBox,
    ItemContainer,
    ImgSvg,
    Container,
    BoxContainer,
    Box,
    ArrowButton,
} from './CursoLivre.styled';
import { gotoSection } from './Inicio';

export interface ICursoLivre {}

const CursoLivre = ({}: ICursoLivre) => {
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= 1200);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= 1200);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={true} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={false} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292891C7.31658 -0.0976325 6.68342 -0.0976325 6.29289 0.292891L0.292894 6.29289C-0.0976308 6.68342 -0.0976309 7.31658 0.292894 7.7071L6.29289 13.7071Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    const openUrl = () => {
            history.push('/recursos/trilhas/cpc-na-escola');
    };

    const CursoLivreCss = () => {
        return IsCustomMinWidth(1200) ? { maxWidth: '650px' } : { maxWidth: '100%' };
    };

    return (
        <Container id="curso_section">
            <TextoApresentacao
                style={{ container: CursoLivreCss(), titulo: {}, subtitulo: {} }}
                tipo="Curso Livre"
                titulo="Criatividade e Pensamento Crítico na Escola"
                subtitulo="Conheça conceitos, referências e inspirações que possam contribuir para sua reflexão sobre o desenvolvimento da criatividade e do pensamento crítico entre si, no outro e na escola. Tenha acesso a atividades práticas para trabalhar com o desenvolvimento dessas habilidades em sala de aula."
            >
                <BoxContainer>
                    <MobileBox>
                        <WhiteText style={{ textTransform: 'uppercase', fontSize: '14px' }}>{`Gratuito - Certificado - 6 horas`}</WhiteText>
                    </MobileBox>
                </BoxContainer>

                {!isMobile ? (
                    <ModuloContainer>
                        <ModuloCPC modulo="Módulo 1" titulo="Eu sendo crítico e criativo" />
                        <ModuloCPC modulo="Módulo 2" titulo="Eu agindo no mundo" />
                        <ModuloCPC modulo="Módulo 3" titulo="Eu atuando na sala de aula" />
                    </ModuloContainer>
                ) : (
                    <SliderContainer>
                        <Slider {...settings}>
                            <ModuloCPC modulo="Módulo 1" titulo="Eu sendo crítico e criativo" />
                            <ModuloCPC modulo="Módulo 2" titulo="Eu agindo no mundo" />
                            <ModuloCPC modulo="Módulo 3" titulo="Eu atuando na sala de aula" />
                        </Slider>
                    </SliderContainer>
                )}

                <Row>
                    <YellowButton onClick={() => openUrl()}>{'Acessar Curso'}</YellowButton>
                    <OutlinerButton onClick={() => gotoSection('material_section')}>Mais conteúdos</OutlinerButton>
                </Row>
            </TextoApresentacao>

            <Box>
                <ItemContainer>
                    <ImgSvg src={Cash} />
                    <WhiteText>{'Gratuito'}</WhiteText>
                </ItemContainer>
                <ItemContainer>
                    <ImgSvg src={Award} />
                    <WhiteText>{'Certificado'}</WhiteText>
                </ItemContainer>
                <ItemContainer>
                    <ImgSvg src={Clock} />
                    <WhiteText>{'6 horas'}</WhiteText>
                </ItemContainer>
            </Box>
        </Container>
    );
};

export default CursoLivre;
