import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import iconClose from '../../../../images/close-circle.svg';

export interface IModalTerms {
    show: boolean;
    setShow: (value: boolean) => void;
}

const Head = styled.div`
    padding: 15px;
    text-align: right;
    z-index: 1;
`;

const IconClose = styled.img`
    height: 25px;
    cursor: pointer;
`;

const ModalTerms = ({ children, show, setShow }: React.PropsWithChildren<IModalTerms>) => {
    return (
        <Modal show={show} size={'lg'}>
            <Head>
                <IconClose src={iconClose} onClick={() => setShow(false)} />
            </Head>
            {children}
        </Modal>
    );
};

export default ModalTerms;
