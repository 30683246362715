import Colors from 'enums/Colors';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}

const StyleCarouselSolucoes = {
    Container: styled.div`
        padding: 33px 73px;
        width: 100%;
        height: 768px;
        background-color: #f5f5f5;

        @media screen and (max-width: 700px) {
            padding: 24px 30px;
            height: 800px;
        }
        @media screen and (max-width: 600px) {
            padding: 24px 30px;
            height: 920px;
        }
    `,

    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 32px;
        font-weight: 700;
        line-height: 44.8px;
        text-align: center;
        color: ${Colors.Blue};
    `,

    SubTitle: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        margin-top: 20px;
        max-width: 338px;

        @media screen and (max-width: 700px) {
            text-align: center;
        }
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean }>`
        width: 100%;
        margin-top: 90px;
        padding: 10px;

        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: start;
        }

        .slick-dots {
            li {
                button::before {
                    color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding: 0;
            .slick-slide {
                justify-content: center;
                align-items: center;
            }
        }
    `,

    Carrousel: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 450px;
        margin: 40px;

        @media screen and (max-width: 600px) {
            margin: 10px;
            height: 520px;
        }
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 59px;
        width: 56px;
        fill: ${Colors.Yellow};

        &:hover {
            fill: ${Colors.Yellow};
        }

        @media screen and (max-width: 600px) {
            width: 26px;
            height: 29px;
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '100%' : '-3%')};

        @media screen and (max-width: 1920px) {
            left: ${props => (props.next ? '100%' : '-4%')};
        }
        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '100%' : '-5%')};
        }

        @media screen and (max-width: 600px) {
            left: ${props => (props.next ? '100%' : '-5%')};
            height: 29px;
            width: 26px;
        }
    `,

    CardCarousel: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 22px;
        background-color: ${Colors.White};
        width: 292px;
        height: 460px;
        border: 1px solid #f1f1f1;
        border-radius: 8px;
        gap: 16px;

        @media screen and (max-width: 600px) {
            width: 284px;
            min-height: 480px;
        }
    `,

    CardContainer: {
        Content: styled.div`
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 16px;
        `,

        ImgContainer: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 120px;
        `,

        Img: styled.img<{ width?: string; height?: string }>`
            width: ${({ width }) => (width ? width : 'auto')};
            height: ${({ height }) => (height ? height : '100%')};
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            color: ${Colors.Blue};

            @media screen and (max-width: 600px) {
                font-size: 18px;
            }
        `,

        Description: styled.p`
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        `,
    },

    ContainerTag: styled.div`
        display: flex;
        justify-content: start;
        gap: 5px;
        flex-wrap: wrap;
        height: 50px;
        width: 100%;

        @media screen and (max-width: 600px) {
            height: 70px;
        }
    `,
    Footer: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    `,
};

export default StyleCarouselSolucoes;
