import { createContext } from 'react';
import { boolean } from 'yup';

export namespace PainelSocioContext {
    export interface IContext {
        showAlertDialog: boolean;
        setShowAlertDialog: () => void;

        showInfoDialog: boolean;
        setShowInfoDialog: () => void;

        showSliderDialog: boolean;
        setShowSliderDialog: () => void;

        currentIndexSlide: number;
        updateCurrentIndexSlide: (current: number) => void;
        prevSlide: () => void;
        nextSlide: () => void;

        currentIndexSlideAutoavaliacao: number;
        updateCurrentIndexSlideAutoavaliacao: (current: number) => void;
        prevSlideAutoavaliacao: () => void;
        nextSlideAutoavaliacao: () => void;

        hideAllDialogs: () => void;

        firstCloseBanner: boolean;

        isExpandedBanner: boolean;
        setExpandedBanner: (value: boolean) => void;

        showBannerInfo: boolean;
        setShowBannerInfo: (value: boolean) => void;
    }

    export const Context = createContext({} as IContext);
}
