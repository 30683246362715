import React, { ReactText, useState } from 'react';
import Styled from './CardCategoria.style';
import { Alert, Button, Card, Divider, Drawer, Input, Popconfirm, Tabs } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { IoAdd } from 'react-icons/io5';
import Colors from 'enums/Colors';
import { DrawerForm } from 'pages/acessoExclusivo/AcessoExclusivo';
import RegraPE from 'models/acessoExclusivo/RegraPE';
import { RoleList } from 'enums/RoleEnum';
import useFetch from 'hooks/useFetch';
import { RegrasPEService } from 'core/http/service/acessosExclusivos/RegrasPE.service';
import { CategoriaRegrasPE } from 'core/http/service/acessosExclusivos/CategoriaRegrasPE.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { TipoSolucoesService } from 'core/http/service/TipoSolucoes.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import TokenPE from 'models/acessoExclusivo/TokenPE';
import { TokenPEService } from 'core/http/service/acessosExclusivos/TokenPE.service';
import { CategoriaTokensPEService } from 'core/http/service/acessosExclusivos/CategoriaTokensPE.service';

export interface DataTypeTableCardCategoria {
    key: React.Key;
    id: number;
    role: string;
    estado: string;
    municipio: string;
    acoes: React.ReactNode[];
}
export interface DataTypeTableCardCategoriaToken {
    key: React.Key;
    id: number;
    token: string;
    status: Boolean;
    acoes: React.ReactNode[];
}
export interface DataTypeTableCardCategoriaSolucao {
    key: React.Key;
    id: number;
    solucao: string;
    acoes: React.ReactNode[];
}

interface ICardCategoria {
    id: number;
    nome: string;
    actions: React.ReactNode[];
    loading: boolean;
    dadosRegras: DataTypeTableCardCategoria[];
    dadosToken: DataTypeTableCardCategoriaToken[];
    dadosSolucao: DataTypeTableCardCategoriaSolucao[];
    reloadAll: number;
    reloadCategorias: () => void;
}

type TAbas = {
    name: string;
};

const Abas: TAbas[] = [
    {
        name: 'Regras',
    },
    {
        name: 'Soluções',
    },
    {
        name: 'Tokens',
    },
];

export interface ICategoriaRegra {
    codigoCategoria: number;
    codigoRegra: number;
}
export interface ICategoriaRegra {
    codigoCategoria: number;
    codigoRegra: number;
}

export interface ICategoriaSolucoes {
    codigoCategoria: number;
    codigoSolucao: number;
}

export interface ICategoriaToken {
    codigoCategoria: number;
    codigoToken: number;
}

enum TypeDelete {
    REGRA = 'REGRA',
    SOLUCOES = 'SOLUCOES',
    TOKEN = 'TOKEN',
}

const CardCategoria = ({ id, nome, actions, loading, dadosRegras, dadosToken, dadosSolucao, reloadCategorias, reloadAll }: ICardCategoria) => {
    const [selectionType] = useState<'checkbox'>('checkbox');
    const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
    const [abaActive, setAbaActive] = useState<number>(1);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [titleDrawer, setTitleDrawer] = useState<string>('');
    const [regras, setRegras] = useState<RegraPE[]>([]);
    const [roles] = useState(RoleList);
    const [reload, setReload] = useState<number>(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [selectedRowKeysSolucoes, setSelectedRowKeysSolucoes] = useState<ReactText[]>([]);
    const [selectedRowKeysTokens, setSelectedRowKeysTokens] = useState<ReactText[]>([]);
    const [listRegras, setListRegras] = useState<ICategoriaRegra[]>([]);
    const [tipoSolucoes, setTipoSolucoes] = useState<TipoSolucoes[]>([]);
    const [listSolucoes, setListSolucoes] = useState<ICategoriaSolucoes[]>([]);
    const [listTokens, setListTokens] = useState<ICategoriaToken[]>([]);
    const [tokens, setTokens] = useState<TokenPE[]>([]);

    const serviceRegra = new RegrasPEService();
    const serviceCategoriaRegra = new CategoriaRegrasPE();
    const tipoSolucoesService = new TipoSolucoesService();
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const serviceToken = new TokenPEService();
    const serviceCategoriaTokens = new CategoriaTokensPEService();

    useFetch(async () => {
        const response = await tipoSolucoesService.getAll();

        const dados: TipoSolucoes[] = response.data;

        const filteredData = dados.filter(
            item => !dadosSolucao.some(data => data.id === item.id) // Verifique a propriedade correta
        );

        // Ordena os dados pelo nome (alfabeticamente)
        filteredData.sort((a, b) => {
            if (a.nome < b.nome) return -1; // a vem antes de b
            if (a.nome > b.nome) return 1; // a vem depois de b
            return 0; // a e b são iguais
        });

        setTipoSolucoes(filteredData);
    }, [dadosSolucao]);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onClose = () => {
        setOpenDrawer(false);
    };

    useFetch(
        async () => {
            const responses = await serviceRegra.getAll();

            const data: RegraPE[] = responses.data;

            const filteredData = data.filter(
                item => !dadosRegras.some(dado => dado.role === item.role && dado.estado === item.estado && dado.municipio === item.municipio) // Verifique a propriedade correta
            );

            setRegras(filteredData);
        },
        [reload, dadosRegras],
        setLoadingInfo
    );

    useFetch(
        async () => {
            const responses = await serviceToken.getAllTokens();

            const data: TokenPE[] = responses.data;

            const filteredData = data.filter(
                item => !dadosToken.some(dado => dado.token === item.token) && item.status === true // Verifique a propriedade correta
            );

            setTokens(filteredData);
        },
        [reload, dadosToken],
        setLoadingInfo
    );

    const FormRegra = () => {
        const rowSelection = {
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRowKeys(selectedRowKeys);

                const list: ICategoriaRegra[] = selectedRows.map((e: RegraPE) => {
                    return {
                        codigoCategoria: id,
                        codigoRegra: e.id ?? -1 ?? -1,
                    };
                });

                setListRegras(list);
            },
        };

        const handleSubmit = async () => {
            if (!(listRegras.length > 0)) {
                ToastError('Para associar precisa de pelo menos uma regra selecionada.');
                return;
            }

            setLoadingInfo(true);

            const response = await serviceCategoriaRegra.saveAll(listRegras);

            if (response.status === 201) {
                ToastSuccess('Regras associadas com sucesso!');
                reloadCategorias();
                onClose();

                const filteredData = regras.filter(
                    item => !listRegras.some(dado => dado.codigoRegra === item.id) // Verifique a propriedade correta
                );

                setRegras(filteredData);
                setSelectedRowKeys([]);
            }

            setLoadingInfo(false);
        };

        return (
            <div>
                <Table
                    loading={loadingInfo}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeys,
                    }}
                    columns={[
                        { title: 'Role', dataIndex: 'role' },
                        { title: 'Estado', dataIndex: 'estado' },
                        { title: 'Município', dataIndex: 'municipio' },
                    ]}
                    dataSource={
                        regras.length > 0
                            ? regras.map((d, index) => ({
                                  ...d,
                                  role: roles.find(rl => rl.value === d.role)?.label || d.role,

                                  key: index,
                              }))
                            : []
                    }
                    pagination={{ pageSize: 10 }}
                />
                <Styled.Button onClickCapture={handleSubmit} style={{ padding: 20 }} onClick={() => {}}>
                    Associar Regras
                </Styled.Button>
            </div>
        );
    };

    const FormSolucoes = () => {
        const rowSelection = {
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRowKeysSolucoes(selectedRowKeys);

                const list: ICategoriaSolucoes[] = selectedRows.map((e: TipoSolucoes) => {
                    return {
                        codigoCategoria: id,
                        codigoSolucao: e.id ?? -1 ?? -1,
                    };
                });

                setListSolucoes(list);
            },
        };

        const handleSubmit = async () => {
            if (!(listSolucoes.length > 0)) {
                ToastError('Para associar precisa de pelo menos uma solução selecionada.');
                return;
            }

            setLoadingInfo(true);

            const response = await serviceCategoriaSolucoes.saveAll(listSolucoes);

            if (response.status === 201) {
                ToastSuccess('Soluções associadas com sucesso!');
                reloadCategorias();
                onClose();

                const filteredData = tipoSolucoes.filter(
                    item => !listSolucoes.some(dado => dado.codigoSolucao === item.id) // Verifique a propriedade correta
                );

                setTipoSolucoes(filteredData);
                setSelectedRowKeysSolucoes([]);
                setAbaActive(2);
            }

            setLoadingInfo(false);
        };

        return (
            <div>
                <Table
                    loading={loadingInfo}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeysSolucoes,
                    }}
                    columns={[{ title: 'Solução', dataIndex: 'nome' }]}
                    dataSource={
                        tipoSolucoes.length > 0
                            ? tipoSolucoes.map((d, index) => ({
                                  ...d,
                                  key: index,
                              }))
                            : []
                    }
                    pagination={{ pageSize: 10 }}
                />
                <Styled.Button onClickCapture={handleSubmit} style={{ padding: 20 }} onClick={() => {}}>
                    Associar Soluções
                </Styled.Button>
            </div>
        );
    };

    const FormTokens = () => {
        const rowSelection = {
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRowKeysTokens(selectedRowKeys);

                const list: ICategoriaToken[] = selectedRows.map((e: TokenPE) => {
                    return {
                        codigoCategoria: id,
                        codigoToken: e.id ?? -1 ?? -1,
                    };
                });

                setListTokens(list);
            },
        };

        const handleSubmit = async () => {
            if (!(listTokens.length > 0)) {
                ToastError('Para associar precisa de pelo menos um token selecionado.');
                return;
            }
            setLoadingInfo(true);
            const response = await serviceCategoriaTokens.saveAll(listTokens);

            if (response.status === 201) {
                ToastSuccess('Tokens associados com sucesso!');
                reloadCategorias();
                onClose();

                const filteredData = tokens.filter(
                    item => !listTokens.some(dado => dado.codigoToken === item.id) // Verifique a propriedade correta
                );

                setTokens(filteredData);
                setSelectedRowKeysTokens([]);
                setAbaActive(3);
            }
            setLoadingInfo(false);
        };

        return (
            <div>
                <Table
                    loading={loadingInfo}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeysTokens,
                    }}
                    columns={[{ title: 'Token', dataIndex: 'token' }]}
                    dataSource={
                        tokens.length > 0
                            ? tokens.map((d, index) => ({
                                  ...d,
                                  key: index,
                              }))
                            : []
                    }
                    pagination={{ pageSize: 10 }}
                />
                <Styled.Button onClickCapture={handleSubmit} style={{ padding: 20 }} onClick={() => {}}>
                    Associar Tokens
                </Styled.Button>
            </div>
        );
    };

    const handleDelete = async (codigo: number, type: TypeDelete) => {
        let response: number = -1;

        setLoadingInfo(true);

        switch (type) {
            case TypeDelete.REGRA: {
                const data = await serviceCategoriaRegra.deleteCR({ codigoCategoria: id, codigoRegra: codigo });
                response = data.status;
                break;
            }
            case TypeDelete.SOLUCOES: {
                const data = await serviceCategoriaSolucoes.deleteCS({ codigoCategoria: id, codigoSolucao: codigo });
                response = data.status;
                break;
            }
            case TypeDelete.TOKEN: {
                const data = await serviceCategoriaTokens.deleteCT({ codigoCategoria: id, codigoToken: codigo });
                response = data.status;
                break;
            }
        }

        if (response === 200) {
            ToastSuccess(
                type === TypeDelete.REGRA
                    ? 'Regra removida com sucesso!'
                    : type === TypeDelete.SOLUCOES
                    ? 'Solução removida com sucesso!'
                    : 'Token removido com sucesso!'
            );
            reloadCategorias();
            setReload(reload + 1);
        }

        setLoadingInfo(false);
    };

    return (
        <Styled.Container>
            <DrawerForm size="large" handleClose={onClose} open={openDrawer} title={titleDrawer}>
                <>{abaActive === 1 ? FormRegra() : abaActive === 2 ? FormSolucoes() : FormTokens()}</>
            </DrawerForm>

            <Card loading={loading} actions={actions} style={{ minWidth: 500 }}>
                <Card.Meta
                    title={nome + ' -  #' + id}
                    description={
                        <>
                            <Tabs
                                defaultActiveKey="1"
                                activeKey={abaActive.toString()}
                                onChange={e => setAbaActive(parseInt(e))}
                                items={Abas.map((dados, i) => {
                                    const id = String(i + 1);

                                    return {
                                        key: id,
                                        label: dados.name,
                                    };
                                })}
                            />
                            {abaActive === 1 ? (
                                <>
                                    <Styled.Button
                                        onClick={() => {
                                            showDrawer();
                                            setTitleDrawer('Regras');
                                        }}
                                    >
                                        <IoAdd color={Colors.White} /> Regra
                                    </Styled.Button>
                                    <Table
                                        loading={loadingInfo}
                                        style={{
                                            minHeight: 340,
                                        }}
                                        columns={[
                                            { title: 'Role', dataIndex: 'role' },
                                            { title: 'Estado', dataIndex: 'estado' },
                                            { title: 'Município', dataIndex: 'municipio' },
                                            { title: 'Ações', dataIndex: 'acoes' },
                                        ]}
                                        dataSource={dadosRegras.map((d, index) => ({
                                            ...d,
                                            role: roles.find(rl => rl.value === d.role)?.label || d.role,
                                            acoes: (
                                                <>
                                                    <Popconfirm
                                                        title="Remover regra"
                                                        description={
                                                            <p>
                                                                Você realemente deseja remover está regra dessa categoria? <br />
                                                            </p>
                                                        }
                                                        onConfirm={() => handleDelete(d.id ?? -1, TypeDelete.REGRA)}
                                                        okText="Sim, remover"
                                                        cancelText="Cancelar"
                                                    >
                                                        <DeleteOutlined key="edit" style={{ cursor: 'pointer' }} />
                                                    </Popconfirm>
                                                </>
                                            ),
                                            key: index,
                                        }))}
                                        pagination={{ pageSize: 4 }}
                                    />
                                </>
                            ) : abaActive === 2 ? (
                                <>
                                    <Styled.Button
                                        onClick={() => {
                                            showDrawer();
                                            setTitleDrawer('Soluções');
                                        }}
                                    >
                                        <IoAdd color={Colors.White} /> Solução
                                    </Styled.Button>
                                    <Table
                                        loading={loadingInfo}
                                        style={{
                                            minHeight: 340,
                                        }}
                                        columns={[
                                            { title: 'Solução', dataIndex: 'nome' },
                                            { title: 'Ações', dataIndex: 'acoes' },
                                        ]}
                                        dataSource={dadosSolucao.map((d, index) => ({
                                            ...d,
                                            acoes: (
                                                <>
                                                    <Popconfirm
                                                        title="Remover Solução"
                                                        description={
                                                            <p>
                                                                Você realemente deseja remover essa solução dessa categoria? <br />
                                                            </p>
                                                        }
                                                        onConfirm={() => handleDelete(d.id ?? -1, TypeDelete.SOLUCOES)}
                                                        okText="Sim, remover"
                                                        cancelText="Cancelar"
                                                    >
                                                        <DeleteOutlined key="edit" style={{ cursor: 'pointer' }} />
                                                    </Popconfirm>
                                                </>
                                            ),
                                            key: index,
                                        }))}
                                        pagination={{ pageSize: 4 }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Styled.Button
                                        onClick={() => {
                                            showDrawer();
                                            setTitleDrawer('Tokens');
                                        }}
                                    >
                                        <IoAdd color={Colors.White} /> Token
                                    </Styled.Button>
                                    <Table
                                        loading={loadingInfo}
                                        style={{
                                            minHeight: 340,
                                        }}
                                        columns={[
                                            { title: 'Token', dataIndex: 'token' },
                                            { title: 'Status', dataIndex: 'status' },
                                            { title: 'Ações', dataIndex: 'acoes' },
                                        ]}
                                        dataSource={dadosToken.map((d, index) => ({
                                            ...d,
                                            acoes: (
                                                <>
                                                    <Popconfirm
                                                        title="Remover Token"
                                                        description={
                                                            <p>
                                                                Você realemente deseja remover esse token dessa categoria? <br />
                                                            </p>
                                                        }
                                                        onConfirm={() => handleDelete(d.id ?? -1, TypeDelete.TOKEN)}
                                                        okText="Sim, remover"
                                                        cancelText="Cancelar"
                                                    >
                                                        <DeleteOutlined key="edit" style={{ cursor: 'pointer' }} />
                                                    </Popconfirm>
                                                </>
                                            ),
                                            key: index,
                                        }))}
                                        pagination={{ pageSize: 4 }}
                                    />
                                </>
                            )}
                        </>
                    }
                />
            </Card>
        </Styled.Container>
    );
};

export default CardCategoria;
