import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const OtherSolutionsStyle = styled.div``;

export const TitleText = styled.p`
    font-size: 18px;
    color: ${Colors.DarkBlue};
    letter-spacing: 0.1px;
    font-weight: bold;
    line-height: 42px;
`;

export const CardTitleText = styled(TitleText)`
    font-size: 16px;
    line-height: 19px;
    word-break: break-all;
`;

export const MiniCardsContainer = styled.section`
    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        display: flex;
        padding: 16px 0 0 0;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export const MiniCard = styled.div`
    position: relative;
    width: 224px;
    height: 224px;
    background: ${Colors.White};
    box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-right: 26px;
`;

export const ContentContainer = styled.div`
    overflow: auto;
    max-height: 80px;
    padding: 14px 25px 0 15px;
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const FooterContaier = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const CardFooter = styled.div`
    height: 42px;
    border-top: 1px solid ${Colors.Grey6};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
`;

export const FooterText = styled.p`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${Colors.Grey7};
    cursor: pointer;
`;

export const FooterButton = styled.button`
    background: ${Colors.Blue};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    outline: none;
    align-items: center;
    width: 100%;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.White};
    border: 1px solid ${Colors.Blue};

    &:hover {
        background: ${Colors.DarkBlue3};
        color: ${Colors.White};
        border: 1px solid ${Colors.DarkBlue3};
    }
`;

export const CarouselDots = styled.div`
    bottom: -40px;
    .slick-active div {
        background: ${Colors.BlueGrey};
    }
`;

export const CarouselCustomPaging = styled.div`
    width: 12px;
    height: 12px;
    border: 1px solid ${Colors.Grey5};
    border-radius: 50%;
`;

export const MobileMiniCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const ScrollContainer = styled.div`
    max-width: 78vw;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: hidden;
    //transform: translateX(-55px);
    .flip {
        transform: rotate(180deg);
        box-shadow: -4px -2px 10px #888;
    }
    @media only screen and (max-width: 830px) {
        padding: 0;
        max-width: 100%;
    }
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: 570px;
        width: 100%;
        margin-top: 0px;
    }
`;
export const Scroll = styled.div`
    display: flex;
    overflow-x: auto;
    //white-space: nowrap;
    scroll-behavior: smooth;
    padding-top: 10px;
    padding-left: 10px;
    @media only screen and (max-width: 830px) {
        padding-left: 0;
    }
    &::-webkit-scrollbar {
        display: none;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 12px;
            box-sizing: content-box;
            display: inline-block;
        }
    }
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }
        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
        padding-bottom: 0px;
    }
`;
export const ScrollItem = styled.div`
    margin-right: 2em;
`;
export const ArrowButtons = styled.img<{ active: boolean }>`
    box-shadow: 4px 2px 10px #888;
    border-radius: 50%;
    mix-blend-mode: multiply;
    margin: 10px 10px 50px 10px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    filter: ${props => (props.active ? 'none' : 'grayscale(1)')};
    opacity: ${props => (props.active ? '1' : '0.35')};
    transition: 400ms;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;
