import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import React, { useState } from 'react';

// Imgs
import Img_02 from '../../assets/AlfaTeoriaPratica/modulo02/img_02.jpg';
import ImgProcessoAdaptativo from '../../assets/AlfaTeoriaPratica/modulo02/o_processo_adaptativo_de_aprender_a_ler.png';
import ImgC2 from '../../assets/AlfaTeoriaPratica/modulo02/c2_fg2_corda de scarborough.png';

// InfoGrafico
import Infofrafico_01 from '../../assets/AlfaTeoriaPratica/modulo02/infografico_3_dominios.png';
import Infofrafico_02 from '../../assets/AlfaTeoriaPratica/modulo02/infografico_3_estratégias.png';
import Infofrafico_03 from '../../assets/AlfaTeoriaPratica/modulo02/infografico_linguagem_oral_e_processamento_fonologico.png';

import { Divider } from 'antd';

const ATP_Modulo02 = ({ setImgAmpliar }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="02"
                titulo={`
                  Alfabetização e neurociências
            `}
                description={`
                  Gisele Alves e Janaina Weissheimer
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 26,
                }}
            >
                <Styled.Img src={Img_02} alt="" onClick={() => setImgAmpliar(Img_02)} />
                <Styled.Description>
                    A ciência da leitura é um campo interdisciplinar que explora os processos cognitivos, linguísticos e neurais relacionados à
                    compreensão, decodificação e produção de textos escritos. Seu objetivo principal é compreender como o cérebro processa a
                    linguagem escrita, como as habilidades de leitura se desenvolvem ao longo do tempo e como fatores individuais e ambientais
                    influenciam esse processo de aprendizado. Para alcançar seu objetivo, a ciência da leitura emprega uma variedade de métodos
                    de pesquisa, como estudos comportamentais, neuroimagem e análises linguísticas, contribuindo assim para a compreensão
                    essencial desse aspecto crucial da educação e do desenvolvimento humano. <br />
                    <br /> Neste capítulo, abordaremos um recorte de informações oriundas desse campo do conhecimento, tão amplo e multifacetado,
                    para esclarecer por meio de abordagens múltiplas um pouco do que já é conhecido a respeito do processo de aprendizagem da
                    leitura e escrita. Esse tipo de conhecimento é
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 27,
                }}
            >
                <Styled.Description>
                    valioso para educadores pois, além de oferecer insights que permitem a adoção de práticas de ensino mais eficazes,
                    possibilita a identificação precoce de desafios de aprendizagem, a adaptação de abordagens para atender às necessidades
                    individuais dos estudantes, o embasamento de práticas educativas em evidências científicas e a colaboração efetiva com outros
                    profissionais para promover a alfabetização. <br />
                    <br />
                    Nos últimos quarenta anos, testemunhamos avanços significativos nas contribuições das neurociências para a alfabetização,
                    provindas do estudo do funcionamento, da comunicação e do processamento de informações no cérebro. Por meio de imagens
                    cerebrais, as pesquisas em neurociências têm nos permitido investigar, por exemplo, quais regiões do cérebro de crianças e
                    adultos são ativadas durante a leitura. O que encontramos nesses estudos é que a leitura é um dos fenômenos mais complexos em
                    termos neurológicos, pois ativa regiões diversas em que ocorrem desde processos não específicos da leitura (embora
                    participantes dela), como as regiões do cérebro responsáveis pela entrada e processamento de informações visuais e
                    fonológicas, ou regiões responsáveis pelo direcionamento e manutenção da atenção, até processos mais específicos de leitura.{' '}
                    <br />
                    <br />
                    Ademais, com base nas imagens do cérebro em funcionamento, é possível calcular índices de ativação cerebral que permitem, por
                    exemplo, entender quais processos cognitivos “consomem mais energia” em relação a outros. Assim, podemos entender que, quando
                    os índices de ativação cerebral são mais intensos e mais distribuídos, como no caso da leitura, eles representam maior
                    esforço cognitivo, principalmente na fase de aquisição inicial desses processos, quando a automatização das associações
                    grafo-fonológica ainda não se deu por completo. Nesta fase, é fundamental que haja sistematização e instrução formal
                    explícita para estabelecer a integração entre as redes da linguagem oral e as redes cerebrais que subjazem ao mapeamento
                    entre letras e sons. <br />
                    <br />
                    Esse tipo de estudo nos permite compreender o processo de reciclagem neuronal, em que áreas originalmente dedicadas ao
                    processamento visual e fonológico passam a assumir uma nova função – a especialização para o processamento da leitura –, que
                    pode ser percebida pelo fenômeno de quebra
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 28,
                }}
            >
                <Styled.Description>
                    de invariância visual, o qual resulta no espelhamento de letras e números. Ao analisar a interação de crianças ou indivíduos
                    não alfabetizados com textos escritos, percebemos a ativação de certas regiões cerebrais quando visualizam e processam
                    imagens de objetos, formas e faces. Essas áreas são denominadas “caixa de letras” (do inglês letterbox) ou “Área da Forma
                    Visual das Palavras” (do inglês Visual Word Form Area, VWFA) e são ativadas exclusivamente durante a leitura, enquanto o
                    processamento anterior das imagens migra para outras regiões cerebrais <br />
                    <br />
                    Tal observação de como nosso cérebro processa imagens, objetos e faces e, depois do aprendizado em alfabetização, de como o
                    faz com textos escritos nos possibilita notar algo muito relevante: antes de aprendermos a ler, quando objetos são
                    rotacionados e os vemos por ângulos diferentes, nosso cérebro naturalmente continua interpretando o que vemos como sendo os
                    mesmos objetos de antes da rotação ocorrer. Quando vemos uma mesa, por exemplo, pelas suas laterais, por cima ou por baixo,
                    mesmo sob diferentes perspectivas, continuamos a reconhecer o objeto como sendo uma mesa. Contudo, quando somos
                    alfabetizados, esse “funcionamento natural” já não é mais eficaz, pois aprendemos um sistema artificial de funcionamento dos
                    objetos em que rotacionar suas posições muda suas identidades, ou seja, muda a letra. Por exemplo, quando ainda não sabemos
                    ler, as letras b e d são facilmente reconhecidas como sendo a mesma coisa – apenas espelhadas –, mas, durante a
                    alfabetização, nós somos ensinados que a inversão de posição muda a própria identidade do objeto, de modo que uma letra é
                    diferente da outra.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 29,
                }}
            >
                <Styled.TextItalic>
                    <strong>Figura 1.</strong> O processo adaptativo de aprender a ler.
                </Styled.TextItalic>
                <Styled.Img src={ImgProcessoAdaptativo} alt="" onClick={() => setImgAmpliar(ImgProcessoAdaptativo)} />
                <Styled.Description>
                    O fenômeno da invariância visual, com o qual nosso cérebro vem programado, embora natural e comum a todos os indivíduos,
                    representa um gargalo durante a aprendizagem da leitura, uma vez que sua quebra toma tempo e energia de crianças e
                    professores. No entanto, um estudo conduzido com crianças brasileiras mostrou que a quebra de invariância visual (o que
                    permite que b e d sejam decodificadas como letras distintas) pode ser acelerada por intervenções pedagógicas multissensoriais
                    específicas, com foco na estimulação do córtex motor, principalmente quando combinadas a períodos de sono pós aprendizagem.
                    Um exemplo de intervenção seria promover a estimulação multissensorial com jogos de reconhecimento de letras que combinem
                    imagens (estímulos visuais) e sons (estímulos auditivos), ou realizar os traços das letras com materiais táteis, combinado a
                    períodos de sono de 30 a 120 minutos de duração, preferencialmente com distância entre uma criança e outra, com o uso de
                    vendas para limitar a entrada de luz e após o almoço das crianças – tirando vantagem também do período pós-prandial.
                    <br />
                    <br />
                    Com base no exposto, podemos depreender que um dos principais achados dos estudos de neuroimagem é a constatação da ausência
                    de uma região cerebral inata dedicada à leitura similar às áreas visuais, motoras e de linguagem falada, as quais possuímos
                    desde muito cedo. A falta de especialização indica que a habilidade de leitura e escrita não é intrínseca a nossa espécie,
                    mas sim um processo complexo e relativamente recente em termos
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 30,
                }}
            >
                <Styled.Description>
                    evolutivos. Isso coloca um desafio adicional para os professores alfabetizadores, na medida em que estão promovendo a
                    aquisição de uma habilidade que não se desenvolveria naturalmente, o que exige uma conduta intencional e direcionada no
                    processo de ensino-aprendizagem.
                    <br />
                    <br />
                    Por professores alfabetizadores, nos referimos aqui àqueles que trabalham a alfabetização de forma ampla, como algo contínuo,
                    sistematizado e processual e que ocorre desde a pré-escola, passando pela educação infantil, até os primeiros anos do ensino
                    fundamental. É importante lembrar que a etapa da alfabetização faz parte de dois processos mais amplos, que se desenvolvem de
                    forma simultânea e complementar, ao longo de toda a vida do indivíduo letrado: a literacia, que engloba os processos
                    cognitivos e as habilidades linguísticas envolvidas no desenvolvimento da competência da leitura e da escrita; e a literacia
                    social ou letramento, que diz respeito à aplicação dessas competências de leitura e escrita em contextos sociais13. Ter
                    consciência desse fluxo nos permite ter uma noção melhor do grau de complexidade dos processos neurológicos e cognitivos
                    envolvidos nas diferentes fases de desenvolvimento e maturação neural das crianças e, assim, fornecer o apoio adequado a cada
                    momento. É importante também para que o professor reconheça o que é característico de cada faixa etária, mas também direcione
                    seu olhar atento às individualidades em todo o processo, já que nem todas as crianças aprendem na mesma velocidade, ou
                    tropeçam nos mesmos pontos.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 31,
                }}
            >
                <Styled.TextItalic>
                    <strong>Figura 2:</strong> Corda de Scarborough.
                </Styled.TextItalic>
                <Styled.Img src={ImgC2} onClick={() => setImgAmpliar(ImgC2)} />
                <Styled.Credits>Fonte: Scarborough</Styled.Credits>
                <Styled.Description>
                    Se observarmos a estrutura teórica proposta por Scarborugh, notamos que ela descreve o que os estudantes precisam aprender
                    para que sejam alfabetizados e tornem-se cada vez mais competentes nas habilidades de leitura e escrita. Essa estrutura
                    delineia os processos fundamentais envolvidos na alfabetização, destacando dois grupos de componentes principais: um se
                    refere às habilidades de compreensão da linguagem e o outro, às habilidades de reconhecimento de palavras. Assim, a estrutura
                    propõe que, para compreender um texto escrito, um leitor precisa usar tanto seu conhecimento de vocabulário (componente da
                    compreensão da linguagem) quanto suas habilidades de decodificação (componente do reconhecimento de palavras). Desse modo,
                    veja na Figura 2 que os componentes interagem entre si para formar as habilidades de leitura e escrita competentes, em que a
                    compreensão da linguagem falada se integra ao conhecimento das regras do sistema de escrita. Perceba que esse modelo fornece
                    uma estrutura abrangente para compreender o desenvolvimento da alfabetização e útil para identificar áreas de intervenção
                    para aprimorar a proficiência em leitura e escrita.
                    <br />
                    <br />
                    Em seu modelo, que já encontrou sustentação empírica em diversos estudos nacionais e internacionais, Aaron et al.15
                    propuseram igualmente que a
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 32,
                }}
            >
                <Styled.Description>
                    habilidade de leitura e escrita é algo bem complexo e multifacetado e classificaram os componentes que a influenciam em três
                    domínios: ambiental, psicológico e cognitivo linguístico. Este modelo também oferece uma visão ampla e integrada da leitura,
                    reconhecendo a complexidade e a interação entre diferentes influências no processo de desenvolvimento e desempenho da
                    habilidade de leitura.
                </Styled.Description>
                <Styled.Img src={Infofrafico_01} onClick={() => setImgAmpliar(Infofrafico_01)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 33,
                }}
            >
                <Styled.Description>
                    Algo que muitos estudos têm demonstrado recorrentemente é que as habilidades relacionadas ao reconhecimento de palavras são
                    essenciais e necessárias tanto para a fluência leitora quanto para a compreensão eficaz do texto19. Os leitores podem lançar
                    mão de três tipos de estratégias para reconhecer as palavras: logográficas, alfabéticas e ortográficas.
                </Styled.Description>
                <Styled.Img src={Infofrafico_02} onClick={() => setImgAmpliar(Infofrafico_02)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 34,
                }}
            >
                <Styled.ContentBorderYellow>
                    Inicialmente, as estratégias humanas de codificação e de processamento da informação em representações escritas eram mais
                    visuais, isto é, havia uma relação gráfica direta entre o escrito e seu significado, como nos pictogramas, ideogramas e
                    caracteres – e que permanecem em algum grau em determinadas escritas, como é o caso da chinesa ou japonesa. No entanto, com o
                    passar do tempo, em vez de representar diretamente o objeto na escrita, passamos a representar o nome falado desse objeto, ou
                    seja, passamos de sistemas visuais para sistemas fonológicos, em que a <strong>representação escrita das ideias</strong> é
                    mediada pela fala e, em nosso caso, por meio do uso do <strong>alfabeto.</strong> Assim, quando pensamos no ensino de leitura
                    e escrita, o uso de nosso próprio sistema de escrita, o alfabeto – que faz uma representação da fala no nível do fonema –
                    aponta aquilo que dará suporte ao aprendizado desse conteúdo: a instrução oferecida pelo professor em sala de aula para
                    apoiar o estudante a compreender e raciocinar sobre como as letras correspondem aos sons.
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    Os avanços em neuropsicologia nos estudos de neuroimagem permitiram verificar algo muito importante no que se refere às
                    estratégias que usamos para ler. Se expusermos a criança a um ambiente letrado, mas a deixarmos por conta própria e não
                    fornecermos instruções específicas para a leitura, ela, muito embora tenda a se relacionar com o texto escrito, o fará de
                    modo menos eficiente, na medida em que lançará mão apenas da estratégia logográfica, por ser mais natural da espécie humana.{' '}
                    <br />
                    <br />
                    Yoncheva, Wise e McCandliss demonstraram em um experimento que, ao se usar a estratégia logográfica, são ativadas regiões
                    cerebrais que processam imagens, mas não a linguagem, pois estruturas mais sofisticadas ainda não se especializaram para a
                    leitura – como vimos anteriormente. Contudo, no mesmo estudo, quando foram oferecidas instruções específicas em que as
                    palavras eram quebradas em pedaços e estes pedaços escritos eram relacionados a pedaços da fala, os aprendizes passaram a
                    ativar regiões do cérebro responsáveis pela leitura, desenvolvendo progressivamente essas regiões para a linguagem e tornando
                    o processo de aprendizagem mais eficiente.
                    <br />
                    <br />
                    Fica destacada, portanto, a relevância do papel do professor durante o processo de alfabetização. A orientação oferecida por
                    ele ao estudante desempenha um papel crucial na aprendizagem e no desenvolvimento das
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 35,
                }}
            >
                <Styled.Description>
                    habilidades de leitura, influenciando diretamente a construção da consciência linguística e a adoção de estratégias eficazes
                    de leitura. Essas estratégias permitem ao estudante diferenciar desenhos de linguagem escrita utilizando códigos de unidades
                    mínimas, ou seja, letras associadas aos sons da fala, facilitando assim a compreensão e interpretação do texto escrito.
                    <br />
                    <br />
                    Assim, integrando diversos achados do campo da ciência da leitura, aprendemos que são necessários diversos conhecimentos para
                    que o processo de aquisição de habilidades de leitura e escrita ocorra de modo eficaz e que nele sejam considerados os
                    aspectos intrínsecos às próprias fases de desenvolvimento físico e psicológico da criança. Isso posto, trataremos a seguir
                    dos preditores de alfabetização, ou seja, aspectos capazes de predizer o sucesso no processo de aquisição e desempenho das
                    habilidades de leitura e escrita.
                </Styled.Description>
                <Styled.TitleYellow style={{fontSize: 16}}>Preditores de alfabetização</Styled.TitleYellow>
                <Styled.Description>
                    Desde os primeiros anos de escolarização, é necessário dar suporte para que as habilidades mais sofisticadas e específicas de
                    leitura e escrita sejam desenvolvidas na hora certa, e tal suporte engloba dois grupos de habilidades fundamentais:{' '}
                    <strong> linguagem oral e processamento fonológico.</strong>
                </Styled.Description>
                <Styled.Img src={Infofrafico_03} onClick={() => setImgAmpliar(Infofrafico_03)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 36,
                }}
            >
                <Styled.ContentBorderYellow>
                    Estimular o processamento fonológico das crianças é fundamental, também, para que posteriormente, quando elas forem expostas
                    ao código alfabético, possam desenvolver a consciência fonêmica, isto é, entendam a relação entre letras (grafemas) e sons
                    (fonemas). Para aprender a ler, a criança precisa decompor a linguagem oral em pedaços. Por exemplo, ela pode começar
                    identificando duas partes na palavra “bola” (“bo” + “la”); em seguida, ela pode comparar o som inicial de “bola” com o de
                    “boca” e perceber que há um segmento idêntico; então, pode associar esse som inicial a sua representação gráfica, conectando
                    os fonemas aos grafemas que os representam na escrita. É fácil perceber a atuação da consciência fonêmica se perguntarmos a
                    uma pessoa não alfabetizada e a uma criança alfabetizada qual é o primeiro som de “bola”; a resposta da primeira será
                    bastante variada, enquanto a segunda dirá que é o som de “b”
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    Assim, nota-se que trabalhar com a <strong> linguagem oral</strong> e o <strong>processamento fonológico</strong> como
                    pilares desde os primeiros anos da escolarização, como na educação infantil, será fundamental para o enriquecimento
                    linguístico de todo o repertório da criança. Com isso, o processo mais formal de alfabetização, posterior, tenderá a ser
                    muito mais tranquilo e fluido, já que os fundamentos necessários estarão bem estabelecidos.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 37,
                }}
            >
                <Styled.Description>
                    Conhecer mais sobre procedimentos capazes de antecipar o sucesso do aprendizado formal em alfabetização pode provocar no
                    leitor uma pergunta: qual seria a idade certa para alfabetizar? Temos observado, não apenas no Brasil, que, na tentativa de
                    fortalecer a alfabetização ou de promover uma leitura e escrita mais eficaz e competente, algumas escolas têm antecipado a
                    alfabetização formal, submetendo as crianças a ela de modo muito precoce, às vezes aos 4 anos de idade. Em vez disso,
                    recomenda-se estimular desde cedo os <strong>preditores da alfabetização;</strong> não é necessário antecipar o processo
                    formal esperado para mais tarde no ciclo de escolarização. Aquelas crianças que apresentarem dificuldades no desenvolvimento
                    do processamento fonológico, ou que não responderem adequadamente às intervenções realizadas na escola, podem ser
                    encaminhadas a profissionais e terapeutas para intervenções específicas; assim, elas terão mais chances de sanar ou mitigar
                    essas dificuldades, sem precisarem esperar até que o ciclo formal de alfabetização se inicie.
                    <br />
                    <br />
                    Araujo et al. observaram, no Brasil, que a alfabetização no tempo adequado, até o 2º ano do ensino fundamental, foi o melhor
                    preditor da proficiência posteriormente, no 5º ano. No estudo, a probabilidade de se atingir níveis avançados em Língua
                    Portuguesa no 5º ano em uma avaliação externa padronizada, o Sistema Permanente de Avaliação da Educação Básica do Ceará
                    (Spaece), foi de 55% quando a criança fora alfabetizada no tempo adequado, até o 2º ano, contra 21% quando a alfabetização
                    ocorrera mais tardiamente. Ainda, a magnitude desses resultados de probabilidade como efeito da alfabetização na idade certa
                    superou até mesmo a dos efeitos relativos a sexo, cor/raça, nível socioeconômico e localização da escola (urbana ou rural).
                    <br />
                    <br />
                    Assim, ressalta-se que há diversas maneiras de promover desde cedo a alfabetização dentro do trabalho com a linguagem oral e
                    a consciência fonológica, sem que seja necessário sobrecarregar as crianças com demandas muito complexas. Trabalhar com a
                    alfabetização desde cedo inclui promover atividades como leitura supervisionada pelo professor, por outro adulto ou por
                    estudantes mais velhos, exploração de sons através de músicas, contação de estórias e encenações, além de brincadeiras que
                    introduzam a criança ao universo das letras e a seu próprio contexto. No entanto, é igualmente importante não adiar esse
                    processo além do tempo esperado, para
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 38,
                }}
            >
                <Styled.Description>
                    não perder a janela de oportunidade oferecida pela exuberância neuroplástica do cérebro das crianças.
                    <br />
                    <br />
                    A aprendizagem da leitura e escrita é mais desafiadora se iniciada em idades mais avançadas. Estudos como os de Braga et al.
                    observaram que, quando se aprende a ler apenas na idade adulta, há uma lentificação muito maior na aquisição dessas
                    habilidades e uma perda muito significativa de proficiência, tanto em fluência (automatização e rapidez) quanto em
                    compreensão leitora (compreensão do que se lê). Contudo, identificaram também que mesmo em adultos a exposição a instruções
                    específicas para apoiar o raciocínio com o uso dos conhecimentos de correspondência entre letras e sons foi capaz de produzir
                    efeitos bastante substanciais na proficiência leitora. A reflexão sobre a complexidade crescente da aprendizagem da leitura e
                    escrita à medida que o tempo avança nos permite enxergar a importância crucial de implementar estratégias pedagógicas desde
                    os estágios iniciais da educação.
                    <br />
                    <br />
                    Realizando um exercício de delineamento das diretrizes abrangentes de alfabetização, desde a educação infantil até os anos
                    iniciais do ensino fundamental, extraídas dos estudos disponíveis, verificamos que inicialmente é imprescindível imergir as
                    crianças em ambientes linguisticamente ricos, onde sejam expostas a histórias e vocabulários diversificados, em oposição a
                    ambientes desfavorecidos, que ofereçam interações linguísticas limitadas. O estudo de Hart e Risley, por exemplo, observou
                    uma diferença de cerca de 30 milhões de palavras ouvidas entre crianças de 0 a 3 anos de famílias de maior status
                    socioeconômico e crianças de famílias menos favorecidas. Segundo os autores, uma tal diferença nessa faixa etária determina
                    em grande medida o sucesso escolar das crianças no futuro. É fundamental, portanto, ampliar o repertório linguístico das
                    crianças desde a educação infantil, etapa crucial para a expansão do vocabulário, um processo, contudo, que se estenderá por
                    toda a vida do indivíduo. Isso deve se dar em paralelo, como mencionamos anteriormente, com a promoção da compreensão da
                    estrutura fonológica da linguagem oral, que requer um avanço gradual, passando, por exemplo, de jogos com rimas para
                    atividades que enfatizem sílabas e sons individuais.
                    <br />
                    <br />
                    Já o enfoque no ensino explícito de correspondências entre letras e sons,
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Alfabetização e neurociências',
                }}
                footer={{
                    numberSummary: 39,
                }}
            >
                <Styled.Description>
                    habilidade fundamental para dominar o código escrito, é bastante relevante a partir, especialmente, dos primeiros anos do
                    ensino fundamental, e sua estimulação deve levar em conta as necessidades específicas de cada ano escolar. À medida que esse
                    raciocínio com os códigos vai ficando mais automatizado e proficiente, a fluência e a compreensão de textos vão sendo
                    facilitadas, já que os recursos atencionais não precisam mais ser alocados inteiramente para a tarefa de decodificação.
                    Ademais, habilidades sociais e emocionais, bem como de motricidade, devem ser trabalhadas desde cedo de forma transversal às
                    habilidades linguísticas, pois influenciam não apenas a alfabetização, mas todo o processo de aprendizagem.
                    <br />
                    <br />
                    Por fim, ressalta-se que a progressão de todas essas habilidades deve ser cuidadosamente monitorada ao longo dos anos,
                    conforme os estudantes avançam em sua escolaridade, respeitando uma sequência de desenvolvimento neurocognitivo em que
                    determinadas habilidades vão favorecendo a aquisição das demais. Para tanto, é fundamental realizar um acompanhamento
                    periódico e sistematizado da aprendizagem de leitura e escrita, na medida em que possibilita ao professor conhecer
                    informações específicas sobre pontos em que seja necessário intervir.
                    <br />
                    <br />
                    Em suma, a ciência da leitura revela não apenas a complexidade do processo de alfabetização, mas também a importância de
                    realizar intervenções adequadas desde os primeiros anos da educação. Embora saibamos que a relação entre conhecimento e
                    prática não é linear, ou seja, que o conhecimento acerca da ciência da leitura por parte do professor não se traduz
                    necessariamente nem automaticamente em melhores práticas de sala de aula, entendemos que, uma vez de posse desse
                    conhecimento, os professores estarão mais preparados para tomar decisões e fazer escolhas pedagógicas mais acertadas.
                    Compreendendo os desafios enfrentados no aprendizado da leitura e escrita e a eficácia de estratégias pedagógicas, podemos
                    orientar práticas educativas mais eficazes e oferecer suporte adequado aos estudantes em seu desenvolvimento linguístico,
                    cognitivo e emocional. Em outras palavras: a integração dos conhecimentos da ciência da leitura a abordagens pedagógicas pode
                    promover uma alfabetização mais eficiente e competente, de modo a preparar os estudantes para os desafios de leitura e
                    escrita ao longo de suas vidas.
                </Styled.Description>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo02;
