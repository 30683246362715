import React from 'react';

export const LabelObs = () => {
    return (
        <>
            OBS: A opção <strong>Tipo de Redirecionamento</strong> foi projetada para uso em páginas que não possuem validação automática,
            redirecionando o usuário para login ou cadastro caso ele esteja deslogado. Portanto, o comportamento pode variar caso ele seja usado
            em fluxos que já são automatizados.
        </>
    );
};

export const LabelRedirecionamento = () => {
    return (
        <>
            OBS: Para redirecionar o usuário para os <strong>links externos</strong>, é necessário seguir o padrão: <strong>http://url</strong>{' '}
            ou <strong>https://url</strong>. Para <strong>links internos</strong>, temos dois exemplos de opções:{' '}
            <strong>http://ambiente/recursos/trilhas</strong> ou <strong>/recursos/trilhas</strong>.
        </>
    );
};
