import React, { useState } from 'react';
import Styled from './CardCertificacoes.styled';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { BlueSolidButton, RedSolidButton, YellowButton } from 'components/UI/Buttons';
import { FaEdit } from 'react-icons/fa';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import Colors from 'enums/Colors';
import { MdDeleteForever } from 'react-icons/md';
import { Alert } from 'react-bootstrap';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import { useHistory } from 'react-router-dom';
import { message, Popconfirm, PopconfirmProps } from 'antd';

interface ICardCertificacoes {
    dados: CertificacoesModel;
    handleEdit: () => void;
    handleDelete: () => void;
}

const CardCertificacoes = ({ dados, handleEdit, handleDelete }: ICardCertificacoes) => {
    const history = useHistory();

    const goToCertificado = () => history.push('/admin/gestao-certificados/certificado/' + dados.codigo);

    return (
        <Styled.Container>
            <Styled.Title>{dados.nome}</Styled.Title>
            <Styled.Column>
                <div
                    style={{
                        display: 'flex',
                        gap: '0.5rem',
                    }}
                >
                    <FaEdit onClick={handleEdit} size={22} cursor={'pointer'} color={Colors.PureOrange} />
                    <Popconfirm
                        title="Atenção! "
                        description={`Você está prestes a excluir esta certificação. Tem certeza de que deseja prosseguir?`}
                        onConfirm={handleDelete}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <MdDeleteForever onClick={() => {}} size={22} cursor={'pointer'} color={Colors.DeleteRed} />
                    </Popconfirm>
                </div>

                <Divider
                    style={{
                        margin: '2px',
                    }}
                />
                <YellowButton onClick={goToCertificado} style={{}}>
                    Acessar
                </YellowButton>
            </Styled.Column>
        </Styled.Container>
    );
};

export default CardCertificacoes;
