import React, { useState } from 'react';
import Styled from './TrilhaAprofundada.styled';
import { RiGraduationCapLine } from 'react-icons/ri';
import { TbCurrencyDollarOff } from 'react-icons/tb';
import { AiOutlineClockCircle } from 'react-icons/ai';
import CardTrilhaAprofundadaModel, { StatusCardModulo } from 'models/secoes/CardModulos';
import { TrilhaApronfundadaInfoCard } from './TrilhaApronfundadaInfoCard';
import { TrilhaApronfundadaCardAtividades } from './TrilhaApronfundadaCardAtividades';
import { MdMenuBook } from 'react-icons/md';
import { IsCustomMaxWidth, IsMediumSizeMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import { ArrowButton, SliderContainer, SvgIcon } from './CursoLivre.styled';
import Slider from 'react-slick';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import useLogged from 'hooks/useLogged';
import BannerParceirosHorizontal from './assets/banner_parceiros_horizontal3.png';
import BannerParceirosVertical from './assets/banner_parceiros_vertical.png';
import CardAtividades from 'models/secoes/CardAtividades';
import { BsPaperclip } from 'react-icons/bs';
import { Card, Modal } from 'react-bootstrap';
import Arquivo_Consciencia_Fonologica from './assets/files/consciencia_fonologica.pdf';
import Arquivo_DLM from './assets/files/desenvolvimento_da_linguagem_matematica.pdf';
import Arquivo_AMS from './assets/files/atividade-mao-na-massa.pdf';
import Arquivo_AEM from "./assets/files/avalicao_e_monitoramento.pdf"
import Arquivo_CD from "./assets/files/cultura_digital.pdf"
import Arquivo_ADICA from "./assets/files/a-educacao-infantil-e-sua-conexao-com-a-alfabetizacao.pdf"
import { isMobileDevice } from 'react-select/src/utils';

interface ICardTrilha {
    dados: CardTrilhaAprofundadaModel;
}

interface ICardTrilhaAprofundada {
    dados: CardAtividades;
}
export const CardTrilha = ({ dados }: ICardTrilha) => {
    const { isDisponivel, statusLabel } = VerifyStatusCard(dados.status);
    const { authenticated } = useLogged();
    const history = useHistory();

    const goToLink = () => {
        GenericComponentsUtils.gotoLink(dados.link, history, authenticated, dados);
    };

    return (
        <Styled.CardTrilha.Container>
            <div>
                <Styled.CardTrilha.Header>
                    <Styled.CardTrilha.Status isDisponivel={isDisponivel}>
                        {' '}
                        {dados.titleTemp && !isDisponivel && dados.titleTemp?.length > 0 ? dados.titleTemp : statusLabel}
                    </Styled.CardTrilha.Status>
                    {!dados.ordemOculto && (
                        <Styled.CardTrilha.Modulo>
                            <MdMenuBook size={16} color="#003C78" /> Módulo {dados.ordem}
                        </Styled.CardTrilha.Modulo>
                    )}
                </Styled.CardTrilha.Header>
                <Styled.CardTrilha.Title>{dados.title}</Styled.CardTrilha.Title>
                <Styled.CardTrilha.Description>{dados.description}</Styled.CardTrilha.Description>
            </div>
            <div style={{ width: '100%' }}>
                <Styled.CardTrilha.Footer.Container>
                    <Styled.CardTrilha.Footer.Content>
                        <AiOutlineClockCircle color="#003C78" /> {dados.time}
                    </Styled.CardTrilha.Footer.Content>
                    {dados.isCertificated && (
                        <Styled.CardTrilha.Footer.Content>
                            <RiGraduationCapLine color="#003C78" /> Certificado
                        </Styled.CardTrilha.Footer.Content>
                    )}
                </Styled.CardTrilha.Footer.Container>
                <Styled.CardTrilha.ContentCenter>
                    <Styled.CardTrilha.Button onClick={goToLink} isDisponivel={isDisponivel} disabled={!isDisponivel}>
                        ACESSAR CURSO
                    </Styled.CardTrilha.Button>
                </Styled.CardTrilha.ContentCenter>
            </div>
        </Styled.CardTrilha.Container>
    );
};

interface ICardAtividadeDownload {
    title: string;
    fileUrl: string;
    fileName: string;
}

const CardAtividadeDownload = ({ title, fileUrl, fileName }: ICardAtividadeDownload) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Card style={{ width: '18rem' }}>
            <Card.Body
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Card.Title>{title}</Card.Title>
                <Card.Link
                    style={{
                        marginTop: 20,
                    }}
                    href="#"
                    onClick={handleDownload}
                >
                    Baixar atividade
                </Card.Link>
            </Card.Body>
        </Card>
    );
};

export const CardAtividadePraticaTrilha = ({ dados }: ICardTrilhaAprofundada) => {
    const { isDisponivel, statusLabel } = VerifyStatusCard(dados.status);
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Atividades Práticas</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        flexWrap: "wrap",
                        marginLeft: IsCustomMaxWidth(800) ? '0px' : "60px"
                    }}
                >
                    <CardAtividadeDownload
                        title="O desenvolvimento da linguagem matemática"
                        fileUrl={Arquivo_DLM}
                        fileName="desenvolvimento_da_linguagem_matematica.pdf"
                    />
                    <CardAtividadeDownload
                        title="Consciência fonológica"
                        fileUrl={Arquivo_Consciencia_Fonologica}
                        fileName="consciencia_fonologica.pdf"
                    />
                    <CardAtividadeDownload
                        title="Dificuldades de aprendizagem e práticas de superação"
                        fileUrl={Arquivo_AMS}
                        fileName="dificuldades-de-aprendizagem-e-práticas-de-superacao.pdf"
                    />
                    <CardAtividadeDownload
                        title="Avaliação e Monitoramento"
                        fileUrl={Arquivo_AEM}
                        fileName="avaliacao_e_monitoriamento.pdf"
                    />
                    <CardAtividadeDownload
                        title="Cultura Digital"
                        fileUrl={Arquivo_CD}
                        fileName="cultura_digital.pdf"
                    />
                    <CardAtividadeDownload
                        title="A educação infantil e sua conexão com a alfabetização"
                        fileUrl={Arquivo_ADICA}
                        fileName="a-educacao-infantil-e-sua-conexao-com-a-alfabetizacao.pdf"
                    />
                </Modal.Body>
            </Modal>
            <Styled.CardTrilha.Container bgColor="#ffcb00">
                <div>
                    <Styled.CardTrilha.Header>
                        <Styled.CardTrilha.Status isDisponivel={isDisponivel}>{statusLabel}</Styled.CardTrilha.Status>
                        <Styled.CardTrilha.Modulo>
                            <BsPaperclip size={16} color="#003C78" /> Atividades
                        </Styled.CardTrilha.Modulo>
                    </Styled.CardTrilha.Header>
                    <Styled.CardTrilha.Title>{dados.title}</Styled.CardTrilha.Title>
                    <Styled.CardTrilha.Description>{dados.description}</Styled.CardTrilha.Description>
                </div>
                <div style={{ width: '100%' }}>
                    <Styled.CardTrilha.ContentCenter>
                        <Styled.CardTrilha.Button
                            isAtividade
                            onClick={() => setModalShow(true)}
                            isDisponivel={isDisponivel}
                            disabled={!isDisponivel}
                        >
                            ACESSAR ATIVIDADES
                        </Styled.CardTrilha.Button>
                    </Styled.CardTrilha.ContentCenter>
                </div>
            </Styled.CardTrilha.Container>
        </>
    );
};

const VerifyStatusCard = (status: StatusCardModulo) => {
    let isDisponivel = false;
    let statusLabel = '';

    switch (status) {
        case StatusCardModulo.DISPONIVEL: {
            isDisponivel = true;
            statusLabel = 'Disponível';
            break;
        }
        case StatusCardModulo.EMBREVE: {
            isDisponivel = false;
            statusLabel = 'Em breve';
        }
    }

    return { isDisponivel, statusLabel };
};

const TrilhaApronfundada = () => {
    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={true} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={false} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292891C7.31658 -0.0976325 6.68342 -0.0976325 6.29289 0.292891L0.292894 6.29289C-0.0976308 6.68342 -0.0976309 7.31658 0.292894 7.7071L6.29289 13.7071Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <Styled.Container>
            <Styled.Header>
                <div
                    style={{
                        maxWidth: IsMobileMaxWidth() ? '100%' : '70%',
                    }}
                >
                    <Styled.Title>
                        {'ALFABETIZAÇÃO INTEGRAL EM APOIO AO COMPROMISSO NACIONAL CRIANÇA ALFABETIZADA'.toLocaleUpperCase()}
                    </Styled.Title>
                </div>
                <Styled.Certificated.Container>
                    <RiGraduationCapLine size={40} color="#FFCA00" /> Certificado pelo IEA USP Ribeirão Preto e Instituto Ayrton Senna
                </Styled.Certificated.Container>
            </Styled.Header>
            <Styled.SubTitle>
                Mergulhe em mais essa trilha de alfabetização e explore diferentes temas relacionados à temática e promoção da mesma, como
                letramentos e multiletramentos, avaliação e monitoramento, cultura digital na alfabetização, entre outros.
            </Styled.SubTitle>
            <Styled.Status.Cotainer>
                <Styled.Status.Content>
                    <TbCurrencyDollarOff color="#FFCA00" /> Gratuito
                </Styled.Status.Content>
                <Styled.Status.Content>
                    <AiOutlineClockCircle color="#FFCA00" /> 21 horas
                </Styled.Status.Content>
            </Styled.Status.Cotainer>
            <Styled.ContainerConteudos>
                {IsMobileMaxWidth() ? (
                    <SliderContainer>
                        <Slider {...settings}>
                            {TrilhaApronfundadaInfoCard.map((dados, index) => (
                                <CardTrilha key={index} dados={dados} />
                            ))}
                            {TrilhaApronfundadaCardAtividades.map((dados, index) => (
                                <CardAtividadePraticaTrilha key={index} dados={dados} />
                            ))}
                        </Slider>
                    </SliderContainer>
                ) : (
                    <>
                        {TrilhaApronfundadaInfoCard.map((dados, index) => (
                            <CardTrilha key={index} dados={dados} />
                        ))}
                        {TrilhaApronfundadaCardAtividades.map((dados, index) => (
                            <CardAtividadePraticaTrilha key={index} dados={dados} />
                        ))}
                    </>
                )}
            </Styled.ContainerConteudos>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {IsCustomMaxWidth(1200) ? (
                    <img
                        style={{
                            marginTop: '20px',
                            width: '400px',
                        }}
                        src={BannerParceirosVertical}
                    />
                ) : (
                    <img
                        style={{
                            marginTop: '20px',
                        }}
                        src={BannerParceirosHorizontal}
                    />
                )}
            </div>
        </Styled.Container>
    );
};

export default TrilhaApronfundada;
