import PDP from 'models/pdp/PDP';
import { GenericService } from './Generic.service';

export class PdpService extends GenericService {
    readonly URI: string = '/v1/pdp';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public consultarMacrocompetencias() {
        return this.get('findMacrocompetencias');
    }

    public consultarMetas() {
        return this.get('consultarMetas');
    }

    public consultarUltimoPdpPorPerfil(idPerfil: number) {
        const params = {
            idPerfil: idPerfil,
        };
        return this.get('consultarUltimoPdpPorPerfil', params);
    }

    public cadastrarPDP(pdp: PDP) {
        console.log('pdp final', pdp);
        return this.post('cadastrarPdp', pdp);
    }

    public alterarPDP(pdp: PDP) {
        return this.put('alterarPdp', pdp);
    }
}
