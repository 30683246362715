import React, { useContext, useEffect, useState } from 'react';

import Styled from './BiblioFavorites.styled';

import favIcon from '../../../../images/biblioteca/fav-icon.svg';
import noFavIcon from '../../../../images/biblioteca/nofav-icon.svg';
import BiblioCollectionModal from 'pages/biblioteca/subpages/collection/BiblioCollectionModal';
import BiblioColecaoService from 'core/http/service/biblioteca/BiblioColecao.service';
import BiblioCollectionNewModal from 'pages/biblioteca/subpages/collection/BiblioCollectionNewModal';
import BiblioCollectionRemoveModal from 'pages/biblioteca/subpages/collection/BiblioCollectionRemove';
import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';
import BiblioItem from 'models/biblioteca/BiblioItem';
import { useKeycloak } from '@react-keycloak/web';
import useLogged from 'hooks/useLogged';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import { ToastError } from 'components/Toaster';

export interface IBiblioFavoriteButtonItem {
    isFavorited?: boolean;
    data: BiblioItemPreview | BiblioItem;
    width?: string;
}

const BiblioFavoriteButtonItem = ({ data, width }: IBiblioFavoriteButtonItem) => {
    const [showModal, setShowModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showNewCollectionModal, setShowNewCollectionModal] = useState<boolean>(false);
    const { keycloak } = useKeycloak();
    const { login } = useLogged();
    const history = useHistory();

    const MAX_CHARS = 50;

    const [favorited, setFavorite] = useState<boolean>(data.isFavorited);

    const toggleValue = () => {
        if (keycloak?.authenticated) {
            if (data.isFavorited) setShowRemoveModal(true);
            else setShowModal(true);
        } else {
            login(location.pathname, `favorite=true`);
        }
    };

    const onCreate = async (name: string) => {
        if (name.length <= MAX_CHARS) {
            const service = new BiblioColecaoService();
            const response = await service.novaColecao(name, data.id);
            if (response.status == 200) {
                setShowNewCollectionModal(false);
                setFavorite(true);
            }
        } else {
            ToastError('Nome da coleção muito longo!');
        }
    };

    const unfavoriteItem = async () => {
        const service = new BiblioColecaoService();
        await service.removerItem(data.id);
        setShowRemoveModal(false);
        setFavorite(false);

        if (document.URL.includes('biblioteca/meu-perfil/favoritos')) {
            location.reload();
        }
    };

    const checkLastSession = () => {
        const value = location.pathname.split('favorite=')[1];

        if (value && !data.isFavorited) {
            setShowModal(true);
            if (location.pathname.includes('favorite')) {
                history.replace(location.pathname.split('&')[0]);
            }
        }
    };

    useEffect(() => {
        checkLastSession();
    }, []);

    return (
        <>
            <BiblioCollectionModal
                onSelected={() => setFavorite(true)}
                item_id={data.id}
                show={showModal}
                onHide={() => setShowModal(false)}
                toCreate={() => {
                    setShowModal(false);
                    setShowNewCollectionModal(true);
                }}
            />

            <BiblioCollectionNewModal show={showNewCollectionModal} onHide={() => setShowNewCollectionModal(false)} onCreate={onCreate} />

            <BiblioCollectionRemoveModal
                show={showRemoveModal}
                titleItem={data?.title ?? ''}
                onHide={() => setShowRemoveModal(false)}
                onRemove={unfavoriteItem}
            />

            <Styled.Container>
                {favorited ? (
                    <Styled.ActionButton
                        width={width}
                        src={favIcon}
                        onClick={() => {
                            GTM_Page_Biblioteca(`${data.id}`, `favorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`, 'click_favorite_btn');
                            toggleValue();
                        }}
                    />
                ) : (
                    <Styled.ActionButton
                        width={width}
                        src={noFavIcon}
                        onClick={() => {
                            GTM_Page_Biblioteca(
                                `${data.id}`,
                                `not_favorite_${data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'click_not_favorite_btn'
                            );
                            toggleValue();
                        }}
                    />
                )}
            </Styled.Container>
        </>
    );
};

export default BiblioFavoriteButtonItem;
