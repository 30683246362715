import React from 'react';
import Styled from './HeaderNavigateGestaoPaginas.styled';

interface HeaderNavigateGestaoPaginasProps {
    pageIndex: number;
    setPageIndex: (value: number) => void;
}

const HeaderNavigateGestaoPaginas = ({ pageIndex, setPageIndex }: HeaderNavigateGestaoPaginasProps) => {
    const Buttons = ['Páginas', 'Comentários', 'Avaliações'];

    const navigateTo = (value: number) => {
        setPageIndex(value);
    };

    return (
        <Styled.Container>
            <Styled.Title>Gestão de Páginas</Styled.Title>
            <Styled.Row>
                {Buttons.map((nameButton, index) => (
                    <Styled.ButtonLink key={index} active={pageIndex === index} onClick={() => navigateTo(index)}>
                        {nameButton}
                    </Styled.ButtonLink>
                ))}
            </Styled.Row>
        </Styled.Container>
    );
};

export default HeaderNavigateGestaoPaginas;
