import { jsonProperty, Serializable } from 'ts-serializable';

class Macrocompetencia extends Serializable {
    @jsonProperty(Number)
    id: number = 0;

    @jsonProperty(String)
    descricao: string = '';

    constructor() {
        super();
    }
}

export default Macrocompetencia;
