import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const CarouselCardContainer = styled.div`
    width: 100%;
    margin-top: 90px;
    padding: 10px;

    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1367px) {
        display: inline-block;
    }

    @media screen and (max-width: 760px) {
        margin-top: 0px;
    }

    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        max-width: 1366px;

        .slide {
            transform: scale(0.7);
            transition: transform 500ms;
            opacity: 0.4;
        }

        .activeSlide {
            transform: scale(1.1);
            opacity: 1;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        padding: 0px 0 10px 0;
    }
`;
