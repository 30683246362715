import React, { useEffect } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';

//Material
import imagem_01 from '../../assets/realizandoEscolhas/material/imagem_01.png';

import ContactContainer from 'pages/home/components/contact/ContactContainer';

const RealizandoEscolhaSolucao = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Realizando Escolha',
                        descricao: `
                        Em 2020, o Instituto Ayrton Senna criou o Realizando Escolhas, uma proposta desenvolvida para apoiar a estudantes do 9° ano do Ensino Fundamental e do Ensino Médio a 
                        construírem o seu projeto de vida a partir das suas histórias de vida e de suas escolhas
                        `,
                        objetivo: `
                          O objetivo do Realizando Escolhas é oferecer aos estudantes oportunidades
                            de aprofundar seu autoconhecimento em termos de interesses, articulando-os com
                            seu projeto de vida
                         `,
                        publicoAlvo: '9º do Ensino Fundamental e Ensino Médio',
                        avaliacao: 'Instrumento Meus Interesses Profissionais (MIP)',
                        formacao: `
                            Trilha síncrona ou assíncrona comum e específica para cada ator (carga
                            horária – de 6 a 8 horas)
                        `,
                        acompanhamento: `
                            Rubricas, organizadas em formulários de coleta de dados, para
                            guiar e facilitar o acompanhamento de cada etapa
                        `,
                        atores: `
                       Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                        Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                        (gestão da política educacional)
                        `,
                        elementos: `
                          (I) Focada no desenvolvimento do estudante, proporciona aos estudantes
                            a possiblidade de desenvolver capacidades para fazer escolhas e para tomar decisões,
                            além de contribuir com as escolhas relacionadas ao currículo escolar, no Ensino Médio;
                            (II) Apoio à prática docente, materiais de apoio ao professor; e (III) Acompanhamento
                            de gestores, oferece aos gestores escolares e das Secretarias informações sobre como
                            monitorar o processo nas escolas por meio de uma Sistemática de Acompanhamento
                        
                        `,
                    }}
                />
                {/* <InPractice
                    items={[]}
                    description={`
                    Olhar para a alfabetização com a perspectiva da educação integral é essencial para garantir aos estudantes uma base sólida para que sua trajetória escolar consiga apoiar o desenvolvimento de todo o seu potencial.
                    <br />
                    <br />
                    Fortalecer o estudante em suas dimensões cognitivas, socioemocionais, híbridas, culturais e simbólicas, entre outras é um dos principais caminhos para que possam obter conquistas ao longo da vida.
                    `}
                /> */}
                <SupportMaterial
                isBlue
                    description={<></>}
                    img={imagem_01}
                />

                <FormSolucoes  tipoSolucao={TipoSolucoesEnum.REALIZANDO_ESCOLHAS} preDefinition />
                <ContactContainer  />
            </GlobalStyled.Container>
        </div>
    );
};

export default RealizandoEscolhaSolucao;
