import React from 'react';
import { DateHelp } from 'core/util/DateHelp';
import AdminAvaliacao from 'models/admin/AdminAvaliacao';
import AdminModalShow from 'pages/admin/components/modalShow/AdminModalShow';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';
import capitalize from 'pages/admin/utils/capitalize';
import Styled from './AdminSocioEmocional.styled';
import AdminBiblioAvaliacao from 'models/admin/AdminBiblioAvaliacao';

export interface IAdminModalItem {
    show: boolean;
    onHide: () => void;
    item?: AdminAvaliacao;
}

const AdminModalItem = ({ show, onHide, item }: IAdminModalItem) => {
    return (
        <AdminModalShow show={show} onHide={onHide}>
            <Styled.Modal.Title>Avaliação</Styled.Modal.Title>

            {item && (
                <>
                    <Styled.Modal.SubTitle>{capitalize(item.feature)}</Styled.Modal.SubTitle>
                    <br />
                    <Styled.Modal.UserName>Anônimo</Styled.Modal.UserName>
                    <Styled.Modal.Date>{DateHelp.formatDateFull(item.ultimaAtualizacao)}</Styled.Modal.Date>
                    <Styled.Modal.Text>{item.comment}</Styled.Modal.Text>

                    <br />
                    <AdminRateStar rate={item.rate} />
                </>
            )}
        </AdminModalShow>
    );
};

export default AdminModalItem;
