import styled from 'styled-components';

export type TextSizeSmall = 'small' | 's' | 'pequeno';
export type TextSizeMedium = 'medium' | 'm' | 'medio';
export type TextSizeLarge = 'large' | 'l' | 'big' | 'grande';

export type TextSizes = TextSizeLarge | TextSizeMedium | TextSizeSmall;
interface ITitle {
    size?: TextSizes;
}

const getSize = (s: TextSizes): string => {
    if (s === 's' || s === 'small' || s === 'pequeno') return '16px';
    if (s === 'l' || s === 'large' || s === 'grande') return '23px';
    else return '18px';
};

const Title = styled.h2<ITitle>(({ size = 's' }) => {
    const fontSize: string = getSize(size);

    return {
        fontFamily: 'Barlow',
        fontStyle: 'Normal',
        fontWeight: 'bold',
        lineHeight: '29px',

        fontSize: fontSize,
    };
});

export default Title;
