import { Carousel } from 'antd';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleCarouselHome = {
    Container: styled.div`
        position: relative;
        padding-top: 86px;

        @media screen and (max-width: 700px) {
            padding-top: 0;
        }
    `,

    ContainerNav: styled.div`
        position: absolute;
        width: 100%;
        bottom: 9px;
        height: 190px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1000px) {
            height: 160px;
        }

        @media screen and (max-width: 680px) {
            height: 80px;
        }
    `,

    ContentNav: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: ${Colors.White};
        padding-left: 80px;
        padding-right: 80px;
        z-index: 2;

        @media screen and (max-width: 1300px) {
            padding-left: 24px;
            padding-right: 24px;
        }
        @media screen and (max-width: 680px) {
            align-items: center;
            padding-left: 0px;
            padding-right: 0px;
        }
    `,

    ContentInfo: styled.div`
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 80%;

        @media screen and (min-width: 1400px) {
            gap: 3rem;
        }

        @media screen and (max-width: 680px) {
            display: none;
        }
    `,

    ContentCarousel: styled.div<{ img: string; rightPorcent?: string; topPorcent?: string; revert?: boolean }>`
        cursor: pointer;

        margin: 0;
        height: 682px;
        width: 100%;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: ${({ revert }) =>
                    revert
                        ? 'linear-gradient(115deg, rgba(0,0,0,0.4009978991596639) 0%, rgba(47,47,47,0.5102415966386555) 40%, rgba(8,8,8,0.7931547619047619) 100%)'
                        : `linear-gradient(
                    115deg,
                    rgba(0, 0, 0, 0.7567401960784313) 3%,
                    rgba(47, 47, 47, 0.5326505602240896) 37%,
                    rgba(128, 128, 128, 0) 100%
                )`},
                url(${props => props.img});
            background-size: cover;
            background-position: ${({ rightPorcent, topPorcent }) => (rightPorcent ? rightPorcent : '50%') + (topPorcent ? topPorcent : '40%')};
            background-repeat: no-repeat;
            transform: ${({ revert }) => revert && 'scaleX(-1)'}; /* Inverte a imagem */
            z-index: -1; /* Para que o pseudo-elemento fique atrás do conteúdo da div */
        }

        @media screen and (min-width: 1919px) {
            background-position: center 40%;
        }
        @media screen and (min-width: 2500px) {
            background-position: center 20%;
        }
    `,

    ContainerMenuNav: styled.div`
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 100%;
        gap: 0.5rem;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            h1,
            p {
                transform: translateY(-10px); /* Move o texto para cima */
            }

            h1 {
                color: ${Colors.Yellow};
            }

            span {
                opacity: 1; /* Torna o botão visível */
                transform: translateY(0); /* Move o botão para a posição original */
                color: ${Colors.Yellow};

                @media screen and (max-width: 1550px) {
                    transform: translateY(-22px);
                }
            }
        }

        h1,
        p {
            margin: 0;
            transition: transform 0.3s ease; /* Suaviza o movimento */
        }

        span {
            transform: translateY(20px); /* Começa fora da tela, movendo-o para baixo */
            opacity: 0; /* Começa invisível */
            transition: all 0.3s ease; /* Suaviza a transição para visibilidade e posição */
        }
    `,

    ContainerDots: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        bottom: 210px;

        @media screen and (max-width: 1000px) {
            bottom: 180px;
        }

        @media screen and (max-width: 680px) {
            bottom: 100px;
        }
    `,

    ContentItemNav: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;

        @media screen and (max-width: 680px) {
            justify-content: center;
            flex-direction: column;
        }
    `,

    BodyBanner: styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 143px;
        margin-left: 80px;
        position: relative;

        @media screen and (max-width: 1300px) {
            margin-left: 20px;
        }

        @media screen and (max-width: 900px) {
            margin-top: 240px;
        }
        @media screen and (max-width: 670px) {
            margin-top: 240px;
        }
        @media screen and (max-width: 450px) {
            gap: 0.2rem;
        }
    `,

    DestaqueTextBanner: styled.p`
        position: absolute;
        top: -10px;
        font-weight: 600;
        color: ${Colors.SoftBlue};
        text-transform: uppercase;
    `,

    TitleBanner: styled.h1`
        font-family: 'Barlow';
        font-size: 48px;
        font-weight: 700;
        line-height: 62.4px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${Colors.White};

        @media screen and (max-width: 400px) {
            font-size: 42px;
        }
    `,

    DescriptionBanner: styled.p`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        max-width: 564px;
        color: ${Colors.White};
    `,

    TitleNav: styled.h1`
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
    `,
    TitleNavStatic: styled.h2`
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
    `,

    DescriptionNav: styled.p`
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        @media screen and (max-width: 1100px) {
            font-size: 14px;
        }
    `,

    SaibaMais: styled.span`
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
    `,
};

export default StyleCarouselHome;
