import { ToastError, ToastSuccess } from 'components/Toaster';
import { Information } from 'constants/Informations';
import { EmailService } from 'core/http/service/Email.service';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import Email from 'models/Email';
import UsuarioDTO from 'models/UsuarioDTO';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const ModalBlockContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
`;

export const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #003c78;
`;

export const Description = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #003c78;
    text-align: center;
    padding-top: 10px;
`;

export const Link = styled.label`
    text-decoration: underline;
    cursor: pointer;
`;

export const ButtonContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    gap: 1rem;
    padding-top: 20px;
    flex-direction: ${props => (props.isMobile ? 'column-reverse' : 'row')};
`;

export const ButtonFechar = styled.button`
    border: 2px solid #003c78;
    border-radius: 6px;
    background-color: transparent;
    padding: 16px 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #003c78;

    &:hover {
        color: #fcfcfc;
        background: #003c78;
    }
`;

export const ButtonMore = styled.button`
    border: 2px solid #003c78;
    background: #003c78;
    border-radius: 6px;
    padding: 16px 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fcfcfc;

    &:hover {
        color: #003c78;
        background: transparent;
    }
`;

export interface ITrilhaFormativaModal {
    setShow: (value: boolean) => void;
    goToTrilhaFormativaCallback?: () => void;
}
const TrilhaFormativaRoute: string = '/realizandoEscolhas/trilhaFormativa';

const ModalBlocked = ({ setShow, goToTrilhaFormativaCallback }: ITrilhaFormativaModal) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const location = useLocation();
    const history = useHistory();

    const serviceEmail = new EmailService();

    const goToPage = (location: string) => {
        history.push(location);
    };

    const sendEmail = async () => {
        const novoEmail = new Email().fromJSON({
            userId: usuarioLogado.id,
            username: usuarioLogado.username,
            emailUser: usuarioLogado.email,
            emailTo: Information.EmailToHumane.email,
            subject: Information.EmailToHumane.subject,
            isRealizandoEscolha: true,
        });

        const response = await serviceEmail.sendEmail(novoEmail);

        if (response.status === 200) {
            ToastSuccess('E-mail enviado com sucesso. Em breve a equipe da humane responderá seu contato.');
        } else {
            ToastError('Ocorreu alguma falha no envio do e-mail, tente novamente mais tarde.');
        }
    };

    return (
        <ModalBlockContainer>
            <Title>{'Atenção!'}</Title>
            <Description>
                {`A avaliação está disponível somente para Professores. Tem interesse em aplicar na sua escola ou rede?
                        Entre em contato!`}{' '}
                <Link onClick={() => sendEmail()}>(humane@ias.org.br)</Link>
            </Description>
            <ButtonContainer isMobile={IsMobileMaxWidth()}>
                <ButtonFechar onClick={() => setShow(false)}>{'Fechar aviso'}</ButtonFechar>
                <ButtonMore
                    onClick={
                        location.pathname.includes(TrilhaFormativaRoute)
                            ? () => {
                                  goToTrilhaFormativaCallback && goToTrilhaFormativaCallback();
                              }
                            : () => goToPage(TrilhaFormativaRoute)
                    }
                >
                    {'Acessar trilha formativa'}
                </ButtonMore>

                <ButtonMore onClick={() => sendEmail()}>{'Enviar e-mail'}</ButtonMore>
            </ButtonContainer>
        </ModalBlockContainer>
    );
};

export default ModalBlocked;
