import { ProfileService } from 'core/http/service/Profile.service';
import TipoImagem from 'models/imagemPerfil/TipoImagemEnum';
import { ImagePerfilTipo } from 'models/perfil/ImagemPerfilTipo';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import getAvatarFromId from 'pages/profile/components/identificacaoImagem/utils/getAvatarFromId';

const cachedName = 'USERINFO';

export const fetchUserInfo = async (username: string, idPerfil: number): Promise<ProfileWithAvatar> => {
    const service = new ProfileService();

    let result = await service.findByUsername(username);
    const profileData = result.data as ProfileWithAvatar;

    if (result.status === 200) {
        if (profileData) {
            // const cachedProfile = getCache();
            // if (cachedProfile) {
            //     return cachedProfile;
            // }

            if (profileData.tipoIdentificacao !== TipoImagem.EscolherDepois) {
                result = await service.findProfileImage(idPerfil);
                const perfilImageData = result.data as ImagePerfilTipo;

                if (perfilImageData) {
                    if (perfilImageData.tipoImagem == TipoImagem.Avatar) {
                        profileData.srcImage = getAvatarFromId(perfilImageData.codigoAvatar);
                    } else {
                        profileData.srcImage = perfilImageData.imageBase64;
                    }
                }
            }

            setCache(profileData);
        }
    }

    return profileData;
};

const getCache = (): ProfileWithAvatar | null => {
    const cached = sessionStorage.getItem(cachedName);

    if (cached) {
        const cachedProfile: ProfileWithAvatar = JSON.parse(cached);
        return cachedProfile;
    }

    return null;
};

const setCache = (data: ProfileWithAvatar) => {
    sessionStorage.setItem(cachedName, JSON.stringify(data));
};

export default fetchUserInfo;
