import moment from 'moment';
import 'moment/locale/pt-br';
import TimeAgo from 'javascript-time-ago';

import pt from 'javascript-time-ago/locale/pt';

export namespace DateHelp {
    /**
     * Formata uma data no formato pt-BR
     * @param date Data a ser formatada
     * @returns Data no formato brasileiro
     */
    export const formatToStringShort = (date: Date): string => {
        return date.toLocaleString('pt-BR', { dateStyle: 'short', timeZone: 'UTC' });
    };

    export const formatDateWithoutYear = (date: Date): string => {
        return moment(date).format('DD/MM');
    };

    export const formatDate = (date: Date): string => {
        return moment(date).format('DD/MM/YY');
    };

    export const formatDateString = (date: string): string => {
        return moment(date).format('DD/MM/YY');
    };

    export const formatDateFull = (date: Date | string): string => {
        return moment(date).format('DD/MM/YYYY');
    };

    export const formatDateAmericano = (date: Date): string => {
        return moment(date).format('yyyy/MM/dd');
    };

    export const formatTimeAgo = (dateInput: string): any => {
        TimeAgo.addLocale(pt);
        const timeAgo = new TimeAgo('pt-BR');

        const date = Date.parse(dateInput);
        return timeAgo.format(date);
    };

    export const formatDateDiario = (date: Date) => {
        const today = moment();

        if (moment(date).isSame(today, 'day')) {
            return ' às ' + moment(date).format('hh:mm');
        } else {
            return ' em ' + moment(date).format('DD/MM/YY hh:mm');
        }
    };

    export const getYear = (dateInput: string): number => {
        const date = Date.parse(dateInput);
        return moment(date).year();
    };

    export const afterToday = (dataControle: Date | null): Boolean => {
        return dataControle !== null && dataControle < new Date();
    };

    export const beforeToday = (dataControle: Date | null): Boolean => {
        return dataControle !== null && dataControle > new Date();
    };

    export const isToday = (dataControle: Date | null): Boolean => {
        return dataControle !== null && DateHelp.formatDate(dataControle) === DateHelp.formatDate(new Date());
    };

    export const isLessEqualsToday = (dataControle: Date | null): Boolean => {
        return isToday(dataControle) || beforeToday(dataControle);
    };
}
