import _ from 'lodash';
import { AnyAction } from 'redux';

import PDP from '../../models/pdp/PDP';
import MetasDTO from '../../models/pdp/MetasDTO';
import Macrocompetencia from '../../models/pdp/Macrocompetencia';
import {
    GET_MACROCOMPETENCIAS,
    SET_CURRENTSTEPFORM,
    STORE_PDP,
    GET_METAS,
    CLEAR_PDP,
    OPERACAO_ALTERACAO,
    CANCELAR_OPERACAO_ALTERACAO,
    CLEAR_METAS,
    CLEAR_MACRO,
} from '../actions/Pdp';

interface IState {
    pdp: PDP;
    metas: MetasDTO;
    currentStepForm: number;
    macrocompetencias: Macrocompetencia[];
    pdpOriginal: PDP;
}

const initialState: IState = {
    pdp: new PDP(),
    currentStepForm: 1,
    macrocompetencias: [],
    metas: new MetasDTO(),
    pdpOriginal: new PDP(),
};

const pdpReducer = (state = initialState, action: AnyAction): IState => {
    switch (action.type) {
        case GET_MACROCOMPETENCIAS: {
            return {
                ..._.cloneDeep(state),
                macrocompetencias: action.macrocompetencias,
            };
        }
        case GET_METAS: {
            return {
                ..._.cloneDeep(state),
                metas: action.metas,
            };
        }
        case SET_CURRENTSTEPFORM: {
            return {
                ..._.cloneDeep(state),
                currentStepForm: action.currentStepForm,
            };
        }
        case STORE_PDP: {
            return {
                ..._.cloneDeep(state),
                pdp: action.pdp,
            };
        }

        case CLEAR_PDP: {
            return {
                ..._.cloneDeep(state),
                pdp: new PDP(),
                pdpOriginal: new PDP(),
                currentStepForm: 1,
            };
        }

        case CLEAR_METAS: {
            return {
                ..._.cloneDeep(state),
                metas: new MetasDTO(),
                currentStepForm: 1,
            };
        }

        case CLEAR_MACRO: {
            return {
                ..._.cloneDeep(state),
                macrocompetencias: [],
                currentStepForm: 1,
            };
        }

        case OPERACAO_ALTERACAO: {
            return {
                ..._.cloneDeep(state),
                pdpOriginal: state.pdp,
                currentStepForm: 1,
            };
        }

        case CANCELAR_OPERACAO_ALTERACAO: {
            return {
                ..._.cloneDeep(state),
                pdp: state.pdpOriginal,
                pdpOriginal: new PDP(),
                currentStepForm: 1,
            };
        }
        default: {
            return state;
        }
    }
};

export default pdpReducer;
