import styled from 'styled-components';

const AdminImageUploaderStyled = {
    Container: styled.div<{ small: boolean; dash: boolean }>`
        border: ${props => (props.dash ? '1px dashed #b5b5b5' : 'none')};
        box-sizing: border-box;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        width: ${props => (props.small ? '300px' : '100%')};
        height: fit-content;
    `,

    Wrapper: styled.div`
        // border: 1px red solid;
        display: flex;
        flex-direction: column;
    `,

    TitleSoftBlue: styled.p`
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        color: #00a8e9;
    `,

    DescText: styled.p`
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #747474;
        max-width: 140px;
    `,

    PreviewImage: styled.img`
        max-width: 100%;
        object-fit: contain;
    `,

    EditImageIcon: styled.img`
        width: 40px;
        height: 40px;

        transform: translateY(50%);
        cursor: pointer;
    `,

    ImageContainer: styled.div`
        display: flex;
        flex-direction: column;
        width: fit-content;
        position: relative;
        margin-top: -60px;

        p {
            text-align: right;
        }
    `,

    MenuContainer: styled.div`
        position: absolute;
        right: 0;
        top: 60px;
        background-color: white;
        border-radius: 10px;
    `,

    MenuItem: styled.div`
        padding: 10px 20px;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #2e2e2e;
        cursor: pointer;

        :hover {
            color: blue;
        }
    `,

    Hidden: styled.input`
        display: none;
    `,

    BottomText: styled.p`
        color: #747474;
        font-size: 0.85em;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
        max-width: 270px;
    `,

    BottomTextContainer: styled.div`
        // border: 1px red solid;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
};

export default AdminImageUploaderStyled;
