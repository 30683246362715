import styled, { keyframes } from "styled-components";
import { MobileMaxWidth } from "../../../../constants/Dimensions";

export const IdentificacaoStyle = styled.div`
  display: flex;
  height: 100%;
`;

export const Section = styled.section`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

export const FormSection = styled(Section)`
  padding-top: 40px;

  padding-left: 100px;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: ${MobileMaxWidth}px) {
    padding-left: 21px;
    padding-right: 21px;
    display: flex;
    flex-direction: column;
  }
`;

export const ImgSection = styled(Section)`
  justify-content: center;
`;

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SvgFadeImg = styled.img`
  animation: ${fade} 0.3s linear;
`;
