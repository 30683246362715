import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

export type StatusNotificationType = 'LIDA' | 'NAO_LIDA';

class BiblioNotification extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('titulo')
    @jsonProperty(String)
    title: string = '';

    @jsonName('conteudo')
    @jsonProperty(String)
    content: string = '';

    @jsonName('dataEnvio')
    @jsonProperty(String)
    date: string = '';

    @jsonIgnore()
    dateConvert: Date | null = null;

    @jsonName('visualizada')
    @jsonProperty(Boolean)
    viewed: boolean = false;

    @jsonIgnore()
    marked?: boolean = false;
}

export default BiblioNotification;
