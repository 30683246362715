import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Styled from './ConteudoFavorito.styled';
import { IoIosArrowBack } from 'react-icons/io';
import { ConteudoFavoritoCostumizado } from 'models/recursos-costumizados/ConteudoFavoritoCostumizado';
import CardConteudoFavorito from './components/CardConteudoFavorito/CardConteudoFavorito';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import Loading from 'components/Loading';
import ModalRemoveFavorite from './components/modalRemoveFavorite/ModalRemoveFavorite';

const ConteudoFavorito = () => {
    const history = useHistory();
    const [listConteudo, setListConteudo] = useState<ConteudoFavoritoCostumizado[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const recursosContumizadoService = new AdminRecursosCostumizados();

    const listFavoritos = async () => {
        setLoading(true);
        const { data } = await recursosContumizadoService.listConteudosFavoritos();
        const list = Array.from(data.rows).map((conteudo: any) => new ConteudoFavoritoCostumizado().fromJSON(conteudo));
        setListConteudo(list);
        setLoading(false);
    };

    const desfavoritar = async (id: number) => {
        await recursosContumizadoService.desfavoritarConteudo(id);
        setShowModal(false);
        listFavoritos();
    };

    useEffect(() => {
        listFavoritos();
    }, []);

    return (
        <>
            {loading ? (
                <div style={{ marginTop: '120px' }}>
                    <Loading />
                </div>
            ) : (
                <Styled.Container>
                    <Styled.ButtonReturn onClick={() => history.goBack()}>
                        <IoIosArrowBack />
                        Voltar
                    </Styled.ButtonReturn>

                    <Styled.Title style={{ marginTop: '40px', marginBottom: '40px' }}>Meus conteúdos favoritos</Styled.Title>

                    {/* <Styled.SubTitle>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia, fugiat. Tempora assumenda ut quibusdam, fuga, quidem
                        repellendus consectetur.
                    </Styled.SubTitle> */}

                    <Styled.DivisorInative />

                    <Styled.Divisor justify="center">
                        <Styled.Divisor justify="space-between">
                            {listConteudo?.map((d, index) => (
                                <>
                                    <ModalRemoveFavorite
                                        show={showModal}
                                        setShow={setShowModal}
                                        goToExcluded={() => desfavoritar(d.codigoConteudo ?? 0)}
                                    />
                                    <CardConteudoFavorito key={index} dados={d} handleDesfavoritar={setShowModal} />
                                </>
                            ))}
                        </Styled.Divisor>

                        {/*  <Styled.Button>Carregar mais</Styled.Button> */}
                    </Styled.Divisor>
                </Styled.Container>
            )}
        </>
    );
};

export default ConteudoFavorito;
