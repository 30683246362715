import React, { useState, useEffect } from 'react';
import RealizandoEscolhasCard from './RealizandoEscolhasCard';
import { CardGridContainer } from './CardGrid.styled';
import CardHeaderData from '../../data/CardHeaderData.json';
import imgEscolhas from '../assets/images/imgEscolhas.png';
import imgFundamentos from '../assets/images/imgFundamentos.png';
import imgTrilhaFormativa from '../assets/images/imgTrilhaFormativa.png';
import imgInstrumento from '../assets/images/imgInstrumento.png';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import RealizandoEscolhasListMobile from './mobile/RealizandoEscolhasList';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import { useAppSelector } from 'hooks/LocalReduxThunk';

export interface ICardGrid {}

const CardGrid = ({}: ICardGrid) => {
    const [ocupacao, setOcupacao] = useState('');
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const history = useHistory();

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const getImg = (id: number) => {
        switch (id) {
            case (id = 1):
                return imgEscolhas;
            case (id = 2):
                return imgFundamentos;
            case (id = 3):
                return imgTrilhaFormativa;
            case (id = 4):
                return imgInstrumento;
        }
    };

    const goToPage = (location: string) => {
        GTM_Page_Realizando_Escolhas(
            'home_cards_realizando_escolhas',
            `acessar_page_${location.replace('/realizandoEscolhas/', '').toLowerCase()}`
        );
        history.push(location);
    };

    useEffect(() => {
        const userOcupacao = buscarOcupacaoPorPerfil(profile);
        setOcupacao(userOcupacao);
    }, []);

    return (
        <CardGridContainer>
            {!IsMobile() &&
                CardHeaderData.data.map((item, index) => {
                    const count = ocupacao === 'C' ? 2 : CardHeaderData.data.length;

                    return (
                        count > index && (
                            <RealizandoEscolhasCard
                                key={index}
                                title={item.title}
                                contentText={item.text}
                                buttonCallback={() => goToPage(item.location)}
                                srcBackground={getImg(item.id)}
                                moreInfo={item.moreInfo}
                            />
                        )
                    );
                })}

            {IsMobile() &&
                CardHeaderData.data.map((item, index) => {
                    const count = ocupacao === 'C' ? 2 : CardHeaderData.data.length;

                    return (
                        count > index && (
                            <RealizandoEscolhasListMobile
                                key={index}
                                position={item.id}
                                title={item.title}
                                contentText={item.text}
                                buttonCallback={() => goToPage(item.location)}
                                moreInfo={item.moreInfo}
                            />
                        )
                    );
                })}
        </CardGridContainer>
    );
};

export default CardGrid;
