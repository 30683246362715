import { useAppSelector } from 'hooks/LocalReduxThunk';
import React, { useEffect, useState, useCallback } from 'react';
import { PdpContext } from './PdpContext';
import * as pdpActions from '../../../store/actions/Pdp';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export interface IPdpProvider {}

const PdpProvider = ({ children }: React.PropsWithChildren<IPdpProvider>) => {
    const [operacaoAlteracao, _setOperacaoAlteracao] = useState<boolean>(false);
    const [isMetasFetched, _setMetasFetched] = useState(false);

    const setOperacaoAlteracao = (value: boolean) => {
        _setOperacaoAlteracao(value);
    };

    const setMetasFetched = (value: boolean) => {
        _setMetasFetched(value);
    };

    const context: PdpContext.IContext = {
        operacaoAlteracao,
        setOperacaoAlteracao,
        isMetasFetched,
        setMetasFetched
    };

    return <PdpContext.Context.Provider value={context}>{children}</PdpContext.Context.Provider>;
};
export default PdpProvider;
