import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class BiblioItemAnswer extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('conteudo')
    @jsonProperty(String)
    content: string = '';

    @jsonName('nomePerfil')
    @jsonProperty(String)
    username: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    date: string = '';
}

export default BiblioItemAnswer;
