import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const FormLabelStyle = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 42px;
    color: ${Colors.BlueGrey};
    white-space: nowrap;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
    }
`;

interface IFormLabel {
    label: string;
}
const FormLabel = ({ label }: IFormLabel) => {
    return <FormLabelStyle>{label}</FormLabelStyle>;
};

export default FormLabel;
