import React from 'react';
import { Card, Counter, Description } from './InternalCard.styled';

export interface IInternalCard {
    value: number;
    description: string;
    colorPicker: string;
}

const InternalCard = ({ value, description, colorPicker }: IInternalCard) => {
    return (
        <>
            <Card>
                <Counter colorPicker={colorPicker}>{value}</Counter>
                <Description colorPicker={colorPicker}>{description}</Description>
            </Card>
        </>
    );
};
export default InternalCard;
