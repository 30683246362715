import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { BlueSolidButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';

const BibioCommentSectionStyled = {
    Container: styled.div`
        margin-top: 60px;

        @media only screen and (max-width: 1200px) {
            margin-top: 20px;
        }
    `,

    Label: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
    `,

    FormControl: Form.Control,

    AlignRight: styled.div`
        text-align: right;
        margin-top: 10px;
    `,

    CommentWrap: styled.div`
        max-width: 1036px;
    `,

    CommentContainer: styled.div`
        padding: 20px 15px;
        border-radius: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 15px;

        :hover {
            background-color: #fafafa;
            div {
                visibility: visible;
            }
        }
    `,

    InputEditContent: styled.textarea`
        display: block;
        margin-top: 10px;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        :focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }
    `,

    SendEditButton: styled.button`
        margin-top: 10px;
        width: 65px;
        height: 30px;
        font-size: 12px;
        font-weight: bolder;
        color: white;
        border-radius: 50px;
        background-color: #003c78;
        box-sizing: border-box;
        border: none;
    `,

    SendComentary: styled(BlueSolidButton)<{disabled: boolean}>`
    
        background-color: ${({disabled}) => disabled && Colors.Grey10};

        &:hover {
            background-color: ${({disabled}) => disabled && Colors.Grey10};
            color:  ${({disabled}) => disabled && '#FFFFFF'};
            border: ${({disabled}) => disabled && 'none'};
            cursor: ${({disabled}) => disabled && 'auto'}
        }
    
    `,

    CancelEditButton: styled.button`
        margin-top: 10px;
        width: 65px;
        height: 30px;
        font-size: 12px;
        font-weight: bolder;
        color: white;
        border-radius: 50px;
        margin-left: 5px;
        background-color: red;
        box-sizing: border-box;
        border: none;


    `,

    CommentHolder: styled.div`
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
    `,

    CommentContainerRight: styled.div`
        text-align: right;
        height: fit-content;
        transform: translateY(-15px) translateX(-15px);
        position: relative;
    `,

    EditMessage: styled.small`
        color: #797979;
        font-weight: lighter;
        margin-left: 20px;
        font-size: 12px;
    `,

    DotsMenu: styled.img`
        cursor: pointer;
    `,

    DivIcons: styled.div`
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-top: 27px;
        width: fit-content;
    `,

    TrashIcon: styled.img`
        position: absolute;
        margin: 0 -5px;
        height: 15px;
    `,

    EditIcon: styled.img`
        position: absolute;
        margin: 0 -31px;
    `,

    ExcludeMenu: styled.div`
        color: #ff4830;
        background-color: white;
        font-weight: 500;
        border-radius: 5px;
        box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin-top: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;

        position: absolute;
        left: 0;
        top: -20px;

        img {
            margin-right: 7px;
        }
    `,

    CommentUsername: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #003c78;
    `,

    CommentBody: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2e2e2e;
        width: 100%;
        word-wrap: break-word;

        overflow-x: auto;
    `,

    AnswerContainer: styled.div`
        margin-left: 20px;
        padding: 10px;
    `,

    CommentBox: styled.div<{ isDropped: boolean }>`
        display: flex;
        flex-direction: column;
        max-height: ${props => (props.isDropped ? '100%' : '210px')};
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
    `,

    SeeMoreButton: styled.button<{ isDropped: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 62.29%);
        height: 90px;
        width: 100%;
        left: 0;
        bottom: ${props => (props.isDropped ? '20px' : '0')};
        border: none;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    `,

    Icon: styled.img`
        height: 0.9rem;
        transform: translate(-8px, -2px);
    `,
};

export default BibioCommentSectionStyled;
