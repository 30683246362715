import React from 'react';
import Styled from './ParceirosImplementadores.styled';
import GlobalStyled from '../../Solucoes.styled';
import Logo01 from '../../assets/logo_parceiro_01.svg';
import Logo02 from '../../assets/logo_parceiro_02.svg';

type ItemType = {
    titulo: string;
    descricao: string;
    imagem: any;
};

interface IItem {
    dados: ItemType;
}

const Item = ({ dados }: IItem) => {
    return (
        <Styled.Item.Container>
            <Styled.Item.Imagem src={dados.imagem} />
            <Styled.Item.Content>
                <Styled.Item.Title>{dados.titulo}</Styled.Item.Title>
                <Styled.Item.Description>{dados.descricao}</Styled.Item.Description>
            </Styled.Item.Content>
        </Styled.Item.Container>
    );
};

const ParceirosImplementadores = () => {
    return (
        <Styled.Container>
            <GlobalStyled.Title>{`Alguns dos nossos parceiros implementadores`.toLocaleUpperCase()}</GlobalStyled.Title>
            <GlobalStyled.Description
            
                style={{ maxWidth: '80%', fontSize: 20, lineHeight: '28px', marginBottom: 20 }}
            >{`Para tornar a implementação das nossas soluções prática e eficiente, unimos forças com parceiros que compartilham dos nossos sonhos e objetivos. Juntos, garantimos que tudo seja realizado com a máxima atenção e carinho que sua região merece. Conheça alguns deles:`}</GlobalStyled.Description>

            <Item
                dados={{
                    titulo: 'Instituto Âncora Educação',
                    descricao:
                        'O Instituto Âncora Educação trabalha com as mais diferentes ferramentas e tecnologias atuando no ensino infantil, fundamental, técnico e superior. O objetivo é ser completo em nossas soluções para que todas as áreas da educação e cultura possam contar com nossa atuação. Para ser completo o instituto preza pela grande rede de relacionamento que possui.',
                    imagem: Logo01,
                }}
            />
            <Item
                dados={{
                    titulo: 'Instituto FEFIG',
                    descricao:
                        'O Instituto FEFIG faz uma curadoria de projetos educacionais e apoia projetos com resultados comprovados e impacto possível de gerar escala. Ou seja, elevamos a eficácia do projeto: ele alcança o maior número de crianças possíveis e sempre com o objetivo de que cada iniciativa possa futuramente virar política pública',
                    imagem: Logo02,
                }}
            />
        </Styled.Container>
    );
};

export default ParceirosImplementadores;
