import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico from './PerfilDTOGenerico';

class TipoGestorDTO extends PerfilDTOGenerico {
    constructor() {
        super();
    }
}

export default TipoGestorDTO;
