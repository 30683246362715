import { GTM_Menu } from 'components/GoogleTagManager';
import HeaderService from 'core/http/service/admin/AdminHeader.service';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import HeaderItem from 'models/admin/AdminHeaderItem';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import CheckedGoToProvider from 'pages/painelSocioemocional/home/context/CheckedGoToProvider';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import DesktopMenu from './desktop-menu/DesktopMenu';
import Styled from './DynamicHeader.styled';
import Logo from './Logo';
import MobileMenu from './mobile-menu/MobileMenu';

export interface IDynamicHeader {}

const DynamicHeader = ({}: IDynamicHeader) => {
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const history = useHistory();
    const service = new HeaderService();
    const [headerItens, setHeaderItens] = useState<HeaderItem[]>([]);
    const [subItemActive, setSubItemActive] = useState<number>(0);
    const { isSearchView } = useContext(PesquisaContext.Context);

    const isMobile = () => {
        return IsCustomMaxWidth(1250);
    };

    const fetchHeaderItens = async () => {
        const { data } = await service.buscarHeader(keycloak.authenticated ? 'LOGADO' : 'DESLOGADO');

        const _headerItens = Array.from(data).map((h: any) => {
            const _item = new HeaderItem().fromJSON(h);
            _item.subheaders = Array.from(h.subheaders).map((sub: any) => new HeaderItem().fromJSON(sub));
            return _item;
        });

        setHeaderItens(_headerItens);
    };

    const isResumeMenu = () => {
        return (
            location.pathname.startsWith('/admin') ||
            location.pathname.startsWith('/naoAutorizado') ||
            location.pathname.startsWith('/not-found')
        );
    };

    const exit = () => {
        GTM_Menu('menu_header', 'logout_btn_unauthorized');

        if (location.pathname.startsWith('/admin')) history.push('/logout');
        else history.push('/');
    };

    useEffect(() => {
        fetchHeaderItens();
    }, []);

    return (
        <>
            {/* {isSearchView && location.pathname != '/' && location.pathname != '/home' && <BarraPesquisa />} */}

            <Styled.Container>
                <CheckedGoToProvider>
                    <Logo />

                    {isResumeMenu() ? (
                        <Styled.RightSideContainer>
                            <Styled.RightButton onClick={exit}>Sair</Styled.RightButton>
                        </Styled.RightSideContainer>
                    ) : (
                        <Styled.RightSideContainer>
                            {isMobile() ? (
                                <MobileMenu items={headerItens} itemActive={subItemActive} handleActive={setSubItemActive} />
                            ) : (
                                <DesktopMenu items={headerItens} />
                            )}
                        </Styled.RightSideContainer>
                    )}
                </CheckedGoToProvider>
            </Styled.Container>
        </>
    );
};

export default DynamicHeader;
