import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Styled from './AdminBiblioNew.styled';

import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { Radio, RadioGroup } from 'react-radio-input';
import { acceptedExtsLivro, acceptedLanguages, isSaveRascunho, isStringsValid } from './isValidNewContentInput';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminBiblioItemSave from 'models/admin/AdminBiblioItemSave';
import AdminSubAtividade from 'models/admin/AdminSubAtividade';
import { cloneDeep } from 'lodash';
import closeFileIcon from '../../../../images/admin/close-item.svg';

import trashIcon from '../../../../images/admin/trash-red.svg';
import submitAttach, { AdminBiblioAnexoType, fecthAttachImage, fetchTagsItemId, submitSubAttach } from './submitAttach';
import { IAdminBiblioEditorContent } from 'pages/admin/subpages/biblio/post/AdminBiblioNewContent';
import AdminTagSelector from '../tagSelector/AdminTagSelector';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import BiblioSubAtividade from 'models/biblioteca/BiblioSubAtividade';
import { DateHelp } from 'core/util/DateHelp';
import Select, { SelectOptionType } from 'components/Select';
import { StringHelp } from '../../../../core/util/StringHelp';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { ToastError } from 'components/Toaster';
import ItemBibliotecaRelatedContentListagem from '../itemBibliotecaRelatedContentList/ItemBibliotecaRelatedContentListagem';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';

const isMobile = () => IsMobileMaxWidth();

const AdminBiblioNewAtividade = ({ onSaved, itemData, categoriaId, categorias }: IAdminBiblioEditorContent) => {
    const [id, setId] = useState<number>(-1);
    const [slug, setSlug] = useState<string>('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [authors, setAuthors] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [titleTag, setTitleTag] = useState('');
    const [metaDescriptionTag, setMetaDescriptionTag] = useState('');
    const [isPublic, setIsPublic] = useState('false');

    const [imageCover, setImageCover] = useState<File>();
    const [preimageCover, setPreImageCover] = useState<string | null>(null);

    const [subsActivities, setSubsActivities] = useState<AdminSubAtividade[]>([{} as AdminSubAtividade]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isValidInputs = (): boolean => {
        if (!preimageCover && !imageCover) return false;
        return isStringsValid([title, content, authors, slug]);
    };

    const isValidInputsSub = (sub: AdminSubAtividade): boolean => {
        if (sub.yearPublication < '1') {
            ToastError('Ano de Publicacao em uma subatividade inválido');
            return false;
        }

        if (sub.pagesNumber < 1) {
            ToastError('Número de páginas em uma subatividade inválido');
            return false;
        }

        let check = false;
        acceptedExtsLivro.forEach(accepted => {
            if (sub.attach && sub.attach.name.endsWith(accepted)) {
                check = true;
            }
        });

        if (!check) {
            ToastError('Arquivo anexo em uma subatividade inválido ou não selecionado');
            return false;
        }
        return true;
    };

    const categoriaExistente = (): boolean => {
        return categorias.filter(c => c.ativo).some(c => c.id == categoriaId);
    };

    const submit = async (_status: 'PUBLICADO' | 'RASCUNHO') => {
        setIsSubmitting(true);
        const service = new AdminBiblioService();

        const itemSave = new AdminBiblioItemSave();

        for (let i = 0; i < subsActivities.length; i++) {
            const sub = subsActivities[i];
            if (!isValidInputsSub(sub)) return false;
        }

        itemSave.slug = slug;
        itemSave.titulo = title;
        itemSave.conteudo = content;
        itemSave.tipoItemBiblioteca = categoriaId;
        itemSave.status = _status;
        itemSave.publico = isPublic === 'true';
        itemSave.tags = tags;
        itemSave.autores = authors;
        itemSave.titleTag = titleTag;
        itemSave.metaDescriptionTag = metaDescriptionTag;

        if (itemSave.publico && !categoriaExistente()) {
            alert('Não é possível publicar um item de um categoria inativa');
            setIsSubmitting(false);
            return;
        }

        let data, status: number;

        const responseUnicidade = await service.validarUnicidadeSlug(itemData?.id ?? null, itemSave.slug);
        let slugValido: boolean = false;
        if (responseUnicidade.status === 200 && responseUnicidade.data === true) {
            slugValido = true;
        }

        if (slugValido) {
            if (!itemData) {
                const response = await service.criarItem(itemSave);
                data = response.data;
                status = response.status;
            } else {
                const response = await service.atualizarItem2(itemData.id, itemSave, tags);
                data = response.data;
                status = response.status;
                onSaved();
            }

            if (status == 200) {
                if (imageCover) submitAttach(data.id, imageCover, AdminBiblioAnexoType.CAPA);

                for (let i = 0; i < subsActivities.length; i++) {
                    const response = await submitSub(subsActivities[i], data.id);
                    if (subsActivities[i].attach) {
                        await submitSubAttach(response.data.codigo, subsActivities[i].attach);
                    }
                }

                onSaved();
            }
        } else {
            ToastError('URL Slug já cadastrado para outro conteúdo');
        }

        setIsSubmitting(false);
    };

    const submitSub = async (sub: AdminSubAtividade, idItem: number) => {
        const service = new AdminBiblioService();
        sub.itemParent = idItem;
        const year = new Date(parseInt(String(sub.yearPublication)), 1, 1);
        sub.yearPublication = year.toISOString();

        if (sub.id > 0) return service.editarSubItem(sub.id, sub);
        else return service.criarSubItem(sub);
    };

    const addSub = () => {
        const clone = cloneDeep(subsActivities);
        clone.push({} as AdminSubAtividade);
        setSubsActivities(clone);
    };

    const removeSub = (idx: number) => {
        const clone = cloneDeep(subsActivities);
        clone.splice(idx, 1);
        setSubsActivities(clone);
    };

    const setName = (idx: number, value: string) => {
        const clone = cloneDeep(subsActivities);
        clone[idx].title = value;
        setSubsActivities(clone);
    };
    const setNumberPages = (idx: number, value: number) => {
        const clone = cloneDeep(subsActivities);
        clone[idx].pagesNumber = value;
        setSubsActivities(clone);
    };
    const setLanguage = (idx: number, value: string) => {
        const clone = cloneDeep(subsActivities);
        clone[idx].language = value;
        setSubsActivities(clone);
    };
    const setYear = (idx: number, value: number) => {
        const clone = cloneDeep(subsActivities);

        clone[idx].yearPublication = value;
        setSubsActivities(clone);
    };

    const chooseFile = (idx: number) => {
        const inputElement = document.getElementById('file-input-sub-' + idx);

        if (inputElement) {
            inputElement.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.files && target.files[0]) {
                    const clone = cloneDeep(subsActivities);
                    clone[idx].attach = target.files[0];
                    setSubsActivities(clone);
                }
            };
            inputElement.click();
        }
    };

    const getNameFile = (idx: number): string => {
        const attach = subsActivities[idx].attach;

        if (attach) return attach.name;
        else return 'file.ext';
    };

    const removeAttach = (idx: number) => {
        const clone = cloneDeep(subsActivities);
        clone[idx].attach = null;
        setSubsActivities(clone);
    };

    const fillData = async () => {
        if (itemData != null) {
            setSlug(itemData.slug);
            setId(itemData.id);
            setContent(itemData.content ?? '');
            setTitle(itemData.title);
            setAuthors(itemData.authors);
            setTags(itemData.tags ?? []);
            setTitleTag(itemData.titleTag);
            setMetaDescriptionTag(itemData.metaDescriptionTag);
            setIsPublic('false');

            const _imageCover = await fecthAttachImage(itemData.id, AdminBiblioAnexoType.CAPA);
            if (_imageCover) setPreImageCover(_imageCover);

            fetchSubativities();
        }
    };

    const fetchSubativities = async () => {
        if (itemData) {
            const service = new BiblioConteudoService();
            const { data } = await service.buscarSubatividades(itemData.id);
            const _subs: AdminSubAtividade[] = data
                .map((s: any) => new AdminSubAtividade().fromJSON(s))
                .map((s: AdminSubAtividade) => {
                    s.yearPublication = DateHelp.getYear(String(s.yearPublication));
                    return s;
                });

            setSubsActivities(_subs);

            for (let i = 0; i < _subs.length; i++) {
                const { data: data2 } = await service.buscarAnexoSubatividades(_subs[i].id);
                _subs[i].attach = { name: data2.file } as File;
            }

            setSubsActivities(_subs);
            const tags = await fetchTagsItemId(itemData.id);
            setTags(tags);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.FormContainer>
                    {isMobile() && (
                        <Styled.ImageContainer>
                            <AdminImageUploader
                                onSelect={(_, f) => setImageCover(f)}
                                size={'sm'}
                                title={'Imagem da capa'}
                                preImageB64={preimageCover}
                                recommendedDimension={{ w: 200, h: 130 }}
                            />
                        </Styled.ImageContainer>
                    )}

                    <Styled.Label>Url Slug</Styled.Label>
                    <Styled.Input
                        value={slug}
                        onChange={(e: any) => setSlug(StringHelp.alnum(StringHelp.toSize(e.target.value.toLowerCase().trim(), 150)))}
                    />
                    <br />
                    <Styled.Label>Título</Styled.Label>
                    <Styled.Input value={title} onChange={(e: any) => setTitle(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Autores</Styled.Label>
                    <Styled.Input value={authors} onChange={(e: any) => setAuthors(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Descrição</Styled.Label>
                    <ReactQuill
                        theme="snow"
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        value={content}
                        onChange={val => {
                            setContent(val);
                        }}
                    />
                </Styled.FormContainer>

                {!isMobile() && (
                    <Styled.ImageContainer>
                        <AdminImageUploader
                            onSelect={(_, f) => setImageCover(f)}
                            size={'sm'}
                            title={'Imagem da capa'}
                            preImageB64={preimageCover}
                            recommendedDimension={{ w: 200, h: 130 }}
                        />
                    </Styled.ImageContainer>
                )}
            </Styled.Row>
            <br />
            <br />

            {subsActivities.map((sub, idx) => {
                //console.log(sub);

                return (
                    <Styled.RowGrid key={idx}>
                        <Styled.ColumsGrid>
                            <Styled.Label>Nome da atividade</Styled.Label>
                            <Styled.Input value={sub.title} onChange={(e: any) => setName(idx, e.target.value)} />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Números de páginas</Styled.Label>
                            <Styled.Input
                                className={sub.pagesNumber < 0 && 'is-invalid'}
                                value={sub.pagesNumber}
                                type={'number'}
                                onChange={(e: any) => setNumberPages(idx, e.target.value)}
                            />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Idioma</Styled.Label>
                            <Select
                                itens={acceptedLanguages}
                                value={{ value: sub.language, label: sub.language }}
                                onSelected={o => setLanguage(idx, String(o.value))}
                                isSmall={true}
                            />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Ano</Styled.Label>
                            <Styled.Input
                                className={sub.yearPublication < '0' && 'is-invalid'}
                                value={sub.yearPublication}
                                type="number"
                                onChange={(e: any) => setYear(idx, e.target.value)}
                            />
                        </Styled.ColumsGrid>
                        <Styled.ColumsGrid>
                            <Styled.Label>Aquivo</Styled.Label>

                            <Styled.Row>
                                {!sub.attach ? (
                                    <>
                                        <GradientButton onClick={() => chooseFile(idx)}>Adicionar Arquivo</GradientButton>
                                        <Styled.Hidden type="file" id={'file-input-sub-' + idx} accept="*" />
                                    </>
                                ) : (
                                    <Styled.FileHolder style={{ width: '250px' }}>
                                        <p style={{ fontSize: '0.8em' }}>{getNameFile(idx)}</p>
                                        <Styled.FileHolderIcon src={closeFileIcon} onClick={() => removeAttach(idx)} />
                                    </Styled.FileHolder>
                                )}

                                {idx > 0 && (
                                    <img src={trashIcon} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => removeSub(idx)} />
                                )}
                            </Styled.Row>
                        </Styled.ColumsGrid>
                    </Styled.RowGrid>
                );
            })}
            <Styled.PlusButton style={{ marginLeft: '20px' }} onClick={addSub}>
                +
            </Styled.PlusButton>

            <div style={{ padding: '20px' }}>
                <AdminTagSelector tags={tags} onSelected={(newTags: string[]) => setTags(newTags)} />

                <br />
                <Styled.Label>Título da Página</Styled.Label>
                <Styled.Input value={titleTag} onChange={(e: any) => setTitleTag(StringHelp.toSize(e.target.value, 150))} />

                <br />
                <Styled.Label>Meta Description</Styled.Label>
                <Styled.Input value={metaDescriptionTag} onChange={(e: any) => setMetaDescriptionTag(StringHelp.toSize(e.target.value, 500))} />

                <br />

                <Styled.Label>Tipo de visualização</Styled.Label>

                <Styled.CkeckContainer style={{ paddingTop: '12px' }}>
                    <RadioGroup name={'isPublic'} onChange={setIsPublic} selectedValue={'false'}>
                        <label htmlFor="isPublic">
                            <Radio id="isPublic" value={'true'} />
                            <Styled.TextCheckBox>Público</Styled.TextCheckBox>
                        </label>

                        <label htmlFor="isPrivate">
                            <Radio id="isPrivate" value={'false'} />
                            <Styled.TextCheckBox>Privado</Styled.TextCheckBox>
                        </label>
                    </RadioGroup>
                </Styled.CkeckContainer>
                <br />
                <ItemBibliotecaRelatedContentListagem itemData={itemData} />
                <br />
            </div>
            <br />
            <Styled.BottomRight>
                {isSaveRascunho(itemData) && (
                    <OutlineButton disabled={!isValidInputs() || isSubmitting} onClick={() => submit('RASCUNHO')}>
                        {isSubmitting ? 'Salvando...' : 'Salvar Rascunho'}
                    </OutlineButton>
                )}
                <GradientButton
                    disabled={!isValidInputs() || isSubmitting}
                    style={{ marginLeft: '10px', marginTop: '10px' }}
                    onClick={() => submit('PUBLICADO')}
                >
                    {isSubmitting ? 'Salvando...' : 'Publicar'}
                </GradientButton>
            </Styled.BottomRight>
        </Styled.Container>
    );
};

export default AdminBiblioNewAtividade;
