import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class HomepageResult extends Serializable {
    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String, null)
    descricao: string = '';

    @jsonProperty(String)
    urlImage: string = '';

    @jsonProperty(String, null)
    link: string | null = null;

    @jsonProperty(String, null)
    slug: string | null = null;

    @jsonProperty(Date, null)
    prazoInscricaoCursoLivre: Date | null = null;

    @jsonProperty(Date, null)
    dataExpiracaoCursoLivre: Date | null = null;

    @jsonProperty(Boolean)
    cursoLivreEmProducao: boolean = false;

    @jsonProperty(String, null)
    categoria: string | null = null;

    @jsonProperty(String, null)
    subcategoria: string | null = null;
}

export default HomepageResult;
