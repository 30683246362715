import { StringHelp } from './StringHelp';

export namespace ColorHelp {
    /**
     * Encontra uma cor dentro de um gradiente
     * @param c0 Cor inicial do degradê
     * @param c1 Cor final do degradê
     * @param f Valor entre 0 e 1 (0 a 100%) dentro do gradiente
     * @returns A cor desejada
     */
    export const interpolateColor = (c0: string, c1: string, f: number) => {
        let color0: string = StringHelp.replaceAll(c0, '#', '');
        let color1: string = StringHelp.replaceAll(c1, '#', '');

        const _c0 = color0.match(/.{1,2}/g)?.map(oct => parseInt(oct, 16) * (1 - f)) ?? [];
        const _c1 = color1.match(/.{1,2}/g)?.map(oct => parseInt(oct, 16) * f) ?? [];
        let ci = [0, 1, 2].map(i => Math.min(Math.round(_c0[i] + _c1[i]), 255));
        return ci
            .reduce((a, v) => (a << 8) + v, 0)
            .toString(16)
            .padStart(6, '0');
    };

    /**
     * Retorna o valor hexadecimal para transparência
     * @param val Valor entre 0 e 1
     * @returns O valor em HEX
     */
    export const decToHexTransparency = (val: number): string => {
        return (val * 256).toString(16).substr(0, 2);
    };
}
