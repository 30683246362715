import Loading from 'components/Loading';
import { ToastError, ToastSuccess } from 'components/Toaster';
import copy from 'copy-to-clipboard';
import AdminRecursosCostumizadosService from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ConteudoRecursoCustomizado as conteudo } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { ItemRecursosCostumizado as Item, ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { LogAcaoRecursoEnum, LogAcaoRecursoCustomizado, browserGenerateKey } from 'models/recursos-costumizados/LogAcaoRecursoCustomizado';
import React, { useState, useEffect, useContext } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsShare } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import { RecursosContext } from '../context/RecursosContext';
import Accordion from './components/accordion/Accordion';
import BarProgress from './components/barProgress/BarProgress';
import Styled from './RecursosPageItem.styled';
import {
    adicionarRedirecionamentoPosLogin,
    CHAVE_REDIRECT_AFL_AP,
    CHAVE_REDIRECT_ALF,
    CHAVE_REDIRECT_BDS,
    CHAVE_REDIRECT_RE,
    CHAVE_REDIRECT_TM,
    CHAVE_REDIRECT_VB,
    limparTodasChaves,
} from 'core/util/StorageRedirectRulesHelper';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { UsuariosCertificadosService } from 'core/http/service/UsuariosCertificados.service';
import { Divider, notification } from 'antd';
import { LiaCertificateSolid } from 'react-icons/lia';
import UsuarioDTO from 'models/UsuarioDTO';
import { RoleEnum } from 'enums/RoleEnum';
import Colors from 'enums/Colors';

const RecursosPageItem = () => {
    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();
    const { keycloak } = useKeycloak();
    const [api, contextHolder] = notification.useNotification();

    const [currentOpen, setCurrentOpen] = useState(-1);
    const [item, setItem] = useState<Item>();
    const [notFoundItem, setnotFoundItem] = useState<boolean>(false);
    const [isSequential, setSequential] = useState(false);
    const { itemRecurso, loading, pagina, certificacao } = useContext(RecursosContext.Context);
    const [emissaoRealizada, setEmissaoRealizada] = useState<boolean>(false);

    const serviceRecursosCostumizados = new AdminRecursosCostumizadosService();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const usuarioCertificadoService = new UsuariosCertificadosService();

    const handleClickAccordion = (keyAccordion: number) => {
        if (keyAccordion == currentOpen) setCurrentOpen(-1);
        else setCurrentOpen(keyAccordion);
    };

    const getPrevLevel = () => {
        const paths = location.pathname.split('/');
        let path = '';

        for (let i = 0; i < paths.length - 1; i++) {
            if (paths[i].length > 0) {
                path += paths[i] + '/';
            }
        }

        return path;
    };

    const gotoBackLevel = () => {
        let newPath = getPrevLevel();
        newPath = newPath.substring(0, newPath.length - 1);
        history.push('/' + newPath);
    };

    const copyToClipboard = () => {
        copy(window.location.href);
        toast.success('Link do item copiado!');
    };

    const gotoDonePoint = () => {
        if (itemRecurso) {
            for (const etapa of itemRecurso?.etapas)
                for (const conteudo of etapa.conteudos.sort((c1, c2) => c1.codigo - c2.codigo))
                    if (!conteudo.isConcluido) {
                        history.push(`${location.pathname}${etapa.url}/${conteudo.url}`);
                        return;
                    }
        }
    };

    const gotoFavs = () => {
        history.push('/recursos/conteudos-favoritos');
    };

    const validDataLog = (log: LogAcaoRecursoCustomizado): boolean => {
        return log.nomeRecurso.trim().length > 0 && log.codigoUsuario.trim().length > 0;
    };

    const registerAction = (acao: LogAcaoRecursoEnum) => {
        if (userProfile) {
            const log: LogAcaoRecursoCustomizado = new LogAcaoRecursoCustomizado().fromJSON({
                codigo_usuario: userProfile.keycloakId,
                nome_recurso: pagina?.nome,
                url_pagina: location.pathname,
                acao: acao,
                codigo_item: item?.codigo?.toString(),
                especificacao_item: item?.nome,
                browser_key: browserGenerateKey(),
            });

            if (validDataLog(log)) new AdminRecursosCostumizadosService().registrarAcao(log);
        } else if (pagina) {
            const log: LogAcaoRecursoCustomizado = new LogAcaoRecursoCustomizado().fromJSON({
                codigo_usuario: 'NAO_LOGADO',
                nome_recurso: pagina?.nome,
                url_pagina: location.pathname,
                acao: acao,
                especificacao_item: item?.nome,
                browser_key: browserGenerateKey(),
            });

            if (validDataLog(log)) new AdminRecursosCostumizadosService().registrarAcao(log);
        }
    };

    const MessageError = (msg: string) => {
        api['error']({
            style: { backgroundColor: Colors.Grey10 },
            message: <h1 style={{ fontWeight: 700 }}>ACESSO NEGAGADO!</h1>,
            description: <p style={{ fontSize: 16, fontWeight: 500 }}>{msg}</p>,
            duration: 5,
        });
    };

    useEffect(() => {
        if (itemRecurso) {
            setItem(itemRecurso);
            setSequential(itemRecurso.conteudosSequenciais);
        }
    }, [itemRecurso]);

    useEffect(() => {
        if (pagina && item) {
            registerAction(LogAcaoRecursoEnum.ACESSO_PAGINA);
        }
    }, [item?.codigo, pagina?.codigo, userProfile?.codigo]);

    const verifySequencies = (value: conteudo): boolean => {
        const etapas: number = item?.etapas.length ?? 0;
        if (isSequential || item?.etapasSequenciais) {
            for (let i = 0; i < etapas; i++) {
                const sizeConteudo: number = item?.etapas[i].conteudos.length ?? 0;

                for (let k = 0; k < sizeConteudo; k++) {
                    const conteudo = item?.etapas[i].conteudos[k];

                    if (conteudo?.codigo === value.codigo) {
                        if (i !== 0 && item?.etapasSequenciais) {
                            if (item?.etapas[i - 1] !== undefined) {
                                const conteudoEtapaAnterior = item?.etapas[i - 1].conteudos.length ?? 0;

                                for (let j = 0; j < conteudoEtapaAnterior; j++) {
                                    if (!item?.etapas[i - 1].conteudos[j].isConcluido) {
                                        MessageError(`Você precisa concluir a etapa "${item?.etapas[i - 1].nome}" para prosseguir.`);
                                        return false;
                                    }
                                }
                            } else {
                                return false;
                            }
                        }

                        if (k === 0){
                            return true
                        };

                        if (item?.etapas[i].conteudos[k - 1].isConcluido || item?.etapas[i].conteudos[k - 1].oculto === true) {
                            return true;
                        } else {
                            MessageError(`Você precisa concluir o "${item?.etapas[i].conteudos[k - 1].nome}" para prosseguir.`);
                            return false;
                        }
                    }
                }
            }

            return false;
        }

        return true;
    };

    const fetchEmissaoUser = async () => {
        if (certificacao && usuarioLogado.id != '') {
            const response = await usuarioCertificadoService.verificarEmissao(certificacao.codigo ?? -1);
            setEmissaoRealizada(response.data);
        }
    };

    const handleClickCertificado = async () => {
        if (emissaoRealizada) {
            return window.open(certificacao.link?.toString());
        }

        const response = await usuarioCertificadoService.associarUser(certificacao.codigo ?? -1);

        if (certificacao.precisaSolicitar) {
            if (response.status === 201) {
                ToastSuccess('Certificado solicitado com sucesso!');
            }
        } else {
            window.open(certificacao.link?.toString());
        }

        fetchEmissaoUser();
    };

    useEffect(() => {
        limparTodasChaves();

        if (item?.url === '/trilha-maker-criatividade') {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_TM, CHAVE_REDIRECT_TM);
        } else if (item?.url === '/trilha-de-alfabetizacao') {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_ALF, CHAVE_REDIRECT_ALF);
        } else if (item?.url === '/violencia-escolar-e-bullying') {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_VB, CHAVE_REDIRECT_VB);
        } else if (item?.url === '/trilha-de-alfabetizacao-aprofundada') {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_AFL_AP, CHAVE_REDIRECT_AFL_AP);
        } else if (item?.url === '/beneficios-do-desenvolvimento-socioemocional-para-os-estudantes') {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_BDS, CHAVE_REDIRECT_BDS);
        }
    }, [item]);

    useEffect(() => {
        fetchEmissaoUser();
    }, [certificacao]);

    return (
        <>
            <Toaster />
            {contextHolder}
            <Styled.Container>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <Styled.Row>
                            <Styled.GoBackButton onClick={gotoBackLevel}>
                                <IoIosArrowBack />
                                <span>Voltar</span>
                            </Styled.GoBackButton>

                            <Styled.SpaceHorizontal />

                            <Styled.RowButtons>
                                {keycloak.authenticated && (
                                    <Styled.ButtonFavorite width="160px" onClick={gotoFavs}>
                                        <AiOutlineHeart /> {'Meus Favoritos'}
                                    </Styled.ButtonFavorite>
                                )}

                                <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>{'Compartilhar'}</Tooltip>}>
                                    <Styled.ShareButton variant="secondary" onClick={copyToClipboard}>
                                        <BsShare size={'20px'} />
                                    </Styled.ShareButton>
                                </OverlayTrigger>
                            </Styled.RowButtons>
                        </Styled.Row>

                        <Styled.Content>
                            <Styled.InfoContainer>
                                <Styled.Title>{item?.nome}</Styled.Title>
                                <Styled.SubTitle>{item?.descricao}</Styled.SubTitle>
                                <Styled.SubTitle dangerouslySetInnerHTML={{ __html: item?.descricaoExtra ?? '' }} />

                                {keycloak.authenticated && (
                                    <React.Fragment>
                                        <Styled.TextProgress>{'Progresso geral'}</Styled.TextProgress>
                                        <BarProgress porcent={item?.percentualConclusao ?? 0} />
                                        {item?.percentualConclusao != 100 && (
                                            <Styled.Button onClick={gotoDonePoint}>{'Continuar de onde parei'}</Styled.Button>
                                        )}
                                        {item?.percentualConclusao === 100 &&
                                            item?.codigoCertificacao !== -1 &&
                                            item?.codigoCertificacao !== null &&
                                            !usuarioLogado.roles.includes(RoleEnum.Administrador) && (
                                                <div
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <Divider />
                                                    <Styled.Title>
                                                        <LiaCertificateSolid size={32} /> Certificado de conclusão
                                                    </Styled.Title>
                                                    <Styled.SubTitle>{certificacao.textoSolicitacao}</Styled.SubTitle>

                                                    <Styled.Button
                                                        disabled={emissaoRealizada && certificacao.precisaSolicitar}
                                                        style={{
                                                            gap: '0.5rem',
                                                            backgroundColor: emissaoRealizada ? Colors.Blue : '',
                                                            color: emissaoRealizada ? Colors.White : '',
                                                        }}
                                                        onClick={handleClickCertificado}
                                                    >
                                                        {' '}
                                                        <LiaCertificateSolid size={24} />{' '}
                                                        {emissaoRealizada && !certificacao.precisaSolicitar
                                                            ? 'Emitir 2ª via'
                                                            : emissaoRealizada && certificacao.precisaSolicitar
                                                            ? 'Você já solicitou este certificado'
                                                            : certificacao.precisaSolicitar
                                                            ? 'Soliciar certificado'
                                                            : 'Emitir certificado'}
                                                    </Styled.Button>
                                                </div>
                                            )}
                                    </React.Fragment>
                                )}
                            </Styled.InfoContainer>

                            <Styled.EtapasContainer style={{ marginTop: item?.percentualConclusao === 100 ? 30 : 0 }}>
                                {item && item.etapas.length > 0 ? (
                                    item.etapas
                                        .sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                                        .filter(e => {
                                            const conteudosNaoOcultos = e.conteudos.filter(c => c.oculto === false);
                                            return e.oculto === false && conteudosNaoOcultos.length > 0;
                                        })
                                        .map((etapa, index) =>
                                            etapa.conteudos.length > 0 ? (
                                                <Accordion
                                                    keyAccordion={index}
                                                    key={index}
                                                    open={currentOpen}
                                                    handleClick={() => handleClickAccordion(index)}
                                                    data={etapa}
                                                    item={item}
                                                    isSequential={isSequential}
                                                    handleSequential={verifySequencies}
                                                />
                                            ) : undefined
                                        )
                                ) : (
                                    <Styled.SubTitle style={{ textAlign: 'center' }}>Este item ainda não possui etapas</Styled.SubTitle>
                                )}
                            </Styled.EtapasContainer>
                        </Styled.Content>
                    </>
                )}
            </Styled.Container>
        </>
    );
};

export default RecursosPageItem;
