import ProfileData from 'models/ProfileData';
import { GenericService } from './Generic.service';

export class IntegracaoLmsService extends GenericService {
    readonly URI: string = '/v1/integracaoLMS';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public gerarGuid(idPerfil: number, username: string) {
        const params = {
            idPerfil,
            username,
        };
        return this.get('gerarGuid', params);
    }

    public statusMatricula(username: string, idCurso: number) {
        const params = {
            username: username,
            orgUnitId: idCurso,
        };
        return this.get('statusMatricula', params);
    }

    public matricular(username: string, idCurso: number) {
        const requestBody = {
            username: username,
            orgUnitId: idCurso,
        };
        return this.post('matricular', requestBody);
    }

    public consultarCompletions(idCurso: number, username: string) {
        const params = {
            idCurso: idCurso,
            username: username,
        };
        return this.get('consultarCompletion', params);
    }

    public verificarCursoConcluido(idCurso: number, username: string) {
        const params = {
            idCurso: idCurso,
            username: username,
        };
        return this.get('verificarCursoConcluido', params);
    }
}
