import styled from 'styled-components';

const ExemplesStyled = {
    Container: styled.div`
        max-width: 1350px;
        margin: 10px auto;
        padding: 30px 20px;
        background-color: white;
        border-radius: 10px;
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin: 20px 0;
    `,

    Division: styled.div`
        height: 1px;
        width: 100%;
        background-color: #ccc;
        margin: 35px 0;
    `,
};

export default ExemplesStyled;
