import styled from 'styled-components';

const StyledActionComponent = {
    Container: styled.div`
        display: flex;
        gap: 4rem;
        padding: 10px;
    `,

    ColorPicker: styled.input`
        width: 30px;
    `,

    Action: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,

    Label: styled.p`
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
    `,

    InputTamanhoFont: styled.input`
        text-align: center;
        width: 40px;
        height: 30px;

        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;

        :focus {
            outline: none;
        }
    `,
};

export default StyledActionComponent;
