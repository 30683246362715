import "core-js/proposals/reflect-metadata";
import { jsonProperty, Serializable } from "ts-serializable";
import StatusInstrumentoEnum from "./StatusInstrumentoEnum";

class InstrumentoDTO extends Serializable {

   @jsonProperty(Number)
   id: number = -1;

   @jsonProperty(String, null)
   situation: StatusInstrumentoEnum | null = null;

   constructor() {
    super();
  }
}

export default InstrumentoDTO;
