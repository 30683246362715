import { Serializable, jsonProperty } from 'ts-serializable';
import PerfilTipoDeficienciaDTO from './PerfilTipoDeficienciaDTO';
import TipoDeficienciaDTO from './TipoDeficienciaDTO';

class PerfilAtualizationDTO extends Serializable {
    @jsonProperty(String)
    username: string = '';

    @jsonProperty(String)
    dataNascimento: string = '';

    @jsonProperty(String)
    cpf: string = '';

    @jsonProperty(Number)
    cor: number = -1;

    @jsonProperty(Number)
    genero: number = -1;

    @jsonProperty(String)
    estado: string = '';

    @jsonProperty(String)
    cidade: string = '';

    @jsonProperty(String)
    whatsapp: string = '';

    @jsonProperty(Number)
    comoConheceu: number = -1;


    constructor() {
        super();
    }
}

export default PerfilAtualizationDTO;
