import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../enums/Colors';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';

export const IntermediatePageContainer = styled.div(({ ...props }) => () => {
    return new FullCss(
        {
            marginTop: '86px',
            position: 'relative',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            marginTop: '60px',
        })
    ).getCss();
});

export const ContentContainer = styled.div(() => {
    return new FullCss({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingbottom: '70px',
    }).getCss();
});

export const TitleBannerContainer = styled.section(() => {
    return new FullCss(
        {
            height: '183px',
            display: 'flex',
            width: '100%',
            backgroundColor: Colors.LightBlue2,
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            height: '142px',
        })
    ).getCss();
});

export const ReturnButtonContainer = styled.div`
    width: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 93px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        padding-left: 0px;
    }
`;

export const BannerTitle1 = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 68.5%;
    color: ${Colors.Blue};

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        font-size: 16px;
        line-height: 11px;
    }
`;

export const BannerTitle2 = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 70px;
    color: ${Colors.Blue};

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        font-size: 24px;
        line-height: 22px;
    }
`;

interface IIconSvg {
    transformRule: string;
}

export const IconSvg = styled.img<IIconSvg>`
    position: absolute;
    right: 10%;
    transform: ${props => props.transformRule}; ;
`;

interface IBreadcrumbContainer {
    isCursoLivre?: boolean;
}

export const BreadcrumbContainer = styled.div<IBreadcrumbContainer>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${props => (props.isCursoLivre ? '40px 40px 40px 150px' : '40px 40px 40px 150px')};
    white-space: break-spaces;
`;

interface IBreadcrumbItem {
    currentPage?: boolean;
}
export const BreadcrumbItem = styled.p<IBreadcrumbItem>`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    letter-spacing: 0.1px;
    color: ${props => (props.currentPage ? Colors.DarkBlue : Colors.Grey3)};
    &:hover {
        cursor: ${props => (!props.currentPage ? 'pointer' : 'auto')};
        text-decoration: ${props => (!props.currentPage ? 'underline' : 'auto')};
    }
`;

export const Divisor = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${Colors.Grey3};
`;

export const ChildrenContainer = styled.div`
    padding-left: 3%;
    padding-right: 3%;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-left: 0;
        padding-right: 0;
    }
`;
