import React from 'react';
import styled from 'styled-components';

export interface ISliderIndicator {
    current: number;
    length: number;
}

const Dot = styled.div`
    width: 7px;
    height: 7px;
    background-color: #a3a3a3;
    border-radius: 50%;
    margin: 2px;
`;

const DotHilight = styled(Dot)`
    background-color: #f79c00;
    width: 24px;
    border-radius: 4px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    padding: 7px;
    margin-bottom: 14px;
`;

const SliderIndicator = ({ current, length }: ISliderIndicator) => {
    return (
        <Container>
            {[...Array(length)].map((_, i) => {
                return i != current ? <Dot /> : <DotHilight />;
            })}
        </Container>
    );
};

export default SliderIndicator;
