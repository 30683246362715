import React from 'react';
import Styled from './Apresentation.styled';
import GlobalStyled from '../../Solucoes.styled';
import Imagem from '../../assets/foto_01.svg';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';

const Apresentation = () => {

    return (
        <Styled.Container>
            <Styled.Content>
                <GlobalStyled.Title>{`Soluções Educacionais`.toLocaleUpperCase()}</GlobalStyled.Title>
                <GlobalStyled.Description>
                    {`
                    O Instituto Ayrton Senna oferece uma oportunidade única para as redes de ensino e seus educadores lidarem com os desafios diários. 
                    Com uma equipe dedicada, sua rede terá acesso a soluções embasadas em evidências científicas e décadas de experiência na educação. 
                    Imagine ter acesso a ferramentas comprovadas que podem melhorar o desempenho dos alunos e promover o desenvolvimento profissional dos educadores.
                `}
                </GlobalStyled.Description>
            </Styled.Content>
            <Styled.Img isMobile={IsMobileMaxWidth()} src={Imagem} />
        </Styled.Container>
    );
};

export default Apresentation;
