import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import AdminHomeSection from 'models/admin/AdminHomeSection';
import { GenericService } from '../Generic.service';

export enum secaoConteudoEnum {
    CAPA = "CAPA",
    SOLUCOES_EDUCACIONAIS = "SOLUCOES_EDUCACIONAIS",
    BIBLIOTECA = "BIBLIOTECA",
    JORNADA = "JORNADA",
    DEPOIMENTOS = "DEPOIMENTOS",
	ESPACO_EDUCADOR = "ESPACO_EDUCADOR",
    TEMATICAS = "TEMATICAS"
}

export default class AdminHomeConteudoService extends GenericService {
    readonly URI: string = '/v1/homepage';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public submeterCapa(
        titulo: string,
        texto: string,
        texto_botao_a: string,
        texto_botao_b: string,
        link_interno: string,
        paginaLateral: boolean
    ) {
        return this.post('conteudo', {
            titulo,
            conteudo: texto,
            textoBotaoA: texto_botao_a,
            textoBotaoB: texto_botao_b,
            linkInterno: link_interno,
            paginaLateral: paginaLateral,
            secao: 'CAPA',
        });
    }

    public submeterConteudo(conteudo: AdminHomeConteudo) {
        return this.post('conteudo', conteudo.toJSON());
    }

    public atualizarConteudo(id: number, conteudo: AdminHomeConteudo) {
        return this.put('conteudo/' + id, conteudo.toJSON());
    }

    public inserirAnexo = (item_id: number, titulo: string, descricao: string, data: FormData) => {
        return this.post('conteudo/anexo', data, { conteudo_home_page_id: item_id, titulo, descricao });
    };

    public buscarAnexoConteudo(id: number, comBase64?: boolean) {
        const params = {
            comBase64: comBase64,
        };
        return this.get(`conteudo/${id}/anexo`, params);
    }

    public buscarCategoriaConteudo() {
        return this.get(`conteudo/categorias`);
    }

    public modificarCategoria(id: number) {
      this.put(`conteudo/categoria`, { id });
  }

    public atualizarCapa(
        id: number,
        titulo: string,
        texto: string,
        texto_botao_a: string,
        texto_botao_b: string,
        link_interno: string,
        paginaLateral: boolean
    ) {
        return this.put('conteudo/' + id, {
            titulo,
            conteudo: texto,
            textoBotaoA: texto_botao_a,
            textoBotaoB: texto_botao_b,
            linkInterno: link_interno,
            paginaLateral: paginaLateral,
            secao: 'CAPA',
        });
    }

    public submeterSolEducacionais(
        titulo: string,
        texto: string,
        link: string,
        linkExterno: boolean,
        ativo: boolean,
        desabilitado: boolean,
        complemento: string,
        proximoLancamento: boolean,
        novidade: boolean,
        ordem: number,
        categoria: any
    ) {
        return this.post('conteudo', {
            titulo,
            conteudo: texto,
            link,
            complemento,
            linkExterno,
            ativo,
            desabilitado,
            secao: 'SOLUCOES_EDUCACIONAIS',
            proximoLancamento,
            novidade,
            ordem,
            categorias: categoria,
        });
    }

    public atualizarSolEducacionais(
        id: number,
        titulo: string,
        texto: string,
        link: string,
        linkExterno: boolean,
        ativo: boolean,
        desabilitado: boolean,
        complemento: string,
        proximoLancamento: boolean,
        novidade: boolean,
        ordem: number,
        categoria: any
    ) {
        return this.put('conteudo/' + id, {
            titulo,
            conteudo: texto,
            link,
            complemento,
            linkExterno,
            ativo,
            desabilitado,
            proximoLancamento,
            novidade,
            categorias: categoria,
            ordem,
            secao: 'SOLUCOES_EDUCACIONAIS',
        });
    }

    public submeterTematica(
      titulo: string,
      texto: string,
      link: string,
      linkExterno: boolean,
      ativo: boolean,
      desabilitado: boolean,
      complemento: string,
      proximoLancamento: boolean,
      novidade: boolean,
      ordem: number
  ) {
      return this.post('conteudo', {
          titulo,
          conteudo: texto,
          link,
          complemento,
          linkExterno,
          ativo,
          desabilitado,
          secao: 'TEMATICAS',
          proximoLancamento,
          novidade,
          ordem
      });
  }

  public atualizarTematicas(
      id: number,
      titulo: string,
      texto: string,
      link: string,
      linkExterno: boolean,
      ativo: boolean,
      desabilitado: boolean,
      complemento: string,
      proximoLancamento: boolean,
      novidade: boolean,
      ordem: number
  ) {
      return this.put('conteudo/' + id, {
          titulo,
          conteudo: texto,
          link,
          complemento,
          linkExterno,
          ativo,
          desabilitado,
          proximoLancamento,
          novidade,
          ordem,
          secao: 'TEMATICAS',
      });
  }

    public submeterDepoimento(titulo: string, texto: string, ocupacao: string, complemento: string) {
        return this.post('conteudo', { titulo, conteudo: texto, complemento, ocupacao, secao: 'DEPOIMENTOS' });
    }

    public atualizarOrdem(id: number, toIndex: number | string, secao: secaoConteudoEnum) {
        return this.put('conteudo/atualizar-ordem', { id, toIndex, secao });
    }

    public atualizarDepoimento(id: number, titulo: string, texto: string, ocupacao: string, complemento: string) {
        return this.put('conteudo/' + id, { titulo, conteudo: texto, complemento, ocupacao, secao: 'DEPOIMENTOS' });
    }

    public buscaConteudo(secao: AdminHomeSection) {
        return this.get('conteudo', { secao });
    }
}
