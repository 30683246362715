import ProfileData from 'models/ProfileData';
import { GenericService } from './Generic.service';

export class IntegracaoInstrumentoService extends GenericService {
    readonly URI: string = '/v1/integracaoInstrumento';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public consultarInstrumentos(idPerfil: number, username: string) {
        const params = {
            idPerfil,
            username,
        };
        return this.get('consultarInstrumentos', params);
    }
}
