import { jsonProperty, Serializable } from 'ts-serializable';

export class CardCategoria extends Serializable {
    @jsonProperty(Number, null)
    codigo?: number | null = -1;

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(String)
    hoverMenu: string = '';
}
