import { YellowButton } from 'components/UI/Buttons';
import { ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledConteudoFavorito = {
    Container: styled.div`
        padding-top: 150px;
        padding-left: 80px;
        padding-right: 80px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: ${ResizeMediumWidth}px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    `,

    ButtonReturn: styled(YellowButton)`
        width: 150px;
        order: 0;
    `,

    Title: styled.h1`
        font-weight: 600;
        font-size: 30px;
        color: #003c78;
    `,

    SubTitle: styled.p`
        font-family: 'Barlow';
        margin-top: 20px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        max-width: 500px;
    `,

    DivisorInative: styled.div`
        background-color: ${Colors.InativeGrey};
        height: 1px;
        max-width: 500px;
    `,

    Divisor: styled.div<{ justify: string }>`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: ${props => props.justify};
        width: 100%;
        margin-top: 30px;

        @media screen and (max-width: ${ResizeMediumWidth}px) {
            justify-content: center;
            flex-direction: column;
        }
    `,

    Button: styled.button`
        width: 200px;
        height: 70px;
        border-radius: 40px;
        background-color: ${Colors.LightBlue2};
        border: none;
        color: white;
        font-weight: 500;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 30px;
    `,
};

export default StyledConteudoFavorito;
