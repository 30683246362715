import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import Colors from '../../enums/Colors';

interface IStyledCheckbox {
    checked: boolean;
}
const StyledCheckbox = styled.div<IStyledCheckbox>`
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid ${Colors.Grey10};
    box-sizing: border-box;
    border-radius: 5px;
    background: ${props => (props.checked ? Colors.SoftBlue2 : Colors.White)};
    transition: all 100ms;

    cursor: pointer;
`;

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

interface ICheckbox {
    checked: boolean;
    onClick: any;
}
const Checkbox: FunctionComponent<ICheckbox> = ({ checked, onClick }: ICheckbox) => (
    <CheckboxContainer onClick={onClick}>
        <HiddenCheckbox checked={checked} />

        <StyledCheckbox checked={checked}>
            <Icon viewBox="1.5 4 20 20">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
);

export default Checkbox;
