import React, { useEffect, useState } from 'react';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import CursoLivre from 'pages/admin/components/cursoLivre/CursoLivre';
import AdminEditModalSave from 'pages/admin/components/pageEditors/AdminEditModalSave';
import PaginaIntermediaria from 'pages/admin/components/paginaIntermediaria/PaginaIntermediaria';
import Styled from './AdminCursosPage.styled';

export interface IAdminCursosPage {}

const AdminCursosPage = ({}: IAdminCursosPage) => {
    const [showModalSaved, setShowModalSaved] = useState(false);

    const onSaved = () => setShowModalSaved(true);

    return (
        <>
            <AdminEditModalSave
                show={showModalSaved}
                onHide={() => {
                    setShowModalSaved(false);
                }}
            />

            <Styled.Container>
                <AdminBreadCrumb crumbs={[{ route: 'cursos', label: 'Cursos livres' }]} />

                <PaginaIntermediaria onSaved={onSaved} paginaKey={'CURSO_LIVRE'} />

                <CursoLivre onSaved={() => {}} />
            </Styled.Container>
        </>
    );
};

export default AdminCursosPage;
