import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import AmpliarImg from 'components/ampliarImg/AmpliarImg';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import React, { useState } from 'react';

import Styled from '../../AlfaTeoriaPratica.styled';

const ATP_Apresentacao = ({ setImgAmpliar }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo=""
                titulo={`
                    Apresentação
            `}
                description={`
                    Ana Carolina Zuanazzi
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Apresentação',
                }}
                footer={{
                    numberSummary: 6,
                }}
            >
                <Styled.ContentWhite>
                    Em qualquer sociedade, a alfabetização é um dos pilares do desenvolvimento individual e coletivo. No Brasil, a trajetória da
                    alfabetização reflete uma história complexa, marcada por desafios e conquistas que foram moldando o panorama educacional
                    contemporâneo. Neste e-book, buscamos oferecer de forma concisa uma visão abrangente e atualizada dos diversos aspectos
                    envolvidos no processo de alfabetização no país. Para isso, contamos com a colaboração de pesquisadores e educadores de
                    diferentes áreas de atuação dentro da temática da alfabetização.
                    <br />
                    <br />A compreensão do cenário da alfabetização no Brasil exige uma análise do contexto em que ela se insere historicamente.
                    Desde os primórdios da educação no país até os dias atuais, a alfabetização, como política, passou por inúmeras
                    transformações. Por isso, iniciamos o e-book com um panorama histórico, destacando os principais fatos e iniciativas que
                    impactaram o ensino da leitura e da escrita ao longo de décadas. Ao entender o passado, podemos melhor compreender os
                    desafios presentes e delinear estratégias mais eficazes para o futuro.
                    <br />
                    <br />
                    Tão importante quanto olhar para o passado é compreender e apreender os estudos mais recentes sobre o tema, que servem como
                    um farol que orienta o planejamento de ações e seu acompanhamento. Nos últimos anos, as neurociências têm oferecido ideias
                    valiosas sobre o funcionamento do cérebro humano durante o processo de aprendizagem da leitura e da escrita. A integração dos
                    conhecimentos neurocientíficos com a prática educacional pode potencializar significativamente o aprendizado e, assim,
                    contribuir para a formação de leitores competentes e críticos. Esse é o tema abordado no segundo capítulo, que compila os
                    principais achados e avanços na área.
                    <br /> <br />
                    Ainda na perspectiva de estudarmos o desenvolvimento humano, no Capítulo 3 tratamos da educação infantil como a base sobre a
                    qual se constrói toda a trajetória educacional de um indivíduo. Reconhecer a importância dessa fase é indispensável para
                    garantir um processo de alfabetização eficaz. Esse capítulo aborda as melhores práticas e abordagens pedagógicas para a
                    educação infantil, com destaque para o fato de que um ambiente mediado rico em estímulos e experiências pode facilitar a
                    aquisição da leitura e da escrita. A preparação adequada na primeira infância é um investimento
                </Styled.ContentWhite>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas',
                    secoundText: 'Apresentação',
                }}
                footer={{
                    numberSummary: 7,
                }}
            >
                <Styled.ContentWhite>
                    essencial para o sucesso acadêmico futuro.
                    <br />
                    <br />
                    Sabemos que a alfabetização é multifacetada e envolve a segmentação de múltiplas habilidades, incluindo decodificação de
                    palavras e também a compreensão e a interpretação crítica de textos, o desenvolvimento do pensamento reflexivo e a capacidade
                    de se comunicar de maneira eficaz. Esses elementos, também envolvem os aspectos socioemocionais, ambientais e culturais.
                    Sendo assim, no Capítulo 4 discutimos a necessidade de uma abordagem integral na alfabetização, que contemple não apenas a
                    técnica da leitura e escrita, mas também o desenvolvimento cognitivo, emocional e social dos estudantes.
                    <br />
                    <br />
                    Por fim, reunimos no último capítulo algumas práticas inspiradoras implementadas por educadoras que, em diferentes níveis de
                    ensino e gestão, têm feito a diferença na alfabetização de suas comunidades, em diversas regiões do país. Essas práticas não
                    apenas evidenciam a diversidade e a riqueza cultural do Brasil, mas também oferecem modelos e estratégias que podem ser
                    adaptados e replicados em diferentes contextos. São exemplos que demonstram como o conhecimento aplicado com criatividade,
                    compromisso e paixão pela educação pode transformar realidades.
                    <br /> <br />
                    Esperamos que este livro inspire educadores, gestores, pesquisadores e formuladores de políticas a continuar buscando
                    maneiras inovadoras e eficazes de promover a alfabetização em nosso país. Acreditamos que, ao unir teoria e prática, podemos
                    construir um futuro no qual a alfabetização seja um direito plenamente realizado para todos os brasileiros.
                    <br /><br />
                    <Styled.TitleBlue style={{fontSize: 16}}>Boa leitura!</Styled.TitleBlue>
                </Styled.ContentWhite>
            </PageEbook>
        </>
    );
};

export default ATP_Apresentacao;
