import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import React, { useEffect, useState } from 'react';
import {
    CardStyle,
    ContentStyle,
    Title,
    DescriptionText,
    FooterStyle,
    CardImg,
    Wrapper,
    CardImgContainer,
    PrimaryButton,
    SecondaryButton,
    HoverButton,
    SvgIcon,
    DefaultText,
    TextBox,
} from './CardCarousel.styled';
import crossIcon from '../../../../../images/cross-icon.svg';
import playIcon from '../../../../../images/play-icon.svg';
import { bucketS3, caminhoAnexosGestaoPaginaCardCategoria } from 'constants/EnvironmentData';
import ContentLoader from 'react-content-loader';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { CardsSecaoCardCategoria } from 'models/generic-components/CardsSecaoCardCategoria';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import { useHistory } from 'react-router';
import useLogged from 'hooks/useLogged';
import { GTM_Gestao_Paginas } from 'components/GoogleTagManager';

interface IHoverCard {
    item: CardsSecaoCardCategoria;
}

const CardCarousel = ({ item }: IHoverCard) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [isHovered, setHovered] = useState<Boolean>(false);
    const { authenticated, login } = useLogged();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaCardCategoria();
    };

    const getPathname = (): string => {
        return location.pathname.split('/pagina')[1];
    };

    const getSizeCard = (): number => {
        return 440;
    };

    const goToJourney = () => {
        GTM_Gestao_Paginas(getPathname(), `card_carousel_jouney`, `${item.link}`);
        GenericComponentsUtils.gotoLink(item.link, history, authenticated, item);
    };

    // Página Intermediária
    const goToSaibaMais = () => {
        GTM_Gestao_Paginas(getPathname(), `card_carousel_know_more_about`, `${item.link}`);
        GenericComponentsUtils.gotoLink(item.link, history, authenticated, item);
    };

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const MyLoader = () => {
        return IsMobile() ? (
            <>
                <ContentLoader
                    width={290}
                    height={130}
                    viewBox="0 0 290 130"
                    backgroundColor="#f0f0f0"
                    foregroundColor="#dedede"
                    title="Carregando..."
                >
                    <rect x="0" y="0" rx="0" ry="0" width="290" height="130" />
                </ContentLoader>
            </>
        ) : (
            <>
                <ContentLoader
                    width={getSizeCard()}
                    height={174}
                    viewBox={`0 0 ${getSizeCard()} 174`}
                    backgroundColor="#f0f0f0"
                    foregroundColor="#dedede"
                    title="Carregando..."
                >
                    <rect x="0" y="0" rx="0" ry="0" width="440" height="174" />
                </ContentLoader>
            </>
        );
    };
    return (
        <Wrapper>
            <CardStyle width={getSizeCard()}>
                <CardImgContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    {!loading ? (
                        <>
                            <CardImg style={isHovered ? { opacity: '0.3' } : { opacity: '1' }} src={getS3BaseUrl() + item.filePath} />
                        </>
                    ) : (
                        <MyLoader />
                    )}
                    {isHovered && (
                        <HoverButton
                            onClick={() => {
                                item.ativo ? goToJourney() : goToSaibaMais();
                            }}
                        >
                            <SvgIcon src={item.ativo ? playIcon : crossIcon}></SvgIcon>
                            <DefaultText>{item.ativo ? 'Acessar' : 'Saiba mais'}</DefaultText>
                        </HoverButton>
                    )}
                </CardImgContainer>
                <ContentStyle>
                    <Title
                        onClick={() => {
                            item.ativo ? goToJourney() : goToSaibaMais();
                        }}
                    >
                        {item.titulo}
                    </Title>
                    <TextBox>
                        <DescriptionText>{item.conteudo}</DescriptionText>
                    </TextBox>
                </ContentStyle>
                <FooterStyle>
                    <PrimaryButton onClick={goToSaibaMais}>{'Saiba mais'}</PrimaryButton>
                    <SecondaryButton
                        onClick={() => {
                            item.ativo ? goToJourney() : () => {};
                        }}
                        isActive={item.ativo}
                    >
                        {item.ativo ? 'Acessar' : 'Em breve'}
                    </SecondaryButton>
                </FooterStyle>
            </CardStyle>
        </Wrapper>
    );
};

export default CardCarousel;
