import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}

const StyledCarousel = {
    Title: styled.h2`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin: 20px 0;
    `,
    SubTitle: styled.p`
        text-align: flex-start;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #2e2e2e;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 14px;
            max-width: 315px;
            text-align: center;
            width: 100%;
        }
    `,

    ContainerHeader: styled.div`
        margin-left: 100px;
    `,

    ContainerSlider: styled.div`
        margin-top: 90px;
        padding: 10px;

        @media screen and (min-width: 1367px) {
            display: inline-block;
        }

        @media screen and (max-width: 760px) {
            margin-top: 0px;
        }

        @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
            max-width: 1366px;

            .slide {
                transform: scale(0.7);
                transition: transform 500ms;
                opacity: 0.4;
            }

            .activeSlide {
                transform: scale(1.1);
                opacity: 1;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            .slick-slide {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            padding: 0px 0 10px 0;
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 97%;
        right: ${props => (props.next ? '35%' : '')};
        left: ${props => (!props.next ? '35%' : '')};
    `,
};

export default StyledCarousel;
