import BiblioMediaNota from 'models/biblioteca/BiblioMediaNota';
import React, { FunctionComponent } from 'react';

import Styled from './GradeAvarage.styled';
import StyledBiblio from '../AdminBiblioteca.styled';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';

interface IGradeAvarage {
    itens: BiblioMediaNota[];
}

const GradeAvarage = ({ itens }: IGradeAvarage) => {
    return (
        <React.Fragment>
            <Styled.GridGradeAvarage>
                <StyledBiblio.GridHead>Tipo</StyledBiblio.GridHead>
                <StyledBiblio.GridHead>Título do conteúdo</StyledBiblio.GridHead>
                <StyledBiblio.GridHead>Média</StyledBiblio.GridHead>
                <StyledBiblio.GridHead>Total de avaliações</StyledBiblio.GridHead>
                {/* Corpo */}
                {itens.map((d, index) => (
                    <React.Fragment key={index}>
                        <StyledBiblio.GridCol>{d.tipo}</StyledBiblio.GridCol>
                        <StyledBiblio.GridCol>{d.titulo}</StyledBiblio.GridCol>
                        <StyledBiblio.GridCol>
                            <AdminRateStar rate={d.media} />
                        </StyledBiblio.GridCol>
                        <StyledBiblio.GridCol>{d.total}</StyledBiblio.GridCol>
                    </React.Fragment>
                ))}
            </Styled.GridGradeAvarage>
        </React.Fragment>
    );
};

export default GradeAvarage;
