import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

import DiarioTag from './DiarioTag';
import DiarioAttach from './DiarioAttach';

class DiarioBordo extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String, null)
    titulo: string | null = null;

    @jsonProperty(String)
    criacao: string = "";

    @jsonProperty(Date, null)
    edicao: Date | null = new Date();

    @jsonProperty(String, null)
    texto: string | null = null;

    @jsonProperty(Boolean)
    isFixed: boolean = false;

    @jsonProperty(String, null)
    tag: string | null = null;

    @jsonProperty([DiarioTag], null)
    tags: DiarioTag[] | null = null;

    @jsonProperty([DiarioAttach], null)
    attachs: DiarioAttach[] | null = null;

    constructor() {
        super();
    }
}

export default DiarioBordo;
