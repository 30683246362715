import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton } from 'components/UI/Buttons';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import submitAttach from './submitAttach';
import defaultContentHome from 'data/defaultContentHome';
import CursoLivre from '../cursoLivre/CursoLivre';
import PaginaIntermediaria from '../paginaIntermediaria/PaginaIntermediaria';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';

type AdminEditSectionType = 'BIBLIOTECA' | 'ESPACO_EDUCADOR';

export interface IAdminEditSection {
    type: AdminEditSectionType;
    onSaved: () => void;
}

const AdminEditSection = ({ type, onSaved }: IAdminEditSection) => {
    const [cover, setCover] = useState<File>();
    const [preCover, setPreCover] = useState<string | null>(null);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [link_interno, setLinkInterno] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const [thisId, setThisId] = useState<number>(0);

    const MAX_CHAR_CONTENT = 1000;

    const submit = async () => {
        setSubmitting(true);
        const service = new AdminHomeConteudoService();

        const conteudo = new AdminHomeConteudo();
        conteudo.title = title;
        conteudo.content = text;
        conteudo.section = type;
        conteudo.texto_botao_a = texto_botao_a;
        conteudo.linkInterno = link_interno;

        let data, status: number;

        if (thisId <= 0) {
            const response = await service.submeterConteudo(conteudo);
            data = response.data;
            status = response.status;
        } else {
            const response = await service.atualizarConteudo(thisId, conteudo);
            data = response.data;
            status = response.status;
        }

        if (status == 200) {
            setThisId(data.id);
            if (cover) await submitAttach(data.id, cover);
        }

        setSubmitting(false);
        onSaved();
    };

    const fetchItem = async () => {
        const service = new AdminHomeConteudoService();
        const { data } = await service.buscaConteudo(type);

        const result = new AdminHomeConteudo().fromJSON(data[0]);

        if (result.id != -1) {
            setTitle(result.title);
            setText(result.content);
            setThisId(result.id);
            setTextoBotaoA(result.texto_botao_a);
            setLinkInterno(result.linkInterno);
            fetchImage(result.id);
        } else {
            const dft = defaultContentHome;
            setPreCover(type == 'BIBLIOTECA' ? dft.biblio.image : dft.spaceTeacher.image);
            setTitle(type == 'BIBLIOTECA' ? dft.biblio.title : dft.spaceTeacher.title);
            setText(type == 'BIBLIOTECA' ? dft.biblio.text : dft.spaceTeacher.text);
            setTextoBotaoA(type == 'BIBLIOTECA' ? dft.biblio.texto_botao_a : dft.spaceTeacher.texto_botao_a);
            setLinkInterno(type == 'BIBLIOTECA' ? dft.biblio.link_interno : dft.spaceTeacher.link_interno);
        }
    };

    const fetchImage = async (contentId: number) => {
        if (contentId != -1) {
            const service = new AdminHomeConteudoService();
            const { data, status } = await service.buscarAnexoConteudo(contentId, true);
            if (status == 200 && data.base64Content) setPreCover('data:image/*;base64,' + data.base64Content);
        }
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <Styled.Container>
                <div>
                    <Styled.Label>Título da Página Inicial</Styled.Label>
                    <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                    <br />
                    <Styled.Label>Texto da Página Inicial</Styled.Label>
                    <Styled.Input
                        value={text}
                        onChange={(evn: any) => setText(evn.target.value)}
                        rows={8}
                        as="textarea"
                        className={'form-control'}
                        maxLength={MAX_CHAR_CONTENT}
                    />
                    <Styled.CountLetter danger={text.length >= MAX_CHAR_CONTENT}>
                        {text.length}/{MAX_CHAR_CONTENT}
                    </Styled.CountLetter>

                    <Styled.Label>Botão primário</Styled.Label>
                    <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />

                    <Styled.Label>Link interno botão primário</Styled.Label>
                    <Styled.Input value={link_interno} onChange={(evt: any) => setLinkInterno(evt.target.value)} />
                </div>

                <Styled.RigthArea style={{ justifyContent: 'space-between' }}>
                    <AdminImageUploader
                        onSelect={(_, file: File) => setCover(file)}
                        size={'sm'}
                        title={'Imagem do banner'}
                        preImageB64={preCover}
                        recommendedDimension={{ w: 460, h: 530 }}
                    />
                    <br />
                    <GradientButton disabled={submitting} onClick={submit}>
                        {submitting ? 'Salvando...' : 'Salvar'}
                    </GradientButton>
                </Styled.RigthArea>
            </Styled.Container>
        </>
    );
};

export default AdminEditSection;
