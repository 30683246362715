import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { Serializable, jsonProperty } from 'ts-serializable';

export enum StatusCardModulo {
    EMBREVE = 'EMBREVE',
    DISPONIVEL = 'DISPONIVEL',
}

class CardAtividades extends Serializable {
    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(Number)
    status: StatusCardModulo = StatusCardModulo.EMBREVE;

    constructor() {
        super();
    }
}

export default CardAtividades;
