import { PrimaryButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const ContatoLancamentoFormStyled = {
    Container: styled.div`
        width: 40%;
        padding: 15px 20px;
        background-color: #f4f4f4;
        border-radius: 5px;
        box-shadow: 1px 1px 10px 6px rgb(0 0 0 / 10%);

        @media only screen and (min-width: 1460px) and (max-width: 1780px) {
            min-width: 600px;
            max-width: 630px;
            width: 50%;
        }

        @media only screen and (min-width: 855px) and (max-width: 1460px) {
            min-width: 600px;
            max-width: 630px;
            width: 70%;
        }

        @media only screen and (min-width: 760px) and (max-width: 855px) {
            width: 80%;
        }

        @media only screen and (max-width: 760px) {
            width: 100%;
        }
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #003c78;
    `,

    Label: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: #2e2e2e;
    `,

    ButtonNavy: styled(PrimaryButton)`
        background-color: #003c78;
        color: white;
        font-weight: 500;
        border: 1px solid #003c78;

        :hover {
            background-color: white;
            color: #003c78;
            font-weight: 700;
        }
    `,
    FooterContainer: styled.div`
        display: flex;
        align-items: center;
        gap: 2vw;

        @media only screen and (max-width: 540px) {
            flex-direction: column;
        }
    `,

    InnerDiv: styled.div`
        @media only screen and (max-width: 540px) {
            width: 100% !important;
            padding-bottom: 10px;
        }
    `,
    Link: styled.a`
        :hover {
            cursor: pointer;
        }
    `,
};

export default ContatoLancamentoFormStyled;
