import { GenericService } from '../Generic.service';

export default class AdminHeaderService extends GenericService {
    readonly URI: string = '/v1/header';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarHeader(logado: 'LOGADO' | 'DESLOGADO' | 'TODOS') {
        return this.get('public', { tipo: logado });
    }

    public inserirHeader(data: any) {
        return this.post('', data);
    }

    public editarHeader(data: any) {
        return this.put('', data);
    }

    public excluirHeader(headerId: number) {
        return this.deleteByParams('', { headerId });
    }
}
