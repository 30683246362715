import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import Title from '../../../../components/UI/Title';
import Text from '../../../../components/UI/Texts';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { BaseButton, GradientButton, PrimaryButton } from 'components/UI/Buttons';
import * as pdpActions from '../../../../store/actions/Pdp';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PdpContext } from 'pages/pdp/context/PdpContext';
import { BibliotecaContext } from 'pages/biblioteca/context/BibliotecaContext';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

const HeaderStyle = styled.div``;

const TitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const PageSubtitle = styled(Text)`
    margin-top: 17px;
    line-height: 17px;
    color: ${Colors.LightGrey};
`;
const ComponentSubtitle = styled(Text)`
    margin-top: 31px;
    color: ${Colors.DarkGrey};
`;

interface IPDPHeader {
    title: string;
    subtitle?: string;
    pageHeader?: boolean;
    editIcon?: boolean;
}
const PDPHeader: FunctionComponent<IPDPHeader> = (props: IPDPHeader) => {
    const dispatch = useDispatch();

    const { operacaoAlteracao, setOperacaoAlteracao } = useContext(PdpContext.Context);

    const inicializarAlteracaoPdp = () => {
        dispatch(pdpActions.iniciarOperacaoAlteracao());
        setOperacaoAlteracao(true);
    };

    const cancelarEdicaoPdp = () => {
        dispatch(pdpActions.cancelarOperacaoAlteracao());
        setOperacaoAlteracao(false);
    };

    return (
        <HeaderStyle>
            <TitleContainer>
                <Title size="large">{props.title}</Title>
                {props.editIcon && !operacaoAlteracao && (
                    <GradientButton
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('painel_pdp', 'alteracao_btn');
                            inicializarAlteracaoPdp();
                        }}
                        style={{ marginLeft: '25px' }}
                    >
                        Editar
                    </GradientButton>
                )}

                {props.editIcon && operacaoAlteracao && (
                    <GradientButton
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('painel_pdp', 'cancelar_btn');
                            cancelarEdicaoPdp();
                        }}
                        style={{ marginLeft: '25px' }}
                    >
                        Cancelar Edição
                    </GradientButton>
                )}
            </TitleContainer>
            {props.pageHeader && props.subtitle && <PageSubtitle>{props.subtitle}</PageSubtitle>}
            {!props.pageHeader && props.subtitle && <ComponentSubtitle>{props.subtitle}</ComponentSubtitle>}
        </HeaderStyle>
    );
};

export default PDPHeader;
