import ProfileData from '../../models/ProfileData';
import LocalidadeDTO from '../../models/perfil/LocalidadeDTO';
import { ProfileService } from '../../core/http/service/Profile.service';
import ParametrosCadastroPerfilDTO from '../../models/perfil/ParametrosCadastroPerfilDTO';
import PerfilUsuario from '../../models/perfil/PerfilUsuario';

export const PROFILE_DATA: string = 'PROFILE_DATA';
export const SAVE_PROFILE: string = 'SAVE_PROFILE';
export const USER_PROFILE: string = 'USER_PROFILE';
export const GET_CIDADE_POR_UF: string = 'GET_CIDADE_POR_UF';
export const CLEAR_PROFILE_DATA: string = 'CLEAR_PROFILE_DATA';
export const GET_PARAMETROS_CADASTRO: string = 'GET_PARAMETROS_CADASTRO';
export const SET_IS_LOGGEDIN: string = 'SET_IS_LOGGEDIN';

const sendToAnalytics = (where: string = '', error: any = null) => {
    console.error(where, error);
};

export const fetchParametrosCadastro = () => {
    const profileService = new ProfileService();

    return async (dispatch: any) => {
        try {
            const response: any = await profileService.consultarParametrosCadastro();
            const params: ParametrosCadastroPerfilDTO = new ParametrosCadastroPerfilDTO().fromJSON(response.data);

            dispatch({
                type: GET_PARAMETROS_CADASTRO,
                parametrosCadastro: params,
            });
        } catch (error) {
            sendToAnalytics('fetchParametrosCadastro', error);
            throw error;
        }
    };
};

export const fetchCidadesPorEstado = (uf: string) => {
    const profileService = new ProfileService();

    return async (dispatch: any) => {
        try {
            const response: any = await profileService.consultarCidadesPorUF(uf);
            const cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));

            dispatch({
                type: GET_CIDADE_POR_UF,
                cidadesPorUf: cidades,
            });
        } catch (error) {
            sendToAnalytics('fetchCidadesPorEstado', error);
            throw error;
        }
    };
};

export const storeProfileData = (data: ProfileData | null) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: PROFILE_DATA,
                profileData: data,
            });
        } catch (error) {
            sendToAnalytics('storeProfileData', error);
            throw error;
        }
    };
};

export const saveProfile = (data: ProfileData) => {
    return async (dispatch: any) => {
        try {
            const profileService = new ProfileService();
            await profileService.saveProfile(data);
        } catch (error) {
            sendToAnalytics('saveProfile', error);
            throw error;
        }
    };
};

export const findUserProfileByUsername = (username: string) => {
    const profileService = new ProfileService();

    return async (dispatch: any) => {
        try {
            const response: any = await profileService.findByUsername(username);
            let user: PerfilUsuario | null = null;

            if (response.data) {
                user = new PerfilUsuario().fromJSON(response.data);
            }

            dispatch({
                type: USER_PROFILE,
                userProfile: user,
            });
        } catch (error) {
            sendToAnalytics('findUserProfile', error);
            throw error;
        }
    };
};

export const setUserProfile = (profile: PerfilUsuario) => {
    return async (dispatch: any) => {
        dispatch({
            type: USER_PROFILE,
            userProfile: profile,
        });
    };
};

export const setIsLogaded = (isLoggedin: Boolean) => {
    return async (dispatch: any) => {
        dispatch({
            type: SET_IS_LOGGEDIN,
            isLoggedin: isLoggedin,
        });
    };
};

export const clearStore = () => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CLEAR_PROFILE_DATA,
                profileData: undefined,
            });
        } catch (error) {
            sendToAnalytics('clearStore', error);
            throw error;
        }
    };
};
