import React from 'react';
import Styled from './CoursesHome.styled';
import imagem03 from '../../../assets/imagem_06.jpg';
import imagem04 from '../../../assets/imagem_04.jpeg';
import { ButtonHomeV2 } from '../button/ButtonHomeV2.styled';
import { useHistory } from 'react-router-dom';

const CoursesHome = () => {
    const history = useHistory();

    const goToUrl = (link: string) => {
        history.push(link);
    };

    return (
        <Styled.Container>
            <Styled.Body>
                <Styled.Content img={imagem03}>
                    <Styled.TagCourse className="tag">CURSOS</Styled.TagCourse>
                    <Styled.TitleCard>{`Benefícios do Desenvolvimento Socioemocional para a Saúde Mental`.toLocaleUpperCase()}</Styled.TitleCard>
                    <Styled.DescriptionCard className="description_course">
                        {' '}
                        A saúde mental pode impactar diretamente na vida escolar dos estudantes. Veja quais podem ser esses impactos e o papel do
                        desenvolvimento de competências socioemocionais para a saúde mental dos estudantes.
                    </Styled.DescriptionCard>
                    <ButtonHomeV2
                        onClick={() =>
                            goToUrl('/recursos/cursos/beneficios-do-desenvolvimento-socioemocional-para-a-saude-mental-dos-estudantes')
                        }
                        className="button_course"
                        style={{ width: 100 }}
                    >
                        Ver o curso
                    </ButtonHomeV2>
                </Styled.Content>
                <Styled.Content img={imagem04}>
                    <Styled.TagCourse className="tag">CURSOS</Styled.TagCourse>
                    <Styled.TitleCard>{`Trilha Alfabetização Integral em apoio ao Compromisso Nacional Criança Alfabetizada`.toLocaleUpperCase()}</Styled.TitleCard>
                    <Styled.DescriptionCard className="description_course">
                        Mergulhe nessa trilha de alfabetização e explore diferentes temas relacionados, como letramentos e multiletramentos,
                        avaliação e monitoramento, cultura digital na alfabetização, entre outros.
                    </Styled.DescriptionCard>
                    <ButtonHomeV2
                        onClick={() => goToUrl('/recursos/trilhas/trilha-de-alfabetizacao-aprofundada')}
                        className="button_course"
                        style={{ width: 100 }}
                    >
                        Ver o curso
                    </ButtonHomeV2>
                </Styled.Content>
            </Styled.Body>
            <Styled.Footer>
                <span>
                Quer conhecer outros cursos? {' '}
                    <a href="" onClick={() => history.push('/cursos-livres')}>
                        Clique aqui!
                    </a>{' '}
                   
                </span>
            </Styled.Footer>
        </Styled.Container>
    );
};

export default CoursesHome;
