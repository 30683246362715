import { DangerButton, NavyButton, NavyButtonInvert, PrimaryButton } from 'components/UI/Buttons';
import styled from 'styled-components';

const AdminHeaderStyled = {
    Container: styled.div`
        background-color: white;
        border-radius: 10px;
        padding: 15px 35px;
        font-family: 'Barlow';
        font-style: normal;
        margin-left: 120px;
        width: calc(100% - 120px);
    `,

    Title: styled.p`
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
        margin-bottom: 10px;
    `,

    Subtitle: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 159.02%;
        color: #003c78;

        strong {
            font-weight: 700;
        }
    `,

    Division: styled.div`
        height: 1px;
        background-color: #cbd2e0;
        width: 380px;
        max-width: 100%;
        margin: 25px 0;
    `,

    ContentGrid: styled.div`
        display: grid;
        grid-template-columns: 380px 1fr 380px;
        width: 100%;
        max-width: 1050px;
    `,

    DivisionPadding: styled.div`
        //border: 1px blue solid;
        .line {
            height: 80%;
            width: 1px;
            background-color: #cbd2e0;
            margin: 0 auto;
        }
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        //border: 1px blue solid;
        width: 100%;
    `,

    Item: styled.div<{ isSub?: boolean }>`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //border: 1px blue solid;
        width: ${props => (props.isSub ? 'calc(100% - 35px)' : '100%')};
        max-width: 365px;
        border: 2px solid #cbd2e0;
        border-radius: 8px;
        padding: 20px;
        font-size: 18px;
        margin: ${props => (props.isSub ? '15px 0 20px 15px' : '15px 0')};

        .label {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            color: #111111;
        }

        .icon {
            color: #858585;
            font-size: 1.15em;
            cursor: pointer;
        }
    `,

    RoundButton: styled(NavyButtonInvert)`
        width: 45px;
        height: 45x;
        border-radius: 50%;
    `,

    SaveButton: styled(PrimaryButton)`
        width: fit-content;
        padding: 0 14px;
    `,

    Modal: {
        Title: styled.p`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 38px;
            color: #003c78;
        `,

        CloseButton: styled.div`
            width: fit-content;
            cursor: pointer;
            font-size: 1.5em;
        `,

        Container: styled.div`
            padding: 20px;
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
        `,

        Label: styled.span`
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #2d3648;
            margin-bottom: 10px;
        `,

        Count: styled.span`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            color: #acacac;
            margin-top: 7px;
        `,

        Sublabel: styled.span`
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2d3648;

            margin-left: 10px;
        `,

        Autoscroll: styled.div`
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-left: 5px;

            &::-webkit-scrollbar {
                width: 12px;
                box-sizing: content-box;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d8d8d8; /* color of the scroll thumb */
                border-radius: 20px; /* roundness of the scroll thumb */
                margin: 10px;
                border: 4px solid white;
                box-sizing: content-box;
            }

            &::-webkit-scrollbar-track {
                background: transparent; /* color of the tracking area */
            }
        `,

        SubItemContainer: styled.div`
            margin: 25px 0;
        `,

        AddSubButton: styled(NavyButtonInvert)`
            margin-top: 10px;
            font-size: 0.9em;
            font-weight: 500;

            span {
                margin-left: 10px;
            }
        `,

        SaveButton: styled(PrimaryButton)`
            font-weight: 600;
            width: fit-content;
            padding: 0 20px;
        `,

        DeleteButton: styled(DangerButton)`
            width: 40px;
            height: 40px;
            color: white;
            background: #e25541;
        `,

        DeleteSub: styled.span`
            font-size: 0.9em;
            color: #e25541;
            margin-top: 7px;
            cursor: pointer;

            :hover {
                text-decoration: underline;
            }
        `,
    },

    TopContainer: styled.div``,
};

export default AdminHeaderStyled;
