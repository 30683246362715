import React from 'react';
import { Title, Paragraph, ContentContainerInner } from './SocioemocionaisEducadores.styled';

export interface IDescriptionEducationalSolutions {}

const DescriptionEducationalSolutions = ({}: IDescriptionEducationalSolutions) => {
    return (
        <ContentContainerInner>
            <Title>{`O que são competências socioemocionais?`}</Title>
            <Paragraph>
                Nosso objetivo é estar ao lado de professores que querem se <b>conectar com o contexto da educação do século 21</b> por meio de
                experiências de desenvolvimento inovadoras que o apoiem a atingir seu potencial de forma integral. Afinal, assim como o estudante
                precisa ser fortalecido em todas as suas dimensões para aprender, viver, conviver e atuar no mundo contemporâneo, também os
                educadores devem receber o mesmo olhar.
            </Paragraph>
            <Paragraph style={{ margin: '0' }}>
                {`O foco dessa jornada é nas competências mais relevantes para a atuação de professores, articulando características técnicas e socioemocionais que se fazem presentes no cotidiano da sala de aula. Os demais profissionais de educação também podem se beneficiar das experiências da jornada, seja porque podem ser multiplicadores dessa perspectiva em suas unidades escolares ou redes de ensino, seja porque seguem desempenhando papeis com características próximas às de um docente.`}
            </Paragraph>
            <Paragraph style={{ marginTop: '0' }}>{`Conta com as funcionalidades:`}</Paragraph>

            <ul>
                <li style={{ margin: '20px' }}>
                    <b>Instrumento autoavaliativo de competências de professores </b>
                </li>
                <li style={{ margin: '20px' }}>
                    <b>Relatório personalizado e individual</b>
                </li>
                <li style={{ margin: '20px' }}>
                    <b>Orientações para o plano de desenvolvimento pessoal (PDP) </b>
                </li>
                <li style={{ margin: '20px' }}>
                    <b>Trilhas formativas em cada macrocompetência para a profissão docente </b>
                </li>
                <li style={{ margin: '20px' }}>
                    <b>Diário de bordo para registrar suas experiências </b>
                </li>
            </ul>
        </ContentContainerInner>
    );
};

export default DescriptionEducationalSolutions;
