import React, { useEffect } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import ImagemGestaoNota10 from '../../assets/gestaoNota10/logo.jpg';

import imagem_01 from '../../assets/gestaoNota10/material/imagem_01.jpg';

import imagem_inpratice_01 from '../../assets/gestaoNota10/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/gestaoNota10/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/gestaoNota10/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/gestaoNota10/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/gestaoNota10/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/gestaoNota10/inPratice/img_06.jpg';
import imagem_inpratice_07 from '../../assets/gestaoNota10/inPratice/img_07.jpg';
import imagem_inpratice_08 from '../../assets/gestaoNota10/inPratice/img_08.jpg';
import imagem_inpratice_09 from '../../assets/gestaoNota10/inPratice/img_09.jpg';
import imagem_inpratice_10 from '../../assets/gestaoNota10/inPratice/img_10.jpg';
import imagem_inpratice_11 from '../../assets/gestaoNota10/inPratice/img_11.jpg';
import imagem_inpratice_12 from '../../assets/gestaoNota10/inPratice/img_12.jpg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';

const GestaoNota10 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Gestão Nota 10',
                        descricao: `
                        Desde 2005, o Instituto Ayrton Senna leva para as redes de ensino, como política pública, o programa Gestão Nota 10, voltado para o fortalecimento das lideranças educacionais e escolares.
                        A partir de 2024, a solução recebe uma atualização para adequação e alinhamento à Base Nacional Comum Curricular – BNCC, mantendo a mesma essência.
                        
                        `,
                        objetivo: `
                        O objetivo principal do Gestão Nota 10 é promover o desenvolvimento de uma política educacional democrática e inclusiva a partir da construção de uma rede integrada de escolas autônomas, que garantam o ingresso, 
                        a permanência e o sucesso dos alunos, geridas por diretores tecnicamente competentes e capacitados a garantirem o alcance das metas de qualidade da aprendizagem dos alunos e melhoria dos indicadores educacionais.
                         `,
                        publicoAlvo: '1º ao 9º do Ensino Fundamental.',
                        avaliacao: 'Diagnóstico geral da rede de ensino.',
                        formacao: `
                                Encontros formativos para gestores escolares (carga horária – 36 horas).
                        `,
                        acompanhamento: `
                          Dados quantitativos e qualitativos, ancorados em indicadores e
                        metas previamente definidas.
                        `,
                        atores: `
                         Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                        Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                        (gestão da política educacional).
                        `,
                        elementos: `
                           (I) Adoção como política pública educacional, uma ferramenta
                            estruturadora da Secretaria de Educação e da unidade escolar; (II) Recursos e/ou
                            Infraestrutura, se utiliza da estrutura já existente na Secretaria de Educação e viabiliza
                            a articulação dos recursos já disponíveis para a educação; (III) Formação dos
                            profissionais; (IV) Forma de acompanhamento e avaliação, quatro áreas estratégicas
                            da gestão: da aprendizagem, do ensino, da rotina escolar e da política educacional.
                        
                        `,
                        img: ImagemGestaoNota10,
                        heightImg: '280px',
                    }}
                />

                <InPractice
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        // {
                        //     type: TypeCard.IMAGEM,
                        //     url: imagem_inpratice_05,
                        //      credits: {
                        //          credits: 'Aurelio Alves',
                        //          local: 'Sobral',
                        //          ano: '2024',
                        //      },
                        // },
                        // {
                        //     type: TypeCard.IMAGEM,
                        //     url: imagem_inpratice_06,
                        //      credits: {
                        //          credits: 'Aurelio Alves',
                        //          local: 'Sobral',
                        //          ano: '2024',
                        //      },
                        // },
                        // {
                        //     type: TypeCard.IMAGEM,
                        //     url: imagem_inpratice_07,
                        //      credits: {
                        //          credits: 'Aurelio Alves',
                        //          local: 'Sobral',
                        //          ano: '2024',
                        //      },
                        // },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_09,
                            credits: {
                                credits: 'Miguel Castaño',
                                local: 'Ribeirão Pires',
                                ano: '2022',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_10,
                            credits: {
                                credits: 'Miguel Castaño',
                                local: 'Ribeirão Pires',
                                ano: '2022',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_08,
                            credits: {
                                credits: 'Diego Villamarin',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_11,
                            credits: {
                                credits: 'Diego Villamarin',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_12,
                            credits: {
                                credits: 'Diego Villamarin',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                    ]}
                    description={`
                Quer conhecer um pouco mais sobre como o Gestão Nota 10 acontece na prática? <br/><br/>
                Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                Sua rede pode ser a próxima a implementar a solução Gestão Nota 10 e ter as imagens compartilhadas aqui na humane. Que tal?<br/><br/>

                    `}
                />
                <SupportMaterial
                    isBorderBlue
                    description={
                        <>
                            A solução disponibiliza para as redes parceiras que implementam o Gestão Nota 10: <br />
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>
                                    <strong>Documento sobre a prática gestora no processo educacional</strong> para o fortalecimento de
                                    lideranças nas escolas e nas secretarias de educação;
                                </li>
                                <li>
                                    {' '}
                                    <strong>Sistemática de monitoramento e acompanhamento;</strong>
                                </li>
                                <li>
                                  <strong>Documentos para formação continuada</strong> nos temas: Educação Integral, Desenvolvimento da liderança na escola, O
                                    gestor e a política pública educacional com base em evidências, O diretor de escola como gestor de
                                    transformações e o olhar nas intervenções: o diretor e os acidentes de percurso.
                                </li>
                            </ul>
                        </>
                    }
                    img={imagem_01}
                />

                <FormSolucoes isBlue tipoSolucao={TipoSolucoesEnum.GESTAO_NOTA_10} preDefinition />
                <ContactContainer isLight />
            </GlobalStyled.Container>
        </div>
    );
};

export default GestaoNota10;
