import React from 'react';
import Styled from './Dialog.styled';
import Modal from 'react-bootstrap/Modal';

import loadingIcon from '../../../../../images/loading.svg';
import { OutlineButton } from 'components/UI/Buttons';

interface IDialog {
    title: string;
    textButton: string;
    buttonCallback?: () => void;
    icon: string;
    show: boolean;
    isInfo?: boolean;
    onHide?: () => void;
    loading?: boolean;
    cancelable?: boolean;
}

const Dialog = ({ show, title, textButton, buttonCallback, onHide, icon, isInfo, loading, cancelable }: IDialog) => {
    const onclick = () => {
        if (buttonCallback) buttonCallback();
    };

    return (
        <Modal
            show={show}
            centered
            size={'sm'}
            onHide={() => {
                if (onHide) onHide();
            }}
            onEscapeKeyDown={() => {
                if (onHide) onHide();
            }}
        >
            <Modal.Body>
                <Styled.Container>
                    <Styled.Icon src={icon} />
                    <Styled.Title>{title}</Styled.Title>

                    <div>
                        <Styled.Loading src={loadingIcon} visible={loading ?? false} />
                    </div>

                    {isInfo ? (
                        <Styled.ButtonInfo onClick={onclick}>{textButton}</Styled.ButtonInfo>
                    ) : (
                        <Styled.Row>
                            {cancelable && <OutlineButton onClick={onHide}>Cancelar</OutlineButton>}
                            <Styled.ButtonAlert disabled={loading} onClick={onclick}>
                                {textButton}
                            </Styled.ButtonAlert>
                        </Styled.Row>
                    )}
                </Styled.Container>
            </Modal.Body>
        </Modal>
    );
};

export default Dialog;
