import styled, { keyframes } from 'styled-components';

import Colors from '../../../../enums/Colors';

export const ProgressBarStyle = styled.div`
    height: 8px;
    position: fixed;
    width: 100%;
    background-color: ${Colors.Grey8};
`;

const grow = (y: number, x: number) => keyframes`
  from {
    width:${x}%;
  }
  to {
    width: ${y}%;
  }
`;

interface IProgress {
    progress: number;
    prevPercentyal: number;
}
export const Progress = styled.div<IProgress>`
    height: 100%;
    width: ${props => props.progress}%;
    background-color: ${Colors.SoftBlue};
    animation: ${props => grow(props.progress, props.prevPercentyal)} 0.6s linear;
`;
