import { OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const AdminBiblioNewStyled = {
    Container: styled.div``,

    TopImageContainer: styled.div`
        border: 1px dashed #b5b5b5;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,

    SideImageContainer: styled.div`
        border: 1px dashed #b5b5b5;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 245px;
    `,

    TitleSoftBlue: styled.p`
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        color: #00a8e9;
    `,

    DescText: styled.p`
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #747474;
        max-width: 140px;
    `,

    Padding: styled.div`
        height: 110px;
    `,

    Row: styled.div`
        display: flex;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    FormContainer: styled.div`
        flex-grow: 1;
        padding: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 5px;
        }
    `,

    FormContainerFixedWidth: styled.div`
        flex-grow: 1;
        padding: 20px;
        max-width: 780px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 5px;
        }
    `,

    ImageContainer: styled.div`
        width: 400px;

        display: flex;
        justify-content: center;
        padding-top: 20px;
        height: fit-content;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,

    Label: styled.p`
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
    `,

    PlusButton: styled(OutlineButton)`
        width: 30px;
        height: 30px;
        border-radius: 5px;
        font-size: 1.5rem;
        margin-top: 7px;
    `,

    Input: styled(Form.Control)`
        width: 100%;
        margin-top: 5px;
    `,

    RowSol: styled.div`
        display: flex;
        justify-content: space-between;

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #747474;
        }
    `,

    CkeckContainer: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    `,

    TextCheckBox: styled.span`
        margin-right: 50px;
        margin-left: 5px;
    `,

    RowGrid: styled.div`
        padding: 0 20px;
        display: grid;
        grid-template-columns: 1fr 165px 245px 120px 300px;
        column-gap: 15px;
        margin: 20px 0;
        width: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            border: 1px #ddd solid;
            display: flex;
            flex-direction: column;
            padding: 20px 7px;
            border-radius: 10px;
        }
    `,

    RowGridLivro: styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        margin-top: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
            padding: 20px 7px;
        }
    `,

    ColumsGrid: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 10px;
        }
    `,

    BottomRight: styled.div`
        text-align: right;
        width: 100%;
    `,

    Modal: styled(Modal)``,

    Center: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,

    IconSave: styled.img`
        height: 2.5rem;
        width: auto;
    `,

    TextModal: styled.p`
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #2e2e2e;
        max-width: 325px;
    `,

    ContainerBottom: styled.div`
        text-align: center;
        display: flex;
        justify-content: center;
    `,

    Hidden: styled.input`
        display: none;
    `,

    FileHolder: styled.div`
        padding: 7px 15px;
        width: 300px;
        border: 1px solid #b5b5b5;
        border-radius: 5px;

        display: flex;
        justify-content: space-between;

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #1e4e7e;
        }
    `,

    FileHolderIcon: styled.img`
        cursor: pointer;
    `,

    CheckIcon: styled.img<{ show: boolean }>`
        transform: translateX(-125%);
        opacity: ${props => (props.show ? '1' : '0')};
    `,

    TrashIcon: styled.img`
        cursor: pointer;
        transform: translateX(-50%);
    `,
    SectionTitle: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,
    BottomButton: styled.div`
        padding: 10px 0px;
    `,

    CountLetter: styled.p<{ danger: boolean }>`
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: ${props => (props.danger ? 'red' : '#747474')};
        text-align: right;
    `,
};

export default AdminBiblioNewStyled;
