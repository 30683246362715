import styled from 'styled-components';
import { MobileMaxWidth } from 'constants/Dimensions';

const GradeAvarageStyled = {

    GradeAvarageContainer: styled.div``,

    GridGradeAvarage: styled.div`
        display: grid;
        grid-template-columns: 260px 200px 1fr 120px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
            grid-template-columns: 120px 200fr 120px 120px;
        }
    `,
};

export default GradeAvarageStyled;
