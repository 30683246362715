import { DateHelp } from 'core/util/DateHelp';
import { StringHelp } from 'core/util/StringHelp';
import Colors from 'enums/Colors';
import HomepageResult from 'models/homepage/HomepageResult';
import { CardTag } from 'pages/intermediarias/cursoLivre/components/CursoLivreCardTag';
import React from 'react';

import Styled from './ResultadoPesquisa.styled';
import { bucketS3, caminhoAnexosRecursosCostumizados } from 'constants/EnvironmentData';

const DEFAUTL_COVER =
    'https://plataformaestruturante.s3.us-east-2.amazonaws.com/biblioteca/capas/imagem%204.1.jpeg_2022-03-11-1647008370311.jpeg';

export interface IResultadoItemPesquisa {
    data: HomepageResult;
}

const ResultadoItemPesquisa = ({ data }: IResultadoItemPesquisa) => {
    const getRedirect = (): string | null => {
        if (data.categoria == 'CURSOLIVRE') return '/cursos-livres';

        if (data.categoria == 'DYNAMIC_PAGE') return data.link;

        if (data.link) return data.link;
        else if (data.slug) {
            return '/biblioteca/item/' + data.slug;
        }

        return null;
    };

    const getUrlImage = (): string => {
        if (data.urlImage.includes('http')) {
            return data.urlImage;
        } else if (data.categoria == 'BIBLIOTECA') {
            return 'https://plataformaestruturante.s3.us-east-2.amazonaws.com/biblioteca/capas/' + data.urlImage;
        } else if (data.link?.match('^recursos+[/]+[\\w-]+[/]+[\\w-]+$')) {
          return bucketS3() + caminhoAnexosRecursosCostumizados() + data.urlImage;
        }

        return DEFAUTL_COVER;
    };

    const exibirInscricoesEncerradas = (dataControle: Date | null): Boolean => {
        return dataControle !== null && DateHelp.afterToday(dataControle) && !DateHelp.isLessEqualsToday(dataControle);
    };

    const exibirCursoEncerrado = (dataControle: Date | null): Boolean => {
        return dataControle !== null && DateHelp.afterToday(dataControle) && !DateHelp.isLessEqualsToday(dataControle);
    };

    return (
        <Styled.ContainerItem>
            <Styled.ImageItem src={getUrlImage()} />
            <Styled.ContainerText>
                {getRedirect() ? (
                    <Styled.TitleContainer>
                        <a href={getRedirect() ?? ''}>
                            <Styled.TitleItem>{data.titulo}</Styled.TitleItem>
                        </a>

                        {data.cursoLivreEmProducao === true ? (
                            <CardTag style={{ position: 'unset' }}>Em breve nova turma</CardTag>
                        ) : (
                            data.prazoInscricaoCursoLivre &&
                            DateHelp.isLessEqualsToday(data.prazoInscricaoCursoLivre) && (
                                <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue} style={{ position: 'unset' }}>
                                    Inscrições até {DateHelp.formatDateWithoutYear(data.prazoInscricaoCursoLivre)}
                                </CardTag>
                            )
                        )}

                        {exibirInscricoesEncerradas(data.prazoInscricaoCursoLivre) && !exibirCursoEncerrado(data.dataExpiracaoCursoLivre) && (
                            <>
                                <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue} style={{ position: 'unset' }}>
                                    Inscrições Encerradas
                                </CardTag>
                            </>
                        )}

                        {exibirCursoEncerrado(data.dataExpiracaoCursoLivre) && (
                            <>
                                <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue} style={{ position: 'unset' }}>
                                    Curso Encerrado
                                </CardTag>
                            </>
                        )}
                    </Styled.TitleContainer>
                ) : (
                    <Styled.TitleItem>{data.titulo}</Styled.TitleItem>
                )}

                <Styled.TextItem>{StringHelp.removeTags(data.descricao ?? '')}</Styled.TextItem>
            </Styled.ContainerText>
        </Styled.ContainerItem>
    );
};

export default ResultadoItemPesquisa;
