import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico from './PerfilDTOGenerico';

class AreaInteresseDTO extends PerfilDTOGenerico {
    constructor() {
        super();
    }
}

export default AreaInteresseDTO;
