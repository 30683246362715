import React, { useState, useEffect, useContext } from 'react';
import Styled from './AdminUsers.styled';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { transform } from 'lodash';

interface IAdminUsersPagination {
    page: number;
    totalPages: number;

    onSelectPage: (page: number) => void;
}

const AdminUsersPagination = ({ page, totalPages, onSelectPage }: IAdminUsersPagination) => {
    const prevPage = () => {
        onSelectPage(page - 1);
    };

    const nextPage = () => {
        onSelectPage(page + 1);
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        const startPage = Math.max(1, page - 4); // Começa 4 páginas atrás da atual
        const endPage = Math.min(totalPages, startPage + 9); // Limita a 10 páginas

        // Adiciona o botão da primeira página se necessário
        if (startPage > 1) {
            pageButtons.push(
                <Styled.ButtonPagination key={1} onClick={() => onSelectPage(1)}>
                    1
                </Styled.ButtonPagination>
            );
            if (startPage > 2) {
                pageButtons.push(<span key="ellipsis-start">...</span>);
            }
        }

        // Adiciona os botões das páginas
        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <Styled.ButtonPagination key={i} active={i === page} onClick={() => onSelectPage(i)}>
                    {i}
                </Styled.ButtonPagination>
            );
        }

        // Adiciona o botão da última página se necessário
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageButtons.push(<span key="ellipsis-end">...</span>);
            }
            pageButtons.push(
                <Styled.ButtonPagination key={totalPages} onClick={() => onSelectPage(totalPages)}>
                    {totalPages}
                </Styled.ButtonPagination>
            );
        }

        return pageButtons;
    };

    return (
        <Styled.ContanerPagination>
            {page > 1 && (
                <Styled.ButtonNextPagination onClick={prevPage}>
                    <MdOutlineNavigateNext style={{ transform: 'rotate(-180deg)' }} />
                </Styled.ButtonNextPagination>
            )}

            {renderPageButtons()}

            {page < totalPages && (
                <Styled.ButtonNextPagination onClick={nextPage}>
                    <MdOutlineNavigateNext />
                </Styled.ButtonNextPagination>
            )}
        </Styled.ContanerPagination>
    );
};

export default AdminUsersPagination;
