import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useContext, useEffect, useRef } from 'react';
import Styled from './CompetenciasSocioemocionais.styled';
import BannerCSE from './components/banner/BannerCSE';
import SecaoFrame from './components/SecaoFrame/SecaoFrame';
import SecaoNewSimplesWithImage from './components/SecaoNewSimplesWithImage/SecaoNewSimplesWithImage';
import SuporteContainer from 'pages/home/components/FAQ/SuporteContainer';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import SecaoJornadasCompetenciasSocioemocionais from './components/SecaoJornadasCompetenciasSocioemocionais/SecaoJornadasCompetenciasSocioemocionais';
import ImgCSE from './assets/img_cse.svg';
import ImgCSP from './assets/img_csp.svg';
import SecaoLinks from './components/SecaoLinks/SecaoLinks';
import Outros from '../criatividadeDetail/versions/components/sections/Outros';
import OutrosConteudos from './components/OutrosConteudos/OutrosConteudos';
import { PaginasIntermediariasEnum } from 'pages/admin/components/relatedContent/PaginasIntermediariasEnum';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { MobileMaxWidth } from 'constants/Dimensions';
import { CHAVE_REDIRECT_JCS, adicionarRedirecionamentoPosLogin, limparRedirecionamentosPosLogin } from 'core/util/StorageRedirectRulesHelper';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

const CompetenciasSocioemocionais = () => {
    const myDivRef = useRef<HTMLDivElement>(null);

    const { isSearchView } = useContext(PesquisaContext.Context);

    const IsMobile = () => {
        return IsCustomMaxWidth(MobileMaxWidth);
    };

    const goToDiv = () => {
        if (myDivRef.current) {
            const yOffset = -70;
            const y = myDivRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        limparRedirecionamentosPosLogin();
        adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_JCS, CHAVE_REDIRECT_JCS);
    }, []);

    return (
        <>
            <DynamicHeader />

            {isSearchView && <BarraPesquisa />}

            <Styled.Container>
                <BannerCSE isMobile={IsMobile()} onClickSaibaMais={goToDiv} />
                <SecaoFrame ref={myDivRef} videoId="ZsxJHs320k4" isMobile={IsMobile()} />
                <SecaoNewSimplesWithImage
                    isMobile={IsMobile()}
                    title="COMPETÊNCIAS SOCIOEMOCIONAIS DOS ESTUDANTES"
                    subTitle={[
                        `
                        Para alcançar o desenvolvimento pleno dos estudantes, é fundamental olhar e agir com intencionalidade para as 
                        competências socioemocionais e pensá-las em conjunto com habilidades cognitivas e com as competências consideradas híbridas, 
                        como é o caso da criatividade e do pensamento crítico.
                
                    `,
                        `Pensando nisso, há décadas pesquisamos essas competências no contexto escolar e criamos instrumentos, soluções e 
                        formações para ampliar sua compreensão e aplicação nas escolas brasileiras.
                    `,
                        `Um curso de 4 horas e com certificação para você compreender o conceito de Competências Socioemocionais e sua relação com a educação Integral e a proposta da BNCC.`,
                    ]}
                    textDestaque={[`Compreenda cada uma das macrocompetências no curso abaixo:`, `Curso Competências Socioemocionais na Escola`]}
                    textDestaquePosIndex={2}
                    imgPosition={2}
                    img={ImgCSE}
                    textButton={'Começar o curso'}
                    link="/recursos/cursos/competencias-socioemocionais-na-escola"
                />
                <SecaoJornadasCompetenciasSocioemocionais isMobile={IsMobile()} />
                <SecaoNewSimplesWithImage
                    title="COMPETÊNCIAS SOCIOEMOCIONAIS DOS PROFESSORES"
                    subTitle={[
                        `
                        Atuando em parceria com os educadores brasileiros ao longo das últimas décadas, também identificamos a 
                        necessidade de mapear e desenvolver competências socioemocionais específicas para os professores. 
                        A partir de experiências de formação e de um mergulho científico na temática, criamos a jornada de desenvolvimento socioemocional de professores. 
                
                    `,
                        `
                        Ela parte de uma matriz de competências preparada pensando no cotidiano do professor brasileiro e os convida a olharem para suas competências e, a partir de um autodiagnostico, 
                        traçar um plano de desenvolvimento. Por fim, os educadores podem realizar um curso de 8 horas com direito a certificado. 
                    `,
                    ]}
                    isMobile={IsMobile()}
                    img={ImgCSP}
                    imgWidth="700px"
                    imgPosition={2}
                    textButton={'Inicie a jornada'}
                    link="/painelSocioemocional"
                    TipoRedirect={TipoRedirecionamento.CADASTRO}
                />
                <SecaoLinks isMobile={IsMobile()} />
                <OutrosConteudos padding="40px 78px" pageKey={PaginasIntermediariasEnum.COMPETENCIAS_SOCIOEMOCIONAIS} />
                <ContactContainer />
            </Styled.Container>
        </>
    );
};

export default CompetenciasSocioemocionais;
