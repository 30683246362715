import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import Loading from 'components/Loading';
import { OutlineButton, OutlineButtonInvert } from 'components/UI/Buttons';
import { BibliotecaContext } from 'pages/biblioteca_v2/context/BibliotecaContext';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BiblioCardItem from '../card-item/BiblioCardItem';
import BibliotecaNoContent from '../no-content/BibliotecaNoContent';
import Styled from './BiblioResultGrid.styled';

export interface IBiblioResultGrid {}

const BiblioResultGrid = ({}: IBiblioResultGrid) => {
    const { resultItens, hasMore, loading } = useContext(BibliotecaContext.Context);
    const history = useHistory();
    const location = useLocation();

    const nextPage = () => {
        let pathname = location.pathname;

        const serachparams = new URLSearchParams(location.search);

        if (!serachparams.get('page')) {
            serachparams.append('page', '2');
        } else {
            const currentPage = parseInt(serachparams.get('page') ?? '1');
            serachparams.set('page', String(currentPage + 1));
        }

        history.push(pathname + '?' + serachparams.toString());
    };

    return (
        <>
            <Styled.Container>
                {resultItens?.length !== 0 &&
                    resultItens?.map(item => {
                        return <BiblioCardItem key={item.id} data={item} />;
                    })}
            </Styled.Container>
            {loading ? (
                <>
                    <Loading />

                    <label style={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                        Aguarde, estamos carregando os conteúdos para você!
                    </label>
                </>
            ) : (
                <>
                    {resultItens?.length == 0 && <BibliotecaNoContent />}

                    <Styled.ShowMoreContainer>
                        {hasMore && (
                            <OutlineButtonInvert
                                onClick={() => {
                                    GTM_Page_Biblioteca('0', 'biblioteca_result', 'carregar_conteudos_btn');
                                    nextPage();
                                }}
                            >
                                Carregar mais
                            </OutlineButtonInvert>
                        )}
                    </Styled.ShowMoreContainer>
                </>
            )}
        </>
    );
};

export default BiblioResultGrid;
