import styled, { keyframes } from 'styled-components';

import { MobileMaxWidth } from 'constants/Dimensions';

export namespace DiaryTextStyle2 {
    export const DiarioTextContainer = styled.div`
        flex: 2;
        min-height: fit-content;

        .ql-container {
            min-height: 200px;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 25px;
        }
    `;

    const fadeIn = keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `;

    export const FooterContainer = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding: 0;
        height: 40px;
        opacity: 0;

        animation: ${fadeIn} ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    `;

    export const Footer = styled.p`
        font-family: Barlow;
        font-style: italic;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.2px;
        color: #7b7c7d;
        vertical-align: middle;
    `;

    export const AlertContainer = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;
        height: 100%;
        background: rgba(0, 60, 120, 0.85);

        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    `;

    export const AlertContent = styled.div`
        width: 90%;
        height: fit-content;
        padding: 20px;
        margin: auto auto;
        background-color: white;
        margin-top: 50%;
        text-align: center;

        @media only screen and (min-width: ${MobileMaxWidth}px) {
            margin: 0 auto;
            margin-top: 200px;
            max-width: 430px;
        }
    `;

    export const AlertTitle = styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: #003c78;
        margin-bottom: 10px;
    `;

    export const AlertText = styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #003c78;
    `;

    export const Row = styled.div`
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    `;
}
