import React, { useState, useEffect } from 'react';
import SecaoItemCustomizado from 'models/generic-components/SecaoItemCustomizado';
import Styled from './SecaoNumeroDestaqueStyled';
import CardNumeroDestaque from './components/CardNumeroDestaque';

interface ISecaoNumeroDestaque {
    dados: SecaoItemCustomizado;
}

const SecaoNumeroDestaque = ({ dados }: ISecaoNumeroDestaque) => {
    const [isValid, setIsValid] = useState<boolean>(false);

    const validItens = () => {
        let countItensPublic = 0;
        const listItens = dados.itens;

        for (const item of listItens) {
            if (!item.ocultarAnexo || !item.ocultarDetalhamento || !item.ocultarInfoDestaque) {
                countItensPublic++;
            }
        }

        if (countItensPublic >= 2) {
            setIsValid(true);
        }
    };

    useEffect(() => {
        if (dados.itens.length >= 2) {
            validItens();
        }
    }, [dados]);

    return (
        <>
            {isValid && (
                <Styled.Container bgColor={dados.corSecao}>
                    {dados.itens.map((item, index) => (
                        <CardNumeroDestaque dados={item} key={index} />
                    ))}
                </Styled.Container>
            )}
        </>
    );
};

export default SecaoNumeroDestaque;
