import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ModalSizeImage from './ModalSizeImage';
import { Container } from './ImageSelect.styled';
import ProfileData from '../../../../../models/ProfileData';
import { useAppSelector } from '../../../../../hooks/LocalReduxThunk';
import { storeProfileData } from '../../../../../store/actions/Profile';
import TipoImagem from '../../../../../models/imagemPerfil/TipoImagemEnum';
import ImagemPerfil from '../../../../../models/imagemPerfil/ImagemPerfil';
import { AddImageButton, Divider, Gloom, Icon, Label, ModalContainer, Row } from './ImageSelectMobile.styled';

import photoIcon from '../../../../../images/profile/photo-icon.svg';
import cameraIcon from '../../../../../images/profile/camera-icon.svg';

interface IImageSelectMobile {
    onSelected: any;
}

const ImageSelectMobile = ({ onSelected }: IImageSelectMobile) => {
    const [showModal, setShowModal] = useState(false);
    const [showImageSizedModal, setShowImageSizedModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);

    const addImage = () => {
        const elInput = document.getElementById('file-input');

        if (elInput) {
            elInput.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.files) {
                    const file = target.files[0];

                    if (!file) return;

                    const sizeInMB = file.size / 1024 / 1024;

                    if (sizeInMB > 2.0) {
                        setShowImageSizedModal(true);
                    } else {
                        const reader = new FileReader();
                        reader.onloadend = (ev: any) => {
                            const base64 = ev.target.result;
                            setBase64ToImage(base64);
                            onSelected();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            };
        }

        elInput?.click();
    };

    const setBase64ToImage = (encoded: string) => {
        if (profileData) {
            profileData.imagemPerfil = {
                tipoImagem: TipoImagem.Imagem,
                imageBase64: encoded,
            } as ImagemPerfil;

            dispatch(storeProfileData(profileData));
        }
    };

    return (
        <>
            <input type="file" id="file-input" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} />

            <Container>
                {showModal && !showImageSizedModal && (
                    <>
                        <Gloom
                            onClick={() => {
                                setShowModal(false);
                            }}
                        />
                        <ModalContainer>
                            <Row
                                onClick={() => {
                                    history.push('/profile/identificacaoImagemCameraCaptura');
                                }}
                            >
                                <Icon src={cameraIcon} />
                                <Label>Câmera</Label>
                            </Row>
                            <Divider />
                            <Row onClick={addImage}>
                                <Icon src={photoIcon} />
                                <Label>Biblioteca de fotos</Label>
                            </Row>
                        </ModalContainer>
                    </>
                )}
                <AddImageButton
                    disabled={false}
                    onClick={() => {
                        setShowModal(true);
                    }}
                    style={{ marginBottom: '15px' }}
                >
                    Adicionar Imagem
                </AddImageButton>

                {/* -- Modal de alerta caso a imagem exceda 2MB -- */}
                <ModalSizeImage
                    show={showImageSizedModal}
                    onCloseCallback={() => {
                        setShowImageSizedModal(false);
                    }}
                />
            </Container>
        </>
    );
};

export default ImageSelectMobile;
