import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Styled from './CursoLivre.styled';
import AdminEditorStyled from '../pageEditors/AdminEditor.styled';
import { useLocation } from 'react-router-dom';
import AdminCursoLivre from 'models/admin/AdminCursoLivre';
import { CursoLivreService } from './../../../../core/http/service/CursoLivre.service';
import { cloneDeep } from 'lodash';
import Select, { SelectOptionType } from 'components/Select';
import { isStringsValid, isValidLink } from '../newContent/isValidNewContentInput';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { DangerButton, GradientButton, OutlineButton } from 'components/UI/Buttons';
import AdminEditModalDelete from '../pageEditors/AdminEditModalDelete';
import Loading from 'components/Loading';
import ReactSwitch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import AdminStyled from 'pages/admin/Admin.styled';
import moment from 'moment';
import cursosLivresSorter from './cursoLivreSorter';
import { ToastError, ToastSuccess } from 'components/Toaster';
import AllowedGroupsValidator from '../allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

export interface ICursoLivre {
    onSaved: () => void;
}

const CursoLivre = ({ onSaved }: ICursoLivre) => {
    const [cursos, setCursos] = useState<AdminCursoLivre[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [deleting, setDeleting] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const MAX_CHAR_TITLE = 65;
    const MAX_CHAR_CONTENT = 300;
    const MAX_CHAR_URL = 200;

    const service = new CursoLivreService();
    registerLocale('ptBR', ptBR);

    const loadData = async () => {
        try {
            setLoading(true);
            const response: any = await service.listar();

            const output: AdminCursoLivre[] = response.data.map((item: any) =>
                new AdminCursoLivre().fromJSON({
                    codigo: item.codigo,
                    titulo: item.titulo,
                    descricao: item.descricao,
                    duracao: item.duracao,
                    tipoDuracao: item.tipoDuracao,
                    emProducao: item.emProducao,
                    naoAplicavel: item.naoAplicavel,
                    prazoInscricao: item.prazoInscricao,
                    dataExpiracao: item.dataExpiracao,
                    url: item.url,
                    urlAcessarCurso: item.urlAcessarCurso,
                    idCurso: item.idCurso,
                    anexoBase64: item.anexoBase64,
                    filePath: item.filePath,
                    ordem: item.ordem,
                })
            );
            if (output) {
                const sorted = cursosLivresSorter(output);
                setCursos(sorted);
            }
        } catch (error) {
            console.log('rrr', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const acceptedTypes: SelectOptionType[] = [
        { label: 'Hora', value: 'HORA' },
        { label: 'Minuto', value: 'MINUTO' },
    ].map(l => ({ label: l.label, value: l.value } as SelectOptionType));

    const getLabel = (value: string): string => {
        let labelRetorno = value;
        acceptedTypes.filter(type => {
            if (type.value == value) {
                labelRetorno = type.label;
            }
        });
        return labelRetorno;
    };

    const setTitle = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].titulo = value.substring(0, MAX_CHAR_TITLE);
        setCursos(clone);
    };

    const setDescricao = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].descricao = value.substring(0, MAX_CHAR_CONTENT);
        setCursos(clone);
    };

    const setDuracao = (idx: number, value: number) => {
        const clone = cloneDeep(cursos);
        clone[idx].duracao = value;
        setCursos(clone);
    };

    const setTipoDuracao = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].tipoDuracao = value;
        setCursos(clone);
    };

    const setUrl = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].url = value.substring(0, MAX_CHAR_URL);
        setCursos(clone);
    };

    const setUrlAcessarCurso = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].urlAcessarCurso = value.substring(0, 200);
        setCursos(clone);
    };

    const setIdCurso = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].idCurso = value.substring(0, 4);
        setCursos(clone);
    };

    const setImageBase64 = (idx: number, value: string) => {
        const clone = cloneDeep(cursos);
        clone[idx].anexoBase64 = value;
        setCursos(clone);
    };

    const setEmProducao = (idx: number, value: boolean) => {
        const clone = cloneDeep(cursos);

        clone[idx].emProducao = value;
        setCursos(clone);
    };

    const setNaoAplicavel = (idx: number, value: boolean) => {
        const clone = cloneDeep(cursos);
        clone[idx].naoAplicavel = value;
        setCursos(clone);
    };

    const setPrazoInscricao = (idx: number, value: Date) => {
        const clone = cloneDeep(cursos);

        if (!isValidPeriod(value)) {
            ToastError('Data selecionada inválida');
            return;
        }

        clone[idx].prazoInscricao = value;
        setCursos(clone);
    };

    const setDataExpiracao = (idx: number, value: Date) => {
        const clone = cloneDeep(cursos);

        if (!isValidPeriod(value)) {
            ToastError('Data selecionada inválida');
            return;
        }

        clone[idx].dataExpiracao = value;
        setCursos(clone);
    };

    const isValidInputs = (idx: number): boolean => {
        const curso: AdminCursoLivre = cursos[idx];

        if (!curso.anexoBase64 || (curso.anexoBase64 && curso.anexoBase64.trim().length === 0)) {
            return false;
        }

        if (curso.url && !isValidLink(curso.url, false)) return false;

        if (curso.urlAcessarCurso && !isValidLink(curso.urlAcessarCurso, false)) return false;

        if (curso.duracao <= 0) {
            return false;
        }

        if (!isValidDates(idx)) {
            return false;
        }

        return isStringsValid([curso.titulo, curso.descricao, curso.tipoDuracao, curso.url, curso.idCurso]);
    };

    const isValidDates = (idx: number): boolean => {
        const curso = cursos[idx];
        if (curso.prazoInscricao != null && curso.dataExpiracao != null) {
            return curso.dataExpiracao >= curso.prazoInscricao;
        }

        return true;
    };

    const isValidPeriod = (value: Date): boolean => {
        const umDiaEmMilissegundos = 24 * 60 * 60 * 1000;
        const data = new Date().getTime();

        const newValue = new Date(value).getTime();

        if (newValue >= data - umDiaEmMilissegundos) return true;

        return false;
    };

    const submit = async (idx: number) => {
        setSubmitting(true);
        const clone = cloneDeep(cursos);

        let response;

        if (!clone[idx].codigo || clone[idx].codigo === 0) {
            clone[idx].ordem = idx;
            response = await service.inserir(clone[idx]);
        } else {
            response = await service.editar(clone[idx].codigo, clone[idx]);
        }

        if (response.status === 200 || response.status === 201) {
            const objResposta: AdminCursoLivre = new AdminCursoLivre().fromJSON(response.data);
            clone[idx].codigo = objResposta.codigo;
            setCursos(clone);
            onSaved();
        } else {
            ToastError('Houve um erro ao tentar salvar o curso. Por favor, tente novamente!');
        }

        setSubmitting(false);
    };

    const addCurso = () => {
        const _cursos = cloneDeep(cursos);

        const curso = new AdminCursoLivre();

        _cursos.push(curso);
        setCursos(_cursos);
    };

    const excluirCurso = async (idx: number) => {
        if (confirm('Excluir este curso?')) {
            setDeleting(true);

            const clone = cloneDeep(cursos);

            const response = await service.excluir(clone[idx].codigo);
            if (response.status === 200) {
                let newCursos = cursos.filter((curso: AdminCursoLivre) => curso.codigo !== clone[idx].codigo);
                setCursos(newCursos);
                setShowModalDelete(true);
            } else {
                ToastError('Houve um erro ao tentar excluir o curso. Por favor, tente novamente!');
            }
            setDeleting(false);
        }
    };

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';

    const swapCursos = async (fromIndex: number, toIndex: number | string) => {
        const cloneCursos = cloneDeep(cursos);

        if (typeof toIndex == 'string') toIndex = parseInt(toIndex);

        const cursoFrom = cloneDeep(cloneCursos[fromIndex]);
        cursoFrom.ordem = toIndex;
        const cursoTo = cloneDeep(cloneCursos[toIndex]);
        cursoTo.ordem = fromIndex;

        cloneCursos[toIndex] = cursoFrom;
        cloneCursos[fromIndex] = cursoTo;

        setCursos(cloneCursos);

        setSubmitting(true);
        await service.editar(cursoTo.codigo, cursoTo);
        await service.editar(cursoFrom.codigo, cursoFrom);
        setSubmitting(false);

        ToastSuccess('Ordem dos Cursos Atualizada!');
    };

    const setupOrder = async (fromIndex: number, toIndex: number | string) => {
        if (hasCursoNotFilled() || toIndex == fromIndex) return;

        const cloneCursos = cloneDeep(cursos);

        if (typeof toIndex == 'string') toIndex = parseInt(toIndex);

        const cursoFrom = cloneDeep(cloneCursos[fromIndex]);
        cursoFrom.ordem = toIndex;
        setSubmitting(true);

        await service.editar(cursoFrom.codigo, cursoFrom);

        if (fromIndex < toIndex) {
            for (let i = fromIndex + 1; i <= toIndex; i++) {
                cloneCursos[i].ordem -= 1;
                await service.editar(cloneCursos[i].codigo, cloneCursos[i]);
            }
        } else {
            for (let i = toIndex; i < fromIndex; i++) {
                cloneCursos[i].ordem += 1;
                await service.editar(cloneCursos[i].codigo, cloneCursos[i]);
            }
        }

        setSubmitting(false);

        setCursos(cloneCursos);

        ToastSuccess('Ordem dos Cursos Atualizada!');
        loadData();
    };

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            const countValidCursos = cursos.filter(c => c.codigo > 0).length;

            for (let i = 0; i < countValidCursos; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [cursos]
    );

    const hasCursoNotFilled = (): boolean => {
        const someCurso = cursos.some(c => c.codigo == 0);

        if (someCurso) {
            const answer = confirm('Há curso(s) no formulário qua ainda não foram enviado(s). Continuar mesmo assim?');
            return !answer;
        }

        return false;
    };

    const removeCurso = (idx: number) => {
        if (confirm('Remover este curso do formulário?')) {
            const cloneCursos: AdminCursoLivre[] = [];
            for (let i = 0; i < cursos.length; i++) i != idx && cloneCursos.push(cursos[i]);
            setCursos(cloneCursos);
        }
    };

    return (
        <Styled.MainContainer>
            <Styled.Title>Adicionar cursos</Styled.Title>
            <>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {cursos.map((curso, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Styled.Title style={{ paddingTop: '5px 0', marginTop: '35px' }}>Curso {idx + 1}</Styled.Title>
                                    <Styled.CursosContainer>
                                        <div>
                                            <AdminEditorStyled.Label>Título</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                value={curso.titulo}
                                                onChange={(e: any) => setTitle(idx, e.target.value)}
                                                maxLength={MAX_CHAR_TITLE}
                                            />
                                            <AdminEditorStyled.CountLetter danger={curso.titulo.length >= MAX_CHAR_TITLE}>
                                                {curso.titulo.length}/{MAX_CHAR_TITLE}
                                            </AdminEditorStyled.CountLetter>

                                            <AdminEditorStyled.Label>Descrição</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                value={curso.descricao}
                                                onChange={(e: any) => setDescricao(idx, e.target.value)}
                                                rows={5}
                                                as="textarea"
                                                className={'form-control'}
                                                maxLength={MAX_CHAR_CONTENT}
                                            />
                                            <AdminEditorStyled.CountLetter danger={curso.descricao.length >= MAX_CHAR_CONTENT}>
                                                {curso.descricao.length}/{MAX_CHAR_CONTENT}
                                            </AdminEditorStyled.CountLetter>

                                            <Styled.RowGrid>
                                                <Styled.ColumnGrid>
                                                    <AdminEditorStyled.Label>Duração</AdminEditorStyled.Label>
                                                    <AdminEditorStyled.Input
                                                        className={curso.duracao <= 0 && 'is-invalid'}
                                                        type="number"
                                                        value={curso.duracao}
                                                        onChange={(e: any) => {
                                                            if (e.target.value % 1 != 0 || e.target.value < 0 || e.target.value > 100) {
                                                                setDuracao(idx, 0);
                                                            } else {
                                                                setDuracao(idx, e.target.value);
                                                            }
                                                        }}
                                                    />
                                                </Styled.ColumnGrid>

                                                <Styled.ColumnGrid>
                                                    <AdminEditorStyled.Label>Hora/Minuto</AdminEditorStyled.Label>
                                                    <Select
                                                        itens={acceptedTypes}
                                                        value={{ value: curso.tipoDuracao, label: getLabel(curso.tipoDuracao) }}
                                                        onSelected={o => setTipoDuracao(idx, String(o.value))}
                                                        isSmall={true}
                                                    />
                                                </Styled.ColumnGrid>

                                                <Styled.ColumnGrid
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginTop: '30px',
                                                    }}
                                                >
                                                    <AdminEditorStyled.CkeckContainer>
                                                        <span style={{ marginRight: '10px' }}>Em breve nova turma</span>
                                                        <ReactSwitch
                                                            height={18 * 0.7}
                                                            width={40 * 0.7}
                                                            handleDiameter={22 * 0.7}
                                                            onHandleColor={'#0B78C8'}
                                                            offHandleColor={'#0B78C8'}
                                                            onColor={'#BFDFF7'}
                                                            offColor={'#dfdfdf'}
                                                            checkedIcon={false}
                                                            uncheckedIcon={false}
                                                            checked={curso.emProducao ?? false}
                                                            onChange={value => setEmProducao(idx, value)}
                                                        />
                                                    </AdminEditorStyled.CkeckContainer>
                                                </Styled.ColumnGrid>
                                            </Styled.RowGrid>

                                            <Styled.RowGrid>
                                                <Styled.ColumnGrid>
                                                    <AdminEditorStyled.Label>Prazo de Inscrição</AdminEditorStyled.Label>
                                                    <DatePicker
                                                        className={!isValidDates(idx) ? 'is-invalid' : undefined}
                                                        locale="ptBR"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={curso.dataExpiracao != null ? curso.dataExpiracao : undefined}
                                                        minDate={
                                                            curso.prazoInscricao != null && curso.prazoInscricao < new Date()
                                                                ? curso.prazoInscricao
                                                                : new Date()
                                                        }
                                                        selected={curso.prazoInscricao}
                                                        onChange={(date: Date) => setPrazoInscricao(idx, date)}
                                                        disabled={curso.naoAplicavel}
                                                        customInput={<CustomDatePickerInput />}
                                                    />
                                                </Styled.ColumnGrid>

                                                <Styled.ColumnGrid>
                                                    <AdminEditorStyled.Label>Data de Expiração</AdminEditorStyled.Label>
                                                    <DatePicker
                                                        className={!isValidDates(idx) ? 'is-invalid' : undefined}
                                                        locale="ptBR"
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={curso.prazoInscricao != null ? curso.prazoInscricao : new Date()}
                                                        selected={curso.dataExpiracao}
                                                        onChange={(date: Date) => setDataExpiracao(idx, date)}
                                                        disabled={curso.naoAplicavel}
                                                        customInput={<CustomDatePickerInput />}
                                                    />
                                                </Styled.ColumnGrid>

                                                <AdminEditorStyled.CkeckContainer style={{ marginBottom: '-45px' }}>
                                                    <span style={{ marginRight: '10px' }}>Não aplicável</span>
                                                    <ReactSwitch
                                                        height={18 * 0.7}
                                                        width={40 * 0.7}
                                                        handleDiameter={22 * 0.7}
                                                        onHandleColor={'#0B78C8'}
                                                        offHandleColor={'#0B78C8'}
                                                        onColor={'#BFDFF7'}
                                                        offColor={'#dfdfdf'}
                                                        checkedIcon={false}
                                                        uncheckedIcon={false}
                                                        checked={curso.naoAplicavel ?? false}
                                                        onChange={(value: boolean) => setNaoAplicavel(idx, value)}
                                                    />
                                                </AdminEditorStyled.CkeckContainer>
                                            </Styled.RowGrid>

                                            <AdminEditorStyled.Label>Url</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                className={
                                                    curso.url && curso.url.length > 3 && !isValidLink(curso.url ?? '', false) && 'is-invalid'
                                                }
                                                value={curso.url}
                                                onChange={(evt: any) => setUrl(idx, evt.target.value)}
                                                maxLength={MAX_CHAR_URL}
                                            />
                                            <AdminEditorStyled.CountLetter danger={curso.url.length >= MAX_CHAR_URL}>
                                                {curso.url.length}/{MAX_CHAR_URL}
                                            </AdminEditorStyled.CountLetter>
                                            <AdminEditorStyled.Label>Url Acessar Curso</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                className={
                                                    curso.urlAcessarCurso &&
                                                    curso.urlAcessarCurso.length > 3 &&
                                                    !isValidLink(curso.urlAcessarCurso ?? '', false) &&
                                                    'is-invalid'
                                                }
                                                value={curso.urlAcessarCurso}
                                                onChange={(evt: any) => setUrlAcessarCurso(idx, evt.target.value)}
                                                maxLength={MAX_CHAR_URL}
                                            />
                                            <AdminEditorStyled.CountLetter danger={curso.urlAcessarCurso.length >= MAX_CHAR_URL}>
                                                {curso.urlAcessarCurso.length}/{MAX_CHAR_URL}
                                            </AdminEditorStyled.CountLetter>

                                            <AdminEditorStyled.Label>Id do Curso no Brightspace</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                className={curso.idCurso && curso.idCurso.length !== 4 && 'is-invalid'}
                                                value={curso.idCurso}
                                                onChange={(evt: any) => setIdCurso(idx, evt.target.value.replace(/\D/g, ''))}
                                                maxLength={4}
                                            />

                                            {curso.codigo > 0 && (
                                                <>
                                                    <AdminEditorStyled.Label>Ordem</AdminEditorStyled.Label>
                                                    <Select
                                                        itens={itemsOrder(idx)}
                                                        value={{
                                                            value: idx,
                                                            label: String(idx + 1),
                                                        }}
                                                        onSelected={o => swapCursos(curso.ordem, o.value)}
                                                        disabled={submitting || deleting}
                                                        isSmall={true}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <Styled.RightColumn>
                                            <AdminImageUploader
                                                size={'sm'}
                                                title={'Imagem de capa'}
                                                onSelect={(base64: string, file: File) => setImageBase64(idx, base64)}
                                                preImageB64={curso.anexoBase64}
                                                recommendedDimension={{ w: 190, h: 345 }}
                                            />

                                            <br />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <GradientButton
                                                    style={{ marginBottom: '15px' }}
                                                    onClick={async () => await submit(idx)}
                                                    disabled={!isValidInputs(idx) || submitting}
                                                >
                                                    {submitting ? 'Salvando...' : 'Salvar'}
                                                </GradientButton>
                                                {curso.codigo > 0 ? (
                                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                        <DangerButton disabled={deleting} onClick={async () => excluirCurso(idx)}>
                                                            {deleting ? 'Excluindo...' : 'Excluir'}
                                                        </DangerButton>
                                                    </AllowedGroupsValidator>
                                                ) : (
                                                    <DangerButton onClick={async () => removeCurso(idx)} style={{ fontSize: '0.8em' }}>
                                                        Remover do formulário
                                                    </DangerButton>
                                                )}
                                            </div>
                                        </Styled.RightColumn>
                                    </Styled.CursosContainer>
                                </React.Fragment>
                            );
                        })}

                        <AdminEditorStyled.BottomButton>
                            <OutlineButton onClick={addCurso}>+ Adicionar Curso</OutlineButton>
                        </AdminEditorStyled.BottomButton>
                    </>
                )}
            </>
            <AdminEditModalDelete
                show={showModalDelete}
                onHide={() => {
                    setShowModalDelete(false);
                }}
            />
        </Styled.MainContainer>
    );
};

export default CursoLivre;
