import styled from 'styled-components';

import Colors from '../../enums/Colors';

const Text = styled.p({
    color: Colors.DarkGrey,
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
});

export const DestaqueText = styled.span({
    color: Colors.SoftBlue,
    fontWeight: 'bold',
});

export const BoldText = styled(DestaqueText)({
    color: Colors.DarkGrey,
});

export const ItalicText = styled.span({
    fontStyle: 'italic',
});

export const Label = styled.p({
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '9px',
    color: Colors.Grey3,
    letterSpacing: '0.3px',
});

export const H1 = styled.h1({
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontFamily: 'Barlow',

    color: Colors.Grey9,
});

export default Text;
