import React, { useState, useEffect } from 'react';
import Styled from './AdminUsers.styled';

import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { PrimaryButton } from 'components/UI/Buttons';
import Loading from 'components/Loading';
import AdminUserService from 'core/http/service/admin/AdminUser.service';
import Page from 'models/Page';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import AdminUsersPagination from './AdminUsersPagination';
import { CpfMask } from 'core/util/MaskHelper';
import { fetchRoleDescription } from 'core/util/KeycloakHelp';
import Row from 'components/Row';
import { FiKey } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { FaRegTrashAlt } from 'react-icons/fa';
import AdminModalEdit from './modals/AdminModalEdit';
import AdminModalPass from './modals/AdminModalPass';
import AdminModalExclude from './modals/AdminModalExclude';
import { RoleEnum } from 'enums/RoleEnum';
import { ToastError, ToastSuccess } from 'components/Toaster';

const service = new AdminUserService();

const AdminUsers = () => {
    // campos de busca
    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [emptyFields, setEmptyFields] = useState(false);

    //estados de conteúdos
    const [nocontent, setNocontent] = useState(true);
    const [users, setUsers] = useState<PerfilUsuario[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showResultSearch, setshowResultSearch] = useState(false);

    // estados de paginação
    const per_page = 10;
    const [page, setPage] = useState(1);
    const [totalPages, settotalPages] = useState(1);

    //modals
    const [showEditModal, setshowEditModal] = useState(-1);
    const [showPassModal, setshowPassModal] = useState(-1);
    const [showExcludeModal, setshowExcludeModal] = useState(-1);

    //submiitng
    const [submittingExclude, setSubmittingExclude] = useState<boolean>(false);

    const parseResult = (data: any) => {
        const _page = new Page<PerfilUsuario>().fromJSON(data);
        const _users = _page.items;
        const _totalPages = _page.totalPages;

        setNocontent(_users.length == 0);
        settotalPages(_totalPages);
        setUsers(_users);

        scrollTo(0, 0);
    };

    const fetchUsers = async (_page: number) => {
        if (isLoading) return;

        setLoading(true);
        const { data } = await service.listar(page, per_page);
        parseResult(data);
        setLoading(false);
        setshowResultSearch(false);
    };

    const search = async (_page: number) => {
        if (isLoading) return;

        if (cpf.trim().length == 0 && nome.trim().length == 0 && email.trim().length == 0) {
            setEmptyFields(true);
        } else {
            setLoading(true);

            let cpf_sanitized: string | null = null;

            if (cpf) {
                cpf_sanitized = cpf.trim().replace(/(\.|-)/g, '');
            }

            const { data } = await service.buscar(nome.trim() || null, cpf_sanitized || null, email.trim() || null, _page, per_page);
            parseResult(data);
            setLoading(false);
            setshowResultSearch(true);
        }
    };

    ////////////// AÇÕES DO DASHBOARD ////////////////
    const resetPass = async (perfil: PerfilUsuario) => {
        const { status } = await service.resetSenha(perfil.username);
        if (status < 300) {
            setshowPassModal(-1);
            ToastSuccess('Senha redefinida com sucesso!');
        } else ToastError('Problema no sistema. Tente novamante.');
    };

    const excludeUser = async (perfil: PerfilUsuario) => {
        setSubmittingExclude(true);
        const { status } = await service.excluir(perfil.keycloakId);
        if (status < 300) {
            setshowExcludeModal(-1);
            ToastSuccess('Usuário excluído com sucesso!');
            fetchUsers(1);
        } else ToastError('Problema no sistema. Tente novamante.');
        setSubmittingExclude(false);
    };

    const updateUser = async (perfil: PerfilUsuario, role: string) => {
        const { status } = await service.atualizarPerfil(perfil.username, role);
        if (status < 300) {
            setshowEditModal(-1);
            ToastSuccess('Perfil alterado com sucesso!');
            fetchUsers(1);
        } else ToastError('Problema no sistema. Tente novamante.');
    };

    const clearFilters = () => {
        setPage(1);
        setCpf('');
        setEmail('');
        setNome('');
        setSobrenome('');
        fetchUsers(1);
        setEmptyFields(false);
    };
    //////////////////////////////////////////////////////

    useEffect(() => {
        setEmptyFields(false);
    }, [cpf, nome, email]);

    useEffect(() => {
        if (showResultSearch) search(page);
        else fetchUsers(page);
    }, [page]);

    return (
        <>
            <Styled.Container>
                <AdminModalPass
                    show={showPassModal != -1}
                    onHide={() => setshowPassModal(-1)}
                    onSubmit={perfil => resetPass(perfil)}
                    perfil={users.find(u => u.codigo == showPassModal) || new PerfilUsuario()}
                />

                <AdminModalEdit
                    show={showEditModal != -1}
                    onHide={() => setshowEditModal(-1)}
                    onSubmit={(perfil, newRole) => updateUser(perfil, newRole)}
                    perfil={users.find(u => u.codigo == showEditModal) || new PerfilUsuario()}
                />

                <AdminModalExclude
                    show={showExcludeModal != -1}
                    onHide={() => {
                        setshowExcludeModal(-1);
                        setSubmittingExclude(false);
                    }}
                    onSubmit={perfil => excludeUser(perfil)}
                    submitting={submittingExclude}
                    perfil={users.find(u => u.codigo == showExcludeModal) || new PerfilUsuario()}
                />

                <AdminBreadCrumb crumbs={[{ route: 'gestao-usuarios', label: 'Gestão de usuários' }]} />
                <br />

                <Styled.Title>Gestão de usuários da humane</Styled.Title>
                <Styled.Text>Busque e gerencie neste painel os usuários da humane</Styled.Text>

                <br />
                <br />

                <Styled.DivRowRight>
                    <Styled.RowRigth>
                        <span className="label-order">Primeiro nome:</span>
                        <Styled.Input
                            className="admin-search-input"
                            value={nome}
                            onChange={(e: any) => setNome(e.target.value)}
                            placeholder="Digite o nome do usuário"
                            isInvalid={emptyFields}
                        />
                    </Styled.RowRigth>

                    {/* <Styled.RowRigth>
                        <span className="label-order">Sobrenome:</span>
                        <Styled.Input
                            className="admin-search-input"
                            value={sobrenome}
                            onChange={(e: any) => setNome(e.target.value)}
                            placeholder="Digite o sobrenome do usuário"
                            isInvalid={emptyFields}
                        />
                    </Styled.RowRigth> */}

                    <Styled.RowRigth>
                        <span className="label-order">CPF:</span>
                        <Styled.Input
                            className="admin-search-input"
                            value={cpf}
                            onChange={(e: any) => setCpf(e.target.value)}
                            placeholder="Digite o CPF do usuário"
                            isInvalid={emptyFields}
                        />
                    </Styled.RowRigth>

                    <Styled.RowRigth>
                        <span className="label-order">E-mail:</span>
                        <Styled.Input
                            className="admin-search-input"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder="Digite o e-mail do usuário"
                            isInvalid={emptyFields}
                        />
                    </Styled.RowRigth>
                </Styled.DivRowRight>

                <Row justify="space-between" alignVertical="end">
                    <Row alignVertical="center">
                        <PrimaryButton
                            onClick={() => {
                                const _page = 1;
                                setPage(_page);
                                setshowResultSearch(true);
                                search(_page);
                            }}
                        >
                            Buscar
                        </PrimaryButton>
                        {emptyFields && <Styled.RedLabel>Preencha ao menos um dos campos para realizar a busca.</Styled.RedLabel>}
                    </Row>

                    {!isLoading && showResultSearch && <Styled.MiniLabel onClick={clearFilters}>Limpar filtros</Styled.MiniLabel>}
                </Row>

                <Styled.Separator id="division"></Styled.Separator>

                {isLoading ? (
                    <Styled.LoadingContainer>
                        <Loading />
                    </Styled.LoadingContainer>
                ) : (
                    <>
                        {nocontent ? (
                            <Styled.NoContent>
                                Não encontramos usuários com essas informações. Tente novamente com outros dados.
                            </Styled.NoContent>
                        ) : (
                            <Styled.TableContainer>
                                <Styled.GridPost>
                                    <Styled.GridHead>Nome</Styled.GridHead>
                                    <Styled.GridHead>CPF</Styled.GridHead>
                                    <Styled.GridHead>E-mail</Styled.GridHead>
                                    <Styled.GridHead>Perfil</Styled.GridHead>
                                    <Styled.GridHead>Ações</Styled.GridHead>
                                </Styled.GridPost>

                                {users.map(user => {
                                    return (
                                        <>
                                            <Styled.GridPost style={{ borderBottom: '1px #ddd solid' }}>
                                                <Styled.GridCell>{user.apelido}</Styled.GridCell>
                                                <Styled.GridCell>{CpfMask(user.cpf ?? "")}</Styled.GridCell>
                                                <Styled.GridCell>{user.email}</Styled.GridCell>
                                                <Styled.GridCell>
                                                    {fetchRoleDescription(user.role) || user.role || user.ocupacao}
                                                </Styled.GridCell>
                                                <Styled.GridCell>
                                                    <Row justify="space-around" style={{ color: '#003c78' }}>
                                                        <Styled.Col onClick={() => setshowPassModal(user.codigo)}>
                                                            <FiKey />
                                                            <Styled.Label>Redefinir Senha</Styled.Label>
                                                        </Styled.Col>

                                                        <Styled.Col
                                                            disabled={user.role != RoleEnum.Outro}
                                                            onClick={() => {
                                                                if (user.role == RoleEnum.Outro) setshowEditModal(user.codigo);
                                                            }}
                                                        >
                                                            <AiOutlineUser />
                                                            <Styled.Label>Alterar Perfil</Styled.Label>
                                                        </Styled.Col>

                                                        <Styled.Col onClick={() => setshowExcludeModal(user.codigo)}>
                                                            <FaRegTrashAlt />
                                                            <Styled.Label>Excluir Usuário</Styled.Label>
                                                        </Styled.Col>
                                                    </Row>
                                                </Styled.GridCell>
                                            </Styled.GridPost>
                                        </>
                                    );
                                })}

                                <br />

                                {totalPages > 1 && (
                                    <AdminUsersPagination
                                        page={page}
                                        totalPages={totalPages}
                                        onSelectPage={(p: number) => {
                                            if (!showResultSearch) {
                                                setCpf('');
                                                setEmail('');
                                                setNome('');
                                            }

                                            setPage(p);
                                        }}
                                    />
                                )}
                            </Styled.TableContainer>
                        )}
                    </>
                )}
            </Styled.Container>
        </>
    );
};

export default AdminUsers;
