import styled from 'styled-components';

const AdminBiblioCategoriasStyled = {
    Container: styled.div`
        width: 98%;
        margin: 0 auto;
        min-width: 480px;
        border-radius: 10px;
    `,

    ContainerInput: styled.div``,

    SpaceBottom: styled.div`
        height: 200px;
        width: 100%;
    `,

    Row: styled.div`
        padding: 10px;

        :hover {
            background-color: #f9f9fa;
        }
    `,

    Center: styled.p`
        text-align: center;
    `,

    ButtonIcon: styled.span`
        color: #1e4e7e;
        cursor: pointer;
        margin: 0 5px;

        font-size: 1.2em;
    `,

    RowHead: styled.div`
        padding: 10px;
        background-color: #f6f6f6;
    `,

    Title: styled.div`
        font-weight: 600;
        text-align: center;
        color: #1e4e7e;
        font-family: 'Barrow' sans-serif;

        :hover {
            color: #0d47a1;
        }
    `,

    ColumnGrid: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 120px 100px 180px 60px;
    `,

    Input: styled.input`
        padding: 10px 13px;
        outline: none;
        margin: 5px 10px;
        border: 1px #ccc solid;
        border-radius: 10px;
    `,

    RowInput: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    `,

    ButtonText: styled.span`
        margin: 0 5px;
        font-size: 0.9em;
        cursor: pointer;
        font-family: 'Barrow' sans-serif;

        :hover {
            text-decoration: underline;
        }
    `,
};

export default AdminBiblioCategoriasStyled;
