import styled from 'styled-components';

const AdminBreadCrumbStyled = {
    Container: styled.div`
        margin: 10px 0;

        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #003c78;

        display: flex;
    `,

    Item: styled.span<{ active: boolean }>`
        opacity: ${props => (props.active ? '1' : '0.5')};
        cursor: pointer;

        :hover {
            opacity: 1;
            text-decoration: underline;
        }
    `,

    Separator: styled.span`
        margin: 0 3px;
        color: #cbd2e0;
    `,
};

export default AdminBreadCrumbStyled;
