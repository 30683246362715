import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import { BiblioItemCategoria } from './BiblioItemCategoria';

class BiblioItem extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('slug')
    @jsonProperty(String)
    slug: string = '';

    @jsonName('titulo')
    @jsonProperty(String)
    title: string = '';

    @jsonName('autores')
    @jsonProperty(String)
    authors: string = '';

    @jsonName('categoria')
    @jsonProperty(String)
    category: BiblioItemCategoria = BiblioItemCategoria.UNKNONW;

    @jsonName('capa')
    @jsonProperty(String, null)
    cover?: string | null = '';

    @jsonName('favoritado')
    @jsonProperty(Boolean)
    isFavorited: boolean = false;

    @jsonName('avaliacao')
    @jsonProperty(Number, null)
    rateUser: number = 0;

    @jsonName('linkYouTube')
    @jsonProperty([String], [], null)
    linkSrc?: string[] = [];

    @jsonName('linkSoundcloud')
    @jsonProperty([String], [], null)
    linkSoundcloud?: string[] = [];

    @jsonName('numeroPaginas')
    @jsonProperty(Number)
    numberPages?: number = 0;

    @jsonName('anoPublicacao')
    @jsonProperty(String)
    yearPublication?: string = '';

    @jsonName('idioma')
    @jsonProperty(String)
    language?: string = '';

    @jsonName('conteudo')
    @jsonProperty(String)
    content?: string = '';

    @jsonName('status')
    @jsonProperty(String, null)
    status?: string = '';

    @jsonName('criacao')
    @jsonProperty(String)
    create_date: string = '';

    @jsonName('edicao')
    @jsonProperty(String)
    update_date: string = '';

    @jsonName('tags')
    @jsonProperty([String], [])
    tags?: string[] = [];

    @jsonName('titleTag')
    @jsonProperty(String)
    titleTag: string = '';

    @jsonName('metaDescriptionTag')
    @jsonProperty(String)
    metaDescriptionTag: string = '';
}

export default BiblioItem;
