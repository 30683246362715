import { GTM_Event_ABTest, Events } from 'components/GoogleTagManager';
import TestVersion, { stringFromTestVersion } from 'enums/TestVersions';

export const keyLocalStorage = 'AB_TEST_VERSION';
class ABTest {
    private static getRandomVersion(): string {
        return TestVersion.VersionA;
    }

    static getCurrentVersion(): TestVersion {
        let current = localStorage.getItem(keyLocalStorage);

        if (current) return stringFromTestVersion(current);
        else {
            current = ABTest.getRandomVersion();
            localStorage.setItem(keyLocalStorage, current);
        }

        return stringFromTestVersion(current);
    }

    static sendGTM_Event() {
        GTM_Event_ABTest(ABTest.getCurrentVersion());
    }
}

export default ABTest;
