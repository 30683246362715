import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import CommonStyled from 'pages/biblioteca/subpages/itemPage/categoriasItem/CommonStyled';
import { OutlineButton } from 'components/UI/Buttons';

const BiblioShareModalStyled = {
    Container: styled.div`
        padding: 20px;
    `,

    Modal: styled(Modal)`
        .modal-content {
            border-radius: 10px;
            max-width: 660px;
        }
    `,

    CloseContainer: styled.div`
        text-align: right;
    `,

    CloseIcon: styled.img`
        width: 25px;
        cursor: pointer;
        transition: 0.1s;

        :hover {
            transform: scale(1.05);
        }
    `,

    ActionButton: CommonStyled.ActionButton,

    Title: styled.p`
        color: #00a8e9;
        font-weight: 600;
        font-size: 24px;
        line-height: 42px;
        margin-top: -20px;
    `,

    Subtitle: styled.p`
        font-size: 18px;
        line-height: 22px;
        color: #2e2e2e;
    `,

    Label: styled.p`
        color: #003c78;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
    `,

    OutputContainer: styled.div`
        display: flex;
        gap: 7px;
        max-width: 100%;
        height: fit-content;
        margin-top: 7px;
    `,
    Output: styled.div`
        border: 1px solid #00a8e9;
        color: #2e2e2e;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-self: center;
        padding: 10px 5px;
        border-radius: 5px;
        border: 1px solid #b5b5b5;
        box-shadow: 1px 1px 10px 6px rgba(0, 0, 0, 0.05);
        overflow-wrap: anywhere;

        cursor: text;
        ::selection {
            color: #2e2e2e;
            background: #badefe;
        }
    `,

    CopyButton: styled(OutlineButton)`
        max-width: 100%;
        border-radius: 5px;
    `,

    ButtonContainer: styled.div`
        display: flex;
        padding-top: 15px;
    `,

    ButtonMedia: styled.div<{ color: string }>`
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${props => props.color};
        margin: 0 5px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.1s;

        :hover {
            transform: scale(1.07);
        }
    `,

    Icon: styled.img`
        width: 20px;
        height: auto;
    `,

    Br: styled.div`
        height: 30px;
    `,
};

export default BiblioShareModalStyled;
