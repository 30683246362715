import styled, { keyframes } from 'styled-components';
import Colors from '../../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';

export const FormContainer = styled.div`
    width: 100%;
    margin-top: 43px;
    padding-bottom: 60px;
`;

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FormControl = styled.div`
    display: flex;
    animation: ${fade} 0.3s linear;
    margin-bottom: 10px;
`;

export const SubmitButtonContainer = styled.div`
    margin-top: 70px;
`;

export const ContinueButton = styled.img`
    height: 36px;
    margin: 0 15px;

    &:hover {
        cursor: pointer;
    }
`;

export const InfoText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    font-feature-settings: 'calt' off;
    white-space: pre-line;

    margin-left: 18px;
    color: #00a8e9;
    &:hover {
        cursor: pointer;
    }
`;
