import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import {
    ActionButtonContainer,
    MainActionButton,
    MobileMenuContainer,
    PopOver,
    PopupMenuContainer,
    RegisterButton,
    primaryButtonStyle,
    secondaryButtonStyle,
    CloseMenuButton,
    MenuOptionsWithHover,
    OptionsContainer,
} from './ActionButton.styled';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import { GTM_Menu } from 'components/GoogleTagManager';
import { useOutsideRef } from 'hooks/useOutsideRef';
import { CheckedGoToContext } from 'pages/painelSocioemocional/home/context/CheckedGoToContext';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';

interface IActionButton {
    close?: (value: boolean) => void;
}

const ActionButton: FunctionComponent<IActionButton> = ({ ...props }: IActionButton) => {
    const [openMenu, setOpenMenu] = useState(false);

    const { isVerifyGoToPage, showModal, setShowModal, goToPage } = useContext(CheckedGoToContext.Context);

    const history = useHistory();
    const { keycloak } = useKeycloak();

    const logout = async () => {
        isVerifyGoToPage('/logout');
    };

    const gotoEditPage = () => {
        isVerifyGoToPage('/editarPerfil');
    };

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const register = () => {
        const url = keycloak.createRegisterUrl({
            redirectUri: window.location.origin + '/login',
        });
        location.assign(url);
    };

    const login = () => {
        history.push('/login');
    };

    const isMobile = () => {
        return IsCustomMaxWidth(1250);
    };

    const popOverRef = useRef(null);
    useOutsideRef(popOverRef, () => {
        setOpenMenu(false);
    });

    const desktopActionButton = () => {
        return (
            <ActionButtonContainer>
                {!keycloak.authenticated ? (
                    <div style={{ marginLeft: '50px' }}>
                        <RegisterButton
                            onClick={() => {
                                GTM_Menu('menu_header', 'registrar_btn');
                                register();
                            }}
                        >
                            Cadastre-se aqui
                        </RegisterButton>
                        <MainActionButton
                            onClick={() => {
                                GTM_Menu('menu_header', 'login_btn');
                                login();
                            }}
                        >
                            Entrar
                        </MainActionButton>
                    </div>
                ) : (
                    <PopupMenuContainer style={{ marginLeft: '50px' }}>
                        <MainActionButton onClick={toggleMenu}>Opções</MainActionButton>
                        {openMenu && (
                            <PopOver ref={popOverRef} style={{ top: '65px' }}>
                                <OptionsContainer>
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'editar_perfil_btn');
                                            gotoEditPage();
                                        }}
                                    >
                                        Editar perfil
                                    </MenuOptionsWithHover>
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'logout_btn');
                                            logout();
                                        }}
                                    >
                                        Sair da humane
                                    </MenuOptionsWithHover>
                                </OptionsContainer>
                            </PopOver>
                        )}
                    </PopupMenuContainer>
                )}
            </ActionButtonContainer>
        );
    };

    const mobileActionButton = () => {
        return (
            <ActionButtonContainer>
                {!keycloak.authenticated ? (
                    <MobileMenuContainer>
                        <RegisterButton
                            style={primaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'registrar_btn_mobile');
                                register();
                            }}
                        >
                            Cadastre-se
                        </RegisterButton>
                        <MainActionButton
                            style={secondaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'login_btn_mobile');
                                login();
                            }}
                        >
                            Entrar
                        </MainActionButton>
                        <CloseMenuButton
                            onClick={() => {
                                if (props.close) {
                                    props.close(false);
                                }
                            }}
                        >
                            Fechar menu
                        </CloseMenuButton>
                    </MobileMenuContainer>
                ) : (
                    <MobileMenuContainer>
                        <RegisterButton
                            style={primaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'editar_perfil_btn_mobile');
                                gotoEditPage();
                            }}
                        >
                            Editar perfil
                        </RegisterButton>
                        <MainActionButton
                            style={secondaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'logout_btn_mobile');
                                logout();
                            }}
                        >
                            Sair da humane
                        </MainActionButton>
                        <CloseMenuButton
                            onClick={() => {
                                if (props.close) {
                                    props.close(false);
                                }
                            }}
                        >
                            Fechar menu
                        </CloseMenuButton>
                    </MobileMenuContainer>
                )}
                <ModalCheckGoToPage showModal={showModal} setShowModal={setShowModal} goToPage={goToPage} />
            </ActionButtonContainer>
        );
    };

    return <>{isMobile() ? mobileActionButton() : desktopActionButton()}</>;
};

export default ActionButton;
