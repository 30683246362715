import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';
import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';

class AdminBiblioItemPreview extends BiblioItemPreview {
    @jsonName('nomeUsuario')
    @jsonProperty(String)
    userSender: string = '';

    @jsonProperty(String)
    status: string = '';

    @jsonName('edicao')
    @jsonProperty(String)
    date: string = '';

    @jsonName('criacao')
    @jsonProperty(String)
    dateCriacao: string = '';

    @jsonName('conteudo')
    @jsonProperty(String)
    content: string = '';

    @jsonName('publico')
    @jsonProperty(Boolean)
    public: boolean = true;

    @jsonName('relacionado')
    @jsonProperty(Boolean)
    relacionado: boolean = false;

    @jsonIgnore()
    enabled: boolean = true;
}

export default AdminBiblioItemPreview;
