export enum RoleEnum {
    Professor = 'ROLE_TEACHER',
    GestorRede = 'ROLE_NETWORK_MANAGER',
    GestorEscolar = 'ROLE_SCHOOL_MANAGER',
    Administrador = 'ROLE_ADMINISTRATOR',
    Estudante = 'ROLE_EXAMINEE',
    CoordenadorRegional = 'ROLE_REGIONAL_COORDINATOR',
    Pesquisador = 'ROLE_RESEARCHER',
    ColaboradorParceria = 'ROLE_PARTNERSHIP_COLLABORATOR',
    CoordenadorNaoParceiro = 'ROLE_EXTERNAL_COORDINATOR',
    Diretor = 'ROLE_DIRECTOR',
    TecnicoEducacao = 'ROLE_EDUCATOR_TECHNICIAN',
    AgenteTecnico = 'ROLE_TECHNICAL_AGENT',
    LMS = 'ROLE_LMS',
    Outro = 'ROLE_OTHER',
    Unknown = '_',
}

export const RoleList = [
    {
        label: 'Professor',
        value: 'ROLE_TEACHER',
    },
    {
        label: 'Gestor(a) de Rede',
        value: 'ROLE_NETWORK_MANAGER',
    },
    {
        label: 'Gestor(a) Escolar',
        value: 'ROLE_SCHOOL_MANAGER',
    },

    {
        label: 'Coordenador(a) Regional',
        value: 'ROLE_REGIONAL_COORDINATOR',
    },
    {
        label: 'Coordenador Não Parceiro',
        value: 'ROLE_EXTERNAL_COORDINATOR',
    },
    {
        label: 'Diretor',
        value: 'ROLE_DIRECTOR',
    },
    {
        label: 'Técnico da Educação',
        value: 'ROLE_EDUCATOR_TECHNICIAN',
    },
    {
        label: 'Agente Técnico',
        value: 'ROLE_TECHNICAL_AGENT',
    },

    {
        label: 'Outros',
        value: 'ROLE_OTHER',
    },
];
