import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class Page<T> extends Serializable {
    @jsonProperty([Object])
    content: T[] = [];

    @jsonProperty(Number)
    totalElements: number = 0;

    @jsonProperty(Number)
    totalPages: number = 0;

    get items() {
        return this.content;
    }

    constructor() {
        super();
    }
}

export default Page;
