import CardModulos, { StatusCardModulo } from 'models/secoes/CardModulos';

export default function instanciaCardModulo(values: Partial<CardModulos> = {}): CardModulos {
    const instancia = new CardModulos();
    Object.assign(instancia, values);
    return instancia;
}


export const TrilhaApronfundadaInfoCard: CardModulos[] = [
    instanciaCardModulo({
        title: 'Histórico das políticas públicas e o cenário atual',
        description:
            'Neste módulo, falaremos sobre a história da escrita e do alfabeto, o olhar para a educação integral, os dados atuais sobre o cenário da alfabetização no Brasil e as políticas públicas já propostas e implementadas.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 1,
        time: '1h30',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Conceitos e estratégias de Alfabetização',
        description:
            'Conheça os sentidos e as práticas da alfabetização considerando diferentes abordagens na perspectiva da BNCC e outros documentos orientadores.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 2,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Alfabetização Matemática',
        description:
            'Saiba mais sobre o que é a alfabetização matemática e sobre como potencializar o processo de aprendizagem afim de apoiar os estudantes a se apropriarem da linguagem matemática e a utilizá-la em sua vida cotidiana.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 3,
        time: '1h30',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Educação Integral com foco em Alfabetização nos anos iniciais',
        description:
            'Conheça mais sobre educação integral com foco na alfabetização, considerando a formação plena do estudante e o desenvolvimento socioemocional intencional desde o início de seu processo escolar.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 4,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'As etapas do processo de Alfabetização ',
        description:
            'Conheça mais sobre as múltiplas linguagens através da integração curricular e o que diz as diferentes matrizes com base na perspectiva da BNCC, além dos parâmetros indicados pelo INEP e MEC para essa etapa de ensino. ',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 5,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Os fundamentos da Alfabetização ',
        description:
            'Acompanhe essa discussão sobre a função social da alfabetização, a correlação dos processos neurológicos na aprendizagem e as concepções de ensino e práticas para atingir o que é essencial para aprendizagem.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 6,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Dificuldades de Aprendizagem e as práticas de superação',
        description:
            'Neste módulo vamos abordar a importância do desenvolvimento socioemocional, dificuldades de aprendizagem e algumas sugestões de ferramentas e boas práticas para ajudar  na alfabetização e na recomposição da aprendizagem. ',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 7,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Avaliação e Monitoramento ',
        description:
            'Aprenda mais sobre a avaliação como ferramenta de compreensão auditiva e de leitura, além de conhecer as questões basilares de gestão e monitoramento também para avaliação  processual na dimensão de sala de aula. ',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 8,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'Cultura Digital na Alfabetização ',
        description:
            'Conheça um breve histórico sobre o desenvolvimento e chegada da cultura digital na escola e como inseri-la no processo de alfabetização, o que diz as políticas e diretrizes  para o uso responsável da tecnologia em sala de aula e conheça algumas sugestões de boas práticas. ',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 9,
        time: '2h',
        titleTemp: 'A partir de outubro',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'A Educação Infantil e sua conexão com a alfabetização',
        description:
            'Saiba mais sobre a relação entre Educação Infantil e Alfabetização, suas teorias, práticas e desafios para promover um ambiente propício ao desenvolvimento da linguagem oral e de atividades lúdicas no processo inicial da leitura e escrita.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 10,
        time: '2h',
        titleTemp: 'A partir de novembro',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
    instanciaCardModulo({
        title: 'A importância da formação de professores alfabetizadores',
        description:
            'Neste módulo vamos explorar a relevância do conhecimento pedagógico e do uso de recursos adequados no processo de ensino da alfabetização. Além disso, conheça mais sobre  teorias e estratégias para formação do professor alfabetizador. ',
        status: StatusCardModulo.EMBREVE,
        ordem: 11,
        time: '2h',
        titleTemp: 'A partir de dezembro',
        link: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
    }),
];
