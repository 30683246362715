import Colors from 'enums/Colors';
import styled from 'styled-components';

const AdminBarraLateralStyled = {
    Container: styled.div`
        background-color: white;
        width: 110px;
        height: 100%;
        position: fixed;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 100px;

        transition: 0.3s ease-in-out;

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: ${Colors.DisabledBlue};
        }
    `,

    ContainerItem: styled.div<{ enabled: boolean }>`
        background-color: ${props => (props.enabled ? '#FEF0CC' : 'white')};
        width: 100%;
        height: 70px;
        margin: 15px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        :hover {
            background-color: ${props => (!props.enabled ? '#eee' : '#FEF0CC')};
        }
    `,

    ExitItem: styled.img``,

    IconItem: styled.img``,

    LabelItem: styled.p<{ enabled: boolean }>`
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        text-transform: capitalize;
        color: #183f73;
    `,

    ContenItem: styled.div`
        text-align: center;
    `,

    MarkItem: styled.img`
        height: 100%;

        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(-9px) scaleX(0.8);
    `,

    FixedBottom: styled.div`
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;

        text-align: center;
        padding: 10px;

        cursor: pointer;
    `,
};

export default AdminBarraLateralStyled;
