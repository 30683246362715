import React from 'react';

import styled from 'styled-components';

import Title from 'components/UI/Title';
import Input from 'components/UI/Input';
import { useState, useEffect } from 'react';
import { DefaultTexts } from 'constants/DefaultTexts';
import { MobileMaxWidth } from 'constants/Dimensions';

export interface IDiaryTextTitle {
    title: string;
    editable?: boolean;
    onChange?: (value: string) => void;
    onBlur?: () => void;
}

const DiaryTextTitleContainer = styled.div`
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 0px;
    }
`;

const TitleContainer = styled.div({
    padding: '0 7px',
    maxWidth: 'fit-content',
    backgroundColor: 'white',
    transform: 'translate(15px, 15px)',
});

const DiaryTextTitle = ({ title, editable = true, onChange, onBlur }: IDiaryTextTitle) => {
    const [inputValue, setInputValue] = useState(title);

    useEffect(() => {
        setInputValue(title);
    }, [title]);

    const onTitleTextChangeHandler = (e: any) => {
        const {
            target: { value },
        } = e;
        setInputValue(value);
        if (onChange) onChange(value);
    };

    return (
        <DiaryTextTitleContainer>
            <Input
                value={inputValue}
                readonly={!editable}
                placeholder={DefaultTexts.semTitulo}
                onchange={onTitleTextChangeHandler}
                style={{ padding: '22px 15px' }}
                onblur={() => {
                    if (onBlur) onBlur();
                }}
            />
        </DiaryTextTitleContainer>
    );
};

export default DiaryTextTitle;
