import PaginaIntermediariaModeloSecaoDTO from 'models/generic-components/PaginaIntermediariaModeloSecaoDTO';
import Menu from 'pages/menu/Menu';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SectionSelector from '../sectionSelector/SectionSelector';
import TipoModeloEmBrancoStyled from './TipoModeloEmBranco.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

interface ITipoModeloEmBranco {
    listaSecoes: PaginaIntermediariaModeloSecaoDTO[];
}

const TipoModeloEmBranco: FunctionComponent<ITipoModeloEmBranco> = ({ ...props }: ITipoModeloEmBranco) => {
    const [listsaSecoes, _] = useState<PaginaIntermediariaModeloSecaoDTO[]>(props.listaSecoes);

    return (
        <>
            {/* <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <TipoModeloEmBrancoStyled.Container>
                {listsaSecoes.map((item: PaginaIntermediariaModeloSecaoDTO) => {
                    return (
                        <React.Fragment key={`secao_` + item.codigo}>
                            <br />
                            <SectionSelector secao={item} />
                            <br />
                        </React.Fragment>
                    );
                })}
            </TipoModeloEmBrancoStyled.Container>
        </>
    );
};

export default TipoModeloEmBranco;
