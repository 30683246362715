import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import AdminBiblioItemPreview from './AdminBiblioItemPreview';

class AdminBiblioAvaliacao extends Serializable {
    @jsonName('codigo')
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('itemBiblioteca')
    @jsonProperty(AdminBiblioItemPreview)
    item: AdminBiblioItemPreview = {} as AdminBiblioItemPreview;

    @jsonName('item_id')
    @jsonProperty(Number)
    idItem: number = -1;

    @jsonName('nota')
    @jsonProperty(Number)
    rate: number = -1;

    @jsonName('habilitado')
    @jsonProperty(Boolean)
    enabled: boolean = true;

    @jsonName('nomeUsuario')
    @jsonProperty(String)
    username: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    date: string = '';
}

export default AdminBiblioAvaliacao;
