import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio } from 'react-radio-input';
import { GradientButton, OutlineButton } from 'components/UI/Buttons';
import Styled from './AdminBiblioNew.styled';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import editorConfig from '../../../diarioBordo/components/textEditor/editorConfig';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { isSaveRascunho, isStringsValid } from './isValidNewContentInput';
import AdminBiblioItemSave from 'models/admin/AdminBiblioItemSave';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import submitAttach, { AdminBiblioAnexoType, fecthAttachImage, fetchTagsItemId } from './submitAttach';
import { IAdminBiblioEditorContent } from 'pages/admin/subpages/biblio/post/AdminBiblioNewContent';
import AdminTagSelector from '../tagSelector/AdminTagSelector';
import { StringHelp } from '../../../../core/util/StringHelp';

import { IsMobileMaxWidth } from 'core/util/WindowSize';

import { ToastError } from 'components/Toaster';
import ItemBibliotecaRelatedContentListagem from '../itemBibliotecaRelatedContentList/ItemBibliotecaRelatedContentListagem';

const isMobile = () => IsMobileMaxWidth();

const AdminBiblioNewArtigo = ({ onSaved, itemData, categoriaId, categorias }: IAdminBiblioEditorContent) => {
    const [id, setId] = useState<number>(-1);
    const [slug, setSlug] = useState<string>('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [authors, setAuthors] = useState('');
    const [titleTag, setTitleTag] = useState('');
    const [metaDescriptionTag, setMetaDescriptionTag] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [isPublic, setIsPublic] = useState('false');

    const [imageTop, setImageTop] = useState<File>();
    const [preimageTop, setPreImageTop] = useState<string | null>(null);

    const [imageCover, setImageCover] = useState<File>();
    const [preimageCover, setPreImageCover] = useState<string | null>(null);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isValidInputs = (): boolean => {
        if (!imageCover && !preimageCover) return false;
        if (!imageTop && !preimageTop) return false;
        return isStringsValid([title, content, authors, slug]);
    };

    const categoriaExistente = (): boolean => {
        return categorias.filter(c => c.ativo).some(c => c.id == categoriaId);
    };

    const submit = async (_status: 'PUBLICADO' | 'RASCUNHO') => {
        setIsSubmitting(true);
        const service = new AdminBiblioService();

        const itemSave = new AdminBiblioItemSave();

        itemSave.slug = slug;
        itemSave.titulo = title;
        itemSave.conteudo = content;
        itemSave.tipoItemBiblioteca = categoriaId;
        itemSave.status = _status;
        itemSave.publico = isPublic === 'true';
        itemSave.tags = tags;
        itemSave.autores = authors;
        itemSave.titleTag = titleTag;
        itemSave.metaDescriptionTag = metaDescriptionTag;

        if (itemSave.publico && !categoriaExistente()) {
            alert('Não é possível publicar um item de um categoria inativa');
            setIsSubmitting(false);
            return;
        }

        let data, status: number;

        const responseUnicidade = await service.validarUnicidadeSlug(itemData?.id ?? null, itemSave.slug);
        let slugValido: boolean = false;
        if (responseUnicidade.status === 200 && responseUnicidade.data === true) {
            slugValido = true;
        }

        if (slugValido) {
            if (!itemData) {
                const response = await service.criarItem(itemSave);
                data = response.data;
                status = response.status;
            } else {
                const response = await service.atualizarItem2(itemData.id, itemSave, tags);
                data = response.data;
                status = response.status;
                //onSaved();
            }

            if (imageTop) submitAttach(data.id, imageTop, AdminBiblioAnexoType.HEADER);
            if (imageCover) submitAttach(data.id, imageCover, AdminBiblioAnexoType.CAPA);

            onSaved();
        } else {
            ToastError('URL Slug já cadastrado para outro conteúdo');
        }

        setIsSubmitting(false);
    };

    const fillData = async () => {
        //TODO: trazer isPublic
        if (itemData != null) {
            setSlug(itemData.slug);
            setId(itemData.id);
            setContent(itemData.content ?? '');
            setTitle(itemData.title);
            setAuthors(itemData.authors);
            setTitleTag(itemData.titleTag);
            setMetaDescriptionTag(itemData.metaDescriptionTag);
            setIsPublic('false');

            const _imageCover = await fecthAttachImage(itemData.id, AdminBiblioAnexoType.CAPA);
            const _imageTop = await fecthAttachImage(itemData.id, AdminBiblioAnexoType.HEADER);

            if (_imageTop) setPreImageTop(_imageTop);
            if (_imageCover) setPreImageCover(_imageCover);

            const tags = await fetchTagsItemId(itemData.id);
            setTags(tags);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    return (
        <Styled.Container>
            <Styled.Row>
                <Styled.FormContainerFixedWidth>
                    <AdminImageUploader
                        onSelect={(_, f) => setImageTop(f)}
                        size={'lg'}
                        title={'Imagem do topo'}
                        preImageB64={preimageTop}
                        recommendedDimension={{ w: 980, h: 220 }}
                    />
                    <br />
                    {isMobile() && (
                        <Styled.ImageContainer>
                            <AdminImageUploader
                                onSelect={(_, f) => setImageCover(f)}
                                size={'sm'}
                                title={'Imagem da capa'}
                                preImageB64={preimageCover}
                                recommendedDimension={{ w: 200, h: 130 }}
                            />
                        </Styled.ImageContainer>
                    )}
                    <br />
                    <Styled.Label>Url Slug</Styled.Label>
                    <Styled.Input
                        value={slug}
                        onChange={(e: any) => setSlug(StringHelp.alnum(StringHelp.toSize(e.target.value.toLowerCase().trim(), 150)))}
                    />
                    <br />
                    <Styled.Label>Título</Styled.Label>
                    <Styled.Input value={title} onChange={(e: any) => setTitle(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label>Autores</Styled.Label>
                    <Styled.Input value={authors} onChange={(e: any) => setAuthors(StringHelp.toSize(e.target.value, 97))} />
                    <br />
                    <Styled.Label style={{ padding: '7px 0' }}>Conteúdo</Styled.Label>
                    <ReactQuill
                        theme="snow"
                        modules={editorConfig.modules}
                        formats={editorConfig.formats}
                        value={content}
                        onChange={val => {
                            setContent(val);
                        }}
                    />
                    <br />
                    <AdminTagSelector tags={tags} onSelected={(newTags: string[]) => setTags(newTags)} />
                    <br />
                    <Styled.Label>Título da Página</Styled.Label>
                    <Styled.Input value={titleTag} onChange={(e: any) => setTitleTag(StringHelp.toSize(e.target.value, 150))} />
                    <br />
                    <Styled.Label>Meta Description</Styled.Label>
                    <Styled.Input
                        value={metaDescriptionTag}
                        onChange={(e: any) => setMetaDescriptionTag(StringHelp.toSize(e.target.value, 500))}
                    />
                    <br />
                    <Styled.Label>Tipo de visualização</Styled.Label>
                    <Styled.CkeckContainer style={{ paddingTop: '12px' }}>
                        <RadioGroup name={'isPublic'} onChange={setIsPublic} selectedValue={'false'}>
                            <label htmlFor="isPublic">
                                <Radio id="isPublic" value={'true'} />
                                <Styled.TextCheckBox>Público</Styled.TextCheckBox>
                            </label>

                            <label htmlFor="isPrivate">
                                <Radio id="isPrivate" value={'false'} />
                                <Styled.TextCheckBox>Privado</Styled.TextCheckBox>
                            </label>
                        </RadioGroup>
                    </Styled.CkeckContainer>
                    <br />
                    <ItemBibliotecaRelatedContentListagem itemData={itemData} />
                    <br />
                    <Styled.BottomRight>
                        {isSaveRascunho(itemData) && (
                            <OutlineButton disabled={!isValidInputs() || isSubmitting} onClick={() => submit('RASCUNHO')}>
                                {isSubmitting ? 'Salvando...' : 'Salvar Rascunho'}
                            </OutlineButton>
                        )}

                        <GradientButton
                            disabled={!isValidInputs() || isSubmitting}
                            onClick={() => submit('PUBLICADO')}
                            style={{ marginLeft: '10px', marginTop: '10px' }}
                        >
                            {isSubmitting ? 'Salvando...' : 'Publicar'}
                        </GradientButton>
                    </Styled.BottomRight>
                </Styled.FormContainerFixedWidth>

                {!isMobile() && (
                    <Styled.ImageContainer>
                        <AdminImageUploader
                            onSelect={(_, f) => setImageCover(f)}
                            size={'sm'}
                            title={'Imagem da capa'}
                            preImageB64={preimageCover}
                            recommendedDimension={{ w: 200, h: 130 }}
                        />
                    </Styled.ImageContainer>
                )}
            </Styled.Row>
        </Styled.Container>
    );
};

export default AdminBiblioNewArtigo;
