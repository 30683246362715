import React, { useRef } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import SliderItem from './SliderItem';
import sliderData from './SliderData';

import arrowLeft from '../../../../../images/painelSocio/arrow-slide-L.svg';
import { useContext } from 'react';
import { PainelSocioContext } from '../../context/PainelSocioContext';
import { useEffect } from 'react';
import VideoExplicativo from '../VideoExplicativo/VideoExplicativo';
import { IsMobileBiggerWidth, IsMobileMaxWidth } from 'core/util/WindowSize';

export interface ISlider {}

const CustomArrowLeft = (props: any) => {
    const { className, style, onClick } = props;
    return <img className={className} style={{ ...style }} onClick={onClick} src={arrowLeft} />;
};

const CustomArrowRight = (props: any) => {
    const { className, style, onClick } = props;
    return <img className={className} style={{ ...style, transform: 'scale(-1, 1)' }} onClick={onClick} src={arrowLeft} />;
};

const SliderComp = ({}: ISlider) => {
    const slider = useRef<Slider>(null);
    const { currentIndexSlide, updateCurrentIndexSlide } = useContext(PainelSocioContext.Context);

    const settings: SliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current: number) => {
            updateCurrentIndexSlide(current);
        },
        prevArrow: <CustomArrowLeft />,
        nextArrow: <CustomArrowRight />,
    };

    useEffect(() => {
        if (slider?.current) {
            slider.current.slickGoTo(currentIndexSlide);
        }
    }, [currentIndexSlide]);

    const showVideo = (): boolean => {
        return IsMobileBiggerWidth();
    };

    return (
        <Slider ref={slider} {...settings}>
            {!showVideo() ? <VideoExplicativo /> : false}
            {sliderData.map((item, idx) => {
                return <SliderItem key={idx} index={item.index} image={item.image} content={item.content} />;
            })}
        </Slider>
    );
};

export default SliderComp;
