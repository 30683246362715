export type SubHeaderCategoriaType = {
    label: string;
    route: string;
};

export const SubHeaderCategoriaData = {
    destaque: {
        label: 'Destaques',
        route: 'categoria/destaque',
    } as SubHeaderCategoriaType,

    artigos: {
        label: 'Artigos',
        route: 'categoria/artigos',
    } as SubHeaderCategoriaType,

    guias: {
        label: 'Guias Temáticos',
        route: 'categoria/guias',
    } as SubHeaderCategoriaType,

    livros: {
        label: 'Livros',
        route: 'categoria/livros',
    } as SubHeaderCategoriaType,

    estudo: {
        label: 'Estudos',
        route: 'categoria/estudo',
    } as SubHeaderCategoriaType,

    videos: {
        label: 'Vídeos',
        route: 'categoria/videos',
    } as SubHeaderCategoriaType,

    favoritos: {
        label: 'Favoritos',
        route: 'meu-perfil/favoritos',
    } as SubHeaderCategoriaType,

    avaliados: {
        label: 'Avaliados',
        route: 'meu-perfil/avaliados',
    } as SubHeaderCategoriaType,
};

/* Conteúdo removido por solicitação do IAS para subida em produção
audios: {
        label: 'Áudios',
        route: 'categoria/audios',
    } as SubHeaderCategoriaType,
 atividades: {
        label: 'Atividades',
        route: 'categoria/atividades',
    } as SubHeaderCategoriaType,
*/

export default SubHeaderCategoriaData;
