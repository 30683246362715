import { GradientButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledModalExclusao = {
    Modal: {
        Title: styled.p`
            font-size: 30px;
            font-weight: 800;
            color: ${Colors.InfoRed};
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
        `,

        SubTitle: styled.p`
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
            color: ${Colors.BlueGrey};
            margin: 10px 0;
        `,

        Text: styled.div`
            font-size: 16px;
            line-height: 19px;
            color: #2e2e2e;
            overflow-wrap: break-word;
            margin: 10px 0;
        `,

        ButtonArea: styled.div`
            display: flex;
            justify-content: space-between;
            text-align: right;
            margin-top: 10px;
        `,
    },
};

export default StyledModalExclusao;
