import { Serializable, jsonProperty } from 'ts-serializable';
import TipoDeficienciaDTO from './TipoDeficienciaDTO';

class PerfilTipoDeficienciaDTO extends Serializable {
    @jsonProperty()
    codigoPerfil: number = -1;

    @jsonProperty([TipoDeficienciaDTO])
    deficiencias: TipoDeficienciaDTO[] = [];

    constructor() {
        super();
    }
}

export default PerfilTipoDeficienciaDTO;
