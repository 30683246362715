import { GenericService } from '../Generic.service';

export class CategoriaPEService extends GenericService {
    readonly URI: string = '/v1/categoriaPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public inserir(nome: string) {
        return this.post('', { nome: nome });
    }

    public listAll(){
        return this.get('listAll');
    }
}
