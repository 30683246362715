import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

export enum KcAdminGroups {
    MASTER = '/GROUP_ADMIN_MASTER',
    GESTAO_CONTEUDO = '/GROUP_ADMIN_CONTEUDO',
    GESTAO_USUARIO = '/GROUP_ADMIN_USUARIO',
}

class KcUserInfo extends Serializable {
    @jsonProperty([String], [])
    groups: KcAdminGroups[] | [] = [];

    constructor() {
        super();
    }
}

export default KcUserInfo;
