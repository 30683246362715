import React, { FunctionComponent, useContext, useState } from 'react';

import { useHistory } from 'react-router';

import Steps from './UI/steps/Steps';
import Header from './UI/header/Header';
import stepData from './UI/steps/StepData';
import DialogFinal from './UI/dialog/DialogFinal';
import DialogInicio from './UI/dialog/DialogInicio';
import { IsMobileMaxWidth } from '../../core/util/WindowSize';
import {
    PlanoDesenvolvimentoPessoalStyle,
    Separator,
    Container,
    HeaderContainer,
    SharingContainer,
    OptionsContainer,
    Text,
    ImgIconSvg,
} from './PlanoDesenvolvimentoPessoal.styled';
import useFetch from 'hooks/useFetch';
import { useDispatch } from 'react-redux';
import * as pdpActions from 'store/actions/Pdp';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PDP from 'models/pdp/PDP';
import { useEffect } from 'react';
import Visualizacao from './components/Visualizacao';
import { PdpContext } from './context/PdpContext';
import PdpProvider from './context/PdpProvider';
import { values } from 'lodash';
import PrintIcon from '../pdp/components/assets/images/icon-printer.svg';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

interface IPlanoDesenvolvimentoPessoal {}
const PlanoDesenvolvimentoPessoal: FunctionComponent<IPlanoDesenvolvimentoPessoal> = props => {
    const [dialogInicioVisible, setDialogInicioVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const pdp: PDP | null = useAppSelector(state => state.pdpReducer.pdp);
    const pdpOriginal: PDP | null = useAppSelector(state => state.pdpReducer.pdp);
    const currentStep: number = useAppSelector(state => state.pdpReducer.currentStepForm);

    const GoToPainelSocioemocinal = () => {
        history.push('/painelSocioemocional/home');
    };

    useFetch(
        async () => {
            await dispatch(pdpActions.consultarUltimoPdpPorPerfil(userProfile?.codigo ?? -1));
        },
        [],
        setLoading
    );

    useEffect(() => {
        if (pdp && pdp.id > 0) {
            setDialogInicioVisible(false);
        }
    }, [pdp]);

    const Content = () => {
        const { operacaoAlteracao } = useContext(PdpContext.Context);

        return (
            <Container>
                {!(pdp.id > 0) || operacaoAlteracao ? (
                    <Steps stepsInit={stepData} orientation={IsMobile() ? 'horizontal' : 'vertical'} />
                ) : (
                    <Visualizacao onButtonClick={() => setDialogInicioVisible(true)} />
                )}
            </Container>
        );
    };

    return (
        <PdpProvider>
            <PlanoDesenvolvimentoPessoalStyle>
                <HeaderContainer>
                    <Header
                        editIcon={pdp.id > 0}
                        title={`Registro do Plano de Desenvolvimento Pessoal (PDP)`}
                        subtitle={`Faça seu plano de desenvolvimento Pessoal para te ajudar a desenvolver suas habilidades`}
                        pageHeader
                    />

                    {currentStep == 5 && (
                        <SharingContainer>
                            <Text>{`Imprimir PDF`}</Text>
                            <OptionsContainer>
                                <ImgIconSvg
                                    data-tip={'Baixar PDF'}
                                    src={PrintIcon}
                                    onClick={() => {
                                        GTM_Page_Jornada_Desenvolvimento('painel_pdp', 'imprimir_pdf_btn');
                                        window.print();
                                    }}
                                />
                            </OptionsContainer>
                        </SharingContainer>
                    )}
                </HeaderContainer>

                <Separator />

                <Content />

                <DialogInicio
                    visible={dialogInicioVisible}
                    onBeginCallback={() => {
                        setDialogInicioVisible(false);
                    }}
                    onCloseCallback={() => {
                        setDialogInicioVisible(false);
                        GoToPainelSocioemocinal();
                    }}
                />
            </PlanoDesenvolvimentoPessoalStyle>
        </PdpProvider>
    );
};

export default PlanoDesenvolvimentoPessoal;
