import React, { FunctionComponent, useContext, useRef, useState } from 'react';

import { BsTrash } from 'react-icons/bs';
import { IoMdCopy } from 'react-icons/io';
import { HiDotsVertical } from 'react-icons/hi';
import { CSSProperties } from 'styled-components';

import Colors from 'enums/Colors';
import Point from 'core/util/Point';
import Swipe from 'components/swipe/Swipe';
import MiniPop from '../../miniPop/MiniPop';
import { DateHelp } from 'core/util/DateHelp';
import { DiaryCardStyle } from './Card.styled';
import { StringHelp } from 'core/util/StringHelp';
import DiarioTag from 'models/diarioBordo/DiarioTag';
import { DefaultTexts } from 'constants/DefaultTexts';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import DiarioTagButton from '../../moreInfo/tag/DiarioTagButton';
import { DiarioBordoContext } from '../../../context/DiarioContext';
import { FiTrash2 } from 'react-icons/fi';
import { RiEditBoxLine } from 'react-icons/ri';
import moment from 'moment';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

const icon: CSSProperties = {
    fontSize: 22,
};
interface ICard {
    data: DiarioBordo;
    toOpen?: () => void;
    toEdit?: () => void;
    toDelete?: (data: any) => void;
}

const Card: FunctionComponent<ICard> = ({ data, toOpen, toEdit, toDelete }: React.PropsWithChildren<ICard>) => {
    const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { onDelete, onSelect, current, setShowableCurrent, showableCurrent, setOnEditting } = useContext(DiarioBordoContext.Context);

    const _this = useRef<HTMLDivElement>(null);

    const isMobile: boolean = IsMobileMaxWidth();

    let tag: DiarioTag | null = null;

    if (data.tag) {
        tag = new DiarioTag();
        tag.id = 1;
        tag.descricao = data.tag;
    }

    const outsideHandler = () => {
        setIsPopOpen(false);
    };

    const onExcludeHandler = async () => {
        await onDelete(data);
        outsideHandler();
    };

    const onSelectHandler = async () => {
        setOnEditting(false);
        setShowableCurrent(true);
        await onSelect(data);
        outsideHandler();
    };

    const onEditHandler = async () => {
        setShowableCurrent(true);
        await onSelect(data);
        setOnEditting(true);
    };

    const onSelectHandlerMobile = async () => {
        await onSelect(data);
        outsideHandler();
        toOpen && toOpen();
    };

    if (_this.current && current.id === data.id) {
        _this.current.scrollIntoView({
            behavior: 'auto',
        });
    }

    const title: string = StringHelp.toSize(data.titulo ?? DefaultTexts.semTitulo, 50);
    const editedText: string = data.edicao ? DateHelp.formatDate(data.edicao) : moment(data.criacao).format('DD/MM/YYYY');

    return (
        <DiaryCardStyle.Column>
            <DiaryCardStyle.ContainerCard
                onClick={() => {
                    isMobile ? setShowMenu(!showMenu) : onSelectHandler();
                }}
                selected={current.id === data.id && !isMobile && showableCurrent}
            >
                <DiaryCardStyle.TitleContainerCard>
                    <DiaryCardStyle.TitleCard>{title}</DiaryCardStyle.TitleCard>
                    <DiaryCardStyle.SubTitleCard selected={current.id === data.id && !isMobile && showableCurrent}>
                        {editedText}
                    </DiaryCardStyle.SubTitleCard>
                </DiaryCardStyle.TitleContainerCard>

                <DiaryCardStyle.ButtonContainerCard>
                    <DiaryCardStyle.IconCard className="desk-only">
                        <RiEditBoxLine
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'editar_btn');
                                onEditHandler();
                            }}
                        />
                    </DiaryCardStyle.IconCard>

                    {!data.isFixed && (
                        <DiaryCardStyle.IconCard className="desk-only">
                            <FiTrash2
                                onClick={() => {
                                    GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'excluir_btn');
                                    onExcludeHandler();
                                }}
                            />
                        </DiaryCardStyle.IconCard>
                    )}

                    <AiOutlinePlusSquare
                        className="mobile-only"
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'select_btn');
                            onSelectHandler();
                        }}
                    />
                </DiaryCardStyle.ButtonContainerCard>
            </DiaryCardStyle.ContainerCard>

            {showMenu && (
                <DiaryCardStyle.MenuContainer>
                    <DiaryCardStyle.ButtonAction
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'abrir_mobile_btn');
                            onSelectHandlerMobile();
                        }}
                    >
                        Abrir
                    </DiaryCardStyle.ButtonAction>
                    <DiaryCardStyle.ButtonAction
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'editar_mobile_btn');
                            toEdit?.();
                        }}
                    >
                        Editar
                    </DiaryCardStyle.ButtonAction>
                    {!data.isFixed && (
                        <DiaryCardStyle.ButtonAction
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'deletar_mobile_btn');
                                toDelete && toDelete(data);
                            }}
                            style={{ backgroundColor: '#D93C3C' }}
                        >
                            Deletar
                        </DiaryCardStyle.ButtonAction>
                    )}
                </DiaryCardStyle.MenuContainer>
            )}
        </DiaryCardStyle.Column>
    );
};

export default Card;
