import React from 'react';
import Colors from 'enums/Colors';
import styled from 'styled-components';
import { IModuloPage } from '../AlfaTeoriaPratica';

export enum TypeItemSummary {
    TYPE_01 = 'TYPE_01',
    TYPE_02 = 'TYPE_02',
}

export enum TitleSummarySize {
    TYPE_01 = 'TYPE_01',
    TYPE_02 = 'TYPE_02',
    TYPE_03 = 'TYPE_03',
}

const Styled = {
    Container: styled.div`
        display: flex;
        align-items: end;
        flex-direction: column;
        justify-content: center;
        padding: 100px;
        background-color: ${Colors.Blue};
        color: ${Colors.White};
        height: 100%;
        font-family: 'Barlow';
        margin-bottom: 10px;

        @media screen and (max-width: 800px) {
            padding: 0;
        }
    `,

    Text: styled.h1<{ type: TitleSummarySize }>`
        font-size: ${({ type }) => (type === TitleSummarySize.TYPE_01 ? '24px' : type === TitleSummarySize.TYPE_02 ? '18px' : '')};
        font-weight: 700;
        color: ${({ type }) =>
            type === TitleSummarySize.TYPE_01 ? Colors.White : type === TitleSummarySize.TYPE_02 ? Colors.Yellow : Colors.White};
        font-style: ${({ type }) => (type === TitleSummarySize.TYPE_02 ? 'italic' : '')};
    `,

    Titulo: styled.p`
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        margin-bottom: 44px;
    `,

    Description: styled.p`
        font-style: italic;
        font-size: 20px;
        line-height: 24px;
    `,

    Divider: styled.div`
        margin-bottom: 20px;
        height: 20px;
        width: 200px;
        background-color: ${Colors.Yellow};
    `,

    Item: {
        Container: styled.div`
            margin-top: 28px;
            display: flex;
            gap: 0.5rem;
            flex-direction: column;
            cursor: pointer;
        `,

        Column: styled.div`
            display: flex;
            gap: 1rem;
            flex-direction: column;
        `,

        Row: styled.div`
            display: flex;
            gap: 1rem;
        `,
    },
};

interface IItemSummary extends IModuloPage {
    dados: CapituloEbook;
}

const ItemSummary = ({ dados, scrollToDivLink, modulos }: IItemSummary) => {
    const handleScrollToDiv = () => {
        if (scrollToDivLink) {
            if (dados.indexModulo === 103) {
                scrollToDivLink(modulos[0], dados.indexModulo);
            } else {
                scrollToDivLink(modulos[dados.indexModulo], dados.indexModulo);
            }
        }
    };

    return (
        <Styled.Item.Container onClick={handleScrollToDiv}>
            {dados.type === TypeItemSummary.TYPE_01 ? (
                <>
                    <Styled.Item.Row style={{ justifyContent: 'end', alignItems: 'center' }}>
                        <Styled.Text type={TitleSummarySize.TYPE_01}>{dados.title.toLocaleUpperCase()}</Styled.Text>
                        <Styled.Text style={{ fontSize: 48, fontStyle: 'initial' }} type={TitleSummarySize.TYPE_02}>
                            {dados.moduloNumber.toLocaleUpperCase()}
                        </Styled.Text>
                    </Styled.Item.Row>
                    <Styled.Item.Column style={{ marginRight: 69 }}>
                        <Styled.Text type={TitleSummarySize.TYPE_02} style={{ textAlign: 'end' }}>
                            {dados.subTitle}
                        </Styled.Text>
                        <Styled.Text
                            style={{ textAlign: 'end', lineHeight: '24px' }}
                            type={TitleSummarySize.TYPE_03}
                            dangerouslySetInnerHTML={{ __html: dados.description }}
                        />
                    </Styled.Item.Column>
                </>
            ) : (
                <>
                    <Styled.Item.Row>
                        <Styled.Item.Column>
                            <Styled.Text type={TitleSummarySize.TYPE_02}>{dados.subTitle}</Styled.Text>
                            <Styled.Text type={TitleSummarySize.TYPE_03}>{dados.description}</Styled.Text>
                        </Styled.Item.Column>

                        <Styled.Item.Row>
                            <Styled.Text type={TitleSummarySize.TYPE_01}>{dados.title.toLocaleUpperCase()}</Styled.Text>
                            <Styled.Text style={{ fontSize: 48, fontStyle: 'initial' }} type={TitleSummarySize.TYPE_02}>
                                {dados.moduloNumber.toLocaleUpperCase()}
                            </Styled.Text>
                        </Styled.Item.Row>
                    </Styled.Item.Row>
                </>
            )}
        </Styled.Item.Container>
    );
};

export type CapituloEbook = {
    title: string;
    subTitle: string;
    moduloNumber: string;
    description: string;
    indexModulo: number;
    type: TypeItemSummary;
};

export interface ISummaryEbook extends IModuloPage {
    capitulos: CapituloEbook[];
}

const SummaryEbook = ({ capitulos, scrollToDivLink, modulos }: ISummaryEbook) => {
    return (
        <Styled.Container>
            <Styled.Divider />
            <Styled.Titulo>SUMÁRIO</Styled.Titulo>
            {capitulos.length > 0 &&
                capitulos.map((cap, index) => (
                    <ItemSummary key={index} dados={cap} modulos={modulos} setImgAmpliar={() => ''} scrollToDivLink={scrollToDivLink} />
                ))}
        </Styled.Container>
    );
};

export default SummaryEbook;
