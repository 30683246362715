import React, { useCallback, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';
import AdminRateStar from 'pages/admin/components/RateStars/AdminRateStar';
import eyeIcon from '../../../../../images/admin/eye.svg';

import AdminBiblioAvaliacaoModel from '../../../../../models/admin/AdminBiblioAvaliacao';
import capitalize from 'pages/admin/utils/capitalize';
import { DateHelp } from 'core/util/DateHelp';
import AdminModalBiblioItem from './AdminModalBiblioItem';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IAdminBiblioGridRate {
    itens: AdminBiblioAvaliacaoModel[];
}

const AdminBiblioGridRate = ({ itens }: IAdminBiblioGridRate) => {


    const [showIdItemModal, setShowIdItemModal] = useState(-1);

    return (
        <React.Fragment>
            <AdminModalBiblioItem
                show={showIdItemModal != -1}
                onHide={() => setShowIdItemModal(-1)}
                rate={itens.find(i => i.id == showIdItemModal)}
            />

            {itens.map((rate, idx) => (
                <React.Fragment key={idx}>
                    <Styled.GridCol>{capitalize(rate.item.category)}</Styled.GridCol>
                    <Styled.GridCol>{rate.item.title}</Styled.GridCol>
                    <Styled.GridCol>{DateHelp.formatDateFull(rate.date)}</Styled.GridCol>
                    <Styled.GridCol>{rate.username}</Styled.GridCol>
                    <Styled.GridCol>
                        <AdminRateStar rate={rate.rate} />
                    </Styled.GridCol>
                    <Styled.GridCol>
                        <OverlayTrigger overlay={<Tooltip id={'tooltip-action-view_' + rate.id}>Visualizar</Tooltip>} placement={'top'}>
                            <span>
                                <Styled.ViewButton style={{ marginRight: '5px' }} src={eyeIcon} onClick={() => setShowIdItemModal(rate.id)} />
                            </span>
                        </OverlayTrigger>
                    </Styled.GridCol>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default AdminBiblioGridRate;
