import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSupportMaterial = {
    Container: styled.div`
        display: flex;
        padding-bottom: 40px;
        justify-content: space-between;
        min-height: 550px;
        gap: 0.5rem;

        @media screen and (max-width: 940px) {
            height: auto;
            flex-direction: column;
        }
        @media screen and (max-width: 600px) {
            padding-left: 20px;
        }
    `,

    Title: styled.h1`
        font-size: 56px;
        line-height: 61px;
        font-weight: 800;
        padding-bottom: 24px;
    `,

    Description: styled.p`
        font-size: 20px;
        line-height: 36px;
        max-width: 80%;
        font-weight: 400;
        margin-bottom: 24px;
    `,

    Content: styled.div<{ column?: boolean }>`
        padding-top: 70px;
        padding-left: 70px;

        display: flex;
        flex-wrap: wrap;
        flex-direction: ${({ column }) => (column ? 'column' : 'row')};
        width: 50%;
        gap: 0.5rem;
        margin-bottom: 70px;

        @media screen and (max-width: 940px) {
            width: 100%;
        }
    `,

    Img: styled.img<{ isBorderBlue?: boolean }>`
        width: 250px;
        height: 370px;
        border-radius: 30px;
        object-fit: cover;
        border: ${({ isBorderBlue }) => (isBorderBlue ? `1px solid ${Colors.Blue}` : '')};
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    `,

    Carrousel: styled.div`
        display: flex;
        margin: 40px;
    `,

    CarouselCardContainer: styled.div`
        width: 100%;
        padding: 10px;

        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-dots {
            li {
                button::before {
                    color: #4781ae; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: #b1e9ff; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        .slick-arrow {
            background-color: ${Colors.Blue};
            border-radius: 100%;
        }
    `,
};

export default StyledSupportMaterial;
