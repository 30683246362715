import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
    Section,
    AnswerText,
    FlexContainer,
    ProfileSection,
    ProfileInfoCard,
    ProfileInfoText,
    GoToTopContainer,
    SelectedMacroCard,
    VisualizacaoContainer,
    FlexColumnOnlyContainer,
    Separator,
    CentralSection,
} from './Visualizacao.styled';
import PDP from 'models/pdp/PDP';
import Header from '../UI/header/Header';
import OnError from 'core/onError/OnError';
import { Label } from 'components/UI/Texts';
import GoToTop from 'components/UI/GotoTop';
import * as pdpActions from 'store/actions/Pdp';
import DialogFinal from '../UI/dialog/DialogFinal';
import { DestaqueText } from 'components/UI/Texts';
import { PrimaryButton } from 'components/UI/Buttons';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import * as profileActions from 'store/actions/Profile';
import Macrocompetencia from 'models/pdp/Macrocompetencia';
import VisualizacaoRegistroMacro from './VisualizacaoRegistroMacro';
import RegistroMacrocompetencia from 'models/pdp/RegistroMacrocompetencia';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';
import DialogFeedback from '../../../components/feedbackDialog/DialogFeedback';
import { TipoFuncionalidade } from 'models/Feedback';
import { lmsIntegrationUrlSocioemocional } from '../../../constants/EnvironmentData';
import { IntegracaoLmsService } from '../../../core/http/service/IntegracaoLMS.service';
import UsuarioDTO from 'models/UsuarioDTO';
import { PdpContext } from '../context/PdpContext';
import Loading from 'components/Loading';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import { ToasterDefault, ToastSuccess } from 'components/Toaster';

interface IVisualizacao {
    onButtonClick?: () => void;
}

const Visualizacao: FunctionComponent<IVisualizacao> = ({ onButtonClick, ...props }: IVisualizacao) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dialogFeedbackVisible, setDialogFeedbackVisible] = useState<boolean>(false);

    const pdp: PDP = useAppSelector(state => state.pdpReducer.pdp);
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const currentStep: number = useAppSelector(state => state.pdpReducer.currentStepForm);

    const dispatch = useDispatch();
    const history = useHistory();
    const { keycloak } = useKeycloak();

    const { operacaoAlteracao } = useContext(PdpContext.Context);

    const integracaoLmsService = new IntegracaoLmsService();

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
    }, []);

    const loadData = async () => {
        if (!userProfile) {
            await dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));
        }
        setIsLoading(false);
    };

    const onComplete = () => {
        ToastSuccess('Seu PDP foi alterado com sucesso');
    };

    interface IProfileItem {
        label: string;
        value: string;
    }
    const ProfileItem = ({ label, value }: IProfileItem) => {
        return (
            <ProfileInfoCard>
                <Label>{label}</Label>
                <ProfileInfoText>{value}</ProfileInfoText>
            </ProfileInfoCard>
        );
    };

    const OnSubmitHandler = async () => {
        setIsLoading(true);
        window.scrollTo(0, 0);
        if (operacaoAlteracao) {
            await alterarPdp();
        } else {
            await cadastrarPdp();
        }
    };

    const alterarPdp = async () => {
        try {
            await dispatch(pdpActions.alterarPDP(pdp));
            onComplete();
        } catch (err: any) {
            OnError.sendToAnalytics('alterarPdp', String(err));
        } finally {
            setTimeout(() => {
                setIsLoading(false);
                history.push('/painelSocioemocional/home');
            }, 3000);
        }
    };

    const cadastrarPdp = async () => {
        if (userProfile) {
            try {
                pdp.codigoPerfil = userProfile.codigo;
                await dispatch(pdpActions.cadastrarPDP(pdp));
            } catch (error) {
                OnError.sendToAnalytics('', String(error));
            } finally {
                setIsLoading(false);
                if (onButtonClick) {
                    onButtonClick();
                }
            }

            setDialogFeedbackVisible(true);
        }
    };

    const dialogFeedbackHide = () => {
        setDialogFeedbackVisible(false);
        history.push('/painelSocioemocional/home');
    };

    const gotoJornada = async () => {
        await redirectToJornada();
    };

    const redirectToJornada = async () => {
        window.open(lmsIntegrationUrlSocioemocional(), '_blank');
        history.push('/painelSocioemocional/home');
    };

    return (
        <VisualizacaoContainer>
            {isLoading ? (
                <CentralSection>
                    <Loading />
                </CentralSection>
            ) : (
                <>
                    <Header title={`Veja seu Plano de Desenvolvimento Pessoal (PDP)`} />
                    {userProfile && (
                        <ProfileSection>
                            <ProfileItem label="Nome" value={userProfile.apelido} />
                            <ProfileItem label="Local" value={userProfile.cidade + ` (` + userProfile.estado + `)`} />
                            <ProfileItem label="Ensino" value={userProfile.categoriaEnsino.descricao} />
                            <ProfileItem label="Rede" value={userProfile.tipoEnsino.descricao} />
                        </ProfileSection>
                    )}

                    <Section>
                        <Label>Macrocompetências que se destaca</Label>
                        <FlexContainer>
                            {pdp.listaMacrocompetenciasDestaque.map((item: Macrocompetencia, idx: number) => {
                                return (
                                    <SelectedMacroCard key={`pdp_visualizacao_lista_macro_destaque_${idx}`}>{item.descricao}</SelectedMacroCard>
                                );
                            })}
                        </FlexContainer>
                    </Section>

                    <Section>
                        <Label>Macrocompetência que mais desenvolveu nos últimos anos</Label>
                        <FlexContainer>
                            <SelectedMacroCard>{pdp.macrocompetenciaMaisDesenvolvida?.descricao}</SelectedMacroCard>
                        </FlexContainer>
                    </Section>

                    <Section>
                        <FlexContainer>
                            <FlexColumnOnlyContainer>
                                <Label>Como você fez para avançar e o que aprendeu com isso? </Label>
                                <AnswerText>&quot;{pdp.formaDesenvolvimentoMacrocompetencia}&quot;</AnswerText>
                            </FlexColumnOnlyContainer>

                            <FlexColumnOnlyContainer>
                                <Label>O que considerou mais difícil nesse processo e como superou? </Label>
                                <AnswerText>&quot;{pdp.dificuldadesMacrocompetencia}&quot;</AnswerText>
                            </FlexColumnOnlyContainer>
                        </FlexContainer>
                    </Section>

                    <Section>
                        <Label>Macrocompetências que quer desenvolver</Label>
                        <FlexContainer>
                            {pdp.listaMacrocompetencias.map((item: RegistroMacrocompetencia, idx: number) => {
                                return (
                                    <SelectedMacroCard key={`pdp_visualizacao_lista_macro_selected_${idx}`}>
                                        {item.macrocompetencia.descricao}
                                    </SelectedMacroCard>
                                );
                            })}
                        </FlexContainer>
                    </Section>

                    <Section>
                        <FlexContainer>
                            {pdp.listaMacrocompetencias.map((item: RegistroMacrocompetencia, idx: number) => {
                                return (
                                    <FlexColumnOnlyContainer key={`pdp_visualizacao_lista_macro_flex_${idx}`}>
                                        <Label>
                                            Motivo de desenvolver <DestaqueText>{item.macrocompetencia.descricao}</DestaqueText>
                                        </Label>
                                        <AnswerText>&quot;{item.motivoDesenvolvimento}&quot;</AnswerText>
                                    </FlexColumnOnlyContainer>
                                );
                            })}
                        </FlexContainer>
                    </Section>

                    <Section>
                        {pdp.listaMacrocompetencias.map((item: RegistroMacrocompetencia, idx: number) => {
                            return (
                                <React.Fragment key={`lista_macro_fragment_${idx}`}>
                                    <VisualizacaoRegistroMacro currentStep={currentStep} registro={item} number={idx} />

                                    <FlexContainer>
                                        <FlexColumnOnlyContainer>
                                            <Label>
                                                Quem o inspira para conseguir atingir as <DestaqueText>metas</DestaqueText>
                                            </Label>
                                        </FlexColumnOnlyContainer>

                                        <FlexColumnOnlyContainer>
                                            <AnswerText>&quot;{item.quemOInspira}&quot;</AnswerText>
                                        </FlexColumnOnlyContainer>
                                    </FlexContainer>

                                    <Separator style={idx != 0 ? { display: 'none' } : {}} />
                                </React.Fragment>
                            );
                        })}
                    </Section>

                    <Section>
                        {(!(pdp.id > 0) || operacaoAlteracao) && (
                            <PrimaryButton
                                disabled={isLoading}
                                onClick={() => {
                                    GTM_Page_Jornada_Desenvolvimento('painel_pdp_visualizacao', 'salvar_finalizar_btn');
                                    OnSubmitHandler();
                                }}
                            >
                                {operacaoAlteracao ? `Salvar edição` : `Finalizar`}
                            </PrimaryButton>
                        )}

                        <GoToTopContainer>
                            <GoToTop />
                        </GoToTopContainer>
                    </Section>

                    <DialogFeedback
                        show={dialogFeedbackVisible}
                        funcionalidade={TipoFuncionalidade.PDP}
                        nomeFucionalidade={'PDP'}
                        onSubmit={dialogFeedbackHide}
                        onHide={dialogFeedbackHide}
                    />
                </>
            )}

            {ToasterDefault()}
        </VisualizacaoContainer>
    );
};

export default Visualizacao;
