import React, { useEffect, useState } from 'react';
import Styled from './MultiSolucoes.styled';
import Slider from 'react-slick';
import CartaoMultiSolucoes, { TypeCard } from './CartaoMultiSolucoes';
import { CarouselCardContainer } from 'pages/generic-components/carousel/components/carouselCard/CarouselCard.styled';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import styled from 'styled-components';
import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';

// Logos
import logoC360 from '../../assets/circuito360/logo_circuito_360.svg';
import logoGNOTA10 from '../../assets/gestaoNota10/logo.jpg';
import logoSeliga from '../../assets/seLiga/logo.png';
import logoAcelera from '../../assets/aceleraBrasil/logo.png';
import logoFormula from '../../assets/formulaVitoria/logo.jpg';
import logoSocioemocional from '../../assets/dialogoSocioemocional/logo_01.png';
import { ArrowButton, SvgIcon } from 'components/RelatedContent.styled';
import Colors from 'enums/Colors';
import { useHistory } from 'react-router-dom';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import useFetch from 'hooks/useFetch';
import keycloak from 'core/config/keycloak';
import ResgatedToken from './ResgatedToken';
import { KeycloakLoginOptions } from 'keycloak-js';
import { Popconfirm } from 'antd';
import { MdArrowForwardIos } from 'react-icons/md';

export const ListCards: TypeCard[] = [
    {
        id: 6,
        titulo: 'Diálogos Socioemocionais',
        descricao: `
                    É uma proposta que se articula às políticas de educação integral com o objetivo de promover o desenvolvimento socioemocional dos estudantes, 
                    de forma intencional e consistente. A proposta disponibiliza aos gestores escolares e das 
                    Secretarias de Educação uma Sistemática de Monitoramento e Acompanhamento.
                    `,
        smallDescription: `
                    Programa que se soma às políticas de educação integral com o objetivo de promover o desenvolvimento socioemocional dos estudantes, de forma intencional e consistente. 
                    `,
        img: logoSocioemocional,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/dialogos-socioemocionais',
        tag: [TagSolucoesEnum.GESTAO_POLITICA_EDUCACIONAL, TagSolucoesEnum.COMPETENCIAS_SOCIEMOCIONAL],
        linkExclusivo: '/exclusivo/dialogos-socioemocionais',
    },
    {
        id: 1,
        titulo: 'Circuito 360',
        descricao: `Com essa solução, ajudamos as Secretarias de Educação a criar e gerenciar políticas para os primeiros anos do Ensino Fundamental. Nosso objetivo é proporcionar uma educação completa, com métodos de ensino que estimulem tanto o aprendizado das matérias quanto o desenvolvimento emocional e social dos alunos.`,
        img: logoC360,
        link: '/circuito-360',
        smallDescription: `
       Programa que apoia secretarias de educação na elaboração e gestão de políticas educacionais para os anos iniciais do ensino fundamental, abordando temas como a gestão da alfabetização.
        `,
        tag: [TagSolucoesEnum.ALFABETIZACAO, TagSolucoesEnum.GESTAO_POLITICA_EDUCACIONAL],
        linkExclusivo: '',
    },
    {
        id: 2,
        titulo: 'Gestão Nota 10',
        descricao:
            'Capacitar as equipes das unidades escolares e da Secretaria para serem gerentes do sucesso de cada aluno. Institucionalizar práticas gerenciais no cotidiano da escola e da Secretaria, de modo que o processo educacional seja acompanhado sistematicamente. Instituir o planejamento da prática pedagógica, a partir da avaliação da aprendizagem.',
        img: logoGNOTA10,
        link: '/gestao-nota-10',
        smallDescription: `
       Focado na Gestão Escolar, o programa busca levar práticas gerenciais ao cotidiano da escola e ao processo educacional, que é acompanhado sistemática e intencionalmente. 
        `,
        tag: [TagSolucoesEnum.GESTAO_ESCOLAR, TagSolucoesEnum.FORMACAO_LIDERANCA],
        linkExclusivo: '',
    },
    {
        id: 3,
        titulo: 'Se liga',
        descricao: `
              Foi desenvolvido para alunos que não têm mais tempo a perder e não podem mais esperar por soluções de longo prazo. Precisam se alfabetizar o quanto antes.
              Porque não há dúvida de que toda criança é capaz de aprender. E todo o professor é capaz de ensinar!
            `,
        smallDescription: `
        Programa desenvolvido para alfabetizar estudantes matriculados nos anos iniciais do Ensino Fundamental e que apresentam distorção idade-ano.
        `,
        img: logoSeliga,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/se-liga',
        tag: [TagSolucoesEnum.CORRE_DE_FLUXO, TagSolucoesEnum.ALFABETIZACAO],
        linkExclusivo: '',
    },
    {
        id: 4,
        titulo: 'Acelera Brasil',
        descricao: `
                      Foi desenvolvido para promover o desenvolvimento integral dos alunos que chegaram até os anos finais do 
                        Ensino Fundamental com deficiências básicas de aprendizagem em Língua Portuguesa e Matemática, contribuindo para reverter o quadro de reprovação e abandono escolar nesta etapa da Educação Básica,
                        a partir do fortalecimento da construção da autonomia dos alunos advinda da confiança na própria capacidade para aprender.
                        `,
        smallDescription: `
       Programa com foco em estudantes já alfabetizados dos anos iniciais do Ensino Fundamental, mas com distorção idade-série.
        `,
        img: logoAcelera,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/acelera-brasil',
        tag: [TagSolucoesEnum.CORRE_DE_FLUXO, TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM],
        linkExclusivo: '',
    },
    {
        id: 5,
        titulo: 'Fórmula da Vitória',
        descricao: `
            Foco na educação integral como a formação do desenvolvimento
            global das crianças e adolescentes, pelo desenvolvimento
            de competências cognitivas, volitivas,
            híbridas, entre elas as competências socioemocionais e  qualificar a aprendizagem dos estudantes em Língua Portuguesa e Matemática.
            `,
        smallDescription: `
        Programa que visa a recomposição de aprendizagem para de Língua Portuguesa e Matemática para alunos dos anos finais do Ensino Fundamental.
        `,
        img: logoFormula,
        link: '/formula-da-vitoria',
        tag: [TagSolucoesEnum.CORRE_DE_FLUXO, TagSolucoesEnum.ALFABETIZACAO, TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM],
        linkExclusivo: '',
    },

    // {
    //     titulo: 'Realizando escolhas',
    //     descricao: `
    //                     A proposta Realizando Escolhas foi desenhada para
    //                     oferecer aos estudantes do 9º Ano do Ensino Fundamental
    //                     e do Ensino Médio oportunidades de aprofundar seu
    //                     autoconhecimento em termos de interesses, articulando os com seu projeto de vida.
    //                     `,
    //     link: '/solucao-realizando-escolhas',
    //     tag: [TagSolucoesEnum.INTERESSES_PROFISSIONAIS],
    // },
];

interface IMultiSolucoes {
    backgroundColor?: Colors;
    previewSolucoes?: boolean;
    paddingLeft?: string;
    height?: string;
}

const MultiSolucoes = ({ backgroundColor, previewSolucoes, paddingLeft, height }: IMultiSolucoes) => {
    const [solucoesExclusivas, setSolucoesExclusivas] = useState<TipoSolucoes[]>([]);
    const [modalTokenOpen, setModalTokenOpen] = useState<boolean>(false);
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();

    const history = useHistory();

    useFetch(async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);
            }
        }
    }, []);

    const validateAccessToken = (goTologin: boolean) => {
        const current = location.pathname.split('/')[1];

        const redirect = current === 'home' ? '/home' : '/solucoes';

        if (keycloak.authenticated) {
            setModalTokenOpen(true);
        } else {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + redirect,
            };

            const _url = goTologin ? keycloak.createLoginUrl(options) : keycloak.createRegisterUrl(options);
            window.location.assign(_url);
        }
    };

    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                {previewSolucoes ? (
                    <>
                        <Styled.SvgIconPreview>
                            <circle r="16.5" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.Blue} />
                            <path
                                d="M39.3719 27.6903L39.3712 27.6896C39.3701 27.6886 39.3691 27.6875 39.3681 27.6865L34.4729 22.8149M39.3719 27.6903L34.4729 22.8149M39.3719 27.6903C39.5419 27.86 39.5429 28.137 39.3737 28.3079C39.3715 28.3101 39.3694 28.3122 39.3674 28.3142C39.3671 28.3146 39.3667 28.3149 39.3663 28.3153L34.4729 33.1851C34.3016 33.3555 34.0246 33.3549 33.8542 33.1836L33.8541 33.1836C33.6837 33.0123 33.6843 32.7353 33.8556 32.5649C33.8556 32.5649 33.8556 32.5649 33.8556 32.5649L37.1444 29.2919L38.003 28.4375H36.7917H16.9375C16.6959 28.4375 16.5 28.2416 16.5 28C16.5 27.7584 16.6959 27.5625 16.9375 27.5625H36.7918H38.003L37.1445 26.7081L33.8556 23.4351C33.8556 23.4351 33.8555 23.4351 33.8555 23.4351M39.3719 27.6903L33.8555 23.4351M34.4729 22.8149C34.3016 22.6445 34.0246 22.6451 33.8541 22.8164C33.6837 22.9877 33.6843 23.2646 33.8555 23.4351M34.4729 22.8149L33.8555 23.4351"
                                fill={Colors.Blue}
                                stroke={Colors.Blue}
                            />
                        </Styled.SvgIconPreview>
                    </>
                ) : (
                    <>
                        <Styled.SvgIcon>
                            <circle r="16.5" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.Yellow} />
                            <path
                                d="M39.3719 27.6903L39.3712 27.6896C39.3701 27.6886 39.3691 27.6875 39.3681 27.6865L34.4729 22.8149M39.3719 27.6903L34.4729 22.8149M39.3719 27.6903C39.5419 27.86 39.5429 28.137 39.3737 28.3079C39.3715 28.3101 39.3694 28.3122 39.3674 28.3142C39.3671 28.3146 39.3667 28.3149 39.3663 28.3153L34.4729 33.1851C34.3016 33.3555 34.0246 33.3549 33.8542 33.1836L33.8541 33.1836C33.6837 33.0123 33.6843 32.7353 33.8556 32.5649C33.8556 32.5649 33.8556 32.5649 33.8556 32.5649L37.1444 29.2919L38.003 28.4375H36.7917H16.9375C16.6959 28.4375 16.5 28.2416 16.5 28C16.5 27.7584 16.6959 27.5625 16.9375 27.5625H36.7918H38.003L37.1445 26.7081L33.8556 23.4351C33.8556 23.4351 33.8555 23.4351 33.8555 23.4351M39.3719 27.6903L33.8555 23.4351M34.4729 22.8149C34.3016 22.6445 34.0246 22.6451 33.8541 22.8164C33.6837 22.9877 33.6843 23.2646 33.8555 23.4351M34.4729 22.8149L33.8555 23.4351"
                                fill={Colors.Yellow}
                                stroke={Colors.Yellow}
                            />
                        </Styled.SvgIcon>
                    </>
                )}
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                {previewSolucoes ? (
                    <>
                        <Styled.SvgIconPreview>
                            <circle r="16.5" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.Blue} />
                            <path
                                d="M16.6281 28.3097L16.6288 28.3104C16.6299 28.3114 16.6309 28.3125 16.6319 28.3135L21.5271 33.1851M16.6281 28.3097L21.5271 33.1851M16.6281 28.3097C16.4581 28.14 16.4571 27.863 16.6263 27.6921C16.6285 27.6899 16.6306 27.6878 16.6326 27.6858C16.6329 27.6854 16.6333 27.6851 16.6337 27.6847L21.5271 22.8149C21.6984 22.6445 21.9754 22.6451 22.1458 22.8164L22.1459 22.8164C22.3163 22.9877 22.3157 23.2647 22.1444 23.4351C22.1444 23.4351 22.1444 23.4351 22.1444 23.4351L18.8556 26.7081L17.997 27.5625L19.2083 27.5625L39.0625 27.5625C39.3041 27.5625 39.5 27.7584 39.5 28C39.5 28.2416 39.3041 28.4375 39.0625 28.4375L19.2082 28.4375L17.997 28.4375L18.8555 29.2919L22.1444 32.5649C22.1444 32.5649 22.1445 32.5649 22.1445 32.5649M16.6281 28.3097L22.1445 32.5649M21.5271 33.1851C21.6984 33.3555 21.9754 33.3549 22.1459 33.1836C22.3163 33.0123 22.3157 32.7354 22.1445 32.5649M21.5271 33.1851L22.1445 32.5649"
                                fill={Colors.Blue}
                                stroke={Colors.Blue}
                            />
                        </Styled.SvgIconPreview>
                    </>
                ) : (
                    <>
                        <Styled.SvgIcon>
                            <circle r="16.5" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.Yellow} />
                            <path
                                d="M16.6281 28.3097L16.6288 28.3104C16.6299 28.3114 16.6309 28.3125 16.6319 28.3135L21.5271 33.1851M16.6281 28.3097L21.5271 33.1851M16.6281 28.3097C16.4581 28.14 16.4571 27.863 16.6263 27.6921C16.6285 27.6899 16.6306 27.6878 16.6326 27.6858C16.6329 27.6854 16.6333 27.6851 16.6337 27.6847L21.5271 22.8149C21.6984 22.6445 21.9754 22.6451 22.1458 22.8164L22.1459 22.8164C22.3163 22.9877 22.3157 23.2647 22.1444 23.4351C22.1444 23.4351 22.1444 23.4351 22.1444 23.4351L18.8556 26.7081L17.997 27.5625L19.2083 27.5625L39.0625 27.5625C39.3041 27.5625 39.5 27.7584 39.5 28C39.5 28.2416 39.3041 28.4375 39.0625 28.4375L19.2082 28.4375L17.997 28.4375L18.8555 29.2919L22.1444 32.5649C22.1444 32.5649 22.1445 32.5649 22.1445 32.5649M16.6281 28.3097L22.1445 32.5649M21.5271 33.1851C21.6984 33.3555 21.9754 33.3549 22.1459 33.1836C22.3163 33.0123 22.3157 32.7354 22.1445 32.5649M21.5271 33.1851L22.1445 32.5649"
                                fill={Colors.Yellow}
                                stroke={Colors.Yellow}
                            />
                        </Styled.SvgIcon>
                    </>
                )}
            </Styled.ArrowButton>
        );
    };
    const settings = {
        dots: true,
        infinite: previewSolucoes,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,

        responsive: [
            {
                breakpoint: 1500, // Para telas menores que 1500px
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1100, // Para telas menores que 1100px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 800, // Para telas menores que 800px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const settingsMobile = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        customPaging: function (i: number) {
            return (
                <ul style={{ margin: '0px' }}>
                    <Styled.Dots />
                </ul>
            );
        },
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <>
            <ResgatedToken
                hidenButtonIcon
                setHidenModalToken={setModalTokenOpen}
                valueModalToken={modalTokenOpen}
                hidenModalToast={previewSolucoes}
            />
            <Styled.Container backgroundColor={backgroundColor} height={height}>
                <Styled.Header preview={previewSolucoes} padding={paddingLeft}>
                    {previewSolucoes ? (
                        <>
                            <Styled.Title style={{ color: Colors.Blue }}>{`Nossas Soluções`.toLocaleUpperCase()}</Styled.Title>
                            <Styled.Row>
                                <Styled.ButtonPrimary light onClick={() => history.push('/solucoes')}>
                                    Conheça nossas soluções
                                </Styled.ButtonPrimary>
                                <Popconfirm
                                    disabled={keycloak.authenticated}
                                    placement="top"
                                    title={<strong>Continue como Parceiro do Instituto</strong>}
                                    description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                                    onConfirm={() => validateAccessToken(true)}
                                    onCancel={() => validateAccessToken(false)}
                                    okText="Já sou cadastrado e quero fazer meu login"
                                    cancelText="Quero me cadastrar"
                                    cancelButtonProps={{
                                        style: {
                                            width: '200px',
                                            height: '30px',
                                        },
                                    }}
                                    okButtonProps={{
                                        style: {
                                            height: '30px',
                                            backgroundColor: Colors.Blue,
                                        },
                                    }}
                                >
                                    <Styled.ButtonPrimary
                                        style={{
                                            width: keycloak.authenticated ? '400px' : '',
                                        }}
                                        onClick={() => {
                                            keycloak.authenticated && setModalTokenOpen(true);
                                        }}
                                    >
                                        {keycloak.authenticated ? 'Já sou parceiro e possuo um código de acesso' : 'Já sou parceiro'}
                                    </Styled.ButtonPrimary>
                                </Popconfirm>
                            </Styled.Row>
                        </>
                    ) : (
                        <>
                            <Styled.Row
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginRight: IsCustomMaxWidth(800) ? '0px' : '146px',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Styled.Title>{`um pouco sobre as soluções oferecidas`.toLocaleUpperCase()}</Styled.Title>
                                <Popconfirm
                                    disabled={keycloak.authenticated}
                                    placement="leftTop"
                                    title={<strong>Continue como Parceiro do Instituto</strong>}
                                    description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                                    onConfirm={() => validateAccessToken(true)}
                                    onCancel={() => validateAccessToken(false)}
                                    okText="Já sou cadastrado e quero fazer meu login"
                                    cancelText="Quero me cadastrar"
                                    cancelButtonProps={{
                                        style: {
                                            width: '200px',
                                            height: '30px',
                                        },
                                    }}
                                    okButtonProps={{
                                        style: {
                                            height: '30px',
                                            backgroundColor: Colors.Blue,
                                        },
                                    }}
                                >
                                    <Styled.ButtonPrimary
                                        style={{
                                            backgroundColor: Colors.Yellow,
                                            color: Colors.Blue,
                                            width: keycloak.authenticated ? '400px' : '',
                                        }}
                                        onClick={() => {
                                            keycloak.authenticated && setModalTokenOpen(true);
                                        }}
                                    >
                                        {keycloak.authenticated ? 'Já sou parceiro e possuo um código de acesso' : 'Já sou parceiro'}
                                    </Styled.ButtonPrimary>
                                </Popconfirm>
                            </Styled.Row>
                            <Styled.Description>{`
                       As nossas soluções educacionais foram desenvolvidas pensando em diferentes desafios que redes de ensino podem enfrentar, como a gestão de políticas de alfabetização,
                       a correção de fluxo, o reforço de aprendizagem e o desenvolvimento de competências socioemocionais. Conheça mais abaixo:`}</Styled.Description>
                        </>
                    )}
                </Styled.Header>
                <Styled.Carrousel>
                    <Styled.CarouselCardContainer preview={previewSolucoes} style={{ margin: 0 }} className="slider-container-2">
                        <Slider autoplay={previewSolucoes} {...settings}>
                            {ListCards.map((dados, index) => (
                                <CartaoMultiSolucoes
                                    key={index}
                                    mySolucoesExclusivas={solucoesExclusivas}
                                    dados={dados}
                                    isButtonOculto={previewSolucoes}
                                />
                            ))}
                        </Slider>
                    </Styled.CarouselCardContainer>
                </Styled.Carrousel>
            </Styled.Container>
        </>
    );
};

export default MultiSolucoes;
