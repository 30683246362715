import styled from 'styled-components';

const StyleCorrespondetPublic = {
    Container: styled.div`
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-top: 1px dashed;
        padding-bottom: 22px;
        border-bottom: 1px dashed;
        margin-bottom: 22px;
        position: relative;
    `,

    Footer: styled.div`
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    `,

    TextContent: styled.p`
        margin: 40px 0px;
        line-height: 160%;

        ol,
        ul {
            list-style: inside;
        }

        h1,
        h2 {
            font-weight: 700;
        }
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1em;
        }
        h5 {
            font-size: 0.8em;
        }
        h6 {
            font-size: 0.6em;
        }
        iframe {
            width: 100%;
            min-height: 300px;
        }
        img {
            width: 100%;
            cursor: pointer;
        }
    `,

    ContentFooter: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 22px;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
    `,
};

export default StyleCorrespondetPublic;
