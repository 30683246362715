import React, { createContext } from 'react';

export namespace RealizandoEscolhasContext {
    export interface IContext {
        isShowBannerInfo: boolean;
        setShowBannerInfo: () => void;

        showModalNavigate: boolean;
        setShowModalNavigate: (value: boolean) => void;

        isVerifyGoToPage: (value: string) => void;
        goToPage: () => void;
    }

    export const Context = createContext({} as IContext);
}
