import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';

// Img

import Img_01 from '../../assets/AlfaTeoriaPratica/modulo03/img_01.jpg';
import Img_02 from '../../assets/AlfaTeoriaPratica/modulo03/infografico_5_CAMPOS_DE_EXPERIENCIA_bncc.png';

const ATP_Modulo03 = ({ setImgAmpliar }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="03"
                titulo={`
             <strong> Educação infantil: a fase de cuidar do broto </strong><br />
              Por que esta etapa da educação básica é essencial
                para sedimentar as bases para a alfabetização
            `}
                description={`
                  Mariana Luz
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 41,
                }}
            >
                <h1 style={{ color: Colors.SoftBlue, fontSize: 18, fontWeight: 700, fontStyle: 'italic' }}>Mensagens principais</h1>
                <Styled.Img src={Img_01} alt="" onClick={() => setImgAmpliar(Img_01)} />
                <Styled.Description>
                    O desenvolvimento de uma árvore, desde a semente até estar pronta para dar frutos e resistir a ventos fortes e a tempestades,
                    é uma boa analogia para o processo de educação básica, desde a educação infantil até o fim do ensino médio. Neste caso, a
                    árvore frondosa será o estudante bem preparado, que possui domínio sobre os comportamentos necessários para estudar, zelar
                    por seu bem-estar, saúde física e mental e se inserir no mundo do trabalho; a habilidade de trabalhar em grupo;
                    autoconhecimento suficiente para se autogerir; e inteligência emocional para lidar com as situações cada vez mais complexas
                    da vida adulta.
                    <br />
                    <br />
                    Assim como ocorre com a planta, a aquisição de habilidades e conhecimentos e o desenvolvimento integral da criança (e,
                    depois, do adolescente) são processuais e interdependentes. O que vem antes interfere na assimilação do que será ensinado a
                    seguir. O quão forte e saudável será essa árvore ao fim do processo dependerá necessariamente da qualidade de cada uma das
                    etapas. Esse processo tem início formal na educação infantil, que engloba a
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 42,
                }}
            >
                <Styled.Description>
                    creche e a pré-escola. É nessa fase que a semente se tornará um broto saudável, pronto para iniciar seu crescimento vertical
                    e esboçar as primeiras formas da planta grandiosa que vai se tornar.
                    <br />
                    <br />A relevância da educação infantil está diretamente relacionada à importância do desenvolvimento infantil na primeira
                    infância – fase que vai até os seis anos de idade. Nesse período, as estruturas e circuitos cerebrais são desenvolvidos, bem
                    como a aquisição de capacidades fundamentais que permitirão o aprimoramento de habilidades futuras mais complexas.
                </Styled.Description>
                <Styled.ContentBorderYellow>
                    Os seis primeiros anos de vida constituem a fase em que o ser humano se desenvolve mais, e mais rápido. Aos 4 anos, a criança
                    tem mais da metade do potencial cerebral do adulto, chegando a realizar 1 milhão de conexões cerebrais por segundo. Até os 6
                    anos, 90% das conexões cerebrais estão estabelecidas. Em nenhum outro momento da vida o desenvolvimento se dá nessa
                    velocidade. É nesta fase que se forma a base para o desenvolvimento motor, cognitivo, social e emocional.
                    <br />
                    <br />
                    Após o período de desenvolvimento inicial, o cérebro ainda se modifica em resposta à experiência e aos estímulos aos quais
                    está exposto, característica conhecida como plasticidade cerebral. Para determinadas funções, a plasticidade é máxima nos
                    períodos iniciais da vida. Por isso, esta fase é tida como uma “janela de oportunidades”. Funções cognitivas mais
                    especializadas, como atenção, memória, planejamento, raciocínio e juízo crítico, começam a se desenvolver na primeira
                    infância por meio de habilidades como controlar impulsos, direcionar a atenção ou lembrar regras. Essas funções, no entanto,
                    não se desenvolvem automaticamente; elas precisam de estímulos, frequência e ambiente adequados para ocorrer. Os contextos
                    familiar e escolar são fundamentais para criar as raízes de habilidades que serão essenciais ao longo de toda a vida.
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    Desde o nascimento, estamos inseridos em um mundo letrado. É na educação infantil que as crianças desenvolvem o interesse
                    pela leitura de histórias, pelo reconhecimento dos códigos de escrita e pela oralidade. Embora essa etapa, a primeira da
                    educação básica, não tenha como finalidade a alfabetização, ela dará as bases para que o processo se conclua com sucesso no
                    ensino fundamental.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 43,
                }}
            >
                <Styled.Description>
                    Pesquisas mostram a importância da educação infantil na alfabetização: indivíduos matriculados na pré-escola apresentam cinco
                    vezes mais chances de saber ler do que os não matriculados, sendo que a frequência na pré- -escola é o principal determinante
                    individual das taxas de alfabetização.
                    <br />
                    <br />É também nessa fase que as crianças adquirem habilidades de coordenação ampla e fina, sequenciação, equilíbrio e lógica
                    matemática. Crianças com desenvolvimento integral saudável durante os primeiros anos de vida têm maior facilidade de se
                    adaptar a diferentes ambientes e de adquirir novos conhecimentos, características que contribuem para que posteriormente
                    obtenham um bom desempenho escolar e alcancem realização pessoal, profissional e econômica.
                    <br />
                    <br />
                    Há práticas pedagógicas que são reconhecidas por criarem as bases necessárias para a alfabetização. Entre elas, destacam-se:
                </Styled.Description>
                <Styled.ContentBlue3>promover leitura e escrita com base nas interações e nas brincadeiras;</Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    criar situações de aprendizagem planejadas, sequenciadas, sistematizadas e desenvolvidas por profissionais qualificados, as
                    quais, de um lado, garantam o contato cotidiano das crianças com variados suportes e gêneros discursivos orais e escritos e,
                    de outro, incentivem a curiosidade, a exploração, o encantamento, o questionamento e o conhecimento das crianças sobre a
                    linguagem escrita;
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    promover e intensificar o contato da criança com diferentes gêneros discursivos, em especial com a literatura entendida como
                    arte, superando uma visão instrucional e pragmática, respeitando as especificidades da primeira infância;
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    ampliar o acesso a livros e estimular a criança a criar hipóteses de escrita, a apreciar literatura infantil, a interagir com
                    diferentes gêneros textuais, a fazer registros e desenhar, a relacionar a fala com a escrita, a trabalhar o nome próprio e a
                    identificar letras e palavras.
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 44,
                }}
            >
                <Styled.Description>
                    A educação infantil tem como principal contribuição para esse processo fazer com que a criança se interesse pela leitura e
                    pela escrita, com que ela deseje aprender a ler e escrever e, ainda, com que ela acredite que é capaz de fazê-lo. Nesse
                    sentido, é fundamental preparar os professores para trabalhar com intencionalidade pedagógica na construção do desejo pela
                    leitura e escrita e na autoconfiança da criança. Muito antes de dominar a escrita ortográfica convencional, a criança pode e
                    deve familiarizar-se com os usos e funções da escrita por meio de textos literários ou de narrativas visuais. Em ambas as
                    situações, a criança exercita capacidades e habilidades envolvidas na compreensão dos usos e das funções sociais da escrita.
                </Styled.Description>
                <Styled.ContentBorderYellow>
                    A rede municipal da cidade de Sobral, no Ceará, adota um sistema que pode inspirar boas discussões a respeito de como fazer
                    uma transição da educação infantil para a educação fundamental que respeite as peculiaridades de cada fase. Em primeiro
                    lugar, destaca-se o fato de a cidade adotar critérios comuns em relação aos objetivos de aprendizagem para educação infantil,
                    ensino fundamental I e ensino fundamental II. O acompanhamento de cada uma das três fases é feito pelo mesmo superintendente,
                    que enxerga cada período como uma continuidade do anterior. Os diretores das unidades, por exemplo, recebem uma formação em
                    comum. Embora também existam formações específicas, que respeitam as especificidades de cada período, a integração na
                    montagem e na implementação do currículo é uma forma de cuidar para que cada etapa de ensino cumpra seus objetivos de
                    aprendizagem. Gestores e professores da rede, independentemente da etapa com a qual cada um trabalhe, sabem quais são esses
                    objetivos e como devem ser conquistados. O município mantém um acompanhamento diagnóstico que tem início na educação infantil
                    e segue até o 9º ano do ensino fundamental. O objetivo é monitorar, ano a ano, o efeito das práticas da rede no aprendizado
                    de cada criança e promover a correção de rotas, quando necessário.
                </Styled.ContentBorderYellow>
                <Styled.Description>
                    Apesar da comprovada importância desse conjunto de práticas para o processo de alfabetização, uma pesquisa inédita concluiu
                    que muitas escolas de educação infantil não as adotam com regularidade. O estudo “Avaliação da Qualidade da Educação
                    Infantil”, feito pelo Laboratório de Estudos e Pesquisas em Economia Social (Lepes), da Universidade de São Paulo, em
                    parceria com a Fundação Maria Cecilia Souto Vidigal30, analisou mais de 3 mil turmas de creches e pré-escolas em 12
                    municípios brasileiros de todas as
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 45,
                }}
            >
                <Styled.Description>
                    regiões com o objetivo de aferir práticas de qualidade. O estudo constatou que em apenas 38% das 3.467 turmas foram
                    observadas práticas de leitura e escrita com qualidade. A leitura de livros foi reconhecida em menos da metade (45%) das
                    instituições que participaram da pesquisa.
                    <br />
                    <br />
                    Esses aspectos se relacionam diretamente à qualidade do atendimento na educação infantil, condição indispensável para que
                    seus impactos no desenvolvimento integral da criança sejam positivos. Creches e pré-escolas de qualidade oferecem um conjunto
                    de interações positivas, ambiente seguro, nutrição e cuidados adequados para estimular o desenvolvimento integral da criança.
                    Crianças em maior situação de vulnerabilidade tendem a ser mais impactadas positivamente pela educação infantil em seu
                    desenvolvimento. Por uma série de razões – a maior parte delas relacionada à luta pela sobrevivência –, as famílias mais
                    pobres enfrentam mais dificuldades para oferecer o estímulo, o ambiente e, muitas vezes, a nutrição de que a criança precisa.
                    Nesse contexto, o impacto positivo da rotina na creche ou na pré-escola será ainda maior e mais protetor. O contrário também
                    é verdadeiro. Estudos indicam que o atendimento de creches e pré-escolas de baixa qualidade não produz efeito positivo na
                    escolaridade futura e pode até ter um impacto negativo35 no processo de aprendizagem da criança em relação ao que ocorreria
                    se ela não frequentasse a educação infantil.
                </Styled.Description>
                <Styled.TitleYellow style={{fontSize: 16}}>Mas o que é, afinal, uma educação infantil de qualidade?</Styled.TitleYellow>
                <Styled.Description>
                    Na educação infantil, a qualidade se manifesta em múltiplas dimensões: além do próprio projeto pedagógico, a presença de
                    profissionais capacitados, que trabalhem com intencionalidade, a existência de materiais e infraestrutura adequados e a
                    realização de práticas lúdicas e enriquecedoras são fundamentais para a garantia da qualidade.
                    <br />
                    <br />
                    Os resultados do estudo Avaliação da Qualidade da Educação Infantil oferecem subsídios para entender o que é uma educação
                    infantil de qualidade. Relacionamos a seguir os cinco aspectos mais efetivos identificados pelo levantamento nas práticas
                    pedagógicas e no comportamento docente.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 46,
                }}
            >
                <Styled.ContentBlue3>
                    Reconhecer o brincar como a estratégia mais eficiente para o desenvolvimento da criança. Ao brincar, ela socializa, explora,
                    imagina, constrói hipóteses, resolve problemas e expande o seu repertório.
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    Estabelecer uma rotina centrada na criança, oferecendo oportunidades para que ela exerça o protagonismo. Isso pode ser feito
                    dando oportunidades de escolha nas brincadeiras e rotinas.
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    Usar o contexto reconhecido pela criança para propor atividades. Esse contexto pode ter relação com o local onde ela mora ou
                    com momentos que ela já vivenciou na escola. Ela tende a se engajar mais quando encontra mais significado no que faz, e as
                    possibilidades de aprendizado se expandem.
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    Atuar como mediador do processo de aprendizado, tanto ao propor a rotina quanto ao reconhecer os diferentes ritmos de cada
                    criança e agir de acordo com eles.
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    Optar sempre por perguntas abertas em vez de questionamentos que pedem uma resposta única.
                </Styled.ContentBlue3>
                <Styled.Description>
                    As constatações da avaliação brasileira confirmam o que a literatura internacional já apontava: é importante ter consciência
                    dos interesses da criança, saber como envolvê-la e manter um equilíbrio entre as atividades iniciadas por ela e pelo
                    professor. São esses tipos de interações e comunicação que determinam a qualidade do ensino e da didática. Isso porque a
                    educação infantil é um processo relacional. O coração da qualidade do processo são as interações da criança com ela mesma,
                    com as outras crianças e com os adultos.
                    <br />
                    <br />O Brasil conta com documentos que indicam diretrizes de proposta pedagógica, gestão, formação dos profissionais e
                    infraestrutura da escola ou da creche, cujo objetivo é garantir um atendimento de qualidade por parte de creches e
                    pré-escolas. Esses documentos são resultado de um longo trabalho que contou com a participação de educadores, gestores e
                    professores
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 47,
                }}
            >
                <Styled.Description>
                    de todo o país. Entre os principais, estão as Diretrizes Curriculares para a Educação Infantil, os Parâmetros Nacionais de
                    Qualidade para a Educação Infantil, atualmente em aprimoramento, e os Indicadores de Qualidade na Educação Infantil. A
                    elaboração da Base Nacional Comum Curricular (BNCC) de educação infantil, documento que reúne os princípios com base nos
                    quais cada rede deve montar seu currículo, ajudou a construir um consenso sobre os aspectos que essa etapa deve explorar e os
                    direitos de aprendizagem que ela deve garantir a cada criança.
                    <br />
                    <br />A BNCC define que o currículo de qualidade nessa fase educacional deve contemplar cinco campos de experiências:
                </Styled.Description>
                <Styled.Img src={Img_02} onClick={() => setImgAmpliar(Img_02)} />
                <Styled.Description>
                    Essas experiências devem ocorrer com o objetivo de garantir os direitos de aprendizagem da criança nessa fase escolar, também
                    definidos pela BNCC. São eles:
                </Styled.Description>
                <Styled.ContentBlue3>conviver democraticamente com outras crianças e adultos em distintas situações;</Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    brincar cotidianamente de diversas formas, em diferentes espaços e tempos, com diferentes parceiros adultos e crianças;
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    participar com protagonismo ativo tanto no planejamento da gestão da escola como na realização das atividades recorrentes da
                    vida cotidiana;
                </Styled.ContentBlue3>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 48,
                }}
            >
                <Styled.ContentBlue3>
                    explorar movimentos, gestos, sons, palavras, emoções, relacionamentos, histórias, objetos, elementos da natureza,
                    transformações no contexto urbano e do campo;
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    expressar como sujeito criativo e sensível, com diferentes linguagens, sensações corporais, necessidades, opiniões,
                    sentimentos e desejos por meio de linguagens e com o fruir das artes em todas as suas manifestações;
                </Styled.ContentBlue3>
                <Styled.ContentBlue3>
                    conhecer-se e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de
                    pertencimento.
                </Styled.ContentBlue3>
                <Styled.Description>
                    As definições trazidas pela BNCC foram um avanço importante na construção de um projeto pedagógico de educação infantil
                    ancorado em objetivos comuns de aprendizagem e na garantia de direitos em todo o país. O desafio que as redes de educação
                    brasileiras enfrentam é de como implementá-los e transformá-los em prática dentro das instituições. As evidências sobre
                    práticas de qualidade apresentadas aqui podem ser uma bússola para os gestores e profissionais da educação infantil.
                    <br />
                    <br />
                    Um componente fundamental para a garantia da qualidade é a avaliação. Precisamos avançar para além de averiguar
                    infraestrutura e materiais, como propõe o Sistema de Avaliação da Educação Básica (Saeb) para a educação infantil. É
                    importante adotarmos metodologias para avaliar as oportunidades de aprendizagem e práticas pedagógicas no nível da rede,
                    considerando seu efeito na aprendizagem das crianças. No cotidiano da escola, é fundamental que professores possam realizar a
                    documentação pedagógica, registrando as ações cotidianas junto às crianças e utilizando a metodologia para aprimorar as
                    práticas.
                </Styled.Description>
                <Styled.TitleYellow style={{fontSize: 16}}>A educação infantil como estratégia para a equidade</Styled.TitleYellow>
                <Styled.Description>
                    Os dados comprovam a importância da educação infantil de qualidade para o desenvolvimento da criança e como política pública,
                    por ser um antídoto comprovadamente eficaz contra as iniquidades que marcam as crianças
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 49,
                }}
            >
                <Styled.Description>
                    de famílias mais vulnerabilizadas desde o nascimento. A educação infantil é uma medida de cuidado e proteção, contudo, para
                    que esse cuidado chegue a todas as crianças, é preciso ainda combater o desafio do acesso e promover a equidade.
                    <br />
                    <br />
                    Dados da Pnad Contínua Educação 2023 mostram que, dos 11,2 milhões de crianças de 0 a 3 anos do país, 4,3 milhões (38,7%)
                    estão matriculadas em creches. O índice ainda está distante da meta do Plano Nacional de Educação (PNE), que previa atender
                    50% das crianças em creches até esse ano. Sobre a pré-escola, os dados da pesquisa revelam que 5,7 milhões (92,9%) das
                    crianças de 4 e 5 anos estão matriculadas. A pré-escola é uma etapa obrigatória, mas, ainda assim, o Brasil tem hoje 441 mil
                    crianças fora da rede. Os dados mostram as desigualdades: entre os 25% mais ricos da população, o percentual dos que
                    frequentam a creche é mais do que o dobro em comparação aos mais pobres. No caso da pré-escola, etapa considerada obrigatória
                    da educação básica brasileira, os 20% mais pobres da população acessam menos a pré-escola do que aqueles que estão entre os
                    20% mais ricos no Brasil.
                    <br />
                    <br />
                    As desigualdades ficam ainda mais evidenciadas ao se traçar o perfil de crianças na primeira infância e suas famílias
                    cadastradas no Cadastro Único (CadÚnico; instrumento de coleta de dados e informações para identificar todas as famílias de
                    baixa renda existentes no país para fins de inclusão em programas de assistência social e redistribuição de renda): entre as
                    crianças cadastradas, 66,4% são negras; as famílias de mãe solo também são a maioria: 73,8%, dos quais 74,3% são negras. Para
                    as crianças dessas famílias, o efeito da educação infantil de qualidade é ainda mais relevante. No entanto, a mesma pesquisa
                    mostra que 60% das crianças nunca frequentaram creche ou pré-escola; destacam-se ainda as diferenças regionais: no Sudeste, o
                    índice é de 54% e, no Norte, de 70%
                    <br />
                    <br />
                    Para o bem das crianças e de toda a sociedade, é importante acionar e fortalecer os programas de busca ativa escolar que já
                    existem e colocar todas as crianças de 4 e 5 anos na pré-escola, assim como dar vaga em creche com qualidade a quem precisa
                    ou quer.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetização Integral: da Teoria à Prática',
                    secoundText: 'Educação infantil: a fase de cuidar do broto',
                }}
                footer={{
                    numberSummary: 50,
                }}
            >
                <Styled.Description>
                    A educação desempenha um papel fundamental no bem-estar das pessoas ao longo da vida. A criança que participa da educação
                    infantil tende a dedicar mais anos ao estudo durante a juventude e a idade adulta. Indivíduos com maior escolaridade tendem a
                    viver mais, a apresentar melhores condições de saúde, a alcançar melhores níveis socioeconômicos e de qualidade de vida, além
                    de se envolver menos em episódios de crimes e violência. Ao dar a cada criança o atendimento de creche e pré-escola a que
                    ela tem direito, o país cria as condições para que ela se desenvolva física, mental e psicologicamente. Essa criança terá
                    muito mais chances de trilhar um caminho diferente daquele percorrido por pais que tiveram menos oportunidades e, assim, de
                    quebrar o ciclo intergeracional de pobreza para si e para suas próximas gerações. O investimento em educação infantil de
                    qualidade hoje é, portanto, uma estratégia para evitar gastos futuros com educação, saúde, distribuição de renda e segurança
                    pública e, ao mesmo tempo, assegurar uma geração com equidade de oportunidades desde o começo da vida.
                </Styled.Description>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo03;
