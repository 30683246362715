import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import AdminImageUploader from '../imageUploader/AdminImageUploader';

import { IAdminEditSection } from './AdminEditBanner';
import AdminSecaoPaginaData, { AdminSecaoImagem, AdminSecaoPaginaImagemSimples } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import { useParams } from 'react-router-dom';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import Loading from 'components/Loading';
import { isStringsValid, isValidLink } from './isValidNewContentInput';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import Select from 'components/Select';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';
import SelectedButtons from './components/SelectedButtons';
import ActionComponent, { IvaluesActionComponent } from './components/ActionComponent';
import BackgroundComponent from './components/BackgroundComponent';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import { LabelObs, LabelRedirecionamento } from './components/LabelObs';

const AdminEditSimpleImage = ({ onSaved, onUpdate, onDelete, mode, custom }: IAdminEditSection) => {
    const params: any = useParams();
    const [idSecao, setIdSecao] = useState<number>(0);
    const [loading, setloading] = useState(false);
    const [cover, setCover] = useState<string | null>(null);
    const service = new AdminGestaoPaginaService();

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [link_interno, setLinkInterno] = useState('');
    const [tipoRedirecionamento, setTipoRedirecionamento] = useState<TipoRedirecionamento>(TipoRedirecionamento.LOGIN);

    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState(false);

    const { currentSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);

    //Custom Config
    const [colorBackground, setColorBackground] = useState<string | null>(null);
    const [typeButton, setTypeButton] = useState<ButtonsCustom>(ButtonsCustom.Button_01);
    const [buttonOculto, setButtonOculto] = useState(false);

    const [titleCustom, setTitleCustom] = useState<IvaluesActionComponent>({
        valueColorText: '#000000',
        isOculto: false,
        valueFontSize: 22,
    });

    const [textSecao, setTextSecao] = useState<IvaluesActionComponent>({
        valueColorText: '#000000',
        isOculto: false,
        valueFontSize: 16,
    });

    const MAX_CHAR_CONTENT = 12000;

    const isValidInput = (): boolean => {
        if (!titleCustom.isOculto) {
            if (title.length === 0) return false;
        }

        if (!textSecao.isOculto) {
            if (text.length === 0) return false;
        }

        if (!buttonOculto) {
            if (texto_botao_a.length === 0 || link_interno.length === 0) return false;
        }

        return isValidLink(link_interno, false) && cover != null && cover.length > 0;
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaImagemSimples) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onSaved(section);
            setModePage(true);
        } else ToastError('Houve um erro ao tentar salvar o seção!');
    };

    const saveSectionImageTypeB = async (section: AdminSecaoPaginaData, holder: AdminSecaoImagem) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onSaved(section);
            setModePage(true);
        } else ToastError('Houve um erro ao tentar salvar o seção!');
    };

    const updateSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaImagemSimples) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSimplesImagem(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const updateSectionImageTypeB = async (section: AdminSecaoPaginaData, holder: AdminSecaoImagem) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSecaoImagem(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);
        if (!custom) {
            try {
                const response = await service.deleteSecaoSimplesComImagem(idSecao);

                if (response.status === 200) {
                    ToastSuccess('Simples com imagem excluído com sucesso!');
                    setShowModalDeleteSecao(false);
                    setIsLoadingDelete(false);
                    onDelete();
                }
            } catch (error) {
                ToastError('Ocorreu um erro na hora da exclusão.');
            }
        } else {
            try {
                const response = await service.deleteSecaoImagem(idSecao);

                if (response.status === 200) {
                    ToastSuccess('Seção com imagem excluído com sucesso!');
                    setShowModalDeleteSecao(false);
                    setIsLoadingDelete(false);
                    onDelete();
                }
            } catch (error) {
                ToastError('Ocorreu um erro na hora da exclusão.');
            }
        }
    };

    const submit = async () => {
        if (custom) return submitImagemTypeB();
        if (!currentSection) {
            alert('Erro ao salvar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaImagemSimples;

        holder.titulo = title;
        holder.textoBotaoA = texto_botao_a;
        holder.link = link_interno;
        holder.descricao = text;
        holder.anexoBase64 = cover;
        holder.tipoRedirecionamento = tipoRedirecionamento;

        currentSection.modelData = holder;

        setSections([...sections, currentSection]);

        console.log(holder);

        if (mode == 'NEW') await saveSection(currentSection, holder);
        else await updateSection(currentSection, holder);

        setSubmitting(false);
    };

    const submitImagemTypeB = async () => {
        if (!currentSection) {
            alert('Erro ao salvar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoImagem;

        holder.titulo = title;
        holder.corTitulo = titleCustom.valueColorText;
        holder.tamanhoTitulo = titleCustom.valueFontSize.toString();
        holder.ocultarTitulo = titleCustom.isOculto;
        holder.texto = text;
        holder.corTexto = textSecao.valueColorText;
        holder.tamanhoTexto = textSecao.valueFontSize.toString();
        holder.ocultarTexto = textSecao.isOculto;
        holder.linkBotao = link_interno;
        holder.ocultarBotao = buttonOculto;
        holder.textoBotao = texto_botao_a;
        holder.estiloBotao = typeButton;
        holder.corSecao = colorBackground ?? '#FFFFF';
        holder.anexoBase64 = cover;
        holder.tipoRedirecionamento = tipoRedirecionamento;

        currentSection.modelData = holder;

        setSections([...sections, currentSection]);

        console.log(holder);

        if (mode == 'NEW') await saveSectionImageTypeB(currentSection, holder);
        else await updateSectionImageTypeB(currentSection, holder);

        setSubmitting(false);
    };

    const fetchItem = async () => {
        const id = String(params.id);
        setloading(true);
        if (mode == 'EDIT' && !custom) {
            const { data } = await service.buscarSecaoSimplesImagemPorId(parseInt(id));
            setIdSecao(parseInt(id));
            setText(data.descricao);
            setTitle(data.titulo);
            setLinkInterno(data.link);
            setTextoBotaoA(data.textoBotaoA);
            setCover(data.anexoBase64);
            setModePage(true);
            setTipoRedirecionamento(data.tipoRedirecionamento);
        } else if (mode == 'EDIT') {
            const { data } = await service.buscarSecaoImagemPorId(parseInt(id));
            setIdSecao(parseInt(id));
            setText(data.texto);
            setTitle(data.titulo);
            setLinkInterno(data.linkBotao);
            setTextoBotaoA(data.textoBotao);
            setCover(data.anexoBase64);
            setModePage(true);
            setButtonOculto(data.ocultarBotao);
            setTipoRedirecionamento(data.tipoRedirecionamento);
            setTitleCustom(prev => ({
                ...prev,
                valueColorText: data.corTitulo,
                valueFontSize: data.tamanhoTitulo,
                isOculto: data.ocultarTitulo,
            }));

            setTextSecao(prev => ({
                ...prev,
                valueColorText: data.corTexto,
                valueFontSize: data.tamanhoTexto,
                isOculto: data.ocultarTexto,
            }));

            setColorBackground(data.corSecao);
            setTypeButton(data.estiloBotao);
        }
        setloading(false);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <Styled.Container>
                <ModalExclusao
                    showModal={showModalDeleteSecao}
                    handleHiden={setShowModalDeleteSecao}
                    handleExcluxed={handleDelete}
                    isLoading={isLoadingDelete}
                />
                {!loading ? (
                    <>
                        <div>
                            <Styled.Label>Título da Seção</Styled.Label>
                            <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                            {custom && (
                                <ActionComponent
                                    name={'title_custom'}
                                    valueColorText={titleCustom.valueColorText}
                                    valueFontSize={titleCustom.valueFontSize}
                                    isOculto={titleCustom.isOculto}
                                    textOculto={'Titulo'}
                                    setColorText={value =>
                                        setTitleCustom(prevValues => ({
                                            ...prevValues,
                                            valueColorText: value,
                                        }))
                                    }
                                    setValueFontSize={value =>
                                        setTitleCustom(prevValues => ({
                                            ...prevValues,
                                            valueFontSize: value,
                                        }))
                                    }
                                    setIsOculto={value =>
                                        setTitleCustom(prevValues => ({
                                            ...prevValues,
                                            isOculto: value,
                                        }))
                                    }
                                />
                            )}

                            <br />
                            <Styled.Label>Texto da Seção</Styled.Label>

                            {custom ? (
                                <Styled.Input
                                    value={text}
                                    onChange={(evn: any) => setText(evn.target.value)}
                                    rows={8}
                                    as="textarea"
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT}
                                />
                            ) : (
                                <ReactQuill
                                    theme="snow"
                                    modules={editorConfig.modules}
                                    formats={editorConfig.formats}
                                    value={text}
                                    onChange={val => {
                                        setText(val);
                                    }}
                                //className={'form-control'}
                                />
                            )}

                            {custom && (
                                <ActionComponent
                                    name={'text_secao'}
                                    valueColorText={textSecao.valueColorText}
                                    valueFontSize={textSecao.valueFontSize}
                                    isOculto={textSecao.isOculto}
                                    textOculto={'Texto'}
                                    setColorText={value =>
                                        setTextSecao(prevValues => ({
                                            ...prevValues,
                                            valueColorText: value,
                                        }))
                                    }
                                    setValueFontSize={value =>
                                        setTextSecao(prevValues => ({
                                            ...prevValues,
                                            valueFontSize: value,
                                        }))
                                    }
                                    setIsOculto={value =>
                                        setTextSecao(prevValues => ({
                                            ...prevValues,
                                            isOculto: value,
                                        }))
                                    }
                                />
                            )}
                            <Styled.CountLetter danger={text?.length >= MAX_CHAR_CONTENT}>
                                {text.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>

                            <Styled.Label>Botão primário</Styled.Label>
                            <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />
                            {custom && (
                                <Styled.Row style={{ margin: 10 }}>
                                    <Styled.Label style={{ color: '#000', margin: 0, marginRight: 10 }}>Ocultar: </Styled.Label>
                                    <Form.Check
                                        name="button-is-oculto"
                                        checked={buttonOculto}
                                        onChange={e => setButtonOculto(e.target.checked)}
                                        type="switch"
                                        id="button-is-oculto"
                                    />
                                </Styled.Row>
                            )}

                            <Styled.Label>Link do botão primario</Styled.Label>
                            <Styled.Input
                                className={
                                    link_interno &&
                                    link_interno.length > 0 &&
                                    !isValidLink(link_interno, !link_interno.startsWith('/')) &&
                                    'is-invalid'
                                }
                                value={link_interno}
                                onChange={(evt: any) => setLinkInterno(evt.target.value)}
                            />
                            <Styled.SubLabel>
                                <LabelRedirecionamento />
                            </Styled.SubLabel>

                            {custom && !buttonOculto && (
                                <>
                                    <Styled.Label>Aparência do botão:</Styled.Label>{' '}
                                    <SelectedButtons value={typeButton} setSelected={setTypeButton} />
                                </>
                            )}

                            <Styled.Label>Tipo de redirecionamento</Styled.Label>
                            <Select
                                isSmall
                                value={getOptionFromValue(tipoRedirecionamento)}
                                itens={tipoRedirecionamentoOptions}
                                onSelected={o => setTipoRedirecionamento(o.value as TipoRedirecionamento)}
                            />
                            <Styled.SubLabel>
                                <LabelObs />
                            </Styled.SubLabel>
                        </div>

                        <Styled.RigthArea style={{ justifyContent: 'space-between' }}>
                            <AdminImageUploader
                                onSelect={() => { }}
                                setBase64={b => setCover(b)}
                                size={'sm'}
                                title={'Imagem do banner'}
                                preImageB64={cover}
                                recommendedDimension={{ w: 460, h: 530 }}
                            />
                            {custom && (
                                <BackgroundComponent
                                    colorBackground={colorBackground}
                                    colorDescription={textSecao.valueColorText}
                                    colorTitle={titleCustom.valueColorText}
                                    setColor={setColorBackground}
                                />
                            )}
                            <br />
                            <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                                {modePage && (
                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                        <RedSolidButton style={{ marginBottom: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                            Deletar
                                        </RedSolidButton>
                                    </AllowedGroupsValidator>
                                )}
                                <GradientButton disabled={submitting || !isValidInput()} onClick={submit}>
                                    {submitting ? 'Salvando...' : 'Salvar'}
                                </GradientButton>
                            </div>
                        </Styled.RigthArea>
                    </>
                ) : (
                    <Loading />
                )}
            </Styled.Container>
        </>
    );
};

export default AdminEditSimpleImage;
