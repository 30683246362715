import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyleBibliotecaHome = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 237px;
        gap: 37px;
        background-color: ${Colors.SoftBlue};
        color: ${Colors.White};

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            padding: 20px;
            height: auto;
        }
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        max-width: 500px;
        text-align: left;

         @media screen and (max-width: 1100px) {
          text-align: center;
        }
    `,
};

export default StyleBibliotecaHome;
