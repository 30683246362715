import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { DangerButton, GradientButton, OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { FetchUtils } from 'core/util/FetchUtils';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import AdminEditModalDelete from './AdminEditModalDelete';
import AdminEditorStyled from './AdminEditor.styled';
import { ItemFaqService } from 'core/http/service/ItemFaq.service';
import ItemFaq from 'models/faq/ItemFaq';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import ReactSwitch from 'react-switch';
import Select, { SelectOptionType } from 'components/Select';
import { isStringsValid } from '../newContent/isValidNewContentInput';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { PaginasEnum } from 'enums/PaginasEnum';
import { PaginasIntermediariasEnum } from '../relatedContent/PaginasIntermediariasEnum';
import AllowedGroupsValidator from '../allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

const FaqStyles = {
    AdminEditFaqContainer: styled.div`
        margin-top: 20px;
        margin-bottom: 70px;
    `,
    Title: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,

    ContentContainer: styled.div`
        display: flex;
        flex-direction: column;
        gap: 20px;

        margin-left: 120px;
    `,
    InputGroup: styled.div`
        display: flex;
        flex-direction: column;
        gap: 7px;
    `,

    ConteudosContainer: styled.div`
        display: grid;
        grid-template-columns: 1fr 450px;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px #ddd solid;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,
    RightColumn: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    `,
};

const isMobile = () => IsMobileMaxWidth();

interface IAdminEditFaq {
    onSaved: () => void;
}

const AdminEditFaq: FunctionComponent<IAdminEditFaq> = ({ onSaved }: IAdminEditFaq) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [reordering, setReordering] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const itemFaqService = new ItemFaqService();
    const paginaService = new PaginaIntermediariaService();

    const [itensFaq, setItensFaq] = useState<ItemFaq[]>([]);
    const [pagina, setPagina] = useState<AdminPaginaIntermediaria | null>(null);

    const [descricaoSecao, setDescricaoSecao] = useState<string>('');

    const MAX_CHAR_DESCRICAO_SECAO = 1000;
    const MAX_CHAR_PERGUNTA = 100;

    const loadItensFaq = async () => {
        const responsePagina: any = await paginaService.listar();
        const output = responsePagina.data.map((item: any) => new AdminPaginaIntermediaria().fromJSON(item));

        output.forEach((element: AdminPaginaIntermediaria) => {
            if (element.pagina === PaginasIntermediariasEnum.FAQ) {
                setPagina(element);
                setDescricaoSecao(element.descricao);
            }
        });

        const responseFaq: any = await itemFaqService.listarPublico();
        setItensFaq(responseFaq.data.map((item: any) => new ItemFaq().fromJSON(item)));
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoading, loadItensFaq);
    }, []);

    const isValidInputs = (idx: number): boolean => {
        const itemFaq = itensFaq[idx];

        return isStringsValid([itemFaq.pergunta, itemFaq.resposta]);
    };

    const setPergunta = (idx: number, value: string) => {
        const clone = cloneDeep(itensFaq);
        clone[idx].pergunta = value.substring(0, 200);
        setItensFaq(clone);
    };

    const setResposta = (idx: number, value: string) => {
        const clone = cloneDeep(itensFaq);
        clone[idx].resposta = value;
        setItensFaq(clone);
    };

    const setAtivo = (idx: number, value: boolean) => {
        const clone = cloneDeep(itensFaq);
        clone[idx].ativo = value;
        setItensFaq(clone);
    };

    //UPDATE ORDER
    const updateOrder = () => {};

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            const count = itensFaq.filter(c => c.codigo > 0).length;

            for (let i = 0; i < count; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [itensFaq]
    );

    const setupOrder = async (fromIndex: number, toIndex: number | string) => {
        const clone = cloneDeep(itensFaq);

        if (typeof toIndex == 'string') toIndex = parseInt(toIndex);

        const item = cloneDeep(clone[fromIndex]);
        item.ordem = toIndex;

        setReordering(true);
        setLoading(true);

        const response = await itemFaqService.updateOrder(item);
        if (response.status === 200) {
            setItensFaq(response.data.map((item: any) => new ItemFaq().fromJSON(item)));
            ToastSuccess('Ordem Atualizada!');
        } else {
            ToastError('Houve um erro ao atualizar a ordem do FAQ');
        }
        setReordering(false);
        setLoading(false);
    };

    const excluirConteudo = async (idx: number) => {
        if (confirm('Excluir este curso?')) {
            setDeleting(true);

            const clone = cloneDeep(itensFaq);

            const response = await itemFaqService.excluir(clone[idx].codigo);
            if (response.status === 200) {
                FetchUtils.loadingHof(setLoading, loadItensFaq);
                setShowModalDelete(true);
            } else {
                ToastError('Houve um erro ao tentar excluir o curso. Por favor, tente novamente!');
            }
            setDeleting(false);
        }
    };

    const removerItem = (idx: number) => {
        if (confirm('Remover esta pergunta do formulário?')) {
            let clone = cloneDeep(itensFaq);

            clone = clone.filter((item, idxItem) => {
                return idx !== idxItem;
            });

            setItensFaq(clone);
        }
    };

    const addItemFaq = () => {
        const clone = cloneDeep(itensFaq);

        const itemFaq = new ItemFaq();

        clone.push(itemFaq);
        setItensFaq(clone);
    };

    const submit = async (idx: number) => {
        setSubmitting(true);
        const clone = cloneDeep(itensFaq);

        let response;

        if (!clone[idx].codigo || clone[idx].codigo === 0) {
            response = await itemFaqService.inserir(clone[idx]);
        } else {
            response = await itemFaqService.editar(clone[idx].codigo, clone[idx]);
        }

        if (response.status === 200 || response.status === 201) {
            const objResposta: ItemFaq = new ItemFaq().fromJSON(response.data);
            clone[idx].codigo = objResposta.codigo;
            setItensFaq(clone);
            onSaved();
        } else {
            ToastError('Houve um erro ao tentar salvar o curso. Por favor, tente novamente!');
        }

        setSubmitting(false);
    };

    const submitDescricaoSecao = async () => {
        if (pagina) {
            pagina.descricao = descricaoSecao;
            await paginaService.editar(pagina.codigo, pagina);
        }
        onSaved();
    };

    const onSaveDescricaoSecao = () => {
        FetchUtils.loadingHof(setSubmitting, submitDescricaoSecao);
    };

    return (
        <FaqStyles.AdminEditFaqContainer>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <FaqStyles.ContentContainer>
                        <FaqStyles.InputGroup style={{ width: 'calc(50%)' }}>
                            <FaqStyles.Title>Editar</FaqStyles.Title>

                            <div>
                                <AdminEditorStyled.Input
                                    value={descricaoSecao}
                                    onChange={(e: any) => {
                                        setDescricaoSecao(e.target.value);
                                    }}
                                    maxLength={MAX_CHAR_DESCRICAO_SECAO}
                                />

                                <AdminEditorStyled.CountLetter danger={descricaoSecao.length >= MAX_CHAR_DESCRICAO_SECAO}>
                                    {descricaoSecao.length}/{MAX_CHAR_DESCRICAO_SECAO}
                                </AdminEditorStyled.CountLetter>
                            </div>

                            <GradientButton
                                style={{ marginBottom: '15px', alignSelf: 'flex-end' }}
                                onClick={async () => {
                                    await onSaveDescricaoSecao();
                                }}
                                disabled={submitting}
                            >
                                {submitting ? 'Salvando...' : 'Salvar'}
                            </GradientButton>
                        </FaqStyles.InputGroup>

                        <FaqStyles.InputGroup style={{ width: 'calc(50%)' }}>
                            <FaqStyles.Title>Editar Perguntas e Respostas</FaqStyles.Title>
                        </FaqStyles.InputGroup>

                        {itensFaq.map((item, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <FaqStyles.Title style={{ paddingTop: '5px 0', marginTop: '35px' }}>FAQ {idx + 1}</FaqStyles.Title>

                                    <FaqStyles.ConteudosContainer>
                                        <div>
                                            <AdminEditorStyled.Label>Pergunta</AdminEditorStyled.Label>
                                            <AdminEditorStyled.Input
                                                value={item.pergunta}
                                                onChange={(e: any) => setPergunta(idx, e.target.value)}
                                                maxLength={MAX_CHAR_PERGUNTA}
                                            />
                                            <AdminEditorStyled.CountLetter danger={item.pergunta.length >= MAX_CHAR_PERGUNTA}>
                                                {item.pergunta.length}/{MAX_CHAR_PERGUNTA}
                                            </AdminEditorStyled.CountLetter>

                                            <AdminEditorStyled.Label>Resposta</AdminEditorStyled.Label>

                                            <AdminEditorStyled.Quill>
                                                <ReactQuill
                                                    id="toolbar"
                                                    theme="snow"
                                                    modules={editorConfig.modules}
                                                    formats={editorConfig.formats}
                                                    value={item.resposta}
                                                    onChange={val => {
                                                        setResposta(idx, val);
                                                    }}
                                                />
                                            </AdminEditorStyled.Quill>

                                            <AdminEditorStyled.Label>Ativo</AdminEditorStyled.Label>
                                            <AdminEditorStyled.CkeckContainer>
                                                <span style={{ marginRight: '10px' }}>Ativo</span>
                                                <ReactSwitch
                                                    height={18 * 0.7}
                                                    width={40 * 0.7}
                                                    handleDiameter={22 * 0.7}
                                                    onHandleColor={'#0B78C8'}
                                                    offHandleColor={'#0B78C8'}
                                                    onColor={'#BFDFF7'}
                                                    offColor={'#dfdfdf'}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    checked={item.ativo ?? false}
                                                    onChange={value => setAtivo(idx, value)}
                                                />
                                            </AdminEditorStyled.CkeckContainer>

                                            {item.codigo > 0 && (
                                                <>
                                                    <AdminEditorStyled.Label>Ordem</AdminEditorStyled.Label>
                                                    <Select
                                                        itens={itemsOrder(idx)}
                                                        value={{
                                                            value: idx,
                                                            label: String(idx + 1),
                                                        }}
                                                        onSelected={o => setupOrder(idx, o.value)}
                                                        disabled={submitting || deleting || reordering}
                                                        isSmall={true}
                                                    />
                                                </>
                                            )}
                                        </div>

                                        <FaqStyles.RightColumn>
                                            <br />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <GradientButton
                                                    style={{ marginBottom: '15px' }}
                                                    onClick={async () => await submit(idx)}
                                                    disabled={!isValidInputs(idx) || submitting}
                                                >
                                                    {submitting ? 'Salvando...' : 'Salvar'}
                                                </GradientButton>
                                                {item.codigo > 0 ? (
                                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                        <DangerButton disabled={deleting} onClick={async () => excluirConteudo(idx)}>
                                                            {deleting ? 'Excluindo...' : 'Excluir'}
                                                        </DangerButton>
                                                    </AllowedGroupsValidator>
                                                ) : (
                                                    <DangerButton onClick={async () => removerItem(idx)} style={{ fontSize: '0.8em' }}>
                                                        Remover do formulário
                                                    </DangerButton>
                                                )}
                                            </div>
                                        </FaqStyles.RightColumn>
                                    </FaqStyles.ConteudosContainer>
                                </React.Fragment>
                            );
                        })}

                        <AdminEditorStyled.BottomButton>
                            <OutlineButton onClick={addItemFaq}>+ Adicionar Pergunta</OutlineButton>
                        </AdminEditorStyled.BottomButton>
                    </FaqStyles.ContentContainer>
                </div>
            )}

            <AdminEditModalDelete
                show={showModalDelete}
                onHide={() => {
                    setShowModalDelete(false);
                }}
            />
        </FaqStyles.AdminEditFaqContainer>
    );
};

export default AdminEditFaq;
