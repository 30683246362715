import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';
import Colors from '../../../../../enums/Colors';

export const ContainerCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 10px;
    gap: 13px;
    background-color: #fcfcfc;
    color: ${Colors.Blue};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    width: 90%;

    margin-bottom: 0;
`;

export const Content = styled.div(
    new FullCss({ padding: '0', position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }).getCss()
);

export const InnerContent = styled.div(new FullCss({ display: 'flex', flexDirection: 'row', gap: '16px' }).getCss());

export const SideContent = styled.div(new FullCss({ width: '41px' }).getCss());

export const LateralContent = styled.div(new FullCss({ display: 'flex', flexDirection: 'column', width: '65%' }).getCss());

export const Title = styled.p(
    new FullCss({
        fontFamily: 'Barlow',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '22px',
        maxWidth: '290px',
        wordWrap: 'break-word',
        overflowX: 'hidden',
    }).getCss()
);

export const Text = styled.p(
    new FullCss({
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        color: Colors.Blue,
        fontStyle: 'normal',
    }).getCss()
);

export const DescriptionText = styled.p(
    new FullCss(
        { fontSize: '16px', lineHeight: '19px', fontWeight: '400', padding: '10px' },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(400), {
            padding: '10px 0 0 0',
        })
    ).getCss()
);

export const DropIcon = styled.img(
    new FullCss({ position: 'absolute', top: '5px', left: '90%', width: '25px', cursor: 'pointer', zIndex: '1' }).getCss()
);

export const Separator = styled.div(new FullCss({ paddingTop: '20px', borderBottom: '1px solid #cbd2e0', width: '50%' }).getCss());

export const YellowCircle = styled.div(
    new FullCss({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '41px',
        width: '41px',
        backgroundColor: '#FFCA00',
        gap: '10px',
        borderRadius: '50%',
    }).getCss()
);

export const InnerCircleText = styled.p(new FullCss({ fontWeight: '700', fontSize: '30px', color: Colors.Blue }).getCss());

export const ButtonContainer = styled.div(
    new FullCss({ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', width: '100%' }).getCss()
);

export const InnerButton = styled.button(
    new FullCss({
        padding: '8px 16px',
        width: '100%',
        height: '37px',
        background: Colors.Blue,
        borderRadius: '6px',
        border: 'none',
        color: 'white',
        textAlign: 'center',
    }).getCss()
);

export default Container;
