import ABTest from 'components/ab_tests/ABTest';
import { getURLParamFromVersion, stringFromTestVersion } from 'enums/TestVersions';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import Menu from '../menu/Menu';
import BarraPesquisa from '../pesquisa/barraPesquisa/BarraPesquisa';
import Main from './components/Main';
import ResultadoPesquisa from '../pesquisa/resultadoPesquisa/ResultadoPesquisa';
import { PesquisaContext } from './context/PesquisaContext';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
interface IHomePage {}

const UTMContentName = 'utm_content';

const HomePage = ({ ...props }: IHomePage) => {
    const history = useHistory();
    const { isSearchView } = useContext(PesquisaContext.Context);

    const setupRedirectToVersion = () => {
        const serachparams = new URLSearchParams(location.search);

        // const versionTest = ABTest.getCurrentVersion();

        // if (serachparams.has(UTMContentName)) {
        //     const value = serachparams.get(UTMContentName);

        //     if (value && !value.includes('testeAB-versao')) {
        //         serachparams.delete(UTMContentName);
        //         serachparams.append(UTMContentName, getURLParamFromVersion(versionTest));
        //     }
        // } else {
        //     serachparams.append(UTMContentName, getURLParamFromVersion(versionTest));
        // }

        history.push(location.pathname + '?' + serachparams.toString());
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setupRedirectToVersion();
        // ABTest.sendGTM_Event();
    }, []);

    return (
        <>
            <Helmet>
                <title>humane - plataforma para o desenvolvimento de educadores</title>
                <meta
                    name="description"
                    content="Espaço criado pelo Instituto Ayrton Senna para disponibilizar suas soluções de formação de professores, coordenadores pedagógicos e gestores escolares"
                    data-react-helmet="true"
                />
            </Helmet>

            {/*  <Menu homepage /> */}

            <DynamicHeader />

            <Main />

            {/* {isSearchView && <BarraPesquisa />} */}
        </>
    );
};

export default HomePage;
