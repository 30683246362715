import { jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminTag extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('descricao')
    @jsonProperty(String)
    content: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String, null)
    ultimaAtualizacao: string = '';

    @jsonName('quantidadeUtilizada')
    @jsonProperty(Number, null)
    quantity?: number | null = 0;
}

export default AdminTag;
