import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const Container = styled.div(
    new FullCss(
        {
            padding: '100px 85px 50px 13.5%',
            margin: '0 auto',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            padding: '40px 20px 20px 20px',
        })
    ).getCss()
);

export const Content = styled.div(
    new FullCss({
        display: 'flex',
        position: 'relative',
    }).getCss()
);

export const TopContent = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '20px',
            marginBottom: '20px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'column',
        })
    ).getCss()
);

export const InnerTopContent = styled.div(
    new FullCss({
        display: 'flex',
        flexDirection: 'column',
    }).getCss()
);

export const Title = styled.p(
    new FullCss({
        fontWeight: '700',
        fontSize: '40px',
        letterSpacing: '0.3px',
        lineHeight: '35px',
        color: Colors.Blue,
        display: 'flex',
        justifyContent: 'space-between',
    }).getCss()
);

export const Byline = styled.p(
    new FullCss({
        fontWeight: '400',
        fontSize: '28px',
        letterSpacing: '0.3px',
        lineHeight: '34px',
        color: Colors.Blue,
    }).getCss()
);

export const RightContainer = styled.div(
    new FullCss(
        {
            display: 'flex',
            width: 'fit-content',
            transform: 'translateY(-15px)',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {})
    ).getCss()
);

export const ColunmContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .custom-tooltip {
        width: 130px;
        padding: 8px 8px;
        align-self: center;
    }
`;

export const PrimaryButtonCustom = styled(PrimaryButton)`
    width: fit-content;
    height: 35px;
    padding: 0 15px;
    margin-right: 20px;
    border-radius: 15px;

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #003c78;
        margin-left: 10px;
    }

    svg {
        transform: scale(1.4);
    }
`;

export const NavyLabel = styled.p(
    new FullCss(
        {
            fontFamily: 'Inter',
            fontWeight: '700',
            fontSize: '12px',
            lineHeight: '15px',
            width: '100%',
            textAlign: 'left',
            marginBottom: '10px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {})
    ).getCss()
);

export const Icon = styled.img(
    new FullCss(
        {
            cursor: 'pointer',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {})
    ).getCss()
);

export const BottomContent = styled.div(
    new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            position: 'relative',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            flexDirection: 'column',
            alignItems: 'center',
        })
    ).getCss()
);

export const ButtonInitialPage = styled.button(
    new FullCss(
        {
            border: '2px solid #00A8E9',
            borderRadius: '6px',
            width: '359px',
            height: '56px',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '-0.01em',
            fontFeatureSettings: "'calt' off",
            color: '#00A8E9',
            backgroundColor: '#F7F7F7',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '100%',
            }),
            new MediaQuery(':hover', {
                border: '2px solid #003C78',
                background: '#003C78',
                color: 'white',
            }),
        ]
    ).getCss()
);

export const ButtonNext = styled(ButtonInitialPage)(
    new FullCss(
        {
            border: '2px solid #003C78',
            background: '#003C78',
            width: '461px',
            height: '56px',
            color: '#FCFCFC',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                width: '100%',
            }),
            new MediaQuery(':hover', {
                border: '2px solid #003C78',
                background: '#F7F7F7',
                color: '#003C78',
            }),
        ]
    ).getCss()
);

export const LinkEmail = styled.a`
    @media only screen and (max-width: ${MobileMaxWidth}px) {
    }
`;
