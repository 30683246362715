import styled from 'styled-components';

const StyledCardNumeroDestaque = {
    Container: styled.div`
        width: 150px;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
    `,

    Img: styled.img`
        width: 120px;
        height: 120px;
        object-fit: cover;
    `,

    Info: styled.h1<{ color?: string; size?: string }>`
        font-weight: bold;
        text-align: center;
        font-size: ${({ size }) => size && size + 'px'};
        color: ${({ color }) => color && color};
    `,

    Detail: styled.p<{ color?: string; size?: string }>`
        text-align: center;
        font-size: ${({ size }) => size && size + 'px'};
        color: ${({ color }) => color && color};
    `,
};

export default StyledCardNumeroDestaque;
