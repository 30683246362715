import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledAccordion = {
    Accordion: styled.div<{ isOpen: boolean }>`
        width: 100%;
        margin-bottom: 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 20px;
        }
    `,

    Container: styled.div<{ isEtapa: boolean; isOpen: boolean }>`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${props => (props.isEtapa ? Colors.Blue : '#1d5894')};
        width: 100%;
        color: #fcfcfc;
        height: 70px;
        padding-left: 10px;
        padding-right: 20px;
        transition: all 0.1s;
        word-break: break-all;

        &:first-child {
            border-radius: ${props => (props.isOpen ? '8px 8px 0px 0px' : '8px')};
        }

        &:last-child {
            border-radius: ${props => (props.isOpen ? '0px 0px 8px 8px' : '8px')};
        }

        &:hover {
            background-color: ${props => !props.isEtapa && Colors.DarkBlue2};
        }

        cursor: pointer;
    `,

    Div: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
    `,

    Title: styled.label`
        font-weight: 500;
        font-size: 20px;
        margin-left: 20px;
        margin-right: 5px;
        cursor: pointer;
        user-select: none;
    `,

    Label: styled.label`
        font-size: 20px;
        font-weight: 300;
        cursor: pointer;
        user-select: none;
        word-break: keep-all;
    `,
};

export default StyledAccordion;
