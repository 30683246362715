import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const StyledDragDrop = {
    Container: styled.div`
        border-radius: 10px;
        padding: 15px 45px;
        background-color: white;
        margin-left: 120px;
    `,

    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
    `,

    Label: styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #00a8e9;
        margin-bottom: 10px;
    `,

    FormContainer: styled.div`
        max-width: 700px;
        margin-top: 20px;
    `,

    FormSections: styled.div`
        max-width: 820px;
        margin-top: 20px;
    `,

    BottomContainer: styled.div`
        width: 730px;
        margin-top: 20px;
    `,

    SecoesContainer: styled.div``,

    Handler: styled.span`
        color: #888;
        margin-right: 10px;
        cursor: move;
    `,

    RedirectButton: styled.div`
        width: 730px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #c9c9c9;
        user-select: none;

        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        margin: 0;

        :hover {
            background-color: #f8f8f8;
        }

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #5f758b;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,

    ListItem: styled.div``,

    Dropzone: styled.div<{ isHover?: boolean }>`
        height: 18px;
        margin: 5px 0;
        border-radius: 5px;
        transition: background-color 200ms;
        background-color: ${props => (props.isHover ? '#90CAF999' : 'transparent')};
    `,
};

export default StyledDragDrop;
