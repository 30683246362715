import { SelectOptionType } from 'components/Select';
import BiblioItemCategoriaModel from 'models/biblioteca/BiblioItemCategoriaModel';
import { AdminBiblioAnexoType } from 'pages/admin/components/newContent/submitAttach';
import { BiblioBuscaType } from 'pages/biblioteca_v2/context/BibliotecaProvider';
import { GenericService } from '../Generic.service';

export default class BiblioConteudoService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public static getItemPorPagina = (isMobile?: boolean) => (isMobile ? 4 : 9);

    public buscaInicial() {
        return this.get('buscaInicial');
    }

    public buscaInicialItens(auth: boolean, pagina: number, por_pagina: number, filtro = 'TODOS') {
        return this.get(`${auth ? '' : 'public/'}listarFiltroPadrao`, { filtro, pagina, por_pagina });
    }

    public buscaGeralItens(data: BiblioBuscaType, auth: boolean) {
        return this.get(`${auth ? '' : 'public/'}buscaGeral`, data);
    }

    public buscaInicialPublico() {
        return this.get('public/buscaInicial');
    }

    public buscaPorCategoria(categoria: string, pagina = 1, auth: boolean) {
        return this.get(`${auth ? '' : 'public/'}buscarItensPorCategoria/${categoria}`, {
            categoria,
            pagina,
            por_pagina: BiblioConteudoService.getItemPorPagina(),
        });
    }

    public buscaPorCodigo(categoria: string, pagina = 1, status: string = 'PUBLICADO') {
        return this.get('buscar', { categoria, pagina, por_pagina: BiblioConteudoService.getItemPorPagina(), status });
    }

    public buscaPorTermo(filtro: string, pagina = 1, auth: boolean, status: string = 'PUBLICADO') {
        return this.get(!auth ? 'public/buscar' : 'buscar', { filtro, pagina, por_pagina: BiblioConteudoService.getItemPorPagina(), status });
    }

    public buscaPublicaPorCodigoCategoria(categoria: string, pagina = 1, status: string = 'PUBLICADO') {
        return this.get('public/buscar', { categoria, pagina, por_pagina: BiblioConteudoService.getItemPorPagina(), status });
    }

    public buscaItem(id: string | number) {
        return this.get(`buscarItem/${id}`);
    }

    public buscaPublicoItem(id: string | number) {
        return this.get(`public/buscarItem/${id}`);
    }

    public buscaItemBySlug(slug: string) {
        return this.get(`buscarItemBySlug/${slug}`);
    }

    public buscaPublicoItemBySlug(slug: string) {
        return this.get(`public/buscarItemBySlug/${slug}`);
    }

    public buscarPorFiltro(filtro: string, pagina = 1, status: string = 'PUBLICADO', categoria?: string) {
        return this.get('buscar', { categoria, pagina, por_pagina: BiblioConteudoService.getItemPorPagina(), filtro, status });
    }

    public buscarTodosItens(
        pagina: number,
        por_pagina = BiblioConteudoService.getItemPorPagina(),
        filtro?: string,
        order?: string,
        status?: string,
        tipo?: number
    ) {
        return this.get('buscar', { filtro, pagina, por_pagina, ordem: order ?? 'ultimos_adicionados:desc', status, tipo });
    }

    public buscarTodosFiltrados(por_pagina = BiblioConteudoService.getItemPorPagina(), _filtro: any, pagina?: number, order?: string | number) {
        return this.get('buscar', { pagina, por_pagina, ordem: order, ..._filtro });
    }

    public buscarTodosItensFiltrados(pagina: number, por_pagina = BiblioConteudoService.getItemPorPagina(), status?: string, tipo?: number) {
        return this.get('buscar', { pagina, por_pagina, status, tipo });
    }

    public buscarAnexoTipo(content_id: number, tipo: AdminBiblioAnexoType) {
        return this.get(`${content_id}/tipo/anexo`, { tipo });
    }

    public buscarPublicaAnexoTipo(content_id: number, tipo: AdminBiblioAnexoType) {
        return this.get(`public/${content_id}/tipo/anexo`, { tipo });
    }

    public buscarSubatividades(content_id: number) {
        return this.get(`item/${content_id}/atividades`);
    }

    public buscarPublicaSubatividades(content_id: number) {
        return this.get(`public/item/${content_id}/atividades`);
    }

    public buscarAnexoSubatividades(content_id: number) {
        return this.get(`item/atividade/${content_id}/anexo`);
    }

    public buscarPorTag(tag: string, pagina: number = 1, por_pagina = BiblioConteudoService.getItemPorPagina()) {
        return this.get(`buscarPorTag`, { tag, pagina, por_pagina });
    }

    public buscarPublicoPorTag(tag: string, pagina: number = 1, por_pagina = BiblioConteudoService.getItemPorPagina()) {
        return this.get(`public/buscarPorTag`, { tag, pagina, por_pagina });
    }

    public buscarPublicoPorTagFiltro(
        tagList: string,
        filtro: string,
        auth: boolean,
        pagina: number = 1,
        por_pagina = BiblioConteudoService.getItemPorPagina()
    ) {
        return this.get(auth ? `buscarPorTagFiltro` : `public/buscarPorTagFiltro`, { tagList, filtro, pagina, por_pagina });
    }

    public buscarTags() {
        return this.get('public/tags-itens-publicos');
    }

    public buscarCategorias(isAuth: boolean) {
        return this.get(!isAuth ? 'public/categorias' : 'categorias');
    }

    public editarCategoria(data: BiblioItemCategoriaModel) {
        return this.put('categoriaItem', data.toJSON());
    }

    public criarCategoria(descricao: string, comportamento: string, rota: string, perfilExclusivo?: string) {
        return this.post('categoriaItem', { descricao, comportamento, rota, perfil_exclusivo: perfilExclusivo });
    }

    public listarPublicoResumido() {
        return this.get('listarPublicoResumido');
    }

    public listaPalavrasProibidas() {
        return this.get('public/palavrasProibidasAll');
    }
}
