import React, { useContext } from 'react';
import Menu from 'pages/menu/Menu';
import ResultadoPesquisa from './resultadoPesquisa/ResultadoPesquisa';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from './barraPesquisa/BarraPesquisa';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface IPesquisa {}

const Pesquisa = ({}: IPesquisa) => {
    const { isSearchView } = useContext(PesquisaContext.Context);

    return (
        <>
            {/*  <Menu homepage /> */}
            <DynamicHeader />

            <ResultadoPesquisa />

            {/* {isSearchView && <BarraPesquisa />} */}
        </>
    );
};

export default Pesquisa;
