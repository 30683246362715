import Size from '../../models/Size';
import useWindowSize from '../../hooks/useWindowSize';
import { MobileBiggerWidth, MobileMaxWidth, ResizeMediumWidth } from '../../constants/Dimensions';

export const IsMobileMaxWidth = (maxWidth?: number): boolean => {
    const size: Size = useWindowSize();

    return size.width !== undefined && size.width <= (maxWidth ?? MobileMaxWidth);
};

export const IsMobileBiggerWidth = (maxWidth?: number): boolean => {
    const size: Size = useWindowSize();

    return size.width !== undefined && size.width <= (maxWidth ?? MobileBiggerWidth);
};

export const IsMediumSizeMaxWidth = (): boolean => {
    const size: Size = useWindowSize();

    return size.width !== undefined && size.width <= ResizeMediumWidth;
};

export const IsCustomMaxWidth = (width: number): boolean => {
    const size: Size = useWindowSize();

    return size.width !== undefined && size.width <= width;
};

export const IsCustomMinWidth = (width: number): boolean => {
    const size: Size = useWindowSize();

    return size.width !== undefined && size.width >= width;
};
