import styled from 'styled-components';
import Colors from '../../../enums/Colors';
import { MobileMaxWidth } from '../../../constants/Dimensions';
import { media760, mediaOnlyScreenMaxWidth } from '../../../core/util/cssHelp';

export const PriorizacaoStyle = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            backgroundColor: Colors.White,
            padding: '48px',
        },
        {
            padding: '48px 20px',
        },
        1300
    )
);

export const QuestionContainer = styled.div({
    marginTop: '11px',
});

export const SelectionSection = styled.section(
    mediaOnlyScreenMaxWidth(
        {
            marginTop: '50px',
            display: 'flex',
        },
        {
            flexDirection: 'column',
        },
        1300
    )
);

export const SelectionStyled = styled.div(
    mediaOnlyScreenMaxWidth(
        {
            flex: 1,
            flexDirection: 'column',
        },
        {
            marginTop: '20px',
        },
        1300
    )
);

export const SelectLabel = styled.p({
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.3px',
    marginBottom: '9px',
    color: Colors.Grey3,
});

export const ButtonContainer = styled.div({
    marginTop: '40px',
});
