import React from 'react';
import HomeIcon from '../../assets/images/home.svg';
import LogoRealizandoEscolhas from '../../assets/images/logoRealizandoEscolhasMobile.svg';
import { ImgHome, ImgLogo, RealizandoEscolhasHeaderMobileStyle } from './RealizandoEscolhasHeaderMobile.styled';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';

interface IRealizandoEscolhasHeader {}

const RealizandoEscolhasHeaderMobile = ({}: IRealizandoEscolhasHeader) => {
    const history = useHistory();
    const goToHomePage = () => {
        history.push('/realizandoEscolhas/home');
    };

    return (
        <RealizandoEscolhasHeaderMobileStyle>
            <ImgHome
                src={HomeIcon}
                onClick={() => {
                    GTM_Page_Realizando_Escolhas('header_mobile', 'acessar_home_btn');
                    goToHomePage();
                }}
            />
            <ImgLogo src={LogoRealizandoEscolhas} />
        </RealizandoEscolhasHeaderMobileStyle>
    );
};
export default RealizandoEscolhasHeaderMobile;
