import React, { CSSProperties } from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import Colors from '../enums/Colors';
import { CSSObject } from 'styled-components';

export type SelectOptionType = {
    value: string | number;
    label: string;
};

type IsMulti = false;

export interface ISelect {
    style?: any;
    width?: string;
    isSmall?: boolean;
    disabled?: boolean;
    placeholder?: string;
    value?: SelectOptionType;
    itens: SelectOptionType[];
    defaultValue?: SelectOptionType;
    optionWidth?: number;
    onSelected?: (selected: SelectOptionType) => void;
}

const Select = ({ itens, width = '245px', onSelected, value, defaultValue, placeholder, disabled = false, isSmall, optionWidth }: ISelect) => {
    const customControlStyles: CSSProperties = {
        padding: `${isSmall ? '0px 3px' : '6px 8px'}`,
        borderColor: '#DADADA',
        fontFamily: 'Montserrat',
        cursor: 'pointer',
        width: `${width}`,
        height: `${isSmall ? '40px' : ''}`,
        fontSize: `${isSmall ? '12px' : ''}`,
    };

    const customMenuStyles: CSSProperties = {
        boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        padding: '7px',
        width: `${width}px`,
    };

    const customOptionStyle: CSSProperties = {
        padding: '12px',
        cursor: 'pointer',
        fontSize: '15px',
        transition: '0.2s',
        fontStyle: 'normal',
        paddingLeft: '20px',
        borderRadius: '6px',
        fontFamily: 'Montserrat',
    };

    if (optionWidth) customOptionStyle.width = `${optionWidth}px`;

    const selectStyle: StylesConfig<SelectOptionType, IsMulti> = {
        control: (provided: any) => ({
            ...provided,
            ...customControlStyles,
        }),

        menu: (provided: any) => ({
            ...provided,
            ...customMenuStyles,
        }),

        option: (provided: any, state) => ({
            ...provided,
            ...customOptionStyle,
            color: state.isSelected ? Colors.SoftBlue2 : Colors.Grey4,
            backgroundColor: state.isFocused ? '#F1FFFD' : Colors.White,
            fontWeight: state.isSelected ? 'bold' : 'normal',
        }),

        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    return (
        <ReactSelect
            value={value}
            options={itens}
            styles={selectStyle}
            isDisabled={disabled}
            placeholder={placeholder ?? 'Selecione'}
            defaultValue={defaultValue}
            onChange={(sel: any) => {
                if (onSelected && !disabled) onSelected(sel);
            }}
        />
    );
};

export default Select;
