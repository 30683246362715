import React from 'react';
import styled from 'styled-components';

import startEnable from '../../../../images/start-enable.svg';
import startDisable from '../../../../images/start-disable.svg';

export interface IAdminRateStar {
    rate: number;
}

const Image = styled.img`
    height: 0.8rem;
    transform: translateY(1px);
    margin: 0 2px;
`;

const AdminRateStar = ({ rate }: IAdminRateStar) => {
    const generateId = () => Math.random() * Number.MAX_SAFE_INTEGER;
    const MAX = 5;

    return (
        <>
            {[...Array(rate)].map(_ => {
                return <Image src={startEnable} key={generateId()} />;
            })}

            {[...Array(MAX - rate)].map(_ => {
                return <Image src={startDisable} key={generateId()} />;
            })}
        </>
    );
};

export default AdminRateStar;
