import styled from 'styled-components';

const StyleSecaoLikes = {
    Container: styled.div`
        background-color: #003c78;
        color: #ffffff;
        display: flex;
        width: 100%;
        height: 400px;

        @media screen and (max-width: 1260px) {
            height: 470px;
        }

        @media screen and (max-width: 1015px) {
            height: 650px;
        }

        @media screen and (max-width: 950px) {
            height: 800px;
        }

        @media screen and (max-width: 760px) {
            height: 750px;
        }
    `,

    ItemContainer: styled.div`
        display: flex;
        margin-bottom: 26px;
        font-family: Barlow;
        justify-content: space-between;
        padding-right: 32px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 123.5%; /* 19.76px */
        letter-spacing: 0.25px;
        gap: 1.5rem;

        @media screen and (max-width: 1015px) {
            flex-direction: column;
        }
    `,

    Content: styled.div<{ isMobile: boolean }>`
        width:   ${({isMobile}) => isMobile ? "100%" : "50%"};
        height: 100%;
        padding: ${({ isMobile }) => (isMobile ? '32px' : '40px 78px')}; 
    `,
    
    ContentCenter: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    `,

    

    Img: styled.img<{ isMobile: boolean }>`
        width: 50%;
        height: 100%;
        object-fit: cover;
        display: ${({ isMobile }) => isMobile && "none"};

    `,

    ButtonLink: styled.a`
        color: #ffca00;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 169.231% */
        letter-spacing: 0.46px;

        &:hover {
            color: #ffca00;
            cursor: pointer;
        }
    `,
};

export default StyleSecaoLikes;
