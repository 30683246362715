import styled, { keyframes } from 'styled-components';
import Select from 'react-select';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';

const loopAnimation = keyframes`
 from { transform: rotate(0deg); }
 to { transform: rotate(360deg) }
`;

const InformacoesPessoaisStyled = {
    Container: styled.div`
        margin-top: 8px;
        padding-left: 120px;
        padding-top: 60px;
        padding-bottom: 120px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding-top: 40px;
            padding-left: 10px;
        }
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        color: #003c78;
    `,

    Subtitle: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #5f758b;
        margin-top: 5px;
    `,

    Row: styled.div<{ error?: boolean }>`
        display: flex;


        background-color: ${props => (props.error ? 'rgba(255, 0, 0, 0.1)' : 'unset')};

        border-radius: 7px;
        width: fit-content;
        width: 100%;
        gap: 10px;
        margin-bottom: 6px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
            // justify-content: center;
        }
    `,

    RowCheckox: styled.div`
    `,

    Column: styled.div<{ error?: boolean }>`
        display: flex;
        margin: 4px 0;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        background-color: ${props => (props.error ? 'rgba(255, 0, 0, 0.1)' : 'unset')};

        border-radius: 7px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
            // justify-content: center;
        }
    `,
    Column2: styled.div<{ error?: boolean }>`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        background-color: ${props => (props.error ? 'rgba(255, 0, 0, 0.1)' : 'unset')};

        border-radius: 7px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-bottom: 30px;
            // justify-content: center;
        }
    `,

    RowNoMargin: styled.div`
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    `,

    Answer: styled.div`
        padding: 5px 20px;
        border-bottom: 2px solid;
        border-bottom-color: #5f758b;

        span {
            color: #5f758b;
            margin-left: 2px;
            margin-right: 2px;
            color: #5f758b;
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
        }
    `,

    LoadingCitiesContainer: styled.div`
        width: 320px;
        height: 50px;
        border-bottom: 2px #5f758b solid;

        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        span {
            font-size: 1.5em;

            animation-name: ${loopAnimation};
            animation-timing-function: linear;
            animation-duration: 1.25s;
            animation-iteration-count: infinite;
        }
    `,

    Label: styled.span<{ error?: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 42px;
        color: ${props => (props.error ? 'red' : Colors.Blue)};
        margin-right: 10px;
    `,

    ErrorText: styled.span`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ff0000;
        max-width: 224px;

        margin-left: 20px;
    `,

    ErrorText2: styled.span`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #ff0000;
        max-width: 224px;
        background-color: rgba(255, 0, 0, 0.1);
        padding: 10px 20px;
        border-radius: 10px;
        margin-left: 20px;
    `,

    Select: styled(Select)`
        min-width: 250px;
    `,

    TwoColumns: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            grid-template-columns: 1fr;
        }
    `,

    LabelCheck: styled.label`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #455a64;
        cursor: pointer;
    `,

    CheckBox: styled.input`
        margin-right: 10px;

    `,

    TooltipStyle: styled.div`
        position: relative;
        display: inline-block;

        &:hover {
            .tooltipdialog {
                visibility: visible;
            }
        }
        align-self: flex-end;
    `,

    Tooltipdialog: styled.div`
        box-sizing: border-box;
        padding: 10px;
        width: 277px;
        text-align: center;
        background: #f3f3f3;
        border: 1px solid #003c78;
        border-radius: 15px;
        white-space: pre-line;
        visibility: hidden;
        position: absolute;
        bottom: 100%;
        z-index: 1;
        left: 105%;
    `,

    InfoText: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-decoration-line: underline;
        font-feature-settings: 'calt' off;
        white-space: pre-line;

        margin-left: 18px;
        color: #00a8e9;
        &:hover {
            cursor: pointer;
        }
    `,
    QuestionMarkStyle: styled.div`
        align-self: center;
    `,
    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 22px;
        width: 22px;
        fill: transparent;

        &:hover {
            cursor: pointer;
        }
    `,

    InfoDialogText: styled.div`
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        letter-spacing: -0.01em;
        color: #ffffff;
    `,
    InfoDialogTitle: styled.div`
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        letter-spacing: -0.01em;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
    `,

    InfoDialogDescription: styled.div`
        font-family: 'Inter';
        font-style: normal;
        text-align: center;
        letter-spacing: -0.01em;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    `,

    InofoDialogButton: styled.button`
        display: flex;
        align-items: center;
        text-align: center;
        padding: 12px 60px;
        margin-top: 22px;
        height: 42px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        border: none;
        line-height: 18px;
        color: ${Colors.DarkBlue};
        background: ${Colors.Yellow};
        border-radius: 50px;
    `,

    ModalContainer: styled.div(({ ...props }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        alignSelf: 'center',
        top: '50%',
        left: '50%',
        padding: '32px 16px',
        width: '251px',
        height: '289px',
        overflow: 'hidden',
        borderRadius: '15px',
        position: 'absolute',
        justifySelf: 'center',
        background: Colors.White,
        transform: 'translate(-50%, -50%)',
        boxShadow: '1px 1px 10px 6px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        backgroundColor: '#124A88',
        border: '3px solid #FFFFFF',
    })),
};

export default InformacoesPessoaisStyled;
