import { MobileBiggerWidth, MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const SliderItemAutoavaliacaoStyled = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        margin: auto;
        width: 350px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }

        @media only screen and (max-width: ${MobileBiggerWidth}px) {
            flex-direction: column;
        }

        @media only screen and (max-width: 480px) {
            width: 240px;
        }
    `,

    Container2: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        margin: auto;
        width: 400px;
        flex-direction: column;

        @media only screen and (max-width: 480px) {
            width: 240px;
        }
    `,

    ImageContainer: styled.div`
        width: 100%;
        flex-direction: row;
        margin: auto;
        border-spacing: 10px;
    `,

    Content: styled.p`
        padding: 25px;
        max-width: 500px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #003c78; ;
    `,

    Title: styled.div`
        text-align: center;
        font-size: 200px;
    `,

    Image: styled.img`
        width: 220px;
        max-height: 200px;

        @media only screen and (min-width: 320px) and (max-width: ${MobileBiggerWidth}px) {
            width: 100px;
        }
    `,

    Image2: styled.img`
        width: 220px;
        max-height: 200px;

        @media only screen and (min-width: 320px) and (max-width: ${MobileBiggerWidth}px) {
            width: 150px;
        }
    `,
};

export default SliderItemAutoavaliacaoStyled;
