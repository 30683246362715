import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import dangerIcon from '../../../../../images/danger-icon.svg';

import { DangerButton, OutlineButton } from 'components/UI/Buttons';
import AdminTag from 'models/admin/AdminTag';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import Colors from 'enums/Colors';
import { ToastSuccess } from 'components/Toaster';

export interface IAdminModalExcludeTag {
    show: boolean;
    onHide: () => void;
    onDelete: () => void;
    tag?: AdminTag;
}

const AdminModalExcludeTag = ({ show, onDelete, onHide, tag }: IAdminModalExcludeTag) => {
    const [confirmTag, setConfirmTag] = useState<string>('');

    const deleteTag = async () => {
        const service = new AdminBiblioService();

        if (tag) {
            const response = await service.apagarTag(tag.id);

            if (response.status == 200) {
                ToastSuccess('Tag excluida com sucesso!');
            }
            onDelete();
        }
    };

    useEffect(() => {
        setConfirmTag('');
    }, [show]);

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            {tag && (
                <Styled.ContainerModal>
                    <Styled.Center>
                        <img src={dangerIcon} />
                        <br />
                        <h1 style={{ fontSize: 30, fontWeight: 'bold', color: '#ff4830' }}>Atenção!</h1>
                        <br />
                        <Styled.TextModal>
                            A tag <span style={{ color: Colors.Blue, fontWeight: 'bold' }}>“{tag.content}”</span> pode estar associada a
                            conteúdos. Para remover completamente esta tag de todos os conteúdos, por favor, insira o nome no campo abaixo.
                        </Styled.TextModal>
                        <br />
                        <Styled.InputComfirmated
                            value={confirmTag}
                            onChange={e => setConfirmTag(e.target.value)}
                            placeholder="Nome da tag a ser deletada"
                        />
                        <br />
                        <br />
                    </Styled.Center>

                    <Styled.BottomButton style={{ width: '100%' }}>
                        <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                        <DangerButton
                            style={{ backgroundColor: tag.content !== confirmTag ? Colors.BlueGrey : '' }}
                            disabled={tag.content !== confirmTag}
                            onClick={deleteTag}
                        >
                            Excluir
                        </DangerButton>
                    </Styled.BottomButton>
                </Styled.ContainerModal>
            )}
        </Styled.Modal>
    );
};

export default AdminModalExcludeTag;
