import { jsonProperty, Serializable } from 'ts-serializable';
import { CardsItemCustomizado } from './CardsItemCustomizado';

export class SecaoItemCustomizado extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    corSecao: string = '';

    @jsonProperty([CardsItemCustomizado], [])
    itens: [CardsItemCustomizado] | [] = [];

    @jsonProperty(String)
    usuarioResponsavel: string = '';
}

export default SecaoItemCustomizado;
