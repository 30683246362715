import React, { useEffect, useState } from 'react';
import {
    RedirectButton,
    BottomContainer,
    InnerContainer,
    MainContainer as MainContainer,
    PainelContainer,
    Text,
    Title,
    TopContainer,
} from './TrilhasAprendizagem.styled';
import ReactTooltip from 'react-tooltip';
import ShareIcon from '../../assets/ShareIcon.svg';
import { useHistory } from 'react-router-dom';
import InternalCard from '../InternalCard';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import Sharebox from '../Sharebox';
import { PaginasEnum } from 'enums/PaginasEnum';
import { GTM_Page_Meu_Painel } from 'components/GoogleTagManager';
import FullDasboardTrilhasAprendizagem from 'models/perfil/FullDasboardTrilhasAprendizagem';

export interface ITrilhasAprendizagemPainel {
    trilhas: FullDasboardTrilhasAprendizagem;
}

const TrilhasAprendizagem = ({ trilhas }: ITrilhasAprendizagemPainel) => {
    const history = useHistory();
    const [params, setParams] = useState({ iniciados: 0, concluidos: 0, naoIniciados: 0 });

    const updateCursosValue = () => {
        let params = {
            iniciados: 0,
            concluidos: 0,
            naoIniciados: 0,
        };
        if (trilhas != null) {
            params.iniciados = trilhas.iniciados;
            params.concluidos = trilhas.concluidos;
            params.naoIniciados = trilhas.naoIniciados;
        }
        setParams(params);
    };

    useEffect(() => {
        updateCursosValue();
    }, []);

    const goToCursosLivres = () => {
        GTM_Page_Meu_Painel('trilhas_de_aprendizagem_redirect_button', 'acessar_trilhas_de_aprendizagem', 'principal_trilhas_de_aprendizagem');
        history.push('/recursos/trilhas');
    };

    return (
        <PainelContainer>
            <MainContainer>
                <TopContainer>
                    <Title>{`Trilhas de aprendizagem`}</Title>
                    <Sharebox location={PaginasEnum.TRILHAS} />
                </TopContainer>

                <InnerContainer>
                    <InternalCard value={params.iniciados} description={'iniciados'} colorPicker={'curso'}></InternalCard>
                    <InternalCard value={params.concluidos} description={'finalizados'} colorPicker={'curso'}></InternalCard>
                    <InternalCard value={params.naoIniciados} description={'não iniciados'} colorPicker={'curso'}></InternalCard>
                </InnerContainer>

                <BottomContainer>
                    <RedirectButton onClick={goToCursosLivres}>
                        <Text>{'Acessar trilhas'}</Text>
                    </RedirectButton>
                </BottomContainer>
            </MainContainer>

            {/* Others */}

            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </PainelContainer>
    );
};

export default TrilhasAprendizagem;
