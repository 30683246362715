import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

/**
 *
 * @param message Mensagem da mensagem de sucesso
 */
const ToastSuccess = (message: string) => {
    toast.success(message, {
        style: {
            width: '704px',
            minHeight: '67px',
            background: '#43A047',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '5px',

            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '130%',
            color: '#FCFCFC',
        },
    });
};

/**
 *
 * @param message Mensagem da mensagem de erro
 */
const ToastError = (message: string) => {
    toast.error(message, {
        style: {
            width: '704px',
            height: '67px',
            background: '#FF4830',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '5px',

            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '130%',
            color: '#FCFCFC',
        },
    });
};

/**
 *
 * @returns Componente principal para spammar o Toast de maneira correta
 */
const ToasterDefault = () => {
    return (
        <Toaster>
            {t => (
                <div style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)}>
                    <ToastBar toast={t} />
                </div>
            )}
        </Toaster>
    );
};

export { ToasterDefault, ToastSuccess, ToastError };
