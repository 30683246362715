import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import ItemBiblitoecaConteudoRelacionado from 'models/admin/ItemBibliotecaConteudoRelacionado';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from '../../../../images/close.svg';

import styled from 'styled-components';
import AdminBiblioNewStyled from './../newContent/AdminBiblioNew.styled';
import { isStringsValid, isValidLink } from '../newContent/isValidNewContentInput';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { DangerButton, GradientButton } from 'components/UI/Buttons';
import { ItemBibliotecaConteudoRelacionadoService } from 'core/http/service/ItemBibliotecaConteudoRelacionado.service';
import { ToastError } from 'components/Toaster';
import ConteudoRelacionadoInternoResumido from 'models/ConteudoRelacionadoInternoResumido';
import { TipoConteudoInternoEnum } from 'enums/TipoConteudoInternoEnum';
import Select from 'components/Select';
import ReactSwitch from 'react-switch';

const Styled = {
    Modal: styled(Modal)<{ width?: number }>`
        padding-left: unset;

        .dialogWidth {
            min-width: ${props => (props.width ? props.width + 'px' : '1166px')};
            border-radius: 10px;
        }

        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            .dialogWidth {
                min-width: 800px;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            .dialogWidth {
                min-width: 316px;
            }
        }
    `,

    ModalBody: styled(Modal.Body)`
        padding: 0;
        @media only screen and (max-width: ${RESIZE_MEDIUM_WIDTH}px) {
            padding: 21px;
        }
    `,

    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 30px;
    `,

    HeaderContainer: styled.div`
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    `,

    CloseIconContainer: styled.div`
        width: 100%;
    `,

    CloseIcon: styled.img`
        width: 25px;
        height: auto;
        float: right;
        cursor: pointer;
    `,
    SectionTitle: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,
    ConteudosContainer: styled.div`
        display: grid;
        grid-template-columns: 1fr 450px;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px #ddd solid;
        margin-top: 50px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,
    RightColumn: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    `,
};

interface IItemBiliotecaRelatedContentDialog {
    codigoItem: number | null;
    conteudoRelacionado: ItemBiblitoecaConteudoRelacionado | null;
    conteudoIdx: number | null;
    show: boolean;
    cursosAtivos: ConteudoRelacionadoInternoResumido[];
    itensBiblioPublicados: ConteudoRelacionadoInternoResumido[];
    onHide: () => void;
    onSubmit: (conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => void;
    onEdit: (conteudoIdx: number, conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => void;
    onDelete: (conteudoRelacionado: ItemBiblitoecaConteudoRelacionado) => void;
}

const ItemBiliotecaRelatedContentDialog: FunctionComponent<IItemBiliotecaRelatedContentDialog> = ({
    ...props
}: IItemBiliotecaRelatedContentDialog) => {
    const MAX_CHAR_TITLE = 200;
    const MAX_CHAR_URL = 200;

    const [title, setTitle] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [imageBase64, setImageBase64] = useState<string>('');
    const [conteudoInterno, setConteudoInterno] = useState<boolean>(false);
    const [tipoConteudoInterno, setTipoConteudoInterno] = useState<TipoConteudoInternoEnum | null>(null);
    const [identificadorConteudoInterno, setIdentificadorConteudoInterno] = useState<number | null>(null);
    const [ativo, setAtivo] = useState<boolean>(true);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const conteudosService = new ItemBibliotecaConteudoRelacionadoService();

    const isValidInputs = (): boolean => {
        if (!imageBase64 || (imageBase64 && imageBase64.trim().length === 0)) {
            return false;
        }

        if (link && !isValidLink(link, true)) return false;

        if (conteudoInterno && (!tipoConteudoInterno || !identificadorConteudoInterno)) {
            return false;
        }

        return isStringsValid([title, link]);
    };

    const insert = async () => {
        if (props.codigoItem) {
            let newContent = new ItemBiblitoecaConteudoRelacionado();
            newContent.titulo = title;
            newContent.link = link;
            newContent.anexoBase64 = imageBase64;
            newContent.codigoItemBiblioteca = props.codigoItem;
            newContent.conteudoInterno = conteudoInterno;
            newContent.tipoConteudoInterno = tipoConteudoInterno;
            newContent.identificadorConteudoInterno = identificadorConteudoInterno;
            newContent.ativo = ativo;

            const response = await conteudosService.inserir(newContent);
            if (response.status === 201) {
                newContent.codigo = response.data.codigo;
                props.onSubmit(newContent);
                clear();
            }
        }
    };

    const edit = async () => {
        if (props.conteudoRelacionado) {
            props.conteudoRelacionado.titulo = title;
            props.conteudoRelacionado.link = link;
            props.conteudoRelacionado.anexoBase64 = imageBase64;
            props.conteudoRelacionado.conteudoInterno = conteudoInterno;
            props.conteudoRelacionado.tipoConteudoInterno = tipoConteudoInterno;
            props.conteudoRelacionado.identificadorConteudoInterno = identificadorConteudoInterno;
            props.conteudoRelacionado.ativo = ativo;

            const response = await conteudosService.editar(props.conteudoRelacionado.codigo, props.conteudoRelacionado);
            if (response.status === 200) {
                const editedContent: ItemBiblitoecaConteudoRelacionado = new ItemBiblitoecaConteudoRelacionado().fromJSON(response.data);
                props.onEdit(props.conteudoIdx ?? -1, editedContent);
                clear();
            }
        }
    };

    const submit = async () => {
        setSubmitting(true);
        try {
            if (props.conteudoRelacionado) {
                await edit();
            } else {
                await insert();
            }
        } catch (err: any) {
            ToastError('Houve um problema, tente novamente mais tarde');
        } finally {
            setSubmitting(false);
        }
    };

    const excluir = async () => {
        setDeleting(true);
        try {
            if (props.conteudoRelacionado) {
                const response = await conteudosService.excluir(props.conteudoRelacionado.codigo);
                if (response.status === 200) {
                    props.onDelete(props.conteudoRelacionado);
                    clear();
                }
            }
        } catch (err: any) {
            ToastError('Houve um problema, tente novamente mais tarde');
        } finally {
            setDeleting(false);
        }
    };

    const clear = async () => {
        setTitle('');
        setLink('');
        setImageBase64('');
        setConteudoInterno(false);
        setTipoConteudoInterno(null);
        setIdentificadorConteudoInterno(null);
    };

    useEffect(() => {
        setTitle(props.conteudoRelacionado?.titulo ?? '');
        setLink(props.conteudoRelacionado?.link ?? '');
        setImageBase64(props.conteudoRelacionado?.anexoBase64 ?? '');
        setConteudoInterno(props.conteudoRelacionado?.conteudoInterno ?? false);
        setTipoConteudoInterno(props.conteudoRelacionado?.tipoConteudoInterno ?? null);
        setIdentificadorConteudoInterno(props.conteudoRelacionado?.identificadorConteudoInterno ?? null);
    }, [props.conteudoRelacionado, props.conteudoIdx]);

    type SelectOptionTypeConteudosInternos = {
        value: TipoConteudoInternoEnum;
        label: string;
    };
    const acceptedTypesTiposConteudoInterno: SelectOptionTypeConteudosInternos[] = [
        { label: 'Curso Livre', value: TipoConteudoInternoEnum.CURSO_LIVRE },
        { label: 'Conteúdo da Biblioteca', value: TipoConteudoInternoEnum.ITEM_BIBLIOTECA },
    ].map(l => ({ label: l.label, value: l.value } as SelectOptionTypeConteudosInternos));

    const getLabelTiposConteudosInternos = (value: string): string => {
        let labelRetorno = value;
        acceptedTypesTiposConteudoInterno.filter(type => {
            if (type.value == value) {
                labelRetorno = type.label;
            }
        });
        return labelRetorno;
    };

    type SelectOptionType = {
        value: string | number;
        label: string;
    };
    const acceptedTypesConteudosInternos = useCallback((): SelectOptionType[] => {
        let optionsType: SelectOptionType[] = [];
        if (tipoConteudoInterno === TipoConteudoInternoEnum.CURSO_LIVRE) {
            optionsType = props.cursosAtivos.map(
                (itemCurso: ConteudoRelacionadoInternoResumido) =>
                    ({
                        label: itemCurso.titulo,
                        value: itemCurso.codigo,
                    } as SelectOptionType)
            );
        } else {
            optionsType = props.itensBiblioPublicados.map(
                (itemBiblio: ConteudoRelacionadoInternoResumido) =>
                    ({
                        label: itemBiblio.titulo,
                        value: itemBiblio.codigo,
                    } as SelectOptionType)
            );
        }
        return optionsType;
    }, [tipoConteudoInterno, props.cursosAtivos, props.itensBiblioPublicados]);

    const getLabelConteudoInternoFromKey = (): string => {
        let index: number = -1;
        let label: string = '';
        if (tipoConteudoInterno === TipoConteudoInternoEnum.CURSO_LIVRE) {
            index = props.cursosAtivos.findIndex(itemCurso => {
                return itemCurso.codigo === identificadorConteudoInterno;
            });
            if (index !== -1) {
                label = props.cursosAtivos[index].titulo;
            }
        } else {
            index = props.itensBiblioPublicados.findIndex(itemBiblio => {
                return itemBiblio.codigo === identificadorConteudoInterno;
            });
            if (index !== -1) {
                label = props.itensBiblioPublicados[index].titulo;
            }
        }

        return label;
    };

    return (
        <Styled.Modal show={props.show} width={958} dialogClassName="dialogWidth" contentClassName="dialogWidth">
            <Styled.ModalBody>
                <Styled.Container>
                    <Styled.CloseIconContainer>
                        <Styled.HeaderContainer>
                            <Styled.CloseIcon
                                src={closeIcon}
                                onClick={() => {
                                    clear();
                                    props.onHide();
                                }}
                            />
                            <Styled.SectionTitle>Adicionar Conteúdo</Styled.SectionTitle>
                        </Styled.HeaderContainer>

                        <Styled.ConteudosContainer>
                            <div>
                                <AdminBiblioNewStyled.Label>Título</AdminBiblioNewStyled.Label>
                                <AdminBiblioNewStyled.Input
                                    value={title}
                                    onChange={(e: any) => setTitle(e.target.value)}
                                    maxLength={MAX_CHAR_TITLE}
                                />
                                <AdminBiblioNewStyled.CountLetter danger={title.length >= MAX_CHAR_TITLE}>
                                    {title.length}/{MAX_CHAR_TITLE}
                                </AdminBiblioNewStyled.CountLetter>

                                <AdminBiblioNewStyled.Label>Link redirecionamento</AdminBiblioNewStyled.Label>
                                <AdminBiblioNewStyled.Input
                                    className={link && link.length > 3 && !isValidLink(link ?? '', false) && 'is-invalid'}
                                    value={link}
                                    onChange={(evt: any) => setLink(evt.target.value)}
                                    maxLength={MAX_CHAR_URL}
                                />
                                <AdminBiblioNewStyled.CountLetter danger={link.length >= MAX_CHAR_URL}>
                                    {link.length}/{MAX_CHAR_URL}
                                </AdminBiblioNewStyled.CountLetter>

                                <AdminBiblioNewStyled.CkeckContainer>
                                    <span style={{ marginRight: '10px' }}>Ativo</span>
                                    <ReactSwitch
                                        height={18 * 0.7}
                                        width={40 * 0.7}
                                        handleDiameter={22 * 0.7}
                                        onHandleColor={'#0B78C8'}
                                        offHandleColor={'#0B78C8'}
                                        onColor={'#BFDFF7'}
                                        offColor={'#dfdfdf'}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        checked={ativo ?? false}
                                        onChange={value => setAtivo(value)}
                                    />
                                </AdminBiblioNewStyled.CkeckContainer>

                                <AdminBiblioNewStyled.CkeckContainer>
                                    <span style={{ marginRight: '10px' }}>Conteúdo Interno</span>
                                    <ReactSwitch
                                        height={18 * 0.7}
                                        width={40 * 0.7}
                                        handleDiameter={22 * 0.7}
                                        onHandleColor={'#0B78C8'}
                                        offHandleColor={'#0B78C8'}
                                        onColor={'#BFDFF7'}
                                        offColor={'#dfdfdf'}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        checked={conteudoInterno ?? false}
                                        onChange={value => setConteudoInterno(value)}
                                    />
                                </AdminBiblioNewStyled.CkeckContainer>
                                <br />
                                {conteudoInterno && (
                                    <>
                                        <AdminBiblioNewStyled.Label>Tipo de Conteúdo Interno</AdminBiblioNewStyled.Label>
                                        <Select
                                            itens={acceptedTypesTiposConteudoInterno}
                                            value={{
                                                value: tipoConteudoInterno ?? '',
                                                label: getLabelTiposConteudosInternos(tipoConteudoInterno ?? ''),
                                            }}
                                            onSelected={o => setTipoConteudoInterno(o.value as TipoConteudoInternoEnum)}
                                            isSmall={true}
                                        />

                                        <br />

                                        {tipoConteudoInterno && (
                                            <React.Fragment key={tipoConteudoInterno}>
                                                <AdminBiblioNewStyled.Label>Selecione o conteúdo relacionado</AdminBiblioNewStyled.Label>
                                                <Select
                                                    itens={acceptedTypesConteudosInternos()}
                                                    value={{
                                                        value: identificadorConteudoInterno ?? 0,
                                                        label: getLabelConteudoInternoFromKey(),
                                                    }}
                                                    onSelected={o => setIdentificadorConteudoInterno(o.value as number)}
                                                    isSmall={true}
                                                />
                                            </React.Fragment>
                                        )}
                                    </>
                                )}
                            </div>

                            <Styled.RightColumn style={{ marginTop: '0px' }}>
                                <AdminImageUploader
                                    size={'sm'}
                                    title={'Imagem de capa'}
                                    onSelect={(base64: string, file: File) => setImageBase64(base64)}
                                    preImageB64={imageBase64}
                                    recommendedDimension={{ w: 224, h: 112 }}
                                />

                                <br />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <GradientButton
                                        style={{ marginBottom: '15px' }}
                                        onClick={async () => {
                                            await submit();
                                        }}
                                        disabled={!isValidInputs() || submitting || deleting}
                                    >
                                        {submitting ? 'Salvando...' : 'Salvar'}
                                    </GradientButton>
                                    {props.conteudoRelacionado && props.conteudoRelacionado.codigo > 0 && (
                                        <DangerButton
                                            disabled={deleting || submitting}
                                            onClick={async () => {
                                                clear();
                                                await excluir();
                                            }}
                                        >
                                            {deleting ? 'Excluindo...' : 'Excluir'}
                                        </DangerButton>
                                    )}
                                </div>
                            </Styled.RightColumn>
                        </Styled.ConteudosContainer>
                    </Styled.CloseIconContainer>
                </Styled.Container>
            </Styled.ModalBody>
        </Styled.Modal>
    );
};

export default ItemBiliotecaRelatedContentDialog;
