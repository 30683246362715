import React, { useEffect, useState } from 'react';

import { cloneDeep } from 'lodash';
import Styled from './BiblioNotificationConfigPage.styled';
import BiblioSwicth from './BiblioSwicth';

import BiblioNotificationConfig from 'models/biblioteca/BiblioNotificationConfig';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import BiblioItemCategoriaModel from 'models/biblioteca/BiblioItemCategoriaModel';
import Loading from 'components/Loading';
import { PrimaryButton } from 'components/UI/Buttons';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';

export interface IBiblioNotificationConfigPage {}

const BiblioNotificationConfigPage = ({}: IBiblioNotificationConfigPage) => {
    const service = new BiblioConteudoService();
    const [allContentSwicth, setAllContentSwicth] = useState(true);
    const [loadedData, setLoadedData] = useState(false);
    const [categorias, setCategorias] = useState<BiblioItemCategoriaModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [categoriaHabilitadasIds, setCategoriaHabilitadasIds] = useState<number[]>([]);
    const history = useHistory();

    const fetchConfigs = async () => {
        const service = new BiblioNotificacaoService();
        const response = await service.buscarPreferencias();

        const enabledConfigs: BiblioNotificationConfig[] = response.data.map((data: any) => new BiblioNotificationConfig().fromJSON(data));
        console.log(enabledConfigs);

        const ids: number[] = [];

        if (enabledConfigs.length == 0) {
            for (const categoria of categorias) {
                ids.push(categoria.id);
            }
        } else {
            for (const config of enabledConfigs) {
                ids.push(config.id);
            }
        }

        setCategoriaHabilitadasIds(ids);
        setLoadedData(true);
    };

    const submitConfigs = async (categoryIds: number[]) => {
        const service = new BiblioNotificacaoService();

        if (!categoryIds) categoryIds = [];
        await service.atualizarPreferencias(categoryIds);
    };

    const toggleValueConfig = (id: number) => {
        let _categoriaHabilitadasIds = cloneDeep(categoriaHabilitadasIds);

        if (_categoriaHabilitadasIds.includes(id)) {
            _categoriaHabilitadasIds = _categoriaHabilitadasIds.filter(cid => cid != id);
        } else {
            _categoriaHabilitadasIds.push(id);
        }

        setCategoriaHabilitadasIds(_categoriaHabilitadasIds);
        submitConfigs(_categoriaHabilitadasIds);
    };

    const fetchCategorias = async () => {
        setLoading(true);
        const { data, status } = await service.buscarCategorias(true);

        if (status == 200) {
            const _categorias = Array.from(data).map((c: any) => new BiblioItemCategoriaModel().fromJSON(c));
            setCategorias(_categorias);
        }

        await fetchConfigs();
        setLoading(false);
        calculateAllCOntent();
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    useEffect(() => {
        if (loadedData && allContentSwicth) {
            const _categoriasHabilitadas: number[] = [];

            for (let i = 0; i < categorias.length; i++) {
                _categoriasHabilitadas.push(categorias[i].id);
            }

            setCategoriaHabilitadasIds(_categoriasHabilitadas);
            submitConfigs(_categoriasHabilitadas);
        }
    }, [allContentSwicth]);

    const calculateAllCOntent = () => {
        let all = true;

        if (categorias.length == 0) all = false;

        for (const categotria of categorias) {
            if (!categoriaHabilitadasIds.some(cid => categotria.id == cid)) {
                all = false;
                break;
            }
        }

        setAllContentSwicth(all);
    };

    const gotoBack = async () => {
        await submitConfigs(categoriaHabilitadasIds);
        history.push('/biblioteca');
    };

    useEffect(() => {
        calculateAllCOntent();
    }, [categoriaHabilitadasIds, categorias]);

    return (
        <Styled.Container>
            <div>
                <Styled.Goback onClick={gotoBack}>
                    <IoArrowBackOutline style={{ transform: 'translateY(-1px)' }} />
                    <span>Salvar e voltar para a Biblioteca</span>
                </Styled.Goback>
                <Styled.Title>Notificações</Styled.Title>
            </div>

            <Styled.Title>Configurações das notificações</Styled.Title>
            <Styled.Subtitle>Controle quais notificações deseja receber </Styled.Subtitle>
            <br />
            <br />

            {loading ? (
                <Loading />
            ) : (
                <>
                    <Styled.Row>
                        <Styled.Text>Todos conteúdos que forem adicionados na biblioteca</Styled.Text>
                        <Styled.PaddingH />
                        <BiblioSwicth
                            value={allContentSwicth}
                            onChange={value => {
                                GTM_Page_Biblioteca('0', `switch_global_${value}`, 'switch_config_global_biblioteca');
                                setAllContentSwicth(value);
                            }}
                        />
                    </Styled.Row>
                    <br />
                    <Styled.Text>Receber notificações de conteúdos no formato:</Styled.Text>
                    <br />

                    {categorias.map(c => {
                        return (
                            <Styled.Row key={c.id + categoriaHabilitadasIds.join('')}>
                                <Styled.Text>{c.descricao}</Styled.Text>
                                <Styled.PaddingH />
                                <BiblioSwicth
                                    value={categoriaHabilitadasIds.some(cid => cid == c.id)}
                                    onChange={value => {
                                        GTM_Page_Biblioteca(c.id.toString(), `${c.descricao}_${value}`, 'switch_config_biblioteca');
                                        toggleValueConfig(c.id);
                                    }}
                                />
                            </Styled.Row>
                        );
                    })}

                    <PrimaryButton style={{ marginTop: '50px' }} onClick={gotoBack}>
                        Salvar e voltar
                    </PrimaryButton>
                </>
            )}
        </Styled.Container>
    );
};

export default BiblioNotificationConfigPage;
