import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const AdminEditorImageStyled = {
    Container: styled.div`
        border: 1px blue solid;
        padding: 15px;
    `,

    Modal: styled(Modal)``,

    Title: styled.p`
        font-weight: bold;
        font-size: 24px;
        line-height: 42px;
        color: #00a8e9;
    `,

    Subtitle: styled.p`
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #2e2e2e;
    `,

    CloseIconContainer: styled.div`
        width: 100%;
        //transform: translateX(90px);
    `,

    CloseIcon: styled.img`
        width: 25px;
        height: auto;
        float: right;
        cursor: pointer;
    `,

    CropperContainer: styled.div`
        max-width: 100%;
        text-align: center;
    `,

    Crop: styled.img`
        max-width: 100%;
        max-height: 350px;
    `,

    Label: styled.p`
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
    `,

    Input: styled(Form.Control)``,

    BottomButom: styled.div`
        display: flex;
        justify-content: space-around;
    `,
};

export default AdminEditorImageStyled;
