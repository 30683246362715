import Colors from 'enums/Colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaCloudArrowDown } from 'react-icons/fa6';
import { Badge, Breadcrumb, Modal, notification, NotificationArgsProps, Popover } from 'antd';
import { MdOutlineFolder } from 'react-icons/md';
import { RiFolderDownloadFill } from 'react-icons/ri';
import { FaFileDownload } from 'react-icons/fa';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { IsCustomMaxWidth, IsCustomMinWidth } from 'core/util/WindowSize';

const Styled = {
    Container: styled.div<{ isBlue: boolean }>`
        display: flex;
        align-items: center;
        height: auto;
        padding-bottom: 24px;
        color: ${({ isBlue }) => (isBlue ? Colors.White : Colors.Blue)};
        background-color: ${({ isBlue }) => (isBlue ? Colors.Blue : Colors.White)};
    `,

    Content: styled.div`
        display: flex;
        padding-left: 70px;
        padding-top: 40px;
        min-height: 500px;
        width: 100%;
        flex-direction: column;
        gap: 1rem;

        @media screen and (max-width: 1100px) {
            padding-right: 20px;
        }

        @media screen and (max-width: 700px) {
            padding-left: 20px;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        text-align: left;
    `,

    Description: styled.p`
        font-family: Barlow;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.019em;
        text-align: left;
        max-width: 85%;
        margin-bottom: 20px;

        @media screen and (max-width: 1250px) {
            width: 80%;
        }

        @media screen and (max-width: 1164px) {
            font-size: 16px;
            max-width: 100%;
        }
    `,

    Img: styled.img`
        display: flex;
        height: 40%;
        width: 40%;
        align-self: center;
        justify-self: center;
        border-radius: 10%;

        @media screen and (max-width: 1160px) {
            width: 70%;
        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }
    `,

    Item: {
        Container: styled.div<{ isBlue: boolean }>`
            display: flex;
            align-items: center;
            padding: 20px;
            justify-content: space-between;
            background-color: ${({ isBlue }) => (isBlue ? Colors.White : Colors.Blue)};
            width: 50%;
            height: 60px;

            border-radius: 30px;
            color: ${({ isBlue }) => (isBlue ? Colors.Blue : Colors.White)};

            @media screen and (max-width: 1960px) {
                width: 60%;
            }
            @media screen and (max-width: 1620px) {
                width: 70%;
            }
            @media screen and (max-width: 1400px) {
                width: 90%;
            }
            @media screen and (max-width: 1220px) {
                width: 100%;
            }

            @media screen and (max-width: 680px) {
                align-items: start;
                flex-direction: column;
                justify-content: start;
                padding: 10px;
                padding-left: 20px;
                gap: 0.5rem;
            }

            @media screen and (max-width: 520px) {
                min-height: 80px;
            }
        `,
    },

    Folder: styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        width: 160px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.5s;
        border: 1px solid ${Colors.Grey10};

        &:hover {
            background-color: ${Colors.Grey13};
        }
    `,

    File: styled.div`
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 100%;
        font-size: 16px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.5s;
        border: 1px solid ${Colors.Grey10};

        &:hover {
            background-color: ${Colors.Grey13};
        }

        @media screen and (max-width: 600px) {
            min-height: 80px;
        }
    `,
};

type NotificationPlacement = NotificationArgsProps['placement'];

type TArquivos = {
    arquivo: string;
    nome: string;
};

type TMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: TArquivos[];
};

interface IItem {
    title: string;
    materiais: TMaterial[];
}

const Item = ({ title, materiais }: IItem) => {
    const [open, setOpen] = useState<boolean>(false);
    const [arquivos, setArquivos] = useState<TArquivos[]>([]);
    const [titleAbaSelected, setTitleAbaSelected] = useState<string>('');
    const [api, contextHolder] = notification.useNotification();

    const [abaArquivos, setAbaArquivos] = useState<boolean>(false);

    const sanitizeFileName = (filename: string) => {
        return filename.replace(/#/g, ''); // Remove o caractere #
    };

    const downloadMaterial = async (arquivo: string, nome: string) => {
        openNotification();
        try {
            if (!arquivo) {
                console.error('Arquivo não disponível');
                return;
            }

            const response = await fetch(arquivo);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${sanitizeFileName(nome)}.pdf`; // Nome do arquivo sem #
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Limpa a URL criada
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };

    const downloadAllFilesAsZip = async (titlePast?: string) => {
        const zip = new JSZip();

        // Itera sobre materiais
        for (const item of materiais) {
            if (titlePast && titlePast?.length > 0) {
                if (titlePast === item.titlePaste) {
                    const folder = zip.folder(item.titlePaste ?? 'materiais');
                    for (const arquivo of item.arquivos) {
                        if (arquivo && arquivo.arquivo) {
                            // Verifica se o arquivo existe
                            try {
                                // Faz o fetch do arquivo
                                const response = await fetch(arquivo.arquivo);
                                if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);

                                const blob = await response.blob();
                                // Adiciona o arquivo na pasta, usando o nome sanitizado
                                folder?.file(`${sanitizeFileName(arquivo.nome)}.pdf`, blob);
                            } catch (error) {
                                console.error(`Erro ao baixar o arquivo: ${arquivo.arquivo}`, error);
                            }
                        }
                    }
                }
            } else if (!titlePast) {
                const folder = zip.folder(item.isPaste ? item.titlePaste ?? 'materiais' : '');
                for (const arquivo of item.arquivos) {
                    if (arquivo && arquivo.arquivo) {
                        // Verifica se o arquivo existe

                        try {
                            // Faz o fetch do arquivo
                            const response = await fetch(arquivo.arquivo);
                            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);

                            const blob = await response.blob();
                            // Adiciona o arquivo na pasta, usando o nome sanitizado
                            folder?.file(`${sanitizeFileName(arquivo.nome)}.pdf`, blob);
                        } catch (error) {
                            console.error(`Erro ao baixar o arquivo: ${arquivo.arquivo}`, error);
                        }
                    }
                }
            }
        }

        // Gera o zip e faz o download
        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, titlePast && titlePast.length > 0 ? titlePast + '.zip' : title + '.zip'); // Nome do arquivo zip
        });
    };

    const openNotification = () => {
        api.info({
            message: 'Aguarde um momento.',
            description: 'Estamos preparando o material para você! ',
            duration: 0,
        });
    };

    const downloadAll = (titlePaste?: string) => {
        openNotification();

        if (titlePaste) {
            downloadAllFilesAsZip(titlePaste);
        } else {
            downloadAllFilesAsZip();
        }

        setOpen(false);
    };

    return (
        <>
            {contextHolder}
            <Modal
                maskClosable={false}
                title={`Materiais - ${title}`}
                centered
                open={open}
                okText={abaArquivos ? 'Baixar todos os arquivos' : 'Baixar todos os materiais'}
                onOk={() => downloadAll(titleAbaSelected)}
                onCancel={() => (abaArquivos ? setAbaArquivos(false) : setOpen(false))}
                cancelText={abaArquivos ? 'Voltar' : 'Fechar'}
                width={IsCustomMaxWidth(600) ? '100%' : '50%'}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '0.2rem',
                        flexWrap: 'wrap',
                    }}
                >
                    {!abaArquivos &&
                        materiais.map((material, index) => {
                            if (material.isPaste) {
                                return (
                                    <Popover
                                        key={index}
                                        content={
                                            <div onClick={() => downloadAll(material.titlePaste)}>
                                                <p
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Baixar pasta <RiFolderDownloadFill  size={20} />
                                                </p>
                                            </div>
                                        }
                                        title="Baixe a pasta com todos os arquivos se desejar."
                                    >
                                        <Styled.Folder
                                            onClick={() => {
                                                setAbaArquivos(true);
                                                setArquivos(material.arquivos);
                                                setTitleAbaSelected(material.titlePaste ?? '');
                                            }}
                                        >
                                            <Badge
                                                styles={{
                                                    root: {
                                                        top: 5,
                                                    },
                                                }}
                                                count={material.arquivos.length}
                                            >
                                                <MdOutlineFolder size={40} />
                                            </Badge>
                                            <p style={{ textAlign: 'center' }}>{material.titlePaste}</p>
                                        </Styled.Folder>
                                    </Popover>
                                );
                            }
                        })}

                    {abaArquivos && (
                        <Breadcrumb
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <Breadcrumb.Item onClick={() => setAbaArquivos(false)}>Materias</Breadcrumb.Item>
                            <Breadcrumb.Item>{titleAbaSelected}</Breadcrumb.Item>
                        </Breadcrumb>
                    )}

                    {abaArquivos &&
                        arquivos.map((arq, index) => (
                            <Styled.File onClick={() => downloadMaterial(arq.arquivo, arq.nome)} key={index}>
                                <p
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    <FaFileDownload /> {arq.nome}
                                </p>
                            </Styled.File>
                        ))}
                </div>
            </Modal>
            <Styled.Item.Container isBlue={true}>
                <p style={{ fontWeight: 'bold' }}>{title}</p>
                <p
                    onClick={() =>
                        materiais.length > 1 || materiais[0].isPaste
                            ? setOpen(true)
                            : downloadMaterial(materiais[0].arquivos[0].arquivo, materiais[0].arquivos[0].nome)
                    }
                    style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {'Baixar Material'} <FaCloudArrowDown color={Colors.Yellow} size={20} />
                </p>
            </Styled.Item.Container>
        </>
    );
};

interface IMateriaisExclusivos {
    title: string;
    description: string;
    img: string;
    items: IItem[];
}

const MateriaisExclusivos = ({ title, description, img, items }: IMateriaisExclusivos) => {
    return (
        <Styled.Container isBlue={true}>
            <Styled.Content>
                <Styled.Title>{title.toLocaleUpperCase()}</Styled.Title>
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
                {IsCustomMaxWidth(1160) && <Styled.Img src={img} alt="Logo do diálogo socioemocional" />}
                <div
                    style={{
                        display: 'flex',
                       
                        maxWidth: 'auto',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '1rem',
                        }}
                    >
                        {items.length > 0 && items.map((item, index) => <Item key={index} title={item.title} materiais={item.materiais} />)}
                    </div>
                    {IsCustomMinWidth(1160) && <Styled.Img style={{marginRight: "10%"}} src={img} alt="Logo do diálogo socioemocional" />}
                </div>
            </Styled.Content>
        </Styled.Container>
    );
};

export default MateriaisExclusivos;
