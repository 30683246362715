import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const BiblioNotificationShowModalStyled = {
    Container: styled.div`
        padding: 20px 15px;
    `,

    Modal: styled(Modal)``,

    Title: styled.p`
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #00a8e9;
    `,

    TopContainer: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    ItemContiner: styled.div`
        border-radius: 5px;
        margin: 15px 0;
        padding: 10px 5px;

        :hover {
            background-color: #f9f9ff;
        }
    `,

    TitleItem: styled.p`
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #183f73;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,

    ContentText: styled.p`
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #747474;

        margin: 3px 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,

    ScrollContainer: styled.div`
        max-height: 350px;

        overflow-x: auto;
        overflow-y: hidden;
        flex-grow: 1;
        white-space: nowrap;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    `,

    Date: styled.div`
        font-size: 10px;
        line-height: 12px;
        color: #00a8e9;
    `,

    BottomContainer: styled.div`
        text-align: center;
    `,

    Icon: styled.img`
        cursor: pointer;
    `,
};

export default BiblioNotificationShowModalStyled;
