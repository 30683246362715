import { ToastError, ToastSuccess } from 'components/Toaster';
import { GobackButton, PrimaryButton, YellowButton } from 'components/UI/Buttons';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { Formik } from 'formik';
import { values } from 'lodash';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as yup from 'yup';

interface IModalCreateCertificated {
    open: boolean;
    handleHide: () => void;
    isReloading: () => void;
    dados?: CertificacoesModel | null;
}

interface typeValues {
    nome: string;
    textoSolicitacao: string;
    precisaSolicitar: boolean;
    link: string;
}

const InitialValues: typeValues = {
    nome: '',
    textoSolicitacao: '',
    precisaSolicitar: true,
    link: '',
};
const ModalCreateCertificated = ({ open, handleHide, isReloading, dados }: IModalCreateCertificated) => {
    const [formValues, setFormValues] = useState<typeValues>(InitialValues);
    const [editCertificated, setEditCertificated] = useState<boolean>(false);

    const service = new CertificacoesService();

    const validatedForm = (values: typeValues): boolean => {
        if (values.nome.length === 0 || values.nome.length < 3) return false;
        if (values.precisaSolicitar && (values.textoSolicitacao.length === 0 || values.textoSolicitacao.length < 10)) return false;
        if (!values.precisaSolicitar && (values.link.length === 0 || values.link.length < 10)) return false;

        return true;
    };

    const onSubmitHandler = async (values: typeValues) => {
        const valid = validatedForm(values);

        if (valid) {
            const data: CertificacoesModel = new CertificacoesModel().fromJSON({
                nome: values.nome,
                textoSolicitacao: values.textoSolicitacao,
                precisaSolicitar: values.precisaSolicitar,
                link: values.link,
            });

            if (editCertificated) {
                const response = await service.updateCertificated(dados?.codigo ?? -1, data);

                if (response.status === 200) {
                    ToastSuccess('Certificação atualizada com sucesso!');
                    isReloading();
                    hidenModal();
                }
            } else {
                const response = await service.inserir(data);

                if (response.status === 201) {
                    ToastSuccess('Certificação criada com sucesso!');
                    isReloading();
                    hidenModal();
                }
            }
        } else {
            ToastError('Informações incorretas ou faltantes!');
        }
    };

    const hidenModal = () => {
        setFormValues(InitialValues);
        handleHide();
    };

    const validateDados = () => {
        setEditCertificated(false);


        if (dados && dados !== null) {
            const newDados: typeValues = {
                nome: dados.nome || '',
                textoSolicitacao: dados.textoSolicitacao || '',
                precisaSolicitar: dados.precisaSolicitar || false,
                link: dados.link || '',
            };
            setEditCertificated(true);
            setFormValues(newDados);
        } else {
            setFormValues(InitialValues);
        }
    };



    useEffect(() => {
        validateDados();
    }, [open]);

    return (
        <Modal show={open} onHide={hidenModal} size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{editCertificated ? 'Editar' : 'Criar'} nova certificação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik onSubmit={() => {}} initialValues={formValues} enableReinitialize={true}>
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                name="precisaSolicitar"
                                label="O usuário precisa esperar a emissão da certificação?"
                                checked={values.precisaSolicitar}
                                onClick={handleChange}
                            />
                            <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="mb-2">Nome da certificação:</Form.Label>
                                <Form.Control
                                    key={`nome-${errors.nome}`}
                                    name="nome"
                                    value={values.nome}
                                    onChange={handleChange}
                                    isValid={touched.nome && !errors.nome}
                                    type="text"
                                    placeholder="Exemplo: MEC"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className="mb-2">Texto que vai aparecer quando o usuário solicitar a emissão:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="textoSolicitacao"
                                    disabled={!values.precisaSolicitar}
                                    value={values.textoSolicitacao}
                                    onChange={handleChange}
                                    isValid={touched.textoSolicitacao && !errors.textoSolicitacao}
                                    rows={3}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="mb-2">Url para o redirecionamento:</Form.Label>
                                <Form.Control
                                    name="link"
                                    value={values.link}
                                    disabled={values.precisaSolicitar}
                                    onChange={handleChange}
                                    isValid={touched.link && !errors.link}
                                    type="text"
                                    placeholder="Exemplo: https://instituicao.com.br/emitir-certificado"
                                />
                            </Form.Group>
                            <Modal.Footer>
                                <GobackButton
                                    type="button"
                                    style={{
                                        width: '32px',
                                    }}
                                    onClick={hidenModal}
                                >
                                    Voltar
                                </GobackButton>
                                <PrimaryButton onClick={() => onSubmitHandler(values)} type="button">
                                    Salvar
                                </PrimaryButton>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateCertificated;
