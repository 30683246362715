import 'core-js/proposals/reflect-metadata';

import PerfilDTOGenerico from './PerfilDTOGenerico';

class TipoFormacaoDTO extends PerfilDTOGenerico {
    constructor() {
        super();
    }
}

export default TipoFormacaoDTO;
