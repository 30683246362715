import React, { useEffect, useState } from 'react';
import Styled from './TematicasHome.styled';
import EducationalSolution from 'models/EducationalSolution';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import CardsContainerSolutionsCarrousel from '../../tematicas/cardComponent/CardsContainerTematicaCarrousel';
import Slider from 'react-slick';
import Colors from 'enums/Colors';
import { ButtonHomeV2 } from '../button/ButtonHomeV2.styled';
import { useHistory } from 'react-router-dom';
import Logo from './assets/logo.svg';
import { IsCustomMinWidth } from 'core/util/WindowSize';

interface ICardCarousel {
    dados: EducationalSolution;
}

const service = new AdminHomeConteudoService();

const CardTematica = ({ dados }: ICardCarousel) => {
    const [image, setImage] = useState<string | null>(null);
    const history = useHistory();

    const fetchImage = async () => {
        if (!dados.defaultData && dados.id) {
            let { data, status } = await service.buscarAnexoConteudo(dados.id, true);
            setImage('data:image/*;base64,' + data.base64Content);
        }
    };

    const goToDetailPage = () => {
        if (dados.linkExterno) {
            let url = dados.followRoute;
            if (!url.match(/^https?:\/\//i)) {
                url = 'https://' + url;
            }

            window.open(url, '_blank');
        } else
            history.push({
                pathname: dados.followRoute,
                state: {
                    socioemocionalEducadores: true,
                },
            });
    };

    useEffect(() => {
        fetchImage();
    }, []);

    return (
        <Styled.CardTematica>
            <Styled.ImgCard src={image ?? ''} />
            <div style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Styled.TitleCard>{dados.title}</Styled.TitleCard>
                <Styled.DescriptionCard>{dados.text}</Styled.DescriptionCard>
            </div>
            <div>
                <ButtonHomeV2 onClick={goToDetailPage} style={{ width: '167px' }}>
                    Saiba mais
                </ButtonHomeV2>
            </div>
        </Styled.CardTematica>
    );
};

const TematicasHome = () => {
    const [tematicas, setTematicas] = useState<EducationalSolution[]>([]);

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('TEMATICAS');

        if (status == 200) {
            const temas = Array.from(data).map((sol: any) =>
                new EducationalSolution().fromJSON({
                    id: sol.id,
                    title: sol.titulo,
                    text: sol.conteudo,
                    active: sol.ativo,
                    image: '',
                    imagePath: '',
                    followRoute: sol.link,
                    followText: '',
                    linkExterno: sol.linkExterno,
                    proximoLancamento: sol.proximoLancamento,
                    novidade: sol.novidade,
                    disabled: sol.desabilitado,
                    defaultData: false,
                    order: sol.ordem,
                    complement: parseInt(sol.complemento),
                })
            );

            setTematicas(temas);
        }
    };

    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M0.472656 26.5214L11.1593 15.0974L0.472656 3.6734L3.76266 0.164062L17.7627 15.0974L3.76266 30.0307L0.472656 26.5214Z"
                        transform={IsCustomMinWidth(600) ? "translate(18, 12)" : '' }
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M17.9931 26.5214L7.30646 15.0974L17.9931 3.6734L14.7031 0.164062L0.703125 15.0974L14.7031 30.0307L17.9931 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(15, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: tematicas.length >= 3 ? 3 : 2,
        slidesToScroll: 1,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,

        responsive: [
            {
                breakpoint: 1100, // Para telas menores que 1100px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 910, // Para telas menores que 1100px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        fetchContent();
    }, []);

    return (
        <Styled.Container>
            <Styled.Header>
                <img src={Logo} style={{ height: 100 }} alt="" />
                <Styled.SubTitle>
                    Mergulhe nos temas que norteiam o trabalho Instituto Ayrton Senna e explore diferentes assuntos relacionados à promoção da
                    educação integral, como alfabetização e competências socioemocionais.
                </Styled.SubTitle>
            </Styled.Header>

            <Styled.Carrousel>
                <Styled.CarouselCardContainer style={{ margin: 0 }}>
                    <Slider {...settings}>
                        {tematicas
                            .sort((a, b) => a.order - b.order)
                            .filter(tema => !tema.disabled)
                            .map((dados, index) => (
                                <CardTematica dados={dados} key={index} />
                            ))}
                    </Slider>
                </Styled.CarouselCardContainer>
            </Styled.Carrousel>
        </Styled.Container>
    );
};

export default TematicasHome;
