import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import styled from 'styled-components';

import CustomStyles from './SelectSharedStyle';

interface IFormikReactSelectStyle {
    size: number;
    sizeUnit?: string;
}
export const FormikReactSelectStyle = styled(Select)<IFormikReactSelectStyle>`
    width: ${props => props.size + `${props.sizeUnit ? props.sizeUnit : 'px'}`};
`;

interface IOptionModel {
    label: string;
    value: number;
}

interface IFormikReactSelcet {
    formik: any;
    onChangeHandler: any;
    name: string;
    options: any[];
    isValueDefault?: boolean;
    numberIndexDefault?: number;
    size?: number;
    sizeUnit?: string;
    fontSizePlaceholder?: string;
    enumSource?: any;
    filter?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    error?: boolean;
    styledCss?: any;
}

const FormikReactSelect = ({
    formik,
    onChangeHandler,
    name,
    options,
    size,
    sizeUnit,
    enumSource,
    filter,
    isValueDefault,
    numberIndexDefault,
    fontSizePlaceholder,
    isDisabled = false,
    placeholder = 'Selecione',
    error,
    styledCss,
    ...props
}: IFormikReactSelcet) => {
    const [selectOptions, setSelectOptions] = useState<IOptionModel[] | []>([]);
    const [defaultValue, setDefaultValue] = useState<IOptionModel>();

    const [isFilterOn, setFilter] = useState(filter ? filter : false);

    useEffect(() => {
        let initialArray: IOptionModel[] = options.map(item => {
            let itemToAdd: IOptionModel;
            if (enumSource) {
                itemToAdd = createOption(item, item);
            } else {
                itemToAdd = createOption(item.nome ? item.nome : item.descricao, item.id);
            }
            return itemToAdd;
        });
        console.log("🚀 ~ useEffect ~ initialArray:", initialArray)
        setSelectOptions(initialArray);

        //Caso já tenha um valor carregado
        if (formik.values[name]) {
            setDefaultValue(
                initialArray.find(obj => {
                    return obj.value === formik.values[name];
                })
            );
        }

        //estilização do placeholder
        const el: HTMLElement | null = document.querySelector(`#select-${name} > div > div > div`);
        if (el && el.textContent == placeholder) {
            el.style.color = 'rgba(95, 117, 139, 0.25)';
            el.style.fontFamily = 'Barlow';
            el.style.fontWeight = '600';
            el.style.fontSize = fontSizePlaceholder ? fontSizePlaceholder : '22px';
        }
    }, []);

    useEffect(() => {
        const el2: HTMLElement | null = document.querySelector(`#select-${name} > div`);
        if (error == true && el2) el2.style.borderBottomColor = 'red';
    }, [error]);

    const createOption = (label: string, value: number) => ({
        label: label,
        value: value,
    });

    const onChange = async (selectedItem: any) => {
        setDefaultValue(selectedItem);
        await formik.setFieldValue(name, selectedItem.value);
        formik.handleChange(name);
        onChangeHandler(selectedItem.value);
    };

    useEffect(() => {
        if (isValueDefault) {
            setDefaultValue(selectOptions[numberIndexDefault ?? 0]);
        }
    }, [selectOptions]);

    return (
        <FormikReactSelectStyle
            id={`select-${name}`}
            size={size}
            sizeUnit={sizeUnit}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
            isSearchable={isFilterOn}
            noOptionsMessage={() => {
                return 'Não encontrado';
            }}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onChange={onChange}
            options={selectOptions}
            value={defaultValue}
            styles={styledCss ? { ...styledCss } : CustomStyles}
        />
    );
};

export default FormikReactSelect;
