import { MobileBiggerWidth, MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const SliderItemStyled = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        margin: auto;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }

        @media only screen and (max-width: ${MobileBiggerWidth}px) {
            flex-direction: column;
        }
    `,

    Content: styled.p`
        padding: 25px;
        max-width: 500px;

        font-family: Barlow;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #2e2e2e;
    `,

    Image: styled.img`
        width: 220px;
        max-height: 200px;

        @media only screen and (min-width: 425px) and (max-width: 820px) {
            width: 300px;
        }
    `,
};

export default SliderItemStyled;
