import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import React, { useEffect, FunctionComponent, useContext, useState } from 'react';
import { DiaryTextStyle } from './DiaryText.styled';

import { DiaryTextStyle2 } from './DiaryText2.styled';

export interface IDiaryAlert {
    visible: boolean;
    hide: () => void;
    toAction?: () => void;
    text: string;
    textButton: string;
}

const DiaryAlert = ({ visible, hide, toAction, textButton, text }: IDiaryAlert) => {
    return (
        <DiaryTextStyle2.AlertContainer style={{ display: visible ? 'block' : 'none' }} onClick={hide}>
            <DiaryTextStyle2.AlertContent>
                <DiaryTextStyle2.AlertTitle>Atenção!</DiaryTextStyle2.AlertTitle>
                <DiaryTextStyle2.AlertText>{text}</DiaryTextStyle2.AlertText>

                <DiaryTextStyle2.Row>
                    <DiaryTextStyle.OutlineButtonDiary
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'fechar_alerta_btn');
                            hide();
                        }}
                    >
                        Cancelar
                    </DiaryTextStyle.OutlineButtonDiary>

                    <DiaryTextStyle.Primary2ButtonDiary
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                            GTM_Page_Jornada_Desenvolvimento('diario_bordo', 'confirmar_alerta_btn');
                            toAction?.();
                        }}
                    >
                        {textButton}
                    </DiaryTextStyle.Primary2ButtonDiary>
                </DiaryTextStyle2.Row>
            </DiaryTextStyle2.AlertContent>
        </DiaryTextStyle2.AlertContainer>
    );
};

export default DiaryAlert;
