import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const AdminModalShowStyled = {
    Container: styled.div`
        padding: 20px;
    `,

    CloseContainer: styled.div`
        text-align: right;
    `,

    CloseIcon: styled.img`
        cursor: pointer;
        height: 1.75rem;
        z-index: 999;
    `,

    Content: styled.div`
        padding: 10px;
    `,

    Modal: styled(Modal)``,
};

export default AdminModalShowStyled;
