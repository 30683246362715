
export enum TagSolucoesEnum {
    CORRE_DE_FLUXO = "CORRE_DE_FLUXO",
    ALFABETIZACAO = "ALFABETIZACAO",
    RECUPERACAO_APRENDIZAGEM = "RECUPERACAO_APRENDIZAGEM",
    GESTAO_ESCOLAR = "GESTAO_ESCOLAR",
    COMPETENCIAS_SOCIEMOCIONAL = "COMPETENCIAS_SOCIEMOCIONAL",
    INTERESSES_PROFISSIONAIS = "INTERESSES_PROFISSIONAIS",
    GESTAO_POLITICA_EDUCACIONAL = "GESTAO_POLITICA_EDUCACIONAL",
    FORMACAO_LIDERANCA = "FORMACAO_LIDERANCA"
}

