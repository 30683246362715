import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        align-items: end;
        flex-direction: column;
        justify-content: center;
        padding: 100px;
        background-color: ${Colors.Blue};
        color: ${Colors.White};
        height: 100%;
        font-family: 'Barlow';

        @media screen and (max-width: 800px) {
            padding: 0;
            padding-right: 20px;
        }
    `,

    H1: styled.h1`
        font-size: 150px;
        font-weight: 700;
        color: ${Colors.Yellow};
    `,

    Titulo: styled.p`
        font-style: italic;
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        text-align: end;
    `,

    Description: styled.p`
        font-style: italic;
        font-size: 20px;
        line-height: 24px;
        text-align: end;
    `,

    Divider: styled.div`
        margin-top: 20px;
        margin-bottom: 10px;
        height: 20px;
        width: 200px;
        background-color: ${Colors.Yellow};
    `,
};

interface ICapaEbook {
    numberModulo: string;
    titulo: string;
    description: string;
}

const CapaEbook = ({ numberModulo, titulo, description }: ICapaEbook) => {
    return (
        <Styled.Container>
            <Styled.H1>{numberModulo}</Styled.H1>
            <Styled.Titulo
                dangerouslySetInnerHTML={{
                    __html: titulo,
                }}
            />

            <Styled.Divider />
            <Styled.Description
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
        </Styled.Container>
    );
};

export default CapaEbook;
