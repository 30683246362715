import React, { useState } from 'react';
import Styled from './BiblioCollectionPage.styled';
import iconClose from '../../../../images/close-circle.svg';
import { GradientButton, OutlineButton, WarnButton } from 'components/UI/Buttons';

export interface IBiblioCollectionNewModal {
    show: boolean;
    onHide: () => void;
    onRemove: () => void;
    titleItem: string;
}

const BiblioCollectionRemoveModal = ({ show, onHide, onRemove, titleItem }: IBiblioCollectionNewModal) => {
    const [newName, setNewName] = useState<string>('');

    return (
        <Styled.Modal.Component centered show={show} onHide={onHide}>
            <Styled.Modal.Container>
                <Styled.Modal.CloseContainer>
                    <Styled.Modal.IconClose src={iconClose} onClick={onHide} />
                </Styled.Modal.CloseContainer>

                <Styled.Modal.Text>
                    Tem certeza de que deseja remover “<Styled.Modal.TextBold>{titleItem}</Styled.Modal.TextBold>” da coleção?
                </Styled.Modal.Text>

                <br />
                <br />
                <Styled.Modal.ContainerBottom>
                    <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                    <div style={{ width: '30px' }}></div>
                    <WarnButton onClick={onRemove}>Remover</WarnButton>
                </Styled.Modal.ContainerBottom>
            </Styled.Modal.Container>
        </Styled.Modal.Component>
    );
};

export default BiblioCollectionRemoveModal;
