import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class BiblioItemCategoriaModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(String, null)
    comportamento: string = '';

    @jsonProperty(String, null)
    rota: string = '';

    @jsonProperty(Number)
    ordem: number = Number.MAX_SAFE_INTEGER;

    @jsonProperty(Boolean)
    ativo: boolean = true;

    @jsonName('perfil_exclusivo')
    @jsonProperty(String, null)
    perfilExclusivo: string | null = null;

    @jsonProperty(Boolean)
    temConteudos: boolean = false;

    @jsonName('temItemPublico')
    @jsonProperty(Boolean)
    temItemPublico: boolean = false;

    @jsonIgnore()
    isScaffold: boolean = false;
}

export default BiblioItemCategoriaModel;
