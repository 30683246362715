import React, { FunctionComponent } from 'react';

import { Index, InputContainer, TitleMini, TitleMiniMobile } from './Metas.styled';
import Input from '../../../../components/UI/Input';
import Select, { SelectOptionType } from '../../../../components/Select';
import { MetaComponentType } from './Metas';
import { MobileMaxWidth } from 'constants/Dimensions';

interface IMeta {
    metas: MetaComponentType[];
    setMetas: (metas: MetaComponentType[]) => void;
    index: number;
    selectOptions: SelectOptionType[];
    selectDefault?: SelectOptionType;
}

const MetaComponent = ({ metas, setMetas, index, selectOptions, selectDefault }: IMeta) => {
    return (
        <>
            <Index>{index + 1}</Index>
            <InputContainer>
                <TitleMiniMobile>Ação</TitleMiniMobile>
                <Input
                    value={metas[index].acao}
                    onchange={e => {
                        const auxMetas = [...metas];
                        auxMetas[index].acao = e.target.value;
                        setMetas(auxMetas);
                    }}
                ></Input>
            </InputContainer>

            <InputContainer>
                <TitleMiniMobile>Meta</TitleMiniMobile>
                <Select
                    width={window.screen.width <= MobileMaxWidth ? '100%' : '245px'}
                    itens={selectOptions}
                    defaultValue={selectDefault}
                    onSelected={(values: SelectOptionType) => {
                        const auxMetas = [...metas];
                        auxMetas[index].prazo = String(values.label);
                        auxMetas[index].id = parseInt(String(values.value));
                        setMetas(auxMetas);
                    }}
                />
            </InputContainer>
        </>
    );
};

export default MetaComponent;
