import Select, { SelectOptionType } from 'components/Select';
import { PrimaryButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import useLogged from 'hooks/useLogged';
import useUserData from 'hooks/useUserData';
import AdminTag from 'models/admin/AdminTag';
import UsuarioDTO from 'models/UsuarioDTO';
import BiblioNotificationShowModal from 'pages/biblioteca/components/header/BiblioNotificationShowModal';
import BiblioTagsContainer from 'pages/biblioteca/components/header/BiblioTagsContainer';
import { BibliotecaContext } from 'pages/biblioteca_v2/context/BibliotecaContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BsBell } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import biblioFilterData from './biblioFilterData';

import Styled from './BiblioFilterItem.styled';
import { RoleEnum } from 'enums/RoleEnum';

export interface IBiblioFilterItem {}

const optionDefault: SelectOptionType = { label: 'Todos', value: 'TODOS' };

const BiblioFilterItem = ({}: IBiblioFilterItem) => {
    const history = useHistory();
    const location = useLocation();
    const { authenticated } = useLogged();
    const [total, setTotal] = useState<number>(0);
    const adminservice = new AdminBiblioService();
    const service = new BiblioNotificacaoService();
    const [tags, setTags] = useState<AdminTag[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { loading, categorias, tagsFilter } = useContext(BibliotecaContext.Context);
    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);

    const [term, setTerm] = useState('');

    const [material, setMaterial] = useState<SelectOptionType>(optionDefault);
    const [tematica, setTematica] = useState<SelectOptionType>(optionDefault);
    const [filtro, setFiltro] = useState<SelectOptionType>(biblioFilterData[0]);
    const [lockFilters, setLockFilters] = useState<boolean>(false);

    const [widthSelects, setWidthSelects] = useState<number>(245);
    const searchButton = useRef<HTMLButtonElement>(null);

    const fetchNotificationsRemainder = async () => {
        setIsFetching(true);
        const response = await service.totalNotificacaoNaoLida();
        setTotal(parseInt(response.data));
        setIsFetching(false);
    };

    const fetchTags = async () => {
        const { data } = await adminservice.buscarTagsPublica();
        const _tags: AdminTag[] = data.map((s: any) => new AdminTag().fromJSON(s));
        setTags(_tags);
    };

    const adjustWidth = () => {
        const widthscreen = window.screen.width;

        if (widthscreen <= MobileMaxWidth && searchButton.current) {
            const wref = searchButton.current.clientWidth;
            const inputElement = document.getElementById('biblio-search-input');
            if (inputElement) inputElement.style.width = `${wref}px`;
            setWidthSelects(wref);
        } else {
            const filterContainer = document.getElementById('biblio-filter-container');

            if (filterContainer) {
                const padContainer = 22; //em px
                const wref = filterContainer.clientWidth;
                const wselect = (wref - 4 * padContainer) / 3;
                setWidthSelects(wselect);
            }
        }
    };

    useEffect(() => {
        fetchTags();

        addEventListener('resize', adjustWidth);

        const interval = setInterval(() => {
            if (!isFetching) {
                fetchNotificationsRemainder();
            }
        }, 10 * 1000);

        return () => clearTimeout(interval);
    }, []);

    useEffect(() => {
        adjustWidth();
    }, [searchButton.current]);

    useEffect(() => {
        if (!location.search.includes('tag')) setTematica(optionDefault);
        if (!location.search.includes('tag_padrao')) setFiltro(biblioFilterData[0]);
        if (!location.pathname.includes('categoria')) setMaterial(optionDefault);
    }, [location.pathname, location.search]);

    const onChangeMaterial = (option: SelectOptionType) => {
        const categoria = categorias.find(c => c.descricao == option.value);
        setMaterial(option);

        if (categoria) {
            history.push(`/biblioteca/categoria/${categoria.rota}${location.search}`);
        } else {
            setMaterial(optionDefault);
            const re = /\/categoria\/[a-zA-Z0-9_-]+/g;
            history.push(`${location.pathname}${location.search}`.replace(re, ''));
        }
    };

    const onChangeTematica = (option: SelectOptionType) => {
        const tagparams = String(option.value);
        setTematica(option);
        /*  setMaterial({ label: 'Todos', value: 'TODOS' }); */

        const serachparams = new URLSearchParams(location.search);

        if (tagparams == 'TODOS') {
            setTematica(optionDefault);
            const re = /tag=[a-zA-Z0-9_-]+/g;
            history.push(`${location.pathname}${location.search}`.replace(re, ''));
            return;
        }

        if (!serachparams.has('tag')) {
            serachparams.append('tag', tagparams);
        } else serachparams.set('tag', tagparams);

        serachparams.delete('page');

        history.push(location.pathname.replace(/(\/tag).+/g, '') + '?' + serachparams.toString());
    };

    const onChangeFiltro = (option: SelectOptionType) => {
        const tagparams = String(option.value);
        setFiltro(option);

        if (tagparams == 'TODOS') {
            const re = /tag_padrao=[a-zA-Z0-9_-]+/g;
            history.push(`${location.pathname}${location.search}`.replace(re, ''));
            setFiltro(optionDefault);
            return;
        }

        const serachparams = new URLSearchParams(location.search);

        if (!serachparams.has('tag_padrao')) {
            serachparams.append('tag_padrao', tagparams);
        } else serachparams.set('tag_padrao', tagparams);

        serachparams.delete('page');

        history.push(location.pathname.replace(/(\/tag).+/g, '') + '?' + serachparams.toString());
    };

    const search = () => {
        const serachparams = new URLSearchParams(location.search);

        if (term.trim().length == 0) serachparams.delete('q');
        else if (!serachparams.get('q')) serachparams.append('q', term);
        else serachparams.set('q', term);

        history.push(location.pathname.replace(/(\/tag).+/g, '') + '?' + serachparams.toString());
    };

    const getCategoriasOptions = useCallback(() => {
        const options: SelectOptionType[] = [optionDefault];
        categorias.forEach(cat => {
            if (cat.temItemPublico) {
                const label: string = cat.perfilExclusivo === RoleEnum.AgenteTecnico ? `${cat.descricao} - Agente Técnico` : cat.descricao;
                options.push({ label: label, value: cat.descricao });
            }
        });

        return options;
    }, [categorias]);

    useEffect(() => {
        if (tagsFilter.length == 0) setTematica(optionDefault);
    }, [tagsFilter]);

    useEffect(() => {
        const categoria = categorias.find(c => c.descricao == material.value);

        if (categoria?.perfilExclusivo != null && categoria?.perfilExclusivo != 'Não exclusivo') setLockFilters(true);
        else setLockFilters(false);
    }, [material]);

    return (
        <>
            <BiblioNotificationShowModal show={showNotificationModal} onHide={() => setShowNotificationModal(false)} />

            <Styled.Container id="biblio-filter-container">
                <Styled.Row>
                    <Styled.Column>
                        <Styled.label>Material</Styled.label>
                        <Select
                            width={`${widthSelects}px`}
                            isSmall
                            itens={getCategoriasOptions()}
                            onSelected={o => onChangeMaterial(o)}
                            value={material}
                            optionWidth={widthSelects - 30}
                            disabled={loading}
                        />
                    </Styled.Column>

                    <Styled.Column>
                        <Styled.label>Temática</Styled.label>
                        <Select
                            disabled={lockFilters || loading}
                            width={`${widthSelects}px`}
                            isSmall
                            itens={[optionDefault, ...tags.map(t => ({ label: t.content, value: t.content }))]}
                            onSelected={o => onChangeTematica(o)}
                            value={tematica}
                            optionWidth={widthSelects - 30}
                        />
                    </Styled.Column>

                    <Styled.Column>
                        <Styled.label>Filtro</Styled.label>
                        <Select
                            disabled={lockFilters || loading}
                            width={`${widthSelects}px`}
                            isSmall
                            itens={authenticated ? biblioFilterData : biblioFilterData.filter(f => f.value != 'FAVORITOS')}
                            defaultValue={biblioFilterData[0]}
                            onSelected={o => onChangeFiltro(o)}
                            value={filtro}
                            optionWidth={widthSelects - 30}
                        />
                    </Styled.Column>
                </Styled.Row>

                <br />

                <Styled.Row3>
                    <Styled.Column style={{ flexGrow: 1 }}>
                        <Styled.label ref={searchButton}>Buscar</Styled.label>

                        <Styled.Row2>
                            <div style={{ flexGrow: 1, marginRight: '20px' }}>
                                <Styled.Input
                                    id="biblio-search-input"
                                    value={term}
                                    onChange={e => setTerm(e.target.value)}
                                    placeholder="O que você está procurando?"
                                    disabled={loading}
                                />
                            </div>

                            <Styled.SearchContainer>
                                <PrimaryButton onClick={search}>Buscar</PrimaryButton>

                                {!location.pathname.includes('notificacoes') && authenticated && (
                                    <Styled.IconContainer key={total} centralize={total == 0} onClick={() => setShowNotificationModal(true)}>
                                        <Styled.IconInner>
                                            <BsBell />
                                            {total > 0 && (
                                                <Styled.IconCount>
                                                    <span style={{ fontSize: '0.65em' }}>{total ?? 0}</span>
                                                </Styled.IconCount>
                                            )}
                                        </Styled.IconInner>
                                    </Styled.IconContainer>
                                )}
                            </Styled.SearchContainer>
                        </Styled.Row2>
                    </Styled.Column>
                </Styled.Row3>
            </Styled.Container>

            <BiblioTagsContainer />
        </>
    );
};

export default BiblioFilterItem;
