import { MobileMaxWidth } from 'constants/Dimensions';
import React from 'react';
import styled from 'styled-components';
import Title from '../../../../components/UI/Title';
import Colors from '../../../../enums/Colors';

export const Container = styled.div`
    background-color: ${Colors.White};
    padding: 48px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 20px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0;
`;

export const GoToTopContainer = styled.div`
    margin-top: 10px;
`;

export const QuestionContainer = styled.div`
    margin-bottom: 55px;
`;

const QuestionHolderContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
    }
`;

export const QuadroDestaque = styled.p`
    background: #e5f6fd;
    border: 1px solid #00a8e9;
    color: #183f73;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    margin: 40px 0;
    margin-left: 55px;
    padding: 20px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: 0px;
    }
`;

const QuestionHolderText = styled.div`
    margin-left: 15px;
`;

const QuestionIndicatorMacro = styled.div`
    background: rgba(83, 173, 34, 0.1);
    height: 37px;
    width: 37px;
    min-width: 37px;
    border-radius: 50%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    color: ${Colors.DarkBlue};
`;

interface IQuestionHolder {
    numberValue: number;
    question: string;
}

export const QuestionHolder = ({ numberValue, question }: IQuestionHolder) => {
    return (
        <QuestionHolderContainer>
            <QuestionIndicatorMacro>
                <span>{numberValue}</span>
            </QuestionIndicatorMacro>
            <QuestionHolderText>
                <Title size={'s'}>{question}</Title>
            </QuestionHolderText>
        </QuestionHolderContainer>
    );
};
