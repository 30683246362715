export default {
    modules: {
        toolbar: [
            [{ header: [1, 2, false] }],
            [
                'bold',
                'italic',
                'link',
                {
                    color: [
                        '#000000',
                        '#C00D0E',
                        '#FF4830',
                        '#ED3D31',
                        '#ff6565',
                        '#ff9900',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#003C78',
                        '#0068B3',
                        '#04B0F9',
                        '#009FE3',
                        '#183f73',
                        '#124A88',
                        '#013162',
                        '#C9D3DD',
                        '#00A8E9',
                        '#0066FF',
                        '#E5F6FD',
                        '#7291B0',
                        '#5f758b',
                        '#FFCA00',
                        '#FFD500',
                        '#2E2E2E',
                        '#AAAAAA',
                        '#777777',
                        '#888888',
                        '#C9C9C9',
                        '#efefef',
                        '#828282',
                        '#E5E5E5',
                        '#494949',
                        '#C3CBCD',
                        '#fafafa',
                        '#ADADAD',
                        '#CBD2E0',
                        '#737b7d',
                        '#7B7C7D',
                        '#5F758B',
                        '#B4B4B4',
                        '#F7F7F7',
                        '#2D2403',
                        '#DC9D00',
                        '#01C509',
                        '#53AD22',
                        '#53AD22',
                        '#F79C00',
                        '#FBB202',
                        '#FCFCFC',
                    ],
                },
                { list: 'ordered' },
                { list: 'bullet' },
            ],
            [{ indent: '-1' }, { indent: '+1' }],
            ['clean'],
        ],
    },

    formats: ['bold', 'italic', 'underline', 'color', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video'],
};
