import React from 'react';

import styled from 'styled-components';
import Tooltip from 'react-bootstrap/Tooltip';
import Title from '../../../../components/UI/Title';

import Colors from 'enums/Colors';
import { MobileMaxWidth } from 'constants/Dimensions';
import { OutlineButton } from 'components/UI/Buttons';

export const MetasContainer = styled.div`
    margin-bottom: 80px;
    margin-top: 30px;
`;

export const MetaContainer = styled.div`
    margin-bottom: 0px;
    width: 100%;
    /* border: 1px red solid; */

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-bottom: 35px;
        padding: 0 5px;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr calc(245px + (2 * 7px));

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

export const GridMobile = styled.div`
    display: none;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: flex;
        flex-direction: column;
        /*  border: 1px green solid; */
    }
`;

export const Index = styled.p`
    width: 50px;

    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    text-align: center;
    padding-top: 20px;
    color: ${Colors.DarkBlue};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

export const InputContainer = styled.div`
    padding: 7px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 0;
    }
`;

export const TitleMini = styled(Title)`
    font-size: 14px;
    margin-left: 7px;
    margin-bottom: 7px;
    font-weight: bold;

    color: ${Colors.DarkGrey};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-bottom: 0;
    }
`;

export const TitleMiniMobile = styled(TitleMini)`
    display: none;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: block;
    }
`;

export const ButtonAdd = styled(OutlineButton)<{ disabled: boolean }>`
    border-radius: 5px;
    width: 36px;
    height: 36px;

    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
    margin-left: 57px;
    user-select: none;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: 7px;
        margin-top: -25px;
    }

    ${props => {
        if (props.disabled) {
            return `
              opacity: 0.5;
              &:hover {
                border: 1px solid ${Colors.SoftBlue};
                color: ${Colors.SoftBlue};
                background-color: white;
              }`;
        }
    }}
`;

export const InfoIcon = styled.img`
    transform: translateY(1px);
`;
