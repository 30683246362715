import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useContext, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/LocalReduxThunk';
import { Modal } from 'antd';
import { ProfileService } from 'core/http/service/Profile.service';
import { SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import useFetch from 'hooks/useFetch';

interface IModalPlayAudio {
    handleStatus?: (status: boolean) => void;
}

const ModalPlayAudio = ({ handleStatus }: IModalPlayAudio) => {
    const [showModalPlayAudio, setShowModalPlayAudio] = useState<boolean>(false);
    const [api, contextHolder] = notification.useNotification();

    const profileService = new ProfileService();

    const usuarioLogado: any = useAppSelector(state => state.profileReducer.userProfile);

    const handlePlayAudio = async (playAudio: boolean) => {
        const response = await profileService.atualizarPlayAudio(playAudio);

        if (response.status === 200) {
            if (playAudio) {
                openNotification();
                handleStatus && handleStatus(playAudio)
            }
        }

        setShowModalPlayAudio(false);
    };

    const openNotification = () => {
        api.open({
            message: 'Seu perfil foi atualizado!',
            description: 'Estamos felizes em poder ajudá-lo com essa necessidade!',
            duration: 5,
            icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        });
    };

    useFetch(async () => {
        if (usuarioLogado) {
            const user = await profileService.findByUsername(usuarioLogado.username);

            const data = user.data;

            if (!data.playAudio && data.playAudio === null) {
                setShowModalPlayAudio(true);
            } else {
                handleStatus && handleStatus(data.playAudio);
            }
        }
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title="Atenção!"
                centered
                open={showModalPlayAudio}
                onOk={() => handlePlayAudio(true)}
                onCancel={() => handlePlayAudio(false)}
                okText="Sim"
                cancelText="Não"
            >
                <p>Por favor, indique se você necessita utilizar o recurso de áudio descrição. (Sim / Não).</p>
            </Modal>
        </>
    );
};

export default ModalPlayAudio;
