import styled from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

export const SocioEmocionaisEducadoresStyle = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 70px;
`;

export const ReturnButtonContainer = styled.div`
    width: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

export const TitleBanner = styled.section`
    background-color: ${Colors.LightBlue2};
    height: 183px;
    display: flex;
    width: 100%;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: 142px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 93px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-left: 0px;
    }
`;

export const BannerTitle1 = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 68.5%;
    color: ${Colors.Blue};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 16px;
        line-height: 11px;
    }
`;

export const BannerTitle2 = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 70px;
    color: ${Colors.Blue};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 24px;
        line-height: 22px;
    }
`;

interface ILampadaSvg {
    transformRule: string;
}

export const ContentContainerInner = styled.div`
    margin-left: 49px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin: 10px;
    }
`;

export const LampadaSvg = styled.img<ILampadaSvg>`
    position: absolute;
    right: 10%;
    transform: ${props => props.transformRule}; ;
`;

export const TextoSobrepostoStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const TitleText = styled.p`
    font-weight: bold;
    line-height: 86px;
    letter-spacing: 0.1px;
    font-size: 72px;
    color: ${Colors.DarkBlue};
    opacity: 0.15;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 28px;
        line-height: 48px;
    }
`;

export const SubtitleText = styled.p`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: ${Colors.White};
    position: absolute;
    transform: translate(67px, 17px);

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        transform: translate(10px, 17px);
    }
`;

export const BreadcrumbContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    white-space: break-spaces;
`;

interface IBreadcrumbItem {
    currentPage?: boolean;
}
export const BreadcrumbItem = styled.p<IBreadcrumbItem>`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    letter-spacing: 0.1px;
    color: ${props => (props.currentPage ? Colors.DarkBlue : Colors.Grey3)};
    &:hover {
        cursor: ${props => (!props.currentPage ? 'pointer' : 'auto')};
        text-decoration: ${props => (!props.currentPage ? 'underline' : 'auto')};
    }
`;

export const Divisor = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${Colors.Grey3};
`;

export const ContentContainer = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 40px 0 3vw;
`;

export const ContentContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0 40px 0;
`;

export const MobileContentSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 24px 40px 24px;
`;

export const LeftSection = styled.section`
    display: flex;
    flex: 2;
    border-right: 1px solid ${Colors.LightGrey};
    padding: 0 42px 0 0;
`;

export const TypeSelectionContainer = styled.div`
    display: flex;

    @media only screen and (min-width: ${MobileMaxWidth + 1}px) {
        flex: 1;
        flex-direction: column;
        align-items: flex-end;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 70px;
        width: 100%;
        border-bottom: 1px solid ${Colors.Grey8};
    }
`;

interface ITypeSelecionItem {
    selected: boolean;
}
export const TypeSelecionItem = styled.p<ITypeSelecionItem>`
    font-family: Barlow;
    font-style: normal;
    font-size: 16px;
    line-height: 42px;
    margin-bottom: 10px;
    letter-spacing: 0.1px;
    cursor: pointer;
    font-weight: ${props => (props.selected ? 'bold' : 'bold')};
    color: ${props => (props.selected ? Colors.DarkBlue : Colors.Grey4)};
`;

export const RightSection = styled.section`
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10% 0 0;
    margin-left: 40px;
`;

export const Title = styled.p`
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    letter-spacing: 0.1px;
    color: ${Colors.DarkBlue};
`;

export const Paragraph = styled.p`
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    margin-top: 15px;
    margin-bottom: 25px;
    color: ${Colors.DarkGrey};
    max-width: 840px;
`;

export const CompetenciasWrapper = styled.section`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        margin-top: 20px;
    }
`;

export const CompetenciasContainer = styled.section`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
`;

export const CompetenciaItem = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 27px;
    color: ${Colors.DarkGrey};
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

interface IColoredIndication {
    color: string;
}
export const ColoredIndication = styled.div<IColoredIndication>`
    width: 25px;
    height: 4px;
    background: ${props => props.color};
    margin-right: 22px;
    flex-shrink: 0;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 25.6px;
        height: 16px;
    }
`;

export const QueroConhecerButton = styled.button`
    width: 227px;
    height: 42px;
    background: ${Colors.Yellow};
    color: ${Colors.Blue};
    font-weight: bold;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    outline: none;
    border: none;
    margin-bottom: 89px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-bottom: 25px;
    }

    &:hover {
        color: ${Colors.White};
    }
`;

export const BtnVoltar = styled.button`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
    width: 227px;
    height: 42px;
    padding: 12px 40px;
    gap: 10px;
    border: 2px solid #003c78;
    border-radius: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.Blue};
    margin-left: 29px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-left: 0;
    }
`;

interface IButtonsContainer {
    marginTop: string;
}
export const ButtonsContainer = styled.div<IButtonsContainer>`
    margin-top: ${props => props.marginTop};
    display: flex;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        margin-bottom: 43px;
    }
`;
