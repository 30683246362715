import styled from 'styled-components';

import Colors from '../../enums/Colors';
import { MobileMaxWidth } from '../../constants/Dimensions';
import FullCss, { MediaQuery } from '../../core/util/FullCss';
import { NavHashLink } from 'react-router-hash-link';

export const BaseButton = styled.button(() => {
    return new FullCss(
        {
            width: '167px',
            height: '42px',
            fontWeight: 'bold',
            borderRadius: '50px',
            boxSizing: 'border-box',
            border: '1px solid white',
        },
        new MediaQuery(`@media (max-width: ${MobileMaxWidth}px)`, {
            width: '100%',
            margin: '0 auto',
        })
    ).getCss();
});

interface IDisableable {
    disabled?: boolean;
}

interface IColor {
    color?: string;
    hoverColor?: string;
    bgColor?: string;
}
type ColorButton = IDisableable & IColor;

export const PrimaryButton = styled(BaseButton)<ColorButton>(
    ({
        disabled = false,
        color = disabled ? Colors.Grey3 : Colors.Blue,
        bgColor = disabled ? '#e0e0e0' : Colors.Yellow,
        hoverColor = disabled ? Colors.Grey3 : Colors.Blue,
    }: ColorButton) => {
        return new FullCss(
            {
                color: color,
                background: bgColor,
                border: `1px solid ${bgColor}`,
            },
            [new MediaQuery('&:hover', { color: hoverColor, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' })]
        ).getCss();
    }
);

export const SecondaryButton = styled(BaseButton)<IDisableable>`
    background-color: ${Colors.SoftBlue};
    border-radius: 3px;

    font-family: Inter;
    font-style: normal;

    font-size: 12px;
    line-height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;

    color: ${Colors.White};
`;

export const GradientButton = styled(BaseButton)<IDisableable>`
    font-weight: 600;
    color: white;
    background: ${props => (props.disabled ? `${Colors.InativeGrey}` : 'linear-gradient(0, #0B78C8 0%, #47AEFC 100%)')};

    &:hover {
        background: ${props => (props.disabled ? `${Colors.InativeGrey}` : 'linear-gradient(0, #2182c7 0%, #72C6FF 100%)')};
    }
`;

export const YellowButton = styled.button`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #003c78;

    flex: none;
    order: 1;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;

    width: 100%;
    max-width: 245px;
    max-height: 37px;

    background: #ffca00;
    border-radius: 72px;
    border: none;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
        max-width: none;
    }
`;

export const OutlineButton = styled(BaseButton)<IDisableable>`
    border: 1px solid ${Colors.Blue};
    color: ${Colors.Blue};
    background-color: ${Colors.White};
    outline: none;

    &:hover {
        color: ${Colors.White};
        background-color: ${Colors.Blue};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

export const OutlineButtonInvert = styled(BaseButton)<IDisableable>`
    color: ${Colors.White};
    background-color: ${Colors.Blue};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid ${Colors.Blue};

    &:hover {
        border: 1px solid ${Colors.Blue};
        color: ${Colors.Blue};
        background-color: ${Colors.White};
        outline: none;
    }
`;

export const DangerButton = styled(BaseButton)<IDisableable>`
    background-color: #ff4830;
    color: white;

    &:hover {
        background-color: #ff3419;
    }
`;

export const NavyButton = styled(BaseButton)<IDisableable>`
    text-align: center;
    border: 1px solid #003c78;
    background-color: white;
    color: #003c78;

    &:hover {
        background-color: #003c78;
        color: white;
    }
`;

export const NavyButtonInvert = styled(BaseButton)<IDisableable>`
    text-align: center;
    border: 1px solid white;
    background-color: #003c78;
    color: white;

    &:hover {
        background-color: white;
        color: #003c78;
        border: 1px solid #003c78;
    }
`;

export const BlueSolidButton = styled(BaseButton)<IDisableable>`
    text-align: center;
    border: none;
    background-color: #003c78;
    color: white;

    &:hover {
        cursor: pointer;
        border: 1px solid #003c78;
        background-color: transparent;
        color: #003c78;
    }
`;

export const RedSolidButton = styled(BaseButton)<IDisableable>`
    text-align: center;
    border: none;
    background-color: ${Colors.InfoRed};
    color: white;

    &:hover {
        cursor: pointer;
        border: 1px solid ${Colors.InfoRed};
        background-color: transparent;
        color: ${Colors.InfoRed};
        span {
            color: ${Colors.InfoRed} !important;
        }
    }
`;

export const WarnButton = styled(BaseButton)<IDisableable>`
    background-color: #f79c00;
    color: white;

    &:hover {
        background-color: #faaf2e;
    }
`;

export const BaseButtonSquare = styled.button(() => {
    return new FullCss(
        {
            width: '250px',
            height: '51px',
            fontWeight: 'bold',
            borderRadius: '5px',
            boxSizing: 'border-box',
            border: '1px solid white',
            padding: '0 50px 0 50px',
        },
        new MediaQuery(`@media (max-width: ${MobileMaxWidth}px)`, {
            width: '100%',
            margin: '0 auto',
        })
    ).getCss();
});

export const GradientSquareButton = styled(BaseButtonSquare)<IDisableable>`
    font-weight: 600;
    color: white;
    background: ${props => (props.disabled ? `${Colors.InativeGrey}` : 'linear-gradient(0, #0B78C8 0%, #47AEFC 100%)')};

    &:hover {
        background: ${props => (props.disabled ? `${Colors.InativeGrey}` : 'linear-gradient(0, #2182c7 0%, #72C6FF 100%)')};
    }
`;

export const GobackButton = styled.button`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
    width: 227px;
    height: 42px;
    padding: 12px 45px;
    gap: 10px;
    border: 2px solid #003c78;
    border-radius: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background: ${Colors.White};
    color: ${Colors.Blue};

    &:hover {
        background: ${Colors.Blue};
        color: ${Colors.White};
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin: 0 auto;
    }
`;

export const DefaultButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background: #003c78;
    border-radius: 50px;

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #fcfcfc;
    max-width: 209px;
    border: none;
    max-height: 49px;

    text-decoration: none !important;

    &:hover {
        text-decoration: none !important;
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
        max-width: none;
        max-height: 38px;
    }
`;

export const ButtonCPC = styled(NavHashLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background: #003c78;
    border-radius: 50px;

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #fcfcfc;
    max-width: 209px;
    border: none;
    max-height: 49px;

    text-decoration: none !important;

    &:hover {
        text-decoration: none !important;
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
        max-width: none;
        max-height: 38px;
    }
`;
