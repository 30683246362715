import React, { useContext, useEffect, useState } from 'react';

import { BibliotecaContext } from './BibliotecaContext';
import BiblioItemPreview from 'models/biblioteca/BiblioItemPreview';
import BiblioSectionItens from 'models/biblioteca/BiblioSectionItens';
import { SubHeaderCategoriaData } from '../../../pages/biblioteca/components/subheader/BibliotecaSubheaderData';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { cloneDeep } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import BiblioItemCategoria from 'models/biblioteca/BiblioItemCategoriaModel';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { MobileMaxWidth } from 'constants/Dimensions';

interface IBibliotecaProvider {}

export type BiblioBuscaType = {
    material: string | null;
    tematica: string | null;
    filtroPadrao: string | null;
    termo: string | null;
    por_pagina: number;
    pagina: number;
};

const BibliotecaProvider = ({ children }: React.PropsWithChildren<IBibliotecaProvider>) => {
    const { keycloak } = useKeycloak();
    const service = new BiblioConteudoService();
    const [loading, _setLoading] = useState(false);
    const [categoriaActive, _setCategoriaActive] = useState<string>(SubHeaderCategoriaData.destaque.route);
    const [resultItens, setResultItens] = useState<BiblioItemPreview[]>([]);
    const [hasMore, sethasMore] = useState<boolean>(false);
    const [tags, _setTags] = useState<string[]>([]);
    const [showContainerTag, setShowContainerTag] = useState(false);
    const [categorias, setCategorias] = useState<BiblioItemCategoria[]>([]);
    const userReducer: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const [categoriaLabel, setCategoriaLabel] = useState('');
    const [filtroLabel, setFiltroLabel] = useState('');

    const location = useLocation();

    const setTags = (tags: string[]) => {
        _setTags(tags);
    };

    const setLoading = (value: boolean) => {
        _setLoading(value);
    };

    const setCategoriaActive = (value: string) => {
        _setCategoriaActive(value);
    };

    const clearItens = () => {
        setTags([]);
        setResultItens([]);
    };

    const setFavoriteItem = (idItem: number, value: boolean) => {
        const cloneResults = cloneDeep(resultItens);
        for (let i = 0; i < cloneResults.length; i++) {
            if (cloneResults[i].id == idItem) {
                cloneResults[i].isFavorited = value;
                break;
            }
        }

        setResultItens(cloneResults);
    };

    const validatyFilterClearResultItens = (dataBusca: BiblioBuscaType) => {

        if(dataBusca.material !== null || dataBusca.tematica !== null || dataBusca.termo != null) {
            setResultItens([]);
        }

    }

    const fetchBuscaGeral = async (dataBusca: BiblioBuscaType) => {
        _setLoading(true);
        validatyFilterClearResultItens(dataBusca);
        
        const { data } = await service.buscaGeralItens(dataBusca, keycloak?.authenticated ?? false);
        let itens: BiblioItemPreview[] = data.rows.map((item: any) => new BiblioItemPreview().fromJSON(item));
        const totalItens = data.totalItems;

        if (itens.length < BiblioConteudoService.getItemPorPagina(window.screen.width <= MobileMaxWidth)) sethasMore(false);
        else sethasMore(resultItens.length + itens.length < totalItens);

        if (dataBusca.pagina == 1) setResultItens(itens);
        else setResultItens([...resultItens, ...itens]);

        _setLoading(false);
    };

    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath.includes('notificacoes') || currentPath.includes('item')) {
            setResultItens([]);
            return;
        }

        const usp = new URLSearchParams(location.search);

        const tagRoute = currentPath.split('tag/')[1];
        const tematica = usp.get('tag');
        const filtroPadrao = usp.get('tag_padrao');
        const termo = usp.get('q');
        let material: string | null = null;

        if (currentPath.includes('categoria')) {
            material = currentPath.split('categoria/')[1];
        }

        const pageFromParam = usp.get('page');
        const page = pageFromParam ? parseInt(pageFromParam) : 1;

        const dataBusca: BiblioBuscaType = {
            material: null,
            tematica: null,
            filtroPadrao: null,
            termo: null,
            por_pagina: BiblioConteudoService.getItemPorPagina(window.screen.width <= MobileMaxWidth),
            pagina: page,
        };

        if (material) {
            const category = categorias.find(c => c.rota == material);

            if (category) {
                setCategoriaActive(category.descricao);
                setCategoriaLabel(category.descricao);
                dataBusca.material = category.descricao.toLowerCase() ?? null;
            }
        }
        if (filtroPadrao) {
            dataBusca.filtroPadrao = filtroPadrao ?? null;
            setFiltroLabel(dataBusca.filtroPadrao);
        }
        if (tagRoute) {
            dataBusca.tematica == tagRoute;
        }
        if (tematica) {
            dataBusca.tematica = tematica ?? null;
            setTags([dataBusca.tematica]);
        }
        if (termo) {
            dataBusca.termo = termo;
        }

        fetchBuscaGeral(dataBusca);
    }, [location.pathname, location.search]);

    const fetchItensByFilter = async (_tags: string[], filtro: string, page = 1) => {
        setLoading(true);

        const response = await service.buscarPublicoPorTagFiltro(_tags.join(','), filtro, keycloak?.authenticated ?? false, page);

        let itens: BiblioItemPreview[] = response.data.rows.map((item: any) => new BiblioItemPreview().fromJSON(item));
        const totalItens: number = response.data.totalItems;

        if (itens.length == 0 || itens.length < BiblioConteudoService.getItemPorPagina()) sethasMore(false);
        else sethasMore(resultItens.length + itens.length < totalItens);

        if (page == 1) setResultItens(itens);
        else setResultItens([...resultItens, ...itens]);

        setShowContainerTag(true);
        setLoading(false);

        if (page == 1) window.scrollTo(0, 0);

        setLoading(false);
    };

    const fetchCategoria = async () => {
        const service = new BiblioConteudoService();
        const { data } = await service.buscarCategorias(keycloak.authenticated ?? false);
        let _categorias: BiblioItemCategoria[] = data.map((c: any) => new BiblioItemCategoria().fromJSON(c));

        _categorias = _categorias.filter(categoria => {
            if (categoria.perfilExclusivo != null && categoria.perfilExclusivo != 'Não exclusivo') {
                return userReducer.roles.includes(categoria.perfilExclusivo);
            } else return true;
        });

        setCategorias(_categorias.sort((c1, c2) => c1.ordem - c2.ordem));
    };

    const removeFiltroLabel = () => setFiltroLabel('');

    const removeCategoriaLabel = () => setCategoriaLabel('');

    const getCategoria = () => fetchCategoria();

    useEffect(() => {
        fetchCategoria();
    }, []);

    const context: BibliotecaContext.IContext = {
        loading,
        setLoading,

        categorias,

        categoriaActive,
        setCategoriaActive,

        hasMore,

        setFavoriteItem,

        resultItens,

        clearItens,

        tagsFilter: tags,
        setTagsFilter: setTags,

        fetchItensByFilter,

        showContainerTag,

        filtroLabel,
        removeFiltroLabel,

        categoriaLabel,
        removeCategoriaLabel,
        getCategoria,
    };

    return <BibliotecaContext.Context.Provider value={context}>{children}</BibliotecaContext.Context.Provider>;
};

export default BibliotecaProvider;
