import AdminCursoLivre from 'models/admin/AdminCursoLivre';

export default function cursosLivresSorter(cursos: AdminCursoLivre[]): AdminCursoLivre[] {
    let hasOrderArray = cursos.filter(c => c.ordem != -1 && c.ordem != null);
    let hasntOrderArray = cursos.filter(c => c.ordem == -1 || c.ordem == null);

    hasOrderArray = hasOrderArray.sort((c1: AdminCursoLivre, c2: AdminCursoLivre) => {
        return c1.ordem - c2.ordem;
    });

    hasntOrderArray = hasntOrderArray.sort((c1: AdminCursoLivre, c2: AdminCursoLivre) => {
        return c1.codigo - c2.codigo;
    });

    return [...hasOrderArray, ...hasntOrderArray];
}
