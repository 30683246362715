import { GenericService } from '../Generic.service';

export default class BiblioAvaliacaoService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public enviarAvalicaoItem(item_id: number, nota?: number) {
        if (nota) return this.post('avaliacao', { item_id, nota });
    }

    public buscarAvaliacaoItem(item_id: number) {
        return this.get(`${item_id}/avaliacao`);
    }
}
