import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import BiblioItemPreview from './BiblioItemPreview';

class BiblioCollection extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('nomeColecao')
    @jsonProperty(String)
    title: string = '';

    @jsonName('totalPorColecao')
    @jsonProperty(Number)
    totalItems: number = 0;

    @jsonName('itens')
    @jsonProperty([BiblioItemPreview], [])
    itens: BiblioItemPreview[] = [];
}

export default BiblioCollection;
