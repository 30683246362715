import styled from 'styled-components';

const StyledSecaoFrame = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        height: ${({ isMobile }) => (isMobile ? 'auto' : '450px')};
        background-color: #003c78;
        color: #ffffff;
        display: flex;
        padding: ${({ isMobile }) => (isMobile ? '24px 32px 24px 32px' : '40px 78px 40px 78px')};
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    `,

    Iframe: styled.iframe``,

    Content: styled.div<{ isMobile: boolean }>`
        flex-direction: column;
        width: ${({ isMobile }) => (isMobile ? '100%' : '40%')};
        height: 100%;
        position: relative;
        display: inline-block;
        margin-right: ${({ isMobile }) => (!isMobile && '10%')};
    `,

    Thumbnail: styled.div`
        position: relative;
        display: inline-block;
        width: 100%;
    `,

    Img: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,

    Play: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        width: 150px;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.5s;
        z-index: 1;

        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
    `,

    Title: styled.h1<{ isMobile: boolean }>`
        color: #ffffff;
        font-size: ${({ isMobile }) => (isMobile ? '24px' : '34px')};
        font-style: normal;
        font-weight: 600;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        margin-bottom: 24px;
    `,

    SubTitle: styled.p<{maxWidth?: string}>`
        font-weight: 400;
        line-height: 140.5%;
        letter-spacing: 0.30px;
        margin-bottom: 16px;
        max-width: ${({maxWidth}) => maxWidth && maxWidth};
    `,
};

export default StyledSecaoFrame;
