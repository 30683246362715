import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Styled from './AdminSocioEmocionalEdit.styled';

import arrowIcon from '../../../../images/admin/arrow-r.svg';
import adminSocioSections from './adminSocioSections';
import AdminEditSocioPage from 'pages/admin/components/pageEditors/AdminEditSocioPage';
import AdminEditModalSave from 'pages/admin/components/pageEditors/AdminEditModalSave';
import AdminTopBar from 'pages/admin/components/barraSuperior/AdminTopBar';
import { AdminContext } from 'pages/admin/context/AdminContext';
import styled from 'styled-components';

export interface IAdminSocioEmocional {}

const AdminSocioEmocional = ({}: IAdminSocioEmocional) => {
    const [showSavedModal, setShowSavedModal] = useState(false);
    const history = useHistory();
    const { subPage, setSubPage } = useContext(AdminContext.Context);

    const pageReturn = () => {
        setSubPage(null);
    };

    // Espaço horizontal esquerdo (Isso serve para o menu lateral não ficar por cima do conteúdo).
    const SpaceLeft = styled.div`
        margin-left: 100px;
    `;

    return (
        <SpaceLeft>
            <AdminTopBar title={'Edição do Painel Sociemocional'} goBack={subPage === null ? false : true} pageReturn={pageReturn} />

            <AdminEditModalSave show={showSavedModal} onHide={() => setShowSavedModal(false)} />

            {subPage == null ? (
                <Styled.Container>
                    <Styled.Title>Painel Socioemocional de Professores</Styled.Title>
                    <Styled.Text>Edite as informações de cada seção</Styled.Text>

                    <br />
                    <br />

                    {adminSocioSections.map((section: string) => (
                        <Styled.RedirectButton key={section} onClick={() => setSubPage(section)}>
                            <span>Editar {section} </span>
                            <img src={arrowIcon} />
                        </Styled.RedirectButton>
                    ))}
                    <Styled.RedirectButton
                        onClick={() => {
                            history.push('/admin/socioemocional');
                            setSubPage('socioEmocionalPage');
                        }}
                    >
                        <span>Ver Avaliações</span>
                        <img src={arrowIcon} />
                    </Styled.RedirectButton>
                </Styled.Container>
            ) : (
                <AdminEditSocioPage
                    key={subPage}
                    section={subPage}
                    onSaved={() => setShowSavedModal(true)}
                    selectSection={value => setSubPage(value)}
                />
            )}
        </SpaceLeft>
    );
};

export default AdminSocioEmocional;
