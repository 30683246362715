import 'core-js/proposals/reflect-metadata';
import { jsonProperty, Serializable } from 'ts-serializable';

class FullDashboardCursos extends Serializable {
    @jsonProperty(Number)
    iniciados: number = 0;

    @jsonProperty(Number)
    finalizados: number = 0;

    @jsonProperty(Number)
    naoIniciados: number = 0;

    constructor() {
        super();
    }
}

export default FullDashboardCursos;
