import { GenericService } from './Generic.service';

export class DeficienciasService extends GenericService {
    readonly URI: string = '/v1/deficiencias';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listarAll() {
        return this.get('');
    }
}
