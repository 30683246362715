import PickListModel from 'components/UI/pickList/PickListModel';
import Macrocompetencia from 'models/pdp/Macrocompetencia';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { CheckBox, LabelCheck, Row } from './Checklist.styled';

interface IChecklist {
    preLoadList: PickListModel[];
    data: any;
    destaque: boolean;
    disabled?: boolean;
    onCallback?: any;
}

const Checklist: FunctionComponent<IChecklist> = props => {
    const [listaSelecao, setListaSelecao] = useState<PickListModel[] | []>([]);

    useEffect(() => {
        if (props.data && !props.preLoadList.length) {
            let initialArray: PickListModel[] = props.data.map((item: any) => new PickListModel().fromJSON(item));
            setListaSelecao(initialArray);
        } else if (props.preLoadList.length && props.data) {
            let initialArray: PickListModel[] = props.data.map((item: any) => {
                let pickItem: PickListModel = new PickListModel().fromJSON(item);
                if (props.preLoadList.findIndex(preLoadedItem => preLoadedItem.id === pickItem.id) > -1) {
                    pickItem.isSelected = true;
                }
                return pickItem;
            });

            setListaSelecao(initialArray);
        } else {
            setListaSelecao(props.preLoadList);
        }
    }, []);

    const toggleItemSelection = (item: PickListModel) => {
        item.isSelected = !item.isSelected;
        props.onCallback(listaSelecao);
    };
    return (
        <>
            {props.destaque == false &&
                listaSelecao.map((item, idx) => {
                    return (
                        <Row key={item.id}>
                            <CheckBox
                                type="checkbox"
                                id={`macro-01-${idx}`}
                                checked={item.isSelected}
                                disabled={props.disabled}
                                onChange={() => {
                                    toggleItemSelection(item);
                                }}
                            />
                            <LabelCheck htmlFor={`macro-01-${idx}`}>{item.descricao}</LabelCheck>
                        </Row>
                    );
                })}

            {props.destaque == true &&
                listaSelecao.map((item, idx) => {
                    return (
                        <Row key={item.id}>
                            <CheckBox
                                type="checkbox"
                                id={`macro-02-${idx}`}
                                disabled={props.disabled}
                                checked={item.isSelected}
                                onChange={() => {
                                    toggleItemSelection(item);
                                }}
                            />
                            <LabelCheck htmlFor={`macro-02-${idx}`}>{item.descricao}</LabelCheck>
                        </Row>
                    );
                })}
        </>
    );
};

export default Checklist;
