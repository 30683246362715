import { DestaqueText } from 'components/UI/Texts';
import Title from 'components/UI/Title';
import React from 'react';
import image1 from '../../../../../images/painelSocio/slide-item1.svg';
import image2 from '../../../../../images/painelSocio/slide-item2.svg';
import image3 from '../../../../../images/painelSocio/slide-item3.svg';
import image4 from '../../../../../images/painelSocio/slide-item4.svg';

import { ISliderItem } from './SliderItem';
import StepDot from './StepDot';

const SliderData: ISliderItem[] = [
    {
        index: 1,
        image: image2,
        content: (
            <>
                <p>
                    <Title size="large" style={{ color: '#183F73' }}>
                        Olá Educador(a)!
                    </Title>
                    <br />
                </p>
                <p>
                    O desenvolvimento socioemocional do estudante é muito importante e o professor tem papel fundamental neste processo,porém
                    para tal é preciso, primeiramente, que o educador consiga identificar suas competências socioemocionais e como regular suas
                    emoções. <br />
                    Assim, o IAS direcionou esforços para estudos e pesquisas que têm buscado caminhos para o desenvolvimento de uma matriz mais
                    específica e direcionada ao educador, englobando o seu desenvolvimento integral enquanto uma necessidade profissional e uma
                    característica fundamental para o apoio na formação de seus estudantes.
                </p>
                <br />
                <p>
                    Esses esforços, concretizados a partir de pesquisas científicas, resultaram no desenvolvimento de um
                    <strong> instrumento autoavaliativo de competências socioemocionais relevantes ao fazer docente</strong>, disponível nesse
                    percurso formativo para dar mais subsídios e evidências a cada educador para planejar seu processo de desenvolvimento.
                </p>
            </>
        ),
    },

    {
        index: 2,
        image: image3,
        content: (
            <p>
                Com auxílio do <strong>Relatório de devolutiva</strong>, que mostra os resultados de sua autoavaliação, e com base em seus
                interesses pessoais, você, educador, pode acessar os módulos <strong>formativos</strong> específicos de cada macrocompetência do
                educador e criar o seu <strong>Plano de Desenvolvimento Pessoal (PDP)</strong> e assim mergulhar mais a fundo em uma trajetória
                de autorreflexão e formação.
            </p>
        ),
    },
    {
        index: 3,
        image: image4,
        content: (
            <p>
                Como todo processo de desenvolvimento envolve muitas ideias e materiais, criamos um espaço específico para todos os educadores
                acessarem a qualquer momento e deixarem suas impressões, reflexões e anotações: o <strong>Diário de Bordo</strong> .
            </p>
        ),
    },
];

export default SliderData;
