import React, { useState, useEffect } from 'react';

import { FaPlus } from 'react-icons/fa';

import PickListCard from './PickListCard';
import Colors from '../../../enums/Colors';
import PickListModel from './PickListModel';

import { PickerListContainerStyle, ItemsContainer, AddOtherButton, AddOtherInput } from './PickList.styled';
import ordenarPickList from './ordernarPickList';

interface IPickerList {
    data: any;
    onCallback: any;
    showAdicionar: boolean;
    totalSelecao: number;
    preLoadedData: PickListModel[] | [];
    preLoadAllInclusive?: boolean;
    disabled?: boolean;
    small?: boolean;
}
const PickList = ({ data, onCallback, showAdicionar, totalSelecao, preLoadedData, preLoadAllInclusive, disabled, small }: IPickerList) => {
    const [showAddOtherInput, setShowAddOtherInput] = useState<boolean>(false);
    const [listaSelecao, setListaSelecao] = useState<PickListModel[] | []>([]);
    const [tochedItem, setTouchedItem] = useState<boolean>();

    useEffect(() => {
        if (data && !preLoadedData.length) {
            let initialArray: PickListModel[] = data.map((item: any) => new PickListModel().fromJSON(item));
            setListaSelecao(initialArray);
        } else if (preLoadedData.length && data && preLoadAllInclusive) {
            let initialArray: PickListModel[] = data.map((item: any) => {
                let pickItem: PickListModel = new PickListModel().fromJSON(item);
                if (preLoadedData.findIndex(preLoadedItem => preLoadedItem.id === pickItem.id) > -1) {
                    pickItem.isSelected = true;
                }
                return pickItem;
            });
            setListaSelecao(initialArray);
        } else {
            setListaSelecao(preLoadedData);
        }
    }, []);

    useEffect(() => {
        onCallback(listaSelecao);
        setTouchedItem(!tochedItem);
    }, [listaSelecao]);

    const toggleItemSelection = (item: PickListModel) => {
        if ((!item.isSelected && ValidateSelectedItems()) || item.isSelected) {
            item.isSelected = !item.isSelected;
        }
        onCallback(listaSelecao);
    };

    const ValidateSelectedItems = (): boolean => {
        const totalSelected = listaSelecao.filter((item: PickListModel) => item.isSelected === true).length;

        return totalSelected < totalSelecao;
    };

    const handleAddOtherClick = () => {
        setShowAddOtherInput(true);
    };

    const AddOtherItem = (value: string) => {
        let newItem: PickListModel | null = null;
        newItem = new PickListModel();
        newItem.isOutroItem = true;
        newItem.descricao = value;
        newItem.isSelected = ValidateSelectedItems() ? true : false;

        newItem.id = listaSelecao.length + value.length;

        setListaSelecao([...listaSelecao, newItem]);

        setShowAddOtherInput(false);
    };

    const OtherInput = () => {
        const [otherInputValue, setOtherInputValue] = useState<string>('');

        let inputFocusRef: any;
        useEffect(() => {
            inputFocusRef.focus();
        }, []);

        const handleKeyDown = (event: any) => {
            if (event.key === 'Enter') {
                AddOtherItem(otherInputValue);
            }
            return;
        };

        return (
            <AddOtherInput
                ref={input => {
                    inputFocusRef = input;
                }}
                onBlur={() => {
                    setOtherInputValue('');
                    setShowAddOtherInput(false);
                }}
                onKeyDown={handleKeyDown}
                value={otherInputValue}
                placeholder="Digite aqui e aperte enter"
                maxLength={30}
                onChange={(e: any) => {
                    setOtherInputValue(e.target.value);
                }}
            />
        );
    };

    const OtherButton = () => {
        return (
            <AddOtherButton onClick={handleAddOtherClick}>
                <FaPlus style={{ color: Colors.BlueGrey, width: '12px' }} />
            </AddOtherButton>
        );
    };

    return (
        <PickerListContainerStyle>
            <ItemsContainer>
                {ordenarPickList(listaSelecao).map((item: any) => (
                    <PickListCard small={small} key={item.id} item={item} disabled={disabled} onClickHandler={toggleItemSelection} />
                ))}
                {showAdicionar && <>{showAddOtherInput ? <OtherInput /> : <OtherButton />} </>}
            </ItemsContainer>
        </PickerListContainerStyle>
    );
};

export default PickList;
