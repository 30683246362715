import { EnvironmentEnum } from '../enums/EnvironmentEnum';

export const getEnvironmentType = (): EnvironmentEnum => {
    const releaseChannel = process.env.NODE_ENV;
    switch (releaseChannel) {
        case EnvironmentEnum.Production:
            return EnvironmentEnum.Production;
        case EnvironmentEnum.Test:
            return EnvironmentEnum.Test;
        case EnvironmentEnum.Dev:
        default:
            return EnvironmentEnum.Dev;
    }
};

export const isProduction = () => {
    return getEnvironmentType() == EnvironmentEnum.Production;
};

export const isDevelopment = () => {
    return getEnvironmentType() == EnvironmentEnum.Dev;
};

export const baseUrl = (): string => {
    return process.env.REACT_APP_BASE_URL ?? '';
};

export const lmsIntegrationUrl = (): string => {
    return process.env.REACT_APP_LMS_INTEGRATION_URL ?? '';
};

export const caminhoAnexosGestaoPaginaBanner = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_BANNER ?? '';
};

export const caminhoAnexosGestaoPaginaSimplesImagem = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_SIMPLES_IMAGEM ?? '';
};

export const caminhoAnexosGestaoPaginaSecaoImagem = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_SECAO_IMAGEM ?? '';
};

export const caminhoAnexosGestaoPaginaCarousel = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_CARD_CARROSEL ?? '';
};

export const caminhoAnexosItemCustomizados = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_ITEM_CUSTOMIZADO ?? '';
};

export const caminhoAnexosGestaoPaginaCardCategoria = (): string => {
    return process.env.REACT_APP_ANEXOS_GESTAOPAGINA_CARD_CATEGORIA ?? '';
};

export const lmsIntegrationUrlSocioemocional = (): string => {
    return process.env.REACT_APP_LMS_INTEGRATION_URL_SOCIOEMOCIONAL ?? '';
};
export const lmsIntegrationUrlSocioemocionalID = (): string => {
    return process.env.REACT_APP_LMS_INTEGRATION_URL_SOCIOEMOCIONAL_ID ?? '';
};

export const caminhoAnexosRecursosCostumizados = (): string => {
    return process.env.REACT_APP_ANEXOS_RECURSOS_CUSTOMIZADOS ?? '';
};

export const instrumentoIntegrationUrl = (): string => {
    return process.env.REACT_APP_INSTRUMENTO_INTEGRATION_URL ?? '';
};

export const version = (): string => {
    return process.env.REACT_APP_VERSION ?? '';
};

export const keycloakAuthClientId = (): string => {
    return process.env.REACT_APP_KEYCLOAK_AUTH_CLIENT ?? '';
};

export const keycloakAuthURL = (): string => {
    return process.env.REACT_APP_KEYCLOAK_AUTH_URL ?? '';
};

export const cursoLivrePensamentoCritico = (): string => {
    return process.env.REACT_APP_CURSO_PENSAMENTO_CRITICO_URL ?? '';
};

export const bucketS3 = (): string => {
    return process.env.REACT_APP_BUCKET_S3 ?? '';
};

export const caminhoAnexosHomepage = (): string => {
    return process.env.REACT_APP_ANEXOS_HOMEPAGE ?? '';
};

export const caminhoAnexosCursosLivres = (): string => {
    return process.env.REACT_APP_ANEXOS_CURSOLIVRE ?? '';
};

export const caminhoAnexosBibliotecaCapas = (): string => {
    return process.env.REACT_APP_ANEXOS_BIBLIOTECA_CAPAS ?? '';
};

export const caminhoAnexosPaginasIntermediariasConteudosRelacionados = (): string => {
    return process.env.REACT_APP_ANEXOS_PG_INTERMEDIARIA_CONTEUDOS_RELACIONADOS ?? '';
};

export const caminhoAnexosBibliotecaConteudosRelacionados = (): string => {
    return process.env.REACT_APP_ANEXOS_BIBLIOTECA_CONTEUDOS_RELACIONADOS ?? '';
};

export const linkInstrucoesRE = (): string => {
    return process.env.REACT_APP_RE_INSTRUCTIONS ?? '';
};

export const cursoRealizandoEscolhasProfessor9ano = (): string => {
    return process.env.REACT_APP_CURSO_REALIZANDO_ESCOLHAS_PROF_9_ANO ?? '';
};

export const cursoRealizandoEscolhasProfessorEnsinoMedio = (): string => {
    return process.env.REACT_APP_CURSO_REALIZANDO_ESCOLHAS_PROF_ENSINO_MEDIO ?? '';
};

export const cursoRealizandoEscolhasGestor = (): string => {
    return process.env.REACT_APP_CURSO_REALIZANDO_ESCOLHAS_GEST ?? '';
};

export const idCursoRealizandoEscolhasProfessor9ano = (): string => {
    return process.env.REACT_APP_ID_CURSO_REALIZANDO_ESCOLHAS_PROF_9ANO ?? '';
};
export const idCursoRealizandoEscolhasProfessorEnsinoMedio = (): string => {
    return process.env.REACT_APP_ID_CURSO_REALIZANDO_ESCOLHAS_PROF_ENSINO_MEDIO ?? '';
};

export const idCursoRealizandoEscolhasGestor = (): string => {
    return process.env.REACT_APP_ID_CURSO_REALIZANDO_ECOLHAS_GEST ?? '';
};

export const idGoogleTagManager = (): string => {
    return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '';
};

export const urlPagIntermediariaRE = (): string => {
    return process.env.REACT_APP_URL_PAG_INTERMEDIARIA_RE ?? '';
};
