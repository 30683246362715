import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton, OutlineButton, RedSolidButton } from 'components/UI/Buttons';
import { cloneDeep } from 'lodash';

import AdminImageUploader from '../imageUploader/AdminImageUploader';

import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { IAdminEditSection } from './AdminEditBanner';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import AdminSecaoPaginaData, {
    AdminSecaoPaginaCarousel,
    AdminSecaoPaginaCarouselItem,
    AdminSecaoPaginaSimplesTexto,
} from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { useHistory, useParams } from 'react-router-dom';
import Row from 'components/Row';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import SecaoCarrossel from 'models/generic-components/SecaoCarrossel';
import { CardsCarrossel } from 'models/generic-components/CardsCarrossel';
import Loading from 'components/Loading';
import { isValidLink } from './isValidNewContentInput';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { Form } from 'react-bootstrap';
import Select from 'components/Select';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { LabelRedirecionamento } from './components/LabelObs';

const initialContent = new AdminSecaoPaginaCarouselItem('', '', '', '', '', TipoRedirecionamento.LOGIN, '', false);

/// COMPONENTE ORIGINADO DE AdminEditTestemonial
const AdminEditCarousel = ({ mode, onUpdate, onSaved, onDelete }: IAdminEditSection) => {
    const params: any = useParams();
    const service = new AdminGestaoPaginaService();
    const { currentSection, selectSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);

    const [title, settitle] = useState('');
    const [description, setDescription] = useState('');
    const [submittingIndex, setSubmittingIndex] = useState(-1);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState<AdminSecaoPaginaCarouselItem[]>([]);

    const [idSecao, setIdSecao] = useState<number>(0);
    const [idCardSelected, setIdCardSelected] = useState<number>(0);
    const [showModalDeleteCard, setShowModalDeleteCard] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const MAX_CHAR_TITLE = 60;
    const MAX_CHAR_CONTENT = 240;

    const isValidInputs = (idx: number): boolean => {
        const card = cards[idx];

        if (!card.anexoBase64 || (card.anexoBase64 && card.anexoBase64.trim().length === 0)) {
            return false;
        }

        if (!card.titulo || card.titulo.trim().length === 0 || card.titulo.length > MAX_CHAR_TITLE) {
            return false;
        }

        if (!card.conteudo || card.conteudo.trim().length === 0 || card.conteudo.length > MAX_CHAR_CONTENT) {
            return false;
        }

        if (!card.textoBotao || card.textoBotao.trim().length === 0) {
            return false;
        }

        if (!card.ocupacao || card.ocupacao.trim().length === 0) {
            return false;
        }

        return true;
    };

    const isValidSection = (): boolean => {
        if (!title || title.trim().length === 0) {
            return false;
        }

        if (!description || description.trim().length === 0) {
            return false;
        }

        return true;
    };

    const fetchCarousel = async () => {
        if (mode == 'EDIT') {
            setLoading(true);

            const id = String(params.id);
            const { data } = await service.buscarCarrossel(parseInt(id));
            const secao = new SecaoCarrossel().fromJSON(data);
            setIdSecao(parseInt(id));
            settitle(secao.titulo);
            setDescription(secao.descricao);
            setModePage(true);

            const { data: datacards } = await service.buscarCartoesCarrossel(parseInt(id));
            const _cardsraw = Array.from(datacards).map((c: any) => new CardsCarrossel().fromJSON(c));

            const _cards: AdminSecaoPaginaCarouselItem[] = [];

            for (const c of _cardsraw) {
                const newCard = new AdminSecaoPaginaCarouselItem(
                    c.titulo,
                    c.conteudo,
                    c.ocupacao,
                    c.textoBotao,
                    c.link,
                    c.tipoRedirecionamento,
                    c.anexoBase64,
                    c.oculto
                );
                newCard.setCodigo(c.codigo);
                _cards.push(newCard);
            }

            setCards(_cards);

            setLoading(false);
        }
    };

    const saveCarousel = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaCarousel) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.secaoCarrossel.codigo);
            selectSection(section);
            setModePage(true);
            setIdSecao(data.secaoCarrossel.codigo);
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateCarousel = async (section: AdminSecaoPaginaData) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarCarrossel(section.codigo, title, description, section.ordem);

        if (status < 300) {
            alert('Carrosel salvo');
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const saveCard = async (secao: AdminSecaoPaginaData, idx: number) => {
        const item = cards[idx];

        const { status, data } = await service.inserirCartaoCarrossel(
            secao.codigo,
            item.titulo,
            item.conteudo,
            item.ocupacao,
            item.link,
            item.textoBotao,
            item.anexoBase64 ?? ''
        );

        if (status < 300) {
            setCodigo(idx, data.codigo);
            ToastSuccess('Cartão salvo');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateCard = async (idx: number) => {
        const item = cards[idx];

        const { status, data } = await service.atualizarCartaoCarrossel(
            item.codigo,
            item.titulo,
            item.conteudo,
            item.ocupacao,
            item.link,
            item.textoBotao,
            item.anexoBase64 ?? '',
            item.tipoRedirecionamento ?? TipoRedirecionamento.LOGIN
        );

        if (status < 300) {
            ToastSuccess('Cartão atualizado');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const handleDeleteCard = async () => {
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCardCarrossel(idCardSelected);

            if (response.status === 200) {
                ToastSuccess('Cartão excluído com sucesso!');
                fetchCarousel();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteCard(false);
        setIsLoadingDelete(false);
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCarrossel(idSecao);

            if (response.status === 200) {
                ToastSuccess('Carrossel excluído com sucesso!');
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteSecao(false);
        setIsLoadingDelete(false);
    };

    const handleIsOcultar = async (codigo: number) => {
        try {
            const response = await service.updateCartoesCategoriaOcultar(codigo);

            if (response.status === 200) {
                const _cards = cards;
                const filteredCards = _cards.map(dados => {
                    if (dados.codigo === response.data.codigo) {
                        return { ...dados, oculto: response.data.oculto } as AdminSecaoPaginaCarouselItem;
                    }
                    return dados;
                });
                setCards(filteredCards);

                const message = response.data.oculto ? 'Cartão ocultado com secesso!' : 'Cartão visível';
                ToastSuccess(message);
            }
        } catch (error) {}
    };

    const submitCarousel = async () => {
        if (!currentSection) {
            alert('Erro ao enviar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaCarousel;
        holder.titulo = title;
        holder.conteudo = description;

        currentSection.modelData = holder;

        if (!currentSection.isPersist) await saveCarousel(currentSection, holder);
        else await updateCarousel(currentSection);

        setSubmitting(false);
    };

    const submitCard = async (idx: number) => {
        if (!currentSection) {
            alert('Seção não encontrada');
            return;
        }

        setSubmittingIndex(idx);

        const item = cards[idx];

        if (!item.isPersist) await saveCard(currentSection, idx);
        else await updateCard(idx);

        setSubmittingIndex(-1);
    };

    const setCodigo = (idx: number, value: number) => {
        const clone = cloneDeep(cards);
        clone[idx].setCodigo(value);
        setCards(clone);
    };

    const setTitle = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].titulo = value;
        setCards(clone);
    };

    const setOccupation = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].ocupacao = value;
        setCards(clone);
    };

    const setText = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].conteudo = value;
        setCards(clone);
    };

    const setCover = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].anexoBase64 = value;
        setCards(clone);
    };

    const setTextButton = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].textoBotao = value;
        setCards(clone);
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].link = value;
        setCards(clone);
    };

    const setTipoRedirecionamento = (idx: number, value: TipoRedirecionamento) => {
        const clone = cloneDeep(cards);
        clone[idx].tipoRedirecionamento = value;
        setCards(clone);
    };

    const addNewCard = () => {
        const newCard = cloneDeep(initialContent);
        setCards([...cards, newCard]);
    };

    useEffect(() => {
        fetchCarousel();
    }, []);

    return (
        <>
            <ModalExclusao
                showModal={showModalDeleteSecao}
                handleHiden={setShowModalDeleteSecao}
                handleExcluxed={handleDelete}
                isLoading={isLoadingDelete}
            />
            <ModalExclusao
                showModal={showModalDeleteCard}
                handleHiden={setShowModalDeleteCard}
                handleExcluxed={handleDeleteCard}
                isLoading={isLoadingDelete}
            />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Styled.HeadFormContainer>
                        <Styled.Label>Título da Seção</Styled.Label>
                        <Styled.Input value={title} onChange={(evt: any) => settitle(evt.target.value)} />
                        <br />
                        <Styled.Label>Texto da Seção</Styled.Label>
                        <Styled.Input
                            value={description}
                            onChange={(evn: any) => setDescription(evn.target.value)}
                            rows={8}
                            as="textarea"
                            className={'form-control'}
                            maxLength={MAX_CHAR_CONTENT}
                        />
                        <Styled.CountLetter danger={description.length >= MAX_CHAR_CONTENT}>
                            {description.length}/{MAX_CHAR_CONTENT}
                        </Styled.CountLetter>

                        <Styled.RightContainer>
                            {modePage && (
                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <RedSolidButton style={{ marginRight: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                        Deletar
                                    </RedSolidButton>
                                </AllowedGroupsValidator>
                            )}
                            <GradientButton onClick={submitCarousel} disabled={submitting || !isValidSection()}>
                                {submitting ? 'Salvando' : 'Salvar'} Carrossel
                            </GradientButton>
                        </Styled.RightContainer>
                    </Styled.HeadFormContainer>

                    <br />
                    <hr />

                    {currentSection?.isPersist && (
                        <>
                            <Styled.Title style={{ marginTop: '30px' }}>Cards</Styled.Title>
                            <Styled.ContainerCards>
                                {cards.map((_, idx) => {
                                    return (
                                        <>
                                            <Styled.ContentCard key={idx}>
                                                <Styled.Row>
                                                    <div>
                                                        <Styled.NumberIndex>{idx + 1}</Styled.NumberIndex>
                                                        {/* <Styled.Title style={{ marginLeft: '10px' }}>Card</Styled.Title> */}
                                                    </div>
                                                </Styled.Row>

                                                <Styled.ContentCardForm>
                                                    <Styled.Label style={{ marginTop: '0' }}>Nome</Styled.Label>
                                                    <Styled.Input
                                                        value={cards[idx].titulo}
                                                        onChange={(evt: any) => setTitle(idx, evt.target.value)}
                                                    />
                                                    <br />
                                                    <Styled.Label style={{ marginTop: '0' }}>Ocupação</Styled.Label>
                                                    <Styled.Input
                                                        value={cards[idx].ocupacao}
                                                        onChange={(evt: any) => setOccupation(idx, evt.target.value)}
                                                    />
                                                    <br />
                                                    <Styled.Label style={{ marginTop: '0' }}>Texto</Styled.Label>
                                                    <Styled.Input
                                                        value={cards[idx].conteudo}
                                                        onChange={(evt: any) => setText(idx, evt.target.value)}
                                                        rows={8}
                                                        as="textarea"
                                                        className={'form-control'}
                                                        maxLength={MAX_CHAR_CONTENT}
                                                    />
                                                    <Styled.CountLetter danger={cards[idx].conteudo?.length >= MAX_CHAR_CONTENT}>
                                                        {cards[idx].conteudo?.length}/{MAX_CHAR_CONTENT}
                                                    </Styled.CountLetter>
                                                    <br />
                                                    <Styled.Label style={{ marginTop: '0' }}>Texto botão</Styled.Label>
                                                    <Styled.Input
                                                        value={cards[idx].textoBotao}
                                                        onChange={(evt: any) => setTextButton(idx, evt.target.value)}
                                                    />

                                                    <br />
                                                    <Styled.Label style={{ marginTop: '0' }}>Link</Styled.Label>
                                                    <Styled.Input
                                                        className={
                                                            cards[idx].link &&
                                                            cards[idx].link.length > 0 &&
                                                            !isValidLink(cards[idx].link, !cards[idx].link.startsWith('/')) &&
                                                            'is-invalid'
                                                        }
                                                        value={cards[idx].link}
                                                        onChange={(evt: any) => setLink(idx, evt.target.value)}
                                                    />
                                                    <Styled.SubLabel>
                                                        <LabelRedirecionamento />
                                                    </Styled.SubLabel>
                                                    {cards[idx].codigo > 0 && (
                                                        <Row style={{ marginTop: 20 }}>
                                                            <span style={{ marginRight: '10px', fontSize: '14px' }}>Ocultar</span>
                                                            <Form>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={'conteudo' + cards[idx].codigo}
                                                                    checked={cards[idx].oculto}
                                                                    onClick={() => handleIsOcultar(cards[idx].codigo)}
                                                                />
                                                            </Form>
                                                        </Row>
                                                    )}

                                                    <Styled.Label>Tipo de redirecionamento</Styled.Label>
                                                    <Select
                                                        isSmall
                                                        value={getOptionFromValue(cards[idx].tipoRedirecionamento ?? TipoRedirecionamento.LOGIN)}
                                                        itens={tipoRedirecionamentoOptions}
                                                        onSelected={o => setTipoRedirecionamento(idx, o.value as TipoRedirecionamento)}
                                                    />
                                                    <Styled.SubLabel>
                                                        {`OBS: A opção "Tipo de Redirecionamento" foi projetada para uso em páginas que não possuem validação automática,
                                                        redirecionando o usuário para login ou cadastro caso ele esteja deslogado. Portanto, o comportamento pode variar caso
                                                        ele seja usado em fluxos que já são automatizados.`}
                                                    </Styled.SubLabel>

                                                    <Styled.RightContainer>
                                                        {cards[idx].codigo > 0 && (
                                                            <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                                <RedSolidButton
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setIdCardSelected(cards[idx].codigo);
                                                                        setShowModalDeleteCard(true);
                                                                    }}
                                                                >
                                                                    Deletar
                                                                </RedSolidButton>
                                                            </AllowedGroupsValidator>
                                                        )}
                                                        <GradientButton
                                                            onClick={() => submitCard(idx)}
                                                            disabled={!isValidInputs(idx) || submittingIndex == idx}
                                                        >
                                                            {submittingIndex == idx
                                                                ? 'Enviando'
                                                                : !cards[idx].isPersist
                                                                ? 'Salvar Cartão'
                                                                : 'Atualizar Cartão'}
                                                        </GradientButton>
                                                    </Styled.RightContainer>
                                                </Styled.ContentCardForm>

                                                <Styled.ImageContainer style={{ paddingTop: 'calc(1rem + 10px)' }}>
                                                    <AdminImageUploader
                                                        onSelect={() => {}}
                                                        setBase64={(base64: string) => setCover(idx, base64)}
                                                        size={'sm'}
                                                        title={'Imagem do banner'}
                                                        preImageB64={cards[idx].anexoBase64 ?? ''}
                                                        recommendedDimension={{ w: 150, h: 85 }}
                                                    />
                                                </Styled.ImageContainer>
                                            </Styled.ContentCard>

                                            <hr />
                                        </>
                                    );
                                })}
                                <br />

                                <Row justify="start">
                                    <OutlineButton style={{ width: 'fit-content' }} onClick={addNewCard}>
                                        <span style={{ fontSize: '0.95rem' }}>+ Adicionar novo cartão</span>
                                    </OutlineButton>
                                </Row>
                            </Styled.ContainerCards>
                        </>
                    )}
                </>
            )}
            <br />
        </>
    );
};

export default AdminEditCarousel;
