import React from 'react';
import styled from 'styled-components';

export const ModalExcludeContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 220px;
    padding: 20px;
`;

export const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #003c78;
`;

export const Description = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(12px, 1.8vw, 20px);
    line-height: 140%;
    color: #003c78;
    text-align: center;
    padding-top: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    padding-top: 20px;
    gap: 5vw;
`;

export const ButtonFechar = styled.button`
    border: 2px solid #003c78;
    border-radius: 6px;
    background-color: transparent;
    padding: 16px 24px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #003c78;

    &:hover {
        color: #fcfcfc;
        background: #003c78;
    }
`;

export const ButtonMore = styled.button`
    border: 2px solid #003c78;
    padding: 16px 24px;
    background: #ff0000;
    border-radius: 6px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fcfcfc;

    &:hover {
        color: #003c78;
        background: transparent;
    }
`;

export interface IExcludeCommentModal {
    setShow: (value: boolean) => void;
    goToExcludeCommentModalCallback?: () => void;
}

const ExcludeCommentModal = ({ setShow, goToExcludeCommentModalCallback }: IExcludeCommentModal) => {
    return (
        <ModalExcludeContainer>
            <Title>{'Atenção!'}</Title>
            <Description>{'Deseja excluir este comentário?'}</Description>
            <ButtonContainer>
                <ButtonFechar onClick={() => setShow(false)}>{'Fechar aviso'}</ButtonFechar>
                <ButtonMore onClick={goToExcludeCommentModalCallback}>{'Excluir'}</ButtonMore>
            </ButtonContainer>
        </ModalExcludeContainer>
    );
};

export default ExcludeCommentModal;
