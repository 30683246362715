import React from 'react';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    `,
};

interface IReferencesEbook {
    text: string;
    url?: string;
    lastAccess?: string;
}

const ReferencesEbook = ({ text, url, lastAccess }: IReferencesEbook) => {
    return (
        <Styled.Container>
            <p dangerouslySetInnerHTML={{__html: text}} />
            <a href="#" onClick={() => window.open(url)}>
                {url}
            </a>
            <p>{lastAccess}</p>
        </Styled.Container>
    );
};

export default ReferencesEbook;
