import Colors from 'enums/Colors';
import { Popover } from 'react-bootstrap';
import styled from 'styled-components';

/**
 * Componente de função Secundário ao criar um popover
 * É opcional:
 *  background: Cor do background do popover exibido, default: Blue
 *  color: Cor do texto do popover exibido, default: White
 *  placement: Posição do Popover para mudar a cor da arrow do Popover, default: top
 */
export interface IDefaultPopover {
    color?: string;
    background?: string;
    placement?: string;
    height?: number;
    filter?: boolean;
    no_border?: boolean;
}

export const DefaultPopover = styled(Popover)<IDefaultPopover>`
    background-color: ${props => (props.background ? props.background : Colors.Blue)};
    color: ${props => (props.color ? props.color : Colors.White)};
    height: ${props => (props.height ? props.height + 'px' : '40px')};
    filter: ${props => (props.filter ? 'drop-shadow(0px 4px 4px rgba(185, 185, 185, 0.6))' : '')};
    border: ${props => props.no_border ? 'none' : ''};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 5px;

    .arrow::after,
    .bs-popover-${props => (props.placement ? props.placement : 'top')} .arrow::after {
        border-${props => (props.placement ? props.placement : 'top')}-color: ${props =>
    props.background ? props.background : Colors.Blue} !important;
    }

`;
