import { ResizeMediumWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 45px;
    margin-bottom: 60px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        flex-direction: column;
        padding: 0 20px;
        margin-bottom: 20px;
    }
`;

export const Column = styled.div<{ isVideo?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.isVideo ? 'flex-start' : 'center')};
    gap: ${props => (props.isVideo ? '0' : '60px')};

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        align-items: center;
        gap: ${props => (props.isVideo ? '0' : '20px')};
    }
`;

export const SeeMoreContainer = styled.div<{ isDropped: boolean }>`
    display: flex;
    flex-direction: column;
    max-height: ${props => (props.isDropped ? '100%' : '180px')};
    overflow: hidden;
    position: relative;
`;

export const SeeMoreButton = styled.button<{ isDropped: boolean }>`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #003c78;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 62.29%);
    height: 90px;
    width: 100%;
    left: 0;
    bottom: ${props => (props.isDropped ? '20px' : '0')};
    border: none;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

export const CoverImage = styled.img`
    object-fit: cover;
    width: 400px;
    height: 250px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        width: 100%;
    }

    @media only screen and (max-width: 500px) {
        height: 150px;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    justify-content: space-evenly;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        max-width: none;
    }

    @media only screen and (max-width: 1200px) {
        justify-content: space-between;
    }
`;

export const InfoItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100px;
`;

export const BottomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`;

export const Label = styled.p`
    width: 100%;
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5f758b;
`;

export const Value = styled.p`
    width: 100%;
    text-align: center;

    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2e2e2e;
`;

export const ActionContainer = styled.div`
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 1200px) {
        justify-content: flex-end;
        text-align: center;
    }
`;

export const Separator = styled.div`
    border: 1px solid;
    border-color: #cbd2e0;
    margin: 5px 0 15px 0;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
    min-height: 250px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        min-height: initial;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        width: 100%;
        flex-direction: column-reverse;
    }
`;

export const Title = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #1e4e7e;
`;

export const SubTitle = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #797979;
`;

export const Description = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
`;

export const Date = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #003c78;
    margin-bottom: 20px;
`;

export const Icon = styled.img`
    height: 0.9rem;
    transform: translate(-8px, -2px);
`;

export const FlexBox = styled.div<{ direction: string }>`
    display: flex;
    flex-direction: ${props => (props.direction ? props.direction : '')};
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        gap: 20px;
    }
`;

export const MiddleBox = styled.div<{ hasContent?: boolean }>`
    display: ${props => (props.hasContent ? 'flex' : 'none')};
    flex-direction: column;
`;
