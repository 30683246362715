import { useKeycloak } from '@react-keycloak/web';
import Loading from 'components/Loading';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import BiblioItem from 'models/biblioteca/BiblioItem';
import { BiblioItemCategoria } from 'models/biblioteca/BiblioItemCategoria';
import UsuarioDTO from 'models/UsuarioDTO';
import BibliotecaItemPageNotFound from 'pages/biblioteca/subpages/itemPage/BibliotecaItemPageNotFound';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import ArtigoItem from '../../components/categoria-item/ArtigoItem';
import DefaultItem from '../../components/categoria-item/DefaultItem';
import BibioCommentSection from '../../components/comment-section/BibioCommentSection';
import { BibliotecaContext } from '../../context/BibliotecaContext';
import Styled from './BibliotecaItemPage.styled';

export interface IBibliotecaItemPage {}

const BibliotecaItemPage = ({}: IBibliotecaItemPage) => {
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [dataItem, setDataItem] = useState<BiblioItem>();
    const service = new BiblioConteudoService();
    const userReducer: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const { categorias } = useContext(BibliotecaContext.Context);

    const history = useHistory();
    const params: any = useParams();

    const fetchItem = async () => {
        setLoading(true);

        let response: any;

        const itemSlug = String(params.slug).split('&')[0];

        if (keycloak?.authenticated) response = await service.buscaItemBySlug(itemSlug);
        else response = await service.buscaPublicoItemBySlug(itemSlug);

        if (response.status == 200) {
            const _dataItem = new BiblioItem().fromJSON(response.data);

            const categoria = categorias.find(c => c.descricao.toLowerCase() == _dataItem.category.toLowerCase());

            if (categoria?.perfilExclusivo != null && categoria.perfilExclusivo != 'Não exclusivo') {
                if (userReducer.roles.includes(categoria.perfilExclusivo) || userReducer.roles.includes('ADM')) setDataItem(_dataItem);
                else setNotFound(true);
            } else {
                setDataItem(_dataItem);
            }
        } else {
            setNotFound(true);
        }

        setLoading(false);
    };

    const switchContent = (comportamento: string) => {
        const categoria = categorias.find(c => c.descricao == comportamento);

        if (dataItem && categoria) {
            switch (categoria.comportamento) {
                case BiblioItemCategoria.ARTIGO:
                    return <ArtigoItem data={dataItem} />;
                default:
                    return <DefaultItem data={dataItem} />;
            }
        }
    };

    useEffect(() => {
        if (!params.slug) {
            history.push('/biblioteca');
        } else {
            fetchItem();
        }
    }, []);

    return (
        <Styled.Container>
            {dataItem && !notFound && !loading ? (
                <>
                    <Styled.ContainerPageItem>
                        {switchContent(dataItem.category)}
                        <BibioCommentSection item_id={dataItem.id} />
                    </Styled.ContainerPageItem>
                </>
            ) : (
                <>
                    <>{loading && <Loading />}</>
                    <>{notFound && <BibliotecaItemPageNotFound />}</>
                </>
            )}
        </Styled.Container>
    );
};

export default BibliotecaItemPage;
