import { GenericService } from './Generic.service';

export class ContatoLancamentoService extends GenericService {
    readonly URI: string = '/v1/lancamento';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public registrarContato(lancamento: string, email: string, nome: string) {
        return this.post('contato', {
            lancamento,
            email,
            nome,
        });
    }
}
