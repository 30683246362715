import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const ConteudosRelacionados = styled.div`
    display: flex;
    padding: 0;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
        padding: 0;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    gap: 20px;

    @media only screen and (min-width: 1200px) {
        flex-wrap: wrap;
    }
`;

export const ScrollContainer = styled.div`
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: hidden;
    //transform: translateX(-55px);

    .flip {
        transform: rotate(180deg);
        box-shadow: -4px -2px 10px #888;
    }

    @media only screen and (max-width: 830px) {
        padding: 0;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 100%;
        margin-top: 0px;
    }
`;

export const Scroll = styled.div`
    display: flex;
    overflow-x: auto;
    //white-space: nowrap;
    scroll-behavior: smooth;

    padding-top: 10px;
    padding-left: 10px;

    @media only screen and (max-width: 830px) {
        padding-left: 0;
    }

    &::-webkit-scrollbar {
        display: none;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 12px;
            box-sizing: content-box;
            display: inline-block;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }

        padding-bottom: 0px;
    }
`;

export const ScrollItem = styled.div`
    margin-right: 2em;
`;

export const ArrowButtons = styled.img<{ active: boolean }>`
    box-shadow: 4px 2px 10px #888;
    border-radius: 50%;
    mix-blend-mode: multiply;
    margin: 10px 10px 50px 10px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    filter: ${props => (props.active ? 'none' : 'grayscale(1)')};
    opacity: ${props => (props.active ? '1' : '0.35')};

    transition: 400ms;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: none;
    }
`;

export const SliderContainer = styled.div`
    max-width: 60vw;
    width: 100%;
    padding: 30px 0;

    .slick-track {
        display: flex;
        align-items: center;
    }
    .slick-slide.slick-active.slick-current > div {
        display: flex;
        justify-content: center;
    }
    .slick-slide.slick-active > div {
        display: flex;
        justify-content: center;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots li button:before {
        font-size: 10px;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #003c78;
        font-size: 10px;
    }
`;

interface IArrowButton {
    next: boolean;
}

export const ArrowButton = styled.div<IArrowButton>`
    cursor: pointer;
    width: 56px;
    height: 56px;
    outline: none;
    border: none;
    position: absolute;
    z-index: 8;
    top: 45%;
    right: ${props => (props.next ? '0%' : '')};
    left: ${props => (props.next ? '100%' : '-6%')};

    @media screen and (max-width: 1920px) {
        left: ${props => (props.next ? '100%' : '-6%')};
    }
    @media screen and (max-width: 1280px) {
        left: ${props => (props.next ? '70%' : '20%')};
        top: 105%;
    }
    @media screen and (max-width: 1090px) {
        top: 105%;
        left: ${props => (props.next ? '70%' : '20%')};
    }

   

  
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 56px;
    width: 56px;
    fill: transparent;

    &:hover {
        fill: ${Colors.SoftBlue};
    }

    &:hover path {
        stroke: ${Colors.White};
    }
`;

export const Container = styled.div`
    width: 200px;
    max-width: 100%;
    white-space: normal;
    box-sizing: content-box;
    border-radius: 10px;
    transition: 0.4s;

    &:hover {
        background-color: #f8f8fc;
    }

    @media only screen and (max-width: 760px) {
        width: 165px;
        margin-right: 5%;
    }
`;

export const CoverContainer = styled.div`
    width: 100%;
    height: 130px;
    position: relative;
    cursor: pointer;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    cursor: pointer;
`;

export const Title = styled.p`
    width: 100%;
    max-height: 80px;
    color: #1e4e7e;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    margin-top: 20px;
    transition: 0.3s;
    cursor: pointer;

    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;

    &:hover {
        color: #0866c4;
    }
`;

export const Subtitle = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #747474;
    margin-top: 7px;
    max-height: 35px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
`;

export const Category = styled.p`
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #04b0f9;
    margin-top: 7px;
    text-transform: capitalize;
`;
