import { jsonProperty, Serializable } from 'ts-serializable';
import Macrocompetencia from './Macrocompetencia';
import Meta from './Meta';

class AcaoMeta extends Serializable {
    @jsonProperty(Number)
    id: number = 0;

    @jsonProperty(Meta)
    meta: Meta = new Meta();

    @jsonProperty(String)
    descricao: string = '';

    constructor() {
        super();
    }
}

export default AcaoMeta;
