import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import FullCss, { MediaQuery } from '../../../core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from '../../../core/util/cssHelp';
import { ResizeMediumWidth, MobileMaxWidth } from 'constants/Dimensions';
import Colors from '../../../enums/Colors';

// export const ModalStyled = styled(Modal)(() => {
//     const dialogMedia = (num: number) =>
//         new MediaQuery('.dialogWidth', {
//             minWidth: `${num}px`,
//             borderRadius: '10px',
//         });

//     return new FullCss(
//         {
//             paddingLeft: 'unset',
//         },
//         [
//             dialogMedia(1166),
//             new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), dialogMedia(800)),
//             new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), dialogMedia(316)),
//         ]
//     ).getCss();
// });

//ainda não consegui fazer um seletor de classe dentro de outro seletor

export const ModalStyled = styled(Modal)`
    padding-left: unset;

    .dialogWidth {
        min-width: 1166px;
        border-radius: 10px;
    }

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        .dialogWidth {
            min-width: 800px;
        }
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .dialogWidth {
            min-width: 316px;
        }
    }
`;

export const ModalBodyStyled = styled(Modal.Body)(() => {
    return new FullCss(
        {
            padding: 0,
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            padding: '21px',
        })
    ).getCss();
});

export const Container = styled.section(() => {
    return new FullCss(
        {
            width: '100%',
            height: '100%',
            display: 'flex',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
        })
    ).getCss();
});

export const ContentSection = styled.section(() => {
    return new FullCss(
        {
            width: '60%',
            height: '100%',
            padding: '88px 115px 0 88px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            padding: 0,
            width: '100%',
            height: 'unset',
        })
    ).getCss();
});

interface IImageSection {
    svgSource: string;
}
export const ImageSection = styled.section<IImageSection>(({ svgSource }: IImageSection) => {
    return new FullCss(
        {
            height: 956,
            width: '40%',
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url('${svgSource}')`,
            backgroundPosition: 'center right',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            width: '284px',
            height: '177px',
            marginTop: '40px',
            marginBottom: '60px',
        })
    ).getCss();
});

export const SvgIcon = styled.img(() => {
    return new FullCss(
        {
            top: '88px',
            right: '33.5%',
            width: '151px',
            height: '147px',
            position: 'absolute',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            top: '305px',
            right: 'unset',
            width: '100px',
            height: '100px',
        })
    ).getCss();
});

export const Footer = styled.div({
    display: 'flex',
    marginTop: '27px',
    flexDirection: 'column',
});

export const ContentTitle = styled.p(() => {
    return new FullCss(
        {
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '29px',
            color: Colors.Blue,
            marginBottom: '44px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            width: '100%',
            display: 'flex',
            marginTop: '50px',
            marginBottom: '37px',
            justifyContent: 'flex-start',
        })
    ).getCss();
});

export const Content = styled.p({
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'normal',
});
