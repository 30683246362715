import { jsonName, jsonProperty, Serializable } from 'ts-serializable';
import { BiblioItemCategoria } from './BiblioItemCategoria';

class AnexoConteudo extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('file')
    @jsonProperty(String)
    fileName: string = '';

    @jsonName('filePath')
    @jsonProperty(String)
    filePath: string = '';

    @jsonName('titulo')
    @jsonProperty(String)
    titulo: string = '';

    @jsonName('descricao')
    @jsonProperty(String)
    descricao: string = '';

    @jsonName('base64Content')
    @jsonProperty(String)
    base64Content: string = '';
}

export default AnexoConteudo;
