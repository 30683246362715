import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { FetchUtils } from 'core/util/FetchUtils';
import {
    limparRedirecionamentosPosLogin,
    CHAVE_REDIRECT_TM,
    CHAVE_REDIRECT_RE,
    CHAVE_REDIRECT_ALF,
    CHAVE_REDIRECT_JCS,
    adicionarRedirecionamentoPosLogin,
} from 'core/util/StorageRedirectRulesHelper';
import AdminPaginaIntermediariaResumida from 'models/admin/AdminPaginaIntermediariaResumida';
import PaginaIntermediariaModeloSecaoDTO from 'models/generic-components/PaginaIntermediariaModeloSecaoDTO';
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import TipoModeloCursos from './components/TipoModeloCursos/TipoModeloCursos';
import TipoModeloEmBranco from './components/TipoModeloEmBranco/TipoModeloEmBranco';
import PaginaBaseStyled from './PaginaBase.styled';

const TIPO_TEMPLATE_EM_BRANCO: number = 1;
const TIPO_TEMPLATE_CURSOS: number = 2;

interface IPaginaBase {}

const PaginaBase: FunctionComponent<IPaginaBase> = ({ ...props }: IPaginaBase) => {
    const location = useLocation();
    const history = useHistory();

    const paginaIntermediariaService = new PaginaIntermediariaService();
    const gestaoPaginaService = new AdminGestaoPaginaService();

    const [paginaIntermediaria, setPaginaIntermediaria] = useState<AdminPaginaIntermediariaResumida | null>(null);
    const [listaSecoes, setListaSecoes] = useState<PaginaIntermediariaModeloSecaoDTO[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const isPaginaModeloCursosLivres = (paginaIntermediaria: AdminPaginaIntermediariaResumida) => {
        return paginaIntermediaria.tipoTemplate === TIPO_TEMPLATE_CURSOS;
    };

    const setupRedirectStore = (path: string) => {
        if (path.includes('realizandoEscolhas') || path.includes('realizandoescolhas')) {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_RE, CHAVE_REDIRECT_RE);
        } else if (path.includes('competencias-socioemocionais')) {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_JCS, CHAVE_REDIRECT_JCS)
        } else if (path.includes('trilha-maker-criatividade')) {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_TM, CHAVE_REDIRECT_TM);
        } else if (path.includes('trilha-de-alfabetizacao')) {
            adicionarRedirecionamentoPosLogin(CHAVE_REDIRECT_ALF, CHAVE_REDIRECT_ALF);
        } else {
            limparRedirecionamentosPosLogin();
        }
    };

    const loadPage = async () => {
        const pathname: string = location.pathname.split('/pagina/')[1];
        const response = await paginaIntermediariaService.buscarPaginaPorLink(pathname);

        if (response.status === 200) {
            const pagedata = new AdminPaginaIntermediariaResumida().fromJSON(response.data);
            setPaginaIntermediaria(pagedata);

            if (!isPaginaModeloCursosLivres(pagedata)) {
                const { data } = await gestaoPaginaService.listsAllPublic(pagedata.codigo);
                const _listaSecoes = Array.from(data)
                    .map((el: any) => new PaginaIntermediariaModeloSecaoDTO().fromJSON(el))
                    .sort((a, b) => a.ordem - b.ordem);

                setListaSecoes(_listaSecoes);
            }

            setupRedirectStore(pathname);
        } else {
            history.push('/not-found');
        }
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoading, loadPage);
    }, []);

    return (
        <PaginaBaseStyled.Container>
            {!loading && paginaIntermediaria && (
                <>
                    {isPaginaModeloCursosLivres(paginaIntermediaria) ? (
                        <>
                            <TipoModeloCursos />
                        </>
                    ) : (
                        <>
                            <TipoModeloEmBranco listaSecoes={listaSecoes}></TipoModeloEmBranco>
                        </>
                    )}
                </>
            )}
        </PaginaBaseStyled.Container>
    );
};

export default PaginaBase;
