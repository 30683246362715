import _ from 'lodash';
import { AnyAction } from 'redux';

import {
    DIARY_UPDATE,
    DIARY_GET_BY_ID,
    DIARY_CREATE_DIARY,
    DIARY_GET_ALL_TAGS,
    DIARY_EXCLUDE_DIARY,
    DIARY_GET_ALL_DIARIES,
    DIARY_INSERT_ATTACH,
    DIARY_REMOVE_ATTACH,
    DIARY_DUPLICATE_DIARY,
    DIARY_CLEAR_DATA,
} from '../actions/Diary';
import DiarioTag from 'models/diarioBordo/DiarioTag';
import DiarioBordo from 'models/diarioBordo/DiarioBordo';
import DiarioAttach from 'models/diarioBordo/DiarioAttach';

interface IState {
    tags: DiarioTag[];
    diaries: DiarioBordo[];
    current: DiarioBordo | null;
}

const initialState: IState = {
    tags: [],
    diaries: [],
    current: null,
};

const updateCurrentItems = (item: DiarioBordo | null, items: DiarioBordo[]) => {
    const clone = _.cloneDeep(items);

    if (item) {
        clone.forEach((diario, index) => {
            if (diario.id === item.id) {
                clone[index] = item;
            }
        });
    }

    return clone;
};

const homePageReducer = (state = initialState, action: AnyAction): IState => {
    switch (action.type) {
        case DIARY_GET_ALL_TAGS: {
            return {
                ..._.cloneDeep(state),
                tags: action.tags,
            };
        }
        case DIARY_GET_ALL_DIARIES: {
            return {
                ..._.cloneDeep(state),
                diaries: updateCurrentItems(action.current, action.diaries),
                current: action.current,
            };
        }
        case DIARY_CREATE_DIARY: {
            return {
                ..._.cloneDeep(state),
                diaries: action.diaries,
                current: action.current,
            };
        }
        case DIARY_GET_BY_ID:
        case DIARY_UPDATE: {
            return {
                ..._.cloneDeep(state),

                current: action.current,
                diaries: updateCurrentItems(action.current, state.diaries),
            };
        }
        case DIARY_EXCLUDE_DIARY: {
            const diaryList = _.cloneDeep(state.diaries).filter(item => item.id !== action.clearId);
            const current = diaryList.length > 0 ? diaryList[0] : null;

            return {
                ..._.cloneDeep(state),
                current: current,
                diaries: diaryList,
            };
        }
        case DIARY_DUPLICATE_DIARY: {
            const clonedDiary: DiarioBordo = action.current;
            const diaryList = _.cloneDeep(state.diaries);
            diaryList.push(clonedDiary);
            return {
                ..._.cloneDeep(state),
                current: clonedDiary,
                diaries: diaryList,
            };
        }

        ///Reducers de Anexos de Diarios
        case DIARY_INSERT_ATTACH: {
            const newAttach: DiarioAttach = action.attach;
            const current = state.current;

            if (current?.attachs) {
                current.attachs.push(newAttach);
            } else if (current) current.attachs = [newAttach];

            return {
                ..._.cloneDeep(state),
                current,
                diaries: updateCurrentItems(current, state.diaries),
            };
        }

        case DIARY_REMOVE_ATTACH: {
            const attachId = action.attachId;
            const current = state.current;

            if (current?.attachs) {
                current.attachs = current.attachs.filter(attach => attach.id != attachId);
            }

            return {
                ..._.cloneDeep(state),
                current,
                diaries: updateCurrentItems(current, state.diaries),
            };
        }

        case DIARY_CLEAR_DATA: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export default homePageReducer;
