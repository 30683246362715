import styled from 'styled-components';

import Colors from '../../enums/Colors';
import { MobileMaxWidth } from '../../constants/Dimensions';

export const UnauthorizedStyle = styled.div({
    backgroundColor: Colors.White,
    height: '100%',
});

export const MainContainer = styled.section`
    padding-top: 86px;
    position: relative;
    height: 100%;
    padding: 40px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-top: 60px;
    }
`;

export const ContentContainer = styled.div({
    padding: 40,
});

export const Title = styled.p`
    color: ${Colors.DarkBlue};
    font-size: 32px;
    line-height: 42px;
    font-weight: bold;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 24px;
        line-height: 28px;
    }
`;
