import React, { useState, useEffect, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SideImage from '../SideImage';
import FormHeader from '../formHeader/FormHeader';
import { FormSection } from './Identificacao.styled';
import ProfileData from '../../../../models/ProfileData';
import { ProgressConsumer } from '../../ProgressContext';
import IdentificacaoForm from './Form/IdentificacaoForm';
import { IdentificacaoStyle } from './Identificacao.styled';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';

import * as profileActions from '../../../../store/actions/Profile';

import LocationImg from '../../../../images/profile/location.svg';
import QuestionMark from '../../../../images/profile/question_mark_img.svg';
import { useAppSelector } from '../../../../hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import { RoleEnum } from 'enums/RoleEnum';
import { isUserFromLMS, isUsuarioLms } from 'core/util/KeycloakHelp';
import { ProfileContext } from 'pages/profile/context/ProfileContext';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const Identificacao = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentImg, setCurrentImg] = useState(QuestionMark);
    const [statusProgresso, setStatusProgresso] = useState<number>(10);
    const [hasProfileData, setHasProfileData] = useState<boolean>();

    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);
    const authData: UsuarioDTO | null = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const { _setPostProfilePath } = useContext(ProfileContext.Context);
    const location: any = useLocation();

    useEffect(() => {
        if (location && location?.state.postProfilePath) {
            _setPostProfilePath(location?.state.postProfilePath);
        }
    }, []);

    const [title, setTitle] = useState<string>(
        isUsuarioLms(usuarioLogado) ? 'Que bom ter você de volta!' : 'Chegou a hora de construir seu perfil!'
    );

    useEffect(() => {
        if (profileData && profileData.identificacaoCompleta) {
            setHasProfileData(true);
            setTitle('Onde você reside?');
            setCurrentImg(LocationImg);
        }
    }, []);

    const profileParameterData: any = useAppSelector(state => state.profileReducer.parametrosCadastro);

    const isExaminee = (): boolean => {
        return authData.roles.some(r => r == RoleEnum.Estudante);
    };

    const onContinuar = async () => {
        if (isExaminee()) history.push('/profile/identificacaoImagem');
        else history.push('/profile/atuacao');
    };

    const reloadContent = (formik: any, name: string, progress: number, image: any, titleParam?: string) => {
        formik.setFieldTouched(name);
        if (progress >= statusProgresso && !hasProfileData) {
            setStatusProgresso(progress);
            if (image && image !== currentImg) setCurrentImg(image);
            if (titleParam && titleParam !== title) setTitle(titleParam);
        }
    };

    return (
        <IdentificacaoStyle>
            <ProgressConsumer>
                {value => {
                    const { progresso, setProgresso } = value;
                    if (statusProgresso > progresso) {
                        setProgresso(statusProgresso);
                    }
                    return (
                        <FormSection id="FormSection">
                            <FormHeader
                                title={title}
                                subtitle={
                                    isUsuarioLms(usuarioLogado)
                                        ? `Você já tem uma conta no Instituto Ayrton Senna.
                                           Atualize seu perfil para também ter acesso à plataforma humane.`
                                        : `Nos conte um pouco mais sobre você!`
                                }
                            />

                            <IdentificacaoForm
                                onContinuarHandler={onContinuar}
                                ReloadContentHandler={reloadContent}
                                profileParameterData={profileParameterData}
                                profileData={profileData}
                            />
                        </FormSection>
                    );
                }}
            </ProgressConsumer>
            {!IsMobile() && <SideImage image={currentImg} />}
        </IdentificacaoStyle>
    );
};

export default Identificacao;
