import { bucketS3, caminhoAnexosRecursosCostumizados } from 'constants/EnvironmentData';
import React, { useEffect, useRef, useState } from 'react';
import Styled from '../../RecursosConteudoPage.styled';

export interface IRecursoMediaSwitch {
    anexoUrl: string;
    marginBottom?: string;
}

const RecursoMediaSpotify = ({ url }: { url: string }) => {
    const [trackId, setTrackId] = useState('');

    useEffect(() => {
        const re = /\/[a-zA-Z0-9_]{22,}(\?)*/g;
        const matches = url.match(re);

        if (matches) {
            let _trackId = matches[0].replace('/', '').replace('?', '');
            setTrackId(_trackId);
        }
    }, []);

    return (
        <>
            <iframe
                style={{ borderRadius: '12px' }}
                src={'https://open.spotify.com/embed/track/' + trackId}
                width="100%"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
            ></iframe>
        </>
    );
};

const RecursoMediaSoundCloud = ({ url }: { url: string }) => {
    const [trackId, setTrackId] = useState('');

    useEffect(() => {
        const re = /\d{10,}&/g;
        const matches = url.match(re);

        if (matches) {
            let _trackId = matches[0].replace('&', '');
            setTrackId(_trackId);
        }
    }, []);

    return <iframe width="100%" src={'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/' + trackId}></iframe>;
};

const RecursoMediaAudioHumane = ({ url }: { url: string }) => {
    return (
        <Styled.AudioContainer>
            <audio controls src={url}></audio>
        </Styled.AudioContainer>
    );
};

const RecursoMediaYoutube = ({ height, url }: { height: number; url: string }) => {
    const [trackId, setTrackId] = useState('');

    useEffect(() => {
        const re = /\?v=[a-zA-Z0-9_-]{11,}/g;
        const matches = url.match(re);

        if (matches) {
            let _trackId = matches[0].replace('?v=', '');
            setTrackId(_trackId);
        }
    }, []);

    return (
        <iframe
            width="100%"
            height={height}
            src={'https://www.youtube.com/embed/' + trackId}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
    );
};

const RecursoMediaVimeo = ({ height, url }: { height: number; url: string }) => {
    const [trackId, setTrackId] = useState('');

    useEffect(() => {
        const re = /\/\d{9,}/g;
        const matches = url.match(re);

        if (matches) {
            let _trackId = matches[0].replace('/', '').replace('?', '');
            setTrackId(_trackId);
        }
    }, []);

    return (
        <iframe
            src={'https://player.vimeo.com/video/' + trackId}
            width="100%"
            height={height}
            allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
    );
};

const RecursoMediaImage = ({ url }: { url: string }) => {
    const getUrlImage = (): string => {
        return bucketS3() + caminhoAnexosRecursosCostumizados() + url;
    };

    return (
        <Styled.ImageContainer>
            <Styled.Image src={getUrlImage()} />
        </Styled.ImageContainer>
    );
};

const RecursoMediaSwitch = ({ anexoUrl, marginBottom }: IRecursoMediaSwitch) => {
    const container = useRef<HTMLDivElement | null>(null);
    const [wcontainer, setWcontainer] = useState<number>(713);

    const switchMedia = () => {
        if (anexoUrl.includes('spotify')) return <RecursoMediaSpotify url={anexoUrl} />;
        else if (anexoUrl.includes('soundcloud')) return <RecursoMediaSoundCloud url={anexoUrl} />;
        else if (anexoUrl.includes('institutoayrtonsenna.org.br/app/uploads')) return <RecursoMediaAudioHumane url={anexoUrl} />;
        else if (anexoUrl.includes('vimeo')) return <RecursoMediaVimeo url={anexoUrl} height={wcontainer * 0.5625} />;
        else if (anexoUrl.includes('youtu')) return <RecursoMediaYoutube url={anexoUrl} height={wcontainer * 0.5625} />;
        else return <RecursoMediaImage url={anexoUrl} />;
    };

    useEffect(() => {
        if (container.current) {
            setWcontainer(container.current.clientWidth);
        }
    }, [container.current]);

    return <Styled.MediaContainer style={{marginBottom: marginBottom}}>{switchMedia()}</Styled.MediaContainer>;
};

export default RecursoMediaSwitch;
