import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const PaginaIntermediariaStyled = {
    MainContainer: styled.div`
        margin-top: 30px;
        margin-bottom: 70px;
    `,

    HeaderContainer: styled.div``,
    Title: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: ${Colors.DarkBlue};
    `,

    CursosContainer: styled.div`
        display: grid;
        grid-template-columns: 1fr 450px;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px #ddd solid;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,

    RowGrid: styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        margin-top: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
            padding: 20px 7px;
        }
    `,

    ColumnGrid: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: 10px;
        }
    `,

    RightColumn: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    `,
};

export default PaginaIntermediariaStyled;
