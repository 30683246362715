import React from 'react';
import { IColumnsAuthoresEbook } from '../modules/AlfaTeoriaPratica/AuthoresEbook';
import styled from 'styled-components';

import Styled from '../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';

const StyledContent = {
    Container: styled.div`
        display: flex;
        align-items: end;
        text-align: end;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    `,

    SubTitle: styled.div`
        color: ${Colors.Blue};
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
    `,
};

const ContentAuthores = ({ title, subTitles }: IColumnsAuthoresEbook) => {
    return (
        <StyledContent.Container>
            <Styled.TitleBlue style={{ fontSize: 16, marginBottom: 10 }} dangerouslySetInnerHTML={{__html: title}} />
            {subTitles.length > 0 && subTitles.map((text, index) => <StyledContent.SubTitle key={index}>{text}</StyledContent.SubTitle>)}
        </StyledContent.Container>
    );
};

export default ContentAuthores;
