import React, { useContext } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import HomepageMenu from './homepageMenu/HomepageMenu';
import { IsCustomMaxWidth, IsMobileMaxWidth } from '../../core/util/WindowSize';
import * as profileActions from '../../../src/store/actions/Profile';
import * as pdpActions from '../../../src/store/actions/Pdp';
import * as diaryActions from '../../store/actions/Diary';

import * as authenticationActions from '../../../src/store/actions/Authentication';

import Logo from '../../images/logo/logo-header.svg';
import LogoHumane from '../../images/logo/logo-humane.svg';

import Helmet from '../../images/logo/logo-default.svg';
import { LogoContainer, Img, MenuStyle, RightButton, RightMobileButton, RightSideContainer, SvgIcon } from './Menu.styled';
import MenuTabs from './mobile/MenuTabs';
import Colors from '../../enums/Colors';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakLogoutOptions } from 'keycloak-js';
import UserAvatar from './userAvatar/UserAvatar';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { MenuItemsContainer } from './homepageMenu/HomepageMenuStyled.styled';
import { OtherPageWebItems } from './homepageMenu/NavMenuItems';
import ActionButton from './ActionButton/ActionButton';
import { GTM_Menu } from 'components/GoogleTagManager';
import CheckedGoToProvider from 'pages/painelSocioemocional/home/context/CheckedGoToProvider';
import { CheckedGoToContext } from 'pages/painelSocioemocional/home/context/CheckedGoToContext';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';

interface IMenu {
    homepage?: boolean;
    homeIntermediate?: boolean;
    // login?: boolean;
    profile?: boolean;
    homeSocioemocional?: boolean;
    biblioteca?: boolean;
    realizandoEscolhas?: boolean;
    admin?: boolean;
    unauthorized?: boolean;
}

const IsMobile = () => {
    return IsCustomMaxWidth(1250);
};

const profileIdentificacao: string = '/profile/informacoes';

const Menu = ({
    homepage,
    homeIntermediate,
    // login,
    profile,
    homeSocioemocional,
    biblioteca,
    realizandoEscolhas,
    admin,
    unauthorized,
}: IMenu) => {
    const LogoSource = LogoHumane;
    const location = useLocation();
    const history = useHistory();

    const { showModal, setShowModal, goToPage } = useContext(CheckedGoToContext.Context);

    const logout = async () => {
        if (unauthorized) history.push('/');
        else history.push('/logout');
    };

    const LabelProfileRightButton = () => {
        const retorno: string = location.pathname === profileIdentificacao ? 'Sair' : 'Voltar';
        return <>{retorno}</>;
    };

    const logoutOnClickHandler = () => {
        if (location.pathname === profileIdentificacao) {
            logout();
        } else {
            history.goBack();
        }
    };

    const logoutButton = (location: string) => {
        return (
            <RightMobileButton
                onClick={() => {
                    GTM_Menu('menu_header', `logout_btn_${location}`);
                    logout();
                }}
            >
                <SvgIcon>
                    <circle cx="18" cy="18" r="17.5" stroke={Colors.BlueGrey} />
                    <path d="M20.41 22.59L15.83 18L20.41 13.41L19 12L13 18L19 24L20.41 22.59Z" fill={Colors.BlueGrey} />
                </SvgIcon>
            </RightMobileButton>
        );
    };

    const Logo = () => {
        const { isVerifyGoToPage } = useContext(CheckedGoToContext.Context);

        return (
            <LogoContainer>
                <Img
                    onClick={() => {
                        GTM_Menu('header_menu', 'btn_click_logo');
                        isVerifyGoToPage('/home');
                    }}
                    src={LogoSource}
                />
            </LogoContainer>
        );
    };

    return (
        <MenuStyle>
            <CheckedGoToProvider>
                <Logo />

                <RightSideContainer>
                    {/*Componente para as telas da home*/}
                    {homepage && <HomepageMenu jornada={false} homeIntermediate={homeIntermediate ? true : false} />}

                    {/*Componente para a tela do login*/}
                    {/* {login && !IsMobile() && <RightButton onClick={history.goBack}>Voltar</RightButton>} */}

                    {/*Componente para a tela de profile*/}
                    {profile && !IsMobile() && (
                        <RightButton
                            onClick={() => {
                                GTM_Menu('header_menu', 'btn_click_voltar_logout_profile');
                                logoutOnClickHandler();
                            }}
                        >
                            <LabelProfileRightButton />
                        </RightButton>
                    )}

                    {profile && IsMobile() && (
                        <RightMobileButton
                            onClick={() => {
                                GTM_Menu('header_menu', 'btn_click_voltar_logout_profile_mobile');
                                logoutOnClickHandler();
                            }}
                        >
                            <SvgIcon>
                                <circle cx="18" cy="18" r="17.5" stroke={Colors.BlueGrey} />
                                <path d="M20.41 22.59L15.83 18L20.41 13.41L19 12L13 18L19 24L20.41 22.59Z" fill={Colors.BlueGrey} />
                            </SvgIcon>
                        </RightMobileButton>
                    )}

                    {(biblioteca || realizandoEscolhas) && (
                        <>
                            {IsMobile() ? (
                                <HomepageMenu jornada={false} homeIntermediate={true} />
                            ) : (
                                <MenuItemsContainer>
                                    <OtherPageWebItems />
                                    <ActionButton />
                                </MenuItemsContainer>
                            )}
                        </>
                    )}

                    {/*Componente para a tela da home do socioemocional*/}
                    {homeSocioemocional && (
                        <>
                            {IsMobile() ? (
                                <HomepageMenu jornada={true} homeIntermediate={false} />
                            ) : (
                                <MenuItemsContainer>
                                    <OtherPageWebItems />
                                    <ActionButton />
                                </MenuItemsContainer>
                            )}
                        </>
                    )}

                    {admin && (
                        <>
                            {IsMobile() ? (
                                logoutButton('admin')
                            ) : (
                                <RightButton
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'logout_btn_admin');
                                        logout();
                                    }}
                                >
                                    Sair
                                </RightButton>
                            )}
                        </>
                    )}

                    {unauthorized && (
                        <>
                            {IsMobile() ? (
                                logoutButton('unauthorized')
                            ) : (
                                <RightButton
                                    onClick={() => {
                                        GTM_Menu('menu_header', 'logout_btn_unauthorized');
                                        logout();
                                    }}
                                >
                                    Sair
                                </RightButton>
                            )}
                        </>
                    )}
                </RightSideContainer>
                <ModalCheckGoToPage
                    showModal={showModal}
                    setShowModal={setShowModal}
                    goToPage={goToPage}
                    isRealizandoEscolhas={location.pathname === '/realizandoEscolhas/avaliacao' ? true : false}
                />
            </CheckedGoToProvider>
        </MenuStyle>
    );
};

export default Menu;
