import { AdminContext } from 'pages/admin/context/AdminContext';
import React, { Fragment, useContext } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import AdminContent from '../AdminContent';
import { AdminBarraLateralItemType } from '../barraLateral/AdminBarraLateral';
import Styled from './AdminBreadCrumb.styled';

export type AdminBreadCrumbType = {
    label: string;
    route: string;
};

export interface IAdminBreadCrumb {
    crumbs: AdminBreadCrumbType[];
}

const AdminBreadCrumb = ({ crumbs }: IAdminBreadCrumb) => {
    const history = useHistory();

    const { setMenu } = useContext(AdminContext.Context);

    const isLast = (crumb: AdminBreadCrumbType): boolean => {
        const last = crumbs[crumbs.length - 1];

        return crumb.route == last.route;
    };

    const gotoRoute = (crumb: AdminBreadCrumbType) => {
        if (!isLast(crumb)) history.push('/admin/' + crumb.route);
    };

    return (
        <Styled.Container>
            <Styled.Item active={false} onClick={() => setMenu(AdminBarraLateralItemType.HOME)}>
                Início
            </Styled.Item>
            <Styled.Separator>
                <IoIosArrowForward />
            </Styled.Separator>

            {crumbs.map(c => {
                const IsLast = isLast(c);
                return (
                    <Fragment key={c.route}>
                        <Styled.Item active={IsLast} onClick={() => gotoRoute(c)}>
                            {c.label}
                        </Styled.Item>

                        {!IsLast && (
                            <Styled.Separator>
                                <IoIosArrowForward />
                            </Styled.Separator>
                        )}
                    </Fragment>
                );
            })}
        </Styled.Container>
    );
};

export default AdminBreadCrumb;
