import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminStyled = {
    Container: styled.div`
        padding-top: 86px; //altura do Menu
        display: flex;
        background-color: #f6f6f6;
        overflow: hidden;
        height: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding-top: 0; //altura do Menu
        }
    `,

    MenuIcon: styled.div`
        display: none;
        font-size: 2rem;
        padding: 10px;
        color: #ccc;
        margin-top: 56px;
        background-color: #f6f6f6;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: block;
        }
    `,

    ContainerContent: styled.div`
        width: 100%;
        overflow-y: scroll;
        padding: 0 20px;

        min-height: calc(100vh - 86px);

        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 10px;
        }
    `,
};

export default AdminStyled;
