import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import { GradientButton } from 'components/UI/Buttons';
import motivations from './wordsBlockMotivations';

import closeIcon from '../../../../../images/close-circle.svg';
import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import AdminPalavraProibida from 'models/admin/AdminPalavraProibida';
import Select from 'components/Select';

export interface IAdminModalEditPalavra {
    show: boolean;
    onHide: () => void;
    onEdit: () => void;
    word: AdminPalavraProibida;
}

const AdminModalEditword = ({ show, onEdit, onHide, word }: IAdminModalEditPalavra) => {
    const [_word, setWord] = useState(word.content);
    const [motivation, setMotivation] = useState(word.motivation);
    const [variations, setVariations] = useState<string>(word.variations.join(','));

    const edit = async () => {
        let variacao: string[] = [];

        if (variations)
            variacao = variations
                .replace(/\s+/g, ' ')
                .split(/[\s,;]+/)
                .filter(s => s.trim().length > 0);

        const service = new AdminBiblioService();
        const { data } = await service.editarPalavraProibida(word.id, _word, variacao ?? [], motivation);
        onEdit();
    };

    useEffect(() => {
        if (word) setWord(word.content);
    }, []);

    return (
        <Styled.Modal centered show={show} onHide={onHide}>
            {word && (
                <Styled.Container style={{ padding: '10px 30px' }}>
                    <Styled.CloseContainer>
                        <img src={closeIcon} onClick={onHide} />
                    </Styled.CloseContainer>

                    <Styled.ModalEditTitle>Editar palavra proibida</Styled.ModalEditTitle>
                    <Styled.Text>Escreva um nova palavra que será banida na plataforma</Styled.Text>
                    <Styled.Input value={_word} onChange={(e: any) => setWord(e.target.value)} />
                    <br />
                    <Styled.Text>Escreva outras formas que essa palavra pode ser escrita</Styled.Text>
                    <Styled.Input value={variations} onChange={(e: any) => setVariations(e.target.value)} />
                    <br />
                    <Select itens={motivations} placeholder={'Motivação'} onSelected={option => setMotivation(String(option.value))} />
                    <Styled.RightContainer>
                        <GradientButton disabled={_word.trim().length == 0} onClick={edit}>
                            Editar
                        </GradientButton>
                    </Styled.RightContainer>
                </Styled.Container>
            )}
        </Styled.Modal>
    );
};

export default AdminModalEditword;
