import HomepageResult from 'models/homepage/HomepageResult';
import React, { createContext } from 'react';

export namespace PesquisaContext {
    export interface IContext {
        isSearchView: boolean;
        closeSearch: () => void;
        openSearch: () => void;

        isResultView: boolean;
        showResults: () => void;
        closeResults: () => void;

        term: string;
        resultFor: string;
        setTerm: (term: string) => void;

        results: HomepageResult[];
    }

    export const Context = createContext({} as IContext);
}
