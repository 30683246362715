import React, { useEffect, useState } from 'react';
import MainContainer, { ImgIconSvg, OptionsContainer, Text } from './SharingContent.styled';
import DownloadIcon from '../components/assets/images/downloadIcon.svg';
import WhatsAppIcon from '../components/assets/images/whatsappIcon.svg';
import FacebookIcon from '../components/assets/images/facebook.svg';
import LinkIcon from '../components/assets/images/linkIcon.svg';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import { ToasterDefault, ToastSuccess } from 'components/Toaster';
import { PaginasEnum } from 'enums/PaginasEnum';
export interface ISharingContent {
    location: string;
}

const SharingContent = ({ location }: ISharingContent) => {
    const url = window.location.href;

    const copyToClipboard = () => {
        copy(url);
        ToastSuccess('Link copiado com sucesso!');
    };

    const shareWhatsapp = () => {
        const _url = 'https://api.whatsapp.com/send?text=' + url;
        window.open(_url, '_blank');
    };

    const shareFacebook = () => {
        const path = 'https://humane.institutoayrtonsenna.org.br/realizandoEscolhas/trilhaFormativa';
        const _url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(path);
        window.open(_url, '_blank');
    };

    return (
        <MainContainer>
            <Text>{'Baixe ou compartilhe'}</Text>
            <OptionsContainer>
                <ImgIconSvg
                    data-tip={'Baixar PDF'}
                    src={DownloadIcon}
                    onClick={() => {
                        GTM_Page_Realizando_Escolhas('share_realizando_escolhas', `${location}_baixar_pdf_btn`);
                        window.print();
                    }}
                />

                <ImgIconSvg
                    data-tip={'Envie por Whatsapp'}
                    src={WhatsAppIcon}
                    onClick={() => {
                        GTM_Page_Realizando_Escolhas('share_realizando_escolhas', `${location}_whatsapp_btn`);
                        shareWhatsapp();
                    }}
                />

                <ImgIconSvg
                    data-tip={'Envie por Facebook'}
                    src={FacebookIcon}
                    onClick={() => {
                        GTM_Page_Realizando_Escolhas('share_realizando_escolhas', `${location}_whatsapp_btn`);
                        shareFacebook();
                    }}
                />

                <ImgIconSvg
                    data-tip={'Copiar link'}
                    src={LinkIcon}
                    onClick={() => {
                        GTM_Page_Realizando_Escolhas('share_realizando_escolhas', `${location}_clipboard_btn`);
                        copyToClipboard();
                    }}
                />
            </OptionsContainer>
            {ToasterDefault()}

            {!IsCustomMaxWidth(1250) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </MainContainer>
    );
};

export default SharingContent;
