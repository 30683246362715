import { useKeycloak } from '@react-keycloak/web';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import SharingContent from '../components/SharingContent';
import {
    Container,
    TopContent,
    Byline,
    Title,
    InnerTopContent,
    BottomContent,
    ButtonInitialPage,
    ButtonNext,
    Content,
    Text,
} from './Fundamentos.styled';
import { CarouselCardContainer } from '../components/Carousel.styled';
import Card from '../components/Card';
import CardData from '../data/CardData.json';
import Carousel from '../components/Carousel';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import SharingContentMobile from '../components/SharingContentMobile';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';

export interface IFundamentos {}

const Fundamentos = ({}: IFundamentos) => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const [ocupacao, setOcupacao] = useState('');
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const service = new RealizandoEscolhasService();
    const CODIGO_TIPO_CONTEUDO: number = 2;

    const registrarAcesso = async () => {
        await service.registrarAcesso(CODIGO_TIPO_CONTEUDO);
    };

    useEffect(() => {
        registrarAcesso();
        const userOcupacao = buscarOcupacaoPorPerfil(profile);
        setOcupacao(userOcupacao);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('fundamentos_realizando_escolhas', 'voltar_home_realizando_escolhas');
        history.push('/realizandoEscolhas/home');
    };

    const goToTrilhaFormativa = () => {
        GTM_Page_Realizando_Escolhas('fundamentos_realizando_escolhas', 'acessar_trilha_formativa');
        history.push('/realizandoEscolhas/trilhaFormativa');
    };

    return (
        <Container>
            <TopContent>
                <InnerTopContent>
                    <Title>{'Fundamentos da ciência'}</Title>
                    <Byline>{'O que dizem as evidências?'}</Byline>
                </InnerTopContent>
                {IsMobileMaxWidth() ? <SharingContentMobile location="fundamentos" /> : <SharingContent location="fundamentos" />}
            </TopContent>

            <Content>
                <Text>
                    Os interesses profissionais se relacionam com as preferências de cada pessoa, como atividades que gostam ou gostariam de
                    fazer ao trabalhar. Para entender mais, o pesquisador John Holland propôs um modelo que compreende interesses de acordo com
                    atividades e ambientes. Nele, os interesses são classificados em seis tipos:
                </Text>
                <Text>
                    <strong>Realista (R), Investigativo (I), Artístico (A), Social (S), Empreendedor (E) e Convencional (C).</strong>
                </Text>
                <Text style={{ paddingTop: '20px' }}>Entretanto, ao falar de interesses profissionais, precisamos ter alguns cuidados:</Text>

                <Carousel>
                    {CardData.data.map((item, index) => {
                        return (
                            <CarouselCardContainer key={index}>
                                <Card title={item.title} description={item.text} isFundamentos={true} />
                            </CarouselCardContainer>
                        );
                    })}
                </Carousel>
            </Content>

            <BottomContent style={!IsMobile() ? {} : { flexDirection: 'column-reverse' }}>
                {IsMobile() && (
                    <BottomContent>
                        <ButtonInitialPage onClick={goToHomePage}>{'Página inicial Realizando Escolhas'}</ButtonInitialPage>
                    </BottomContent>
                )}
                {ocupacao !== 'C' && <ButtonNext onClick={goToTrilhaFormativa}>{'Clique aqui e veja nossa trilha formativa'}</ButtonNext>}
            </BottomContent>
        </Container>
    );
};

export default Fundamentos;
