import { CSSObject } from 'styled-components';

interface IMediaQuery {
    [k: string]: CSSObject;
}
type internal = IMediaQuery | CSSObject;

export class MediaQuery {
    private selector: string;
    private media: CSSObject;
    constructor(selector: string, media: CSSObject | MediaQuery) {
        this.selector = selector;
        if (media instanceof MediaQuery) {
            this.media = media.getMedia();
        } else {
            this.media = media;
        }
    }

    public getMedia = (): CSSObject => this.media;
    public getSelector = (): string => this.selector;
}

/**
 * Classe que monta um CSS já com media queries
 */
class FullCss {
    private css: CSSObject;
    private queries: MediaQuery[];

    constructor(css: CSSObject, queries: MediaQuery[] | MediaQuery = []) {
        this.css = css;
        if (Array.isArray(queries)) {
            this.queries = queries;
        } else {
            this.queries = [queries];
        }
    }

    public getCss = (): CSSObject => {
        const obj: internal = {
            ...this.css,
        };
        this.queries.forEach(item => {
            obj[item.getSelector()] = item.getMedia();
        });

        return obj;
    };
}

export default FullCss;
