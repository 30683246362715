import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Capelo from './../assets/Capelo.svg';
import styled from 'styled-components';
import IntermediatePage from '../components/IntermediatePage';
import AdminCursoLivre from 'models/admin/AdminCursoLivre';
import { CursoLivreService } from 'core/http/service/CursoLivre.service';
import Loading from 'components/Loading';
import Colors from 'enums/Colors';
import CursoLivreCard from './CursoLivreCard';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import { GobackButton } from 'components/UI/Buttons';
import { useHistory } from 'react-router-dom';
import { limparRedirecionamentosPosLogin } from 'core/util/StorageRedirectRulesHelper';
import { bucketS3, caminhoAnexosCursosLivres } from 'constants/EnvironmentData';
import { DateHelp } from 'core/util/DateHelp';
import { DateSchema } from 'yup';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import cursosLivresSorter from 'pages/admin/components/cursoLivre/cursoLivreSorter';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { RESIZE_MEDIUM_WIDTH } from 'pages/pdp/UI/dialog/DialogFinal.styled';
import Slider from 'react-slick';
import { GTM_GA4_Ecommerce_View_Item_List, GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';

const CursoLivreDetailContainer: any = styled.div(() => {
    return new FullCss(
        {
            padding: '0px 40px 40px 105px',
            overflow: 'hidden',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
            padding: '40px 0',
        })
    ).getCss();
});

const TitlePage = styled.p({
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '42px',
    letterSpacing: '0.1px',
    color: Colors.Blue,
    marginBottom: '39px',
});

const SubtitlePage = styled.p({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '27px',
    Colors: Colors.DarkGrey,
    marginBottom: '90px',
});

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    margin-bottom: 125px;
    padding: 0px 20px 0px 0px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        display: block;
        margin-bottom: 80px;
    }
    @media only screen and (max-width: 912px) {
        justify-content: center;
        padding: 0;
    }
`;

const CardsContainerInner = styled.div`
    display: flex;
    justify-content: center;
`;

const SliderContainer = styled.div`
    max-width: 100%;
`;

const TextContainer = styled.div`
    padding: 0px 20px 0px 0px;
`;

interface ICursoLivreDetail {}

const CursoLivreDetail: FunctionComponent<ICursoLivreDetail> = ({ ...props }: ICursoLivreDetail) => {
    const [cursos, setCursos] = useState<AdminCursoLivre[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const [dadosPagina, setDadosPagina] = useState<AdminPaginaIntermediaria>(new AdminPaginaIntermediaria());
    const [isMobile, setIsMobile] = useState(false);
    const { isSearchView } = useContext(PesquisaContext.Context);

    const paginaService = new PaginaIntermediariaService();
    const service = new CursoLivreService();

    const PAGINA_KEY: string = 'CURSO_LIVRE';

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosCursosLivres();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= MobileMaxWidth);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const response: any = await service.listarPublico();

            const output: AdminCursoLivre[] = response.data.map((item: any) => new AdminCursoLivre().fromJSON(item));
            setCursos(cursosLivresSorter(output));
            buildViewItemListEvent(output);

            const responsePagina: any = await paginaService.listar();
            const outputPagina: AdminPaginaIntermediaria[] = responsePagina.data.map((item: any) =>
                new AdminPaginaIntermediaria().fromJSON(item)
            );
            if (outputPagina) {
                let paginaOutput: AdminPaginaIntermediaria =
                    outputPagina.find(item => item.pagina === PAGINA_KEY) ?? new AdminPaginaIntermediaria();
                setDadosPagina(paginaOutput);
            }
        } catch (error) {
            console.log('rrr', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
        limparRedirecionamentosPosLogin();
    }, []);

    const buildViewItemListEvent = (cursosParaEnviar: AdminCursoLivre[]) => {
        if (cursosParaEnviar.length !== 0 && !loading) {
            let paramEventList: any[] = [];
            cursosParaEnviar.map((item, index) => {
                paramEventList.push({ item_name: item.titulo, item_id: item.codigo.toString(), price: '0', index: index.toString() });
            });

            GTM_GA4_Ecommerce_View_Item_List(paramEventList);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <IntermediatePage
                title1="Outros recursos"
                title2="Cursos Livres"
                helmetTitle="Cursos Livres"
                helmetMetaDescription="Cursos gratuitos e de curta duração produzidos pelo Instituto Ayrton Senna"
                icon={Capelo}
                breadCrumbName={`Cursos Livres`}
            >
                <CursoLivreDetailContainer>
                    <TextContainer>
                        <TitlePage>{dadosPagina.titulo}</TitlePage>
                        <SubtitlePage>{dadosPagina.descricao}</SubtitlePage>
                    </TextContainer>
                    {loading ? (
                        <Loading />
                    ) : (
                        <CardsContainer>
                            {!isMobile ? (
                                cursos.map((curso, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <CursoLivreCard curso={curso} />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <>
                                    {cursos.length > 0 && (
                                        <SliderContainer>
                                            <Slider {...settings}>
                                                {cursos.map((curso, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <CardsContainerInner>
                                                                <CursoLivreCard curso={curso} />
                                                            </CardsContainerInner>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Slider>
                                        </SliderContainer>
                                    )}
                                </>
                            )}
                        </CardsContainer>
                    )}

                    <GobackButton
                        onClick={() => {
                            GTM_Page_Intermediaria('cursolivre_intermediaria', 'voltar_pagina_btn');
                            history.push('/');
                        }}
                    >
                        Voltar página inicial
                    </GobackButton>
                </CursoLivreDetailContainer>
            </IntermediatePage>

            {/* {isSearchView && <BarraPesquisa />} */}
        </>
    );
};

export default CursoLivreDetail;
