import React from 'react';
import Styled from './SecaoNewSimplesWithImage.styled';
import StyledGlobal from '../../CompetenciasSocioemocionais.styled';
import { MdArrowForward } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { GTM_Page_Competencias_Socioemocionais } from 'components/GoogleTagManager';
import useLogged from 'hooks/useLogged';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

enum positionContents {
    STARTED = 'STARTED',
    CENTER = 'CENTER',
    END = 'END',
}

interface ISecaoNewSimplesWithImage {
    title?: string;
    subTitle?: any[];
    textDestaque?: string[];
    img?: string;
    textButton: string;
    link: string;
    TipoRedirect?: TipoRedirecionamento 

    // OPTIONS CUSTOM
    isMobile: boolean;
    imgPosition?: number;
    imgWidth?: string;
    textDestaquePosIndex?: number;
}

const SecaoNewSimplesWithImage = ({
    title,
    subTitle,
    textDestaque,
    textButton,
    img,
    imgPosition,
    imgWidth,
    textDestaquePosIndex,
    isMobile,
    link,
    TipoRedirect,
}: ISecaoNewSimplesWithImage) => {
    const history = useHistory();
    const { authenticated } = useLogged();

    const goToLink = () => {
        GenericComponentsUtils.gotoLink(link, history, authenticated, {tipoRedirecionamento: TipoRedirect ?? TipoRedirecionamento.IGNORAR});
    };

    return (
        <Styled.Container isMobile={isMobile}>
            <StyledGlobal.Title isMobile={isMobile}>{title}</StyledGlobal.Title>
            <Styled.Content>
                <Styled.Column>
                    {subTitle &&
                        subTitle.length > 0 &&
                        subTitle.map((text, index) => {
                            return (
                                <>
                                    <StyledGlobal.SubTitle maxWidth={!isMobile ? "750px" : 'auto'} >{text}</StyledGlobal.SubTitle>
                                    {isMobile && index + 1 === imgPosition && <Styled.Img src={img} />}
                                    {index + 1 === textDestaquePosIndex &&
                                        textDestaque &&
                                        textDestaque.map((textDestaque, index) => (
                                            <Styled.TextDestaque key={index}>{textDestaque}</Styled.TextDestaque>
                                        ))}
                                </>
                            );
                        })}

                    <StyledGlobal.Button marginTop={"50px"}  onClick={goToLink} isMobile={isMobile}>
                        {textButton} <MdArrowForward size={24} />
                    </StyledGlobal.Button>
                </Styled.Column>
                {!isMobile && <Styled.Img width={imgWidth} src={img} />}
            </Styled.Content>
        </Styled.Container>
    );
};

export default SecaoNewSimplesWithImage;
