import React, { FunctionComponent, useState, useEffect, useCallback, useContext } from 'react';

import { useDispatch } from 'react-redux';

import PDP from 'models/pdp/PDP';
import Header from '../UI/header/Header';
import * as pdpActions from 'store/actions/Pdp';
import { GradientButton } from 'components/UI/Buttons';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import QuestionHolder from 'components/UI/QuestionHolder';
import Macrocompetencia from 'models/pdp/Macrocompetencia';
import Select, { SelectOptionType } from 'components/Select';
import RegistroMacrocompetencia from 'models/pdp/RegistroMacrocompetencia';
import { PriorizacaoStyle, QuestionContainer, SelectionSection, SelectionStyled, SelectLabel, ButtonContainer } from './Priorizacao.styled';
import { orderMacrocompetencia } from './Macrocompetencia/utils';
import { PdpContext } from '../context/PdpContext';
import { GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';

interface IPriorizacao {}
const Priorizacao: FunctionComponent<IPriorizacao> = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [macroSelection, setMacroSelection] = useState<SelectOptionType[]>([]);

    const [firstMacro, setFirstMacro] = useState<SelectOptionType>();
    const [secondMacro, setSecondMacro] = useState<SelectOptionType>();

    const [listaRegistroMacrocompetencia, setListaRegistroMacrocompetencia] = useState<RegistroMacrocompetencia[]>([]);

    const dispatch = useDispatch();
    const macrocompetencias: Macrocompetencia[] = useAppSelector(state => state.pdpReducer.macrocompetencias);
    const currentStep = useAppSelector(state => state.pdpReducer.currentStepForm);
    const pdp: PDP = useAppSelector(state => state.pdpReducer.pdp);
    const pdpOriginal: PDP = useAppSelector(state => state.pdpReducer.pdpOriginal);

    const { operacaoAlteracao } = useContext(PdpContext.Context);

    const loadData = async () => {
        setIsLoading(true);
        if (!macrocompetencias) {
            await dispatch(pdpActions.fetchMacrocompetencias());
        }
        await carregarLista();

        await inicializarPorNavegacao(pdp);
        setListaRegistroMacrocompetencia([...pdp.listaMacrocompetencias]);
        setIsLoading(false);
    };

    const inicializarPorNavegacao = async (pdp: PDP) => {
        if (pdp.listaMacrocompetencias.length > 0) {
            pdp.listaMacrocompetencias.forEach((item: RegistroMacrocompetencia) => {
                let macro: SelectOptionType = { label: item.macrocompetencia.descricao, value: item.macrocompetencia.id };
                if (item.ordem === 1) {
                    setFirstMacro(macro);
                } else if (item.ordem === 2) {
                    setSecondMacro(macro);
                }
            });
        }
    };

    const carregarLista = useCallback(async () => {
        let newArr: SelectOptionType[] = [];
        orderMacrocompetencia(macrocompetencias).forEach((item: Macrocompetencia) => {
            let newItem: SelectOptionType = { value: item.id, label: item.descricao };
            newArr.push(newItem);
        });
        setMacroSelection(newArr);
    }, [macrocompetencias]);

    useEffect(() => {
        loadData();
    }, []);

    const findMacro = (item: SelectOptionType | undefined): Macrocompetencia | null => {
        const selectedMacro: Macrocompetencia | undefined = macrocompetencias.find((macro: Macrocompetencia) => {
            return macro.id === item?.value;
        });
        return selectedMacro || null;
    };

    const onChangeMacro = (item: SelectOptionType, first: boolean) => {
        const listaRegistMacrocompetenciaAux = [...listaRegistroMacrocompetencia];
        let index = 0;

        if (first) {
            setFirstMacro(item);
        } else {
            setSecondMacro(item);
            index = 1;
        }

        listaRegistMacrocompetenciaAux[index] = new RegistroMacrocompetencia().fromJSON({
            ordem: index + 1,
            macrocompetencia: findMacro(item),
        });

        setListaRegistroMacrocompetencia(listaRegistMacrocompetenciaAux);
    };

    const onSubmitHandler = async () => {
        try {
            pdp.listaMacrocompetencias = listaRegistroMacrocompetencia;

            await dispatch(pdpActions.storePDP(pdp));

            dispatch(pdpActions.setCurrentFormStep(currentStep + 1));
        } catch (err) {
            console.error('err onSubmitHandler Priorizacao.tsx', err);
        }
    };

    return (
        <PriorizacaoStyle>
            <Header
                title={`Priorize as Macrocompetências a Desenvolver`}
                subtitle={`Com o resultado da sua autoavaliação socioemocional em mãos, utilize as questões a seguir para refletir sobre suas competências socioemocionais e elaborar os seus objetivos de desenvolvimento`}
            />
            <QuestionContainer>
                <QuestionHolder
                    numberValue={1}
                    question={`Registre numa ordem de prioridade, quais macrocompetências socioemocionais você gostaria de desenvolver a seguir:`}
                />
            </QuestionContainer>

            {!isLoading && (
                <>
                    <SelectionSection>
                        <SelectionStyled>
                            <SelectLabel>Macrocompetência 1</SelectLabel>
                            <Select
                                value={firstMacro}
                                width={'377px'}
                                itens={macroSelection.filter(item => {
                                    return item.value !== secondMacro?.value;
                                })}
                                onSelected={(item: SelectOptionType) => {
                                    onChangeMacro(item, true);
                                }}
                            />
                        </SelectionStyled>

                        <SelectionStyled>
                            <SelectLabel>Macrocompetência 2</SelectLabel>
                            <Select
                                value={secondMacro}
                                width={'377px'}
                                itens={macroSelection.filter(item => {
                                    return item.value !== firstMacro?.value;
                                })}
                                onSelected={(item: SelectOptionType) => {
                                    onChangeMacro(item, false);
                                }}
                            />
                        </SelectionStyled>
                    </SelectionSection>
                    <ButtonContainer>
                        <GradientButton
                            disabled={!firstMacro || !secondMacro}
                            onClick={() => {
                                GTM_Page_Jornada_Desenvolvimento('painel_pdp_priorizacao', 'continuar_btn');
                                onSubmitHandler();
                            }}
                        >
                            Continuar
                        </GradientButton>
                    </ButtonContainer>
                </>
            )}
        </PriorizacaoStyle>
    );
};

export default Priorizacao;
