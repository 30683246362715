import React from 'react';
import { BaseButton, GradientButton, YellowButton } from 'components/UI/Buttons';
import styled from 'styled-components';
import arrowDownload from '../../../../images/biblioteca/blue-download.svg';
import startIcon from '../../../../images/biblioteca/blue-star.svg';

const CommonStyled = {
    Title: styled.p`
        width: 90%;
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #1e4e7e;
        margin-bottom: 7px;
    `,

    SubTitle: styled.p`
        width: 100%;

        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        color: #5f758b;
        margin-top: 5px;
    `,

    Description: styled.p`
        width: 100%;

        font-family: Barlow;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color: #2e2e2e;

        margin-top: 20px;
    `,

    ActionButton: styled.img<{ width?: string }>`
        width: ${props => (props.width ? props.width : '50px')};
        height: auto;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
            transform: scale(1.2);
        }
    `,

    Icon: styled.img`
        height: 0.9rem;
        transform: translate(-7px);
    `,

    OutlinerButton: styled.button`
        padding: 16px 40px;
        width: 100%;
        max-width: 245px;
        max-height: 37px;
        border: 1px solid #003c78;
        border-radius: 72px;
        background-color: white;
        justify-content: center;

        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3px;
        color: #003c78;

        &:hover {
            background-color: #003c78;
            color: white;

            img {
                filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
            }
        }

        @media only screen and (max-width: 1200px) {
            width: 100%;
            max-width: none;
        }
    `,

    Date: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #003c78;
        margin-bottom: 20px;
    `,
};
interface IBiblioDownloadButton {
    onClick?: () => Promise<void>;
    preparing?: boolean;
}

export const BiblioDownloadButton = ({ onClick }: IBiblioDownloadButton) => {
    return (
        <YellowButton onClick={onClick}>
            <CommonStyled.Icon src={arrowDownload} />
            {'Baixar'}
        </YellowButton>
    );
};

interface IBiblioRateButton {
    onClick: () => void;
    disabled?: boolean;
}

export const BiblioRateButton = ({ onClick, disabled = false }: IBiblioRateButton) => {
    return (
        <CommonStyled.OutlinerButton onClick={onClick} disabled={disabled}>
            <CommonStyled.Icon src={startIcon} />
            <p>Avaliar</p>
        </CommonStyled.OutlinerButton>
    );
};

export default CommonStyled;
