import 'core-js/proposals/reflect-metadata';
import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class SocioEmocionalConteudo extends Serializable {
    @jsonProperty(Number, null)
    id: number = -1;

    @jsonName('secao')
    @jsonProperty(String)
    section: string = '';

    @jsonName('titulo')
    @jsonProperty(String)
    title: string = '';

    @jsonName('conteudo')
    @jsonProperty(String)
    content: string = '';

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    date: string = '';

    @jsonIgnore()
    cover: string = '';

    constructor() {
        super();
    }
}

export default SocioEmocionalConteudo;
