import { IsCustomMinWidth } from 'core/util/WindowSize';
import React from 'react';
import { useHistory } from 'react-router-dom';

import TextoApresentacao from '../../../../../../components/TextoApresentacao';
import JourneyChild from '../assets/JourneyChild.png';
import InitialPageButton from '../BackInitialPageButton';
import { YellowButton, SplitContainer, ImgLateral, Img, Container, ButtonContainer } from './Inicio.styled';

export interface IInicio {}

export const gotoSection = (sectionId: string) => {
    const el = document.getElementById(sectionId);

    if (el) {
        el.scrollIntoView({ block: 'nearest' });
    }
};

const Inicio = ({}: IInicio) => {
    const history = useHistory();

    const GoToInitialPage = () => {
        history.push('/');
    };

    const InicioPic = () => {
        return <Img src={JourneyChild} />;
    };

    const Style = () => {
        return IsCustomMinWidth(1200) ? { paddingTop: '55px', maxWidth: '480px' } : { paddingTop: '30px', maxWidth: '100%' };
    };

    return (
        <Container>
            <InitialPageButton goToLocation={() => GoToInitialPage()} />
            <SplitContainer>
                <TextoApresentacao
                    style={{ container: Style(), titulo: {}, subtitulo: {} }}
                    tipo="Jornada de Desenvolvimento"
                    titulo="Criatividade e Pensamento Critico"
                    subtitulo="Lidar com as demandas do século 21 e com os desafios da educação  requer um conjunto de competências, como o pensamento crítico e a criatividade. Confira nossos conteúdos para se desenvolver na temática!"
                    midia={InicioPic()}
                >
                    <ButtonContainer>
                        <YellowButton onClick={() => gotoSection('lancamento_section')}>{'Acessar os conteúdos'}</YellowButton>
                    </ButtonContainer>
                </TextoApresentacao>
                <ImgLateral src={JourneyChild} />
            </SplitContainer>
        </Container>
    );
};

export default Inicio;
