import React, { useEffect, useState } from 'react';
import Styled from './BiblioCollectionPage.styled';

import iconClose from '../../../../images/close-circle.svg';
import BiblioCollection from 'models/biblioteca/BiblioCollection';
import newCollectionIcon from '../../../../images/biblioteca/new-collection.png';
import BiblioColecaoService from 'core/http/service/biblioteca/BiblioColecao.service';

import coverDefalut from '../../../../images/biblioteca/cover-default.png';
import Loading from 'components/Loading';

export interface IBiblioCollectionModal {
    show: boolean;
    onHide: () => void;
    onSelected: () => void;
    toCreate: () => void;
    item_id: number;
}

const service = new BiblioColecaoService();

const BiblioCollectionModal = ({ show, onHide, item_id, onSelected, toCreate }: IBiblioCollectionModal) => {
    const [collections, setCollections] = useState<BiblioCollection[]>([]);
    const [loading, setloading] = useState<boolean>(false);

    const onSelect = async (collection_id: number) => {
        const response = await service.adicionarItem(item_id, collection_id);

        if (response.status == 200) {
            onSelected();
            onHide();
        }
    };

    useEffect(() => {
        if (show) fecthCollections();
    }, [show]);

    const fecthCollections = async () => {
        setloading(true);
        const response = await service.buscarColecoes();

        const _collections = response.data.map((data: any) => new BiblioCollection().fromJSON(data));
        setCollections(_collections);
        setloading(false);
    };

    return (
        <>
            <Styled.Modal.Component centered show={show} onHide={onHide}>
                <Styled.Modal.Container>
                    <Styled.Modal.CloseContainer>
                        <Styled.Modal.IconClose src={iconClose} onClick={onHide} />
                    </Styled.Modal.CloseContainer>
                    <Styled.Modal.Title>Salvar em Meus Favoritos</Styled.Modal.Title>
                    <Styled.Modal.Subtilte>Organize e categorize suas leituras e materiais</Styled.Modal.Subtilte>
                    <br />
                    <Styled.Modal.ContainerItem onClick={toCreate}>
                        <Styled.Modal.ImageItem src={newCollectionIcon} />
                        <Styled.Modal.LabelItem>Nova Coleção</Styled.Modal.LabelItem>
                    </Styled.Modal.ContainerItem>

                    {loading && (
                        <Styled.Modal.LoadingText>
                            <i>Buscando suas coleções...</i>
                        </Styled.Modal.LoadingText>
                    )}

                    {loading ? (
                        <Loading />
                    ) : (
                        <Styled.Modal.ScrollContainer>
                            {collections.map((collection, idx) => {
                                return (
                                    <Styled.Modal.ContainerItem key={collection.id} onClick={() => onSelect(collection.id)}>
                                        <Styled.Modal.ImageItem src={coverDefalut} />
                                        <Styled.Modal.LabelItem>{collection.title}</Styled.Modal.LabelItem>
                                    </Styled.Modal.ContainerItem>
                                );
                            })}
                        </Styled.Modal.ScrollContainer>
                    )}
                </Styled.Modal.Container>
            </Styled.Modal.Component>
        </>
    );
};

export default BiblioCollectionModal;
