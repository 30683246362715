import React, { useEffect, useState } from 'react';
import Styled from './AdminGestaoCertificados.styled';
import { YellowButton } from 'components/UI/Buttons';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import ModalCreateCertificated from './components/ModalCreateCertificated';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import Loading from 'components/Loading';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import CardCertificacoes from './components/CardCertificacoes';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { Empty, Typography } from 'antd';

interface IAdminGestaoCertificados {}

const AdminGestaoCertificados = ({}: IAdminGestaoCertificados) => {
    const [openModalCreateCertificated, setModalCreateCertificated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [listCertificacoes, setListCertificacoes] = useState<CertificacoesModel[]>([]);
    const [dadosEditCertificacao, setDadosEditCertificacao] = useState<CertificacoesModel | null>(null);

    const serviceCertificacoes = new CertificacoesService();

    const fetchAllCertificacoes = async () => {
        setLoading(true);
        const response = await serviceCertificacoes.listarAll();
        setListCertificacoes(response.data);

        setLoading(false);
    };

    const deletarCertificacao = async (id: number, idx: number) => {
        const response = await serviceCertificacoes.deleteCertificated(id);

        if (response.status === 204) {
            ToastSuccess('Certificado deletado com sucesso!');

            // Removendo o objeto do array
            let newArray = listCertificacoes.slice();
            newArray.splice(idx, 1);

            //atualizando a lista dos certificados sem o objeto que foi removido!
            setListCertificacoes(newArray);
        } else {
            ToastError('Ocorreu algum problema na comunicação com o servidor!');
        }
    };

    useEffect(() => {
        fetchAllCertificacoes();
    }, []);

    useEffect(() => {
        if (!openModalCreateCertificated) {
            setDadosEditCertificacao(null);
        }
    }, [openModalCreateCertificated]);

    return (
        <Styled.Container>
            <ModalCreateCertificated
                dados={dadosEditCertificacao}
                open={openModalCreateCertificated}
                handleHide={() => setModalCreateCertificated(false)}
                isReloading={fetchAllCertificacoes}
            />

            <Styled.RowBetween>
                <Styled.Title>Gestão de Certificados</Styled.Title>
                <YellowButton
                    onClick={() => setModalCreateCertificated(true)}
                    style={{
                        height: '40px',
                    }}
                >
                    Criar nova certificação
                </YellowButton>
            </Styled.RowBetween>
            <Divider
                style={{
                    margin: '10px',
                }}
            />

            {loading ? (
                <Loading />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '1rem',
                    }}
                >
                    {listCertificacoes.map((dados, index) => (
                        <CardCertificacoes
                            handleEdit={() => {
                                setDadosEditCertificacao(dados);
                                setModalCreateCertificated(true);
                            }}
                            handleDelete={() => deletarCertificacao(dados.codigo ?? -1, index)}
                            dados={dados}
                            key={index}
                        />
                    ))}

                    {listCertificacoes.length === 0 && (
                        <>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{ height: 120 }}
                                description={
                                    <Typography.Text>
                                        Ainda não possui nenhuma certificação criada.{' '}
                                        <a
                                            onClick={() => setModalCreateCertificated(true)}
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                            href="#"
                                        >
                                            Criar uma certificação.
                                        </a>
                                    </Typography.Text>
                                }
                            ></Empty>
                        </>
                    )}
                </div>
            )}
        </Styled.Container>
    );
};

export default AdminGestaoCertificados;
