import { BlueSolidButton, GradientButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const RecursosRatesStyled = {
    Container: styled.div`
        padding-top: 20px;
    `,

    Title: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: -0.02em;
        color: #003c78;
        padding-left: 20px;
    `,

    Header: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,

    RightContainer: styled.div`
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    `,

    SearchContainer: styled.div`
        width: 50%;
        height: 38px;
        max-width: 390px;
        display: flex;

        border: 1px solid #dadada;
        border-radius: 6px;

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-grow: 1;
        }
    `,

    SearchIconButton: styled.img`
        cursor: pointer;
        height: 60%;
    `,

    SearchInput: styled.input`
        flex-grow: 1;
        margin-right: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        color: grey;

        &::placeholder {
            color: grey;
            opacity: 0.6;
        }
    `,

    Filter: styled.div`
        border: 1px solid #dadada;
        border-radius: 6px;
        margin-left: 20px;
        height: 100%;
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
            background-color: #f8f8fc;
        }

        & > span {
            font-family: Barlow;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            text-align: right;

            color: #5f758b;
            margin-left: 7px;
        }
    `,

    Icon: styled.img``,

    Subtitle: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #263238;
        padding-left: 20px;
    `,

    //=======MODAL=========

    Modal: {
        Component: styled(Modal)`
            .modal-content {
                width: 520px;
            }
        `,

        Content: styled.div`
            padding: 30px;
            text-align: center;
            width: 100%;
        `,

        CloseContainer: styled.div`
            text-align: right;
            padding-top: 15px;
            padding-right: 15px;
        `,

        CloseIcon: styled.img`
            width: 25px;
            cursor: pointer;
            transition: 0.1s;

            :hover {
                transform: scale(1.05);
            }
        `,
        Title: styled.p`
            color: #00a8e9;
            font-size: 24px;
            line-height: 42px;
            font-weight: 600;
        `,
        Subtitle: styled.p`
            color: #5f758b;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
        `,

        Button: styled(BlueSolidButton)`
            margin-top: 25px;
        `,

        ImageStar: styled.img`
            margin: 0 2px;
            cursor: pointer;

            height: 42px;
            width: 42px;

            :hover {
                transform: scale(1.15);
            }
        `,

        FormControl: Form.Control,
    },
};

export default RecursosRatesStyled;
