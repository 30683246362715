import styled from 'styled-components';

const BarraPesquisaStyled = {
    BackdropShadow: styled.div`
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;

        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.25;

        transition: opacity 0.15s ease-out;

        display: flex;
        flex-direction: column;
    `,

    Container: styled.div`
        background: #003c78;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 20px 60px;

        display: flex;
        align-items: center;

        transition: opacity 0.25s ease-out;

        @media only screen and (max-width: 760px) {
            padding: 15px 7px;

            justify-content: center;
            justify-items: center;
        }

        .desktop-only {
            display: inline-block;

            @media only screen and (max-width: 760px) {
                display: none;
            }
        }

        .mobile-only {
            display: none;

            @media only screen and (max-width: 760px) {
                display: inline-block;
            }
        }
    `,

    Button: styled.button`
        background: #fcfcfc;
        border-radius: 72px;
        color: #003c78;

        width: fit-content;

        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        padding: 12px 24px;
        margin-right: 20px;
        border: none;
        outline: none;

        @media only screen and (max-width: 760px) {
            font-size: 0.8rem;
            padding: 10px 18px;
            margin-left: 7px;
            margin-right: 0;
        }
    `,

    Input: styled.input`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        border: none;
        outline: none;

        background: transparent;
        flex-grow: 1;
        margin: 0 20px;

        ::placeholder {
            color: #ffffff;
            opacity: 0.5;
        }

        @media only screen and (max-width: 760px) {
            width: fit-content;

            font-size: 18px;
            line-height: 25px;
            margin: 0;
            padding: 7px;

            ::placeholder {
                font-size: 16px;
            }
        }
    `,

    Icon: styled.span`
        color: white;
        font-size: 1.75rem;

        .clickable {
            cursor: pointer;
        }
    `,

    CloseArea: styled.div`
        width: 100%;
        flex-grow: 1;
        background-color: transparent;
    `,
};

export default BarraPesquisaStyled;
