import { GenericService } from '../Generic.service';

export class ComponentAbasService extends GenericService {
    readonly URI: string = '/v1/componentAbas';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public deleteById(idComponente: number) {
        return this.delete(idComponente);
    }
}
