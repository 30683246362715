import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSubModalConfirmated = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        padding: 20px;
        padding-top: 40px;
        height: 200px;
        border-radius: 10px;
        height: 535px;
    `,

    Icon: styled.img``,

    Title: styled.h2`
        font-size: 30px;
        text-align: center;
        font-weight: 700;
        line-height: 40px;
        color: ${Colors.Blue};
    `,
};

export default StyledSubModalConfirmated;
