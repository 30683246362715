import React from 'react';
import Styled from './BibliotecaHome.styled';
import logoBiblioteca from '../../../assets/imagem_05.svg';
import { ButtonHomeNotHoverV2 } from '../button/ButtonHomeV2.styled';
import { useHistory } from 'react-router-dom';

const BibliotecaHome = () => {

    const history = useHistory();

    return (
        <Styled.Container>
            <img src={logoBiblioteca} alt="Biblioteca de conteúdos" />
            <Styled.Description>Navegue em nosso acervo com guias temáticos, livros, vídeos e estudos, tudo organizado com uma curadoria especial e com download gratuito!</Styled.Description>
            <ButtonHomeNotHoverV2 onClick={() => history.push("/biblioteca")}>Ir para biblioteca</ButtonHomeNotHoverV2>
        </Styled.Container>
    );
};

export default BibliotecaHome;
