import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import {
    bucketS3,
    caminhoAnexosBibliotecaConteudosRelacionados,
    caminhoAnexosPaginasIntermediariasConteudosRelacionados,
    isDevelopment,
} from 'constants/EnvironmentData';
import {
    ArrowButton,
    ArrowButtons,
    Category,
    Container,
    ConteudosRelacionados,
    CoverContainer,
    Image,
    ItemContainer,
    Scroll,
    ScrollContainer,
    ScrollItem,
    SliderContainer,
    Subtitle,
    SvgIcon,
    Title,
} from './RelatedContent.styled';
import Loading from './Loading';
import arrowYellow from '../images/homepage/arrow-yellow.svg';
import { RelatedContentAnexoEnum } from './RelatedContentEnum';
import HoverCard from 'pages/home/components/educationalSolutions/cardComponent/HoverCard';

export interface IOtherContent {
    title: string;
    authors: string;
    category: string;
    filePath: string;
    categoria: RelatedContentAnexoEnum;
    onclick: () => void;
}

const CategoriaAnexo = (cat: RelatedContentAnexoEnum): string => {
    switch (cat) {
        case RelatedContentAnexoEnum.INTERMEDIARIA:
            return caminhoAnexosPaginasIntermediariasConteudosRelacionados();
        case RelatedContentAnexoEnum.BIBLIOTECA:
            return caminhoAnexosBibliotecaConteudosRelacionados();
        default:
            return '';
    }
};

const OtherContent = ({ title, authors, category, filePath, categoria, onclick }: IOtherContent) => {
    const getS3BaseUrl = (): string => {
        return bucketS3() + CategoriaAnexo(categoria);
    };
    return (
        <Container>
            <CoverContainer>
                <Image src={getS3BaseUrl() + filePath} onClick={onclick} />
            </CoverContainer>

            <Title onClick={onclick}>{title}</Title>
            <Subtitle>{authors}</Subtitle>
            <Category>{category}</Category>
        </Container>
    );
};

const NextArrow = ({ ...props }: any) => {
    return (
        <ArrowButton next={true} onClick={props.onClick}>
            <SvgIcon>
                <path
                    d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893Z"
                    fill="#00A8E9"
                />
            </SvgIcon>
        </ArrowButton>
    );
};

const PreviousArrow = ({ ...props }: any) => {
    return (
        <ArrowButton next={false} onClick={props.onClick}>
            <SvgIcon>
                <path
                    d="M6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292891C7.31658 -0.0976325 6.68342 -0.0976325 6.29289 0.292891L0.292894 6.29289C-0.0976308 6.68342 -0.0976309 7.31658 0.292894 7.7071L6.29289 13.7071Z"
                    fill="#00A8E9"
                />
            </SvgIcon>
        </ArrowButton>
    );
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
};

class RelatedContent {
    static ConteudosRelacionados(props: any, categoria: RelatedContentAnexoEnum, conteudosRelacionados: any[]) {
        const [showScroll, setShowSroll] = useState<boolean>(false);
        const [scrollLeft, setScrollLeft] = useState(0);

        const scrow = useRef<HTMLDivElement>(null);
        const scrollContainer = useRef<HTMLDivElement>(null);

        const scrollTo = (orientation: 'R' | 'L') => {
            const increaseScroll = 472 * 2;

            if (orientation == 'R' && scrow.current) scrow.current.scrollBy(increaseScroll, 0);
            else if (scrow.current) scrow.current.scrollBy(-1 * increaseScroll, 0);
        };

        useEffect(() => {
            if (scrollContainer.current && scrow.current) {
                const containerW = scrollContainer.current.clientWidth;
                const scrowW = scrow.current.scrollWidth;

                setShowSroll(containerW >= scrowW);
            }
        }, [scrollContainer.current, scrow.current]);

        useEffect(() => {
            if (scrow.current) {
                scrow.current.addEventListener('scroll', e => {
                    setScrollLeft(scrow.current?.scrollLeft ?? 0);
                });
            }

            return () => scrow.current?.removeEventListener('scroll', () => {});
        }, [scrow.current]);

        return (
            <ConteudosRelacionados>
                <ScrollContainer ref={scrollContainer}>
                    {showScroll && <ArrowButtons src={arrowYellow} onClick={() => scrollTo('L')} active={scrollLeft > 0} />}

                    <Scroll ref={scrow}>
                        {conteudosRelacionados.map((item, idx) => {
                            return (
                                <ScrollItem key={item.id}>
                                    <OtherContent
                                        categoria={categoria}
                                        key={idx}
                                        authors={item.authors}
                                        category={item.category}
                                        title={item.titulo}
                                        filePath={item.filePath}
                                        onclick={() => {
                                            RelatedContent.RedirectLinkAction(item.link);
                                        }}
                                    />
                                </ScrollItem>
                            );
                        })}
                    </Scroll>

                    {showScroll && (
                        <ArrowButtons
                            className="flip"
                            src={arrowYellow}
                            style={{ visibility: showScroll ? 'visible' : 'hidden' }}
                            onClick={() => scrollTo('R')}
                            active={
                                !(scrow.current && scrow.current?.scrollLeft >= scrow.current?.scrollWidth - scrow.current?.clientWidth) ?? true
                            }
                        />
                    )}
                </ScrollContainer>

                {/* {props.IsMobile() && !props.loadingPage && !props.loadingRelatedContent ? (
                    <SliderContainer>
                        <Slider {...settings}>
                            {conteudosRelacionados.map((item, idx) => {
                                return (
                                    <OtherContent
                                        categoria={categoria}
                                        key={idx}
                                        authors={''}
                                        category={''}
                                        title={item.titulo}
                                        filePath={item.filePath}
                                        onclick={() => {
                                            RelatedContent.RedirectLinkAction(item.link);
                                        }}
                                    />
                                );
                            })}
                        </Slider>
                    </SliderContainer>
                ) : null}

                {!props.IsMobile() && !props.loadingPage && !props.loadingRelatedContent ? (
                    <ItemContainer>
                        {conteudosRelacionados.map((item, idx) => {
                            return (
                                <OtherContent
                                    categoria={categoria}
                                    key={idx}
                                    authors={''}
                                    category={''}
                                    title={item.titulo}
                                    filePath={item.filePath}
                                    onclick={() => {
                                        RelatedContent.RedirectLinkAction(item.link);
                                    }}
                                />
                            );
                        })}
                    </ItemContainer>
                ) : null} */}

                {props.loadingPage || props.loadingRelatedContent ? <Loading /> : null}
            </ConteudosRelacionados>
        );
    }

    static RedirectLinkAction = (link: string) => {
        if (isDevelopment()) {
            if (!link.match(/^http?:\/\//i)) {
                link = 'http://' + link;
            }
        } else {
            if (!link.match(/^https?:\/\//i)) {
                link = 'https://' + link;
            }
        }

        window.location.assign(link);
    };
}

export default RelatedContent;
