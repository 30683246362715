import React, { useContext, useEffect, useState } from 'react';
import Styled from './RecursosRates.styled';

import closeIcon from '../../../../../images/biblioteca/modal-close.svg';
import useLogged from 'hooks/useLogged';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ToasterDefault, ToastError, ToastSuccess } from 'components/Toaster';
import RecursosRateStarsSelector from './RecursosRateStarsSelector';
import { toast } from 'react-hot-toast';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { AvaliacaoRecursosCustomizado } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';

const RATE_LASTSESSION_KEY = 'RATE_LASTSESSION_KEY';

export interface IRecursosRateModal {
    show: boolean;
    idConteudo?: number | null;
    prerate: number | null;
    onHide: () => void;
}

const RecursosRateModal = ({ idConteudo, prerate, show, onHide }: IRecursosRateModal) => {
    const service = new AdminRecursosCostumizados();
    const [loading, setLoading] = useState(false);
    const [rate, setRate] = useState(prerate ?? 0);

    const [commentInput, setCommentInput] = useState('');

    const { login } = useLogged();
    const { keycloak } = useKeycloak();

    const location = useLocation();

    const sendRate = async (_rate?: number) => {
        if (rate > 0 || (_rate && _rate > 0)) {
            if (keycloak.authenticated) {
                setLoading(true);

                const dados = AvaliacaoRecursosCustomizado.fromJSON({ codigoConteudo: idConteudo ?? 0, nota: rate, comentario: commentInput });

                const response = await service.avaliarConteudo(dados);
                if (response.status == 200) {
                    ToastSuccess('Avaliação enviada');
                    setRate(0)
                    setCommentInput('')
                    onHide();
                } else {
                    ToastError('Erro ao enviar avaliação');
                }
                setLoading(false);
            } else {
                login(location.pathname, `rate=${rate ?? _rate}`);
            }
        }
    };

    const checkLastSession = () => {
        const value = location.pathname.split('rate=')[1];

        if (value) {
            sendRate(parseInt(value));
            setRate(parseInt(value));
        }
    };

    useEffect(() => {
        checkLastSession();
    }, []);

    return (
        <>
            <Styled.Modal.Component centered show={show} onHide={onHide}>
                <Styled.Modal.CloseContainer>
                    <Styled.Modal.CloseIcon src={closeIcon} onClick={onHide} />
                </Styled.Modal.CloseContainer>
                <Styled.Modal.Content>
                    <Styled.Modal.Title>Avaliar</Styled.Modal.Title>
                    <Styled.Modal.Subtitle>Deixe sua avaliação sobre este conteúdo</Styled.Modal.Subtitle>
                    <RecursosRateStarsSelector onSelect={(value: number) => setRate(value)} prerate={rate} />
                    <br />
                    <Styled.Modal.FormControl
                        as="textarea"
                        rows={2}
                        placeholder="Insira seu comentário de avaliação aqui."
                        onChange={evt => {
                            const value = evt.target.value;
                            if (value.length >= 300) toast.error('Comentário muito longo');
                            else setCommentInput(value);
                        }}
                        maxLength={300}
                        value={commentInput}
                    />
                    <Styled.Modal.Button
                        onClick={() => {
                            sendRate();
                        }}
                        disabled={rate == 0 || loading}
                    >
                        {loading ? 'Enviando...' : 'Enviar'}
                    </Styled.Modal.Button>
                </Styled.Modal.Content>
            </Styled.Modal.Component>
        </>
    );
};

export default RecursosRateModal;
