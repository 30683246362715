import { MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

export const PainelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 40px 40px 100px;
    overflow: hidden;
    gap: 15vh;
    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        padding: 40px 0;
        align-items: center;
        gap: 5vh;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    max-width: 945px;
    min-height: 500px;
    margin: 0px 40px 40px 0px;
    flex-direction: column;
    gap: 50px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        height: 100%;
        max-width: 450px;
        margin: 0 40px 0;
        gap: 20px;
    }

    @media only screen and (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
`;

export const AuxContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const GobackButton = styled.button`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
    width: 227px;
    height: 42px;
    padding: 12px 40px;
    gap: 10px;
    border: 2px solid #003c78;
    border-radius: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background: ${Colors.White};
    color: ${Colors.Blue};

    &:hover {
        background: ${Colors.Blue};
        color: ${Colors.White};
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin: 0 auto;
    }
`;
