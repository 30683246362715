import React from 'react';
import styled from 'styled-components';

export const LoadingStyle = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '50px',
});

export const Text = styled.p({
    fontWeight: 600,
    fontSize: '1.5em',
    color: '#888',
});

const BibliotecaItemPageNotFound = () => {
    return (
        <LoadingStyle>
            <Text>Item não encontrado {':('} </Text>
        </LoadingStyle>
    );
};

export default BibliotecaItemPageNotFound;
