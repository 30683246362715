import React, { useState } from 'react';
import Select, { SelectOptionType } from 'components/Select';

import adminEditorSections from './adminEditorSections';
import Styled from './AdminPageEditor.styled';
import AdminEditCover from 'pages/admin/components/pageEditors/AdminEditCover';
import AdminEditSection from 'pages/admin/components/pageEditors/AdminEditSection';
import AdminEditTestemonial from 'pages/admin/components/pageEditors/AdminEditTestemonial';
import AdminEditEducationSolutions from 'pages/admin/components/pageEditors/AdminEditEducationSolutions';
import AdminEditModalSave from 'pages/admin/components/pageEditors/AdminEditModalSave';
import AdminEditIntermediateRelatedContent from 'pages/admin/components/pageEditors/AdminEditIntermediateRelatedContent';
import AdminEditFaq from 'pages/admin/components/pageEditors/AdminEditFaq';
import AdminEditTematica from 'pages/admin/components/pageEditors/AdminEditTematica';

export interface IAdminPageEditor {
    section: string;
    onChangeSection: (value: string) => void;
}

const AdminPageEditor = ({ section, onChangeSection }: IAdminPageEditor) => {
    const [showModalSaved, setShowModalSaved] = useState(false);

    const onSaved = () => setShowModalSaved(true);

    const switchContent = () => {
        if (section == adminEditorSections[0]) return <AdminEditCover onSaved={onSaved} />;
        else if (section == adminEditorSections[1]) return <AdminEditTematica onSaved={onSaved} />;
        else if (section == adminEditorSections[2]) return <AdminEditEducationSolutions onSaved={onSaved} />;
        else if (section == adminEditorSections[3]) return <AdminEditSection key={'BIBLIOTECA'} type="BIBLIOTECA" onSaved={onSaved} />;
        else if (section == adminEditorSections[4]) return <AdminEditSection key={'ESPACO_EDUCADOR'} type="ESPACO_EDUCADOR" onSaved={onSaved} />;
        else if (section == adminEditorSections[5]) return <AdminEditTestemonial onSaved={onSaved} />;
        else if (section == adminEditorSections[6]) return <AdminEditIntermediateRelatedContent onSaved={onSaved} />;
        else return <p>Vazio</p>;
    };

    const options: SelectOptionType[] = adminEditorSections.map(s => ({ value: s, label: s } as SelectOptionType));

    const onSelect = (option: SelectOptionType) => {
        onChangeSection(String(option.value));
    };

    return (
        <Styled.Container>
            <Styled.TopBar>
                <Styled.TextTop>Qual seção da home você deseja alterar?</Styled.TextTop>
                <Styled.Select isSmall={true} itens={options} onSelected={onSelect} defaultValue={options.find(o => o.value == section)} />
            </Styled.TopBar>

            <br />
            {switchContent()}
            <AdminEditModalSave
                show={showModalSaved}
                onHide={() => {
                    setShowModalSaved(false);
                }}
            />
        </Styled.Container>
    );
};

export default AdminPageEditor;
