import React from 'react';
import styled from 'styled-components';

export interface IModuloCPC {
    modulo: string;
    titulo: string;
    isSmall?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 220px;
    gap: 10px;

    @media only screen and (max-width: 1200px) {
        max-width: 200px;
        align-items: center;
        text-align: center;
    }
`;

const ModuloContainer = styled.div`
    padding: 4px 8px;
    gap: 10px;
    background: #ffca00;
    border-radius: 10px;
    max-width: 80px;

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #003c78;
`;

const Titulo = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;

    color: #003c78;
`;

const ModuloCPC = ({ modulo, titulo, isSmall = false }: IModuloCPC) => {
    return (
        <Container>
            <ModuloContainer>{modulo}</ModuloContainer>
            <Titulo style={{ fontSize: isSmall ? '22px' : '30px' }}>{titulo}</Titulo>
        </Container>
    );
};

export default ModuloCPC;
