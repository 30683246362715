enum TipoSolucoesEnum {
    CIRCUITO_360 = 1,
    GESTAO_NOTA_10 = 2,
    SE_LIGA = 3,
    ACELERA_BRASIL = 4,
    FORMULA_DA_VITORIA = 5,
    DIALOG_SOCIOEMOCIONAL = 6,
    REALIZANDO_ESCOLHAS = 7,
}

export default TipoSolucoesEnum;
