import React from 'react';

import styled from 'styled-components';

import FormLabel from '../../../formik/FormLabel';
import FormControl from '../../../formik/FormControl';
import ContinuarButton from '../../../UI/ContinuarButton';
import FormikTextInput from '../../../formik/FormikTextInput';
import FormikReactSelect from '../../../formik/FormikReactSelect';
import FormikCreatableInput from '../../../formik/FormikCreatableInput';

import Books_1 from '../../../../../../images/profile/books_1.svg';
import Books_2 from '../../../../../../images/profile/books_2.svg';
import continueIcon from '../../../../../../images/profile/continue.svg';
import LocationImg from '../../../../../../images/profile/location.svg';
import { ContinueButton, InfoText } from '../IdentificacaoForm.styled';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { isUsuarioLms } from 'core/util/KeycloakHelp';
import {
    CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL,
    CODIGO_TIPO_FORMACAO_ENSINO_MEDIO,
    CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL,
} from '../IdentificacaoForm';

const SubmitButtonContainer = styled.div`
    margin-top: 70px;
`;

const TooltipStyle = styled.div`
    position: relative;
    display: inline-block;

    &:hover {
        .tooltipdialog {
            visibility: visible;
        }
    }
    align-self: flex-end;
`;

const Tooltipdialog = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 277px;
    text-align: center;
    background: #f3f3f3;
    border: 1px solid #003c78;
    border-radius: 15px;
    white-space: pre-line;
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    z-index: 1;
    left: 105%;
`;

interface IWebForm {
    formik: any;
    ReloadContentHandler: any;
    hasProfileInfo: boolean;
    profileParameterData: any;
    loadCidadesPorUf: any;
    isLoading: boolean;
    cidadesPorUf: any;
    submitting: boolean;
}
const WebForm = ({
    formik,
    ReloadContentHandler: reloadContentHandler,
    hasProfileInfo,
    profileParameterData,
    loadCidadesPorUf,
    isLoading,
    cidadesPorUf,
    submitting,
}: IWebForm) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const Tooltip = () => {
        return (
            <TooltipStyle>
                <InfoText>{`Por que pedimos essa ${` `} 
                            informação?`}</InfoText>
                <Tooltipdialog className="tooltipdialog">
                    {`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.
                        ${` `}
                        Ele não será compartilhado com organizações externas.`}
                </Tooltipdialog>
            </TooltipStyle>
        );
    };

    const isTipoFormacaoSemComplemento = (value: number) => {
        return (
            value === CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL ||
            value === CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL ||
            value === CODIGO_TIPO_FORMACAO_ENSINO_MEDIO
        );
    };

    const labelEstadoForm: string = !(
        formik.values['tipoFormacao'] === CODIGO_TIPO_FORMACAO_SEM_ENSINO_FUNDAMENTAL ||
        formik.values['tipoFormacao'] === CODIGO_TIPO_FORMACAO_ENSINO_FUNDAMENTAL ||
        formik.values['tipoFormacao'] === CODIGO_TIPO_FORMACAO_ENSINO_MEDIO
    )
        ? '. Moro no estado de'
        : 'Moro no estado de';

    return (
        <>
            {/*Quando for usuário do lms solicita cpf */}
            {isUsuarioLms(usuarioLogado) ? (
                <>
                    <FormControl>
                        <FormLabel label="Meu CPF é " />
                        <FormikTextInput
                            validateCpf={true}
                            maxLength={14}
                            size={265}
                            name="cpf"
                            placeholder="Seu CPF"
                            onBlurHandler={() => {
                                reloadContentHandler(formik, 'cpf', 12, Books_2, 'Que bom ter você de volta!');
                            }}
                            formik={formik}
                            onChangeHandler={() => {}}
                        />

                        <Tooltip />
                    </FormControl>
                </>
            ) : (
                <></>
            )}

            {/* O Campo de apelido só é exibido nesse formato quando NÃO for usuário migrado do LMS */}
            {!isUsuarioLms(usuarioLogado) && (
                <>
                    <FormControl>
                        <FormLabel label="Prefiro ser chamado(a) por" />
                    </FormControl>
                    <FormControl>
                        <FormikTextInput
                            size={295}
                            name="apelido"
                            placeholder="Seu nome"
                            onBlurHandler={() => {
                                reloadContentHandler(formik, 'apelido', 15, Books_2, 'Qual sua formação?');
                            }}
                            formik={formik}
                            onChangeHandler={() => {}}
                        />
                        {!formik.touched.apelido && (
                            <ContinueButton
                                src={continueIcon}
                                onClick={() => {
                                    const el = document.querySelector('input');
                                    el?.blur();
                                }}
                            />
                        )}
                    </FormControl>
                </>
            )}
            {/* O Campo de apelido só é exibido nesse formato quando FOR USUÁRIO MIGRADO DO LMS */}
            {(formik.touched.cpf || hasProfileInfo) && isUsuarioLms(usuarioLogado) && (
                <>
                    <FormControl>
                        <FormLabel label="Prefiro ser chamado(a) por" />
                        <FormikTextInput
                            size={275}
                            name="apelido"
                            placeholder="Seu nome"
                            onBlurHandler={() => {
                                reloadContentHandler(formik, 'apelido', 15, Books_2, 'Qual sua formação?');
                            }}
                            formik={formik}
                            onChangeHandler={() => {}}
                        />
                    </FormControl>
                </>
            )}

            {(formik.touched.apelido || hasProfileInfo) && (
                <FormControl noFlex={true}>
                    <FormLabel label="Meu maior nível de escolaridade concluído é " />
                    <FormikReactSelect
                        size={420}
                        name="tipoFormacao"
                        options={profileParameterData.listaTipoFormacao}
                        formik={formik}
                        onChangeHandler={async (value: number) => {
                            if (isTipoFormacaoSemComplemento(value)) {
                                await formik.setFieldValue('areaFormacao', 0);
                                reloadContentHandler(formik, 'tipoFormacao', 20, LocationImg, 'Onde você reside?');
                            } else {
                                reloadContentHandler(formik, 'tipoFormacao', 20, Books_1, 'Qual sua formação?');
                            }
                        }}
                    />
                </FormControl>
            )}

            {(formik.touched.tipoFormacao || hasProfileInfo) && (
                <FormControl>
                    {((formik.touched.tipoFormacao && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao'])) ||
                        (hasProfileInfo && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao']))) && <FormLabel label=" em" />}

                    {((formik.touched.tipoFormacao && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao'])) ||
                        (hasProfileInfo && !isTipoFormacaoSemComplemento(formik.values['tipoFormacao']))) && (
                        <FormikCreatableInput
                            name="areaFormacao"
                            otherField="outraAreaFormacao"
                            size={240}
                            options={profileParameterData.listaAreasFormacao}
                            formik={formik}
                            onChangeHandler={async (value: any) => {
                                reloadContentHandler(formik, 'areaFormacao', 25, LocationImg, 'Onde você reside?');
                            }}
                        />
                    )}

                    {(formik.touched.areaFormacao ||
                        (formik.touched.tipoFormacao && isTipoFormacaoSemComplemento(formik.values['tipoFormacao'])) ||
                        hasProfileInfo) && (
                        <FormControl>
                            <FormLabel label={labelEstadoForm} />
                            <FormikReactSelect
                                placeholder="UF"
                                size={80}
                                filter={true}
                                name="estado"
                                options={profileParameterData.listaEstados}
                                formik={formik}
                                onChangeHandler={async (value: string) => {
                                    await loadCidadesPorUf(value);
                                    if (!(value === formik.values['estado'])) {
                                        await formik.setFieldValue('municipio', null);
                                    }
                                    reloadContentHandler(formik, 'estado', 30, LocationImg, 'Onde você reside?');
                                }}
                            />
                        </FormControl>
                    )}
                </FormControl>
            )}

            {((formik.touched.estado && !isLoading) || (!formik.touched.estado && hasProfileInfo && formik.values['municipio'])) && (
                <>
                    <FormControl>
                        <FormLabel label="no município de " />
                        <FormikReactSelect
                            size={300}
                            filter={true}
                            name="municipio"
                            options={cidadesPorUf}
                            formik={formik}
                            onChangeHandler={() => {
                                reloadContentHandler(formik, 'municipio', 35, LocationImg);
                            }}
                        />

                        <FormLabel label=" no Brasil." />
                    </FormControl>

                    <SubmitButtonContainer>
                        <ContinuarButton
                            isDisabled={
                                (hasProfileInfo && !formik.isValid) ||
                                (!hasProfileInfo && !(formik.isValid && formik.dirty)) ||
                                formik.isSubmitting ||
                                submitting
                            }
                            label={`Continuar`}
                            onClickHandler={formik.handleSubmit}
                        />
                    </SubmitButtonContainer>
                </>
            )}
        </>
    );
};

export default WebForm;
