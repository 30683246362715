import { GradientButton } from 'components/UI/Buttons';
import { MobileBiggerWidth, MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const AdminHomeStyled = {
    Container: styled.div`
        background-color: white;
        padding: 20px 115px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 20px;
        }
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 26px;
        line-height: 42px;
        color: #183f73;
    `,

    Subtitle: styled.p`
        font-size: 18px;
        line-height: 20px;
        color: #2e2e2e;
        margin: 10px 0;
    `,

    Row: styled.div`
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: ${MobileBiggerWidth}px) {
            overflow-x: auto;
            flex-direction: column;
            align-items: center;
        }
    `,

    TileContainer: styled.div<{ contract?: boolean }>`
        box-shadow: 0px 4px 80px -20px rgba(0, 60, 120, 0.15);
        border-radius: 5px;
        width: 255px;
        height: 300px;
        margin: 20px;
        margin-left: 0;
        overflow: hidden;

        position: relative;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
            min-width: ${props => (props.contract ? '195px' : '155px')};
            width: ${props => (props.contract ? '195px' : '155px')};
            transition: 0.3s ease-in-out;
        }
    `,

    ImageBackground: styled.img`
        object-fit: cover;
        height: 100%;
        width: 100%;
    `,

    GradientOpacity: styled.div`
        background: linear-gradient(104.02deg, #2e2e2e 7.66%, rgba(46, 46, 46, 0) 92.34%);
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
    `,

    ContentCointener: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    `,

    TileTitle: styled.p`
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        text-align: center;
        width: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 15px;
        }
    `,

    GradientButtonz: styled(GradientButton)`
        border: none;
        width: 120px;
        height: 30px;
        font-size: 14px;
    `,
};

export default AdminHomeStyled;
