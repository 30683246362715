import { ResizeMediumWidth } from 'constants/Dimensions';
import styled from 'styled-components';

export const PainelContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 50%;
    height: 270px;

    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 18px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        width: 100%;
    }

    @media only screen and (max-width: 480px) {
        height: 240px;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 15px;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80px;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const RedirectButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    height: 27px;
    background: #003c78;
    border: none;
    border-radius: 6px;

    &:hover {
        border: 1px solid #003c78;
        background: #ffffff;
    }
`;

export const Text = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #fcfcfc;

    &:hover {
        color: #003c78;
    }
`;

export const Title = styled.p`
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(14px, 4.5vw, 34px);
    line-height: 97%;

    text-transform: uppercase;
    color: #ffca00;
`;
