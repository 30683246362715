import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { AppStorage } from 'storage/AppStorage';
import { baseUrl } from 'constants/EnvironmentData';

export class HttpHandler {
    protected axios: AxiosInstance;
    private static instance: HttpHandler;

    private constructor() {
        this.axios = axios.create({
            baseURL: baseUrl(),
            validateStatus: (status: number) => status < 500,
        });
        this.setInterceptor();
    }

    public static getInstance() {
        if (!HttpHandler.instance) {
            HttpHandler.instance = new HttpHandler();
        }

        return HttpHandler.instance;
    }

    public getAxios() {
        return this.axios;
    }

    private setInterceptor = (): void => {
        this.axios.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                const accessToken: String | undefined = await AppStorage.getAccessToken();
                //const accessToken = AppStorage.getKeycloakAccessToken();

                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${accessToken}`,
                    };
                }
                return config;
            },
            (err: AxiosError) => {
                console.error('file: HttpHandler.ts ~ line 33 ~ HttpHandler ~ err', err);
                return err;
            }
        );
    };
}
