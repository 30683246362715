import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

import Styled from './BiblioShareModal.styled';

import shareIcon from '../../../../images/biblioteca/share.svg';
import fbIcon from '../../../../images/biblioteca/medias/feice.svg';
import mailIcon from '../../../../images/biblioteca/medias/imei.svg';
import inIcon from '../../../../images/biblioteca/medias/jinkidjin.svg';
import whtasappIcon from '../../../../images/biblioteca/medias/zapzap.svg';

import closeIcon from '../../../../images/biblioteca/modal-close.svg';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';

export interface IBiblioShareModal {
    item_id: number;
    show: boolean;
    width?: string;
    onHide: () => void;
    onShow: () => void;
}

const BiblioShareModal = ({ item_id, show, width, onHide, onShow }: IBiblioShareModal) => {
    const url = window.location.href;
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        GTM_Page_Biblioteca(`${item_id}`, `share_item_${item_id}`, 'clipboard_btn');
        copy(url);
        setCopied(true);
    };

    useEffect(() => {
        if (!show) setCopied(false);
    }, [show]);

    const shareWhatsApp = () => {
        GTM_Page_Biblioteca(`${item_id}`, `share_item_${item_id}`, 'whatsapp_btn');
        const _url = 'https://api.whatsapp.com/send?text=' + url;
        window.open(_url, '_blank');
    };

    const shareFacebook = () => {
        GTM_Page_Biblioteca(`${item_id}`, `share_item_${item_id}`, 'facebook_btn');
        const _url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
        window.open(_url, '_blank');
    };

    const shareLinkedin = () => {
        GTM_Page_Biblioteca(`${item_id}`, `share_item_${item_id}`, 'linkedin_btn');
        const _url = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=TITULO`;
        window.open(_url, '_blank');
    };

    const shareEmail = () => {
        GTM_Page_Biblioteca(`${item_id}`, `share_item_${item_id}`, 'email_btn');
        const _url = `mailto:?body=${url}`;
        window.open(_url, '_blank');
    };

    return (
        <>
            <Styled.ActionButton width={width} src={shareIcon} onClick={onShow} />

            <Styled.Modal size={'lg'} show={show} centered onHide={onHide} dialogClassName="dialogWidth">
                <Styled.Container>
                    <Styled.CloseContainer>
                        <Styled.CloseIcon src={closeIcon} onClick={onHide} />
                    </Styled.CloseContainer>
                    <Styled.Title>Compartilhar</Styled.Title>
                    <Styled.Subtitle>Compartilhe suas leituras e materiais com seus colegas.</Styled.Subtitle>

                    <Styled.Br />
                    <Styled.Label>Copiar link da publicação</Styled.Label>
                    <Styled.OutputContainer>
                        <Styled.Output>{url}</Styled.Output>
                        <Styled.CopyButton onClick={copyToClipboard}>{copied ? 'Copiado' : 'Copiar'}</Styled.CopyButton>
                    </Styled.OutputContainer>
                    <Styled.Br />
                    <Styled.Label>Enviar</Styled.Label>
                    <Styled.ButtonContainer>
                        <Styled.ButtonMedia color={'#32BA46'} onClick={shareWhatsApp}>
                            <Styled.Icon src={whtasappIcon} />
                        </Styled.ButtonMedia>
                        <Styled.ButtonMedia color={'#3D5A96'} onClick={shareFacebook}>
                            <Styled.Icon src={fbIcon} style={{ width: '12px' }} />
                        </Styled.ButtonMedia>
                        <Styled.ButtonMedia color={'#007AB7'} onClick={shareLinkedin}>
                            <Styled.Icon src={inIcon} />
                        </Styled.ButtonMedia>
                        <Styled.ButtonMedia color={'#2F75EC'} onClick={shareEmail}>
                            <Styled.Icon src={mailIcon} />
                        </Styled.ButtonMedia>
                    </Styled.ButtonContainer>
                </Styled.Container>
            </Styled.Modal>
        </>
    );
};

export default BiblioShareModal;
