import { GenericService } from '../Generic.service';

export class ComponentItemCorrespondentService extends GenericService {
    readonly URI: string = '/v1/componentItemCorrespondent';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public deleteById(idItem: number) {
        return this.delete(idItem);
    }
}
