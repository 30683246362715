import React, { useEffect, useState } from 'react';
import Styled from './BiblioCollectionPage.styled';
import iconClose from '../../../../images/close-circle.svg';
import { GradientButton } from 'components/UI/Buttons';

export interface IBiblioCollectionNewModal {
    show: boolean;
    onHide: () => void;
    onCreate: (value: string) => void;
}

const BiblioCollectionNewModal = ({ show, onHide, onCreate }: IBiblioCollectionNewModal) => {
    const [newName, setNewName] = useState<string>('');
    const MAX_CHARS = 50;

    useEffect(() => {
        if (newName.length > MAX_CHARS) setNewName(newName.substring(0, MAX_CHARS));
    }, [newName]);

    const hide = () => {
        setNewName('');
        onHide();
    };

    return (
        <Styled.Modal.Component centered show={show} onHide={hide}>
            <Styled.Modal.Container>
                <Styled.Modal.CloseContainer>
                    <Styled.Modal.IconClose src={iconClose} onClick={hide} />
                </Styled.Modal.CloseContainer>

                <Styled.Modal.TitleCyan>Nova Coleção</Styled.Modal.TitleCyan>
                <Styled.Modal.CharCounter style={{ color: ' #2E2E2E' }}>
                    Organize e categorize suas leituras e materiais.
                </Styled.Modal.CharCounter>
                <br />
                <Styled.Modal.InputEdit value={newName} onChange={evt => setNewName(evt.target.value)} />
                <Styled.RightContainer>
                    <Styled.CountChar>{newName.length}/50</Styled.CountChar>
                </Styled.RightContainer>
                <Styled.Modal.CharCounter>Máximo 50 caracteres</Styled.Modal.CharCounter>

                <br />
                <br />
                <br />
                <Styled.Modal.ContainerBottom>
                    <GradientButton
                        onClick={() => {
                            const name = newName;
                            setNewName('');
                            onCreate(name);
                        }}
                    >
                        Criar
                    </GradientButton>
                </Styled.Modal.ContainerBottom>
            </Styled.Modal.Container>
        </Styled.Modal.Component>
    );
};

export default BiblioCollectionNewModal;
