import Loading from 'components/Loading';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import { capitalize } from 'lodash';
import BiblioItem from 'models/biblioteca/BiblioItem';
import BiblioItemCategoria from 'models/biblioteca/BiblioItemCategoriaModel';
import AdminBiblioNewArtigo from 'pages/admin/components/newContent/AdminBiblioNewArtigo';
import AdminBiblioNewAtividade from 'pages/admin/components/newContent/AdminBiblioNewAtividade';
import AdminBiblioNewAudio from 'pages/admin/components/newContent/AdminBiblioNewAudio';
import AdminBiblioNewLivro from 'pages/admin/components/newContent/AdminBiblioNewLivro';
import AdminBiblioNewModalConfirm from 'pages/admin/components/newContent/AdminBiblioNewModalConfirm';
import AdminBiblioNewVideo from 'pages/admin/components/newContent/AdminBiblioNewVideo';
import React, { useCallback, useEffect, useState } from 'react';

import Styled from './AdminBiblioNewContent.styled';

export interface IAdminBiblioNewContent {}

export interface IAdminBiblioEditorContent {
    onSaved: () => void;
    itemData?: BiblioItem | null;
    complemento?: string;
    categoriaId: number;
    categorias: BiblioItemCategoria[];
}

const AdminBiblioNewContent = ({}: IAdminBiblioNewContent) => {
    const service = new BiblioConteudoService();
    const [showModal, setShowModal] = useState(false);

    const [categorias, setCategorias] = useState<BiblioItemCategoria[]>([]);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<BiblioItemCategoria | null>(null);
    const [loading, setLoading] = useState(false);

    const onSaved = () => setShowModal(true);

    const fetchCategorias = async () => {
        setLoading(true);
        const { data } = await service.buscarCategorias(true);
        const _categorias = Array.from(data).map((c: any) => new BiblioItemCategoria().fromJSON(c));
        setCategorias(_categorias.filter(c => c.ativo).sort((c1, c2) => c1.ordem - c2.ordem));
        setLoading(false);
    };

    const switchContent = useCallback(() => {
        if (categoriaSelecionada?.comportamento == 'artigo')
            return <AdminBiblioNewArtigo onSaved={onSaved} categoriaId={categoriaSelecionada.id} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'atividade')
            return <AdminBiblioNewAtividade onSaved={onSaved} categoriaId={categoriaSelecionada.id} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'audio')
            return <AdminBiblioNewAudio onSaved={onSaved} categoriaId={categoriaSelecionada.id} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'livro')
            return <AdminBiblioNewLivro onSaved={onSaved} categoriaId={categoriaSelecionada.id} categorias={categorias} />;
        if (categoriaSelecionada?.comportamento == 'video')
            return <AdminBiblioNewVideo onSaved={onSaved} categoriaId={categoriaSelecionada.id} categorias={categorias} />;
    }, [categoriaSelecionada]);

    const selectCategoria = (value: string) => {
        const categoria = categorias.find(c => c.descricao == value);

        if (categoria) {
            setCategoriaSelecionada(categoria);
        }
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    return (
        <Styled.Container>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Styled.TopBar>
                        <Styled.TextTop>Qual formato de conteúdo deseja adicionar?</Styled.TextTop>
                        {categorias.length > 0 && (
                            <Styled.Select
                                isSmall={true}
                                itens={categorias.map(c => ({ value: c.descricao, label: capitalize(c.descricao) }))}
                                onSelected={o => selectCategoria(String(o.value))}
                            />
                        )}
                    </Styled.TopBar>
                    <br />
                    {categoriaSelecionada && switchContent()}
                </>
            )}

            <AdminBiblioNewModalConfirm show={showModal} onHide={() => setShowModal(false)} />
        </Styled.Container>
    );
};

export default AdminBiblioNewContent;
