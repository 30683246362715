import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const BiblioResultGridStyled = {
    Container: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 55px;
        grid-row-gap: 55px;
        padding-top: 15px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            grid-template-columns: 1fr;
            padding: 10px 15px;
        }
    `,

    ShowMoreContainer: styled.div`
        width: 100%;
        text-align: center;
        padding: 10px;
        padding-top: 50px;

        button {
            width: 200px;
        }
    `,

    Title: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        color: #003c78;
    `,

    Text: styled.p`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #555;
        margin-top: 20px;
    `,
};

export default BiblioResultGridStyled;
