import React, { useState } from 'react';

import styled, { keyframes } from 'styled-components';

import Title from './Title';
import Colors from '../../enums/Colors';

import iconPlus from '../../images/plus.svg';
import iconLess from '../../images/less.svg';

export interface ICollapse {
    title: string;
    content: any;
    toggled?: () => void;
}

const Container = styled.div`
    border: 1px solid ${Colors.Grey5};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 20px;
`;

const Content = styled.div`
    overflow-y: hidden;
    height: fit-content;
    width: 100%;
    transition: 1s;
    line-height: 18px;
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;
`;

const rotate = () => keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180);
  }
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;

    animation: ${rotate} 0.5s ease-out;
`;

const Expandable = ({ title, content, toggled }: ICollapse) => {
    const [expand, setExpand] = useState<boolean>(false);

    const toggle = () => {
        setExpand(!expand);

        if (toggled) toggled();
    };

    return (
        <Container>
            <Head onClick={toggle}>
                <Title size="small" style={{ color: Colors.BlueGrey, lineHeight: '20px' }}>
                    {title}
                </Title>

                {!expand ? <Icon src={iconPlus} /> : <Icon src={iconLess} />}
            </Head>

            <Content style={{ height: !expand ? '0' : 'fit-content' }}>{content}</Content>
        </Container>
    );
};

export default Expandable;
