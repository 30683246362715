import { useKeycloak } from '@react-keycloak/web';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import { PrimaryButton } from 'components/UI/Buttons';
import { ProfileService } from 'core/http/service/Profile.service';
import { getUserRoleForProfile, isAutoCadastrado, isUsuarioLms } from 'core/util/KeycloakHelp';
import { MaskDate } from 'core/util/MaskHelper';
import userOccupations, { UserOccupation } from 'data/userOccupations';
import { RoleEnum } from 'enums/RoleEnum';
import { Formik, FormikProps } from 'formik';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { cloneDeep } from 'lodash';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';
import ProfileData from 'models/ProfileData';
import UsuarioDTO from 'models/UsuarioDTO';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as authenticationActions from '../../../../../store/actions/Authentication';
import * as profileActions from '../../../../../store/actions/Profile';
import Styled from '../InformacoesPessoais.styled';
import { cpf } from 'cpf-cnpj-validator';
import { Modal } from 'react-bootstrap';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import TipoDeficiencia from 'models/perfil/TipoDeficiencia';
import { DeficienciasService } from 'core/http/service/Deficiencias.service';
import TipoDeficienciaDTO from 'models/perfil/TipoDeficienciaDTO';
import { Input, Select } from 'antd';
import { formatCPF } from 'pages/gestaoCertificados/subpages/AdminGestaoCertificadosUsuarios';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';

import etapasEnsino from '../../../../../data/etapasEnsino.json';

import PerfilTipoDeficienciaDTO from 'models/perfil/PerfilTipoDeficienciaDTO';
import { PerfilDeficienciaService } from 'core/http/service/PerfilDeficiencia.service';
import { KeycloakService } from 'core/http/service/Keycloak.service';
export interface IInformacoesPessoaisForm1 {}

const InformacoesPessoaisForm1 = ({}: IInformacoesPessoaisForm1) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const profileService = new ProfileService();
    const deficienciasService = new DeficienciasService();
    const [submitting, setSubmitting] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [tiposDeficiencias, setTiposDeficiencias] = useState<TipoDeficiencia[]>([]);
    const [selectedDeficiencias, setSelectedDeficiencias] = useState<TipoDeficienciaDTO[]>([]);
    const [selectedPossuiDeficiencia, setSelectedPossuiDeficiencia] = useState<number>(-1);
    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profileParameterData: ParametrosCadastroPerfilDTO = useAppSelector(state => state.profileReducer.parametrosCadastro);
    const [usOc, setUsOc] = useState<UserOccupation[]>(userOccupations);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [isDeficiente, setIsDeficiente] = useState<boolean>(false);
    const [isLoadingCities, setIsLoadingCities] = useState(false);
    const [cidades, setCidades] = useState<LocalidadeDTO[]>([]);
 

    const perfilDeficienciaService = new PerfilDeficienciaService();
    const service = new KeycloakService();

    const isMobile = IsCustomMaxWidth(600);

    const [otherDeficiencia, setOtherDeficiencia] = useState<boolean>(false);

    const listIsDeficiencia = [
        { id: 1, nome: 'Sim', value: false },
        { id: 2, nome: 'Não', value: false },
    ];

    const userRoles = (): RoleEnum[] => {
        return getUserRoleForProfile(usuarioLogado.roles);
    };

    const isUserPreRegistered = (): boolean => {
        return userRoles().length > 0;
    };

    const roleDescription = (): string => {
        return usOc.find(o => o.role == userRoles()[0])?.descricao ?? '';
    };

    const handleCheckboxChange = (deficiencia: TipoDeficiencia, isChecked: boolean) => {
        const tipoDeficienciaInstance = new TipoDeficienciaDTO();
        tipoDeficienciaInstance.codigoTipoDeficiencia = deficiencia.id;

        if (isChecked) {
            setSelectedDeficiencias(
                prevSelected =>
                    prevSelected.filter(
                        selected => selected.codigoTipoDeficiencia !== tipoDeficienciaInstance.codigoTipoDeficiencia
                    ) as TipoDeficienciaDTO[]
            );
        } else {
            setSelectedDeficiencias(prevSelected => [...prevSelected, tipoDeficienciaInstance]);
        }

        if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && !isChecked) {
            setOtherDeficiencia(true);
        } else if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && isChecked) {
            setOtherDeficiencia(false);
        }
    };

    const [otherOccupation, setOtherOccupation] = useState<boolean>(false);

    const initialErrors = {
        erroCpf: '',
        erroComoConheceu: '',
        erroDataNascimento: '',
        erroGenero: '',
        erroCor: '',
        erroTipoFormacao: '',
        erroOcupacao: '',
        erroOcupacaoOutra: '',
        erroSeledtedDeficiencia: '',
        erroDeficiencia: '',
        erroDeficienciaOutra: '',
        erroEstado: '',
        erroTipoRede: '',
        erroMunicipio: '',
        erroEtapasEnsino: '',
    };

    const [erros, setErros] = useState(initialErrors);

    const OTHER_OCCUPATION_ID = 99;

    interface IValues {
        //Form 1
        cpf: string;
        comoConheceu: number;
        dataNascimento: string;
        genero: number;
        cor: number;
        tipoFormacao: number;
        ocupacao: string;
        ocupacaoOutra: string;
        selectedDeficiencia: number;
        deficiencia: TipoDeficienciaDTO[];
        deficienciaOutra: string;
        tipoRede: number;
        estado: string;
        municipio: number;
        etapasEnsino: number[];
    }

    let formikInitial: IValues = {
        cpf: profileData?.cpf ?? '',
        comoConheceu: profileData?.comoConheceu ?? 0,
        dataNascimento: profileData?.dataNascimento ?? '',
        genero: profileData?.genero ?? 0,
        cor: profileData?.cor ?? 0,
        tipoFormacao: profileData?.tipoFormacao ?? 0,
        ocupacao: roleDescription() ?? profileData?.ocupacao ?? '',
        ocupacaoOutra: profileData?.outraOcupacao ?? '',
        deficiencia: profileData?.tipoDeficiencia ?? [],
        selectedDeficiencia: selectedPossuiDeficiencia ?? -1,
        deficienciaOutra: profileData?.tipoDeficiencia.find(f => f.codigoTipoDeficiencia === 7)?.descricao || '',
        tipoRede: profileData?.tipoEnsino ?? 0,
        estado: profileData?.estado ?? '',
        municipio: profileData?.municipio ?? -1,
        etapasEnsino: profileData?.etapasEnsino ?? [],
    };

    const handleDescription = (value: string) => {
        const novaLista: TipoDeficienciaDTO[] = selectedDeficiencias.map((obj: TipoDeficienciaDTO) => {
            if (obj.codigoTipoDeficiencia === 7) {
                return { ...obj, descricao: value };
            }
            return obj;
        }) as TipoDeficienciaDTO[];

        setSelectedDeficiencias(novaLista);
    };

    const saveProfile = async (data: ProfileData) => {
        try {
            const response = await profileService.saveProfile(data);

            if (response.status === 201) {
                const codigoPerfil = response.data;

                if (selectedDeficiencias.length > 0) {
                    const dados = new PerfilTipoDeficienciaDTO();
                    dados.codigoPerfil = codigoPerfil;
                    dados.deficiencias = selectedDeficiencias;

                    await perfilDeficienciaService.insert(dados);
                }
            }
        } catch (error) {
            console.log('saveProfile', error);
            throw error;
        }
    };

  
    const onSubmitHandler = async (e: any, formik: FormikProps<IValues>): Promise<any> => {
        e.preventDefault();
        const values = formik.values;

        if (!isValid(formik)) {
            return updateErrors(values);
        }

        setSubmitting(true);
        const cloneData = profileData == null ? new ProfileData() : cloneDeep(profileData);

        cloneData.comoConheceu = values.comoConheceu;
        cloneData.dataNascimento = values.dataNascimento;
        cloneData.genero = values.genero;
        cloneData.cor = values.cor;
        cloneData.tipoFormacao = values.tipoFormacao;
        cloneData.outraOcupacao = values.ocupacaoOutra;
        cloneData.username = usuarioLogado?.username;
        cloneData.tipoDeficiencia = selectedDeficiencias;
        cloneData.cpf = isUsuarioLms(usuarioLogado) ? values.cpf : usuarioLogado?.username;
        cloneData.tipoEnsino = values.tipoRede;
        cloneData.estado = values.estado;
        cloneData.municipio = values.municipio;
        cloneData.etapasEnsino = values.etapasEnsino;
        cloneData.estadoDescricao = profileParameterData.listaEstados.find(e => e.id == cloneData.estado)?.nome ?? '';
        cloneData.municipioDescricao = cidades.find(c => c.id == cloneData.municipio)?.nome ?? '';
        cloneData.email = usuarioLogado.email ?? '';
        cloneData.keycloakId = usuarioLogado?.id ?? keycloak.subject;
        cloneData.autoCadastrado = isAutoCadastrado(usuarioLogado);
        cloneData.catedraOrIea = 0;

        let cpfJaCadastrado: boolean = false;

        let occupation = usOc.find(o => o.id == parseInt(values.ocupacao));

        if (isUserPreRegistered()) occupation = usOc.find(o => o.role == userRoles()[0]);

        cloneData.ocupacao = occupation?.descricao ?? values.ocupacao;

        if (!cpf.isValid(cloneData.cpf)) {
            cloneData.cpf = '';
        } else {
            const response: any = await profileService.existePorCpf(cloneData.cpf);
            cpfJaCadastrado = response.data;
        }

        if (occupation?.id == OTHER_OCCUPATION_ID) {
            cloneData.tipoPerfil = RoleEnum.Outro;

            cloneData.ocupacao = values.ocupacaoOutra;
        } else {
            cloneData.tipoPerfil = occupation?.role ?? RoleEnum.Professor;
        }

        if (!cpfJaCadastrado) {
            await saveProfile(cloneData);
            await dispatch(authenticationActions.getUsuarioLogado());
            await dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));

            keycloak.updateToken(-1).then(async refreshed => {
                //forcing token refresh after saving the profile to update the realm access roles
                console.log('TOKEN REFRESHED? ', refreshed);
                let usuario: UsuarioDTO | null = null;
                const response: any = await service.getUsuarioLogado();
                if (response.data) {
                    usuario = new UsuarioDTO().fromJSON(response.data);
                    await dispatch(authenticationActions.setUsuarioLogado(usuario));
                }

                //After token refresh, get the loggedin user again, now with the role
                history.push('/validar');

              
            });
        } else setShowErrorModal(true);
    };

    const fetchDeficiencias = async () => {
        const { data } = await deficienciasService.listarAll();

        if (data) setTiposDeficiencias(data);
    };

    const isValid = (formik: FormikProps<IValues>) => {
        const values = formik.values;

        if (!validDate(values.dataNascimento)) return false;

        if (isUsuarioLms(usuarioLogado) && !cpf.isValid(values.cpf)) return false;

        const deficienciaOutra = selectedDeficiencias.find(selected => selected.codigoTipoDeficiencia === 7);

        if (values.comoConheceu == 0) return false;
        if (values.genero == 0) return false;
        if (values.cor == 0) return false;
        if (values.tipoFormacao == 0) return false;
        if (values.ocupacao == '') return false;
        if (values.selectedDeficiencia == -1) return false;
        if (values.selectedDeficiencia === 1 && selectedDeficiencias.length === 0) return false;
        if (values.selectedDeficiencia === 1 && deficienciaOutra && values.deficienciaOutra.length < 5) return false;

        const occupation = usOc.find(o => o.id === parseInt(values.ocupacao.toString()));
        if (occupation?.id == OTHER_OCCUPATION_ID && (values.ocupacaoOutra == '' || values.ocupacaoOutra.length < 4)) return false;

        if (!validOutraOcupacao(values.ocupacaoOutra)) return false;

        return true;
    };

    const validDate = (stringDate: string): boolean => {
        const re = /\d{2}\/\d{2}\/\d{4}/g;
        const [d, m, y] = stringDate.split('/');
        const date = moment(`${y}-${m}-${d}`);

        if (!re.test(stringDate) || !date.isValid() || date.diff(new Date(), 'day') >= 0) return false;

        return true;
    };

    const validOutraOcupacao = (stringText: string): boolean => {
        if (stringText.length <= 3) return true;

        const re = /^[A-Z\s]+$/i;
        return re.test(stringText);
    };

    const updateErrors = (values: IValues) => {
        const _errors = {
            erroCpf: isUsuarioLms(usuarioLogado) && !cpf.isValid(values.cpf) ? 'CPF Inválido' : '',
            erroComoConheceu: values.comoConheceu == 0 ? 'Campo não preenchido' : '',
            erroDataNascimento: !validDate(values.dataNascimento) ? 'Data de nascimento inválida' : '',
            erroGenero: values.genero == 0 ? 'Campo não preenchido' : '',
            erroCor: values.cor == 0 ? 'Campo não preenchido' : '',
            erroTipoFormacao: values.tipoFormacao == 0 ? 'Campo não preenchido' : '',
            erroOcupacao: values.ocupacao == '' ? 'Campo não preenchido' : '',
            erroSeledtedDeficiencia: values.selectedDeficiencia == -1 ? 'Campo não preenchido' : '',
            erroDeficiencia: values.deficiencia.length == 0 && values.selectedDeficiencia === 1 ? 'Campo não preenchido' : '',
            erroOcupacaoOutra: '',
            erroDeficienciaOutra: '',
            erroEstado: values.estado == '' ? 'Campo não preenchido' : '',
            erroTipoRede: values.tipoRede == 0 ? 'Campo não preenchido' : '',
            erroMunicipio: values.municipio == -1 ? 'Campo não preenchido' : '',
            erroEtapasEnsino: values.etapasEnsino.length == 0 ? 'Campo não preenchido' : '',
        };

        const occupation = usOc.find(o => o.id === parseInt(values.ocupacao.toString()));

        _errors.erroOcupacaoOutra =
            occupation?.id == OTHER_OCCUPATION_ID && (values.ocupacaoOutra == '' || values.ocupacaoOutra.length < 4)
                ? 'Campo não preenchido ou insuficiente'
                : '';

        setErros(_errors);
    };

    const clearError = () => setErros(initialErrors);

    const getSomeError = (values: IValues) => {
        const {
            erroCpf,
            erroComoConheceu,
            erroDataNascimento,
            erroGenero,
            erroCor,
            erroTipoFormacao,
            erroOcupacao,
            erroOcupacaoOutra,
            erroDeficiencia,
            erroDeficienciaOutra,
            erroSeledtedDeficiencia,
        } = erros;

        const value =
            !!erroCpf ||
            !!erroComoConheceu ||
            !!erroDataNascimento ||
            !!erroGenero ||
            !!erroCor ||
            !!erroTipoFormacao ||
            !!erroOcupacao ||
            !!erroDeficiencia ||
            !!erroDeficienciaOutra ||
            !!erroSeledtedDeficiencia ||
            !!erroOcupacaoOutra;
        if (
            value == true &&
            !erroCpf &&
            !erroComoConheceu &&
            !erroGenero &&
            !erroCor &&
            !erroTipoFormacao &&
            !erroOcupacao &&
            !erroDeficiencia &&
            !erroDeficienciaOutra &&
            !erroSeledtedDeficiencia &&
            !erroOcupacaoOutra
        ) {
            if (values.dataNascimento.length == 0) return true;
            return validDate(values.dataNascimento);
        }

        return value;
    };

    const loadCidadesPorUf = async (uf: string) => {
        try {
            setIsLoadingCities(true);
            const response: any = await profileService.consultarCidadesPorUF(uf);
            const _cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));
            setCidades(_cidades);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingCities(false);
        }
    };

    const setupEtapa = (formik: FormikProps<any>, etapaId: number, value: boolean) => {
        clearError();

        let etapas = formik.values['etapasEnsino'] as number[];

        if (value && !etapas.includes(etapaId)) {
            etapas.push(etapaId);
        } else etapas = etapas.filter(e => e != etapaId);

        formik.setFieldValue('etapasEnsino', etapas);
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);

    useEffect(() => {
        fetchDeficiencias();
    }, []);

    const Tooltip = () => {
        return (
            <Styled.TooltipStyle>
                <Styled.InfoText>{`Por que pedimos essa ${` `} 
                            informação?`}</Styled.InfoText>
                <Styled.Tooltipdialog className="tooltipdialog">
                    {`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.
                        ${` `}
                        Ele não será compartilhado com organizações externas.`}
                </Styled.Tooltipdialog>
            </Styled.TooltipStyle>
        );
    };

    const QuestionMark = () => {
        return (
            <Styled.QuestionMarkStyle
                onClick={() => {
                    setShowDialog(true);
                }}
            >
                <Styled.SvgIcon>
                    <path
                        d="M8.17248 5.3494C7.86213 5.29616 7.54296 5.35448 7.27149 5.51403C7.00003 5.67357 6.79378 5.92404 6.68929 6.22108C6.56711 6.56841 6.1865 6.75092 5.83917 6.62874C5.49185 6.50656 5.30933 6.12595 5.43151 5.77862C5.64049 5.18455 6.05298 4.68361 6.59592 4.36452C7.13885 4.04543 7.7772 3.92879 8.39789 4.03526C9.01859 4.14172 9.58157 4.46442 9.98714 4.9462C10.3926 5.42789 10.6146 6.0375 10.6137 6.66713C10.6134 7.68736 9.85703 8.36111 9.31687 8.72122C9.02645 8.91483 8.74077 9.0572 8.53033 9.15073C8.42416 9.19791 8.33476 9.23372 8.27034 9.25826C8.23807 9.27055 8.21191 9.28008 8.19287 9.28685L8.16972 9.29496L8.16234 9.29748L8.15974 9.29835L8.15872 9.29869C8.15872 9.29869 8.15789 9.29897 7.94707 8.66652L8.15789 9.29897C7.80859 9.41541 7.43105 9.22663 7.31461 8.87734C7.19826 8.52827 7.3867 8.151 7.73555 8.0343L7.74619 8.03056C7.75645 8.02692 7.77325 8.02082 7.79568 8.01228C7.84063 7.99515 7.90748 7.96846 7.98881 7.93231C8.15337 7.85917 8.36769 7.75154 8.57727 7.61182C9.037 7.30533 9.2804 6.97927 9.2804 6.66652L9.2804 6.66553C9.28087 6.35065 9.16989 6.04576 8.9671 5.80487C8.76432 5.56398 8.48283 5.40263 8.17248 5.3494Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M8.00033 10.6665C7.63213 10.6665 7.33366 10.965 7.33366 11.3332C7.33366 11.7014 7.63213 11.9998 8.00033 11.9998H8.00699C8.37518 11.9998 8.67366 11.7014 8.67366 11.3332C8.67366 10.965 8.37518 10.6665 8.00699 10.6665H8.00033Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984ZM8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984Z"
                        fill="#5F758B"
                    />
                </Styled.SvgIcon>
            </Styled.QuestionMarkStyle>
        );
    };

    const InfoDialog = () => {
        return (
            <>
                <Modal
                    show={showDialog}
                    onHide={() => {
                        setShowDialog(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    dialogAs={Styled.ModalContainer}
                >
                    <Modal.Body>
                        <Styled.InfoDialogTitle>{`Por que pedimos essa informação?`}</Styled.InfoDialogTitle>
                        <br />
                        <Styled.InfoDialogDescription>{`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.`}</Styled.InfoDialogDescription>
                        <br />
                        <Styled.InfoDialogDescription>{`Ele não será compartilhado com organizações externas.`}</Styled.InfoDialogDescription>
                        <Styled.InofoDialogButton
                            onClick={() => {
                                setShowDialog(false);
                            }}
                        >
                            Continuar Cadastro
                        </Styled.InofoDialogButton>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
    return (
        <Styled.Container>
            <Styled.Title>É o seu primeiro acesso?</Styled.Title>
            <Styled.Subtitle>Nos conte um pouco mais sobre você!</Styled.Subtitle>
            <br />
            <br />

            <Formik initialValues={formikInitial} onSubmit={() => {}}>
                {formik => {
                    useEffect(() => clearError(), [formik.values]);

                    return (
                        <form style={{ maxWidth: isMobile ? '100%' : '600px' }}>
                            {/*Quando for usuário do lms solicita cpf */}
                            {isUsuarioLms(usuarioLogado) ? (
                                <>
                                    <Styled.Column error={!!erros.erroCpf}>
                                        <Styled.Label error={!!erros.erroCpf}>Meu CPF é:</Styled.Label>
                                        <Input
                                            status={!!erros.erroCpf ? 'error' : ''}
                                            name="cpf"
                                            maxLength={14}
                                            size="large"
                                            style={{ width: '500px' }}
                                            placeholder="Digite seu CPF"
                                            value={formik.values.cpf}
                                            onChange={(evt: any) => {
                                                const value: string = formatCPF(evt.target.value);
                                                formik.setFieldValue('cpf', value);
                                            }}
                                        />

                                        {IsMobileMaxWidth() ? <QuestionMark /> : <Tooltip />}
                                        {erros.erroCpf && <Styled.ErrorText2>Cpf Inválido</Styled.ErrorText2>}
                                    </Styled.Column>
                                </>
                            ) : (
                                <></>
                            )}
                            <Styled.Row>
                                <Styled.Column error={!!erros.erroComoConheceu}>
                                    <Styled.Label error={!!erros.erroComoConheceu}>Como conheceu a humane?</Styled.Label>
                                    <Select
                                        key={`comoConheceu-${erros.erroComoConheceu}`}
                                        status={!!erros.erroComoConheceu ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '100%' }}
                                        placeholder="Selecione"
                                        onChange={value => formik.setFieldValue('comoConheceu', value)}
                                        options={profileParameterData.listaComoConheceu.map(dados => ({
                                            value: dados.id,
                                            label: dados.descricao,
                                        }))}
                                    />
                                </Styled.Column>
                            </Styled.Row>

                            <Styled.Row>
                                <Styled.Column2 error={!!erros.erroOcupacao}>
                                    <Styled.Label error={!!erros.erroOcupacao}>Qual sua ocupação?</Styled.Label>
                                    {/* <Styled.Answer>
                                        <span>{usOc.find(o => o.role == userRoles()[0])?.descricao ?? ''}</span>
                                    </Styled.Answer> */}
                                    <Select
                                        key={`ocupacao-${erros.erroOcupacao}`}
                                        status={!!erros.erroOcupacao ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '294px' }}
                                        placeholder="Selecione"
                                        onChange={value => {
                                            formik.setFieldValue('ocupacao', value);
                                            setOtherOccupation(value === OTHER_OCCUPATION_ID);
                                        }}
                                        options={userOccupations
                                            .filter(o => o.autoCadastravel === true)
                                            .map(dados => ({
                                                value: dados.id,
                                                label: dados.descricao,
                                            }))}
                                    />
                                </Styled.Column2>
                                <Styled.Column2 error={!!erros.erroTipoFormacao}>
                                    <Styled.Label error={!!erros.erroTipoFormacao}>Escolaridade:</Styled.Label>
                                    <Select
                                        key={`tipoFormacao-${erros.erroTipoFormacao}`}
                                        status={!!erros.erroTipoFormacao ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '294px' }}
                                        placeholder="Selecione"
                                        onChange={value => formik.setFieldValue('tipoFormacao', value)}
                                        options={profileParameterData.listaTipoFormacao.map(dados => ({
                                            value: dados.id,
                                            label: dados.descricao,
                                        }))}
                                    />
                                </Styled.Column2>
                            </Styled.Row>
                            <Styled.Column>
                                {otherOccupation && (
                                    <Styled.Column2 error={!!erros.erroOcupacaoOutra}>
                                        <Styled.Label error={!!erros.erroOcupacaoOutra}>Identifique a ocupação:</Styled.Label>
                                        <Input
                                            status={!!erros.erroCpf ? 'error' : ''}
                                            name="ocupacaoOutra"
                                            size="large"
                                            width={'100%'}
                                            placeholder="Escreva sua ocupação"
                                            value={formik.values.ocupacaoOutra}
                                            onChange={(evt: any) => {
                                                formik.setFieldValue('ocupacaoOutra', evt.target.value);
                                            }}
                                        />
                                    </Styled.Column2>
                                )}
                            </Styled.Column>

                            <Styled.Row>
                                <Styled.Column2 error={!!erros.erroDataNascimento}>
                                    <Styled.Label error={!!erros.erroDataNascimento}>Nascimento:</Styled.Label>
                                    <Input
                                        status={!!erros.erroDataNascimento ? 'error' : ''}
                                        name="dataNascimento"
                                        size="large"
                                        placeholder="DD/MM/AAAA"
                                        maxLength={10}
                                        style={{ width: '200px' }}
                                        value={formik.values.dataNascimento}
                                        onChange={(evt: any) => {
                                            const value: string = evt.target.value;
                                            const newDate = formik.values['dataNascimento'].endsWith('/') ? value : MaskDate(value);
                                            formik.setFieldValue('dataNascimento', newDate);
                                        }}
                                    />
                                </Styled.Column2>
                                <Styled.Column2 error={!!erros.erroGenero}>
                                    <Styled.Label error={!!erros.erroGenero}>Gênero:</Styled.Label>
                                    <Select
                                        key={`genero-${erros.erroGenero}`}
                                        status={!!erros.erroGenero ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '200px' }}
                                        placeholder="Selecione"
                                        onChange={value => formik.setFieldValue('genero', value)}
                                        options={profileParameterData.listaGeneros.map(dados => ({
                                            value: dados.id,
                                            label: dados.descricao,
                                        }))}
                                    />
                                </Styled.Column2>
                                <Styled.Column2 error={!!erros.erroCor}>
                                    <Styled.Label error={!!erros.erroCor}>Cor:</Styled.Label>
                                    <Select
                                        key={`comoConheceu-${erros.erroCor}`}
                                        status={!!erros.erroCor ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '178px' }}
                                        placeholder="Selecione"
                                        onChange={value => formik.setFieldValue('cor', value)}
                                        options={profileParameterData.listaCores.map(dados => ({
                                            value: dados.id,
                                            label: dados.descricao,
                                        }))}
                                    />
                                </Styled.Column2>
                            </Styled.Row>

                            <Styled.Column error={!!erros.erroSeledtedDeficiencia}>
                                <Styled.Label error={!!erros.erroSeledtedDeficiencia}>Você possui deficiência?</Styled.Label>
                                <Select
                                    key={`selectedDeficiencia-${erros.erroSeledtedDeficiencia}`}
                                    status={!!erros.erroSeledtedDeficiencia ? 'error' : ''}
                                    size={'large'}
                                    placeholder="Selecione"
                                    onChange={value => {
                                        formik.setFieldValue('selectedDeficiencia', value);
                                        setIsDeficiente(value === 1);
                                        setSelectedPossuiDeficiencia(value);
                                    }}
                                    options={listIsDeficiencia.map(dados => ({
                                        value: dados.id,
                                        label: dados.nome,
                                    }))}
                                />
                            </Styled.Column>
                            {isDeficiente && (
                                <Styled.Column error={!!erros.erroDeficiencia}>
                                    <Styled.Label error={!!erros.erroDeficiencia}>Informe qual o tipo de deficiência você possui:</Styled.Label>

                                    <Styled.TwoColumns>
                                        {tiposDeficiencias.map((e, idx) => {
                                            const isChecked = selectedDeficiencias.some(
                                                deficiencia => deficiencia.codigoTipoDeficiencia === e.id
                                            );

                                            return (
                                                <Styled.RowCheckox style={{ marginBottom: '10px' }} key={e.id}>
                                                    <Styled.CheckBox
                                                        type="checkbox"
                                                        name="deficiencia"
                                                        id={`deficiencia-${idx}`}
                                                        checked={isChecked}
                                                        onChange={evt => handleCheckboxChange(e, isChecked)}
                                                    />
                                                    <Styled.LabelCheck htmlFor={`deficiencia-${idx}`}>{e.descricao}</Styled.LabelCheck>
                                                </Styled.RowCheckox>
                                            );
                                        })}
                                    </Styled.TwoColumns>
                                    {otherDeficiencia && (
                                        <Styled.Column error={!!erros.erroDeficienciaOutra}>
                                            <Styled.Label error={!!erros.erroDeficienciaOutra}>Informe qual é a deficiência:</Styled.Label>
                                            <Styled.Column>
                                                <Input
                                                    status={!!erros.erroDeficienciaOutra ? 'error' : ''}
                                                    name="deficienciaOutra"
                                                    size="large"
                                                    placeholder="Descreva"
                                                    value={formik.values.deficienciaOutra}
                                                    onChange={(evt: any) => {
                                                        formik.setFieldValue('deficienciaOutra', evt.target.value);
                                                        handleDescription(evt.target.value);
                                                    }}
                                                />
                                                <label style={{ marginTop: '5px' }}>Digite no mínimo 5 caractéres</label>
                                            </Styled.Column>
                                        </Styled.Column>
                                    )}
                                </Styled.Column>
                            )}

                            <Styled.Row>
                                <Styled.Column2 error={!!erros.erroTipoRede}>
                                    <Styled.Label error={!!erros.erroTipoRede}>Rede de atuação:</Styled.Label>
                                    <Select
                                        key={`tipoRede-${erros.erroTipoRede}`}
                                        status={!!erros.erroTipoRede ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '200px' }}
                                        placeholder="Selecione"
                                        onChange={value => formik.setFieldValue('tipoRede', value)}
                                        options={profileParameterData.listaRedeEnsino.map(dados => ({
                                            value: dados.id,
                                            label: dados.descricao,
                                        }))}
                                    />
                                </Styled.Column2>
                                <Styled.Column2 error={!!erros.erroEstado}>
                                    <Styled.Label error={!!erros.erroEstado}>Estado de atuação:</Styled.Label>
                                    <Select
                                        key={`genero-${erros.erroEstado}`}
                                        status={!!erros.erroEstado ? 'error' : ''}
                                        size={'large'}
                                        style={{ width: '200px' }}
                                        placeholder="Selecione"
                                        onChange={value => {
                                            formik.setFieldValue('estado', value);
                                            loadCidadesPorUf(value);
                                        }}
                                        options={profileParameterData.listaEstados.map(dados => ({
                                            value: dados.id,
                                            label: dados.nome,
                                        }))}
                                    />
                                </Styled.Column2>
                                <Styled.Column2 error={!!erros.erroCor}>
                                    <Styled.Label error={!!erros.erroCor}>Cidade de atuação:</Styled.Label>
                                    <Select
                                        showSearch
                                        disabled={cidades.length === 0 || isLoadingCities}
                                        placeholder="Selecione"
                                        optionFilterProp="label"
                                        size={'large'}
                                        style={{ width: '178px' }}
                                        onChange={value => {
                                            formik.setFieldValue('municipio', value);
                                        }}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={cidades.map(dados => ({ label: dados.nome, value: dados.id }))}
                                    />
                                </Styled.Column2>
                            </Styled.Row>

                            <Styled.Row error={!!erros.erroEtapasEnsino}>
                                <Styled.Label error={!!erros.erroEtapasEnsino}>Você atua em quais etapas de ensino?</Styled.Label>
                            </Styled.Row>
                            <Styled.TwoColumns>
                                {etapasEnsino.listaEtapasEnsino.map((e, idx) => {
                                    return (
                                        <Styled.Row style={{ marginBottom: '10px' }} key={e.id}>
                                            <Styled.CheckBox
                                                type="checkbox"
                                                id={`etapa-${idx}`}
                                                onChange={evt => setupEtapa(formik, e.id, evt.target.checked)}
                                            />
                                            <Styled.LabelCheck htmlFor={`etapa-${idx}`}>{e.descricao}</Styled.LabelCheck>
                                        </Styled.Row>
                                    );
                                })}
                            </Styled.TwoColumns>
                            <br />
                            <br />

                            <Styled.Row>
                                <PrimaryButton
                                    style={{
                                        padding: '10px 10px 10px 10px',
                                        borderRadius: '6px',
                                        width: '300px',
                                        height: '80px',
                                        fontSize: '18px',
                                    }}
                                    type="submit"
                                    onClick={e => onSubmitHandler(e, formik)}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Enviando...' : 'Finalizar cadastro'}
                                </PrimaryButton>

                                {getSomeError(formik.values) && (
                                    <Styled.ErrorText>Atenção: preencha todos os campos antes de avançar.</Styled.ErrorText>
                                )}
                            </Styled.Row>

                            {showDialog && <InfoDialog />}
                        </form>
                    );
                }}
            </Formik>
            <PopupQuestion
                onButton={() => setShowErrorModal(false)}
                okButtonText={'Ok'}
                cancelButtonText={'Ok'}
                infoOnly={true}
                question={'O CPF informado já possui uma conta cadastrada em nossa base de dados.'}
                visible={showErrorModal}
            />
        </Styled.Container>
    );
};

export default InformacoesPessoaisForm1;
