import QueryString from 'qs';
import { AxiosInstance } from 'axios';

import { HttpHandler } from '../HttpHandler';

export class GenericService {
    private httpHandler: HttpHandler = HttpHandler.getInstance();
    private uri: string = '';

    protected getLink(resourceName?: string): string {
        return this.uri + (!!resourceName ? `/${resourceName}` : ``);
    }

    public async get(resourceName?: string, params?: any): Promise<any> {
        const _instance: AxiosInstance = this.httpHandler.getAxios();
        const _uri: string = this.getLink(resourceName);
        try {
            if (!!params) {
                return _instance.get(_uri, {
                    params,
                    paramsSerializer: QueryString.stringify,
                });
            } else {
                return _instance.get(_uri);
            }
        } catch (error) {
            console.error('generic service error get: ', error);
        }
        return null;
    }

    public async post(resourceName: string, body: any, params?: any): Promise<any> {
        try {
            const _uri: string = this.getLink(resourceName);

            if (params != null) {
                return this.httpHandler.getAxios().post(_uri, body, { params });
            } else return this.httpHandler.getAxios().post(_uri, body);
        } catch (error) {
            console.error('generic service error post: ', error);
        }
        return null;
    }

    public async postFormData(resourceName: string, data: FormData): Promise<any> {
        try {
            const _uri: string = this.getLink(resourceName);
            return this.httpHandler.getAxios().post(_uri, data);
        } catch (error) {
            console.error('generic service error post: ', error);
        }
        return null;
    }

    public async put(resourceName: string, body: any): Promise<any> {
        const _uri: string = this.getLink(resourceName);

        try {
            return this.httpHandler.getAxios().put(_uri, body);
        } catch (error) {
            console.error('generic service error put: ', error);
        }
        return null;
    }

    public async patch(resourceName: string, body?: any): Promise<any> {
        const _uri: string = this.getLink(resourceName);

        try {
            return this.httpHandler.getAxios().patch(_uri, body);
        } catch (error) {
            console.error('generic service error put: ', error);
        }
        return null;
    }

    public async delete(id: number): Promise<any> {
        try {
            return this.httpHandler.getAxios().delete(`${this.uri}/${id}`);
        } catch (error) {
            console.error('generic service error delete:', error);
        }
        return null;
    }

    public async deleteByParams(resourceName?: string, params?: any): Promise<any> {
        const _instance: AxiosInstance = this.httpHandler.getAxios();
        const _uri: string = this.getLink(resourceName);
        try {
            if (!!params) {
                return _instance.delete(_uri, {
                    params,
                    paramsSerializer: QueryString.stringify,
                });
            } else {
                return _instance.delete(_uri);
            }
        } catch (error) {
            console.error('generic service error deleteByParams: ', error);
        }
        return null;
    }

    public async deleteByBody(resourceName?: string, body?: any): Promise<any> {
        const _instance: AxiosInstance = this.httpHandler.getAxios();
        const _uri: string = this.getLink(resourceName);
        try {
            if (!!body) {
                return _instance.delete(_uri, {
                    data: body,
                    paramsSerializer: QueryString.stringify,
                });
            } else {
                return _instance.delete(_uri);
            }
        } catch (error) {
            console.error('generic service error deleteByParams: ', error);
        }
        return null;
    }

    protected async setUri(uri: string) {
        this.uri = uri;
    }
}
