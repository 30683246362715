import React, { useEffect, useState } from 'react';
import Styled from './CartaoMultiSolucoes.styled';
import TagSolucoes from './TagSolucoes';
import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';
import { YellowButton } from 'components/UI/Buttons';
import { MdArrowForwardIos } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import styled from 'styled-components';
import Colors from 'enums/Colors';
import keycloak from 'core/config/keycloak';

const styleButton = {
    button: styled.button<{bgColor?: Colors, marginTop?: string}>`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: ${({bgColor}) => bgColor ? bgColor : Colors.Yellow};
        color: ${({bgColor}) =>  bgColor === Colors.Blue ? Colors.White : Colors.Blue};
        border-radius: 10px;
        height: 45px;
        font-weight: 700;
        font-size: 12px;
        width: 250px;
        border: none;
        gap: 0.5rem;
        margin-top: ${({marginTop}) => marginTop};
    `,
};

export type TypeCard = {
    id: number;
    tag: TagSolucoesEnum[];
    img?: string;
    imgWith?: string;
    imgHeight?: string;
    smallDescription?: string;
    titulo: string;
    descricao: string;
    link: string;
    linkExclusivo: string;
};

interface ICartaoMultiSolucoes {
    dados: TypeCard;
    isButtonOculto?: boolean;
    mySolucoesExclusivas: TipoSolucoes[];
    marginTop?: string;
}
const CartaoMultiSolucoes = ({ dados, isButtonOculto, mySolucoesExclusivas, marginTop }: ICartaoMultiSolucoes) => {
    const [possuiExclusividade, setPossuiExclusividade] = useState<boolean>(false);
    const history = useHistory();

    const goToPush = () => {
        history.push(dados.link);
    };

    const goToPageExclusiva = () => {
        history.push(dados.linkExclusivo);
    };

    useEffect(() => {
        const acesso = mySolucoesExclusivas.filter(f => f.id === dados.id);

        if (acesso.length > 0) {
            setPossuiExclusividade(true);
        }
    }, [mySolucoesExclusivas, dados]);

    return (
        <Styled.Container>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.2rem',
                    height: '60px',
                }}
            >
                {dados.tag.map((dados, index) => (
                    <TagSolucoes key={index} type={dados} />
                ))}
            </div>

            <Styled.ContentImg>
                <Styled.Img
                    width={dados.imgWith}
                    height={dados.imgHeight}
                    src={
                        dados.img ??
                        'https://estado.sc.gov.br/noticias/wp-content/uploads/sites/3/2019/12/reuniao_sao_paulo_instituto_ayrton_senna_20191203_2072413675.jpeg'
                    }
                />
            </Styled.ContentImg>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', height: 100 }}>
                <Styled.Title>{dados.titulo}</Styled.Title>
                <Styled.Description dangerouslySetInnerHTML={{ __html: dados.descricao }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', height: keycloak.authenticated ? 85 : 40 }}>
                    {' '}
                    {!isButtonOculto && (
                        <styleButton.button onClick={goToPush}>
                            SABER MAIS <MdArrowForwardIos />
                        </styleButton.button>
                    )}
                    {possuiExclusividade && (
                        <styleButton.button marginTop={marginTop} bgColor={Colors.Blue} onClick={goToPageExclusiva}>
                            {` Acessar conteúdo exclusivo`.toLocaleUpperCase()} <MdArrowForwardIos />
                        </styleButton.button>
                    )}
                </div>
            </div>
        </Styled.Container>
    );
};

export default CartaoMultiSolucoes;
