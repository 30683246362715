import { ResizeMediumWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';

const TipoModeloEmBrancoStyled = {
    Container: styled.div(({ ...props }) => () => {
        return new FullCss(
            {
                marginTop: '86px',
                position: 'relative',
            },
            new MediaQuery(mediaOnlyScreenMaxWidthStr(ResizeMediumWidth), {
                marginTop: '60px',
            })
        ).getCss();
    }),
};

export default TipoModeloEmBrancoStyled;
