import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Colors from '../../../../../enums/Colors';
import FormHeader from '../../formHeader/FormHeader';
import ImagePreview from '../components/ImagePreview';
import { ProgressConsumer } from '../../../ProgressContext';
import { ConfirmButton } from '../components/ImageSelect.styled';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';
import { IdentificacaoImagemStyle, FormContainer, ImgSection } from '../IdentificacaoImagem.styled';

import detailsImage from '../../../../../images/profile/details.svg';

const Text = styled.p({
    fontSize: '21px',
    color: Colors.BlueGrey,
    margin: '23px 0',
    width: '472px',
    lineHeight: '25px',
    maxWidth: '100%',
});

const Bold = styled.span({
    fontWeight: 'bold',
});

const Column = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 'fit-content',
});

const Details = styled.img`
    width: 125px;
    height: 125px;
    transform: translateX(95px);

    @media (max-width: ${MobileMaxWidth}px) {
        margin-top: 0px;
    }
`;

const ConfirmarImagemPerfil = () => {
    const history = useHistory();
    const statusProgresso: number = 78;

    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(window.screen.width <= MobileMaxWidth);
    }, []);

    const continuar = () => {
        history.push('/profile/regrasConvivencia');
    };

    return (
        <ProgressConsumer>
            {value => {
                const { progresso, setProgresso } = value;
                if (statusProgresso > progresso) {
                    setProgresso(statusProgresso);
                }

                return (
                    <IdentificacaoImagemStyle>
                        <FormContainer>
                            <FormHeader title="Excelente!! Ficou do jeito que você queria?" subtitle="Nada como um perfil bem caprichado!" />
                            <Text>
                                Caso não, é só clicar em <Bold>Trocar Imagem</Bold> para selecionar uma outra imagem.
                            </Text>
                            {!isMobile && (
                                <ConfirmButton disabled={false} onClick={continuar}>
                                    Confirmar
                                </ConfirmButton>
                            )}
                        </FormContainer>

                        <Column>
                            <Details src={detailsImage} />
                            <ImgSection
                                style={{
                                    height: 'fit-content',
                                    marginTop: isMobile ? '-95px' : '-75px',
                                }}
                            >
                                <ImagePreview />
                            </ImgSection>
                            {isMobile && (
                                <ConfirmButton disabled={false} onClick={continuar} style={{ width: '100%' }}>
                                    Confirmar
                                </ConfirmButton>
                            )}
                        </Column>
                    </IdentificacaoImagemStyle>
                );
            }}
        </ProgressConsumer>
    );
};

export default ConfirmarImagemPerfil;
