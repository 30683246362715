import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSecaoInteresse = {
    Container: styled.div<{ color?: string }>`
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        padding-top: 20px;
        background-color: ${({ color }) => `${color}`};
    `,

    Title: styled.p<{ color?: string; fontSize?: string }>`
        color: #183f73;
        font-family: Barlow;
        font-style: normal;
        font-weight: bold;
        font-size: ${({ fontSize }) => fontSize + 'px'};
        color: ${({ color }) => color};
        line-height: 43px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            max-width: 315px;
            padding-bottom: 30px;
        }
    `,

    Text: styled.p<{ color?: string; fontSize?: string }>`
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: ${({ fontSize }) => fontSize + 'px'};
        line-height: 24px;
        color: ${({ color }) => color};
        width: 60%;
        margin-bottom: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 14px;
            max-width: 315px;
            text-align: center;
            width: 100%;
        }
    `,

    ContentSendEmail: {
        Container: styled.div`
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 10px;
            width: 70%;
            height: 50px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                height: auto;
            }
        `,

        Input: styled.input<{ isWhite: boolean; isInvalid: boolean }>`
            position: absolute;
            width: 100%;
            border: none;
            height: 100%;
            border-radius: 20px;
            padding-left: 20px;
            padding-right: 200px;
            font-size: 20px;
            border: ${({ isWhite, isInvalid }) => (!isInvalid ? '2px solid ' + Colors.InfoRed : isWhite && '2px solid' + Colors.Blue)};
            border-radius: 20px;

            &:focus {
                outline: none;
            }

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                position: relative;
                padding: 20px;
                height: 60px !important;
            }
        `,
    },
};

export default StyledSecaoInteresse;
