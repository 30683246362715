import styled, { keyframes } from "styled-components";
import { MobileMaxWidth } from "../../../../constants/Dimensions";

export const IdentificacaoImagemStyle = styled.div`
  padding-top: 70px;
  padding-left: 100px;
  display: flex;
  width: 100%;
  min-height: 100vh;

  @media (max-width: ${MobileMaxWidth}px) {
    max-width: 100%;
    overflow: hidden;
    padding: 40px 20px;
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  margin-top: 0px;
  height: fit-content;
`;

export const Section = styled.section`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const ImgSection = styled(Section)`
  justify-content: center;
  padding: 20px 0;
`;

const fade = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SvgFadeImg = styled.img`
  animation: ${fade} 0.3s linear;

  @media (max-width: ${MobileMaxWidth}px) {
    display: none;
  }
`;

export const FormRow = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: ${MobileMaxWidth}px) {
    margin-top: 20px;
  }
`;
