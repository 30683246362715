import { OutlineButton } from 'components/UI/Buttons';
import Checkbox from 'components/UI/Checkbox';
import styled from 'styled-components';

const BiblioNotificationItemMarkStyled = {
    Container: styled.div`
        padding: 5px;
        display: grid;
        grid-template-columns: 30px 1fr;
        cursor: pointer;
        border-radius: 5px;
        margin: 20px 0;

        :hover {
            background-color: #f6f6f6;
        }
    `,

    MarkContainer: styled.div`
        width: 100%;
    `,

    ContentContainer: styled.div`
        width: 100%;
        padding: 10px 0;
        padding-top: 0;
    `,

    Title: styled.p`
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
    `,

    Text: styled.p`
        font-size: 16px;
        line-height: 19px;
        color: #747474;
    `,

    Date: styled.p`
        font-size: 10px;
        line-height: 12px;
        color: #00a8e9;
        margin-top: 10px;
    `,

    Checkbox,
};

export default BiblioNotificationItemMarkStyled;
