import React from 'react';
import Styled from './BarProgress.styled';

interface IBarProgress {
    porcent: number | null | undefined;
}

const BarProgress = ({porcent}: IBarProgress) => {
    return (
        <Styled.Container>
            <Styled.Barra>
                <Styled.BarraActive width={porcent ?? 0}/>
            </Styled.Barra>
            <Styled.Porcent>{porcent ?? 0}%</Styled.Porcent>
        </Styled.Container>
    );
};

export default BarProgress;
