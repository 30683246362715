import { Button } from 'antd';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import ComponentSanfonaConteudoModel from 'models/componentInteraction/ComponentSanfonaConteudo';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { FaFileAudio } from 'react-icons/fa6';
import { CiText } from 'react-icons/ci';
import { Form } from 'react-bootstrap';

interface ISanfonaInteractionAdmin {
    conteudos: ComponentSanfonaConteudoModel[] | null;
    onChangeValue: (val: string, tipoConteudo: ComponentInteractionEnum, index: number) => void;
    handleNewConteud: (val: string, tipoConteudo: ComponentInteractionEnum) => void;
}

const SanfonaInteractionAdmin = ({ conteudos, onChangeValue, handleNewConteud }: ISanfonaInteractionAdmin) => {

 
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {conteudos &&
                conteudos?.length > 0 &&
                conteudos.map((dados, idx) => {
                    if (dados.tipoConteudo === ComponentInteractionEnum.TEXT) {
                        return (
                            <ReactQuill
                                key={idx}
                                style={{ marginTop: '20px' }}
                                theme="snow"
                                modules={editorConfig.modules}
                                formats={editorConfig.formats}
                                value={dados.conteudo}
                                onChange={v => onChangeValue(v, ComponentInteractionEnum.TEXT, idx)}
                            />
                        );
                    }

                    if (dados.tipoConteudo === ComponentInteractionEnum.AUDIO) {
                        return (
                            <div key={idx} style={{ display: 'flex', flexDirection: 'column' }}>
                                <h1 style={{ fontWeight: 600 }}>URL do áudio</h1>
                                <Form.Control
                                    key={idx}
                                    value={dados.conteudo}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        onChangeValue(evt.target.value, ComponentInteractionEnum.AUDIO, idx)
                                    }
                                />
                            </div>
                        );
                    }
                })}
            <div style={{ display: 'flex', gap: '2rem', marginTop: '10px' }}>
                <Button onClick={() => handleNewConteud('', ComponentInteractionEnum.TEXT)} type="primary">
                    {' '}
                    <CiText /> Texto
                </Button>
                <Button onClick={() => handleNewConteud('', ComponentInteractionEnum.AUDIO)} type="primary">
                    {' '}
                    <FaFileAudio /> Áudio
                </Button>
            </div>
        </div>
    );
};

export default SanfonaInteractionAdmin;
