import { Serializable, jsonProperty, jsonName } from 'ts-serializable';
import { v4 as uuidV4 } from 'uuid';

export enum LogAcaoRecursoEnum {
    ACESSO_PAGINA = 'ACESSO_PAGINA',
    ACESSO_CONTEUDO = 'ACESSO_CONTEUDO',
    DOWNLOAD_MATERIAL = 'DOWNLOAD_MATERIAL',
    CONCLUSAO_CONTEUDO = 'CONCLUSAO_CONTEUDO',
}

export function browserGenerateKey(): string {
    const STORE_NAME = 'browserKey';

    let key = localStorage.getItem(STORE_NAME);

    if (key) return key;
    else {
        key = uuidV4();
        localStorage.setItem(STORE_NAME, key);
        return key;
    }
}
export class LogAcaoRecursoCustomizado extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonName('codigo_usuario')
    @jsonProperty(String)
    codigoUsuario: string = '';

    @jsonName('nome_recurso')
    @jsonProperty(String)
    nomeRecurso: string = '';

    @jsonName('url_pagina')
    @jsonProperty(String)
    urlPagina: string = '';

    @jsonName('acao')
    @jsonProperty(String)
    acao: string = '';

    @jsonName('browser_key')
    @jsonProperty(String)
    browserKey: string = '';

    @jsonName('especificacao_item')
    @jsonProperty(String)
    especificacaoItem: string = '';

    @jsonName('codigo_conteudo')
    @jsonProperty(String, null)
    codigoConteudo: string = '';

    @jsonName('codigo_item')
    @jsonProperty(String, null)
    codigoItem: string = '';
}
