import React from 'react';

import styled from 'styled-components';

import { DateHelp } from 'core/util/DateHelp';
import { DiaryTextStyle2 } from './DiaryText2.styled';

import iconLoading from '../../../../images/loading.svg';

export interface IDiaryTextFooter {
    isLoading: boolean;
    dateUpdate: Date;
}

const LoadingIcon = styled.img`
    height: 50px;
`;

const DiaryTextFooter = ({ isLoading, dateUpdate }: IDiaryTextFooter) => {
    return isLoading ? (
        <DiaryTextStyle2.FooterContainer style={{ transform: 'translate(-15px, -0px)' }}>
            <LoadingIcon src={iconLoading} />
            <DiaryTextStyle2.Footer style={{ marginLeft: '-12px' }}>Salvando Diário... </DiaryTextStyle2.Footer>
        </DiaryTextStyle2.FooterContainer>
    ) : (
        <DiaryTextStyle2.FooterContainer>
            <DiaryTextStyle2.Footer>Salvo automaticamente {DateHelp.formatDateDiario(dateUpdate)}</DiaryTextStyle2.Footer>
        </DiaryTextStyle2.FooterContainer>
    );
};

export default DiaryTextFooter;
