import { EducationalSolutionCategory } from 'models/EducationalSolution';
import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminHomeConteudo extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonName('titulo')
    @jsonProperty(String)
    title: string = '';

    @jsonName('conteudo')
    @jsonProperty(String)
    content: string = '';

    @jsonName('ocupacao')
    @jsonProperty(String, null)
    occupation?: string = '';

    @jsonName('secao')
    @jsonProperty(String)
    section: string = '';

    @jsonName('complemento')
    @jsonProperty(String)
    complement?: string = '';

    @jsonName('link')
    @jsonProperty(String, null)
    link?: string = '';

    @jsonName('linkExterno')
    @jsonProperty(Boolean, null)
    linkExtern?: boolean = false;

    @jsonName('linkInterno')
    @jsonProperty(String)
    linkInterno: string = '';

    @jsonName('textoBotaoA')
    @jsonProperty(String, null)
    texto_botao_a: string = '';

    @jsonName('textoBotaoB')
    @jsonProperty(String, null)
    texto_botao_b: string = '';

    @jsonName('paginaLateral')
    @jsonProperty(Boolean, null)
    paginaLateral: boolean = false;

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    date?: string = '';

    @jsonIgnore()
    categories?: EducationalSolutionCategory | null = null;

    @jsonName('ativo')
    @jsonProperty(Boolean, null)
    active?: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonName('proximoLancamento')
    @jsonProperty(Boolean, null)
    proximoLancamento?: boolean = false;

    @jsonName('novidade')
    @jsonProperty(Boolean, null)
    novidade?: boolean = false;

    @jsonName('desabilitado')
    @jsonProperty(Boolean, null)
    disabled?: boolean = false;

    @jsonIgnore()
    attach?: File | null = null;

    @jsonIgnore()
    precover: string | null = null;

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String)
    ultimaAtualizacao: string = '';
}

export default AdminHomeConteudo;
