import React, { CSSProperties } from 'react';
import styled, { CSSProp } from 'styled-components';

type RowJustifyType = 'space-between' | 'space-around' | 'center' | 'start' | 'end';
type RowAlignType = 'center' | 'start' | 'end';

export interface IRow {
    justify?: RowJustifyType;
    alignVertical?: RowAlignType;
    style?: CSSProperties;
}

const Container = styled.div<{ justify: string; alignVertical: string; styles?: any }>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify};
    align-items: ${props => props.alignVertical};

    ${props => props.styles}
`;

const Row = ({ children, justify, alignVertical, style }: React.PropsWithChildren<IRow>) => {
    return (
        <Container styles={style} justify={justify ?? 'start'} alignVertical={alignVertical ?? 'center'}>
            {children}
        </Container>
    );
};

export default Row;
