import PgIntermediariaConteudoRelacionado from 'models/admin/PgIntermediariaConteudoRelacionado';
import ItemFaq from 'models/faq/ItemFaq';
import { GenericService } from './Generic.service';

export class ItemFaqService extends GenericService {
    readonly URI: string = '/v1/itemFaq';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listarPublico() {
        return this.get('public/list');
    }

    public updateOrder(data: ItemFaq) {
        return this.post('updateOrder', data);
    }

    public inserir(data: ItemFaq) {
        return this.post('insert', data);
    }

    public editar(id: number, data: ItemFaq) {
        return this.put(String(id), data);
    }

    public excluir(id: number) {
        return this.deleteByParams(String(id));
    }
}
