import Colors from 'enums/Colors';
import styled from 'styled-components';

interface ICardTag {
    bgColor?: string;
    textColor?: string;
    exibirAbaixo?: boolean;
    exibirNormal?: boolean;
}

export const CardTag = styled.div<ICardTag>`
    min-width: 170px;
    position: absolute;
    height: 23px;
    width: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 5px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.bgColor ? props.bgColor : Colors.Grey12)};

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: ${props => (props.textColor ? props.textColor : Colors.UncleanWhite)};

    top: ${props => (props.exibirAbaixo ? '45px' : '15px') && (props.exibirNormal ? '15px' : '45px')};

    left: 45%;

    @media only screen and (max-width: 350px) {
        left: 40%;
    }
`;
