import React, { useEffect } from 'react';

import { CSSProperties } from 'styled-components';
import {
    StatementCarouselCardStyle,
    ProfileContainer,
    DescriptionContainer,
    DescriptionText,
    IdenficationContainer,
    NameText,
    FunctionText,
    AvatarSvg,
    HumaneButton,
    ButtonContainer,
} from 'pages/home/components/statements/statementCarouselCard/StatementCarouselCard.styled';
import { bucketS3, caminhoAnexosGestaoPaginaCarousel } from 'constants/EnvironmentData';
import { CardsCarrossel } from 'models/generic-components/CardsCarrossel';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import { useHistory } from 'react-router';
import useLogged from 'hooks/useLogged';
import { GTM_Gestao_Paginas } from 'components/GoogleTagManager';
import { IsMobileMaxWidth } from 'core/util/WindowSize';

interface IStatementCarouselCard {
    statement: CardsCarrossel;
    isActive: boolean;
}

const CarouselCard = ({ statement, isActive }: IStatementCarouselCard) => {
    const history = useHistory();
    const { authenticated } = useLogged();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaCarousel();
    };

    const getPathname = (): string => {
        return location.pathname.split('/pagina')[1];
    };

    const goToLink = () => {
        GTM_Gestao_Paginas(getPathname(), `carousel_${statement.textoBotao}`, `${statement.link}`);
        GenericComponentsUtils.gotoLink(statement.link, history, authenticated, statement);
    };

    return (
        <StatementCarouselCardStyle>
            <ProfileContainer>
                <AvatarSvg src={getS3BaseUrl() + statement.filePath} />

                <IdenficationContainer>
                    <NameText>{statement.titulo}</NameText>
                    <FunctionText>{statement.ocupacao}</FunctionText>
                </IdenficationContainer>
            </ProfileContainer>
            <DescriptionContainer>
                <DescriptionText>&quot;{statement.conteudo}&quot;</DescriptionText>
            </DescriptionContainer>
            <ButtonContainer>
                <HumaneButton style={{ display: 'block' }} onClick={goToLink}>
                    {statement.textoBotao}
                </HumaneButton>
            </ButtonContainer>
        </StatementCarouselCardStyle>
    );
};

export default CarouselCard;
