import React from 'react';
import Styled from './SecaoJornadasCompetenciasSocioemocionais.styled';
import StyledGlobal from '../../CompetenciasSocioemocionais.styled';
import { ArrowButton, SliderContainer, SvgIcon } from 'pages/intermediarias/alfabetizacao360Detail/subcomponents/CursoLivre.styled';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Slider from 'react-slick';
import ItensSecaoJornadasCS from './ItensSecaoJornadasCS';
import { CardTrilha } from 'pages/intermediarias/alfabetizacao360Detail/subcomponents/TrilhaAprofundada';

interface ISecaoJornadasCompetenciasSocioemocionais {
    isMobile: boolean;
}

const SecaoJornadasCompetenciasSocioemocionais = ({ isMobile }: ISecaoJornadasCompetenciasSocioemocionais) => {
    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton
                style={{
                    left: '100%',
                }}
                next={true}
                onClick={props.onClick}
            >
                <SvgIcon>
                    <path
                        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893Z"
                        fill="#ffffff"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton
                style={{
                    left: '-3%',
                }}
                next={false}
                onClick={props.onClick}
            >
                <SvgIcon>
                    <path
                        d="M6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292891C7.31658 -0.0976325 6.68342 -0.0976325 6.29289 0.292891L0.292894 6.29289C-0.0976308 6.68342 -0.0976309 7.31658 0.292894 7.7071L6.29289 13.7071Z"
                        fill="#ffffff"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <Styled.Container isMobile={isMobile}>
            <StyledGlobal.Title isMobile={isMobile} style={{ color: '#ffffff' }}>
                AMPLIE SEUS CONHECIMENTOS: 
            </StyledGlobal.Title>
            <StyledGlobal.SubTitle style={{ width: isMobile ? '100%' : '60%', marginBottom: '16px' }}>
                Neste curso trazemos um olhar inovador para o desenvolvimento das competências socioemocionais pelos estudantes. A partir dos
                resultados de nossas pesquisas, apresentamos os benefícios que esse desenvolvimento traz para a vida de quem as desenvolve com
                intencionalidade.
            </StyledGlobal.SubTitle>
            {isMobile ? (
                <SliderContainer colorDots="#ffffff">
                    <Slider {...settings}>
                        {ItensSecaoJornadasCS.map((item, index) => (
                            <CardTrilha key={index} dados={item} />
                        ))}
                    </Slider>
                </SliderContainer>
            ) : (
                <Styled.Row>
                    {ItensSecaoJornadasCS.map((item, index) => (
                        <CardTrilha key={index} dados={item} />
                    ))}
                </Styled.Row>
            )}
        </Styled.Container>
    );
};

export default SecaoJornadasCompetenciasSocioemocionais;
