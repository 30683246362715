import { jsonProperty, Serializable } from "ts-serializable";


class ComponentQuizAlternativasModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    alternativa: string = '';

    @jsonProperty(Boolean)
    correct: boolean = false;

}


export default ComponentQuizAlternativasModel;