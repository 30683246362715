import styled from 'styled-components';

const StyledBarProgress = {
    Container: styled.div`
        display: flex;
        width: 100%;
    `,

    Barra: styled.div`
        background-color: #cbd2e0;
        width: 100%;
        height: 15px;
        border-radius: 20px;
    `,

    BarraActive: styled.div<{width?: number}>`
        width: ${props => props.width ? props.width + '%' : "0"};
        height: 100%;
        background-color: #003c78;
        border-radius: 20px;
    `,

    Porcent: styled.div`
    font-size: 15px;
        margin-left: 20px;
        font-weight: 400;
    `,
};

export default StyledBarProgress;
