import { PaginaIntermediariaTipoEnum } from 'models/paginas/PaginaIntermediariaTipo';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdminGestaoPaginasMode } from '../AdminGestaoPaginasContextWrapper';
import { AdminGestaoPaginasContext } from '../context/AdminGestaoPaginasContext';
import { initialBreadCrumb } from '../context/AdminGestaoPaginasProvider';
import Styled from './AdminGestaoPaginaNovoRecurso.styled';
import Row from 'components/Row';
import { Form } from 'react-bootstrap';
import { BlueSolidButton, PrimaryButton, RedSolidButton } from 'components/UI/Buttons';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { StringHelp } from 'core/util/StringHelp';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import AdminPaginaIntermediariaResumida from 'models/admin/AdminPaginaIntermediariaResumida';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import arrowIcon from '../../../images/admin/arrow-r.svg';
import Loading from 'components/Loading';
import { MdDeleteForever } from 'react-icons/md';
import Colors from 'enums/Colors';
import hash from 'object-hash';
import { RecursosCostumizadosEnum } from 'enums/RecursosCostumizados';
import { ResumoRecursoCustomizado } from 'models/recursos-costumizados/ResumoRecursoCustomizado';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AdminGestaoPaginasRecursosDragDrop, AdminGestaoPaginasRecursosDropzone } from './components/dragdrop/DragDrop';
import { ConteudoOrderItem } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import ModalExclusao from './components/modal/ModalExclusao';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalDelete from 'components/modal/ModalDelete';
import Select, { SelectOptionType } from 'components/Select';
import { HomePageService } from 'core/http/service/HomePage.service';

interface IAdminGestaoPaginaNovoRecurso {
    mode: AdminGestaoPaginasMode;
}

const AdminGestaoPaginaNovoRecurso = ({ mode }: IAdminGestaoPaginaNovoRecurso) => {
    const [templateType, setTemplateType] = useState<PaginaIntermediariaTipoEnum>(PaginaIntermediariaTipoEnum.NOVOSRECURSO);
    const [namePage, setNamePage] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [itensResumidos, setItensResumidos] = useState<ResumoRecursoCustomizado[] | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);
    const params: any = useParams();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [codigoItem, setCodigoItem] = useState<number>(-1);
    const [codigoRecurso, setCodigoRecurso] = useState<number>(-1);
    const [excluxed, setExcluxed] = useState<RecursosCostumizadosEnum>();
    const [modePage, setModePage] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [categoriasBusca, setCategoriasBusca] = useState<SelectOptionType[]>([]);
    const [categoriaBusca, setCategoriaBusca] = useState<number>(-1);

    const history = useHistory();
    const serviceRecursosCostumizados = new AdminRecursosCostumizados();

    const MAX_CHAR_CONTENT = 200;
    const MAX_CHAR_CONTENT_NAME = 75;

    const service = new AdminGestaoPaginaService();
    const servicePageIntermiaria = new PaginaIntermediariaService();
    const serviceRecursos = new AdminRecursosCostumizados();
    const serviceHeader = new HomePageService();

    const { breadCrumb, setBreadCrumb, currentPage, selectPage, saveOrUpdatePage } = useContext(AdminGestaoPaginasContext.Context);

    const fetchItensResumidos = async (pageId: number) => {
        const { data } = await serviceRecursos.buscarListaResumida(pageId);

        const itensResumidos = Array.from(data).map((i: any) => new ResumoRecursoCustomizado().fromJSON(i));

        setItensResumidos(itensResumidos);
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const fetchPage = async (pageId: number) => {
        const { data } = await service.buscarPagina(pageId);
        const pagedata = new AdminPaginaIntermediariaResumida().fromJSON(data);

        setNamePage(pagedata.nome);
        setUrl(pagedata.link);
        setDescription(pagedata.descricao);
        setCategoriaBusca(pagedata.categoriaBusca ?? -1);
        selectPage(pagedata);
        fetchItensResumidos(pageId);
        setLoading(false);
    };

    const fetchCategoriasBusca = async () => {
      const { data } = await serviceHeader.getCategoriasPesquisa();


      let options : SelectOptionType[] = [];

      data.forEach((ele: any) => {
        options.push({label: ele.nome, value: ele.codigo})
      });

      setCategoriasBusca(options!);
    };

    const isValidInputs = (): boolean => {
        return url.trim().length > 0 && namePage.trim().length > 0 && description.trim().length > 0;
    };

    const submitPage = async () => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');
            setSubmitting(false);
            return;
        }

        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await servicePageIntermiaria.verificarUriPageExistent('/' + sanitizeUrl);
            isExistent = value;
        }

        if (!isExistent) {
            const onsuccess = (paginasalva: AdminPaginaIntermediaria) => {
                setNamePage(paginasalva.nome);
                setUrl(paginasalva.link);
                ToastSuccess('Novo recurso salvo');
            };

            const onerror = () => {
                ToastError('Error ao salvar o novo recurso');
            };

            saveOrUpdatePage(namePage, sanitizeUrl, categoriaBusca, templateType, description, onsuccess, onerror);
            setModePage(true);
        } else ToastError('Já existe uma página para a URL informada');
        setSubmitting(false);
        scrollToTop();
    };

    const handleOcultar = async (idItem: number) => {
        const response = await serviceRecursosCostumizados.ocultarItem(idItem);

        if (response.status === 200) {
            ToastSuccess('Item ocultado.');
            fetchInitial();
        }
    };

    const handleExibir = async (idItem: number) => {
        const response = await serviceRecursosCostumizados.exibirItem(idItem);

        if (response.status === 200) {
            ToastSuccess('Item visivel.');
            fetchInitial();
        }
    };

    const handleDelete = async () => {
        setIsLoadingDelete(true);

        const response = await servicePageIntermiaria.excluirRecurso(codigoRecurso);

        if (response.status === 200) {
            ToastSuccess('Item excluido com sucesso!');
            history.push('/admin/gestao-paginas');
        } else {
            ToastError('Houve algum problema ao tentar excluir o conteúdo.');
        }
        setIsLoadingDelete(false);
        setShowModalDelete(false);
    };

    const setupOrder = async (item: ResumoRecursoCustomizado, neworder: number) => {
        const { codigo } = item;
        const dados = new ConteudoOrderItem();
        dados.codigo = codigo;
        dados.codigoPaginaIntermediaria = codigoRecurso;
        dados.ordem = neworder;
        const { data, status } = await serviceRecursosCostumizados.uptadeOrdemItem(dados);
        if (status == 200) {
            const resumoRecurso = Array.from(data).map((s: any) => new ResumoRecursoCustomizado().fromJSON(s));
            const _resumos: ResumoRecursoCustomizado[] = [];
            for (const resumo of resumoRecurso) {
                const dados = new ResumoRecursoCustomizado();
                dados.codigo = resumo.codigo;
                dados.nome = resumo.nome;
                dados.oculto = resumo.oculto;
                dados.ordem = resumo.ordem;
                _resumos.push(resumo);
            }
            setItensResumidos(_resumos);
            ToastSuccess('Ordem alterada com sucesso!');
        } else {
            ToastError('Houve um erro ao tentar ordenar os conteúdos.');
        }
    };

    const fetchInitial = () => {
        const queryParams = new URLSearchParams(location.search);
        const _templateTypeRaw = queryParams.get('template');

        if (_templateTypeRaw) {
            setTemplateType(parseInt(_templateTypeRaw));
        }

        setBreadCrumb(initialBreadCrumb(mode));

        fetchCategoriasBusca();

        if (currentPage) {
            setNamePage(currentPage.nome);
            setUrl(currentPage.link);
        }

        if (mode == 'EDIT') {
            const id = String(params.id);
            setLoading(true);
            fetchPage(parseInt(id));
            setCodigoRecurso(parseInt(id));
            setModePage(true);
        }
    };

    const validateOrder = () => {
        let newOrder = 0;

        for (const item of itensResumidos ?? []) {
            if (item.ordem > newOrder) {
                newOrder = item.ordem;
            }
        }

        return newOrder + 1;
    };

    useEffect(() => {
        fetchInitial();
    }, []);

    return (
        <Styled.Container>
            <ModalDelete
                isLoading={isLoadingDelete}
                namePage={namePage}
                handleDelete={() => handleDelete()}
                onHiden={() => {
                    setShowModalDelete(false);
                }}
                showModal={showModalDelete}
            />
            {isLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <ModalExclusao showModal={showModal} handleHiden={setShowModal} handleExcluxed={handleDelete} />
                    <AdminBreadCrumb crumbs={breadCrumb} />
                    <br />

                    <Styled.Title>{'Dados básicos do novo recurso'}</Styled.Title>

                    <br />

                    <Styled.FormContainer>
                        <Styled.Divisor width="400px">
                            <Styled.Label>Nome da página</Styled.Label>
                            <Styled.Input
                                value={namePage}
                                onChange={(evn: any) => setNamePage(evn.target.value)}
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT_NAME}
                            />
                            <Styled.CountLetter danger={namePage.length >= MAX_CHAR_CONTENT_NAME}>
                                {namePage.length}/{MAX_CHAR_CONTENT_NAME}
                            </Styled.CountLetter>
                        </Styled.Divisor>
                        <Styled.Divisor width="400px">
                            <Styled.Label>URL</Styled.Label>
                            <Styled.Input
                                value={url}
                                onChange={(evn: any) =>
                                    setUrl('/' + StringHelp.alnum(StringHelp.toSize(evn.target.value.toLowerCase().trim(), 200)))
                                }
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />
                            <Styled.CountLetter danger={url.length >= MAX_CHAR_CONTENT}>
                                {url.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>
                        </Styled.Divisor>
                    </Styled.FormContainer>
                    <Styled.FormContainer>
                        <Styled.Divisor width="100%">
                            <Styled.Label>Descrição da Página</Styled.Label>

                            <Styled.Input
                                value={description}
                                onChange={(evn: any) => setDescription(evn.target.value)}
                                rows={5}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />
                            <Styled.CountLetter danger={description.length >= MAX_CHAR_CONTENT}>
                                {description.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>

                    <Styled.Label>Categoria na busca</Styled.Label>
                    <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={categoriasBusca}
                                    value={categoriasBusca.find(c => c.value == categoriaBusca)}
                                    onSelected={o => setCategoriaBusca(o.value as number)}
                                />

                            <Row justify="space-between" style={{ marginTop: 20 }}>
                                {modePage && (
                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                        <RedSolidButton onClick={() => setShowModalDelete(true)}>Deletar</RedSolidButton>
                                    </AllowedGroupsValidator>
                                )}
                                <BlueSolidButton
                                    disabled={submitting}
                                    onClick={() => {
                                        setSubmitting(true);
                                        submitPage();
                                    }}
                                >
                                    {submitting ? 'Salvando...' : 'Salvar página'}
                                </BlueSolidButton>
                            </Row>
                        </Styled.Divisor>
                    </Styled.FormContainer>

                    <Styled.BottomContainer>
                        <hr style={{ margin: ' 30px 0' }} />
                        {currentPage != null && (
                            <>
                                <Row justify="space-between" style={{ maxWidth: '1100px', alignItems: 'center', marginBottom: 40 }}>
                                    <Styled.Title>Itens do novo recurso</Styled.Title>
                                </Row>
                                <div key={hash(itensResumidos)}>
                                    <DndProvider backend={HTML5Backend}>
                                        {itensResumidos
                                            ?.sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                                            .map((d, index) => (
                                                <React.Fragment key={index}>
                                                    <AdminGestaoPaginasRecursosDropzone thisOrder={d.ordem ?? -1} onDropItem={setupOrder} />
                                                    <AdminGestaoPaginasRecursosDragDrop secao={d}>
                                                        <Styled.RedirectButton>
                                                            <Styled.DivSpanCard
                                                                onClick={() =>
                                                                    history.push('/admin/gestao-paginas/editar-item-recurso/' + d.codigo)
                                                                }
                                                            >
                                                                <span>{d.nome}</span>
                                                            </Styled.DivSpanCard>
                                                            <Styled.DivAround>
                                                                <Styled.CkeckContainer>
                                                                    <Row>
                                                                        <span style={{ marginRight: '10px', fontSize: '14px' }}>Ocultar</span>
                                                                        <Form>
                                                                            <Form.Check
                                                                                type="switch"
                                                                                id={'item' + d.codigo}
                                                                                checked={d.oculto}
                                                                                onClick={() => {
                                                                                    !d.oculto ? handleOcultar(d.codigo) : handleExibir(d.codigo);
                                                                                }}
                                                                            />
                                                                        </Form>
                                                                    </Row>
                                                                </Styled.CkeckContainer>

                                                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                                    <MdDeleteForever
                                                                        onClick={() => {
                                                                            setCodigoItem(d.codigo);
                                                                            setShowModal(true);
                                                                        }}
                                                                        size={25}
                                                                        color={Colors.DeleteRed}
                                                                        cursor={'pointer'}
                                                                    />
                                                                </AllowedGroupsValidator>
                                                            </Styled.DivAround>

                                                            <img
                                                                src={arrowIcon}
                                                                onClick={() =>
                                                                    history.push('/admin/gestao-paginas/editar-item-recurso/' + d.codigo)
                                                                }
                                                            />
                                                        </Styled.RedirectButton>
                                                    </AdminGestaoPaginasRecursosDragDrop>
                                                    {index == itensResumidos.length - 1 && (
                                                        <AdminGestaoPaginasRecursosDropzone
                                                            thisOrder={(d.ordem ?? 0) + 1}
                                                            onDropItem={setupOrder}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </DndProvider>
                                </div>
                                <PrimaryButton onClick={() => history.push('/admin/gestao-paginas/novo-item-recurso/' + validateOrder())}>
                                    Adicionar novo item
                                </PrimaryButton>
                            </>
                        )}
                    </Styled.BottomContainer>
                </React.Fragment>
            )}
        </Styled.Container>
    );
};

export default AdminGestaoPaginaNovoRecurso;
