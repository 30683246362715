import { GradientButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const DialogSliderStyled = {
    Container: styled.div`
        width: calc(100% - 50px);
        margin: 0 auto;

        .slick-track {
            display: flex;
            align-items: center;
        }
    `,

    ButtonContainer: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    `,

    ButtonContainerAuto: styled.div`
        width: 140%;
        display: flex;
        justify-content: space-evenly;
        margin-left: -20%;
        .btnAuto {
            @media only screen and (max-width: ${MobileMaxWidth}px) {
                width: 30%;
            }

            @media only screen and (max-width: 400px) {
                width: 40%;
            }
        }
    `,

    Button: styled(GradientButton)``,

    CloseContainer: styled.div`
        text-align: right;
    `,

    CloseModal: styled.img`
        height: 25px;
        width: auto;

        transform: translateX(20px);
        cursor: pointer;
    `,
};

export default DialogSliderStyled;
